import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from "@material-ui/icons/Refresh";

import AccordionComponent from "../core/accordion";
import {
    unassignRoute,
    updateSelectedTruckSolutionList,
    confirmBundle,
    rejectBundle,
    recalculateSDBundle,
} from "../../actions/smartDispatch";
import {
    selectCurrentBundle,
    selectCurrentBundleAllowSubhaulers,
    selectCurrentBundleSolution,
    selectIfHasSelectedRoute,
    selectSelectedTruckSolutionList,
    selectSmartDispatchIsLoading,
} from "../../selectors/smartDispatchSelectors";
import ConfirmationModal from "../core/confirmationModal";
import { UNASSIGN_WARNING, REJECT_ALL_WARNING } from "../../constants/strings";
import * as strings from "./constants/strings";
import { BUNDLES_VIEW_BY } from "./constants/index";
import SolutionTruckListItem from "./SolutionTruckListItem";
import BundleSettingsForm from "./BundleSettingsForm";
import SplitButton from "../core/SplitButton";
import { DividerThin } from "../core/divider";
import { BUNDLE_FULLY_ASSIGNED } from "../bundles/BundlePills";

const useStyles = makeStyles(() => ({
    trucksSolutionList: {
        padding: "10px 5px",
    },
    trucksSolutionMessage: {
        padding: "15px",
    },
    button: {
        flex: 1,
        margin: 5,
        display: "flex",
        alignItems: "center",
        borderColor: "#D68530",
        color: "#D68530",
        background: "#fff",
        "&:hover": {
            background: "#fff",
        },
    },
}));

const options = [
    {
        label: "Confirm All",
        icon: <CheckIcon />,
        helperText: "Confirm all bundle routes",
    },
    {
        label: "Reject All",
        icon: <CloseIcon />,
        helperText: "Reject all routes and close the bundle",
    },
];

const SolutionTrucksList = (props) => {
    const [unassignTruckConfirmationModal, setUnassignTruckConfirmationModal] = useState(false);
    const [rejectAllConfirmation, setRejectAllConfirmation] = useState(false);
    const [solutionToUnassign, setSolutionToUnassign] = useState(null);

    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedTruckSolutionList = useSelector(selectSelectedTruckSolutionList);
    const currentBundle = useSelector(selectCurrentBundle);
    const isLoading = useSelector(selectSmartDispatchIsLoading);
    const trucksSolutionList = useSelector(selectCurrentBundleSolution);
    const allowSubhaulers = useSelector(selectCurrentBundleAllowSubhaulers);
    const hasRoute = useSelector(selectIfHasSelectedRoute);
    const isIntraDay = currentBundle?.isIntraDay;
    const isDraft = currentBundle?.isDraft;
    const canBeRecalculated = currentBundle?.canBeRecalculated;
    const bundleStatus = currentBundle?.status;
    const isFullyAssigned = bundleStatus === BUNDLE_FULLY_ASSIGNED;
    const handleTruckSolutionClick = (routeIndex) => {
        if (selectedTruckSolutionList.includes(routeIndex)) {
            dispatch(updateSelectedTruckSolutionList([]));
            props.resetPickUpDistanceParam();
        } else {
            dispatch(updateSelectedTruckSolutionList([routeIndex]));
        }
    };

    const handleUnassignClick = (event, route) => {
        event && event.stopPropagation();
        setUnassignTruckConfirmationModal(true);
        setSolutionToUnassign(route);
    };

    const yesHandler = () => {
        dispatch(unassignRoute(solutionToUnassign)).finally(() => {
            setUnassignTruckConfirmationModal(false);
        });
    };

    const noHandler = () => {
        setUnassignTruckConfirmationModal(false);
    };

    const confirmAllRoutes = () => {
        dispatch(confirmBundle());
        props.setUrlAfterRejectBundle();
    };

    const rejectAllRoutes = () => {
        dispatch(rejectBundle());
        props.setUrlAfterRejectBundle();
        setRejectAllConfirmation(false);
    };

    const recalculateBundle = () => {
        dispatch(recalculateSDBundle());
        props.setUrlAfterRejectBundle();
    };

    const rederLeftSideText = (text) => (
        <div className={classes.trucksSolutionMessage}>
            <br />
            <br />
            <h1 className="--text-center">{text}</h1>
        </div>
    );

    const getLeftText = () => {
        if (props.view === BUNDLES_VIEW_BY.BUNDLE) return strings.VIEW_BY_BUNDLES_LEFT_SIDE_TEXT;
        if (props.view === BUNDLES_VIEW_BY.TRUCK && hasRoute)
            return strings.VIEW_BY_TRUCKS_LEFT_SIDE_TEXT_WITH_SELECTED_ROUTE;
        if (props.view === BUNDLES_VIEW_BY.TRUCK) return strings.VIEW_BY_TRUCKS_LEFT_SIDE_TEXT;
        return strings.VIEW_BY_BUNDLES_LEFT_SIDE_TEXT;
    };
    if (!trucksSolutionList) {
        return rederLeftSideText(getLeftText());
    }

    if (props.view === BUNDLES_VIEW_BY.TRUCK) {
        return rederLeftSideText(
            hasRoute
                ? strings.VIEW_BY_TRUCKS_LEFT_SIDE_TEXT_WITH_SELECTED_ROUTE
                : strings.VIEW_BY_TRUCKS_LEFT_SIDE_TEXT,
        );
    }

    const actions = [confirmAllRoutes, () => setRejectAllConfirmation(true)];

    return (
        <div>
            <AccordionComponent
                styles={{ padding: "10px 5px" }}
                accordionItems={[
                    {
                        title: "Options",
                        content: (
                            <>
                                <BundleSettingsForm
                                    initialValues={{ allowSubhaulers }}
                                    disabled={!currentBundle.iAmOwner || currentBundle.isDraft}
                                />
                                {canBeRecalculated && (
                                    <>
                                        <DividerThin />
                                        <Box>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                size={"small"}
                                                className={"today"}
                                                startIcon={<RefreshIcon />}
                                                onClick={recalculateBundle}
                                                disabled={isDraft}
                                            >
                                                Recalculate Bundle
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </>
                        ),
                    },
                ]}
            />

            {isIntraDay && !isFullyAssigned && (
                <SplitButton
                    disabled={isDraft}
                    options={options}
                    actions={actions}
                    containerClassName={classes.trucksSolutionList}
                />
            )}
            <div className={classes.trucksSolutionList}>
                {trucksSolutionList.map((route) => {
                    const { id } = route;
                    const truckIsSelected = selectedTruckSolutionList.includes(id);

                    return (
                        <SolutionTruckListItem
                            key={id}
                            route={route}
                            isIntraDay={isIntraDay}
                            truckIsSelected={truckIsSelected}
                            handleUnassignClick={handleUnassignClick}
                            handleTruckSolutionClick={handleTruckSolutionClick}
                        />
                    );
                })}
            </div>
            <ConfirmationModal
                isOpen={unassignTruckConfirmationModal}
                isLoading={isLoading}
                question={UNASSIGN_WARNING}
                yesHandler={yesHandler}
                noHandler={noHandler}
                modalStyles={{ width: 700 }}
            />
            <ConfirmationModal
                isOpen={rejectAllConfirmation}
                question={REJECT_ALL_WARNING}
                yesHandler={rejectAllRoutes}
                noHandler={() => setRejectAllConfirmation(false)}
                modalStyles={{ width: 700 }}
            />
        </div>
    );
};

export default SolutionTrucksList;
