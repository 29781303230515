import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import CreateUserForm, { CREATE_USER_FORM } from "./createUserForm";
import { connect } from "react-redux";
import { handleTeamDialogToggle, setUserForEdit } from "../../actions/team";
import { createTeamUser, editUser } from "../../dataServers/teams";
import { TRUCKER_TYPES } from "../../constants/maps";
import AppModal from "../core/modal";

const TeamDialog = (props) => {
    const { handleTeamDialogToggle, teamDialogOpen, setUserForEdit, userForEdit, activeTab } = props;
    const handleClose = () => {
        handleTeamDialogToggle(false);
        setUserForEdit(null);
    };

    const mapUserData = (userData) => {
        userData = userData || {};
        const { region } = userData;

        return {
            ...userData,
            region: !_.isEmpty(region)
                ? {
                      value: region.id,
                      label: region.regionName,
                  }
                : null,
            truckerType: userData.truckerType
                ? {
                      value: userData.truckerType,
                      label: TRUCKER_TYPES[userData.truckerType],
                  }
                : null,
            company: userData.company
                ? {
                      value: userData.company.id,
                      label: userData.company.name,
                  }
                : null,
            phone: userData.phone,
            role: (userData.role && userData.role.toString()) || null,
            hasTemporaryPassword: userData.hasTemporaryPassword === undefined ? true : userData.hasTemporaryPassword,
        };
    };

    const handleSubmit = (data) => {
        return userForEdit ? editUser(data, userForEdit.id) : createTeamUser(data);
    };

    return (
        <AppModal isOpen={teamDialogOpen} form={CREATE_USER_FORM} closeModal={handleClose}>
            <h2 className="title">{userForEdit ? "CHANGE PROFILE" : "NEW USER"}</h2>
            <CreateUserForm
                handleClose={handleClose}
                initialValues={mapUserData(userForEdit)}
                userForEdit={mapUserData(userForEdit)}
                onSubmitHandler={handleSubmit}
                isEditForm={!!userForEdit}
                activeTab={activeTab}
            />
        </AppModal>
    );
};

TeamDialog.propTypes = {
    handleTeamDialogToggle: PropTypes.func.isRequired,
    setUserForEdit: PropTypes.func.isRequired,
    userForEdit: PropTypes.object,
    teamDialogOpen: PropTypes.bool,
    activeTab: PropTypes.number,
};

const mapStateToProps = (state) => ({
    teamDialogOpen: state.teams.teamDialogOpen,
    userForEdit: state.teams.userForEdit,
});

const mapDispatchToProps = (dispatch) => ({
    handleTeamDialogToggle: (open) => dispatch(handleTeamDialogToggle(open)),
    setUserForEdit: (userForEdit) => dispatch(setUserForEdit(userForEdit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDialog);
