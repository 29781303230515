import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../../constants/global";

export const REPORTING_FILTERS_DATE_ID = "namedPeriod";
export const REPORTING_FILTERS_HAULER_ID = "haulers";
export const REPORTING_FILTERS_TRUCK_ID = "trucks";
export const REPORTING_FILTERS_PROJECT_ID = "projects";
export const REPORTING_FILTERS_PAYLOAD_ID = "payloads";
export const REPORTING_FILTERS_SITE_ID = "sites";
export const REPORTING_FILTERS_DRIVER_ID = "drivers";
export const REPORTING_FILTERS_REGIONS_ID = "regions";
export const REPORTING_FILTERS_RANGE_ID = 0;
export const REPORTING_FILTERS_TODAY_ID = 1;
export const REPORTING_FILTERS_YESTERDAY_ID = 2;
export const REPORTING_FILTERS_THIS_WEEK_ID = 3;
export const REPORTING_FILTERS_LAST_WEEK_ID = 4;
export const REPORTING_FILTERS_THIS_MONTH_ID = 5;
export const REPORTING_FILTERS_LAST_MONTH_ID = 6;
export const DATE_ALL_VALUE = { label: "All", value: null };
export const DATE_INITIAL_VALUE = { label: "Today", value: REPORTING_FILTERS_TODAY_ID };
export const DATE_RANGE_VALUE = { label: "Date Range", value: REPORTING_FILTERS_RANGE_ID };
export const REPORTING_GROUP_BY_ID = "groupBy";

export const ADDITIONAL_FIELDS = {
    REPORTING_FILTERS_DATE_ID: "reportingFiltersAdditionalDate",
    START_DATE_NAME: "startDateAdditional",
    END_DATE_NAME: "endDateAdditional",
};
export const getFiltersForAdditionalReports = (formValues, isStartTimeReport = false) => {
    const regionsValues = formValues[REPORTING_FILTERS_REGIONS_ID];
    const regions = regionsValues && regionsValues.map((i) => i.value);
    const startDate = moment(formValues[ADDITIONAL_FIELDS.START_DATE_NAME]).format(SERVER_DATE_FORMAT);
    const payload = {
        startDate,
        endDate: isStartTimeReport
            ? startDate
            : moment(formValues[ADDITIONAL_FIELDS.END_DATE_NAME]).format(SERVER_DATE_FORMAT),
        groupBy: "trucks",
    };
    if (regions && regions.length) payload.regions = regions.toString();
    return payload;
};

export const getFilters = (...data) => {
    const [formValues, mainFilter, keywords, params, currentFilter] = data;
    const {
        [REPORTING_FILTERS_TRUCK_ID]: trucks,
        [REPORTING_FILTERS_PROJECT_ID]: projects,
        [REPORTING_FILTERS_SITE_ID]: sites,
        [REPORTING_FILTERS_PAYLOAD_ID]: payloads,
        [REPORTING_FILTERS_DRIVER_ID]: drivers,
        [REPORTING_FILTERS_HAULER_ID]: haulers,
        [REPORTING_FILTERS_DATE_ID]: date,
    } = formValues;
    const dateRange = date && defineDateRange(date.value);
    const startDate = dateRange && dateRange[START_DATE_NAME];
    const endDate = dateRange && dateRange[END_DATE_NAME];
    const dates = {
        [START_DATE_NAME]:
            date &&
            (date.value === REPORTING_FILTERS_RANGE_ID
                ? moment(formValues[START_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : startDate),
        [END_DATE_NAME]:
            date &&
            (date.value === REPORTING_FILTERS_RANGE_ID
                ? moment(formValues[END_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : endDate),
    };
    const filters = {
        [REPORTING_FILTERS_TRUCK_ID]: trucks && (trucks.map((i) => i.value).join(",") || null),
        [REPORTING_FILTERS_PROJECT_ID]: projects && (projects.map((i) => i.value).join(",") || null),
        [REPORTING_FILTERS_SITE_ID]: sites && (sites.map((i) => i.value).join(",") || null),
        [REPORTING_FILTERS_PAYLOAD_ID]: payloads && (payloads.map((i) => i.value).join(",") || null),
        [REPORTING_FILTERS_DRIVER_ID]: drivers && (drivers.map((i) => i.value).join(",") || null),
        [REPORTING_FILTERS_HAULER_ID]: haulers && (haulers.map((i) => i.value).join(",") || null),
    };

    delete filters[currentFilter];

    return mainFilter === currentFilter
        ? {
              keywords: keywords || undefined,
              page: params ? params.page : 1,
              ...dates,
          }
        : {
              keywords: keywords || undefined,
              page: params ? params.page : 1,
              ...dates,
              ...filters,
          };
};

export const defineDateRange = (value) => {
    let result = {};
    const currentDate = moment();

    switch (value) {
        case REPORTING_FILTERS_TODAY_ID:
            var today = moment().format(SERVER_DATE_FORMAT);

            result = {
                [START_DATE_NAME]: today,
                [END_DATE_NAME]: today,
            };
            break;
        case REPORTING_FILTERS_YESTERDAY_ID:
            var yesterday = moment().subtract("1", "d").format(SERVER_DATE_FORMAT);

            result = {
                [START_DATE_NAME]: yesterday,
                [END_DATE_NAME]: yesterday,
            };
            break;
        case REPORTING_FILTERS_THIS_WEEK_ID:
            var weekStart = currentDate.clone().startOf("isoWeek");
            var weekEnd = currentDate.clone().endOf("isoWeek");

            result = {
                [START_DATE_NAME]: weekStart.format(SERVER_DATE_FORMAT),
                [END_DATE_NAME]: weekEnd.format(SERVER_DATE_FORMAT),
            };
            break;
        case REPORTING_FILTERS_LAST_WEEK_ID:
            var lastWeek = moment().subtract(1, "week");

            result = {
                [START_DATE_NAME]: lastWeek.startOf("isoWeek").format(SERVER_DATE_FORMAT),
                [END_DATE_NAME]: lastWeek.endOf("isoWeek").format(SERVER_DATE_FORMAT),
            };
            break;
        case REPORTING_FILTERS_THIS_MONTH_ID:
            var monthStart = currentDate.clone().startOf("month");
            var monthEnd = currentDate.clone().endOf("month");

            result = {
                [START_DATE_NAME]: monthStart.format(SERVER_DATE_FORMAT),
                [END_DATE_NAME]: monthEnd.format(SERVER_DATE_FORMAT),
            };
            break;
        case REPORTING_FILTERS_LAST_MONTH_ID:
            var lastMonth = moment().subtract(1, "months");

            result = {
                [START_DATE_NAME]: lastMonth.startOf("month").format(SERVER_DATE_FORMAT),
                [END_DATE_NAME]: lastMonth.endOf("month").format(SERVER_DATE_FORMAT),
            };
            break;
        default:
            break;
    }

    return result;
};
export const DATE_OPTIONS = [
    DATE_ALL_VALUE,
    DATE_RANGE_VALUE,
    { label: "Current Month", value: REPORTING_FILTERS_THIS_MONTH_ID },
    { label: "Last Month", value: REPORTING_FILTERS_LAST_MONTH_ID },
    { label: "Current Week", value: REPORTING_FILTERS_THIS_WEEK_ID },
    { label: "Last Week", value: REPORTING_FILTERS_LAST_WEEK_ID },
    DATE_INITIAL_VALUE,
    { label: "Yesterday", value: REPORTING_FILTERS_YESTERDAY_ID },
];

export const REPORTING_ADDITIONAL_FILTERS = [
    {
        id: REPORTING_FILTERS_REGIONS_ID,
        name: REPORTING_FILTERS_REGIONS_ID,
        placeholder: "Region",
        isClearable: true,
        isMulti: true,
        disabled: false,
        selectAll: true,
        selectAllOption: { label: "Select All Regions" },
    },
];

export const REPORTING_MAIN_FILTERS = [
    {
        id: REPORTING_FILTERS_HAULER_ID,
        label: "Hauler",
        isMulti: true,
        disabled: false,
    },
    {
        id: REPORTING_FILTERS_TRUCK_ID,
        label: "Truck",
        isMulti: true,
        disabled: false,
    },
    {
        id: REPORTING_FILTERS_PROJECT_ID,
        label: "Project",
        isMulti: true,
        disabled: false,
    },
    {
        id: REPORTING_FILTERS_PAYLOAD_ID,
        label: "Payload",
        isMulti: true,
        disabled: false,
    },
    {
        id: REPORTING_FILTERS_SITE_ID,
        label: "Site",
        isMulti: true,
        disabled: false,
    },
    {
        id: REPORTING_FILTERS_DRIVER_ID,
        label: "Driver",
        isMulti: true,
        disabled: false,
    },
];

export const REPORTING_SECONDARY_FILTERS = {
    [REPORTING_FILTERS_DATE_ID]: [
        REPORTING_FILTERS_DATE_ID,
        REPORTING_FILTERS_HAULER_ID,
        REPORTING_FILTERS_TRUCK_ID,
        REPORTING_FILTERS_DRIVER_ID,
        REPORTING_FILTERS_PROJECT_ID,
        REPORTING_FILTERS_PAYLOAD_ID,
        REPORTING_FILTERS_SITE_ID,
    ],
    [REPORTING_FILTERS_HAULER_ID]: [
        REPORTING_FILTERS_DATE_ID,
        REPORTING_FILTERS_HAULER_ID,
        REPORTING_FILTERS_TRUCK_ID,
        REPORTING_FILTERS_PROJECT_ID,
        REPORTING_FILTERS_PAYLOAD_ID,
        REPORTING_FILTERS_SITE_ID,
    ],
    [REPORTING_FILTERS_TRUCK_ID]: [
        REPORTING_FILTERS_DATE_ID,
        REPORTING_FILTERS_TRUCK_ID,
        REPORTING_FILTERS_PROJECT_ID,
        REPORTING_FILTERS_PAYLOAD_ID,
        REPORTING_FILTERS_SITE_ID,
    ],
    [REPORTING_FILTERS_PROJECT_ID]: [
        REPORTING_FILTERS_DATE_ID,
        REPORTING_FILTERS_HAULER_ID,
        REPORTING_FILTERS_TRUCK_ID,
        REPORTING_FILTERS_DRIVER_ID,
        REPORTING_FILTERS_PROJECT_ID,
        REPORTING_FILTERS_PAYLOAD_ID,
        REPORTING_FILTERS_SITE_ID,
    ],
    [REPORTING_FILTERS_PAYLOAD_ID]: [
        REPORTING_FILTERS_DATE_ID,
        REPORTING_FILTERS_HAULER_ID,
        REPORTING_FILTERS_TRUCK_ID,
        REPORTING_FILTERS_DRIVER_ID,
        REPORTING_FILTERS_PROJECT_ID,
        REPORTING_FILTERS_PAYLOAD_ID,
        REPORTING_FILTERS_SITE_ID,
    ],
};
