import {
    WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK,
    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED,
    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/maps";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK]: "Invalid assignment for bundle #|{bundleId}|",
    [WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED]: "Bundle |#{bundleId}| updated",
    [WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR]: "Bundle |#{bundleId}| error: {message}",
};

const getNotificationTitle = (event) => {
    return notificationTitles[event.type].replace("{bundleId}", event.bundleId).replace("{message}", event.message);
};

export const processSmartDispatchEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};

// replace event type if bundle failed
export const processSmartDispatchErrorEvent = ({ event }) => {
    const updatedType =
        event.type === WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED && event.message
            ? WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR
            : event.type;

    return {
        ...event,
        type: updatedType,
    };
};
