import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";

import { MAIN_THEME } from "../../styles/globalThemeConfig";
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import {
    IS_ADMIN_USER,
    IS_CONTRACTOR_USER,
    IS_HAULER_USER,
    JO_STATUS_ACTIVE_ID,
    JO_STATUS_COMPLETED_ID,
    JO_STATUS_NOT_STARTED_ID,
    JO_STATUS_PENDING_ID,
    TICKET_SOURCE_AIRTICKET,
    TICKET_SOURCE_MANUAL,
    TICKET_SOURCE_SUBTICKET,
    TICKET_SOURCE_TICKET,
    TICKET_STATUS_CLOSED,
    TICKET_STATUS_DISPUTED,
    TICKET_STATUS_FINALLY_RECONCILED,
    TICKET_STATUS_INTERRUPTED,
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RECONCILED,
    TICKET_STATUS_REJECTED_BY_DRIVER,
    TICKET_STATUSES_LABELS,
    TICKET_TYPE_AIRTICKET,
    TICKET_TYPE_MANUAL,
    TICKET_TYPE_SUBTICKET,
    TICKET_TYPE_TICKET,
    TICKET_TYPES_LABELS,
    TICKETS_TITLES_LIST,
    UNIT_OF_MEASURE_LABELS,
} from "../../constants/maps";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import SelectTrailers from "./selectTrailers";
import Tooltip from "@material-ui/core/Tooltip";
import { THEME_ICONS } from "../../styles/icons";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";
import { selectTrailers } from "../../reducers/trailersReducer";
import { JOLI_FIELD_ID } from "../jobs/constants";

const statusPillPalette = {
    default: MAIN_THEME.palette.secondary.dark,
    [JO_STATUS_ACTIVE_ID]: MAIN_THEME.palette.general.active,
    [JO_STATUS_PENDING_ID]: MAIN_THEME.palette.general.pending,
    [JO_STATUS_NOT_STARTED_ID]: MAIN_THEME.palette.secondary.superDark,
    [JO_STATUS_COMPLETED_ID]: MAIN_THEME.palette.primary.main,
};
export const STATUS_PILL_LABELS = {
    [JO_STATUS_ACTIVE_ID]: "Active",
    [JO_STATUS_PENDING_ID]: "Pending",
    [JO_STATUS_NOT_STARTED_ID]: "Not Started",
    [JO_STATUS_COMPLETED_ID]: "Closed",
};

const pillsStyles = makeStyles((theme) => ({
    statusPill: {
        color: "#fff",
        padding: 0,
        fontSize: 11,
        letterSpacing: -0.6,
        textTransform: "uppercase",
        height: 15,
        width: 74,
        fontWeight: theme.typography.fontWeightBold,
        textAlign: "center",
        borderRadius: theme.shape.borderRadius * 3,

        "& span": {
            display: "block",
            padding: "1px 12px 0",
            fontSize: "inherit",
            overflow: "visible",
        },
    },
    default: {
        width: "auto",
        height: "auto",
        fontSize: theme.typography.fontSize,
        padding: 2,
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: "none",
    },
}));

export const StatusPill = (props) => {
    const { label, component, type, className, onClick } = props;
    const classes = pillsStyles();
    const text = _.isNull(label) || typeof label === "undefined" ? STATUS_PILL_LABELS[type] : label;

    return (
        <Chip
            component={component}
            onClick={(e) => onClick && onClick(e)}
            label={text}
            style={type ? { backgroundColor: statusPillPalette[type] } : {}}
            className={clsx(classes.statusPill, type === "default" && classes.default, className)}
        />
    );
};

StatusPill.propTypes = {
    type: PropTypes.oneOf([
        JO_STATUS_ACTIVE_ID,
        JO_STATUS_PENDING_ID,
        JO_STATUS_NOT_STARTED_ID,
        JO_STATUS_COMPLETED_ID,
        "default",
    ]),
    label: PropTypes.any,
    component: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

StatusPill.defaultProps = {
    component: "div",
};

const ticketStatusPillStyles = makeStyles((theme) => ({
    ticketStatusPill: {
        height: 24,
        width: 24,

        "& span": {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    open: {
        color: "#fff",
        backgroundColor: theme.palette.general.green,
    },
    closed: {
        color: "#fff",
        backgroundColor: theme.palette.general.darkishRed,
    },
    interrupted: {
        color: "#fff",
        backgroundColor: theme.palette.general.black,
    },
    finallyReconciled: {
        color: theme.palette.general.black,
        backgroundColor: theme.palette.general.white,
        border: "1px solid black",
    },
    reconciled: {
        color: theme.palette.general.black,
        backgroundColor: theme.palette.general.white,
        border: `1px solid ${theme.palette.general.darkishRed}`,
    },
}));

export const TicketStatusPillWrapper = (props) => {
    const { status } = props;
    const classes = ticketStatusPillStyles();
    const getColorByStatus = (status) => {
        if (status === TICKET_STATUS_OPEN) {
            return classes.open;
        } else if (status === TICKET_STATUS_INTERRUPTED) {
            return classes.interrupted;
        } else if (status === TICKET_STATUS_CLOSED) {
            return classes.closed;
        } else if (status === TICKET_STATUS_RECONCILED) {
            return classes.reconciled;
        } else if (status === TICKET_STATUS_FINALLY_RECONCILED) {
            return classes.finallyReconciled;
        }
    };
    const colorClass = getColorByStatus(status);

    return <TicketStatusPill {...props} classes={classes} colorClass={colorClass} />;
};

TicketStatusPillWrapper.propTypes = {
    status: PropTypes.oneOf([
        TICKET_STATUS_OPEN,
        TICKET_STATUS_CLOSED,
        TICKET_STATUS_RECONCILED,
        TICKET_STATUS_DISPUTED,
        TICKET_STATUS_INTERRUPTED,
        TICKET_STATUS_REJECTED_BY_DRIVER,
        TICKET_STATUS_FINALLY_RECONCILED,
    ]).isRequired,
};

export const TicketStatusPill = (props) => {
    const { status, ticket, title, className, classes, colorClass } = props;
    let sourceType;
    const getTypeByTicket = (ticket) => {
        if (ticket.isAirticket) {
            sourceType = TICKET_SOURCE_AIRTICKET;

            return TICKET_TYPE_AIRTICKET;
        } else if (ticket.isManualTicket) {
            sourceType = TICKET_SOURCE_MANUAL;

            return TICKET_TYPE_MANUAL;
        } else if (!ticket.isSubticket) {
            sourceType = TICKET_SOURCE_TICKET;

            return TICKET_TYPE_TICKET;
        }
    };

    const type = getTypeByTicket(ticket);
    const ticketType = TICKET_TYPES_LABELS[type];

    return (
        <React.Fragment>
            {(ticket.isManualTicket || ticket.isAirticket || ticket.isTicket) && (
                <Chip
                    label={ticketType}
                    title={
                        title ||
                        `#${ticket.id} ${TICKET_STATUSES_LABELS[status]} ${
                            UNIT_OF_MEASURE_LABELS[ticket.unitOfMeasure]
                        } ${TICKETS_TITLES_LIST[sourceType]}`
                    }
                    className={clsx(classes.ticketStatusPill, colorClass, className, "ticket-status-pill")}
                />
            )}
            {ticket.isSubticket && (
                <Chip
                    label={TICKET_TYPES_LABELS[TICKET_TYPE_SUBTICKET]}
                    title={
                        title ||
                        `#${ticket.id} ${TICKET_STATUSES_LABELS[status]} ${
                            UNIT_OF_MEASURE_LABELS[ticket.unitOfMeasure]
                        } ${TICKETS_TITLES_LIST[TICKET_SOURCE_SUBTICKET]}`
                    }
                    className={clsx(classes.ticketStatusPill, colorClass, className, "ticket-status-pill")}
                />
            )}
        </React.Fragment>
    );
};

TicketStatusPill.propTypes = {
    status: PropTypes.oneOf([
        TICKET_STATUS_OPEN,
        TICKET_STATUS_CLOSED,
        TICKET_STATUS_RECONCILED,
        TICKET_STATUS_DISPUTED,
        TICKET_STATUS_INTERRUPTED,
        TICKET_STATUS_REJECTED_BY_DRIVER,
        TICKET_STATUS_FINALLY_RECONCILED,
    ]).isRequired,
    ticket: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
    colorClass: PropTypes.string,
    className: PropTypes.string,
};

const ticketTypePillStyles = makeStyles((theme) => ({
    ticketsTypePill: {
        width: 32,

        "& span": {
            textOverflow: "unset",
        },
    },
    ticket: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
    },
    airticket: {
        color: "#000",
        backgroundColor: theme.palette.general.grey,
    },
    manual: {
        color: "#fff",
        backgroundColor: theme.palette.general.paleBlue,
    },
    subticket: {
        color: "#fff",
        backgroundColor: "#000",
    },
}));

export const TicketTypePill = (props) => {
    const { type, subticketsCount } = props;
    const classes = ticketTypePillStyles();

    return (
        <React.Fragment>
            {!!type && (
                <Chip
                    key={type}
                    label={TICKET_TYPES_LABELS[type]}
                    className={clsx(
                        classes.ticketsTypePill,
                        "tickets-type-pill",
                        type === TICKET_TYPE_TICKET ? classes.ticket : "",
                        type === TICKET_TYPE_AIRTICKET ? classes.airticket : "",
                        type === TICKET_TYPE_MANUAL ? classes.manual : "",
                        type === TICKET_TYPE_SUBTICKET ? classes.subticket : "",
                    )}
                />
            )}
            {!!subticketsCount && subticketsCount > 0 && <Chip label={subticketsCount} className={classes.subticket} />}
        </React.Fragment>
    );
};

TicketTypePill.propTypes = {
    type: PropTypes.PropTypes.oneOf([
        TICKET_TYPE_TICKET,
        TICKET_TYPE_AIRTICKET,
        TICKET_TYPE_MANUAL,
        TICKET_TYPE_SUBTICKET,
    ]),
    subticketsCount: PropTypes.number,
};

const circleRadius = 28;

const truckPillStyles = makeStyles((theme) => ({
    truckPill: {
        borderRadius: circleRadius / 2 + 5,
        padding: "2px 3px 2px",
        border: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: "#fff",
        color: theme.palette.secondary.dark,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "left",
        height: 36,

        "&.my-fleet-truck": {
            backgroundColor: theme.palette.general.softOrange,
            border: "none",

            "& .wrap-icon": {
                backgroundColor: "#fff",
                color: theme.palette.secondary.dark,
            },
        },

        "&.sub-truck": {
            height: 25,
            fontSize: 12,
            width: "auto",
            display: "inline-block",
            padding: "2px 7px 0",
            marginTop: 10,
            marginLeft: 15,
            textAlign: "left",

            "& .wrap-icon": {
                background: "transparent",
                height: "auto",
                width: "auto",
                marginRight: 5,
            },

            "& svg": {
                height: 18,
                width: 18,
                color: theme.palette.secondary.dark,
            },
        },

        "&.-alert-truck": {
            backgroundColor: theme.palette.general.softRed,
        },

        "&.-no-icon": {
            paddingLelt: 5,
        },

        "& .wrap-icon": {
            backgroundColor: theme.palette.secondary.dark,
            color: "#fff",
            minWidth: circleRadius,
            height: circleRadius,
            borderRadius: circleRadius,
            padding: "2px 2px 0",
            marginRight: 10,
            fontSize: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",

            "& svg": {
                height: 20,
                width: circleRadius,
            },
        },

        "& .name": {
            lineHeight: 1.2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },

        "& .truckInfo": {
            width: 100,
        },

        "& .hauler": {
            fontSize: 9,
            textTransform: "capitalize",
        },

        "& .pill-button": {
            width: 20,
            height: 20,
            padding: 0,

            "&:last-child": {
                marginRight: 5,
            },

            "& svg": {
                width: 20,
                height: 20,
            },
        },
    },
}));

const TruckPillWrapper = (props) => {
    const { account, truckName, truckId, trailers, form } = props;
    const classes = truckPillStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const trailerItems = useSelector((state) =>
        form
            ? formValueSelector(form)(state, `${truckId}-trailers`)
            : (trailers || []).filter((trailer) => trailer.truckId === truckId),
    );

    return (
        <>
            <TruckPill {...props} handleClickOpen={handleClickOpen} classes={classes} account={account} />
            {trailerItems &&
                trailerItems.map((trailer) => {
                    return (
                        <TrailerPill
                            trailerName={trailer.name || trailer.trailerName}
                            classes={classes}
                            form={form}
                            trailerIcon={<Icon style={THEME_ICONS.trailerFilled} />}
                            className="sub-truck"
                        />
                    );
                })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <SelectTrailers form={form} name={`${truckId}-trailers`} handleClose={handleClose} />
            </Dialog>
        </>
    );
};

TruckPillWrapper.propTypes = {
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
        };
    })(TruckPillWrapper),
);

export const TrailerPill = ({ classes, form, className, trailerIcon, trailerName }) => {
    return (
        <Box>
            <Box
                style={{
                    backgroundColor: "#ffd6b1",
                    marginTop: 10,
                    textAlign: "left",
                    marginLeft: 15,
                    borderRadius: 19,
                    maxWidth: 150,
                    // display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center"
                }}
            >
                <Box display="flex" alignItems="center" style={{ height: 25, padding: "0px 11px 0" }}>
                    <span style={{ marginRight: 5, padding: "2px 2px 0" }}>{trailerIcon}</span>
                    <span
                        style={{
                            overflow: "hidden",
                            lineHeight: 1.2,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: 85,
                            color: "#505050",
                        }}
                    >
                        {trailerName}
                    </span>
                </Box>
                {/* <IconButton size="small"><DeleteOutlineIcon fontSize="small"/></IconButton> */}
            </Box>
        </Box>
    );
};

export const TruckPill = withRouter((props) => {
    const {
        truckName,
        isMyFleet,
        haulerName,
        showIcon,
        iconContent,
        title,
        className,
        onClick,
        classes,
        specifyNumberOfTrucksHandler,
        needTrucksCount,
        account,
        specifyNumberOfTrucks,
        allowButtons,
        handleClickOpen,
        history,
        isOfflineHaulerTruck,
        allJolis,
        joliIndex,
        form,
        truckId,
        haulerId,
        jobDisabled,
    } = props;
    const dispatch = useDispatch();
    const pathname = history.location.pathname;
    const isJobBoardPage = pathname.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM);
    const isDispatchPage = pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
    const trailers = useSelector(selectTrailers);
    const hasTrailers = !!(trailers || []).length;

    const showButtons = !!specifyNumberOfTrucks && !isMyFleet;

    const handleOfflineHaulerTruckDelete = () => {
        const updatedJolis = _.cloneDeep(allJolis).map((joli, index) => {
            if (joliIndex === index) {
                joli.haulers = joli.haulers.map((hauler) => {
                    if (hauler.id === haulerId) {
                        hauler.trucks = hauler.trucks.filter((i) => i.truckId !== truckId);
                    }
                    if (hauler.needTrucksCount > 1) {
                        hauler.needTrucksCount = +hauler.needTrucksCount - 1;
                    }

                    return hauler;
                });
            }

            return joli;
        });
        dispatch(change(form, JOLI_FIELD_ID, updatedJolis));
    };

    return (
        <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            onClick={() => onClick && onClick(props)}
            className={clsx(
                isMyFleet && "my-fleet-truck",
                classes.truckPill,
                className,
                "truck-pill",
                !showIcon && !iconContent && "-no-icon",
            )}
            title={title}
        >
            <Grid item xs={showButtons ? 8 : 12}>
                <Box display="flex" alignItems="center" style={{ position: "relative" }}>
                    {showIcon ? (
                        <Box component="span" className="wrap-icon">
                            <CheckIcon />
                        </Box>
                    ) : (
                        iconContent && <span className="wrap-icon">{iconContent}</span>
                    )}
                    <Grid item xs={10} className="truckInfo">
                        <Grid className="name" title={truckName} item xs={9}>
                            <span>{truckName}</span>
                        </Grid>
                        {haulerName ? <Box className="hauler">{haulerName}</Box> : ""}
                    </Grid>
                    {/*removed until https://github.com/TruckITllc/truckit-backend/issues/6355#issuecomment-1480714964 will be ready*/}
                    {/*{!jobDisabled && isOfflineHaulerTruck && (isJobBoardPage || isDispatchPage) && (*/}
                    {/*    <IconButton className="pill-button" onClick={handleOfflineHaulerTruckDelete}>*/}
                    {/*        <DeleteOutlineIcon />*/}
                    {/*    </IconButton>*/}
                    {/*)}*/}
                    {hasTrailers && isMyFleet && (isJobBoardPage || isDispatchPage) && !jobDisabled && (
                        <Tooltip title="Add trailer">
                            <IconButton
                                style={{
                                    position: "absolute",
                                    right: 0,
                                }}
                                onClick={handleClickOpen}
                            >
                                <Icon style={THEME_ICONS.trailerFilledLarge} />
                                <AddIcon style={{ width: 20, height: 20 }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Grid>
            {showButtons && (
                <Grid item xs={4} className="--text-right">
                    <i className="--text-10">{needTrucksCount} Req.</i>&nbsp;
                    {allowButtons &&
                        (IS_CONTRACTOR_USER(account.role) ||
                            account.isSuperUser ||
                            IS_ADMIN_USER(account.company.id) ||
                            IS_HAULER_USER(account.role)) && (
                            <React.Fragment>
                                <IconButton
                                    title="add hauler truck"
                                    className="pill-button"
                                    onClick={() => specifyNumberOfTrucksHandler(true)}
                                >
                                    <AddIcon />
                                </IconButton>
                                &nbsp;
                                <IconButton
                                    title="remove hauler truck"
                                    className="pill-button"
                                    onClick={() => specifyNumberOfTrucksHandler(false)}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </React.Fragment>
                        )}
                </Grid>
            )}
        </Grid>
    );
});

TruckPill.propTypes = {
    truckName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    haulerName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    component: PropTypes.string.isRequired,
    title: PropTypes.string,
    isMyFleet: PropTypes.bool.isRequired,
    showIcon: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    needTrucksCount: PropTypes.number,
    specifyNumberOfTrucksHandler: PropTypes.func,
    onClick: PropTypes.func,
    specifyNumberOfTrucks: PropTypes.bool,
    allowButtons: PropTypes.bool,
    className: PropTypes.string,
    iconContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TruckPill.defaultProps = {
    component: "div",
    isMyFleet: false,
    allowButtons: true,
    specifyNumberOfTrucks: true,
    showIcon: true,
    needTrucksCount: 1,
};
