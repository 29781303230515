import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import { ROUTE_HOME } from "./routes/globalRoutes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { MAIN_THEME } from "./styles/globalThemeConfig";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";

import browserHistory from "./utils/browserHistory";
import "./styles.scss";
import "./styles/fonts.css";

Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: "https://e3acdd5bcf4a4720bccc5217818a8fdb@sentry.io/4486201",
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <CssBaseline />
        <ThemeProvider theme={MAIN_THEME}>
            <SnackbarProvider
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                preventDuplicate={true}
                maxSnack={5}
                hideIconVariant={false}
            >
                <Router history={browserHistory}>
                    <Route path={[ROUTE_HOME, "/"]} component={App} />
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>,
);
