import moment from "moment";
import _ from "lodash";
import dataProvider from "../helpers/api";
import { GLOBAL_COUNT_TO_LOAD, GLOBAL_ENDPOINTS, JOB_ORDERS } from "../constants/endpoints";
import { END_DATE_NAME, START_DATE_NAME } from "../components/global/datesRange";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { FILTERS_JOB_REQUESTS, FILTERS_REGIONS_ID, FILTERS_STATUS_ID } from "../components/global/searchAndFilters";

export const getJobOrders = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDERS(),
        method: "GET",
        params: {
            order: params.order || "-id",
            [FILTERS_STATUS_ID]: params[FILTERS_STATUS_ID],
            [FILTERS_REGIONS_ID]: params[FILTERS_REGIONS_ID],
            page: params.page || 1,
            perPage: params.count || GLOBAL_COUNT_TO_LOAD,
            startDate: params[START_DATE_NAME] ? moment(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
            endDate: params[END_DATE_NAME] ? moment(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
            keywords: params.keywords,
            flat: _.isUndefined(params.flat) ? true : params.flat,
            projects: params.project || null,
            id: params.id,
            dropdown: params.dropdown,
            withTrucks: params.withTrucks === undefined ? true : params.withTrucks,
            smartDispatch: params.smartDispatch,
            estimate: params.estimate,
            proximityTo: params.proximityTo,
            locationId: params.locationId,
            isDraft: params.isDraft,
            excludeUnitsOfMeasure: params.excludeUnitsOfMeasure,
            isOvernight: params.isOvernight,
            isUnlimited: params.isUnlimited,
            draft: params.draft ? true : null,
            templated: params.templated ? true : null,
            [FILTERS_JOB_REQUESTS]: params[FILTERS_JOB_REQUESTS] ? true : null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getInspectorJobOrders = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.INSPECTOR_JOB_ORDERS(),
        method: "GET",
        params: {
            order: params.order || "-id",
            page: params.page || 1,
            perPage: params.count || GLOBAL_COUNT_TO_LOAD,
            startDate: params[START_DATE_NAME] ? moment.utc(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
            endDate: params[END_DATE_NAME] ? moment.utc(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
            keywords: params.keywords,
            id: params.id,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getJobBoardJobOrders = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_BOARD(),
        method: "GET",
        params: {
            [FILTERS_STATUS_ID]: params[FILTERS_STATUS_ID],
            [FILTERS_REGIONS_ID]: params[FILTERS_REGIONS_ID],
            page: params.page || 1,
            perPage: params.count || GLOBAL_COUNT_TO_LOAD,
            [START_DATE_NAME]: params.keywords
                ? null
                : params[START_DATE_NAME]
                ? moment(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : null,
            [END_DATE_NAME]: params.keywords
                ? null
                : params[END_DATE_NAME]
                ? moment(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : null,
            keywords: params.keywords,
            flat: true,
            projects: params.project || null,
            [FILTERS_JOB_REQUESTS]: params[FILTERS_JOB_REQUESTS] ? true : null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getJobOrderById = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_BY_ID(joId),
        method: "GET",
    });
};

export const getJobBoardJobOrderJoli = (joId, params = {}) => {
    const parameters = params;

    delete parameters.cancelToken;
    delete parameters.page;

    return dataProvider({
        url: JOB_ORDERS.JOB_BOARD_JOB_ORDER_JOLIS(joId),
        method: "GET",
        params: parameters,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const createJobOrder = (data) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDERS(),
        method: "POST",
        data,
    });
};

export const updateJobOrderById = (id, data) => {
    return dataProvider({
        url: `${JOB_ORDERS.JOB_ORDERS()}/${id}`,
        method: "PUT",
        data,
    });
};

export const partialJobOrderUpdateById = (id, data) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_PARTIAL_UPDATE(id),
        method: "PUT",
        data,
    });
};

export const updateCopySchedule = (jobId, data) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_TEMPLATE_COPY_SCHEDULE(jobId),
        method: "PUT",
        data,
    });
};

export const closeJobOrderById = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_CLOSE(joId),
        method: "POST",
    });
};

export const deleteJobOrderById = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_BY_ID(joId),
        method: "DELETE",
    });
};

export const reopenJobOrderById = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_REOPEN(joId),
        method: "POST",
    });
};

export const copyJobOrderById = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_COPY(joId),
        method: "POST",
    });
};

export const getJobOrderETAData = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_ETA(),
        method: "GET",
        params,
    });
};

export const acceptJob = (joliId, trucksId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ACCEPTED(joliId, trucksId),
        method: "POST",
    });
};
export const activateTruck = (trucksId, joliId) => {
    return dataProvider({
        url: JOB_ORDERS.ACTIVATE_TRUCK_BY_ID(trucksId),
        method: "POST",
        data: { joLineItemId: joliId },
    });
};

export const makeDemoJobOrder = (data) => {
    return dataProvider({
        url: JOB_ORDERS.MAKE_DEMO_JOB(),
        method: "POST",
        data: data,
    });
};

export const makeDemoAirTicket = (data) => {
    return dataProvider({
        url: JOB_ORDERS.MAKE_DEMO_AIRTICKET(),
        method: "POST",
        data: data,
    });
};

export const makeTruckMove = (trucksId) => {
    return dataProvider({
        url: JOB_ORDERS.MAKE_TRUCK_MOVE(),
        method: "POST",
        data: { truckId: trucksId, delay: 0, fileName: "tests/vulcan_kennesaw_2_wework.csv" },
    });
};

export const getTrucksTabData = (joId, params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_TRUCKS_TAB(joId),
        method: "GET",
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};
export const getJobOrderTrucksActivity = (joId, params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_TRUCKS_ACTIVITY(joId),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getJobOrderTrucksForecast = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_TRUCKS_FORECAST(),
        method: "GET",
        params,
    });
};

export const getStatsTabData = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_STATS_TAB(joId),
        method: "GET",
    });
};

export const getJobOrderMapData = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_MAP(joId),
        method: "GET",
    });
};

export const getTerms = (params = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.TERMS(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getJobOrderRoutes = (joId) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_ROUTES(joId),
        method: "GET",
    });
};

export const getDriversList = (params) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.DRIVERS(),
        method: "GET",
        params,
    });
};

export const messageDrivers = (joId, data) => {
    return dataProvider({
        url: JOB_ORDERS.MESSAGE_DRIVERS(joId),
        method: "POST",
        data,
    });
};

export const getJobOrdersCount = (params = {}) => {
    return dataProvider({
        url: JOB_ORDERS.COUNT(),
        method: "GET",
        params: {
            ...params,
            startDate: params[START_DATE_NAME] ? moment.utc(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
            endDate: params[END_DATE_NAME] ? moment.utc(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT) : null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const fetchTrailersHistory = (jobOrderId, params) => {
    return dataProvider({
        url: JOB_ORDERS.TRAILERS_HISTORY(jobOrderId),
        method: "GET",
        params,
    });
};

export const splitJobOrderById = (jobOrderId, data) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_ORDER_SPLIT(jobOrderId),
        method: "POST",
        data,
    });
};
