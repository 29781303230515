/* global google */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { MAIN_THEME } from "../../../styles/globalThemeConfig";
import CustomMarker from "./customMarker";
import _ from "lodash";
import dropOffPin from "../../../styles/images/pale-red-pin.svg";
import midPin from "../../../styles/images/pale-blue-pin.svg";
import { GOOGLE_MAPS_SITE_ICON_SIZE } from "../../../constants/global";
import { Circle, Polygon } from "react-google-maps";
import { SITE_CIRCLE_OPACITY, SITE_CIRCLE_WIDTH } from "../../../constants/sites";

const DropOffMapData = ({ dropOffSites, activeRoute, notFadeMarkers, isDropOffMidpoint }) => {
    return dropOffSites.map(({ geofenceData, id, latitude, longitude, polygon, radius }, index) => {
        const dropOffLocation = latitude && longitude && { lat: latitude, lng: longitude };
        const options = {
            strokeColor: isDropOffMidpoint ? MAIN_THEME.palette.general.paleBlue : MAIN_THEME.palette.general.paleRed,
            strokeWeight: SITE_CIRCLE_WIDTH,
            fillColor: isDropOffMidpoint ? MAIN_THEME.palette.general.paleBlue : MAIN_THEME.palette.general.paleRed,
            fillOpacity: SITE_CIRCLE_OPACITY,
        };

        return (
            <React.Fragment key={id}>
                {dropOffLocation && (
                    <CustomMarker
                        position={dropOffLocation}
                        opacity={!_.isEmpty(notFadeMarkers) || (activeRoute && activeRoute !== id) ? 0.1 : 1}
                        icon={{
                            url: isDropOffMidpoint ? midPin : dropOffPin,
                            zIndex: 0,
                            scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        }}
                    />
                )}
                {geofenceData &&
                    geofenceData
                        .filter((i) => i.radius)
                        .map((zone, index) => {
                            return (
                                <Circle
                                    defaultCenter={dropOffLocation}
                                    key={index}
                                    radius={zone.radius}
                                    options={options}
                                />
                            );
                        })}
                {geofenceData &&
                    geofenceData
                        .filter((i) => i.polygon)
                        .map((zone, index) => {
                            const paths = zone.polygon && zone.polygon.map(([lat, lng]) => ({ lat, lng }));

                            return (
                                <Polygon path={paths} key={index} defaultCenter={dropOffLocation} options={options} />
                            );
                        })}
                {polygon && (
                    <Polygon
                        path={polygon.map(([lat, lng]) => ({ lat, lng }))}
                        key={index}
                        defaultCenter={dropOffLocation}
                        options={options}
                    />
                )}
                {radius && <Circle defaultCenter={dropOffLocation} key={index} radius={radius} options={options} />}
            </React.Fragment>
        );
    });
};

DropOffMapData.propTypes = {
    dropOffSites: PropTypes.array.isRequired,
    notFadeMarkers: PropTypes.array.isRequired,
    activeRoute: PropTypes.number,
};

DropOffMapData.defaultProps = {
    dropOffSites: [],
    notFadeMarkers: [],
};

export default withRouter(DropOffMapData);
