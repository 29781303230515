import React, { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";

import { Box, makeStyles, Tooltip } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { SimpleLink } from "../core/link";
import { DividerThin } from "../core/divider";
import CountNotification from "../core/countNotification";
import { StatusPill } from "../core/pills";
import { LOCAL_STORAGE_FILTERS_ID } from "../../helpers/api";
import { IS_ADMIN_USER } from "../../constants/maps";
import { change, formValueSelector } from "redux-form";

export const useStyles = makeStyles((theme) => ({
    filtersComponent: {
        "& .action-icons": {
            width: theme.icon.width,
            height: theme.icon.height,
            color: theme.palette.secondary.main,
            marginLeft: 16,
            cursor: "pointer",
        },
        "& .filters-pill": {
            marginRight: 10,
            marginBottom: 10,
            backgroundColor: theme.palette.secondary.superLight,
            height: 32,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: theme.shape.borderRadius * 4,
            color: theme.palette.secondary.superDark,
            fontSize: theme.typography.fontSize,
            textTransform: "none",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "0 5px",
            width: "auto",
            justifyContent: "center",
            fontWeight: theme.typography.fontWeightMedium,
            letterSpacing: 0.14994,

            "& span": {
                padding: 0,
            },

            "&.active": {
                backgroundColor: theme.palette.general.softOrange,
            },
        },

        "& .filters-wrap": {
            position: "relative",

            "& .counter-wrapper": {
                position: "relative",
            },
        },

        "& .filters-section": {
            position: "absolute",
            width: "auto",
            height: "auto",
            backgroundColor: "#fff",
            border: `2px solid ${theme.palette.border.primary}`,
            borderRadius: theme.shape.borderRadius,
            padding: 15,
            top: "90%",
            right: 0,
            zIndex: 102,
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",

            "& .category": {
                marginBottom: 10,

                "&:last-child": {
                    marginBottom: 0,
                },
            },
        },
        "& .filters-section-width-limitation": {
            maxWidth: "50%",
        },
        "& .clear-all-link": {
            color: theme.palette.primary.main,
        },
    },
}));

export const setInitialFilters = (filters, initialFilters, defaultFilter = {}) => {
    const newInitialFilters = {};
    filters.forEach((category) => {
        newInitialFilters[category.filterKey] = {};
        category &&
            category.filters.forEach((filter) => {
                newInitialFilters[category.filterKey][filter.id] =
                    initialFilters &&
                    initialFilters[category.filterKey] &&
                    initialFilters[category.filterKey][filter.id];
            });
    });
    const defaultFilterKey = Object.keys(defaultFilter);
    const hasSavedDefaultFilter = newInitialFilters[defaultFilterKey];
    const defaultFilterValues = hasSavedDefaultFilter
        ? { ...hasSavedDefaultFilter, ...defaultFilter[defaultFilterKey] }
        : defaultFilter;

    return { ...newInitialFilters, [defaultFilterKey]: defaultFilterValues };
};

const Filters = (props) => {
    const classes = useStyles();
    const {
        applyFilter,
        filters,
        search,
        clearFiltersHandler,
        clearAllTitle,
        resetToDefaultHandler,
        updateFiltersHandler,
        dataLoadedFor,
        isResetToDefault,
        initialStatus,
        initialFilters: pageInitialFilters,
        form,
        rememberFilters,
        defaultFilter,
        account,
        isHaulersFilters,
        showDefaultFilterTip,
        limitWidth,
        renderCustomIcon,
        chooseOneFilterOptionList,
        handleOneFilterOptionListChange,
        filtersSectionClassName,
        triggerClearFilters,
        dispatch,
    } = props;
    const formPrevFilters = localStorage.getItem(LOCAL_STORAGE_FILTERS_ID);
    const prevAppliedFilters = formPrevFilters ? JSON.parse(formPrevFilters) : null;
    const [showTip, setShowTip] = useState(false);
    const [state, setState] = useState({
        isShowFilters: false,
        appliedFilters: undefined,
        initialFilters: {
            ...pageInitialFilters,
            ...(prevAppliedFilters && rememberFilters ? prevAppliedFilters[form] : {}),
        },
    });
    const { isShowFilters, appliedFilters, initialFilters } = state;
    const clearAllFilters = () => {
        const initialFilters = setInitialFilters(filters, {});

        setState({
            ...state,
            isShowFilters: false,
            appliedFilters: initialFilters,
        });
        clearFiltersHandler && clearFiltersHandler();
    };
    const switchFiltersView = (show) => {
        setState({
            ...state,
            isShowFilters: show,
        });
    };

    useEffect(() => {
        if (triggerClearFilters) {
            setState({
                ...state,
                isShowFilters: false,
                appliedFilters: {},
            });
            dispatch(change(form, "triggerClearFilters", false));
        }
    }, [triggerClearFilters]);

    const hideFilters = () => {
        setState({
            ...state,
            isShowFilters: false,
        });
    };

    const updateAppliedFilters = (filterCategory, filter) => {
        const preValues = state.appliedFilters ? state.appliedFilters[filterCategory.filterKey] || {} : {};
        const value =
            state.appliedFilters && state.appliedFilters[filterCategory.filterKey]
                ? !state.appliedFilters[filterCategory.filterKey][filter.id]
                : true;

        let filters = {};

        const hasFilter =
            !_.isEmpty(chooseOneFilterOptionList) &&
            chooseOneFilterOptionList.some((i) => i === filterCategory.filterKey);

        if (hasFilter) {
            filters = { [filter.id]: value };
        } else {
            filters = {
                ...preValues,
                [filter.id]: value,
            };
        }

        const updatedFilters = {
            ...state.appliedFilters,
            [filterCategory.filterKey]: filters,
        };

        updateFiltersHandler && updateFiltersHandler();
        setState({
            ...state,
            appliedFilters: updatedFilters,
        });
    };

    const defineActiveFiltersCount = () => {
        let count = 0;

        _.forEach(appliedFilters, (category, index) => {
            _.forEach(category, (filter) => {
                if (filter) {
                    count += 1;
                }
            });
        });

        return count;
    };

    const resultFilters = (appliedFilters) => {
        const onlyActiveFilters = {};
        const formatted = {};
        _.mapKeys(appliedFilters, (value, key) => {
            const appliedExist = _.find(value, (i) => i);
            if (appliedExist) onlyActiveFilters[key] = [];
            _.mapKeys(value, (applied, filter) => applied && onlyActiveFilters[key].push(filter));
        });
        _.mapValues(onlyActiveFilters, (value, category) => (formatted[category] = value.join(",")));

        if (search) {
            formatted.keywords = search;
        }

        return formatted;
    };

    useEffect(() => {
        if (appliedFilters) {
            const restFormValues = localStorage.getItem(LOCAL_STORAGE_FILTERS_ID);
            const prevAppliedFilters = restFormValues ? JSON.parse(restFormValues) : null;

            if (form && rememberFilters) {
                const newFormValues =
                    prevAppliedFilters && prevAppliedFilters[form]
                        ? { ...prevAppliedFilters[form], ...appliedFilters }
                        : { ...appliedFilters };

                localStorage.setItem(
                    LOCAL_STORAGE_FILTERS_ID,
                    JSON.stringify({
                        ...prevAppliedFilters,
                        [form]: newFormValues,
                    }),
                );
            }

            const formatted = resultFilters(appliedFilters);

            applyFilter(formatted, closeModal);
        }
    }, [appliedFilters]);

    // special case for jobs list page
    useEffect(() => {
        // if (dataLoadedFor &&
        //     (dataLoadedFor.includes(ROUTE_JOBS.JOB_BOARD) || dataLoadedFor.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM))){
        //     setState({
        //         ...state,
        //         renderedFor: dataLoadedFor,
        //         appliedFilters: setInitialFilters(filters, )
        //     });
        // }

        if (dataLoadedFor && initialFilters) {
            setState({
                ...state,
                renderedFor: dataLoadedFor,
                appliedFilters: setInitialFilters(filters, initialFilters, defaultFilter),
            });
        }
    }, [dataLoadedFor]);

    useEffect(() => {
        if (!_.isEmpty(pageInitialFilters)) {
            const newInitialFilters = setInitialFilters(filters, pageInitialFilters, defaultFilter);
            const forcedInitialStatus = !_.isEmpty(initialStatus)
                ? setInitialFilters(filters, initialStatus, defaultFilter)
                : {};

            const newAppliedFilters = {
                ...newInitialFilters,
                ...appliedFilters,
                ...forcedInitialStatus,
            };
            flushSync(() => {
                setState({
                    ...state,
                    renderedFor: dataLoadedFor,
                    appliedFilters: newAppliedFilters,
                });
            });
        }
    }, [pageInitialFilters, filters]);

    useEffect(() => {
        if (!_.isEmpty(initialFilters) && !appliedFilters && _.isEmpty(initialStatus)) {
            flushSync(() => {
                setState({
                    ...state,
                    appliedFilters: setInitialFilters(filters, initialFilters, defaultFilter),
                });
            });
        }

        if (_.isEmpty(initialFilters) && !appliedFilters && _.isEmpty(initialStatus)) {
            flushSync(() => {
                setState({
                    ...state,
                    appliedFilters: setInitialFilters(filters, {}, defaultFilter),
                });
            });
        }
    }, [initialFilters]);

    useEffect(() => {
        setShowTip(showDefaultFilterTip && isHaulersFilters && !IS_ADMIN_USER(account.company.id));
    }, [showDefaultFilterTip, isHaulersFilters]);

    useEffect(() => {
        handleOneFilterOptionListChange && handleOneFilterOptionListChange(resultFilters(appliedFilters), setState);
    }, [chooseOneFilterOptionList?.length]);

    const activeFilters = defineActiveFiltersCount();

    const closeModal = () => {
        setState({
            ...state,
            isShowFilters: false,
            appliedFilters: undefined,
        });
    };

    const resetToDefault = () => {
        setState({
            ...state,
            isShowFilters: false,
            appliedFilters: setInitialFilters(filters, pageInitialFilters, defaultFilter),
        });

        resetToDefaultHandler && resetToDefaultHandler();
    };

    const filtersIcons = (
        <Box className="counter-wrapper">
            {isShowFilters ? (
                <KeyboardArrowUpIcon className="action-icons" onClick={() => switchFiltersView(false)} />
            ) : renderCustomIcon ? (
                renderCustomIcon(() => switchFiltersView(true))
            ) : (
                <TuneIcon className="action-icons" onClick={() => switchFiltersView(true)} />
            )}
            {!!(activeFilters && !isShowFilters) && <CountNotification value={activeFilters} />}
        </Box>
    );

    return (
        <div className={`${classes.filtersComponent} ${classes.filtersComponentMaxWidth}`}>
            <div className="filters-wrap">
                <Box display="flex">
                    {!_.isEmpty(filters) &&
                        (isHaulersFilters ? (
                            <ClickAwayListener onClickAway={() => setShowTip(false)}>
                                <Tooltip
                                    open={showTip}
                                    handleTooltipClose={() => setShowTip(false)}
                                    onClick={() => setShowTip(false)}
                                    title={
                                        "No Preferred Haulers found. Please configure your Preferred Haulers filter."
                                    }
                                >
                                    {filtersIcons}
                                </Tooltip>
                            </ClickAwayListener>
                        ) : (
                            filtersIcons
                        ))}
                    {isResetToDefault && (
                        <Tooltip title="Reset to default filter">
                            <RefreshIcon className="action-icons" onClick={resetToDefault} />
                        </Tooltip>
                    )}
                </Box>
            </div>
            {filters && (
                <ClickAwayListener onClickAway={hideFilters}>
                    <div
                        className={clsx(
                            `filters-section`,
                            filtersSectionClassName,
                            limitWidth && "filters-section-width-limitation",
                        )}
                        style={{ display: isShowFilters ? "inline" : "none" }}
                    >
                        {filters
                            .filter((i) => i.filters && i.filters.length)
                            .map((filterCategory, index) => {
                                return (
                                    <div key={index} className="category">
                                        <Grid container component="div" justify={"space-between"}>
                                            <Grid component="h3" item xs={index === 0 ? 7 : 12}>
                                                {filterCategory.title}
                                                {filterCategory.additionalLink}
                                            </Grid>
                                            {index === 0 && clearFiltersHandler && (
                                                <Grid component="h3" xs={5} item className="--text-right">
                                                    <SimpleLink className="clear-all-link" onClick={clearAllFilters}>
                                                        {clearAllTitle}
                                                    </SimpleLink>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <DividerThin marginTop={1} marginBottom={1} />
                                        <Grid container component="div">
                                            {filterCategory.filters
                                                .filter((i) => i)
                                                .map((filter, filterIndex) => {
                                                    const isActive =
                                                        !_.isEmpty(appliedFilters) &&
                                                        appliedFilters[filterCategory.filterKey] &&
                                                        appliedFilters[filterCategory.filterKey][filter.id];

                                                    return (
                                                        <Grid component="div" key={filterIndex} item>
                                                            <StatusPill
                                                                label={filter.label}
                                                                className={clsx("filters-pill", isActive && "active")}
                                                                onClick={() =>
                                                                    updateAppliedFilters(filterCategory, filter)
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </div>
                                );
                            })}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

Filters.propTypes = {
    filters: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    applyFilter: PropTypes.func.isRequired,
    clearFiltersHandler: PropTypes.func,
    updateFiltersHandler: PropTypes.func,
    resetToDefaultHandler: PropTypes.func,
    clearAllTitle: PropTypes.string,
    dataLoadedFor: PropTypes.string,
    form: PropTypes.string,
    initialFilters: PropTypes.object,
    defaultFilter: PropTypes.object,
    initialStatus: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isResetToDefault: PropTypes.bool.isRequired,
    isHaulersFilters: PropTypes.bool,
    rememberFilters: PropTypes.bool,
    showDefaultFilterTip: PropTypes.bool,
    limitWidth: PropTypes.bool,
    search: PropTypes.string,
    renderCustomIcon: PropTypes.any,
    chooseOneFilterOptionList: PropTypes.array,
    handleOneFilterOptionListChange: PropTypes.func,
    filtersSectionClassName: PropTypes.string,
};

Filters.defaultProps = {
    clearAllTitle: "Clear",
    initialStatus: {},
    isResetToDefault: false,
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form || "defaultFormName");

        return {
            triggerClearFilters: formSelector(state, "triggerClearFilters"),
            account: state.account,
        };
    })(Filters),
);
