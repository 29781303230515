import {
    PO_LINE_ITEM_FORCED_SELECTED,
    PO_LINE_ITEM_REMOVED,
    PO_LINE_ITEM_SELECTED,
    PO_LINE_ITEMS_FAILED,
    PO_LINE_ITEMS_LOADED,
    PO_LINE_ITEMS_LOADING,
    PO_LINE_ITEMS_RESET,
} from "../events/poLineItems";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    poLineItems: [],
    poLineItemsLoading: false,
    poLineItemsError: null,
    poLineItemsCount: 0,
    poLineItemsNextPage: null,
    currentPoLineItem: null,
    forcedSelectedPoLineItem: null,
};

export const poLIneItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PO_LINE_ITEMS_LOADING:
            return {
                ...state,
                poLineItemsLoading: true,
                poLineItemsError: null,
            };
        case PO_LINE_ITEMS_LOADED:
            return {
                ...state,
                poLineItems: action.payload.poLineItems,
                poLineItemsLoading: false,
                poLineItemsError: null,
                poLineItemsCount: action.payload.poLineItemsCount,
            };
        case PO_LINE_ITEMS_FAILED:
            return {
                ...state,
                poLineItemsLoading: false,
                poLineItemsError: action.payload.poLineItemsError,
            };
        case PO_LINE_ITEMS_RESET:
            return initialState;
        case PO_LINE_ITEM_SELECTED:
            return {
                ...state,
                currentPoLineItem: action.payload.currentPoLineItem,
            };
        case PO_LINE_ITEM_REMOVED:
            return {
                ...state,
                poLineItemsError: null,
                poLineItemsLoading: false,
                poLineItems: action.payload.poLineItems,
                currentPoLineItem: action.payload.currentPoLineItem,
            };
        case PO_LINE_ITEM_FORCED_SELECTED: {
            return {
                ...state,
                forcedSelectedPoLineItem: action.payload,
            };
        }
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
