import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { NotificationLabel } from "./notificationLabelListItem";
import Popper from "@material-ui/core/Popper";
import { IS_INSPECTOR_USER } from "../../constants/maps";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { THEME_ICONS } from "../../styles/icons";

const useStyles = makeStyles(() => ({
    uiIconNotificationsBadge: {
        width: 48,
    },
    uiIconNotifications: {
        ...THEME_ICONS.notifications,
    },
    notificationsList: {
        maxHeight: 280,
        overflowY: "scroll",
    },
}));

const NavigationNotifications = (props) => {
    const classes = useStyles();
    const [showNotifications, openNotifications] = useState(false);
    const notificationsAnchorRef = React.useRef(null);
    const { notificationPushEvents, account } = props;

    return (
        <React.Fragment>
            {!IS_INSPECTOR_USER(account.role) && (
                <React.Fragment>
                    <IconButton
                        id="notification"
                        className={classes.uiIconNotificationsBadge}
                        onClick={() => openNotifications(!showNotifications)}
                    >
                        <Badge id="notification" color="error" badgeContent={notificationPushEvents.length}>
                            <i
                                ref={notificationsAnchorRef}
                                className={classes.uiIconNotifications}
                                title="Notifications"
                                id="notification"
                            />
                        </Badge>
                    </IconButton>
                    <Popper open={showNotifications} anchorEl={notificationsAnchorRef.current} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={(e) => {
                                            if (!(e.target.localName === "span") && e.target.id !== "notification") {
                                                openNotifications(!showNotifications);
                                            }
                                        }}
                                    >
                                        <List>
                                            <ListSubheader disableSticky>
                                                You have {notificationPushEvents.length} notifications
                                            </ListSubheader>
                                            <div className={classes.notificationsList}>
                                                {notificationPushEvents.map((notificationEvent, index) => {
                                                    const { displayDate, notificationTitle } = notificationEvent;

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Divider />
                                                            <ListItem alignItems="center">
                                                                <ListItemAvatar>
                                                                    <Avatar />
                                                                </ListItemAvatar>
                                                                <li>
                                                                    <Grid item>
                                                                        <Grid>
                                                                            <NotificationLabel
                                                                                text={notificationTitle}
                                                                            />
                                                                        </Grid>
                                                                        <Grid>
                                                                            <span>{displayDate}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </li>
                                                            </ListItem>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </List>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

NavigationNotifications.propTypes = {
    notificationPushEvents: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            notificationPushEvents: state.notifications.notificationPushEvents,
        };
    })(NavigationNotifications),
);
