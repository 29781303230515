import dataProvider from "../helpers/api";
import {
    GLOBAL_COUNT_TO_LOAD,
    GLOBAL_ENDPOINTS,
    JOB_ORDERS,
    MY_FLEET,
    MY_TEAM,
    TRUCKS,
    USER,
} from "../constants/endpoints";
import {
    FILTERS_LIST_TRUCK_AVAILABILITY,
    FILTERS_MY_FLEET_TRUCK_TYPE_ID,
    FILTERS_REGIONS_ID,
    FILTERS_TRUCK_TYPE_ID,
} from "../components/global/searchAndFilters";
import _ from "lodash";
import {
    AVAILABILITY_ACTIVE,
    AVAILABILITY_INACTIVE,
    COMPANY_TYPE_TRUCKER,
    TRUCK_ACTIVE_AVAILABILITY_FILTERS_ID,
    TRUCK_INACTIVE_AVAILABILITY_FILTERS_ID,
} from "../constants/maps";
import { FILTERS_IDLE_TYPE_ID } from "../components/fleet/fleetFilters";
import { PICKUP_DISTANCE } from "../constants/global";

export const createTruck = (data) => {
    return dataProvider({
        url: MY_FLEET.TRUCKS(),
        method: "POST",
        data,
    });
};

export const editTruck = (truckId, data) => {
    return dataProvider({
        url: MY_FLEET.EDIT_TRUCK(truckId),
        method: "PUT",
        data,
    });
};

export const getTruckById = (truckId) => {
    return dataProvider({
        url: MY_FLEET.GET_TRUCK_BY_ID(truckId),
        method: "GET",
    });
};

export const archiveTruck = (truckId) => {
    return dataProvider({
        url: MY_FLEET.ARCHIVE_TRUCK(truckId),
        method: "DELETE",
    });
};

export const getHaulerTrucks = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.HAULERS(),
        method: "GET",
        params: {
            ...params,
            // https://github.com/TruckITllc/truckit-frontend/issues/1264
            withYourself: false,
            page: params.keywords ? 1 : params.page || 1,
            perPage: params.perPage || GLOBAL_COUNT_TO_LOAD,
            company: params.company, // 1,2
            type: COMPANY_TYPE_TRUCKER,
            keywords: params.keywords,
            validatedOnly: true,
            withArchived: true,
            [FILTERS_REGIONS_ID]: !_.isEmpty(params[FILTERS_REGIONS_ID]) ? params[FILTERS_REGIONS_ID] : undefined,
            [FILTERS_TRUCK_TYPE_ID]: !_.isEmpty(params[FILTERS_TRUCK_TYPE_ID])
                ? params[FILTERS_TRUCK_TYPE_ID]
                : undefined,
        },
    });
};

export const getHaulersWithoutPreferred = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.HAULERS(),
        method: "GET",
        params: {
            ...params,
            order: "name",
            withYourself: false,
            validatedOnly: true,
            type: 1,
            withoutPreferred: true,
            withArchived: false,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

const prepareTrucksKeywordsParam = (keywords) => {
    let keywordsString = keywords
        .trim()
        .split(",")
        .filter((i) => i)
        .map((i) => i.trim())
        .join(",");
    while (keywordsString.slice(-1) === ",") {
        keywordsString = keywordsString.slice(0, -1);
    }

    return keywordsString;
};

const getTruckAvailabilityParam = (truckActivity) => {
    if (+truckActivity === TRUCK_ACTIVE_AVAILABILITY_FILTERS_ID) {
        return AVAILABILITY_ACTIVE;
    } else if (+truckActivity === TRUCK_INACTIVE_AVAILABILITY_FILTERS_ID) {
        return AVAILABILITY_INACTIVE;
    }

    return null;
};

const TRUCKS_CALL_PARAMS = (params) => {
    return {
        ...params,
        order: params.order || "name",
        page: params.page || 1,
        perPage: params.perPage || params.limit || GLOBAL_COUNT_TO_LOAD,
        keywords: params.keywords ? prepareTrucksKeywordsParam(params.keywords) : undefined,
        [FILTERS_REGIONS_ID]: !_.isEmpty(params[FILTERS_REGIONS_ID]) ? params[FILTERS_REGIONS_ID] : undefined,
        type: !_.isEmpty(params[FILTERS_MY_FLEET_TRUCK_TYPE_ID]) ? params[FILTERS_MY_FLEET_TRUCK_TYPE_ID] : undefined,
        [FILTERS_LIST_TRUCK_AVAILABILITY]: getTruckAvailabilityParam(params[FILTERS_LIST_TRUCK_AVAILABILITY]),
    };
};

export const getCompanyTrucks = (params = {}) => {
    const parameters = params;
    if (parameters.cancelToken) delete parameters.cancelToken;

    return dataProvider({
        url: MY_FLEET.TRUCKSV2(),
        method: "GET",
        params: TRUCKS_CALL_PARAMS(params),
    });
};

export const getDispatchTrucks = (params = {}) => {
    if (params.withGranted !== undefined) {
        delete params.withGranted;
    }
    if (params.order !== PICKUP_DISTANCE) {
        delete params.locationId;
    }

    return dataProvider({
        url: MY_FLEET.TRUCKS_DISPATCH(),
        method: "GET",
        params: TRUCKS_CALL_PARAMS(params),
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getDeployedTrucks = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.TRUCKS_DISPATCH(),
        method: "GET",
        params: params,
    });
};

export const getLiveMapTrucks = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.LIVEMAP(),
        method: "GET",
        params: {
            status: params.status,
            [FILTERS_IDLE_TYPE_ID]: params[FILTERS_IDLE_TYPE_ID],
            keywords: params.keywords,
            regions: params.regions,
        },
    });
};

export const getTruckTypes = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.TRUCK_TYPES(),
        method: "GET",
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getMyTeam = (params = {}) => {
    const defaultOrderParams = {
        order: "lastName,firstName",
    };

    return dataProvider({
        url: USER.ALL(),
        method: "GET",
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
        params: {
            ...defaultOrderParams,
            ...params,
            page: params.page || 1,
            perPage: params.perPage || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords || undefined,
        },
    });
};

export const getCurrentLocationOfTruck = (params) => {
    return dataProvider({
        url: MY_FLEET.LIVEMAP(),
        method: "GET",
        params,
    });
};

export const grantAccessTruck = (truckId, data) => {
    return dataProvider({
        url: MY_FLEET.GRANT_ACCESS(truckId),
        method: "POST",
        data,
    });
};

export const getRegions = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.REGIONS(),
        method: "GET",
        params,
    });
};

export const assignRegion = (truckId, data) => {
    return dataProvider({
        url: MY_FLEET.ASSIGN_REGION(truckId),
        method: "PATCH",
        data,
    });
};

export const getUsersWithTrucks = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.USERS_FOR_LINK(),
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            role: params.role,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getDriversWithLinkEvents = (truckId, params = {}) => {
    return dataProvider({
        url: TRUCKS.DRIVERS_WITH_LINK_EVENTS(truckId),
        params: {
            date: params.date,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const linkTruck = (data = {}) => {
    return dataProvider({
        url: MY_FLEET.LINK_TRUCK_TO_USER(),
        method: "POST",
        data,
    });
};

export const unlinkTruck = (data = {}) => {
    return dataProvider({
        url: MY_FLEET.UNLINK_TRUCK(),
        method: "POST",
        data,
    });
};

export const getIotDevices = (params = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.IOT_DEVICES(),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const linkIotDevice = (data = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.LINK_IOT_TO_TRUCK(),
        method: "POST",
        data,
    });
};

export const unlinkIotDevice = (data = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.UNLINK_IOT_DEVICE(),
        method: "POST",
        data,
    });
};

export const getTruckActivity = (truckId, params) => {
    return dataProvider({
        url: MY_FLEET.TRUCK_ACTIVITY(truckId),
        method: "GET",
        params,
    });
};

export const updateTruckActivity = (truckId, data, params) => {
    return dataProvider({
        url: MY_FLEET.TRUCK_ACTIVITY(truckId),
        method: "PUT",
        data,
        params,
    });
};

export const getCompanyActivity = (companyId, params) => {
    return dataProvider({
        url: MY_FLEET.COMPANIES_ACTIVITY(companyId),
        method: "GET",
        params,
    });
};
export const getCompaniesCustomer = (companyId, customerId, params) => {
    return dataProvider({
        url: MY_FLEET.COMPANY_CUSTOMER(companyId, customerId),
        method: "GET",
        params,
    });
};

export const editCompaniesCustomer = (companyId, customerId, data) => {
    return dataProvider({
        url: MY_FLEET.COMPANY_CUSTOMER(companyId, customerId),
        method: "PUT",
        data,
    });
};

export const deleteCompaniesCustomer = (companyId, customerId) => {
    return dataProvider({
        url: MY_FLEET.COMPANY_CUSTOMER(companyId, customerId),
        method: "DELETE",
    });
};

export const removeTruckJoliActivity = (truckId, joLineItemId) => {
    return dataProvider({
        url: MY_FLEET.TRUCK_ACTIVITY(truckId),
        method: "DELETE",
        params: { joLineItemId },
    });
};

export const sendMessageToTruckDevice = (deviceId, data) => {
    return dataProvider({
        url: MY_FLEET.TRUCK_DEVICE_NOTIFY(deviceId),
        method: "POST",
        data,
    });
};

export const suspendTruck = (truckId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SUSPEND_TRUCK_BY_ID(truckId),
        method: "POST",
        data,
    });
};

export const unsuspendTruck = (truckId) => {
    return dataProvider({
        url: JOB_ORDERS.UNSUSPEND_TRUCK_BY_ID(truckId),
        method: "POST",
    });
};

export const getTruckStartTime = (truckId) => {
    return dataProvider({
        url: MY_TEAM.GET_TRUCK_START_TIME(truckId),
        method: "GET",
    });
};

export const fetchCompanyTruckTypes = (companyId, params) => {
    let requestParams = _.cloneDeep(params);
    if (requestParams && requestParams.cancelToken) {
        delete requestParams.cancelToken;
    }

    return dataProvider({
        url: TRUCKS.COMPANY_TRUCK_TYPES(companyId),
        method: "GET",
        params: requestParams,
    });
};

export const archiveTruckType = (truckTypeId) => {
    return dataProvider({
        url: TRUCKS.ARCHIVE_TRUCK_TYPE(truckTypeId),
        method: "POST",
    });
};

export const unarchiveTruckTypeRequest = (truckTypeId) => {
    return dataProvider({
        url: TRUCKS.UNARCHIVE_TRUCK_TYPE(truckTypeId),
        method: "POST",
    });
};

export const deleteTruckTypeRequest = (truckTypeId) => {
    return dataProvider({
        url: TRUCKS.TRUCK_TYPE(truckTypeId),
        method: "DELETE",
    });
};

export const updateTruckTypeRequest = (truckTypeId, data) => {
    return dataProvider({
        url: TRUCKS.TRUCK_TYPE(truckTypeId),
        method: "PUT",
        data,
    });
};

export const createNewTruckTypeRequest = (companyId, data) => {
    return dataProvider({
        url: TRUCKS.COMPANY_TRUCK_TYPES(companyId),
        method: "POST",
        data,
    });
};
