import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    warningPill: {
        border: `1px solid ${theme.palette.primary.superLight}`,
        color: theme.palette.primary.superLight,
        cursor: "pointer",
        padding: "0 5px",
        height: "25px",

        "& .MuiChip-label": {
            padding: "0 5px",
            fontSize: "12px",
        },
    },
}));

const SmartDispatchWarningLabel = ({ labelText }) => {
    const classes = useStyles();

    return (
        <Tooltip title={"Smart Dispatch is in beta version"}>
            <Chip label={labelText} variant="outlined" className={classes.warningPill} />
        </Tooltip>
    );
};

export default SmartDispatchWarningLabel;
