import { makeStyles } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    bundleError: {
        fontSize: 14,
        color: theme.palette.general.darkishRed,
    },
}));

const BundleItemErrorMessage = ({ message }) => {
    const classes = useStyles();

    return <Typography className={clsx(classes.bundleError)}>{message}</Typography>;
};

export default BundleItemErrorMessage;
