import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useSnackbar, withSnackbar } from "notistack";

import { notificationSnackbarRemove } from "../../actions/notifications";
import {
    TICPIC_REPORT_STATUS_IN_PROGRESS,
    TICPIC_REPORT_STATUS_SUCCESS,
    WEBSOCKET_DATA_TYPES_FOR_ERROR_NOTIFICATION,
    WEBSOCKET_DATA_TYPES_FOR_WARNING_NOTIFICATION,
} from "../../constants/maps";
import Button from "@material-ui/core/Button";
import { Close } from "@material-ui/icons";

let displayed = new Set();

const StoreNotification = (props) => {
    const { enqueueSnackbar, config, type, notificationSnackbars, notificationSnackbarRemove } = props;

    const { closeSnackbar } = useSnackbar();

    const storeDisplayed = (key) => {
        displayed.add(key);
    };

    const removeDisplayed = (key) => {
        displayed.delete(key);
    };

    const getVariant = (notificationType) => {
        if (WEBSOCKET_DATA_TYPES_FOR_WARNING_NOTIFICATION.includes(notificationType)) {
            return "warning";
        }
        if (WEBSOCKET_DATA_TYPES_FOR_ERROR_NOTIFICATION.includes(notificationType)) {
            return "error";
        }

        return type;
    };

    useEffect(() => {
        notificationSnackbars.forEach(({ key, message, notificationType, reportStatus }) => {
            if (displayed.has(key)) return;
            const snackbar = {
                key,
                variant: getVariant(notificationType),
                ...config,
                onExited: (_, myKey) => {
                    notificationSnackbarRemove({ key: myKey });
                    removeDisplayed(myKey);
                },
            };
            if (reportStatus === TICPIC_REPORT_STATUS_IN_PROGRESS) {
                snackbar.persist = true;
                snackbar.action = () => (
                    <Button onClick={() => closeSnackbar(key)}>
                        <Close style={{ fill: "white" }} />
                    </Button>
                );
            }
            if (reportStatus === TICPIC_REPORT_STATUS_SUCCESS) {
                const snackbarToClose = notificationSnackbars.find(
                    (i) => i?.reportStatus === TICPIC_REPORT_STATUS_IN_PROGRESS,
                );
                if (snackbarToClose) {
                    setTimeout(() => {
                        closeSnackbar(snackbarToClose.key);
                    }, 1000);
                }
            }

            enqueueSnackbar(
                <span dangerouslySetInnerHTML={{ __html: message.replace(/\|/g, "").replace("&link", "") }} />,
                snackbar,
            );

            storeDisplayed(key);
        });
    }, [notificationSnackbars, enqueueSnackbar, notificationSnackbarRemove, type, config]);

    return <div />;
};

StoreNotification.propTypes = {
    config: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["default", "success", "error", "info"]),
    notificationSnackbars: PropTypes.array.isRequired,
    notificationSnackbarRemove: PropTypes.func.isRequired,
};

StoreNotification.defaultProps = {
    config: {},
    type: "success",
    notificationSnackbars: [],
};

export default withSnackbar(
    compose(
        connect(
            (state) => ({
                notificationSnackbars: state.notifications.notificationSnackbars,
            }),
            (dispatch) => ({
                notificationSnackbarRemove: (payload) => {
                    dispatch(notificationSnackbarRemove(payload));
                },
            }),
        ),
    )(StoreNotification),
);
