import _ from "lodash";
import moment from "moment";
import { sortByStartDate } from "./global";
import { DEFAULT_DATE_FORMAT } from "../constants/global";
import { METRIC } from "../constants/accounts";

export const openJobOrderById = (historyData, jobId, callBackData) => {
    historyData.history.push(`${historyData._goTo}/${jobId}`);
    callBackData && callBackData.callBack && callBackData.callBack(callBackData.args);
};

export const timeFromSeconds = (seconds, showText = true, minText = "m", noDataText = "") => {
    if (!_.isNumber(seconds)) {
        return "";
    }

    if (seconds < 0) {
        return noDataText ? noDataText : `No Data`;
    }

    if (+seconds === 0) {
        return noDataText ? noDataText : `-`;
    }

    if (seconds < 60) {
        return `< 1${showText ? minText : ""}`;
    }

    const dateObj = new Date(+seconds * 1000);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();

    return `${+hours ? `${+hours}${showText ? "h" : ""}` : ""}${
        +minutes ? ` ${minutes}${showText ? minText : ""}` : ""
    }`;
};

const haulerTruckData = (truck) => {
    return {
        startDate: truck.startDate,
        quantity: truck.quantity,
        name: truck.isMyFleetTruck ? truck.company.name : truck.name,
        id: truck.isMyFleetTruck ? truck.company.id : truck.id,
        needTrucksCount: 1,
        haulerRate: truck.defaultRate,
        trucks: [
            {
                hasTickets: false,
                isPending: false,
                truckId: truck.id || truck.truckId,
                truckName: truck.truckName || truck.name,
                truckType: truck.truckType,
            },
        ],
    };
};

export const reorganizeJolis = (jolis, truck, needSort = true) => {
    const formSpecifyTrucksPerHauler = truck.specifyTrucksPerHauler;
    const sameTimeJoli = jolis.findIndex((i) => {
        const joliTime = moment(i.startDate).format("LT");
        const truckTime = moment(truck.startDate).format("LT");

        return joliTime === truckTime;
    });

    if (truck.isHaulerTruck) {
        truck.isMyFleetTruck = true;
    }

    const joliIndexWithHauler = truck.isMyFleetTruck
        ? truck.truckShiftAllowed
            ? jolis.findIndex((i) => {
                  return (
                      i.haulers &&
                      i.haulers.find((hauler) => {
                          return (
                              hauler.id === truck.company.id && // no such trucks assigned yet
                              !hauler.trucks.find((i) => (i.id || i.truckId) === (truck.id || truck.truckId))
                          );
                      })
                  );
              })
            : jolis.findIndex((i) => {
                  let jobHaulersTrucksCount = 0;

                  i.haulers
                      .filter((h) => !_.isEmpty(h.trucks))
                      .forEach((h) => (jobHaulersTrucksCount = jobHaulersTrucksCount + h.trucks.length));

                  const joliAssignedTrucksCount = i.trucks.length + jobHaulersTrucksCount;

                  return (
                      i.haulers &&
                      i.haulers.find((hauler) => {
                          return (
                              hauler.id === truck.company.id && // truck belongs to company
                              (!formSpecifyTrucksPerHauler
                                  ? joliAssignedTrucksCount + 1 <= i.notAddressedTrucksCount
                                  : hauler.needTrucksCount > hauler.trucks.length) && // trucks count less then hauler request
                              !hauler.trucks.find((i) => (i.id || i.truckId) === (truck.id || truck.truckId))
                          ); // no such trucks assigned yet
                      })
                  );
              })
        : -1;

    let newJOlis = _.cloneDeep(jolis);
    if (sameTimeJoli !== -1 && joliIndexWithHauler === -1) {
        newJOlis = newJOlis.map((joli, index) => {
            if (index === sameTimeJoli) {
                if (truck.truckShiftAllowed && truck.isMyFleetTruck) {
                    joli.haulers.push(haulerTruckData(truck));
                    joli.haulers = sortByStartDate(joli.haulers);
                } else if (truck.isMyFleetTruck) {
                    if (!joli.trucks.find((i) => i.truckId === (truck.id || truck.truckId))) {
                        joli.trucks.push(truck);
                        joli.trucks = sortByStartDate(joli.trucks);
                    }
                } else {
                    if (!joli.haulers.find((i) => i.id === (truck.id || truck.truckId))) {
                        joli.haulers.push(truck);
                        joli.haulers = sortByStartDate(joli.haulers);
                    }
                }
            }

            return joli;
        });
    } else if (joliIndexWithHauler !== -1) {
        newJOlis = newJOlis.map((joli, index) => {
            if (index === joliIndexWithHauler) {
                if (truck.isMyFleetTruck) {
                    const truckCompanyIndex = joli.haulers.findIndex((i) => i.id === truck.company.id);
                    if (truckCompanyIndex !== -1) {
                        joli.haulers = joli.haulers.map((hauler, haulerIndex) => {
                            if (
                                !truck.isHaulerTruck &&
                                truck.truckShiftAllowed &&
                                (hauler.needTrucksCount > 1 || hauler.trucks.find((i) => truck.truckShiftAllowed))
                            ) {
                                hauler.needTrucksCount = hauler.needTrucksCount + 1;
                            }
                            if (haulerIndex === truckCompanyIndex) {
                                if (truck.isHaulerTruck) {
                                    hauler.needTrucksCount =
                                        hauler.needTrucksCount === hauler.trucks.length
                                            ? hauler.needTrucksCount + 1
                                            : hauler.needTrucksCount;
                                }

                                hauler.trucks.push(truck);
                            }

                            return hauler;
                        });
                    }
                }
            }

            return joli;
        });
    } else {
        const data = {
            trucks: [],
            haulers: [],
            quantity: null,
            startDate: truck.startDate,
        };
        if (truck.truckShiftAllowed && truck.isMyFleetTruck) {
            data.haulers.push(haulerTruckData(truck));
            data.haulers = sortByStartDate(data.haulers);
        } else if (truck.isMyFleetTruck) {
            data.trucks.push(truck);
            data.trucks = sortByStartDate(data.trucks);
        } else {
            data.haulers.push(truck);
            data.haulers = sortByStartDate(data.haulers);
        }

        newJOlis.push(data);
    }

    if (needSort) {
        return sortByStartDate(newJOlis);
    } else {
        return newJOlis;
    }
};

export const noTrucksInJobOrder = (joliItems) =>
    _.isEmpty(joliItems) || joliItems.every((i) => !i || (_.isEmpty(i.trucks) && _.isEmpty(i.haulers)));

export const dropItemTypes = {
    TRUCK: "truck",
    JOB: "job",
};

export const convertDateToUserTimeZone = (date, timezone, format = null) => {
    let user_date;
    date = _.isDate(date) ? date : new Date(date);
    if (timezone) {
        user_date = moment().tz(timezone);
    } else {
        user_date = moment().tz();
    }
    user_date.milliseconds(1);
    user_date.seconds(date.getSeconds());
    user_date.minutes(date.getMinutes());
    user_date.hours(date.getHours());
    user_date.year(date.getFullYear());
    user_date.date(1);
    user_date.month(date.getMonth());
    user_date.date(date.getDate());

    return user_date.format(format);
};

export const convertTimeToJsFormat = (date, timezone) => {
    let utc;
    let newDate;
    let monentDate = moment(date);
    if (timezone) {
        utc = monentDate.tz(timezone);
    } else {
        utc = monentDate.tz("utc");
    }

    utc.utcOffset(0, true);

    newDate = new Date();
    newDate.setMilliseconds(1);
    newDate.setSeconds(utc.seconds());
    newDate.setMinutes(utc.minutes());
    newDate.setHours(utc.hours());
    newDate.setFullYear(utc.year());
    newDate.setDate(1);
    newDate.setMonth(utc.month());
    newDate.setDate(utc.date());

    return newDate;
};

export const processTrailers = (items) => {
    const trailers =
        items &&
        items
            .filter((jobItem) => jobItem.trailers.length)
            .reduce((acc, jobItem) => {
                return [...acc, ...jobItem.trailers];
            }, []);
    if (trailers && trailers.length) {
        const trucks = [...new Set(trailers.map((trailer) => trailer.truckId))];
        const truckWithTrailers = {};
        trucks.forEach((truckId) => {
            const trucksTrailers = trailers
                .filter((trailer) => trailer.truckId === truckId)
                .map((i) => ({ ...i, id: i.trailerId }));
            truckWithTrailers[`${truckId}-trailers`] = trucksTrailers;
        });

        return truckWithTrailers;
    }
    return {};
};

// admin users specified as Contractors too
export const processJolisForContractor = (items, timeZone) => {
    return items.map((joli) => {
        const startDate = convertTimeToJsFormat(joli.startDate, timeZone);

        return {
            ...joli,
            startDate,
            trucks: joli.trucks
                ? joli.trucks.map((t) => ({
                      ...t,
                      startDate: startDate,
                      isMyFleetTruck: true,
                  }))
                : [],
            haulers: joli.haulers
                ? joli.haulers.map((h) => ({
                      ...h,
                      startDate: startDate,
                      needTrucksCount: h.needTrucksCount,
                  }))
                : [],
        };
    });
};

export const getJobOrderInitialTime = () => {
    const time = new Date();

    time.setHours(8);
    time.setMinutes(0);
    time.setSeconds(0);

    return moment(time);
};

export const jobOrderHasHaulers = (jolis = []) => {
    return jolis && jolis.find((i) => !_.isEmpty(i.haulers));
};

export const getJobOrderInitialDate = (defaultStartTime) => {
    const tomorrowDate = moment().add(1, "d");
    const tomorrowDay = moment(tomorrowDate).format(DEFAULT_DATE_FORMAT);
    const formattedDefaultJoStartTime = moment(defaultStartTime, "hh:mm:ss").format("hh:mm");
    const temp = moment(`${tomorrowDay} ${formattedDefaultJoStartTime}`, `${DEFAULT_DATE_FORMAT} hh:mm`).format();

    return moment(temp);
};

export const getCopyStartDate = (startDate) => {
    const startTime = moment(startDate).format("HH:mm");
    const tomorrowDate = moment().add(1, "d");
    const tomorrowDay = moment(tomorrowDate).format(DEFAULT_DATE_FORMAT);
    const formattedDefaultJoStartTime = moment(startTime, "HH:mm:ss").format("HH:mm");
    const temp = moment(`${tomorrowDay} ${formattedDefaultJoStartTime}`, `${DEFAULT_DATE_FORMAT} HH:mm`).format();

    return moment(temp);
};

export const getDateObject = (date) => {
    return {
        year: moment(date).get("year"),
        month: moment(date).get("month"),
        date: moment(date).get("date"),
    };
};

export const getIsJobOrderRequest = (jobOrder, account) => {
    if (jobOrder) {
        const { grantedAccess, requestedBy } = jobOrder;
        return grantedAccess && requestedBy?.id && requestedBy.id === account.company.id;
    }

    return false;
};

export const getIfIsDropOffMidpoint = (jobOrder) => jobOrder && jobOrder.isSplit && !!jobOrder.nextJobOrderId;

export const getIfIsPickUpMidpoint = (jobOrder) => jobOrder && jobOrder.isSplit && !!jobOrder.previousJobOrderId;

export const getShowCompletedCubage = (completedCubage, measurementSystem) =>
    !!completedCubage && measurementSystem !== METRIC;
