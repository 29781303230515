import React, { useEffect, useState } from "react";
import { getTickets } from "../dataServers/tickets";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { PREDICTIVE_OPTIONS_CATEGORY_VALUE_SEPARATOR } from "../helpers/predictiveSearchHelpers";
import Axios from "axios";

let requestPromise;
let cancelPromise = null;

const SYMBOLS_TO_ELIMINATE = /[ !@_?*+:;#%&`~,./={}<>^$'"()\]\[\-]/g;

export const useTicketExists = (
    externalRef,
    projectId,
    projectCreateDate,
    currentTicketId = null,
    initialExtRef = null,
    disableSearch = null,
) => {
    const [exists, setExists] = useState(null);

    const updateTicketExists = () => {
        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        const params = {
            startDate: moment(projectCreateDate || moment().subtract(1, "year")).format(SERVER_DATE_FORMAT),
            endDate: moment().format(SERVER_DATE_FORMAT),
            projectId,
            keywords: `ticketExtRef${PREDICTIVE_OPTIONS_CATEGORY_VALUE_SEPARATOR}${externalRef}`,
        };

        requestPromise = getTickets({ ...params, cancelToken });

        requestPromise
            .then(({ data }) => {
                const sameExtRefTicket = data.find((ticket) => {
                    const cleanedTicketExtRef = ticket.externalRef.replace(SYMBOLS_TO_ELIMINATE, "");
                    const cleanedInputtedExtRef = externalRef.replace(SYMBOLS_TO_ELIMINATE, "");

                    return cleanedTicketExtRef === cleanedInputtedExtRef && ticket.id !== currentTicketId;
                });

                setExists(sameExtRefTicket);
            })
            .catch(() => {});
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (externalRef && projectId && initialExtRef !== externalRef && !disableSearch) {
                updateTicketExists();
            } else {
                setExists(null);
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [externalRef]);

    useEffect(() => {
        if (!currentTicketId && externalRef && projectId) {
            updateTicketExists();
        } else {
            setExists(null);
        }
    }, [projectId]);

    return exists;
};
