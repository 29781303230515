import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { change, destroy } from "redux-form";
import { useDrop } from "react-dnd";
import { withRouter } from "react-router-dom";

import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import {
    assignToRoute,
    getDeployedTrucks,
    myFleetNeedsToBeUpdatedChange,
    resetDeyployedTrucks,
    resetRouteDetails,
    setSmartDispatchConfirmationModal,
    setSmartDispatchHaulerAssignConfirmationModal,
    showSmartDispatchFailureMessage,
    updateAssignBundleData,
    updateCurrentBundle,
    updateHaulerToAssign,
    updateSelectedTruckSolutionList,
} from "../../actions/smartDispatch";
import { resetHaulerParam, resetJobOrderParams } from "../../actions/jobOrders";
import { DRAG_AND_DROP_STYLES } from "../../styles/reusableStyles";
import { SmartDispatchBundlePill } from "./BundlePills";
import {
    selectCurrentBundle,
    selectIfIsTruckSolutionSelected,
    selectRouteFirstPickUpLocation,
    selectSelectedTruckSolutionList,
    selectSmartDispatchIsConfirmationModalShown,
    selectSmartDispatchIsHaulerAssignModalShown,
    selectSmartDispatchIsLoading,
    selectSolutionEmptyRoutes,
} from "../../selectors/smartDispatchSelectors";
import { selectActiveHaulerOrders, selectTruckDispatchedParam } from "../../selectors/index";
import BundleRoute from "./route/BundleRoute";
import {
    ASSIGN_WARNING,
    INCORRECT_AMOUNT_OF_SELECTED_TRUCKS,
    MULTIPLE_ASSIGNED_DRAGGABLE_OVERLAY_MESSAGE,
    NO_EMPTY_ROUTES,
} from "../../constants/strings";
import { dropItemTypes } from "../../helpers/jobOrders";
import ConfirmationModal from "../core/confirmationModal";
import { LOADER_WHITE_OVERLAY, PICKUP_DISTANCE } from "../../constants/global";
import Loader from "../core/loader";
import HaulerAssignConfirmationModal from "./HaulerAssignConfirmationModal";
import { setUpUrl } from "./constants/index";
import { useBrowserButtonHandler } from "../../hooks";
import { WarningRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    bundleDetails: {
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",
        padding: "15px",
        marginBottom: "15px",

        "& .bundle-title": {
            fontSize: "24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontWeight: 700,
            marginBottom: "15px",
        },

        "& .bundle-detail-jo-list": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    truckAssignWarningContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    truckAssignWarningIcon: {
        width: "20px",
        height: "20px",
        color: theme.palette.general.paleRed,
    },
}));

const BundleDetails = ({
    jobFormId,
    history,
    startDate,
    match: {
        params: { viewBy },
    },
}) => {
    const classes = useStyles();
    const dnDStyles = DRAG_AND_DROP_STYLES();

    const dispatch = useDispatch();

    const currentBundle = useSelector(selectCurrentBundle);

    const emptyRoutes = useSelector(selectSolutionEmptyRoutes);
    const selectedTruckSolutionList = useSelector(selectSelectedTruckSolutionList);
    const isShown = useSelector(selectSmartDispatchIsConfirmationModalShown);
    const haulerAssignModalIsShown = useSelector(selectSmartDispatchIsHaulerAssignModalShown);
    const isLoading = useSelector(selectSmartDispatchIsLoading);
    const routeFirstPickUpLocation = useSelector(selectRouteFirstPickUpLocation);
    const truckSolutionIsSelected = useSelector(selectIfIsTruckSolutionSelected);
    const myFleetSelectedTrucks = useSelector((state) => state.jobOrders.myFleetSelectedTrucks);

    const truckDispatchedParam = useSelector(selectTruckDispatchedParam);
    const activeHaulerOrders = useSelector(selectActiveHaulerOrders);

    const [{ canDrop }, drop] = useDrop({
        accept: dropItemTypes.TRUCK,
        drop: (item) => {
            if (emptyRoutes.length === 0) {
                dispatch(showSmartDispatchFailureMessage([NO_EMPTY_ROUTES]));

                return;
            }

            if (!item.isTruck) {
                dispatch(setSmartDispatchHaulerAssignConfirmationModal(true));
                dispatch(updateHaulerToAssign({ id: item.id, defaultRate: item.defaultRate }));

                return;
            }

            if (myFleetSelectedTrucks.length > emptyRoutes.length) {
                dispatch(showSmartDispatchFailureMessage([INCORRECT_AMOUNT_OF_SELECTED_TRUCKS]));

                return;
            }

            const assignData = myFleetSelectedTrucks.map((truck, index) => {
                return {
                    index: emptyRoutes[index].index,
                    truckId: truck.id,
                };
            });
            if (myFleetSelectedTrucks.length === 0) {
                assignData.push({
                    index: emptyRoutes[0].index,
                    truckId: item.id,
                });
            }
            dispatch(updateAssignBundleData({ assignments: assignData }));
            dispatch(setSmartDispatchConfirmationModal(true));
        },
        collect: (monitor) => ({
            canDrop: !!monitor.canDrop(),
        }),
        canDrop: () => {
            if (currentBundle && currentBundle.isIntraDay) return false;
            if (currentBundle?.isDraft) return false;

            return selectedTruckSolutionList.length === 0;
        },
    });

    const onCancelClick = () => {
        dispatch(resetJobOrderParams());
        dispatch(resetHaulerParam());
        dispatch(updateCurrentBundle(null));
        setUpUrl(history.push)({ viewBy }).push();
    };

    useBrowserButtonHandler(onCancelClick, viewBy === "bundle");

    const yesHandler = () => {
        dispatch(assignToRoute());
        dispatch(setSmartDispatchConfirmationModal(false));
    };

    const noHandler = () => {
        dispatch(setSmartDispatchConfirmationModal(false));
    };

    useEffect(() => {
        return () => {
            dispatch(updateCurrentBundle(null));
            dispatch(resetRouteDetails());
            dispatch(updateSelectedTruckSolutionList([]));
            dispatch(myFleetNeedsToBeUpdatedChange(false));
            dispatch(resetJobOrderParams());
            dispatch(resetHaulerParam());
            dispatch(destroy(jobFormId));
        };
    }, []);

    useEffect(() => {
        if (currentBundle && currentBundle.isIntraDay) {
            dispatch(getDeployedTrucks(false, { bundleId: currentBundle.problemId, jobsForDate: startDate }));
        }

        return () => {
            dispatch(resetDeyployedTrucks());
        };
    }, []);

    const isRequestTrucks = PICKUP_DISTANCE === truckDispatchedParam || PICKUP_DISTANCE === activeHaulerOrders;

    useEffect(() => {
        if (routeFirstPickUpLocation?.id && truckSolutionIsSelected) {
            const { id } = routeFirstPickUpLocation;
            dispatch(
                change(jobFormId, "pickUpSite", {
                    ...routeFirstPickUpLocation,
                    value: routeFirstPickUpLocation.id,
                }),
            );
            isRequestTrucks && dispatch(change(jobFormId, "requestFleet", id));
        }
    }, [routeFirstPickUpLocation?.id, truckSolutionIsSelected]);

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <Box margin={"15px"} display="flex" justifyContent="flex-end">
                <CloseIcon color={"action"} fontSize={"default"} cursor={"pointer"} onClick={onCancelClick} />
            </Box>

            <div className={clsx(classes.bundleDetails, dnDStyles.dragAndDrop)}>
                <div className="bundle-title">
                    <div>
                        {currentBundle?.isDraft && "Draft"} Bundle # {currentBundle?.problemId}
                    </div>
                    <div className="bundle-status-container">
                        <SmartDispatchBundlePill status={currentBundle?.status} />
                    </div>
                </div>
                {currentBundle?.isDraft && (
                    <div className={clsx(classes.truckAssignWarningContainer)}>
                        <WarningRounded className={clsx(classes.truckAssignWarningIcon)} />
                        You are not allowed to assign trucks to draft bundles
                    </div>
                )}
                <div className="draggable-overlay-wrap" ref={drop}>
                    {canDrop && (
                        <div
                            className="draggable-overlay"
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                            }}
                        >
                            <p
                                className="draggable-text --text-center full-height-text"
                                style={{ marginTop: "35px", height: "100px" }}
                            >
                                {MULTIPLE_ASSIGNED_DRAGGABLE_OVERLAY_MESSAGE}
                            </p>
                        </div>
                    )}

                    {currentBundle &&
                        currentBundle.solution
                            .filter(
                                (route) =>
                                    selectedTruckSolutionList.length === 0 ||
                                    selectedTruckSolutionList.includes(route.id),
                            )
                            .map(({ routeItems, assignedTruck, primeHauler, subHauler, index, id }) => {
                                const routeJobOrders =
                                    routeItems &&
                                    routeItems
                                        .map((routeItem) => {
                                            const jobOrderData = currentBundle.jobOrders.find(
                                                (jobOrder) => jobOrder.id === routeItem.jobId,
                                            );
                                            if (jobOrderData) {
                                                return {
                                                    ...jobOrderData,
                                                    loadCount: routeItem.loadCount,
                                                    totalLoadsCount: routeItem.totalLoadsCount,
                                                    manuallyAdded: routeItem.manuallyAdded,
                                                };
                                            }
                                            return null;
                                        })
                                        .filter((route) => route !== null);

                                return (
                                    <BundleRoute
                                        viewBy={viewBy}
                                        jobFormId={jobFormId}
                                        jobOrders={routeJobOrders}
                                        assignedTruck={assignedTruck}
                                        primeHauler={primeHauler}
                                        subHauler={subHauler}
                                        solutionIndex={index}
                                        routeId={id}
                                        bundleId={currentBundle.problemId}
                                        history={history}
                                        key={id}
                                    />
                                );
                            })}
                </div>
            </div>
            <ConfirmationModal
                isOpen={isShown}
                question={ASSIGN_WARNING}
                yesHandler={yesHandler}
                noHandler={noHandler}
                modalStyles={{ width: 700 }}
            />
            <HaulerAssignConfirmationModal isOpen={haulerAssignModalIsShown} />
        </div>
    );
};

export default withRouter(BundleDetails);
