import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import {
    TRUCK_ACTIVITY_EVENT_DO,
    TRUCK_ACTIVITY_EVENT_DO_COLOR,
    TRUCK_ACTIVITY_EVENT_PU,
    TRUCK_ACTIVITY_EVENT_PU_COLOR,
    TRUCK_ACTIVITY_NA,
    TRUCK_ACTIVITY_NA_EVENT_COLOR,
} from "../../constants/maps";

const legendMap = {
    [TRUCK_ACTIVITY_EVENT_DO]: {
        label: "Drop Off",
        color: TRUCK_ACTIVITY_EVENT_DO_COLOR,
    },
    [TRUCK_ACTIVITY_EVENT_PU]: {
        label: "Pick Up",
        color: TRUCK_ACTIVITY_EVENT_PU_COLOR,
    },
    [TRUCK_ACTIVITY_NA]: {
        label: "En Route",
        color: TRUCK_ACTIVITY_NA_EVENT_COLOR,
    },
};

const useStyles = makeStyles(() => ({
    activityLegend: {
        width: "100%",
        position: "relative",
        marginLeft: 20,

        "& .color": {
            width: 10,
            height: 10,
        },
        "& .text": {
            fontSize: 14,
            fontWeight: 500,
        },
    },
}));

const ActivityLegend = (props) => {
    const classes = useStyles();
    const { trucksActivity } = props;
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const uniq = _.unionBy([...trucksActivity, { type: TRUCK_ACTIVITY_NA }], "type");
        setEvents(uniq);
    }, []);

    return (
        <table className={classes.activityLegend}>
            <tbody>
                {events.map(({ type }, index) => {
                    const eventData = legendMap[type];

                    return eventData ? (
                        <tr key={index}>
                            <td>
                                <div
                                    className="color"
                                    style={{
                                        backgroundColor: legendMap[type].color,
                                    }}
                                />
                            </td>
                            <td className="text">{legendMap[type].label}</td>
                        </tr>
                    ) : null;
                })}
            </tbody>
        </table>
    );
};

ActivityLegend.propTypes = {
    trucksActivity: PropTypes.array.isRequired,
};

export default ActivityLegend;
