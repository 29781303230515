import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import CreditCardDialog from "./creditCardDialog";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { COMPANY_TYPE_LIMITED_CONTRACTOR, COMPANY_TYPE_LIMITED_TRUCKER } from "../../../constants/maps";

const CreditCardDialogWrapper = () => {
    const companyType = useSelector((state) => state.account.company.type);
    const [creditCardDialogOpen, setCreditCardDialogOpen] = useState(true);

    const stripePromise = () => loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

    useEffect(() => {
        stripePromise();
    }, []);

    if (companyType !== COMPANY_TYPE_LIMITED_CONTRACTOR && companyType !== COMPANY_TYPE_LIMITED_TRUCKER) {
        return null;
    }

    return (
        <Elements stripe={stripePromise()}>
            <CreditCardDialog
                handleClose={() => setCreditCardDialogOpen(false)}
                creditCardDialogOpen={creditCardDialogOpen}
            />
        </Elements>
    );
};

export default CreditCardDialogWrapper;
