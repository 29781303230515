import dataProvider from "../helpers/api";
import { TRAILERS } from "../constants/endpoints";

export const fetchTrailers = (params = {}) => {
    return dataProvider({
        url: TRAILERS.TRAILERS(),
        method: "GET",
        params,
    });
};

export const createTrailer = (data = {}) => {
    return dataProvider({
        url: TRAILERS.TRAILERS(),
        method: "POST",
        data,
    });
};

export const updateTrailer = (id, data = {}) => {
    return dataProvider({
        url: TRAILERS.TRAILER_ID(id),
        method: "PUT",
        data,
    });
};

export const archiveTrailer = (id) => {
    return dataProvider({
        url: TRAILERS.TRAILERS_ARCHIVE(id),
        method: "PUT",
    });
};
