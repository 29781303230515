import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { reduxForm, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";

import { lighten, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

import ListWaypoint from "./listWaypoint";

function EnhancedTableHead(props) {
    const { headCells, classes, onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={"center"} padding={"normal"}>
                        <TableSortLabel>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, header } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {header}
                </Typography>
            )}
            <Tooltip title="Close">
                <IconButton onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "&.Mui-selected": {
            borderColor: theme.palette.primary.main,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.primary.main,
            fontSize: theme.typography.fontSize,

            "& .label-wrapper": {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        width: "100%",
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    tableCell: {
        cursor: "pointer",
        padding: 10,
    },
}));

function EnhancedTable({ headCells, rows, createData, change, form, name, header, handleClose }) {
    const classes = useStyles();
    const selected = useSelector((state) => formValueSelector(form)(state, name)) || [];

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            change(name, rows);
            return;
        }
        change(name, []);
    };

    const getId = (row) => row.id || row.trailerId;

    const isSelected = (row) => !!(selected.filter((i) => getId(i) === getId(row)) || []).length;

    const handleClick = (event, row) => {
        if (isSelected(row)) {
            const newSelected = selected.filter((i) => getId(i) !== getId(row));
            change(name, newSelected);
        } else {
            change(name, [...selected, row]);
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar handleClose={handleClose} header={header} numSelected={selected.length} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"small"}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            classes={classes}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows &&
                                rows.map((row, index) => {
                                    const data = createData(row, index);
                                    const isItemSelected = isSelected(row);

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={getId(row)}
                                            selected={isItemSelected}
                                        >
                                            <TableCell
                                                align={"center"}
                                                padding="checkbox"
                                                className={classes.tableCell}
                                            >
                                                <Checkbox checked={isItemSelected} />
                                            </TableCell>
                                            {headCells.map((row) => {
                                                return (
                                                    <TableCell
                                                        align={"center"}
                                                        component="th"
                                                        key={getId(row)}
                                                        scope="row"
                                                        padding="none"
                                                        className={classes.tableCell}
                                                    >
                                                        {data[getId(row)]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default reduxForm({
    destroyOnUnmount: false,
})(EnhancedTable);
