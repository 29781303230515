import {
    PO_LINE_ITEM_FORCED_SELECTED,
    PO_LINE_ITEM_REMOVED,
    PO_LINE_ITEM_SELECTED,
    PO_LINE_ITEMS_FAILED,
    PO_LINE_ITEMS_LOADED,
    PO_LINE_ITEMS_LOADING,
    PO_LINE_ITEMS_RESET,
} from "../events/poLineItems";
import { selectPoLineItemsLoading } from "../selectors/index";
import * as POLineItemsDataServer from "../dataServers/poLineItems";
import _ from "lodash";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import { handleError } from "../helpers/global";

export const getPOLineItems = (projectId, concat, archived) => {
    return (dispatch, getState) => {
        const state = getState();

        const loading = selectPoLineItemsLoading(state);

        if (loading) return;

        const currentPage = Math.ceil(state.poLineItems.poLineItems.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.poLineItems.poLineItems) ? +currentPage + 1 : 1;
        dispatch({
            type: PO_LINE_ITEMS_LOADING,
            payload: {},
        });

        return POLineItemsDataServer.getPOLineItems(projectId, {
            page: concat ? nextPage : 1,
            archived: !!archived,
        })
            .then((response) => {
                dispatch({
                    type: PO_LINE_ITEMS_LOADED,
                    payload: {
                        poLineItems: concat ? [...state.poLineItems.poLineItems, ...response.data] : response.data,
                        poLineItemsCount: response.meta.count,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: PO_LINE_ITEMS_FAILED,
                    payload: {
                        poLineItemsError: error.message,
                    },
                });
            });
    };
};

export const resetPOLineItems = () => {
    return {
        type: PO_LINE_ITEMS_RESET,
        payload: {},
    };
};

export const setCurrentPOLineItem = (poLineItem) => {
    return {
        type: PO_LINE_ITEM_SELECTED,
        payload: {
            currentPoLineItem: poLineItem,
        },
    };
};

export const updatePoLineItem = (id, values) => async () => {
    try {
        return await POLineItemsDataServer.updatePoLineItem(id, values);
    } catch (error) {
        throw new Error(handleError(error).message);
    }
};

export const removePOLineItem = (poLineItem) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: PO_LINE_ITEMS_LOADING,
            payload: {},
        });

        return POLineItemsDataServer.deletePOLineItem(poLineItem.id)
            .then(() => {
                dispatch({
                    type: PO_LINE_ITEM_REMOVED,
                    payload: {
                        currentPoLineItem: null,
                        poLineItems: state.poLineItems.poLineItems.filter((item) => item.id !== poLineItem.id),
                        poLineItemsCount: state.poLineItems.poLineItemsCount - 1,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: PO_LINE_ITEMS_FAILED,
                    payload: {
                        poLineItemsError: error.message,
                    },
                });
            });
    };
};

export const archivePOLineItem = (poLineItem) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: PO_LINE_ITEMS_LOADING,
            payload: {},
        });

        return POLineItemsDataServer.archivePoli(poLineItem.id)
            .then(() => {
                dispatch({
                    type: PO_LINE_ITEM_REMOVED,
                    payload: {
                        currentPoLineItem: null,
                        poLineItems: state.poLineItems.poLineItems.filter((item) => item.id !== poLineItem.id),
                        poLineItemsCount: state.poLineItems.poLineItemsCount - 1,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: PO_LINE_ITEMS_FAILED,
                    payload: {
                        poLineItemsError: error.message,
                    },
                });
            });
    };
};

export const unArchivePOLineItem = (poLineItem) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: PO_LINE_ITEMS_LOADING,
            payload: {},
        });

        return POLineItemsDataServer.unarchivePoli(poLineItem.id)
            .then(() => {
                dispatch({
                    type: PO_LINE_ITEM_REMOVED,
                    payload: {
                        currentPoLineItem: null,
                        poLineItems: state.poLineItems.poLineItems.filter((item) => item.id !== poLineItem.id),
                        poLineItemsCount: state.poLineItems.poLineItemsCount - 1,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: PO_LINE_ITEMS_FAILED,
                    payload: {
                        poLineItemsError: error.message,
                    },
                });
            });
    };
};

export const getForcedSelectedPoLineItem = (projectId, poLineItemId) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await POLineItemsDataServer.getPOLineItems(projectId, {
                poLineItemIds: poLineItemId,
            });
            const forcedSelectedPoLiteItem = data && data[0];
            if (forcedSelectedPoLiteItem) {
                dispatch(updateForcedSelectedPoLineItem(forcedSelectedPoLiteItem));
            }
        } catch (error) {
            dispatch({
                type: PO_LINE_ITEMS_FAILED,
                payload: {
                    poLineItemsError: error.message,
                },
            });
        }
    };
};

export const updateForcedSelectedPoLineItem = (poLiteItem) => {
    return {
        type: PO_LINE_ITEM_FORCED_SELECTED,
        payload: poLiteItem,
    };
};
