import {
    PROJECT_OPENED,
    PROJECTS_CLEAR_FILTERS,
    PROJECTS_FAILED,
    PROJECTS_LOADED,
    PROJECTS_LOADING,
    PROJECTS_RESET,
    PROJECTS_SET_DATE_FILTER,
    PROJECTS_SET_REGION_FILTER,
    PROJECTS_SET_SEARCH_QUERY,
    PROJECTS_SET_STATUS_FILTER,
    PROJECTS_UPDATED,
    PROJECTS_VISIBLE_LOADING,
    SET_LOADING,
    UPDATE_ACTIVE_PROJECT_HISTORY_ORDER,
    UPDATE_ACTIVE_PROJECT_ORDER,
    UPDATE_CURRENT_PROJECT_COMPANY,
    UPDATE_PROJECT_HISTORY_JOBS,
    UPDATE_PROJECT_HISTORY_JOBS_COUNT,
    UPDATE_PROJECT_HISTORY_LOADING,
    UPDATE_PROJECT_SELECTED_TAB,
    UPDATE_RESTRICTED_CUSTOMERS_LIST,
} from "../events/projects";
import { LOGOUT_USER } from "../events/globalEvents";
import _ from "lodash";
import { getActiveOrderParam } from "../helpers/filters";

const initialState = {
    projects: [],
    projectsSearchQuery: undefined,
    projectsStatusFilter: undefined,
    projectsDateFilter: undefined,
    projectsRegionFilter: undefined,
    projectsLoading: false,
    projectsError: null,
    projectsCount: 0,
    currentProject: null,
    projectSelectedManually: null,
    currentProjectCompany: null,
    activeProjectOrder: "-createdDate",
    loading: false,
    projectHistoryJobs: [],
    projectHistoryJobsCount: 0,
    projectHistoryJobsOrder: "-id",
    projectHistoryLoading: false,
    projectSelectedTab: 0,
    restrictedCustomersList: [],
};

export const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROJECTS_LOADING:
            return {
                ...state,
                projectsLoading: true,
                projectsError: null,
            };
        case PROJECTS_VISIBLE_LOADING:
            return {
                ...state,
                projectsVisibleLoading: true,
                projectsError: null,
            };
        case PROJECTS_LOADED:
            return {
                ...state,
                projects: _.unionBy(action.payload.projects, "id"),
                projectsLoading: false,
                projectsVisibleLoading: false,
                projectsError: null,
                projectsCount: action.payload.projectsCount,
                currentProject: state.currentProject || null,
            };
        case PROJECTS_FAILED:
            return {
                ...state,
                projectsLoading: false,
                projectsError: action.payload.projectsError,
            };
        case PROJECTS_UPDATED:
            return {
                ...state,
                projects: _.unionBy(action.payload, "id"),
            };
        case PROJECTS_SET_SEARCH_QUERY:
            return {
                ...state,
                projectsSearchQuery: action.payload,
            };
        case PROJECTS_SET_STATUS_FILTER:
            return {
                ...state,
                projectsStatusFilter: action.payload,
            };
        case PROJECTS_SET_REGION_FILTER: {
            return {
                ...state,
                projectsRegionFilter: action.payload,
            };
        }
        case PROJECTS_SET_DATE_FILTER:
            return {
                ...state,
                projectsDateFilter: action.payload,
            };
        case PROJECTS_CLEAR_FILTERS:
            return {
                ...state,
                projectsStatusFilter: null,
                projectsRegionFilter: null,
            };
        case PROJECTS_RESET:
            return {
                ...initialState,
                projectsSearchQuery: state.projectsSearchQuery,
                activeProjectOrder: state.activeProjectOrder,
                projectsStatusFilter: state.projectsStatusFilter,
                projectsDateFilter: state.projectsDateFilter,
                projectsRegionFilter: state.projectsRegionFilter,
            };
        case LOGOUT_USER:
            return initialState;
        case PROJECT_OPENED:
            return {
                ...state,
                projectsLoading: false,
                currentProject: action.payload.project,
                projectSelectedManually: action.payload.manuallySelected,
            };
        case UPDATE_CURRENT_PROJECT_COMPANY:
            return {
                ...state,
                currentProjectCompany: action.payload,
            };
        case UPDATE_ACTIVE_PROJECT_ORDER: {
            const updatedOrderParam = action.payload.order;
            const activeProjectOrder = state.activeProjectOrder;
            if (activeProjectOrder && updatedOrderParam === activeProjectOrder) {
                return {
                    ...state,
                    activeProjectOrder: `-${updatedOrderParam}`,
                    clearProjectActiveItem: action.payload.clearProjectActiveItem,
                };
            }

            return {
                ...state,
                activeProjectOrder: updatedOrderParam,
                clearProjectActiveItem: action.payload.clearProjectActiveItem,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case UPDATE_PROJECT_HISTORY_JOBS: {
            return {
                ...state,
                projectHistoryJobs: action.payload,
            };
        }
        case UPDATE_PROJECT_HISTORY_JOBS_COUNT: {
            return {
                ...state,
                projectHistoryJobsCount: action.payload,
            };
        }
        case UPDATE_ACTIVE_PROJECT_HISTORY_ORDER: {
            return {
                ...state,
                projectHistoryJobsOrder: getActiveOrderParam(action.payload, state.projectHistoryJobsOrder),
            };
        }
        case UPDATE_PROJECT_HISTORY_LOADING: {
            return {
                ...state,
                projectHistoryLoading: action.payload,
            };
        }
        case UPDATE_PROJECT_SELECTED_TAB: {
            return {
                ...state,
                projectSelectedTab: action.payload,
            };
        }
        case UPDATE_RESTRICTED_CUSTOMERS_LIST: {
            return {
                ...state,
                restrictedCustomersList: action.payload,
            };
        }
        default:
            return state;
    }
};
