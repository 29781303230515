import {
    SETTINGS_PAYLOAD_FAILURE_MESSAGE,
    SETTINGS_PAYLOAD_SUCCESS_MESSAGE,
    SETTINGS_PAYLOAD_UPDATE_PAYLOAD,
    SETTINGS_PAYLOADS_FILTERS_SET,
    SETTINGS_PAYLOADS_LOADING,
    SETTINGS_PAYLOADS_LOADING_FAILURE,
    SETTINGS_PAYLOADS_LOADING_SUCCESS,
} from "../events/payloadSettings";

const initialState = {
    isLoading: false,
    payloadsList: [],
    payloadsListCount: 0,
    payloadsFilters: {},
    success: undefined,
    error: undefined,
};

export const payloadsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_PAYLOADS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SETTINGS_PAYLOADS_LOADING_SUCCESS: {
            const { meta, data } = action.payload;

            return {
                ...state,
                isLoading: false,
                payloadsList: data,
                payloadsListCount: meta.count,
            };
        }
        case SETTINGS_PAYLOADS_LOADING_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SETTINGS_PAYLOADS_FILTERS_SET:
            return {
                ...state,
                payloadsFilters: action.payload,
            };
        case SETTINGS_PAYLOAD_SUCCESS_MESSAGE:
            return {
                ...state,
                success: action.payload,
            };
        case SETTINGS_PAYLOAD_FAILURE_MESSAGE:
            return {
                ...state,
                error: action.payload,
            };
        case SETTINGS_PAYLOAD_UPDATE_PAYLOAD: {
            const updatedPayloadsList = state.payloadsList.map((payload) => {
                if (payload.id === action.payload.id) {
                    return action.payload;
                }

                return payload;
            });

            return {
                ...state,
                payloadsList: updatedPayloadsList,
            };
        }
        default:
            return state;
    }
};

export default payloadsSettingsReducer;
