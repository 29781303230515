import _ from "lodash";

export const TICKET_CATEGORY_NAME = {
    customer: "Customer",
    dropOffName: "Drop-off",
    pickupName: "Pick-up",
    jobId: "Job id",
    jobExtRef: "Job ext. ref",
    jobOwner: "Job owner",
    payloadName: "Payload",
    projectName: "Project",
    dotNumber: "Project DOT number",
    truckName: "Truck",
    ticketId: "Ticket id",
    ticketExtRef: "Ticket ext. ref",
};

export const PREDICTIVE_OPTIONS_SEPARATOR = ";;";
export const MANUALLY_ADDED_OPTIONS_SEPARATOR = ",";
export const PREDICTIVE_OPTIONS_CATEGORY_VALUE_SEPARATOR = "::";

export const cleanOptionWithMinusCharacter = (option) => {
    const minusCharacterIndex = option.indexOf("-");

    try {
        if (minusCharacterIndex !== -1) {
            const prevCharacter = option[minusCharacterIndex - 1];
            const nextCharacter = option[minusCharacterIndex + 1];

            if (prevCharacter !== " " && nextCharacter !== " ") {
                return option.slice(0, minusCharacterIndex);
            }
        }
    } catch {
        return option;
    }

    return option;
};

// to prevent back-end errors:
// 1) Replace all "-" with " " from string
// 2) Remove all special characters
// 3) Remove extra spaces
// 4) Replace all spaces with "+"
export const cleanPredictiveParamOptionString = (optionString) => {
    const cleanedOptionString = cleanOptionWithMinusCharacter(optionString);

    return cleanedOptionString
        .replaceAll("-", " ")
        .replace(/[<>()~*+,"]/g, "") // https://github.com/TruckITllc/truckit-frontend/issues/3106#issuecomment-1169691911
        .replace(/\s+/g, " ") // remove extra spaces
        .trim()
        .replaceAll(" ", "+");
};

// to generate string for predictive search:
// 1) Category name is separated from value with "::"
// 2) Multiple categories are separated form each other with ";;"
// 3) Options that are added manually (not from offered list of search options) are separated from each other with "," and don't have category name
// 4) Every word is separated from each other with "+"
export const generatePredictiveKeywordParam = (options) => {
    if (_.isEmpty(options)) {
        return "";
    }
    const searchOptions = options.map((option) => {
        const { predictiveSearchOptionValue, value, category, __isNew__ } = option;

        if (__isNew__) {
            return cleanPredictiveParamOptionString(`+${value}`);
        }

        return cleanPredictiveParamOptionString(
            `${category}${PREDICTIVE_OPTIONS_CATEGORY_VALUE_SEPARATOR}+${predictiveSearchOptionValue}`,
        );
    });
    const separator = options[0].__isNew__ ? MANUALLY_ADDED_OPTIONS_SEPARATOR : PREDICTIVE_OPTIONS_SEPARATOR;

    return searchOptions.join(separator);
};
