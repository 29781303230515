import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useDrop } from "react-dnd";
import { dropItemTypes } from "../../../helpers/jobOrders";
import { TABLE_SECONDARY } from "../../../styles/reusableStyles";
import _ from "lodash";
import { JO_STATUS_COMPLETED_ID } from "../../../constants/maps";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { DEFAULT_TIME_FORMAT } from "../../../constants/global";
import clsx from "clsx";
import { FieldArray } from "redux-form";
import JobBoardTrucksTable from "./trucksTable";
import {
    DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE,
    ITEM_IS_ALREADY_IN_JOB_ORDER_MESSAGE,
    SELECT_TRUCK_TO_DISPATCH_OVERLAY_MESSAGE,
    SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE,
} from "../../../constants/strings";
import { itemIsInJobOrder } from "../jobOrder/jolis/jolisTableWraper";

const useStyles = makeStyles((theme) => ({
    draggableOverlayWrap: {
        overflow: "hidden",
        position: "relative",
        width: "100%",
        height: "100%",
    },
    draggableOverlay: {
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 2,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    draggableText: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 70,
        width: "80%",
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        padding: 20,
        textAlign: "center",
        borderRadius: theme.shape.borderRadius * 2,
        border: `3px dashed ${theme.palette.primary.main}`,
    },
    trucksTable: {
        "& .no-trucks": {
            position: "relative",

            "& tr td": {
                height: 40,
            },
        },
    },
}));

const renderTrucksTables = ({
    fields,
    jobOrder,
    summaryInfo,
    customFooter,
    startDateName,
    unitOfMeasure,
    specifyNumberOfTrucksHandler,
    joliIndex,
    form,
    joliData,
    showHaulersColumns,
}) => {
    return (
        <JobBoardTrucksTable
            trucks={fields}
            specifyNumberOfTrucks={true}
            jobOrder={jobOrder}
            unitOfMeasure={unitOfMeasure}
            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
            startDateName={startDateName}
            showAsMyFleet={true}
            joliIndex={joliIndex}
            customFooter={customFooter}
            className="job-order-trucks-table"
            summaryInfo={summaryInfo}
            form={form}
            joliData={joliData}
            showHaulersColumns={showHaulersColumns}
            editMode={true}
        />
    );
};

renderTrucksTables.propTypes = {
    fields: PropTypes.object.isRequired,
    showHaulersColumns: PropTypes.bool,
    jobOrder: PropTypes.object.isRequired,
    startDateName: PropTypes.string,
    summaryInfo: PropTypes.object,
    joliIndex: PropTypes.number,
    customFooter: PropTypes.any,
    specifyNumberOfTrucksHandler: PropTypes.func,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    smartDispatch: PropTypes.bool,
};

export const GET_ALL_HAULERS_TRUCKS_COUNT = (haulers) => {
    return haulers.reduce((prev, next) => {
        return prev + next.needTrucksCount;
    }, 0);
};

const JoliTable = (props) => {
    const { current, item, jobOrder, unitOfMeasure, index, specifyNumberOfTrucksHandler, form } = props;
    const { trucks, needTrucksCount, startDate, haulers, notAddressedTrucksCount, savedTrucks } = current;
    const { smartDispatch, status } = jobOrder;
    const countLeftToFill = needTrucksCount;
    const classes = useStyles();
    const [{ canDrop, isOver, draggedItem }, drop] = useDrop({
        accept: dropItemTypes.TRUCK,
        drop: () => ({ name: current.id }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            draggedItem: monitor.getItem(),
        }),
        canDrop: () => {
            return !itemIsInJobOrder(draggedItem, [current]) && !smartDispatch;
        },
    });
    const itemIsAlreadyInJO = itemIsInJobOrder(draggedItem, [current]);
    const haulersRequiredTrucksCount = GET_ALL_HAULERS_TRUCKS_COUNT(haulers);
    const addedTrucks = trucks.length - savedTrucks.length + haulersRequiredTrucksCount;
    const canAssignTrucks = notAddressedTrucksCount
        ? addedTrucks < needTrucksCount
        : trucks.length + haulersRequiredTrucksCount < needTrucksCount;
    const tableStyles = TABLE_SECONDARY();
    const emptyTrucks = new Array(3).fill({});
    const emptyRows = _.isEmpty(trucks) && _.isEmpty(haulers);
    const showRemoveCell =
        !smartDispatch &&
        [...trucks, ...haulers].find((i) => i.isNewAdded || (status !== JO_STATUS_COMPLETED_ID && !i.hasTickets));
    const showHaulersColumns = !_.isEmpty(haulers);

    const renderJoliDraggableOverlayMessage = () => {
        if (smartDispatch) {
            return SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE;
        }

        if (canAssignTrucks && (canDrop || isOver || itemIsAlreadyInJO)) {
            if (itemIsAlreadyInJO) {
                return ITEM_IS_ALREADY_IN_JOB_ORDER_MESSAGE(draggedItem.isTruck);
            }

            return DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE;
        }

        return SELECT_TRUCK_TO_DISPATCH_OVERLAY_MESSAGE;
    };

    return (
        <div key={current.id}>
            <Box className="--text-right" pr={2}>
                Requested{" "}
                <span className="--text-semi-bold">
                    {notAddressedTrucksCount || needTrucksCount}
                    {` `}
                </span>
                for {moment(startDate).format(DEFAULT_TIME_FORMAT)}
            </Box>
            {!!notAddressedTrucksCount && (
                <Box className="--text-right" pr={2}>
                    You can add {needTrucksCount} more
                </Box>
            )}
            <br />
            <div ref={canAssignTrucks ? drop : null} className={classes.draggableOverlayWrap}>
                {(canDrop || isOver || emptyRows || itemIsAlreadyInJO) && (
                    <div className={classes.draggableOverlay}>
                        {canAssignTrucks ? (
                            <p className={classes.draggableText}>{renderJoliDraggableOverlayMessage()}</p>
                        ) : (
                            <p className={classes.draggableText}>
                                You can't assign more trucks then requested.
                                <br />
                                {!notAddressedTrucksCount
                                    ? `For this JO ${needTrucksCount} requested`
                                    : `For this JO ${countLeftToFill} can be added`}
                            </p>
                        )}
                    </div>
                )}
                <table className={clsx(tableStyles.secondaryTable, classes.trucksTable)}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Trucks</th>
                            {showHaulersColumns && <th>Hauler Rate($)</th>}
                            <th className="start-date">Starting</th>
                            {showRemoveCell && <th className="remove" />}
                        </tr>
                    </thead>
                    <tbody className={clsx(emptyRows && "no-trucks")}>
                        <FieldArray
                            name={`${item}.trucks`}
                            form={form}
                            joliData={current}
                            startDateName={`${item}.startDate`}
                            jobOrder={jobOrder}
                            joliIndex={index}
                            unitOfMeasure={unitOfMeasure}
                            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                            customFooter={<tfoot />}
                            showHaulersColumns={showHaulersColumns}
                            component={renderTrucksTables}
                        />
                        <FieldArray
                            name={`${item}.haulers`}
                            form={form}
                            joliData={current}
                            startDateName={`${item}.startDate`}
                            jobOrder={jobOrder}
                            joliIndex={index}
                            unitOfMeasure={unitOfMeasure}
                            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                            customFooter={<tfoot />}
                            showHaulersColumns={showHaulersColumns}
                            component={renderTrucksTables}
                        />
                        {emptyRows &&
                            emptyTrucks.map((i, index) => {
                                return (
                                    <tr key={index}>
                                        <td />
                                        <td />
                                        <td />
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

JoliTable.propTypes = {
    current: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    classes: PropTypes.object,
    specifyNumberOfTrucksHandler: PropTypes.func,
    item: PropTypes.string.isRequired,
    unitOfMeasure: PropTypes.string.isRequired,
    form: PropTypes.string,
};

JoliTable.propTypes = {};

JoliTable.defaultProps = {};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(JoliTable),
);
