import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { FREE } from "../../constants/accounts";
import { USER_ROLE_RESTRICTED_CUSTOMER } from "../../constants/maps";

const upgradeMessageStyles = makeStyles((theme) => ({
    upgradeMessage: {
        backgroundColor: theme.palette.general.darkishRed,
        color: theme.palette.general.lightGrey,
        width: "100vw",
        display: "flex",
        justifyContent: "center",
    },
}));

const UpgradeAccountMessage = () => {
    const accountType = useSelector((state) => state.account.company.accountType);
    const role = useSelector((state) => state.account.role);
    const classes = upgradeMessageStyles();
    if (accountType === FREE && role !== USER_ROLE_RESTRICTED_CUSTOMER) {
        return <Grid className={classes.upgradeMessage}>Call (404) 990-4233 to upgrade to full-access account.</Grid>;
    }

    return null;
};

export default UpgradeAccountMessage;
