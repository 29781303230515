import {
    FLEET_LIST_REQUESTED_DATE_CHANGE,
    IGNORE_WEBSOCKET_JO_DATA,
    JO_CLOSED_NOTIFICATION,
    JO_CREATED_NOTIFICATION,
    JO_DATE_CHANGE,
    JO_DELETED_NOTIFICATION,
    JO_REOPENED_NOTIFICATION,
    JO_SHOW_TABLE_LOADER,
    JO_UPDATE_NOTIFICATION,
    JO_UPDATED_WEBSOCKET_DATA_RECEIVED,
    JOB_ORDER_OPENED,
    JOB_ORDERS_LOADED,
    NEED_UPDATE_JOB_ORDERS,
    NEED_UPDATE_MY_FLEET_LIST_FOR_ADMIN,
    NEW_JOB_ORDER_PAGE_LEAVE,
    REFRESH_JOBS_LIST,
    RESET_ACTIVE_JOB_ORDERS,
    RESET_HAULER_ORDER,
    RESET_JOB_ORDERS_PARAMS,
    SET_JO_MAP_COORDINATES,
    SKIP_JOB_ORDER_INITIAL_LOADING,
    SKIP_TRUCKS_INITIAL_LOADING_FOR_LISTING_DATE,
    UPDATE_ACTIVE_DISPATCHED_TRUCK_ORDER,
    UPDATE_ACTIVE_JOB_ORDER,
    UPDATE_CURRENT_JOB_ORDER_REQUEST,
    UPDATE_HAULER_ORDER,
    UPDATE_JO_FIRST_RENDERED_INITIAL_VALUES,
    UPDATE_JOB_ORDERS_LIST_COUNT,
    UPDATE_MY_FLEET_SELECTED_TRUCKS,
    UPDATE_REDIRECT_JOB_ID,
    UPDATE_REQUESTED_JOB_ORDER_ID,
} from "../events/jobOrders";
import { JO_STATUS_ACTIVE_ID, JO_STATUS_COMPLETED_ID } from "../constants/maps";
import _ from "lodash";
import moment from "moment";
import { LOGOUT_USER } from "../events/globalEvents";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { getActiveOrderParam } from "../helpers/filters";

const initialState = {
    list: [],
    jobOrdersCount: 0,
    coordinatesForTruckId: null,
    jobOrdersNeedsToBeUpdated: false,
    noOrdersLoaded: false,
    currentJobOrder: null,
    getFleetForJoById: undefined,
    showTableLoader: false,
    joDate: undefined,
    joWebsocketData: undefined,
    joWebsocketDataReceivedFlag: false,
    joFirstRenderInitialValues: undefined,
    activeJobOrderParam: null,
    requestedJobOrderId: null,
    newJobOrderPageLeave: false,
    requestFleetDate: undefined,
    skipTrucksInitialListingLoading: false,
    skipJobOrdersInitialLoading: false,
    activeTruckDispatchedParam: "name",
    activeHaulerParam: "name",
    myFleetSelectedTrucks: [],
    ignoreJoWebsocketData: false,
    currentJobOrderRequest: null,
    refreshing: false,
    redirectJobId: null,
};

export const jobOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case JOB_ORDERS_LOADED:
            return {
                ...state,
                list: action.jobOrders,
                noOrdersLoaded: _.isEmpty(action.jobOrders),
                jobOrdersNeedsToBeUpdated: false,
            };
        case UPDATE_JOB_ORDERS_LIST_COUNT:
            return {
                ...state,
                jobOrdersCount: action.payload,
            };
        case UPDATE_REQUESTED_JOB_ORDER_ID:
            return {
                ...state,
                requestedJobOrderId: action.payload,
            };
        case JOB_ORDER_OPENED:
            return {
                ...state,
                currentJobOrder: action.jobOrderId,
            };
        case NEED_UPDATE_MY_FLEET_LIST_FOR_ADMIN:
            return {
                ...state,
                getFleetForJoById: action.joId || null,
            };
        case NEED_UPDATE_JOB_ORDERS:
            return {
                ...state,
                jobOrdersNeedsToBeUpdated: true,
            };
        case SET_JO_MAP_COORDINATES:
            return {
                ...state,
                coordinatesForTruckId: action.truckId,
            };
        case JO_CLOSED_NOTIFICATION:
            return {
                ...state,
                list: state.list.map((jo) =>
                    jo.id === action.payload.id
                        ? {
                              ...jo,
                              status: JO_STATUS_COMPLETED_ID,
                              canBeReopened: action.payload.job.canBeReopened,
                              canBeRemoved: action.payload.job.canBeRemoved,
                          }
                        : jo,
                ),
            };
        case JO_REOPENED_NOTIFICATION:
            return {
                ...state,
                list: state.list.map((jo) =>
                    jo.id === action.payload
                        ? {
                              ...jo,
                              status: JO_STATUS_ACTIVE_ID,
                          }
                        : jo,
                ),
            };
        case JO_DELETED_NOTIFICATION:
            return {
                ...state,
                list: state.list.reduce((list, jo) => {
                    if (jo.id !== action.payload.id) list.push(jo);

                    return list;
                }, []),
            };
        case JO_CREATED_NOTIFICATION:
            return {
                ...state,
                list: [...action.payload, ...state.list],
                currentJobOrder:
                    !state.currentJobOrder && !_.isEmpty(action.payload) ? action.payload[0].id : state.currentJobOrder,
                noOrdersLoaded: _.isEmpty(action.payload),
            };
        case JO_UPDATE_NOTIFICATION:
            return {
                ...state,
                list: _.cloneDeep(state.list).map((jo) => {
                    if (jo.id === action.payload.id) {
                        return action.payload;
                    }

                    return jo;
                }),
            };
        case JO_SHOW_TABLE_LOADER:
            return {
                ...state,
                showTableLoader: action.show,
            };
        case JO_DATE_CHANGE:
            return {
                ...state,
                joDate: action.date && moment(action.date).format(SERVER_DATE_FORMAT),
            };
        case FLEET_LIST_REQUESTED_DATE_CHANGE:
            return {
                ...state,
                requestFleetDate: action.date && moment(action.date).format(SERVER_DATE_FORMAT),
            };
        case NEW_JOB_ORDER_PAGE_LEAVE:
            return {
                ...state,
                newJobOrderPageLeave: action.payload,
            };
        case JO_UPDATED_WEBSOCKET_DATA_RECEIVED:
            return {
                ...state,
                joWebsocketData: action.joData,
                joWebsocketDataReceivedFlag: !state.joWebsocketDataReceivedFlag,
            };
        case IGNORE_WEBSOCKET_JO_DATA:
            return {
                ...state,
                ignoreJoWebsocketData: action.payload,
            };
        case UPDATE_ACTIVE_JOB_ORDER: {
            const updatedOrderParam = action.payload.order;
            const initialParam = action.payload.hardParam;
            const activeJobOrderParam = state.activeJobOrderParam;
            if (initialParam) {
                return {
                    ...state,
                    activeJobOrderParam: initialParam,
                };
            }
            if (activeJobOrderParam && updatedOrderParam === activeJobOrderParam) {
                return {
                    ...state,
                    activeJobOrderParam: `-${updatedOrderParam}`,
                };
            }

            return {
                ...state,
                activeJobOrderParam: updatedOrderParam,
            };
        }
        case RESET_ACTIVE_JOB_ORDERS:
            return {
                ...state,
                activeJobOrderParam: action.payload,
            };
        case UPDATE_JO_FIRST_RENDERED_INITIAL_VALUES:
            return {
                ...state,
                joFirstRenderInitialValues: action.payload,
            };
        case SKIP_TRUCKS_INITIAL_LOADING_FOR_LISTING_DATE:
            return {
                ...state,
                skipTrucksInitialListingLoading: action.payload,
            };
        case SKIP_JOB_ORDER_INITIAL_LOADING:
            return {
                ...state,
                skipJobOrdersInitialLoading: action.payload,
            };
        case UPDATE_ACTIVE_DISPATCHED_TRUCK_ORDER: {
            return {
                ...state,
                activeTruckDispatchedParam: getActiveOrderParam(action.payload, state.activeTruckDispatchedParam),
            };
        }
        case UPDATE_HAULER_ORDER: {
            return {
                ...state,
                activeHaulerParam: getActiveOrderParam(action.payload, state.activeHaulerParam),
            };
        }
        case RESET_HAULER_ORDER: {
            return {
                ...state,
                activeHaulerParam: initialState.activeHaulerParam,
            };
        }
        case RESET_JOB_ORDERS_PARAMS: {
            return {
                ...state,
                activeTruckDispatchedParam: initialState.activeTruckDispatchedParam,
            };
        }
        case UPDATE_MY_FLEET_SELECTED_TRUCKS: {
            return {
                ...state,
                myFleetSelectedTrucks: action.payload,
            };
        }
        case UPDATE_CURRENT_JOB_ORDER_REQUEST: {
            return {
                ...state,
                currentJobOrderRequest: action.payload,
            };
        }

        case REFRESH_JOBS_LIST: {
            return {
                ...state,
                refreshing: action.payload,
            };
        }

        case UPDATE_REDIRECT_JOB_ID: {
            return {
                ...state,
                redirectJobId: action.payload,
            };
        }

        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
