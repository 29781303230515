import {
    TRUCKS_ACTIVITY_SET_ACTIVE_TRUCK,
    TRUCKS_ACTIVITY_SET_MAP_DATA,
    TRUCKS_ACTIVITY_SET_NOTIFICATION_SELECTED_TRUCK,
    TRUCKS_ACTIVITY_SET_TIMESTAMP_FOR_MAP,
} from "../events/trucksActivityActions";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    timestampForMap: null,
    activeTruckId: null,
    activeTruckMapData: [],
    notificationSelectedTruckId: null,
};

export const trucksActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRUCKS_ACTIVITY_SET_TIMESTAMP_FOR_MAP:
            return {
                ...state,
                timestampForMap: action.time,
            };
        case TRUCKS_ACTIVITY_SET_MAP_DATA:
            return {
                ...state,
                activeTruckMapData: action.mapData,
            };
        case TRUCKS_ACTIVITY_SET_ACTIVE_TRUCK:
            return {
                ...state,
                activeTruckId: action.truckId,
            };
        case TRUCKS_ACTIVITY_SET_NOTIFICATION_SELECTED_TRUCK:
            return {
                ...state,
                notificationSelectedTruckId: action.payload,
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
