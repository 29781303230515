import React from "react";
import { change, formValueSelector, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { updateDropOffNotifications } from "../../actions/notifications";
import { Validation } from "../../helpers/validation";
import { useErrorNotification } from "../../hooks";
import useSuccessNotification from "../../hooks/useSuccessNotification";
import { SETTINGS_NOTIFICATIONS_FORM } from "./notifications";
import { RECIPIENTS_TITLE } from "../../constants/settings";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import { AppModal, ButtonsGroup, PrimaryButton, SecondaryButton } from "../core";
import { DEFAULT_NOTIFICATIONS_RECIPIENTS_LABEL } from "./constants/strings";
import { updateAccountData } from "../../helpers/global";
import { authorizeUser } from "../../actions/globalActions";
import { ACCOUNT_NOTIFICATION_PROFILE_KEY } from "../../constants/global";

const DefaultDropOffNotificationsRecipientsModal = ({
    isOpen,
    onClose,
    handleSubmit,
    dropOffRecipients,
    dispatch,
    onFormCancel,
}) => {
    const showError = useErrorNotification();
    const showSuccess = useSuccessNotification();

    const onFormSubmit = async ({ dropOffRecipients }) => {
        try {
            const { data } = await dispatch(
                updateDropOffNotifications(
                    dropOffRecipients && dropOffRecipients.map((recipient) => recipient.value),
                    true,
                ),
            );
            updateAccountData(
                data,
                (updatedData) => dispatch(authorizeUser(updatedData)),
                ACCOUNT_NOTIFICATION_PROFILE_KEY,
            );
            dispatch(change(SETTINGS_NOTIFICATIONS_FORM, "dropOffRecipients", dropOffRecipients));
            onClose();
            showSuccess("Settings are updated");
        } catch (error) {
            showError(error);
        }
    };

    return (
        <AppModal
            isOpen={isOpen}
            form={DEFAULT_DROP_OFF_RECIPIENTS_FORM}
            closeModal={onFormCancel}
            modalStyles={{ width: 800, paddingBottom: 50 }}
        >
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Grid container justify="center" spacing={3}>
                    <Grid item>
                        <h1 className="--text-center">{RECIPIENTS_TITLE}</h1>
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{ marginBottom: 10 }}>
                            <DispatchersFieldSelector
                                isMulti={true}
                                name="dropOffRecipients"
                                label={DEFAULT_NOTIFICATIONS_RECIPIENTS_LABEL}
                                loadedList={dropOffRecipients}
                                validate={[Validation.required]}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <ButtonsGroup>
                            <SecondaryButton type="button" onClick={onFormCancel}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton type="submit">Save</PrimaryButton>
                        </ButtonsGroup>
                    </Grid>
                </Grid>
            </form>
        </AppModal>
    );
};

export const DEFAULT_DROP_OFF_RECIPIENTS_FORM = "DEFAULT_DROP_OFF_RECIPIENTS_FORM";

export default compose(
    reduxForm({
        form: DEFAULT_DROP_OFF_RECIPIENTS_FORM,
    }),
    connect((state, props) => {
        const formSelector = formValueSelector(DEFAULT_DROP_OFF_RECIPIENTS_FORM);

        return {
            dropOffRecipients: formSelector(state, "dropOffRecipients"),
        };
    }),
)(DefaultDropOffNotificationsRecipientsModal);
