import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import NavigationIcon from "@material-ui/icons/Navigation";
import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const styles = () => ({
    scrollToTop: {
        position: "fixed",
        bottom: "10%",
        right: "4%",
        opacity: 0,
        zIndex: 100,
        transition: "opacity 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

        "&.-show": {
            opacity: 1,
            transition: "opacity 300ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        },
    },
});

class JumpToTopButton extends Component {
    constructor(props) {
        super(props);

        this.state = { showButton: null };
    }

    componentDidMount() {
        var lastScrollTop = 0;

        if (document.documentElement.scrollTop > 200) {
            this.setState({ showButton: true });
        }

        document.addEventListener(
            "scroll",
            () => {
                const st = window.pageYOffset || document.documentElement.scrollTop;

                if (st > lastScrollTop || document.documentElement.scrollTop === 0) {
                    this.setState({ showButton: false });
                } else {
                    this.setState({ showButton: true });
                }

                lastScrollTop = st <= 0 ? 0 : st;
            },
            false,
        );
    }

    jumpToTop = () => {
        window.scrollTo(0, document.offsetTop);
    };

    render() {
        const { showButton } = this.state;
        const { classes } = this.props;

        return (
            <Fab onClick={this.jumpToTop} className={clsx(classes.scrollToTop, showButton && "-show")}>
                <NavigationIcon />
            </Fab>
        );
    }
}

JumpToTopButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(JumpToTopButton));
