import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreActions from "../../core/moreActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DescriptionIcon from "@material-ui/icons/Description";
import MenuItem from "@material-ui/core/MenuItem";
import {
    closeJobOrderById,
    deleteJobOrderById,
    getJobBoardJobOrderJoli,
    getJobOrderById,
    reopenJobOrderById,
    updateCopySchedule,
} from "../../../dataServers/jobOrder";
import { IS_ADMIN_USER, JO_STATUS_COMPLETED_ID } from "../../../constants/maps";
import ErrorNotification from "../../core/notification";
import RepeatIcon from "@material-ui/icons/Repeat";
import clsx from "clsx";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { connect, useDispatch } from "react-redux";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import ConfirmationModal from "../../core/confirmationModal";
import Loader from "../../core/loader";
import { JOLI_FIELD_ID } from "../constants";
import { getJobOrderEditRoute } from "../../../helpers/global";
import { processTrailers } from "../../../helpers/jobOrders";
import { changeInitialJobOrdersListLoadingSkip, changeInitialTrucksListLoadingSkip } from "../../../actions/jobOrders";
import { Delete } from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";
import { SPLIT_ICON } from "../../../styles/icons";
import AppModal from "../../core/modal";
import SplitJobForm, { SPLIT_JOB_FORM } from "./splitJobForm";
import { selectUsesSplitJob } from "../../../selectors";
import { selectIfIsRestrictedCustomer } from "../../../selectors/user";

export const showMoreActions = (jobOrder, account) => {
    const isJoClosed = +jobOrder.status === JO_STATUS_COMPLETED_ID;

    return (
        (jobOrder.iAmOwner || IS_ADMIN_USER(account.company.id) || account.isSuperUser) &&
        ((isJoClosed && jobOrder.canBeReopened) || !isJoClosed || jobOrder.iAmOwner)
    );
};

export const deleteAddedFromSdFieldAfterCopy = (joli) => {
    return joli.map((item) => {
        const updatedItemTrucks = item.trucks?.map((truck) => {
            return { ...truck, addedFromSd: false };
        });
        const updatedItemHaulers = item.haulers?.map((hauler) => {
            const updatedHaulerTrucks = hauler.trucks?.map((truck) => {
                return { ...truck, addedFromSd: false };
            });
            return { ...hauler, addedFromSd: false, trucks: updatedHaulerTrucks };
        });

        return { ...item, trucks: updatedItemTrucks, haulers: updatedItemHaulers };
    });
};

const JobOrderMoreActions = (props) => {
    const dispatch = useDispatch();

    const {
        jobOrder,
        updateJoStatus,
        updateJoCopySchedule,
        displayAsButtons,
        history,
        showParentLoader,
        account,
        deleteJoById,
        deleteJOHandler,
        setActive,
        isJobBoardPage,
        buttonClassName,
        usesSplitJob,
        isRestrictedCustomer,
    } = props;
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [showConfirmationModal, setShowConfirmationModal] = useState(null);
    const [showSplitJobModal, setShowSplitJobModal] = useState(false);
    const [deleteJobOrderActionSelected, setDeleteJobOrderActionSelected] = useState(false);
    const [isLoadingCopy, setLoadingCopy] = useState();
    const [isLoadingTemplate, setLoadingTemplate] = useState();
    const [isLoadingStatus, setLoadingStatus] = useState();

    const isDispatchPage = history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);

    const showLoader = (setLoading) => {
        setLoading(true);
        setError(null);
        setMessage(null);
        setShowConfirmationModal("");
        showParentLoader && showParentLoader(true);
    };
    const showCloseJobConfirmation = (e) => {
        e && e.stopPropagation();

        setShowConfirmationModal("Are you sure you want to close this job?");
    };
    const showDeleteJobConfirmation = (e) => {
        e && e.stopPropagation();

        setDeleteJobOrderActionSelected("Are you sure you want to delete this job?");
    };
    const showTemplate = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingTemplate);

        if (isDispatchPage) {
            setActive({ ...jobOrder, id: jobOrder.templateId });
        } else {
            history.push(getJobOrderEditRoute(jobOrder.templateId));
        }

        setLoadingTemplate(false);
    };
    const updateTemplateStatus = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingStatus);
        showParentLoader && showParentLoader(true);

        const body = {
            copySchedule: {
                ...jobOrder.copySchedule,
                enabled: !jobOrder.copySchedule.enabled,
            },
        };

        updateCopySchedule(jobOrder.id, body)
            .then((template) => {
                const { copySchedule } = template.data;
                updateJoCopySchedule && updateJoCopySchedule(jobOrder.id, copySchedule);
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            });
    };
    const closeJobOrder = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingStatus);
        showParentLoader && showParentLoader(true);

        closeJobOrderById(jobOrder.id)
            .then(() => {
                updateJoStatus && updateJoStatus(jobOrder.id, JO_STATUS_COMPLETED_ID);
                isJobBoardPage && deleteJoById && deleteJoById();
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            });
    };
    const deleteJobOrder = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingStatus);
        showParentLoader && showParentLoader(true);

        deleteJobOrderById(jobOrder.id)
            .then(() => {
                deleteJOHandler && deleteJOHandler();
                deleteJoById && deleteJoById(jobOrder.id);
                setLoadingStatus(false);
                setDeleteJobOrderActionSelected(null);
                showParentLoader && showParentLoader(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            });
    };
    const copyJobOrder = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingCopy);

        Promise.all([getJobOrderById(jobOrder.id), getJobBoardJobOrderJoli(jobOrder.id)])
            .then(([{ data }, { data: joli }]) => {
                const updatedJoli = deleteAddedFromSdFieldAfterCopy(joli);
                const copyJobOrderData = _.cloneDeep({
                    ...jobOrder,
                    ...data,
                    [JOLI_FIELD_ID]: updatedJoli,
                    ...processTrailers(joli),
                });

                if (history.location.pathname.includes(ROUTE_JOBS.MY_JOBS)) {
                    // https://github.com/TruckITllc/truckit-frontend/issues/2177#issuecomment-924027066
                    dispatch(changeInitialTrucksListLoadingSkip(true));
                    // https://github.com/TruckITllc/truckit-frontend/issues/2382
                    dispatch(changeInitialJobOrdersListLoadingSkip(true));
                }

                showParentLoader && showParentLoader(false);
                history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS, {
                    jobOrder: {
                        ...copyJobOrderData,
                        isCopy: true,
                        isPastJob: false,
                        isSplit: false,
                        nextJobOrderId: null,
                        previousJobOrderId: null,
                    },
                });

                setLoadingCopy(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoadingCopy(false);
                showParentLoader && showParentLoader(false);
            });
    };

    const reopenJobOrder = (e) => {
        e && e.stopPropagation();

        showLoader(setLoadingStatus);

        reopenJobOrderById(jobOrder.id)
            .then(({ data }) => {
                showParentLoader && showParentLoader(false);
                updateJoStatus && updateJoStatus(jobOrder.id, data.status);
                setLoadingStatus(false);
                setError(null);
                setMessage(null);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoadingStatus(false);
                showParentLoader && showParentLoader(false);
            });
    };

    const deleteNoHandler = (event) => {
        event && event.stopPropagation();
        setDeleteJobOrderActionSelected(null);
    };

    const closeOrUpdateNoHandler = (event) => {
        event && event.stopPropagation();
        setShowConfirmationModal(null);
    };
    const handleSplitJobClick = (e) => {
        e.stopPropagation();
        setShowSplitJobModal(true);
    };
    const isJoClosed = +jobOrder.status === JO_STATUS_COMPLETED_ID;

    if (isRestrictedCustomer) {
        return null;
    }

    if (isJobBoardPage) {
        return (
            <React.Fragment>
                {message && (
                    <ErrorNotification message={message} type="success" config={{ onClose: () => setMessage(null) }} />
                )}
                <MoreActions isLoading={isLoadingCopy} className={clsx("job-order-more-actions")}>
                    {(!isJoClosed || (jobOrder.copySchedule && jobOrder.copySchedule.enabled)) && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => showCloseJobConfirmation(event)}
                            component="div"
                        >
                            <HighlightOffIcon />
                            <span>Close Job</span>
                        </MenuItem>
                    )}
                </MoreActions>
                <ConfirmationModal
                    isOpen={!!showConfirmationModal}
                    question={showConfirmationModal}
                    yesHandler={closeJobOrder}
                    noHandler={closeOrUpdateNoHandler}
                />
            </React.Fragment>
        );
    }

    return showMoreActions(jobOrder, account) ? (
        <React.Fragment>
            {message && (
                <ErrorNotification message={message} type="success" config={{ onClose: () => setMessage(null) }} />
            )}
            {error && <ErrorNotification message={error} config={{ onClose: () => setError(null) }} />}
            {!displayAsButtons ? (
                <MoreActions isLoading={isLoadingCopy} className={clsx("job-order-more-actions")}>
                    {jobOrder.iAmOwner && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => copyJobOrder(event)}
                            component="div"
                        >
                            <FileCopyIcon />
                            <span>Copy Job</span>
                        </MenuItem>
                    )}
                    {jobOrder.canBeSplitted && !jobOrder.isSplit && usesSplitJob && (
                        <MenuItem className="more-actions-item" onClick={handleSplitJobClick} component="div">
                            <Icon style={SPLIT_ICON} />
                            <span>Split Job</span>
                        </MenuItem>
                    )}
                    {isJoClosed && jobOrder.canBeReopened && !jobOrder.copySchedule && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => reopenJobOrder(event)}
                            component="div"
                        >
                            <RepeatIcon />
                            <span>Reopen Job</span>
                        </MenuItem>
                    )}
                    {jobOrder.copySchedule && !jobOrder.copySchedule.enabled && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => updateTemplateStatus(event)}
                            component="div"
                        >
                            <RepeatIcon />
                            <span>Reopen Job</span>
                        </MenuItem>
                    )}
                    {!isJoClosed && !jobOrder.copySchedule && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => showCloseJobConfirmation(event)}
                            component="div"
                        >
                            <HighlightOffIcon />
                            <span>Close Job</span>
                        </MenuItem>
                    )}
                    {jobOrder.copySchedule && jobOrder.copySchedule.enabled && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => showCloseJobConfirmation(event)}
                            component="div"
                        >
                            <HighlightOffIcon />
                            <span>Close Job</span>
                        </MenuItem>
                    )}
                    {jobOrder.canBeRemoved && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => showDeleteJobConfirmation(event)}
                            component="div"
                        >
                            <Delete />
                            <span>Delete Job</span>
                        </MenuItem>
                    )}
                    {jobOrder.templateId && (
                        <MenuItem
                            className="more-actions-item"
                            onClick={(event) => showTemplate(event)}
                            component="div"
                        >
                            <DescriptionIcon />
                            <span>Show Template</span>
                        </MenuItem>
                    )}
                </MoreActions>
            ) : (
                <React.Fragment>
                    {jobOrder.iAmOwner && (
                        <SecondaryButton
                            className={clsx(
                                "more-actions-item",
                                isLoadingCopy && LOADER_WHITE_OVERLAY,
                                buttonClassName,
                            )}
                            onClick={copyJobOrder}
                        >
                            {isLoadingCopy && <Loader />}
                            Copy Job
                        </SecondaryButton>
                    )}
                    {isJoClosed && jobOrder.canBeReopened && !jobOrder.copySchedule && (
                        <SecondaryButton
                            className={clsx(
                                "more-actions-item",
                                isLoadingStatus && LOADER_WHITE_OVERLAY,
                                buttonClassName,
                            )}
                            onClick={reopenJobOrder}
                        >
                            {isLoadingStatus && <Loader />}
                            Reopen Job
                        </SecondaryButton>
                    )}
                    {jobOrder && jobOrder.copySchedule && !jobOrder.copySchedule.enabled && (
                        <SecondaryButton
                            className={clsx(
                                "more-actions-item",
                                isLoadingStatus && LOADER_WHITE_OVERLAY,
                                buttonClassName,
                            )}
                            onClick={updateTemplateStatus}
                        >
                            {isLoadingStatus && <Loader />}
                            Reopen Job
                        </SecondaryButton>
                    )}
                    {jobOrder.copySchedule && jobOrder.copySchedule.enabled && (
                        <SecondaryButton
                            className={clsx(
                                "more-actions-item",
                                isLoadingStatus && LOADER_WHITE_OVERLAY,
                                buttonClassName,
                            )}
                            onClick={showCloseJobConfirmation}
                        >
                            {isLoadingStatus && <Loader />}
                            Close Job
                        </SecondaryButton>
                    )}
                    {!isJoClosed && !jobOrder.copySchedule && (
                        <SecondaryButton
                            className={clsx(
                                "more-actions-item",
                                isLoadingStatus && LOADER_WHITE_OVERLAY,
                                buttonClassName,
                            )}
                            onClick={showCloseJobConfirmation}
                        >
                            {isLoadingStatus && <Loader />}
                            Close Job
                        </SecondaryButton>
                    )}
                </React.Fragment>
            )}
            <ConfirmationModal
                isOpen={!!showConfirmationModal}
                question={showConfirmationModal}
                yesHandler={jobOrder.copySchedule ? updateTemplateStatus : closeJobOrder}
                noHandler={closeOrUpdateNoHandler}
            />
            <ConfirmationModal
                isOpen={!!deleteJobOrderActionSelected}
                question={deleteJobOrderActionSelected}
                yesHandler={deleteJobOrder}
                noHandler={deleteNoHandler}
            />
            <AppModal
                isOpen={showSplitJobModal}
                form={SPLIT_JOB_FORM}
                closeModal={() => setShowSplitJobModal(false)}
                modalStyles={{ width: 550 }}
                handleFormWrapperClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <SplitJobForm
                    handleClose={() => setShowSplitJobModal(false)}
                    setActive={setActive}
                    jobOrderId={jobOrder.id}
                />
            </AppModal>
        </React.Fragment>
    ) : null;
};

JobOrderMoreActions.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    deleteJoById: PropTypes.func,
    updateJoStatus: PropTypes.func,
    updateJoCopySchedule: PropTypes.func,
    handleJoCopy: PropTypes.func,
    showParentLoader: PropTypes.func,
    deleteJOHandler: PropTypes.func,
    displayAsButtons: PropTypes.bool.isRequired,
};

JobOrderMoreActions.defaultProps = {
    displayAsButtons: false,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            usesSplitJob: selectUsesSplitJob(state),
            isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
        };
    })(JobOrderMoreActions),
);
