import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import { Field, FieldArray } from "redux-form";
import { connect, useSelector } from "react-redux";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import TruckPillWrapper from "../../../core/pills";
import TruckItTimePicker from "../../../core/form/timePicker";
import { Validation, VALIDATION_ERRORS } from "../../../../helpers/validation";
import TextInputComponent from "../../../core/form/textInputComponent";
import { TABLE_SECONDARY } from "../../../../styles/reusableStyles";
import { noTrucksInJobOrder } from "../../../../helpers/jobOrders";
import { findSameHaulerWithSameTime, jobOrderDisabled } from "../jobOrderForm";
import { JO_STATUS_COMPLETED_ID, UNIT_OF_MEASURE_LOAD } from "../../../../constants/maps";
import { THEME_ICONS } from "../../../../styles/icons";
import CustomSwitch from "../../../core/form/customSwitch";
import { GET_ALL_HAULERS_TRUCKS_COUNT } from "../../jobBoard/joliTable";
import { selectAllSubCompaniesIdList } from "../../../../reducers/subCompaniesReducer";

export const JoliTablesStyles = makeStyles((theme) => ({
    table: {
        minWidth: 695,
    },
    bigTable: {
        minWidth: 700,
    },
    header: {
        fontSize: "inherit",
        textAlign: " center",
        fontWeight: "inherit",
        color: "rgba(0, 0, 0, 0.87)",
    },
    customAllowSubHaulers: {
        width: "10% !important",
        [theme.breakpoints.down("1625")]: {
            padding: "0 !important",
        },
    },
    subTrucksWrapper: {
        "& .sub-trucks-pill": {
            textAlign: "left",
        },
    },
    trucksHeader: {
        textTransform: "none",
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        height: 30,
        paddingTop: 0,
        paddingBottom: 0,

        "& .wrap-icon": {
            backgroundColor: "#fff",
        },
    },
    shortTruck: {
        width: "80%",
    },
    truckWithHauler: {
        padding: "4px 12px",
    },
    allowSubHaulers: {
        width: "10%",
    },
    truckIcon: {
        ...THEME_ICONS.generalTruck,
        top: -1,
        position: "relative",
    },
}));

export const IS_SUB_HAULER = (hauler) => {
    return hauler.assignedBy && hauler.assignedBy.id !== hauler.ownerCompanyId;
};

export const GET_HAULER_NEED_TRUCKS_COUNT = (currentHauler = {}, haulers = []) => {
    const subHauler = IS_SUB_HAULER({
        ...currentHauler,
        ownerCompanyId: currentHauler.ownerCompanyId,
    });
    const currentHaulerAllSubHaulers = haulers.filter((i) => i.assignedBy && i.assignedBy.id === currentHauler.id);
    const subHaulersTrucksCount = subHauler
        ? currentHauler.needTrucksCount
        : GET_ALL_HAULERS_TRUCKS_COUNT(currentHaulerAllSubHaulers);
    const result = subHauler ? subHaulersTrucksCount : currentHauler.needTrucksCount - subHaulersTrucksCount;

    return result;
};

const TruckRow = (props) => {
    const {
        form,
        specifyNumberOfTrucksHandler,
        truckStartDateWasChanged,
        joli,
        unlimited,
        joliIndex,
        isMyFleetTruck,
        showRemoveCell,
        removeTruckById,
        fields,
        haulers,
        trucks,
        unitOfMeasure,
        specifyTrucksPerHauler,
        jobOrder,
        joliData,
        showHaulersColumns,
        allJolis,
        account,
        pastJobOrderDateSelected,
    } = props;
    const classes = JoliTablesStyles();
    const items = fields.getAll();
    const subCompaniesIdList = useSelector(selectAllSubCompaniesIdList);
    const [showTip, setShowTip] = useState(false);
    const subHaulingWasChanged = (checked, haulerId) => {
        if (!checked) {
            const hasSubHaulersAssigned = allJolis.find(
                (i) => i.haulers && i.haulers.find((h) => h.assignedBy && h.assignedBy.id === haulerId),
            );
            setShowTip(!!hasSubHaulersAssigned);
        } else {
            setShowTip(false);
        }
    };
    const handleTooltipClose = () => {
        setShowTip(false);
    };

    return fields.map((field, truckIndex) => {
        const current = fields.get(truckIndex);
        current.haulerRate = current.haulerRate || current.defaultRate;
        const showQty =
            unitOfMeasure === UNIT_OF_MEASURE_LOAD ||
            (isMyFleetTruck
                ? _.isEmpty(haulers) && truckIndex === trucks.length - 1
                : truckIndex === haulers.length - 1);
        const sameHaulerWithSameTime = findSameHaulerWithSameTime(trucks, current, truckIndex);
        const jobDisabled = jobOrderDisabled(jobOrder, account);
        const canRemove = () => {
            if (current.addedFromSd) {
                return false;
            }
            return jobDisabled ? current.isNewAdded || jobOrder.copySchedule?.enabled : !current.hasTickets;
        };

        const subHauler = IS_SUB_HAULER({
            ...current,
            ownerCompanyId: jobOrder.company && jobOrder.company.id,
        });
        const needTrucksCount = GET_HAULER_NEED_TRUCKS_COUNT(
            {
                ...current,
                ownerCompanyId: jobOrder.company && jobOrder.company.id,
            },
            items,
        );

        const handleDateChange = (date) => {
            truckStartDateWasChanged(
                {
                    ...current,
                    startDate: date,
                    isMyFleetTruck,
                },
                joliIndex,
                truckIndex,
                joliData,
            );
        };

        return (
            <tr key={truckIndex}>
                <td colSpan={2}>
                    <TruckPillWrapper
                        form={form}
                        haulerName={current.truckCompany}
                        className={clsx(sameHaulerWithSameTime && "-alert-truck")}
                        title={
                            sameHaulerWithSameTime
                                ? VALIDATION_ERRORS.sameTruckTime
                                : isMyFleetTruck
                                ? current.truckName
                                : current.name
                        }
                        specifyNumberOfTrucks={specifyTrucksPerHauler}
                        specifyNumberOfTrucksHandler={(add) =>
                            specifyNumberOfTrucksHandler && specifyNumberOfTrucksHandler(add, joliIndex, truckIndex)
                        }
                        allowButtons={current.assignedBy && current.assignedBy.id === account.company.id}
                        needTrucksCount={specifyTrucksPerHauler ? needTrucksCount : joliData.notAddressedTrucksCount}
                        truckName={isMyFleetTruck ? current.truckName : current.name}
                        isMyFleet={isMyFleetTruck}
                        truckId={current.id || current.truckId}
                        jobDisabled={jobDisabled}
                    />

                    {!_.isEmpty(current.trucks) && (
                        <div className={clsx(classes.subTrucksWrapper, "sub-trucks-wrap")}>
                            {current.trucks.map((subTruck) => {
                                const { truckName, truckId } = subTruck;

                                return (
                                    <div key={truckId} className="sub-trucks-pill">
                                        <TruckPillWrapper
                                            allJolis={allJolis}
                                            joliIndex={joliIndex}
                                            isOfflineHaulerTruck={subCompaniesIdList.includes(current.id)}
                                            form={form}
                                            title={truckName}
                                            showIcon={false}
                                            specifyNumberOfTrucks={false}
                                            iconContent={<LocalShippingIcon />}
                                            className="sub-truck"
                                            truckName={truckName}
                                            truckId={truckId}
                                            haulerId={current.id}
                                            isMyFleet={false}
                                            jobDisabled={jobDisabled}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </td>
                {showHaulersColumns && (
                    <td>
                        {!isMyFleetTruck && !subHauler && (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipClose}
                                    open={showTip}
                                    placement={"top"}
                                    disableFocusListener
                                    disableTouchListener
                                    title={
                                        !specifyTrucksPerHauler
                                            ? "Subhauling only allowed if Trucks Per Hauler is turned on."
                                            : "Please note SubHaulers were added by this hauler"
                                    }
                                >
                                    <Field
                                        name={`${field}.allowSubhaulers`}
                                        type="checkbox"
                                        disabled={jobDisabled || !specifyTrucksPerHauler || pastJobOrderDateSelected}
                                        onMouseEnter={() => {
                                            !specifyTrucksPerHauler && setShowTip(true);
                                        }}
                                        onChange={(e) => subHaulingWasChanged(e.target.checked, current.id)}
                                        component={CustomSwitch}
                                    />
                                </Tooltip>
                            </ClickAwayListener>
                        )}
                    </td>
                )}
                {showHaulersColumns && (
                    <td className="--text-center">
                        {!isMyFleetTruck && !subHauler && (
                            <Field
                                name={`${field}.haulerRate`}
                                type="number"
                                className={clsx("hauler-rate-field", "job-order-input-field")}
                                style={{ width: "80px" }}
                                fullWidth={false}
                                component={TextInputComponent}
                            />
                        )}
                    </td>
                )}
                <td colSpan={2} className="start-date">
                    <Field
                        name={`${field}.startDate`}
                        required
                        handleDateChange={(date) => handleDateChange(date)}
                        disabled={current.hasTickets || jobDisabled || subHauler}
                        component={TruckItTimePicker}
                    />
                </td>

                {!unlimited && unitOfMeasure === UNIT_OF_MEASURE_LOAD && (
                    <td className="--text-center">
                        {showQty && (
                            <Field
                                name={`${joli}.quantity`}
                                validate={[Validation.required]}
                                type="number"
                                placeholder="Qty"
                                fullWidth={false}
                                disabled={jobDisabled}
                                className="job-order-input-field"
                                needShowEndAdornment={false}
                                component={TextInputComponent}
                            />
                        )}
                    </td>
                )}
                {showRemoveCell && (
                    <td className="remove-cell">
                        {canRemove() && (
                            <IconButton
                                aria-label="delete"
                                onClick={() =>
                                    removeTruckById(
                                        current.truckId || current.id,
                                        joliIndex,
                                        truckIndex,
                                        joliData,
                                        current,
                                    )
                                }
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        )}
                    </td>
                )}
            </tr>
        );
    });
};

TruckRow.propTypes = {
    specifyNumberOfTrucksHandler: PropTypes.func,
    specifyNumberOfTrucks: PropTypes.bool,
    removeTruckById: PropTypes.func.isRequired,
    truckStartDateWasChanged: PropTypes.func.isRequired,
    unlimited: PropTypes.bool.isRequired,
    showRemoveCell: PropTypes.bool.isRequired,
    joliIndex: PropTypes.number.isRequired,
    isMyFleetTruck: PropTypes.bool.isRequired,
    specifyTrucksPerHauler: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    form: PropTypes.string,
};

TruckRow.defaultProps = {
    unlimited: false,
};

const JolisTable = (props) => {
    const classes = JoliTablesStyles();
    const tableStyles = TABLE_SECONDARY();
    const {
        joliItems,
        removeTruckById,
        truckStartDateWasChanged,
        specifyNumberOfTrucksHandler,
        unlimited,
        unitOfMeasure,
        specifyTrucksPerHauler,
        jobOrder,
        account,
        form,
        pastJobOrderDateSelected,
    } = props;
    const allJolis = joliItems.getAll();
    const closedJob = jobOrder.status === JO_STATUS_COMPLETED_ID;
    const isPastJobOrTemplateEnabled = jobOrder.isPastJob || jobOrder.copySchedule?.enabled;
    const hasAddedTrucks = allJolis.find((i) => {
        return [...i.haulers, ...i.trucks].find((item) => item.addedFromSd);
    });

    const showRemoveCell = () => {
        if (hasAddedTrucks) {
            return false;
        }

        return (
            allJolis.find((i) => {
                return (
                    !i.id ||
                    (i &&
                        ((!_.isEmpty(i.trucks) &&
                            i.trucks.some((t) => t && !t.hasTickets && (closedJob ? t.isNewAdded : !closedJob))) ||
                            (!_.isEmpty(i.haulers) &&
                                i.haulers.some((t) => t && !t.hasTickets && (closedJob ? t.isNewAdded : !closedJob)))))
                );
            }) || isPastJobOrTemplateEnabled
        );
    };

    const emptyTrucks = new Array(4).fill({});
    const noTrucks = noTrucksInJobOrder(allJolis);
    const showQty = unitOfMeasure === UNIT_OF_MEASURE_LOAD && !unlimited;
    const showHaulersColumns = allJolis.find((i) => !_.isEmpty(i.haulers));

    return (
        <TableContainer component={Paper}>
            <Table
                className={clsx(
                    showQty ? classes.bigTable : classes.table,
                    tableStyles.secondaryTable,
                    classes.trucksTable,
                    "job-order-trucks-table",
                )}
            >
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} className={classes.header}>
                            Trucks
                        </TableCell>
                        {showHaulersColumns && (
                            <>
                                <TableCell className={clsx(classes.header, classes.customAllowSubHaulers)}>
                                    Allow SubHaulers
                                </TableCell>
                                <TableCell className={classes.header}>Hauler Rate($)</TableCell>
                            </>
                        )}
                        <TableCell colSpan={2} className={classes.header}>
                            Starting
                        </TableCell>
                        {showQty && <TableCell className={classes.header}>Quantity</TableCell>}
                        {showRemoveCell() && <TableCell className={clsx(classes.header, classes.customDeleteCell)} />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {joliItems.map((joli, index) => {
                        const current = joliItems.get(index);

                        return (
                            <React.Fragment key={index}>
                                <FieldArray
                                    name={`${joli}.trucks`}
                                    rerenderOnEveryChange
                                    showHaulersColumns={showHaulersColumns}
                                    joliIndex={index}
                                    unlimited={unlimited}
                                    isMyFleetTruck={true}
                                    removeTruckById={removeTruckById}
                                    truckStartDateWasChanged={truckStartDateWasChanged}
                                    showRemoveCell={!!showRemoveCell()}
                                    joli={joli}
                                    jobOrder={jobOrder}
                                    joliData={current}
                                    account={account}
                                    unitOfMeasure={unitOfMeasure}
                                    haulers={current.haulers}
                                    trucks={current.trucks}
                                    joliItems={joliItems}
                                    form={form}
                                    component={TruckRow}
                                />
                                {!specifyTrucksPerHauler &&
                                    !_.isEmpty(current.haulers) &&
                                    unitOfMeasure !== UNIT_OF_MEASURE_LOAD && (
                                        <TableRow>
                                            <TableCell colSpan={2}>
                                                <TruckPillWrapper
                                                    className={clsx(classes.trucksHeader)}
                                                    showIcon={false}
                                                    form={form}
                                                    iconContent={<div className={classes.truckIcon} />}
                                                    title="Total Trucks Needed"
                                                    specifyNumberOfTrucks={true}
                                                    specifyNumberOfTrucksHandler={(add) =>
                                                        specifyNumberOfTrucksHandler &&
                                                        specifyNumberOfTrucksHandler(add, index)
                                                    }
                                                    needTrucksCount={current.notAddressedTrucksCount}
                                                    truckName="Total Trucks Needed"
                                                    isMyFleet={false}
                                                />
                                            </TableCell>
                                            {showHaulersColumns && <TableCell />}
                                            <TableCell />
                                            {(showQty || showRemoveCell()) && <TableCell />}
                                            <TableCell colSpan={2} />
                                        </TableRow>
                                    )}
                                <FieldArray
                                    name={`${joli}.haulers`}
                                    rerenderOnEveryChange
                                    joliIndex={index}
                                    specifyTrucksPerHauler={specifyTrucksPerHauler}
                                    isMyFleetTruck={false}
                                    specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                                    specifyNumberOfTrucks={true}
                                    unlimited={unlimited}
                                    showHaulersColumns={showHaulersColumns}
                                    joli={joli}
                                    jobOrder={jobOrder}
                                    unitOfMeasure={unitOfMeasure}
                                    joliItems={joliItems}
                                    joliData={current}
                                    haulers={current.haulers}
                                    trucks={current.trucks}
                                    allJolis={allJolis}
                                    account={account}
                                    removeTruckById={removeTruckById}
                                    showRemoveCell={!!showRemoveCell()}
                                    truckStartDateWasChanged={truckStartDateWasChanged}
                                    form={form}
                                    pastJobOrderDateSelected={pastJobOrderDateSelected}
                                    component={TruckRow}
                                />
                            </React.Fragment>
                        );
                    })}
                    {noTrucks &&
                        emptyTrucks.map((i, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell
                                        colSpan={isPastJobOrTemplateEnabled ? 3 : 2}
                                        style={{ color: "transparent" }}
                                    >
                                        truck
                                    </TableCell>
                                    <TableCell style={{ color: "transparent" }} colspan="2">
                                        truck
                                    </TableCell>
                                    {showQty && <TableCell style={{ color: "transparent" }}>truck</TableCell>}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

JolisTable.propTypes = {
    truckStartDateWasChanged: PropTypes.func.isRequired,
    removeTruckById: PropTypes.func.isRequired,
    specifyNumberOfTrucksHandler: PropTypes.func.isRequired,
    joliItems: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    unlimited: PropTypes.bool.isRequired,
    jobOrder: PropTypes.object.isRequired,
    specifyTrucksPerHauler: PropTypes.bool,
    form: PropTypes.string,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pastJobOrderDateSelected: PropTypes.bool,
};

JolisTable.defaultProps = {
    unlimited: false,
    specifyTrucksPerHauler: false,
};

export default withRouter(
    connect((state, props) => {
        return {
            account: state.account,
        };
    })(JolisTable),
);
