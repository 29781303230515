import React from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    unitOfMeasureNote: {
        fontSize: 12,
        color: theme.palette.general.darkishRed,
    },
}));
const UnitsOfMeasureWarning = () => {
    const classes = useStyles();

    return (
        <Typography className={clsx(classes.unitOfMeasureNote)}>
            If both measurement systems are selected, metric system will be used as the primary one
        </Typography>
    );
};

export default UnitsOfMeasureWarning;
