import { LIVEMAP_TRUCK_SELECTED } from "../events/livemap";

const initialState = {
    selectedLivemapTruck: {},
};

export const livemapReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVEMAP_TRUCK_SELECTED:
            return {
                ...state,
                selectedLivemapTruck: action.payload.selectedLivemapTruck,
            };
        default:
            return state;
    }
};
