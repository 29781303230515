import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    sideBar: {
        display: "flex",
        overflow: "hidden",

        "& .main-content-wrap, .side-content": {
            transition: "width 300ms ease-in-out",
        },

        "& .main-content-wrap": {
            position: "relative",
        },

        "& .side-content": {
            backgroundColor: theme.palette.ternary.superLight,
        },
    },
    actionButton: {
        position: "absolute",
        top: 2,
        right: 5,
        zIndex: 2,
    },
}));

const SideBar = (props) => {
    const classes = useStyles();
    const {
        sideBarContent,
        mainContent,
        actionButtonContent,
        openOnRender,
        onClickHandler,
        sideBarWidth,
        mainContentClass,
        sideContentClass,
        buttonClassName,
    } = props;
    const [isOpened, setOpen] = useState(openOnRender);
    const switchSideBar = (value) => {
        setOpen(value);
        onClickHandler && onClickHandler(value);
    };
    const mainContentWidth = isOpened ? `calc(100% - ${sideBarWidth})` : "100%";

    return (
        <div className={classes.sideBar}>
            <div style={{ width: mainContentWidth }} className={clsx(mainContentClass, "main-content-wrap")}>
                {mainContent}
                <Fab
                    color="primary"
                    className={buttonClassName || classes.actionButton}
                    onClick={() => switchSideBar(!isOpened)}
                >
                    {actionButtonContent || !isOpened ? <ArrowBackIcon /> : <ArrowForwardIcon />}
                </Fab>
            </div>
            <div style={{ width: isOpened ? sideBarWidth : 0 }} className={clsx(sideContentClass, "side-content")}>
                {sideBarContent}
            </div>
        </div>
    );
};

SideBar.propTypes = {
    mainContent: PropTypes.object.isRequired,
    sideBarContent: PropTypes.object.isRequired,
    actionButtonContent: PropTypes.object,
    mainContentClass: PropTypes.string,
    sideContentClass: PropTypes.string,
    sideBarWidth: PropTypes.string.isRequired,
    openOnRender: PropTypes.bool.isRequired,
    buttonClassName: PropTypes.string,
    onClickHandler: PropTypes.func,
};

SideBar.defaultProps = {
    openOnRender: false,
    sideBarWidth: "300px",
};

export default withRouter(SideBar);
