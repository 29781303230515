import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { GLOBAL_CONTENT_WIDTH } from "../../styles/globalThemeConfig";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { ROUTE_ANALYTICS } from "../../routes/globalRoutes";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SubNavigation, { subNavigationStyles as parentStyles } from "../global/subNavigation";
import { THEME_ICONS } from "../../styles/icons";
import ViewSwitcher from "../global/viewSwitcher";
import { IS_INSPECTOR_USER } from "../../constants/maps";

const useStyles = makeStyles(() => ({
    uiIconReport: {
        ...THEME_ICONS.eventIcon,
        marginRight: 20,
    },
}));

const ReportSubNav = (props) => {
    const classes = useStyles();
    const {
        history: {
            location: { pathname },
        },
        setListView,
        isListView,
        account,
    } = props;
    const styles = useStyles();
    const subNavigationStyles = parentStyles();

    return (
        <SubNavigation
            subMenuWidth={GLOBAL_CONTENT_WIDTH}
            subNavStyles={!setListView && subNavigationStyles.subNavWithoutListView}
        >
            <Grid className={subNavigationStyles.subMenuResponsive} container component="div" alignItems={"center"}>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            styles.viewSwitcher,
                            pathname === ROUTE_ANALYTICS.TICKETS && "active",
                        )}
                    >
                        <AssignmentTurnedInIcon className={classes.uiIconReport} />
                        <Link to={ROUTE_ANALYTICS.TICKETS}>Tickets</Link>
                    </div>
                </Grid>
                {!IS_INSPECTOR_USER(account.role) && (
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationStyles.subMenuItems,
                                pathname === ROUTE_ANALYTICS.TRUCK_ACTIVITY && "active",
                            )}
                        >
                            <LocalShippingIcon className={styles.uiIconReport} />
                            <Link to={ROUTE_ANALYTICS.TRUCK_ACTIVITY}>Truck Activity</Link>
                        </div>
                    </Grid>
                )}
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            pathname === ROUTE_ANALYTICS.REPORT && "active",
                        )}
                    >
                        <AssessmentIcon className={styles.uiIconReport} />
                        <Link to={ROUTE_ANALYTICS.REPORT}>Reporting</Link>
                    </div>
                </Grid>
                {setListView && (
                    <Grid item component="div">
                        <div
                            className={clsx(subNavigationStyles.subMenuItems, subNavigationStyles.overrideSubMenuItems)}
                        >
                            <ViewSwitcher setListView={setListView} isListView={isListView} />
                        </div>
                    </Grid>
                )}
            </Grid>
        </SubNavigation>
    );
};

ReportSubNav.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    setListView: PropTypes.func,
    isListView: PropTypes.bool,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(ReportSubNav),
);
