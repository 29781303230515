import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { TimeSeries } from "pondjs";
import { ChartContainer, ChartRow, Charts, LineChart, Resizable, YAxis } from "react-timeseries-charts";
import { makeStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { convertDateToUserTimeZone } from "../../helpers/jobOrders";
import { ErrorBoundary } from "../core/errorBoudaries";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../constants/global";

const useStyles = makeStyles(() => ({
    devicesChart: {
        "& .yaxis": {
            fontSize: 14,
        },
        "& .x.axis .tick": {
            fontSize: 12,
        },
    },
}));
const BATTERY_KEY = "battery";
const TEMPERATURE_KEY = "temperature";
const SPEED_KEY = "speed";
const SIGNAL_KEY = "signalStrength";
const CHARTS_ITEMS = [
    { id: BATTERY_KEY, label: "Battery (V)" },
    { id: TEMPERATURE_KEY, label: "Temperature °C" },
    { id: SPEED_KEY, label: "Speed (mph)" },
    { id: SIGNAL_KEY, label: "Signal Strength (dB)" },
];

const getSeriesData = (deviceData) => {
    const data = deviceData.map((i) => ({
        ...i,
        [TEMPERATURE_KEY]: +i[TEMPERATURE_KEY],
        [BATTERY_KEY]: +i[BATTERY_KEY],
        [SPEED_KEY]: +i[SIGNAL_KEY],
        [SIGNAL_KEY]: +i[SIGNAL_KEY],
    }));
    const result = CHARTS_ITEMS.map((item) => {
        const minObject = _.minBy(data, item.id);
        const maxObject = _.maxBy(data, item.id);
        const max = maxObject && maxObject[item.id];
        const min = minObject && minObject[item.id];

        return { ...item, max, min };
    });

    return result.filter((i) => !_.isNil(i.max) && !_.isNil(i.min));
};

const DeviceCharts = (props) => {
    const classes = useStyles();
    const { deviceData, account } = props;
    const [state, setState] = useState({ tracker: null });
    const { tracker, charts } = state;

    useEffect(() => {
        const temperaturePoints = [];
        const speedPoints = [];
        const signalPoints = [];
        const batteryPoints = [];

        _.cloneDeep(deviceData)
            .filter((i) => i.deviceTransmitTimestamp)
            .forEach((item) => {
                const timeFormat = `${DEFAULT_DATE_FORMAT}T${DEFAULT_TIME_FORMAT}`;
                const convertedTime = convertDateToUserTimeZone(
                    item.deviceTransmitTimestamp,
                    account.timezone,
                    timeFormat,
                );
                const a = moment(convertedTime, timeFormat);
                const time = a.valueOf();
                const tempReading = item.temperature;
                const speedReading = item.speed;
                const signalReading = item.signalStrength;
                const batteryReading = item.battery;

                temperaturePoints.push([time, tempReading]);
                speedPoints.push([time, speedReading]);
                signalPoints.push([time, signalReading]);
                batteryPoints.push([time, batteryReading]);
            });

        const tempSeries = new TimeSeries({
            name: "Temperature",
            columns: ["time", TEMPERATURE_KEY],
            points: temperaturePoints,
        });
        const speedSeries = new TimeSeries({
            name: "speed",
            columns: ["time", SPEED_KEY],
            points: speedPoints,
        });
        const signalSeries = new TimeSeries({
            name: "signal",
            columns: ["time", SIGNAL_KEY],
            points: signalPoints,
        });
        const batterySeries = new TimeSeries({
            name: "battery",
            columns: ["time", BATTERY_KEY],
            points: batteryPoints,
        });

        setState({
            ...state,
            charts: deviceData ? getSeriesData(deviceData) : [],
            [TEMPERATURE_KEY]: tempSeries,
            [SPEED_KEY]: speedSeries,
            [SIGNAL_KEY]: signalSeries,
            [BATTERY_KEY]: batterySeries,
        });
    }, []);

    return (
        <div className={classes.devicesChart}>
            <ErrorBoundary>
                {!_.isEmpty(charts) && (
                    <Resizable>
                        <ChartContainer
                            utc={false}
                            width={600}
                            enablePanZoom={false}
                            timeRange={state[TEMPERATURE_KEY].timerange()}
                            showGridPosition="under"
                            trackerPosition={tracker}
                            onTrackerChanged={(tracker) => setState({ ...state, tracker })}
                        >
                            {charts.map((item) => {
                                const labelOffset = -30;
                                const chartRowHeight = 150;
                                const yAxisWidth = 80;
                                const yAxisFormat = ",";
                                const commonStyles = { normal: { strokeWidth: 2 } };
                                const { id, max, min, label } = item;
                                const series = state[id];

                                return (
                                    <ChartRow height={chartRowHeight} key={id}>
                                        <YAxis
                                            id={id}
                                            label={label}
                                            labelOffset={labelOffset}
                                            width={yAxisWidth}
                                            min={+min}
                                            max={+max}
                                            type="linear"
                                            format={yAxisFormat}
                                        />
                                        <Charts>
                                            <LineChart
                                                axis={id}
                                                style={{ [id]: commonStyles }}
                                                series={series}
                                                columns={[id]}
                                            />
                                        </Charts>
                                    </ChartRow>
                                );
                            })}
                        </ChartContainer>
                    </Resizable>
                )}
            </ErrorBoundary>
        </div>
    );
};

DeviceCharts.propTypes = {
    deviceData: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(DeviceCharts),
);
