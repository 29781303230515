import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { connect, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import EventIcon from "@material-ui/icons/Event";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Badge from "@material-ui/core/Badge";
import { FiberSmartRecord } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

import SubNavigation, { subNavigationStyles as parentStyles } from "../../global/subNavigation";
import { THEME_ICONS } from "../../../styles/icons";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { getNavigationProps } from "../../../helpers/ui";
import { GLOBAL_CONTENT_WIDTH } from "../../../styles/globalThemeConfig";
import { setStats } from "../../../actions/stats";
import { getJobOrdersCount } from "../../../dataServers/jobOrder";
import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import { IS_INSPECTOR_USER, JO_STATUS_PENDING_ID } from "../../../constants/maps";
import ViewSwitcher from "../../global/viewSwitcher";
import UpgradeAccountMessage from "../../dashboard/upgradeAccountMessage";
import { changeJoDate } from "../../../actions/jobOrders";
import { selectIfIsUsesSmartDispatch } from "../../../selectors/index";
import SmartDispatchWarningLabel from "../../bundles/SmartDispatchWarningLabel";

const activeClass = "active";

const useStyles = makeStyles((theme) => ({
    uiIconCalendar: {
        ...THEME_ICONS.eventIcon,
        marginRight: 20,
    },
    uiIconAssignment: {
        ...THEME_ICONS.eventIcon,
        marginRight: 20,
    },
    uiIconBasket: {
        width: theme.icon.width,
        height: theme.icon.height,
        marginRight: 20,
    },
    actions: {},
    addJobsButtonWrap: {
        textAlign: "right",
    },
    subNavigation: {
        backgroundColor: theme.palette.secondary.superDark,
    },
    uiIconNotificationsBadge: {
        width: 48,
    },
    uiIconNotifications: {
        ...THEME_ICONS.notifications,
    },
    viewButtonStyleWhenMyJobsIsActive: {
        [theme.breakpoints.down("1265")]: {
            marginLeft: 30,
        },
    },
    viewButtonStyleWhenTicketsIsActive: {
        [theme.breakpoints.down("1260")]: {
            marginLeft: 30,
        },
    },
    disabled: {
        opacity: 0.3,
        cursor: "not-allowed",
    },
}));

function JobsSubNav(props) {
    const classes = useStyles();
    const subNavigationStyles = parentStyles();
    const {
        history: {
            location: { pathname },
        },
        setListView,
        isListView,
        subMenuWidth,
        account,
        updateJobsCount,
        pendingJobsCount,
        isUsesSmartDispatch,
    } = props;

    const dispatch = useDispatch();
    const showSmartDispatchLink = isUsesSmartDispatch;

    useEffect(() => {
        // updateJobsCount
        !IS_INSPECTOR_USER(account.role) &&
            getJobOrdersCount({
                [START_DATE_NAME]: moment(),
                [END_DATE_NAME]: moment(),
                status: JO_STATUS_PENDING_ID,
                includeUnfilledJobs: true,
            }).then((pendingJobs) => {
                updateJobsCount(pendingJobs.data);
            });
    }, []);

    return (
        <SubNavigation
            subMenuWidth={subMenuWidth}
            subtabStyles={!setListView && subNavigationStyles.subNavWithoutListView}
        >
            <Grid className={subNavigationStyles.subMenuResponsive} container component="div" alignItems={"center"}>
                <React.Fragment>
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationStyles.subMenuItems,
                                pathname.includes(ROUTE_JOBS.MY_JOBS) && activeClass,
                            )}
                        >
                            <EventIcon className={classes.uiIconCalendar} />
                            <Link to={ROUTE_JOBS.MY_JOBS}>My Jobs</Link>
                        </div>
                    </Grid>
                    {account.company.hasOwnFleet && !IS_INSPECTOR_USER(account.role) && (
                        <Grid item component="div">
                            <div
                                className={clsx(
                                    subNavigationStyles.subMenuItems,
                                    (pathname.includes(ROUTE_JOBS.JOB_BOARD) ||
                                        pathname.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM)) &&
                                        activeClass,
                                )}
                            >
                                <ShoppingBasketIcon
                                    className={clsx(classes.uiIconBasket)}
                                    style={getNavigationProps(account.role, ROUTE_JOBS.JOB_BOARD).tabStyles}
                                />
                                <Link
                                    to={getNavigationProps(account.role, ROUTE_JOBS.JOB_BOARD).to}
                                    style={getNavigationProps(account.role, ROUTE_JOBS.JOB_BOARD).tabStyles}
                                >
                                    Job Board
                                </Link>
                                {!!pendingJobsCount && (
                                    <IconButton className={classes.uiIconNotificationsBadge}>
                                        <Tooltip title="Pending jobs count for today">
                                            <Badge color="error" badgeContent={pendingJobsCount} />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </div>
                        </Grid>
                    )}
                    {!IS_INSPECTOR_USER(account.role) && (
                        <>
                            <Grid item component="div">
                                <div
                                    className={clsx(
                                        subNavigationStyles.subMenuItems,
                                        pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS) && activeClass,
                                    )}
                                >
                                    <AssignmentIcon
                                        className={clsx(classes.uiIconAssignment)}
                                        style={
                                            getNavigationProps(account.role, ROUTE_JOBS.DISPATCH_JOB_ORDERS).tabStyles
                                        }
                                    />
                                    <Link
                                        to={getNavigationProps(account.role, ROUTE_JOBS.DISPATCH_JOB_ORDERS).to}
                                        // prevents redundant dispatch/ request after
                                        // changing of joDate and navigation
                                        // between SMART_DISPATCH and DISPATCH_JOB_ORDERS
                                        onClick={
                                            getNavigationProps(account.role, ROUTE_JOBS.DISPATCH_JOB_ORDERS, () =>
                                                dispatch(changeJoDate(undefined)),
                                            ).onClick
                                        }
                                        style={
                                            getNavigationProps(account.role, ROUTE_JOBS.DISPATCH_JOB_ORDERS).tabStyles
                                        }
                                    >
                                        Dispatch
                                    </Link>
                                </div>
                            </Grid>
                            {showSmartDispatchLink && (
                                <Grid item component="div">
                                    <div
                                        className={clsx(
                                            subNavigationStyles.subMenuItems,
                                            pathname.includes(ROUTE_JOBS.SMART_DISPATCH) && activeClass,
                                            subNavigationStyles.smartDispatchSubMenuItem,
                                        )}
                                    >
                                        <FiberSmartRecord
                                            className={clsx(classes.uiIconAssignment)}
                                            style={
                                                getNavigationProps(account.role, ROUTE_JOBS.SMART_DISPATCH).tabStyles
                                            }
                                        />
                                        <Link
                                            to={getNavigationProps(account.role, ROUTE_JOBS.SMART_DISPATCH).to}
                                            // prevents redundant dispatch/ request after
                                            // changing of joDate and navigation
                                            // between SMART_DISPATCH and DISPATCH_JOB_ORDERS
                                            onClick={() => dispatch(changeJoDate(undefined))}
                                            style={
                                                getNavigationProps(account.role, ROUTE_JOBS.SMART_DISPATCH).tabStyles
                                            }
                                        >
                                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                                <div>Smart Dispatch</div>
                                                <Box sx={{ marginLeft: "5px" }}>
                                                    <SmartDispatchWarningLabel labelText={"BETA"} />
                                                </Box>
                                            </Box>
                                        </Link>
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                </React.Fragment>
                {!IS_INSPECTOR_USER(account.role) && (
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationStyles.subMenuItems,
                                pathname.includes(ROUTE_JOBS.TICKETS) && activeClass,
                            )}
                        >
                            <AssignmentTurnedInIcon className={classes.uiIconBasket} />
                            <Link to={ROUTE_JOBS.TICKETS}>Tickets</Link>
                        </div>
                    </Grid>
                )}
                {setListView && (
                    <Grid item component="div">
                        <div
                            className={clsx(subNavigationStyles.subMenuItems, subNavigationStyles.overrideSubMenuItems)}
                        >
                            <ViewSwitcher
                                setListView={setListView}
                                isListView={isListView}
                                viewButtonStyle={
                                    (pathname.includes(ROUTE_JOBS.MY_JOBS) &&
                                        classes.viewButtonStyleWhenMyJobsIsActive) ||
                                    (pathname.includes(ROUTE_JOBS.TICKETS) &&
                                        classes.viewButtonStyleWhenTicketsIsActive)
                                }
                            />
                        </div>{" "}
                    </Grid>
                )}
            </Grid>
        </SubNavigation>
    );
}

JobsSubNav.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    setListView: PropTypes.func,
    isListView: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool.isRequired,
    updateJobsCount: PropTypes.func.isRequired,
    pendingJobsCount: PropTypes.number,
    subMenuWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isUsesSmartDispatch: PropTypes.bool.isRequired,
};

JobsSubNav.defaultProps = {
    setActiveJO: true,
    fullWidth: true,
    isListView: false,
    subMenuWidth: GLOBAL_CONTENT_WIDTH,
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                pendingJobsCount: state.stats.pendingJobsCount,
                isUsesSmartDispatch: selectIfIsUsesSmartDispatch(state),
            };
        },
        (dispatch) => ({
            updateJobsCount: (payload) => {
                dispatch(
                    setStats({
                        pendingJobsCount: payload,
                    }),
                );
            },
        }),
    )(JobsSubNav),
);

const emptySubtabStyles = makeStyles((theme) => ({
    emptySubNav: {
        backgroundColor: theme.palette.secondary.superDark,
        height: 60,
        display: "flex",
        position: "relative",
        justifyContent: "flex-start",
    },
}));

export const EmptySubNav = () => {
    const classes = emptySubtabStyles();

    return (
        <>
            <div className={classes.emptySubNav} />
            <UpgradeAccountMessage />
        </>
    );
};
