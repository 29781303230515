import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { change } from "redux-form";
import { useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Box } from "@material-ui/core";

import FieldNote from "./fieldNote";

export const DAYS = [
    {
        key: "sunday",
        label: "Sun",
        value: "Sun",
    },
    {
        key: "monday",
        label: "Mon",
        value: "Mon",
    },
    {
        key: "tuesday",
        label: "Tue",
        value: "Tue",
    },
    {
        key: "wednesday",
        label: "Wed",
        value: "Wed",
    },
    {
        key: "thursday",
        label: "Thu",
        value: "Thu",
    },
    {
        key: "friday",
        label: "Fri",
        value: "Fri",
    },
    {
        key: "Saturday",
        label: "Sat",
        value: "Sat",
    },
];

export const DEFAULT_DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri"];

const StyledToggle = withStyles({
    root: {
        color: "#393939",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        borderRadius: 16,
        justifyContent: "center",
        backgroundColor: "#d8d8d8",

        "&$selected": {
            color: "#393939",
            background: "#ffd6b1",
        },
        "&:hover": {
            color: "#393939",
            background: "#ffd6b1",
        },
        "&:hover$selected": {
            color: "#393939",
            background: "#ffd6b1",
        },
        "& .Mui-disabled": {
            color: "rgba(0, 0, 0, 0.29)",
        },
        minWidth: 40,
        maxWidth: 40,
        height: 40,
        textTransform: "unset",
        fontSize: "0.75rem",
    },
    selected: {},
})(ToggleButton);

const WeekDays = ({
    input: { name, onChange, value },
    meta: { error, touched, form },
    containerStyles,
    fieldNoteStyles,
    withDefaultDays,
    defaultDays,
    disabled,
    disableDay,
}) => {
    const dispatch = useDispatch();
    const isError = touched && error;

    useEffect(() => {
        withDefaultDays && dispatch(change(form, name, defaultDays));
    }, []);

    const isDisabledDay = (day) => disableDay && disableDay(day);

    return (
        <Box className={clsx(containerStyles)}>
            <ToggleButtonGroup
                size="small"
                arial-label="Days of the week"
                value={value}
                onChange={(event, value) => onChange(value)}
            >
                {DAYS.map((day, index) => (
                    <StyledToggle
                        key={day.key}
                        value={day.value}
                        aria-label={day.key}
                        disabled={disabled || isDisabledDay(day.value)}
                    >
                        {day.label}
                    </StyledToggle>
                ))}
            </ToggleButtonGroup>
            {isError && <FieldNote className={fieldNoteStyles} fieldNote={error} isError={isError} />}
        </Box>
    );
};

WeekDays.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    containerStyles: PropTypes.string,
    fieldNoteStyles: PropTypes.string,
    withDefaultDays: PropTypes.bool,
    defaultDays: PropTypes.array,
    disabled: PropTypes.bool,
    disableDay: PropTypes.func,
};

export default WeekDays;
