import dataProvider from "../helpers/api";
import { COMPANIES } from "../constants/endpoints";

export const fetchCompanyAdmins = (companyId, params = {}) => {
    return dataProvider({
        url: COMPANIES.ADMINS(companyId),
        method: "GET",
        params,
    });
};
export const updateCompanyAdminsRequest = (companyId, data) => {
    return dataProvider({
        url: COMPANIES.ADMINS(companyId),
        method: "PUT",
        data,
    });
};
