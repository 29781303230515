import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";
import { change, destroy, formValueSelector } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { SELECT_PROJECT_WITHOUT_REGION_WARNING } from "../constants/strings.js";
import SideBar from "../components/global/sideBar";
import Grid from "@material-ui/core/Grid";
import JobsSubNav from "../components/jobs/jobComponents/jobsSubNav";
import JobOrdersWrapper from "../components/jobs/jobsWrapper";
import ProjectsList from "../components/projects/projectsList";
import Fleet from "../components/fleet/fleet";
import { ROUTE_JOBS, ROUTE_PROJECTS } from "../routes/globalRoutes";
import { getJobBoardJobOrderJoli, getJobOrderById } from "../dataServers/jobOrder";
import {
    convertTimeToJsFormat,
    getIsJobOrderRequest,
    processJolisForContractor,
    processTrailers,
} from "../helpers/jobOrders";
import * as projectsActions from "../actions/projects";
import * as poLineItemsActions from "../actions/poLineItems";
import { getProjectById } from "../dataServers/projects";
import { IS_ADMIN_USER } from "../constants/maps";
import { LOADER_WHITE_OVERLAY } from "../constants/global";
import Loader from "../components/core/loader";
import Notification from "../components/core/actionNotification";
import { getJobOrderEditRoute, getJobOrderRequestRoute } from "../helpers/global";
import { getSortedByTruckNameItems, mergeJOItems } from "../components/fleet/fleetList";
import {
    changeJoDate,
    newJobOrderPageLeave,
    resetHaulerParam,
    resetJobOrderParams,
    updateIgnoreWebsocketJoData,
} from "../actions/jobOrders";
import { EDIT_JO_FORM, JOLI_FIELD_ID } from "../components/jobs/constants";
import { getIfIsRegionRequired, selectActiveHaulerOrders, selectTruckDispatchedParam } from "../selectors/index";
import RequestJobOrder from "../components/jobs/RequestJobOrder/RequestJobOrder";
import ErrorNotification from "../components/core/notification";
import { myFleetNeedsToBeUpdatedChange } from "../actions/smartDispatch";

export const COPY_JOB_ORDER_FORM_ID = "copyJobOrder";

const useStyles = makeStyles(() => ({
    dispatchJobOrders: {
        "& .job-list": {
            padding: "0 15px 300px 15px",
        },

        "& .--local-padding": {
            padding: "0 22px",
        },
    },
    mainContent: {
        width: "100%",
        height: "100%",
        position: "relative",
        zIndex: 1,
    },
}));

export const JOBS_LIST_FORM_ID = "dispatchPageJobOrdersFilters";

const DispatchJobOrders = React.memo(
    (props) => {
        const {
            history,
            projects,
            account,
            currentProject,
            projectSelectedManually,
            setCurrentProject,
            setCurrentPOLineItem,
            resetJobOrderParams,
            resetHaulerParam,
            updateProjects,
            updateJoDate,
            joWebsocketData,
            joWebsocketDataReceivedFlag,
            ignoreJoWebsocketData,
            joFirstRenderInitialValues,
            jobOrdersNeedsToBeUpdated,
            [JOLI_FIELD_ID]: formJoItems,
            paverTrackers: formPaverTrackers,
            match: {
                params: { jobOrderId },
            },
            isRegionRequired,
            activeJobOrderParam,
            activeHaulerOrder,
        } = props;
        const classes = useStyles();
        const dispatch = useDispatch();
        const [state, setState] = useState({
            currentJobOrder: null,
            isLoading: false, // loader triggers by each child component
            needUpdateJobOrders: false,
            warning: {},
        });
        const [error, setError] = useState(null);
        const { currentJobOrder, needUpdateJobOrders, isLoading, warning } = state;
        const newJoInitialValues = (currentProject) => {
            if (!currentProject) {
                return null;
            }

            return {};
        };

        const goToProject = (project) => history.push(ROUTE_PROJECTS.PROJECTS + `/${project.id}`, { project });

        const onProjectSelection = (project) => {
            if (!_.isEmpty(currentJobOrder)) {
                return false;
            }
            if (history.location.pathname.includes(ROUTE_JOBS.REQUEST_JOB_ORDER)) {
                return;
            }
            dispatch(destroy(jobFormId));
            dispatch(change(jobFormId, "poLineItem", null));
            resetJobOrderParams();
            resetHaulerParam();
            dispatch(destroy("radiusInput"));
            setCurrentPOLineItem(null);

            if ((currentProject && currentProject.id !== project.id) || !currentProject) {
                setState({
                    ...state,
                    isLoading: true,
                });

                return getProjectById(project.id, { dispatch: true })
                    .then(({ data }) => {
                        if (data && !data.region && isRegionRequired) {
                            setState({
                                ...state,
                                isLoading: false,
                                warning: {
                                    message: SELECT_PROJECT_WITHOUT_REGION_WARNING(data.name),
                                    data,
                                },
                            });
                            return;
                        }
                        setState({
                            ...state,
                            isLoading: false,
                            currentJobOrder:
                                project.iAmOwner || IS_ADMIN_USER(account.company.id) ? newJoInitialValues(data) : null,
                        });
                        setCurrentProject(!_.isEmpty(data) ? { ...data, ...project } : project, true);
                    })
                    .catch(() => {
                        setState({
                            ...state,
                            isLoading: false,
                        });
                    });
            } else {
                setCurrentProject(null);
                setCurrentPOLineItem(null);
            }
        };

        const setActiveJobOrder = (jobOrder) => {
            if (getIsJobOrderRequest(jobOrder, account)) {
                return history.push(getJobOrderRequestRoute(jobOrder.id));
            }

            updateJoDate(undefined);
            history.push(getJobOrderEditRoute(jobOrder.id));

            setState({
                ...state,
                isLoading: true,
                currentJobOrder: null,
            });

            return Promise.all([getJobOrderById(jobOrder.id), getJobBoardJobOrderJoli(jobOrder.id)])
                .then(([{ data: joData }, itemsData]) => {
                    const projectId = joData.poLineItem.purchaseOrder.project.id;
                    const jobOrderValues = {
                        ...joData,
                        projectId,
                        startDate: convertTimeToJsFormat(joData.startDate, account.timezone),
                        // if hauler creates JO he is a contractor
                        [JOLI_FIELD_ID]: getSortedByTruckNameItems(
                            processJolisForContractor(itemsData.data, account.timezone),
                        ),
                        ...processTrailers(itemsData.data),
                    };
                    const newState = {
                        ...state,
                        currentJobOrder: jobOrderValues,
                        isLoading: false,
                    };
                    dispatch(myFleetNeedsToBeUpdatedChange(true));

                    if (!currentProject) {
                        return getProjectById(projectId, { dispatch: true })
                            .then(({ data }) => {
                                const insertedProject = {
                                    ...data,
                                    insertedProject: true,
                                };
                                setCurrentProject(insertedProject, false);
                                updateProjects(insertedProject);

                                setState(newState);
                            })
                            .catch((error) => {
                                if (
                                    error.status === 404 &&
                                    !IS_ADMIN_USER(account.company.id) &&
                                    !account.isSuperUser
                                ) {
                                    setState(newState);
                                }
                            });
                    }

                    setState(newState);
                })
                .catch(() => {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                });
        };

        const cancelEditJobOrder = (needUpdateJobOrders = true) => {
            if (history.location.pathname.includes("new")) {
                dispatch(newJobOrderPageLeave(true));
                updateJoDate(null);
            }
            history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
            setState({
                ...state,
                currentJobOrder: newJoInitialValues(currentProject),
                needUpdateJobOrders,
                isLoading: false,
            });
            currentProject && !projectSelectedManually && setCurrentProject(null);
            setCurrentPOLineItem(null);
        };

        const projectsLoadedHandler = (loadedProjects) => {
            if (history.location.state && !jobOrderId) {
                const { currentProject, currentPOLineItem, jobOrder } = history.location.state;
                const requiredProject =
                    (currentProject && currentProject.id) || (jobOrder && jobOrder.poLineItem.purchaseOrder.project.id);
                const project = (loadedProjects || projects).find((i) => i.id === requiredProject) || {};
                const jolis = jobOrder ? processJolisForContractor(jobOrder[JOLI_FIELD_ID], account.timezone) : [];
                const joStartDate = jobOrder ? convertTimeToJsFormat(jobOrder.startDate, account.timezone) : new Date();
                const proccesedJolis = jolis.map((item) => {
                    delete item.id;

                    const joDate = {
                        year: moment(joStartDate).get("year"),
                        month: moment(joStartDate).get("month"),
                        date: moment(joStartDate).get("date"),
                    };
                    item.startDate = moment(item.startDate).set(joDate);
                    item.haulers = item.haulers.map((i) => ({
                        ...i,
                        trucks: [],
                        hasTickets: false,
                    }));
                    item.trucks = item.trucks.map((i) => ({
                        ...i,
                        hasTickets: false,
                    }));
                    item.hasTickets = false;

                    return item;
                });
                const currentJobOrderData = jobOrder
                    ? {
                          ...jobOrder,
                          hasTickets: false,
                          copyJoId: jobOrder.id,
                          startDate: joStartDate,
                          [JOLI_FIELD_ID]: getSortedByTruckNameItems(proccesedJolis),
                      }
                    : {};

                delete currentJobOrderData.id;
                delete currentJobOrderData.status;
                const updatedProject = {
                    ...project,
                    ...currentProject,
                };
                if (updatedProject && !updatedProject?.owner?.id) {
                    updatedProject.owner = {
                        id: currentJobOrderData.company.id,
                    };
                }

                setCurrentProject(updatedProject, true);
                updateProjects({
                    ...project,
                    ...currentProject,
                    insertedProject: !!project,
                });
                setCurrentPOLineItem(currentPOLineItem || (jobOrder ? jobOrder.poLineItem : {}));
                setState({
                    ...state,
                    currentJobOrder: currentJobOrderData,
                });
            }

            if (jobOrderId) {
                setActiveJobOrder({ id: +jobOrderId });
            }
        };

        useEffect(() => {
            return () => {
                setCurrentProject(null);
                dispatch(newJobOrderPageLeave(false));
            };
        }, []);

        useEffect(() => {
            if (history.location.state && history.location.state.jobOrder) {
                projectsLoadedHandler();
            }
        }, [history.location.state]);

        useEffect(() => {
            setState({
                ...state,
                needUpdateJobOrders: !!jobOrdersNeedsToBeUpdated,
            });
        }, [jobOrdersNeedsToBeUpdated]);

        useEffect(() => {
            if (ignoreJoWebsocketData) {
                dispatch(updateIgnoreWebsocketJoData(false));
            } else if (joWebsocketData && state.currentJobOrder && state.currentJobOrder.id === joWebsocketData.id) {
                const projectId = joWebsocketData.poLineItem.purchaseOrder.project.id;
                const joliInitialItems = joFirstRenderInitialValues.joliItems;
                const websocketJoItems = getSortedByTruckNameItems(
                    processJolisForContractor(joWebsocketData.items, account.timezone),
                );
                const mergedItems = mergeJOItems(websocketJoItems, formJoItems, joliInitialItems);
                const formPaverTrackersValuesList = formPaverTrackers
                    ?.filter((tracker) => tracker.isNewAdded)
                    ?.map((tracker) => tracker.value);

                const mergedPaverTrackers = _.uniq([
                    ...joWebsocketData.paverTrackers,
                    ...(formPaverTrackersValuesList ? formPaverTrackersValuesList : []),
                ]);

                const jobOrderValues = {
                    ...joWebsocketData,
                    startDate: convertTimeToJsFormat(joWebsocketData.startDate, account.timezone),
                    projectId,
                    [JOLI_FIELD_ID]: mergedItems,
                    paverTrackers: mergedPaverTrackers,
                    forceUpdate: !state.currentJobOrder.forceUpdate, //In case if user get several websocket events with same data
                };
                const newState = {
                    ...state,
                    currentJobOrder: jobOrderValues,
                };
                setState(newState);
            }
        }, [joWebsocketData, joWebsocketDataReceivedFlag]);

        const jobFormId = currentJobOrder
            ? currentJobOrder.isCopy
                ? COPY_JOB_ORDER_FORM_ID
                : `${EDIT_JO_FORM}-${currentJobOrder.id || 0}`
            : `${EDIT_JO_FORM}-0`;

        const memoizedCancelEditJobOrder = useCallback(
            (needUpdateJobOrders) => {
                cancelEditJobOrder(needUpdateJobOrders);
            },
            [projectSelectedManually, currentProject],
        );
        const memoizedSetActiveJobOrder = useCallback(
            (jobOrder) => {
                return setActiveJobOrder(jobOrder);
            },
            [currentProject],
        );
        const memoizedOnProjectSelection = useCallback(
            (project) => {
                onProjectSelection(project);
            },
            [currentProject, currentJobOrder, activeJobOrderParam, activeHaulerOrder],
        );

        return (
            <div className={classes.dispatchJobOrders}>
                <SideBar
                    sideBarWidth={`${100 / 4}%`}
                    openOnRender={true}
                    mainContent={
                        <div className={clsx(classes.mainContent)}>
                            <JobsSubNav subMenuWidth={"80%"} />
                            <Grid component="div" container className="--full-height" direction={"row-reverse"}>
                                {history.location.pathname.includes(ROUTE_JOBS.REQUEST_JOB_ORDER) ? (
                                    <Grid item xs={8}>
                                        <RequestJobOrder setError={setError} />
                                    </Grid>
                                ) : (
                                    <Grid
                                        component="div"
                                        item
                                        xs={8}
                                        className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                                    >
                                        {isLoading && <Loader />}
                                        <JobOrdersWrapper
                                            className="job-list"
                                            setActiveJO={false}
                                            noJobsFoundText={"No jobs for the selected project or filter parameters"}
                                            filtersOffset={1}
                                            parentLoading={isLoading}
                                            jobFormId={jobFormId}
                                            pageUrl={ROUTE_JOBS.DISPATCH_JOB_ORDERS}
                                            dateRangeSize={6}
                                            currentProject={currentProject}
                                            currentProjectId={
                                                currentProject
                                                    ? currentProject.iAmOwner || IS_ADMIN_USER(account.company.id)
                                                        ? currentProject.id
                                                        : null
                                                    : history.location.state
                                                    ? history.location.state.currentProject
                                                        ? history.location.state.currentProject.id
                                                        : null
                                                    : null
                                            }
                                            needUpdateJobOrders={needUpdateJobOrders}
                                            parentFilters={{
                                                project: currentProject
                                                    ? currentProject.id
                                                    : history.location.state
                                                    ? history.location.state.currentProject
                                                        ? history.location.state.currentProject.id
                                                        : null
                                                    : null,
                                            }}
                                            editJobOrder={true}
                                            form={JOBS_LIST_FORM_ID}
                                            cancelFormEditHandler={memoizedCancelEditJobOrder}
                                            currentJobOrder={currentJobOrder}
                                            setActive={memoizedSetActiveJobOrder}
                                            isListView={false}
                                            currentProjectiAmOwner={
                                                currentProject
                                                    ? currentProject.iAmOwner || IS_ADMIN_USER(account.company.id)
                                                        ? currentProject.iAmOwner
                                                        : null
                                                    : history.location.state
                                                    ? history.location.state.currentProject
                                                        ? history.location.state.currentProject.iAmOwner
                                                        : null
                                                    : null
                                            }
                                        />
                                    </Grid>
                                )}
                                <ProjectsList
                                    onProjectSelection={memoizedOnProjectSelection}
                                    isDispatch={true}
                                    jobsFormId={JOBS_LIST_FORM_ID}
                                    projectsLoadedHandler={projectsLoadedHandler}
                                    activeJobOrderParam={activeJobOrderParam}
                                />
                            </Grid>
                        </div>
                    }
                    sideBarContent={<Fleet jobFormId={jobFormId} jobsListFormId={JOBS_LIST_FORM_ID} />}
                />
                <Notification
                    message={warning.message}
                    config={{ onRedirect: () => goToProject(warning.data), variant: "warning" }}
                />
                {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

DispatchJobOrders.propTypes = {
    history: PropTypes.object.isRequired,
    jobOrders: PropTypes.array.isRequired,
    jobOrdersNeedsToBeUpdated: PropTypes.bool,
    projects: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    joWebsocketData: PropTypes.object,
    initialValues: PropTypes.object,
    setCurrentProject: PropTypes.func.isRequired,
    setCurrentPOLineItem: PropTypes.func.isRequired,
    updateProjects: PropTypes.func.isRequired,
    updateJoDate: PropTypes.func,
    projectSelectedManually: PropTypes.bool,
    joWebsocketDataReceivedFlag: PropTypes.bool,
    ignoreJoWebsocketData: PropTypes.bool,
    joFirstRenderInitialValues: PropTypes.object,
    resetJobOrderParams: PropTypes.func.isRequired,
};

export default withRouter(
    connect(
        (state, props) => {
            const stateToProps = {
                account: state.account,
                currentProject: state.projects.currentProject,
                projects: state.projects.projects,
                jobOrders: state.jobOrders.list,
                jobOrdersNeedsToBeUpdated: state.jobOrders.jobOrdersNeedsToBeUpdated,
                projectSelectedManually: state.projects.projectSelectedManually,
                joWebsocketData: state.jobOrders.joWebsocketData,
                joWebsocketDataReceivedFlag: state.jobOrders.joWebsocketDataReceivedFlag,
                ignoreJoWebsocketData: state.jobOrders.ignoreJoWebsocketData,
                joFirstRenderInitialValues: state.jobOrders.joFirstRenderInitialValues,
                isRegionRequired: getIfIsRegionRequired(state),
                activeJobOrderParam: selectTruckDispatchedParam(state),
                activeHaulerOrder: selectActiveHaulerOrders(state),
            };

            const jobOrderId = props?.match?.params?.jobOrderId;
            if (jobOrderId) {
                const jobFormId = `${EDIT_JO_FORM}-${jobOrderId}`;
                const joFormSelector = formValueSelector(jobFormId);

                stateToProps[JOLI_FIELD_ID] = joFormSelector(state, JOLI_FIELD_ID);
                stateToProps.paverTrackers = joFormSelector(state, "paverTrackers");
            }

            return stateToProps;
        },
        (dispatch) => ({
            setCurrentProject: (payload, manuallySelected) => {
                dispatch(projectsActions.setCurrentProject(payload, manuallySelected));
            },
            updateProjects: (payload) => {
                dispatch(projectsActions.updateProjects(payload));
            },
            setCurrentPOLineItem: (payload) => {
                dispatch(poLineItemsActions.setCurrentPOLineItem(payload));
            },
            updateJoDate: (joDate) => {
                dispatch(changeJoDate(joDate));
            },
            resetJobOrderParams: () => dispatch(resetJobOrderParams()),
            resetHaulerParam: () => dispatch(resetHaulerParam()),
        }),
    )(DispatchJobOrders),
);
