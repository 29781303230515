import {
    WEBSOCKET_EVENT_TYPE_CROWDED_ZONE,
    WEBSOCKET_EVENT_TYPE_IDLE_TIME,
    WEBSOCKET_EVENT_TYPE_JO_CLOSED,
    WEBSOCKET_EVENT_TYPE_JO_CREATED,
    WEBSOCKET_EVENT_TYPE_JO_DELETED,
    WEBSOCKET_EVENT_TYPE_JO_REOPENED,
    WEBSOCKET_EVENT_TYPE_JO_UPDATED,
    WEBSOCKET_EVENT_TYPE_LATE_DRIVER,
    WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS,
    WEBSOCKET_EVENT_TYPE_MESSAGE_NOT_SENT,
    WEBSOCKET_EVENT_TYPE_MESSAGE_SENT,
    WEBSOCKET_EVENT_TYPE_MISSING_TICKET,
    WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK,
    WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
    WEBSOCKET_EVENT_TYPE_REQUEST_FILL,
    WEBSOCKET_EVENT_TYPE_SLOW_TRIP,
    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED,
    WEBSOCKET_EVENT_TYPE_TICKET_CLOSED,
    WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED,
    WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED,
    WEBSOCKET_EVENT_TYPE_TICKET_OPENED,
    WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICKET_REOPENED,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED,
    WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_OFFLINE,
    WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_ONLINE,
    WEBSOCKET_EVENT_TYPE_TRUCK_ENTERED,
    WEBSOCKET_EVENT_TYPE_TRUCK_LEFT,
    WEBSOCKET_EVENT_TYPE_TRUCK_LINKED,
    WEBSOCKET_EVENT_TYPE_TRUCK_NOT_MOVING,
    WEBSOCKET_EVENT_TYPE_TRUCK_NOT_SEND_COORDS,
    WEBSOCKET_EVENT_TYPE_TRUCK_PAUSED,
    WEBSOCKET_EVENT_TYPE_TRUCK_RESUMED,
    WEBSOCKET_EVENT_TYPE_TRUCK_SIGN_IN,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../constants/maps";

export const getEventTypeFromDescription = (description) => {
    return (
        {
            // Tickets events
            ticket_closed: WEBSOCKET_EVENT_TYPE_TICKET_CLOSED,
            ticket_dispute: WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED,
            ticket_interrupted: WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED,
            ticket_opened: WEBSOCKET_EVENT_TYPE_TICKET_OPENED,
            ticket_reopened: WEBSOCKET_EVENT_TYPE_TICKET_REOPENED,
            ticket_reconcile: WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED,
            airticketlite_reconcile: WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED,
            ticket_final_reconcile: WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED,
            ticpic_report: WEBSOCKET_EVENT_TYPE_TICPIC_REPORT,
            ticpic_report_failed: WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED,

            // Trucks events
            entered: WEBSOCKET_EVENT_TYPE_TRUCK_ENTERED,
            left: WEBSOCKET_EVENT_TYPE_TRUCK_LEFT,
            not_moving: WEBSOCKET_EVENT_TYPE_TRUCK_NOT_MOVING,
            not_send_coords: WEBSOCKET_EVENT_TYPE_TRUCK_NOT_SEND_COORDS,
            truck_device_went_offline: WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_OFFLINE,
            truck_device_went_online: WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_ONLINE,
            truck_linked: WEBSOCKET_EVENT_TYPE_TRUCK_LINKED,
            truck_sign_in: WEBSOCKET_EVENT_TYPE_TRUCK_SIGN_IN,
            // JO events
            close_job_order: WEBSOCKET_EVENT_TYPE_JO_CLOSED,
            create_job_order: WEBSOCKET_EVENT_TYPE_JO_CREATED,
            delete_job_order: WEBSOCKET_EVENT_TYPE_JO_DELETED,
            reopen_job_order: WEBSOCKET_EVENT_TYPE_JO_REOPENED,
            update_job_order: WEBSOCKET_EVENT_TYPE_JO_UPDATED,
            // Job Requests
            create_job_request: WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
            fill_request_job: WEBSOCKET_EVENT_TYPE_REQUEST_FILL,

            message_sent: WEBSOCKET_EVENT_TYPE_MESSAGE_SENT,
            message_not_sent: WEBSOCKET_EVENT_TYPE_MESSAGE_NOT_SENT,

            pause_truck: WEBSOCKET_EVENT_TYPE_TRUCK_PAUSED,
            resume_truck: WEBSOCKET_EVENT_TYPE_TRUCK_RESUMED,

            payment_failure: WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS,

            // Anomaly Detection
            idle_time_alert: WEBSOCKET_EVENT_TYPE_IDLE_TIME,
            crowded_zone_alert: WEBSOCKET_EVENT_TYPE_CROWDED_ZONE,
            slow_trip_alert: WEBSOCKET_EVENT_TYPE_SLOW_TRIP,
            late_driver_alert: WEBSOCKET_EVENT_TYPE_LATE_DRIVER,
            missing_ticket_alert: WEBSOCKET_EVENT_TYPE_MISSING_TICKET,

            //Smart dispatch
            smart_dispatch_data: WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED,
            no_truck_on_the_route: WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK,
        }[description] || WEBSOCKET_EVENT_TYPE_UNKNOWN
    );
};
