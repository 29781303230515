export const SETTINGS_REGIONS_LOADING = "settingsRegionsLoading";
export const SETTINGS_REGIONS_LOADING_SUCCESS = "settingsRegionsLoadingSuccess";
export const SETTINGS_REGIONS_LOADING_FAILURE = "settingsRegionsLoadingFailure";
export const SETTINGS_REGIONS_FILTERS_SET = "settingsRegionsFiltersSet";
export const SETTINGS_REGIONS_SUCCESS_MESSAGE = "settingsRegionsSuccessMessage";
export const SETTINGS_REGIONS_FAILURE_MESSAGE = "settingsRegionsFailureMessage";
export const SETTINGS_REGIONS_UPDATE = "settingsRegionsUpdate";
export const SETTINGS_REGIONS_DELETE = "settingsRegionsDelete";
export const SETTINGS_REGIONS_REQUEST = "settingsRegionsRequet";
export const SETTINGS_REGIONS_REQUEST_RESULT = "settingsRegionsRequestResult";
