import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import CustomSwitch from "../core/form/customSwitch";
import { PrimaryButton } from "../core/buttons/primaryButton";
import TextInputComponent from "../core/form/textInputComponent";
import { Validation } from "../../helpers/validation";
import Loader from "../core/loader";
import clsx from "clsx";
import { ACCOUNT_NOTIFICATION_PROFILE_KEY, LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { getDispatchersLoadOptions, updateJobNotificationRecipients } from "../../actions/notifications";
import ErrorNotification from "../core/notification";
import { updateCompanyNotificationsRequest, updateUserNotificationsProfile } from "../../dataServers/user";
import { makeStyles } from "@material-ui/core";
import AccordionComponent from "../core/accordion";
import {
    JOB_NOTIFICATIONS_FIELD_NAME,
    JOB_REQUEST_NOTIFICATIONS,
    JOB_REQUEST_NOTIFICATIONS_FIELD_NAME,
    MESSAGE_NOTIFICATIONS_FIELD_NAME,
    PROFILE_JOB_NOTIFICATIONS,
    PROFILE_MESSAGE_NOTIFICATIONS,
    PROFILE_TICKET_NOTIFICATIONS,
    PROFILE_TRUCK_NOTIFICATIONS,
    TICKETS_NOTIFICATION_FIELD_NAME,
    TRUCK_NOTIFICATIONS_FIELD_NAME,
} from "../../constants/settings";
import { updateAccountData, updatePerPageParamForMultiSelector } from "../../helpers/global";
import { authorizeUser } from "../../actions/globalActions";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import RequestDropOffNotifications from "./RequestDropOffNotifications";

const useStyles = makeStyles((theme) => ({
    uiIcon: {
        width: 25,
        height: 25,
        position: "relative",
        top: 8,
        marginRight: 11,
    },
    uiIconGrey: {
        color: theme.palette.secondary.dark,
    },
    input: {
        margin: "0 10px",

        "& .fieldset, input, .MuiInputBase-formControl": {
            width: 100,
            height: 30,
        },
        "& .MuiInputAdornment-positionEnd": {
            margin: "inherit",
        },
    },
    errorClassName: {
        position: "absolute",
    },
    text: {
        color: "#979797",
    },
    container: {
        display: "flex",
        alignItems: "center",
    },
    boxContent: {
        "& .form-view": {
            width: 270,
            margin: "10px 36px",
        },
        "& .text-offset": {
            paddingLeft: 36,
            margin: "5px 0 15px",
        },
    },
    requestNotificationLabel: {
        margin: "10px 5px 20px",
    },
}));

const Notifications = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        isLoading: false,
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { isLoading } = state;
    const { account, setFieldValue, authorizeUserWithData, formValues: recipients } = props;

    const onMessageClose = () => {
        setError(null);
    };
    const updateValue = (value, name, field) => {
        setState({ ...state, isLoading: true });
        const request = field ? updateCompanyNotificationsRequest : updateUserNotificationsProfile;
        request(field ? account.company.id : account.id, field ? { [field]: { [name]: value } } : { [name]: value })
            .then((data) => {
                field && updateAccountData(data.data, authorizeUserWithData, ACCOUNT_NOTIFICATION_PROFILE_KEY);
                setState({ ...state, isLoading: false });
                onSuccess();
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const onSuccess = () => {
        setSuccess(["Notification Settings are updated"]);
        setSuccess(null);
    };

    useEffect(() => {
        props.formValuesADA.idleTimeAlertThreshold &&
            setFieldValue("idleTimeAlertThreshold", props.formValuesADA.idleTimeAlertThreshold / 60);
        props.formValuesADA.lateDriverAlertThreshold &&
            setFieldValue("lateDriverAlertThreshold", props.formValuesADA.lateDriverAlertThreshold / 60);
    }, []);

    const saveAnomalyDetectionValues = () => {
        const params = {
            anomalyAlertNotifications: {
                ...props.formValuesADA,
                slowTripAlertThreshold: +props.formValuesADA.slowTripAlertThreshold,
                crowdedZoneAlertThreshold: +props.formValuesADA.crowdedZoneAlertThreshold,
                idleTimeAlertThreshold: +props.formValuesADA.idleTimeAlertThreshold * 60,
                lateDriverAlertThreshold: +props.formValuesADA.lateDriverAlertThreshold * 60,
            },
        };
        updateCompanyNotificationsRequest(account.company.id, params)
            .then((data) => {
                updateAccountData(data.data, authorizeUserWithData, ACCOUNT_NOTIFICATION_PROFILE_KEY);
                setState({ ...state, isLoading: false });
                onSuccess();
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const renderFooter = ({ text, name, format, validate }) => {
        return (
            <div className={classes.container}>
                {text && <span className={classes.text}>{text}</span>}
                {name && (
                    <Field
                        name={name}
                        type="text"
                        fullWidth={false}
                        className={classes.input}
                        component={TextInputComponent}
                        validate={validate}
                        errorClassName={classes.errorClassName}
                    />
                )}
                {format && <span className={classes.text}>{format}</span>}
            </div>
        );
    };

    const anomalyDetectionContent = () => {
        return (
            <>
                <Field
                    name="idleTimeAlert"
                    onChange={(e) => updateValue(e.target.checked, "idleTimeAlert", "anomalyAlertNotifications")}
                    label="Idle Time"
                    type="checkbox"
                    component={CustomSwitch}
                />
                {renderFooter({
                    text: "Alert if driver is idle for more than",
                    name: "idleTimeAlertThreshold",
                    format: "minutes",
                    validate: [Validation.noSpecialSymbols, Validation.isNumber, Validation.required],
                })}
                <br />
                <Field
                    name="crowdedZoneAlert"
                    onChange={(e) => updateValue(e.target.checked, "crowdedZoneAlert", "anomalyAlertNotifications")}
                    label="Crowded Zone"
                    type="checkbox"
                    component={CustomSwitch}
                />
                {renderFooter({
                    text: "Alert if geozone contains more than",
                    name: "crowdedZoneAlertThreshold",
                    format: "trucks",
                    validate: [Validation.isInteger, Validation.isNumber, Validation.required],
                })}
                <br />
                <Field
                    name="slowTripAlert"
                    onChange={(e) => updateValue(e.target.checked, "slowTripAlert", "anomalyAlertNotifications")}
                    label="Slow Trip"
                    type="checkbox"
                    component={CustomSwitch}
                />
                {renderFooter({
                    text: "Alert if trip duration is more than",
                    name: "slowTripAlertThreshold",
                    format: "x estimated time",
                    validate: [Validation.required, Validation.isNumber],
                })}
                <br />
                <Field
                    name="lateDriverAlert"
                    onChange={(e) => updateValue(e.target.checked, "lateDriverAlert", "anomalyAlertNotifications")}
                    label="Late Driver"
                    type="checkbox"
                    component={CustomSwitch}
                />
                {renderFooter({
                    text: "Alert if driver is more than",
                    name: "lateDriverAlertThreshold",
                    format: "minutes late to pickup site",
                    validate: [Validation.noSpecialSymbols, Validation.isNumber, Validation.required],
                })}
                <br />
                <Field
                    name="missingTicketAlert"
                    onChange={(e) => updateValue(e.target.checked, "missingTicketAlert", "anomalyAlertNotifications")}
                    label="Missing Ticket"
                    type="checkbox"
                    component={CustomSwitch}
                />
                {renderFooter({
                    text: "Alert if number of tickets does not match geozone entrance/exits",
                })}
                <br />
                <PrimaryButton type="button" disabled={props.invalid} onClick={saveAnomalyDetectionValues}>
                    Save
                </PrimaryButton>
            </>
        );
    };

    const getNotifContent = (items, field) => {
        return items.map((i, index, array) => (
            <>
                <Field
                    name={i.name}
                    onChange={(e) => updateValue(e.target.checked, i.name, field)}
                    label={i.label}
                    type="checkbox"
                    component={CustomSwitch}
                />
                {index !== array.length - 1 && (
                    <>
                        <br />
                        <br />
                    </>
                )}
            </>
        ));
    };

    const loadOptions = async (inputValue, { params = {}, loadedCount }) => {
        const requestParams = updatePerPageParamForMultiSelector(params, recipients);

        return await dispatch(getDispatchersLoadOptions(inputValue, requestParams, loadedCount, setError));
    };

    const onRecipientChange = async (values, actionMeta) => {
        setState({ ...state, isLoading: true });

        try {
            const response = await dispatch(updateJobNotificationRecipients(values, actionMeta, setError));
            if (response) {
                setSuccess("Recipients are updated");
            }
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const jobRequestContent = () => {
        return (
            <div>
                <Field
                    name="recipients"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={loadOptions}
                    component={AsyncAutocompleteComponent}
                    isMulti={true}
                    isClearable={false}
                    label="Notifications Recipients"
                    onFieldChange={onRecipientChange}
                />
                <div className={clsx(classes.text, classes.requestNotificationLabel)}>
                    If the list of recipients is not specified, notification will be sent to request creator and to
                    project owner dispatchers.
                </div>
                {getNotifContent(JOB_REQUEST_NOTIFICATIONS, JOB_REQUEST_NOTIFICATIONS_FIELD_NAME)}
            </div>
        );
    };

    return (
        <form noValidate={true} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose, onClick: onMessageClose }} />}
            {success && (
                <ErrorNotification
                    message={success}
                    type={"success"}
                    config={{ onClose: onMessageClose, onClick: onMessageClose }}
                />
            )}
            <h1>Notify me when</h1>
            <br />
            <Field
                name="notifyWhenMyJobFilled"
                label="Notify me when my jobs are filled"
                onChange={(e) => updateValue(e.target.checked, "notifyWhenMyJobFilled")}
                type="checkbox"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Field
                name="notifyWhenJobPosted"
                onChange={(e) => updateValue(e.target.checked, "notifyWhenJobPosted")}
                label="New jobs are posted to job board"
                type="checkbox"
                component={CustomSwitch}
            />
            <br />
            <br />
            <AccordionComponent
                accordionItems={[
                    {
                        title: "Anomaly Detection Alerts",
                        content: anomalyDetectionContent(),
                    },
                    {
                        title: "Tickets Notifications",
                        content: getNotifContent(PROFILE_TICKET_NOTIFICATIONS, TICKETS_NOTIFICATION_FIELD_NAME),
                    },
                    {
                        title: "Truck Notifications",
                        content: getNotifContent(PROFILE_TRUCK_NOTIFICATIONS, TRUCK_NOTIFICATIONS_FIELD_NAME),
                    },
                    {
                        title: "Job Notifications",
                        content: getNotifContent(PROFILE_JOB_NOTIFICATIONS, JOB_NOTIFICATIONS_FIELD_NAME),
                    },
                    {
                        title: "Job Request Notifications",
                        content: jobRequestContent(),
                    },
                    {
                        title: "Message Notifications",
                        content: getNotifContent(PROFILE_MESSAGE_NOTIFICATIONS, MESSAGE_NOTIFICATIONS_FIELD_NAME),
                    },
                    {
                        title: "Notifications For Requests To Drop Off",
                        content: <RequestDropOffNotifications form={SETTINGS_NOTIFICATIONS_FORM} />,
                    },
                ]}
            />
        </form>
    );
};

Notifications.propTypes = {
    account: PropTypes.object.isRequired,
};

export const SETTINGS_NOTIFICATIONS_FORM = "settingsNotificationsForm";

export default withRouter(
    compose(
        reduxForm({
            form: SETTINGS_NOTIFICATIONS_FORM,
            enableReinitialize: true,
        }),
        connect(
            (state) => {
                const formSelector = formValueSelector(SETTINGS_NOTIFICATIONS_FORM);

                return {
                    account: state.account,
                    formValuesADA: formSelector(
                        state,
                        "crowdedZoneAlert",
                        "crowdedZoneAlertThreshold",
                        "idleTimeAlert",
                        "idleTimeAlertThreshold",
                        "lateDriverAlert",
                        "lateDriverAlertThreshold",
                        "missingTicketAlert",
                        "slowTripAlert",
                        "slowTripAlertThreshold",
                    ),
                    formValues: formSelector(state, "recipients"),
                };
            },
            (dispatch) => ({
                setFieldValue: (field, value) => dispatch(change(SETTINGS_NOTIFICATIONS_FORM, field, value)),
                authorizeUserWithData: (accountData) => {
                    dispatch(authorizeUser(accountData));
                },
            }),
        ),
    )(Notifications),
);
