import { initialize } from "redux-form";

import { actions } from "../reducers/unitsOfMeasureReducer";
import * as api from "../dataServers/unitsOfMeasure";
import { handleError } from "../helpers/global";
import handleFormErrors from "../helpers/handleFormErrors";

import * as sel from "../selectors";
import { SETTINGS_UOM_EDIT_UON } from "../constants/forms";

export const getUnitsOfMeasure =
    ({ companyId, ...restParams } = {}) =>
    async (dispatch, getState) => {
        try {
            dispatch(actions.requestUnitsOfMeasure());
            const selectedCompanyId = sel.selectCompanyId(getState());
            const { data } = await api.fetchUnitsOfMeasure({
                companyId: companyId || selectedCompanyId,
                ...restParams,
            });
            dispatch(actions.resultUnitsOfMeasure(data));
        } catch (error) {
            dispatch(actions.resultUnitsOfMeasure(handleError(error)));
        }
    };

export const refreshUnitsOfMeasure = (params) => async (dispatch, getState) => {
    dispatch(getUnitsOfMeasure(params));
};

export const reloadUnitsOfMeasure =
    ({ companyId, ...restParams } = {}) =>
    async (dispatch, getState) => {
        try {
            const selectedCompanyId = sel.selectCompanyId(getState());
            const { data } = await api.fetchUnitsOfMeasure({
                companyId: companyId || selectedCompanyId,
                ...restParams,
            });
            dispatch(actions.resultUnitsOfMeasure(data));
        } catch (e) {
            throw new Error(handleError(e).message);
        }
    };

export const initializeUnitOfMeasureById = (id) => async (dispatch) => {
    try {
        dispatch(actions.requestUnitsOfMeasureDetails());
        const { data: uom } = await api.fetchUnitOfMeasureById(id);
        dispatch(
            initialize(SETTINGS_UOM_EDIT_UON, {
                name: uom.name,
                measurementBase: uom.measurementBase.toString(),
                conversionRate: uom.conversion[0] && uom.conversion[0].conversionRate.toString(),
                baseUomId: uom.conversion[0] && uom.conversion[0].baseUomId.toString(),
            }),
        );
        dispatch(actions.resultUnitsOfMeasureDetails());
    } catch (error) {
        dispatch(actions.resultUnitsOfMeasure(handleError(error)));
    }
};

export const createUnitsOfMeasure = (values) => async (dispatch, getState) => {
    try {
        await api.postUnitOfMeasure(values);
        await dispatch(refreshUnitsOfMeasure());
    } catch (e) {
        handleFormErrors(e);
    }
};

export const deleteUnitsOfMeasure = (uomId) => async (dispatch, getState) => {
    try {
        await api.deleteUnitOfMeasure(uomId);
        await dispatch(refreshUnitsOfMeasure());
    } catch (e) {
        throw new Error(handleError(e).message);
    }
};

export const updateUnitsOfMeasure = (uom, values) => async (dispatch, getState) => {
    try {
        const body = {
            name: values.name,
        };
        await api.putUnitOfMeasure(uom.id, body);
        await dispatch(refreshUnitsOfMeasure());
    } catch (e) {
        handleFormErrors(e);
    }
};

export const updateUOMPosition = (uomId, displayIndex) => async (dispatch, getState) => {
    try {
        const body = {
            displayIndex: displayIndex + 1,
        };
        await api.putUnitOfMeasure(uomId, body);
        await dispatch(reloadUnitsOfMeasure());
    } catch (e) {
        throw new Error(handleError(e).message);
    }
};

export const loadUnitsOfMeasureOptions = async (companyId, inputValue, { params = {}, loadedCount }) => {
    try {
        const { data } = await api.fetchUnitsOfMeasure({ companyId });
        const results = data
            .filter((i) => !i.isDefault)
            .sort((a, b) => a.displayIndex - b.displayIndex)
            .map((i) => ({
                value: i.id,
                label: `${i.name} ${
                    i.conversion[0] ? `- ${i.conversion[0].conversionRate} ${i.conversion[0].baseUomName}s` : ""
                }`,
            }));

        return {
            options: results,
            hasMore: false,
        };
    } catch (error) {
        throw new Error(error);
    }
};
