import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, reset, getFormSubmitErrors, hasSubmitSucceeded } from "redux-form";
import { useSelector } from "react-redux";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";

import Loader from "../../core/loader";
import TextInputComponent from "../../core/form/textInputComponent";
import ErrorNotification from "../../core/notification";
import { Validation } from "../../../helpers/validation";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import AppModal from "../../core/modal";
import AutocompleteAddress from "../../../components/global/autocompleteAddress";
import { loadStaticOptions } from "../../../helpers/global";
import { ACCOUNT_TYPES, FREE } from "../../../constants/accounts";
import {
    COMPANY_TYPE_TRUCKER,
    COMPANY_TYPES,
    PO_TYPES,
    PO_TYPE_AGG_DIRT_SAND,
    TIME_ZONES,
    TIME_ZONE_US_EASTERN,
} from "../../../constants/maps";
import {
    ADD_OFFLINE_HAULER_BUTTON_TITLE,
    ADD_OFFLINE_HAULER_FORM_TITLE,
    ADD_OFFLINE_HAULER_CANCEL_BUTTON_TITLE,
    ADD_OFFLINE_HAULER_SUBMIT_BUTTON_TITLE,
    ADD_OFFLINE_HAULER_FORM_COMPANY_NAME_LABEL,
    ADD_OFFLINE_HAULER_FORM_COMPANY_TYPE_PLACEHOLDER,
    ADD_OFFLINE_HAULER_FORM_ACCOUNT_TYPE_PLACEHOLDER,
    ADD_OFFLINE_HAULER_FORM_PO_TYPE_PLACEHOLDER,
    ADD_OFFLINE_HAULER_FORM_TIMEZONE_PLACEHOLDER,
} from "../constants/strings";
import { ADD_HAULER_FORM } from "../constants/forms";
import { addOfflineHauler } from "../../../actions/settings";

const Separator = ({ single }) => (
    <React.Fragment>
        <br />
        {!single && <br />}
    </React.Fragment>
);

Separator.propTypes = {
    single: PropTypes.bool,
};

const modalStyles = { textAlign: "left", width: 600 };

const AddOfflineHauler = ({ dispatch, handleSubmit, error, submitting, invalid }) => {
    const [open, setOpen] = useState(false);

    const submitErrors = useSelector(getFormSubmitErrors(ADD_HAULER_FORM));
    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(ADD_HAULER_FORM));

    const openModal = () => setOpen(true);

    const closeModal = () => {
        setOpen(false);
        dispatch(reset(ADD_HAULER_FORM));
    };

    useEffect(() => {
        if (hasSubmitSuccess) closeModal();
    }, [hasSubmitSuccess]);

    return (
        <React.Fragment>
            <Button size="small" color="primary" onClick={openModal}>
                <AddCircleOutlineIcon />
                {ADD_OFFLINE_HAULER_BUTTON_TITLE}
            </Button>
            <AppModal closeModal={closeModal} isOpen={open} form={ADD_HAULER_FORM} modalStyles={modalStyles}>
                <Box className={clsx(submitting && LOADER_WHITE_OVERLAY)}>
                    <form onSubmit={handleSubmit} noValidate={true}>
                        {submitting && <Loader />}
                        <h1 className="title">{ADD_OFFLINE_HAULER_FORM_TITLE}</h1>
                        <Field
                            name="name"
                            validate={[Validation.required, Validation.noSpecialSymbols]}
                            type="text"
                            label={ADD_OFFLINE_HAULER_FORM_COMPANY_NAME_LABEL}
                            component={TextInputComponent}
                            error={submitErrors.name}
                        />
                        <Separator />
                        <AutocompleteAddress error={submitErrors.adress} />
                        <Separator single />
                        <Field
                            name="companyType"
                            placeholder={ADD_OFFLINE_HAULER_FORM_COMPANY_TYPE_PLACEHOLDER}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            validate={[Validation.required]}
                            loadOptions={() =>
                                loadStaticOptions({ [COMPANY_TYPE_TRUCKER]: COMPANY_TYPES[COMPANY_TYPE_TRUCKER] })
                            }
                            error={submitErrors.companyType}
                            component={AsyncAutocompleteComponent}
                        />
                        <Separator single />
                        <Field
                            name="accountType"
                            placeholder={ADD_OFFLINE_HAULER_FORM_ACCOUNT_TYPE_PLACEHOLDER}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            validate={[Validation.required]}
                            loadOptions={() => loadStaticOptions({ [FREE]: ACCOUNT_TYPES[FREE] })}
                            component={AsyncAutocompleteComponent}
                            error={submitErrors.accountType}
                        />
                        <Separator single />
                        <Field
                            name="poType"
                            placeholder={ADD_OFFLINE_HAULER_FORM_PO_TYPE_PLACEHOLDER}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            validate={[Validation.required]}
                            loadOptions={() =>
                                loadStaticOptions({ [PO_TYPE_AGG_DIRT_SAND]: PO_TYPES[PO_TYPE_AGG_DIRT_SAND] })
                            }
                            error={submitErrors.poType}
                            component={AsyncAutocompleteComponent}
                        />
                        <Separator single />
                        <Field
                            name="timezone"
                            placeholder={ADD_OFFLINE_HAULER_FORM_TIMEZONE_PLACEHOLDER}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            loadOptions={() =>
                                loadStaticOptions({ [TIME_ZONE_US_EASTERN]: TIME_ZONES[TIME_ZONE_US_EASTERN] })
                            }
                            error={submitErrors.timezone}
                            component={AsyncAutocompleteComponent}
                        />
                        <Separator />
                        <ButtonsGroup>
                            <SecondaryButton onClick={closeModal}>
                                {ADD_OFFLINE_HAULER_CANCEL_BUTTON_TITLE}
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={invalid}>
                                {ADD_OFFLINE_HAULER_SUBMIT_BUTTON_TITLE}
                            </PrimaryButton>
                        </ButtonsGroup>
                    </form>
                </Box>
                <ErrorNotification message={error} config={{ autoHideDuration: 5000 }} />
            </AppModal>
        </React.Fragment>
    );
};

AddOfflineHauler.propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

const onSubmit = (values, dispatch) => dispatch(addOfflineHauler(values));

export default reduxForm({
    form: ADD_HAULER_FORM,
    initialValues: {
        companyType: { label: COMPANY_TYPES[COMPANY_TYPE_TRUCKER], value: COMPANY_TYPE_TRUCKER },
        accountType: { label: ACCOUNT_TYPES[FREE], value: FREE },
        poType: { label: PO_TYPES[PO_TYPE_AGG_DIRT_SAND], value: PO_TYPE_AGG_DIRT_SAND },
        timezone: { label: TIME_ZONES[TIME_ZONE_US_EASTERN], value: TIME_ZONE_US_EASTERN },
    },
    onSubmit,
})(AddOfflineHauler);
