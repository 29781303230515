import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";

import { SecondaryButton } from "../../core/buttons/secondaryButton";

export default function HaulerFilterList({ companies }) {
    return (
        <div>
            <Grid item xs={12} md={12}>
                <div>
                    <List>
                        {companies.map(({ id, name, defaultRate }) => {
                            return (
                                <>
                                    <ListItem key={id}>
                                        <ListItemText primary={name} />
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </div>
            </Grid>
        </div>
    );
}
