import React from "react";

import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TRUCK_ITEM_ICONS } from "../../styles/icons";

export const TRUCK_ITEM_ICON_SIZE = 25;

const useStyles = makeStyles((theme) => ({
    truckItemIcon: ({ status }) => {
        return {
            ...TRUCK_ITEM_ICONS.truckIcon(status),
            marginLeft: 8,
            width: TRUCK_ITEM_ICON_SIZE,
            height: TRUCK_ITEM_ICON_SIZE,
        };
    },
}));

const TruckItemIcon = ({ status }) => {
    const classes = useStyles({ status });
    return <Icon className={classes.truckItemIcon} />;
};

export default TruckItemIcon;
