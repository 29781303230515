import React from "react";
import Container from "@material-ui/core/Container";
import AccordionComponent from "../components/core/accordion";
import { PAGE_GLOBAL_STYLES } from "../styles/reusableStyles";
import { EmptySubNav } from "../components/jobs/jobComponents/jobsSubNav";

const Faq = () => {
    const classes = PAGE_GLOBAL_STYLES();

    return (
        <div>
            <EmptySubNav />
            <Container className={classes.globalPageStyles}>
                <br />
                <h1 className="--text-center">Resource Center</h1>
                <br />

                <h2 className="--text-left">Dashboard</h2>
                <br />
                <AccordionComponent
                    accordionItems={[
                        {
                            title: "View your day at a glance",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Dashboard utilities provide an easy way to quickly monitor various
                                                aspects of your busy day
                                            </li>
                                            <br />
                                            <li>Easily view job and fleet progress with job and ticket statistics</li>
                                            <br />
                                            <li>
                                                Have complete visibility into fleet activity and ETAs with the live map
                                            </li>
                                            <br />
                                            <li>
                                                Make job assignments changes quickly through the truck activity pane by
                                                dragging and dropping to rearrange a truck's assignments
                                            </li>
                                            <br />
                                            <li>
                                                Search and view any activity from the day with an auto-updating
                                                notification feed
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Dashboard.gif"
                                                alt="Dashboard"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                    ]}
                />
                <br />
                <br />
                <h2 className="--text-left">Jobs</h2>
                <br />
                <AccordionComponent
                    accordionItems={[
                        {
                            title: "Get the work started by creating new jobs",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Select a project to initiate job creation and select the date and time
                                                (this defaults to the company and user preferences)
                                            </li>
                                            <br />
                                            <li>Add the desired quantity or choose unlimited for all day work</li>
                                            <br />
                                            <li>
                                                Point of contact, pick-up, drop-off, and options are all pulled from the
                                                project payload but can be edited at job creation
                                            </li>
                                            <br />
                                            <li>
                                                Add your own trucks or haulers by dragging them into the trucks area
                                            </li>
                                            <br />
                                            <li>
                                                Keep everyone on the same page with job notes visible to all team
                                                members and haulers
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Creat+a+job+and+dispatch.gif"
                                                alt="Create a job"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                        {
                            title: "Put your fleet to work",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Job cards show you all the relevant details for the job - price, number
                                                of trucks needed, and amount of material to be moved
                                            </li>
                                            <br />
                                            <li>
                                                If a job is right for you, fill the job with the requested amount of
                                                trucks by clicking the fill button and dragging the appropriate trucks
                                                into the truck area
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Fill+a+job.gif"
                                                alt="Fill a job"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                    ]}
                />
                <br />
                <br />

                <h2 className="--text-left">Projects & Sites</h2>
                <br />
                <AccordionComponent
                    accordionItems={[
                        {
                            title: "Set up a new project",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Add a project name, number (if you use an internal company number), and
                                                notes that are available to all drivers, dispatchers, and users
                                                associated with this project
                                            </li>
                                            <li>
                                                Add a foreman/point of contact from your users who will be listed for
                                                driver assistance
                                            </li>
                                            <li>
                                                Hover over the project options to pull up an info button that will
                                                explain that option
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Add+a+project.gif"
                                                alt="Add a project"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                        {
                            title: "Create and edit project sites",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Use either an address or the map to drop a pin on the exact location of
                                                the site
                                            </li>
                                            <br />
                                            <li>
                                                You have an option to create a geometric alert zone after site creation
                                            </li>
                                            <br />
                                            <li>Site notes are available to the driver in the app</li>
                                            <br />
                                            <li>Site types are: quarry, dump, plant, job, or other</li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Add+a+site.gif"
                                                alt="Add Site"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                        {
                            title: "Monitor site activity in real-time with alert zones",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                After clicking the add zone button, click the outer edges of the zone
                                                and end on the original point
                                            </li>
                                            <br />
                                            <li>You can create multiple alert zones for the same site</li>
                                            <br />
                                            <li>
                                                Alert zones allow you to track the enter zone time, exit zone time, and
                                                the total time spent in the zone
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Add+an+alert+zone.gif"
                                                alt="Add alert Zone"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                        {
                            title: "Specify project needs",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Complete the pop-up form with truck preference, pick-up and drop-off,
                                                material, unit of measure (UOM), price per UOM, and quantity for the
                                                entirety of the project
                                            </li>
                                            <br />
                                            <li>
                                                The infinity button will create an unlimited job (all day, no cap to
                                                quantity)
                                            </li>
                                            <br />
                                            <li>Start and end dates for the payload are optional</li>
                                            <br />
                                            <li>
                                                Add fields allows you to create more UOM measurements and costs;
                                                optional
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Add+a+payload.gif"
                                                alt="Add payload"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                    ]}
                />
                <br />
                <br />
                <h2 className="--text-left">My Company</h2>
                <br />
                <AccordionComponent
                    accordionItems={[
                        {
                            title: "Manage your team",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>Username and password are used for login</li>
                                            <br />
                                            <li>
                                                The trucker role is further broken down into dispatcher (can view
                                                portal) and driver (only able to use TruckIT app)
                                            </li>
                                            <br />
                                            <li>
                                                The Other Action button on the right-hand side allows you to edit,
                                                archive (remove access to account), or send a message to that user
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Create+a+user.gif"
                                                alt="Create a user"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                        {
                            title: "Manage your fleet",
                            content: (
                                <React.Fragment>
                                    <div style={{ display: "flex" }}>
                                        <ul style={{ float: "right", maxWidth: 400 }}>
                                            <br />
                                            <br />
                                            <br />
                                            <li>
                                                Truck type is used to estimate load tonnages and filter depending on job
                                                requirements
                                            </li>
                                            <br />
                                            <li>
                                                The Other Action button on the right-hand side allows you to edit,
                                                archive (remove it from your dispatch list), or find the current
                                                location of that truck
                                            </li>
                                        </ul>
                                        <div style={{ maxWidth: 1024, marginLeft: 20 }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src="https://ti-faq.s3.amazonaws.com/Create+a+truck.gif"
                                                alt="Create truck"
                                                width="1024"
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ),
                        },
                    ]}
                />
                <br />
                <br />
            </Container>
        </div>
    );
};

export default Faq;
