/* global google */
import React, { useEffect, useState } from "react";
import {
    GOOGLE_MAPS_REDUCED_TRUCK_ICON_SIZE,
    GOOGLE_MAPS_SITE_ICON_SIZE,
    GOOGLE_MAPS_URL,
} from "../../constants/global";
import _ from "lodash";
import CustomMarker from "../global/maps/customMarker";
import { getTrucksForMap } from "../../helpers/global";
import { TRUCK_STATUS_AT_PICK_UP } from "../../constants/maps";
import { getDirectionMapIcon, getMarkerInfo } from "../../helpers/mapDirectionsHelpers";

const { compose, withProps } = require("recompose");
const { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer } = require("react-google-maps");

// https://developers.google.com/maps/documentation/directions/usage-and-billing
// 25 waypoints, 1 origin, 1 destination
export const MAX_COORDINATES_AMOUNT = 27;

const MapWithDirections = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
)(({ coordinates, locationsMarkers, truckLocation, handleMapRenderError }) => {
    const [directions, setDirections] = useState([]);

    useEffect(() => {
        const DirectionsService = new google.maps.DirectionsService();

        const origin = new google.maps.LatLng(...coordinates.shift());
        const destination = new google.maps.LatLng(...coordinates.pop());
        const waypoints = coordinates.map((coordinate) => {
            return {
                location: new google.maps.LatLng(...coordinate),
            };
        });

        DirectionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: waypoints,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    setDirections([result]);
                } else {
                    handleMapRenderError && handleMapRenderError();
                }
            },
        );
    }, [coordinates]);

    return (
        <GoogleMap defaultZoom={7}>
            {!_.isEmpty(directions) &&
                directions.map((direction, index) => {
                    return (
                        <DirectionsRenderer
                            key={index}
                            directions={direction}
                            options={{ suppressMarkers: true, markerOptions: new google.maps.Marker() }}
                        />
                    );
                })}
            {!_.isEmpty(locationsMarkers) &&
                locationsMarkers.map((location, index) => {
                    return (
                        <CustomMarker
                            key={index}
                            position={{ lat: location.latitude, lng: location.longitude }}
                            icon={{
                                scaledSize: new google.maps.Size(
                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                ),
                                url: getDirectionMapIcon(location),
                                zIndex: 3,
                            }}
                        >
                            <div>{getMarkerInfo(location, locationsMarkers)}</div>
                        </CustomMarker>
                    );
                })}
            {truckLocation && (
                <CustomMarker
                    position={truckLocation}
                    icon={{
                        scaledSize: new google.maps.Size(
                            GOOGLE_MAPS_REDUCED_TRUCK_ICON_SIZE,
                            GOOGLE_MAPS_REDUCED_TRUCK_ICON_SIZE,
                        ),
                        url: getTrucksForMap(TRUCK_STATUS_AT_PICK_UP, 0),
                        zIndex: 10,
                    }}
                />
            )}
        </GoogleMap>
    );
});

export default MapWithDirections;
