import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import clsx from "clsx";

import { ticketsListReplaceTicket } from "../../../actions/tickets";
import { closeTicket } from "../../../dataServers/tickets";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import TextInputComponent from "../../core/form/textInputComponent";
import ErrorNotification from "../../core/notification";
import { formatReduxFormErrors } from "../../../helpers/errorFormatting";
import Loader from "../../core/loader";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";

function CloseTicketForm(props) {
    const { ticketId, ticketsListReplaceTicket, handleSubmit, handleSuccess, error } = props;
    const [isLoading, setLoading] = useState(false);

    const onSubmit = (values) => {
        setLoading(true);

        return closeTicket(ticketId, { message: values.message })
            .then((response) => {
                setLoading(false);
                ticketsListReplaceTicket(response.data);
                handleSuccess && handleSuccess();
            })
            .catch((errorResponse) => {
                setLoading(false);
                formatReduxFormErrors(errorResponse);
            });
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                <Field
                    type="text"
                    name="message"
                    label="Note"
                    fieldNote="Leave a note"
                    multiline={true}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <ButtonsGroup>
                    <PrimaryButton type="submit">Close</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} />}
        </div>
    );
}

CloseTicketForm.propTypes = {
    ticketId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    ticketsListReplaceTicket: PropTypes.func.isRequired,
    error: PropTypes.any,
};

export const CLOSE_TICKET_FORM = "closeTicketForm";

export default compose(
    reduxForm({
        form: CLOSE_TICKET_FORM,
        initialValues: { message: "" },
    }),
    connect(null, (dispatch) => ({
        ticketsListReplaceTicket: (payload) => {
            dispatch(ticketsListReplaceTicket(payload));
        },
    })),
)(CloseTicketForm);
