import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import JolisTable, { IS_SUB_HAULER } from "./jolisTable";
import { change, FieldArray, formValueSelector, getFormInitialValues } from "redux-form";
import _ from "lodash";
import { connect } from "react-redux";
import { dropItemTypes, noTrucksInJobOrder } from "../../../../helpers/jobOrders";
import { useDrop } from "react-dnd";
import moment from "moment";
import clsx from "clsx";
import Loader from "../../../core/loader";
import { sortByStartDate } from "../../../../helpers/global";
import { START_DATE_NAME } from "../../../global/datesRange";
import { LOADER_WHITE_OVERLAY } from "../../../../constants/global";
import { IS_HAULER_USER, UNIT_OF_MEASURE_LOAD } from "../../../../constants/maps";
import { DRAG_AND_DROP_STYLES } from "../../../../styles/reusableStyles";
import { getSortedByTruckNameItems } from "../../../fleet/fleetList";
import { JOLI_FIELD_ID, SPECIFY_TRUCKS_PER_HAULER } from "../../constants";
import {
    DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE,
    ITEM_IS_ALREADY_IN_JOB_ORDER_MESSAGE,
    NOT_OWNER_DRAGGABLE_OVERLAY_MESSAGE,
    SELECT_ITEM_DRAGGABLE_OVERLAY_MESSAGE,
    SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE,
} from "../../../../constants/strings";

const renderTrucksTables = ({
    fields,
    jobOrder,
    truckQtyWasChanged,
    truckStartDateWasChanged,
    unitOfMeasure,
    unlimited,
    specifyNumberOfTrucksHandler,
    removeTruckById,
    specifyTrucksPerHauler,
    form,
    pastJobOrderDateSelected,
}) => {
    return (
        <JolisTable
            joliItems={fields}
            jobOrder={jobOrder}
            unlimited={unlimited}
            unitOfMeasure={unitOfMeasure}
            specifyTrucksPerHauler={specifyTrucksPerHauler}
            truckQtyWasChanged={truckQtyWasChanged}
            removeTruckById={removeTruckById}
            truckStartDateWasChanged={truckStartDateWasChanged}
            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
            form={form}
            editMode={true}
            pastJobOrderDateSelected={pastJobOrderDateSelected}
        />
    );
};

renderTrucksTables.propTypes = {
    fields: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    truckQtyWasChanged: PropTypes.func,
    removeTruckById: PropTypes.func.isRequired,
    truckStartDateWasChanged: PropTypes.func,
    itemQuantityWasChanged: PropTypes.func,
    specifyNumberOfTrucksHandler: PropTypes.func.isRequired,
    unlimited: PropTypes.bool,
    form: PropTypes.string,
    specifyTrucksPerHauler: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const sortForDisplay = (items) => {
    const jolis = [];

    items.forEach((joli) => {
        [
            ...joli.trucks.filter((truck) => !moment.isMoment(truck.startDate)),
            ...joli.trucks.filter((truck) => moment.isMoment(truck.startDate)),
        ].forEach((truck, truckIndex) => {
            const sameTimeJoli = jolis.findIndex((i) => {
                const joliTime = moment(i.startDate).format("LT");
                const truckTime = moment(truck.startDate).format("LT");

                return joliTime === truckTime;
            });
            const sameJoliById = jolis.find((i) => i.id === joli.id);
            if (sameTimeJoli !== -1) {
                jolis[sameTimeJoli].trucks.push(truck);

                if (!jolis[sameTimeJoli].id) {
                    jolis[sameTimeJoli].id = joli.id;
                }

                if (!jolis[sameTimeJoli].quantity) {
                    jolis[sameTimeJoli].quantity = joli.quantity;
                }
            } else {
                const data = {
                    trucks: [truck],
                    haulers: [],
                    quantity: sameJoliById ? null : joli.quantity,
                    id: sameJoliById ? null : joli.id,
                    startDate: truck.startDate,
                };
                jolis.push(data);
            }
        });

        [
            ...joli.haulers.filter((truck) => !moment.isMoment(truck.startDate)),
            ...joli.haulers.filter((truck) => moment.isMoment(truck.startDate)),
        ].forEach((truck, truckIndex) => {
            const sameTimeJoli = jolis.findIndex((i) => {
                const joliTime = moment(i.startDate).format("LT");
                const truckTime = moment(truck.startDate).format("LT");

                return joliTime === truckTime;
            });
            const sameJoliById = jolis.find((i) => i.id === joli.id);

            if (sameTimeJoli !== -1) {
                jolis[sameTimeJoli].haulers.push(truck);
                if (!jolis[sameTimeJoli].id) {
                    jolis[sameTimeJoli].id = joli.id;
                }
                if (!jolis[sameTimeJoli].quantity) {
                    jolis[sameTimeJoli].quantity = joli.quantity;
                }
            } else {
                jolis.push({
                    notAddressedTrucksCount: joli.notAddressedTrucksCount || 0,
                    trucks: [],
                    haulers: [truck],
                    quantity: sameJoliById ? null : joli.quantity,
                    id: sameJoliById ? null : joli.id,
                    startDate: truck.startDate,
                });
            }
        });
    });
    const jolisWithSortedTrucks = getSortedByTruckNameItems(jolis);

    return sortByStartDate(jolisWithSortedTrucks);
};

export const itemIsInJobOrder = (item, joliItems) => {
    const trucksList = [];
    const haulersList = [];
    const haulersTrucks = [];

    if (item) {
        if (item.isTruck) {
            joliItems.forEach((joli) => {
                trucksList.push(...joli.trucks);

                //truckShiftAllowed trucks
                joli.haulers.forEach((hauler) => {
                    haulersTrucks.push(...hauler.trucks);
                });
            });

            const trucksIdList = trucksList.map((truck) => truck.truckId);
            const haulersTrucksIdList = haulersTrucks.map((truck) => truck.truckId);

            return trucksIdList.includes(item.id) || haulersTrucksIdList.includes(item.id);
        }
        joliItems.forEach((joli) => haulersList.push(...joli.haulers));
        const haulersIdList = haulersList.map((hauler) => hauler.id);

        return haulersIdList.includes(item.id);
    }
};

const JolisTableWrapper = (props) => {
    const classes = DRAG_AND_DROP_STYLES();
    const [isLoading, setLoading] = useState(false);
    const {
        jobOrder,
        dispatch,
        form,
        account,
        showTableLoader,
        pastJobOrderDateSelected,
        formValues: {
            unlimited,
            [JOLI_FIELD_ID]: joliItems,
            [START_DATE_NAME]: joStartDate,
            unitOfMeasure,
            [SPECIFY_TRUCKS_PER_HAULER]: specifyTrucksPerHauler,
            smartDispatch,
        },
    } = props;
    let timer;
    const [{ canDrop, isOver, item }, drop] = useDrop({
        accept: dropItemTypes.TRUCK,
        drop: () => ({ name: "Job Trucks" }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
        canDrop: () => {
            if (itemIsInJobOrder(item, joliItems)) {
                return false;
            }

            return !smartDispatch;
        },
    });
    const noTrucks = noTrucksInJobOrder(joliItems);
    const itemIsAlreadyInJO = itemIsInJobOrder(item, joliItems);

    const renderJoliDraggableOverlayMessage = () => {
        if (smartDispatch) {
            return SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE;
        }
        if (itemIsAlreadyInJO) {
            return ITEM_IS_ALREADY_IN_JOB_ORDER_MESSAGE(item.isTruck);
        }
        if (IS_HAULER_USER(account.role) && !jobOrder.iAmOwner) {
            return NOT_OWNER_DRAGGABLE_OVERLAY_MESSAGE;
        }
        if (canDrop || isOver) {
            return DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE;
        }

        return SELECT_ITEM_DRAGGABLE_OVERLAY_MESSAGE;
    };

    const specifyNumberOfTrucksHandler = (add, joliIndex, truckIndex) => {
        dispatch(
            change(
                form,
                JOLI_FIELD_ID,
                _.cloneDeep(joliItems).map((joli, indexJoli) => {
                    const sumTrucks = joli.haulers.reduce(
                        (prev, currentHauler) =>
                            prev +
                            (currentHauler.assignedBy && currentHauler.assignedBy.id !== account.company.id
                                ? currentHauler.needTrucksCount
                                : 0),
                        0,
                    );
                    if (joliIndex === indexJoli) {
                        if (specifyTrucksPerHauler) {
                            // @todo add check for possible assign trucks count
                            joli.haulers = joli.haulers.map((truck, index) => {
                                // is not used as several same trucks can be added in table
                                if (index === truckIndex) {
                                    if (add) {
                                        truck.needTrucksCount = +truck.needTrucksCount + 1;
                                    } else if (+truck.needTrucksCount - sumTrucks > 1) {
                                        if (_.isEmpty(truck.trucks)) {
                                            truck.needTrucksCount = +truck.needTrucksCount - 1;
                                        } else if (+truck.needTrucksCount > truck.trucks.length) {
                                            truck.needTrucksCount = +truck.needTrucksCount - 1;
                                        }
                                    }
                                }

                                return truck;
                            });
                        } else {
                            if (add) {
                                joli.notAddressedTrucksCount = (+joli.notAddressedTrucksCount || 1) + 1;
                            } else {
                                joli.notAddressedTrucksCount = (+joli.notAddressedTrucksCount || 1) - 1;
                            }
                        }
                    }

                    return joli;
                }),
            ),
        );
    };

    const removeTruckById = (truckId, joliIndex) => {
        setLoading(true);
        const updatedJolis = _.cloneDeep(joliItems)
            .map((joli, index) => {
                if (joliIndex === index) {
                    joli.trucks = joli.trucks.filter((i) => i.truckId !== truckId);
                    joli.haulers = joli.haulers.filter((i) => i.id !== truckId);
                }

                return joli;
            })
            .filter((i) => !_.isEmpty(i.trucks) || !_.isEmpty(i.haulers));
        dispatch(change(form, JOLI_FIELD_ID, updatedJolis));
        dispatch(change(form, `${truckId}-trailers`, null));
        setLoading(false);
    };

    const truckStartDateWasChanged = (truck, joliIndex, truckIndex) => {
        const joDate = {
            year: moment(joStartDate || moment()).get("year"),
            month: moment(joStartDate || moment()).get("month"),
            date: moment(joStartDate || moment()).get("date"),
        };
        truck.startDate = moment(truck.startDate).set(joDate);
        const updatedJolis = _.cloneDeep(joliItems).map((joli, index) => {
            if (index === joliIndex) {
                if (truck.isMyFleetTruck) {
                    joli.trucks = joli.trucks.map((t, tIndex) => {
                        if (truckIndex === tIndex) {
                            t.startDate = truck.startDate;
                        }

                        return t;
                    });
                } else {
                    joli.haulers = joli.haulers.map((t, tIndex) => {
                        const startTime = truck.startDate;

                        if (truckIndex === tIndex) {
                            t.startDate = startTime;
                        }

                        if (
                            IS_SUB_HAULER({
                                ...t,
                                ownerCompanyId: jobOrder.company && jobOrder.company.id,
                            })
                        ) {
                            const parent = joli.haulers.find((i) => t.assignedBy.id === i.id);

                            if (parent && parent.id === truck.id) {
                                t.startDate = startTime;
                            }
                        }

                        return t;
                    });
                }
            }

            return joli;
        });

        let reorganisedJolis = updatedJolis;

        // https://github.com/TruckITllc/truckit-frontend/issues/273#issuecomment-606177427
        if (unitOfMeasure !== UNIT_OF_MEASURE_LOAD) {
            reorganisedJolis = sortForDisplay(updatedJolis);
        } else {
            // as joli can has only one truck or hauler joli time should be same as truck time
            const jolisWithTruckTime = reorganisedJolis.map((joli, index) => {
                if (joliIndex === index) {
                    joli.startDate = truck.startDate;
                }

                return joli;
            });

            reorganisedJolis = sortByStartDate(jolisWithTruckTime);
        }

        setLoading(true);

        timer = setTimeout(() => {
            dispatch(change(form, JOLI_FIELD_ID, reorganisedJolis));
            setLoading(false);
        }, 0);
    };

    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={clsx((isLoading || showTableLoader) && LOADER_WHITE_OVERLAY)}>
            {(isLoading || showTableLoader) && <Loader />}
            <div className={clsx(classes.dragAndDrop)}>
                <div ref={drop} className="draggable-overlay-wrap">
                    {/*contractor can't add trucks or haulers on dispatch*/}
                    {(canDrop || isOver || noTrucks || itemIsAlreadyInJO) && (
                        <div className="draggable-overlay">
                            <p className="draggable-text --text-center">{renderJoliDraggableOverlayMessage()}</p>
                        </div>
                    )}
                    <FieldArray
                        name={JOLI_FIELD_ID}
                        unlimited={unlimited}
                        rerenderOnEveryChange
                        specifyTrucksPerHauler={specifyTrucksPerHauler}
                        unitOfMeasure={unitOfMeasure}
                        truckStartDateWasChanged={truckStartDateWasChanged}
                        removeTruckById={removeTruckById}
                        specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                        jobOrder={jobOrder}
                        form={form}
                        pastJobOrderDateSelected={pastJobOrderDateSelected}
                        component={renderTrucksTables}
                    />
                </div>
            </div>
        </div>
    );
};

JolisTableWrapper.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    disableForHauler: PropTypes.bool,
    SPECIFY_TRUCKS_PER_HAULER: PropTypes.bool,
    showTableLoader: PropTypes.bool,
    initialValues: PropTypes.object.isRequired,
    pastJobOrderDateSelected: PropTypes.bool,
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);
        const formInitialValuesSelector = getFormInitialValues(props.form);

        const unitOfMeasure = formValueSelector(props.form)(state, "unitOfMeasure");

        const formValues = formSelector(
            state,
            "unlimited",
            "id",
            JOLI_FIELD_ID,
            START_DATE_NAME,
            "unitOfMeasure",
            SPECIFY_TRUCKS_PER_HAULER,
            "smartDispatch",
        );

        return {
            account: state.account,
            showTableLoader: state.jobOrders.showTableLoader,
            formValues: { ...formValues, unitOfMeasure: unitOfMeasure?.id || unitOfMeasure },
            initialValues: formInitialValuesSelector(state),
        };
    })(JolisTableWrapper),
);
