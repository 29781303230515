import React, { useEffect, useState } from "react";
import { change, Field, reduxForm, reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import { Cached } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TocIcon from "@material-ui/icons/Toc";

import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getHasSubCompanies, getSubCompaniesLoadOptions, getOtherCompanyUserData } from "../../actions/companies";
import { changeCurrentUser } from "../../actions/globalActions";
import { PREVIOUS_ACCOUNT_DATA_STORAGE_KEY } from "../../helpers/api";
import { selectAccount } from "../../selectors";
import { selectHasSubCompanies } from "../../selectors/companies";
import { IS_INSPECTOR_USER } from "../../constants/maps";
import { ROUTE_DASHBOARD, ROUTE_MY_COMPANY } from "../../routes/globalRoutes";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import { NO_DEFAULT_USER_MESSAGE } from "../../constants/strings";

const useStyles = makeStyles((theme) => ({
    companySelectContainer: {
        padding: "20px",
        width: "400px",
    },
    loadingContainer: {
        width: "100vw",
        height: "100vh",
    },
}));

const NavigationLoginAs = ({ form, setError }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const account = useSelector(selectAccount);
    const hasSubCompanies = useSelector(selectHasSubCompanies);

    const [showLoginAsMenu, setShowLoginAsMenu] = useState(false);
    const loginAsAnchorRef = React.useRef(null);

    const loadOptions = async (inputValue, { params = {}, loadedCount }) => {
        return await dispatch(getSubCompaniesLoadOptions(inputValue, loadedCount, params));
    };

    const onClickAway = (e) => {
        if (!(e.target.localName === "span") && e.target.id !== "loginAs") {
            setShowLoginAsMenu(!showLoginAsMenu);
            dispatch(reset(form));
        }
    };

    const onSubCompanyChange = async (subCompany) => {
        const masterUser = subCompany.masterUsers[0];

        if (!masterUser) {
            return setError([NO_DEFAULT_USER_MESSAGE]);
        }

        try {
            const newAccountData = await dispatch(getOtherCompanyUserData(masterUser.id));
            if (newAccountData) {
                dispatch(change(form, LOGIN_AS_FIELD, subCompany));
                const routeToRedirect = newAccountData.company.hasOwnFleet
                    ? ROUTE_DASHBOARD
                    : ROUTE_MY_COMPANY.MY_FLEET;
                dispatch(changeCurrentUser(newAccountData, routeToRedirect));
                localStorage.setItem(PREVIOUS_ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(account));
            }
        } catch (error) {
            setError([PROCESS_SERVER_ERROR(error)]);
        }
    };

    useEffect(() => {
        if (!IS_INSPECTOR_USER(account.role)) {
            dispatch(getHasSubCompanies(setError));
        }
    }, []);

    if (!hasSubCompanies) {
        return null;
    }

    return (
        <>
            <IconButton
                id="loginAs"
                title="Login As"
                onClick={() => setShowLoginAsMenu(!showLoginAsMenu)}
                ref={loginAsAnchorRef}
            >
                <Cached id="loginAs" />
            </IconButton>
            <Popper open={showLoginAsMenu} anchorEl={loginAsAnchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={onClickAway}>
                                <div className={classes.companySelectContainer}>
                                    <Field
                                        name={LOGIN_AS_FIELD}
                                        startAdornment={<TocIcon />}
                                        loadOptions={loadOptions}
                                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                        placeholder="Select company to login as"
                                        component={AsyncAutocompleteComponent}
                                        onFieldChange={onSubCompanyChange}
                                    />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export const LOGIN_AS_FORM = "loginAsForm";
export const LOGIN_AS_FIELD = "loginAsCompany";

export default reduxForm({
    form: LOGIN_AS_FORM,
    destroyOnUnmount: false,
})(NavigationLoginAs);
