import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    fieldNote: {
        fontSize: 10,
        color: theme.palette.secondary.superDark,
        marginLeft: 14,
        marginTop: 3,

        "&.error": {
            color: theme.palette.general.darkishRed,
        },
    },
}));

const Component = (props) => {
    const { fieldNote, isError, textAlign, classes, className } = props;

    return (
        <div>
            {fieldNote && (
                <Typography align={textAlign} className={clsx(classes.fieldNote, isError && "error", className)}>
                    {fieldNote}
                </Typography>
            )}
        </div>
    );
};

Component.propTypes = {
    fieldNote: PropTypes.any,
    isError: PropTypes.bool.isRequired,
    textAlign: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

Component.defaultProps = {
    isError: false,
    textAlign: "left",
};

const FieldNote = (props) => {
    const classes = useStyles();

    return <Component {...props} classes={classes} />;
};

export default FieldNote;
