import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const dividerStyles = makeStyles((theme) => ({
    divider: {
        width: "100%",
        height: 5,
        backgroundColor: theme.palette.secondary.superLight,
    },
}));

export const Divider = (props) => {
    const classes = dividerStyles();
    const { marginTop, marginBottom, style } = props;

    return <Box {...props} marginTop={marginTop} marginBottom={marginBottom} style={style} className={classes.divider} />;
};

Divider.propTypes = {
    marginBottom: PropTypes.number,
    marginTop: PropTypes.number,
};

Divider.defaultProps = {
    marginTop: 2,
    marginBottom: 2,
};

const dividerThinStyles = makeStyles((theme) => ({
    dividerThin: {
        width: "100%",
        height: 1,
        backgroundColor: theme.palette.secondary.main,
        padding: "0px !important",
    },
    verticalRoot: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const DividerThin = (props) => {
    const classes = dividerThinStyles();
    const { marginTop, marginBottom, isVertical, className } = props;

    return (
        <Box
            marginTop={marginTop}
            marginBottom={marginBottom}
            className={clsx(isVertical ? classes.verticalRoot : classes.dividerThin, className)}
        />
    );
};

DividerThin.propTypes = {
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isVertical: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

DividerThin.defaultProps = {
    marginTop: 2,
    marginBottom: 2,
    isVertical: false,
};
