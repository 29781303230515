import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { DividerThin } from "../core/divider";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import Loader from "../core/loader";
import _ from "lodash";
import List from "@material-ui/core/List";
import ListWaypoint from "../core/listWaypoint";
import ListItem from "@material-ui/core/ListItem";
import NotificationLabelListItem from "../global/notificationLabelListItem";
import ErrorNotification from "../core/notification";
import Grid from "@material-ui/core/Grid";
import NotificationFeedForm, { DASHBOARD_FORM } from "./notificationFeedForm";
import {
    getNotificationEvents,
    setNotificationsFilters,
    setNotificationsRequestedFilters,
} from "../../actions/notifications";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { formValueSelector } from "redux-form";
import { toDate } from "../../helpers/date";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { ALERT_NOTIFICATIONS, HIGHLIGHT_NOTIFICATIONS } from "../../constants/maps";

const useStyles = makeStyles((theme) => ({
    highlightNotifications: {
        backgroundColor: theme.palette.primary.superLight,
    },
    alertNotification: {
        backgroundColor: theme.palette.general.softPink,
    },
}));

const NotificationFeedList = React.memo(
    (props) => {
        const {
            notificationEventsLoading,
            notificationEvents,
            notificationEventsCount,
            notificationEventsFilters,
            notificationEventsRequestedFilters,
            account,
            notificationEventsError,
            setFilters,
            formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate, search },
        } = props;

        const classes = useStyles();
        const dispatch = useDispatch();
        const updateListByFilters = (filters) => {
            let newFilters = {
                ..._.cloneDeep(filters),
                startDate: startDate.format(SERVER_DATE_FORMAT),
                endDate: endDate.format(SERVER_DATE_FORMAT),
                keywords: search === "" ? null : search,
            };
            if (!_.isEqual(notificationEventsFilters, newFilters)) {
                setFilters(newFilters);
            }
        };
        const getMoreNotificationEvents = (concat) =>
            dispatch(getNotificationEvents(concat, notificationEventsFilters));

        useEffect(() => {
            if (
                !_.isEmpty(notificationEventsFilters) &&
                !_.isEqual(notificationEventsRequestedFilters, notificationEventsFilters)
            ) {
                dispatch(getNotificationEvents(false, notificationEventsFilters));
                dispatch(setNotificationsRequestedFilters(notificationEventsFilters));
            }

            return () => {
                dispatch(setNotificationsRequestedFilters(undefined));
            };
        }, [notificationEventsFilters]);

        const memoizedUpdateListByFilters = useCallback(
            (filters, loadedFilters) => {
                return loadedFilters && updateListByFilters(filters);
            },
            [notificationEventsFilters, search],
        );

        return (
            <Grid item xs={3} component="div">
                <h3>NOTIFICATION FEED</h3>
                <DividerThin marginBottom={0} />
                <NotificationFeedForm
                    updateListByFilters={memoizedUpdateListByFilters}
                    initialValues={{
                        search: null,
                        [START_DATE_NAME]: toDate({
                            date: moment(),
                            timezone: account.timezone,
                        }),
                        [END_DATE_NAME]: toDate({
                            date: moment(),
                            timezone: account.timezone,
                        }),
                    }}
                />
                <div className={clsx(notificationEventsLoading && LOADER_WHITE_OVERLAY)}>
                    {notificationEventsLoading && <Loader />}
                    {!_.isEmpty(notificationEvents) && (
                        <List
                            style={{
                                paddingTop: 0,
                                maxHeight: 500,
                                overflowY: "scroll",
                                paddingRight: 5,
                            }}
                        >
                            {notificationEvents.map((notificationEvent, index) => {
                                const isHighlightNotification = HIGHLIGHT_NOTIFICATIONS.includes(
                                    notificationEvent.type,
                                );
                                const isAlertNotification = ALERT_NOTIFICATIONS.includes(notificationEvent.type);

                                return (
                                    <React.Fragment key={index}>
                                        <ListWaypoint
                                            fetchMore={getMoreNotificationEvents}
                                            index={index}
                                            lastPosition={5}
                                            items={notificationEvents}
                                            itemsCount={notificationEventsCount}
                                        />
                                        <ListItem
                                            alignItems="flex-start"
                                            className={clsx(
                                                isHighlightNotification && classes.highlightNotifications,
                                                isAlertNotification && classes.alertNotification,
                                            )}
                                        >
                                            {notificationEvent && (
                                                <NotificationLabelListItem
                                                    notificationEvent={notificationEvent}
                                                    className="notification-label"
                                                />
                                            )}
                                        </ListItem>
                                        <DividerThin marginBottom={0} marginTop={0} />
                                    </React.Fragment>
                                );
                            })}
                        </List>
                    )}
                </div>
                {notificationEventsError && <ErrorNotification message={notificationEventsError} />}
                {!notificationEventsLoading && _.isEmpty(notificationEvents) && (
                    <React.Fragment>
                        <br />
                        <br />
                        <br />
                        <h3 className="--text-center">No notifications found</h3>
                    </React.Fragment>
                )}
            </Grid>
        );
    },
    (prev, nextProps) => {
        return (
            _.isEqual(prev.notificationEvents, nextProps.notificationEvents) &&
            _.isEqual(prev.notificationEventsFilters, nextProps.notificationEventsFilters) &&
            _.isEqual(prev.notificationEventsLoading, nextProps.notificationEventsLoading) &&
            _.isEqual(prev.notificationEventsError, nextProps.notificationEventsError) &&
            _.isEqual(prev.formValues, nextProps.formValues)
        );
    },
);

NotificationFeedList.propTypes = {
    notificationEventsLoading: PropTypes.bool.isRequired,
    notificationEventsError: PropTypes.string,
    notificationEventsCount: PropTypes.number,
    notificationEventsFilters: PropTypes.object.isRequired,
    notificationEventsRequestedFilters: PropTypes.object,
    setFilters: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    notificationEvents: PropTypes.array.isRequired,
};

export default withRouter(
    connect(
        (state) => {
            const formSelector = formValueSelector(DASHBOARD_FORM);

            return {
                account: state.account,
                notificationEvents: state.notifications.notificationEvents,
                notificationEventsFilters: state.notifications.notificationFilters,
                notificationEventsRequestedFilters: state.notifications.notificationRequestedFilters,
                notificationEventsCount: state.notifications.notificationEventsCount,
                notificationEventsLoading: state.notifications.notificationEventsLoading,
                notificationEventsError: state.notifications.notificationEventsError,
                formValues: formSelector(state, START_DATE_NAME, END_DATE_NAME, "search"),
            };
        },
        (dispatch) => ({
            setFilters: (payload) => {
                dispatch(setNotificationsFilters(payload));
            },
        }),
    )(NotificationFeedList),
);
