import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";

import { getLabelForWeatherItems } from "../../helpers/global";

const useStyles = makeStyles((theme) => ({
    weather: {
        height: "100%",
    },
    infoBox: {
        height: "100%",
        textAlign: "center",

        "& .temperature-box": {
            marginTop: 10,
            fontSize: "2.5vw",
            fontWeight: theme.typography.fontWeightLight,
            color: theme.palette.text.secondary.dark,
        },
    },
}));

const WhetherForecast = (props) => {
    const { weatherToday, system } = props;
    const { condition, humidity, location, maxTemp, minTemp, totalPrecip, windSpeed } = weatherToday;
    const classes = useStyles();
    const today = moment();

    return (
        <Grid container alignItems={"center"} justify={"center"} className={clsx(classes.weather)}>
            <Grid item component="div" className={clsx(classes.infoBox)}>
                <Grid container alignItems={"center"} justify={"center"}>
                    <img style={{ maxWidth: 50 }} src={`http:${condition.icon}`} />
                    <h2>{`${today.format("dddd").toUpperCase()} - ${today.format("ll")}`}</h2>
                </Grid>
                <label className="temperature-box">
                    {`${minTemp.toFixed(1)}°${getLabelForWeatherItems(system, { minTemp })} - 
                      ${maxTemp.toFixed(1)}°${getLabelForWeatherItems(system, { maxTemp })}`}
                </label>
                <Grid container component="div" alignItems={"center"} justify={"center"}>
                    <label className="weather-data" style={{ marginRight: 15 }}>
                        {`${humidity.toFixed(1)}% Humidity`}
                    </label>
                    <label className="weather-data">
                        {`${windSpeed.toFixed(1)} ${getLabelForWeatherItems(system, { windSpeed })} Winds`}
                    </label>
                </Grid>
                <Grid container component="div" alignItems={"center"} justify={"center"}>
                    <label className="weather-data">
                        {`${totalPrecip.toFixed(1)} ${getLabelForWeatherItems(system, { totalPrecip })} Precipitation`}
                    </label>
                </Grid>
            </Grid>
        </Grid>
    );
};

WhetherForecast.propTypes = {
    weatherToday: PropTypes.object.isRequired,
};

export default withRouter(WhetherForecast);
