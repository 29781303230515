import React from "react";
import { compose } from "redux";
import { useSelector } from "react-redux";
import { Field, FieldArray, formValueSelector, getFormSubmitErrors, reduxForm } from "redux-form";
import clsx from "clsx";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";

import TextInputComponent from "../../core/form/textInputComponent";
import { ButtonsGroup, PrimaryButton, SecondaryButton } from "../../core";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";
import { Validation } from "../../../helpers/validation";
import ErrorNotification from "../../core/notification";
import { updateTicket } from "../../../actions/tickets";
import { UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD } from "../../../constants/maps";
import { useTicketExists } from "../../../hooks/useTicketExists";
import TrailersWeight from "./trailersWeight";
import ExistedTicketWarning from "./ExistedTicketWarning";
import CustomSwitch from "../../core/form/customSwitch";
import AdditionalExternalRefs from "./additionalExternalRefs";
import { selectHasAdditionalExternalRefs } from "../../../reducers/additionalExternalRefsReducer";

const UpdateTicketForm = ({
    handleSubmit,
    closeModal,
    ticketForUpdate,
    submitting,
    error,
    loadTickets,
    dispatch,
    form,
    change,
}) => {
    const {
        id: ticketId,
        unitOfMeasure,
        joLineItem,
        externalRef: initialExtRef,
        isAirTicketLite,
        isSubticket,
    } = ticketForUpdate;

    const submitErrors = useSelector(getFormSubmitErrors(UPDATE_TICKET_FORM));
    const externalRef = useSelector((state) => formValueSelector(UPDATE_TICKET_FORM)(state, "externalRef"));
    const trailersQuantity = useSelector((state) => formValueSelector(form)(state, "trailersQuantity"));
    const hasAdditionalExternalRefs = useSelector(selectHasAdditionalExternalRefs);

    const exists = useTicketExists(
        externalRef,
        joLineItem?.project.id,
        joLineItem?.project.createDate,
        ticketId,
        initialExtRef,
    );
    const isLoadTicket = unitOfMeasure.id === UNIT_OF_MEASURE_LOAD;
    const isHourTicket = unitOfMeasure.id === UNIT_OF_MEASURE_HOUR;
    const showAdditionalExternalRefs = hasAdditionalExternalRefs && !isHourTicket && !isSubticket && !externalRef;

    const onSubmit = async (values) => {
        await dispatch(updateTicket(ticketId, values, isLoadTicket, ticketForUpdate));
        loadTickets();
        closeModal();
    };

    return (
        <div className={clsx(submitting && LOADER_WHITE_OVERLAY)}>
            {submitting && <Loader />}
            <h1 className="title">{"Edit Ticket"} </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*<TrailersWeight change={change} unitOfMeasure={unitOfMeasure} form={form} submitErrors={submitErrors} />*/}
                <Field
                    validate={[Validation.isNumber, Validation.required]}
                    name="quantity"
                    label={"Quantity"}
                    component={TextInputComponent}
                    endAdornment={`${unitOfMeasure.name}s`.toUpperCase()}
                    error={submitErrors.quantity}
                />
                <br />
                <br />
                {showAdditionalExternalRefs ? (
                    <FieldArray name="additionalExternalRefs" form={form} component={AdditionalExternalRefs} />
                ) : (
                    <>
                        <Field
                            name="externalRef"
                            label="Ticket Number"
                            component={TextInputComponent}
                            error={submitErrors.externalRef}
                        />
                        <ExistedTicketWarning exists={exists} />
                    </>
                )}
                <br />
                <br />
                <Grid container alignItems={"center"} justify={"flex-end"} spacing={2}>
                    <Grid item xs={6}>
                        <Field
                            type="text"
                            validate={[Validation.noSpecialSymbols]}
                            name="phaseCode"
                            label={"Phase Code"}
                            component={TextInputComponent}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field name="phaseCodeQuantity" type="text" label="Quantity" component={TextInputComponent} />
                    </Grid>
                </Grid>
                <br />
                {!isAirTicketLite && (
                    <Grid container>
                        <Grid item xs={6}>
                            <Field component={CustomSwitch} name="isDuplicate" label="Is Duplicate" type="checkbox" />
                        </Grid>
                    </Grid>
                )}

                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {error && <ErrorNotification message={error} config={{ autoHideDuration: 5000 }} />}
        </div>
    );
};

export const UPDATE_TICKET_FORM = "updateTicketForm";

const validate = ({ quantity, trailersQuantity }) => {
    const errors = {
        trailersQuantity: trailersQuantity ? trailersQuantity.map((item) => ({})) : [],
    };
    if (
        trailersQuantity &&
        trailersQuantity.length &&
        trailersQuantity.filter((item) => !!item.quantity).length &&
        quantity
    ) {
        const sumTrailersQuantity = trailersQuantity.reduce((acc, { quantity }) => acc + +quantity, 0);
        if (+quantity !== +sumTrailersQuantity)
            errors.quantity = "Different quantity in the ticket and quantity in trailers";
    }

    if (trailersQuantity && trailersQuantity.length && trailersQuantity.filter((item) => !!item.quantity).length) {
        trailersQuantity.map(({ quantity }, index) => {
            if (!quantity) {
                errors.trailersQuantity[index].quantity = "Required";
            }
        });
    }

    return errors;
};

export default compose(
    reduxForm({
        form: UPDATE_TICKET_FORM,
        validate,
    }),
)(UpdateTicketForm);
