import React, { useState } from "react";
import AppModal from "../core/modal";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CustomCheckbox from "../core/form/customCheckbox";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { emitUserLoggedOutEvent, updateUserTermsAndPermissionsStatus } from "../../actions/globalActions";
import ErrorNotification from "../core/notification";
import { agreeWithTermsAndConditions } from "../../dataServers/user";
import { ACCOUNT_DATA_STORAGE_KEY } from "../../helpers/api";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import { GLOBAL_ENDPOINTS } from "../../constants/endpoints";

const TermsDialog = (props) => {
    const dispatch = useDispatch();
    const { handleSubmit, isAccepted, handleClose, termsDialogOpen } = props;
    const [errorMessage, setErrorMessage] = useState(false);

    const onFormSubmit = () => {
        agreeWithTermsAndConditions()
            .then(() => {
                const currentData = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));
                const newDate = {
                    ...currentData,
                    hasAgreedToLatestTC: true,
                };
                localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(newDate));
                dispatch(updateUserTermsAndPermissionsStatus());
            })
            .catch((error) => {
                setErrorMessage(PROCESS_SERVER_ERROR(error));
            });

        handleClose && handleClose();
    };

    const closeModal = () => {
        emitUserLoggedOutEvent();
        setErrorMessage("To use app you must accept terms and conditions.");
        handleClose && handleClose();
    };

    return (
        <AppModal
            isOpen={termsDialogOpen}
            form={CONFIRM_TERMS_FORM_ID}
            modalStyles={{ width: 800 }}
            closeModal={closeModal}
        >
            <h2 className="title">{"To use website you have to accept these Terms And Conditions"}</h2>
            <iframe
                title="Terms and Conditions"
                src={GLOBAL_ENDPOINTS.TERMS_AND_CONDITIONS(Math.random())}
                frameBorder="0"
                height={300}
                className="iframe --full-width"
                id={"iframe"}
            />
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Field
                    name="acceptTermsAndConditions"
                    type="checkbox"
                    label="I accept these Terms and Conditions."
                    component={CustomCheckbox}
                />
                <ButtonsGroup>
                    <SecondaryButton type="button" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={!isAccepted}>
                        Approve
                    </PrimaryButton>
                </ButtonsGroup>
            </form>
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
        </AppModal>
    );
};

TermsDialog.propTypes = {
    isAccepted: PropTypes.bool.isRequired,
    termsDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export const CONFIRM_TERMS_FORM_ID = "confirmTermsFormId";

export default compose(
    reduxForm({
        form: CONFIRM_TERMS_FORM_ID,
        fields: ["acceptTermsAndConditions"],
    }),
    connect((state, props) => {
        const formSelector = formValueSelector(CONFIRM_TERMS_FORM_ID);

        return {
            isAccepted: formSelector(state, "acceptTermsAndConditions"),
        };
    }),
)(TermsDialog);
