import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const radiusIncrement = 2;

const linearProgressStyles = makeStyles((theme) => ({
    linearProgress: {
        width: "100%",
        height: 7,
        backgroundColor: "transparent",
        borderTopLeftRadius: theme.shape.borderRadius * radiusIncrement,
        borderTopRightRadius: theme.shape.borderRadius * radiusIncrement,
        marginBottom: 13,
        position: "relative",

        "& .bar": {
            height: 7,
            borderTopLeftRadius: theme.shape.borderRadius * radiusIncrement,
        },
    },
    full: {
        borderTopRightRadius: theme.shape.borderRadius * radiusIncrement,
    },
}));

const BorderLinearProgress = (props) => {
    const classes = linearProgressStyles();
    const { value, totalValue, backgroundColor, readyPercent, className } = props;
    const percent = _.isNumber(readyPercent) ? readyPercent : (value * 100) / totalValue;
    const width = totalValue <= (value || readyPercent) ? 100 : percent || 0;

    return (
        <Box className={clsx(classes.linearProgress, className)}>
            <Box
                className={clsx("bar", className, (percent > 99 || totalValue <= value) && classes.full)}
                style={{
                    width: `${width}%`,
                    background: backgroundColor || "linear-gradient(91deg, #f6b77e 12%, #ff912d 73%)",
                }}
            />
        </Box>
    );
};

BorderLinearProgress.propTypes = {
    value: PropTypes.number,
    totalValue: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string,
    className: PropTypes.string,
    readyPercent: PropTypes.number,
};

BorderLinearProgress.defaultProps = {
    totalValue: 100,
};

export default BorderLinearProgress;
