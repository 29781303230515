import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import { DividerThin } from "../core/divider";
import LiveMap from "./livemap";
import LiveMapTrucksList from "./livemapTrucksList";
import ErrorNotification from "../core/notification";
import NotificationFeedList from "./notificationFeedList";

const LivemapWrapper = (props) => {
    const { liveMapTrucksLoading, liveMapTrucksError, canShowTruckOnMap, liveMapTrucks, account } = props;
    const [coordinates, setCoordinates] = useState(null);

    return (
        <Grid component="div" container spacing={2}>
            <Grid item xs={9} component="div" className={clsx(liveMapTrucksLoading && LOADER_WHITE_OVERLAY)}>
                {liveMapTrucksLoading && <Loader />}
                <h3>LIVE MAP</h3>
                <DividerThin marginBottom={3} />
                <div style={{ width: "100%", height: "40vh" }}>
                    <LiveMap
                        height={"40vh"}
                        account={account}
                        liveMapTrucks={liveMapTrucks.filter(canShowTruckOnMap)}
                        coordinates={coordinates}
                    />
                </div>
                <br />
                <LiveMapTrucksList centerMapToCoordinates={setCoordinates} initialValues={{ search: null }} />
                {liveMapTrucksError && <ErrorNotification message={liveMapTrucksError} />}
            </Grid>
            <NotificationFeedList key={account.companyRegions} regions={account.companyRegions} />
        </Grid>
    );
};

LivemapWrapper.propTypes = {
    account: PropTypes.object.isRequired,
    canShowTruckOnMap: PropTypes.func.isRequired,
    liveMapTrucks: PropTypes.array.isRequired,
    liveMapTrucksLoading: PropTypes.bool.isRequired,
    liveMapTrucksError: PropTypes.string,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        liveMapTrucks: state.trucks.liveMapTrucks,
        liveMapTrucksError: state.trucks.liveMapTrucksError,
        liveMapTrucksLoading: state.trucks.liveMapTrucksLoading,
    }))(LivemapWrapper),
);
