import moment from "moment";
import { SERVER_DATE_FORMAT } from "../constants/global";

export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const INVALID_DATE = "Invalid date";
export const INVALID_DATE_FORMAT_MESSAGE = "Invalid Date Format";

export const toDate = ({ date, timezone = null }) => {
    let m = moment.utc(date);
    if (timezone) {
        m.tz(timezone);
    }

    return m;
};

export const toMinutes = (value) => {
    const m = moment(value);

    return m.hour() * 60 + m.minute();
};

export const getTimeFromMins = (mins, format) => {
    const hours = Math.trunc(mins / 60);
    const minutes = mins % 60;

    return moment.utc().hours(hours).minutes(minutes).format(format);
};

export const isValidDate = (date) => moment(date).format() !== INVALID_DATE;

export const getMaxYear = () => new Date(1000000000000000);

export const getWeekDaysBetweenDates = (startDate, endDate) => {
    const now = startDate.clone();
    const dates = [];

    while (moment(now.format("YYYY-MM-DD")).isSameOrBefore(moment(endDate.format("YYYY-MM-DD")))) {
        dates.push(DAYS_OF_WEEK[now.day()]);
        now.add(1, "days");
    }

    return [...new Set(dates)];
};

export const generateDaysBetweenDates = (startDate, endDate) => {
    const dates = [];
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    while (momentEndDate.diff(momentStartDate, "days") >= 0) {
        dates.push(momentStartDate.format(SERVER_DATE_FORMAT));
        momentStartDate.add(1, "days");
    }

    return dates;
};

export const calculateMinutesFromSeconds = (value) => Math.floor(value / 60);
export const calculateSecondsFromMinutes = (value) => value * 60;
