import {
    SITES_LOADING,
    SITES_LOADED,
    SITES_FAILED,
    SITES_SET_SEARCH_QUERY,
    SITES_RESET,
    SITE_OPENED,
    SITE_SET_ALERT_ZONE_COORDINATES,
    SITES_SET_FILTERS,
    SITES_UPDATED,
    SITES_VISIBLE_LOADING,
    SITE_SET_NEW_ALERT_ZONE_COORDINATES,
} from "../events/sites";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    sites: [],
    newZoneData: {
        markers: [],
        polylinePath: [],
        createdPolygon: {},
    },
    sitesSearchQuery: null,
    sitesFilters: null,
    sitesVisibleLoading: false,
    sitesLoading: false,
    sitesError: null,
    sitesCount: 0,
    currentSite: null,
    alertZoneCoordinates: null,
};

export const sitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SITES_LOADING:
            return {
                ...state,
                sitesLoading: true,
                sitesError: null,
            };
        case SITES_VISIBLE_LOADING:
            return {
                ...state,
                sitesVisibleLoading: true,
                sitesError: null,
            };
        case SITES_LOADED:
            return {
                ...state,
                sites: action.payload.sites,
                sitesLoading: false,
                sitesVisibleLoading: false,
                sitesError: null,
                sitesCount: action.payload.sitesCount,
                currentSite: state.currentSite || null,
            };
        case SITES_FAILED:
            return {
                ...state,
                sitesLoading: false,
                sitesError: action.payload.sitesError,
            };
        case SITES_SET_SEARCH_QUERY:
            return {
                ...state,
                sitesSearchQuery: action.payload,
            };
        case SITES_SET_FILTERS:
            return {
                ...state,
                sitesFilters: action.payload,
            };
        case SITES_UPDATED:
            return {
                ...state,
                sites: action.payload,
            };
        case SITES_RESET:
            return initialState;
        case SITE_OPENED:
            return {
                ...state,
                currentSite: action.payload,
            };
        case SITE_SET_ALERT_ZONE_COORDINATES:
            return {
                ...state,
                alertZoneCoordinates: action.payload,
            };
        case SITE_SET_NEW_ALERT_ZONE_COORDINATES:
            return {
                ...state,
                newZoneData: action.payload,
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
