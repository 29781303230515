import * as api from "../dataServers/additionalExternalRefs";
import { handleError } from "../helpers/global";
import { actions } from "../reducers/additionalExternalRefsReducer";

export const getAdditionalExternalRefs = () => async (dispatch, getState) => {
    try {
        dispatch(actions.requestAdditionalExternalRefs());
        const { data } = await api.fetchAdditionalExternalRefs();
        dispatch(actions.resultAdditionalExternalRefs(data));
    } catch (e) {
        dispatch(actions.resultAdditionalExternalRefs(handleError(e)));
    }
};
