import { BODY_FONT_SIZE } from "./globalThemeConfig";

export const GLOBAL_STYLES = (theme) => ({
    html: {
        overflow: "visible !important",
    },
    body: {
        fontSize: BODY_FONT_SIZE,
    },
    a: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.link.fontSize,
        textDecoration: theme.typography.link.textDecoration,
        cursor: "pointer",
    },
    button: {
        border: "none",
        outline: "none",
    },
    h1: {
        margin: 0,
        color: theme.palette.text.secondary,
    },
    h2: {
        margin: 0,
    },
    h3: {
        margin: 0,
    },
    h4: {
        margin: 0,
    },
    h5: {
        margin: 0,
    },
    h6: {
        margin: 0,
        color: theme,
    },
    ".--color-secondary-main": {
        color: theme.palette.secondary.superDark,
    },
    "::-webkit-scrollbar": {
        width: 6,
    },
    "::-webkit-scrollbar-track": {
        borderRadius: theme.shape.borderRadius * 2,
    },
    "::-webkit-scrollbar-thumb": {
        borderRadius: theme.shape.borderRadius * 2,
        backgroundColor: "#6e6e6e",
    },
    ".verticalDivider": {
        position: "relative",

        "&::before": {
            content: "''",
            height: "100%",
            width: 1,
            backgroundColor: theme.palette.border.primary,
            position: "absolute",
            left: 0,
        },
    },
    ...INPUT_STYLES(theme),
    ".Mui-error": {
        fontSize: 10,
        marginTop: 1,
    },
});

export const INPUT_STYLES = (theme) => ({
    "::-webkit-input-placeholder, :-ms-input-placeholder, ::placeholder": {
        color: theme.palette.secondary.dark,
        fontSize: 16,
    },
    input: {
        fontFamily: theme.typography.fontFamily,
    },
    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
    },
    "input[type=number]": {
        "-moz-appearance": "textfield",
    },
});
