import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ButtonsGroup } from "./buttons/buttonsGroup";
import { PrimaryButton } from "./buttons/primaryButton";
import { AppModalWithoutConfirmation } from "./modal";
import { SecondaryButton } from "./buttons/secondaryButton";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "./loader";

const ConfirmationModal = (props) => {
    const { yesHandler, noHandler, isOpen, question, modalStyles, isLoading } = props;

    return (
        <AppModalWithoutConfirmation isOpen={isOpen} modalStyles={modalStyles} closeModal={noHandler}>
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <h2 className="title">{question}</h2>
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={yesHandler}>Yes</SecondaryButton>
                    <PrimaryButton onClick={noHandler}>No</PrimaryButton>
                </ButtonsGroup>
            </div>
        </AppModalWithoutConfirmation>
    );
};

ConfirmationModal.propTypes = {
    yesHandler: PropTypes.func.isRequired,
    noHandler: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    question: PropTypes.string,
    modalStyles: PropTypes.object,
    isLoading: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
    isOpen: false,
    question: "Are you sure you want to leave without changes?",
};

export default withRouter(ConfirmationModal);
