import {
    TICKET_TYPE_AIRTICKET,
    TICKET_TYPE_MANUAL,
    TICKET_TYPE_SUBTICKET,
    TICKET_TYPE_TICKET,
    TICKET_USAGE_TYPE_REUSABLE,
    UNIT_OF_MEASURE_HOUR,
    UNIT_OF_MEASURE_LOAD,
    UNIT_OF_MEASURE_TON,
} from "../constants/maps";
import { DUPLICATE_TICKET_OPTION_ID, UNIQUE_TICKET_OPTION_ID } from "../components/global/searchAndFilters";

export const needToSetWeight = (ticket) => {
    return (
        (ticket.unitOfMeasure === UNIT_OF_MEASURE_TON &&
            ticket.joLineItem &&
            ticket.joLineItem.unitOfMeasure === UNIT_OF_MEASURE_LOAD) ||
        ticket.isSubticket
    );
};

export const needToSetLoadsCount = (ticket) => {
    return ticket.unitOfMeasure === UNIT_OF_MEASURE_HOUR && ticket.type === TICKET_USAGE_TYPE_REUSABLE;
};

export const getUnitOfMeasureLabel = (units, id) => ((units || []).filter((i) => i.id === id) || [])[0]?.name;

export const getTicketTypes = (ticket) => {
    const types = [];
    if (ticket.isTicket) {
        types.push(TICKET_TYPE_TICKET);
    }
    if (ticket.isAirticket) {
        types.push(TICKET_TYPE_AIRTICKET);
    }
    if (ticket.isManualTicket) {
        types.push(TICKET_TYPE_MANUAL);
    }
    if (ticket.isSubticket) {
        types.push(TICKET_TYPE_SUBTICKET);
    }

    return types;
};

export const getIfIsDropOffMidpoint = (ticket) =>
    ticket && ticket.isSplit && (!!ticket.nextTicketId || !!(!ticket.previousTicketId && !ticket.nextTicketId));

export const getIfIsPickUpMidpoint = (ticket) => ticket && ticket.isSplit && !!ticket.previousTicketId;

export const getDuplicateTicketParam = (param) => {
    switch (+param) {
        case DUPLICATE_TICKET_OPTION_ID:
            return true;
        case UNIQUE_TICKET_OPTION_ID:
            return false;
        default:
            return null;
    }
};
