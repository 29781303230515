import { getRandomColor } from "./global";
import {
    ALERT_ZONE_TYPE_CIRCLE,
    ALERT_ZONE_TYPE_POLYGON,
    alertZoneFillOpacity,
    alertZoneStrokeOpacity,
    alertZoneStrokeWeight,
    getAlertZoneType,
} from "../constants/sites";

export const getAlertZoneOptions = () => {
    const color = getRandomColor();

    return {
        color: color,
        strokeOpacity: alertZoneStrokeOpacity,
        fillOpacity: alertZoneFillOpacity,
        strokeWeight: alertZoneStrokeWeight,
        fillColor: color,
    };
};

export const parseAlertZones = (alertZones) => {
    const circleZones = [],
        polygonZones = [];

    alertZones.forEach((alertZone) => {
        const options = getAlertZoneOptions();

        if (getAlertZoneType(alertZone.type) === ALERT_ZONE_TYPE_CIRCLE) {
            circleZones.push({
                options: options,
                center: {
                    lat: alertZone.coordinates[0],
                    lng: alertZone.coordinates[1],
                },
                id: alertZone.id,
                radius: alertZone.radius,
                name: alertZone.name,
                siteId: alertZone.siteId,
                type: alertZone.type,
            });
        } else if (getAlertZoneType(alertZone.type) === ALERT_ZONE_TYPE_POLYGON)
            polygonZones.push({
                options: options,
                id: alertZone.id,
                paths: alertZone.coordinates.map((coords) => ({
                    lat: coords[0],
                    lng: coords[1],
                })),
                name: alertZone.name,
                siteId: alertZone.siteId,
                type: alertZone.type,
            });
    });

    return [circleZones, polygonZones];
};

export const getValidCoordinates = (input) => {
    if (input) {
        const coordsArray = input.split(", ");
        if (coordsArray.length === 2 && coordsArray[0] && coordsArray[1]) {
            const lat = parseFloat(coordsArray[0]),
                lng = parseFloat(coordsArray[1]);
            if (lat && lng)
                return {
                    lat,
                    lng,
                };
        }
    }

    return null;
};
