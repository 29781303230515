/* global google */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withGoogleMap, withScriptjs, GoogleMap } from "react-google-maps";
import _ from "lodash";

import { GOOGLE_MAPS_URL, ATLANTA_COORDINATES } from "../../constants/global";

import { MAP } from "react-google-maps/lib/constants";
import ErrorNotification from "../core/notification";
import DeviceMapIcon from "./deviceMapIcon";

let _map;

const BaseSiteMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { devises } = props;
    const [mapCenter] = useState(ATLANTA_COORDINATES);
    const devisesWithData = _.cloneDeep(devises).filter((i) => !_.isEmpty(i.coordinates));

    useEffect(() => {
        if (!_.isEmpty(devisesWithData)) {
            const bounds = new google.maps.LatLngBounds();

            devisesWithData.forEach(({ coordinates }) => {
                bounds.extend(new google.maps.LatLng(coordinates.latitude, coordinates.longitude));
            });

            _map && _map.context[MAP].fitBounds(bounds);
        }
    }, [devises]);

    return (
        <GoogleMap center={mapCenter} defaultZoom={14} ref={(map) => (_map = map)}>
            {devisesWithData.map((device) => {
                const { deviceId, type, connectedNetwork, signalStrength, temperature, geoZone, coordinates } = device;
                const infoText = (
                    <div>
                        DeviceId: {deviceId}
                        <br />
                        Network: {connectedNetwork}
                        <br />
                        Network Strength: {signalStrength || "N/A"}(dB)
                        <br />
                        Temperature: {temperature || "N/A"}°C
                    </div>
                );

                return (
                    <DeviceMapIcon
                        id={+deviceId}
                        key={deviceId}
                        type={type}
                        infoText={infoText}
                        geoZone={geoZone}
                        coordinates={
                            coordinates && {
                                lat: coordinates.latitude,
                                lng: coordinates.longitude,
                            }
                        }
                    />
                );
            })}
        </GoogleMap>
    );
});

const DevisesMap = (props) => {
    const { height, devises } = props;
    const [error, showParentError] = useState(null);

    return (
        <div>
            {error && <ErrorNotification error={error} config={{ onClose: () => showParentError(null) }} />}
            <BaseSiteMap
                googleMapURL={GOOGLE_MAPS_URL}
                devises={devises}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: height }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
    );
};

DevisesMap.propTypes = {
    height: PropTypes.string,
    devises: PropTypes.array.isRequired,
};

DevisesMap.defaultProps = {
    height: "50vh",
    devises: [],
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(DevisesMap),
);
