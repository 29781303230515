import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import AppsIcon from "@material-ui/icons/Apps";
import DehazeIcon from "@material-ui/icons/Dehaze";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    viewSwitcherWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    viewSwitcher: {
        padding: 4,
        border: "1px solid transparent",
        marginLeft: 16,

        "&.-dark": {
            "& svg": {
                color: theme.palette.secondary.main,
            },
            [`&.active`]: {
                border: `1px solid ${theme.palette.secondary.superDark}`,

                "& svg": {
                    color: theme.palette.secondary.superDark,
                },
            },
        },

        "& svg": {
            color: theme.palette.ternary.main,
        },

        [`&.active`]: {
            borderRadius: 2,
            border: "solid 1px #ffffff",

            "& svg": {
                color: "#fff",
            },
        },
    },
    uiIconGridView: {
        width: 25,
        height: 25,
    },
    uiIconListView: {
        width: 28,
        height: 20,
    },
}));

const ViewSwitcher = (props) => {
    const classes = useStyles();
    const { setListView, isListView, className, viewButtonStyle } = props;

    return (
        <Grid item component="div" xs={3} className={classes.viewSwitcherWrap}>
            <IconButton
                onClick={() => setListView(false)}
                className={clsx(classes.viewSwitcher, !isListView && "active", className, viewButtonStyle)}
            >
                <AppsIcon className={classes.uiIconGridView} />
            </IconButton>
            <IconButton
                onClick={() => setListView(true)}
                className={clsx(classes.viewSwitcher, isListView && "active", className)}
            >
                <DehazeIcon className={classes.uiIconListView} />
            </IconButton>
        </Grid>
    );
};

ViewSwitcher.propTypes = {
    setListView: PropTypes.func.isRequired,
    isListView: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

ViewSwitcher.defaultProps = {
    isListView: false,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(ViewSwitcher),
);
