import React from "react";
import { Field, reduxForm } from "redux-form";
import CustomSwitch from "../core/form/customSwitch";
import { compose } from "redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch } from "react-redux";
import { updateRouteSettings } from "../../actions/smartDispatch";

const useStyles = makeStyles(() => ({
    bundleSettingsForm: {
        display: "flex",
        justifyContent: "flex-start",
    },
}));

const BundleSettingsForm = ({ disabled }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onAllowSubhaulersSwitchChange = (e) => {
        dispatch(updateRouteSettings({ allowSubhaulers: e.target.checked }));
    };

    return (
        <form className={classes.bundleSettingsForm}>
            <Field
                component={CustomSwitch}
                name="allowSubhaulers"
                label="Allow Subhaulers"
                type="checkbox"
                onChange={onAllowSubhaulersSwitchChange}
                disabled={disabled}
            />
        </form>
    );
};

export const BUNDLE_SETTINGS_FORM = "bundleSettingsForm";

export default compose(
    reduxForm({
        form: BUNDLE_SETTINGS_FORM,
    }),
)(BundleSettingsForm);
