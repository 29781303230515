import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CustomSwitch from "../core/form/customSwitch";
import { loadStaticOptions, updateAccountData } from "../../helpers/global";
import { ACCOUNT_COMPANY_PROFILE_KEY, LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { MAPS_FOR_IOS, OVERNIGHT_JOB_CLOSING_HOURS, REGULAR_JOB_CLOSING_HOURS } from "../../constants/accounts";
import clsx from "clsx";
import Loader from "../core/loader";
import { saveUserProfile } from "../../dataServers/user";
import ErrorNotification from "../core/notification";
import { authorizeUser, saveUsersRegions } from "../../actions/globalActions";
import TruckItTimePicker from "../core/form/timePicker";
import { IS_ADMIN_USER, IS_HAULER_USER } from "../../constants/maps";
import { getCompanyRegions } from "../../dataServers/projects";
import MiscFieldLabelForm from "./miscFieldLabelForm";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { useStyles as filtersStyles } from "../global/filters";
import SmartDispatchJobModePicker from "../jobs/jobComponents/smartDispatchJobmodePicker";

const useStyles = makeStyles((theme) => ({
    jobsSettingsPage: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },

        "& .job-mode-settings-container": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        "& .job-mode-settings-label": {
            fontSize: "16px",
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.secondary.dark,
            marginBottom: "5px",
        },
    },
    section: {
        "& .custom-switch": {
            marginLeft: 20,
        },
        "& .picker-wrap": {
            justifyContent: "flex-start",
            marginLeft: 20,
        },
    },
}));

const JobsSetting = (props) => {
    const classes = useStyles();
    const filtersClasses = filtersStyles();
    const [state, setState] = useState({
        isLoading: false,
    });
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const { isLoading } = state;
    const { account, authorizeUserWithData, dispatch, form, mapsForIosValue } = props;

    const onMessageClose = () => {
        setSuccess(null);
        setError(null);
    };

    const updateValue = (value, name) => {
        setState({ ...state, isLoading: true });
        saveUserProfile(account.company.id, { [name]: value })
            .then((data) => {
                setState({ ...state, isLoading: false });
                updateAccountData(data.data, authorizeUserWithData, ACCOUNT_COMPANY_PROFILE_KEY);
                setSuccess(["Jobs settings are updated"]);
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const saveMiscValue = (value) => {
        updateValue(value, "miscFieldLabel");
    };

    const saveMapsForIosValue = (mfi) => {
        if (+mfi.value !== +mapsForIosValue.value) {
            updateValue(mfi.value, "mapsForIos");
            dispatch(change(form, "mapsForIos", mfi));
        }
    };

    const saveClosingHourValue = (option, jobType) => {
        updateValue(option.value, jobType);
    };

    return (
        <div className={clsx(classes.jobsSettingsPage, isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {success && <ErrorNotification message={success} type={"success"} config={{ onClose: onMessageClose }} />}
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            <Box display="flex" alignItems="center">
                <Field
                    name="startDateByDefault"
                    label="Default Job Start Time"
                    onChange={(e) => updateValue(e.target.checked, "startDateByDefault")}
                    type="checkbox"
                    component={CustomSwitch}
                />
                <Box style={{ marginLeft: 20 }}>
                    <Field
                        name="defaultStartTime"
                        className="picker-wrap"
                        onChange={(time) => updateValue(moment(time).format("hh:mm:ss"), "defaultStartTime")}
                        component={TruckItTimePicker}
                    />
                </Box>
            </Box>
            <br />
            <Field
                name="startCopyJobNextDay"
                label="Copied job start next day"
                onChange={(e) => updateValue(e.target.checked, "startCopyJobNextDay")}
                type="checkbox"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Field
                name="showPricingInApp"
                onChange={(e) => updateValue(e.target.checked, "showPricingInApp")}
                type="checkbox"
                label="Show Pricing to Driver"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Field
                name="truckShiftAllowed"
                onChange={(e) => updateValue(e.target.checked, "truckShiftAllowed")}
                type="checkbox"
                disabled={!(IS_ADMIN_USER(account.company.id) || IS_HAULER_USER(account.role))}
                label="Allow shift change"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Field
                name="jobAcceptanceAllowed"
                onChange={(e) => updateValue(e.target.checked, "jobAcceptanceAllowed")}
                type="checkbox"
                label="Allow Job Acceptance"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Field
                name="usesSplitJob"
                onChange={(e) => updateValue(e.target.checked, "usesSplitJob")}
                type="checkbox"
                label="Allow Split Job Usage"
                component={CustomSwitch}
            />
            <br />
            <br />
            <Grid container xs={4} direction="row" alignItems="center">
                <Field
                    name="jobClosingHour"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={() => loadStaticOptions(REGULAR_JOB_CLOSING_HOURS)}
                    placeholder="Regular Job Closing Hour"
                    onChange={(e) => saveClosingHourValue(e, "jobClosingHour")}
                    component={AsyncAutocompleteComponent}
                />
            </Grid>
            <br />
            <Grid container xs={4} direction="row" alignItems="center">
                <Field
                    name="overnightJobClosingHour"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={() => loadStaticOptions(OVERNIGHT_JOB_CLOSING_HOURS)}
                    placeholder="Overnight Job Closing Hour"
                    onChange={(e) => saveClosingHourValue(e, "overnightJobClosingHour")}
                    component={AsyncAutocompleteComponent}
                />
            </Grid>
            <br />
            <br />
            <div className={clsx("job-mode-settings-container", filtersClasses.filtersComponent)}>
                <Field
                    component={SmartDispatchJobModePicker}
                    activeModeId={account.companyProfile.jobMode}
                    name="jobMode"
                    handleJobModeClick={(id) => updateValue(id, "jobMode")}
                />
                <div className="job-mode-settings-label">Smart dispatch default job mode</div>
            </div>
            <br />
            <br />
            <Grid container xs={3} direction="row" alignItems="center">
                <Field
                    name="mapsForIos"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={() => loadStaticOptions(MAPS_FOR_IOS)}
                    placeholder="Maps For Ios"
                    label="Maps For Ios"
                    onFieldChange={saveMapsForIosValue}
                    component={AsyncAutocompleteComponent}
                />
            </Grid>
            <br />
            <Grid container className={classes.section} alignItems={"flex-start"} justify={"space-between"}>
                <MiscFieldLabelForm
                    initLabelValue={account.companyProfile.miscFieldLabel ? account.companyProfile.miscFieldLabel : ""}
                    isLoading={isLoading}
                    onSubmit={saveMiscValue}
                />
            </Grid>
            <br />
            <br />
        </div>
    );
};

JobsSetting.propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    authorizeUserWithData: PropTypes.func.isRequired,
    saveRegions: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    mapsForIosValue: PropTypes.object.isRequired,
    jobClosingHour: PropTypes.object,
    overnightJobClosingHour: PropTypes.object,
};

export default withRouter(
    compose(
        reduxForm({
            form: "settingsJobsForm",
            enableReinitialize: true,
        }),
        connect(
            (state, props) => {
                const formSelector = formValueSelector("settingsJobsForm");

                return {
                    account: state.account,
                    mapsForIosValue: formSelector(state, "mapsForIos"),
                    overnightJobClosingHour: formSelector(state, "overnightJobClosingHour"),
                    jobClosingHour: formSelector(state, "jobClosingHour"),
                };
            },
            (dispatch) => ({
                authorizeUserWithData: (accountData) => {
                    dispatch(authorizeUser(accountData));
                },
                saveRegions: (companyId) => {
                    getCompanyRegions(companyId).then((data) => {
                        const result = data.data;
                        if (!_.isEmpty(result)) {
                            dispatch(
                                saveUsersRegions(
                                    result.map((item) => ({
                                        name: item.regionName,
                                        id: item.id,
                                    })),
                                ),
                            );
                        }
                    });
                },
            }),
        ),
    )(JobsSetting),
);
