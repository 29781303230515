import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { compose } from "redux";
import { change, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import Button from "@material-ui/core/Button";
import MuiDivider from "@material-ui/core/Divider";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import EventIcon from "@material-ui/icons/Event";
import DateRangeIcon from "@material-ui/icons/DateRange";

import {
    createBundle,
    selectAllJobs,
    showSmartDispatchFailureMessage,
    showSmartDispatchSuccessMessage,
    updateActiveSmartDispatchTab,
    updateAddToBundleJobList,
} from "../actions/smartDispatch";
import JobsSubNav from "../components/jobs/jobComponents/jobsSubNav";
import JobOrdersWrapper from "../components/jobs/jobsWrapper";
import Fleet from "../components/fleet/fleet";
import { EDIT_JO_FORM } from "../components/jobs/constants";
import { BUNDLE_FULLY_ASSIGNED } from "../components/bundles/BundlePills";
import { Divider, ErrorNotification, PrimaryButton, SuccessNotification, TabsCustom } from "../components/core";
import BundlesWrapper from "../components/bundles/BundlesWrapper";
import { changeJoDate, resetHaulerParam, resetJobOrderParams } from "../actions/jobOrders";
import SolutionTrucksList from "../components/bundles/SolutionTrucksList";
import { BUNDLES_FILTERS_FORM, SD_VIEW_FIELD } from "../constants/forms";
import {
    selectAddToBundleSelectedJobs,
    selectCurrentBundle,
    selectDeployedTrucksData,
    selectIsSubhauler,
    selectJobsForTrucksMeta,
    selectRouteDetailsData,
    selectRouteDetailsMeta,
    selectRoutesMeta,
    selectSmartDispatchErrorMessage,
    selectSmartDispatchIsLoading,
    selectSmartDispatchMeta,
    selectSmartDispatchSuccessMessage,
} from "../selectors/smartDispatchSelectors";
import { SecondaryButton } from "../components/core/buttons/secondaryButton";
import { LOADER_WHITE_OVERLAY, PICKUP_DISTANCE } from "../constants/global";
import {
    selectActiveHaulerOrders,
    selectIfIsUsesSmartDispatch,
    selectTruckDispatchedParam,
    selectUserRegion,
} from "../selectors";
import { RegularJobs } from "../components";
import { ErrorBoundary } from "../components/core/errorBoudaries";
import { BUNDLES_VIEW_BY, BUNDLES_VIEWS } from "../components/bundles/constants";
import { setUpUrl } from "../components/bundles/constants/index";
import Loader from "../components/core/loader";
import { flushSync } from "react-dom";
import BundleDraftModeForm, { BUNDLE_DRAFT_MODE_FORM } from "../components/bundles/BundleDraftModeForm";
import ToggleButtons from "../components/core/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
    smartDispatch: {
        display: "flex",
        justifyContent: "center",

        "& .smart-dispatch": {
            position: "relative",
            padding: "0 15px",
            width: "55%",
        },

        "& .my-fleet": {
            backgroundColor: theme.palette.ternary.superLight,
            width: "25%",
        },

        "& .regular-jobs": {
            width: "25%",
            borderLeft: `1px solid ${theme.palette.secondary.main}`,
            padding: "0 10px",
        },

        "& .trucks-solution-list": {
            backgroundColor: theme.palette.general.paleOrange,
            width: "20%",
        },
    },
    createBundleContainer: {
        padding: "0 16px",

        "& .bundle-actions-container": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",

            "& button": {
                lineHeight: "20px",
                fontSize: 14,
                width: "30%",
            },
        },
    },

    toggleButton: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    toggle: {
        marginRight: 20,
        flex: 1,
    },
    buttonGroup: {
        position: "relative",
        width: "30%",
        height: 55,
        "& .primary-button": {
            ...theme.mainButton,
            flex: 1,
            display: "flex",
            alignTtems: " flex-start",
            paddingRight: 30,
            boxShadow: "none",
        },
        "& .arrow-button": {
            position: "absolute",
            width: "10px !important",
            background: "transparent",
            right: 0,
            top: 0,
            bottom: 0,
            borderLeft: "1px solid rgb(185 98 17 / 55%)",
            "&:hover": {
                backgroundColor: `rgb(185 98 17 / 55%)`,
                boxShadow: "none",
            },
        },
    },
    popper: {
        zIndex: 10000,
    },
    menuItem: {
        fontSize: 14,
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "10px 20px",
        "& .menu-item-header": { display: "flex", alignItems: "center" },
        "& .menu-item-subheader": { fontSize: 12, color: "#57606a", fontWeight: 500 },
    },
}));

export const JOB_LIST_SMART_DISPATCH_FORM_ID = "jobListSmartDispatchFilters";

const JOB_ORDERS_JO_TAB = 0;
const JOB_ORDERS_BUNLDE_TAB = 1;

export const ACTIVE_TAB_FORM = {
    ["job-orders"]: JOB_LIST_SMART_DISPATCH_FORM_ID,
    ["job-bundles"]: BUNDLES_FILTERS_FORM,
};

export const INITIAL_TAB = {
    ["job-orders"]: JOB_ORDERS_JO_TAB,
    ["job-bundles"]: JOB_ORDERS_BUNLDE_TAB,
};

const options = ["Next day dispatch", "Intra day dispatch"];

const BUNDLE_OPTION = {
    [0]: {
        label: "Next day dispatch",
        icon: <EventIcon fontSize="small" style={{ marginRight: 4 }} />,
        helperText: "All routes proceed from the same location. Vehicles are assumed to be empty and inactive",
        value: 0,
    },
    [1]: {
        label: "Intra day dispatch",
        icon: <DateRangeIcon fontSize="small" style={{ marginRight: 4 }} />,
        helperText:
            "All routes use the current locations of active trucks in your fleet. Will assign new job orders to active trucks",
        value: 1,
    },
};

export const SMART_DISPATCH_TABS_LIST = [JOB_ORDERS_JO_TAB, JOB_ORDERS_BUNLDE_TAB];

const SmartDispatchPage = ({ match: { params }, history }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const startDate = useSelector((state) => formValueSelector(JOB_LIST_SMART_DISPATCH_FORM_ID)(state, "startDate"));
    const jobOrdersCount = useSelector((state) => state.jobOrders.jobOrdersCount);
    const jobOrdersList = useSelector((state) => state.jobOrders.list);
    const addToBundleSelectedJobs = useSelector(selectAddToBundleSelectedJobs);
    const errorMessage = useSelector(selectSmartDispatchErrorMessage);
    const successMessage = useSelector(selectSmartDispatchSuccessMessage);
    const isLoading = useSelector(selectSmartDispatchIsLoading);
    const isUsesSmartDispatch = useSelector(selectIfIsUsesSmartDispatch);
    const currentBundle = useSelector(selectCurrentBundle);
    const truckDispatchedParam = useSelector(selectTruckDispatchedParam);
    const activeHaulerOrders = useSelector(selectActiveHaulerOrders);
    const isSubhauler = useSelector(selectIsSubhauler);
    const userRegion = useSelector(selectUserRegion);
    const { error: smartDispatchError, loading: mainLoading } = useSelector(selectSmartDispatchMeta);
    const { error: jobsError } = useSelector(selectJobsForTrucksMeta);
    const { error: routesError } = useSelector(selectRoutesMeta);
    const { error: routeDetailsError } = useSelector(selectRouteDetailsMeta);
    const routeDetails = useSelector(selectRouteDetailsData);
    const deployedTrucks = useSelector(selectDeployedTrucksData);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const iAmBundleOwner = currentBundle?.iAmOwner;
    const isFullyAssigned = currentBundle?.status === BUNDLE_FULLY_ASSIGNED;
    // const showHaulers = (iAmBundleOwner || currentBundle?.allowSubhaulers) && !isSubhauler;
    const showHaulers = false;
    const today = moment().format("YYYY-MM-DD");
    const { bundleId, truckId, currentTab, viewBy, routeNumber } = params;

    const jobFormId = `${EDIT_JO_FORM}-0`;
    const tabsPanels = [];
    const tabsHeaders = [];
    const addToBundleSelectedJobsLength = addToBundleSelectedJobs.length;
    const isIntraDay = !!BUNDLE_OPTION[selectedIndex].value;

    const closeErrorNotification = () => {
        dispatch(showSmartDispatchFailureMessage(false));
    };

    const closeSuccessNotification = () => {
        dispatch(showSmartDispatchSuccessMessage(false));
    };

    const onCreateBundleClick = () => {
        if (addToBundleSelectedJobsLength === 0) return;
        dispatch(createBundle(addToBundleSelectedJobs, isIntraDay));
    };

    const onSelectAllJobsClick = () => {
        if (addToBundleSelectedJobsLength === 0) {
            dispatch(selectAllJobs());
        } else {
            dispatch(updateAddToBundleJobList([]));
        }
    };

    const onActiveTabChange = (currentTab) => {
        dispatch(updateActiveSmartDispatchTab(currentTab));
        setUpUrl(history.push)({ currentTab }).push();
    };

    const setUrlAfterRejectBundle = () => setUpUrl(history.push)({ viewBy }).push();

    const onChangeFilterForm = (field, viewBy) => setUpUrl(history.push)({ viewBy }).push();

    const resetPickUpDistanceParam = () => {
        dispatch(change(jobFormId, "pickUpSite", null));
        if (PICKUP_DISTANCE === truckDispatchedParam || PICKUP_DISTANCE === activeHaulerOrders) {
            dispatch(resetJobOrderParams());
            dispatch(resetHaulerParam());
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === BUNDLE_OPTION[1].value) {
            dispatch(change(JOB_LIST_SMART_DISPATCH_FORM_ID, "startDate", today));
            dispatch(changeJoDate(today));
        }
        if (index === BUNDLE_OPTION[0].value) {
            const nextDay = moment().add(1, "d").format("YYYY-MM-DD");
            dispatch(change(JOB_LIST_SMART_DISPATCH_FORM_ID, "startDate", nextDay));
            dispatch(changeJoDate(nextDay));
        }

        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleOneFilterOptionListChange = (filtersList, setState) => {
        const regionsIdList = filtersList?.regions?.split(",");
        if (regionsIdList && regionsIdList.length > 1) {
            const updatedRegionFilter = userRegion ? { [userRegion.id]: true } : {};
            flushSync(() => {
                setState((prevState) => ({
                    ...prevState,
                    appliedFilters: {
                        ...prevState?.appliedFilters,
                        regions: updatedRegionFilter,
                    },
                }));
            });
        }
    };

    const JobOrdersWrapperComponent = (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <div className={classes.createBundleContainer}>
                <Divider marginTop={0} />
                <div className="bundle-actions-container">
                    <SecondaryButton onClick={onSelectAllJobsClick} style={{ marginRight: "20px" }}>
                        {addToBundleSelectedJobsLength === 0 ? (
                            <>
                                Select All Jobs
                                <br />
                                (amount: {jobOrdersCount})
                            </>
                        ) : (
                            "Deselect All Jobs"
                        )}
                    </SecondaryButton>
                    <ButtonGroup className={classes.buttonGroup} variant="contained" color="primary" ref={anchorRef}>
                        <PrimaryButton className="primary-button" onClick={onCreateBundleClick}>
                            <div>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    {BUNDLE_OPTION[selectedIndex].icon}
                                    {BUNDLE_OPTION[selectedIndex].label}
                                </span>
                                ({addToBundleSelectedJobsLength} jobs)
                            </div>
                        </PrimaryButton>
                        <Button color="primary" size="small" onClick={handleToggle} className="arrow-button">
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        className={classes.popper}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {options.map((option, index) => (
                                                <>
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        key={option}
                                                        disabled={index === 2}
                                                        selected={index === selectedIndex}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        <Typography variant="h4" className="menu-item-header">
                                                            {BUNDLE_OPTION[index].icon}
                                                            {BUNDLE_OPTION[index].label}
                                                        </Typography>
                                                        <Typography className="menu-item-subheader">
                                                            {BUNDLE_OPTION[index].helperText}
                                                        </Typography>
                                                    </MenuItem>
                                                    <MuiDivider />
                                                </>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
                <Divider marginBottom={0} />
            </div>
            <JobOrdersWrapper
                disabledLeftArrow={moment(today).isSame(startDate)}
                form={JOB_LIST_SMART_DISPATCH_FORM_ID}
                updateList={selectedIndex === BUNDLE_OPTION[1].value || selectedIndex === BUNDLE_OPTION[0].value}
                parentJobListLoading={isLoading}
                minDate={moment()}
                handleOneFilterOptionListChange={handleOneFilterOptionListChange}
            />
        </div>
    );

    const BundlesWrapperComponent = (
        <>
            <Box
                style={{
                    padding: "0 16px",
                }}
            >
                <Divider marginTop={0} />
                <ToggleButtons
                    key={viewBy}
                    containerStyle={classes.toggleButton}
                    buttons={BUNDLES_VIEWS}
                    onChange={(value) => onChangeFilterForm(SD_VIEW_FIELD, value)}
                    initialValue={viewBy}
                    exclusive
                    withoutReset
                    fullWidth
                />

                <Divider marginBottom={0} />
            </Box>
            <BundlesWrapper
                jobFormId={jobFormId}
                viewBy={viewBy}
                bundleId={bundleId}
                truckId={truckId}
                routeNumber={routeNumber}
            />
        </>
    );

    if (isUsesSmartDispatch) {
        tabsHeaders.push({ label: "JOB ORDERS" });
        tabsPanels.push({ content: JobOrdersWrapperComponent });
    }

    tabsHeaders.push({ label: "JOB BUNDLES" });
    tabsPanels.push({ content: BundlesWrapperComponent });

    useEffect(() => {
        if (_.isUndefined(currentTab)) {
            const { push, jobOrdersTab } = setUpUrl(history.push)();
            push(jobOrdersTab);
        }
    }, [currentTab]);

    useEffect(() => {
        const loadedJobOrdersIdList = jobOrdersList.map((jo) => jo.id);
        const updatedAddToBundleList = addToBundleSelectedJobs.filter((joId) => loadedJobOrdersIdList.includes(joId));

        dispatch(updateAddToBundleJobList(updatedAddToBundleList));
    }, [jobOrdersList]);

    useEffect(() => {
        const currentDate = startDate && moment(startDate).format("YYYY-MM-DD");
        if (currentDate) {
            if (!moment(today).isSame(currentDate)) {
                setSelectedIndex(0);
            }
            if (moment(today).isSame(currentDate)) {
                dispatch(change(BUNDLE_DRAFT_MODE_FORM, "isDraft", false));
                setSelectedIndex(1);
            }
        }
    }, [startDate]);

    const renderRightContent = () => {
        if (viewBy === BUNDLES_VIEW_BY.BUNDLE || !viewBy) {
            return (
                <Box className={"my-fleet"}>
                    <Fleet
                        key={deployedTrucks?.length}
                        showHaulers={showHaulers}
                        jobFormId={jobFormId}
                        jobsListFormId={ACTIVE_TAB_FORM[currentTab] || JOB_LIST_SMART_DISPATCH_FORM_ID}
                        mainListSubheader={currentBundle && currentBundle.isIntraDay && "Inactive Trucks"}
                        additionalTrucks={
                            currentBundle &&
                            currentBundle.isIntraDay && [
                                {
                                    trucks: deployedTrucks,
                                    listSubheader: "Deployed Trucks",
                                    disabledDrag: true,
                                    isCanDrop: !isFullyAssigned,
                                },
                            ]
                        }
                        hiddenFilter={{
                            idleStatus: true,
                            availability: true,
                        }}
                        additionalParams={currentBundle && currentBundle.isIntraDay && { idleStatus: 1 }}
                    />
                </Box>
            );
        }
        if (viewBy === BUNDLES_VIEW_BY.TRUCK) {
            if (routeDetails && !routeDetails.iAmOwner)
                return (
                    <Box className={"regular-jobs"}>
                        <br />
                        <h1 className="--text-center">Hauler isn't allowed to add jobs to the route</h1>
                        <br />
                    </Box>
                );

            return (
                <Box className={"regular-jobs"}>
                    <RegularJobs />
                </Box>
            );
        }
    };

    return (
        <Box className={clsx(mainLoading && LOADER_WHITE_OVERLAY, classes.dispatchJobOrders)}>
            {mainLoading && <Loader />}
            <JobsSubNav />
            <ErrorBoundary>
                <Box className={clsx(classes.smartDispatch)}>
                    <Box className="trucks-solution-list">
                        <SolutionTrucksList
                            setUrlAfterRejectBundle={setUrlAfterRejectBundle}
                            resetPickUpDistanceParam={resetPickUpDistanceParam}
                            view={viewBy}
                        />
                    </Box>
                    <Box className={"smart-dispatch"}>
                        <TabsCustom
                            tabsHeaders={tabsHeaders}
                            tabsPanels={tabsPanels}
                            initialTab={INITIAL_TAB[currentTab]}
                            onChangeTab={onActiveTabChange}
                        />
                        <Box
                            sx={{
                                position: "absolute",
                                top: 2,
                                right: 32,
                                flexDirection: "column",
                                justifyContent: "center",
                                display: "flex",
                                height: "56px",
                            }}
                        >
                            <BundleDraftModeForm isIntraDay={isIntraDay} />
                        </Box>
                    </Box>
                    {renderRightContent()}
                </Box>
                {errorMessage && (
                    <ErrorNotification message={errorMessage} config={{ onClose: () => closeErrorNotification() }} />
                )}
                {successMessage && (
                    <SuccessNotification
                        message={successMessage}
                        config={{ onClose: () => closeSuccessNotification() }}
                    />
                )}
                {jobsError && <ErrorNotification message={jobsError} />}
                {routesError && <ErrorNotification message={routesError} />}
                {routeDetailsError && <ErrorNotification message={routeDetailsError} />}
                {smartDispatchError && <ErrorNotification message={smartDispatchError} />}
            </ErrorBoundary>
        </Box>
    );
};

export default compose(withRouter)(SmartDispatchPage);
