import {
    WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_OFFLINE,
    WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_ONLINE,
    WEBSOCKET_EVENT_TYPE_TRUCK_ENTERED,
    WEBSOCKET_EVENT_TYPE_TRUCK_LEFT,
    WEBSOCKET_EVENT_TYPE_TRUCK_LINKED,
    WEBSOCKET_EVENT_TYPE_TRUCK_NOT_MOVING,
    WEBSOCKET_EVENT_TYPE_TRUCK_NOT_SEND_COORDS,
    WEBSOCKET_EVENT_TYPE_TRUCK_SIGN_IN,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/maps";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_OFFLINE]: "|{truck.deviceName}| is offline",
    [WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_ONLINE]: "|{truck.deviceName}| is online",
    [WEBSOCKET_EVENT_TYPE_TRUCK_ENTERED]: "|{truck.deviceName}| entered at |{region}|",
    [WEBSOCKET_EVENT_TYPE_TRUCK_LEFT]: "|{truck.deviceName}| left |{region}|",
    [WEBSOCKET_EVENT_TYPE_TRUCK_NOT_MOVING]: "|{truck.deviceName}| not moved more than 50 meters",
    [WEBSOCKET_EVENT_TYPE_TRUCK_NOT_SEND_COORDS]: "|{truck.deviceName}| is offline",
    [WEBSOCKET_EVENT_TYPE_TRUCK_LINKED]: "|{truck.deviceName}| was linked",
    [WEBSOCKET_EVENT_TYPE_TRUCK_SIGN_IN]: "Truck |#{truck.id}| was sign in",
};

const getNotificationTitle = (event) => {
    return notificationTitles[event.type]
        .replace(
            "{truck.deviceName}",
            (event.truck || {}).deviceName ? `${(event.truck || {}).deviceName.toUpperCase()}&link` : "Unknown",
        )
        .replace("{truck.id}", (event.truck || {}).id || "Unknown")
        .replace("{region}", event.region ? event.region.toUpperCase() : "Unknown");
};

export const processTruckEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
