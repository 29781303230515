import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
    addToBundleSelectedJobs: [],
    bundlesList: null,
    bundlesListCount: 0,
    errorMessage: null,
    successMessage: null,
    isLoading: false,
    bundlesFilters: null,
    currentBundle: null,
    selectedTruckSolutionList: [],
    selectedRoute: null,
    activeSmartDispatchTab: 0,
    myFleetNeedsToBeUpdated: false,
    isConfirmationModalShown: false,
    isHaulerAssignModalShown: false,
    bundleAssignData: null,
    haulerToAssign: null,
    truckRouteLocation: null,
    jobsForTrucks: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    selectedRouteIndex: null,
    routes: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    routeDetails: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    newJob: null,
    activeSortParam: null,
    deployedTrucks: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    meta: {
        success: false,
        loading: false,
        error: null,
    },
};

const smartDispatch = createSlice({
    name: "SMART DISPATCH",
    initialState,
    reducers: {
        requestDeployedTrucks(state, { payload }) {
            state.deployedTrucks.meta.loading = payload;
        },
        resultDeployedTrucks(state, action) {
            if (action.payload.error) {
                state.deployedTrucks.meta.loading = false;
                state.deployedTrucks.meta.error = action.payload.message;
            } else {
                state.deployedTrucks.meta.loading = false;
                if (action.payload.concat) {
                    state.deployedTrucks.data.push(...action.payload.data);
                } else {
                    state.deployedTrucks.data = action.payload.data;
                }
                state.deployedTrucks.meta = {
                    ...state.deployedTrucks.meta,
                    ...action.payload.meta,
                };
            }
        },
        resetDeyployedTrucks(state) {
            state.deployedTrucks = initialState.deployedTrucks;
        },
        addJobToBundleSelected(state, action) {
            state.addToBundleSelectedJobs.push(action.payload);
        },
        removeJobFromBundleSelected(state, action) {
            state.addToBundleSelectedJobs = state.addToBundleSelectedJobs.filter((jobId) => jobId !== action.payload);
        },
        updateAddToBundleJobList(state, action) {
            state.addToBundleSelectedJobs = action.payload;
        },
        showSmartDispatchFailureMessage(state, action) {
            state.errorMessage = action.payload;
        },
        showSmartDispatchSuccessMessage(state, action) {
            state.successMessage = action.payload;
        },
        smartDispatchIsLoadingUpdate(state, action) {
            state.isLoading = action.payload;
        },
        setSmartDispatchConfirmationModal(state, action) {
            state.isConfirmationModalShown = action.payload;
        },
        setSmartDispatchHaulerAssignConfirmationModal(state, action) {
            state.isHaulerAssignModalShown = action.payload;
        },
        bundlesListLoading(state, action) {
            state.isLoading = true;
        },
        bundlesListLoadingSuccess(state, action) {
            const { meta, data } = action.payload;
            state.bundlesList = data;
            state.bundlesListCount = meta.count;
            state.isLoading = false;
        },
        bundlesListLoadingFailure(state, action) {
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
        updateCurrentBundle(state, action) {
            const updatedCurrentBundle = action.payload;
            const extendedSolution = updatedCurrentBundle?.solution.map((route) => {
                const jobOrderId = route.routeItems[0]?.jobId;
                const jobOrderData = updatedCurrentBundle.jobOrders.find((jobOrder) => jobOrder.id === jobOrderId);
                return {
                    ...route,
                    firstJobOrderPickUpLocation: jobOrderData?.pickupLocation,
                    firstJobOrderStartDate: jobOrderData?.startDate,
                };
            });
            state.currentBundle = updatedCurrentBundle && {
                ...updatedCurrentBundle,
                solution: extendedSolution,
            };
        },
        updateSelectedTruckSolutionList(state, action) {
            state.selectedTruckSolutionList = action.payload;
        },
        addSolutionToSelectedList(state, action) {
            state.selectedTruckSolutionList.push(action.payload);
        },
        removeSolutionFromSelectedList(state, action) {
            state.selectedTruckSolutionList = state.selectedTruckSolutionList.filter(
                (solution) => solution !== action.payload,
            );
        },
        updateBundleFilters(state, action) {
            state.bundlesFilters = action.payload;
        },
        updateActiveSmartDispatchTab(state, action) {
            state.activeSmartDispatchTab = action.payload;
        },
        updatedBundleInList(state, action) {
            const { bundleId, updatedData } = action.payload;
            const updatedBundleList = state.bundlesList?.map((bundle) => {
                if (bundle.problemId === bundleId) {
                    return {
                        ...bundle,
                        ...updatedData,
                    };
                }

                return bundle;
            });
            state.bundlesList = updatedBundleList;
        },
        myFleetNeedsToBeUpdatedChange(state, action) {
            state.myFleetNeedsToBeUpdated = action.payload;
        },
        updateAssignBundleData(state, action) {
            state.bundleAssignData = action.payload;
        },
        updateHaulerToAssign(state, action) {
            state.haulerToAssign = action.payload;
        },
        updateTruckRouteLocation(state, action) {
            state.truckRouteLocation = action.payload;
        },
        requestJobsForTrucks(state) {
            state.jobsForTrucks.meta.loading = true;
        },
        resultJobsForTrucks(state, action) {
            if (action.payload.error) {
                state.jobsForTrucks.meta.loading = false;
                state.jobsForTrucks.meta.error = action.payload.message;
            } else {
                state.jobsForTrucks.meta.loading = false;
                if (action.payload.concat) {
                    state.jobsForTrucks.data.push(...action.payload.data);
                } else {
                    state.jobsForTrucks.data = action.payload.data;
                }
                state.jobsForTrucks.meta = {
                    ...state.jobsForTrucks.meta,
                    ...action.payload.meta,
                };
            }
        },
        resetJobsForTrucks(state) {
            state.jobsForTrucks = initialState.jobsForTrucks;
        },
        resetJobsForTrucksMeta(state) {
            state.jobsForTrucks.meta = initialState.jobsForTrucks.meta;
        },
        requestRoutes(state) {
            state.routes.meta.loading = true;
        },
        resultRoutes(state, action) {
            if (action.payload.error) {
                state.routes.meta.loading = false;
                state.routes.meta.error = action.payload.message;
            } else {
                state.routes.meta.loading = false;
                if (action.payload.concat) {
                    state.routes.data.push(...action.payload.data);
                } else {
                    state.routes.data = action.payload.data;
                }
                state.routes.meta = {
                    ...state.routes.meta,
                    ...action.payload.meta,
                };
            }
        },
        resetRoutesMeta(state) {
            state.routes.meta = initialState.routes.meta;
        },
        resetRoutes(state) {
            state.routes = initialState.routes;
        },
        requestRouteDetails(state, action) {
            state.routeDetails.meta.loading = true;
        },
        resultRouteDetails(state, action) {
            if (action.payload.error) {
                state.routeDetails.meta.loading = false;
                state.routeDetails.meta.error = action.payload.message;
            } else {
                state.routeDetails.meta.loading = false;
                state.routeDetails.meta.error = action.payload.message;
                state.routeDetails.data = action.payload;
            }
        },
        resetRouteDetailsMeta(state, action) {
            state.routeDetails.meta = initialState.routeDetails.meta;
        },
        resetRouteDetails(state, action) {
            state.routeDetails = initialState.routeDetails;
        },
        setRoute(state, action) {
            state.selectedRoute = action.payload;
        },
        resetSelectedRoute(state) {
            state.selectedRoute = initialState.selectedRoute;
        },
        updateActiveRegularJobsSortParam(state, action) {
            state.activeSortParam = action.payload;
        },
        moveJobPosition(state, action) {
            const dragIndex = action.payload.dragIndex;
            const hoverIndex = action.payload.hoverIndex;
            const prevJobs = state.routeDetails.data?.jobOrders;
            if (prevJobs) {
                state.routeDetails.data.jobOrders = update(prevJobs, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevJobs[dragIndex]],
                    ],
                }).map((i, index) => ({ ...i, index, dragIndex, hoverIndex }));
            }
        },
        newJobAdded(state, action) {
            state.newJob = action.payload;
        },
        requestRecalculateBundle(state) {
            state.meta.loading = true;
        },
        resultRecalculateBundle(state, { payload }) {
            state.meta.loading = false;
        },
        resetMeta(state) {
            state.meta = initialState.meta;
        },
        replaceDeployedTruck(state, action) {
            const { oldTruck, newTruck } = action.payload;
            const deployedTrucks = state.deployedTrucks.data;
            const newTrucks =
                deployedTrucks &&
                deployedTrucks.map((truck) => {
                    if (truck.id === oldTruck.id) return newTruck;

                    return truck;
                });
            state.deployedTrucks.data = newTrucks;
        },
    },
});

export const { actions } = smartDispatch;
export default smartDispatch.reducer;
