import React from "react";
import CustomSwitch from "../../core/form/customSwitch";
import { change, Field } from "redux-form";
import Box from "@material-ui/core/Box";
import TruckItTimePicker from "../../core/form/timePicker";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../constants/global";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useStyles as filtersStyles } from "../../global/filters";
import { useDispatch } from "react-redux";
import SmartDispatchJobModePicker from "../jobComponents/smartDispatchJobmodePicker";
import { Validation } from "../../../helpers/validation";

export const JOB_ORDER_END_DATE = "endDate";
export const SMART_DISPATCH_JOB_MODE = "jobMode";

const styles = makeStyles((theme) => ({
    smartDispatchToggleContainer: {
        display: "flex",
        flexDirection: "column",
    },

    toggleGroupElement: {
        margin: "2px",
    },

    smartDispatchSettingsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& .smart-dispatch-status-container": {
            [theme.breakpoints.down(1286)]: {
                flexDirection: "column",
            },

            display: "flex",
            flexDirection: "row",
            width: "50%",
            padding: "5px",
        },
        "& .timepicker-container": {
            paddingBottom: "10px",
            width: "50%",
        },
    },
}));

const SmartDispatchSettingsContainer = ({ disabled, onSmartDispatchClick, smartDispatch, jobMode, form }) => {
    const classes = styles();
    const filtersClasses = filtersStyles();
    const dispatch = useDispatch();

    const handleJobModeUpdate = (updatedModeId) => {
        dispatch(change(form, SMART_DISPATCH_JOB_MODE, updatedModeId));
    };

    return (
        <div className={classes.smartDispatchToggleContainer}>
            <Box minWidth={175}>
                <Field
                    name="smartDispatch"
                    disabled={disabled}
                    type="checkbox"
                    label="Smart Dispatch"
                    className={classes.toggleGroupElement}
                    onChange={onSmartDispatchClick}
                    component={CustomSwitch}
                />
            </Box>
            {smartDispatch && (
                <div className={clsx(filtersClasses.filtersComponent, classes.smartDispatchSettingsContainer)}>
                    <div className="smart-dispatch-status-container">
                        <Field
                            component={SmartDispatchJobModePicker}
                            activeModeId={jobMode}
                            name={SMART_DISPATCH_JOB_MODE}
                            handleJobModeClick={(id) => handleJobModeUpdate(id)}
                        />
                    </div>
                    <div className={"timepicker-container"}>
                        <Field
                            name={JOB_ORDER_END_DATE}
                            dateFormat={DEFAULT_DATE_TIME_FORMAT}
                            disabled={disabled}
                            component={TruckItTimePicker}
                            placeholder={"End Time"}
                            useFormattedDate={false}
                            validate={[Validation.isValidTime]}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SmartDispatchSettingsContainer;
