import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { change, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import DateTimePicker, { INPUT_VARIANT_OUTLINED } from "../core/form/dateTimePicker";
import { DEFAULT_DATE_FORMAT } from "../../constants/global";
import { LOCAL_STORAGE_FILTERS_ID } from "../../helpers/api";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";

const DatesRange = React.memo((props) => {
    const {
        showEndDate,
        handleStartDateChange: onStartDateChange,
        startDateName,
        endDateName,
        form,
        dispatch,
        handleEndDateChange: onEndDateChange,
        isFormView,
        dateOnly,
        dateFormat,
        isForceSaveDates,
        isInitialLoadingDatesSave,
        handleForceSaveDates,
        disabled,
        disabledLeftArrow,
        updateStartDate,
        minDate,
        startDate,
        endDate,
        setUpdateStartDate,
        disableTodayButton,
    } = props;
    let startTimer;
    let endTimer;
    const saveDatesInLocalStorage = (date, name) => {
        const restFormValues = localStorage.getItem(LOCAL_STORAGE_FILTERS_ID);
        const prevAppliedFilters = restFormValues ? JSON.parse(restFormValues) : null;

        if (form) {
            const newFormValues =
                prevAppliedFilters && prevAppliedFilters[form]
                    ? {
                          ...prevAppliedFilters[form],
                          [name]: date,
                      }
                    : { [name]: date };
            localStorage.setItem(
                LOCAL_STORAGE_FILTERS_ID,
                JSON.stringify({
                    ...prevAppliedFilters,
                    [form]: newFormValues,
                }),
            );
        }
    };
    const handleEndDateChange = (date) => {
        if (moment(date).isBefore(moment(startDate))) {
            dispatch(change(form, startDateName, date));
            endTimer = setTimeout(() => {
                onEndDateChange && onEndDateChange(date, null, true);
                saveDatesInLocalStorage(date, startDateName);
                saveDatesInLocalStorage(date, endDateName);
            }, 0);

            return false;
        }

        onEndDateChange && onEndDateChange(date, endDateName);
        saveDatesInLocalStorage(date, endDateName);
    };

    const handleStartDateChange = (date) => {
        setUpdateStartDate && setUpdateStartDate("");

        if (moment(date).isAfter(moment(endDate))) {
            dispatch(change(form, endDateName, date));
            startTimer = setTimeout(() => {
                onStartDateChange && onStartDateChange(date, null, true);
                saveDatesInLocalStorage(date, endDateName);
                saveDatesInLocalStorage(date, startDateName);
            }, 0);

            return false;
        }

        onStartDateChange && onStartDateChange(date, startDateName);
        saveDatesInLocalStorage(date, startDateName);
    };

    useEffect(() => {
        return () => {
            clearTimeout(startTimer);
            clearTimeout(endTimer);
        };
    }, []);

    useEffect(() => {
        if (updateStartDate) {
            handleStartDateChange(updateStartDate);
        }
    }, [updateStartDate]);

    useEffect(() => {
        if (isForceSaveDates && handleForceSaveDates) {
            saveDatesInLocalStorage(startDate, startDateName);
            saveDatesInLocalStorage(endDate, endDateName);
            handleForceSaveDates();
        }
    }, [isForceSaveDates]);

    // https://github.com/TruckITllc/truckit-frontend/issues/2524
    useEffect(() => {
        if (isInitialLoadingDatesSave) {
            saveDatesInLocalStorage(startDate, startDateName);
            saveDatesInLocalStorage(endDate, endDateName);
        }
    }, [isInitialLoadingDatesSave]);

    return (
        <Grid container component="div">
            <Grid item xs={showEndDate ? 6 : 12} component="div">
                <Field
                    name={startDateName}
                    placeholder="Start Date"
                    dateOnly={dateOnly}
                    disabled={disabled}
                    disabledLeftArrow={disabledLeftArrow}
                    className={clsx(isFormView && AUTOCOMPLETE_FORM_VIEW_CLASS)}
                    inputVariant={INPUT_VARIANT_OUTLINED}
                    dateFormat={dateFormat}
                    minDate={minDate}
                    handleDateChange={handleStartDateChange}
                    disableTodayButton={disableTodayButton}
                    component={DateTimePicker}
                />
            </Grid>
            {showEndDate && (
                <Grid item xs={6} component="div">
                    <Field
                        name={endDateName}
                        disabled={disabled}
                        dateOnly={dateOnly}
                        minDate={minDate}
                        className={clsx(isFormView && AUTOCOMPLETE_FORM_VIEW_CLASS)}
                        inputVariant={INPUT_VARIANT_OUTLINED}
                        placeholder="End Date"
                        dateFormat={dateFormat}
                        handleDateChange={handleEndDateChange}
                        disableTodayButton={disableTodayButton}
                        component={DateTimePicker}
                    />
                </Grid>
            )}
        </Grid>
    );
});

DatesRange.propTypes = {
    showEndDate: PropTypes.bool.isRequired,
    startDateName: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    endDateName: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    handleStartDateChange: PropTypes.func,
    handleEndDateChange: PropTypes.func,
    setUpdateStartDate: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    isFormView: PropTypes.bool.isRequired,
    updateStartDate: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    dateOnly: PropTypes.bool.isRequired,
    disableTodayButton: PropTypes.bool.isRequired,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    isForceSaveDates: PropTypes.bool,
    isInitialLoadingDatesSave: PropTypes.bool,
    handleForceSaveDates: PropTypes.func,
    minDate: PropTypes.string,
};

export const START_DATE_NAME = "startDate";
export const END_DATE_NAME = "endDate";

DatesRange.defaultProps = {
    showEndDate: true,
    disabled: false,
    updateStartDate: "",
    isFormView: false,
    dateOnly: true,
    disableTodayButton: false,
    startDateName: START_DATE_NAME,
    endDateName: END_DATE_NAME,
    dateFormat: DEFAULT_DATE_FORMAT,
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            startDate: formSelector(state, props.startDateName || START_DATE_NAME),
            endDate: formSelector(state, props.endDateName || END_DATE_NAME),
        };
    })(DatesRange),
);
