import dataProvider from "../helpers/api";
import { WEATHER } from "../constants/endpoints";

export const getForecast = (params) => {
    return dataProvider({
        url: WEATHER.FORECAST(),
        method: "GET",
        params: {
            latitude: params.latitude,
            longitude: params.longitude,
        },
    });
};
