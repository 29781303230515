import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import TextInputComponent from "../core/form/textInputComponent";
import { compose as reduxCompose } from "redux";
import { connect, useSelector } from "react-redux";
import { Validation } from "../../helpers/validation";
import { resetUomData, resetWeather } from "../../actions/globalActions";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    REMOVE_ACTION_TYPE,
} from "../core/form/asyncAutocompleteComponent";
import { saveUserProfile, updateUserPaymentDetails } from "../../dataServers/user";
import { getCompanyTimezones, loadStates, updateUserProfileData } from "../../actions/globalActions.js";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { PrimaryButton } from "../core/buttons/primaryButton";
import clsx from "clsx";
import { convertHoursToSeconds, LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { STRIPE_BASIC, STRIPE_PRO } from "../../constants/accounts";
import { fullCompanyAddress } from "../../constants/users.js";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { ActionLink, SimpleLink } from "../core/link";
import { uploadCompanyCertificate } from "../../dataServers/companies";
import PhoneField from "../global/phoneField";
import EmailField from "../global/emailField";
import { Grid } from "@material-ui/core";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import {
    COMPANY_ADMINS_FIELD_NOTE,
    ONE_ADMIN_REQUIRED_ERROR_MESSAGE,
    REMOVE_YOURSELF_CONFIRMATION_TITLE,
} from "./constants/strings";
import ConfirmationModal from "../core/confirmationModal";
import { updateCompanyAdminsRequest } from "../../dataServers/companyAdmins";

const CompanyAddressForm = ({ state, accountType, form, dispatch }) => {
    const setStateValue = (value) => {
        dispatch(change(form, "state", { label: value.abbreviation, value: value.value }));
    };

    const isStripeAccount = accountType == STRIPE_BASIC || accountType === STRIPE_PRO;

    return isStripeAccount ? (
        <>
            <Field
                name="companyName"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                disabled={true}
                label="Company Name"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="address"
                validate={[Validation.required]}
                type="text"
                disabled={true}
                label="Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="streetAddress"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="Street Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="secondaryAddress"
                validate={[Validation.noSpecialSymbols]}
                type="text"
                label="Secondary Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="city"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="City"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="state"
                validate={[Validation.required]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                loadOptions={loadStates}
                placeholder="State"
                label="State"
                onFieldChange={setStateValue}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <Field
                name="zipCode"
                validate={[Validation.required]}
                type="text"
                label="Zip Code"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="timezone"
                validate={[Validation.required]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                loadOptions={getCompanyTimezones}
                placeholder="Choose Time Zone"
                label="Choose Time Zone"
                component={AsyncAutocompleteComponent}
            />
        </>
    ) : (
        <>
            <Field
                name="companyName"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                disabled={true}
                label="Company Name"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="address"
                validate={[Validation.required]}
                type="text"
                label="Address"
                component={TextInputComponent}
            />
        </>
    );
};

const General = (props) => {
    const accountType = useSelector((state) => state.account.company.accountType);
    const [state, setState] = useState({ isLoading: false, enableRenew: {} });
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [adminsToRemove, setAdminsToRemove] = useState(null);
    const { isLoading } = state;
    const {
        formValues: { file, companyAdmins },
        handleSubmit,
        account,
        dispatch,
        form,
        initialValues: { measurementSystem },
    } = props;

    const onSubmit = (values) => {
        const { timezone } = values;

        if (!_.isEqual(values.measurementSystem.value, measurementSystem.value)) {
            dispatch(resetUomData());
            dispatch(resetWeather());
        }

        const isStripeAccount = accountType === STRIPE_BASIC || accountType === STRIPE_PRO;
        const updateCompanyData = {
            address: isStripeAccount ? fullCompanyAddress(values) : values.address,
            city: values.city,
            defaultStartTime: values.defaultStartTime,
            ignoreJobsLimit: values.ignoreJobsLimit,
            miscFieldLabel: values.miscFieldLabel,
            paymentEmail: values.paymentEmail ? values.paymentEmail : null,
            paymentName: values.paymentName,
            paymentPhone: values.paymentPhone,
            showPricingInApp: values.showPricingInApp,
            startCopyJobNextDay: values.startCopyJobNextDay,
            startDateByDefault: values.startDateByDefault,
            state: values.state,
            streetAddress: values.streetAddress,
            timezone: values.timezone,
            truckShiftAllowed: values.truckShiftAllowed,
            usesIotDevices: values.usesIotDevices,
            zipCode: values.zipCode,
            arAccount: values.arAccount ? values.arAccount : null,
            incomeAccount: values.incomeAccount ? values.incomeAccount : null,
            measurementSystem: values.measurementSystem.value,
            workingDayDuration: values.workingDayDuration ? convertHoursToSeconds(values.workingDayDuration) : null,
        };

        if (values.state) updateCompanyData.state = values.state.label;

        if (values.secondaryAddress) {
            updateCompanyData.secondaryAddress = values.secondaryAddress;
        } else {
            updateCompanyData.secondaryAddress = null;
        }
        setState({ ...state, isLoading: true });
        Promise.all([
            saveUserProfile(account.company.id, {
                ...updateCompanyData,
                timezone: { id: timezone.value, name: timezone.label },
            }),
            addCertificate(),
        ])
            .then(() => {
                dispatch(updateUserProfileData());
                setState({ ...state, isLoading: false });
                setSuccess(["Profile Data Updated"]);
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const addCertificate = () => {
        if (!file || (file && !file.length)) {
            return Promise.resolve();
        }

        setState({ ...state, isLoading: true });

        uploadCompanyCertificate(account.company.id, file[0])
            .then((data) => {
                setState({ ...state, isLoading: false });
                setSuccess(["New Certificate Uploaded"]);
                dispatch(change, form, "certificate", data);
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const onMessageClose = () => {
        setError(null);
        setSuccess(null);
    };

    const onUpdatePaymentMethodClick = () => {
        updateUserPaymentDetails()
            .then((res) => {
                const customerPortalUrl = res.data.customerPortalUrl;
                if (customerPortalUrl) {
                    window.open(customerPortalUrl, "_blank");
                }
            })
            .catch((error) => {
                setError("Error");
            });
    };

    const updateCompanyAdmins = async (admins) => {
        setState({ ...state, isLoading: true });
        const updatedAdminsIds = admins?.map((admin) => admin.value);
        try {
            await updateCompanyAdminsRequest(account.company.id, { usersIds: updatedAdminsIds });
            dispatch(change(form, "companyAdmins", admins));
            setSuccess("Company Admins Are Updated");
        } catch (error) {
            if (error?.message?.usersIds) {
                return setError([ONE_ADMIN_REQUIRED_ERROR_MESSAGE]);
            }
            setError([PROCESS_SERVER_ERROR(error)]);
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const handleCompanyAdminChange = async (admins, actionMeta) => {
        const { action } = actionMeta;

        if (action === REMOVE_ACTION_TYPE) {
            return setAdminsToRemove(admins || []);
        }
        await updateCompanyAdmins(admins);
    };

    const onRemoveAdminApprove = async () => {
        await updateCompanyAdmins(adminsToRemove);
        setAdminsToRemove(null);
        dispatch(updateUserProfileData());
    };

    const onRemoveAdminCancel = async () => {
        setAdminsToRemove(null);
    };

    return (
        <form noValidate={true} onSubmit={handleSubmit(onSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            {success && <ErrorNotification type={"success"} message={success} config={{ onClose: onMessageClose }} />}

            <Grid container justify="space-between">
                <Grid item>
                    <h2>General Data</h2>
                </Grid>
                {accountType === STRIPE_BASIC || accountType === STRIPE_PRO ? (
                    <Grid item>
                        <ActionLink>
                            <SimpleLink onClick={onUpdatePaymentMethodClick}>Update Payment Details</SimpleLink>
                        </ActionLink>
                    </Grid>
                ) : null}
            </Grid>
            <br />
            <CompanyAddressForm dispatch={dispatch} form={form} accountType={accountType} />
            <br />
            <br />
            <Field
                type="number"
                name={"workingDayDuration"}
                label={"Working Day Duration"}
                validate={[
                    Validation.required,
                    Validation.isNumber,
                    Validation.isWorkingDay,
                    Validation.maxLengthAfterDot1,
                ]}
                component={TextInputComponent}
            />
            <br />
            <br />
            <DispatchersFieldSelector
                name="companyAdmins"
                label="Company Admins"
                fieldNote={COMPANY_ADMINS_FIELD_NOTE}
                isMulti={true}
                onFieldChange={handleCompanyAdminChange}
                loadedList={companyAdmins}
            />
            <br />
            <h2>Quickbooks</h2>
            <br />
            <Field
                name="arAccount"
                type="text"
                label="Receivable Account"
                validate={[Validation.maxLength100]}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="incomeAccount"
                type="text"
                label="Revenue Account"
                validate={[Validation.maxLength100]}
                component={TextInputComponent}
            />
            <br />
            <br />
            {accountType === STRIPE_BASIC || accountType === STRIPE_PRO ? null : (
                <>
                    <h2>Contact for Payment</h2>
                    <br />
                    <Field
                        name="paymentName"
                        validate={[Validation.noSpecialSymbols]}
                        type="text"
                        label="Name"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <EmailField name="paymentEmail" />
                    <br />
                    <br />
                    <PhoneField name="paymentPhone" />
                    <br />
                    <br />
                </>
            )}
            <ButtonsGroup>
                <PrimaryButton type="submit">Save</PrimaryButton>
            </ButtonsGroup>
            <ConfirmationModal
                yesHandler={onRemoveAdminApprove}
                noHandler={onRemoveAdminCancel}
                question={REMOVE_YOURSELF_CONFIRMATION_TITLE}
                isOpen={!_.isNull(adminsToRemove)}
            />
        </form>
    );
};

General.propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
};

General.defaultProps = {
    formValues: {},
};

export const GENERAL_SETTINGS_FORM = "settingsGeneralForm";

export default reduxCompose(
    reduxForm({
        form: GENERAL_SETTINGS_FORM,
        enableReinitialize: true,
        initialValues: {},
    }),
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            account: state.account,
            formValues: formSelector(state, "certificate", "showPricingInApp", "file", "address", "companyAdmins"),
        };
    }),
)(General);
