import {
    NOTIFICATION_EVENTS_FAILED,
    NOTIFICATION_EVENTS_LOADED,
    NOTIFICATION_EVENTS_LOADING,
    NOTIFICATION_PUSH_EVENT_RECEIVED,
    NOTIFICATION_SNACKBAR_RECEIVED,
    NOTIFICATION_SNACKBAR_REMOVE,
    NOTIFICATION_SNACKBARS_HIDE,
    NOTIFICATION_SNACKBARS_SHOW,
    NOTIFICATIONS_CLEAR,
    NOTIFICATIONS_FILTERS_SET,
    NOTIFICATIONS_REQUESTED_FILTERS_SET,
    NOTIFICATIONS_SET_ALERT_ZONE,
} from "../events/notifications";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    notificationEvents: [],
    notificationFilters: {},
    notificationRequestedFilters: undefined,
    notificationEventsLoading: false,
    notificationEventsError: null,
    notificationEventsCount: 0,
    notificationPushEvents: [],
    notificationSnackbars: [],
    notificationAlertZoneCoordinates: [],
    notificationSnackbarsShow: true,
};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_EVENTS_LOADING:
            return {
                ...state,
                notificationEventsLoading: true,
                notificationEventsError: null,
            };
        case NOTIFICATION_EVENTS_LOADED: {
            return {
                ...state,
                notificationEvents: action.payload.notificationEvents,
                notificationEventsLoading: false,
                notificationEventsError: null,
                notificationEventsCount: action.payload.notificationEventsCount,
            };
        }
        case NOTIFICATION_EVENTS_FAILED:
            return {
                ...state,
                notificationEventsLoading: false,
                notificationEventsError: action.payload.notificationEventsError,
            };
        case NOTIFICATION_PUSH_EVENT_RECEIVED:
            return {
                ...state,
                notificationEvents: state.notificationFilters.type
                    ? state.notificationFilters.type.includes(action.payload.dataSubType)
                        ? [action.payload, ...state.notificationEvents]
                        : state.notificationEvents
                    : [action.payload, ...state.notificationEvents],
                notificationPushEvents: [action.payload, ...state.notificationPushEvents],
            };
        case NOTIFICATION_SNACKBAR_RECEIVED:
            return {
                ...state,
                notificationSnackbars: [action.payload, ...state.notificationSnackbars],
            };
        case NOTIFICATION_SNACKBAR_REMOVE:
            return {
                ...state,
                notificationSnackbars: state.notificationSnackbars.filter(
                    (notification) => notification.key !== action.payload.key,
                ),
            };
        case NOTIFICATION_SNACKBARS_SHOW:
            return {
                ...state,
                notificationSnackbarsShow: true,
            };
        case NOTIFICATION_SNACKBARS_HIDE:
            return {
                ...state,
                notificationSnackbarsShow: false,
            };
        case NOTIFICATIONS_CLEAR:
            return initialState;
        case NOTIFICATIONS_SET_ALERT_ZONE:
            return {
                ...state,
                notificationAlertZoneCoordinates: action.payload,
            };
        case NOTIFICATIONS_FILTERS_SET:
            return {
                ...state,
                notificationFilters: action.payload,
            };
        case NOTIFICATIONS_REQUESTED_FILTERS_SET:
            return {
                ...state,
                notificationRequestedFilters: action.payload,
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
