import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const styles = makeStyles(() => ({
    tooltipContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const SliderMarkTooltip = ({ title, timestamp }) => {
    const classes = styles();

    return (
        <div className={clsx(classes.tooltipContainer)}>
            <div>{title}</div>
            <div>{timestamp}</div>
        </div>
    );
};

SliderMarkTooltip.propTypes = {
    title: PropTypes.string,
    timestamp: PropTypes.string,
};

export default SliderMarkTooltip;
