import React, { useState } from "react";
import { useSelector } from "react-redux";
import TermsDialog from "./termsDialog";

const TermsDialogWrapper = () => {
    const account = useSelector((state) => state.account);
    const [termsDialogOpen, setTermsDialogOpen] = useState(true);

    if (account.hasAgreedToLatestTC || account.isFakeUser) {
        return null;
    }

    return <TermsDialog handleClose={() => setTermsDialogOpen(false)} termsDialogOpen={termsDialogOpen} />;
};

export default TermsDialogWrapper;
