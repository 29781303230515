import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { makeStyles } from "@material-ui/core";
import TrucksList from "./trucksList";
import clsx from "clsx";
import TruckDialog from "./truckDialog";
import { handleTruckDialogToggle, loadCompanyTrucks, resetTrucks } from "../../actions/trucks";
import TrucksFilters from "./trucksFilters";

const useStyles = makeStyles(() => ({
    trucksWrapper: {
        position: "relative",
    },
}));

function TrucksWrapper({ history }) {
    const classes = useStyles();
    const trucks = useSelector((state) => state.trucks.trucksList);
    const activeTruckOrder = useSelector((state) => state.trucks.activeTruckOrder);
    const trucksCount = useSelector((state) => state.trucks.trucksCount);
    const isLoading = useSelector((state) => state.trucks.isTrucksLoading);
    const companyName =
        history.location.state && history.location.state.company ? history.location.state.company.name : "";
    const [trucksFilters, setTrucksFilters] = useState();
    const dispatchHook = useDispatch();
    const loadTrucks = (concat) => {
        let params = { ...trucksFilters };
        if (activeTruckOrder) {
            params.order = activeTruckOrder;
        }
        dispatchHook(loadCompanyTrucks(params, concat));
    };

    const fetchMoreTrucks = (concat) => {
        return loadTrucks(concat);
    };
    const updateListByFilters = (filters) => {
        dispatchHook(resetTrucks());
        setTrucksFilters({
            ...trucksFilters,
            keywords: filters.keywords === undefined ? companyName : filters.keywords,
        });
    };

    useEffect(() => {
        trucksFilters && loadTrucks();
    }, [trucksFilters, activeTruckOrder]);

    return (
        <div className={clsx(classes.trucksWrapper)}>
            <TrucksFilters
                updateListByFilters={updateListByFilters}
                initialValues={{
                    search: companyName,
                }}
            />
            <TrucksList
                isLoading={isLoading}
                trucksCount={trucksCount}
                trucks={trucks}
                trucksFilters={trucksFilters}
                fetchMore={fetchMoreTrucks}
                loadTrucksData={loadTrucks}
            />
            <TruckDialog trucksFilters={trucksFilters} />
        </div>
    );
}

TrucksWrapper.propTypes = {
    handleTruckDialogToggle: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(
    compose(
        connect(
            (state) => state,
            (dispatch) => ({
                handleTruckDialogToggle: (open) => dispatch(handleTruckDialogToggle(open)),
            }),
        ),
    )(TrucksWrapper),
);
