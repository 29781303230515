import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TimeStampSlider from "./slider/timeStampSlider";

class CustomTimeStampSlider extends Component {
    constructor(props) {
        super(props);
        const { getCurrentValue, time } = props;

        this.timeChangeHandler = this.timeChangeHandler.bind(this);
        this.state = { value: time };

        getCurrentValue && getCurrentValue(time);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { time } = this.props;
        const { time: newTime } = nextProps;

        if (newTime && !_.isEqual(time, newTime)) {
            this.timeChangeHandler(newTime);
        }
    }

    timeChangeHandler(time) {
        const { getCurrentValue } = this.props;

        this.setState({
            value: time,
        });

        getCurrentValue && getCurrentValue(time);
    }

    render() {
        const { name, minTime, maxTime, disabled, format, timezone } = this.props;
        const { value } = this.state;

        return (
            <div>
                <TimeStampSlider
                    disabled={disabled}
                    format={format}
                    timezone={timezone}
                    minValue={minTime}
                    maxValue={maxTime}
                    draggableTrack={false}
                    name={name}
                    onChange={this.timeChangeHandler}
                    step={1}
                    value={value}
                />
            </div>
        );
    }
}

CustomTimeStampSlider.propTypes = {
    getCurrentValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,
    format: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};

CustomTimeStampSlider.defaultProps = {
    time: "06:30 AM",
    disabled: false,
    maxTime: "06:30 AM",
    minTime: "06:30 PM",
    format: 12,
    timezone: "US/Eastern",
};

export default CustomTimeStampSlider;
