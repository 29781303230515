import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TimelineIcon from "@material-ui/icons/Timeline";
import RoomIcon from "@material-ui/icons/Room";
import clsx from "clsx";
import { SimpleLink } from "../../core/link";
import _ from "lodash";
import { StatusPill } from "../../core/pills";
import { timeFromSeconds } from "../../../helpers/jobOrders";
import { ICONS_SITE } from "../../../styles/icons";
import { change, Field, formValueSelector } from "redux-form";
import { Validation } from "../../../helpers/validation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../../core/form/asyncAutocompleteComponent";
import { fetchCompanySitesList } from "../../../dataServers/purchaseOrders";
import HiddenInput from "../../core/form/hiddenInput";
import { connect } from "react-redux";
import SiteForm, { SITE_FORM } from "../../sites/siteForm";
import AppModal from "../../core/modal";
import {
    getAppliedFiltersFromLocalStorage,
    getSiteEditRoute,
    setAppliedFiltersToLocalStorage,
} from "../../../helpers/global";
import { ROUTE_ANALYTICS } from "../../../routes/globalRoutes";
import { JO_STATUS_NOT_STARTED_ID } from "../../../constants/maps";
import { REPORT_TRUCKS_ACTIVITY_FORM } from "../../../components/reportAndAnalytic/trucksActivity";

const useStyles = makeStyles(() => ({
    pickUpDropOffSection: {
        "& .-small-size": {
            "& .___value-container--is-multi": {
                maxWidth: "80%",
            },
        },
    },
}));

const PickUpDropOff = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        showSiteModal: false,
        fieldName: null,
    });
    const { showSiteModal, fieldName } = state;
    const {
        jobOrder,
        summaryInfo,
        className,
        canSetSites,
        additionalField,
        disabled,
        smallSize,
        afterSaveHandler,
        formValues,
        history,
        currentProject,
        currentProjectCompanyId,
        onFieldChange,
        sitesRequestParams,
        dropOffAdditionalValidation,
        isDropOffMidpoint,
        isPickUpMidpoint,
    } = props;
    const siteIconsClasses = ICONS_SITE();
    const loadSites = (inputValue, { params = {}, loadedCount }) => {
        const restParams = {
            ...sitesRequestParams,
        };
        if (formValues.siteFilter && formValues.siteFilter.id) {
            restParams.accessType = formValues.siteFilter.id;
        }

        return fetchCompanySitesList({
            keywords: inputValue,
            company: currentProject?.owner?.id || currentProjectCompanyId,
            ...params,
            ...restParams,
        }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: `${i.name} - ${i.address}`,
                latitude: i.latitude,
                longitude: i.longitude,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };
    const closeModal = () => {
        showCreateSiteModal(false, null);
    };
    const showCreateSiteModal = (show, fieldName) => {
        setState({
            ...state,
            showSiteModal: show,
            fieldName,
        });
    };
    const onRedirectToTruckActivity = () => {
        const { startDate, endDate } = getAppliedFiltersFromLocalStorage("jobOrdersFilters");
        setAppliedFiltersToLocalStorage(REPORT_TRUCKS_ACTIVITY_FORM, { startDate, endDate });
        const project = jobOrder.poLineItem.purchaseOrder.project;
        history.push(ROUTE_ANALYTICS.TRUCK_ACTIVITY, { redirect: true });
        props.changeTruckActivityForm("redirect", true);
        props.changeTruckActivityForm("jobOrder", { ...jobOrder });
        props.changeTruckActivityForm("project", {
            ...project,
            value: project.id,
            label: project.name,
        });
    };
    const isStarted = jobOrder.status !== JO_STATUS_NOT_STARTED_ID;
    const smallBox = summaryInfo || additionalField;

    return (
        <div className={clsx(classes.pickUpDropOffSection)}>
            <Grid component="div" container justify={"space-between"} alignItems={"center"}>
                <Grid item component="div" xs={smallBox ? 6 : 12} className={className}>
                    {!canSetSites && jobOrder.pickUpSite && (
                        <Box display="flex">
                            <RoomIcon
                                className={clsx(siteIconsClasses.iconSite, isPickUpMidpoint ? "mid" : "pick-up")}
                            />
                            <SimpleLink to={getSiteEditRoute(jobOrder.pickUpSite.id)}>
                                {jobOrder.pickUpSite.name}
                            </SimpleLink>
                            <Field name={PICK_UP_SITE_NAME} component={HiddenInput} />
                        </Box>
                    )}
                    {canSetSites && (
                        <Field
                            name={PICK_UP_SITE_NAME}
                            validate={[Validation.required]}
                            disabled={disabled}
                            loadOptions={loadSites}
                            placeholder="Pick-Up Site"
                            startAdornment={
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, isPickUpMidpoint ? "mid" : "pick-up")}
                                />
                            }
                            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, smallSize && "-small-size")}
                            createNewOption={{
                                label: `Add Site`,
                                value: CREATE_NEW_OPTION_ID,
                            }}
                            createNewHandler={() => showCreateSiteModal(true, PICK_UP_SITE_NAME)}
                            wrapperClassName="pick-up"
                            onFieldChange={onFieldChange}
                            component={AsyncAutocompleteComponent}
                        />
                    )}
                    <br />
                    {!canSetSites &&
                        !_.isEmpty(jobOrder.dropOffSites) &&
                        jobOrder.dropOffSites.map((site) => {
                            const { id, name } = site;

                            return (
                                <Box marginBottom={1} key={id} display="flex">
                                    <RoomIcon
                                        className={clsx(
                                            siteIconsClasses.iconSite,
                                            isDropOffMidpoint ? "mid" : "drop-off",
                                        )}
                                    />
                                    <SimpleLink to={getSiteEditRoute(id)}>{name}</SimpleLink>
                                    <Field name={DROP_OF_SITE_NAME} component={HiddenInput} />
                                </Box>
                            );
                        })}
                    {canSetSites && (
                        <Field
                            name={DROP_OF_SITE_NAME}
                            loadOptions={loadSites}
                            validate={[Validation.required, ...dropOffAdditionalValidation]}
                            disabled={disabled}
                            startAdornment={
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, isDropOffMidpoint ? "mid" : "drop-off")}
                                />
                            }
                            placeholder="Drop-Off Site(s)"
                            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, smallSize && "-small-size")}
                            isMulti={true}
                            createNewOption={{
                                label: `Add Site`,
                                value: CREATE_NEW_OPTION_ID,
                            }}
                            createNewHandler={() => showCreateSiteModal(true, DROP_OF_SITE_NAME)}
                            wrapperClassName="drop-off"
                            component={AsyncAutocompleteComponent}
                        />
                    )}
                </Grid>
                {summaryInfo && (
                    <Grid component="div" item xs={6}>
                        <Box
                            display="flex"
                            justifyContent={isStarted ? "space-between" : "center"}
                            textAlign="center"
                            fontSize={12}
                        >
                            <Box
                                marginBottom={0.5}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                className="turn-time"
                            >
                                <span>Expected Turn Time</span>
                                <StatusPill
                                    label={summaryInfo && timeFromSeconds(+summaryInfo.distanceDuration)}
                                    className={classes.expectedTime}
                                    type="default"
                                />
                            </Box>
                            {isStarted && (
                                <Box
                                    marginBottom={0.5}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    className="turn-time"
                                >
                                    <SimpleLink onClick={onRedirectToTruckActivity}>Truck Activity</SimpleLink>
                                    <TimelineIcon />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                )}
                {!!additionalField && additionalField}
                <AppModal isOpen={showSiteModal} modalStyles={{ width: 600 }} form={SITE_FORM} closeModal={closeModal}>
                    <SiteForm
                        closeModal={closeModal}
                        createForDropdown={true}
                        fieldName={fieldName}
                        afterSaveHandler={afterSaveHandler}
                        initialValues={{
                            // When creating a new site, we should by default turn on the "create alert zone"
                            // https://github.com/TruckITllc/truckit-frontend/issues/424#issue-597412526
                            alertZone: true,
                            // have a default value of 100 meter.
                            // https://github.com/TruckITllc/truckit-frontend/issues/424#issue-597412526
                            radius: DEFAULT_RADIUS_VALUE,
                        }}
                    />
                </AppModal>
            </Grid>
        </div>
    );
};

export const DROP_OF_SITE_NAME = "dropOffSites";
export const PICK_UP_SITE_NAME = "pickUpSite";
export const DEFAULT_RADIUS_VALUE = 500;

PickUpDropOff.propTypes = {
    summaryInfo: PropTypes.object,
    formValues: PropTypes.object,
    jobOrder: PropTypes.object,
    canSetSites: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    additionalField: PropTypes.any,
    form: PropTypes.string.isRequired,
    smallSize: PropTypes.bool,
    afterSaveHandler: PropTypes.func,
    sitesRequestParams: PropTypes.object,
    history: PropTypes.object,
    currentProject: PropTypes.object,
    currentProjectCompanyId: PropTypes.number,
    onFieldChange: PropTypes.func,
    dropOffAdditionalValidation: PropTypes.array,
};

PickUpDropOff.defaultProps = {
    canSetSites: false,
    jobOrder: {},
    form: "",
    afterSaveHandler: () => {},
    sitesRequestParams: {},
    dropOffAdditionalValidation: [],
};

export default withRouter(
    connect(
        (state, props) => {
            let formValues = {};

            if (props.canSetSites) {
                const formSelector = formValueSelector(props.form);

                formValues = formSelector(state, PICK_UP_SITE_NAME, DROP_OF_SITE_NAME, "siteFilter");
            }

            return {
                currentProject: state.projects.currentProject,
                currentProjectCompanyId: state.projects.currentProjectCompany?.id,
                account: state.account,
                formValues,
            };
        },
        (dispatch) => {
            return {
                changeTruckActivityForm: (field, value) => dispatch(change("truckActivity", field, value)),
            };
        },
    )(PickUpDropOff),
);
