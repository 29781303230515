import PropTypes from "prop-types";
import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { MAIN_THEME } from "../../styles/globalThemeConfig";

const joPillStyles = makeStyles((theme) => ({
    joPill: ({ manuallyAdded }) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "30px",
        padding: "2px 5px",
        margin: "2px",
        paddingRight: "5px",
        whiteSpace: "nowrap",
        minWidth: "10%",
        backgroundColor: manuallyAdded ? theme.palette.secondary.superDark : theme.palette.secondary.light,
        borderRadius: "15px",
        color: "#fff",
        textTransform: "uppercase",
    }),
}));

export const SmartDispatchBundleJoPill = ({ jobOrderId, manuallyAdded }) => {
    const classes = joPillStyles({ manuallyAdded });

    return (
        <Tooltip title={"Job added manually to the route"} enterDelay={200} disableHoverListener={!manuallyAdded}>
            <div className={classes.joPill}>JO # {jobOrderId}</div>
        </Tooltip>
    );
};

SmartDispatchBundleJoPill.propTypes = {
    jobOrderId: PropTypes.number,
    manuallyAdded: PropTypes.bool,
};

// bundle statuses
export const BUNDLE_ERROR = "error";
export const BUNDLE_INVALIDATED = "invalidated";
export const BUNDLE_PENDING = "pending";
export const BUNDLE_READY_TO_ASSIGN = "ready_to_assign";
export const BUNDLE_PARTIALLY_ASSIGNED = "partially_assigned";
export const BUNDLE_FULLY_ASSIGNED = "fully_assigned";
export const BUNDLE_AWAITING_CONFIRMATION = "awaiting_confirmation";
export const BUNDLE_AWAITING_REJECTED = "reject";
export const BUNDLE_RECALCULATED = "recalculated";

const statusPillPalette = {
    [BUNDLE_ERROR]: MAIN_THEME.palette.general.paleRed,
    [BUNDLE_INVALIDATED]: MAIN_THEME.palette.general.paleRed,
    [BUNDLE_PENDING]: MAIN_THEME.palette.general.pending,
    [BUNDLE_READY_TO_ASSIGN]: MAIN_THEME.palette.general.softGreen,
    [BUNDLE_PARTIALLY_ASSIGNED]: MAIN_THEME.palette.general.softGreen,
    [BUNDLE_FULLY_ASSIGNED]: MAIN_THEME.palette.general.softGreen,
    [BUNDLE_AWAITING_CONFIRMATION]: MAIN_THEME.palette.general.pending,
    [BUNDLE_AWAITING_REJECTED]: MAIN_THEME.palette.general.paleRed,
    [BUNDLE_RECALCULATED]: MAIN_THEME.palette.general.paleRed,
};

const STATUS_PILL_LABELS = {
    [BUNDLE_ERROR]: "Error",
    [BUNDLE_INVALIDATED]: "Invalidated",
    [BUNDLE_PENDING]: "Pending",
    [BUNDLE_READY_TO_ASSIGN]: "Ready To Assign",
    [BUNDLE_PARTIALLY_ASSIGNED]: "Partially Assigned",
    [BUNDLE_FULLY_ASSIGNED]: "Fully Assigned",
    [BUNDLE_AWAITING_CONFIRMATION]: "Awaiting Confirmation",
    [BUNDLE_AWAITING_REJECTED]: "Rejected",
    [BUNDLE_RECALCULATED]: "Recalculated",
};

const bundleStatusStyles = makeStyles((theme) => ({
    bundleStatusPill: {
        textAlign: "center",
        backgroundColor: theme.palette.general.softGreen,
        borderRadius: "10px",
        color: "#fff",
        paddingRight: "10px",
        paddingLeft: "10px",
    },
}));

export const SmartDispatchBundlePill = ({ status }) => {
    const classes = bundleStatusStyles();

    return (
        <div className={classes.bundleStatusPill} style={{ backgroundColor: statusPillPalette[status] }}>
            {STATUS_PILL_LABELS[status]}
        </div>
    );
};

SmartDispatchBundlePill.propTypes = {
    status: PropTypes.string,
};
