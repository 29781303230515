import React from "react";
import PropTypes from "prop-types";

const BundleData = ({ bundleCreatedDate, bundleCreator, bundleTrucksCount, bundleRegion }) => {
    return (
        <>
            <h5>
                Created Date: <span className="--font-400">{bundleCreatedDate}</span>
            </h5>
            <h5>
                Created By: <span className="--font-400">{bundleCreator}</span>{" "}
            </h5>
            <h5>
                Calculated Trucks: <span className="--font-400">{bundleTrucksCount}</span>
            </h5>
            <h5>
                Region: <span className="--font-400">{bundleRegion}</span>
            </h5>
        </>
    );
};

BundleData.propTypes = {
    bundleCreatedDate: PropTypes.string,
    bundleCreator: PropTypes.string,
    bundleTrucksCount: PropTypes.any,
    bundleRegion: PropTypes.string,
};

export default BundleData;
