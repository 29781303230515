import React, { useEffect, useState } from "react";
import clsx from "clsx";
import _ from "lodash";

import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import { Divider } from "../../core";
import { fetchTrailersHistory } from "../../../dataServers/jobOrder";
import TrailersHistory from "./TrailersHistory";
import ErrorNotification from "../../core/notification";

const TrailersJobDetailsTab = ({ jobOrderId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [trailersHistory, setTrailersHistory] = useState(null);

    useEffect(() => {
        if (jobOrderId) {
            setIsLoading(true);
            fetchTrailersHistory(jobOrderId)
                .then(({ data }) => {
                    setTrailersHistory(data);
                })
                .catch((error) => {
                    setError([PROCESS_SERVER_ERROR(error)]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [jobOrderId]);

    return (
        <div className={clsx(isLoading && `${LOADER_WHITE_OVERLAY} -min-height`)}>
            <Divider />
            {!_.isEmpty(trailersHistory) ? (
                trailersHistory.map((trailerHistory) => {
                    return <TrailersHistory trailerHistoryData={trailerHistory} key={trailerHistory.truckId} />;
                })
            ) : (
                <h2 className="--text-center">No trailers data</h2>
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </div>
    );
};
export default TrailersJobDetailsTab;
