import {
    WEBSOCKET_EVENT_TYPE_TICKET_CLOSED,
    WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED,
    WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED,
    WEBSOCKET_EVENT_TYPE_TICKET_OPENED,
    WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICKET_REOPENED,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/maps";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_TICKET_CLOSED]: "Ticket |#{ticket.id}| was closed by |{truck.deviceName}|",
    [WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED]: "Ticket |#{ticket.id}| was disputed",
    [WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED]: "Ticket |#{ticket.id}| was closed by System",
    [WEBSOCKET_EVENT_TYPE_TICKET_OPENED]: "Ticket |#{ticket.id}| was opened by |{truck.deviceName}|",
    [WEBSOCKET_EVENT_TYPE_TICKET_REOPENED]: "Ticket |#{ticket.id}| was reopened by |{truck.deviceName}|",
    [WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED]: "Ticket |#{ticket.id}| was finally reconciled",
    [WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED]: "Ticket |#{ticket.id}| was reconciled",
    [WEBSOCKET_EVENT_TYPE_TICPIC_REPORT]: "{event.message}",
    [WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED]: "{event.failMessage}",
};

const getNotificationTitle = (event) => {
    return notificationTitles[event.type]
        .replace(
            "{truck.deviceName}",
            (event.truck || {}).deviceName ? (event.truck || {}).deviceName.toUpperCase() : "Unknown",
        )
        .replace("{ticket.id}", `${(event.ticket || {}).id}&link` || "Unknown")
        .replace("{event.message}", event.message)
        .replace("{event.failMessage}", event.message || "Download error");
};

export const processTicketEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
