import { LIVEMAP_TRUCK_SELECTED } from "../events/livemap";

export const setLivemapTruck = (selectedLivemapTruck) => {
    return {
        type: LIVEMAP_TRUCK_SELECTED,
        payload: {
            selectedLivemapTruck,
        },
    };
};
