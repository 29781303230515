import React from "react";

const PredictiveSearchOptionLabel = ({ categoryName, value }) => {
    return (
        <div>
            {categoryName && <span className="--font-900 --capitalize">{categoryName}: </span>}
            <span className="--font-300">{value}</span>
        </div>
    );
};

export default PredictiveSearchOptionLabel;
