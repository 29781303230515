import React, { useEffect, useRef } from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CustomSwitch from "../core/form/customSwitch";
import { updateJobOrder } from "../../actions/jobOrders";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { selectActiveSmartDispatchTab } from "../../selectors/smartDispatchSelectors";

const BundleDraftModeForm = ({ dispatch, form, isIntraDay }) => {
    const isInitialMount = useRef(true);
    const isDraft = useSelector((state) => formValueSelector(form)(state, "isDraft"));
    const activeSmartDispatchTab = useSelector(selectActiveSmartDispatchTab);
    const disabled = isIntraDay && activeSmartDispatchTab === 0;

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            dispatch(updateJobOrder());
        }
    }, [isDraft]);

    return <Field name="isDraft" type="checkbox" label="Draft Mode" component={CustomSwitch} disabled={disabled} />;
};

export const BUNDLE_DRAFT_MODE_FORM = "bundleDraftModeForm";

BundleDraftModeForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    isIntraDay: PropTypes.bool,
};

export default withRouter(
    compose(
        reduxForm({
            form: BUNDLE_DRAFT_MODE_FORM,
            initialValues: { isDraft: false },
        }),
    )(BundleDraftModeForm),
);
