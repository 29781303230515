import { change, Field, formValueSelector, reduxForm } from "redux-form";
import React, { useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TocIcon from "@material-ui/icons/Toc";

import { START_DATE_NAME } from "../../global/datesRange";
import { Validation } from "../../../helpers/validation";
import DateTimePicker from "../../core/form/dateTimePicker";
import { ButtonsGroup, Divider, PrimaryButton, SecondaryButton, SuccessNotification } from "../../core";
import { ICONS_SITE } from "../../../styles/icons";
import QuantityWithUnlimited from "../../core/quantityWithUnlimited";
import TextInputComponent from "../../core/form/textInputComponent";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobComponents/pickUp&DropOff";
import CustomSwitch from "../../core/form/customSwitch";
import { DEFAULT_DATE_TIME_FORMAT, LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";
import { createOrUpdateJobOrderRequest, deleteJobOrderRequest } from "../../../actions/jobOrders";
import ErrorNotification from "../../core/notification";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import ConfirmationModal from "../../core/confirmationModal";
import { selectCurrentJobOrderRequest } from "../../../selectors/jobOrders";
import RequestItems from "./RequestItems";

export const useStyles = makeStyles((theme) => ({
    generalPadding: {
        padding: "20px 27px",
    },
    jobOrderRequestForm: {
        margin: "15px",
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",

        "& .sites-section": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            "& .pick-up": {
                marginRight: "8px",
            },

            "& .drop-off": {
                marginLeft: "8px",
            },
        },
    },

    startDateContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "8px",

        "& .date-input-container": {
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "8px",
        },

        "& .today-button": {
            marginRight: "8px",
        },
    },

    overnightContainer: {
        paddingLeft: "8px",
        display: "flex",
        alignItems: "center",
    },
    requestedTrucksField: {
        marginLeft: "7px",
    },
    sitesContainer: {
        padding: "0 27px",
    },
    payloadContainer: {
        padding: "20px 27px 0",
    },
}));

const loadStaticDropOffSites = (dropOffSites) => {
    return Promise.resolve().then(() => {
        return {
            options: dropOffSites.map((site) => {
                return {
                    value: site.id,
                    label: site.name,
                };
            }),
            hasMore: false,
        };
    });
};

const RequestJobOrderForm = ({
    requestPOLineItem,
    handleSubmit,
    submitting,
    form,
    requestId,
    error,
    history,
    pristine,
    setError,
}) => {
    const siteIconsClasses = ICONS_SITE();
    const classes = useStyles();
    const dispatch = useDispatch();

    const startDate = useSelector((state) => formValueSelector(REQUEST_JOB_ORDER_FORM)(state, "startDate"));
    const currentJobOrderRequest = useSelector(selectCurrentJobOrderRequest);

    const [success, setSuccess] = useState(null);
    const [closeConfirmModal, setCloseConfirmModal] = useState(false);
    const [cancelRequestConfirmModal, setCancelRequestConfirmModal] = useState(false);

    const disableCancelButton = !currentJobOrderRequest?.canBeRemoved;

    const onRequestDateChange = (date) => {
        const newDate = {
            year: moment(date || moment()).get("year"),
            month: moment(date || moment()).get("month"),
            date: moment(date || moment()).get("date"),
        };
        const requestDate = moment(startDate || moment()).set(newDate);

        dispatch(change(form, START_DATE_NAME, requestDate));
    };

    const onCloseFormClick = () => {
        if (pristine) {
            history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        } else {
            setCloseConfirmModal(true);
        }
    };

    const closeFormYesHandler = () => {
        history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
    };

    const closeFormNoHandler = () => {
        setCloseConfirmModal(false);
    };

    const onCancelRequestClick = () => {
        setCancelRequestConfirmModal(true);
    };

    const cancelFormNoHandler = () => {
        setCancelRequestConfirmModal(false);
    };

    const cancelFormYesHandler = async () => {
        const response = await dispatch(deleteJobOrderRequest(requestId, setError));
        if (response?.status === 200) {
            history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        }
        setCancelRequestConfirmModal(false);
    };

    const onSubmit = async (values) => {
        await dispatch(createOrUpdateJobOrderRequest(values, requestPOLineItem.id, requestId));

        if (requestId) {
            setSuccess(["Request was updated"]);
        } else {
            setSuccess(["Request was created"]);
        }
        history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
    };

    return (
        <>
            <form
                className={clsx(classes.jobOrderRequestForm, submitting && LOADER_WHITE_OVERLAY)}
                onSubmit={handleSubmit(onSubmit)}
            >
                {submitting && <Loader />}
                <Grid container justify="space-between" className={clsx(classes.generalPadding)}>
                    <Grid item xs={6}>
                        <h2>Request {requestId ? `# ${requestId}` : "Job"}</h2>
                    </Grid>
                    <Grid item xs={5} className={clsx(classes.startDateContainer)}>
                        <div className="date-input-container">
                            <Field
                                name={START_DATE_NAME}
                                validate={[Validation.required]}
                                placeholder="Start Date"
                                component={DateTimePicker}
                                dateFormat={DEFAULT_DATE_TIME_FORMAT}
                                className={classes.dateTimePicker}
                            />
                        </div>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
                            <SecondaryButton
                                size={"small"}
                                className={"today-button"}
                                onClick={() => onRequestDateChange(moment())}
                            >
                                Today
                            </SecondaryButton>
                            <SecondaryButton size={"small"} onClick={() => onRequestDateChange(moment().add(1, "d"))}>
                                Tomorrow
                            </SecondaryButton>
                        </Box>
                    </Grid>
                </Grid>
                <Divider marginBottom={0} marginTop={0} />
                <Grid container justify="space-between" alignItems="center" className={classes.payloadContainer}>
                    <Grid item xs={6}>
                        <Box display="flex" alignItems="center">
                            <TocIcon className={clsx(classes.tocIconsStyles, "icon")} />
                            <h3>Payload: {requestPOLineItem.payload.name}</h3>
                        </Box>
                    </Grid>
                    <Grid item xs={3} className={classes.overnightContainer}>
                        <Field name="isOvernight" type="checkbox" label="Overnight" component={CustomSwitch} />
                    </Grid>
                </Grid>
                <Grid container className={classes.generalPadding}>
                    <Grid item xs={6}>
                        <QuantityWithUnlimited form={form} name="totalQuantity" dispatch={dispatch} />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="numberOfTrucksRequested"
                            type="number"
                            validate={[Validation.isInteger, Validation.numbersPositive, Validation.greaterThan0]}
                            placeholder="Requested Trucks"
                            label="Requested Trucks"
                            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, classes.requestedTrucksField)}
                            component={TextInputComponent}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="space-between" className={classes.sitesContainer}>
                    <Grid item xs={12} className="sites-section">
                        <Field
                            name={PICK_UP_SITE_NAME}
                            validate={[Validation.required]}
                            disabled={true}
                            placeholder="Pick-Up Site"
                            startAdornment={<LocationOnIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />}
                            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, "pick-up")}
                            component={AsyncAutocompleteComponent}
                        />
                        <Field
                            name={DROP_OF_SITE_NAME}
                            validate={[Validation.required]}
                            loadOptions={() => loadStaticDropOffSites(requestPOLineItem.dropOffSites)}
                            startAdornment={<LocationOnIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />}
                            placeholder="Drop-Off Site(s)"
                            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, "drop-off")}
                            isMulti={true}
                            component={AsyncAutocompleteComponent}
                        />
                    </Grid>
                </Grid>
                {currentJobOrderRequest && <RequestItems requestId={requestId} />}
                <Grid container className={classes.generalPadding}>
                    <Grid item xs={12}>
                        <Field
                            name="requestNotes"
                            rows={3}
                            label="Request notes (optional)"
                            placeholder="Request notes (optional)"
                            multiline={true}
                            needShowEndAdornment={false}
                            component={TextInputComponent}
                        />
                    </Grid>
                </Grid>
                <div className={classes.generalPadding}>
                    <ButtonsGroup justifyContent="flex-end">
                        <SecondaryButton onClick={() => onCloseFormClick()}>Close Form</SecondaryButton>
                        {currentJobOrderRequest && (
                            <SecondaryButton onClick={() => onCancelRequestClick()} disabled={disableCancelButton}>
                                Cancel Request
                            </SecondaryButton>
                        )}
                        <PrimaryButton type="submit">{requestId ? "Save" : "Request Job"}</PrimaryButton>
                    </ButtonsGroup>
                </div>
                {error && <ErrorNotification message={error} config={{ autoHideDuration: 5000 }} />}
                {success && (
                    <SuccessNotification
                        message={success}
                        type={"success"}
                        config={{ onClose: () => setSuccess(null) }}
                    />
                )}
            </form>
            <ConfirmationModal
                yesHandler={closeFormYesHandler}
                noHandler={closeFormNoHandler}
                isOpen={closeConfirmModal}
            />
            <ConfirmationModal
                yesHandler={cancelFormYesHandler}
                noHandler={cancelFormNoHandler}
                isOpen={cancelRequestConfirmModal}
                question={"Are you sure you want to cancel request?"}
            />
        </>
    );
};

export const REQUEST_JOB_ORDER_FORM = "requestJobOrderForm";

export default compose(
    withRouter,
    reduxForm({
        form: REQUEST_JOB_ORDER_FORM,
    }),
)(RequestJobOrderForm);
