import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { change, Field, formValueSelector, getFormValues, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { compose as reduxCompose } from "redux";
import { useLocation } from "react-router";
import ReactGA from "react-ga";
import { withSnackbar } from "notistack";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";

import { SimpleLink } from "../components/core/link";
import { LOGIN_STYLES, PRICE_PLANS } from "../styles/reusableStyles";
import { ROUTE_LOGIN, ROUTE_SING_UP_INTERNAL } from "../routes/globalRoutes";
import { fullCompanyAddress } from "../constants/users.js";
import { getPricePlans, userSignUp } from "../dataServers/user";
import { loadStates } from "../actions/globalActions.js";
import ErrorNotification from "../components/core/notification";
import Loader from "../components/core/loader";
import TextInputComponent from "../components/core/form/textInputComponent";
import { ButtonsGroup } from "../components/core/buttons/buttonsGroup";
import { PrimaryButton } from "../components/core/buttons/primaryButton";
import HiddenInput from "../components/core/form/hiddenInput";
import CustomCheckbox from "../components/core/form/customCheckbox";
import { connect } from "react-redux";
import { Validation, VALIDATION_ERRORS } from "../helpers/validation";
import { GLOBAL_ENDPOINTS } from "../constants/endpoints";
import { USER_ROLE_CUSTOMER, USER_ROLE_INSPECTOR, USER_ROLE_TRUCKER } from "../constants/maps";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR, TRUCKIT_SUPPORT_EMAIL } from "../constants/global";
import { loadStaticOptions, parseQuery, preparePhone } from "../helpers/global";
import { ACCOUNT_DATA_STORAGE_KEY } from "../helpers/api";
import PhoneField from "../components/global/phoneField";
import PasswordField from "../components/global/passwordField";
import EmailField from "../components/global/emailField";
import { MailTo } from "../components/core";
import {
    ACCOUNT_TYPES,
    IMPERIAL,
    MEASUREMENT_SYSTEM,
    MEASUREMENT_SYSTEM_NAME,
    MEASUREMENT_SYSTEM_PLACEHOLDER,
    STRIPE_BASIC,
    STRIPE_PRO,
} from "../constants/accounts";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
} from "../components/core/form/asyncAutocompleteComponent.jsx";

const minWidth = "950";

const useStyles = makeStyles((theme) => ({
    createUserForm: {
        "& .terms-and-conditions": {
            border: theme.palette.border.primary,
            padding: 20,
            backgroundColor: "#fff",

            "& .container-loader-wrap, & .container-loader": {
                zIndex: 1,
            },

            "& .terms-wrap": {
                position: "relative",
                zIndex: 2,

                "& .iframe": {
                    background: "#fff",
                },
            },
        },
    },
    columnContainer: {
        [theme.breakpoints.down(minWidth)]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    fullWidthItem: {
        [theme.breakpoints.down(minWidth)]: {
            width: "100%",
            maxWidth: "100%",
        },
    },
    formStyles: {
        padding: "0 24px",
        [theme.breakpoints.down(minWidth)]: {
            padding: "24px 0",
        },
    },
    responsiveStyles: {
        [theme.breakpoints.down(minWidth)]: {
            "& form": {
                minWidth: 0,
                padding: 0,
            },

            "& .content-container": {
                padding: "0 5px",
            },

            "& .MuiStepper-root": {
                padding: 0,
            },
            "& .MuiStep-horizontal": {
                padding: 0,
            },
        },
    },
    userInfoResponsive: {
        [theme.breakpoints.down(minWidth)]: {
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    formContainer: {
        paddingBottom: 200,
    },
}));

function getSteps() {
    return ["Type of Account", "Company Contact info", "Terms and Conditions", "User Info"];
}

const validate = ({ accountTypeGroup, acceptTermsAndConditions, password, passwordConfirm }) => {
    const errors = {};

    if (!accountTypeGroup) {
        errors.firstStepError = "Please make the choice.";
    }

    if (!acceptTermsAndConditions) {
        errors.thirdStepError = "Please accept Terms and Conditions to continue.";
    }

    if (password !== passwordConfirm) {
        errors.passwordConfirm = VALIDATION_ERRORS.passwordConfirm;
    }

    return errors;
};

const PricePlans = ({ handleError, handleLoad, accountType }) => {
    const classes = PRICE_PLANS();
    const localClasses = useStyles();
    const [pricePlans, setPricePlans] = useState(null);

    const loadPricePlans = () => {
        handleLoad(true);
        getPricePlans(accountType)
            .then((data) => {
                setPricePlans(data.data);
                handleLoad(false);
            })
            .catch((error) => {
                handleError(error);
            });
    };

    useEffect(() => loadPricePlans(), []);

    const returnSelectedPlan = () => (accountType === STRIPE_BASIC ? "Basic" : "Pro");

    const returnUnitAmount = () =>
        pricePlans && pricePlans.unitAmount / 100 < 1
            ? pricePlans && "$." + `${pricePlans.unitAmount / 100}`.split(".")[1]
            : pricePlans && `$${pricePlans.unitAmount / 100}`;

    const returnFooterInfo = () =>
        pricePlans && `${pricePlans.upTo} loads included, ${returnUnitAmount()} per load after`;

    const returnCountOfLoads = () => pricePlans && `${pricePlans.upTo} loads included`;

    const returnFlatAmount = () => pricePlans && `$${+pricePlans.flatAmount / 100}`;

    return (
        <Grid item xs={6} className={clsx(localClasses.fullWidthItem, classes.container)}>
            <Box className={classes.header}>
                <Box>
                    <Typography variant="h1" className={classes.header}>
                        Selected Plan:
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.main}>
                <Typography variant="h1" className={classes.title}>
                    {returnSelectedPlan()}
                </Typography>
                <Box>
                    <li className={classes.listItem}>
                        <span className={classes.specialSymbol}>-</span>
                        <span>{returnCountOfLoads()}</span>
                    </li>
                    <li className={classes.listItem}>
                        <span className={classes.specialSymbol}>-</span>
                        <span>
                            Access to web portal and mobile app for unlimited drivers, dispatchers, and third party
                            haulers
                        </span>
                    </li>
                </Box>
                <Divider />
                <Typography variant="caption" className={classes.secondItem}>
                    Recurring Monthly Cost
                </Typography>
            </Box>
            <Box className={classes.footer}>
                <Box className={classes.detailsContainer}>
                    <Box className={classes.item}>
                        <Typography variant="subtitle2">{returnSelectedPlan()}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography variant="body2">{returnFlatAmount()}</Typography>
                    </Box>
                    <Typography variant="caption" color="red" className={classes.footerItem}>
                        {returnFooterInfo()}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};

const CONTRACTOR_ID = "customer";
const TRUCKER_ID = "trucker";
const INSPECTOR_ID = "inspector";

const CompanyAddressForm = (props) => {
    const { accountType, setStateValue } = props;

    return (
        <React.Fragment>
            <h3 className="--text-center">Company Address</h3>
            <br />
            <Field
                name="streetAddress"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="Street Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="secondaryAddress"
                validate={[Validation.noSpecialSymbols]}
                type="text"
                label="Secondary Address"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="city"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                type="text"
                label="City"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="state"
                placeholder="State"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={[Validation.required]}
                onFieldChange={setStateValue}
                loadOptions={loadStates}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <Field
                name="zipCode"
                validate={[Validation.required]}
                type="text"
                label="Zip Code"
                component={TextInputComponent}
            />
            {accountType === STRIPE_PRO || accountType === STRIPE_BASIC ? null : (
                <>
                    <br />
                    <br />
                    <h3 className="--text-center">Contact for Payments</h3>
                    <br />
                    <Field
                        name="paymentName"
                        validate={[Validation.noSpecialSymbols]}
                        type="text"
                        label="Name"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <EmailField name="paymentEmail" />
                    <br />
                    <br />
                    <PhoneField name="paymentPhone" />
                    <br />
                </>
            )}
        </React.Fragment>
    );
};

function getStepContent(
    stepIndex,
    accountType,
    { accountTypeGroup, setCompanyValue, setStateValue, renderPricePlans, localClasses, loadStaticOptions },
) {
    switch (stepIndex) {
        case 0:
            return (
                <div>
                    <br />
                    <h3 className="--text-center">What type of Account do you want to create?</h3>
                    <br />
                    <ButtonsGroup justifyContent={"space-around"}>
                        <Field
                            name="accountTypeGroup"
                            type="radio"
                            value={CONTRACTOR_ID}
                            label="I need Trucks (Contractor)"
                            component={CustomCheckbox}
                        />
                        <Field
                            name="accountTypeGroup"
                            type="radio"
                            value={TRUCKER_ID}
                            label="I provide Trucks (Trucker)"
                            component={CustomCheckbox}
                        />
                        <Field
                            name="accountTypeGroup"
                            type="radio"
                            onChange={setCompanyValue}
                            value={INSPECTOR_ID}
                            label="Inspector"
                            component={CustomCheckbox}
                        />
                    </ButtonsGroup>
                    <br />
                    <Field name="firstStepError" errorAlign="center" component={HiddenInput} />
                </div>
            );
        case 1:
            return (
                <Grid container justify={"center"} className={localClasses.columnContainer}>
                    <br />
                    {renderPricePlans()}
                    <Grid item xs={6} className={clsx(localClasses.fullWidthItem, localClasses.formStyles)}>
                        <Field
                            name="companyName"
                            validate={[Validation.required, Validation.noSpecialSymbols]}
                            type="text"
                            disabled={accountTypeGroup === INSPECTOR_ID}
                            label="Company Name"
                            component={TextInputComponent}
                        />
                        <br />
                        <br />
                        <Field
                            name={MEASUREMENT_SYSTEM_NAME}
                            placeholder={MEASUREMENT_SYSTEM_PLACEHOLDER}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            validate={[Validation.required]}
                            loadOptions={() => loadStaticOptions(MEASUREMENT_SYSTEM)}
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                        {accountTypeGroup === TRUCKER_ID && (
                            <CompanyAddressForm setStateValue={setStateValue} accountType={accountType} />
                        )}
                    </Grid>
                </Grid>
            );
        case 2:
            return (
                <div>
                    <div className="terms-and-conditions">
                        <div className="terms-wrap">
                            <iframe
                                title="Terms and Conditions"
                                src={GLOBAL_ENDPOINTS.TERMS_AND_CONDITIONS()}
                                frameBorder="0"
                                height={300}
                                className="iframe --full-width"
                            />
                        </div>
                        <br />
                        <br />
                        <Field
                            name="acceptTermsAndConditions"
                            type="checkbox"
                            label="I accept these Terms and Conditions."
                            component={CustomCheckbox}
                        />
                        <Field name="thirdStepError" errorAlign="center" component={HiddenInput} />
                    </div>
                </div>
            );
        case 3:
            return (
                <div>
                    <br />
                    <Grid container justify={"center"} alignItems={"center"}>
                        <Grid item xs={6} className={clsx(localClasses.userInfoResponsive)}>
                            <Field
                                name="firstName"
                                validate={[Validation.required, Validation.noSpecialSymbols]}
                                type="text"
                                label="First Name"
                                component={TextInputComponent}
                            />
                            <br />
                            <br />
                            <Field
                                name="lastName"
                                validate={[Validation.required, Validation.noSpecialSymbols]}
                                type="text"
                                label="Last Name"
                                component={TextInputComponent}
                            />
                            <br />
                            <br />
                            <EmailField isRequired={true} />
                            <br />
                            <br />
                            <PhoneField isRequired={true} />
                            <br />
                            <Field
                                name="username"
                                validate={[Validation.required, Validation.minLength4, Validation.maxLength64]}
                                type="text"
                                label="Username"
                                component={TextInputComponent}
                            />
                            <br />
                            <br />
                            <PasswordField />
                            <br />
                            <br />
                            <Field
                                name="passwordConfirm"
                                validate={[Validation.required, Validation.password]}
                                type="password"
                                label="Password Again"
                                component={TextInputComponent}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        default:
            return "Unknown stepIndex";
    }
}

const SingUp = (props) => {
    const {
        handleSubmit,
        formValues: { accountTypeGroup, username },
        history,
        dispatch,
        form,
        formValuesAll,
        enqueueSnackbar,
    } = props;
    const signUpByInvite = history.location.pathname === ROUTE_SING_UP_INTERNAL;
    const classes = LOGIN_STYLES();
    const localClasses = useStyles();
    const location = useLocation();
    const [localState, setLocalState] = useState({
        isLoading: false,
        activeStep: signUpByInvite ? 1 : 0,
    });
    const [succusesCreate, setSuccusesCreate] = useState(false);
    const [errorMessage, setError] = useState();
    const [initialData, setFormInitialData] = useState();
    const { isLoading, activeStep } = localState;
    const steps = getSteps();
    const lastStep = activeStep === steps.length - 1;
    const accountType = +new URLSearchParams(location.search).get("accountType");

    useEffect(() => {
        setPayloadMeasurementValue();
        signUpByInvite && dispatch(change(form, "accountTypeGroup", TRUCKER_ID));
        setFormInitialData(parseQuery(history.location.search));
        if (!window.location.href.includes("localhost")) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, []);

    useEffect(() => {
        if (initialData) {
            dispatch(change(form, "companyName", initialData.name));
            dispatch(change(form, "email", initialData.email));
            dispatch(change(form, "phone", initialData.phone));
        }
    }, [initialData]);

    const returnCompanyAddress = (values) => {
        const address = {};
        if (values.companyAddress) address.companyAddress = values.companyAddress;
        if (values.streetAddress) address.streetAddress = values.streetAddress;
        if (values.secondaryAddress) address.secondaryAddress = values.secondaryAddress;
        if (values.zipCode) address.zipCode = values.zipCode;
        if (values.state) address.state = values.state.label;
        if (values.city) address.city = values.city;
        address.companyAddress = fullCompanyAddress(values);

        return { ...address };
    };

    const handleNext = (values) => {
        if (!lastStep) {
            setLocalState({ ...localState, activeStep: activeStep + 1 });
        } else {
            let dataForSave = {
                password: values.password,
                acceptTermsAndConditions: values.acceptTermsAndConditions,
                role:
                    values.accountTypeGroup === CONTRACTOR_ID
                        ? USER_ROLE_CUSTOMER
                        : values.accountTypeGroup === TRUCKER_ID
                        ? USER_ROLE_TRUCKER
                        : USER_ROLE_INSPECTOR,
                companyName: values.accountTypeGroup === INSPECTOR_ID ? undefined : values.companyName || undefined,
                companyAddress:
                    values.accountTypeGroup === INSPECTOR_ID
                        ? undefined
                        : values.companyAddress
                        ? values.companyAddress
                        : undefined,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: preparePhone(values.phone),
                username: values.username,
                inviterId: initialData ? initialData.inviterId : undefined,
                measurementSystem: +values.measurementSystem.value,
                ...returnCompanyAddress(values),
            };

            if (accountType) {
                dataForSave.accountType = accountType;
            }

            setError(null);
            setLocalState({ ...localState, isLoading: true, succusesCreate: false });

            if (values.accountTypeGroup === TRUCKER_ID) {
                dataForSave = {
                    ...dataForSave,
                    paymentName: values.paymentName,
                    paymentEmail: values.paymentEmail,
                    paymentPhone: preparePhone(values.paymentPhone),
                };
            }

            userSignUp(dataForSave)
                .then((data) => {
                    setLocalState({ ...localState, isLoading: false });
                    setSuccusesCreate(true);
                    localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY);
                })
                .catch((error) => {
                    setError(PROCESS_SERVER_ERROR(error));
                    setLocalState({
                        ...localState,
                        isLoading: false,
                    });
                });
        }
    };

    const handleBack = () => {
        setLocalState({ ...localState, activeStep: activeStep - 1 });
    };

    const handleLoad = (loading) => setLocalState({ ...localState, isLoading: loading });

    const handleError = (error) => {
        setError(PROCESS_SERVER_ERROR(error));
        setLocalState({
            ...localState,
            isLoading: false,
        });
    };

    const isStripeAccount = accountType === STRIPE_BASIC || accountType === STRIPE_PRO;

    const renderPricePlans = () =>
        isStripeAccount && <PricePlans accountType={accountType} handleError={handleError} handleLoad={handleLoad} />;

    const returnToLogin = () => {
        history.push(ROUTE_LOGIN, { username });
    };

    const setCompanyValue = (value) => {
        dispatch(change(form, "companyName", "DOT"));
    };

    const setStateValue = (value) => {
        dispatch(change(form, "state", { label: value.abbreviation, value: value.value }));
    };

    const setPayloadMeasurementValue = () => {
        dispatch(change(form, MEASUREMENT_SYSTEM_NAME, { value: IMPERIAL, label: MEASUREMENT_SYSTEM[IMPERIAL] }));
    };

    const returnPlanLabel = () => {
        if (accountType === STRIPE_BASIC) return "Basic";
        if (accountType === STRIPE_PRO) return "Pro";

        return ACCOUNT_TYPES[accountType];
    };

    const isSupportError =
        Array.isArray(errorMessage) && errorMessage.length && errorMessage[0].includes(TRUCKIT_SUPPORT_EMAIL);

    const generateBody = () => {
        const preparedValues = {};
        Object.keys(formValuesAll).forEach((key) => {
            if (key !== "password" && key !== "passwordConfirm") {
                preparedValues[key] = formValuesAll[key];
            }
        });

        return encodeURIComponent(JSON.stringify(preparedValues, null, " "));
    };

    const generateError = () => {
        if (isSupportError) {
            const message = errorMessage[0].split(" ");
            const result = message.map((item) => {
                if (item === `"${TRUCKIT_SUPPORT_EMAIL}"`) {
                    return (
                        <MailTo
                            email={TRUCKIT_SUPPORT_EMAIL}
                            subject={"TruckIT Sign Up Request"}
                            body={generateBody()}
                            label={TRUCKIT_SUPPORT_EMAIL}
                        />
                    );
                }

                return item + " ";
            });

            enqueueSnackbar(result, { variant: "error", autoHideDuration: 20000, preventDuplicate: false });
            setError();
        }
    };

    useEffect(() => {
        generateError();
    }, [isSupportError]);

    return (
        <div
            className={clsx(
                classes.loginStyles,
                localClasses.createUserForm,
                localClasses.responsiveStyles,
                isLoading && LOADER_WHITE_OVERLAY,
            )}
        >
            <div className={classes.uiIconLogo} />
            <br />
            <br />
            {!!accountType && (
                <span className={classes.title}>
                    Selected Plan <span className={classes.bold}>{returnPlanLabel()}</span>
                </span>
            )}
            <div className={localClasses.formContainer}>
                <form noValidate={true} onSubmit={handleSubmit(handleNext)}>
                    {!succusesCreate ? (
                        <React.Fragment>
                            <br />
                            <div className={classes.loginStyles}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div className="--full-width content-container">
                                    <Typography>
                                        {getStepContent(activeStep, accountType, {
                                            accountTypeGroup,
                                            setCompanyValue,
                                            setStateValue,
                                            renderPricePlans,
                                            localClasses,
                                            loadStaticOptions,
                                        })}
                                    </Typography>
                                    <div>
                                        <br />
                                        <ButtonsGroup>
                                            <PrimaryButton
                                                size={"small"}
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                            >
                                                Back
                                            </PrimaryButton>
                                            <PrimaryButton size={"small"} type="submit">
                                                {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                            </PrimaryButton>
                                        </ButtonsGroup>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="--text-center">
                                <SimpleLink to={ROUTE_LOGIN}>Return to Login page</SimpleLink>
                            </div>
                            <br />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box className="--text-center">
                                <h1>Congratulations!</h1>
                                <br />
                                Please check your e-mail to finish registration.
                            </Box>
                            <br />
                            <ButtonsGroup className="buttons-set">
                                <PrimaryButton type="button" onClick={returnToLogin} size={"large"}>
                                    Return to Login page
                                </PrimaryButton>
                            </ButtonsGroup>
                        </React.Fragment>
                    )}
                </form>
            </div>
            {!isSupportError && !!errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setError(null) }} />
            )}
            {isLoading && <Loader />}
        </div>
    );
};

SingUp.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValuesAll: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export const SIGN_UP_FORM_ID = "createUser";

export default withRouter(
    reduxCompose(
        reduxForm({
            form: SIGN_UP_FORM_ID,
            initialValues: {},
            validate,
        }),
        withSnackbar,
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "accountTypeGroup", "id", "username"),
                account: state.account,
                formValuesAll: getFormValues(props.form)(state),
            };
        }),
    )(SingUp),
);
