import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const tabsStyles = makeStyles((theme) => ({
    tabStyles: {
        "& button": {
            minWidth: 0,
            border: `1px solid`,
            borderColor: `transparent`,
            fontSize: theme.typography.fontSize,
            padding: "0 9px",
            color: theme.palette.text.primary,

            "&:only-child": {
                maxWidth: "50%",
            },

            "& .label-wrapper": {
                display: "flex",
                flexDirection: "row",
                paddingBottom: 5,
                borderBottom: `4px solid transparent`,
            },

            "&.Mui-selected": {
                borderColor: theme.palette.primary.main,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.primary.superLight,
                fontSize: theme.typography.fontSize,

                "& .label-wrapper": {
                    borderColor: theme.palette.primary.main,
                },
            },
        },

        "& .custom-label-icon .label-wrapper": {
            "& svg": {
                marginBottom: "2px",
                marginRight: 15,
            },
        },
    },
    indicator: {
        backgroundColor: "transparent",
    },
    labelIcon: {
        ".MuiTab-wrapped svg": {
            marginBottom: "4px",
        },
    },
    wrapper: {
        "& svg": {
            width: theme.icon.width,
            height: theme.icon.height,
        },
    },
    textColorSecondary: {
        color: theme.palette.secondary.dark,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    value: PropTypes.number,
    index: PropTypes.number,
};

export const AppTabs = (props) => {
    const { tabsControls, tabsContent, value, handleChange, itemStyles, containerStyles } = props;
    const classes = tabsStyles();

    return (
        <div>
            <Tabs
                value={value}
                className={classes.tabStyles}
                component="div"
                style={containerStyles}
                classes={{
                    indicator: classes.indicator,
                }}
                onChange={handleChange}
                textColor="primary"
                variant="fullWidth"
                aria-label="job order tabs"
            >
                {tabsControls.map((control, index) => (
                    <Tab
                        disabled={control.disabled}
                        label={control.title}
                        key={index}
                        icon={control.icon}
                        style={itemStyles}
                        classes={{
                            wrapper: clsx(classes.wrapper, "label-wrapper"),
                            textColorSecondary: classes.textColorSecondary,
                            labelIcon: "custom-label-icon",
                        }}
                    />
                ))}
            </Tabs>
            {tabsContent.map((tab, index) => {
                return (
                    <TabPanel value={value} index={index} key={index}>
                        {tab.content}
                    </TabPanel>
                );
            })}
        </div>
    );
};

AppTabs.propTypes = {
    tabsControls: PropTypes.array.isRequired,
    tabsContent: PropTypes.array.isRequired,
    firstTab: PropTypes.number.isRequired,
    itemStyles: PropTypes.any,
    containerStyles: PropTypes.any,
    value: PropTypes.number,
    handleChange: PropTypes.func,
};

AppTabs.defaultProps = {
    component: "div",
    firstTab: 0,
};
