import React, { useState } from "react";
import ActionMenuItem from "../core/actionMenuItem";
import MoreActionsMenu from "../core/moreActions";
import PropTypes from "prop-types";
import AddBoxIcon from "@material-ui/icons/AddBox";
import _ from "lodash";
import AppModal from "../core/modal";
import DeviceDetails from "./deviceDetails";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LinkTruckToIotDeviceDialog from "./linkTruckToIotDeviceDialog";
import { linkIotDevice, unlinkIotDevice } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import { IS_ADMIN_USER, TRUCKER_TYPE_DISPATCH } from "../../constants/maps";
import { useSelector } from "react-redux";

const DeviceActionMenu = (props) => {
    const {
        itemForEdit,
        itemForEdit: { linkedTruck, deviceId },
        loadDevises,
    } = props;
    const account = useSelector((state) => state.account);

    const [openDetailsModal, showDetailModal] = useState(false);
    const [openLinkTruckToIotDialog, setOpenLinkTruckToIotDialog] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const isAdmin = IS_ADMIN_USER(account.company.id);

    const handleItemClick = (callback) => {
        itemForEdit ? callback(itemForEdit) : callback();
    };

    const toggleDialog = () => {
        showDetailModal(true);
    };

    const toggleTruckLinkDialog = () => {
        setOpenLinkTruckToIotDialog(true);
    };

    const handleUnlinkTruck = () => {
        const data = {
            deviceId,
        };
        unlinkIotDevice(data)
            .then(() => {
                setOpenLinkTruckToIotDialog(false);
                setIsHandledSuccessfully(true);
                loadDevises();
            })
            .catch((error) => {
                setErrorMessage([PROCESS_SERVER_ERROR(error)]);
            });
    };

    const handleLinkTruckToIotDevice = (truckId, deviceId) => {
        const data = {
            truckId,
            deviceId,
        };

        return linkIotDevice(data).then(() => {
            loadDevises();
        });
    };

    const menuItems = [
        {
            id: "details",
            title: "Details",
            renderIcon: () => <HelpOutlineIcon />,
            handleClick: toggleDialog,
            visible: true,
        },
        {
            id: "linkTruck",
            title: linkedTruck ? "Unlink Truck" : "Link Truck",
            renderIcon: () => <AddBoxIcon />,
            handleClick: linkedTruck ? handleUnlinkTruck : toggleTruckLinkDialog,
            visible:
                (isAdmin || account.truckerType === TRUCKER_TYPE_DISPATCH) && account.companyProfile.usesIotDevices,
        },
    ];

    const visibleItems = _.cloneDeep(menuItems).filter((i) => i.visible);

    return (
        <React.Fragment>
            <MoreActionsMenu>
                {visibleItems.map((menuItem) => {
                    const { id, title, renderIcon, handleClick } = menuItem;

                    return (
                        <ActionMenuItem
                            key={id}
                            title={title}
                            renderIcon={renderIcon}
                            handleClick={() => handleItemClick(handleClick)}
                        />
                    );
                })}
            </MoreActionsMenu>
            <AppModal isOpen={openDetailsModal} closeModal={() => showDetailModal(false)} modalStyles={{ width: 1280 }}>
                <DeviceDetails device={itemForEdit} />
            </AppModal>
            <LinkTruckToIotDeviceDialog
                openLinkTruckToIotDialog={openLinkTruckToIotDialog}
                handleClose={() => setOpenLinkTruckToIotDialog(false)}
                onSubmit={handleLinkTruckToIotDevice}
                iotDeviceForEdit={itemForEdit}
                truckLinkDialogOpen={setOpenLinkTruckToIotDialog}
            />
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
        </React.Fragment>
    );
};

DeviceActionMenu.propTypes = {
    itemForEdit: PropTypes.object.isRequired,
    loadDevises: PropTypes.func.isRequired,
};

export default DeviceActionMenu;
