export const COMPANIES_LOADING = "companiesLoading";
export const COMPANIES_LOADED = "companiesLoaded";
export const COMPANIES_FAILED = "companiesFailed";
export const COMPANIES_RESET = "companiesReset";
export const SET_COMPANY_FOR_EDIT = "setCompanyForEdit";
export const COMPANY_DIALOG_TOGGLE = "companyDialogToggle";
export const COMPANY_LOADING = "companyLoading";
export const COMPANY_LOADED = "companyLoaded";
export const COMPANY_FAILED = "companyFailed";
export const COMPANY_CLEARED = "companyCleared";
export const UPDATE_ACTIVE_COMPANIES_ORDER = "updateActiveCompaniesOrder";
export const UPDATE_COMPANY_FOR_MANAGING_HAULERS = "updateCompanyForManagingHaulers";
export const UPDATE_COMPANY_FOR_UPDATING_ADMINS = "updateCompanyForUpdatingAdmins";
export const UPDATE_HAS_SUB_COMPANIES = "updateHasSubCompanies";
