import React from "react";
import AppModal from "../core/modal";
import { useDispatch, useSelector } from "react-redux";
import { updateRedirectJobId } from "../../actions/jobOrders";
import { selectRedirectJobId } from "../../selectors/jobOrders";
import { ButtonsGroup, PrimaryButton, SimpleLink } from "../core";

const RedirectJobModal = ({ setActive }) => {
    const dispatch = useDispatch();
    const redirectJobId = useSelector(selectRedirectJobId);

    const onCloseModal = () => {
        dispatch(updateRedirectJobId(null));
    };

    const onLinkClick = () => {
        dispatch(updateRedirectJobId(null));
        setActive({ id: redirectJobId });
    };

    return (
        <AppModal isOpen={redirectJobId} modalStyles={{ width: 800 }} closeModal={onCloseModal}>
            <h1 className="--text-center">Linked jobs have been created.</h1>
            <h1 className="--text-center">
                You can navigate to the draft job{" "}
                <SimpleLink className="--text-h1-size" onClick={onLinkClick}>
                    #{redirectJobId}
                </SimpleLink>{" "}
                to edit it, or stay on the current page by closing this window.
            </h1>
            <br />
            <br />
            <ButtonsGroup>
                <PrimaryButton onClick={onCloseModal}>Ok</PrimaryButton>
            </ButtonsGroup>
        </AppModal>
    );
};

export default RedirectJobModal;
