import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { fetchSiteStatsDailyLoads } from "../../dataServers/sites";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { SITE_STATS_DAILY_TICKET_COUNT_LABEL, SITE_STATS_DAILY_TICKET_COUNT } from "./constants/strings";
import clsx from "clsx";
import Loader from "../core/loader";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    dailyLoadsLabel: {
        "& h2": {
            display: "inline",
        },
        "& span": {
            display: "inline-block",
            marginLeft: "5px",
            fontSize: "1.3em",
        },
    },
}));

const SiteDailyLoads = ({ siteId, errorHandler }) => {
    const classes = styles();
    const [dailyLoadsData, setDailyLoadsData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        if (siteId) {
            setIsLoading(true);
            fetchSiteStatsDailyLoads(siteId)
                .then(({ data }) => {
                    setDailyLoadsData(data.dailyLoads);
                })
                .catch((error) => {
                    errorHandler([PROCESS_SERVER_ERROR(error)]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [siteId]);

    return (
        <Box className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <Box marginLeft="5px" marginBottom="15px" display="flex" flexDirection="row" alignItems="flex-end">
                <Box className={classes.dailyLoadsLabel}>
                    <Typography variant="h2">{SITE_STATS_DAILY_TICKET_COUNT_LABEL}</Typography>
                    <Typography variant="span">{SITE_STATS_DAILY_TICKET_COUNT(dailyLoadsData)}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SiteDailyLoads;

