/* global google */
import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import { compose } from "redux";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";

import pickUpPin from "../../styles/images/soft-green-pin.svg";
import dropOffPin from "../../styles/images/pale-red-pin.svg";
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_TIME_FORMAT,
    GOOGLE_MAPS_SITE_ICON_SIZE,
    GOOGLE_MAPS_TRUCK_ICON_SIZE,
    GOOGLE_MAPS_URL,
} from "../../constants/global";
import { loadCurrentCoordinates } from "../../helpers/location";
import CustomMarker from "../global/maps/customMarker";
import { getTrucksForMap } from "../../helpers/global";
import DeviceMapIcon from "../devises/deviceMapIcon";
import { useDispatch } from "react-redux";
import { setLivemapTruck } from "../../actions/livemapActions";

let _map;

const BaseLiveMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { liveMapTrucks, coordinates, account } = props;
    const dispatch = useDispatch();
    const [defaultCoordinates, setDefaultCoordinates] = useState(null);
    const [activeTruckId, setActiveTruckId] = useState(null);

    useEffect(() => {
        const _bounds = new google.maps.LatLngBounds();

        const allTruckCoordinates = [];

        liveMapTrucks.forEach((liveMapTruck) => {
            const { truckCoordinates, pickupSite, dropoffSite } = liveMapTruck;

            !_.isEmpty(truckCoordinates) && allTruckCoordinates.push(truckCoordinates);
            !_.isEmpty(pickupSite) && allTruckCoordinates.push(pickupSite);
            !_.isEmpty(dropoffSite) && allTruckCoordinates.push(dropoffSite);
        });

        allTruckCoordinates.forEach((coordinates) => {
            _bounds.extend(new google.maps.LatLng(coordinates.latitude, coordinates.longitude));
        });

        if (_map) {
            _map.context[MAP].setCenter(_bounds.getCenter());
            _map.context[MAP].fitBounds(_bounds);
        }

        loadCurrentCoordinates().then((coordinates) => {
            setDefaultCoordinates({
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            });
        });
    }, [_map]);

    useEffect(() => {
        if (coordinates) {
            setDefaultCoordinates(coordinates);
        }
    }, [coordinates]);

    const handleOpenMarkerTip = (id) => {
        setActiveTruckId(id);
    };

    const handleCloseMarkerTip = (id) => {
        if (activeTruckId === id) {
            setActiveTruckId(null);
        }
    };

    const focusTruck = (selectedLivemapTruck) => {
        if (!_.isEmpty(selectedLivemapTruck)) {
            dispatch(setLivemapTruck(selectedLivemapTruck));
        }
    };

    return (
        <div>
            {defaultCoordinates && (
                <GoogleMap ref={(map) => (_map = map)} center={defaultCoordinates} defaultZoom={11}>
                    <div>
                        {liveMapTrucks.map((liveMapTruck, index) => {
                            const {
                                status,
                                dropoffSite,
                                truckCoordinates,
                                pickupSite,
                                heading,
                                currentJobOrder,
                                truck,
                            } = liveMapTruck;
                            const { paverTrackers } = currentJobOrder || {};
                            const lastUpdated =
                                truckCoordinates &&
                                moment(truckCoordinates.lastUpdateTimestamp)
                                    .tz(account.timezone)
                                    .format(`${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`);

                            return (
                                <React.Fragment key={index}>
                                    {!_.isEmpty(truckCoordinates) && (
                                        <CustomMarker
                                            id={truck.id}
                                            position={
                                                new google.maps.LatLng(
                                                    truckCoordinates.latitude,
                                                    truckCoordinates.longitude,
                                                )
                                            }
                                            icon={{
                                                scaledSize: new google.maps.Size(
                                                    GOOGLE_MAPS_TRUCK_ICON_SIZE,
                                                    GOOGLE_MAPS_TRUCK_ICON_SIZE,
                                                ),
                                                url: getTrucksForMap(status, heading),
                                                zIndex: 3,
                                            }}
                                            onClick={() => focusTruck(liveMapTruck)}
                                            onOpenTip={handleOpenMarkerTip}
                                            onCloseTip={handleCloseMarkerTip}
                                        >
                                            <div>
                                                <div>Truck: {truck.deviceName}</div>
                                                {truck.driver && (
                                                    <React.Fragment>
                                                        <div>
                                                            Driver Name:{` `}
                                                            {truck.driver.firstName}
                                                            {` `}
                                                            {truck.driver.lastName}
                                                        </div>
                                                        <div>Driver Phone: {truck.driver.phone}</div>
                                                    </React.Fragment>
                                                )}
                                                {lastUpdated && <div>Last Updated: {lastUpdated}</div>}
                                                {currentJobOrder && <div>Job: #{currentJobOrder.id}</div>}
                                            </div>
                                        </CustomMarker>
                                    )}
                                    {!_.isEmpty(pickupSite) && (
                                        <CustomMarker
                                            id={truck.id}
                                            position={new google.maps.LatLng(pickupSite.latitude, pickupSite.longitude)}
                                            icon={{
                                                url: pickUpPin,
                                                scaledSize: new google.maps.Size(
                                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                                ),
                                            }}
                                            opacity={activeTruckId === truck.id ? 1 : 0}
                                        />
                                    )}
                                    {!_.isEmpty(dropoffSite) && (
                                        <CustomMarker
                                            id={truck.id}
                                            position={
                                                new google.maps.LatLng(dropoffSite.latitude, dropoffSite.longitude)
                                            }
                                            icon={{
                                                url: dropOffPin,
                                                scaledSize: new google.maps.Size(
                                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                                    GOOGLE_MAPS_SITE_ICON_SIZE,
                                                ),
                                            }}
                                            opacity={activeTruckId === truck.id ? 1 : 0}
                                        />
                                    )}
                                    {!_.isEmpty(paverTrackers) &&
                                        paverTrackers.map((device) => {
                                            const { deviceId, coordinates, geoZone } = device;

                                            return (
                                                <DeviceMapIcon
                                                    id={deviceId}
                                                    key={deviceId}
                                                    coordinates={
                                                        coordinates
                                                            ? {
                                                                  lat: coordinates.latitude,
                                                                  lng: coordinates.longitude,
                                                              }
                                                            : undefined
                                                    }
                                                    geoZone={geoZone}
                                                />
                                            );
                                        })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </GoogleMap>
            )}
        </div>
    );
});

const LiveMap = (props) => (
    <BaseLiveMap
        googleMapURL={GOOGLE_MAPS_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: props.height }} />}
        mapElement={<div style={{ height: `100%` }} />}
        liveMapTrucks={props.liveMapTrucks}
        account={props.account}
        coordinates={props.coordinates}
    />
);

LiveMap.propTypes = {
    coordinates: PropTypes.object,
    account: PropTypes.object.isRequired,
    height: PropTypes.string.isRequired,
    liveMapTrucks: PropTypes.array.isRequired,
};

LiveMap.defaultProps = {
    height: "40vh",
    liveMapTrucks: [],
    account: {},
};

export default LiveMap;
