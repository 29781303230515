import React, { useState } from "react";
import AppModal from "../../core/modal";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import ErrorNotification from "../../core/notification";
import SuccessNotification from "../../core/successNotification";
import Loader from "../../core/loader";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import PhoneField from "../../global/phoneField";
import { editUser } from "../../../dataServers/teams";
import { connect, useDispatch } from "react-redux";
import { ENTER_PHONE_IS_REQUIRED, PHONE_DIALOG_TITLE, PHONE_UPDATED } from "../../../constants/strings";
import { preparePhone, updateAccountData } from "../../../helpers/global";
import { emitUserLoggedOutEvent, updateUserPhoneNumber } from "../../../actions/globalActions";

const useStyles = makeStyles(() => ({
    phoneFieldWrapper: {
        padding: "0 100px",
    },
}));

const PhoneDialog = ({ phoneDialogOpen, handleSubmit, handleClose, account }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const classes = useStyles();

    const onFormSubmit = (values) => {
        setIsLoading(true);

        return editUser(
            {
                phone: preparePhone(values.phone),
            },
            account.id,
        )
            .then(() => {
                const updatedPhone = values.phone;

                handleClose();
                setSuccessMessage([PHONE_UPDATED]);
                updateAccountData(updatedPhone, null, "phone");
                dispatch(updateUserPhoneNumber(updatedPhone));
            })
            .catch((e) => {
                setErrorMessage([PROCESS_SERVER_ERROR(e)]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const closeModal = () => {
        if (!isLoading) {
            emitUserLoggedOutEvent();
            setErrorMessage([ENTER_PHONE_IS_REQUIRED]);
            handleClose && handleClose();
        }
    };

    return (
        <>
            <AppModal
                isOpen={phoneDialogOpen}
                form={PHONE_DIALOG_FORM}
                closeModal={closeModal}
                modalStyles={{ width: 800 }}
            >
                <form onSubmit={handleSubmit(onFormSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                    {isLoading && <Loader />}
                    <h2 className="title">{PHONE_DIALOG_TITLE}</h2>
                    <br />
                    <div className={clsx(classes.phoneFieldWrapper)}>
                        <PhoneField isRequired={true} />
                    </div>
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton type="button" onClick={closeModal}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={false}>
                            Approve
                        </PrimaryButton>
                    </ButtonsGroup>
                </form>
            </AppModal>
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
            {successMessage && (
                <SuccessNotification message={successMessage} config={{ onClose: () => setSuccessMessage(null) }} />
            )}
        </>
    );
};

PhoneDialog.propTypes = {
    phoneDialogOpen: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
};

export const PHONE_DIALOG_FORM = "phoneDialogForm";

export default compose(
    reduxForm({
        form: PHONE_DIALOG_FORM,
    }),
    connect((state, props) => {
        return {
            account: state.account,
        };
    }),
)(PhoneDialog);
