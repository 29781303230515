import React from "react";
import clsx from "clsx";
import _ from "lodash";
import { change, Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import pdfjsLib from "pdfjs-dist/build/pdf";

import { makeStyles, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Popover from "@material-ui/core/Popover";

import ErrorNotification from "../core/notification";
import { downloadFile } from "../../dataServers/index.js";
import { Divider } from "../core/divider";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import TextInputComponent from "../core/form/textInputComponent";
import { Validation } from "../../helpers/validation";
import IconButton from "@material-ui/core/IconButton";

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://npmcdn.com/pdfjs-dist@2.3.200/build/pdf.worker.js";

const maxPreviewSize = 500;
const maxPreviewWidth = 300;

const useStyles = makeStyles((theme) => ({
    draggableTextStyle: {
        padding: 40,
    },
    browseButton: {
        padding: "15px 0",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    fileInput: {
        "& .MuiOutlinedInput-inputAdornedStart": {
            display: "none",
        },
    },
    previewWrap: {
        minWidth: maxPreviewWidth,
        height: maxPreviewSize,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        zIndex: 1,
    },
    file: {
        flex: 1.5,
        padding: "5px 0",
        "& .file-item": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        "& .file-container": {
            maxWidth: "80%",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("1370")]: {
                width: "65%",
            },
            [theme.breakpoints.down("1100")]: {
                width: "45%",
            },
        },
        "& .file-name": {
            wordBreak: "break-all",
            marginLeft: 5,
        },
        "& .file-icon-container": {
            position: "relative",
            color: "#d8d8d8",
            cursor: "pointer",
        },
        "& .file-icon-hover": {
            "&:hover": {
                color: "#747373",
            },
        },
        "& .file-icon-label": {
            left: 3,
            position: "absolute",
            bottom: 15,
            lineHeight: 1.5,
            borderRadius: 3,
            color: "#fff",
            width: 25,
            textAlign: "center",
            fontSize: 9,
            textTransform: "uppercase",
            fontWeight: 900,
        },
    },
}));

const getColorForFile = (type) => {
    const colors = {
        pdf: "#ff2626",
        doc: "#22cef8",
        docx: "#22cef8",
        jpg: "#0035efe3",
        jpeg: "#0035efe3",
        ppt: "#ff2626",
        png: "#9100efe3",
        zip: "#0da30fe3",
        php: "#0b2d62e3",
        txt: "#09192ad4",
        xlsx: "#0bc500d4",
        xls: "#0bc500d4",
    };
    return colors[type] || "#09192ad4";
};

function FieldInput({ field, fields, index, file, onFileAdd, removeFile, removeField, getPreview }) {
    const styles = useStyles();

    const fileData = fields.get(index)?.value;
    const fileId = fields.get(index)?.fileId;
    const fieldOption = fields.get(index)?.option;

    const [option, setOption] = React.useState(fieldOption || "text");

    const isFile = option === "file";

    const handleChange = (event) => {
        if (event.target.value === "text" && option === "file") {
            removeFile(`${field}.value`);
            setOption(event.target.value);
        } else {
            setOption(event.target.value);
        }
    };

    const getFormat = (file) => {
        let fileExtension = file?.name?.split(".");

        return _.last(fileExtension) || "";
    };

    const loadFile = async () => {
        await downloadFile(fileId);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Field
                    type="text"
                    name={`${field}.name`}
                    label="Field name"
                    fieldNote="Additional field name"
                    validate={[Validation.required]}
                    component={TextInputComponent}
                    disabled={!!fileId}
                    needShowEndAdornment={true}
                    endAdornment={
                        <FormControl variant="outlined" style={{ position: "absolute", right: 20, border: "none" }}>
                            <NativeSelect
                                disabled={!!fileId}
                                value={option}
                                onChange={handleChange}
                                inputProps={{
                                    name: "option",
                                    id: "age-native-helper",
                                }}
                            >
                                <option value={"text"}>text</option>
                                <option value={"file"}>file</option>
                            </NativeSelect>
                        </FormControl>
                    }
                />
            </Grid>
            <Grid item xs={7}>
                <Field
                    type="text"
                    name={`${field}.value`}
                    label="Field value"
                    key={`${field}.value`}
                    fieldNote="Additional field value"
                    needShowEndAdornment={false}
                    validate={[Validation.required]}
                    readOnly={isFile}
                    className={isFile && styles.fileInput}
                    component={TextInputComponent}
                    startAdornment={
                        isFile && (
                            <>
                                <input
                                    type="file"
                                    id={`contained-button-file-${field}`}
                                    hidden
                                    name={`${field}.value`}
                                    onChange={(e) => {
                                        onFileAdd(e, `${field}.value`);
                                    }}
                                    accept="*"
                                />

                                <label htmlFor={`contained-button-file-${field}`}>
                                    <Button component="span" className={styles.browseButton}>
                                        browse
                                    </Button>
                                </label>

                                {fileData && (fileData instanceof File || fileData instanceof Object) && (
                                    <Box className={styles.file}>
                                        <Box className="file-item">
                                            <Box className="file-container">
                                                <Tooltip
                                                    title={"Preview"}
                                                    // disableHoverListener={isNotFormatForPreview}
                                                >
                                                    <>
                                                        <Box
                                                            className={clsx(
                                                                "file-icon-container",
                                                                // !isNotFormatForPreview && "file-icon-hover",
                                                            )}
                                                            onClick={(event) =>
                                                                file instanceof File && getPreview(file)
                                                            }
                                                        >
                                                            <span
                                                                className="file-icon-label"
                                                                style={{
                                                                    backgroundColor: getColorForFile(
                                                                        getFormat(fileData),
                                                                    ),
                                                                }}
                                                            >
                                                                {getFormat(fileData)}
                                                            </span>
                                                            <InsertDriveFileOutlinedIcon fontSize="large" />
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                            }}
                                                            onClick={loadFile}
                                                        >
                                                            <a>{fileData.name}</a>
                                                        </Box>
                                                    </>
                                                </Tooltip>
                                            </Box>

                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <IconButton
                                                    style={{
                                                        position: "absolute",
                                                        right: 0,
                                                    }}
                                                >
                                                    <DeleteIcon
                                                        className={styles.iconStyle}
                                                        onClick={() => {
                                                            removeFile(`${field}.value`);
                                                        }}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )
                    }
                />
            </Grid>

            <Grid item xs={1} style={{ paddingTop: 16 }}>
                <CloseIcon
                    color={"action"}
                    fontSize={"default"}
                    cursor={"pointer"}
                    onClick={() => removeField(index, field)}
                />
            </Grid>
        </Grid>
    );
}

function renderAdditionalFields({
    fields,
    isCreateForm,
    fieldsToRemove,
    filesToRemove,
    form,
    dispatch,
    styles,
    values,
    handleSubmit,
    restProps,
    getPreview,
}) {
    const removeField = (index, field) => {
        fields.remove(index);
        const fileData = fields.get(index);

        if (_.isObject(fileData?.value) && fileData.fileId) {
            fileData.fileId && dispatch(change(form, `filesToRemove`, [...(filesToRemove || []), fileData.fileId]));
        } else {
            dispatch(change(form, "fieldsToRemove", [...(fieldsToRemove || []), fileData.name]));
        }
    };

    const addField = () => {
        fields.push({ fieldId: _.uniqueId() });
    };

    const onFileAdd = (e, fieldName) => {
        const file = e.target.files[0] || e.target.files;

        if (!file || (file && !file.size)) {
            return false;
        }

        dispatch(change(form, fieldName, file));
    };

    const removeFile = (fieldName) => {
        dispatch(change(form, fieldName, ""));
    };

    return (
        <div>
            {fields.map((field, index) => {
                const fieldId = fields.get(index)?.fieldId;

                return (
                    <Grid item key={fieldId} value={index}>
                        <FieldInput
                            onFileAdd={onFileAdd}
                            removeFile={removeFile}
                            fields={fields}
                            index={index}
                            field={field}
                            file={field}
                            getPreview={getPreview}
                            removeField={() => removeField(index, field)}
                        />
                        <br />
                    </Grid>
                );
            })}

            <ButtonsGroup>
                <SecondaryButton size="small" style={{ flex: 1, height: 50 }} onClick={addField}>
                    Add Field
                </SecondaryButton>
                {!isCreateForm && (
                    <PrimaryButton style={{ flex: 1, height: 50 }} size="small" onClick={handleSubmit}>
                        Save
                    </PrimaryButton>
                )}
            </ButtonsGroup>
        </div>
    );
}

function CustomFields({ handleSubmit, isCreateForm, error, form, dispatch, ...restProps }) {
    const styles = useStyles();

    const [imgAnchorEl, setImgAnchorEl] = React.useState();

    const values = useSelector((state) => getFormValues(form)(state));

    const showImg = (e, img) => {
        if (!img) {
            setImgAnchorEl(null);

            return false;
        }

        setImgAnchorEl(e.currentTarget);
    };

    const calculateSize = (width, height) => {
        const ratio = maxPreviewSize / maxPreviewSize;
        const maxh = maxPreviewSize;
        const maxw = maxPreviewSize;

        if (height / width > ratio) {
            // height is the problem
            if (height > maxh) {
                width = Math.round(width * (maxh / height));
                height = maxh;
            }
        } else {
            // width is the problem
            if (width > maxh) {
                height = Math.round(height * (maxw / width));
                width = maxw;
            }
        }

        return {
            width,
            height,
        };
    };

    const getPdfPreview = (upload) => {
        var loadingTask = pdfjsLib.getDocument({
            data: atob(upload.target.result.slice("data:application/pdf;base64,".length)),
        });

        return loadingTask.promise.then((pdf) => {
            // Fetch the first page
            return pdf.getPage(1).then((page) => {
                const scale = 0.72;
                const viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions
                const canvas = document.getElementById("pdf-preview");
                const context = canvas.getContext("2d");
                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                canvas.height = calculateSize(viewport.width, viewport.height).height;
                canvas.width = calculateSize(viewport.width, viewport.height).width;

                // should return Promise
                return page.render(renderContext);
            });
        });
    };

    const getPreview = (file) => {
        const reader = new FileReader();

        if (!file) {
            return false;
        }

        reader.onload = (upload) => {
            if (file.type === "application/pdf") {
                getPdfPreview(upload).then(() => {
                    const pdfPreview = document.querySelector("#pdf-preview");
                    const data = pdfPreview ? pdfPreview.toDataURL("image/jpeg", 0.72) : "";

                    setImgAnchorEl({
                        name: file.name,
                        fileLink: data,
                    });
                });
            } else if (file.type.startsWith("image/")) {
                const URL = window.URL;
                const img = new Image();

                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    const canvas = document.getElementById("img-preview");

                    const ctx = canvas.getContext("2d");
                    const secondCtx = canvas.getContext("2d");

                    canvas.width = calculateSize(img.naturalWidth, img.naturalHeight).width;
                    canvas.height = calculateSize(img.naturalWidth, img.naturalHeight).height;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // get full image base64
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    secondCtx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
                    const fullImage = canvas ? canvas.toDataURL("image/jpeg") : "";

                    setImgAnchorEl({
                        name: file.name,
                        fileLink: fullImage,
                    });
                };
            }
        };
        reader.readAsDataURL(file);
    };

    return (
        <div style={{ flex: 1 }}>
            {!isCreateForm && <Divider marginTop={0} />}
            <FieldArray
                name="additionalFields"
                form={form}
                values={values}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
                rerenderOnEveryChange
                styles={styles}
                filesToRemove={values?.filesToRemove}
                fieldsToRemove={values?.fieldsToRemove}
                getPreview={getPreview}
                isCreateForm={isCreateForm}
                component={renderAdditionalFields}
            />

            <ErrorNotification message={error?.message} />
            {!!imgAnchorEl && (
                <Popover
                    id="tikect-pic-popover"
                    disableRestoreFocus={true}
                    open={!!imgAnchorEl}
                    anchorEl={imgAnchorEl}
                    onClose={() => showImg()}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                >
                    <Box style={{ position: "relative" }}>
                        <Box className={styles.previewWrap}>
                            <img src={imgAnchorEl.fileLink} alt="" height={maxPreviewSize} />
                        </Box>
                    </Box>
                </Popover>
            )}
        </div>
    );
}

const onSubmit = (values, dispatch, props) => {
    if (props.submitHandler) {
        return dispatch(props.submitHandler(props.id, values));
    }
};

export default reduxForm({
    onSubmit,
})(CustomFields);
