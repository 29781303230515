import { LOCAL_STORAGE_FILTERS_ID } from "./api";
import { dispatchRoute, jobBoardRoute, ROUTE_JOBS } from "../routes/globalRoutes";
import { JO_DRAFT_ID, JO_REQUEST_ID, JO_TEMPLATED_ID } from "../constants/maps";
import { FILTERS_JOB_DRAFTS, FILTERS_JOB_REQUESTS } from "../components/global/searchAndFilters";

export const getJobOrderFiltersFromLocalStorage = (currentLocation, initialParams) => {
    let formFilters;
    const savedFilters = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FILTERS_ID));
    if (currentLocation.includes(jobBoardRoute)) {
        formFilters = savedFilters?.jobBoardFilters || {};
    } else if (currentLocation.includes(ROUTE_JOBS.MY_JOBS)) {
        formFilters = savedFilters?.jobOrdersFilters || {};
    } else if (currentLocation.includes(dispatchRoute)) {
        formFilters = savedFilters?.dispatchPageJobOrdersFilters || {};
        formFilters.endDate = formFilters.startDate;
    } else if (currentLocation.includes(ROUTE_JOBS.SMART_DISPATCH)) {
        formFilters = savedFilters?.jobListSmartDispatchFilters || {};
        formFilters.endDate = formFilters.startDate;
        formFilters.smartDispatch = true;
    }

    if (formFilters) {
        const {
            startDate,
            endDate,
            regions,
            status,
            templated,
            smartDispatch,
            [FILTERS_JOB_REQUESTS]: jobRequests,
            [FILTERS_JOB_DRAFTS]: draft,
        } = formFilters;
        if (startDate) {
            initialParams.startDate = startDate;
        }
        if (endDate) {
            initialParams.endDate = endDate;
        }
        if (regions) {
            const regionsIdList = [];
            for (const [key, value] of Object.entries(regions)) {
                if (value) {
                    regionsIdList.push(key);
                }
            }
            if (regionsIdList.length > 0) {
                initialParams.regions = regionsIdList.join(",");
            }
        }
        if (status) {
            const statusesIdList = [];
            for (const [key, value] of Object.entries(status)) {
                if (value) {
                    statusesIdList.push(key);
                }
            }
            if (statusesIdList.length > 0) {
                initialParams.status = statusesIdList.join(",");
            }
        }
        if (templated) {
            initialParams.templated = templated[JO_TEMPLATED_ID];
        }
        if (smartDispatch) {
            initialParams.smartDispatch = smartDispatch;
        }
        if (jobRequests) {
            initialParams[FILTERS_JOB_REQUESTS] = jobRequests[JO_REQUEST_ID];
        }
        if (draft) {
            initialParams[FILTERS_JOB_DRAFTS] = draft[JO_DRAFT_ID];
        }
    }

    return initialParams;
};
