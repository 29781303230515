import React, { useState } from "react";
import PhoneDialog from "./phoneDialog";
import { useSelector } from "react-redux";

const PhoneDialogWrapper = () => {
    const account = useSelector((state) => state.account);
    const [phoneDialogOpen, setPhoneDialogOpen] = useState(true);

    const handleClose = () => {
        setPhoneDialogOpen(false);
    };

    if (account.phone || account.isFakeUser) {
        return null;
    }

    return <PhoneDialog phoneDialogOpen={phoneDialogOpen} handleClose={handleClose} />;
};

export default PhoneDialogWrapper;
