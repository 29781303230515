import React from "react";
import { getJobOrderEditRoute } from "../../../../helpers/global";
import { useHistory } from "react-router";
import { SimpleLink } from "../../../core";
import { LINKED_JOB_LABEL } from "../../../../constants/strings";

const JobListItemLinkedJobLink = ({ linkedJobId, setActive, isJobBoardDispatch }) => {
    const history = useHistory();

    const onLinkedJobLinkClick = (e) => {
        e.stopPropagation();
        if (isJobBoardDispatch) {
            setActive({ id: linkedJobId });
        } else {
            history.push(getJobOrderEditRoute(linkedJobId));
        }
    };

    return <SimpleLink onClick={onLinkedJobLinkClick}>{LINKED_JOB_LABEL(linkedJobId)}</SimpleLink>;
};

export default JobListItemLinkedJobLink;
