import React, { useEffect, useState } from "react";
import { compose } from "redux";
import _ from "lodash";
import { change, Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import clsx from "clsx";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import TextInputComponent from "../core/form/textInputComponent";
import ErrorNotification from "../core/notification";
import Loader from "../core/loader";
import Grid from "@material-ui/core/Grid";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { formatReduxFormErrors } from "../../helpers/errorFormatting";
import { Validation } from "../../helpers/validation";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const AlertZoneCustomPopUp = (props) => {
    const {
        error,
        handleClose,
        handleSave,
        handleSubmit,
        newZoneData,
        alertZoneCoordinates,
        dispatch,
        form,
        showRadius,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const onlyOneMaker = newZoneData && newZoneData.markers && newZoneData.markers.length === 1;
    const disabled =
        (newZoneData && newZoneData.markers && newZoneData.markers.length && !onlyOneMaker) ||
        !_.isEmpty(alertZoneCoordinates);

    const onSubmit = (values) => {
        setLoading(true);

        return handleSave(values)
            .then((response) => {
                setLoading(false);
            })
            .catch((errorResponse) => {
                setLoading(false);
                formatReduxFormErrors(errorResponse);
            });
    };

    useEffect(() => {
        if (disabled) {
            dispatch(change(form, "radius", undefined));
        }
    }, [newZoneData]);

    return (
        <Grid container className={clsx("alert-zone-popup", isLoading && LOADER_WHITE_OVERLAY)}>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
                style={{ width: "100%" }}
            >
                <Grid container alignItems={"flex-start"} justify={"flex-end"} spacing={2}>
                    {showRadius && (
                        <Grid item xs={3}>
                            <Field
                                type="number"
                                name="radius"
                                disabled={!!disabled}
                                placeholder="meters"
                                validate={
                                    newZoneData && newZoneData.markers && newZoneData.markers.length === 1
                                        ? [Validation.required]
                                        : []
                                }
                                label="Radius"
                                needShowEndAdornment={false}
                                component={TextInputComponent}
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Field
                            type="text"
                            name="name"
                            validate={[Validation.required, Validation.noSpecialSymbols]}
                            label="Alert Zone Name"
                            needShowEndAdornment={false}
                            component={TextInputComponent}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ButtonsGroup>
                            <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit">Save</PrimaryButton>
                        </ButtonsGroup>
                    </Grid>
                </Grid>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} />}
        </Grid>
    );
};

AlertZoneCustomPopUp.propTypes = {
    error: PropTypes.any,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    newZoneData: PropTypes.object.isRequired,
    alertZoneCoordinates: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    showRadius: PropTypes.bool.isRequired,
};

AlertZoneCustomPopUp.defaultProps = {
    showRadius: true,
};

const ALERT_ZONE_FORM = "alertZoneForm";

export default withRouter(
    compose(
        reduxForm({
            form: ALERT_ZONE_FORM,
        }),
        connect((state) => {
            return {
                newZoneData: state.sites.newZoneData,
                alertZoneCoordinates: state.sites.alertZoneCoordinates,
            };
        }),
    )(AlertZoneCustomPopUp),
);
