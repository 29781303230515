import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Box from "@material-ui/core/Box";
import ErrorNotification from "../core/notification";
import { FILTERS_SITES_TYPE_ID, FILTERS_STATUS_ID } from "../global/searchAndFilters";
import ProjectCheckIcon from "../global/checkIcon";
import Loader from "../core/loader";

import * as SitesActions from "../../actions/sites";
import { getSiteEditRoute } from "../../helpers/global";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import ListWaypoint from "../core/listWaypoint";
import SiteFilters from "./siteFilters";
import { SITES_STATUS_ARCHIVE } from "../../constants/maps";

const useStyles = makeStyles((theme) => ({
    sitesList: {
        "& .create-site": {
            padding: " 0 31px 20px 31px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-start",
            color: theme.palette.primary.main,

            "& svg, & a": {
                color: "inherit",
            },

            "& a": {
                marginLeft: 10,
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: `${theme.typography.fontSize}px`,
            },
        },
    },
    siteMain: {
        backgroundColor: theme.palette.general.paleOrange,
        height: "100%",
        "& .scroll-wrap": {
            height: 700,
            overflowY: "scroll",
            marginRight: 12,
            position: "relative",
            zIndex: 1,
        },
    },

    sitesWrap: {
        margin: "0 15px 0 33px",
    },
    siteItem: {
        padding: 16,
        border: `2px solid ${theme.palette.border.primary}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 10,
        backgroundColor: "#fff",

        "&:hover": {
            cursor: "pointer",
            boxShadow: `0 0 6px 0 ${theme.palette.secondary.main}`,
        },
        "&.-is-loading": {
            "& h3": {
                width: 134,
                height: 15,
                marginBottom: 6,
                backgroundColor: theme.palette.secondary.main,
                fontSize: 0,
            },
            "& p": {
                width: 100,
                height: 8,
                backgroundColor: theme.palette.secondary.main,
                fontSize: "0 !important",
                marginBottom: 11,
            },
        },
        "&.-current": {
            borderColor: theme.palette.general.green,
            position: "relative",
        },
        "&.-archived-site": {
            opacity: 0.7,

            "&.-current": {
                borderColor: theme.palette.border.primary,
            },
        },
    },
    icon: {
        marginRight: 15,
    },
}));

const SitesList = (props) => {
    const classes = useStyles();
    const {
        sites,
        sitesLoading,
        sitesCount,
        sitesError,
        getSites,
        sitesVisibleLoading,
        onSiteSelection,
        setSitesSearchQuery,
        setNewFilters,
        currentSite,
        match,
        history,
    } = props;
    const redirectedSiteId = history.location.state && history.location.state.siteId;
    const [error, setError] = useState();

    const updateListByFilters = (filters, clearFilters) => {
        if (!_.isNil(filters.keywords)) {
            setSitesSearchQuery(filters.keywords);
        }

        setNewFilters({
            accessType: filters[FILTERS_SITES_TYPE_ID],
            status: filters[FILTERS_STATUS_ID],
        });

        loadSites();
    };
    const loadSites = () => {
        return getSites(false, redirectedSiteId);
    };
    const setActiveSite = (site) => {
        onSiteSelection && onSiteSelection(site);
        history.push(getSiteEditRoute(site.id));
    };

    const getMoreSites = (concat) => getSites(concat, redirectedSiteId);
    const onMessageClose = () => {
        setError(null);
    };

    return (
        <Grid component="div" item xs={4} className={clsx(classes.sitesList)}>
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            <div className={clsx(classes.siteMain, "sites-and-projects-wrapper")}>
                <SiteFilters updateListByFilters={updateListByFilters} />
                <div className={clsx(sitesVisibleLoading && LOADER_WHITE_OVERLAY)}>
                    {sitesVisibleLoading && <Loader />}
                    {sitesLoading || !_.isEmpty(sites) ? (
                        <div className={clsx("scroll-wrap")}>
                            <div className={clsx(classes.sitesWrap)}>
                                {((sitesLoading && new Array(8).fill(0)) || sites).map((site, index) => {
                                    const isArchived = site.status === SITES_STATUS_ARCHIVE;
                                    const selectedSite = currentSite ? currentSite.id === site.id : false;

                                    return (
                                        <React.Fragment key={index}>
                                            <ListWaypoint
                                                fetchMore={getMoreSites}
                                                index={index}
                                                items={sites}
                                                itemsCount={sitesCount}
                                            />
                                            <div
                                                key={index}
                                                onClick={() => !sitesLoading && setActiveSite(site)}
                                                className={clsx(
                                                    classes.siteItem,
                                                    selectedSite && "-current",
                                                    sitesLoading && "-is-loading container-loader-wrap",
                                                    isArchived && "-archived-site",
                                                )}
                                            >
                                                {selectedSite && <ProjectCheckIcon />}
                                                <Grid component="div" container alignItems={"center"} wrap={"nowrap"}>
                                                    <Grid component="div" item className={classes.icon}>
                                                        <LocationOnIcon />
                                                    </Grid>
                                                    {sitesLoading && (
                                                        <Grid component="div" item>
                                                            <Loader />
                                                        </Grid>
                                                    )}
                                                    <Grid component="div" item>
                                                        <h3>{site.name}</h3>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <Box component="h3" textAlign="center">
                            No sites found
                        </Box>
                    )}
                </div>
                {sitesError && <ErrorNotification message={sitesError} />}
            </div>
        </Grid>
    );
};

SitesList.propTypes = {
    formValues: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    sites: PropTypes.array,
    sitesLoading: PropTypes.bool,
    sitesVisibleLoading: PropTypes.bool,
    sitesCount: PropTypes.number,
    sitesError: PropTypes.bool,
    getSites: PropTypes.func.isRequired,
    onSiteSelection: PropTypes.func,
    setSitesSearchQuery: PropTypes.func,
    setNewFilters: PropTypes.func,
    setCurrentSite: PropTypes.func.isRequired,
    currentSite: PropTypes.object,
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
            sites: state.sites.sites,
            currentSite: state.sites.currentSite,
            sitesLoading: state.sites.sitesLoading,
            sitesVisibleLoading: state.sites.sitesVisibleLoading,
            sitesError: state.sites.sitesError,
            sitesCount: state.sites.sitesCount,
        }),
        (dispatch) => ({
            getSites: (concat, siteId) => dispatch(SitesActions.getSites(concat, siteId)),
            setCurrentSite: (payload) => dispatch(SitesActions.setCurrentSite(payload)),
            setSitesSearchQuery: (payload) => dispatch(SitesActions.setSitesSearchQuery(payload)),
            setNewFilters: (payload) => dispatch(SitesActions.setSitesFilters(payload)),
            resetSites: (payload) => dispatch(SitesActions.resetSites(payload)),
        }),
    )(SitesList),
);
