import {
    SETTINGS_PAYLOAD_FAILURE_MESSAGE,
    SETTINGS_PAYLOAD_SUCCESS_MESSAGE,
    SETTINGS_PAYLOAD_UPDATE_PAYLOAD,
    SETTINGS_PAYLOADS_FILTERS_SET,
    SETTINGS_PAYLOADS_LOADING,
    SETTINGS_PAYLOADS_LOADING_FAILURE,
    SETTINGS_PAYLOADS_LOADING_SUCCESS,
} from "../events/payloadSettings";
import { archivePayload, deletePayloadRequest, fetchUserPayloads, unarchivePayload } from "../dataServers/payloads";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import { formValueSelector } from "redux-form";
import { SETTINGS_PAYLOAD_COMPANY_SELECTOR } from "../components/settings/payloadsSettings";

const settingsPayloadsLoading = () => {
    return {
        type: SETTINGS_PAYLOADS_LOADING,
    };
};

const settingsPayloadsLoadingSuccess = (payloadsResponse) => {
    return {
        type: SETTINGS_PAYLOADS_LOADING_SUCCESS,
        payload: payloadsResponse,
    };
};

const settingsPayloadsLoadingFailure = (error) => {
    return {
        type: SETTINGS_PAYLOADS_LOADING_FAILURE,
        payload: error,
    };
};

export const settingsPayloadsFiltersSet = (filters) => {
    return {
        type: SETTINGS_PAYLOADS_FILTERS_SET,
        payload: filters,
    };
};

export const payloadSettingsSuccessMessage = (success) => {
    return {
        type: SETTINGS_PAYLOAD_SUCCESS_MESSAGE,
        payload: success,
    };
};

export const payloadSettingsFailureMessage = (error) => {
    return {
        type: SETTINGS_PAYLOAD_FAILURE_MESSAGE,
        payload: error,
    };
};

export const updatePayload = (updatedPayload) => {
    return {
        type: SETTINGS_PAYLOAD_UPDATE_PAYLOAD,
        payload: updatedPayload,
    };
};

export const changePayloadStatus = (isArchived, payloadId, payloadName) => {
    return (dispatch, getState) => {
        const state = getState();
        const formSelector = formValueSelector(SETTINGS_PAYLOAD_COMPANY_SELECTOR);
        const selectedCompany = formSelector(state, "company");
        const companyId = state.account.company.id;

        const params = state.payloadsSettings.payloadsFilters;
        const request = isArchived ? unarchivePayload : archivePayload;

        request(payloadId)
            .then((response) => {
                dispatch(getSettingsPayloads(false, selectedCompany?.value || companyId, params));
                const successMessage = `Payload ${payloadName} successfully ${isArchived ? "unarchived" : "archived"}`;
                dispatch(payloadSettingsSuccessMessage(successMessage));
            })
            .catch((error) => {
                dispatch(payloadSettingsFailureMessage(error.message));
            });
    };
};

export const deletePayload = (payloadId, payloadName) => {
    return (dispatch, getState) => {
        const state = getState();
        const formSelector = formValueSelector(SETTINGS_PAYLOAD_COMPANY_SELECTOR);
        const companyId = state.account.company.id;
        const selectedCompany = formSelector(state, "company");
        const params = state.payloadsSettings.payloadsFilters;

        return deletePayloadRequest(payloadId)
            .then((response) => {
                dispatch(payloadSettingsSuccessMessage(`Payload ${payloadName} was successfully deleted`));
                dispatch(getSettingsPayloads(false, selectedCompany?.value || companyId, params));
            })
            .catch((error) => {
                dispatch(payloadSettingsFailureMessage(error.message));
            });
    };
};

export const getSettingsPayloads = (concat, companyId, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const payloadsList = state.payloadsSettings.payloadsList;
        const currentPage = Math.ceil(payloadsList.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(payloadsList) ? +currentPage + 1 : 1;

        dispatch(settingsPayloadsLoading());

        return fetchUserPayloads(companyId, {
            page: concat ? nextPage : 1,
            ...params,
        })
            .then((response) => {
                dispatch(
                    settingsPayloadsLoadingSuccess({
                        meta: response.meta,
                        data: concat ? [...payloadsList, ...response.data] : response.data,
                    }),
                );
            })
            .catch((error) => {
                dispatch(settingsPayloadsLoadingFailure(error));
            });
    };
};
