import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MapIcon from "@material-ui/icons/Map";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

import { IS_INSPECTOR_USER } from "../../../constants/maps";

const TicketMapAndTickPic = (props) => {
    const {
        isAirTicketLite,
        handleClickTicketForRouteInfo,
        ticket,
        handleHoverTicpicIcon,
        account,
        actionIconsClasses,
        signedTicketStyles,
        onlyView
    } = props;

    return (
        <React.Fragment>
            {!IS_INSPECTOR_USER(account.role) && !isAirTicketLite && !onlyView && (
                <IconButton
                    className={actionIconsClasses}
                    onClick={(event) => handleClickTicketForRouteInfo(event, ticket)}
                >
                    <MapIcon />
                </IconButton>
            )}
            {!!ticket.ticPicsCount && !!ticket.signed && (
                <Tooltip title={"Signed ticket"}>
                    <IconButton
                        onClick={(event) => handleHoverTicpicIcon(event, ticket)}
                        className={clsx(actionIconsClasses, signedTicketStyles)}
                    >
                        <span className="label">S</span>
                        <ConfirmationNumberIcon className="sign-icon" />
                    </IconButton>
                </Tooltip>
            )}
            {!!ticket.ticPicsCount && !ticket.signed && (
                <IconButton className={actionIconsClasses} onClick={(event) => handleHoverTicpicIcon(event, ticket)}>
                    <PhotoLibraryIcon />
                </IconButton>
            )}
        </React.Fragment>
    );
};

TicketMapAndTickPic.propTypes = {
    isAirTicketLite: PropTypes.bool,
    handleClickTicketForRouteInfo: PropTypes.func,
    ticket: PropTypes.object,
    account: PropTypes.object.isRequired,
    handleHoverTicpicIcon: PropTypes.func,
    actionIconsClasses: PropTypes.string,
};

export default TicketMapAndTickPic;
