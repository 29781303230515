export const LIVE_MAP_TRUCKS_LOADING = "liveMapTrucksLoading";
export const LIVE_MAP_TRUCKS_LOADED = "liveMapTrucksLoaded";
export const LIVE_MAP_TRUCKS_FAILED = "liveMapTrucksFailed";
export const TRUCKS_COORDINATES_UPDATED = "trucksCoordinatesUpdated";
export const TRUCKS_DIALOG_TOGGLE = "trucksDialogToggle";
export const SET_TRUCK_FOR_EDIT = "setTruckForEdit";
export const GET_COMPANY_TRUCKS_SUCCESS = "getCompanyTrucksSuccess";
export const IS_TRUCKS_LOADING = "isTrucksLoading";
export const RESET_COMPANY_TRUCKS = "resetCompanyTrucks";
export const UPDATE_ACTIVE_TRUCK_ORDER = "updateActiveTruckOrder";
export const RESET_TRUCKS_DATA = "resetTrucksData";
