import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        width: 17,
        height: 17,
        color: theme.palette.general.green,
        backgroundColor: "#C9F0E5",
        borderRadius: 20,
        position: "absolute",
        top: 3,
        right: 3,
    },
}));

const ProjectCheckIcon = () => {
    const classes = useStyles();

    return <CheckIcon className={classes.checkIcon} />;
};

export default withRouter(ProjectCheckIcon);
