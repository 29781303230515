export const JOB_ORDERS_LOADED = "jobOrdersLoaded";
export const UPDATE_JOB_ORDERS_LIST_COUNT = "updateJobOrdersListCount";
export const JOB_ORDER_OPENED = "jobOrderOpened";
export const UPDATE_REQUESTED_JOB_ORDER_ID = "updateRequestedJobOrderId";
export const NEED_UPDATE_JOB_ORDERS = "needUpdateCurrentJobOrder";
export const NEED_UPDATE_MY_FLEET_LIST_FOR_ADMIN = "needUpdateMyFleetListForAdmin";
export const SET_JO_MAP_COORDINATES = "setJobOrderCoordinates";
export const JO_CLOSED_NOTIFICATION = "joClosedNotification";
export const JO_REOPENED_NOTIFICATION = "joReopenedNotification";
export const JO_DELETED_NOTIFICATION = "joDeletedNotification";
export const JO_CREATED_NOTIFICATION = "joCreatedNotification";
export const JO_UPDATE_NOTIFICATION = "joUpdateNotification";
export const JO_UPDATED_WEBSOCKET_DATA_RECEIVED = "joUpdateWebsocketDataReceived";
export const IGNORE_WEBSOCKET_JO_DATA = "ignoreWebsocketJoData";
export const JO_SHOW_TABLE_LOADER = "joShowTableLoader";
export const JO_DATE_CHANGE = "joDateChange";
export const FLEET_LIST_REQUESTED_DATE_CHANGE = "fleetListRequestedDateChange";
export const NEW_JOB_ORDER_PAGE_LEAVE = "newJobOrderPageLeave";
export const UPDATE_ACTIVE_JOB_ORDER = "updateActiveJobOrder";
export const RESET_ACTIVE_JOB_ORDERS = "resetActiveJobOrders";
export const UPDATE_JO_FIRST_RENDERED_INITIAL_VALUES = "updateJobOrderFirstRenderedInitialValues";
export const SKIP_TRUCKS_INITIAL_LOADING_FOR_LISTING_DATE = "skipTrucksInitialLoadingForListingData";
export const SKIP_JOB_ORDER_INITIAL_LOADING = "skipJobOrdersInitialLoading";
export const UPDATE_ACTIVE_DISPATCHED_TRUCK_ORDER = "updateActiveDispatchedTruckOrder";
export const RESET_JOB_ORDERS_PARAMS = "resetJobOrdersParams";
export const UPDATE_MY_FLEET_SELECTED_TRUCKS = "updateMyFleetSelectedTrucks";
export const RESET_HAULER_ORDER = "resetHaulerOrder";
export const UPDATE_HAULER_ORDER = "updateHaulerOrder";
export const UPDATE_CURRENT_JOB_ORDER_REQUEST = "updateCurrentJobOrderRequest";
export const REFRESH_JOBS_LIST = "JOBS: REFRESH JOBS LIST";
export const UPDATE_REDIRECT_JOB_ID = "updateRedirectJobId";
