import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";
import {
    WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_LOSING_ACCESS_EVENT,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_SMART_DISPATCH,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT,
} from "../../constants/maps";
import store from "../../store";
import { processEvent } from "./processors/baseProcessor";
import { processJobEvent } from "./processors/jobProcessor";
import { processTicketEvent } from "./processors/ticketProcessor";
import { processTruckEvent } from "./processors/truckProcessor";
import { processMessageEvent } from "./processors/messageProcessor";
import { processLosingAccessEvent } from "./processors/lossingEventProcessor";
import { processAnomalyDetectionEvent } from "./processors/anomalyDetectionProcessor";
import { processSmartDispatchErrorEvent, processSmartDispatchEvent } from "./processors/smartDispatchProcessor";

const getState = store.getState;

const _prepareEvent = ({ event, timezone = null, dateTimeFormat = DEFAULT_DATE_TIME_FORMAT }, processors) => {
    return processors.reduce(
        (newEvent, processor) =>
            processor({
                event: newEvent,
                timezone: timezone || (getState().account && getState().account.timezone),
                dateTimeFormat,
            }),
        event,
    );
};

export const prepareJobEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processJobEvent]);
};

export const prepareTicketEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processTicketEvent]);
};

export const prepareTruckEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processTruckEvent]);
};

export const prepareMessageEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processMessageEvent]);
};

export const prepareLosingAccessEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, ...options }, [processEvent, processLosingAccessEvent]);
};

export const prepareAnomalyDetectionEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, options }, [processEvent, processAnomalyDetectionEvent]);
};

export const prepareSmartDispatchEvent = ({ event, ...options }) => {
    return _prepareEvent({ event, options }, [processEvent, processSmartDispatchErrorEvent, processSmartDispatchEvent]);
};

export const prepareEvent = ({ event, ...options }) => {
    switch (event.subType) {
        case WEBSOCKET_DATA_SUB_TYPE_JO_EVENT:
            return prepareJobEvent({ event, ...options });
        case WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT:
            return prepareTicketEvent({ event, ...options });
        case WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT:
            return prepareTruckEvent({ event, ...options });
        case WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT:
            return prepareMessageEvent({ event, ...options });
        case WEBSOCKET_DATA_TYPE_SUB_TYPE_LOSING_ACCESS_EVENT:
            return prepareLosingAccessEvent({ event, ...options });
        case WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION:
            return prepareAnomalyDetectionEvent({ event, ...options });
        case WEBSOCKET_DATA_TYPE_SUB_TYPE_SMART_DISPATCH:
            return prepareSmartDispatchEvent({ event, ...options });
        default:
            return _prepareEvent({ event, ...options }, [processEvent]);
    }
};
