import React, { useEffect, useState } from "react";
import _ from "lodash";
import { BundleRouteMap } from "./BundleRouteMap";
import { fetchBundleRouteDetails } from "../../../dataServers/smartDispatch";
import BundleRouteSlider from "./RouteSlider";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentBundle, selectRouteDetailsData } from "../../../selectors/smartDispatchSelectors";
import PropTypes from "prop-types";
import { showSmartDispatchFailureMessage, smartDispatchIsLoadingUpdate } from "../../../actions/smartDispatch";
import { PROCESS_SERVER_ERROR } from "../../../constants/global";
import { FAKE_BUNDLE_761_DATA, FAKE_BUNDLE_764_DATA } from "./fakeBundleData";
import { BUNDLES_VIEW_BY } from "../constants/index.js";

export const FAKE_761_BUNDLE_DATA_ID = 761;
export const FAKE_764_BUNDLE_DATA_ID = 764;

const SelectedRouteContainer = ({ selectedTruckSolutionIndex, viewBy }) => {
    const dispatch = useDispatch();

    const currentBundle = useSelector(selectCurrentBundle);

    const route = useSelector(selectRouteDetailsData);

    const [routeLocations, setRouteLocations] = useState(null);

    useEffect(() => {
        if (!currentBundle || _.isUndefined(selectedTruckSolutionIndex)) return;
        if (viewBy !== BUNDLES_VIEW_BY.BUNDLE) return;
        dispatch(smartDispatchIsLoadingUpdate(true));
        fetchBundleRouteDetails(currentBundle.problemId, selectedTruckSolutionIndex)
            .then(({ data: routeLocations }) => {
                // might be used for https://github.com/TruckITllc/truckit-frontend/issues/2761#issuecomment-1024206289 test
                if (currentBundle.problemId === FAKE_761_BUNDLE_DATA_ID) {
                    setRouteLocations(FAKE_BUNDLE_761_DATA);
                } else if (currentBundle.problemId === FAKE_764_BUNDLE_DATA_ID) {
                    setRouteLocations(FAKE_BUNDLE_764_DATA);
                } else {
                    setRouteLocations(routeLocations);
                }
            })
            .catch((e) => {
                dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(e)]));
            })
            .finally(() => {
                dispatch(smartDispatchIsLoadingUpdate(false));
            });
    }, [selectedTruckSolutionIndex, currentBundle]);

    useEffect(() => {
        if (!route) return;
        if (viewBy !== BUNDLES_VIEW_BY.TRUCK) return;
        dispatch(smartDispatchIsLoadingUpdate(true));
        fetchBundleRouteDetails(route.bundleId, route.id)
            .then(({ data: routeLocations }) => {
                // might be used for https://github.com/TruckITllc/truckit-frontend/issues/2761#issuecomment-1024206289 test
                if (route.bundleId === FAKE_761_BUNDLE_DATA_ID) {
                    setRouteLocations(FAKE_BUNDLE_761_DATA);
                } else if (route.bundleId === FAKE_764_BUNDLE_DATA_ID) {
                    setRouteLocations(FAKE_BUNDLE_764_DATA);
                } else {
                    setRouteLocations(routeLocations);
                }
            })
            .catch((e) => {
                dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(e)]));
            })
            .finally(() => {
                dispatch(smartDispatchIsLoadingUpdate(false));
            });
    }, [route]);

    if (!routeLocations) {
        return null;
    }

    return (
        <>
            <BundleRouteMap routeLocations={routeLocations} />
            <BundleRouteSlider routeLocations={routeLocations} />
        </>
    );
};

SelectedRouteContainer.propTypes = {
    selectedTruckSolutionIndex: PropTypes.number,
    viewBy: PropTypes.string,
};

export default SelectedRouteContainer;
