import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { PAGE_ACTIONS_STYLES, TABLE_PRIMARY, TABLE_SECONDARY_SCROLLABLE } from "../../styles/reusableStyles";
import {
    IS_ADMIN_USER,
    PO_TYPE_ASPHALT_MILLINGS,
    TRUCK_STATUS_AN_ROUTE_TO_DROPOFF,
    TRUCK_STATUS_AN_ROUTE_TO_PICK_UP,
    TRUCK_STATUS_AT_DROPOFF,
    TRUCK_STATUS_AT_PAVER,
    TRUCK_STATUS_AT_PICK_UP,
    TRUCK_STATUS_OFFLINE,
    TRUCK_STATUS_ONLINE,
    TRUCK_STATUSES,
} from "../../constants/maps";
import {
    ENV_MODE_PROD,
    FREQUENCY_UPDATE_PROD,
    FREQUENCY_UPDATE_TEST_DEMO,
    LOADER_WHITE_OVERLAY,
} from "../../constants/global";
import SearchAndFilters, { FILTERS_REGIONS_ID, FILTERS_STATUS_ID } from "../global/searchAndFilters";
import * as TrucksActions from "../../actions/trucks";
import ErrorNotification from "../core/notification";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import TruckItem from "../fleet/truckItem";
import Loader from "../core/loader";
import ListWaypoint from "../core/listWaypoint";
import { timeFromSeconds } from "../../helpers/jobOrders";
import { GET_STATUS_SITE_NAME } from "../jobs/jobDetails/stats";
import { idleStatus } from "../fleet/fleetFilters";
import { setLivemapTruck } from "../../actions/livemapActions";
import { DASHBOARD_FORM } from "./notificationFeedForm";
import { LOCAL_STORAGE_FILTERS_ID } from "../../helpers/api";

export const LIVE_TRUCK_ROW_HEIGHT = 70;
const useStyles = makeStyles((theme) => ({
    trucksTable: {
        borderTop: `2px solid ${theme.palette.border.primary}`,

        "& .active-truck": {
            cursor: "pointer",
        },
        "& .selected-truck": {
            position: "relative",
            boxShadow: `0px 0px 3px ${theme.palette.primary.main} !important`,
        },
        "& td": {
            height: LIVE_TRUCK_ROW_HEIGHT,
        },
        "& .thead .tr .th": {
            height: 52,
        },
    },
    trucksFilters: {
        position: "relative",

        "& .search-filters": {
            borderBottom: 0,
        },
    },
}));

const isActiveRegionFilter = (regionFilters, companyRegions) => {
    let isActiveRegionFilter = false;
    const companyRegionsIdLst = companyRegions?.map((region) => region.id);
    if (regionFilters) {
        Object.values(regionFilters).forEach((value) => {
            if (value) {
                isActiveRegionFilter = true;
            }
        });
        if (companyRegionsIdLst) {
            Object.keys(regionFilters).forEach((key) => {
                if (!companyRegionsIdLst.includes(key)) {
                    isActiveRegionFilter = false;
                }
            });
        }
    }

    return isActiveRegionFilter;
};

function LiveMapTrucksList(props) {
    const {
        centerMapToCoordinates,
        selectedLivemapTruck,
        liveMapTrucks,
        getLiveMapTrucks,
        liveMapTrucksLoading,
        form,
        history,
        regionsMeta,
        account,
        searchValue,
        account: { companyRegions },
    } = props;
    const dispatch = useDispatch();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();
    const classes = useStyles();
    const tableStyles = TABLE_PRIMARY();
    const tableScrolableStyles = TABLE_SECONDARY_SCROLLABLE();
    const isResetToDefault = !_.isEmpty(companyRegions) && !_.isEmpty(account.region);
    const filtersData = localStorage.getItem(LOCAL_STORAGE_FILTERS_ID);
    const filtersDataObject = filtersData ? JSON.parse(filtersData) : {};
    const liveMapTrucksFilters = filtersDataObject ? filtersDataObject[LIVE_MAP_TRUCKS_LIST_FORM] : {};
    const regionSavedFilters = liveMapTrucksFilters && liveMapTrucksFilters.regions ? liveMapTrucksFilters.regions : {};
    const [appliedFilters, setFilters] = useState({});
    const [activeTruck, setActiveTruck] = useState();
    const [error, setError] = useState(false);
    const [filtersList, updateFiltersList] = useState([]);
    const [initialFilters, updateInitialFilters] = useState({});

    const updateListByFilters = (filters) => {
        let newFilters = _.cloneDeep(filters);
        if (!_.isEqual(appliedFilters, newFilters)) {
            setFilters(newFilters);
        }
    };

    const getMoreTrucks = (concat) => getLiveMapTrucks(concat, appliedFilters);

    const showTruckInfo = (liveMapTruck) => {
        if (!liveMapTruck) {
            return false;
        }

        liveMapTruck.truckCoordinates &&
            centerMapToCoordinates({
                lat: liveMapTruck.truckCoordinates.latitude,
                lng: liveMapTruck.truckCoordinates.longitude,
            });

        if (activeTruck && activeTruck.truck.id === liveMapTruck.truck.id) {
            return false;
        }
        setActiveTruck(liveMapTruck);
    };

    const closeMessage = () => {
        setError(null);
    };

    const handleTruckSelected = (liveMapTruck) => {
        showTruckInfo(liveMapTruck);
        dispatch(setLivemapTruck(liveMapTruck));
    };

    useEffect(() => {
        //get meta of async filters
        if (!_.isEmpty(appliedFilters) && regionsMeta && regionsMeta.loaded) {
            getLiveMapTrucks(false, appliedFilters);
        }
        if (
            regionSavedFilters &&
            !isActiveRegionFilter(regionSavedFilters, account.companyRegions) &&
            _.isEmpty(appliedFilters) &&
            regionsMeta &&
            regionsMeta.loaded
        ) {
            getLiveMapTrucks(false, appliedFilters);
        }
    }, [appliedFilters, regionsMeta]);

    useEffect(() => {
        if (!_.isEmpty(companyRegions)) {
            updateFiltersList([
                ...filtersList,
                {
                    title: "Regions",
                    filterKey: FILTERS_REGIONS_ID,
                    filters: companyRegions.map((item) => ({
                        label: item.name,
                        id: item.id,
                    })),
                },
            ]);
        }
    }, [companyRegions]);

    useEffect(() => {
        const time =
            process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD ? FREQUENCY_UPDATE_PROD : FREQUENCY_UPDATE_TEST_DEMO;
        const interval = setInterval(() => {
            getLiveMapTrucks(false, appliedFilters);
        }, time);

        return () => clearInterval(interval);
    }, [appliedFilters]);

    useEffect(() => {
        const setUpFilterList = [
            {
                title: "Truck Status",
                filterKey: FILTERS_STATUS_ID,
                filters: [
                    {
                        label: TRUCK_STATUSES[TRUCK_STATUS_ONLINE](),
                        id: TRUCK_STATUS_ONLINE,
                    },
                    {
                        label: TRUCK_STATUSES[TRUCK_STATUS_OFFLINE](),
                        id: TRUCK_STATUS_OFFLINE,
                    },
                    {
                        label: TRUCK_STATUSES[TRUCK_STATUS_AT_PICK_UP](),
                        id: [TRUCK_STATUS_AT_PICK_UP, TRUCK_STATUS_AN_ROUTE_TO_PICK_UP],
                    },
                    {
                        label: TRUCK_STATUSES[TRUCK_STATUS_AT_DROPOFF](),
                        id: [TRUCK_STATUS_AT_DROPOFF, TRUCK_STATUS_AN_ROUTE_TO_DROPOFF],
                    },
                    account.company.poType === PO_TYPE_ASPHALT_MILLINGS && {
                        label: TRUCK_STATUSES[TRUCK_STATUS_AT_PAVER](),
                        id: TRUCK_STATUS_AT_PAVER,
                    },
                ],
            },
        ];

        if (!IS_ADMIN_USER(account.company.id)) {
            setUpFilterList.push(idleStatus);
        }

        if (!_.isEmpty(companyRegions)) {
            setUpFilterList.push({
                title: "Regions",
                filterKey: FILTERS_REGIONS_ID,
                filters: companyRegions.map((item) => ({
                    label: item.name,
                    id: item.id,
                })),
            });
        }

        updateFiltersList(setUpFilterList);

        if (account.region) {
            updateInitialFilters({
                ...initialFilters,
                [FILTERS_REGIONS_ID]: {
                    [account.region.id]: true,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (liveMapTrucks.length) {
            let liveMapTruck = liveMapTrucks[0];
            if (history.location && history.location.state && history.location.state.truckId) {
                liveMapTruck = liveMapTrucks.find((i) => +i.truck.id === +history.location.state.truckId);
            }

            showTruckInfo(liveMapTruck);
        }
    }, [liveMapTrucks.length]);

    useEffect(() => {
        if (!_.isEmpty(selectedLivemapTruck)) {
            const {
                truck: { id: truckId },
            } = selectedLivemapTruck;
            const truckElem = document.getElementById(truckId);

            showTruckInfo(selectedLivemapTruck);

            if (truckElem) {
                truckElem.scrollIntoView();
            }
        }
    }, [selectedLivemapTruck]);

    useEffect(() => {
        if (searchValue === "") {
            setActiveTruck(undefined);
            dispatch(setLivemapTruck(null));
        }
    }, [searchValue]);

    return (
        <React.Fragment>
            {error && <ErrorNotification error={error} config={{ onClose: closeMessage }} />}
            <Grid container component="div" className={clsx(classes.trucksFilters, "search-and-filters")}>
                <Grid item xs={8} className={clsx(pageActionsStyles.pageActions, "search-filters")}>
                    <SearchAndFilters
                        key={filtersList.length}
                        form={form}
                        rememberFilters
                        isResetToDefault={isResetToDefault}
                        shouldListenType={true}
                        isLoading={false}
                        updateList={updateListByFilters}
                        initialFilters={initialFilters}
                        filtersList={filtersList}
                    />
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Grid container style={{ height: "auto" }} className={clsx(liveMapTrucksLoading && LOADER_WHITE_OVERLAY)}>
                {liveMapTrucksLoading && <Loader />}
                <Grid item xs={8}>
                    <div className={clsx(classes.trucksTable)}>
                        <div className={clsx(tableStyles.primaryTable, tableScrolableStyles.scrolableTable)}>
                            <div className="thead">
                                <div className="tr">
                                    <div className="th">Name</div>
                                    <div className="th" style={{ flex: "3" }}>
                                        Current Job
                                    </div>
                                    <div className="th">Status</div>
                                    <div className="th">ETA</div>
                                </div>
                            </div>
                            <div
                                className="tbody"
                                style={{
                                    maxHeight: 200,
                                    overflowY: "scroll",
                                }}
                            >
                                {!_.isEmpty(liveMapTrucks) ? (
                                    liveMapTrucks.map((liveMapTruck, index) => {
                                        const {
                                            truckCoordinates,
                                            truck,
                                            currentProjectName,
                                            status,
                                            estimatedTimeLeft,
                                            currentJobOrder,
                                            pickupSite,
                                            dropoffSite,
                                        } = liveMapTruck;
                                        const {
                                            id: jobOrderId,
                                            extRef,
                                            completedQuantity,
                                            totalQuantity,
                                        } = currentJobOrder || {};
                                        const statusLabel = TRUCK_STATUSES[status];
                                        const siteName =
                                            statusLabel &&
                                            GET_STATUS_SITE_NAME({
                                                status: status,
                                                pickUpSite: pickupSite,
                                                dropOffSite: dropoffSite,
                                            });

                                        return (
                                            <React.Fragment key={index}>
                                                <ListWaypoint
                                                    fetchMore={getMoreTrucks}
                                                    index={index}
                                                    items={liveMapTrucks}
                                                    itemsCount={liveMapTrucks.length}
                                                />
                                                <div
                                                    className={clsx(
                                                        "tr",
                                                        truckCoordinates && "active-truck",
                                                        activeTruck &&
                                                            activeTruck.truck.id === truck.id &&
                                                            "selected-truck",
                                                    )}
                                                    id={truck.id}
                                                    onClick={() => handleTruckSelected(liveMapTruck)}
                                                >
                                                    <div className="td">{truck.deviceName}</div>
                                                    <div className="td" style={{ flex: "3" }}>
                                                        {currentJobOrder && currentProjectName ? (
                                                            <span>
                                                                {`${currentProjectName} - #${jobOrderId}/${
                                                                    extRef || ""
                                                                }`}
                                                                {completedQuantity && totalQuantity && (
                                                                    <div>
                                                                        Qty:{" "}
                                                                        {`${completedQuantity.toFixed(
                                                                            2,
                                                                        )} / ${totalQuantity.toFixed(2)}`}
                                                                    </div>
                                                                )}
                                                            </span>
                                                        ) : (
                                                            "Not started today"
                                                        )}
                                                    </div>
                                                    <div className="td">{statusLabel && statusLabel(siteName)}</div>
                                                    <div className="td">{timeFromSeconds(estimatedTimeLeft, true)}</div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    <React.Fragment>
                                        <br />
                                        <h1 className="--text-center">No trucks to display</h1>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    {activeTruck ? (
                        <DndProvider backend={Backend}>
                            <TruckItem
                                showActivityOnly={true}
                                liveMapTrucksCount={liveMapTrucks.length}
                                id={activeTruck.truck.id}
                                truck={{
                                    ...activeTruck,
                                    id: activeTruck.truck.id,
                                    deviceName: activeTruck.truck.name,
                                }}
                            />
                        </DndProvider>
                    ) : (
                        <Grid container alignItems={"center"}>
                            <h2 className="--text-center">Select Truck to see it's activity</h2>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

LiveMapTrucksList.propTypes = {
    centerMapToCoordinates: PropTypes.func.isRequired,
    getLiveMapTrucks: PropTypes.func.isRequired,
    liveMapTrucksLoading: PropTypes.bool.isRequired,
    liveMapTrucks: PropTypes.array.isRequired,
    searchValue: PropTypes.string,
    form: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    selectedLivemapTruck: PropTypes.object.isRequired,
};

LiveMapTrucksList.defaultProps = {
    formValues: {},
    liveMapTrucks: [],
};

export const LIVE_MAP_TRUCKS_LIST_FORM = "liveMapTrucksListForm";

export default withRouter(
    compose(
        reduxForm({
            form: LIVE_MAP_TRUCKS_LIST_FORM,
        }),
        connect(
            (state) => {
                const formSelector = formValueSelector(DASHBOARD_FORM);

                return {
                    account: state.account,
                    liveMapTrucks: state.trucks.liveMapTrucks,
                    selectedLivemapTruck: state.livemap.selectedLivemapTruck,
                    liveMapTrucksLoading: state.trucks.liveMapTrucksLoading,
                    regionsMeta: state.account.regionsMeta,
                    searchValue: formSelector(state, "search"),
                };
            },
            (dispatch) => ({
                getLiveMapTrucks: (concat, params) => {
                    dispatch(TrucksActions.getLiveMapTrucks(concat, params));
                },
            }),
        ),
    )(LiveMapTrucksList),
);
