export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_SING_UP_EXTERNAL = "/sign-up";
export const ROUTE_SING_UP_INTERNAL = "/signup";
export const ROUTE_CONFIRM_EMAIL = "/confirm-email";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_FAQ = "/help";

export const projectsRoute = "/projects-and-sites";
export const analyticsRoute = "/reporting-and-analytics";
export const myJobsRoute = "/jobs";
export const dispatchRoute = "/dispatch";
export const smartDispatchRoute = "/smart-dispatch";
export const myCompanyRoute = "/my-company";
export const jobBoardRoute = "/job-board";
export const jobOrderRoute = "/job-order";
export const dispatchJobBoardRoute = "/job-board-item";

export const JOB_ORDER_ID = "/:jobOrderId(\\d+)";
export const PROJECT_ID = "/:projectId(\\d+)";
export const SITE_ID = "/:siteId(\\d+)";
export const REQUEST_ID = "/:requestId(\\d+)";

export const MOBILE_REDIRECTION = "/ios-redirection";

export const ROUTE_JOBS = {
    ROOT: myJobsRoute,
    MY_JOBS: `${myJobsRoute}/my-jobs`,
    JOB_ORDER_BY_ID: `${myJobsRoute}/my-jobs${JOB_ORDER_ID}`,
    JOB_BOARD: `${myJobsRoute}${jobBoardRoute}`,
    TICKETS: `${myJobsRoute}/tickets`,
    DISPATCH_JOB_ORDERS: `${dispatchRoute}${myJobsRoute}/job-order`,
    DISPATCH_JOB_ORDERS_NEW: `${dispatchRoute}${myJobsRoute}/job-order/new`,
    REQUEST_JOB_ORDER: `${dispatchRoute}${myJobsRoute}/job-order/request`,
    REQUEST_JOB_ORDER_BY_ID: `${dispatchRoute}${myJobsRoute}/job-order/request${REQUEST_ID}`,
    DISPATCH_JOB_BOARD_ITEM: `${dispatchRoute}${myJobsRoute}${dispatchJobBoardRoute}`,
    SMART_DISPATCH: `${myJobsRoute}${smartDispatchRoute}`,
};

export const ROUTE_MY_COMPANY = {
    ROOT: myCompanyRoute,
    MY_FLEET: `${myCompanyRoute}/my-fleet`,
    TRAILERS: `${myCompanyRoute}/trailers`,
    MY_TEAM: `${myCompanyRoute}/my-team`,
    ALL_COMPANIES: `${myCompanyRoute}/all-companies`,
    SETTINGS: `${myCompanyRoute}/setting`,
    DEVISES: `${myCompanyRoute}/iot-devices`,
    SETTINGS_JOBS: `${myCompanyRoute}/setting/job-orders`,
    SETTINGS_NOTIFICATIONS: `${myCompanyRoute}/setting/notifications`,
    SETTINGS_CUSTOMERS: `${myCompanyRoute}/setting/customers`,
    SETTINGS_HAULERS: `${myCompanyRoute}/setting/haulers`,
    SETTINGS_PAYLOADS: `${myCompanyRoute}/setting/payloads`,
    SETTINGS_TRUCKS: `${myCompanyRoute}/setting/truck-types`,
    SETTINGS_REGIONS: `${myCompanyRoute}/setting/regions`,
    SETTINGS_UNITS_OF_MEASURE: `${myCompanyRoute}/setting/units-of-measure`,
};
export const ROUTE_PROJECTS = {
    ROOT: projectsRoute,
    PROJECTS: `${projectsRoute}/projects`,
    SITES: `${projectsRoute}/sites`,
};

export const ROUTE_ANALYTICS = {
    ROOT: `${analyticsRoute}`,
    TICKETS: `${analyticsRoute}/tickets`,
    TRUCK_ACTIVITY: `${analyticsRoute}/truck-activity`,
    REPORT: `${analyticsRoute}/reporting`,
};

export const BUNDLE_ID = "/:bundleId(\\d+)";
export const TRUCK_ID = "/:truckId(\\d+)";
export const ROUTE_NUMBER = "/:routeNumber(\\d+)";
export const SD_VIEW = "/:viewBy(bundle|truck)";
export const SD_VIEW_BUNDLE = "/:viewBy(bundle)";
export const SD_VIEW_TRUCK = "/:viewBy(truck)";
export const SD_TAB = "/:currentTab(job-orders|job-bundles)";

export const SMART_DISPATCH = {
    INITIAL: `${myJobsRoute}${smartDispatchRoute}`,
    ROOT: `${myJobsRoute}${smartDispatchRoute}${SD_TAB}`,
    VIEW: `${myJobsRoute}${smartDispatchRoute}${SD_TAB}`,
    BUNDLE: `${myJobsRoute}${smartDispatchRoute}${SD_TAB}${SD_VIEW}`,
    BUNDLE_ID: `${myJobsRoute}${smartDispatchRoute}${SD_TAB}${SD_VIEW_BUNDLE}${BUNDLE_ID}`,
    TRUCK_ID: `${myJobsRoute}${smartDispatchRoute}${SD_TAB}${SD_VIEW_TRUCK}${TRUCK_ID}${ROUTE_NUMBER}`,
};

export const HELP_JUICE_LINK = "https://truckit.helpjuice.com";
