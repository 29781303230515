import { SET_STATS, TICKETS_STATS_FAILED, TICKETS_STATS_LOADED, TICKETS_STATS_LOADING } from "../events/stats";
import * as ticketsDataServer from "../dataServers/tickets";
import {
    JO_STATUS_ACTIVE_ID,
    JO_STATUS_PENDING_ID,
    TICKET_STATUSES_LABELS,
    UNIT_OF_MEASURE_BY_ID,
    UNIT_OF_MEASURE_LABELS,
    UNIT_OF_MEASURE_LABELS_BY_NAME,
    UNIT_OF_MEASURE_TON,
    UNIT_OF_MEASURE_TONNE,
} from "../constants/maps";
import { PROFILE_MEASURE_SYSTEM } from "../constants/accounts";
import React from "react";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { getJobOrdersCount } from "../dataServers/jobOrder";
import moment from "moment";
import { END_DATE_NAME, START_DATE_NAME } from "../components/global/datesRange";
import { selectMeasureSystem } from "../selectors/index";
import store from "../store";

const dispatch = store.dispatch;

export const setStats = (payload) => {
    return {
        type: SET_STATS,
        payload,
    };
};

export const getJobOrderStats = () => {
    const dates = {
        [START_DATE_NAME]: moment().format(SERVER_DATE_FORMAT),
        [END_DATE_NAME]: moment().format(SERVER_DATE_FORMAT),
    };

    return Promise.all([
        getJobOrdersCount({
            ...dates,
            status: JO_STATUS_ACTIVE_ID,
        }),
        getJobOrdersCount({
            ...dates,
            status: JO_STATUS_PENDING_ID,
            includeUnfilledJobs: true,
        }),
        getJobOrdersCount({
            ...dates,
            jobRequests: true,
        }),
    ]).then(([{ data: activeJobsCount }, { data: pendingJobsCount }, { data: jobRequestsCount }]) => {
        const data = {
            activeJobsCount,
            pendingJobsCount,
            jobRequestsCount,
        };

        dispatch(setStats(data));

        return data;
    });
};

export const getTicketsStats = (loud = true) => {
    return (dispatch, getState) => {
        dispatch({
            type: TICKETS_STATS_LOADING,
            payload: loud,
        });

        const system = selectMeasureSystem(getState());

        ticketsDataServer
            .getTicketsStats()
            .then(({ data: ticketsStats }) => {
                if (ticketsStats) {
                    const stats = {
                        statsByStatus: {},
                        quantityStats: {},
                        statsByUOM: {},
                    };
                    let isEmpty = true;
                    const statuses = Object.keys(ticketsStats.statsByStatus);
                    const unitsOfMeasure = Object.keys(ticketsStats.statsByUOM).map((i) => i);

                    statuses.forEach((status) => {
                        if (ticketsStats.statsByStatus[status]) {
                            isEmpty = false;
                            stats.statsByStatus[status] = {
                                value: ticketsStats.statsByStatus[status],
                                name: TICKET_STATUSES_LABELS[status],
                            };
                        }
                    });

                    unitsOfMeasure.forEach((unit) => {
                        if (ticketsStats.statsByUOM[unit]) {
                            const name = UNIT_OF_MEASURE_LABELS_BY_NAME[unit];

                            isEmpty = false;
                            stats.statsByUOM[unit] = {
                                value: ticketsStats.statsByUOM[unit],
                                name: name ? <span>{name} Tickets</span> : unit === "air" ? "AirTickets™" : unit,
                            };
                        }
                        if (ticketsStats.quantityStats[unit]) {
                            isEmpty = false;
                            stats.quantityStats[unit] = {
                                value: ticketsStats.quantityStats[unit],
                                name: unit,
                            };
                        }
                    });

                    dispatch({
                        type: TICKETS_STATS_LOADED,
                        payload: isEmpty ? {} : stats,
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: TICKETS_STATS_FAILED,
                    payload: {},
                });
            });
    };
};
