import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { change, destroy, Field, formValueSelector, getFormInitialValues, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Loader from "../../core/loader";
import { Divider } from "../../core/divider";
import PickUpDropOff, { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobComponents/pickUp&DropOff";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import AppModal from "../../core/modal";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../../core/form/asyncAutocompleteComponent";
import CustomSwitch from "../../core/form/customSwitch";
import TextInputComponent from "../../core/form/textInputComponent";
import { getJobBoardEditRoute, getJobOrderEditRoute } from "../../../helpers/global";
import {
    calculateSecondsFromMinutes,
    getWeekDaysBetweenDates,
    INVALID_DATE,
    INVALID_DATE_FORMAT_MESSAGE,
    isValidDate,
} from "../../../helpers/date";
import PoLineItemForm, { CREATE_POLI_FORM } from "../../poLineItems/poLineItemForm";
import TocIcon from "@material-ui/icons/Toc";
import {
    IS_ADMIN_USER,
    IS_HAULER_USER,
    JO_STATUS_COMPLETED_ID,
    PO_TYPE_TIMBER,
    SITES_TYPE_PRIVATE,
    UNIT_OF_MEASURE_LOAD,
} from "../../../constants/maps";
import HiddenInput from "../../core/form/hiddenInput";
import JobOrderMoreActions from "../jobComponents/moreActions";
import JobContactSelector, { JOB_CONTACT_NAME } from "../../global/jobContactSelector";
import { Validation, VALIDATION_ERRORS } from "../../../helpers/validation";
import {
    createJobOrder,
    getJobBoardJobOrderJoli,
    getJobOrderTrucksForecast,
    partialJobOrderUpdateById,
    updateJobOrderById,
} from "../../../dataServers/jobOrder";
import ErrorNotification from "../../core/notification";
import ActionNotification from "../../core/actionNotification";
import JolisTableWrapper from "./jolis/jolisTableWraper";
import { dispatchJobBoardRoute, ROUTE_JOBS, ROUTE_PROJECTS } from "../../../routes/globalRoutes";
import DateTimePicker from "../../core/form/dateTimePicker";
import WeekDays, { DEFAULT_DAYS } from "../../core/form/weekDays";
import TruckItTimePicker from "../../core/form/timePicker";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import { TRUCKS_TABLE_STYLES } from "../../../styles/reusableStyles";
import { getPhaseCodeFromAdditionalFields, timberAdditionalFields } from "../../../helpers/projects";
import { getPOLineItems } from "../../../dataServers/poLineItems";
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
    SERVER_DATE_FORMAT,
} from "../../../constants/global";
import QuantityWithUnlimited from "../../core/quantityWithUnlimited";
import {
    convertDateToUserTimeZone,
    getDateObject,
    getIfIsDropOffMidpoint,
    getIfIsPickUpMidpoint,
    jobOrderHasHaulers,
} from "../../../helpers/jobOrders";
import { getProjectById } from "../../../dataServers/projects";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import {
    changeJoDate,
    changeRequestedFleetListDate,
    resetHaulerParam,
    resetJobOrderParams,
    updateActiveDispatchedTruckOrder,
    updateActiveHaulerOrder,
    updateIgnoreWebsocketJoData,
    updateJobOrder,
    updateMyFleetListForAdmin,
    updateRedirectJobId,
} from "../../../actions/jobOrders";
import { setCurrentProject } from "../../../actions/projects";
import ConfirmationModal from "../../core/confirmationModal";
import TermsSelector from "../../global/termsSelector";
import PoLineItemLabel from "../../global/PoLineItemLabel";
import PavertrackersSelector, {
    IS_CAN_SHOW_PAVERTRAKERS_SELECTOR,
    PAVERTRACKERS_NAME,
} from "../../global/pavertrackersSelector";
import { GET_ALL_HAULERS_TRUCKS_COUNT } from "../jobBoard/joliTable";
import { JOLI_FIELD_ID, SPECIFY_TRUCKS_PER_HAULER } from "../constants";
import { timeFromSeconds } from "../../../helpers/jobOrders.js";
import { NEW_JOBS_SPLIT_LABEL, RESET_TRUCKS_CONFIRM_MESSAGE } from "../../../constants/strings";
import { getSiteByIdWithoutAlertZones } from "../../../dataServers/sites";
import { selectIfIsUsesSmartDispatch, selectMeasureSystem, selectUsesSplitJob } from "../../../selectors/index";
import SmartDispatchSettingsContainer, {
    JOB_ORDER_END_DATE,
    SMART_DISPATCH_JOB_MODE,
} from "./SmartDispatchSettingsContainer";
import JobOrderStatusPill from "./jobOrderStatusPill";
import PayloadLink from "./PayloadLink";
import CopyProjectNotesButton from "./copyProjectNotesButton";
import { getAllSubCompanies } from "../../../actions/subCompanies";
import MidSite, { MID_SITE_NAME } from "./midSite";
import LinkedJobFormLink from "./linkedJobFormLink";
import SitesDistance from "./sitesDistance";

const useStyles = makeStyles((theme) => ({
    generalPadding: {
        padding: "20px 27px",
    },
    generalSidePadding: {
        padding: "0 27px",
    },
    joSwitchers: {
        marginBottom: 10,

        "& > *": {
            marginBottom: 5,
            marginRight: 5,
        },
    },
    pickUp: {
        "& a": {
            fontSize: 16,
        },
    },
    darkColor: {
        color: theme.palette.secondary.dark,
    },
    poSelection: {
        "& .___value-container .hide-when-closed": {
            display: "none",
        },
        "& .___option": {
            padding: "10px",
        },
    },
    calcContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    responsibleCalcContainer: {
        [theme.breakpoints.down(1550)]: {
            width: "100%",
        },
    },
    infoText: {
        display: "flex",
        fontSize: 16,
        alignItems: "center",
        color: theme.palette.secondary.dark,
        fontWeight: theme.typography.fontWeightMedium,
    },
    timePicker: {
        "& .picker-wrap": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
    },
    dateTimePicker: {
        marginLeft: 10,
    },
    weekDaysError: { position: "absolute" },
    weekDaysStyles: {
        [theme.breakpoints.down(1490)]: {
            marginTop: 10,
        },
    },
    responsiveTemplateSwitch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down(1550)]: {
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
        },
    },
    responsiveWithActionsTemplateSwitch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down(1815)]: {
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
        },
    },
    templateSwitch: {
        display: "flex",
        justifyContent: "flex-end",
    },
    responsibleTemplate: {
        [theme.breakpoints.down(1490)]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    disabledTemplate: {
        cursor: "not-allowed",
    },
    jobOrderDate: {
        display: "flex",
        justifyContent: "flex-end",
    },
    dateContainer: {
        marginBottom: 10,
    },
    toggleGroupElement: {
        margin: "2px",
    },
    toggleGroupWrapper: {
        width: "fit-content",
    },
    requestedDataContainer: {
        display: "flex",
        fontSize: "1.5em",
        padding: "16px 16px 0",
    },
    midSiteSection: {
        marginBottom: 14,
    },
    midSiteLabel: {
        textAlign: "center",
        color: theme.palette.secondary.main,
        fontSize: 12,
    },
    distanceContainer: {
        marginBottom: "10px",
    },
}));

const MILLISECONDS_IN_MINUTE = 60000;

export const findSameHaulerWithSameTime = (trucks, truck, truckIndex) => {
    return trucks.find((i, index) => {
        return (
            moment(truck.startDate).isSame(moment(i.startDate)) &&
            (!truck.isMyFleetTruck ? i.id === truck.id : i.truckId === truck.truckId) &&
            truckIndex !== index
        );
    });
};

const validate = (values) => {
    const errors = {};

    const {
        totalQuantity,
        unlimited,
        [JOLI_FIELD_ID]: joliItems,
        poLineItem,
        unitOfMeasure,
        totalRequestedTrucksNumber,
        [START_DATE_NAME]: startDate,
        joStartTime,
        enabled,
        weekDays,
        templateStartDate,
        templateEndDate,
        [DROP_OF_SITE_NAME]: dropOffSites,
        [MID_SITE_NAME]: midSite,
        isSplit,
    } = values;
    const unitOfMeasureId = unitOfMeasure?.id || unitOfMeasure;
    if (!templateStartDate) errors.templateStartDate = "Required";
    if (!templateEndDate) errors.templateEndDate = "Required";
    if (!isValidDate(startDate) && !isSplit) errors.startDate = INVALID_DATE_FORMAT_MESSAGE;
    if (!isValidDate(startDate) && isSplit && startDate !== null) errors.startDate = INVALID_DATE_FORMAT_MESSAGE;

    if (weekDays && !weekDays.length) errors.weekDays = "Required";
    if (joStartTime && !enabled) errors[START_DATE_NAME] = "Required";

    if (!unlimited && unitOfMeasureId === UNIT_OF_MEASURE_LOAD && totalQuantity && !_.isEmpty(joliItems)) {
        const joliQtySum = _.cloneDeep(joliItems)
            .filter((i) => i && i.startDate)
            .reduce((prev, next) => prev + +next.quantity, 0);

        if (+joliQtySum > +totalQuantity) {
            errors.totalQuantityError = "Job Order quantity value is less then trucks sum of quantity";
        }
    }

    if (!unlimited && unitOfMeasureId === UNIT_OF_MEASURE_LOAD && totalQuantity && !_.isEmpty(joliItems)) {
        const jolis = _.cloneDeep(joliItems).filter((i) => i && i.startDate);
        const joliHaulersRequestedSum = jolis.reduce((prev, next) => {
            return prev + GET_ALL_HAULERS_TRUCKS_COUNT(next.haulers);
        }, 0);
        const joliMyFleetTruck = jolis.reduce((prev, next) => {
            return prev + next.trucks.length;
        }, 0);

        if (+joliHaulersRequestedSum + joliMyFleetTruck > +totalQuantity) {
            errors.totalQuantityError = "Job Order quantity value is less than trucks sum of quantity";
        }
    }

    if (totalQuantity && totalQuantity > poLineItem.availableQuantity) {
        errors.totalQuantityError = (
            <span>
                Job quantity can't be more <br />
                then quantity of selected P.O.L.I. <br />
                P.O.L.I qty is {poLineItem.availableQuantity}
            </span>
        );
    }

    if (!_.isEmpty(joliItems)) {
        const rowsArrayErrors = [];
        const validateTrucks = (trucks = []) => {
            const trucksArrayErrors = [];

            trucks &&
                trucks.forEach((truck, truckIndex) => {
                    const trucksErrors = {};

                    if (!truck) {
                        return false;
                    }

                    if (!truck.id || !truck.truckId) {
                        return false;
                    }

                    const sameHaulerWithSameTime = findSameHaulerWithSameTime(trucks, truck, truckIndex);

                    if (sameHaulerWithSameTime) {
                        errors.formError = VALIDATION_ERRORS.sameTruckTime;
                    }

                    trucksArrayErrors[truckIndex] = trucksErrors;
                });

            return trucksArrayErrors;
        };

        joliItems.forEach((joli, joliIndex) => {
            if (_.isEmpty(joli.trucks) && _.isEmpty(joli.haulers)) {
                return false;
            }

            const trucksArrayErrors = !_.isEmpty(joli.trucks) ? validateTrucks(joli.trucks) : [];
            const haulersArrayErrors = !_.isEmpty(joli.haulers) ? validateTrucks(joli.haulers) : [];
            const rowErrors = {};
            const anotherJoliWithTruckForSameTime = joliItems.find((j, jIndex) => {
                if (j && jIndex !== joliIndex) {
                    joli &&
                        joli.haulers &&
                        joli.haulers.forEach((hauler) => {
                            const same = j.haulers.find((i) => moment(i.startDate).isSame(moment(hauler.startDate)));

                            return same;
                        });
                }

                return false;
            });

            if (anotherJoliWithTruckForSameTime) {
                errors.formError = "You should not have same hauler/truck for equal time";
            }

            rowErrors.trucks = trucksArrayErrors;
            rowErrors.haulers = haulersArrayErrors;

            rowsArrayErrors[joliIndex] = rowErrors;
        });

        errors[JOLI_FIELD_ID] = rowsArrayErrors;

        if (totalRequestedTrucksNumber && joliItems.length > totalRequestedTrucksNumber) {
            errors.totalRequestedTrucksNumber = <span>Trucks limit is exceeded</span>;
        }
    }

    if (midSite) {
        if (dropOffSites && dropOffSites.length > 1) {
            errors[DROP_OF_SITE_NAME] = "Only 1 drop-off must be specified";
        }

        const allTrucks = [];
        if (!_.isEmpty(joliItems)) {
            joliItems.forEach((joli) => {
                allTrucks.push(...joli.trucks);
            });
        }
        if (allTrucks.length > 1) {
            errors.formError = VALIDATION_ERRORS.splitJobTrucksAmount;
        }
    }

    return errors;
};

export const jobOrderDisabled = (jobOrder = {}, account = {}) => {
    if (jobOrder?.isPastJob) {
        return false;
    }

    if (jobOrder && jobOrder.copySchedule && !jobOrder.copySchedule.enabled && !jobOrder.isCopy) {
        return true;
    }

    if (jobOrder?.copySchedule?.enabled) {
        return false;
    }

    return (
        jobOrder.status === JO_STATUS_COMPLETED_ID ||
        (IS_HAULER_USER(account.role) && jobOrder.id ? !jobOrder.iAmOwner : false)
    );
};

const JobOrderForm = (props) => {
    const classes = useStyles();
    const trucksTableStyles = TRUCKS_TABLE_STYLES();
    const [state, setState] = useState({
        isLoading: false,
        openChangeDateConfirmModal: false,
        confirmQuestion: undefined,
        newJoDate: undefined,
    });
    const [trucksForecast, setTrucksForecast] = useState();
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState({});
    const [showCreatePoliModal, setShowCreatePoliModal] = useState(false);
    const [success, setSuccess] = useState(null);
    const [prevForecastValues, setPrevForecastValues] = useState(props.initialValues || {});
    const [isCalculated, setIsCalculated] = useState(false);
    const [openResetJoliConfirmModal, setOpenResetJoliConfirmModal] = useState(false);

    const { openChangeDateConfirmModal, isLoading } = state;
    const {
        jobOrder,
        cancelFormEditHandler,
        pristine,
        reset,
        handleSubmit,
        form,
        dispatch,
        account,
        updateMyFleetList,
        history,
        initialValues,
        initialValues: { [PICK_UP_SITE_NAME]: initialPickUpSite, [DROP_OF_SITE_NAME]: initialDropOffSites },
        setActive,
        updateJoDate,
        setCurrentProject,
        formValues: {
            purchaseOrder,
            [JOLI_FIELD_ID]: joliItems,
            poLineItem,
            unitOfMeasure,
            poType,
            [START_DATE_NAME]: startDate,
            projectId,
            joStartTime,
            dynamicStartDate,
            enabled,
            hasCopySchedule,
            isCopy,
            smartDispatch,
            isPastJob,
            templateStartDate,
            templateEndDate,
            weekdays,
            [SMART_DISPATCH_JOB_MODE]: jobMode,
            phaseCodeQuantity,
            splitDispatch,
            [PICK_UP_SITE_NAME]: pickUpSite,
            [DROP_OF_SITE_NAME]: dropOffSites,
        },
        forecastValues,
        currentProject,
        isStartDateByDefault,
        isStartCopyJobNextDay,
        isUsesSmartDispatch,
        usesSplitJob,
        measureSystem,
    } = props;
    const { id, requestNotes, previousJobOrderId, nextJobOrderId, isSplit, numberOfTrucksRequested, distance } =
        jobOrder;
    const isDropOffMidpoint = getIfIsDropOffMidpoint(jobOrder);
    const isPickUpMidpoint = getIfIsPickUpMidpoint(jobOrder);
    const hasJoli = !_.isEmpty(joliItems);
    const linkedJobId = previousJobOrderId || nextJobOrderId;
    const allFieldsExist = !_.isEmpty(poLineItem);
    const isJobBoardDispatch = history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM);
    const showPavertrakers = IS_CAN_SHOW_PAVERTRAKERS_SELECTOR(poType);
    const payloadId = poLineItem?.value || poLineItem?.id || poLineItem;
    const disableStartDateField = !!(
        isJobBoardDispatch &&
        joliItems &&
        joliItems.find((i) => {
            const hasAssignedTrucks = i.haulers && i.haulers.find((h) => !_.isEmpty(h.trucks));
            const hasTickets = i.truck && i.trucks.find((t) => t.hasTickets);

            return hasAssignedTrucks || hasTickets;
        })
    );
    const getStartDateValidators = () => {
        if (isSplit && previousJobOrderId && !hasJoli) {
            return [];
        }

        return [Validation.required];
    };
    const projectNotes = currentProject?.notes;
    const notUndefined = (param) => typeof param !== "undefined";
    const disable = jobOrderDisabled(jobOrder, account);

    const pastJobOrderDateSelected = startDate && moment(startDate).isBefore(moment(), "days");

    const showParentLoader = (show) => {
        setState({
            ...state,
            isLoading: show,
        });
    };
    const setTemplateJob = () => dispatch(change(form, "enabled", !enabled));
    const updateJobOrderData = (jobOrder) => {
        setState({
            ...state,
            isLoading: true,
        });

        setActive(jobOrder)
            .then(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const setStartDateByDefault = () => {
        if (notUndefined(isStartDateByDefault)) {
            !isStartDateByDefault && dispatch(change(form, "startDate", null));
        }
        dispatch(change(form, "updatedStartDate", startDate));
        dispatch(change(form, "isAppearNewJobOrderForm", true));
    };

    const callConfirmationToCancel = () => {
        if (pristine) {
            reset();
            updateJoDate(null);
            dispatch(changeRequestedFleetListDate(undefined));
            cancelFormEditHandler && cancelFormEditHandler();
        } else {
            setState({
                ...state,
                openChangeDateConfirmModal: true,
            });
        }
    };
    const cancelFormEdit = (needUpdateJobList = true) => {
        reset();
        dispatch(destroy("radiusInput"));
        updateJoDate(null);
        dispatch(resetJobOrderParams());
        dispatch(resetHaulerParam());
        dispatch(changeRequestedFleetListDate(undefined));
        cancelFormEditHandler && cancelFormEditHandler(needUpdateJobList);
    };

    const handleDelayTruckTimes = (value) => {
        const joDate = {
            year: moment(dynamicStartDate || moment()).get("year"),
            month: moment(dynamicStartDate || moment()).get("month"),
            date: moment(dynamicStartDate || moment()).get("date"),
        };

        // This expression copied info about date except of hours to calculate
        // diff between hours (minutes) skipping date diff
        const minutesDelay = moment.duration(value?.clone().set(joDate).diff(dynamicStartDate));
        const delay = Math.round(+minutesDelay / MILLISECONDS_IN_MINUTE);
        setState({
            ...state,
            openChangeDateConfirmModal: delay,
            delay: delay,
            confirmQuestion: "Do you want truck times to be updated?",
        });
        delay && dispatch(change(form, "dynamicStartDate", value));
        joStartTime && dispatch(change(form, "joStartTime", null));

        if (!delay) {
            jobOrderDateChanged(value, null);
        }
    };

    const changeDateNoHandler = () => {
        if (state.confirmQuestion) {
            jobOrderDateChanged(startDate, null);
        }
        setState({
            ...state,
            openChangeDateConfirmModal: false,
            confirmQuestion: undefined,
            delay: undefined,
        });
    };

    const changeDateYesHandler = () => {
        if (state.confirmQuestion) {
            jobOrderDateChanged(startDate, state.delay);
        } else {
            cancelFormEdit();
        }
        setState({
            ...state,
            openChangeDateConfirmModal: false,
            confirmQuestion: undefined,
            delay: undefined,
        });
    };

    const resetFieldsForSmartDispatchJob = () => {
        dispatch(change(form, "totalRequestedTrucksNumber", null));
        dispatch(change(form, "unlimited", null));
        dispatch(change(form, "isOvernight", false));
        dispatch(change(form, "enabled", false));
        dispatch(change(form, JOLI_FIELD_ID, []));
    };

    const resetJoliYesHandler = async () => {
        if (_.isEmpty(joliItems)) {
            resetFieldsForSmartDispatchJob();
            setOpenResetJoliConfirmModal(false);

            return;
        }
        getJobBoardJobOrderJoli(id)
            .then((response) => {
                setState({
                    ...state,
                    isLoading: true,
                });
                const joliData = response.data;
                const items = joliData.map((joli) => {
                    return {
                        id: joli.id,
                        trucksToRemove: joli.trucks.map((truck) => truck.truckId),
                        haulersToRemove: joli.haulers.map((hauler) => hauler.id),
                    };
                });
                dispatch(updateIgnoreWebsocketJoData(true));

                partialJobOrderUpdateById(id, { items })
                    .then(() => {
                        resetFieldsForSmartDispatchJob();
                    })
                    .catch((e) => {
                        setError([PROCESS_SERVER_ERROR(e)]);
                    })
                    .finally(() => {
                        setOpenResetJoliConfirmModal(false);
                        setState({
                            ...state,
                            isLoading: false,
                        });
                    });
            })
            .catch((e) => {
                setError([PROCESS_SERVER_ERROR(e)]);
            });
    };

    const resetJoliNoHandler = () => {
        dispatch(change(form, "smartDispatch", false));
        setOpenResetJoliConfirmModal(false);
    };

    const getTrailersForItem = (values, trucks) => {
        const addTrailers = Object.keys(values)
            .filter((fieldName) => fieldName.includes("-trailers") && !!values[fieldName])
            .filter((fieldName) => {
                const truckId = +fieldName.split("-")[0];
                return trucks.includes(truckId);
            })
            .map((name) => {
                const truckId = name.split("-")[0];
                const trailers = values[name];
                return trailers.map((t) => ({ ...t, truckId }));
            })
            .flat()
            .map((trailer) => ({
                trailer: +trailer.id || +trailer.trailerId,
                truck: +trailer.truckId,
            }));

        if (addTrailers && addTrailers.length) {
            return addTrailers;
        }

        return null;
    };

    const onSubmit = (values) => {
        const {
            allowToNotify,
            autoAccept,
            allowUnfilledStart,
            dropOffNotes,
            [DROP_OF_SITE_NAME]: dropOffSites,
            extRef,
            isOvernight,
            jobContactId,
            notes,
            pickUpNotes,
            [PICK_UP_SITE_NAME]: pickUpSite,
            poLineItem,
            regionId,
            terms,
            startDate,
            totalQuantity,
            unlimited,
            [JOLI_FIELD_ID]: joliItems,
            id,
            costCode,
            totalRequestedTrucksNumber,
            [SPECIFY_TRUCKS_PER_HAULER]: specifyTrucksPerHauler,
            [PAVERTRACKERS_NAME]: pavertrackers,
            smartDispatch,
            weekdays,
            enabled,
            templateEndDate,
            templateStartDate,
            [JOB_ORDER_END_DATE]: endDate,
            [SMART_DISPATCH_JOB_MODE]: jobMode,
            [MID_SITE_NAME]: midSite,
        } = values;
        const templateDate = enabled && moment().set({ h: moment(startDate).hours(), m: moment(startDate).minutes() });
        const correctStartDate = convertDateToUserTimeZone(templateDate || startDate, account.timezone);

        const startTimeIsInvalid = !isValidDate(startDate) || !startDate;
        const joDate = getDateObject(startDate);
        const momentEndDate = moment(endDate).set(joDate);
        const correctEndDate =
            smartDispatch && endDate && isValidDate(endDate)
                ? convertDateToUserTimeZone(momentEndDate.format(), account.timezone)
                : null;

        const generatedDropOffSites = !_.isEmpty(dropOffSites) ? dropOffSites.map((i) => i.value || i.id) : [];
        const createData = {
            allowToNotify: allowToNotify || false,
            allowUnfilledStart: allowUnfilledStart || false,
            autoAccept: autoAccept || false,
            dropOffNotes,
            [PAVERTRACKERS_NAME]: pavertrackers
                ? pavertrackers.map((i) => i.label)
                : pavertrackers === null
                ? []
                : undefined,
            dropOffSites: generatedDropOffSites,
            extRef,
            isOvernight: isOvernight || false,
            smartDispatch: smartDispatch || false,
            jobMode: smartDispatch ? jobMode : account.companyProfile.jobMode,
            jobContact: {
                isCustomersContact: jobContactId && (jobContactId.isCustomersContact || false),
                jobContactId: jobContactId ? jobContactId.id || jobContactId.value || jobContactId : null,
            },
            totalRequestedTrucksNumber: totalRequestedTrucksNumber ? +totalRequestedTrucksNumber : null,
            notes: notes || "",
            pickUpNotes,
            pickUpSite: pickUpSite ? pickUpSite.value || pickUpSite.id : null,
            poLineItemId: poLineItem.id || poLineItem.value || poLineItem,
            regionId,
            startDate: isSplit && startTimeIsInvalid ? null : correctStartDate,
            [JOB_ORDER_END_DATE]: correctEndDate,
            totalQuantity: totalQuantity ? +totalQuantity : 0,
            unlimited: unlimited || false,
            costCode: costCode || "",
            items: joliItems
                ? joliItems
                      .filter((i) => !(_.isEmpty(i.trucks) && _.isEmpty(i.haulers)))
                      .map((i) => {
                          const haulers = i.haulers.map((hauler) => {
                              return {
                                  companyId: hauler.id,
                                  needTrucksCount: specifyTrucksPerHauler ? hauler.needTrucksCount : 0,
                                  trucks: hauler.trucks ? hauler.trucks.map((t) => t.id || t.truckId) : [],
                                  allowSubhaulers: hauler.allowSubhaulers,
                                  haulerRate: hauler.haulerRate || null,
                              };
                          });

                          const truckIds = i.trucks.map((i) => i.truckId);

                          const item = {
                              autoApprove: false,
                              haulers,
                              quantity:
                                  unitOfMeasure === UNIT_OF_MEASURE_LOAD
                                      ? unlimited
                                          ? 0
                                          : i.quantity || 1
                                      : unlimited
                                      ? 0
                                      : totalQuantity / joliItems.length,
                              startDate: convertDateToUserTimeZone(i.startDate, account.timezone),
                              terms: terms ? terms.id || terms.value : 1,
                              trucks: truckIds,
                              notAddressedTrucksCount: specifyTrucksPerHauler ? 0 : i.notAddressedTrucksCount,
                          };

                          const addTrailers = getTrailersForItem(values, truckIds);
                          const removeTrailers = getTrailersForItem(props.initValues, truckIds);

                          if (addTrailers) {
                              item.addTrailers = addTrailers;
                          }
                          if (!isCopy && removeTrailers) {
                              item.removeTrailers = removeTrailers;
                          }
                          if (i.id) {
                              item.id = i.id;
                          }

                          return item;
                      })
                : [],
        };

        if (values.phaseCode) {
            createData.additionalFields = { ["phase_code"]: { [values.phaseCode]: values.phaseCodeQuantity || null } };
        } else {
            createData.additionalFields = {};
        }

        if (enabled) {
            createData.copySchedule = {
                enabled,
                endDate: templateEndDate && convertDateToUserTimeZone(templateEndDate, account.timezone),
                startDate: templateStartDate && convertDateToUserTimeZone(templateStartDate, account.timezone),
                weekdays,
            };
        }

        if (smartDispatch) {
            createData.loadTimeSec = calculateSecondsFromMinutes(values.loadTime);
            createData.unloadTimeSec = calculateSecondsFromMinutes(values.unloadTime);
        }

        if (splitDispatch) {
            createData.isSplit = true;
            createData.dropOffSites = [midSite.value];
        }

        if (isSplit) {
            createData.isSplit = isSplit;
        }

        const request = !values.id ? createJobOrder(createData) : updateJobOrderById(id, createData);

        setState({
            ...state,
            isLoading: true,
        });
        setError(null);

        return request
            .then(async ({ data }) => {
                if (splitDispatch) {
                    try {
                        const newJobCreateData = {
                            ...createData,
                            previousJobOrderId: data.id,
                            pickUpSite: midSite.value,
                            dropOffSites: generatedDropOffSites,
                            items: [],
                            startDate: null,
                        };
                        const response = await createJobOrder(newJobCreateData);
                        dispatch(updateRedirectJobId(response?.data?.id));
                    } catch (error) {
                        setError([PROCESS_SERVER_ERROR(error)]);
                    }
                }
                setState({
                    ...state,
                    isLoading: false,
                });
                const isUpdateJobOrdersList = enabled
                    ? true
                    : history.location.pathname.includes(dispatchJobBoardRoute) || isCopy;
                !history.location.pathname.includes(dispatchJobBoardRoute) && reset();
                cancelFormEditHandler && cancelFormEditHandler(isUpdateJobOrdersList);
                dispatch(destroy("radiusInput"));
                dispatch(resetJobOrderParams());
                dispatch(resetHaulerParam());
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                if (error && error.projectId) {
                    setWarning({ message: error.message, projectId: error.projectId });
                } else {
                    setError([PROCESS_SERVER_ERROR(error, "Job order was not saved")]);
                }
            });
    };

    const loadPoliOptions = (inputValue, { params = {}, loadedCount }) => {
        return getPOLineItems(projectId, { ...params }).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: <PoLineItemLabel item={{ ...i }} />,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const showCreateNewPoliModal = () => {
        setShowCreatePoliModal(true);
    };

    const poliWasChanged = (data) => {
        const { unitOfMeasure: newUnitOfMeasure, pickUpSite, dropOffSites, purchaseOrder, additionalFields } = data;
        setStartDateByDefault();
        dispatch(change(form, "unitOfMeasure", newUnitOfMeasure));
        dispatch(change(form, "purchaseOrder", purchaseOrder));
        if (pickUpSite) {
            getSiteByIdWithoutAlertZones(pickUpSite.id)
                .then((r) => {
                    const site = r.data;
                    pickUpSite.latitude = site?.latitude;
                    pickUpSite.longitude = site?.longitude;
                })
                .finally(() => {
                    dispatch(
                        change(form, PICK_UP_SITE_NAME, {
                            value: pickUpSite.id,
                            label: pickUpSite.name,
                            latitude: pickUpSite.latitude,
                            longitude: pickUpSite.longitude,
                        }),
                    );
                });
        }
        additionalFields &&
            additionalFields.cost_code &&
            dispatch(change(form, "costCode", additionalFields.cost_code));

        const phaseCode = getPhaseCodeFromAdditionalFields(additionalFields);
        dispatch(change(form, "phaseCode", phaseCode?.phaseCode));
        dispatch(change(form, "phaseCodeQuantity", phaseCode?.phaseCodeQuantity));

        dropOffSites &&
            dispatch(
                change(
                    form,
                    DROP_OF_SITE_NAME,
                    dropOffSites.map((i) => ({
                        value: i.id,
                        label: i.name,
                    })),
                ),
            );

        setState({
            ...state,
            isLoading: true,
        });

        updateJoDate(initialValues[START_DATE_NAME]);

        getProjectById(projectId)
            .then(({ data: { allowToNotify, autoAccept, jobContact } }) => {
                dispatch(change(form, "autoAccept", autoAccept));
                dispatch(change(form, "allowToNotify", allowToNotify));
                jobContact &&
                    dispatch(
                        change(form, JOB_CONTACT_NAME, {
                            value: jobOrder.jobContact.id,
                            label: jobOrder.jobContact.fullName,
                        }),
                    );
                setState({
                    ...state,
                    isLoading: false,
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const changeTrucksQty = (jobQtyValue) => {
        if (!_.isEmpty(joliItems)) {
            joliItems.forEach((joli, joliId) => {
                if (!joli.quantity) {
                    const updatedQuantity = jobQtyValue / joliItems.length;
                    dispatch(change(form, `${JOLI_FIELD_ID}[${joliId}].quantity`, updatedQuantity));
                }
            });
        }
    };

    const resetTrucksQty = (checked) => {
        if (checked) {
            dispatch(
                change(
                    form,
                    JOLI_FIELD_ID,
                    _.cloneDeep(joliItems).map((joli) => ({
                        ...joli,
                        quantity: null,
                    })),
                ),
            );
        }
    };

    const onMessageClose = () => {
        setError(null);
        setSuccess(null);
    };

    const updateHaulersNumberOfCount = (checked) => {
        if (checked) {
            dispatch(
                change(
                    form,
                    JOLI_FIELD_ID,
                    _.cloneDeep(joliItems).map((joli) => {
                        return {
                            ...joli,
                            haulers: joli.haulers.map((hauler) => ({
                                ...hauler,
                                needTrucksCount: hauler.needTrucksCount || 1,
                            })),
                        };
                    }),
                ),
            );
        } else {
            dispatch(
                change(
                    form,
                    JOLI_FIELD_ID,
                    _.cloneDeep(joliItems).map((joli) => {
                        return {
                            ...joli,
                            notAddressedTrucksCount: joli.notAddressedTrucksCount || 1,
                            haulers: joli.haulers.map((hauler) => ({
                                ...hauler,
                                allowSubhaulers: false,
                            })),
                        };
                    }),
                ),
            );
        }
    };

    const jobOrderDateChanged = (newJoDate, minutesDiff = 0) => {
        const joDate = {
            year: moment(newJoDate).get("year"),
            month: moment(newJoDate).get("month"),
            date: moment(newJoDate).get("date"),
        };

        const newJolis =
            joliItems &&
            _.cloneDeep(joliItems).map((joli) => {
                const processInstance = (inst) => {
                    const startDate = moment(inst.startDate || moment(newJoDate)).set(joDate);
                    inst.startDate = minutesDiff ? _.cloneDeep(startDate).subtract(-minutesDiff, "minutes") : startDate;

                    return inst;
                };
                joli.startDate = moment(joli.startDate || moment(newJoDate)).set(joDate);

                joli.trucks = joli.trucks
                    ? joli.trucks.map((truck) => {
                          return processInstance(truck);
                      })
                    : [];

                joli.haulers = joli.haulers
                    ? joli.haulers.map((truck) => {
                          return processInstance(truck);
                      })
                    : [];

                return processInstance(joli);
            });

        updateJoDate(newJoDate);
        newJolis && dispatch(change(form, JOLI_FIELD_ID, newJolis));
    };
    const poliCreationHandler = (data) => {
        if (data) {
            dispatch(
                change(form, "poLineItem", {
                    value: data.id,
                    label: <PoLineItemLabel item={data} />,
                }),
            );
            poliWasChanged(data);
        }
    };
    const setStartDate = (date) => {
        const newDate = {
            year: moment(date || moment()).get("year"),
            month: moment(date || moment()).get("month"),
            date: moment(date || moment()).get("date"),
        };
        if (moment(startDate).format(SERVER_DATE_FORMAT) !== date.format(SERVER_DATE_FORMAT)) {
            const joDate = moment(startDate || moment()).set(newDate);

            dispatch(change(form, "updatedStartDate", joDate));
            dispatch(change(form, START_DATE_NAME, joDate));
            jobOrderDateChanged(joDate);
            updateJoDate(joDate);
        }

        dispatch(change(form, "joStartTime", null));
    };

    const loadTruckForecast = (inititValues, forecastValues) => {
        setState({
            ...state,
            isLoading: true,
        });
        const initLocation = {
            pickUpSite: inititValues.pickUpSite,
            dropOffSites: inititValues.dropOffSites,
        };
        const currentLocation = {
            pickUpSite: forecastValues.pickUpSite,
            dropOffSites: forecastValues.dropOffSites,
        };
        const initData = {
            totalQuantity: inititValues.totalQuantity,
            unitOfMeasure: inititValues.unitOfMeasure,
        };
        const currentData = {
            totalQuantity: forecastValues.totalQuantity,
            unitOfMeasure: forecastValues.unitOfMeasure,
        };

        let params = {};

        if (jobOrder.id) {
            if (_.isEqual(initLocation, currentLocation) && _.isEqual(initData, currentData)) {
                params.jobOrder = jobOrder.id;
            } else if (!_.isEqual(initLocation, currentLocation)) {
                params = {
                    totalQuantity: forecastValues.totalQuantity,
                    pickup: forecastValues.pickUpSite,
                    dropoff: forecastValues.dropOffSites,
                    uom: forecastValues.unitOfMeasure,
                };
            } else if (_.isEqual(initLocation, currentLocation) && !_.isEqual(initData, currentData)) {
                params.jobOrder = jobOrder.id;
                if (!_.isEqual(initData.totalQuantity, currentData.totalQuantity)) {
                    params.totalQuantity = currentData.totalQuantity;
                }
                if (!_.isEqual(initData.unitOfMeasure, currentData.unitOfMeasure)) {
                    params.uom = currentData.unitOfMeasure;
                }
            }
        }

        if (!jobOrder.id) {
            params = {
                totalQuantity: forecastValues.totalQuantity,
                pickup: forecastValues.pickUpSite,
                dropoff: forecastValues.dropOffSites,
                uom: forecastValues.unitOfMeasure,
            };
        }

        getJobOrderTrucksForecast(params)
            .then((data) => {
                setTrucksForecast(data.data);
                setState({
                    ...state,
                    isLoading: false,
                });
                setIsCalculated(true);
                setPrevForecastValues({
                    totalQuantity: forecastValues.totalQuantity,
                    pickUpSite: { value: forecastValues.pickUpSite },
                    dropOffSites: [{ value: forecastValues.dropOffSites }],
                    unitOfMeasure: forecastValues.unitOfMeasure,
                });
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const renderCalculateData = () => {
        const time = trucksForecast && timeFromSeconds(trucksForecast.oneWayTime);
        const turn = trucksForecast && timeFromSeconds(trucksForecast.turnTime);
        const isResponsive =
            (jobOrderHasHaulers(joliItems) && unitOfMeasure !== UNIT_OF_MEASURE_LOAD && trucksForecast) ||
            trucksForecast;
        const isFullWidth = jobOrderHasHaulers(joliItems) && unitOfMeasure !== UNIT_OF_MEASURE_LOAD && trucksForecast;
        const isDisabled =
            !forecastValues.totalQuantity ||
            !forecastValues.pickUpSite.value ||
            !forecastValues.dropOffSites ||
            !forecastValues.dropOffSites.length ||
            !forecastValues.unitOfMeasure;
        const initialTrucksForecastValues = {
            totalQuantity: +prevForecastValues.totalQuantity,
            pickUpSite: +(prevForecastValues.pickUpSite && prevForecastValues.pickUpSite.value),
            dropOffSites: +(prevForecastValues.dropOffSites && prevForecastValues.dropOffSites[0].value),
            unitOfMeasure: +prevForecastValues.unitOfMeasure,
        };
        const trucksForecastValues = {
            totalQuantity: +forecastValues.totalQuantity,
            pickUpSite: +(forecastValues.pickUpSite && forecastValues.pickUpSite.value),
            dropOffSites: +(forecastValues.dropOffSites && forecastValues.dropOffSites[0].value),
            unitOfMeasure: +forecastValues.unitOfMeasure,
        };

        const isChanged = _.isEqual(initialTrucksForecastValues, trucksForecastValues) && isCalculated;

        if (splitDispatch) return null;

        if (+forecastValues.totalQuantity === 0 || !forecastValues.totalQuantity) return null;

        return (
            <Grid
                className={clsx(
                    classes.calcContainer,
                    isResponsive && !_.isEmpty(trucksForecast) && classes.responsibleCalcContainer,
                    isFullWidth && !_.isEmpty(trucksForecast) && "--full-width",
                )}
            >
                {!_.isEmpty(trucksForecast) && (
                    <Grid component="div" item className={classes.infoText}>
                        Calculated Turn Time: {time.trim()} / {turn.trim()}
                    </Grid>
                )}
                {!_.isEmpty(trucksForecast) && (
                    <Grid component="div" item className={clsx(classes.infoText, classes.generalSidePadding)}>
                        Optimal Trucks: {trucksForecast.optimalTrucksNum}
                    </Grid>
                )}
                {trucksForecast && _.isEmpty(trucksForecast) && (
                    <Grid component="div" item className={clsx(classes.infoText, classes.generalSidePadding)}>
                        No Data
                    </Grid>
                )}
                <SecondaryButton
                    size={"small"}
                    className={"today"}
                    disabled={isDisabled || isChanged}
                    onClick={() => loadTruckForecast(initialTrucksForecastValues, trucksForecastValues)}
                >
                    Calculate
                </SecondaryButton>
            </Grid>
        );
    };

    const renderTemplateTime = () => (
        <Field
            name={START_DATE_NAME}
            validate={[Validation.required]}
            disabled={disable}
            containerClassName={classes.timePicker}
            onChange={(date, value) => handleDelayTruckTimes(moment(value))}
            component={TruckItTimePicker}
        />
    );

    const isEnteredTemplateDates = templateStartDate && templateEndDate;

    useEffect(() => {
        if (isEnteredTemplateDates) {
            const selectedWeekDays = getWeekDaysBetweenDates(moment(templateStartDate), moment(templateEndDate)).filter(
                (dayBetweenDates) =>
                    (weekdays && weekdays.includes(dayBetweenDates)) || DEFAULT_DAYS.includes(dayBetweenDates),
            );
            dispatch(change(form, "weekdays", selectedWeekDays));
        }
    }, [templateStartDate, templateEndDate]);

    const getDisabledWeekDay = (weekDay) =>
        isEnteredTemplateDates &&
        !getWeekDaysBetweenDates(moment(templateStartDate), moment(templateEndDate)).includes(weekDay);

    const renderTemplateDatesRange = () => (
        <Box className={classes.generalSidePadding}>
            <Box display="flex" className={clsx(classes.responsibleTemplate, disable && classes.disabledTemplate)}>
                <DatesRange
                    startDateName="templateStartDate"
                    endDateName="templateEndDate"
                    form={form}
                    minDate={moment().add(1, "d")}
                    validate={[Validation.required]}
                    dateOnly={true}
                    isFormView={true}
                    disabled={disable}
                    disableTodayButton={true}
                    dateFormat={DEFAULT_DATE_FORMAT}
                />
                <Field
                    name="weekdays"
                    withDefaultDays
                    form={form}
                    component={WeekDays}
                    defaultDays={weekdays}
                    disabled={disable}
                    validate={[Validation.required]}
                    containerStyles={classes.weekDaysStyles}
                    fieldNoteStyles={classes.weekDaysError}
                    disableDay={getDisabledWeekDay}
                />
            </Box>
            <br />
        </Box>
    );

    const renderDefaultDate = () => (
        <Field
            name={START_DATE_NAME}
            onChange={(date, value) => handleDelayTruckTimes(value)}
            handleDateChange={(date, value) => dispatch(change(form, "updatedStartDate", value))}
            disabled={disableStartDateField}
            validate={getStartDateValidators()}
            placeholder="Start Date"
            dateFormat={getFormatForStartDateField()}
            component={DateTimePicker}
            className={classes.dateTimePicker}
            {...restParamsForDatePicker()}
        />
    );

    const renderTodayTommorrow = () => (
        <React.Fragment>
            <Grid item component="div">
                <SecondaryButton size={"small"} className={"today"} onClick={() => setStartDate(moment())}>
                    Today
                </SecondaryButton>
            </Grid>
            <Grid item component="div">
                <SecondaryButton
                    size={"small"}
                    style={{ marginLeft: 10 }}
                    className={"tomorrow"}
                    onClick={() => setStartDate(moment().add(1, "d"))}
                >
                    Tomorrow
                </SecondaryButton>
            </Grid>
        </React.Fragment>
    );

    const renderDisabledDate = () => (
        <Grid container alignItems="center" justify="flex-end">
            <Grid item xs={4} className={classes.templateSwitch}>
                <Field
                    name="enabled"
                    label="Template"
                    onChange={setTemplateJob}
                    type="checkbox"
                    disabled={disable || hasCopySchedule || pastJobOrderDateSelected || splitDispatch || isSplit}
                    component={CustomSwitch}
                />
            </Grid>
            <Grid item xs={8} alignItems="flex-end">
                <div className={clsx(classes.jobOrderDate, "--text-right --text-18")}>
                    {moment(startDate).format(hasCopySchedule ? DEFAULT_TIME_FORMAT : DEFAULT_DATE_TIME_FORMAT)}
                </div>
            </Grid>
        </Grid>
    );

    const renderActiveDate = () => (
        <Grid container justify={"flex-end"}>
            <Grid
                container
                className={clsx(
                    classes.responsiveTemplateSwitch,
                    id && classes.responsiveWithActionsTemplateSwitch,
                    !enabled && classes.dateContainer,
                )}
            >
                <Grid item className={classes.templateSwitch}>
                    {!splitDispatch && !isSplit && !smartDispatch && (
                        <Field
                            name="enabled"
                            label="Template"
                            onChange={setTemplateJob}
                            type="checkbox"
                            disabled={(!isCopy && hasCopySchedule) || pastJobOrderDateSelected}
                            component={CustomSwitch}
                        />
                    )}
                </Grid>
                <Grid item alignItems="flex-end">
                    <Grid className={classes.jobOrderDate}>{enabled ? renderTemplateTime() : renderDefaultDate()}</Grid>
                </Grid>
            </Grid>
            <Grid item container justify={"flex-end"}>
                {!enabled && renderTodayTommorrow()}
            </Grid>
        </Grid>
    );

    const getFormatForStartDateField = () => {
        if (!isStartCopyJobNextDay && joStartTime && notUndefined(isStartCopyJobNextDay)) return DEFAULT_TIME_FORMAT;
        if (startDate && !joStartTime) return DEFAULT_DATE_TIME_FORMAT;

        return DEFAULT_DATE_TIME_FORMAT;
    };

    const getLabelFunc = (date) => {
        const initialDateView = "Select Date " + moment(date).format(DEFAULT_TIME_FORMAT);
        const dateView = moment(date).format(DEFAULT_DATE_TIME_FORMAT);

        if (isSplit && dateView === INVALID_DATE) {
            return "";
        }

        return joStartTime ? initialDateView : dateView;
    };

    const restParamsForDatePicker = () => {
        if (!notUndefined(isStartDateByDefault)) {
            return {
                labelFunc: getLabelFunc,
                isDisabledInputProps: !!joStartTime,
            };
        }
    };

    const onSmartDispatchClick = (event, newValue) => {
        if (newValue && !id) {
            resetFieldsForSmartDispatchJob();
        }
        if (newValue && id) {
            setOpenResetJoliConfirmModal(true);
        }
    };

    const goToProject = (projectId) => {
        dispatch(setCurrentProject(null));
        history.push(ROUTE_PROJECTS.PROJECTS + `/${projectId}`, { project: { id: projectId } });
    };

    const setPickUpSiteValue = (site) => {
        dispatch(change(form, PICK_UP_SITE_NAME, site));
        dispatch(change(form, "updatedPickupSite", site?.value));
    };

    const getPhaseCodeValidators = () => {
        const validators = [Validation.noSpecialSymbols];
        if (phaseCodeQuantity) {
            validators.push(Validation.required);
        }

        return validators;
    };

    const getJobOrderFormTitle = () => {
        if (id) {
            return `${jobOrder.isSplit && !initialValues.startDate ? "Draft " : ""}Job #${id}`;
        }

        return "New Job";
    };

    useEffect(() => {
        if (!isJobBoardDispatch) {
            reset();
        }
    }, [jobOrder.projectId]);

    useEffect(() => {
        const { id, totalRequestedTrucksNumber, copyJoId } = jobOrder;

        setStartDateByDefault();

        if (id || copyJoId) {
            if (IS_ADMIN_USER(account.company.id) || account.isSuperUser) {
                updateMyFleetList(id || copyJoId);
            }

            if (id) {
                !isJobBoardDispatch ? history.push(getJobOrderEditRoute(id)) : history.push(getJobBoardEditRoute(id));
            }
        }

        if (totalRequestedTrucksNumber) {
            dispatch(change(form, "totalRequestedTrucksNumber", +totalRequestedTrucksNumber));
        }

        dispatch(change(form, "siteFilter", { id: SITES_TYPE_PRIVATE }));

        return () => {
            if (id || copyJoId) {
                (IS_ADMIN_USER(account.company.id) || account.isSuperUser) && updateMyFleetList(undefined);
            }
            updateJoDate(null);
            dispatch(changeRequestedFleetListDate(undefined));
            dispatch(resetJobOrderParams());
            dispatch(resetHaulerParam());
            dispatch(change(form, "isAppearNewJobOrderForm", false));
        };
    }, []);

    useEffect(() => {
        const isToday = moment(startDate).format(SERVER_DATE_FORMAT) !== moment().format(SERVER_DATE_FORMAT);
        if (enabled && isToday) {
            if (notUndefined(isStartCopyJobNextDay) && !isStartCopyJobNextDay) {
                const templateDate = moment().set({ h: moment(startDate).hours(), m: moment(startDate).minutes() });
                dispatch(change(form, START_DATE_NAME, templateDate));
            } else {
                const templateDate = moment().set({ h: moment(startDate).hours(), m: moment(startDate).minutes() });
                dispatch(change(form, START_DATE_NAME, templateDate));
            }
        }
    }, [enabled]);

    useEffect(() => {
        if (pastJobOrderDateSelected && !id) {
            const updatedJoliItems = _.cloneDeep(joliItems).map((joli) => {
                return {
                    ...joli,
                    haulers: joli.haulers.map((hauler) => ({
                        ...hauler,
                        allowSubhaulers: false,
                    })),
                };
            });
            dispatch(change(form, JOLI_FIELD_ID, updatedJoliItems));
            dispatch(change(form, "smartDispatch", false));
        }
    }, [startDate]);

    useEffect(() => {
        if (isCopy) {
            dispatch(updateJobOrder());
        }
    }, [isCopy]);

    useEffect(() => {
        dispatch(getAllSubCompanies());
    }, []);

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate={true}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            >
                {!_.isEmpty(jobOrder) && (
                    <div className={clsx(trucksTableStyles.jobOrderEditForm)}>
                        <Grid
                            container
                            component="div"
                            justify={"space-between"}
                            alignItems={"flex-start"}
                            className={classes.generalPadding}
                        >
                            <Grid item component="div" xs={6}>
                                <h2>
                                    <Grid
                                        container
                                        component="div"
                                        alignItems={"flex-start"}
                                        direction={id ? "column" : "row"}
                                    >
                                        <Grid item container direction="row">
                                            {getJobOrderFormTitle()}
                                            {id && (
                                                <Box sx={{ marginLeft: 5 }}>
                                                    <JobOrderStatusPill
                                                        status={jobOrder.status}
                                                        copySchedule={jobOrder.copySchedule}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                        {linkedJobId && (
                                            <LinkedJobFormLink linkedJobId={linkedJobId} setActive={setActive} />
                                        )}
                                        <Grid item style={id ? { marginTop: 5 } : { marginLeft: 5 }}>
                                            Ext. Ref &nbsp;&nbsp;
                                            <Field
                                                name="extRef"
                                                type="text"
                                                disabled={disable}
                                                className="job-order-input-field ext-ref-field"
                                                placeholder="#"
                                                validate={[Validation.noSpecialSymbols]}
                                                fullWidth={false}
                                                needShowEndAdornment={false}
                                                component={TextInputComponent}
                                            />
                                        </Grid>
                                    </Grid>
                                </h2>
                                <Field name="unitOfMeasure" component={HiddenInput} />
                                <Field name="iAmOwner" component={HiddenInput} />
                            </Grid>
                            <Grid item component="div" xs={6}>
                                {poLineItem && (
                                    <Grid container component="div" alignItems={"center"} justify={"flex-end"}>
                                        <Grid item xs={!id ? 12 : 10}>
                                            {disable || isPastJob ? renderDisabledDate() : renderActiveDate()}
                                        </Grid>
                                        {id && (
                                            <Grid item xs={2}>
                                                <JobOrderMoreActions
                                                    setActive={setActive}
                                                    jobOrder={jobOrder}
                                                    showParentLoader={showParentLoader}
                                                    deleteJOHandler={() => cancelFormEdit(true)}
                                                    updateJoCopySchedule={(id) => updateJobOrderData({ id })}
                                                    updateJoStatus={(id) => updateJobOrderData({ id })}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {((hasCopySchedule && !isCopy) || enabled) && renderTemplateDatesRange()}
                        <div className={classes.generalSidePadding}>
                            <Grid container justify={"space-between"} alignItems={"center"} spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        name="poLineItem"
                                        loadOptions={loadPoliOptions}
                                        disabled={disable}
                                        startAdornment={<TocIcon className={classes.tocIconsStyles} />}
                                        createNewOption={{
                                            label: `Add Payload`,
                                            value: CREATE_NEW_OPTION_ID,
                                        }}
                                        createNewHandler={showCreateNewPoliModal}
                                        onChange={(value) => poliWasChanged(value)}
                                        className={clsx("po-selection specific-view", classes.poSelection)}
                                        placeholder="Select Payload"
                                        component={AsyncAutocompleteComponent}
                                    />
                                    {payloadId && projectId && (
                                        <Box sx={{ position: "relative", top: "-15px" }}>
                                            <PayloadLink poLineItemId={payloadId} projectId={projectId}>
                                                Go To Payload
                                            </PayloadLink>
                                        </Box>
                                    )}
                                    <Field name="purchaseOrder" component={HiddenInput} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        {poLineItem &&
                                            (IS_ADMIN_USER(account.company.id) || jobOrder.iAmOwner) &&
                                            !smartDispatch && (
                                                <Grid item xs={5}>
                                                    <Field
                                                        name="totalRequestedTrucksNumber"
                                                        disabled={disable}
                                                        type="number"
                                                        validate={[Validation.numbersPositive]}
                                                        label="Total Trucks"
                                                        component={TextInputComponent}
                                                    />
                                                </Grid>
                                            )}
                                        {poLineItem && (
                                            <Grid item xs={smartDispatch ? 12 : 7}>
                                                <Box display="flex" flexDirection={"row"} justifyContent="center">
                                                    <div className={classes.toggleGroupWrapper}>
                                                        {!smartDispatch && (
                                                            <Field
                                                                name="isOvernight"
                                                                disabled={disable || smartDispatch}
                                                                type="checkbox"
                                                                label="Overnight"
                                                                component={CustomSwitch}
                                                                className={classes.toggleGroupElement}
                                                            />
                                                        )}
                                                        {isUsesSmartDispatch &&
                                                            !isSplit &&
                                                            !splitDispatch &&
                                                            !enabled && (
                                                                <SmartDispatchSettingsContainer
                                                                    smartDispatch={smartDispatch}
                                                                    onSmartDispatchClick={onSmartDispatchClick}
                                                                    disabled={disable || pastJobOrderDateSelected}
                                                                    jobMode={jobMode}
                                                                    form={form}
                                                                />
                                                            )}
                                                    </div>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        {poLineItem && (
                            <React.Fragment>
                                <br />
                                <Grid
                                    container
                                    alignItems={"flex-start"}
                                    className={classes.generalSidePadding}
                                    spacing={2}
                                    justify={"flex-start"}
                                >
                                    <Grid item xs={6}>
                                        <QuantityWithUnlimited
                                            name="totalQuantity"
                                            disabled={disable}
                                            disableUnlimitedButton={smartDispatch}
                                            form={form}
                                            dispatch={dispatch}
                                            setUnlimitedHandler={resetTrucksQty}
                                            qtyOnBlur={changeTrucksQty}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={showPavertrakers ? 2 : 0}>
                                            <Grid item xs={showPavertrakers ? 5 : 12}>
                                                <TermsSelector disabled={disable} />
                                            </Grid>
                                            {showPavertrakers && (
                                                <Grid item xs={7}>
                                                    <PavertrackersSelector disabled={disable} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid
                                    container
                                    alignItems={"center"}
                                    justify={"flex-start"}
                                    className={classes.generalSidePadding}
                                    spacing={2}
                                >
                                    <Grid item xs={6}>
                                        <JobContactSelector
                                            disabled={disable || currentProject?.restrictedCustomerId}
                                            additionalParams={
                                                currentProject.customer
                                                    ? { customerId: currentProject.customer.id }
                                                    : {}
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            type="text"
                                            validate={[Validation.noSpecialSymbols]}
                                            name="costCode"
                                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                            disabled={disable}
                                            label={
                                                account.companyProfile.miscFieldLabel
                                                    ? account.companyProfile.miscFieldLabel
                                                    : "Cost Code"
                                            }
                                            component={TextInputComponent}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        {poLineItem && (
                            <React.Fragment>
                                <br />
                                <Grid
                                    container
                                    alignItems={"center"}
                                    justify={"flex-end"}
                                    className={classes.generalSidePadding}
                                    spacing={2}
                                >
                                    <Grid item xs={6}>
                                        <Field
                                            type="text"
                                            validate={getPhaseCodeValidators()}
                                            name="phaseCode"
                                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                            disabled={disable}
                                            label={"Phase Code"}
                                            component={TextInputComponent}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name="phaseCodeQuantity"
                                            type="text"
                                            label="Phase Code Quantity"
                                            component={TextInputComponent}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        {poLineItem ? (
                            <React.Fragment>
                                <Divider marginBottom={0} />
                                <PickUpDropOff
                                    jobOrder={jobOrder}
                                    className={clsx(
                                        classes.pickUp,
                                        "pick-up-drop-off-section",
                                        classes.generalSidePadding,
                                    )}
                                    smallSize={true}
                                    disabled={disable}
                                    form={form}
                                    canSetSites={true}
                                    onFieldChange={setPickUpSiteValue}
                                    sitesRequestParams={{ accessType: SITES_TYPE_PRIVATE }}
                                    dropOffAdditionalValidation={splitDispatch ? [] : []}
                                    isDropOffMidpoint={isDropOffMidpoint}
                                    isPickUpMidpoint={isPickUpMidpoint}
                                />
                                <SitesDistance
                                    isLoading={isLoading}
                                    setState={setState}
                                    classes={classes}
                                    initialDropOffSites={initialDropOffSites}
                                    initialPickUpSite={initialPickUpSite}
                                    dropOffSites={dropOffSites}
                                    pickUpSite={pickUpSite}
                                    id={id}
                                    isCopy={isCopy}
                                    distance={distance}
                                    poLineItem={poLineItem}
                                />
                                {splitDispatch && (
                                    <Grid
                                        container
                                        className={clsx(classes.generalSidePadding, classes.midSiteSection)}
                                        justify="center"
                                    >
                                        <Grid item xs={6}>
                                            <MidSite
                                                form={form}
                                                sitesRequestParams={{ accessType: SITES_TYPE_PRIVATE }}
                                            />
                                            <div className={clsx(classes.midSiteLabel)}>{NEW_JOBS_SPLIT_LABEL}</div>
                                        </Grid>
                                    </Grid>
                                )}
                                {smartDispatch && (
                                    <Grid container spacing={2} className={clsx(classes.generalSidePadding)}>
                                        <Grid item xs={6}>
                                            <Field
                                                type="number"
                                                validate={[Validation.noSpecialSymbols]}
                                                name="loadTime"
                                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                                disabled={disable}
                                                label="Loading Time"
                                                endAdornment={"MINUTES"}
                                                component={TextInputComponent}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                type="number"
                                                validate={[Validation.noSpecialSymbols]}
                                                name="unloadTime"
                                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                                disabled={disable}
                                                label="Unloading Time"
                                                endAdornment={"MINUTES"}
                                                component={TextInputComponent}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <Divider marginTop={smartDispatch ? undefined : 0} />
                                <Grid
                                    container
                                    component="div"
                                    justify={"space-between"}
                                    alignItems="center"
                                    className={clsx(classes.generalSidePadding, classes.joSwitchers)}
                                >
                                    <Grid component="div" item>
                                        <Field
                                            name="allowToNotify"
                                            type="checkbox"
                                            disabled={disable}
                                            label="Notify"
                                            component={CustomSwitch}
                                        />
                                        {!isSplit && !enabled && usesSplitJob && poLineItem?.canBeSplitted && (
                                            <Field
                                                name="splitDispatch"
                                                type="checkbox"
                                                disabled={disable}
                                                label="Split Dispatch"
                                                component={CustomSwitch}
                                                containerStyle={{ marginLeft: 10 }}
                                            />
                                        )}
                                    </Grid>
                                    {jobOrderHasHaulers(joliItems) && unitOfMeasure !== UNIT_OF_MEASURE_LOAD && (
                                        <Grid component="div" item>
                                            <Field
                                                name={SPECIFY_TRUCKS_PER_HAULER}
                                                type="checkbox"
                                                disabled={disable}
                                                onChange={(e) => updateHaulersNumberOfCount(e.target.checked)}
                                                label="Trucks Per Hauler"
                                                component={CustomSwitch}
                                            />
                                        </Grid>
                                    )}
                                    {renderCalculateData()}
                                </Grid>
                                <JolisTableWrapper
                                    jobOrder={jobOrder}
                                    handleSubmit={handleSubmit}
                                    joliItems={joliItems}
                                    disable={disable}
                                    initialValues={initialValues}
                                    dispatch={dispatch}
                                    form={form}
                                    pastJobOrderDateSelected={pastJobOrderDateSelected}
                                />
                                {numberOfTrucksRequested && (
                                    <div className={classes.requestedDataContainer}>
                                        <AssignmentIcon />
                                        <div>
                                            <span className="--font-600">Requested Trucks Count:</span>{" "}
                                            {numberOfTrucksRequested}
                                        </div>
                                    </div>
                                )}
                                {requestNotes && (
                                    <div className={classes.requestedDataContainer}>
                                        <AssignmentIcon />
                                        <div>
                                            <span className="--font-600">Request Notes:</span> {requestNotes}
                                        </div>
                                    </div>
                                )}
                                <Box p={2}>
                                    <Field
                                        name="notes"
                                        rows={3}
                                        disabled={disable}
                                        label="Job notes (optional)"
                                        placeholder="Job notes (optional)"
                                        multiline={true}
                                        needShowEndAdornment={false}
                                        component={TextInputComponent}
                                    />
                                </Box>
                                {!!projectNotes && (
                                    <CopyProjectNotesButton
                                        disabled={disable}
                                        projectNotes={projectNotes}
                                        form={form}
                                    />
                                )}
                            </React.Fragment>
                        ) : (
                            <br />
                        )}
                    </div>
                )}
                {(id || allFieldsExist) && (
                    <div>
                        <br />
                        <Field name="formError" errorAlign="right" component={HiddenInput} />
                        <br />
                        <ButtonsGroup justifyContent="flex-end">
                            {!_.isEmpty(poLineItem) && (
                                <SecondaryButton onClick={callConfirmationToCancel}>Cancel</SecondaryButton>
                            )}
                            {jobOrder.status === JO_STATUS_COMPLETED_ID && (
                                <JobOrderMoreActions
                                    jobOrder={jobOrder}
                                    showParentLoader={showParentLoader}
                                    updateJoStatus={(id) => updateJobOrderData({ id })}
                                    updateJoCopySchedule={(id) => updateJobOrderData({ id })}
                                    displayAsButtons={true}
                                    print={true}
                                />
                            )}
                            {(id || allFieldsExist) && !disable && (
                                <PrimaryButton
                                    type="submit"
                                    disabled={
                                        !(jobOrder.id ? jobOrder.iAmOwner || IS_ADMIN_USER(account.company.id) : true)
                                    }
                                >
                                    Save Job
                                </PrimaryButton>
                            )}
                        </ButtonsGroup>
                        <br />
                    </div>
                )}
                {isLoading && <Loader />}
                <ActionNotification
                    message={warning.message}
                    config={{ onRedirect: () => goToProject(warning.projectId), variant: "error" }}
                />
                {error && (
                    <ErrorNotification
                        error={error}
                        config={{
                            onClose: onMessageClose,
                            onClick: onMessageClose,
                        }}
                    />
                )}
                {success && (
                    <ErrorNotification
                        error={success}
                        type={"success"}
                        config={{
                            onClose: onMessageClose,
                            onClick: onMessageClose,
                        }}
                    />
                )}
            </form>
            <AppModal
                isOpen={showCreatePoliModal}
                form={CREATE_POLI_FORM}
                modalStyles={{ width: 800 }}
                closeModal={() => setShowCreatePoliModal(false)}
            >
                <PoLineItemForm
                    closeModal={() => setShowCreatePoliModal(false)}
                    initialValues={{
                        purchaseOrder: purchaseOrder && {
                            id: purchaseOrder.id,
                            label: purchaseOrder.customerRef,
                        },
                        [START_DATE_NAME]: new Date(),
                        [END_DATE_NAME]: new Date(),
                        additionalFields:
                            account.company.poType === PO_TYPE_TIMBER &&
                            timberAdditionalFields.map((fieldName) => ({
                                name: fieldName,
                                value: null,
                            })),
                    }}
                    poliCreationHandler={poliCreationHandler}
                />
            </AppModal>
            <ConfirmationModal
                yesHandler={changeDateYesHandler}
                noHandler={changeDateNoHandler}
                question={state.confirmQuestion}
                isOpen={openChangeDateConfirmModal}
            />
            <ConfirmationModal
                yesHandler={resetJoliYesHandler}
                modalStyles={{ width: 700 }}
                isLoading={isLoading}
                question={RESET_TRUCKS_CONFIRM_MESSAGE}
                noHandler={resetJoliNoHandler}
                isOpen={openResetJoliConfirmModal}
            />
        </div>
    );
};

JobOrderForm.propTypes = {
    account: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    formValues: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    forecastValues: PropTypes.object,
    updateJoDate: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired, // pass form id form parent as it can be several JO form on the page
    cancelFormEditHandler: PropTypes.func,
    updateMyFleetList: PropTypes.func.isRequired,
    isStartDateByDefault: PropTypes.bool,
    isStartCopyJobNextDay: PropTypes.bool,
    isUsesSmartDispatch: PropTypes.bool.isRequired,
    setDispatchedTruckOrder: PropTypes.func.isRequired,
};

JobOrderForm.defaultProps = {
    formValues: {},
    currentProject: {},
};

export default withRouter(
    compose(
        reduxForm({
            validate,
            enableReinitialize: true,
            destroyOnUnmount: true,
        }),
        connect(
            (state, props) => {
                const formSelector = formValueSelector(props.form);
                const currentProject = state.projects.currentProject;
                const initValues = getFormInitialValues(props.form)(state);

                const unitOfMeasure = formValueSelector(props.form)(state, "unitOfMeasure");
                const formValues = formSelector(
                    state,
                    "purchaseOrder",
                    JOLI_FIELD_ID,
                    "poLineItem",
                    "unlimited",
                    START_DATE_NAME,
                    "poType",
                    "projectId",
                    "totalRequestedTrucksNumber",
                    "joStartTime",
                    "dynamicStartDate",
                    "enabled",
                    "templateStartDate",
                    "templateEndDate",
                    "hasCopySchedule",
                    "isCopy",
                    "smartDispatch",
                    "isPastJob",
                    "weekdays",
                    "phaseCode",
                    "phaseCodeQuantity",
                    "splitDispatch",
                    SMART_DISPATCH_JOB_MODE,
                    DROP_OF_SITE_NAME,
                    PICK_UP_SITE_NAME,
                );

                const forecastValues = formSelector(
                    state,
                    "dropOffSites",
                    "pickUpSite",
                    "totalQuantity",
                    "unitOfMeasure",
                );

                return {
                    account: state.account,
                    jobOrders: state.jobOrders.list,
                    currentProject: _.isEmpty(currentProject) ? {} : currentProject,
                    formValues: {
                        ...formValues,
                        unitOfMeasure: unitOfMeasure?.id || unitOfMeasure,
                    },
                    forecastValues: {
                        ...forecastValues,
                        unitOfMeasure: unitOfMeasure?.id || unitOfMeasure,
                    },
                    isUsesSmartDispatch: selectIfIsUsesSmartDispatch(state),
                    usesSplitJob: selectUsesSplitJob(state),
                    initValues,
                    measureSystem: selectMeasureSystem(state),
                };
            },
            (dispatch) => ({
                updateMyFleetList: (joId) => dispatch(updateMyFleetListForAdmin(joId)),
                updateJoDate: (joDate) => dispatch(changeJoDate(joDate)),
                setCurrentProject: (payload) => dispatch(setCurrentProject(payload)),
                setDispatchedTruckOrder: (order) => dispatch(updateActiveDispatchedTruckOrder(order)),
                setActiveHaulerOrder: (order) => dispatch(updateActiveHaulerOrder(order)),
            }),
        ),
    )(JobOrderForm),
);
