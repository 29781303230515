import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ErrorBoundary } from "../../core/errorBoudaries";
import JobBoardForm from "./jobBoardForm";
import { IS_ADMIN_USER, IS_CONTRACTOR_USER, IS_HAULER_USER } from "../../../constants/maps";
import { convertTimeToJsFormat, processJolisForContractor } from "../../../helpers/jobOrders";
import { getSortedByTruckNameItems } from "../../fleet/fleetList";
import { JOLI_FIELD_ID } from "../constants";

// @todo add tests here ASAP
export const prepareJobOrderValues = (jobOrder, account) => {
    let jolis = [];

    if (IS_HAULER_USER(account.role) && !jobOrder.iAmOwner) {
        jolis = jobOrder.items
            .filter((i) => i.haulers.find((i) => i.id === account.company.id))
            .map((joli) => {
                const currentHauler = joli.haulers.find((i) => i.id === account.company.id);

                return {
                    ...joli,
                    haulers: joli.haulers.filter((i) => i.id !== account.company.id),
                    id: joli.id,
                    currentHauler,
                    allowSubhaulers: !!currentHauler.allowSubhaulers,
                    trucks: currentHauler
                        ? currentHauler.trucks.map((i) => ({
                              ...i,
                              isMyFleetTruck: true,
                          })) || []
                        : [],
                    startDate: convertTimeToJsFormat(joli.startDate, account.timezone),
                    needTrucksCount: currentHauler.needTrucksCount || currentHauler.unfilledTrucksCount,
                    savedTrucks: currentHauler ? currentHauler.trucks || [] : [],
                };
            });

        jolis = getSortedByTruckNameItems(jolis);
    }

    if (IS_CONTRACTOR_USER(account.role) || IS_ADMIN_USER(account.company.id) || jobOrder.iAmOwner) {
        jolis = processJolisForContractor(jobOrder.items, account.timezone);
    }

    delete jobOrder.items;

    return {
        ...jobOrder,
        id: jobOrder.id,
        unlimited: jobOrder.unlimited,
        dropOffNotes: jobOrder.dropOffNotes || "",
        pickUpNotes: jobOrder.pickUpNotes || "",
        extRef: jobOrder.extRef || "",
        startDate: convertTimeToJsFormat(jobOrder.startDate, account.timezone),
        [JOLI_FIELD_ID]: getSortedByTruckNameItems(jolis),
    };
};

const JobBoardFormWrapper = (props) => {
    const { jobOrder, saveChangesHandler, jobFormId, showFormWarn, closeWarn } = props;

    return (
        <div>
            <ErrorBoundary>
                <JobBoardForm
                    jobOrder={jobOrder}
                    showFormWarn={showFormWarn}
                    closeWarn={closeWarn}
                    form={jobFormId}
                    saveChangesHandler={saveChangesHandler}
                    initialValues={jobOrder}
                />
            </ErrorBoundary>
        </div>
    );
};

JobBoardFormWrapper.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    jobFormId: PropTypes.string.isRequired,
    saveChangesHandler: PropTypes.func,
    closeWarn: PropTypes.func.isRequired,
    showFormWarn: PropTypes.bool.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
        };
    })(JobBoardFormWrapper),
);
