import React from "react";
import PropTypes from "prop-types";
import { AppTabs } from "./appTabs";

export const ControlledAppTabs = (props) => {
    const { tabsControls, tabsContent, firstTab, itemStyles, containerStyles, handleChange, value } = props;

    return (
        <AppTabs
            tabsControls={tabsControls}
            tabsContent={tabsContent}
            firstTab={firstTab}
            itemStyles={itemStyles}
            containerStyles={containerStyles}
            value={value}
            handleChange={handleChange}
        />
    );
};

ControlledAppTabs.propTypes = {
    tabsControls: PropTypes.array.isRequired,
    tabsContent: PropTypes.array.isRequired,
    firstTab: PropTypes.number.isRequired,
    itemStyles: PropTypes.any,
    containerStyles: PropTypes.any,
    value: PropTypes.number,
    handleChange: PropTypes.func,
};

ControlledAppTabs.defaultProps = {
    component: "div",
    firstTab: 0,
};
