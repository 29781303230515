import React from "react";
import { compose } from "redux";
import { change, reduxForm } from "redux-form";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Tooltip } from "@material-ui/core";

import { PAGE_ACTIONS_STYLES } from "../../../styles/reusableStyles";
import DatesRange from "../../global/datesRange";
import { getProjectHistoryJobs } from "../../../actions/projects";
import moment from "moment";

const ProjectJobsHistoryFilters = ({ dispatch, form }) => {
    const pageActionsStyles = PAGE_ACTIONS_STYLES();

    const handleDateChange = () => {
        dispatch(getProjectHistoryJobs());
    };

    const handleResetToDefault = () => {
        dispatch(change(PROJECT_HISTORY_FILTERS_FORM, "startDate", moment()));
        dispatch(change(PROJECT_HISTORY_FILTERS_FORM, "endDate", moment()));

        dispatch(getProjectHistoryJobs());
    };

    return (
        <form noValidate={true}>
            <Grid container alignItems="center" className={pageActionsStyles.pageActions}>
                <Grid item xs={8}>
                    <DatesRange
                        form={form}
                        handleStartDateChange={handleDateChange}
                        handleEndDateChange={handleDateChange}
                    />
                </Grid>
                <Grid item container xs={1} alignItems="center">
                    <Tooltip title="Reset to default filter">
                        <RefreshIcon className="action-icons" onClick={handleResetToDefault} />
                    </Tooltip>
                </Grid>
            </Grid>
        </form>
    );
};

ProjectJobsHistoryFilters.propTypes = {
    form: PropTypes.string,
    dispatch: PropTypes.func,
};

export const PROJECT_HISTORY_FILTERS_FORM = "projectHistoryFiltersForm";

export default compose(
    reduxForm({
        form: PROJECT_HISTORY_FILTERS_FORM,
    }),
)(ProjectJobsHistoryFilters);
