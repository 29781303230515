import _ from "lodash";
import { IS_ADMIN_USER } from "../constants/maps";

export const selectIfIsUsesSmartDispatch = (state) => _.get(state, "account.companyProfile.usesSmartDispatch");

export const selectUnitOfMeasures = (state) => state.constants.unitOfMeasures.data;
export const selectUnitOfMeasuresMeta = (state) => state.constants.unitOfMeasures.meta;

export const selectMeasureSystem = (state) => state.account.companyProfile.measurementSystem;

export const selectCompanyRegions = (state) => state.constants.regions.data;
export const selectCompanyRegionsMeta = (state) => state.constants.regions.meta;
export const selectUserRegion = (state) => selectAccount(state).region;

export const selectWeather = (state) => state.constants.weather.data;
export const selectWeatherMeta = (state) => state.constants.weather.meta;

export const selectIfIsStartDateByDefault = (state) =>
    state.account && state.account.companyProfile && state.account.companyProfile.startDateByDefault;
export const selectIfIsStartCopyJobNextDay = (state) =>
    state.account && state.account.companyProfile && state.account.companyProfile.startCopyJobNextDay;
export const selectUsesSplitJob = (state) =>
    state.account && state.account.companyProfile && state.account.companyProfile.usesSplitJob;
export const selectRestrictedCustomerShowPrice = (state) => state.account?.companyProfile?.restrictedCustomerShowPrice;
export const selectIfIsCanAddPayloads = (state) => state.account && state.account.canAddPayloads;
export const selectIfIsCanAddProjects = (state) => state.account && state.account.canAddProjects;

export const getUserAccountId = (state) => state.account.company.id;
export const selectUserAccountRole = (state) => state.account.role;
export const getIfIsRegionRequired = (state) =>
    state.account && state.account.companyProfile && state.account.companyProfile.regionRequired;

export const selectCurrentProject = (state) => state.projects.currentProject;
export const selectProjectAdditinalFields = (state) => state.projects.currentProject?.additionalFields;
export const selectReportNamesMeta = (state) => state.constants.reportNamesMeta;
export const selectReportsNames = (state) => state.constants.reportNames && state.constants.reportNames.data;
export const selectAdditionReports = (state) =>
    state.constants.reportNames &&
    state.constants.reportNames.data
        .filter((i) => !i.isGeneral)
        .map((i) => ({
            label: i.name.replace("_", " "),
            value: i.name,
        }));

export const selectFormatRegions = (state) => {
    const regions = selectCompanyRegions(state);

    return regions && regions.length
        ? regions.map((i) => ({
              label: i.regionName,
              value: i.id,
          }))
        : [];
};

export const selectAccount = (state) => state.account;
export const selectAccountTimezone = (state) => selectAccount(state).timezone;

export const selectCalendarEvents = (state) => state.constants.calendarEvents.data;
export const selectSuspendPeriod = (state) => state.constants.calendarEvents.suspendPeriod;
export const selectCalendarMeta = (state) => state.constants.calendarEvents.meta;

export const selectUserEvents = (state) => state.teams.userEvents.data;
export const selectUserSuspendPeriod = (state) => state.teams.userEvents.suspendPeriod;
export const selectUserEventsMeta = (state) => state.teams.userEvents.meta;

export const selectPreferredHaulers = (state) =>
    state.settings.preferredHaulers.data && state.settings.preferredHaulers.data.companies;
export const selectPreferredMeta = (state) => state.settings.preferredHaulers.meta;
export const selectPreferredHaulersFilterId = (state) => _.get(state, "settings.preferredHaulers.data.id");

export const selectCompany = (state) => selectAccount(state).company;
export const selectCompanyId = (state) => selectCompany(state)?.id;
export const selectCompanyAdmin = (state) => selectAccount(state)?.companyAdmin;
export const selectCompanyType = (state) => selectCompany(state)?.type;
export const selectStripeId = (state) => selectCompany(state).stripeId;

export const selectPoLineItemsLoading = (state) => state.poLineItems.poLineItemsLoading;

export const selectTruckDispatchedParam = (state) => state.jobOrders.activeTruckDispatchedParam;
export const selectActiveHaulerOrders = (state) => state.jobOrders.activeHaulerParam;

export const selectTrailers = (state) => state.trucks.trucksList;

export const selectOfflineHaulers = (state) => state.settings.offlineHaulers.data;
export const selectOfflineHaulersMeta = (state) => state.settings.offlineHaulers.meta;

export const selectCanEditSettings = (state) => {
    const companyAdmin = selectCompanyAdmin(state);
    const companyId = selectCompanyId(state);

    return companyAdmin || IS_ADMIN_USER(companyId);
};
