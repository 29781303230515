import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import clsx from "clsx";
import { getTicketsMaterial } from "../../../dataServers/tickets";
import ErrorNotification from "../../core/notification";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../../../constants/global";
import SuccessNotification from "../../core/successNotification";
import Loader from "../../core/loader";
import AirTicketsStatsList from "./ticketsStats";
import Axios from "axios";
import _ from "lodash";
import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import moment from "moment";
import { formValueSelector } from "redux-form";

let requestPromise;
let cancelPromise = null;

function AirTicketsWrapper(props) {
    const { ticketsFilters, formValues } = props;
    const [ticketsStats, setTicketsStats] = useState([]);
    const [ticketsStatsCount, setTicketsStatsCount] = useState(0);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setError] = useState(null);

    const loadTicketsStats = (concat) => {
        const pageSize = 10;
        const currentPage = Math.ceil(ticketsStats.length / pageSize);
        const nextPage = _.isEmpty(ticketsStats) ? 1 : +currentPage + 1;
        let params = {
            ...(ticketsFilters || {}),
            jobOrder: undefined,
            jobOrders: undefined,
            joLineItemId: ticketsFilters.jobOrders
                ? _.cloneDeep(ticketsFilters.jobOrders)
                      .filter((i) => !_.isEmpty(i.joLineItems))
                      .map((i) => i.joLineItems)
                : undefined,
            perPage: pageSize,
            [START_DATE_NAME]: moment(formValues[START_DATE_NAME]).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: moment(formValues[END_DATE_NAME]).format(SERVER_DATE_FORMAT),
            page: concat ? nextPage : 1,
        };

        if (params?.keywords && _.isObject(params.keywords)) {
            params.keywords = params?.keywords[0]?.value;
        }
        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        setLoading(true);

        requestPromise = getTicketsMaterial({ ...params, cancelToken });

        requestPromise
            .then((ticketsStatsData) => {
                setTicketsStats(concat ? [...ticketsStats, ...ticketsStatsData.data] : ticketsStatsData.data);
                setTicketsStatsCount(ticketsStatsData.meta.count || 0);
                setLoading(false);
                requestPromise = null;
            })
            .catch((error) => {
                if (_.isEmpty(error)) {
                    return false;
                }

                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    const onErrorClose = () => {
        setError(null);
        setIsHandledSuccessfully(null);
    };

    useEffect(() => {
        ticketsFilters && loadTicketsStats();
    }, [ticketsFilters]);

    useEffect(() => {
        return () => {
            if (requestPromise && cancelPromise) {
                cancelPromise.cancel();
                cancelPromise = null;
            }
        };
    }, []);

    return (
        <div className={clsx("air-tickets-wrap")}>
            {errorMessage && <ErrorNotification error={errorMessage} config={{ onClose: onErrorClose }} />}
            {isHandledSuccessfully && <SuccessNotification message="Success" config={{ onClose: onErrorClose }} />}
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <br />
                <h2 className="--text-center --text-h1-size">Summary by Material</h2>
                <br />
                <AirTicketsStatsList
                    tickets={ticketsStats}
                    fetchMore={loadTicketsStats}
                    ticketsCount={ticketsStatsCount}
                />
            </div>
        </div>
    );
}

AirTicketsWrapper.propTypes = {
    history: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    tickets: PropTypes.array.isRequired,
    ticketsFilters: PropTypes.object,
};

export default withRouter(
    compose(
        connect((state, props) => {
            const formValuesSelector = formValueSelector(props.form);

            return {
                tickets: state.tickets.tickets,
                account: state.account,
                formValues: formValuesSelector(state, START_DATE_NAME, END_DATE_NAME),
            };
        }),
    )(AirTicketsWrapper),
);
