import dataProvider from "../helpers/api";
import { FILTER, SETTINGS } from "../constants/endpoints";

export const getPreferredHaulers = () => {
    return dataProvider({
        url: FILTER.PREFERRED_HAULERS(),
        method: "GET",
    });
};

export const deleteHauler = (filterId, { id, name }) => {
    return dataProvider({
        url: FILTER.PREFERRED_HAULER(filterId),
        method: "PUT",
        data: {
            haulersToRemove: [id],
            name,
        },
    });
};

export const addHauler = (filterId, { label, value }) => {
    return dataProvider({
        url: FILTER.PREFERRED_HAULER(filterId),
        method: "PUT",
        data: {
            haulersToAdd: [
                {
                    haulerId: value,
                },
            ],
            name: label,
        },
    });
};

export const updateHauler = (filterId, { defaultRate, id, name }) => {
    return dataProvider({
        url: FILTER.PREFERRED_HAULER(filterId),
        method: "PUT",
        data: {
            haulersToUpdate: [
                {
                    defaultRate: defaultRate ? defaultRate : null,
                    haulerId: id,
                },
            ],
            name,
        },
    });
};

export const addOfflineHauler = (data) => {
    return dataProvider({
        url: SETTINGS.ADD_OFFLINE_HAULER(),
        method: "POST",
        data,
    });
};

export const updateOfflineHauler = (companyId, data) => {
    return dataProvider({
        url: SETTINGS.UPDATE_COMPANY(companyId),
        method: "PUT",
        data,
    });
};
