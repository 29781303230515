/* global google */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { GoogleMap, Polygon, Polyline, withGoogleMap, withScriptjs } from "react-google-maps";
import _ from "lodash";

import CustomMarker, { CircleWithTooltip, PolygonWithTooltip } from "../global/maps/customMarker";

import sitePin from "../../styles/images/black-pin.svg";
import redSitePin from "../../styles/images/pale-red-pin.svg";
import { GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_URL, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { alertZoneFillOpacity, alertZoneStrokeOpacity, alertZoneStrokeWeight } from "../../constants/sites";
import { getAlertZoneOptions, parseAlertZones } from "../../helpers/sites";
import { MAP } from "react-google-maps/lib/constants";
import { polygonCenter } from "../../helpers/global";
import clsx from "clsx";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import * as SiteActions from "../../actions/sites";
import { setNewAlertZoneCoordinates } from "../../actions/sites";

const polyLineMarkerIconSize = 30;
const newZoneColor = "#f34740";

let _map;

const boundAllZones = (alertZones, { lat, lng } = {}) => {
    const bounds = new google.maps.LatLngBounds();

    lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));

    alertZones.forEach((item) => {
        const { center, paths, radius } = item;

        if (!_.isEmpty(center) && radius) {
            const { lat, lng } = center;

            const centerCircle = new google.maps.Circle({
                center: center,
                radius: +radius,
                strokeColor: "#232",
                fillColor: "#F43",
            });

            bounds.union(centerCircle.getBounds());
            lat && lng && bounds.extend(new google.maps.LatLng(lat, lng));
        }

        if (!_.isEmpty(paths)) {
            paths.forEach((i) => {
                bounds.extend(new google.maps.LatLng(i.lat, i.lng));
            });
        }
    });

    _map && _map.context[MAP].fitBounds(bounds);
};

const BaseSiteMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const {
        alertZones,
        drawingEnabled,
        lat,
        lng,
        setAlertZoneCoordinates,
        highlightedZoneId,
        activeSiteZoneId,
        removeZone,
        saveEditOfZone,
        onEditAlertZoneId,
        onEmitSave,
        setOnEditAlertZone,
        addNewAlertZoneCoordinates,
        newZoneData,
    } = props;
    const [circles, setCircles] = useState([]);
    const [polygons, setPolygons] = useState([]);
    const [mapCenter, setMapCenter] = useState({
        lat,
        lng,
    });
    const { polylinePath, createdPolygon, markers } = newZoneData;

    const mainMarkerIcon = {
        url: sitePin,
        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
    };
    const newZoneMarkerIcon = {
        url: redSitePin,
        scaledSize: new google.maps.Size(polyLineMarkerIconSize, polyLineMarkerIconSize),
    };
    const polylineOptions = {
        strokeColor: newZoneColor,
        strokeOpacity: alertZoneStrokeOpacity,
        strokeWeight: alertZoneStrokeWeight,
    };

    const onAddPoint = (event) => {
        addNewAlertZoneCoordinates({
            ...newZoneData,
            markers: [...markers, event.latLng],
            polylinePath: [...polylinePath, event.latLng],
        });
    };

    const onAlertZoneClose = () => {
        if (polylinePath.length > 2) {
            const polygonPaths = [...polylinePath, polylinePath[0]];

            addNewAlertZoneCoordinates({
                markers: [],
                polylinePath: [],
                createdPolygon: {
                    options: {
                        ...getAlertZoneOptions(),
                        fillColor: newZoneColor,
                    },
                    paths: polygonPaths,
                },
            });
            setAlertZoneCoordinates(polygonPaths);
        }
    };

    useEffect(() => {
        const [circleZones, polygonZones] = parseAlertZones(alertZones);

        addNewAlertZoneCoordinates({
            polylinePath: [],
            markers: [],
            createdPolygon: {},
        });
        setMapCenter({
            lat,
            lng,
        });
        setPolygons([...polygonZones]);
        setCircles(circleZones);
        boundAllZones([..._.cloneDeep(circleZones), ..._.cloneDeep(polygonZones)], {
            lat,
            lng,
        });
    }, [alertZones]);

    useEffect(() => {
        if (!drawingEnabled && (!_.isEmpty(polylinePath) || !_.isEmpty(markers) || !_.isEmpty(createdPolygon))) {
            addNewAlertZoneCoordinates({
                polylinePath: [],
                markers: [],
                createdPolygon: {},
            });
            setAlertZoneCoordinates(null);
        }
    }, [drawingEnabled]);

    useEffect(() => {
        if (activeSiteZoneId) {
            const cordsCircle = circles && circles.find((i) => i.id === activeSiteZoneId);
            const cordsPolygon = polygons && polygons.find((i) => i.id === activeSiteZoneId);
            const bounds = new google.maps.LatLngBounds();

            if (cordsCircle && cordsCircle.center && cordsCircle.radius) {
                const centerCircle = new window.google.maps.Circle({
                    center: cordsCircle.center,
                    radius: +cordsCircle.radius,
                });

                setMapCenter({
                    lat: cordsCircle.center.lat,
                    lng: cordsCircle.center.lng,
                });
                bounds.union(centerCircle.getBounds());
                bounds.extend(new google.maps.LatLng(cordsCircle.center.lat, cordsCircle.center.lng));
            }

            if (cordsPolygon) {
                const center = polygonCenter(cordsPolygon.paths);

                setMapCenter({
                    lat: center.lat(),
                    lng: center.lng(),
                });
                cordsPolygon.paths.forEach((i) => {
                    bounds.extend(new google.maps.LatLng(i.lat, i.lng));
                });
            }

            _map && _map.context[MAP].fitBounds(bounds);
        } else {
            setMapCenter({
                lat,
                lng,
            });
        }
    }, [activeSiteZoneId]);

    useEffect(() => {
        return () => {
            _map = null;
            setPolygons([]);
            setCircles([]);
        };
    }, []);

    return (
        <GoogleMap
            center={mapCenter}
            defaultZoom={14}
            ref={(map) => (_map = map)}
            onClick={drawingEnabled ? (e) => onAddPoint(e) : null}
        >
            <CustomMarker
                id={0}
                position={{
                    lat,
                    lng,
                }}
                icon={mainMarkerIcon}
            />
            {!_.isEmpty(circles) &&
                circles.map((circleConfig, index) => {
                    return (
                        <CircleWithTooltip
                            key={index}
                            circleConfig={{
                                ...circleConfig,
                                options: {
                                    ...circleConfig.options,
                                    strokeOpacity: highlightedZoneId
                                        ? circleConfig.id === highlightedZoneId
                                            ? alertZoneStrokeOpacity
                                            : 0.1
                                        : alertZoneStrokeOpacity,
                                    fillOpacity: highlightedZoneId
                                        ? circleConfig.id === highlightedZoneId
                                            ? alertZoneFillOpacity
                                            : 0.1
                                        : alertZoneFillOpacity,
                                },
                            }}
                            removeAlertZone={removeZone}
                            infoContent={circleConfig.name}
                        />
                    );
                })}
            {!_.isEmpty(polygons) &&
                polygons.map((polygonConfig, index) => {
                    const options = {
                        ...polygonConfig.options,
                        strokeOpacity: highlightedZoneId
                            ? polygonConfig.id === highlightedZoneId
                                ? alertZoneStrokeOpacity
                                : 0.1
                            : alertZoneStrokeOpacity,
                        fillOpacity: highlightedZoneId
                            ? polygonConfig.id === highlightedZoneId
                                ? alertZoneFillOpacity
                                : 0.1
                            : alertZoneFillOpacity,
                    };

                    return (
                        <PolygonWithTooltip
                            key={index}
                            polygonConfig={{
                                ...polygonConfig,
                                options,
                            }}
                            saveEditOfZone={saveEditOfZone}
                            setOnEditAlertZone={setOnEditAlertZone}
                            onEmitSave={onEmitSave === polygonConfig.id}
                            onEditAlertZoneId={onEditAlertZoneId}
                            removeAlertZone={removeZone}
                            infoContent={polygonConfig.name}
                        />
                    );
                })}
            {!_.isEmpty(markers) &&
                markers.map((position, index) => (
                    <CustomMarker
                        key={index}
                        id={index + 1}
                        position={position}
                        icon={newZoneMarkerIcon}
                        onClick={index === 0 ? () => onAlertZoneClose() : null}
                    />
                ))}
            {!_.isEmpty(polylinePath) && <Polyline options={polylineOptions} path={polylinePath} />}
            {!_.isEmpty(createdPolygon) && <Polygon paths={createdPolygon.paths} options={createdPolygon.options} />}
        </GoogleMap>
    );
});

const SiteMap = (props) => {
    const {
        currentSite,
        drawingEnabled,
        height,
        setAlertZoneCoordinates,
        highlightedZoneId,
        activeSiteZoneId,
        removeZone,
        saveEditOfZone,
        onEditAlertZoneId,
        onEmitSave,
        setOnEditAlertZone,
        newZoneData,
        addNewAlertZoneCoordinates,
    } = props;
    const { latitude, longitude, alertZones } = currentSite;
    const [error, showParentError] = useState(null);
    const [isLoading, showParentLoader] = useState(null);

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => showParentError(null) }} />}
            <BaseSiteMap
                lat={latitude}
                highlightedZoneId={highlightedZoneId}
                activeSiteZoneId={activeSiteZoneId}
                lng={longitude}
                newZoneData={newZoneData}
                addNewAlertZoneCoordinates={addNewAlertZoneCoordinates}
                showParentError={showParentError}
                removeZone={removeZone}
                onEditAlertZoneId={onEditAlertZoneId}
                saveEditOfZone={saveEditOfZone}
                onEmitSave={onEmitSave}
                showParentLoader={showParentLoader}
                setOnEditAlertZone={setOnEditAlertZone}
                alertZones={alertZones}
                setAlertZoneCoordinates={setAlertZoneCoordinates}
                drawingEnabled={drawingEnabled}
                googleMapURL={GOOGLE_MAPS_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: height }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
    );
};

SiteMap.propTypes = {
    currentSite: PropTypes.object.isRequired,
    newZoneData: PropTypes.object.isRequired,
    drawingEnabled: PropTypes.bool.isRequired,
    height: PropTypes.string,
    highlightedZoneId: PropTypes.string,
    onEditAlertZoneId: PropTypes.string,
    activeSiteZoneId: PropTypes.string,
    setAlertZoneCoordinates: PropTypes.func.isRequired,
    removeZone: PropTypes.func,
    saveEditOfZone: PropTypes.func,
    setOnEditAlertZone: PropTypes.func,
    addNewAlertZoneCoordinates: PropTypes.func,
    onEmitSave: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

SiteMap.defaultProps = {
    height: "40vh",
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                newZoneData: state.sites.newZoneData,
            };
        },
        (dispatch) => ({
            setCurrentSite: (payload) => dispatch(SiteActions.setCurrentSite(payload)),
            addNewAlertZoneCoordinates: (payload) => dispatch(setNewAlertZoneCoordinates(payload)),
        }),
    )(SiteMap),
);
