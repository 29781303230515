import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TableHead from "@material-ui/core/TableHead";

import ErrorNotification from "../core/notification";
import ProjectForm, { PROJECT_FORM } from "./projectForm";
import Loader from "../core/loader";
import AppModal from "../core/modal";
import { ActionLink, SimpleLink } from "../core/link";
import { ROUTE_PROJECTS } from "../../routes/globalRoutes";
import * as ProjectsActions from "../../actions/projects";
import * as projectsActions from "../../actions/projects";
import { createProject } from "../../dataServers/projects";
import { getProjectEditRoute } from "../../helpers/global";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import ListWaypoint from "../core/listWaypoint";
import ProjectsFilters from "./projectsFilters";
import ProjectItem from "./projectItem";
import { FREE } from "../../constants/accounts";
import TableHeaderSortingCell from "../../components/core/tableHeaderSortingCell";
import { selectCompanyRegions, selectCompanyRegionsMeta, selectIfIsCanAddProjects } from "../../selectors";
import {
    ADD_PROJECT_NETSUITE_INTEGRATION_WARNING_TEXT,
    ADD_PROJECT_UPGRADE_TO_FULL_ACCESS_WARNING_TEXT,
} from "./constants/strings";
import { selectDropOffRecipients } from "../../selectors/user";
import { generateUserSelectOptions } from "../../constants/users";

const useStyles = makeStyles((theme) => ({
    projectsList: {
        "& .create-project": {
            padding: " 0 31px 20px 31px",
        },
    },
    projectHeader: {
        backgroundColor: theme.palette.secondary.superDark,
        height: 60,
    },
    projectMain: {
        backgroundColor: theme.palette.general.paleOrange,
        height: "100%",
        "& .scroll-wrap": {
            height: "100vh",
            overflowY: "scroll",
            marginRight: 12,
            position: "relative",
            zIndex: 1,
        },
    },

    projectsWrap: {
        margin: "0 15px 0 33px",
    },
    sortContainer: {
        display: "flex",
        justifyContent: "space-around",
        margin: "0 33px",
        borderBottom: `1px solid #979797`,
        marginBottom: 12,
    },
}));

const ProjectsList = (props) => {
    const classes = useStyles();

    const {
        account,
        projects,
        projectsLoading,
        projectsCount,
        projectsError,
        getProjects,
        projectsVisibleLoading,
        resetProjects,
        onProjectSelection,
        setProjectsSearchQuery,
        setProjectsStatusFilter,
        setProjectsRegionFilter,
        clearProjectsFilters,
        currentProject,
        canCreateProject,
        history,
        projectsStatusFilter,
        projectsRegionFilter,
        projectsSearchQuery,
        projectsDateFilter,
        projectsLoadedHandler,
        josListFormValues: { [START_DATE_NAME]: jobsStartDate },
        isDispatch,
        regions,
        isProjectPage,
        updateActiveProjectOrder,
        canAddProjects,
        dropOffRecipients,
    } = props;
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [localFilters, setFilters] = useState({});
    const [needToLoad, setNeedToLoad] = useState(true);
    const activeProjectOrder = useSelector((state) => state.projects.activeProjectOrder);
    const accountType = useSelector((state) => state.account.company.accountType);
    const isProjectsPage = history.location.pathname.includes(ROUTE_PROJECTS.PROJECTS);
    const locationState = history.location.state;
    const getDates = () => {
        if (!isDispatch) {
            return {};
        }

        const applyStartDate = moment(jobsStartDate).format(SERVER_DATE_FORMAT) || moment().format(SERVER_DATE_FORMAT);

        return {
            dispatch: isDispatch,
            [START_DATE_NAME]: applyStartDate,
            [END_DATE_NAME]: applyStartDate,
        };
    };

    const updateListByFilters = (filters, clearFilters, localFilters) => {
        if (
            (projectsStatusFilter || projectsSearchQuery || projectsRegionFilter) &&
            (clearFilters || _.isEmpty(filters))
        ) {
            clearProjectsFilters();
        }
        let newFilters = _.cloneDeep(filters);
        if (!_.isEqual(localFilters, newFilters) && regions) {
            setFilters(newFilters);
            setNeedToLoad(true);
            setProjectsSearchQuery(filters.keywords);
            setProjectsStatusFilter(filters.status);
            setProjectsRegionFilter(filters.regions);
        }
        if (_.isEmpty(filters) && needToLoad) {
            loadProjects();
            setNeedToLoad(false);
        }
    };

    const resetHistoryState = () => {
        if (locationState && locationState.project) {
            let state = { ...locationState };
            delete state.project;
            history.replace({ ...history.location, state });
        }
    };

    const loadProjects = () => {
        const project = locationState && locationState.project;
        getProjects({
            ...getDates(),
            isProjectPage,
            project,
            withNewProject: true,
        }).then(({ loadedProjects, currentProject }) => {
            projectsLoadedHandler && projectsLoadedHandler(loadedProjects, currentProject);
        });
        resetHistoryState();
    };

    const getMoreProjects = (concat) => {
        const loadedProjects = projects.filter((i) => !i.insertedProject);
        const currentPage = Math.ceil(loadedProjects.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = concat && loadedProjects.length >= GLOBAL_COUNT_TO_LOAD - 1 ? +currentPage + 1 : 1;
        getProjects({ page: concat ? nextPage : 1, ...getDates(), isProjectPage }, concat);
    };

    const handleProjectCreate = (data) => {
        return createProject(data).then(({ data }) => {
            resetProjects();
            setShowCreateProjectModal(false);
            history.push(getProjectEditRoute(data.id));

            return getProjects({
                ...getDates(),
                isProjectPage,
                withNewProject: true,
            });
        });
    };
    const setActiveProject = (project) => {
        isProjectsPage && history.push(getProjectEditRoute(project.id));
        onProjectSelection && onProjectSelection(project);
    };

    useEffect(() => {
        return () => {
            resetProjects();
            projectsStatusFilter && setProjectsStatusFilter(undefined);
        };
    }, []);

    useEffect(() => {
        if (
            projectsStatusFilter ||
            projectsSearchQuery ||
            projectsDateFilter ||
            projectsRegionFilter ||
            !_.isEmpty(localFilters)
        ) {
            if (
                !(
                    _.isEmpty(localFilters) &&
                    (projectsStatusFilter || projectsSearchQuery || projectsDateFilter || projectsRegionFilter)
                )
            ) {
                loadProjects();
            }
        }
    }, [projectsStatusFilter, projectsSearchQuery, projectsDateFilter, projectsRegionFilter, localFilters]);

    const handleSort = (sortParam) => {
        updateActiveProjectOrder({
            order: sortParam,
            clearProjectActiveItem: true,
        });
        loadProjects();
    };

    const columns = [
        {
            id: "date",
            sortParam: "createdDate",
            label: "Created Date",
        },
        {
            id: "projectName",
            sortParam: "name",
            label: "Project Name",
        },
    ];

    const renderSortParams = () => {
        return (
            <TableHead className={classes.sortContainer}>
                {columns.map((column, index) => (
                    <TableHeaderSortingCell
                        key={index}
                        align={"center"}
                        handleSort={handleSort}
                        sortParam={column.sortParam}
                        label={column.label}
                        borderBottom={"none"}
                        padding={"0 10px 10px 10px"}
                        activeOrderParam={activeProjectOrder}
                    />
                ))}
            </TableHead>
        );
    };

    return (
        <Grid
            component="div"
            item
            xs={4}
            className={clsx(classes.projectsList, projectsVisibleLoading && LOADER_WHITE_OVERLAY)}
        >
            {projectsVisibleLoading && <Loader />}
            <div className={clsx(classes.projectMain, "sites-and-projects-wrapper")}>
                <ProjectsFilters
                    key={regions}
                    updateListByFilters={(filters, clear, loadedFilters) =>
                        loadedFilters && updateListByFilters(filters, clear, localFilters)
                    }
                    initialValues={{ search: null }}
                />
                {renderSortParams()}
                {canCreateProject && (
                    <ActionLink className="create-project">
                        <AddCircleOutlineIcon />
                        <SimpleLink
                            onClick={() => setShowCreateProjectModal(true)}
                            disabled={accountType === FREE || !canAddProjects}
                            tooltipTitle={
                                accountType === FREE
                                    ? ADD_PROJECT_UPGRADE_TO_FULL_ACCESS_WARNING_TEXT
                                    : !canAddProjects && ADD_PROJECT_NETSUITE_INTEGRATION_WARNING_TEXT
                            }
                        >
                            Create new project
                        </SimpleLink>
                    </ActionLink>
                )}

                {projectsLoading || !_.isEmpty(projects) ? (
                    <div className="scroll-wrap">
                        <div className={classes.projectsWrap}>
                            {((projectsLoading && new Array(8).fill(0)) || projects).map((project, index) => {
                                const selectedProject = currentProject ? currentProject.id === project.id : false;

                                return (
                                    <Fragment key={index}>
                                        <ListWaypoint
                                            fetchMore={getMoreProjects}
                                            index={index}
                                            items={projects.filter((i) => !i.insertedProject)}
                                            itemsCount={projectsCount}
                                        />
                                        <ProjectItem
                                            project={project || {}}
                                            isDispatch={isDispatch}
                                            projectsLoading={projectsLoading}
                                            selectedProject={selectedProject}
                                            setActiveProject={setActiveProject}
                                        />
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <Box component="h3" textAlign="center">
                        No projects found
                    </Box>
                )}
                {projectsError && <ErrorNotification message={projectsError} />}
                {!!showCreateProjectModal && (
                    <AppModal
                        isOpen={!!showCreateProjectModal}
                        form={PROJECT_FORM}
                        closeModal={() => setShowCreateProjectModal(false)}
                        modalStyles={{ minWidth: 700 }}
                    >
                        <h1 className="title">Create Project</h1>
                        <br />
                        <ProjectForm
                            iAmOwner
                            form={PROJECT_FORM}
                            handleSave={handleProjectCreate}
                            closeModal={() => setShowCreateProjectModal(false)}
                            initialValues={{
                                owner: {
                                    label: account.company.name,
                                    value: account.company.id,
                                },
                                customer: null,
                                name: "",
                                externalJobNumber: "",
                                prices: [],
                                dropOffNotificationsRecipients: dropOffRecipients?.map(generateUserSelectOptions),
                            }}
                        />
                    </AppModal>
                )}
            </div>
        </Grid>
    );
};

ProjectsList.propTypes = {
    onProjectSelection: PropTypes.func.isRequired,
    setProjectsSearchQuery: PropTypes.func.isRequired,
    setProjectsStatusFilter: PropTypes.func.isRequired,
    clearProjectsFilters: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    canCreateProject: PropTypes.bool,
    account: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    projectsLoading: PropTypes.bool.isRequired,
    projectsError: PropTypes.string,
    getProjects: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    resetProjects: PropTypes.func.isRequired,
    updateProjects: PropTypes.func.isRequired,
    projectsLoadedHandler: PropTypes.func,
    projectsCount: PropTypes.number.isRequired,
    josListFormValues: PropTypes.object.isRequired,
    isDispatch: PropTypes.bool.isRequired,
    isProjectPage: PropTypes.bool.isRequired,
    projectsVisibleLoading: PropTypes.bool.isRequired,
    projectsStatusFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    projectsDateFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    projectsSearchQuery: PropTypes.string,
    updateActiveProjectOrder: PropTypes.func.isRequired,
    canAddProjects: PropTypes.bool.isRequired,
    dropOffRecipients: PropTypes.array,
};

ProjectsList.defaultProps = {
    canCreateProject: false,
    projectsVisibleLoading: false,
    isDispatch: false,
    isProjectPage: false,
};

export default withRouter(
    connect(
        (state, props) => {
            let josListFormSelector = null;
            const result = {
                account: state.account,
                projects: state.projects.projects,
                currentProject: state.projects.currentProject,
                projectsLoading: state.projects.projectsLoading,
                projectsVisibleLoading: state.projects.projectsVisibleLoading,
                projectsError: state.projects.projectsError,
                projectsCount: state.projects.projectsCount,
                projectsSearchQuery: state.projects.projectsSearchQuery,
                projectsStatusFilter: state.projects.projectsStatusFilter,
                projectsRegionFilter: state.projects.projectsRegionFilter,
                projectsDateFilter: state.projects.projectsDateFilter,
                regionsMeta: selectCompanyRegionsMeta(state),
                josListFormValues: {},
                regions: selectCompanyRegions(state),
                canAddProjects: selectIfIsCanAddProjects(state),
                dropOffRecipients: selectDropOffRecipients(state),
            };

            if (props.jobsFormId) {
                josListFormSelector = formValueSelector(props.jobsFormId);

                result.josListFormValues = josListFormSelector(state, START_DATE_NAME, END_DATE_NAME);
            }

            return result;
        },
        (dispatch) => ({
            getProjects: (params, concat) => dispatch(ProjectsActions.getProjects(params, concat)),
            updateProjects: (payload) => dispatch(ProjectsActions.updateProjects(payload)),
            setProjectsSearchQuery: (payload) => dispatch(ProjectsActions.setProjectsSearchQuery(payload)),
            setProjectsStatusFilter: (payload) => dispatch(ProjectsActions.setProjectsStatusFilter(payload)),
            setProjectsRegionFilter: (payload) => dispatch(ProjectsActions.setProjectsRegionFilter(payload)),
            clearProjectsFilters: (payload) => dispatch(ProjectsActions.clearProjectsFilters(payload)),
            resetProjects: (payload) => dispatch(ProjectsActions.resetProjects(payload)),
            setCurrentProject: (project) => {
                dispatch(projectsActions.setCurrentProject(project));
            },
            updateActiveProjectOrder: (payload) => {
                dispatch(ProjectsActions.updateActiveProjectOrder(payload));
            },
        }),
    )(ProjectsList),
);
