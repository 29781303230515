export const VIEW_BY_BUNDLES_LEFT_SIDE_TEXT = "Select Bundle To See Recommended Routes";
export const VIEW_BY_TRUCKS_LEFT_SIDE_TEXT = "Select Truck To See Route";
export const VIEW_BY_TRUCKS_LEFT_SIDE_TEXT_WITH_SELECTED_ROUTE = "Drag And Drop To Add Job To Current Truck's Route";

export const INPUT_LOADS_FORM_LABEL = "Quantity";
export const INPUT_LOADS_FORM_HEADER = "Specify number of loads";
export const INPUT_LOADS_FORM_END_ADRORNMENT = "LOADS";
export const INPUT_LOADS_FORM_SUBMIT_TITLE = "Save";
export const INPUT_LOADS_FORM_END_CANCEL_TITLE = "Cancel";

export const REMOVE_JOB_WARNING = "ARE YOU SURE YOU WANT TO REMOVE JOB FROM ROUTE?";

export const OPEN_ROUTE_WITH_ADDED_MANUALLY_JOB_WARNING = "jobs added manually are not considered on the map";
export const START_LOCATION = "Start Location";
