import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import AppModal from "../core/modal";
import TextInputComponent from "../core/form/textInputComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Validation } from "../../helpers/validation";
import { createCustomer } from "../../dataServers/companies";
import { editCompaniesCustomer } from "../../dataServers/trucks";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ActionLink, SimpleLink } from "../core/link";
import clsx from "clsx";
import Loader from "../core/loader";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import PhoneField from "../global/phoneField";
import ConfirmationModal from "../core/confirmationModal";

const useStyles = makeStyles(() => ({
    customerFormIcon: {
        float: "right",
    },
}));

const renderEntries = ({ fields, removeEntry }) => {
    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <Grid item key={index}>
                    <br />
                    <CloseIcon
                        color={"action"}
                        fontSize={"default"}
                        cursor={"pointer"}
                        style={{
                            float: "right",
                        }}
                        onClick={() => removeEntry(index)}
                    />
                    <Field
                        type="text"
                        name={`${field}.name`}
                        label="Contact Name"
                        needShowEndAdornment={false}
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <Field
                        type="text"
                        name={`${field}.email`}
                        label="Contact email"
                        needShowEndAdornment={false}
                        validate={[Validation.email]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <PhoneField
                        name={`${field}.phone`}
                        label="Contact phone"
                        needShowEndAdornment={false}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        isRequired
                    />
                    <br />
                    <br />
                </Grid>
            ))}
        </React.Fragment>
    );
};

const CustomerSettingsForm = (props) => {
    const {
        account,
        form,
        isOpen,
        isNewCustomer,
        closeModal,
        handleSubmit,
        customerId,
        setSuccess,
        setError,
        formValues: { contacts, accountNumber, name: customerName },
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [entryToRemove, setEntryToRemove] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    const addContactField = () => {
        dispatch(
            change(form, "contacts", [
                ...contacts,
                {
                    name: null,
                    email: null,
                    phone: null,
                },
            ]),
        );
    };

    const removeEntry = (entryIndex) => {
        const newContacts = contacts.filter((item, index) => index !== entryIndex);

        dispatch(change(form, "contacts", newContacts));
        setEntryToRemove(null);
    };

    const onSubmit = () => {
        let data = {
            accountNumber: accountNumber ? accountNumber : null,
            contacts,
            customerName,
        };

        if (isNewCustomer) data.companyId = account.company.id;

        setIsLoading(true);

        const request = isNewCustomer
            ? createCustomer(data)
            : editCompaniesCustomer(account.company.id, customerId, data);

        return request
            .then(() => {
                setSuccess([isNewCustomer ? "Customer Created" : "Customer Data Updated"]);
                closeModal();
            })
            .catch((e) => {
                setError(PROCESS_SERVER_ERROR(e));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <AppModal isOpen={isOpen} closeModal={closeModal} form={form}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate={true}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            >
                <Loader />
                <h1 className="--text-center">{isNewCustomer ? "Create Customer" : "Edit Customer"}</h1>
                <br />
                <br />
                <Field
                    type="number"
                    name="accountNumber"
                    label="Account Number"
                    validate={[Validation.numbersPositive]}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    type="text"
                    name="name"
                    label="Customer Name"
                    validate={[Validation.required]}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Box className={classes.customerFormIcon}>
                    <ActionLink>
                        <AddIcon />
                        <SimpleLink onClick={addContactField}>Add customer contact</SimpleLink>
                    </ActionLink>
                </Box>

                <Grid container direction="column">
                    <FieldArray
                        name="contacts"
                        rerenderOnEveryChange
                        removeEntry={(index) => setEntryToRemove(index)}
                        component={renderEntries}
                    />
                </Grid>
                <br />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            <ConfirmationModal
                question="Are you sure you want to delete customer contact?"
                isOpen={!_.isNull(entryToRemove)}
                yesHandler={() => removeEntry(entryToRemove)}
                noHandler={() => setEntryToRemove(null)}
            />
        </AppModal>
    );
};

CustomerSettingsForm.propTypes = {
    form: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isNewCustomer: PropTypes.bool.isRequired,
    customerId: PropTypes.number.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formValues: PropTypes.object.isRequired,
    setSuccess: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
};

CustomerSettingsForm.defaultProps = {
    isNewCustomer: false,
};

export default withRouter(
    compose(
        reduxForm({
            form: "settingsCustomerForm",
            enableReinitialize: true,
            destroyOnUnmount: true,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(state, "name", "contacts", "accountNumber"),
            };
        }),
    )(CustomerSettingsForm),
);
