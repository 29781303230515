import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export const MEDIUM_BUTTON_SIZE = 150;

const primaryButtonStyles = makeStyles((theme) => ({
    button: {
        "&:disabled": {
            color: "#fff",
            background: theme.palette.primary.disabled,
        },
    },
    text: {
        width: MEDIUM_BUTTON_SIZE,
        height: 55,
        padding: "8px 10px",
        lineHeight: 1.3,
        boxShadow: "0 2px 4px 0 rgba(83, 83, 83, 0.5)",
    },
    textPrimary: {
        color: "#fff",
        background: `linear-gradient(129deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark}) 80%`,

        "&:hover, &:active": {
            color: "#fff",
            background: `linear-gradient(129deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.dark}) 80%`,
        },
    },
    textSizeSmall: {
        fontSize: theme.typography.button.fontSize,
    },
    textSizeLarge: {
        fontSize: theme.typography.button.fontSize,
    },
    sizeSmall: {
        width: 125,
        height: 35,
    },
    sizeLarge: {
        width: 280,
        height: 64,
    },
}));

const primaryButtonGreenStyles = makeStyles(() => ({
    textPrimary: {
        color: "#fff",
        background: `linear-gradient(129deg, #80cbc4 0%, #4db6ac) 80%`,

        "&:hover, &:active": {
            color: "#fff",
            background: `linear-gradient(129deg, #4db6ac 0%, #80cbc4) 80%`,
        },
    },
}));

export const PrimaryButton = (props) => {
    const { onClick, children, otherProps, size, disabled, type, className, classes: passedClasses, style } = props;
    const classes = passedClasses || primaryButtonStyles();

    return (
        <Button
            color={"primary"}
            type={type}
            style={style}
            className={clsx(classes.button, size === "xs" && classes.sizeXSmall, className)}
            disabled={disabled}
            classes={{
                text: classes.text,
                textPrimary: classes.textPrimary,
                textSizeSmall: classes.textSizeSmall,
                textSizeLarge: classes.textSizeLarge,
                sizeSmall: classes.sizeSmall,
                sizeLarge: classes.sizeLarge,
            }}
            onClick={(e) => onClick && onClick(e)}
            size={size}
            {...otherProps}
        >
            {children}
        </Button>
    );
};

PrimaryButton.propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    otherProps: PropTypes.object.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    disabled: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    classes: PropTypes.object,
    style: PropTypes.object,
};

PrimaryButton.defaultProps = {
    otherProps: {},
    style: {},
    size: "medium",
    type: "button",
    color: "primary",
    disabled: false,
};

export const PrimaryButtonGreen = (props) => {
    const { onClick, children, otherProps, size, disabled, type, className } = props;
    const classes = primaryButtonStyles();
    const classesForGreen = primaryButtonGreenStyles();

    return (
        <Button
            color={"primary"}
            type={type}
            className={clsx(classes.button, className)}
            disabled={disabled}
            classes={{
                text: classes.text,
                textPrimary: classesForGreen.textPrimary,
                textSizeSmall: classes.textSizeSmall,
                textSizeLarge: classes.textSizeLarge,
                sizeSmall: classes.sizeSmall,
                sizeLarge: classes.sizeLarge,
            }}
            onClick={(e) => onClick && onClick(e)}
            size={size}
            {...otherProps}
        >
            {children}
        </Button>
    );
};

PrimaryButtonGreen.propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    otherProps: PropTypes.object.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    disabled: PropTypes.bool.isRequired,
};

PrimaryButtonGreen.defaultProps = {
    otherProps: {},
    size: "medium",
    type: "button",
    disabled: false,
};
