import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, hasSubmitSucceeded } from "redux-form";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { Loader, ErrorNotification } from "../core";
import TextInputComponent from "../core/form/textInputComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { Validation } from "../../helpers/validation";
import {
    INPUT_LOADS_FORM_HEADER,
    INPUT_LOADS_FORM_LABEL,
    INPUT_LOADS_FORM_END_ADRORNMENT,
    INPUT_LOADS_FORM_END_CANCEL_TITLE,
    INPUT_LOADS_FORM_SUBMIT_TITLE,
} from "./constants/strings";

function InputLoads(props) {
    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(props.form));

    useEffect(() => {
        if (hasSubmitSuccess) props.closeModal();
    }, [hasSubmitSuccess]);

    return (
        <form
            noValidate={true}
            className={clsx(props.submitting && LOADER_WHITE_OVERLAY)}
            onSubmit={props.handleSubmit(props.onSubmit)}
        >
            <h1 className="title">{INPUT_LOADS_FORM_HEADER}</h1>
            {props.submitting && <Loader />}
            <Field
                validate={[Validation.required]}
                name="loadsCount"
                label={INPUT_LOADS_FORM_LABEL}
                endAdornment={INPUT_LOADS_FORM_END_ADRORNMENT}
                component={TextInputComponent}
            />
            <br />
            <br />
            <ErrorNotification message={props.error} config={{ autoHideDuration: 5000 }} />
            <ButtonsGroup>
                <SecondaryButton onClick={props.closeModal}>{INPUT_LOADS_FORM_END_CANCEL_TITLE}</SecondaryButton>
                <PrimaryButton disabled={props.invalid || props.submitting} type="submit">
                    {INPUT_LOADS_FORM_SUBMIT_TITLE}
                </PrimaryButton>
            </ButtonsGroup>
        </form>
    );
}

InputLoads.propTypes = {
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
};

export default reduxForm()(InputLoads);
