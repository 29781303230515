import makeStyles from "@material-ui/core/styles/makeStyles";
import { THEME_ICONS } from "./icons";
import { GLOBAL_CONTENT_WIDTH } from "./globalThemeConfig";

export const PAGE_GLOBAL_STYLES = makeStyles(() => ({
    globalPageStyles: {
        width: GLOBAL_CONTENT_WIDTH,
        padding: 0,
    },
}));

export const TABLE_PRIMARY = makeStyles((theme) => ({
    primaryTable: {
        width: "100%",
        tableLayout: "fixed",
        fontSize: 12,
        borderCollapse: "collapse",
        fontWeight: "normal",

        "& .thead, &.tbody": {
            display: "flex",
        },

        "& .thead": {
            flexDirection: "column",
        },

        "& thead": {
            // boxShadow: `0 0 6px 0 ${theme.palette.secondary.main}`,
        },

        "& .tbody": {
            flexDirection: "row",
        },

        "& tr": {
            "&:hover": {
                backgroundColor: "transparent !important",
            },
        },

        "& .tr": {
            display: "flex",
            alignItems: "center",
        },

        "& .td, & .th": {
            flex: "1",
            textAlign: "center",
        },

        "& tr:nth-child(even), & .tr:nth-child(even)": {
            // backgroundColor: theme.palette.secondary.superLight,
        },

        "& tbody tr, & .tbody .tr": {
            boxShadow: "0 0 8px 0 transparent",
        },

        "& th": {
            // borderTop: '2px solid rgba(0, 0, 0, 0.5)',
            borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
            fontSize: theme.typography.fontSize,
            height: "70px",
            textTransform: "uppercase",
            color: theme.palette.secondary.light,
        },

        "& .th": {
            // borderTop: '2px solid rgba(0, 0, 0, 0.5)',
            borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
            fontSize: theme.typography.fontSize,
            height: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            color: theme.palette.secondary.light,
        },

        "& td, & .td": {
            textAlign: "center",
            fontWeight: "inherit",
            fontSize: "inherit",
            // padding: '15px 15px 20px',
            padding: "7px",
            lineHeight: 1,
            verticalAlign: "middle",
            color: theme.palette.secondary.dark,
            wordBreak: "break-word",
        },
    },
}));

export const TABLE_SECONDARY = makeStyles((theme) => ({
    secondaryTable: {
        width: "100%",
        tableLayout: "fixed",
        fontSize: theme.typography.fontSize,
        borderCollapse: "collapse",
        fontWeight: theme.typography.fontWeightMedium,

        "& tr:nth-child(odd) td, & .tr:nth-child(odd) .td": {
            backgroundColor: theme.palette.general.grey,
        },

        "& th, & .th": {
            borderTop: `1px solid ${theme.palette.border.primary}`,
            borderBottom: `1px solid ${theme.palette.border.primary}`,
        },

        "& th, & td, & .th, & .td": {
            textAlign: "center",
            fontWeight: "inherit",
            fontSize: "inherit",
            padding: "8px 12px",
            lineHeight: 1.43,
            verticalAlign: "middle",
        },

        "& .small": {
            width: "8%",
        },
        "& .medium": {
            width: "30%",
        },
        "& .large": {
            width: "40%",
        },

        "& tfoot, & .tfoot": {
            borderTop: `1px solid ${theme.palette.border.primary}`,
        },

        "& tfoot tr td, & tfoot tr:nth-child(odd) td": {
            backgroundColor: "transparent",
            fontSize: 18,
            padding: "15px 8px",

            "& *": {
                fontSize: "inherit",
            },
        },
    },
}));

export const TABLE_SECONDARY_SCROLLABLE = makeStyles((theme) => ({
    scrolableTable: {
        "& .tbody": {
            maxHeight: 520,

            "&.-scroll": {
                overflowY: "scroll",
            },
        },

        "& .tr": {
            display: "flex",
            height: 52,

            "&.-no-height": {
                height: "auto",
            },

            "&.tfoot": {
                borderTop: `1px solid ${theme.palette.border.primary}`,
                height: "auto",

                "& .td": {
                    backgroundColor: "transparent",
                    fontSize: 18,
                    padding: "15px 8px",

                    "& > div": {
                        textAlign: "center",
                        flexDirection: "column",
                    },

                    "& *": {
                        fontSize: "inherit",
                    },
                },
            },
        },

        "& .td, & .th": {
            flex: 1,

            "&.flex-2": {
                flex: 2,
            },

            "&.flex-3": {
                flex: 2,
            },

            "& > div": {
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            },
        },

        "& .tr:last-child .td:first-child": {
            borderBottomLeftRadius: theme.shape.borderRadius * 2,
        },

        "& .tbody:not(.-scroll) .tr:last-child .td:last-child": {
            borderBottomRightRadius: theme.shape.borderRadius * 2,
        },
    },
}));

export const MAP_PRIMARY = [
    {
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5",
            },
        ],
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161",
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#f5f5f5",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#bdbdbd",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#dadada",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5",
            },
        ],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#c9c9c9",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e",
            },
        ],
    },
];

export const TRUCKS_TABLE_STYLES = makeStyles((theme) => ({
    jobOrderEditForm: {
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",

        "& .job-order-trucks-table": {
            "& th:nth-child(1)": {
                width: "40%",
            },
            "& th:nth-child(2):not(.sub-haulers)": {
                width: "20%",
            },
            "& th:nth-child(3):not(.start-date)": {
                width: "15%",
            },
            "& th.remove": {
                width: "10%",
            },
        },
        "& .job-order-more-actions": {
            marginLeft: 10,
        },
        "& .ext-ref-field": {
            width: 100,
        },

        "& .hauler-rate-field": {
            width: 80,
        },

        "& .pick-up-drop-off-section": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "14px 0",

            "& .async-select-wrapper": {
                width: "50%",
            },

            "& .pick-up": {
                marginRight: 8,
            },
            "&  .drop-off": {
                marginLeft: 8,
            },
        },
    },
}));

export const LOGIN_STYLES = makeStyles((theme) => ({
    loginStyles: {
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",

        "& form": {
            minHeight: 300,
            minWidth: 566,
            backgroundColor: "white",
            borderRadius: theme.shape.borderRadius * 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 40px",
        },
    },
    uiIconLogo: THEME_ICONS.logo,
    title: {
        fontSize: "21px",
        color: "rgb(80, 80, 80)",
    },
    bold: {
        fontWeight: 700,
        color: "rgba(0, 0, 0, 0.87)",
    },
}));

export const LIST_ITEM_STYLES = makeStyles((theme) => ({
    listItem: {
        padding: 15,
        border: `2px solid ${theme.palette.border.primary}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 10,
        backgroundColor: "#fff",
        textDecoration: "none",
        color: "#000",

        "&:hover": {
            cursor: "pointer",
            boxShadow: `0 0 6px 0 ${theme.palette.secondary.main}`,
        },
        "&.-is-loading": {
            "& h3, & a": {
                width: 134,
                height: 15,
                marginBottom: 6,
                backgroundColor: theme.palette.secondary.main,
                fontSize: 0,
                color: "inherit",
            },
            "& p": {
                width: 100,
                height: 8,
                backgroundColor: theme.palette.secondary.main,
                fontSize: "0 !important",
                marginBottom: 11,
            },
        },
        "&.-current": {
            borderColor: theme.palette.general.green,
            position: "relative",
        },

        "&.-not-my-project": {
            opacity: 0.7,

            "&.-current": {
                borderColor: theme.palette.border.primary,
            },
        },

        "& .icon": {
            marginRight: 15,
        },

        "& .ext-number, & .name": {
            width: "calc(100% - 16px)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            lineHeight: 1,
        },
    },
}));

export const PAGE_ACTIONS_STYLES = makeStyles((theme) => ({
    pageActions: {
        position: "relative",
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        minHeight: 55,

        "& > *": {
            // height: 60,
            padding: "10px 0",
        },

        "& .action-icons": {
            width: theme.icon.width,
            height: theme.icon.height,
            color: theme.palette.secondary.main,
            marginLeft: 16,
            cursor: "pointer",
        },
    },
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

export const DRAG_AND_DROP_STYLES = makeStyles((theme) => ({
    dragAndDrop: {
        "& .draggable-overlay-wrap": {
            overflow: "hidden",
            position: "relative",
            width: "100%",
            height: "100%",
        },
        "& .draggable-overlay": {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.7)",

            "&.-active": {
                opacity: 0.4,
            },
        },
        "& .draggable-text": {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 70,
            width: "80%",
            fontSize: 16,
            fontWeight: theme.typography.fontWeightMedium,
            padding: 20,
            borderRadius: theme.shape.borderRadius * 2,
            border: `3px dashed ${theme.palette.primary.main}`,
        },

        "& .full-height-text": {
            height: "100%",
        },
    },
}));

export const CARD_STYLES = makeStyles((theme) => ({
    cardStyles: {
        border: `1px solid ${theme.palette.border.primary}`,
        boxShadow: "0 0 4px 0 rgba(112, 112, 112, 0.5)",
        borderRadius: theme.shape.borderRadius * 2,
        marginBottom: 11,
        fontSize: 12,
        lineHeight: 1.67,
        fontWeight: theme.typography.fontWeightMedium,
        cursor: "pointer",
        width: "100%",
        // width: 'calc(100% - 8px)',
        transition: "box-shadow 0.3s ease-in-out",
        backgroundColor: "white",

        "&.is-unlimited": {
            paddingTop: 15,
        },

        "&:hover": {
            boxShadow: "0 0 10px 1px rgba(112, 112, 112, 0.5)",
        },

        "&.active": {
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.superLight,
        },

        "&.container-loader-wrap:after": {
            zIndex: 1,
        },

        "& .container-loader": {
            zIndex: 2,
        },

        "& .content": {
            padding: "0 50px 11px 17px",
        },
    },
}));

export const PRICE_PLANS = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        padding: 20,
        boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
    },
    paper: {
        padding: "24px",
    },
    header: {
        fontSize: 22,
    },
    title: {
        fontSize: 28,
        margin: "10px 0",
    },
    listItem: {
        display: "flex",
        listStyleType: "none",
        margin: "10px 0",
    },
    boldItem: {
        fontWeight: 700,
    },
    detailsContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 0",
    },
    item: {
        flex: "1",
    },
    secondItem: {
        display: "inline-block",
        color: "#868686",
        margin: "10px 0",
    },
    footerItem: {
        position: "absolute",
        bottom: 0,
        color: "#868686",
    },
    specialSymbol: {
        margin: "0 10px",
    },
}));
