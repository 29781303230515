import { SubmissionError } from "redux-form";
import * as ds from "../dataServers/settings";
import {
    ERROR_OFFLINE_HAULERS,
    ERROR_PREFERRED_HAULERS,
    RECEIVE_OFFLINE_HAULERS,
    RECEIVE_PREFERRED_HAULERS,
    REQUEST_OFFLINE_HAULERS,
    REQUEST_PREFERRED_HAULERS,
    RESET_OFFLINE_HAULERS_META,
    RESET_PREFERRED_HAULERS,
    RESET_PREFERRED_HAULERS_META,
    SUCCESS_OFFLINE_HAULERS,
    SUCCESS_PREFERRED_HAULERS,
    UPDATE_CUSTOMERS_SETTINGS_ACTIVE_TAB,
} from "../events/settings";
import { selectCompanyId, selectOfflineHaulers, selectPreferredHaulersFilterId } from "../selectors/index";
import { PROCESS_SERVER_ERROR } from "../constants/global";
import { getHasSubCompanies } from "./companies";
import { getNextPageForRequest, handleError } from "../helpers/global";
import { createRestrictedCustomer, fetchSubCompanies, updateCompanyProfile } from "../dataServers/companies";
import { deleteCompaniesCustomer, editCompaniesCustomer } from "../dataServers/trucks";

const requestPrefferedHaulers = () => ({ type: REQUEST_PREFERRED_HAULERS });
const receivePrefferedHaulers = (payload) => ({ type: RECEIVE_PREFERRED_HAULERS, payload });
const errorPreferredHaulers = (error) => ({ type: ERROR_PREFERRED_HAULERS, payload: error });
const resetPreferredHaulersMeta = () => ({ type: RESET_PREFERRED_HAULERS_META });
const successPreferredHaulers = (payload) => ({ type: SUCCESS_PREFERRED_HAULERS, payload });

export const resetPrefferedHaulers = () => ({ type: RESET_PREFERRED_HAULERS });

export const getPrefferedHaulers = () => async (dispatch, getState) => {
    try {
        dispatch(requestPrefferedHaulers());
        const { data: haulers } = await ds.getPreferredHaulers();
        dispatch(receivePrefferedHaulers(haulers));
    } catch (error) {
        dispatch(errorPreferredHaulers(PROCESS_SERVER_ERROR(error)));
        dispatch(resetPreferredHaulersMeta());
    }
};

export const deleteHauler = (hauler) => async (dispatch, getState) => {
    try {
        const filterId = selectPreferredHaulersFilterId(getState());
        dispatch(requestPrefferedHaulers());
        await ds.deleteHauler(filterId, hauler);
        dispatch(successPreferredHaulers("Hauler successfully deleted from preferred filter."));
        dispatch(resetPreferredHaulersMeta());
        dispatch(getPrefferedHaulers());
    } catch (error) {
        dispatch(errorPreferredHaulers(PROCESS_SERVER_ERROR(error)));
        dispatch(resetPreferredHaulersMeta());
    }
};

export const addHauler =
    ({ hauler }) =>
    async (dispatch, getState) => {
        try {
            const filterId = selectPreferredHaulersFilterId(getState());
            dispatch(requestPrefferedHaulers());
            await ds.addHauler(filterId, hauler);
            dispatch(successPreferredHaulers("Hauler successfully added to preferred filter."));
            dispatch(resetPreferredHaulersMeta());
            dispatch(getPrefferedHaulers());
        } catch (error) {
            dispatch(errorPreferredHaulers(PROCESS_SERVER_ERROR(error)));
            dispatch(resetPreferredHaulersMeta());
        }
    };

export const updateHauler = (hauler) => async (dispatch, getState) => {
    try {
        const filterId = selectPreferredHaulersFilterId(getState());
        dispatch(requestPrefferedHaulers());
        await ds.updateHauler(filterId, hauler);
        dispatch(successPreferredHaulers("Hauler rate was updated"));
        dispatch(resetPreferredHaulersMeta());
        dispatch(getPrefferedHaulers());
    } catch (error) {
        dispatch(errorPreferredHaulers(PROCESS_SERVER_ERROR(error)));
        dispatch(resetPreferredHaulersMeta());
    }
};

export const addOfflineHauler = (values) => async (dispatch) => {
    try {
        const createBody = {
            accountType: values.accountType.value,
            name: values.name,
            type: values.companyType.value,
            poType: values.poType.value,
            offlineHauler: true,
        };

        const updateBody = {
            address: values.address.label,
            timezone: {
                id: values.timezone.value,
                name: values.timezone.label,
            },
        };

        const {
            data: { id: companyId },
        } = await ds.addOfflineHauler(createBody);
        await ds.updateOfflineHauler(companyId, updateBody);
        dispatch(getPrefferedHaulers());
        dispatch(getHasSubCompanies());
        dispatch(getOfflineHaulers());
    } catch (e) {
        const errors = {};
        if (!e.message) throw new SubmissionError({ _error: e });
        if (typeof e.message === "string") throw new SubmissionError({ _error: e.message });
        if (e.message.name) errors.name = e.message.name[0];
        if (e.message.companyType) errors.companyType = e.message.companyType[0];
        if (e.message.accountType) errors.accountType = e.message.accountType[0];
        if (e.message.poType) errors.poType = e.message.poType[0];
        if (e.message.timezone) errors.timezone = e.message.timezone[0];
        if (e.message.address) errors.address = e.message.address[0];
        throw new SubmissionError(errors);
    }
};

const requestOfflineHaulers = () => ({ type: REQUEST_OFFLINE_HAULERS });
const receiveOfflineHaulers = (payload) => ({ type: RECEIVE_OFFLINE_HAULERS, payload });
const errorOfflineHaulers = (error) => ({ type: ERROR_OFFLINE_HAULERS, payload: error });
const resetOfflineHaulersMeta = () => ({ type: RESET_OFFLINE_HAULERS_META });
const successOfflineHaulers = (payload) => ({ type: SUCCESS_OFFLINE_HAULERS, payload });
export const updateCustomersActiveTab = (payload) => ({ type: UPDATE_CUSTOMERS_SETTINGS_ACTIVE_TAB, payload });

export const getOfflineHaulers =
    (concat = false) =>
    async (dispatch, getState) => {
        const state = getState();
        const offlineHaulers = selectOfflineHaulers(state);
        try {
            dispatch(requestOfflineHaulers());
            const nextPage = getNextPageForRequest(offlineHaulers);
            const params = {
                page: concat ? nextPage : 1,
            };
            const response = await fetchSubCompanies(params);
            const responseData = response.data;

            dispatch(
                receiveOfflineHaulers({
                    ...response,
                    data: concat ? [...offlineHaulers, ...responseData] : responseData,
                }),
            );
        } catch (error) {
            dispatch(errorOfflineHaulers(PROCESS_SERVER_ERROR(error)));
            dispatch(resetOfflineHaulersMeta());
        }
    };

export const makeCustomerRestricted = (customer, setSuccessMessage) => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);

    try {
        await createRestrictedCustomer(companyId, { name: customer.name });
        setSuccessMessage(["New restricted customer is created"]);
        try {
            await deleteCompaniesCustomer(companyId, customer.id);
        } catch (error) {
            await editCompaniesCustomer(companyId, customer.id, { customerName: `${customer.name} (Deprecated)` });
        }
    } catch (error) {
        throw new Error(handleError(error).message);
    }
};

export const updateCompanyShowPrice = (customerId, showPrice) => async (dispatch, getState) => {
    try {
        return await updateCompanyProfile(customerId, { restrictedCustomerShowPrice: showPrice });
    } catch (error) {
        throw new Error(handleError(error).message);
    }
};
