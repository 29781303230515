import PropTypes from "prop-types";
import _ from "lodash";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import clsx from "clsx";
import "moment-timezone";
import { isMobile } from "react-device-detect";

import { withStyles } from "@material-ui/core/styles";

import {
    JOB_ORDER_ID,
    MOBILE_REDIRECTION,
    PROJECT_ID,
    ROUTE_ANALYTICS,
    ROUTE_CONFIRM_EMAIL,
    ROUTE_DASHBOARD,
    ROUTE_FAQ,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_JOBS,
    ROUTE_LOGIN,
    ROUTE_MY_COMPANY,
    ROUTE_PROJECTS,
    ROUTE_RESET_PASSWORD,
    ROUTE_SING_UP_EXTERNAL,
    ROUTE_SING_UP_INTERNAL,
    SITE_ID,
    SMART_DISPATCH,
} from "./routes/globalRoutes";
import { authorizeUser } from "./actions/globalActions";
import { GLOBAL_STYLES } from "./styles/globalStyles";

import { selectCompanyType, selectIfIsUsesSmartDispatch } from "./selectors/index";
import { selectIfIsRestrictedCustomer } from "./selectors/user";

import Navigation from "./components/global/navigation";
import StoreNotification from "./components/core/storeNotification";
import TrucksSubNav from "./components/trucks/trucksSubNav";
import ProjectsSubNav from "./components/projects/projectsSubNav";
import { IOSRedirection, MobileLoginDrawer, MobileShortcut } from "./containers/MobileRedirection";
import Dashboard from "./containers/Dashboard";
import JobOrder from "./containers/JobOrder";
import Login from "./containers/Login";
import Jobs from "./containers/Jobs";
import MyCompany from "./containers/MyCompany";
import Trailers from "./containers/Trailers";
import MyCompanyTeams from "./containers/MyCompanyTeams";
import MyCompanyCompanies from "./containers/MyCompanyCompanies";
import DispatchJobOrders from "./containers/DispatchJobOrders";
import Tickets from "./containers/Tickets";
import DispatchJobBoardItem from "./containers/DispatchJobBoardItem";
import Projects from "./containers/Projects";
import Sites from "./containers/Sites";
import ForgotPassword from "./containers/ForgotPassword";
import Faq from "./containers/Faq";
import Devises from "./containers/Devises";
import { IS_INSPECTOR_USER } from "./constants/maps";
import { STRIPE_BASIC, STRIPE_PRO } from "./constants/accounts";
import ReportAndAnalyticks from "./containers/ReportAndAnalyticks";
import ConfirmEmail from "./containers/ConfirmEmail";
import JobBoard from "./containers/JobBoard";
import TrucksActivity from "./components/reportAndAnalytic/trucksActivity";
import JumpToTopButton from "./components/global/jumpToTopButton";
import ReportWrapper from "./components/reportAndAnalytic/reporting/reportWrapper";
import { signUpRedirectUrl } from "./config";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import SingUp from "./containers/SingUp";
import TermsDialogWrapper from "./components/dashboard/termsDialogWrapper";
import CreditCardDialogWrapper from "./components/dashboard/CreditCardDialog/сreditCardDialogWrapper";
import UpdatePasswordPage from "./containers/UpdatePassword";
import SmartDispatchPage from "./containers/SmartDispatchPage";
import PhoneDialogWrapper from "./components/dashboard/PhoneDialog/phoneDialogWrapper";

import { RestrictedCustomerRoutes } from "./routes";
import ProtectedSettings from "./containers/ProtectedSettings";

export const GlobalContext = React.createContext();
const styles = (theme) => ({
    appComponent: {
        width: "100%",
        position: "relative",
        paddingBottom: isMobile ? 95 : 0,
    },
    "@global": {
        ...GLOBAL_STYLES(theme),
    },
});

GlobalContext.displayName = "GlobalContext";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE}`);

class TruckIT extends Component {
    constructor(props) {
        super(props);
        this.state = { backend: Backend };
    }

    componentWillUnmount() {
        this.setState({
            backend: null,
        });
    }

    isStripeAccount = () => {
        const accountType = this.props.account.company && this.props.account.company.accountType;

        return accountType === STRIPE_BASIC || accountType === STRIPE_PRO;
    };

    render() {
        const { classes, enqueueSnackbar, account, history, isUsesSmartDispatch, isRestrictedCustomer } = this.props;
        const showSmartDispatchPage = isUsesSmartDispatch;

        const { backend } = this.state;

        const renderInspectorComponents = () => {
            if (account.hasTemporaryPassword) {
                return (
                    <>
                        <Redirect from={"*"} to={ROUTE_RESET_PASSWORD} />
                        <Route path={ROUTE_RESET_PASSWORD} exact>
                            <UpdatePasswordPage />
                        </Route>
                    </>
                );
            }

            return (
                <React.Fragment>
                    <PhoneDialogWrapper />
                    <TermsDialogWrapper />
                    <Navigation currentUrl={history.location.pathname} />
                    <Switch>
                        <Route path={ROUTE_ANALYTICS.ROOT} component={ReportAndAnalyticks}>
                            <Route path={ROUTE_ANALYTICS.TICKETS} component={ReportAndAnalyticks} />
                            <Route path={ROUTE_ANALYTICS.REPORT} component={ReportWrapper} />
                        </Route>
                        <Route path={ROUTE_JOBS.ROOT}>
                            <Route exact={false} path={[ROUTE_JOBS.JOB_ORDER_BY_ID, ROUTE_JOBS.MY_JOBS]}>
                                <Jobs>
                                    <JobOrder />
                                </Jobs>
                            </Route>
                        </Route>
                        <Redirect from={ROUTE_LOGIN} to={ROUTE_ANALYTICS.TICKETS} />
                    </Switch>
                </React.Fragment>
            );
        };

        const renderCustomerAndTruckerComponents = () => {
            if (account.hasTemporaryPassword) {
                return (
                    <>
                        <Redirect from={"*"} to={ROUTE_RESET_PASSWORD} />
                        <Route path={ROUTE_RESET_PASSWORD} exact>
                            <UpdatePasswordPage />
                        </Route>
                    </>
                );
            }

            return (
                <React.Fragment>
                    {this.isStripeAccount() && <CreditCardDialogWrapper />}
                    <PhoneDialogWrapper />
                    <TermsDialogWrapper />
                    <Navigation currentUrl={history.location.pathname} />
                    <MobileShortcut />
                    <Switch>
                        <Route path={ROUTE_DASHBOARD} component={Dashboard} />
                        <Route path={ROUTE_FAQ} component={Faq} />
                        <Route path={ROUTE_PROJECTS.ROOT} component={Projects}>
                            <ProjectsSubNav />
                            <Route
                                path={[`${ROUTE_PROJECTS.PROJECTS}${PROJECT_ID}`, ROUTE_PROJECTS.PROJECTS]}
                                component={Projects}
                            />
                            <Route
                                path={[`${ROUTE_PROJECTS.SITES}${SITE_ID}`, ROUTE_PROJECTS.SITES]}
                                component={Sites}
                            />
                        </Route>
                        <Route path={ROUTE_ANALYTICS.ROOT} component={ReportAndAnalyticks}>
                            <Route path={ROUTE_ANALYTICS.TICKETS} component={ReportAndAnalyticks} />
                            <Route path={ROUTE_ANALYTICS.TRUCK_ACTIVITY} component={TrucksActivity} />
                            <Route path={ROUTE_ANALYTICS.REPORT} component={ReportWrapper} />
                        </Route>
                        <Route path={ROUTE_MY_COMPANY.ROOT} component={MyCompany}>
                            <TrucksSubNav />
                            <Route path={ROUTE_MY_COMPANY.MY_FLEET} component={MyCompany} />
                            <Route path={ROUTE_MY_COMPANY.TRAILERS} component={Trailers} />
                            <Route path={ROUTE_MY_COMPANY.MY_TEAM} component={MyCompanyTeams} />
                            <Route path={ROUTE_MY_COMPANY.ALL_COMPANIES} component={MyCompanyCompanies} />
                            <Route path={ROUTE_MY_COMPANY.DEVISES} component={Devises} />
                            <Route path={ROUTE_MY_COMPANY.SETTINGS} component={ProtectedSettings} />
                        </Route>
                        <Route path={ROUTE_FAQ} component={Faq} />
                        <Route path={ROUTE_JOBS.ROOT}>
                            <Route exact={false} path={[ROUTE_JOBS.JOB_ORDER_BY_ID, ROUTE_JOBS.MY_JOBS]}>
                                <Jobs>
                                    <JobOrder />
                                </Jobs>
                            </Route>
                            <Route
                                exact={false}
                                path={[`${ROUTE_JOBS.JOB_BOARD}${JOB_ORDER_ID}`, ROUTE_JOBS.JOB_BOARD]}
                            >
                                <JobBoard>
                                    <JobOrder />
                                </JobBoard>
                            </Route>
                            {showSmartDispatchPage && (
                                <Route
                                    path={[
                                        SMART_DISPATCH.INITIAL,
                                        SMART_DISPATCH.ROOT,
                                        SMART_DISPATCH.BUNDLE,
                                        SMART_DISPATCH.BUNDLE_ID,
                                        SMART_DISPATCH.TRUCK_ID,
                                    ]}
                                    exact
                                >
                                    <SmartDispatchPage />
                                </Route>
                            )}
                            <Route path={ROUTE_JOBS.TICKETS} component={Tickets} />
                        </Route>
                        <Route
                            path={[
                                `${ROUTE_JOBS.DISPATCH_JOB_ORDERS}${JOB_ORDER_ID}`,
                                ROUTE_JOBS.DISPATCH_JOB_ORDERS,
                                ROUTE_JOBS.DISPATCH_JOB_ORDERS_NEW,
                                ROUTE_JOBS.REQUEST_JOB_ORDER,
                                ROUTE_JOBS.REQUEST_JOB_ORDER_BY_ID,
                            ]}
                            exact
                        >
                            <DispatchJobOrders />
                        </Route>
                        <Route
                            path={[
                                `${ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM}${JOB_ORDER_ID}`,
                                ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM,
                            ]}
                        >
                            <DispatchJobBoardItem />
                        </Route>
                        <Route path={MOBILE_REDIRECTION}>
                            <IOSRedirection />
                        </Route>
                        <Redirect from={ROUTE_LOGIN} to={ROUTE_DASHBOARD} />
                        <Redirect from={ROUTE_CONFIRM_EMAIL} to={ROUTE_DASHBOARD} />
                        <Redirect from={ROUTE_SING_UP_EXTERNAL} to={ROUTE_DASHBOARD} />
                        <Redirect from={ROUTE_SING_UP_INTERNAL} to={ROUTE_DASHBOARD} />
                        <Redirect from={ROUTE_FORGOT_PASSWORD} to={ROUTE_DASHBOARD} />
                    </Switch>
                </React.Fragment>
            );
        };
        const renderEmptyAccountDataComponents = () => {
            return (
                <>
                    <MobileLoginDrawer />
                    <Switch>
                        <Route path={ROUTE_LOGIN} exact>
                            <Login redirectFrom={history.location.pathname} />
                        </Route>
                        <Route path={ROUTE_FORGOT_PASSWORD} exact>
                            <ForgotPassword />
                        </Route>
                        <Route
                            exact
                            path={ROUTE_SING_UP_EXTERNAL}
                            render={() => {
                                window.location = signUpRedirectUrl;

                                return null;
                            }}
                        />
                        <Route path={ROUTE_SING_UP_INTERNAL}>
                            <SingUp />
                        </Route>
                        <Route path={ROUTE_CONFIRM_EMAIL}>
                            <ConfirmEmail />
                        </Route>
                        <Route path={MOBILE_REDIRECTION}>
                            <IOSRedirection />
                        </Route>
                        <Redirect from="*" to={ROUTE_LOGIN} />
                    </Switch>
                </>
            );
        };

        const renderLoggedInUserComponents = () => {
            if (IS_INSPECTOR_USER(account.role)) {
                return renderInspectorComponents();
            }

            return renderCustomerAndTruckerComponents();
        };

        const renderAppComponents = () => {
            if (_.isEmpty(account)) {
                return renderEmptyAccountDataComponents();
            }
            if (isRestrictedCustomer) {
                return <RestrictedCustomerRoutes />;
            }

            return renderLoggedInUserComponents();
        };

        const renderApp = () => {
            return <Router history={history}>{renderAppComponents()}</Router>;
        };

        return (
            <div style={{ height: "100%" }}>
                <GlobalContext.Provider value={{ enqueueSnackbar }}>
                    <div className={clsx(classes.appComponent)}>
                        <StoreNotification />
                        <DndProvider backend={backend}>{renderApp()}</DndProvider>
                        <JumpToTopButton />
                    </div>
                </GlobalContext.Provider>
            </div>
        );
    }
}

TruckIT.propTypes = {
    classes: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isUsesSmartDispatch: PropTypes.bool,
    authorizeUserWithData: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    companyType: PropTypes.number,
    isRestrictedCustomer: PropTypes.bool,
};

export default withStyles(styles)(
    withSnackbar(
        withRouter(
            connect(
                (state) => {
                    return {
                        account: state.account,
                        isUsesSmartDispatch: selectIfIsUsesSmartDispatch(state),
                        companyType: selectCompanyType(state),
                        isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
                    };
                },
                (dispatch) => ({
                    authorizeUserWithData: (name) => {
                        dispatch(authorizeUser(name));
                    },
                }),
            )(TruckIT),
        ),
    ),
);
