import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const inputHeight = 17;

const useStyles = makeStyles((theme) => ({
    customSwitch: {
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        color: theme.palette.secondary.dark,
        fontWeight: theme.typography.fontWeightMedium,
        cursor: "pointer",

        "&.-disabled": {
            cursor: "not-allowed",

            "& .input": {
                backgroundColor: theme.palette.secondary.dark,
            },
        },

        "& .input": {
            marginRight: 10,
            height: 25,
            width: 45,
            borderRadius: 45,
            backgroundColor: theme.palette.secondary.main,
            position: "relative",

            "& input": {
                display: "none",
            },

            "&.checked": {
                backgroundColor: theme.palette.general.green,
            },

            "& input:checked + .switch": {
                transform: "translate(145% , 3px)",
            },

            "& .switch": {
                height: inputHeight,
                width: inputHeight,
                borderRadius: inputHeight,
                backgroundColor: "#fff",
                position: "absolute",
                top: 1,
                left: 0,
                transition: "transform ease 0.3s",
                transform: "translate(25%, 3px)",
            },
        },
    },
    title: {
        color: theme.palette.secondary.main,
        marginTop: "10px",
    },
}));

const CustomSwitch = (props) => {
    const classes = useStyles();
    const { input, label, type, disabled, className, onChange, onMouseEnter, containerStyle, title } = props;
    const onChangeHandler = (e) => {
        input.onChange(e);
        onChange && onChange(e);
    };

    return (
        <Box display="inline-block" className="custom-switch" style={containerStyle} onMouseEnter={onMouseEnter}>
            <label
                htmlFor={`${input.name}-${input.value}`}
                className={clsx(classes.customSwitch, className, disabled && "-disabled")}
            >
                <div className={clsx("input", input.value && "checked")}>
                    <input
                        type={type}
                        {...input}
                        onChange={onChangeHandler}
                        id={`${input.name}-${input.value}`}
                        disabled={disabled}
                    />
                    <span className="switch" />
                </div>
                {label}
            </label>
            {title && <div className={classes.title}>{title}</div>}
        </Box>
    );
};

CustomSwitch.propTypes = {
    input: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    containerStyle: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

CustomSwitch.defaultProps = {
    disabled: false,
};

export default withRouter(CustomSwitch);
