import React from "react";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";

import { Container, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { ActionLink, SimpleLink } from "../core/link";
import SearchAndFilters from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";

const useStyles = makeStyles(() => ({
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const TrailersFilters = (props) => {
    const classes = useStyles();
    const { form, openModal, updateListByFilters } = props;
    const wrapper = PAGE_GLOBAL_STYLES();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();

    return (
        <Container className={wrapper.globalPageStyles}>
            <div className={pageActionsStyles.pageActions}>
                <Box className={classes.headerBox}>
                    <ActionLink>
                        <AddCircleOutlineIcon />
                        <SimpleLink onClick={openModal}>Create new trailer</SimpleLink>
                    </ActionLink>
                    <SearchAndFilters form={form} updateList={updateListByFilters} />
                </Box>
            </div>
        </Container>
    );
};

const TRAILERS_FILTERS = "TRAILERS FILTERS";

export default withRouter(
    reduxForm({
        form: TRAILERS_FILTERS,
    })(TrailersFilters),
);
