import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { PropTypes } from "prop-types";
import moment from "moment";

import clsx from "clsx";

import { getMaxYear } from "../../../helpers/date";
import Loader from "../../core/loader";
import ErrorNotification from "../../core/notification";

import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import "./styles.css";

const localizer = momentLocalizer(moment);

const VIEW = {
    MONTH: "month",
    DAY: "day",
};

const CalendarView = ({
    id,
    timezone,
    getEvents,
    resetEvents,
    events,
    loading,
    error,
    components,
    disabledDays,
    setEventPropGetter,
    additional,
}) => {
    const [view, setView] = useState(VIEW.MONTH);
    const [day, setDay] = useState();
    const [dateRange, setDateRange] = useState();

    useEffect(() => {
        if (view === VIEW.MONTH) {
            getEvents(id, dateRange);
        }
        if (view === VIEW.DAY) {
            getEvents(id, { start: day, end: day });
        }
    }, [view, dateRange, day]);

    useEffect(() => {
        return () => {
            resetEvents && resetEvents();
        };
    }, []);

    const setViewTab = (view) => setView(view);

    const setDayPropGetter = (day) => {
        const isSuspend =
            disabledDays &&
            !!disabledDays.filter(({ start, end }) =>
                moment(day).isBetween(
                    moment(start).format("MM/DD/YYYY"),
                    moment(end || getMaxYear()).format("MM/DD/YYYY"),
                    null,
                    "[]",
                ),
            ).length;
        if (isSuspend) {
            return {
                style: {
                    backgroundColor: "#e6e6e6",
                },
            };
        }
    };

    return (
        <div className={loading && clsx(LOADER_WHITE_OVERLAY)} style={{ height: 700 }}>
            {loading && <Loader />}
            {additional && additional.renderFlags()}
            <Calendar
                components={components}
                dayPropGetter={setDayPropGetter}
                eventPropGetter={setEventPropGetter}
                localizer={localizer}
                views={{ month: true, day: true }}
                events={events}
                step={60}
                defaultDate={new Date()}
                onRangeChange={(date) => {
                    setDateRange(date);
                }}
                onNavigate={(day) => {
                    setDay(day);
                }}
                onView={setViewTab}
                formats={{
                    timeGutterFormat: (date) => moment(date).format("hh:mm A"),
                    eventTimeRangeFormat: (date) => moment(date).format("hh:mm A"),
                }}
            />
            {error && <ErrorNotification error={error} />}
        </div>
    );
};

CalendarView.propTypes = {
    id: PropTypes.number.isRequired,
    getEvents: PropTypes.func.isRequired,
    components: PropTypes.object.isRequired,
    resetEvents: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    disabledDays: PropTypes.array,
    setEventPropGetter: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    additional: PropTypes.object.isRequired,
};

export default CalendarView;

// https://jquense.github.io/react-big-calendar/examples/index.html#customView
