import React, { useEffect } from "react";
import { useHistory } from "react-router";

export function useBrowserButtonHandler(onBack, isFire) {
    const history = useHistory();

    useEffect(() => {
        if (isFire) {
            const unlisten = history.listen((location) => {
                if (history.action === "POP") {
                    history.push(location.pathname);
                    onBack && onBack();
                }
            });

            return unlisten;
        }
    }, []);
}
