import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { THEME_ICONS } from "../../../styles/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    uiIconTrailer: {
        ...THEME_ICONS.trailerFilledLarge,
    },
    trailersList: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 300,
    },
}));

export const TicketTrailers = ({ trailers, additionalFields }) => {
    const classes = useStyles();
    const trailerNames = trailers && trailers.map((i) => i.trailerName).join(", ");

    const trailersQuantity = additionalFields && additionalFields[TRAILERS_QUANTITY];

    return (
        <Grid item>
            <Tooltip title={trailerNames}>
                <Grid container alignItems={"center"}>
                    <div className={classes.uiIconTrailer} />
                    <div className={classes.trailersList}>
                        {trailers.map((trailer, index) => {
                            const trailerQuantity =
                                trailersQuantity &&
                                trailersQuantity.find(
                                    (trailerQuantity) => trailerQuantity.trailer_id === trailer.trailerId,
                                );

                            return (
                                <div className="--text-right">
                                    {trailer.trailerName}
                                    {!!trailerQuantity && `(Quantity: ${trailerQuantity.quantity})`}
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            </Tooltip>
        </Grid>
    );
};

export const TRAILERS_QUANTITY = "trailers_quantity";

TicketTrailers.propTypes = {
    trailers: PropTypes.array,
    additionalFields: PropTypes.any,
};
