import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Tooltip from "@material-ui/core/Tooltip";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Grid from "@material-ui/core/Grid";
import { getRandomColor, getSiteEditRoute, toCapitalLetter } from "../../helpers/global";
import { DividerThin } from "../core/divider";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PinDropIcon from "@material-ui/icons/PinDrop";
import HomeIcon from "@material-ui/icons/Home";
import BusinessIcon from "@material-ui/icons/Business";
import Box from "@material-ui/core/Box";
import SiteMap from "./siteMap";
import { IS_ADMIN_USER, SITES_STATUS_ARCHIVE } from "../../constants/maps";
import SiteForm, { SITE_FORM } from "./siteForm";
import AlertZoneCustomPopUp from "./alertZoneCustomPopUp";
import AppModal from "../core/modal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import * as SiteActions from "../../actions/sites";
import { setAlertZoneCoordinates } from "../../actions/sites";
import {
    createAlertZone,
    getSiteById,
    removeAlertZone,
    updateAlertZoneById,
    updateSite,
} from "../../dataServers/sites";
import { ALERT_ZONE_TYPE_CIRCLE, ALERT_ZONE_TYPE_POLYGON, getAlertZoneType } from "../../constants/sites";
import BorderOuterIcon from "@material-ui/icons/BorderOuter";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal from "../core/confirmationModal";
import SiteDetailsStatsWrapper from "./SiteDetailsStatsWrapper";

const useStyles = makeStyles((theme) => ({
    projectInfo: {
        padding: "10px 20px 20px 20px",
        "& h3": {
            color: theme.palette.secondary.dark,
        },
        "& .text-offset": {
            paddingLeft: 36,
            margin: "5px 0 15px",
        },
        "& .button-offset": {
            paddingRight: 30,
        },
        "& .info-offset": {
            paddingLeft: 30,
            margin: "10px 0",
        },
    },
    uiIcon: {
        width: 25,
        height: 25,
        position: "relative",
        top: 8,
        marginRight: 11,
    },
    uiIconGrey: {
        color: theme.palette.secondary.dark,
    },
    boxContent: {
        "& .form-view": {
            width: 270,
            margin: "10px 36px",
        },
    },
    notes: {
        whiteSpace: "normal",
        paddingLeft: theme.icon.width + 11,
    },
    headerButton: {
        marginLeft: 15,
        [theme.breakpoints.down("817")]: {
            marginTop: 10,
        },
    },
}));

const NewAlertZone = React.forwardRef(({ cancelAlertZoneCreate, handleAlertZoneCreate }, ref) => {
    return (
        <div ref={ref}>
            <AlertZoneCustomPopUp handleClose={cancelAlertZoneCreate} handleSave={handleAlertZoneCreate} />
        </div>
    );
});

NewAlertZone.propTypes = {
    cancelAlertZoneCreate: PropTypes.func,
    handleAlertZoneCreate: PropTypes.func,
};

function SiteDetails(props) {
    const {
        account,
        alertZoneCoordinates,
        currentSite,
        setCurrentSite,
        setAlertZoneCoordinates,
        getSites,
        newZoneData,
        onSiteSelection,
        history,
    } = props;
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const isArchivedSite = currentSite.status === SITES_STATUS_ARCHIVE;
    const isArchiveButtonDisabled = currentSite.isPublic && !isAdmin;

    const classes = useStyles();
    const [showEditSiteModal, setShowEditSiteModal] = useState(false);
    const [onEmitSave, setEmitSave] = useState(false);
    const [error, setError] = useState();
    const [onAddAlertZone, setOnAddAlertZone] = useState(false);
    const [onEditAlertZoneId, setOnEditAlertZone] = useState();
    const [zoneForRemove, setZoneForRemove] = useState(null);
    const [siteForRemove, setSiteForRemove] = useState(null);
    const [zoneConfirmationMessage, setZoneConfirmationMessage] = useState(null);
    const [siteConfirmationMessage, setSiteConfirmationMessage] = useState(null);
    const [state, setState] = useState({
        isLoading: false,
        highlightedZoneId: null,
        colors: [],
        activeSiteZoneId: null,
    });
    const { isLoading, highlightedZoneId, colors, activeSiteZoneId } = state;

    const onMessageClose = () => {
        setError(null);
    };
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const addNewAlertZone = () => {
        setOpen(true);
        setOnAddAlertZone(true);
    };

    const cancelAlertZoneCreate = () => {
        setOnAddAlertZone(false);
        setAlertZoneCoordinates(null);
    };

    const loadSites = async () => {
        const result = await getSites(false, null);
        if (result) {
            const site = result.data && result.data.length && result.data[0];
            onSiteSelection(site);
            history.push(getSiteEditRoute(site.id));
        }
    };

    const handleAlertZoneCreate = (values) => {
        const { name } = values;

        if (newZoneData && newZoneData.markers.length === 1) {
            if (!values.radius) {
                setError(["Please specify radius or add full Alert Zone Coordinates"]);

                return Promise.resolve();
            }
            // https://github.com/TruckITllc/truckit-frontend/issues/442
        } else if (!alertZoneCoordinates) {
            setError(["For save add full Alert Zone Coordinates"]);

            return Promise.resolve();
        }

        return createAlertZone({
            name: name,
            coordinates:
                values.radius && newZoneData
                    ? [[newZoneData.markers[0].lat(), newZoneData.markers[0].lng()]]
                    : alertZoneCoordinates.map((coords) => [coords.lat(), coords.lng()]),
            siteId: currentSite.id,
            type: values.radius ? ALERT_ZONE_TYPE_CIRCLE : ALERT_ZONE_TYPE_POLYGON,
            radius: values.radius ? +values.radius : undefined,
        })
            .then(() => {
                setAlertZoneCoordinates(null);

                return getSiteZones(currentSite.id);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const getSiteZones = (siteId) => {
        return getSiteById(siteId, account)
            .then((data) => {
                if (currentSite.alertZoneRadius) {
                    data.alertZoneRadius = +currentSite.alertZoneRadius;
                }

                setState({
                    ...state,
                    isLoading: false,
                });
                setCurrentSite(data);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const highlighteZone = (id) => {
        setState({
            ...state,
            highlightedZoneId: id,
        });
    };

    const setActiveZone = (id) => {
        setState({
            ...state,
            activeSiteZoneId: id,
        });
    };

    const openRemoveConfirmationModal = (zone) => {
        setZoneForRemove(zone);
        setZoneConfirmationMessage("Are you sure you want to remove this alert zone?");
    };

    const openRemoveSiteConfirmationModal = (site) => {
        setSiteForRemove(site);
        if (isArchivedSite) setSiteConfirmationMessage("Are you sure you want to unarchive this site?");
        else setSiteConfirmationMessage("Are you sure you want to archive this site?");
    };

    const closeRemoveConfirmationModal = () => {
        setZoneForRemove(null);
        setZoneConfirmationMessage(null);
    };

    const closeRemoveSiteConfirmationModal = () => {
        setSiteForRemove(null);
        setSiteConfirmationMessage(null);
    };

    const removeZone = (data) => {
        setState({
            ...state,
            isLoading: true,
        });

        removeAlertZone({ name: data.id })
            .then(() => {
                closeRemoveConfirmationModal();

                return getSiteZones(data.siteId);
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const removeSite = (data) => {
        setState({
            ...state,
            isLoading: true,
        });

        updateSite(data.id, { status: isArchivedSite ? 1 : 3 })
            .then(() => {
                closeRemoveSiteConfirmationModal();

                loadSites();
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const saveEditOfZone = (data) => {
        setState({
            ...state,
            isLoading: true,
        });

        updateAlertZoneById(data.id, {
            coordinates: data.paths.map((i) => [i.lat, i.lng]),
            type: data.type,
        })
            .then(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setOnEditAlertZone(null);
                setEmitSave(false);
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    useEffect(() => {
        setOnAddAlertZone(false);
        setState({
            ...state,
            colors: currentSite.alertZones ? currentSite.alertZones.map(() => getRandomColor()) : [],
        });
    }, [currentSite]);

    const iconStyle = {
        marginLeft: 10,
        width: 20,
        height: 20,
        cursor: isArchivedSite || isArchiveButtonDisabled ? "no-drop" : "pointer",
        color: (isArchivedSite || isArchiveButtonDisabled) && "#868686",
    };

    return (
        <Grid className={clsx(classes.projectInfo, isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            <h1>
                <Grid component="div" container>
                    <Grid item xs={4}>
                        {currentSite.name}
                    </Grid>
                    <Grid container xs={8} justify={"flex-end"} className="--text-right">
                        {
                            <SecondaryButton
                                size={"small"}
                                disabled={isArchivedSite || isArchiveButtonDisabled}
                                onClick={() => setShowEditSiteModal(true)}
                            >
                                Edit
                            </SecondaryButton>
                        }
                        {
                            <SecondaryButton
                                size={"small"}
                                className={classes.headerButton}
                                onClick={() => openRemoveSiteConfirmationModal(currentSite)}
                                disabled={isArchiveButtonDisabled}
                            >
                                {isArchivedSite ? "UnArchive" : "Archive"}
                            </SecondaryButton>
                        }
                    </Grid>
                </Grid>
            </h1>
            <DividerThin marginTop={1} />
            <SiteMap
                height="45vh"
                colors={colors}
                removeZone={openRemoveConfirmationModal}
                setOnEditAlertZone={setOnEditAlertZone}
                saveEditOfZone={saveEditOfZone}
                onEmitSave={onEmitSave}
                currentSite={currentSite}
                setAlertZoneCoordinates={setAlertZoneCoordinates}
                highlightedZoneId={highlightedZoneId}
                activeSiteZoneId={activeSiteZoneId}
                onEditAlertZoneId={onEditAlertZoneId}
                drawingEnabled={onAddAlertZone}
            />
            <Grid container alignItems={"center"} style={{ marginTop: 8 }}>
                <Grid item xs={2}>
                    <h2>Site Info</h2>
                </Grid>
                <Grid item container xs={10} justify={"flex-end"} alignItems={"center"}>
                    {onAddAlertZone ? (
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="You can create a polygon by selecting several points on the map or create a circle by selecting its center and specifying a radius"
                                >
                                    <NewAlertZone
                                        cancelAlertZoneCreate={cancelAlertZoneCreate}
                                        handleAlertZoneCreate={handleAlertZoneCreate}
                                    />
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    ) : (
                        <SecondaryButton
                            size={"medium"}
                            disabled={isArchivedSite || isArchiveButtonDisabled}
                            onClick={addNewAlertZone}
                        >
                            <AddCircleOutlineIcon />
                            Add Zone
                        </SecondaryButton>
                    )}
                </Grid>
            </Grid>
            <DividerThin marginTop={1} />
            <Grid component="div" container className="info-offset">
                <Grid item xs={6}>
                    <h3>
                        <AssignmentIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                    </h3>
                    <Box className={classes.boxContent}>
                        <p className={classes.notes}>{currentSite.notes}</p>
                    </Box>
                    <h3>
                        <PinDropIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        Site Type
                    </h3>
                    <Box className={classes.boxContent}>
                        <p className="text-offset">{toCapitalLetter(currentSite.type)}</p>
                    </Box>
                    <h3>
                        <HomeIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        Site Address
                    </h3>
                    <Box className={classes.boxContent}>
                        <p className="text-offset">{currentSite.address}</p>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    {isAdmin && currentSite.propertyOwner && (
                        <React.Fragment>
                            <h3>
                                <BusinessIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                                Property Owner
                            </h3>
                            <Box>
                                <p className="text-offset">{currentSite.propertyOwner.name}</p>
                            </Box>
                        </React.Fragment>
                    )}
                    {!_.isEmpty(currentSite.alertZones) && (
                        <React.Fragment>
                            <h3>
                                <BorderOuterIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                                Zones
                            </h3>
                            <br />
                            <Box className={classes.boxContent}>
                                {currentSite.alertZones.map((zone, index) => {
                                    return (
                                        <Grid
                                            container
                                            alignItems={"center"}
                                            key={index}
                                            className={clsx("text-offset")}
                                        >
                                            <div
                                                key={index}
                                                className={clsx(
                                                    !isArchivedSite ||
                                                        (!isArchiveButtonDisabled &&
                                                            highlightedZoneId === zone.id &&
                                                            "--green-text"),
                                                )}
                                                style={{
                                                    display: "inline-block",
                                                    width: "auto",
                                                    cursor:
                                                        isArchivedSite || isArchiveButtonDisabled
                                                            ? "no-drop"
                                                            : "pointer",
                                                    color: (isArchivedSite || isArchiveButtonDisabled) && "#868686",
                                                }}
                                                onClick={() =>
                                                    !isArchivedSite &&
                                                    !isArchiveButtonDisabled &&
                                                    setActiveZone(activeSiteZoneId === zone.id ? null : zone.id)
                                                }
                                                onMouseOver={() =>
                                                    !isArchivedSite &&
                                                    !isArchiveButtonDisabled &&
                                                    highlighteZone(zone.id)
                                                }
                                                onMouseOut={() =>
                                                    !isArchivedSite && !isArchiveButtonDisabled && highlighteZone(null)
                                                }
                                            >
                                                {zone.name}
                                            </div>
                                            <DeleteOutlineIcon
                                                disabled={isArchivedSite || isArchiveButtonDisabled}
                                                onClick={() =>
                                                    !isArchivedSite &&
                                                    !isArchiveButtonDisabled &&
                                                    openRemoveConfirmationModal(zone)
                                                }
                                                style={iconStyle}
                                            />
                                            {getAlertZoneType(zone.type) === ALERT_ZONE_TYPE_POLYGON &&
                                                (onEditAlertZoneId === zone.id ? (
                                                    <SaveIcon
                                                        onClick={() =>
                                                            !isArchivedSite &&
                                                            !isArchiveButtonDisabled &&
                                                            setEmitSave(zone.id)
                                                        }
                                                        style={iconStyle}
                                                    />
                                                ) : (
                                                    <EditIcon
                                                        onClick={() =>
                                                            !isArchivedSite &&
                                                            !isArchiveButtonDisabled &&
                                                            setOnEditAlertZone(zone.id)
                                                        }
                                                        disabled={true}
                                                        style={iconStyle}
                                                    />
                                                ))}
                                        </Grid>
                                    );
                                })}
                            </Box>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            {!currentSite.grantedAccess && <SiteDetailsStatsWrapper siteId={currentSite.id} errorHandler={setError} />}
            <ConfirmationModal
                isOpen={!!zoneConfirmationMessage}
                question={zoneConfirmationMessage}
                yesHandler={() => removeZone(zoneForRemove)}
                noHandler={closeRemoveConfirmationModal}
            />
            <ConfirmationModal
                isOpen={!!siteConfirmationMessage}
                question={siteConfirmationMessage}
                yesHandler={() => removeSite(siteForRemove)}
                noHandler={closeRemoveSiteConfirmationModal}
            />
            <AppModal
                isOpen={showEditSiteModal}
                modalStyles={{ width: 600 }}
                form={SITE_FORM}
                closeModal={() => setShowEditSiteModal(false)}
            >
                <SiteForm
                    closeModal={() => setShowEditSiteModal(false)}
                    defaultCoordinates={{
                        lat: parseFloat(currentSite.latitude),
                        lng: parseFloat(currentSite.longitude),
                    }}
                    initialValues={{
                        id: currentSite.id,
                        name: currentSite.name,
                        address: {
                            value: 1,
                            label: currentSite.address,
                        },
                        coordinates: currentSite.latitude + ", " + currentSite.longitude,
                        notes: currentSite.notes,
                        city: currentSite.cityName,
                        postalCode: currentSite.postalCode,
                        locationType: currentSite.type && {
                            value: 1,
                            label: toCapitalLetter(currentSite.type),
                        },
                        propertyOwner: currentSite.propertyOwner && {
                            value: currentSite.propertyOwner.id,
                            label: currentSite.propertyOwner.name,
                        },
                        company: currentSite.company && {
                            value: currentSite.company.id,
                            label: currentSite.company.name,
                        },
                        alertZone: !!currentSite.alertZoneRadius,
                        radius: currentSite.alertZoneRadius,
                        isAirTicketEnabled: currentSite.isAirTicketEnabled,
                        isPublic: currentSite.isPublic,
                        isSubSite: !!currentSite.parentSite,
                        parentSite: !!currentSite.parentSite && {
                            label: currentSite.parentSite.name,
                            value: currentSite.parentSite.id,
                        },
                    }}
                />
            </AppModal>
        </Grid>
    );
}

SiteDetails.propTypes = {
    account: PropTypes.object.isRequired,
    newZoneData: PropTypes.object.isRequired,
    alertZoneCoordinates: PropTypes.array,
    currentSite: PropTypes.object.isRequired,
    setCurrentSite: PropTypes.func.isRequired,
    setAlertZoneCoordinates: PropTypes.func.isRequired,
};

export default withRouter(
    compose(
        connect(
            (state) => {
                return {
                    account: state.account,
                    currentSite: state.sites.currentSite,
                    newZoneData: state.sites.newZoneData,
                    alertZoneCoordinates: state.sites.alertZoneCoordinates,
                };
            },
            (dispatch) => ({
                setCurrentSite: (payload) => dispatch(SiteActions.setCurrentSite(payload)),
                setAlertZoneCoordinates: (payload) => dispatch(setAlertZoneCoordinates(payload)),
                getSites: (concat, siteId) => dispatch(SiteActions.getSites(concat, siteId)),
            }),
        ),
    )(SiteDetails),
);
