import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HistoryIcon from "@material-ui/icons/History";

import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import InputFileComponent from "../../core/form/inputFileComponent";
import { change, Field, formValueSelector } from "redux-form";
import clsx from "clsx";
import { DRAG_AND_DROP_STYLES } from "../../../styles/reusableStyles";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import HiddenInput from "../../core/form/hiddenInput";
import Popover from "@material-ui/core/Popover";
import pdfjsLib from "pdfjs-dist/build/pdf";
import CloseIcon from "@material-ui/icons/Close";
import { MAIN_THEME } from "../../../styles/globalThemeConfig";

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://npmcdn.com/pdfjs-dist@2.3.200/build/pdf.worker.js";

const maxPreviewSize = 400;
const maxPreviewWidth = 280;

const useStyles = makeStyles(() => ({
    ticPic: {
        height: 100,

        "& .draggable-overlay-wrap": {
            display: "flex",
        },
        "& .draggable-text": {
            width: "100%",
        },
    },
    iconStyle: {
        cursor: "pointer",
        width: 17,
        height: 17,
    },
    closeIcon: {
        cursor: "pointer",
        width: 12,
        height: 12,
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 2,
        color: "#fff",
    },
    previewWrap: {
        minWidth: maxPreviewWidth,
        height: maxPreviewSize,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        zIndex: 1,
    },
}));

const TicpickDrop = (props) => {
    const classes = DRAG_AND_DROP_STYLES();
    const styles = useStyles();
    const {
        formValues: { files, shortNotes, filesToRemove },
        dispatch,
        form,
    } = props;
    const [imgAnchorEl, setImgAnchorEl] = useState(undefined);
    const [imgToShow, setImgToShow] = useState(undefined);
    const [previews, setUploadedPreview] = useState([]);
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            handleFileDrop(props, monitor);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const isActive = canDrop && isOver;
    const handleFileDrop = useCallback((item, monitor) => {
        if (monitor) {
            const newFilesData = monitor.getItem().files;
            const newFiles = [];

            newFilesData.forEach((i) => {
                onChangeFile(i);
                newFiles.push(i);
            });

            dispatch(change(form, "files", [...(files || []), ...newFiles]));
        }
    }, []);
    const onFileAdd = (e) => {
        const file = e.target.files[0] || e.target.files; // ei: e.target.files

        if (!file || (file && !file.size)) {
            return false;
        }

        onChangeFile(file);
        dispatch(change(form, "files", [...(files || []), file]));
        dispatch(change(form, "uploadFiles", null));
    };
    const removeFile = (fileIndex) => {
        dispatch(
            change(
                form,
                "files",
                files.filter((i, index) => index !== fileIndex),
            ),
        );
    };
    const removeSavedFile = (shotNote, attachmentId, remove) => {
        const newShortNotes = _.cloneDeep(shortNotes).map((i, index) => {
            if (i.id === shotNote.id) {
                i.attachments = i.attachments.map((attachment) => {
                    if (attachment.id === attachmentId) {
                        attachment.isRemoved = remove;
                    }

                    return attachment;
                });
            }

            return i;
        });

        let newFilesToRemove = filesToRemove || [];

        remove
            ? (newFilesToRemove = [...newFilesToRemove, +attachmentId])
            : (newFilesToRemove = newFilesToRemove.filter((i) => +i === +attachmentId));

        dispatch(change(form, "shortNotes", newShortNotes));
        dispatch(change(form, "filesToRemove", newFilesToRemove));
    };
    const showImg = (e, img) => {
        if (!img) {
            setImgToShow(null);
            setImgAnchorEl(null);

            return false;
        }

        setImgToShow(img);
        setImgAnchorEl(e.currentTarget);
    };

    const insertPreviewFile = (file) => {
        setUploadedPreview([...previews, file]);
    };
    const calculateSize = (width, height) => {
        const ratio = maxPreviewSize / maxPreviewSize;
        const maxh = maxPreviewSize;
        const maxw = maxPreviewSize;

        if (height / width > ratio) {
            // height is the problem
            if (height > maxh) {
                width = Math.round(width * (maxh / height));
                height = maxh;
            }
        } else {
            // width is the problem
            if (width > maxh) {
                height = Math.round(height * (maxw / width));
                width = maxw;
            }
        }

        return {
            width,
            height,
        };
    };
    const getPdfPreview = (upload) => {
        var loadingTask = pdfjsLib.getDocument({
            data: atob(upload.target.result.slice("data:application/pdf;base64,".length)),
        });

        return loadingTask.promise.then((pdf) => {
            // Fetch the first page
            return pdf.getPage(1).then((page) => {
                const scale = 0.72;
                const viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions
                const canvas = document.getElementById("pdf-preview");
                const context = canvas.getContext("2d");
                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                canvas.height = calculateSize(viewport.width, viewport.height).height;
                canvas.width = calculateSize(viewport.width, viewport.height).width;

                // should return Promise
                return page.render(renderContext);
            });
        });
    };

    const onChangeFile = (file) => {
        const reader = new FileReader();
        // const file = data.files[0] || data.files; // ei: e.target.files

        if (!file) {
            return false;
        }

        reader.onload = (upload) => {
            if (file.type === "application/pdf") {
                getPdfPreview(upload).then(() => {
                    const pdfPreview = document.querySelector("#pdf-preview");
                    const data = pdfPreview ? pdfPreview.toDataURL("image/jpeg", 0.72) : "";

                    insertPreviewFile({
                        name: file.name,
                        fileLink: data,
                    });
                });
            } else if (file.type.startsWith("image/")) {
                const URL = window.URL;
                const img = new Image();

                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    const canvas = document.getElementById("img-preview");

                    const ctx = canvas.getContext("2d");
                    const secondCtx = canvas.getContext("2d");

                    canvas.width = calculateSize(img.naturalWidth, img.naturalHeight).width;
                    canvas.height = calculateSize(img.naturalWidth, img.naturalHeight).height;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // get full image base64
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    secondCtx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
                    const fullImage = canvas ? canvas.toDataURL("image/jpeg") : "";

                    insertPreviewFile({
                        name: file.name,
                        fileLink: fullImage,
                    });
                };
            }
        };

        reader.readAsDataURL(file);
    };

    return (
        <React.Fragment>
            <Field name="filesToRemove" component={HiddenInput} />
            <Field
                name="uploadFiles"
                onChange={onFileAdd}
                title="Allowed to add: jpg, png, tif, pdf"
                component={InputFileComponent}
            />
            <br />
            <div className={clsx(classes.dragAndDrop, styles.ticPic)}>
                <div ref={drop} className="draggable-overlay-wrap">
                    <div className={clsx("draggable-overlay", isActive && "-active")}>
                        <p className="draggable-text">Drag & drop ticket picture here</p>
                    </div>
                </div>
            </div>
            {!!imgToShow && (
                <Popover
                    id="tikect-pic-popover"
                    disableRestoreFocus={true}
                    open={!!imgToShow}
                    anchorEl={imgAnchorEl}
                    onClose={() => showImg()}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div style={{ position: "relative" }}>
                        <CloseIcon onClick={() => showImg()} className={clsx(styles.closeIcon)} />
                        <div className={styles.previewWrap}>
                            <img src={imgToShow.fileLink} alt="" height={maxPreviewSize} />
                        </div>
                    </div>
                </Popover>
            )}
            <canvas
                id="img-preview"
                style={{
                    display: "none",
                    height: maxPreviewSize,
                    width: maxPreviewWidth,
                }}
            />
            <canvas
                id="pdf-preview"
                style={{
                    display: "none",
                    height: maxPreviewSize,
                    width: maxPreviewWidth,
                }}
            />
            <Field name="files" component={HiddenInput} />
            {!!(shortNotes && _.cloneDeep(shortNotes).filter((i) => !_.isEmpty(i.attachments)).length) && (
                <React.Fragment>
                    {_.cloneDeep(shortNotes)
                        .filter((i) => !_.isEmpty(i.attachments))
                        .map((i) => {
                            return i.attachments.map((img, index) => {
                                return (
                                    <Grid container key={index} alignItems={"center"}>
                                        <Grid item xs={11}>
                                            <span
                                                onMouseOver={(event) => showImg(event, img)}
                                                style={{
                                                    wordBreak: "break-all",
                                                    textDecoration: img.isRemoved ? "line-through" : "none",
                                                }}
                                            >
                                                {img.filename}
                                            </span>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {img.isRemoved ? (
                                                <HistoryIcon
                                                    className={styles.iconStyle}
                                                    onClick={() => removeSavedFile(i, img.id, false)}
                                                />
                                            ) : (
                                                <DeleteOutlineIcon
                                                    className={styles.iconStyle}
                                                    onClick={() => removeSavedFile(i, img.id, true)}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                );
                            });
                        })}
                </React.Fragment>
            )}
            {!_.isEmpty(files) && (
                <React.Fragment>
                    {files.map((file, index) => {
                        return (
                            <Grid container key={index} alignItems={"center"} title="New added files">
                                <Grid item xs={11}>
                                    <div
                                        style={{
                                            wordBreak: "break-all",
                                            color: MAIN_THEME.palette.primary.light,
                                        }}
                                        onMouseOver={(event) =>
                                            showImg(
                                                event,
                                                previews.find((i) => i.name === file.name),
                                            )
                                        }
                                    >
                                        {file.name}
                                    </div>
                                </Grid>
                                <Grid item xs={1}>
                                    <DeleteOutlineIcon className={styles.iconStyle} onClick={() => removeFile(index)} />
                                </Grid>
                            </Grid>
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

TicpickDrop.propTypes = {
    ticket: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            account: state.account,
            formValues: formSelector(state, "files", "shortNotes", "filesToRemove"),
        };
    })(TicpickDrop),
);
