import React from "react";
import PropTypes from "prop-types";

import { GOOGLE_MAPS_URL } from "../../constants/global.js";
import { BaseMapWithDirections } from "../tickets/components/ticketMap";

const TruckLocMap = ({ pickUpSite, height, truckData }) => {
    const { truckLocation, status } = truckData;

    return (
        <BaseMapWithDirections
            googleMapURL={GOOGLE_MAPS_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height }} />}
            mapElement={<div style={{ height: `100%` }} />}
            mapInfo={{
                pickUpSite,
                truckData: {
                    truckLocation: {
                        lat: truckLocation[0],
                        lng: truckLocation[1],
                    },
                    truckStatus: status,
                },
            }}
        />
    );
};

TruckLocMap.propTypes = {
    height: PropTypes.string,
    pickUpSite: PropTypes.object,
    truckData: PropTypes.object.isRequired,
};

export default TruckLocMap;
