import React, { useState } from "react";
import { Validation } from "../../helpers/validation";
import TextInputComponent from "./form/textInputComponent";
import { change, Field, formValueSelector, isDirty, isValid, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { MATERIAL_UI_ICONS_COLOR } from "../../styles/icons";

const useStyles = makeStyles((theme) => ({
    editableInputContainer: {
        display: "flex",
        flexDirection: "row",

        "& .editable-input": {
            "& input.MuiOutlinedInput-input": {
                padding: "6px 14px",
                backgroundColor: "#fff",
            },
        },

        "& .actions-container": {
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",

            "& svg": {
                color: MATERIAL_UI_ICONS_COLOR,
            },
        },
    },
}));

export const EDITABLE_FIELD_NAME = "editableValue";

const EditableInput = ({ form, handleSave, editableInputValue, isValid, isDirty, handleSubmit }) => {
    const [editMode, setEditMode] = useState(false);
    const [beforeEditValue, setBeforeEditValue] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleOnEditClick = () => {
        setEditMode(true);
        setBeforeEditValue(editableInputValue);
    };

    const handleOnCancelClick = () => {
        setEditMode(false);
        dispatch(change(form, EDITABLE_FIELD_NAME, beforeEditValue));
    };

    const handleOnSave = async (e) => {
        if (isValid && isDirty) {
            handleSave(editableInputValue);
            setEditMode(false);
        }
    };

    const onFormClick = (e) => {
        e && e.stopPropagation();
    };

    const renderActions = () =>
        editMode ? (
            <React.Fragment>
                <CheckIcon onClick={handleOnSave} />
                <ClearIcon onClick={handleOnCancelClick} />
            </React.Fragment>
        ) : (
            <EditIcon onClick={handleOnEditClick} />
        );

    return (
        <form className={classes.editableInputContainer} onSubmit={handleSubmit(handleOnSave)} onClick={onFormClick}>
            <Field
                disabled={!editMode}
                className="editable-input"
                name={EDITABLE_FIELD_NAME}
                validate={[Validation.isNumberWithSpace]}
                type="text"
                component={TextInputComponent}
            />
            <div className={"actions-container"}>{renderActions()}</div>
        </form>
    );
};

EditableInput.propTypes = {
    form: PropTypes.string.isRequired,
    handleSave: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    editableInputValue: PropTypes.any,
};

export default withRouter(
    compose(
        reduxForm({
            enableReinitialize: true,
            destroyOnUnmount: false,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);
            const validSelector = isValid(props.form);

            return {
                editableInputValue: formSelector(state, EDITABLE_FIELD_NAME),
                isValid: validSelector(state),
                isDirty: isDirty(props.form)(state),
            };
        }),
    )(EditableInput),
);
