import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import BorderLinearProgress from "../core/borderLinearProgress";
import Loader from "../core/loader";
import StartDate from "../core/time";
import TrucksRatio from "../core/truckRatio";
import UnitOfMeasure from "../core/unitOfMeasure";
import {
    IS_INSPECTOR_USER,
    UNIT_OF_MEASURE_BY_ID,
    UNIT_OF_MEASURE_HOUR,
    UNIT_OF_MEASURE_LOAD,
} from "../../constants/maps";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from "../../constants/global";
import { CARD_STYLES, DRAG_AND_DROP_STYLES } from "../../styles/reusableStyles";
import { getHoursQty } from "../tickets/components/ticketCard";
import { TYPOGRAPHY_ICONS_SITE } from "../../styles/icons";
import { ESTIMATED_LOAD_COUNT, ESTIMATED_TIME_TO_COMPLETE } from "./constants";
import { selectAccount, selectUserAccountRole } from "../../selectors/index";
import { getIsJobOrderRequest } from "../../helpers/jobOrders";
import JobOrderStatusPill from "./jobOrder/jobOrderStatusPill";

const useStyles = makeStyles((theme) => ({
    jobOrder: {
        "&.selected-job": {
            borderColor: theme.palette.general.green,
            borderWidth: 2,
        },
        "&.is-unlimited": {
            paddingTop: 7,
        },
        "&.isSkeleton": {
            paddingTop: 11,

            "& .name, & .startTime, & .payload, & .qty, & .numberOfTrucks, & .status ": {
                backgroundColor: theme.palette.secondary.main,
                color: "transparent",
                height: 12,
                marginBottom: 9,
                fontSize: 0,
            },

            "& .name": {
                width: "39%",
            },

            "& .numberOfTrucks": {
                width: "50%",
            },

            "& .startTime": {
                width: "100%",
            },

            "& .payload": {
                width: "21%",
            },

            "& .status": {
                height: 14,
                width: "73%",
                borderRadius: theme.shape.borderRadius * 2,
                marginTop: 0,
                backgroundColor: theme.palette.secondary.dark,
            },

            "& .qty": {
                height: 18,
                width: "25%",
                marginBottom: 7,
            },
        },

        "&.is-job-board .content, &.is-smart-dispatch .content": {
            padding: "11px 17px",
        },

        "& .name, & .startTime, & .payload": {
            color: theme.palette.text.main,
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& .finishedQty, & .totalQty": {
            fontSize: 19,
            fontWeight: theme.typography.fontWeightBold,
        },
        "& .finishedQty": {
            color: theme.palette.secondary.main,
        },
        "& .hour-qty": {
            minWidth: 55,
        },
        "& .totalQty": {
            color: theme.palette.text.secondary,
            whiteSpace: "nowrap",
        },
        "& .status": {
            margin: "6px 0",
            lineHeight: 0,
        },
        "& .qty": {
            display: "flex",
            alignItems: "center",
        },
        "& .qty-wrap": {
            display: "flex",
            [theme.breakpoints.down("1710")]: {
                flexWrap: "wrap",
            },
        },
        "& .total-requested": {
            marginLeft: 5,
            [theme.breakpoints.down("1710")]: {
                margin: 0,
            },
            [theme.breakpoints.down("1275")]: {
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
        "& .trucks-list": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    infiniteIcon: {
        height: 34,
        width: 34,
        marginRight: 4,
    },
    moreActionWrap: {
        position: "relative",
        height: 10,

        "& .more-actions": {
            position: "absolute",
            top: "0px",
            right: 3,
            zIndex: 10,
        },
    },
    checkIconWrapper: {
        position: "relative",

        "& .check-icon": {
            position: "absolute",
            top: "-20px",
            left: "25px",
            zIndex: 10,
        },
    },
    progress: {
        marginBottom: 0,
    },
    estimate: {
        backgroundColor: "rgba(0,0,0,.03)",
        borderBottom: " 1px solid rgba(0,0,0,.125)",
        borderTop: " 1px solid rgba(0,0,0,.125)",
        padding: "3px 17px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    estimateData: { textAlign: "center" },
}));

const RegularJobOrderItem = (props) => {
    const { history, isSkeleton, jobOrder } = props;
    const {
        assignedBy,
        projectName,
        id,
        totalQuantity,
        payloadName,
        assignedTrucksCount,
        startDate,
        unlimited,
        completedQuantity,
        unitOfMeasure,
        totalNeededTrucksCount,
        status,
        unitPrice,
        iAmOwner,
        extRef,
        companyName,
        grantedAccess,
        projectExternalJobNumber,
        dispatchedQuantity,
        trucks,
        copySchedule,
        estimation,
    } = jobOrder;
    const pathname = history.location.pathname;
    const account = useSelector(selectAccount);

    const isJobBoardPage = pathname.includes(ROUTE_JOBS.JOB_BOARD);
    const isSmartDispatchPage = pathname.includes(ROUTE_JOBS.SMART_DISPATCH);
    const isJobOrderRequest = getIsJobOrderRequest(jobOrder, account);

    const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();
    const classes = useStyles();
    const cardStyles = CARD_STYLES();
    const dnDStyles = DRAG_AND_DROP_STYLES();
    const joTrucks = trucks && trucks.join(", ");

    const accountRole = useSelector(selectUserAccountRole);

    const [{ isDragging }, drag] = useDrag({
        item: {
            ...jobOrder,
            id,
            type: "job",
            pickupLocation: {
                name: jobOrder?.pickupLocation?.name,
            },
            unitOfMeasure,
            unlimited,
        },
        end: (item, monitor) => {},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    return (
        <div
            ref={drag}
            style={{ opacity }}
            className={clsx(
                classes.jobOrder,
                cardStyles.cardStyles,
                unlimited && "is-unlimited",
                isSmartDispatchPage && "is-smart-dispatch",
                isSkeleton && "isSkeleton container-loader-wrap -grey",
                "draggable-overlay-wrap",
                dnDStyles.dragAndDrop,
            )}
        >
            {isSkeleton && <Loader />}
            {!isSkeleton && !unlimited && !isJobBoardPage && !!completedQuantity && (
                <BorderLinearProgress
                    value={completedQuantity}
                    totalValue={totalQuantity}
                    className={classes.progress}
                />
            )}

            <div className="draggable-overlay-wrap">
                {estimation && (
                    <Box className={classes.estimate}>
                        <Typography variant="h3">Estimation</Typography>
                        <Box className={classes.estimateData}>
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography>
                                    {ESTIMATED_LOAD_COUNT(
                                        estimation.complLoadsCount,
                                        estimation.loadsCountToDo,
                                        unlimited,
                                    )}
                                </Typography>
                                {unlimited && <AllInclusiveIcon style={{ marginLeft: 3 }} />}
                            </Box>
                            <Typography>{ESTIMATED_TIME_TO_COMPLETE(estimation.oneWayTime)}</Typography>
                        </Box>
                    </Box>
                )}
                <Grid container component="div" className="content" style={{ paddingBottom: 0 }}>
                    <Grid item xs={copySchedule ? 5 : 6} component="div">
                        <Typography className={clsx("name")}>
                            {isJobOrderRequest ? "Request" : "Job"} # {id} -{" "}
                            {extRef ? (
                                <React.Fragment>
                                    {extRef}
                                    <br />
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                            {iAmOwner ? (
                                <>
                                    {projectName}
                                    {projectExternalJobNumber ? ` - ${projectExternalJobNumber}` : ""}
                                </>
                            ) : assignedBy ? (
                                assignedBy.name
                            ) : (
                                companyName
                            )}
                        </Typography>
                        <div className="qty-wrap">
                            <Box className={clsx("qty")} component="div">
                                {!isSkeleton && (
                                    <div
                                        className={clsx(
                                            "finishedQty",
                                            unitOfMeasure === UNIT_OF_MEASURE_HOUR && "hour-qty",
                                        )}
                                    >
                                        {unitOfMeasure === UNIT_OF_MEASURE_HOUR
                                            ? getHoursQty(completedQuantity)
                                            : +completedQuantity?.toFixed(2)}
                                    </div>
                                )}
                                <div style={{ padding: "0 4px" }}>{` / `}</div>
                                {!isSkeleton &&
                                    (!unlimited ? (
                                        <div className="totalQty">
                                            {unitOfMeasure === UNIT_OF_MEASURE_LOAD ? (
                                                <>
                                                    {dispatchedQuantity ? +dispatchedQuantity?.toFixed(2) : 0}
                                                    {` `}
                                                    <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                                                </>
                                            ) : unitOfMeasure === UNIT_OF_MEASURE_HOUR ? (
                                                getHoursQty(totalQuantity)
                                            ) : (
                                                <>
                                                    {+totalQuantity?.toFixed(2)}
                                                    {` `}
                                                    <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <AllInclusiveIcon className={clsx(classes.infiniteIcon)} />
                                            <div className="totalQty">
                                                <UnitOfMeasure
                                                    className="totalQty"
                                                    value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                            </Box>
                            {!isSkeleton && !unlimited && unitOfMeasure === UNIT_OF_MEASURE_LOAD && (
                                <span className="totalQty total-requested">
                                    {+dispatchedQuantity !== +totalQuantity &&
                                        ` (${+totalQuantity.toFixed(2)} Total Requested)`}
                                </span>
                            )}
                        </div>
                        {!IS_INSPECTOR_USER(accountRole) && (
                            <Typography className="payload">
                                {payloadName}
                                {unitPrice && !grantedAccess && (
                                    <React.Fragment>
                                        {` - `}
                                        <span className="--green-text">${unitPrice.toFixed(2)}</span>
                                        {` / `}
                                        <UnitOfMeasure
                                            value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]}
                                            className={classes.unitOfMeasure}
                                            isPlural={false}
                                        />
                                    </React.Fragment>
                                )}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={copySchedule ? 7 : 6} component="div">
                        <Grid container alignItems={"flex-end"} direction={"column"} component="div">
                            {(copySchedule || startDate) && (
                                <Box className="startTime --text-right" component="div">
                                    <StartDate
                                        time={startDate}
                                        copySchedule={copySchedule}
                                        format={DEFAULT_DATE_TIME_FORMAT}
                                        periodFormat={DEFAULT_DATE_FORMAT}
                                        periodTimeFormat={DEFAULT_TIME_FORMAT}
                                        showIcon={false}
                                    />
                                </Box>
                            )}
                            <Box component="div" className="status ----text-right">
                                {!isSkeleton && <JobOrderStatusPill status={status} copySchedule={copySchedule} />}
                            </Box>
                            <Box className="numberOfTrucks --text-right" component="div">
                                <TrucksRatio
                                    showIcon={!isSkeleton}
                                    assignedTrucks={assignedTrucksCount}
                                    requestedTrucks={+totalNeededTrucksCount}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {!isSkeleton && (
                <Grid container justify={"space-between"} className={"content"}>
                    <Grid item xs={8}>
                        <div className="trucks-list" title={joTrucks}>
                            {joTrucks}
                        </div>
                    </Grid>
                    <Grid item xs={joTrucks ? 4 : 12}>
                        <Typography className={clsx("payload", "--text-right")}>
                            <LocationOnIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />
                            {jobOrder?.pickupLocation?.name}
                        </Typography>
                        <Typography className={clsx("payload", "--text-right")}>
                            <LocationOnIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                            {jobOrder?.dropOffSites}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

RegularJobOrderItem.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isSkeleton: PropTypes.bool.isRequired,
    index: PropTypes.number,
};

RegularJobOrderItem.defaultProps = {
    isSkeleton: false,
};

export default withRouter(RegularJobOrderItem);
