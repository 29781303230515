import React from "react";
import { Field, hasSubmitSucceeded, reduxForm } from "redux-form";
import { useSelector } from "react-redux";

import AppModal from "../core/modal";
import TextInputComponent from "../core/form/textInputComponent";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { createTrailer, refreshTrailers, updateTrailer } from "../../actions/trailers";
import { ErrorNotification } from "../core";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { selectMeasureSystem } from "../../selectors";
import { VOLUMETRIC_TYPE_BY_METRIC_SYSTEM } from "../../constants/maps";
import Loader from "../core/loader";
import { getCapacityAdornment } from "../../helpers/measureSystemHelpers";

function CreateNewTrailer(props) {
    const mSystem = useSelector(selectMeasureSystem);
    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(props.form));

    React.useEffect(() => {
        if (hasSubmitSuccess) {
            props.closeModal();
            props.destroy();
        }
    }, [hasSubmitSuccess]);

    const onCloseModal = () => {
        props.closeModal();
        props.destroy();
    };

    return (
        <AppModal form={props.form} isOpen={props.openModal} closeModal={onCloseModal}>
            <form onSubmit={props.handleSubmit} className={props.submitting && LOADER_WHITE_OVERLAY}>
                {props.submitting && <Loader />}
                <h2 className="title">{props.title}</h2>
                <Field
                    type="text"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    name="name"
                    label="Trailer Name"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    type="number"
                    validate={[Validation.required, Validation.numbersPositive]}
                    name="capacity"
                    label="Weight Capacity"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                    endAdornment={getCapacityAdornment(mSystem)}
                />
                <br />
                <br />
                <Field
                    type="number"
                    validate={[Validation.numbersPositive]}
                    name="volumeCapacity"
                    label="Volume Capacity"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                    endAdornment={
                        <span style={{ width: 200, textAlign: "right" }}>
                            {`${VOLUMETRIC_TYPE_BY_METRIC_SYSTEM[mSystem] || "Cubic Meter"}s`.toUpperCase()}
                        </span>
                    }
                />
                <br />
                <br />
                <Field
                    type="number"
                    validate={[Validation.numbersPositive, Validation.isInteger]}
                    name="axle"
                    label="Axle"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={props.closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Create</PrimaryButton>
                </ButtonsGroup>
            </form>
            {props.error && <ErrorNotification error={[props.error]} config={{ autoHideDuration: 5000 }} />}
        </AppModal>
    );
}

CreateNewTrailer.propTypes = {};

export const CREATE_TRAILER_FORM = "CreateNewTrailer";

const onSubmit = (values, dispatch, props) => {
    return props.trailerId ? dispatch(updateTrailer(props.trailerId, values)) : dispatch(createTrailer(values));
};

const onSubmitSuccess = (_, dispatch) => dispatch(refreshTrailers());

export default reduxForm({
    form: CREATE_TRAILER_FORM,
    onSubmit,
    onSubmitSuccess,
})(CreateNewTrailer);
