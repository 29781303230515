import { useSnackbar } from "notistack";

const useSuccessNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSuccess = (successMessage) => {
        enqueueSnackbar(successMessage, {
            autoHideDuration: 5000,
            variant: "success",
        });
    };

    return showSuccess;
};

export default useSuccessNotification;
