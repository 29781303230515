import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

import AppModal from "../core/modal";
import Calendar from "../../components/global/calendar/MyTeamCalendar";
import { THEME_ICONS } from "../../styles/icons";

import { selectUserEvents, selectUserSuspendPeriod, selectUserEventsMeta } from "../../selectors/index";
import { requestUserActivities, resetUserEvents } from "../../actions/team";

const useStyles = makeStyles(() => ({
    calendarHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    uiIconTeam: {
        ...THEME_ICONS.account,
        marginRight: 5,
    },
}));

const UserCalendar = ({ isOpenCalendar, closeCalendar, timezone, userName, userId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const events = useSelector(selectUserEvents);
    const suspendPeriod = useSelector(selectUserSuspendPeriod);
    const { loading, error } = useSelector(selectUserEventsMeta);

    const getEvents = (userId, params) => dispatch(requestUserActivities(userId, params));

    const resetEvents = () => dispatch(resetUserEvents());

    return (
        <AppModal isOpen={isOpenCalendar} closeModal={closeCalendar} modalStyles={{ minWidth: 1500 }}>
            <h2 className={clsx("title", classes.calendarHeader)}>
                <Icon className={classes.uiIconTeam} />
                {userName}
            </h2>
            <Calendar
                events={events}
                timezone={timezone}
                userId={userId}
                getEvents={getEvents}
                resetEvents={resetEvents}
                suspendPeriod={suspendPeriod}
                loading={loading}
                error={error}
            />
        </AppModal>
    );
};

export default UserCalendar;

UserCalendar.propTypes = {
    isOpenCalendar: PropTypes.bool.isRequired,
    closeCalendar: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
};
