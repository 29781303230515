import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";

import RequestJobOrderForm from "./RequestJobOrderForm";
import { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobComponents/pickUp&DropOff";
import { selectAccount, selectIfIsStartDateByDefault } from "../../../selectors";
import { selectCurrentJobOrderRequest } from "../../../selectors/jobOrders";
import { getCurrentJobOrderRequest, updateCurrentJobOrderRequest } from "../../../actions/jobOrders";
import { convertTimeToJsFormat, getJobOrderInitialDate } from "../../../helpers/jobOrders";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { Loader } from "../../core";

const getRequestJobInitialValues = (
    isStartDateByDefault,
    account,
    currentJobOrderRequest,
    locationStateRequestPOLineItem,
) => {
    const initialValuesSource = currentJobOrderRequest || locationStateRequestPOLineItem;
    const { requestNotes, dropOffSites, pickUpSite } = initialValuesSource;

    const initialValues = {
        [DROP_OF_SITE_NAME]: dropOffSites.map((site) => {
            return {
                label: site.name,
                value: site.id,
            };
        }),
        [PICK_UP_SITE_NAME]: {
            label: pickUpSite.name,
            value: pickUpSite.id,
        },
        requestNotes,
    };

    if (currentJobOrderRequest) {
        // case for created request
        const { unlimited, startDate, unitOfMeasure, totalQuantity, isOvernight, numberOfTrucksRequested } =
            currentJobOrderRequest;
        initialValues.unitOfMeasure = unitOfMeasure;
        initialValues.startDate = convertTimeToJsFormat(startDate, account.timezone);
        initialValues.unlimited = unlimited;
        initialValues.totalQuantity = totalQuantity || null;
        initialValues.isOvernight = isOvernight;
        initialValues.numberOfTrucksRequested = numberOfTrucksRequested;
    } else {
        // case for new request
        initialValues.unitOfMeasure = locationStateRequestPOLineItem.unitOfMeasure;
        initialValues.startDate = isStartDateByDefault
            ? getJobOrderInitialDate(account.companyProfile.defaultStartTime)
            : null;
    }

    return initialValues;
};

const RequestJobOrder = ({
    history,
    history: { location },
    match: {
        params: { requestId },
    },
    setError,
}) => {
    const dispatch = useDispatch();
    const isStartDateByDefault = useSelector(selectIfIsStartDateByDefault);
    const account = useSelector(selectAccount);
    const currentJobOrderRequest = useSelector(selectCurrentJobOrderRequest);

    const requestPOLineItem = location.state?.requestPOLineItem;

    useEffect(() => {
        if (requestId && !currentJobOrderRequest) {
            dispatch(getCurrentJobOrderRequest(requestId, setError));
        }
    }, [requestId]);

    useEffect(() => {
        return () => {
            dispatch(updateCurrentJobOrderRequest(null));
        };
    }, []);

    useEffect(() => {
        if (!currentJobOrderRequest && !requestPOLineItem && !requestId) {
            history.push(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        }
    }, [currentJobOrderRequest, requestPOLineItem]);

    if (!currentJobOrderRequest && !requestPOLineItem) {
        return (
            <Box sx={{ height: "50%" }}>
                <div className={LOADER_WHITE_OVERLAY}>
                    <Loader />
                </div>
            </Box>
        );
    }

    const initialValues = getRequestJobInitialValues(
        isStartDateByDefault,
        account,
        currentJobOrderRequest,
        requestPOLineItem,
    );

    return (
        <div>
            <RequestJobOrderForm
                requestId={requestId}
                requestPOLineItem={currentJobOrderRequest?.poLineItem || requestPOLineItem}
                initialValues={initialValues}
                setError={setError}
            />
        </div>
    );
};

export default withRouter(RequestJobOrder);
