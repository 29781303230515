import React, { useCallback, useEffect, useRef, useState } from "react";
import TruckLocMap from "./truckLocMap";
import PropTypes, { string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import moment from "moment";
import update from "immutability-helper";
import _ from "lodash";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SwapIcon from "@material-ui/icons/SwapVerticalCircleOutlined";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Icon from "@material-ui/core/Icon";
import SendIcon from "@material-ui/icons/Send";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { WarningRounded } from "@material-ui/icons";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Tooltip from "@material-ui/core/Tooltip";

import { TABLE_SECONDARY_SCROLLABLE } from "../../styles/reusableStyles";
import { dropItemTypes } from "../../helpers/jobOrders";
import { TRUCK_ITEM_REGULAR_JOB_HINT, TRUCK_ITEM_TEMPLATE_HINT } from "../../constants/strings";
import {
    getCompanyActivity,
    getCompanyTrucks,
    getTruckActivity,
    removeTruckJoliActivity,
    updateTruckActivity,
} from "../../dataServers/trucks";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../../constants/global";
import { getTruckEvents, resetTruckEvents } from "../../actions/trucks";
import { replaceTrucksAction } from "../../actions/smartDispatch";
import AppModal from "../../components/core/modal";
import JobCalendar from "../../components/global/calendar/JobCalendar";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import { THEME_ICONS } from "../../styles/icons";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { Divider } from "../core/divider";
import ConfirmationModal from "../core/confirmationModal";
import ProjectCheckIcon from "../global/checkIcon";
import BorderLinearProgress from "../core/borderLinearProgress";
import Row from "./truckActivityRow";
import {
    IMPERIAL,
    KILOMETERS_TO_METERS_FACTOR,
    KILOMETERS_UNIT,
    MEASUREMENT_SYSTEM_NAME,
    MILES_TO_METERS_FACTOR,
    MILES_UNIT,
} from "../../constants/accounts";
import TruckItemIcon from "./truckItemIcon";
import HaulerTrucksList from "./haulerTrucksList";

const truckItemStyles = makeStyles((theme) => ({
    truckCard: {
        position: "relative",
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 10,
        paddingBottom: 10,
        backgroundColor: "#fff",
        height: "auto",

        "&:hover": {
            cursor: "pointer",
        },

        "& .driver-block": {
            fontSize: "0.9em",
        },

        "& .progress-bar": {
            marginBottom: 5,

            "& .bar": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            },
        },

        "& .content": {
            padding: "0 16px",
            width: "100%",

            "& h3": {
                textTransform: "uppercase",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },

            "& > *": {
                lineHeight: "1",
            },
        },

        "&.-is-hauler": {
            "& .content h3": {
                width: "calc(100% - 16px)",
            },
        },

        "&.-is-loading": {
            "& h3": {
                width: 190,
                height: 15,
                marginBottom: 6,
                backgroundColor: theme.palette.secondary.main,
                fontSize: 0,
            },
            "& p": {
                width: 100,
                height: 8,
                backgroundColor: theme.palette.secondary.main,
                fontSize: "0 !important",
                marginBottom: 11,
            },
        },
        "&.-is-selected": {
            border: `2px solid ${theme.palette.general.green}`,
            position: "relative",
        },

        "&.-has-jobs-for-today": {
            border: `2px solid ${theme.palette.primary.main}`,
        },
        "&.-only-activity-data": {
            border: "none",
            borderTop: `2px solid ${theme.palette.border.primary}`,
            borderRadius: 0,
            marginBottom: 6,
            marginLeft: 10,

            "& .content": {
                textAlign: "right",
            },

            "& .toggler": {
                "& svg": {
                    display: "none",
                },

                display: "inline-block",
                textDecoration: "underline",
            },

            "& .activity-table": {
                "& .tbody .tr:only-child": {
                    "& .td": {
                        borderBottom: "none",
                    },
                },

                "& .td, .th": {
                    minHeight: 55,
                },
                "& .th": {
                    minHeight: 52,
                    textTransform: "uppercase",
                    color: theme.palette.secondary.light,
                    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
                },
            },
        },

        "& .toggler": {
            height: "100%",
            cursor: "pointer",
            fontSize: 14,
            textDecoration: "underline",

            "& svg": {
                width: 21,
                height: 21,
                marginRight: 10,
            },
        },
        "& .driver": {
            height: "100%",
            fontSize: 14,
        },
        "& .activity-table": {
            borderCollapse: "collapse",
            width: "100%",

            "& .tr": {
                cursor: "pointer",
                height: "unset",
            },

            "& .tbody .tr": {
                height: "auto",
            },

            "& .td, .th": {
                padding: 5,
                fontSize: 12,
                wordBreak: "break-word",
                minHeight: 0,
                borderBottom: `1px solid ${theme.palette.border.primary}`,
                borderRadius: "0 !important",

                "&:first-child": {
                    flex: "none",
                },
            },
            "& .th": {
                fontWeight: 500,
                textAlign: "left",
                fontSize: 13,
            },
            "& .td": {
                "& svg": {
                    width: 14,
                    height: 14,
                    position: "relative",
                    top: 2,
                },
            },
        },

        "& .infinite-icon": {
            top: "0px !important",
            marginRight: 5,
        },

        "& .dispatched-icon": theme.icon,
        "& .calendar-icon": {
            width: 25,
            cursor: "pointer",
        },
        "& .template-body": {
            backgroundColor: "#fbf7f3",
        },
    },
    asteriskOrange: {
        ...THEME_ICONS.asteriskOrange,
        width: "100%",
        height: "100%",
    },
    activeSuspendPeriod: {
        backgroundColor: `${theme.palette.secondary.superLight} !important`,
    },
    reasonContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: 5,
        padding: "0 10px 5px",
    },
    reasonItemContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        "&:not(:last-child)": { marginBottom: 5 },
    },
    reasonItemTitle: {
        fontSize: 13,
        fontWeight: 500,
        marginRight: 10,
    },
    reasonForLargeText: {
        wordBreak: "break-all",
        textAlign: "right",
    },
    haulerNameTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
    },
    haulerWarningIcon: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        marginRight: "5px",
        color: theme.palette.general.paleRed,
    },
    calendarHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    uiMyFleet: {
        ...THEME_ICONS.generalTruckDark,
        marginRight: 10,
    },
    truckItemResponsive: {
        [theme.breakpoints.down("1550")]: {
            maxWidth: "100%",
        },
    },
    calendarIcon: {
        padding: 0,
        marginRight: 12,
        marginBottom: 4,
        color: theme.palette.secondary.superDark,
        ...theme.icon,
    },
    reasonLabelContainer: {
        whiteSpace: "nowrap",
        padding: "0 10px",
        [theme.breakpoints.down("1500")]: {
            whiteSpace: "normal",
        },
    },
    togglerContainer: {
        margin: 0,
        padding: "0 10px",
    },
    truckItemsResponsive: {
        paddingRight: "16px",

        [theme.breakpoints.down("1550")]: {
            alignItems: "flex-end",
        },
    },
    deployedTruck: {
        backgroundColor: `${theme.palette.secondary.superLight} !important`,
        "&:hover": {
            cursor: "auto",
        },
    },
    haulerNoDistanceText: { marginTop: 4 },
    dropStyles: {
        display: "flex",
        position: "absolute",
        justifyContent: "center",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: 10,
        "& .drop-item": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            border: "3px dashed #b96211",
            borderRadius: 8,
        },
    },
    truckIcon: {
        ...THEME_ICONS.generalTruck,
    },
}));

const TruckItem = React.memo(
    ({
        isLoading,
        truck,
        handleTruckSelection,
        isSelected,
        selectedTrucks,
        handleDropEnd,
        isHaulers,
        showActivityOnly,
        history,
        isDirtyJoOrderForm,
        currentJobOrder,
        account,
        hasAnotherJobOnDate,
        truckIsDispatched,
        isFleetList,
        startDate,
        pickUpSite,
        showDistance,
        requestTruckEvents,
        resetTruckEvents,
        isTemplateOn,
        allFieldsExist,
        withDrop,
        disabledDrag,
        isCanDrop,
        isDeployedTruck,
        replaceTrucks,
    }) => {
        const classes = truckItemStyles();
        const scrolableTableStyles = TABLE_SECONDARY_SCROLLABLE();
        const pathname = history.location.pathname;
        const isSmartDispatchPage = pathname.includes(ROUTE_JOBS.SMART_DISPATCH);
        const [state, setState] = useState({
            open: false,
            showReason: false,
            showCalendar: false,
            isLocalLoading: false,
            activityData: [],
        });
        const [openMap, setOpenMap] = useState(false);
        const [truckCellAnchor, setTruckCellAnchor] = useState(null);
        const [rows, setRows] = useState([]);
        const [message, showMessage] = useState();
        const [error, setError] = useState(null);
        const [joConfirmationId, setConfirmationToLeave] = useState();
        const [deleteTruckItem, setDeleteTruckItem] = useState();
        const [showHaulersTrucks, setShowHaulersTrucks] = useState(false);
        const [haulersTrucks, setHaulersTrucks] = useState([]);
        const truckCellRef = useRef(truck.id);
        const { open, showReason, isLocalLoading, activityData, showCalendar } = state;
        const [{ isDragging }, drag] = useDrag({
            item: {
                ...truck,
                id: truck.id,
                isTruck: !!truck.truckName,
                defaultRate: truck.defaultRate,
                type: dropItemTypes.TRUCK,
            },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();

                if (item && dropResult && !isSmartDispatchPage) {
                    handleDropEnd(selectedTrucks, item, dropResult);
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [{ canDrop }, drop] = useDrop({
            accept: dropItemTypes.TRUCK,
            drop: (newTruck) => {
                replaceTrucks(truck, newTruck);
            },
            canDrop: () => {
                return !!isCanDrop;
            },
            collect: (monitor) => ({
                canDrop: !!monitor.canDrop(),
            }),
        });

        const params = {
            startDate: moment(startDate).format(SERVER_DATE_FORMAT),
            endDate: moment(startDate).format(SERVER_DATE_FORMAT),
        };
        const opacity = isDragging ? 0.4 : 1;
        const truckId = truck.truckId || truck.id;
        const openTruckInfo = () => {
            const request = isHaulers ? getCompanyActivity(truckId, params) : getTruckActivity(truckId, params);

            setState({
                ...state,
                open: true,
                isLocalLoading: true,
            });
            showMessage(null);

            request
                .then(({ data }) => {
                    const rows = isHaulers ? data : data.scheduledJobs;
                    showMessage(null);
                    setState({
                        ...state,
                        isLocalLoading: false,
                        activityData: rows,
                        open: true,
                    });
                    setRows(rows);
                })
                .catch(() => {
                    setState({
                        ...state,
                        isLocalLoading: false,
                    });
                });
        };
        const openHaulersTrucks = async () => {
            const haulerId = truck.id;
            try {
                setState({
                    ...state,
                    isLocalLoading: true,
                });
                const { data } = await getCompanyTrucks({ company: haulerId, perPage: 1000 });
                setShowHaulersTrucks(true);
                setHaulersTrucks(data);
            } catch (error) {
                setError([PROCESS_SERVER_ERROR(error)]);
            } finally {
                setState({
                    ...state,
                    isLocalLoading: false,
                });
            }
        };
        const moveCard = useCallback(
            (dragIndex, hoverIndex) => {
                const dragCard = rows[dragIndex];

                setRows(
                    update(rows, {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, dragCard],
                        ],
                    }),
                );
            },
            [rows],
        );
        const saveJobs = () => {
            if (_.isEqual(activityData, rows)) {
                setState({
                    ...state,
                    open: false,
                });

                return false;
            }

            const data = rows.map((i, index) => ({
                joLineItemId: i.joLineItemId,
                displayIndex: index + 1,
            }));
            setState({
                ...state,
                isLocalLoading: true,
            });

            updateTruckActivity(truckId, data, params)
                .then(() => {
                    setState({
                        ...state,
                        open: showActivityOnly,
                        isLocalLoading: false,
                    });
                    showMessage([`Truck ${truck.deviceName || truck.id} updated`]);
                })
                .catch(() => {
                    setState({
                        ...state,
                        isLocalLoading: false,
                    });
                });
        };

        const openJoForEdit = (jobOrderId) => {
            if (+jobOrderId === +currentJobOrder) {
                showMessage(["You are already watching this job order"]);
            } else {
                setConfirmationToLeave(null);
                history.push(`${ROUTE_JOBS.DISPATCH_JOB_ORDERS}/${jobOrderId}`, { jobOrder: { id: jobOrderId } });
            }
        };
        const closeActivity = () => {
            setState({
                ...state,
                open: false,
            });
        };

        const showConfirmation = (jobOrderId) => {
            if (isDirtyJoOrderForm && allFieldsExist) {
                setConfirmationToLeave(jobOrderId);
            } else {
                openJoForEdit(jobOrderId);
            }
        };

        const removeTruckActivityConfirmation = (item) => setDeleteTruckItem(item);

        const removeTruckActivity = (item) => {
            const newRows = _.cloneDeep(activityData).filter((i) => i.joLineItemId !== item.joLineItemId);
            setState({
                ...state,
                isLocalLoading: true,
            });
            showMessage(null);

            removeTruckJoliActivity(item.id, item.joLineItemId)
                .then(() => {
                    showMessage(["Job order successfully unassigned from truck"]);
                    setState({
                        ...state,
                        isLocalLoading: false,
                        activityData: newRows,
                    });
                    setRows(newRows);
                    setDeleteTruckItem();
                })
                .catch((error) => {
                    setState({
                        ...state,
                        isLocalLoading: false,
                    });
                    setDeleteTruckItem();
                });
        };

        useEffect(() => {
            if (showActivityOnly) {
                openTruckInfo();
            }

            closeActivity();
        }, [truck.id]);

        useEffect(() => {
            if (startDate && open) {
                openTruckInfo();
            }
        }, [startDate]);

        const openReason = () => {
            setState({
                ...state,
                showReason: !state.showReason,
            });
        };

        const renderReasonLabel = () => {
            return (
                !isLoading &&
                truck.activeSuspendPeriod && (
                    <Grid container xs={6}>
                        <Box
                            component={"div"}
                            m={0}
                            display={"flex"}
                            alignItems={"flex-end"}
                            onClick={openReason}
                            className={clsx(classes.reasonLabelContainer, "toggler")}
                        >
                            {showReason ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            Unavailability Details
                        </Box>
                    </Grid>
                )
            );
        };

        const renderShowTrucksButton = () => {
            return (
                !isLoading &&
                truck.isOfflineHauler &&
                !showHaulersTrucks &&
                isHaulers &&
                allFieldsExist && (
                    <Grid container>
                        <Box
                            component={"div"}
                            display={"flex"}
                            alignItems={"flex-end"}
                            onClick={openHaulersTrucks}
                            className={clsx(classes.togglerContainer, "toggler")}
                            justifyContent="flex-end"
                        >
                            <ExpandMoreIcon />
                            Show Trucks
                            <LocalShippingIcon style={{ marginLeft: 5 }} />
                        </Box>
                    </Grid>
                )
            );
        };

        const openCalendar = (showCalendar) => {
            setState({ ...state, showCalendar: true });
        };

        const closeCalendar = () => {
            setState({ ...state, showCalendar: false });
        };

        const renderShowCalendar = () => {
            return (
                !isLoading &&
                !showActivityOnly &&
                !isHaulers && (
                    <Grid item xs={isTemplateOn ? 12 : 6}>
                        <Box flexDirection="row" display="flex" justifyContent="flex-end">
                            <Tooltip title="Open Calendar">
                                <IconButton onClick={openCalendar} className={classes.calendarIcon}>
                                    <EventNoteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                )
            );
        };

        const renderShowJobs = () => {
            if (isTemplateOn || isHaulers) return;

            return (
                !isLoading &&
                !showActivityOnly &&
                !truck.activeSuspendPeriod &&
                !isTemplateOn && (
                    <Grid item xs={6} className={clsx(classes.truckItemResponsive)}>
                        <Box
                            component={"div"}
                            display={"flex"}
                            alignItems={"flex-end"}
                            onClick={open ? closeActivity : openTruckInfo}
                            className={clsx(classes.togglerContainer, "toggler")}
                        >
                            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            {open ? "Hide Jobs" : "Show Jobs"}
                        </Box>
                    </Grid>
                )
            );
        };

        const renderReasonData = () => {
            return (
                !isLoading &&
                showReason && (
                    <div className={classes.reasonContainer}>
                        <div className={classes.reasonItemContainer}>
                            <div className={classes.reasonItemTitle}>Notes:</div>
                            <span className={classes.reasonForLargeText}>{truck.activeSuspendPeriod.reason}</span>
                        </div>
                        {truck.activeSuspendPeriod && truck.activeSuspendPeriod.startDate && (
                            <div className={classes.reasonItemContainer}>
                                <span className={classes.reasonItemTitle}>Start Date:</span>
                                <span>{moment(truck.activeSuspendPeriod.startDate).format("YYYY-MM-DD h:mma")}</span>
                            </div>
                        )}
                        {truck.activeSuspendPeriod && truck.activeSuspendPeriod.endDate && (
                            <div className={classes.reasonItemContainer}>
                                <span className={classes.reasonItemTitle}>End Date:</span>
                                <span>{moment(truck.activeSuspendPeriod.endDate).format("YYYY-MM-DD h:mma")}</span>
                            </div>
                        )}
                    </div>
                )
            );
        };

        const getDistanceDataForHauler = (truck) => {
            if (_.isNull(truck.distance)) {
                if (truck.hasHqCoords) {
                    return "Out of radius";
                } else {
                    return "Address wasn't specified";
                }
            } else {
                if (account.companyProfile[MEASUREMENT_SYSTEM_NAME] === IMPERIAL) {
                    return parseFloat(truck.distance / MILES_TO_METERS_FACTOR, -1).toFixed(1) + MILES_UNIT;
                } else {
                    return parseFloat(truck.distance / KILOMETERS_TO_METERS_FACTOR, -1).toFixed(1) + KILOMETERS_UNIT;
                }
            }
        };

        const makeDistanceLink = (truck) => {
            const value = truck.distance;
            if (isHaulers) return getDistanceDataForHauler(truck);
            if (account.companyProfile[MEASUREMENT_SYSTEM_NAME] === IMPERIAL) {
                return parseFloat(value / MILES_TO_METERS_FACTOR, -1).toFixed(1) + MILES_UNIT;
            } else {
                return parseFloat(value / KILOMETERS_TO_METERS_FACTOR, -1).toFixed(1) + KILOMETERS_UNIT;
            }
        };
        const showTruckOnMap = (event) => {
            setTruckCellAnchor(truckCellRef);
            setOpenMap(true);
        };

        const handleTruckMapClose = () => {
            setOpenMap(false);
            setTruckCellAnchor(null);
        };

        const getTitleForTruckBlock = () => {
            if (isTemplateOn) {
                if (truck.truckIsBusy) return TRUCK_ITEM_TEMPLATE_HINT;
            } else {
                if (hasAnotherJobOnDate) return TRUCK_ITEM_REGULAR_JOB_HINT;
            }
        };

        const renderDistanceData = () => (
            <Grid container justify="space-between">
                <Box
                    component={"p"}
                    onClick={showTruckOnMap}
                    style={{ marginTop: 5, marginBottom: 0 }}
                    className="toggler"
                >
                    <Tooltip title="Click to see the map with a truck and pick up locations">
                        <h4>{makeDistanceLink(truck)}</h4>
                    </Tooltip>
                </Box>
            </Grid>
        );

        const ref = disabledDrag ? null : { ref: drag };

        return (
            <div
                {...ref}
                style={{ opacity }}
                onClick={(e) => handleTruckSelection(truck, e.ctrlKey || e.metaKey)}
                title={getTitleForTruckBlock()}
                className={clsx(
                    classes.truckCard,
                    isLoading && "-is-loading container-loader-wrap",
                    isSelected && "-is-selected",
                    isHaulers && "-is-hauler",
                    isLocalLoading && LOADER_WHITE_OVERLAY,
                    showActivityOnly && "-only-activity-data",
                    !isTemplateOn && hasAnotherJobOnDate && "-has-jobs-for-today",
                    isTemplateOn && truck.truckIsBusy && "-has-jobs-for-today",
                    truck.activeSuspendPeriod && classes.activeSuspendPeriod,
                    isDeployedTruck && classes.deployedTruck,
                )}
            >
                {isFleetList && (
                    <BorderLinearProgress readyPercent={isTemplateOn ? 0 : truck.progress} className={"progress-bar"} />
                )}
                {isLocalLoading && <Loader />}
                {message && (
                    <ErrorNotification
                        message={message}
                        type={"success"}
                        config={{ onClose: () => showMessage(null) }}
                    />
                )}
                {error && (
                    <ErrorNotification message={error} type={"error"} config={{ onClose: () => setError(null) }} />
                )}
                {canDrop && withDrop && (
                    <div className={classes.dropStyles} ref={drop}>
                        <div className={"drop-item"}>
                            <SwapIcon />
                            <p style={{ margin: 0, fontSize: 16, fontWeight: 500, marginLeft: 5 }}>
                                Replace {truck.deviceName} Truck
                            </p>
                        </div>
                    </div>
                )}
                <Box display="flex" flexDirection="row" alignItems="center">
                    {!isHaulers && isFleetList && !isLoading && <TruckItemIcon status={truck.status} />}
                    {!showActivityOnly && (
                        <div
                            id={"truck-cell-" + truck.id}
                            ref={truckCellRef}
                            className="content"
                            style={!isLoading ? { paddingTop: 0 } : {}}
                        >
                            {isLoading && <Loader />}
                            {isHaulers ? (
                                <Grid container justify="space-between">
                                    <Grid item xs={truck.usedInCurrentMonth ? 11 : 12} direction="row">
                                        <Box component={"div"} m={0} display={"flex"} alignItems={"flex-end"}>
                                            {!truck.useTruckIT && (
                                                <Tooltip title="This company doesn't use TruckIT" placement="left">
                                                    <WarningRounded className={classes.haulerWarningIcon} />
                                                </Tooltip>
                                            )}
                                            <h3>{truck.name}</h3>
                                        </Box>
                                    </Grid>
                                    {truck.usedInCurrentMonth && (
                                        <Grid item xs={1}>
                                            <div className={classes.asteriskOrange} />
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <Grid container justify="space-between">
                                    <h3>{truck?.deviceName}</h3>
                                    <Box component={"p"} m={0} className="--capitalize">
                                        {truck?.truckType}
                                    </Box>
                                </Grid>
                            )}
                            {showDistance &&
                                (isHaulers ? (
                                    _.isNull(truck.distance) ? (
                                        <h4 className={classes.haulerNoDistanceText}>{makeDistanceLink(truck)}</h4>
                                    ) : (
                                        renderDistanceData()
                                    )
                                ) : (
                                    renderDistanceData()
                                ))}

                            <Popover
                                open={openMap && !!truck.location}
                                onClose={handleTruckMapClose}
                                anchorEl={truckCellAnchor?.current}
                                disableRestoreFocus={true}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <div
                                    style={{
                                        width: "30vw",
                                        height: "30vh",
                                    }}
                                >
                                    <TruckLocMap
                                        truckData={{ truckLocation: truck.location, status: truck.status }}
                                        pickUpSite={pickUpSite}
                                        height={"30vh"}
                                    />
                                </div>
                            </Popover>
                        </div>
                    )}
                </Box>
                <Grid container display="flex" flexDirection="row" justifyContent="space-between">
                    <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {renderShowJobs()}
                        {renderReasonLabel()}
                        {renderShowCalendar()}
                    </Grid>
                    {(truckIsDispatched || truck.currentDriver) && !isLoading && (
                        <Grid
                            item
                            xs={truck.activeSuspendPeriod ? 6 : 12}
                            display={"flex"}
                            alignItems={"flex-start"}
                            className={truck.currentDriver && classes.truckItemResponsive}
                        >
                            <Box
                                component={"div"}
                                m={0}
                                display={"flex"}
                                alignItems={"flex-end"}
                                style={{ paddingRight: 12 }}
                                justifyContent={"flex-end"}
                                className={"driver"}
                            >
                                {truck.currentDriver && (
                                    <Typography
                                        style={{
                                            marginRight: truckIsDispatched ? 5 : 0,
                                            paddingBottom: 3,
                                        }}
                                    >
                                        {truck.currentDriver}
                                    </Typography>
                                )}
                                {truckIsDispatched && (
                                    <Box display={"flex"} justifyContent={"flex-end"}>
                                        <Tooltip title={"Dispatched for current job"}>
                                            <SendIcon className={"dispatched-icon"} />
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    )}
                </Grid>
                {renderReasonData()}
                {open && !isLoading && (
                    <div>
                        {!showActivityOnly && <Divider marginBottom={0} marginTop={0} />}
                        {!_.isEmpty(activityData) || showActivityOnly ? (
                            <React.Fragment>
                                <div className={clsx(scrolableTableStyles.scrolableTable, "activity-table")}>
                                    <div className="tbody">
                                        <div className="tr">
                                            <div className="th" />
                                            <div className="th">
                                                <div>Project / Job #</div>
                                            </div>
                                            <div className="th">
                                                <div className="--text-center">
                                                    {!isHaulers ? "Quantity" : "Trucks Assigned / Requested"}
                                                </div>
                                            </div>
                                            <div className="th" style={{ flex: 0 }} />
                                        </div>
                                    </div>
                                    {!_.isEmpty(activityData) ? (
                                        <div
                                            className={clsx("tbody", showActivityOnly && "-scroll")}
                                            style={{
                                                maxHeight: showActivityOnly ? 350 : "unset",
                                            }}
                                        >
                                            {rows.map((item, index) => {
                                                return (
                                                    <Row
                                                        key={item.jobOrderId}
                                                        classes={classes}
                                                        moveCard={moveCard}
                                                        item={item}
                                                        index={index}
                                                        account={account}
                                                        isHaulers={isHaulers}
                                                        removeTruckActivity={removeTruckActivityConfirmation}
                                                        openJoForEdit={showConfirmation}
                                                        history={history}
                                                        id={item.jobOrderId}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        !!showActivityOnly && (
                                            <div className="--text-center">
                                                <br />
                                                No jobs for today
                                            </div>
                                        )
                                    )}
                                </div>
                                {!_.isEmpty(activityData) && (
                                    <div className="content">
                                        <Box
                                            component={"div"}
                                            m={0}
                                            display={"flex"}
                                            alignItems={"center"}
                                            className={clsx(classes.togglerContainer, "toggler")}
                                            onClick={saveJobs}
                                        >
                                            {isHaulers ? <ExpandLessIcon /> : <SaveIcon />}
                                            {isHaulers ? "Close" : "Save Job Order"}
                                        </Box>
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            !showActivityOnly && (
                                <>
                                    <div className="content">No jobs for selected date range</div>
                                </>
                            )
                        )}
                    </div>
                )}
                {renderShowTrucksButton()}
                {showHaulersTrucks && !isLoading && allFieldsExist && (
                    <>
                        <Divider marginBottom={0} style={{ marginTop: "5px" }} />
                        <div className="content">
                            {_.isEmpty(haulersTrucks) ? (
                                <div>No trucks for selected company</div>
                            ) : (
                                <HaulerTrucksList
                                    haulersTrucks={haulersTrucks}
                                    handleDropEnd={handleDropEnd}
                                    haulerDefaultRate={truck.defaultRate}
                                />
                            )}
                        </div>
                        <Box
                            component={"div"}
                            m={0}
                            display={"flex"}
                            alignItems={"center"}
                            style={{ marginTop: 5 }}
                            className={clsx(classes.togglerContainer, "toggler")}
                            onClick={() => setShowHaulersTrucks(false)}
                        >
                            <ExpandLessIcon />
                            Hide Trucks
                            <LocalShippingIcon style={{ marginLeft: 5 }} />
                        </Box>
                    </>
                )}
                <AppModal isOpen={showCalendar} closeModal={closeCalendar} modalStyles={{ minWidth: 1500 }}>
                    <h2 className={clsx("title", classes.calendarHeader)}>
                        <Icon className={classes.uiMyFleet} />
                        {truck.deviceName}
                    </h2>
                    <JobCalendar
                        timezone={account.timezone}
                        truckId={truckId}
                        getEvents={requestTruckEvents}
                        resetEvents={resetTruckEvents}
                    />
                </AppModal>
                <ConfirmationModal
                    isOpen={joConfirmationId}
                    yesHandler={() => openJoForEdit(joConfirmationId)}
                    noHandler={() => setConfirmationToLeave(null)}
                />
                <ConfirmationModal
                    isOpen={!!deleteTruckItem}
                    question={"ARE YOU SURE YOU WANT TO REMOVE TRUCK FROM JOB?"}
                    yesHandler={() => removeTruckActivity(deleteTruckItem)}
                    noHandler={() => setDeleteTruckItem()}
                />
                {isSelected && <ProjectCheckIcon />}
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

TruckItem.propTypes = {
    hasAnotherJobOnDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    truckIsDispatched: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDirtyJoOrderForm: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isFleetList: PropTypes.bool.isRequired,
    isHaulers: PropTypes.bool.isRequired,
    showActivityOnly: PropTypes.bool.isRequired,
    truck: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    handleTruckSelection: PropTypes.func.isRequired,
    handleDropEnd: PropTypes.func.isRequired,
    selectedTrucks: PropTypes.array.isRequired,
    josListFormSelector: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    truckActivityData: PropTypes.object,
    currentDriver: PropTypes.string,
    currentJobOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.instanceOf(Date), string]),
    pickUpSite: PropTypes.object,
    showDistance: PropTypes.bool,
    resetTruckEvents: PropTypes.func,
    isTemplateOn: PropTypes.bool,
    requestTruckEvents: PropTypes.func.isRequired,
    resetEvents: PropTypes.func.isRequired,
    allFieldsExist: PropTypes.bool,
    withDrop: PropTypes.bool,
    disabledDrag: PropTypes.bool,
    isDeployedTruck: PropTypes.bool,
    replaceTrucks: PropTypes.func.isRequired,
};

TruckItem.defaultProps = {
    showActivityOnly: false,
    truckIsDispatched: false,
    isDirtyJoOrderForm: false,
    isFleetList: false,
    isLoading: false,
    isHaulers: false,
    isSelected: false,
    hasAnotherJobOnDate: false,
    handleTruckSelection: () => {},
    handleDropEnd: () => {},
    selectedTrucks: [],
    josListFormSelector: {},
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
            };
        },
        (dispatch) => ({
            requestTruckEvents: (id, params) => dispatch(getTruckEvents(id, params)),
            resetEvents: () => dispatch(resetTruckEvents()),
            replaceTrucks: (oldTruck, newTruck) => dispatch(replaceTrucksAction(oldTruck, newTruck)),
        }),
    )(TruckItem),
);
