import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import JobOrdersWrapper from "../components/jobs/jobsWrapper";
import Container from "@material-ui/core/Container";
import { GLOBAL_CONTENT_WIDTH } from "../styles/globalThemeConfig";
import JobsSubNav from "../components/jobs/jobComponents/jobsSubNav";
import { openJobOrderById } from "../helpers/jobOrders";
import { connect } from "react-redux";
import { saveCurrentJobOrderIdInRedux } from "../actions/jobOrders";
import { ROUTE_JOBS } from "../routes/globalRoutes";
import { GET_USER_INITIAL_FILTERS } from "../components/global/searchAndFilters";

const useStyles = makeStyles((theme) => ({
    myJobsPage: {
        width: GLOBAL_CONTENT_WIDTH,
        padding: 0,
        position: "relative",
    },
    verticalDivider: {
        "&::before": {
            top: theme.spacing(3),
        },
    },
}));

export const MY_JOB_BOARD_LIST_FORM = "jobBoardFilters";

function Jobs(props) {
    const classes = useStyles();
    const { children, setActiveJO, setCurrentJobOrder, history } = props;
    const [isListView, setListView] = useState(false);

    const setActive = (jo) => {
        openJobOrderById(
            {
                history,
                _goTo: ROUTE_JOBS.JOB_BOARD,
            },
            jo.id,
            {
                callBack: setCurrentJobOrder,
                args: jo.id,
            },
        );
    };

    return (
        <div>
            <JobsSubNav setListView={setListView} isListView={isListView} showDispatchButton={true} />
            <Container className={clsx(classes.myJobsPage)}>
                <Grid container component="div" spacing={6}>
                    <Grid
                        item
                        xs={isListView ? 12 : 6}
                        component="div"
                        className={clsx(!isListView && "job-board-page")}
                    >
                        <JobOrdersWrapper
                            form={MY_JOB_BOARD_LIST_FORM}
                            pageUrl={ROUTE_JOBS.JOB_BOARD}
                            initialHeader={true}
                            initialValues={GET_USER_INITIAL_FILTERS(MY_JOB_BOARD_LIST_FORM)}
                            setActiveJO={setActiveJO}
                            dateRangeSize={7}
                            setActive={setActive}
                            isListView={isListView}
                        />
                    </Grid>
                    {!isListView && children && (
                        <Grid item xs={6} component="div" className={clsx("verticalDivider", classes.verticalDivider)}>
                            {children}
                        </Grid>
                    )}
                </Grid>
            </Container>
        </div>
    );
}

Jobs.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    setActiveJO: PropTypes.bool.isRequired,
    setCurrentJobOrder: PropTypes.func.isRequired,
};

Jobs.defaultProps = {
    setActiveJO: true,
};

export default withRouter(
    connect(
        (state) => {
            return {
                jobOrders: state.jobOrders.list,
            };
        },
        (dispatch) => ({
            setCurrentJobOrder: (jobOrderId) => {
                dispatch(saveCurrentJobOrderIdInRedux(jobOrderId));
            },
        }),
    )(Jobs),
);
