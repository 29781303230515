import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { formValueSelector, reduxForm, Field, hasSubmitSucceeded } from "redux-form";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import HiddenInput from "../core/form/hiddenInput";
import TextInputComponent from "../core/form/textInputComponent";
import PillSwitch from "../core/form/pillSwitch";
import AppModal from "../core/modal";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";

import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import ConfirmationModal from "../core/confirmationModal";
import { ActionLink, SimpleLink } from "../core/link";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import DragableTable from "../core/dragableTable";
import ErrorNotification from "../core/notification";
import { handleError } from "../../helpers/global";
import {
    IS_ADMIN_USER,
    UNIT_OF_MEASURE_CUBIC_YARD,
    UNIT_OF_MEASURE_CUBIC_METER,
    UNIT_OF_MEASURE_TON,
    UNIT_OF_MEASURE_TONNE,
    UNIT_OF_MEASURE_BY_ID,
} from "../../constants/maps";
import SuccessNotification from "../core/successNotification";
import SettingsCompanySelector from "./settingsCompanySelector";
import { Validation } from "../../helpers/validation";
import {
    selectUnitsOfMeasure,
    selectCustomUnitsOfMeasure,
    selectUnitsOfMeasureMeta,
    selectUnitOfMeasureDetails,
    actions,
} from "../../reducers/unitsOfMeasureReducer";
import {
    getUnitsOfMeasure,
    initializeUnitOfMeasureById,
    createUnitsOfMeasure,
    deleteUnitsOfMeasure,
    updateUnitsOfMeasure,
    updateUOMPosition,
    refreshUnitsOfMeasure,
} from "../../actions/unitsOfMeasure";
import { updateMeasurementSystemSettings } from "../../actions/companies";
import { selectAccount } from "../../selectors/index";
import {
    SETTINGS_UOM_COMPANY_SELECTOR,
    SETTINGS_UOM_EDIT_UON,
    SETTINGS_UOM_CREATE_NEW_UOM,
    UNITS_OF_MEASURE_TABLE,
} from "../../constants/forms";
import UnitsOfMeasureWarning from "./constants/UnitsOfMeasureWarning";

const useStyles = makeStyles(() => ({
    modalForm: {
        "& .measure-pill": {
            width: "100%",

            "& .pill": {
                width: "100%",
            },
        },
        "& .form-label-medium": {
            marginTop: 5,
            fontWeight: "normal",
            fontSize: "11px",
            marginLeft: 14,
        },
    },
}));

const TABLE_COLUMNS = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "measurementBase",
        label: "Measurement Base",
    },
    {
        id: "conversion",
        label: "Conversion",
    },
    {
        id: "action",
        label: TEXT_ACTIONS_TABLE_CELL,
    },
];

const IMPERIAL_SYSTEM = 1;
const METRIC_SYSTEM = 2;

const WEIGHT_CONVERSION = "1";
const VOLUMETRIC_CONVERSION = "2";

const MEASUREMENT_BASE = {
    [WEIGHT_CONVERSION]: "Weight",
    [VOLUMETRIC_CONVERSION]: "Volumetric",
};

const CONVERSIONS = {
    [WEIGHT_CONVERSION]: [
        { value: UNIT_OF_MEASURE_TON, label: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TON] },
        { value: UNIT_OF_MEASURE_TONNE, label: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TONNE] },
    ],
    [VOLUMETRIC_CONVERSION]: [
        { value: UNIT_OF_MEASURE_CUBIC_YARD, label: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_YARD] },
        { value: UNIT_OF_MEASURE_CUBIC_METER, label: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_METER] },
    ],
};

const validate = (values) => {
    const errors = {};
    if (!values.measurementBase) errors.measurementBaseError = "Required";
    if (!values.baseUomId) errors.baseUomIdError = "Required";

    return errors;
};

const AddNewUnitOfMeasureForm = compose(reduxForm({ validate }))(
    ({
        OpenModalComponent,
        handleSubmit,
        form,
        destroy,
        submitting,
        error,
        title,
        change,
        submitButtonTitle,
        isEdit,
    }) => {
        const classes = useStyles();

        const [open, setOpen] = useState(false);

        const openModal = () => setOpen(true);

        const measurementBase = useSelector((state) => formValueSelector(form)(state, "measurementBase"));
        const hasSubmitSucceess = useSelector(hasSubmitSucceeded(form));

        const { loading } = useSelector(selectUnitOfMeasureDetails);

        useEffect(() => {
            if (hasSubmitSucceess) onCloseModal();
        }, [hasSubmitSucceess]);

        const onCloseModal = async () => {
            await setOpen(false);
            await destroy();
        };

        const onChangeMeasurementBase = (value) => {
            change("measurementBase", value);
            change("baseUomId", null);
        };

        return (
            <>
                {OpenModalComponent(openModal)}
                <AppModal isOpen={open} form={form} closeModal={onCloseModal}>
                    <form
                        onSubmit={handleSubmit}
                        className={clsx(classes.modalForm, (submitting || loading) && LOADER_WHITE_OVERLAY)}
                    >
                        {(submitting || loading) && <Loader />}
                        <h2 className="title">{title}</h2>
                        <Field
                            type="text"
                            name="name"
                            validate={[Validation.required]}
                            placeholder="Name"
                            label="Name"
                            component={TextInputComponent}
                        />
                        <br />
                        <br />
                        <Field
                            type="number"
                            name="conversionRate"
                            validate={[Validation.required, Validation.numbersPositive]}
                            placeholder="Rate For Conversion"
                            label="Rate For Conversion"
                            component={TextInputComponent}
                            disabled={isEdit}
                        />
                        <br />
                        <br />
                        <Grid container spacing={1} justify={"space-between"} alignItems={"center"}>
                            <Grid item xs>
                                <Field
                                    type="radio"
                                    name="measurementBase"
                                    value={WEIGHT_CONVERSION}
                                    label={MEASUREMENT_BASE[WEIGHT_CONVERSION]}
                                    className="measure-pill"
                                    component={PillSwitch}
                                    onChange={(value) => onChangeMeasurementBase(WEIGHT_CONVERSION)}
                                    disabled={isEdit}
                                />
                            </Grid>
                            <Grid item xs>
                                <Field
                                    type="radio"
                                    name="measurementBase"
                                    value={VOLUMETRIC_CONVERSION}
                                    label={MEASUREMENT_BASE[VOLUMETRIC_CONVERSION]}
                                    className="measure-pill"
                                    component={PillSwitch}
                                    onChange={(value) => onChangeMeasurementBase(WEIGHT_CONVERSION)}
                                    disabled={isEdit}
                                />
                            </Grid>
                        </Grid>
                        <label className="form-label-medium">Measurement base</label>
                        <Field type="hidden" name="measurementBaseError" component={HiddenInput} />
                        <br />
                        {measurementBase && (
                            <>
                                <Grid container spacing={1} justify="space-between" alignItems="center">
                                    {CONVERSIONS[measurementBase].map((item, index) => (
                                        <Grid item xs>
                                            <Field
                                                type="radio"
                                                name="baseUomId"
                                                value={item.value.toString()}
                                                label={item.label}
                                                className="measure-pill"
                                                component={PillSwitch}
                                                disabled={isEdit}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <label className="form-label-medium">Units of measure for conversion</label>
                                <Field type="hidden" name="baseUomIdError" component={HiddenInput} />
                                <br />
                            </>
                        )}
                        <ButtonsGroup>
                            <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit">{submitButtonTitle}</PrimaryButton>
                        </ButtonsGroup>
                    </form>
                </AppModal>
                {error && <ErrorNotification message={error} />}
            </>
        );
    },
);

const DeleteUOM = ({ uom }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const closeModal = () => setOpen(false);

    const openModal = () => setOpen(true);

    const yesHandler = async () => {
        try {
            closeModal();
            await dispatch(deleteUnitsOfMeasure(uom.id));
            setSuccess(`Unit of measure ${uom.name} was successfully deleted`);
        } catch (error) {
            setError(handleError(error).message);
        }
    };

    return (
        <>
            <IconButton title="Delete UOM" onClick={openModal}>
                <DeleteOutlineIcon />
            </IconButton>
            <ConfirmationModal
                isOpen={open}
                question={`Are you sure you want to delete ${uom.name} unit of measure?`}
                yesHandler={yesHandler}
                noHandler={closeModal}
            />
            {success && <SuccessNotification message={success} config={{ onClose: () => setSuccess(false) }} />}
            {error && <ErrorNotification message={error} simpleString config={{ onClose: () => setError(false) }} />}
        </>
    );
};

const EditUOM = ({ uom }) => {
    const dispatch = useDispatch();

    return (
        <AddNewUnitOfMeasureForm
            OpenModalComponent={(openModal) => (
                <IconButton
                    title="Edit UOM"
                    onClick={() => {
                        dispatch(initializeUnitOfMeasureById(uom.id));
                        openModal();
                    }}
                >
                    <EditIcon />
                </IconButton>
            )}
            submitButtonTitle="SAVE"
            uomId={uom.id}
            title="EDIT UNIT OF MEASURE"
            form={SETTINGS_UOM_EDIT_UON}
            onSubmit={(values) => dispatch(updateUnitsOfMeasure(uom, values))}
            isEdit
        />
    );
};

const returnUomRow = (uom, index) => ({
    key: uom.id,
    id: uom.id,
    index,
    name: uom.name,
    isDefault: uom.isDefault,
    measurementSystem: uom.measurementSystem,
    measurementBase: !uom.isDefault && MEASUREMENT_BASE[uom.measurementBase],
    conversion:
        uom.conversion[0] && !uom.isDefault
            ? `${uom.conversion[0].conversionRate} ${uom.conversion[0].baseUomName}s`
            : "",
    action: !uom.isDefault ? (
        <Grid key={uom.id} container justify={"center"} style={{ minHeight: 50 }}>
            <EditUOM uom={uom} />
            <DeleteUOM uom={uom} />
        </Grid>
    ) : (
        <Grid key={uom.id} container justify={"center"} style={{ minHeight: 50 }} />
    ),
});

const StyledSwitch = withStyles((theme) => ({
    root: {
        width: 45,
        height: 25,
        padding: 0,
        borderRadius: 45,
        display: "flex",
    },
    switchBase: {
        padding: 4,
        color: theme.palette.common.white,
        "&$checked": {
            transform: "translate(75% , 0px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#229440",
                borderColor: "#229440",
            },
        },
    },
    thumb: {
        width: 17,
        height: 17,
        boxShadow: "none",
    },
    track: {
        borderColor: "#979797",
        borderRadius: 45,
        opacity: 1,
        color: theme.palette.common.white,
        backgroundColor: "#979797",
    },
    checked: {},
}))(Switch);

const UnitsOfMeasureSettings = ({ form, dispatch, change, error: submitError, getUserData }) => {
    const [error, setError] = useState();
    const account = useSelector(selectAccount);
    const unitsOfMeasure = useSelector(selectUnitsOfMeasure);
    const customUnitsOfMeasure = useSelector(selectCustomUnitsOfMeasure);
    const { loading, error: uomError } = useSelector(selectUnitsOfMeasureMeta);

    const hasCreateUomSucceeded = useSelector(hasSubmitSucceeded(SETTINGS_UOM_CREATE_NEW_UOM));
    const hasUpdateUomSucceeded = useSelector(hasSubmitSucceeded(SETTINGS_UOM_EDIT_UON));

    const company = useSelector((state) => formValueSelector(SETTINGS_UOM_COMPANY_SELECTOR)(state, "company"));
    const systems = useSelector((state) => formValueSelector(form)(state, "systems"));

    const tableRows = (customUnitsOfMeasure || []).map(returnUomRow);
    const haveBothSystems = systems && systems.includes(IMPERIAL_SYSTEM) && systems.includes(METRIC_SYSTEM);

    const handleChangeSystem = async (value) => {
        if (systems.includes(value)) {
            change(
                "systems",
                systems.filter((i) => i !== value),
            );
        } else {
            change("systems", [...systems, value]);
        }
        dispatch(updateMeasurementSystemSettings(getUserData));
    };

    const onMoveRow = (dragIndex, hoverIndex) => {
        dispatch(actions.moveUom({ dragIndex, hoverIndex }));
    };

    const onEndRow = async (item) => {
        try {
            await dispatch(updateUOMPosition(item.id, item.index));
        } catch (error) {
            setError(handleError(error).message);
            dispatch(refreshUnitsOfMeasure());
        }
    };

    const companyId = company?.value || account.company.id;

    useEffect(() => {
        if (companyId) {
            dispatch(getUnitsOfMeasure({ companyId }));
        }

        return () => {
            dispatch(actions.initializeUnitsOfMeasure());
        };
    }, [companyId]);

    return (
        <Box className={clsx(loading && LOADER_WHITE_OVERLAY)}>
            {loading && <Loader />}
            <AddNewUnitOfMeasureForm
                OpenModalComponent={(openModal) => (
                    <ActionLink>
                        <AddCircleOutlineIcon />
                        <SimpleLink onClick={openModal}>Add NEW UNIT OF MEASURE</SimpleLink>
                    </ActionLink>
                )}
                submitButtonTitle="CREATE"
                destroyOnUnmount
                title="ADD NEW UNIT OF MEASURE"
                form={SETTINGS_UOM_CREATE_NEW_UOM}
                onSubmit={(values) => dispatch(createUnitsOfMeasure({ ...values, companyId }))}
            />
            <br />
            <FormControlLabel
                style={{
                    marginLeft: 4,
                }}
                control={
                    <StyledSwitch
                        checked={systems?.includes(METRIC_SYSTEM)}
                        disabled={systems?.includes(METRIC_SYSTEM) && systems?.length === 1}
                        onChange={() => handleChangeSystem(METRIC_SYSTEM)}
                        name={"systems"}
                    />
                }
                label={
                    <Typography
                        style={{
                            color: "#505050",
                            cursor: "pointer",
                            display: "flex",
                            fontSize: 16,
                            alignItems: "center",
                            fontWeight: 500,
                            marginLeft: 4,
                        }}
                    >{`Use metric system (${(unitsOfMeasure || [])
                        .filter((i) => i.measurementSystem === METRIC_SYSTEM)
                        .map((i) => i.name)
                        .sort()
                        .join(", ")})`}</Typography>
                }
            />
            <br />
            <br />
            <FormControlLabel
                style={{
                    marginLeft: 4,
                }}
                control={
                    <StyledSwitch
                        checked={systems?.includes(IMPERIAL_SYSTEM)}
                        disabled={systems?.includes(IMPERIAL_SYSTEM) && systems?.length === 1}
                        onChange={() => handleChangeSystem(IMPERIAL_SYSTEM)}
                        name={"systems"}
                    />
                }
                label={
                    <Typography
                        style={{
                            color: "#505050",
                            cursor: "pointer",
                            display: "flex",
                            fontSize: 16,
                            alignItems: "center",
                            fontWeight: 500,
                            marginLeft: 4,
                        }}
                    >{`Use imperial system (${(unitsOfMeasure || [])
                        .filter((i) => i.measurementSystem === IMPERIAL_SYSTEM)
                        .map((i) => i.name)
                        .sort()
                        .join(", ")})`}</Typography>
                }
            />
            <br />
            <br />
            {haveBothSystems && <UnitsOfMeasureWarning />}
            {IS_ADMIN_USER(account.company.id) && (
                <SettingsCompanySelector
                    form={SETTINGS_UOM_COMPANY_SELECTOR}
                    initialValues={{
                        company: {
                            value: account.company.id,
                            label: account.company.name,
                        },
                    }}
                />
            )}
            <DragableTable
                caption="You can change the order in which custom UOMs will appear using the drag and drop functionality"
                rows={tableRows}
                columns={TABLE_COLUMNS}
                onMoveRow={onMoveRow}
                onEndRow={onEndRow}
            />
            {(error || uomError) && <ErrorNotification message={error || uomError} />}
            {hasUpdateUomSucceeded && <SuccessNotification message="Unit Of Measure Data Updated" />}
            {hasCreateUomSucceeded && <SuccessNotification message="New unit of measure was successfully created" />}
        </Box>
    );
};

export default reduxForm({
    form: UNITS_OF_MEASURE_TABLE,
})(UnitsOfMeasureSettings);
