import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import FleetList from "./fleetList";
import { getDispatchTrucks, getHaulerTrucks } from "../../dataServers/trucks";
import Loader from "../core/loader";
import clsx from "clsx";
import TabsCustom from "../core/tabsCustom";
import { IS_ADMIN_USER } from "../../constants/maps";
import { useHistory, withRouter } from "react-router";
import { connect, useDispatch } from "react-redux";
import { LOADER_WHITE_OVERLAY, PICKUP_DISTANCE, SERVER_DATE_FORMAT } from "../../constants/global";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import { destroy, formValueSelector } from "redux-form";
import {
    changeInitialTrucksListLoadingSkip,
    changeRequestedFleetListDate,
    resetHaulerParam,
    resetJobOrderParams,
    updateActiveDispatchedTruckOrder,
} from "../../actions/jobOrders";
import { dispatchJobBoardRoute, ROUTE_JOBS } from "../../routes/globalRoutes";
import { selectIfIsStartCopyJobNextDay, selectIfIsStartDateByDefault } from "../../selectors/index";
import { selectSelectedTruckSolutionList } from "../../selectors/smartDispatchSelectors";
import { isValidDate } from "../../helpers/date";
import { SMART_DISPATCH_TABS_LIST } from "../../containers/SmartDispatchPage";
import { usePrevious } from "../../helpers/usePrevious";
import { myFleetNeedsToBeUpdatedChange } from "../../actions/smartDispatch";
import {
    IMPERIAL,
    KILOMETERS_TO_METERS_FACTOR,
    MEASUREMENT_SYSTEM_NAME,
    MILES_TO_METERS_FACTOR,
} from "../../constants/accounts";
import { RADIUS_FIELD_NAME, SET_RADIUS_TO_SEARCH_TRUCKS } from "./radiusInputForm";
import { MULTIPLE_COMMA_SEPARATED_SEARCH_PLACEHOLDER } from "../global/searchAndFilters";
import { useDebounce } from "../../hooks/useDebounce";
import Axios from "axios";
import { JOLI_FIELD_ID } from "../jobs/constants";

export const FLEET_TRUCK_TAB_INDEX = 0;
export const HAULERS_TAB_INDEX = 1;

let requestPromise;
let cancelPromise = null;

const Fleet = React.memo(
    (props) => {
        const {
            account,
            jobFormId,
            getFleetForJoById,
            jobsListFormId,
            match: {
                params: { jobOrderId },
            },
            joListStartDate,
            joStartDate,
            joDate,
            poLineItem,
            isAppearNewJobOrderForm,
            requestFleet,
            isStartDateByDefault,
            isStartCopyJobNextDay,
            newJobOrderPageLeave,
            skipTrucksInitialListingLoading,
            dispatchedTrucksOrder,
            joStartTime,
            enabledTemplate,
            showHaulers,
            activeSmartDispatchTab,
            myFleetNeedsToBeUpdated,
            currentBundle,
            locationId,
            radiusInputValue,
            templateStartDate,
            templateEndDate,
            weekdays,
            updatedStartDate,
            updatedPickupSite,
            dispatchPageDate,
            activeHaulerParam,
            additionalParams,
            isSplit,
            joliItems,
        } = props;
        const [isLoading, setLoading] = useState(false);
        const dispatch = useDispatch();
        const history = useHistory();
        const pathname = history.location.pathname;
        const [activeTab, setActiveTab] = useState(0);
        const [isLocalLoading, setLocalLoading] = useState(false);
        const [trucks, setTrucksList] = useState([]);
        const [haulers, setHaulersList] = useState([]);
        const [haulersLoaded, setHaulersLoaded] = useState(false);
        const [allTrucksCount, setTrucksCount] = useState(0);
        const [allHaulersCount, setHaulersCount] = useState(0);
        const setLoadingValue = (concat, value) => (concat ? setLoading(value) : setLocalLoading(value));
        const [appliedFilters, setFilters] = useState();
        const [appliedHaulersFilters, setHaulersFilters] = useState();

        const isSDPage = pathname.includes(ROUTE_JOBS.SMART_DISPATCH);

        const prevActiveSmartDispatchTab = usePrevious(activeSmartDispatchTab);
        const prevEnabledTemplate = usePrevious(enabledTemplate);

        const isNotEnteredTemplateDateRange = enabledTemplate && (!templateStartDate || !templateEndDate);

        const showMoreTrucks = (concat, params = {}, getFleetForJoById, forceLoad = false) => {
            const currentPage = Math.ceil(trucks.length / GLOBAL_COUNT_TO_LOAD);
            const nextPage = concat ? +currentPage + 1 : 1;
            const withGranted =
                IS_ADMIN_USER(account.company.id) && joDate !== null
                    ? { orderId: getFleetForJoById }
                    : { withGranted: true };
            const loader = concat || _.isEmpty(trucks);
            let startDate = (!newJobOrderPageLeave && poLineItem && joStartDate) || joListStartDate;

            // in case after assign truck to route
            if (!startDate && pathname.includes(ROUTE_JOBS.SMART_DISPATCH) && currentBundle) {
                startDate = currentBundle.bundleDate;
            }
            // prevent request for jobs listing if there is selected Job Order
            if (!(poLineItem && joStartDate) && joListStartDate && jobOrderId && !forceLoad) {
                return false;
            }

            if (jobOrderId && !getFleetForJoById && IS_ADMIN_USER(account.company.id)) {
                return false;
            }

            if (
                pathname.includes("new") &&
                (isStartDateByDefault || isStartCopyJobNextDay) &&
                !(poLineItem && joStartDate)
            ) {
                return false;
            }

            // prevent request after clicking Copy Job from My Jobs page
            if (skipTrucksInitialListingLoading) {
                dispatch(changeInitialTrucksListLoadingSkip(false));

                return false;
            }

            // prevent request if dispatch listing date is differs from selected Job Order page
            if (jobOrderId && !(poLineItem && joStartDate) && !pathname.includes(dispatchJobBoardRoute)) {
                return false;
            }

            if (!isStartDateByDefault && (isAppearNewJobOrderForm || poLineItem) && !joStartDate) {
                return false;
            }

            if (poLineItem && joStartTime && !enabledTemplate) return false;

            if (dispatchedTrucksOrder === PICKUP_DISTANCE && (!locationId || !radiusInputValue)) {
                return false;
            }

            if (enabledTemplate && dispatchedTrucksOrder === PICKUP_DISTANCE) return false;

            if (isNotEnteredTemplateDateRange) return false;

            const pickupDistanceParams = {};
            if (locationId && radiusInputValue && dispatchedTrucksOrder === PICKUP_DISTANCE) {
                pickupDistanceParams.radius =
                    account.companyProfile[MEASUREMENT_SYSTEM_NAME] === IMPERIAL
                        ? Math.ceil(radiusInputValue * MILES_TO_METERS_FACTOR)
                        : Math.ceil(radiusInputValue * KILOMETERS_TO_METERS_FACTOR);

                pickupDistanceParams.locationId = locationId;
            }

            const templateParams = {};
            const regularParams = {};

            if (enabledTemplate) {
                if (templateStartDate && isValidDate(templateStartDate))
                    templateParams.startDate = moment(templateStartDate).format(SERVER_DATE_FORMAT);
                if (templateEndDate && isValidDate(templateEndDate))
                    templateParams.endDate = moment(templateEndDate).format(SERVER_DATE_FORMAT);
                if (weekdays && weekdays.length) templateParams.weekdays = weekdays.join();
            } else {
                regularParams.jobsForDate = poLineItem
                    ? moment(startDate).format(SERVER_DATE_FORMAT)
                    : isSDPage
                    ? moment(startDate).format(SERVER_DATE_FORMAT)
                    : moment(dispatchPageDate).format(SERVER_DATE_FORMAT);
            }

            if (!isValidDate(regularParams.jobsForDate)) return false;

            if (requestPromise && cancelPromise) {
                cancelPromise.cancel();
                cancelPromise = null;
            }

            const cancelToken = Axios.CancelToken.source();
            cancelPromise = cancelToken;
            requestPromise = getDispatchTrucks({
                page: concat ? nextPage : 1,
                order: dispatchedTrucksOrder,
                ...withGranted,
                ...params,
                ...pickupDistanceParams,
                ...templateParams,
                ...regularParams,
                ...(additionalParams || {}),
                cancelToken,
            });

            dispatch(changeRequestedFleetListDate(startDate));
            setLoadingValue(loader, true);
            requestPromise
                .then((trucksData) => {
                    requestPromise = null;

                    const newTrucks = trucksData.data
                        .map((i) => ({
                            ...i,
                            deviceName: i.truckName,
                            truckType: i.truckType && i.truckType.name,
                        }))
                        .sort((a, b) => a.deviceName - b.deviceName);
                    const trucksList = concat ? [...trucks, ...newTrucks] : newTrucks;

                    setTrucksList(trucksList);
                    setLoadingValue(loader, false);
                    setTrucksCount(trucksData.meta.count);
                })
                .catch(() => {
                    setLoadingValue(loader, false);
                });
        };

        const showMoreHaulers = (concat, params = {}) => {
            const currentPage = Math.ceil(haulers.length / GLOBAL_COUNT_TO_LOAD);
            const nextPage = concat ? +currentPage + 1 : 1;
            const loader = concat || _.isEmpty(haulers);

            const restParams = {};

            restParams.order = activeHaulerParam;

            const pickupDistanceParams = {};
            if (locationId && radiusInputValue && activeHaulerParam === PICKUP_DISTANCE) {
                pickupDistanceParams.radius =
                    account.companyProfile[MEASUREMENT_SYSTEM_NAME] === IMPERIAL
                        ? Math.ceil(radiusInputValue * MILES_TO_METERS_FACTOR)
                        : Math.ceil(radiusInputValue * KILOMETERS_TO_METERS_FACTOR);

                pickupDistanceParams.locationId = locationId;
                pickupDistanceParams.order = "closest";
                restParams.order = "closest";
            }

            if (restParams.order === PICKUP_DISTANCE) return false;

            setLoadingValue(loader, true);

            getHaulerTrucks({
                page: concat ? nextPage : 1,
                ...params,
                ...pickupDistanceParams,
                ...restParams,
            })
                .then((haulersData) => {
                    const newTrucks = haulersData.data.sort((a, b) => a.name - b.name);
                    const headersList = concat ? [...haulers, ...newTrucks] : newTrucks;

                    setHaulersList(headersList);
                    setLoadingValue(loader, false);
                    setHaulersLoaded(true);
                    setHaulersCount(haulersData.meta.count);
                })
                .catch(() => {
                    setLoadingValue(loader, false);
                });
        };

        const updateListByFilters = (filters = {}, clear = false, isHaulers = false) => {
            let newFilters = _.cloneDeep(filters);

            if (isHaulers) {
                !_.isEqual(appliedHaulersFilters, newFilters) && setHaulersFilters(newFilters);
            } else {
                !_.isEqual(appliedFilters, newFilters) && setFilters(newFilters);
            }
        };

        const prevDispatchPageDate = usePrevious(dispatchPageDate);
        const prevPoLineItem = usePrevious(poLineItem);

        useEffect(() => {
            if (
                (prevDispatchPageDate &&
                    dispatchPageDate &&
                    dispatchPageDate !== prevDispatchPageDate &&
                    !poLineItem) ||
                (prevPoLineItem && !poLineItem)
            ) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [dispatchPageDate, prevDispatchPageDate, poLineItem]);

        useEffect(() => {
            if (isValidDate(updatedStartDate) && updatedStartDate && poLineItem) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [updatedStartDate, poLineItem]);

        useEffect(() => {
            if (updatedPickupSite && poLineItem) {
                if (activeTab) showMoreHaulers(false, appliedHaulersFilters);
                else showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [updatedPickupSite]);

        useEffect(() => {
            if (joStartTime) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [joStartTime]);

        useEffect(() => {
            if (joDate && isSDPage) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [joDate]);

        useEffect(() => {
            if (
                SMART_DISPATCH_TABS_LIST.includes(activeSmartDispatchTab) &&
                SMART_DISPATCH_TABS_LIST.includes(prevActiveSmartDispatchTab) &&
                prevActiveSmartDispatchTab !== activeSmartDispatchTab &&
                activeTab !== HAULERS_TAB_INDEX
            ) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [activeSmartDispatchTab]);

        useEffect(() => {
            return () => {
                dispatch(resetJobOrderParams());
                dispatch(resetHaulerParam());
            };
        }, []);

        useEffect(() => {
            return () => {
                dispatch(destroy("radiusInput"));
            };
        }, []);

        useEffect(() => {
            if (myFleetNeedsToBeUpdated) {
                dispatch(myFleetNeedsToBeUpdatedChange(false));
                if (activeTab === FLEET_TRUCK_TAB_INDEX) {
                    showMoreTrucks(false, appliedFilters, getFleetForJoById);
                }
            }
        }, [myFleetNeedsToBeUpdated]);

        const [, showMoreTrucksWithDebounce] = useDebounce(showMoreTrucks);

        useEffect(() => {
            const isNeedChangeOrder = dispatchedTrucksOrder !== "name" && dispatchedTrucksOrder !== "-name";
            if (enabledTemplate && !isNeedChangeOrder && isNotRequested()) {
                if (activeTab) return;
                showMoreTrucksWithDebounce(false, appliedFilters, getFleetForJoById);
            }
            if (enabledTemplate && isNeedChangeOrder && isNotRequested()) {
                if (activeTab) return;
                dispatch(updateActiveDispatchedTruckOrder("name"));
            }
        }, [templateStartDate, templateEndDate, weekdays, enabledTemplate]);

        useEffect(() => {
            if (!enabledTemplate && prevEnabledTemplate && isNotRequested()) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [enabledTemplate]);

        const prevDispatchedTrucksOrder = usePrevious(dispatchedTrucksOrder);

        useEffect(() => {
            if (
                dispatchedTrucksOrder &&
                prevDispatchedTrucksOrder &&
                prevDispatchedTrucksOrder !== dispatchedTrucksOrder &&
                !activeTab &&
                prevPoLineItem === poLineItem
            ) {
                showMoreTrucks(false, appliedFilters, getFleetForJoById);
            }
        }, [dispatchedTrucksOrder, poLineItem]);

        const prevActiveHaulerParam = usePrevious(activeHaulerParam);

        useEffect(() => {
            if (
                activeHaulerParam &&
                prevActiveHaulerParam &&
                prevActiveHaulerParam !== activeHaulerParam &&
                activeTab &&
                prevPoLineItem === poLineItem
            ) {
                showMoreHaulers(false, appliedHaulersFilters);
            }
        }, [activeHaulerParam, poLineItem]);

        useEffect(() => {
            if (requestFleet) {
                if (activeTab) {
                    showMoreHaulers(false, appliedHaulersFilters);
                } else {
                    showMoreTrucks(false, appliedFilters, getFleetForJoById);
                }
            }
        }, [requestFleet]);

        const tabsPanels = [];
        const tabsHeaders = [];
        const memoizedShowMoreTrucks = useCallback(
            (concat, params) => {
                showMoreTrucks(concat, params, getFleetForJoById, true);
            },
            [
                trucks,
                getFleetForJoById,
                dispatchedTrucksOrder,
                isAppearNewJobOrderForm,
                radiusInputValue,
                locationId,
                enabledTemplate,
                templateStartDate,
                templateEndDate,
                weekdays,
            ],
        );
        const memoizedShowMoreHaulers = useCallback(
            (concat, params) => {
                showMoreHaulers(concat, params, getFleetForJoById);
            },
            [haulers, getFleetForJoById, activeHaulerParam, radiusInputValue, locationId, enabledTemplate, poLineItem],
        );
        const memoizedUpdateListByFilters = useCallback(
            (filters, clear, isHaulers) => {
                updateListByFilters(filters, clear, isHaulers);
            },
            [appliedHaulersFilters, appliedFilters],
        );

        const FleetListComponent = (
            <FleetList
                hiddenFilter={props.hiddenFilter}
                mainListSubheader={props.mainListSubheader}
                trucks={trucks}
                additionalTrucks={props.additionalTrucks}
                jobFormId={jobFormId}
                jobsListFormId={jobsListFormId}
                form="myFleetFiltersForm"
                appliedFilters={appliedFilters}
                allTrucksCount={allTrucksCount}
                placeholder={MULTIPLE_COMMA_SEPARATED_SEARCH_PLACEHOLDER}
                isLoading={isLocalLoading}
                updateListByFilters={(filters, clear) => memoizedUpdateListByFilters(filters, clear, false)}
                isHaulers={false}
                showMoreTrucks={memoizedShowMoreTrucks}
            />
        );

        const HaulersComponent = (
            <FleetList
                trucks={haulers}
                haulersLoaded={haulersLoaded}
                jobsListFormId={jobsListFormId}
                jobFormId={jobFormId}
                isHaulers={true}
                appliedFilters={appliedHaulersFilters}
                form="myHaulersFiltersForm"
                updateListByFilters={(filters, clear) => memoizedUpdateListByFilters(filters, clear, true)}
                allTrucksCount={allHaulersCount}
                isLoading={isLocalLoading}
                jobOrderId={jobOrderId}
                showMoreTrucks={memoizedShowMoreHaulers}
            />
        );

        const renderWarning = (text) => (
            <>
                <h1 className="--text-center">Select start {enabledTemplate ? "time" : "date"}</h1>
                <h1 className="--text-center">to see {text}</h1>
            </>
        );

        const isNotRequested = () => {
            if (joStartTime && enabledTemplate && !isAppearNewJobOrderForm) {
                return true;
            }
            if (joStartTime && !enabledTemplate) {
                return false;
            }
            if (joStartTime && enabledTemplate) {
                return false;
            }

            if (isAppearNewJobOrderForm && poLineItem && !joStartDate) {
                if (!isStartDateByDefault) return false;
            }
            if (enabledTemplate && (!templateStartDate || !templateEndDate)) {
                return false;
            }

            if (!enabledTemplate && prevEnabledTemplate && (!templateStartDate || !templateEndDate)) {
                return false;
            }

            return true;
        };

        const renderTab = (component, text) => {
            if (joStartTime && !enabledTemplate) return renderWarning(text);
            if (isAppearNewJobOrderForm && poLineItem && !joStartDate) {
                if (!isStartDateByDefault) return renderWarning(text);
            }
            if (!joStartDate && isSplit) return renderWarning(text);
            if (isNotEnteredTemplateDateRange)
                return (
                    <>
                        <h1 className="--text-center">Select date range</h1>
                        <h1 className="--text-center">to see trucks</h1>
                    </>
                );

            return component;
        };

        tabsHeaders.push({ label: "My Fleet" });
        tabsPanels.push({ content: renderTab(FleetListComponent, "trucks") });
        if (showHaulers) {
            tabsHeaders.push({ label: "Haulers" });
            tabsPanels.push({ content: HaulersComponent });
        }

        const handleMyFleetTabsChange = (activeTab) => {
            setActiveTab(activeTab);
            dispatch(resetJobOrderParams());
            dispatch(resetHaulerParam());
        };

        return (
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                <TabsCustom tabsHeaders={tabsHeaders} tabsPanels={tabsPanels} onChangeTab={handleMyFleetTabsChange} />
                {isLoading && <Loader />}
            </div>
        );
    },
    (prev, newProps) => {
        return (
            _.isEqual(prev.getFleetForJoById, newProps.getFleetForJoById) &&
            _.isEqual(prev.joDate, newProps.joDate) &&
            _.isEqual(prev.joStartDate, newProps.joStartDate) &&
            _.isEqual(prev.joStartTime, newProps.joStartTime) &&
            _.isEqual(prev.jobFormId, newProps.jobFormId) &&
            _.isEqual(prev.poLineItem, newProps.poLineItem) &&
            _.isEqual(prev.jobsListFormId, newProps.jobsListFormId) &&
            _.isEqual(prev.requestFleet, newProps.requestFleet) &&
            _.isEqual(prev.isAppearNewJobOrderForm, newProps.isAppearNewJobOrderForm) &&
            _.isEqual(prev.isStartDateByDefault, newProps.isStartDateByDefault) &&
            _.isEqual(prev.isStartCopyJobNextDay, newProps.isStartCopyJobNextDay) &&
            _.isEqual(prev.dispatchedTrucksOrder, newProps.dispatchedTrucksOrder) &&
            _.isEqual(prev.activeHaulerParam, newProps.activeHaulerParam) &&
            _.isEqual(prev.enabledTemplate, newProps.enabledTemplate) &&
            _.isEqual(prev.myFleetNeedsToBeUpdated, newProps.myFleetNeedsToBeUpdated) &&
            _.isEqual(prev.locationId, newProps.locationId) &&
            _.isEqual(prev.radiusInputValue, newProps.radiusInputValue) &&
            _.isEqual(prev.templateEndDate, newProps.templateEndDate) &&
            _.isEqual(prev.templateStartDate, newProps.templateStartDate) &&
            _.isEqual(prev.updatedStartDate, newProps.updatedStartDate) &&
            _.isEqual(prev.updatedPickupSite, newProps.updatedPickupSite) &&
            _.isEqual(prev.weekdays, newProps.weekdays) &&
            _.isEqual(prev.dispatchPageDate, newProps.dispatchPageDate) &&
            _.isEqual(prev.truckSolutionIsSelected, newProps.truckSolutionIsSelected) &&
            _.isEqual(prev.additionalParams, newProps.additionalParams) &&
            _.isEqual(prev.showHaulers, newProps.showHaulers) &&
            _.isEqual(prev.jobsListFormId, newProps.jobsListFormId) &&
            _.isEqual(prev.additionalTrucks, newProps.additionalTrucks) &&
            _.isEqual(prev.mainListSubheader, newProps.mainListSubheader) &&
            _.isEqual(prev.additionalParams, newProps.additionalParams)
        );
    },
);

Fleet.propTypes = {
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    jobFormId: PropTypes.string.isRequired,
    jobsListFormId: PropTypes.string.isRequired,
    showHaulers: PropTypes.bool.isRequired,
    getFleetForJoById: PropTypes.number,
    poLineItem: PropTypes.object,
    joStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
    joDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    joListStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    requestFleetDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    newJobOrderPageLeave: PropTypes.bool,
    dispatchedTrucksOrder: PropTypes.string,
    skipTrucksInitialListingLoading: PropTypes.bool,
    isAppearNewJobOrderForm: PropTypes.bool,
    isStartDateByDefault: PropTypes.bool,
    isStartCopyJobNextDay: PropTypes.bool,
    joStartTime: PropTypes.string,
    enabledTemplate: PropTypes.bool,
    activeSmartDispatchTab: PropTypes.number,
    locationId: PropTypes.number,
};
Fleet.defaultProps = {
    showHaulers: true,
};

export default withRouter(
    connect((state, props) => {
        const joFormSelector = formValueSelector(props.jobFormId);
        const joListFormSelector = formValueSelector(props.jobsListFormId);
        const copyJobSelector = formValueSelector("copyJobOrder");
        const radiusInputSelector = formValueSelector(SET_RADIUS_TO_SEARCH_TRUCKS);

        return {
            account: state.account,
            getFleetForJoById: state.jobOrders.getFleetForJoById,
            joDate: state.jobOrders.joDate,
            requestFleetDate: state.jobOrders.requestFleetDate,
            newJobOrderPageLeave: state.jobOrders.newJobOrderPageLeave,
            skipTrucksInitialListingLoading: state.jobOrders.skipTrucksInitialListingLoading,
            activeSmartDispatchTab: state.smartDispatch.activeSmartDispatchTab,
            myFleetNeedsToBeUpdated: state.smartDispatch.myFleetNeedsToBeUpdated,
            currentBundle: state.smartDispatch.currentBundle,
            joStartDate: joFormSelector(state, "startDate"),
            enabledTemplate: joFormSelector(state, "enabled"),
            poLineItem: joFormSelector(state, "poLineItem"),
            templateStartDate: joFormSelector(state, "templateStartDate"),
            templateEndDate: joFormSelector(state, "templateEndDate"),
            weekdays: joFormSelector(state, "weekdays"),
            isAppearNewJobOrderForm: joFormSelector(state, "isAppearNewJobOrderForm"),
            updatedStartDate: joFormSelector(state, "updatedStartDate"),
            updatedPickupSite: joFormSelector(state, "updatedPickupSite"),
            requestFleet: joFormSelector(state, "requestFleet") || copyJobSelector(state, "requestFleet"),
            joliItems: joFormSelector(state, JOLI_FIELD_ID),
            isSplit: joFormSelector(state, "isSplit"),
            joListStartDate: joListFormSelector(state, "startDate"),
            dispatchedTrucksOrder: state.jobOrders.activeTruckDispatchedParam,
            activeHaulerParam: state.jobOrders.activeHaulerParam,
            isStartDateByDefault: selectIfIsStartDateByDefault(state),
            isStartCopyJobNextDay: selectIfIsStartCopyJobNextDay(state),
            joStartTime: copyJobSelector(state, "joStartTime"),
            locationId: joFormSelector(state, "pickUpSite.value"),
            radiusInputValue: radiusInputSelector(state, RADIUS_FIELD_NAME),
            dispatchPageDate: formValueSelector("dispatchPageJobOrdersFilters")(state, "startDate"),
            truckSolutionIsSelected: _.isInteger(selectSelectedTruckSolutionList(state)[0]),
        };
    })(Fleet),
);
