import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { CARD_STYLES } from "../../../styles/reusableStyles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { IS_INSPECTOR_USER, UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR } from "../../../constants/maps";
import { TicketStatusPillWrapper } from "../../core/pills";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../constants/global";
import _ from "lodash";
import UnitOfMeasure from "../../core/unitOfMeasure";
import { SimpleLink } from "../../core/link";
import { getSiteEditRoute } from "../../../helpers/global";
import RoomIcon from "@material-ui/icons/Room";
import { ICONS_SITE, THEME_ICONS } from "../../../styles/icons";
import TicketAction from "./ticketAction";
import TicketMapAndTickPic from "./ticketMapAndTickPic";
import { DividerThin } from "../../core/divider";
import PoliAdditionalFields from "../../global/poliAdditionalFields";
import { TicketTrailers } from "./ticketTrailers";
import TicketNumber from "./ticketNumber";
import LinkedTicketIdLink from "./LinkedTicketIdLink";
import { selectRestrictedCustomerShowPrice } from "../../../selectors";
import { getIfIsDropOffMidpoint, getIfIsPickUpMidpoint } from "../../../helpers/tickets";

const useStyles = makeStyles((theme) => ({
    ticketCard: {
        cursor: "text",

        "&.air-ticket": {
            backgroundColor: theme.palette.primary.superLight,
        },
        "& .card-content": {
            padding: "0px 10px 10px",
            height: "100%",
        },
        "& .more-actions": {
            textAlign: "center",
        },
    },
    uiIcon: {
        marginRight: 4,
        height: 15,
        width: 15,
        top: 2,

        "&.pick-up": {
            marginRight: 0,
            left: -3,
        },
    },
    uiIconTrailer: {
        ...THEME_ICONS.trailerFilledLarge,
    },
    trailersList: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: 200,
    },
    uiIconGeneralTruck: {
        marginRight: 4,
        ...THEME_ICONS.generalTruck,
        width: 20,
        height: 20,
    },
    statusPill: {
        marginBottom: 7,
    },
    textOverflow: {
        maxWidth: 70,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    trucksName: {
        textOverflow: "ellipsis",
        maxWidth: 48,
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    signedTicketStyles: {
        "& .label": {
            position: "absolute",
            fontSize: 20,
            color: "rgb(0 0 0)",
            fontWeight: 600,
        },
        "& .sign-icon": {
            width: 27,
            height: 26,
            color: "#d8d8d8",
        },
    },
}));
export const getHoursQty = (quantity) =>
    `${Math.trunc(quantity)}h ${((quantity - Math.trunc(quantity)) * 60).toFixed(0)}m`;
export const PrimaryTicketQty = ({ primaryUom, unitOfMeasure, primaryQuantity }) => {
    return (
        <React.Fragment>
            {primaryUom === UNIT_OF_MEASURE_HOUR ? (
                getHoursQty(primaryQuantity)
            ) : (
                <React.Fragment>
                    <>{primaryQuantity}&nbsp;</>
                    <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[primaryUom] || unitOfMeasure?.name} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

PrimaryTicketQty.propTypes = {
    primaryUom: PropTypes.number.isRequired,
    primaryQuantity: PropTypes.number.isRequired,
};

const TicketCard = (props) => {
    const cardStyles = CARD_STYLES();
    const classes = useStyles();
    const siteIconsStyles = ICONS_SITE();
    const {
        ticket,
        handleClickTicketForRouteInfo,
        handleHoverTicpicIcon,
        setTicketIdForEdit,
        account,
        reopenTicket,
        setTicketForReconcile,
        setTicketIdForDelete,
        setTicketIdForClose,
        setTicketForUpdate,
        setTicketForSplit,
        openTicketActionsModal,
        displayUserDate,
        actionIconsClasses,
        form,
        setLoading,
        onlyView,
        restrictedCustomerShowPrice,
    } = props;
    const {
        id,
        phaseCode,
        canEditNotes,
        grantedAccess,
        company,
        status,
        canClose,
        canBeReconciledByUser,
        externalRef,
        haulerCompany,
        driver,
        ticket: ticketNumber,
        dropOffSite,
        jobNumber,
        payloadName,
        timeLoaded,
        isAirTicketLite,
        pickUpSite,
        hauler,
        joLineItem,
        trailers,
        truck,
        truckName,
        isSubticket,
        perUnitPrice,
        secondaryQuantity,
        primaryQuantity,
        primaryUom,
        unitOfMeasure,
        secondaryUom,
        createdDate,
        closedDate,
        totalPrice,
        payload,
        grossTons,
        netTons,
        tareTons,
        weighmaster,
        additionalFields,
        additionalExternalRef,
        previousTicketId,
        nextTicketId,
        isDuplicate,
    } = ticket;
    const phaseCodes =
        phaseCode && !_.isEmpty(phaseCode.phaseCodes) ? phaseCode.phaseCodes.map((phaseCode) => phaseCode.code) : [];
    const bidItems = phaseCode && phaseCode.bidItems ? Object.keys(phaseCode.bidItems) : [];
    const linkedTicketId = nextTicketId || previousTicketId;

    const isDropOffMidpoint = getIfIsDropOffMidpoint(ticket);
    const isPickUpMidpoint = getIfIsPickUpMidpoint(ticket);

    return (
        <div
            className={clsx(cardStyles.cardStyles, classes.ticketCard, isAirTicketLite && "air-ticket", "ticket-card")}
            title={isAirTicketLite ? "Air Ticket Lite" : ""}
        >
            <Grid container direction={"column"} justify={"space-between"} className="card-content">
                <Grid item container justify={"space-between"}>
                    <Grid item xs={11} style={{ paddingTop: 11, paddingRight: 5 }}>
                        <Grid container justify={"space-between"}>
                            <TicketNumber
                                externalRef={externalRef}
                                ticketNumber={ticketNumber}
                                additionalExternalRef={additionalExternalRef}
                                isDuplicate={isDuplicate}
                                form={form}
                                createdDate={createdDate}
                            />
                            {(!_.isEmpty(bidItems) || !_.isEmpty(phaseCodes)) && (
                                <Grid item>
                                    {!_.isEmpty(phaseCodes) ? (
                                        <>
                                            Phase Code:&nbsp;{phaseCodes.join(", ")}
                                            {` `}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {!_.isEmpty(bidItems) && <>Bid Items:&nbsp;{bidItems.join(", ")}</>}
                                </Grid>
                            )}
                        </Grid>
                        {linkedTicketId && (
                            <LinkedTicketIdLink linkedTicketId={linkedTicketId} form={form} setLoading={setLoading} />
                        )}
                        <Grid container alignItems={"flex-start"} className="--text-18">
                            {(primaryQuantity || primaryQuantity === 0) && primaryUom ? (
                                <React.Fragment>
                                    <PrimaryTicketQty
                                        primaryUom={primaryUom}
                                        unitOfMeasure={unitOfMeasure}
                                        primaryQuantity={primaryQuantity}
                                    />
                                    {!!secondaryQuantity && (
                                        <React.Fragment>
                                            &nbsp;(
                                            <>{secondaryQuantity}&nbsp;</>
                                            <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[secondaryUom]} />)
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                isAirTicketLite && netTons && <React.Fragment>{netTons} TONS</React.Fragment>
                            )}
                            {(!!totalPrice || totalPrice === 0) &&
                                !isSubticket &&
                                !grantedAccess &&
                                restrictedCustomerShowPrice && (
                                    <React.Fragment>
                                        {(netTons || ((primaryQuantity || primaryQuantity === 0) && primaryUom)) && (
                                            <React.Fragment>&nbsp;-&nbsp;</React.Fragment>
                                        )}
                                        <span className="--green-text">${totalPrice.toFixed(2)}</span>
                                    </React.Fragment>
                                )}
                            {payloadName || payload ? (
                                <>
                                    {!!(
                                        ((primaryQuantity || primaryQuantity === 0) && primaryUom) ||
                                        (isAirTicketLite && netTons) ||
                                        (!!totalPrice && !isSubticket && !grantedAccess)
                                    ) && <>&nbsp;|&nbsp;</>}
                                    {payloadName || payload}
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid container alignItems={"center"}>
                            {!isSubticket && !grantedAccess && perUnitPrice && restrictedCustomerShowPrice && (
                                <Grid item xs={8} container alignItems={"center"}>
                                    <span className="--green-text">${perUnitPrice.toFixed(2)}</span>
                                    &nbsp;/&nbsp;
                                    <UnitOfMeasure
                                        value={UNIT_OF_MEASURE_BY_ID[joLineItem.unitOfMeasure] || unitOfMeasure?.name}
                                        isPlural={false}
                                    />
                                </Grid>
                            )}
                            {!IS_INSPECTOR_USER(account.role) && !_.isEmpty(additionalFields) && (
                                <Grid
                                    item
                                    xs={!isSubticket && !grantedAccess && perUnitPrice ? 4 : 12}
                                    className={clsx(!isSubticket && !grantedAccess && perUnitPrice && "--text-right")}
                                >
                                    <PoliAdditionalFields additionalFields={additionalFields} />
                                </Grid>
                            )}
                            {(grossTons || tareTons || weighmaster) && (
                                <Grid
                                    item
                                    xs={!isSubticket && !grantedAccess && perUnitPrice ? 4 : 12}
                                    className={clsx(!isSubticket && !grantedAccess && perUnitPrice && "--text-right")}
                                >
                                    {weighmaster ? <>Weighmaster:&nbsp;{weighmaster}; </> : ""}
                                    {grossTons ? <>Gross Tons:&nbsp;{grossTons}; </> : ""}
                                    {tareTons ? <>Tare Tons:&nbsp;{tareTons}; </> : ""}
                                </Grid>
                            )}
                        </Grid>
                        <Grid container alignItems={"center"}>
                            {joLineItem ? (
                                <div style={{ wordBreak: "break-all" }}>
                                    {!_.isEmpty(joLineItem.project) &&
                                        joLineItem.project.customer &&
                                        joLineItem.project.customer.name}
                                    <br />
                                    {!_.isEmpty(joLineItem.project) &&
                                        joLineItem.project.externalJobNumber &&
                                        `${joLineItem.project.externalJobNumber} -`}
                                    {!_.isEmpty(joLineItem.project) && joLineItem.project.name}
                                    <br />
                                    {joLineItem.jobOrderId ? `Job #${joLineItem.jobOrderId}` : ""}
                                </div>
                            ) : (
                                <React.Fragment>Job #{jobNumber || ""}</React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <Grid
                            container
                            direction={"column"}
                            justify={"center"}
                            alignItems={"center"}
                            style={{ paddingTop: 11 }}
                        >
                            {!onlyView && (
                                <TicketAction
                                    grantedAccess={grantedAccess}
                                    canClose={canClose}
                                    canBeReconciledByUser={canBeReconciledByUser}
                                    setTicketIdForEdit={setTicketIdForEdit}
                                    isAirTicketLite={isAirTicketLite}
                                    setTicketIdForDelete={setTicketIdForDelete}
                                    setTicketIdForClose={setTicketIdForClose}
                                    status={status}
                                    id={id}
                                    canEditNotes={canEditNotes}
                                    setTicketForReconcile={setTicketForReconcile}
                                    setTicketForUpdate={setTicketForUpdate}
                                    account={account}
                                    ticket={ticket}
                                    reopenTicket={reopenTicket}
                                    openTicketActionsModal={openTicketActionsModal}
                                    setTicketForSplit={setTicketForSplit}
                                />
                            )}
                            <TicketStatusPillWrapper status={status} className={classes.statusPill} ticket={ticket} />
                            <TicketMapAndTickPic
                                ticket={ticket}
                                onlyView={onlyView}
                                account={account}
                                actionIconsClasses={actionIconsClasses}
                                isAirTicketLite={isAirTicketLite}
                                handleClickTicketForRouteInfo={handleClickTicketForRouteInfo}
                                handleHoverTicpicIcon={handleHoverTicpicIcon}
                                signedTicketStyles={classes.signedTicketStyles}
                                isPickUpMidpoint={isPickUpMidpoint}
                                isDropOffMidpoint={isDropOffMidpoint}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction={"column"} justify={"space-between"} alignItems={"flex-end"}>
                    <Grid container alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={!_.isEmpty(haulerCompany) || hauler || !_.isEmpty(truck) || truckName ? 6 : 12}>
                            {company && company.name}
                        </Grid>
                        {(!_.isEmpty(haulerCompany) || hauler || !_.isEmpty(truck) || truckName) && (
                            <Grid item xs={company ? 6 : 12} className="--text-right">
                                <Grid container alignItems={"center"} justify={"flex-end"}>
                                    {(!_.isEmpty(haulerCompany) || hauler) && (
                                        <Grid item>
                                            <Grid container alignItems={"center"}>
                                                <PersonOutlineIcon style={{ width: 20, height: 20 }} />
                                                <div
                                                    className={classes.textOverflow}
                                                    title={!_.isEmpty(haulerCompany) ? haulerCompany.name : hauler}
                                                >
                                                    {!_.isEmpty(haulerCompany) ? haulerCompany.name : hauler}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {(!_.isEmpty(truck) || truckName) && (
                                        <Grid item>
                                            <Grid container alignItems={"center"}>
                                                {!_.isEmpty(haulerCompany) || hauler ? <>&nbsp;/&nbsp;</> : ""}
                                                <div className={classes.uiIconGeneralTruck} />
                                                <span
                                                    className={clsx("--uppercase", classes.trucksName)}
                                                    title={!_.isEmpty(truck) ? truck.name : truckName}
                                                >
                                                    {!_.isEmpty(truck) ? truck.name : truckName}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                {!!driver && `(${driver})`}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container direction={"row"} justify={"flex-end"} alignItems={"center"}>
                        {trailers && trailers.length && (
                            <TicketTrailers trailers={trailers} additionalFields={additionalFields} />
                        )}
                    </Grid>
                    <Grid container alignItems={"center"}>
                        {!!pickUpSite && (
                            <Grid item xs={dropOffSite ? 6 : 12}>
                                <SimpleLink
                                    to={getSiteEditRoute(pickUpSite.id)}
                                    className="--uppercase --text-12 --text-right"
                                >
                                    <RoomIcon
                                        className={clsx(
                                            siteIconsStyles.iconSite,
                                            classes.uiIcon,
                                            isPickUpMidpoint ? "mid" : "pick-up",
                                        )}
                                    />
                                    {pickUpSite.name}
                                </SimpleLink>
                            </Grid>
                        )}
                        {!!dropOffSite && (
                            <Grid item xs={pickUpSite ? 6 : 12} className={clsx(pickUpSite && "--text-right")}>
                                <SimpleLink
                                    to={getSiteEditRoute(dropOffSite.id)}
                                    className="--uppercase --text-12 --text-right"
                                >
                                    <RoomIcon
                                        className={clsx(
                                            siteIconsStyles.iconSite,
                                            classes.uiIcon,
                                            isDropOffMidpoint ? "mid" : "drop-off",
                                        )}
                                    />
                                    {dropOffSite.name}
                                </SimpleLink>
                            </Grid>
                        )}
                    </Grid>
                    <DividerThin marginTop={"3px"} marginBottom={0} />
                    <Grid container alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={6}>
                            {createdDate
                                ? displayUserDate(createdDate)
                                : timeLoaded
                                ? moment.utc(timeLoaded).format(DEFAULT_DATE_TIME_FORMAT)
                                : "N/A"}
                        </Grid>
                        <Grid item xs={6} className="--text-right">
                            {closedDate && displayUserDate(closedDate)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

TicketCard.propTypes = {
    ticket: PropTypes.object.isRequired,
    handleClickTicketForRouteInfo: PropTypes.func,
    handleHoverTicpicIcon: PropTypes.func,
    setTicketIdForEdit: PropTypes.func,
    reopenTicket: PropTypes.func,
    setTicketForReconcile: PropTypes.func,
    setTicketIdForDelete: PropTypes.func,
    setTicketIdForClose: PropTypes.func,
    setTicketForSplit: PropTypes.func,
    setTicketForUpdate: PropTypes.func,
    openTicketActionsModal: PropTypes.func,
    displayUserDate: PropTypes.func,
    account: PropTypes.object,
    actionIconsClasses: PropTypes.string,
    form: PropTypes.string,
    setLoading: PropTypes.func,
    onlyView: PropTypes.bool,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        restrictedCustomerShowPrice: selectRestrictedCustomerShowPrice(state),
    }))(TicketCard),
);
