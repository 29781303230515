import React, { useEffect, useState } from "react";
import _ from "lodash";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/styles";

import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import { fetchRestrictedCustomers } from "../../dataServers/companies";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import Loader from "../core/loader";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Cached } from "@material-ui/icons";
import ConfirmationModal from "../core/confirmationModal";
import { selectCompanyId } from "../../selectors";
import { NO_DEFAULT_USER_MESSAGE } from "../../constants/strings";
import { getOtherCompanyUserData } from "../../actions/companies";
import { changeCurrentUser } from "../../actions/globalActions";
import { PREVIOUS_ACCOUNT_DATA_STORAGE_KEY } from "../../helpers/api";
import UpdateRestrictedCustomerShowPriceFrom, {
    getShowPriceFieldName,
    UPDATE_RESTRICTED_CUSTOMER_SHOW_PRICING_FROM,
} from "./haulers/updateRestrictedCustomerShowPriceFrom";

const useStyles = makeStyles(() => ({
    customersTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
}));
const RestrictedCustomersSettings = (props) => {
    const dispatch = useDispatch();
    const { account, companyId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [customersData, setCustomersData] = useState([]);
    const [customersCount, setCustomersCount] = useState(null);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState(null);
    const [confirmLoginAsCustomer, setConfirmLoginAsCustomer] = useState({});
    const classes = useStyles();

    useEffect(() => {
        loadCustomers();
    }, []);

    const loadCustomers = (concat = false) => {
        setIsLoading(true);
        const currentPage = Math.ceil(customersData.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(customersData) ? +currentPage + 1 : 1;
        let getCustomersByCompanyParams = {
            page: concat ? nextPage : 1,
        };
        fetchRestrictedCustomers(companyId, getCustomersByCompanyParams)
            .then(({ data, meta }) => {
                setCustomersCount(meta.count);
                setCustomersData(concat ? [...customersData, ...data] : data);
            })
            .catch((error) => {
                setError([PROCESS_SERVER_ERROR(error)]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleLoginAsCustomer = async (customer) => {
        const defaultManager = customer?.defaultManager;

        if (!defaultManager) {
            setConfirmLoginAsCustomer({});

            return setError([NO_DEFAULT_USER_MESSAGE]);
        }
        try {
            const newAccountData = await dispatch(getOtherCompanyUserData(defaultManager.id));
            dispatch(changeCurrentUser(newAccountData));
            localStorage.setItem(PREVIOUS_ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(account));
        } catch (error) {
            setError([PROCESS_SERVER_ERROR(error)]);
        }
    };

    const getTableData = () => {
        const rows = customersData.map((customer, index) => {
            const { name, id, profile } = customer;

            return {
                index,
                name,
                showPricing: (
                    <UpdateRestrictedCustomerShowPriceFrom
                        form={`${UPDATE_RESTRICTED_CUSTOMER_SHOW_PRICING_FROM}-${id}`}
                        customerId={id}
                        initialValues={{
                            [getShowPriceFieldName(id)]: profile.restrictedCustomerShowPrice,
                        }}
                    />
                ),
                action: (
                    <Grid container justify={"center"} style={{ minHeight: 50 }}>
                        <React.Fragment>
                            <IconButton
                                id="loginAs"
                                title="Login As"
                                onClick={() => setConfirmLoginAsCustomer(customer)}
                            >
                                <Cached id="loginAs" />
                            </IconButton>
                        </React.Fragment>
                    </Grid>
                ),
            };
        });
        let columns = [
            {
                id: "name",
                label: "Name",
            },
            {
                id: "showPricing",
                label: "Show Price",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        return {
            rows: rows,
            columns: columns,
        };
    };

    return (
        <Grid container spacing={1} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <Loader />
            {!_.isEmpty(customersData) ? (
                <Grid item>
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={loadCustomers}
                        itemsCount={customersCount}
                        className={clsx(classes.customersTable)}
                        columns={getTableData().columns}
                    />
                </Grid>
            ) : isLoading ? null : (
                <Grid container justify="center">
                    <h1 className="--text-center">No customers match your search</h1>
                </Grid>
            )}
            <ConfirmationModal
                isOpen={!_.isEmpty(confirmLoginAsCustomer)}
                question={"Are you sure you want to login as restricted customer?"}
                yesHandler={() => handleLoginAsCustomer(confirmLoginAsCustomer)}
                noHandler={() => setConfirmLoginAsCustomer({})}
            />
            {!_.isEmpty(success) && (
                <SuccessNotification message={success} config={{ onClose: () => setSuccess([]) }} />
            )}
            {!_.isEmpty(error) && <ErrorNotification error={error} config={{ onClose: () => setError([]) }} />}
        </Grid>
    );
};

export default withRouter(
    compose(
        connect((state) => {
            return {
                account: state.account,
                companyId: selectCompanyId(state),
            };
        }),
    )(RestrictedCustomersSettings),
);
