import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector, isDirty } from "redux-form";
import { withRouter } from "react-router-dom";

import { getJobBoardJobOrders, getJobOrders } from "../../dataServers/jobOrder";
import ErrorNotification from "../core/notification";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import {
    changeInitialJobOrdersListLoadingSkip,
    changeJoDate,
    refreshJobsList,
    resetActiveJobOrders,
    saveCurrentJobOrderIdInRedux,
    saveJobOrdersCountInRedux,
    saveJobOrdersInRedux,
    updateActiveJobOrder,
    updateRequestedJobOrderId,
} from "../../actions/jobOrders";
import { dispatchRoute, jobBoardRoute, ROUTE_JOBS, ROUTE_PROJECTS } from "../../routes/globalRoutes";
import { openJobOrderById } from "../../helpers/jobOrders";

import clsx from "clsx";
import { FILTERS_STATUS_ID, GET_USER_INITIAL_FILTERS } from "../global/searchAndFilters";
import JobsList from "./jobsList";
import JobOrderFormWrapper from "./jobOrder/jobOrderFormWrapper";
import { ErrorBoundary } from "../core/errorBoudaries";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import { SimpleLink } from "../core/link";
import { EDIT_JOB_BOARD_FORM } from "./jobBoard/jobBoardForm";
import moment from "moment";
import Axios from "axios";
import JobOrderFiltersForm, { IS_ONLY_START_DATA } from "./jobOrder/jobOrderFiltersForm";
import { COPY_JOB_ORDER_FORM_ID } from "../../containers/DispatchJobOrders";
import { setCurrentProject, setProjectsDateFilter } from "../../actions/projects";
import {
    getIfIsRegionRequired,
    selectCompanyRegions,
    selectIfIsStartCopyJobNextDay,
    selectIfIsStartDateByDefault,
} from "../../selectors/index";
import {
    JO_STATUS_ACTIVE_ID,
    JO_STATUS_NOT_STARTED_ID,
    JO_STATUS_PENDING_ID,
    JO_TEMPLATED_ID,
    UNIT_OF_MEASURE_HOUR,
    UNIT_OF_MEASURE_LOAD,
} from "../../constants/maps";
import { flushSync } from "react-dom";
import { BUNDLE_DRAFT_MODE_FORM } from "../bundles/BundleDraftModeForm";
import { selectRedirectJobId } from "../../selectors/jobOrders";
import RedirectJobModal from "./RedirectJobModal";

const getDataLoadedFor = (url) => {
    if (url.includes(ROUTE_JOBS.JOB_BOARD)) {
        return ROUTE_JOBS.JOB_BOARD;
    }

    if (url.includes(ROUTE_JOBS.MY_JOBS)) {
        return ROUTE_JOBS.MY_JOBS;
    }

    if (url.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS)) {
        return ROUTE_JOBS.DISPATCH_JOB_ORDERS;
    }

    if (url.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM)) {
        return ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM;
    }

    if (url.includes(ROUTE_JOBS.SMART_DISPATCH)) {
        return ROUTE_JOBS.SMART_DISPATCH;
    }

    return "";
};

let requestPromise;
let cancelPromise = null;

class JobOrdersWrapper extends Component {
    constructor(props) {
        super(props);
        const { history } = props;

        this.state = {
            isLoading: false,
            setActiveJO: props.setActiveJO,
            appliedFilters: null,
            errorMessage: null,
            jobOrdersCount: 0,
            dataLoadedFor: getDataLoadedFor(history.location.pathname),
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.updateFiltersHandler = this.updateFiltersHandler.bind(this);
        this.updateListByFilters = this.updateListByFilters.bind(this);
        this.loadJobOrders = this.loadJobOrders.bind(this);
        this.updateJoStatus = this.updateJoStatus.bind(this);
        this.updateJoCopySchedule = this.updateJoCopySchedule.bind(this);
        this.refreshJobsList = this.refreshJobsList.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { parentFilters, needUpdateJobOrders, projectSelectedManually, jobOrdersNeedsToBeUpdated } = this.props;
        const { dataLoadedFor } = this.state;
        const newRoute = nextProps.history.location.pathname;

        if (
            ((dataLoadedFor.includes(ROUTE_JOBS.JOB_BOARD) && newRoute.includes(ROUTE_JOBS.MY_JOBS)) ||
                (dataLoadedFor.includes(ROUTE_JOBS.MY_JOBS) && newRoute.includes(ROUTE_JOBS.JOB_BOARD))) &&
            newRoute !== dataLoadedFor
        ) {
            // setActiveJO: false required to prevent openJobOrderById on loadJobOrders if user leave the page
            this.setState({
                dataLoadedFor: getDataLoadedFor(newRoute),
                setActiveJO: false,
            });
        }

        if (
            (nextProps.needUpdateJobOrders && !needUpdateJobOrders) ||
            (nextProps.jobOrdersNeedsToBeUpdated && !jobOrdersNeedsToBeUpdated)
        ) {
            const filters = { project: projectSelectedManually ? nextProps.parentFilters.project : null };
            const jobOrderId = nextProps.match.params && nextProps.match.params.jobOrderId;

            this.loadJobOrders(false, filters, jobOrderId);
        }

        if (nextProps.parentFilters.project !== parentFilters.project) {
            const filters = { project: nextProps.parentFilters.project || null };
            const jobOrderId = nextProps.match.params && nextProps.match.params.jobOrderId;

            this.loadJobOrders(false, filters, jobOrderId);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            formValues: { [START_DATE_NAME]: startDate },
            updateList,
            refreshing,
            setRefreshing,
        } = this.props;
        const { appliedFilters } = this.state;
        const {
            formValues: { [START_DATE_NAME]: prevStartDate },
        } = prevProps;
        if (startDate !== prevStartDate && updateList) {
            this.updateListByFilters(appliedFilters);
        }
        if (refreshing && refreshing !== prevProps.refreshing) {
            this.refreshJobsList();
            setRefreshing(false);
        }
    }

    componentWillUnmount() {
        const { setJobOrders, setProjectDate, projectsDateFilter } = this.props;

        setJobOrders([]);
        projectsDateFilter && setProjectDate(undefined);

        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }
    }

    setInitialRouteUrl = () => {
        this.props.setCurrentJobOrder(null);
        this.props.history.push(this.props.pageUrl);
    };

    loadJobOrders = (concat, filters = {}, jobOrderId) => {
        const {
            history,
            parentFilters,
            setJobOrders,
            setCurrentJobOrder,
            isDirtyJobBoardForm,
            openedJobOrder,
            jobOrders,
            form,
            skipJobOrdersInitialLoading,
            updateInitialJobOrdersListLoadingSkip,
            setJobOrdersListCountInRedux,
            isDraft,
        } = this.props;
        const { appliedFilters, isListView } = this.state;
        const currentPage = Math.ceil(jobOrders.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(jobOrders) ? +currentPage + 1 : 1;
        const pathname = history.location.pathname;
        const isJobBoardPage = pathname.includes(ROUTE_JOBS.JOB_BOARD);
        const isDispatchPage = pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        const isSmartDispatchPage = pathname.includes(ROUTE_JOBS.SMART_DISPATCH);
        const params = {
            page: concat ? nextPage : 1,
            ...parentFilters,
            ...appliedFilters,
            ...filters,
            withTrucks: true,
            order: this.props.activeJobOrderParam,
        };
        const request = !isJobBoardPage ? getJobOrders : getJobBoardJobOrders;

        if (
            _.isObject(params[FILTERS_STATUS_ID]) ||
            !params[START_DATE_NAME] ||
            (IS_ONLY_START_DATA(form) ? !params[START_DATE_NAME] : !params[END_DATE_NAME]) ||
            (isDispatchPage && jobOrderId && !isJobBoardPage)
        ) {
            return false;
        }

        if (skipJobOrdersInitialLoading) {
            updateInitialJobOrdersListLoadingSkip(false);

            return false;
        }

        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        this.setState({
            isLoading: true,
            dataLoadedFor: getDataLoadedFor(history.location.pathname),
        });

        if (isSmartDispatchPage) {
            params.smartDispatch = true;
            params.status = JO_STATUS_PENDING_ID;
            params.endDate = params.startDate;
            if (isDraft) {
                params.smartDispatch = false;
                params.isOvernight = false;
                params.isUnlimited = false;
                params.status = [JO_STATUS_PENDING_ID, JO_STATUS_ACTIVE_ID, JO_STATUS_NOT_STARTED_ID].join(",");
                params.excludeUnitsOfMeasure = [UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD].join(",");
            }
        }
        requestPromise = request({
            ...params,
            cancelToken,
        });

        requestPromise
            .then((jobOrdersData) => {
                const { setActiveJO } = this.state;
                let newJobOrders = concat ? [...jobOrders, ...jobOrdersData.data] : jobOrdersData.data;

                requestPromise = null;

                // https://github.com/TruckITllc/truckit-backend/issues/2175#issuecomment-608243355
                // newJobOrders = newJobOrders.sort((a, b) => a.id - b.id);
                setJobOrders(newJobOrders);
                this.setState({
                    isLoading: false,
                });

                setJobOrdersListCountInRedux(jobOrdersData.meta.count);

                let stillOnThePage = false;

                if (history.location.pathname.includes(jobBoardRoute)) {
                    stillOnThePage = history.location.pathname.includes(dispatchRoute)
                        ? isDirtyJobBoardForm
                            ? null
                            : ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM
                        : ROUTE_JOBS.JOB_BOARD;
                }

                if (history.location.pathname.includes("my-jobs")) {
                    stillOnThePage = ROUTE_JOBS.MY_JOBS;
                }

                if (
                    (setActiveJO || !newJobOrders.find((i) => i.id === openedJobOrder)) &&
                    !isListView &&
                    !_.isEmpty(newJobOrders) &&
                    stillOnThePage
                ) {
                    const {
                        match: {
                            params: { jobOrderId },
                        },
                    } = this.props;
                    const openJoById = +jobOrderId || newJobOrders[0].id;
                    const _goTo = stillOnThePage;

                    openJobOrderById(
                        {
                            history,
                            _goTo,
                        },
                        openJoById,
                        {
                            callBack: setCurrentJobOrder,
                            args: openJoById,
                        },
                    );
                }
            })
            .catch((error) => {
                if (_.isEmpty(error)) {
                    return false;
                }

                this.setState({
                    errorMessage: PROCESS_SERVER_ERROR(error),
                    isLoading: false,
                });
            });
    };

    updateListByFiltersChange = () => {
        const { match, setRequestedJobOrderId } = this.props;
        this.loadJobOrders(false, {}, match.params && match.params.jobOrderId);
        setRequestedJobOrderId(null);
    };

    handleSort = async (sortParam, hardParam) => {
        await this.props.updateActiveJobOrderParam(sortParam, hardParam);
        await this.loadJobOrders(false, {}, this.props.match.params && this.props.match.params.jobOrderId);
    };

    handleDateChange = (date, name) => {
        const { appliedFilters } = this.state;
        const {
            formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate },
            form,
            updateJoDate,
            setProjectDate,
            history,
        } = this.props;
        const newDates = {
            [END_DATE_NAME]: endDate || startDate,
            [START_DATE_NAME]: startDate,
        };

        if (moment(date).format() === "Invalid date") {
            return false;
        }

        this.updateFiltersHandler();

        if (name) {
            if (IS_ONLY_START_DATA(form)) {
                newDates[START_DATE_NAME] = date;
                newDates[END_DATE_NAME] = date;
            } else {
                // if date was changed in ordinary way we should use it
                newDates[name] = date;
            }
        }

        if (
            history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS) ||
            history.location.pathname.includes(ROUTE_JOBS.SMART_DISPATCH)
        ) {
            updateJoDate(newDates[START_DATE_NAME]);
            setProjectDate(newDates[START_DATE_NAME]);
        }

        this.setState(
            {
                appliedFilters: {
                    ...appliedFilters,
                    ...newDates,
                },
            },
            this.updateListByFiltersChange,
        );
    };

    updateListByFilters = (filters) => {
        const { appliedFilters } = this.state;
        const {
            formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate, search },
        } = this.props;
        let newFilters = _.cloneDeep(filters);
        newFilters = {
            ...newFilters,
            [START_DATE_NAME]: startDate,
            [END_DATE_NAME]: endDate || startDate,
            keywords: search,
        };

        if (!_.isEqual(appliedFilters, newFilters)) {
            flushSync(() => {
                this.setState(
                    {
                        appliedFilters: newFilters,
                    },
                    this.updateListByFiltersChange,
                );
            });
        }
    };

    updateJoStatus = (id, status) => {
        const { setJobOrders, jobOrders } = this.props;
        const newJobOrders = _.cloneDeep(jobOrders).map((i) => {
            if (+i.id === +id) {
                i.status = status;
            }

            return i;
        });

        setJobOrders(newJobOrders);
    };

    updateJoCopySchedule = (id, copySchedule) => {
        const { setJobOrders, jobOrders } = this.props;
        const newJobOrders =
            jobOrders &&
            jobOrders.map((i) => {
                if (+i.id === +id) {
                    i.copySchedule = copySchedule;
                }

                return i;
            });
        setJobOrders(newJobOrders);
    };

    refreshJobsList = (id) => {
        this.setInitialRouteUrl();
        this.loadJobOrders();
        this.props.setRequestedJobOrderId(null);
    };

    updateFiltersHandler = () => {
        const {
            setCurrentJobOrder,
            pageUrl,
            history,
            formValues: { search },
            openedJobOrder,
        } = this.props;
        if (search && +search === openedJobOrder) {
            return false;
        } else {
            setCurrentJobOrder(null);
            history.push(pageUrl);
        }
    };

    goToProject = () => {
        this.props.setCurrentProject(null);
        this.props.history.push(ROUTE_PROJECTS.PROJECTS + `/${this.props.currentProject.id}`, {
            project: { id: this.props.currentProject.id },
        });
    };

    render() {
        const {
            className: rootClassName,
            isListView,
            setActive,
            currentJobOrder,
            formValues,
            form,
            filtersOffset,
            editJobOrder,
            cancelFormEditHandler,
            jobFormId,
            currentProjectId,
            dateRangeSize,
            pageActionsClassName,
            history,
            noJobsFoundText,
            parentLoading,
            jobOrders,
            account,
            currentProjectiAmOwner,
            isStartDateByDefault,
            isStartCopyJobNextDay,
            jobOrdersCount,
            parentJobListLoading,
            currentProject,
            isRegionsRequired,
            disabledLeftArrow,
            minDate,
            handleOneFilterOptionListChange,
            redirectJobId,
        } = this.props;
        const { isLoading: localLoading, errorMessage, dataLoadedFor } = this.state;
        const isLoading = (localLoading && !_.isEmpty(jobOrders)) || parentJobListLoading;
        const { [START_DATE_NAME]: startDate } = formValues;
        const skeletonJobOrders = new Array(7).fill(0);
        const historyLocation = history.location;
        let defaultFilter;
        const isJobBoardPage = historyLocation.pathname.includes(ROUTE_JOBS.JOB_BOARD);
        const isDispatchPage = historyLocation.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        const isSmartDispatchPage = historyLocation.pathname.includes(ROUTE_JOBS.SMART_DISPATCH);
        const showFilters = !(isDispatchPage && currentJobOrder && currentJobOrder.id);

        const isEditJobOrderForm =
            editJobOrder && !_.isEmpty(currentJobOrder) && currentJobOrder && !currentJobOrder.isCopy;
        const isCopiedJobOrderForm = editJobOrder && currentJobOrder && currentJobOrder.isCopy;
        const isRenderJobsList =
            isRegionsRequired && currentProject ? currentProject.region || currentProject.regionId : true;

        const initialValues = GET_USER_INITIAL_FILTERS(form);
        if (historyLocation?.state?.initialFilters?.jobOrderId) {
            initialValues.search = historyLocation.state.initialFilters.jobOrderId;

            if (historyLocation?.state?.initialFilters?.isTemplatedJob) {
                defaultFilter = {
                    templated: {
                        [JO_TEMPLATED_ID]: true,
                    },
                };
            }
        }

        return (
            <div className={rootClassName}>
                {errorMessage && <ErrorNotification message={errorMessage} />}
                <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                    <ErrorBoundary>
                        {/*new fob order form*/}
                        {!!currentProjectId && currentJobOrder && _.isEmpty(currentJobOrder) && isRenderJobsList ? (
                            <JobOrderFormWrapper
                                form={jobFormId}
                                setActive={setActive}
                                parentLoading={parentLoading}
                                jobOrder={{
                                    projectId: currentProjectId,
                                    startDate: startDate,
                                    iAmOwner: currentProjectiAmOwner,
                                }}
                                cancelFormEditHandler={cancelFormEditHandler}
                                isStartDateByDefault={isStartDateByDefault}
                            />
                        ) : (
                            !localLoading &&
                            !isRenderJobsList &&
                            !isEditJobOrderForm &&
                            !isCopiedJobOrderForm && (
                                <div>
                                    <h1 className="--text-center">
                                        Project {currentProject.name} does not have a region but it is required
                                        according to your company's settings{" "}
                                        <SimpleLink onClick={this.goToProject} otherProps={{ style: { fontSize: 20 } }}>
                                            Select Region
                                        </SimpleLink>
                                    </h1>
                                </div>
                            )
                        )}
                        {/* edit JobOrder Form*/}
                        {editJobOrder && !_.isEmpty(currentJobOrder) && currentJobOrder && !currentJobOrder.isCopy && (
                            <JobOrderFormWrapper
                                form={jobFormId}
                                setActive={setActive}
                                parentLoading={parentLoading}
                                jobOrder={currentJobOrder}
                                cancelFormEditHandler={cancelFormEditHandler}
                            />
                        )}
                        {/* copied job order form */}
                        {editJobOrder && currentJobOrder && currentJobOrder.isCopy && (
                            <JobOrderFormWrapper
                                form={COPY_JOB_ORDER_FORM_ID}
                                setActive={setActive}
                                parentLoading={parentLoading}
                                jobOrder={currentJobOrder}
                                cancelFormEditHandler={cancelFormEditHandler}
                                isStartCopyJobNextDay={isStartCopyJobNextDay}
                            />
                        )}
                        <JobOrderFiltersForm
                            minDate={minDate}
                            disabledLeftArrow={disabledLeftArrow}
                            isListView={isListView}
                            form={form}
                            showFilters={showFilters}
                            initialValues={initialValues}
                            defaultFilter={!_.isEmpty(defaultFilter) ? defaultFilter : undefined}
                            isLoading={isLoading}
                            filtersOffset={filtersOffset}
                            handleDateChange={this.handleDateChange}
                            handleOneFilterOptionListChange={handleOneFilterOptionListChange}
                            updateListByFilters={this.updateListByFilters}
                            updateFiltersHandler={this.updateFiltersHandler}
                            resetActiveJobOrders={this.props.resetActiveJobOrders}
                            dateRangeSize={dateRangeSize}
                            dataLoadedFor={dataLoadedFor}
                            pageActionsClassName={pageActionsClassName}
                            isJobBoardPage={isJobBoardPage}
                            isSmartDispatchPage={isSmartDispatchPage}
                            autoFocusOnSearch={currentJobOrder && !currentJobOrder.isCopy}
                        />
                        {(_.isEmpty(currentJobOrder) || (currentJobOrder && !currentJobOrder.id) || isJobBoardPage) &&
                            isRenderJobsList &&
                            showFilters && (
                                <JobsList
                                    isLoading={localLoading}
                                    isListView={isListView}
                                    jobOrders={jobOrders}
                                    account={account}
                                    updateJoStatus={this.updateJoStatus}
                                    updateJoCopySchedule={this.updateJoCopySchedule}
                                    deleteJoById={this.refreshJobsList}
                                    jobOrdersCount={jobOrdersCount}
                                    requestFetch={this.loadJobOrders}
                                    setActive={setActive}
                                    skeletonJobOrders={skeletonJobOrders}
                                    handleSort={this.handleSort}
                                    initialHeader={this.props.initialHeader}
                                    resetActiveJobOrders={this.props.resetActiveJobOrders}
                                    activeJobOrderParam={this.props.activeJobOrderParam}
                                />
                            )}
                    </ErrorBoundary>
                    {_.isEmpty(jobOrders) &&
                    isRenderJobsList &&
                    !localLoading &&
                    showFilters &&
                    (_.isEmpty(currentJobOrder) || isJobBoardPage || isDispatchPage) ? (
                        <div>
                            <h1 className="--text-center">{noJobsFoundText}</h1>
                            <br />
                            <br />
                        </div>
                    ) : (
                        <div>
                            <br />
                        </div>
                    )}
                    <RedirectJobModal setActive={setActive} />
                    {isLoading && <Loader />}
                </div>
            </div>
        );
    }
}

JobOrdersWrapper.propTypes = {
    className: PropTypes.string,
    setProjectDate: PropTypes.func,
    updateJoDate: PropTypes.func.isRequired,
    projectsDateFilter: PropTypes.bool,
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    parentFilters: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    jobOrders: PropTypes.array.isRequired,
    setCurrentJobOrder: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    setJobOrdersListCountInRedux: PropTypes.func.isRequired,
    setJobOrders: PropTypes.func.isRequired,
    isListView: PropTypes.bool.isRequired,
    setActiveJO: PropTypes.bool.isRequired,
    editJobOrder: PropTypes.bool.isRequired,
    needUpdateJobOrders: PropTypes.bool.isRequired,
    jobOrdersNeedsToBeUpdated: PropTypes.bool,
    setActive: PropTypes.func,
    dateRangeSize: PropTypes.number.isRequired,
    currentProjectId: PropTypes.number,
    currentProject: PropTypes.object,
    currentProjectiAmOwner: PropTypes.bool,
    filtersOffset: PropTypes.number.isRequired,
    form: PropTypes.string.isRequired,
    currentJobOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    jobFormId: PropTypes.string,
    pageActionsStyles: PropTypes.object,
    pageUrl: PropTypes.string,
    noJobsFoundText: PropTypes.string.isRequired,
    cancelFormEditHandler: PropTypes.func,
    saveChangesHandler: PropTypes.func,
    setRequestedJobOrderId: PropTypes.func,
    updateInitialJobOrdersListLoadingSkip: PropTypes.func,
    parentLoading: PropTypes.bool.isRequired,
    parentJobListLoading: PropTypes.bool,
    isDirtyJobBoardForm: PropTypes.bool,
    skipJobOrdersInitialLoading: PropTypes.bool,
    projectSelectedManually: PropTypes.bool,
    openedJobOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageActionsClassName: PropTypes.string,
    isStartDateByDefault: PropTypes.bool,
    isStartCopyJobNextDay: PropTypes.bool,
    initialHeader: PropTypes.string,
    resetActiveJobOrders: PropTypes.func,
    activeJobOrderParam: PropTypes.string,
    updateActiveJobOrderParam: PropTypes.func,
    jobOrdersCount: PropTypes.number.isRequired,
    handleOneFilterOptionListChange: PropTypes.func,
    isDraft: PropTypes.bool,
    redirectJobId: PropTypes.number,
};

JobOrdersWrapper.defaultProps = {
    parentFilters: {},
    editJobOrder: false,
    parentLoading: false,
    parentJobListLoading: false,
    needUpdateJobOrders: false,
    dateRangeSize: 6,
    filtersOffset: 0,
    noJobsFoundText: `No jobs match your search / filter parameters.
    Try using options such as project name, material, job #, job status, and more.
    `,
};

export default withRouter(
    connect(
        (state, props) => {
            const formSelector = formValueSelector(props.form);
            const bundleDraftFormSelector = formValueSelector(BUNDLE_DRAFT_MODE_FORM);

            return {
                jobOrders: state.jobOrders.list,
                activeJobOrderParam: state.jobOrders.activeJobOrderParam,
                account: state.account,
                isDirtyJobBoardForm: isDirty(EDIT_JOB_BOARD_FORM)(state),
                jobOrdersNeedsToBeUpdated: state.jobOrders.jobOrdersNeedsToBeUpdated,
                openedJobOrder: state.jobOrders.currentJobOrder,
                jobOrdersCount: state.jobOrders.jobOrdersCount,
                skipJobOrdersInitialLoading: state.jobOrders.skipJobOrdersInitialLoading,
                projectSelectedManually: state.projects.projectSelectedManually,
                projectsDateFilter: state.projects.projectsDateFilter,
                refreshing: state.jobOrders.refreshing,
                formValues: formSelector(state, START_DATE_NAME, END_DATE_NAME, "filters", "search"),
                isDraft: bundleDraftFormSelector(state, "isDraft"),
                regions: selectCompanyRegions(state),
                isStartDateByDefault: selectIfIsStartDateByDefault(state),
                isStartCopyJobNextDay: selectIfIsStartCopyJobNextDay(state),
                isRegionsRequired: getIfIsRegionRequired(state),
                redirectJobId: selectRedirectJobId(state),
            };
        },
        (dispatch) => ({
            setJobOrders: (jobOrders) => {
                dispatch(saveJobOrdersInRedux(jobOrders));
            },
            setJobOrdersListCountInRedux: (count) => {
                dispatch(saveJobOrdersCountInRedux(count));
            },
            updateJoDate: (joDate) => {
                dispatch(changeJoDate(joDate));
            },
            setCurrentJobOrder: (jobOrderId) => {
                dispatch(saveCurrentJobOrderIdInRedux(jobOrderId));
            },
            setProjectDate: (date) => {
                dispatch(setProjectsDateFilter(date));
            },
            setCurrentProject: (project) => {
                dispatch(setCurrentProject(project));
            },
            updateActiveJobOrderParam: (payload, hardParam) => {
                dispatch(updateActiveJobOrder(payload, hardParam));
            },
            resetActiveJobOrders: (payload) => dispatch(resetActiveJobOrders(payload)),
            updateInitialJobOrdersListLoadingSkip: (payload) => {
                dispatch(changeInitialJobOrdersListLoadingSkip(payload));
            },
            setRequestedJobOrderId: (requestedId) => dispatch(updateRequestedJobOrderId(requestedId)),
            setRefreshing: (payload) => dispatch(refreshJobsList(payload)),
        }),
    )(JobOrdersWrapper),
);
