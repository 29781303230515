/* global google */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import CustomMarker from "./customMarker";
import _ from "lodash";
import pickUpPin from "../../../styles/images/soft-green-pin.svg";
import midPin from "../../../styles/images/pale-blue-pin.svg";
import { GOOGLE_MAPS_SITE_ICON_SIZE } from "../../../constants/global";
import { MAIN_THEME } from "../../../styles/globalThemeConfig";
import { SITE_CIRCLE_OPACITY, SITE_CIRCLE_WIDTH } from "../../../constants/sites";
import { Circle, Polygon } from "react-google-maps";

const PickUpMapData = (props) => {
    const { pickUpSite, notFadeMarkers, isPickUpMidpoint } = props;
    const pickUpdLocation = { lat: pickUpSite.latitude, lng: pickUpSite.longitude };
    const options = {
        strokeColor: isPickUpMidpoint ? MAIN_THEME.palette.general.paleBlue : MAIN_THEME.palette.general.softGreen,
        strokeWeight: SITE_CIRCLE_WIDTH,
        fillColor: isPickUpMidpoint ? MAIN_THEME.palette.general.paleBlue : MAIN_THEME.palette.general.softGreen,
        fillOpacity: SITE_CIRCLE_OPACITY,
    };

    return (
        <React.Fragment>
            {pickUpSite && (
                <CustomMarker
                    position={pickUpdLocation}
                    opacity={!_.isEmpty(notFadeMarkers) ? 0.1 : 1}
                    icon={{
                        url: isPickUpMidpoint ? midPin : pickUpPin,
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        zIndex: 1,
                    }}
                />
            )}
            {pickUpSite.geofenceData &&
                pickUpSite.geofenceData.map((zone, index) => {
                    const paths = zone.polygon && zone.polygon.map(([lat, lng]) => ({ lat, lng }));

                    return zone.radius ? (
                        <Circle defaultCenter={pickUpdLocation} radius={+zone.radius} key={index} options={options} />
                    ) : (
                        <Polygon path={paths} key={index} defaultCenter={pickUpdLocation} options={options} />
                    );
                })}
            {pickUpSite.radius && (
                <Circle defaultCenter={pickUpdLocation} radius={+pickUpSite.radius} options={options} />
            )}
            {pickUpSite.polygon && (
                <Polygon
                    path={pickUpSite.polygon.map(([lat, lng]) => ({ lat, lng }))}
                    defaultCenter={pickUpdLocation}
                    options={options}
                />
            )}
        </React.Fragment>
    );
};

PickUpMapData.propTypes = {
    notFadeMarkers: PropTypes.array.isRequired,
    pickUpSite: PropTypes.object.isRequired,
};

PickUpMapData.defaultProps = {
    notFadeMarkers: [],
    pickUpSite: {},
};

export default withRouter(PickUpMapData);
