import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { toDate } from "../../helpers/date";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import DeviceActionMenu from "./devicesActionMenu";
import { DEVISES_TYPES } from "../../constants/maps";
import { updateActiveIotOrder } from "../../actions/globalActions.js";

const DevisesList = (props) => {
    const { devises, account, loadDevises, devisesCount, updateActiveIotOrder, activeOrderIotParam } = props;
    const getTableData = () => {
        const rows = devises.map((devise, index) => {
            const {
                deviceId,
                currentJobOrderId,
                firmwareVersion,
                lastUpdate,
                battery,
                company,
                type,
                lastUpdateTimestamp,
                linkedTruck,
            } = devise;
            const date = toDate({
                date: lastUpdateTimestamp || lastUpdate,
                timezone: account.timezone,
            });

            return {
                index,
                id: deviceId,
                type: DEVISES_TYPES[type],
                company: company && company.name,
                truck: linkedTruck && linkedTruck.name,
                currentJob: currentJobOrderId || "",
                battery: battery ? `${battery}V` : "N/A",
                firmwareVersion,
                lastUpdate: lastUpdateTimestamp || lastUpdate ? moment(date).format(DEFAULT_DATE_TIME_FORMAT) : "N/A",
                action: <DeviceActionMenu itemForEdit={devise} loadDevises={loadDevises} />,
            };
        });
        let columns = [
            { id: "id", label: "Device ID", sortParam: "deviceId" },
            { id: "type", label: "Type", sortParam: "type" },
            { id: "company", label: "Company", sortParam: "company" },
            { id: "truck", label: "Truck", sortParam: "truck" },
            { id: "currentJob", label: "Current Job", sortParam: "job" },
            { id: "battery", label: "Battery" },
            { id: "firmwareVersion", label: "Firmware Version" },
            { id: "lastUpdate", label: "Last Update" },
            { id: "action", label: TEXT_ACTIONS_TABLE_CELL },
        ];

        return { rows: rows, columns: columns };
    };

    const handleSort = (sortParam) => updateActiveIotOrder(sortParam);

    return (
        <div>
            {!_.isEmpty(devises) ? (
                <TableWithStickyHeader
                    rows={getTableData().rows}
                    fetchMore={loadDevises}
                    itemsCount={devisesCount}
                    columns={getTableData().columns}
                    handleSort={handleSort}
                    activeOrderParam={activeOrderIotParam}
                />
            ) : (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No Devices</h1>
                </React.Fragment>
            )}
        </div>
    );
};

DevisesList.propTypes = {
    devises: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    loadDevises: PropTypes.func.isRequired,
    devisesCount: PropTypes.number,
};

DevisesList.defaultProps = {
    devises: [],
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
            activeOrderIotParam: state.companies.activeOrderIotParam,
        }),
        (dispatch) => {
            return {
                updateActiveIotOrder: (payload) => dispatch(updateActiveIotOrder(payload)),
            };
        },
    )(DevisesList),
);
