import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { SimpleLink } from "../core/link";
import clsx from "clsx";
import { TABLE_PRIMARY } from "../../styles/reusableStyles";
import { ICONS_SITE } from "../../styles/icons";
import TrucksRatio from "../core/truckRatio";
import UnitOfMeasure from "../core/unitOfMeasure";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import StartDate from "../core/time";
import RoomIcon from "@material-ui/icons/Room";
import { IS_INSPECTOR_USER, UNIT_OF_MEASURE_HOUR } from "../../constants/maps";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableHeaderSortingCell from "../core/tableHeaderSortingCell";
import TableCell from "@material-ui/core/TableCell";
import {
    getJobBoardEditRoute,
    getJobOrderEditRoute,
    getProjectEditRoute,
    getSiteEditRoute,
} from "../../helpers/global";
import { getIfIsPickUpMidpoint } from "../../helpers/jobOrders";
import ListWaypoint from "../core/listWaypoint";
import { getHoursQty } from "../tickets/components/ticketCard";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";
import PayloadLink from "./jobOrder/PayloadLink";
import { updateProjectSelectedTab } from "../../actions/projects";
import { useDispatch } from "react-redux";
import { PROJECT_DETAILS_TAB_INDEX } from "../projects/projectDetailsForm";

const useStyles = makeStyles((theme) => ({
    jobOrderListView: {
        "& .pick-up-site": {
            display: "flex",
            alignItems: "center",
            textAlign: "left",
        },
    },
    infiniteIcon: {
        height: 16,
        width: 16,
    },
    uiIcon: {
        marginRight: 4,
        height: 15,
        width: 18,
        top: 0,
    },
    unitOfMeasure: {
        fontSize: 9,
        color: theme.palette.secondary.main,
    },
    completedQty: {
        color: theme.palette.secondary.main,
    },
    trucksRatio: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    headerContainer: {
        display: "flex",
    },
    header: {
        display: "flex",
        width: "100%",
        borderBottom: " 1px solid #d8d8d8",
    },
    headerItem: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        borderBottom: "none",
    },
}));

const JobOrdersListView = (props) => {
    const classes = useStyles();
    const {
        jobOrders,
        jobOrdersCount,
        fetchMore,
        history,
        account,
        activeJobOrderParam,
        handleSort,
        initialHeader,
        isProjectHistoryPage,
        hideProjectColumn,
    } = props;
    const dispatch = useDispatch();
    const tableStyles = TABLE_PRIMARY();
    const siteIconsStyles = ICONS_SITE();
    const isJobBoardPage = history.location.pathname.includes(ROUTE_JOBS.JOB_BOARD);

    useEffect(() => {
        if (!activeJobOrderParam && !initialHeader) {
            handleSort && handleSort(null, "id");
        }
    }, []);

    const columns = [
        {
            id: "name",
            sortParam: "id",
            label: "JOB #",
        },
        !hideProjectColumn && {
            id: "project",
            sortParam: "project",
            label: "PROJECT",
        },
        {
            id: "pickup",
            sortParam: "pickup",
            label: "PICK-UP SITE",
        },
        !IS_INSPECTOR_USER(account.role) && {
            id: "price",
            sortParam: "price",
            label: "PRICE",
        },
        {
            id: "quantity",
            label: "QUANTITY",
        },
        {
            id: "payload",
            sortParam: "payload",
            label: "PAYLOAD",
        },
        {
            id: "startDate",
            sortParam: "startDate",
            label: "STARTING",
        },
        {
            id: "trucks",
            label: "Trucks",
        },
        {
            id: "numberOfTrucksRequested",
            label: "Requested Trucks",
        },
        !IS_INSPECTOR_USER(account.role) && {
            id: "amount",
            label: "Amount",
        },
    ];

    const renderInitialHeader = () => {
        return (
            <div className="thead">
                <div className="tr">
                    <div className="th">JOB #</div>
                    {!hideProjectColumn && <div className="th">Project</div>}
                    <div className="th">Pick-Up Site</div>
                    {!IS_INSPECTOR_USER(account.role) && <div className="th">Price</div>}
                    <div className="th">Quantity</div>
                    <div className="th">Payload</div>
                    <div className="th">Starting</div>
                    <div className="th">Trucks</div>
                    <div className="th">Requested Trucks</div>
                    {!IS_INSPECTOR_USER(account.role) && <div className="th">Amount</div>}
                </div>
            </div>
        );
    };

    const handlePayloadClick = () => {
        dispatch(updateProjectSelectedTab(PROJECT_DETAILS_TAB_INDEX));
    };

    return (
        <div className={classes.jobOrderListView}>
            <div className={clsx(tableStyles.primaryTable, classes.jobOrdersList)}>
                {!initialHeader ? (
                    <TableHead className={classes.headerContainer}>
                        <TableRow className={classes.header}>
                            {columns
                                .filter((i) => !!i)
                                .map((column, index) => {
                                    if (column.sortParam) {
                                        return (
                                            <TableHeaderSortingCell
                                                key={index}
                                                className={classes.headerItem}
                                                handleSort={handleSort}
                                                sortParam={column.sortParam}
                                                label={column.label}
                                                activeOrderParam={activeJobOrderParam}
                                            />
                                        );
                                    }

                                    return (
                                        <TableCell key={index} className={classes.headerItem}>
                                            {column.label}
                                        </TableCell>
                                    );
                                })}
                        </TableRow>
                    </TableHead>
                ) : (
                    renderInitialHeader()
                )}
                <div className="tbody">
                    {jobOrders.map((jobOrder, index) => {
                        const {
                            projectName,
                            id,
                            totalQuantity,
                            payloadName,
                            assignedTrucksCount,
                            startDate,
                            unlimited,
                            completedQuantity,
                            unitOfMeasure,
                            totalNeededTrucksCount,
                            unitPrice,
                            amount,
                            pickupLocation,
                            poLineItemId,
                            projectId,
                            numberOfTrucksRequested,
                        } = jobOrder;

                        const isPickUpMidpoint = getIfIsPickUpMidpoint(jobOrder);

                        return (
                            <React.Fragment>
                                <ListWaypoint
                                    fetchMore={fetchMore}
                                    index={index}
                                    items={jobOrders}
                                    itemsCount={jobOrdersCount}
                                />
                                <div className="tr" key={id}>
                                    <div className="td">
                                        {!IS_INSPECTOR_USER(account.role) ? (
                                            <SimpleLink
                                                to={
                                                    !isJobBoardPage
                                                        ? getJobOrderEditRoute(jobOrder.id)
                                                        : getJobBoardEditRoute(jobOrder.id)
                                                }
                                            >
                                                JO #{id}
                                            </SimpleLink>
                                        ) : (
                                            `JO #${id}`
                                        )}
                                    </div>
                                    {!hideProjectColumn && (
                                        <div className="td">
                                            {!IS_INSPECTOR_USER(account.role) ? (
                                                <SimpleLink
                                                    className="--uppercase"
                                                    to={{
                                                        pathname: getProjectEditRoute(projectId),
                                                        state: { project: { id: projectId } },
                                                    }}
                                                >
                                                    {projectName}
                                                </SimpleLink>
                                            ) : (
                                                projectName
                                            )}
                                        </div>
                                    )}
                                    <div className="td">
                                        {!IS_INSPECTOR_USER(account.role)
                                            ? !!pickupLocation && (
                                                  <SimpleLink
                                                      to={getSiteEditRoute(pickupLocation.id)}
                                                      className="--uppercase pick-up-site"
                                                  >
                                                      <RoomIcon
                                                          className={clsx(
                                                              siteIconsStyles.iconSite,
                                                              classes.uiIcon,
                                                              isPickUpMidpoint ? "mid" : "pick-up",
                                                          )}
                                                      />
                                                      {pickupLocation && pickupLocation.name}
                                                  </SimpleLink>
                                              )
                                            : pickupLocation && pickupLocation.name}
                                    </div>
                                    {!IS_INSPECTOR_USER(account.role) && unitPrice && (
                                        <div className="td">
                                            <span className="--green-text">${unitPrice.toFixed(2)}</span>
                                            {` / `}
                                            <UnitOfMeasure
                                                value={unitOfMeasure.name}
                                                className={classes.unitOfMeasure}
                                                isPlural={false}
                                            />
                                        </div>
                                    )}
                                    <div className="td">
                                        {!unlimited ? (
                                            <div className="totalQty">
                                                {unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                                                    <>
                                                        <span className={classes.completedQty}>
                                                            {getHoursQty(completedQuantity)}
                                                        </span>
                                                        /{getHoursQty(totalQuantity)}
                                                        {` `}
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className={classes.completedQty}>
                                                            {+completedQuantity.toFixed(2)}
                                                        </span>
                                                        /{+totalQuantity.toFixed(2)}
                                                        {` `}
                                                        <UnitOfMeasure
                                                            value={unitOfMeasure.name}
                                                            className={classes.unitOfMeasure}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <Grid container alignItems={"center"} justify={"center"}>
                                                    {!!completedQuantity &&
                                                        (unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                                                            <>
                                                                <span className={classes.completedQty}>
                                                                    {getHoursQty(completedQuantity)}/
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className={classes.completedQty}>
                                                                {+completedQuantity.toFixed(2)}/
                                                            </span>
                                                        ))}
                                                    <AllInclusiveIcon className={clsx(classes.infiniteIcon)} />
                                                </Grid>
                                                <div className="totalQty">
                                                    <UnitOfMeasure className="totalQty" value={unitOfMeasure.name} />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="td">
                                        {isProjectHistoryPage ? (
                                            <PayloadLink
                                                poLineItemId={poLineItemId}
                                                projectId={projectId}
                                                onPayloadClick={handlePayloadClick}
                                            >
                                                {payloadName}
                                            </PayloadLink>
                                        ) : (
                                            payloadName
                                        )}
                                    </div>
                                    <div className="td">
                                        {startDate && (
                                            <Box className="startTime" component="div">
                                                <StartDate
                                                    format={DEFAULT_DATE_TIME_FORMAT}
                                                    time={startDate}
                                                    showIcon={false}
                                                />
                                            </Box>
                                        )}
                                    </div>
                                    <div className="td">
                                        <TrucksRatio
                                            showIcon={true}
                                            assignedTrucks={assignedTrucksCount}
                                            className={classes.trucksRatio}
                                            requestedTrucks={+totalNeededTrucksCount}
                                        />
                                    </div>
                                    <div className="td">
                                        {numberOfTrucksRequested ? numberOfTrucksRequested : "N/A"}
                                    </div>
                                    {!IS_INSPECTOR_USER(account.role) && (
                                        <div className="td">{amount ? `$${amount}` : "N/A"}</div>
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
        </div>
    );
};

JobOrdersListView.propTypes = {
    jobOrders: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    jobOrdersCount: PropTypes.number.isRequired,
    fetchMore: PropTypes.func.isRequired,
    initialHeader: PropTypes.bool,
    hideProjectColumn: PropTypes.bool,
    isProjectHistoryPage: PropTypes.bool,
};

export default withRouter(JobOrdersListView);
