import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import Loader from "../core/loader";
import _ from "lodash";
import { getHoursQty } from "../tickets/components/ticketCard";
import { FILTERS_STATUS_ID } from "../global/searchAndFilters";
import { JO_STATUS_ACTIVE_ID } from "../../constants/maps";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import moment from "moment";
import { DASHBOARD_STATS_BUTTON_HEIGHT } from "./dashboardWrapper";

const useStyles = makeStyles(() => ({
    viewTicketsButton: {
        height: DASHBOARD_STATS_BUTTON_HEIGHT,
        fontSize: 21,
        width: "100%",
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.87)",
    },
    commanStatsBox: {
        fontSize: 14,
        textAlign: "left",
        padding: "10px 0 10px",
        textTransform: "capitalize",

        "& > div": {
            marginBottom: 5,
        },
    },
}));

const statsKeys = ["statsByStatus", "statsByUOM", "quantityStats"];

const StatisticDashboard = React.memo(
    (props) => {
        const classes = useStyles();
        const { history, isStatsLoading, allTicketsCount, ticketsStats } = props;
        const vewTickets = () => {
            const currentDate = moment().format(SERVER_DATE_FORMAT);
            history.push(ROUTE_JOBS.TICKETS, {
                initialFilters: {
                    [FILTERS_STATUS_ID]: { [JO_STATUS_ACTIVE_ID]: true },
                    [START_DATE_NAME]: currentDate,
                    [END_DATE_NAME]: currentDate,
                },
            });
        };

        return (
            <Grid component="div" container alignItems="flex-start" item xs={7}>
                <Grid container justify="center" alignItems="center">
                    <SecondaryButton
                        size="medium"
                        onClick={vewTickets}
                        className={clsx(classes.viewTicketsButton, isStatsLoading && LOADER_WHITE_OVERLAY)}
                    >
                        {isStatsLoading && <Loader />}
                        {allTicketsCount} View Tickets
                    </SecondaryButton>
                    <Grid
                        container
                        item
                        spacing={1}
                        style={{ minHeight: 81 }}
                        justify={"space-between"}
                        className={clsx(ticketsStats.ticketsStatsLoading && LOADER_WHITE_OVERLAY)}
                    >
                        {ticketsStats.ticketsStatsLoading && <Loader />}
                        {!_.isEmpty(ticketsStats) &&
                            statsKeys.map((key, index) => {
                                return (
                                    <Grid item xs={4} key={index}>
                                        <div className={clsx(classes.commanStatsBox)}>
                                            <table className="stats-table">
                                                <tbody>
                                                    {Object.values(ticketsStats[key]).map((item, index) => {
                                                        let value = item.value;

                                                        if (key === "quantityStats") {
                                                            if (item.name === "Hour") {
                                                                value = getHoursQty(value);
                                                            } else {
                                                                value = (+value).toFixed(2);
                                                            }
                                                        }

                                                        return (
                                                            <tr key={index}>
                                                                <td className="--text-semi-bold">{value}</td>
                                                                <td>
                                                                    {item.name !== "Hour" && (
                                                                        <>
                                                                            {item.name}
                                                                            {key === "quantityStats"
                                                                                ? `s`
                                                                                : key === "statsByStatus" && (
                                                                                      <span> Tickets</span>
                                                                                  )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </Grid>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

StatisticDashboard.propTypes = {
    history: PropTypes.object.isRequired,
    isStatsLoading: PropTypes.bool.isRequired,
    allTicketsCount: PropTypes.number,
    ticketsStats: PropTypes.object,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        allTicketsCount: state.stats.allTicketsCount,
        ticketsStats: state.stats.ticketsStats,
    }))(StatisticDashboard),
);
