import moment from "moment";

export const FREE = 1;
export const ENTERPRISE = 2;
export const STRIPE_BASIC = 3;
export const STRIPE_PRO = 4;

export const ACCOUNT_TYPES = {
    [FREE]: "Free",
    [ENTERPRISE]: "Enterprise",
    [STRIPE_BASIC]: "Stripe Basic",
    [STRIPE_PRO]: "Stripe Pro",
};

export const getIsStripeAccount = (accountType) => {
    const parsedAccountType = !!accountType && Number(accountType);

    return parsedAccountType && (parsedAccountType === STRIPE_PRO || parsedAccountType === STRIPE_BASIC);
};
export const CREATE_COMPANY_FORM_ACCOUNT_TYPES = {
    [FREE]: "Free",
    [ENTERPRISE]: "Enterprise",
};

export const MEASUREMENT_SYSTEM_NAME = "measurementSystem";
export const MEASUREMENT_SYSTEM_PLACEHOLDER = "Payload Measurement";
export const IMPERIAL = 1;
export const METRIC = 2;
export const KILOMETERS_UNIT = "km";
export const MILES_UNIT = "mi";
export const MILES_TO_METERS_FACTOR = 1609;
export const KILOMETERS_TO_METERS_FACTOR = 1000;

export const MEASUREMENT_SYSTEM = {
    [IMPERIAL]: "Imperial (Tons)",
    [METRIC]: "Metric (Tonnes)",
};

export const PROFILE_MEASURE_SYSTEM = {
    IMPERIAL: 1,
    METRIC: 2,
};

export const APPLE_MAPS = 1;
export const GOOGLE_MAPS = 2;
export const MAPS_FOR_IOS = {
    [APPLE_MAPS]: "Apple Maps",
    [GOOGLE_MAPS]: "Google Maps",
};

export const MIN_JOB_CLOSING_HOUR = 0;
export const MAX_JOB_CLOSING_HOUR = 23;
export const generateJobClosingHours = (minutesToAdd = 0) => {
    const closingHours = {};
    for (let hour = MIN_JOB_CLOSING_HOUR; hour <= MAX_JOB_CLOSING_HOUR; hour++) {
        closingHours[hour] = moment(hour, ["HH"]).add(minutesToAdd, "minutes").format("hh:mm A");
    }

    return closingHours;
};
export const REGULAR_JOB_CLOSING_HOURS = generateJobClosingHours(10);
export const OVERNIGHT_JOB_CLOSING_HOURS = generateJobClosingHours(0);

export const parseMetersToUserSystem = (meters, measureSystem) => {
    switch (measureSystem) {
        case IMPERIAL: {
            return parseFloat(meters / MILES_TO_METERS_FACTOR).toFixed(1) + MILES_UNIT;
        }

        case METRIC: {
            return parseFloat(meters / KILOMETERS_TO_METERS_FACTOR).toFixed(1) + KILOMETERS_UNIT;
        }

        default: {
            return parseFloat(meters / KILOMETERS_TO_METERS_FACTOR).toFixed(1) + KILOMETERS_UNIT;
        }
    }
};
