// Smart Dispatch Bundles and Routes
export const BUNDLES_FILTERS_FORM = "bundles-filters-form";
export const TRUCK_FILTERS_FORM = "truck-filters-form";
export const TRUCK_FILTERS_FORM_DATE_FIELD = "startDate";
// Smart Dispatch Regular Jobs List
export const SD_REGULAR_JOBS_LIST_FORM = "SD_REGULAR_JOBS_LIST_FORM";
export const SD_REGULAR_JOBS_LIST_FIELD = "filters";
// Smart Dispatch View Form
export const SD_VIEW_FORM = "smart-dispatch-view-form";
export const SD_VIEW_FIELD = "view";
//Input Loads from
export const INPUT_LOADS_FORM = "input-loads-form";
// Truck Custom Fields
export const TRUCK_CUSTOM_FIELDS_FORM = "truck-custom-fields-form";

// Settings - Unit Of Measure
export const SETTINGS_UOM_COMPANY_SELECTOR = "SETTINGS UOM COMPANY SELECTOR";
export const SETTINGS_UOM_EDIT_UON = "SETTINGS UOM EDIT UON";
export const SETTINGS_UOM_CREATE_NEW_UOM = "SETTINGS UOM CREATE NEW UOM";
export const UNITS_OF_MEASURE_TABLE = "UNITS OF MEASURE TABLE";
