import React from "react";

export function useDebounce(request, time = 500) {
    const [value, setValue] = React.useState(null);
    const [timer, setTimer] = React.useState(null);

    const searchRequest = (args) => {
        const [dataValue] = args;
        setValue(dataValue);
        request(...args);
        clearTimeout(timer);
        setTimer(null);
    };

    const handleSearchDebounce = (...args) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(timer);
        }
        setTimer(setTimeout(() => searchRequest(args), time));
    };

    return [value, handleSearchDebounce, setValue];
}
