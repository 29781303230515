import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { Validation } from "../../../helpers/validation";
import TextInputComponent from "../../core/form/textInputComponent";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import AppModal from "../../core/modal";

const CreateFilterForm = (props) => {
    const { showCreateSetModal, closeCreateFilterModal, handleSubmit, saveFiltersSet, savedFilter } = props;

    return (
        <AppModal isOpen={!!showCreateSetModal} closeModal={closeCreateFilterModal}>
            <form onSubmit={handleSubmit((values) => saveFiltersSet(values))}>
                <h2 className="title">{!savedFilter.id ? "Save Filters Set" : "Edit Filters Set"}</h2>
                <br />
                <Field
                    type="text"
                    validate={[Validation.required, Validation.noSpecialSymbols, Validation.maxLength100]}
                    name="name"
                    className="form-view"
                    label="Name"
                    component={TextInputComponent}
                />
                <br />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeCreateFilterModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
        </AppModal>
    );
};

CreateFilterForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    closeCreateFilterModal: PropTypes.func.isRequired,
    saveFiltersSet: PropTypes.func.isRequired,
    showCreateSetModal: PropTypes.bool.isRequired,
    savedFilter: PropTypes.object,
};

CreateFilterForm.defaultProps = {};

export const CREATE_FILTERS_SET_FORM = "createFilersForm";
export default withRouter(
    compose(
        reduxForm({
            form: CREATE_FILTERS_SET_FORM,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(CreateFilterForm),
);
