import React, { useEffect } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Field, reduxForm, hasSubmitSucceeded } from "redux-form";

import AppModal from "../../core/modal";
import { selectPreferredMeta } from "../../../selectors/index";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";
import { Validation } from "../../../helpers/validation";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { getHaulersWithoutPreferred } from "../../../dataServers/trucks";
import { GLOBAL_COUNT_TO_LOAD } from "../../../constants/endpoints";
import { addHauler } from "../../../actions/settings";

const AddHaulerToPreferredListModal = (props) => {
    const { form, isOpen, closeModal, handleSubmit } = props;

    const { loading } = useSelector(selectPreferredMeta);

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(SETTINGS_HAULERS_FORM));

    useEffect(() => {
        hasSubmitSuccess && closeModal();
    }, [hasSubmitSuccess]);

    const loadHaulersWithoutPreferred = (inputValue, { params = {}, loadedCount }) => {
        return getHaulersWithoutPreferred({ keywords: inputValue, ...params }).then((data) => {
            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;
            const options = data.data.map((hauler) => ({
                label: hauler.name,
                value: hauler.id,
                defaultRate: hauler.defaultRate,
            }));

            return {
                options: options,
                hasMore: data.meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        });
    };

    return (
        <AppModal isOpen={isOpen} closeModal={closeModal} form={form}>
            <form onSubmit={handleSubmit} noValidate={true} className={clsx(loading && LOADER_WHITE_OVERLAY)}>
                <Loader />
                <h1 className="--text-center">Add Hauler to preferred filter list</h1>
                <br />
                <br />
                <Field
                    name="hauler"
                    validate={[Validation.required]}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={loadHaulersWithoutPreferred}
                    placeholder="Choose Hauler"
                    label="Choose Hauler"
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
        </AppModal>
    );
};

AddHaulerToPreferredListModal.propTypes = {
    form: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    setSuccess: PropTypes.func.isRequired,
};

const SETTINGS_HAULERS_FORM = "AddHaulerToPreferredListModal";

const onSubmit = (hauler, dispatch) => dispatch(addHauler(hauler));

export default withRouter(
    compose(
        reduxForm({
            form: SETTINGS_HAULERS_FORM,
            onSubmit,
            enableReinitialize: true,
            destroyOnUnmount: true,
        }),
    )(AddHaulerToPreferredListModal),
);
