export const CUSTOMER_VALUE = "customer";
export const RESTRICTED_CUSTOMER_VALUE = "restricted-customer";

export const CUSTOMER_TYPES = [
    {
        label: "Customer",
        value: CUSTOMER_VALUE,
    },
    {
        label: "Restricted Customer",
        value: RESTRICTED_CUSTOMER_VALUE,
    },
];
