import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import _ from "lodash";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getCompanies } from "../../dataServers/companies";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { updateProjectAllowedCompanies } from "../../dataServers/projects";
import Loader from "../core/loader";
import clsx from "clsx";
import ErrorNotification from "../core/notification";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

const AllowedCompaniesForm = (props) => {
    const { closeModal, handleSubmit, currentProject, initialValues } = props;
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [isLoading, setLoading] = useState(false);
    const onSubmit = (values) => {
        const noChanges = _.isEqual(values, initialValues);
        const isRemoved =
            values.allowedCompanies && initialValues.allowedCompanies
                ? initialValues.allowedCompanies.length > values.allowedCompanies.length
                : !values.allowedCompanies;

        setLoading(true);

        !noChanges &&
            updateProjectAllowedCompanies(currentProject.id, {
                allowedCompaniesIds: values.allowedCompanies ? values.allowedCompanies.map((i) => i.value) : [],
            })
                .then((data) => {
                    setLoading(false);
                    isRemoved
                        ? setSuccess(["Project grant access companies successfully removed"])
                        : setSuccess(["Project grant access companies successfully added"]);
                    closeModal();
                })
                .catch((error) => {
                    setLoading(false);
                    setError(PROCESS_SERVER_ERROR(error));
                });

        if (noChanges) {
            setLoading(false);
            closeModal();
        }
    };
    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: i.name }));
            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: nextPage,
            };
        });
    };

    return (
        <form noValidate={true} onSubmit={handleSubmit(onSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {success && (
                <ErrorNotification error={success} type={"success"} config={{ onClose: () => setError(null) }} />
            )}
            <h1 className="title">Select Grant Access Companies</h1>
            <Field
                name="allowedCompanies"
                loadOptions={loadCompanies}
                isClearable={true}
                placeholder="Select Companies"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                isMulti={true}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Save</PrimaryButton>
            </ButtonsGroup>
        </form>
    );
};

AllowedCompaniesForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    currentProject: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
};

export const ALLOWED_COMPANIES_FORM = "allowedCompaniesForm";

export default withRouter(
    compose(
        reduxForm({
            form: ALLOWED_COMPANIES_FORM,
            enableReinitialize: true,
        }),
        connect((state) => {
            return {
                account: state.account,
                currentProject: state.projects.currentProject,
            };
        }),
    )(AllowedCompaniesForm),
);
