import React, { useState } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { useHistory } from "react-router";
import clsx from "clsx";

import { ButtonsGroup, PrimaryButton, SecondaryButton } from "../../core";
import { SITES_TYPE_PRIVATE } from "../../../constants/maps";
import { EXISTING_JOB_SPLIT_LABEL } from "../../../constants/strings";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { getJobOrderEditRoute } from "../../../helpers/global";
import { splitJobOrderById } from "../../../dataServers/jobOrder";
import ErrorNotification from "../../core/notification";
import MidSite from "../jobOrder/midSite";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import { formatReduxFormErrors } from "../../../helpers/errorFormatting";
import Loader from "../../core/loader";

const SplitJobForm = ({ handleClose, form, handleSubmit, submitting, jobOrderId, setActive }) => {
    const [error, setError] = useState(null);
    const history = useHistory();
    const isDispatchPage = history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);

    const onSubmit = async ({ midSite }) => {
        try {
            const { data } = await splitJobOrderById(jobOrderId, { locationId: midSite.value });
            const newJobOrderId = data.id;

            if (isDispatchPage) {
                setActive(data);
            } else {
                history.push(getJobOrderEditRoute(newJobOrderId));
            }
        } catch (error) {
            setError([PROCESS_SERVER_ERROR(error)]);
            formatReduxFormErrors(error);
        }
    };

    return (
        <form
            onSubmit={(e) => {
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
            }}
            className={clsx(submitting && LOADER_WHITE_OVERLAY)}
        >
            {submitting && <Loader />}
            <h2 className="--text-center">Split Job</h2>
            <h3 className="--text-center --font-100">{EXISTING_JOB_SPLIT_LABEL}</h3>
            <br />
            <MidSite sitesRequestParams={{ accessType: SITES_TYPE_PRIVATE }} form={form} />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Save</PrimaryButton>
            </ButtonsGroup>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </form>
    );
};

export const SPLIT_JOB_FORM = "SPLIT_JOB_FORM";

export default compose(
    reduxForm({
        form: SPLIT_JOB_FORM,
    }),
)(SplitJobForm);
