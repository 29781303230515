import React from "react";
import { Field } from "redux-form";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getUsers } from "../../dataServers/user";

export const JOB_CONTACT_NAME = "jobContactId";

const JobContactSelector = (props) => {
    const { onChange, disabled, additionalParams } = props;
    const loadContactUsers = (inputValue, { params = {}, loadedCount }) => {
        return getUsers({ truckerType: 1, keywords: inputValue, ...params, ...additionalParams }).then((data) => {
            const results = data.data.map((i) => ({ ...i, value: i.id, label: i.fullName }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <Field
            name={JOB_CONTACT_NAME}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            disabled={disabled}
            loadOptions={loadContactUsers}
            onChange={onChange}
            isClearable={true}
            placeholder="Foreman / Point-of-Contact"
            component={AsyncAutocompleteComponent}
        />
    );
};

JobContactSelector.propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    additionalParams: PropTypes.object,
};

JobContactSelector.defaultProps = {
    additionalParams: {},
};

export default withRouter(JobContactSelector);
