import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import AppModal from "../../core/modal";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import Loader from "../../core/loader";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { verifyTicket } from "../../../dataServers/tickets";
import ErrorNotification from "../../core/notification";
import TextInputComponent from "../../core/form/textInputComponent";
import {
    AIR_TICKET_STATUS_ACCEPT,
    AIR_TICKET_STATUS_ACCEPT_LABEL,
    AIR_TICKET_STATUS_REJECT,
    AIR_TICKET_STATUS_REJECT_LABEL,
} from "../../../constants/maps";
import CustomCheckbox from "../../core/form/customCheckbox";

const VerifyModal = (props) => {
    const { handleSubmit, isOpen, handleClose, ticket, updateItemInTherList } = props;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const onSubmit = (values) => {
        setLoading(true);

        verifyTicket({
            id: ticket.id,
            isAirTicketLite: ticket.isAirTicketLite,
            plantQC: values.plantQC || undefined,
            roadwayQC: values.roadwayQC || undefined,
            plantVT: values.plantVT || undefined,
            roadwayVT: values.roadwayVT || undefined,
            notes: values.notes || undefined,
            status:
                values.status === "accept"
                    ? AIR_TICKET_STATUS_ACCEPT
                    : values.status === "reject"
                    ? AIR_TICKET_STATUS_REJECT
                    : undefined,
        })
            .then(({ data }) => {
                setLoading(false);
                updateItemInTherList(data);
                setSuccess([`Ticket #${ticket.id} is successfully updated`]);
                handleClose();
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    return (
        <AppModal isOpen={isOpen} modalStyles={{ width: 550 }} form={AIR_TICKETS_EDIT_FORM} closeModal={handleClose}>
            <form noValidate={true} autoComplete="off" className="centered-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                    {isLoading && <Loader />}
                    {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                    {success && (
                        <ErrorNotification
                            message={success}
                            type={"success"}
                            config={{ onClose: () => setSuccess(null) }}
                        />
                    )}
                    <h1 className="title">
                        Ticket #{ticket && ticket.externalRef}
                        {ticket.id ? ` (${ticket.id})` : null}
                    </h1>
                    <Grid container justify={"space-between"} spacing={2}>
                        <Grid component="div" item xs={6}>
                            <Field
                                name="status"
                                type="radio"
                                value={AIR_TICKET_STATUS_ACCEPT_LABEL}
                                label="Accept"
                                component={CustomCheckbox}
                            />
                            <br />
                            <Field
                                name="status"
                                value={AIR_TICKET_STATUS_REJECT_LABEL}
                                type="radio"
                                label="Reject"
                                component={CustomCheckbox}
                            />
                        </Grid>
                        <Grid component="div" item xs={6} />
                    </Grid>
                    <br />
                    <Grid container justify={"space-between"} spacing={2}>
                        <Grid component="div" item xs={6}>
                            <Field
                                name="plantQC"
                                type="number"
                                label="Plant QC Temperature"
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid component="div" item xs={6}>
                            <Field
                                name="roadwayQC"
                                type="number"
                                label="Roadway QC Temperature"
                                component={TextInputComponent}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container justify={"space-between"} spacing={2}>
                        <Grid component="div" item xs={6}>
                            <Field
                                name="plantVT"
                                type="number"
                                label="Plant VT Temperature"
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid component="div" item xs={6}>
                            <Field
                                name="roadwayVT"
                                type="number"
                                label="Roadway VT Temperature"
                                component={TextInputComponent}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Field name="notes" type="text" multiline={true} label="Notes" component={TextInputComponent} />
                    <br />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </ButtonsGroup>
                </div>
            </form>
        </AppModal>
    );
};

VerifyModal.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    updateItemInTherList: PropTypes.func.isRequired,
    ticket: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

VerifyModal.defaultProps = {
    ticket: {},
};

export const AIR_TICKETS_EDIT_FORM = "airTicketsEditForm";
export default withRouter(
    compose(
        reduxForm({
            form: AIR_TICKETS_EDIT_FORM,
            enableReinitialize: true,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(VerifyModal),
);
