import React from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { SimpleLink } from "../core/link";
import { LIST_ITEM_STYLES } from "../../styles/reusableStyles";
import { makeStyles } from "@material-ui/core/styles";
import ProjectCheckIcon from "../global/checkIcon";
import HaulerSolutionRateInput from "./HaulerSolutionRateInput";
import { useSelector } from "react-redux";
import { selectCurrentBundleIAmOwner, selectRouteIsEmpty } from "../../selectors/smartDispatchSelectors";
import { selectAccountTimezone, selectCompanyId } from "../../selectors";
import { toDate } from "../../helpers/date";
import { DEFAULT_TIME_FORMAT } from "../../constants/global";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { ICONS_SITE } from "../../styles/icons";
import { Tooltip } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    solutionItem: {
        padding: "5px",
        "& .routeIcon": {
            margin: 0,
            width: "15px",
            height: "15px",
        },
    },
    routeAssignedDataContainer: {
        padding: "2px 5px",
        margin: "10px 0",
        boxShadow: `0 0 4px 0 ${theme.palette.secondary.superDark}`,
        borderRadius: "10px",
    },
    haulerContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    unassignContainer: {
        marginRight: "20px",
    },
    routeDataContainer: {
        marginTop: "15px",
    },
}));

const SolutionTruckListItem = ({
    truckIsSelected,
    handleTruckSolutionClick,
    route,
    handleUnassignClick,
    isIntraDay,
}) => {
    const classesListItem = LIST_ITEM_STYLES();
    const siteIconsClasses = ICONS_SITE();
    const classes = styles();
    const {
        id: routeId,
        index: routeIndex,
        primeHauler,
        subHauler,
        assignedTruck,
        firstJobOrderPickUpLocation,
        firstJobOrderStartDate,
    } = route;
    const routeIsEmpty = useSelector((state) => selectRouteIsEmpty(state, routeIndex));
    const iAmOwner = useSelector(selectCurrentBundleIAmOwner);
    const timezone = useSelector(selectAccountTimezone);
    const companyId = useSelector(selectCompanyId);

    const firstJobOrderStartTime = toDate({ date: firstJobOrderStartDate, timezone: timezone }).format(
        DEFAULT_TIME_FORMAT,
    );
    const requestedFrom = primeHauler?.requestedFrom;

    const onTruckSolutionClick = () => {
        handleTruckSolutionClick && handleTruckSolutionClick(routeId);
    };
    const onUnassignLinkClick = (e) => {
        handleUnassignClick && handleUnassignClick(e, route);
    };

    return (
        <div
            className={clsx(classesListItem.listItem, classes.solutionItem, truckIsSelected && "-current")}
            onClick={onTruckSolutionClick}
        >
            <Grid container justify="space-between">
                <Grid item>ROUTE # {+routeIndex + 1}</Grid>
                {!routeIsEmpty && !isIntraDay && (
                    <Grid item className={classes.unassignContainer}>
                        <SimpleLink onClick={onUnassignLinkClick}>Unassign</SimpleLink>
                    </Grid>
                )}
            </Grid>
            {assignedTruck && (
                <div className={classes.routeAssignedDataContainer}>
                    <h4>Assigned Truck: {assignedTruck.name}</h4>
                </div>
            )}
            {primeHauler && (
                <div className={classes.routeAssignedDataContainer}>
                    <div className={classes.haulerContainer}>
                        <div>
                            <h4>Hauler: {primeHauler.name}</h4>
                        </div>
                        {iAmOwner && (
                            <div className={"hauler-rate-container"}>
                                <HaulerSolutionRateInput hauler={primeHauler} routeIndex={routeIndex} />
                            </div>
                        )}
                    </div>
                    {requestedFrom && !iAmOwner && <h4>Requested From: {requestedFrom.name} </h4>}
                </div>
            )}
            {subHauler && (
                <div className={classes.routeAssignedDataContainer}>
                    <div className={classes.haulerContainer}>
                        <div>
                            <h4>Subhauler: {subHauler.name}</h4>
                        </div>
                        {companyId === primeHauler.id && (
                            <div className={"hauler-rate-container"}>
                                <HaulerSolutionRateInput hauler={subHauler} routeIndex={routeIndex} />
                            </div>
                        )}
                    </div>
                    {requestedFrom && !iAmOwner && <h4>Requested From: {requestedFrom.name} </h4>}
                </div>
            )}
            <Grid container justify={"space-between"} className={classes.routeDataContainer}>
                {firstJobOrderPickUpLocation && (
                    <Grid item xs={9}>
                        <Tooltip title={"First Job Order pick up site"} enterDelay={300}>
                            <h4>
                                <LocationOnIcon className={clsx(siteIconsClasses.iconSite, "pick-up", "routeIcon")} />
                                {firstJobOrderPickUpLocation.name}
                            </h4>
                        </Tooltip>
                    </Grid>
                )}
                {firstJobOrderStartDate && (
                    <Grid item xs={3}>
                        <Tooltip title={"Start Time"} enterDelay={300}>
                            <h4 className="--text-right">{firstJobOrderStartTime}</h4>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            {truckIsSelected && <ProjectCheckIcon />}
        </div>
    );
};

export default SolutionTruckListItem;
