import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { DEFAULT_TIME_FORMAT } from "../../../constants/global";

const useStyles = makeStyles((theme) => ({
    timePickerRoot: {
        "& .picker-wrap": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .picker": {
            minWidth: 100,
            width: 100,
        },
        "& input": {
            fontSize: 16,
            color: theme.palette.secondary.dark,

            "&:before": {
                content: "none",
            },
        },
        "& .MuiInput-underline:not(:hover)::before": {
            borderBottomColor: "transparent",
        },
        "& .Mui-focused::after": {
            borderBottomColor: theme.palette.general.green,
        },
        "& .ui-icon-clock": {
            width: theme.icon.width,
            height: theme.icon.height,
            color: theme.palette.secondary.dark,
            marginRight: 5,
        },
        "& button": {
            padding: 0,

            "& svg": {
                width: 20,
                height: 20,
            },
        },
        "& .MuiFormControl-root .MuiInput-underline.Mui-error:after": {
            borderBottomColor: theme.palette.general.darkishRed,
        },
        "& .MuiFormControl-root .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.general.darkishRed,
        },
    },
}));

const TruckItTimePicker = (props) => {
    const classes = useStyles();
    const {
        handleDateChange,
        format,
        containerClassName,
        label,
        disabled,
        variant,
        handleBlur,
        onAccept,
        onClose,
        autoOk,
        placeholder,
        useFormattedDate,
    } = props;
    const {
        input: { onBlur: inputOnBlur, value, ...inputProps },
        meta: { error, touched },
    } = props;
    const [focused, setFocus] = useState(false);

    const onChange = (date, value) => {
        const temp = moment(value, DEFAULT_TIME_FORMAT).format();

        const usedDate = useFormattedDate ? temp : date;

        inputProps.onChange(usedDate);
        handleDateChange && handleDateChange(usedDate);
    };

    const onBlur = (value) => {
        inputOnBlur(value);
        setFocus(false);
        handleBlur && handleBlur(value);
    };

    const onFocus = (e) => {
        setFocus(true);
        inputProps.onFocus(e);
    };

    const checkErrors = () => touched && error;

    return (
        <div className={clsx(classes.timePickerRoot, containerClassName)}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                    <div className="picker-wrap">
                        <AccessTimeIcon className="ui-icon-clock" />
                        <div className="picker">
                            <KeyboardTimePicker
                                label={label}
                                {...inputProps}
                                autoOk={autoOk}
                                value={value || null}
                                onFocus={onFocus}
                                variant={variant}
                                placeholder={placeholder || moment().format(format)}
                                format={format}
                                disabled={disabled}
                                onAccept={() => onAccept && onAccept(value)}
                                onClose={() => onClose && onClose(value)}
                                onBlur={() => onBlur(value)}
                                onChange={onChange}
                                invalidDateMessage={focused || disabled ? "" : "Invalid Time"}
                                InputProps={{ className: classes.input, endAdornment: <span /> }}
                                error={checkErrors()}
                                helperText={checkErrors()}
                            />
                        </div>
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        </div>
    );
};

TruckItTimePicker.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    format: PropTypes.string.isRequired,
    handleDateChange: PropTypes.func,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    handleBlur: PropTypes.func,
    input: PropTypes.object.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    autoOk: PropTypes.bool,
    meta: PropTypes.object,
    variant: PropTypes.oneOf(["dialog", "inline", "static"]),
    placeholder: PropTypes.string,
    useFormattedDate: PropTypes.bool,
};

TruckItTimePicker.defaultProps = {
    format: DEFAULT_TIME_FORMAT,
    handleDateChange: () => {},
    disabled: false,
    autoOk: false,
    variant: "dialog",
    useFormattedDate: true,
};

export default withRouter(TruckItTimePicker);
