import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTE_ANALYTICS, ROUTE_JOBS } from "../../routes/globalRoutes";
import { useHistory } from "react-router";
import {
    WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION,
} from "../../constants/maps";
import { END_DATE_NAME, START_DATE_NAME } from "./datesRange";
import { getJobOrderById } from "../../dataServers/jobOrder";
import { getTicketById } from "../../dataServers/tickets";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";
import { setNotificationSelectedTruckId } from "../../actions/trucksAcitivityActions";
import { SERVER_DATE_FORMAT } from "../../constants/global";
import moment from "moment";
import { REPORT_TRUCKS_ACTIVITY_FORM } from "../reportAndAnalytic/trucksActivity";
import { setAppliedFiltersToLocalStorage } from "../../helpers/global";

const useStyles = makeStyles(() => ({
    notificationLink: {
        textDecoration: "underline",
        cursor: "pointer",
    },
}));

const NotificationWord = ({ word, wordIndex, notificationEvent }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);

    const isLinkWord = word.includes("link") && notificationEvent;
    const processedWord = word.replace("&link", "");

    const handleNotificationLinkClick = () => {
        switch (notificationEvent.subType || notificationEvent.dataSubType) {
            case WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT: {
                getTicketById(notificationEvent.ticket.id).then((response) => {
                    const ticketData = response.data;
                    history.push(ROUTE_JOBS.TICKETS, {
                        ticketId: notificationEvent.ticket.id,
                        [START_DATE_NAME]: ticketData.createdDate,
                        [END_DATE_NAME]: ticketData.createdDate,
                    });
                });
                break;
            }
            case WEBSOCKET_DATA_SUB_TYPE_JO_EVENT: {
                getJobOrderById(notificationEvent.jobOrderId || notificationEvent.data.id).then((response) => {
                    const jobOrderData = response.data;

                    history.push(ROUTE_JOBS.MY_JOBS, {
                        initialFilters: {
                            [START_DATE_NAME]: moment(jobOrderData.startDate)
                                .tz(account.timezone)
                                .format(SERVER_DATE_FORMAT),
                            [END_DATE_NAME]: moment(jobOrderData.startDate)
                                .tz(account.timezone)
                                .format(SERVER_DATE_FORMAT),
                            jobOrderId: jobOrderData.id,
                            isTemplatedJob: !!jobOrderData.copySchedule,
                        },
                    });
                });
                break;
            }
            case WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION:
            case WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT:
                getJobOrderById(notificationEvent.jobOrderId).then((response) => {
                    const jobOrderData = response.data;
                    const project = jobOrderData.poLineItem.purchaseOrder.project;

                    dispatch(change(REPORT_TRUCKS_ACTIVITY_FORM, "redirect", true));
                    dispatch(change(REPORT_TRUCKS_ACTIVITY_FORM, "jobOrder", jobOrderData));
                    dispatch(
                        change(REPORT_TRUCKS_ACTIVITY_FORM, "project", {
                            ...project,
                            value: project.id,
                            label: project.name,
                        }),
                    );
                    dispatch(setNotificationSelectedTruckId(notificationEvent.truckId));

                    setAppliedFiltersToLocalStorage(REPORT_TRUCKS_ACTIVITY_FORM, {
                        [START_DATE_NAME]: moment(jobOrderData.startDate).format(SERVER_DATE_FORMAT),
                        [END_DATE_NAME]: moment(jobOrderData.startDate).format(SERVER_DATE_FORMAT),
                    });

                    history.push(ROUTE_ANALYTICS.TRUCK_ACTIVITY, { redirect: true });
                });
                break;
            default:
                return null;
        }
    };

    if (isLinkWord) {
        return (
            <span key={wordIndex} className={clsx(classes.notificationLink)} onClick={handleNotificationLinkClick}>
                {processedWord}
            </span>
        );
    }

    return (
        <span key={wordIndex} className={clsx(wordIndex % 2 !== 0 && "--text-semi-bold")}>
            {processedWord}
        </span>
    );
};

NotificationWord.propTypes = {
    word: PropTypes.string.isRequired,
    wordIndex: PropTypes.number.isRequired,
    notificationEvent: PropTypes.object,
};

export const NotificationLabel = (props) => {
    const { className, text, notificationEvent } = props;

    return (
        <span className={className}>
            {text.split("|").map((word, index) => (
                <NotificationWord word={word} wordIndex={index} notificationEvent={notificationEvent} key={index} />
            ))}
        </span>
    );
};

NotificationLabel.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.any,
    notificationEvent: PropTypes.object,
};

const NotificationLabelListItem = (props) => {
    const { notificationEvent, className } = props;

    return (
        <Grid
            container
            component={"ul"}
            className={className}
            justify={"flex-start"}
            spacing={1}
            alignItems={"center"}
            style={{ padding: 0 }}
        >
            <Grid item xs={6}>
                <NotificationLabel text={notificationEvent.notificationTitle} notificationEvent={notificationEvent} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }}>
                <span>{notificationEvent.displayDate}</span>
            </Grid>
        </Grid>
    );
};

NotificationLabelListItem.propTypes = {
    notificationEvent: PropTypes.object.isRequired,
    className: PropTypes.any,
};

export default NotificationLabelListItem;
