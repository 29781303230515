import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DEFAULT_DATE_TIME_FORMAT, SERVER_DATE_FORMAT } from "../../../constants/global";
import MomentUtils from "@date-io/moment";
import clsx from "clsx";
import moment from "moment";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import IconButton from "@material-ui/core/IconButton";
import FieldNote from "./fieldNote";

const useStyles = makeStyles((theme) => ({
    dateTimePickerRoot: {
        "& .piker-wrapper": {
            display: "flex",

            "& > *:not(button)": {
                width: "100%",
            },
        },
        "& button": {
            padding: 0,

            "& svg": {
                width: 20,
                height: 20,
            },
        },
        "& .MuiInput-formControl": {
            fontSize: 16,
        },
        "& .MuiOutlinedInput-root": {
            fontSize: theme.typography.fontSize,
            paddingRight: 3,
            backgroundColor: "#fff",

            "& input": {
                padding: "7px 0px 7px 7px",
            },
        },
        "&.form-view": {
            "& .MuiOutlinedInput-root": {
                paddingRight: 10,
                backgroundColor: "#fff",
                color: theme.palette.secondary.superDark,
                fontSize: 16,
                fontWeight: theme.typography.fontWeightMedium,

                "& input": {
                    padding: "16px 0px 16px 12px",
                },
            },
            "& fieldset": {
                borderWidth: 2,
                borderColor: theme.palette.border.primary,
            },
            "& .MuiInputAdornment-positionEnd": {
                marginLeft: 3,
            },
            "& .arrow-button": {
                height: 30,
                top: 9,
                position: "relative",
            },
            "& button": {
                padding: 0,

                "& svg": {
                    width: 28,
                    height: 30,
                    color: theme.palette.secondary.superDark,
                },
            },
        },
        "& .MuiFormControl-root .MuiInput-underline.Mui-error:after": {
            borderBottomColor: theme.palette.general.darkishRed,
        },
        "& .MuiFormControl-root .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.general.darkishRed,
        },
    },
    dateTimeModal: {
        "& h4": {
            fontSize: 33,
        },
        "& h3": {
            fontSize: 40,
        },
    },
}));

const todayDisabledButtonStyles = makeStyles((theme) => ({
    todayDisabledButton: {
        color: theme.palette.secondary.light,
        cursor: "not-allowed",
        pointerEvents: null,
    },
}));

const DisabledTodayButton = () => {
    const classes = todayDisabledButtonStyles();

    return <span className={clsx(classes.todayDisabledButton)}>today</span>;
};

const DateTimePicker = React.memo(
    (props) => {
        const classes = useStyles();
        const {
            handleDateChange,
            dateFormat,
            label,
            disabled,
            variant,
            handleBlur,
            onAccept,
            onClose,
            inputVariant,
            disablePast,
            placeholder,
            fieldNote,
            dateOnly,
            needValidation,
            autoOk,
            className,
            isDisabledInputProps,
            labelFunc,
            generalInputVariant,
            minDate,
            disableTodayButton,
            disabledLeftArrow,
        } = props;
        const {
            input: { onBlur: inputOnBlur, value, ...inputProps },
            meta: { error, touched },
        } = props;
        const [focused, setFocus] = useState(false);
        const [open, setOpen] = useState(false);
        const onChange = (date, value) => {
            inputProps.onChange(date);
            handleDateChange && handleDateChange(date, value);
        };
        const onArrowClick = (add) => {
            add
                ? onChange(moment(moment(value).add(1, "days")).format(SERVER_DATE_FORMAT))
                : onChange(moment(moment(value).subtract(1, "days")).format(SERVER_DATE_FORMAT));
        };

        const onBlur = (value) => {
            inputOnBlur(value);
            setFocus(false);
            handleBlur && handleBlur(value);
        };

        const onFocus = (e) => {
            setFocus(true);
            inputProps.onFocus(e);
        };

        const checkErrors = () => error && touched && error;

        const PickerComponent = dateOnly ? KeyboardDatePicker : KeyboardDateTimePicker;

        return (
            <div className={clsx(classes.dateTimePickerRoot, "date-time-picker", className)}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <>
                        <div className="piker-wrapper">
                            {inputVariant === INPUT_VARIANT_OUTLINED && (
                                <IconButton
                                    className="arrow-button"
                                    disabled={disabled || disabledLeftArrow}
                                    onClick={() => onArrowClick(false)}
                                >
                                    <ArrowLeftIcon className="ui-icon-arrow" />
                                </IconButton>
                            )}
                            <PickerComponent
                                variant={variant}
                                label={label}
                                disabled={disabled}
                                {...inputProps}
                                minDate={minDate}
                                value={value || null}
                                onAccept={onAccept}
                                placeholder={placeholder}
                                autoOk={autoOk}
                                onFocus={onFocus}
                                ampm={true}
                                disablePast={disablePast}
                                allowKeyboardControl={true}
                                onBlur={onBlur}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => {
                                    onClose && onClose();
                                    setOpen(false);
                                }}
                                showTodayButton={true}
                                todayLabel={disableTodayButton ? <DisabledTodayButton /> : "TODAY"}
                                onChange={onChange}
                                inputVariant={generalInputVariant || inputVariant}
                                labelFunc={labelFunc}
                                DialogProps={{
                                    className: clsx(classes.dateTimeModal, "date-time-modal"),
                                    onSetToday: () => {
                                        if (disableTodayButton) {
                                            return;
                                        }
                                        const currentDay = {
                                            year: moment().get("year"),
                                            month: moment().get("month"),
                                            date: moment().get("date"),
                                        };
                                        const todayDate = (value ? moment(value) : moment()).set(currentDay);
                                        onChange(todayDate);
                                        setOpen(false);
                                    },
                                }}
                                InputProps={{
                                    disabled: isDisabledInputProps,
                                }}
                                invalidDateMessage={focused ? "" : needValidation && "Invalid Date Format"}
                                format={dateFormat}
                                error={checkErrors()}
                                helperText={checkErrors()}
                            />
                            {inputVariant === INPUT_VARIANT_OUTLINED && (
                                <IconButton
                                    className="arrow-button"
                                    disabled={disabled}
                                    onClick={() => onArrowClick(true)}
                                >
                                    <ArrowRightIcon className="ui-icon-arrow" />
                                </IconButton>
                            )}
                        </div>
                        {fieldNote && <FieldNote fieldNote={fieldNote} isError={false} />}
                    </>
                </MuiPickersUtilsProvider>
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);
export const INPUT_VARIANT_OUTLINED = "outlined";

DateTimePicker.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    fieldNote: PropTypes.string,
    handleDateChange: PropTypes.func,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    handleBlur: PropTypes.func,
    input: PropTypes.object.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool.isRequired,
    needValidation: PropTypes.bool.isRequired,
    autoOk: PropTypes.bool.isRequired,
    dateOnly: PropTypes.bool,
    className: PropTypes.string,
    disablePast: PropTypes.bool.isRequired,
    disableTodayButton: PropTypes.bool.isRequired,
    variant: PropTypes.oneOf(["dialog", "inline", "static"]),
    inputVariant: PropTypes.oneOf(["standard", INPUT_VARIANT_OUTLINED, "filled"]),
};

DateTimePicker.defaultProps = {
    variant: "dialog",
    disablePast: false,
    disabled: false,
    needValidation: true,
    autoOk: false,
    inputVariant: "standard",
    dateFormat: DEFAULT_DATE_TIME_FORMAT,
    disableTodayButton: false,
};

export default DateTimePicker;
