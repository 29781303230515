import {
    confirmBundle as confirmAllRoutes,
    createBundleRequest,
    fetchBundleItemDetails,
    fetchBundlesList,
    fetchRoutes,
    recalculateBundle,
    updateRouteRequest,
    assignIntradayRouteRequest,
    assignNextDayRouteRequest,
    replaceTrucks,
} from "../dataServers/smartDispatch";
import { getDeployedTrucks as requestDeployedTrucks } from "../dataServers/trucks";
import moment from "moment";
import { PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../constants/global";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import { saveJobOrdersInRedux, updateMyFleetSelectedTrucks, resetJobOrderParams, resetHaulerParam } from "./jobOrders";
import formValueSelector from "redux-form/lib/formValueSelector";
import { JO_STATUS_PENDING_ID } from "../constants/maps";
import { ROUTE_JOBS } from "../routes/globalRoutes";
import { getJobOrderFiltersFromLocalStorage } from "../helpers/getJobOrderFiltersFromLocalStorage";
import { getJobOrders } from "../dataServers/jobOrder";
import { JOB_LIST_SMART_DISPATCH_FORM_ID } from "../containers/SmartDispatchPage";
import {
    selectActiveRegularJobsSortParam,
    selectBundleAssignData,
    selectCurrentBundle,
    selectBundlesFilters,
    selectCurrentBundleIAmOwner,
    selectCurrentBundleId,
    selectCurrentBundleSolution,
    selectDeployedTrucksData,
    selectJobsForTrucksData,
    selectProximityLocationId,
    selectRouteDetailsData,
    selectRoutesData,
    selectSelectedTruckSolutionList,
} from "../selectors/smartDispatchSelectors";
import { selectCompanyId, selectCompanyRegionsMeta } from "../selectors/index";
import { BUNDLE_UPDATED_MESSAGE, PROXIMITY_ORDER } from "../constants/strings";
import { actions } from "../reducers/smartDispatchReducer";
import { getNextPageForRequest, handleError } from "../helpers/global";
import { isValidDate } from "../helpers/date";
import { BUNDLES_FILTERS_FORM, SD_REGULAR_JOBS_LIST_FIELD, SD_REGULAR_JOBS_LIST_FORM } from "../constants/forms";
import {
    BUNDLE_ERROR,
    BUNDLE_FULLY_ASSIGNED,
    BUNDLE_AWAITING_CONFIRMATION,
    BUNDLE_INVALIDATED,
    BUNDLE_PARTIALLY_ASSIGNED,
    BUNDLE_PENDING,
    BUNDLE_READY_TO_ASSIGN,
} from "../components/bundles/BundlePills";
import { BUNDLE_DRAFT_MODE_FORM } from "../components/bundles/BundleDraftModeForm";

export const createBundle = (selectedJobOrders, isIntraday) => {
    return (dispatch, getState) => {
        const state = getState();
        const isDraft = formValueSelector(BUNDLE_DRAFT_MODE_FORM)(state, "isDraft");

        const requestData = {
            isIntraDay: !!isIntraday,
            jobOrders: selectedJobOrders,
            isDraft: isDraft,
        };

        dispatch(actions.smartDispatchIsLoadingUpdate(true));

        return createBundleRequest(requestData)
            .then(() => {
                dispatch(actions.showSmartDispatchSuccessMessage("Bundle created successfully"));
                dispatch(actions.updateAddToBundleJobList([]));
            })
            .catch((error) => {
                dispatch(actions.showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(error)]));
            })
            .finally(() => {
                dispatch(actions.smartDispatchIsLoadingUpdate(false));
            });
    };
};

export const selectAllJobs = () => {
    return (dispatch, getState) => {
        const state = getState();
        const allJobsCount = state.jobOrders.jobOrdersCount;
        const search = formValueSelector(JOB_LIST_SMART_DISPATCH_FORM_ID)(state, "search");
        let params = {
            count: allJobsCount,
            status: JO_STATUS_PENDING_ID,
            keywords: search,
        };

        params = getJobOrderFiltersFromLocalStorage(ROUTE_JOBS.SMART_DISPATCH, params);

        dispatch(actions.smartDispatchIsLoadingUpdate(true));

        getJobOrders(params)
            .then((response) => {
                const jobOrders = response.data;
                dispatch(actions.updateAddToBundleJobList(jobOrders.map((jo) => jo.id)));
                dispatch(saveJobOrdersInRedux(jobOrders));
            })
            .catch((e) => {
                dispatch(actions.showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(e)]));
            })
            .finally(() => {
                dispatch(actions.smartDispatchIsLoadingUpdate(false));
            });
    };
};

const updateSmartDispatchPageData = (dispatch, openedBundleId, updatedData) => {
    dispatch(actions.updatedBundleInList(openedBundleId, updatedData));
    dispatch(actions.showSmartDispatchSuccessMessage(BUNDLE_UPDATED_MESSAGE));
    dispatch(actions.myFleetNeedsToBeUpdatedChange(true));
};

export const assignToRoute = (assignData = null) => {
    return (dispatch, getState) => {
        const state = getState();
        const bundleId = selectCurrentBundleId(state);
        const isIntraDay = selectCurrentBundle(state).isIntraDay;
        const startDate = formValueSelector(BUNDLES_FILTERS_FORM)(state, "startDate");
        const reduxAssignData = selectBundleAssignData(state);
        const routeId = selectSelectedTruckSolutionList(state)[0];

        const body = assignData || reduxAssignData;

        const isAssignTruck = body?.assignments[0]?.truckId;

        const isIntraDayBundle = isIntraDay && isAssignTruck;

        dispatch(actions.smartDispatchIsLoadingUpdate(true));

        (isIntraDayBundle
            ? assignIntradayRouteRequest(bundleId, routeId, body)
            : assignNextDayRouteRequest(bundleId, body)
        )
            .then((response) => {
                if (isIntraDayBundle) {
                    updateSmartDispatchPageData(dispatch, bundleId, response.data);
                    dispatch(requestBundleDetails(bundleId));
                    dispatch(updateMyFleetSelectedTrucks([]));
                } else {
                    dispatch(actions.updateCurrentBundle(response.data));
                    updateSmartDispatchPageData(dispatch, bundleId, response.data);
                    dispatch(updateMyFleetSelectedTrucks([]));
                }
            })
            .catch((error) => {
                dispatch(actions.showSmartDispatchFailureMessage(PROCESS_SERVER_ERROR(error)));
            })
            .finally(() => {
                dispatch(actions.smartDispatchIsLoadingUpdate(false));
                isIntraDay && dispatch(getDeployedTrucks(false, { bundleId, jobsForDate: startDate }));
            });
    };
};

export const unassignRoute = (routeToUnassign) => {
    return (dispatch, getState) => {
        const { index, primeHauler, assignedTruck, subHauler } = routeToUnassign;
        const state = getState();
        const bundleId = selectCurrentBundleId(state);
        const iAmOwner = selectCurrentBundleIAmOwner(state);
        const companyId = selectCompanyId(state);

        const unassignRouteData = {
            index: index,
        };
        if (primeHauler && iAmOwner) {
            unassignRouteData.haulerId = null;
        }
        if (primeHauler?.id === companyId && subHauler) {
            unassignRouteData.haulerId = null;
        }
        if (assignedTruck) {
            unassignRouteData.truckId = null;
        }

        const data = {
            assignments: [unassignRouteData],
        };

        dispatch(actions.smartDispatchIsLoadingUpdate(true));

        return updateRouteRequest(bundleId, data)
            .then((response) => {
                dispatch(actions.updateCurrentBundle(response.data));
                updateSmartDispatchPageData(dispatch, bundleId, response.data);
            })
            .catch((error) => {
                dispatch(actions.showSmartDispatchFailureMessage(PROCESS_SERVER_ERROR(error)));
            })
            .finally(() => {
                dispatch(actions.smartDispatchIsLoadingUpdate(false));
            });
    };
};

export const updateRouteSettings = (updatedData) => {
    return (dispatch, getState) => {
        const state = getState();
        const bundleId = selectCurrentBundleId(state);

        dispatch(actions.smartDispatchIsLoadingUpdate(true));
        return updateRouteRequest(bundleId, updatedData)
            .then(() => {
                dispatch(actions.showSmartDispatchSuccessMessage(BUNDLE_UPDATED_MESSAGE));
            })
            .catch((error) => {
                dispatch(actions.showSmartDispatchFailureMessage(PROCESS_SERVER_ERROR(error)));
            })
            .finally(() => {
                dispatch(actions.smartDispatchIsLoadingUpdate(false));
            });
    };
};

export const getBundlesList = (concat, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const filters = selectBundlesFilters(state);
        const isDraft = formValueSelector(BUNDLE_DRAFT_MODE_FORM)(state, "isDraft");

        if (!isValidDate(filters?.startDate)) return;
        const bundlesList = state.smartDispatch.bundlesList;
        const currentPage = Math.ceil(bundlesList?.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(bundlesList) ? +currentPage + 1 : 1;
        dispatch(actions.bundlesListLoading());

        return fetchBundlesList({
            page: concat ? nextPage : 1,
            order: "-id",
            isDraft,
            ...filters,
        })
            .then((response) => {
                const fetchedBundles = _.isEmpty(response.data) ? [] : response.data;
                dispatch(
                    actions.bundlesListLoadingSuccess({
                        meta: response.meta,
                        data: concat ? [...bundlesList, ...fetchedBundles] : fetchedBundles,
                    }),
                );
            })
            .catch((error) => {
                dispatch(actions.bundlesListLoadingFailure(error.message));
            });
    };
};

export const requestNoneSmartDispatchJobs = (concat) => async (dispatch, getState) => {
    try {
        const state = getState();

        const { loading } = selectCompanyRegionsMeta(state);
        if (loading) return;

        const date = formValueSelector(BUNDLES_FILTERS_FORM)(state, "startDate");
        if (!isValidDate(date)) return;

        dispatch(actions.requestJobsForTrucks());
        const filters = formValueSelector(SD_REGULAR_JOBS_LIST_FORM)(state, SD_REGULAR_JOBS_LIST_FIELD);
        const sortingParam = selectActiveRegularJobsSortParam(state);
        const locationId = selectProximityLocationId(state);

        const jobs = selectJobsForTrucksData(state) || [];
        const nextPage = getNextPageForRequest(jobs);
        const params = {
            page: concat ? nextPage : 1,
            startDate: date,
            endDate: date,
            status: filters?.status,
            regions: filters?.regions,
            smartDispatch: false,
            withTrucks: null,
            estimate: true,
        };
        if (filters?.keywords) params.keywords = filters.keywords;

        if (sortingParam) {
            params.order = PROXIMITY_ORDER;
            params.proximityTo = sortingParam;
            params.locationId = locationId;
        }

        const { data, meta } = await getJobOrders(params);
        dispatch(actions.resultJobsForTrucks({ data, concat, meta }));
    } catch (error) {
        dispatch(actions.resultJobsForTrucks(handleError(error)));
        dispatch(actions.resetJobsForTrucksMeta());
    }
};

export const requestRoutes = (concat) => async (dispatch, getState) => {
    try {
        const date = formValueSelector(BUNDLES_FILTERS_FORM)(getState(), "startDate");
        if (!isValidDate(date)) return;
        dispatch(actions.requestRoutes());
        const filters = selectBundlesFilters(getState());
        const routes = selectRoutesData(getState()) || [];
        const nextPage = getNextPageForRequest(routes);
        const params = {
            startDate: moment(date).format(SERVER_DATE_FORMAT),
            endDate: moment(date).format(SERVER_DATE_FORMAT),
            page: concat ? nextPage : 1,
            regions: filters?.regions,
            perPage: 20,
        };
        if (filters?.keywords) params.keywords = filters.keywords;
        const { data, meta } = await fetchRoutes(params);
        dispatch(actions.resultRoutes({ data, concat, meta }));
    } catch (error) {
        dispatch(actions.resultRoutes(handleError(error)));
        dispatch(actions.resetRoutesMeta());
    }
};

const getRouteData = (bundleData, routeIndex) => {
    const solution = bundleData.solution.filter((route) => [routeIndex].includes(route.index))[0];
    const data = {
        jobOrders:
            solution &&
            solution.routeItems &&
            solution.routeItems
                .map((routeItem, index) => {
                    const jobOrderData = bundleData.jobOrders.find((jobOrder) => jobOrder.id === routeItem.jobId);
                    if (jobOrderData) {
                        return {
                            ...jobOrderData,
                            index,
                            orderingIndex: routeItem.orderingIndex,
                            routeItemId: routeItem.routeItemId,
                            loadCount: routeItem.loadCount,
                            totalLoadsCount: routeItem.totalLoadsCount,
                            manuallyAdded: routeItem.manuallyAdded,
                        };
                    }
                    return null;
                })
                .filter((route) => route !== null),
        assignedTruck: solution?.assignedTruck,
        primeHauler: solution?.primeHauler,
        id: solution.id,
        solutionIndex: routeIndex,
        bundleId: bundleData.problemId,
        iAmOwner: bundleData.iAmOwner,
    };

    return data;
};

export const requestRouteDetails = (bundleId, routeIndex) => async (dispatch, getState) => {
    try {
        dispatch(actions.requestRouteDetails());
        const { data: bundleData } = await fetchBundleItemDetails(bundleId);
        const data = getRouteData(bundleData, routeIndex);
        dispatch(actions.resultRouteDetails(data));
    } catch (error) {
        dispatch(actions.resultRouteDetails(handleError(error)));
        dispatch(actions.resetRouteDetailsMeta());
    }
};

export const requestBundleDetails = (bundleId) => async (dispatch, getState) => {
    try {
        const { data } = await fetchBundleItemDetails(bundleId);
        const { status: bundleStatus } = data;

        if (bundleStatus === BUNDLE_PENDING) {
            dispatch(actions.showSmartDispatchFailureMessage("Bundle is not ready"));
        } else if (bundleStatus === BUNDLE_INVALIDATED || bundleStatus === BUNDLE_ERROR) {
            dispatch(actions.showSmartDispatchFailureMessage("Bundle is invalid"));
        } else if (
            bundleStatus === BUNDLE_READY_TO_ASSIGN ||
            bundleStatus === BUNDLE_PARTIALLY_ASSIGNED ||
            bundleStatus === BUNDLE_FULLY_ASSIGNED ||
            bundleStatus === BUNDLE_AWAITING_CONFIRMATION
        ) {
            dispatch(actions.updateCurrentBundle(data));

            return true;
        }
    } catch (error) {}
};

export const setJobToRoute =
    ({ bundleId, jobId, truckId, index, loadsCount }) =>
    async (dispatch, getState) => {
        dispatch(actions.requestRouteDetails());
        try {
            const jobToAdd = { id: jobId };
            if ((loadsCount && +loadsCount) || +loadsCount === 0) jobToAdd.loadsCount = +loadsCount;
            const data = {
                assignments: [
                    {
                        index,
                        jobsToAdd: [jobToAdd],
                    },
                ],
            };

            const { data: bundleData } = await updateRouteRequest(bundleId, data);
            const result = getRouteData(bundleData, index);
            dispatch(actions.resultRouteDetails(result));
        } catch (error) {
            dispatch(actions.resultRouteDetails(handleError(error)));
            dispatch(actions.resetRouteDetailsMeta());
        }
    };

export const moveJob = (params) => async (dispatch, getState) => {
    try {
        if (params.createCall) {
            const { jobOrders } = selectRouteDetailsData(getState());
            const { bundleId, index, job } = params;
            if (_.isUndefined(job.moveIndex)) return;
            const dragIndex = job.moveIndex - 1;
            const inertAfter = jobOrders?.filter((i) => i.index === dragIndex)[0]?.routeItemId || null;
            dispatch(actions.requestRouteDetails());
            const data = {
                assignments: [
                    {
                        index,
                        jobsToUpdate: [
                            {
                                id: Math.floor(Math.random() * 1000),
                                routeItemId: job.routeItemId,
                                insertAfter: inertAfter,
                            },
                        ],
                    },
                ],
            };
            const { data: bundleData } = await updateRouteRequest(bundleId, data);
            const result = getRouteData(bundleData, index);
            dispatch(actions.resultRouteDetails(result));
        } else {
            dispatch(actions.moveJobPosition(params));
        }
    } catch (error) {
        dispatch(actions.resultRouteDetails(handleError(error)));
        dispatch(actions.resetRouteDetailsMeta());
    }
};

export const insertJob = (bundleId, index, job) => async (dispatch, getState) => {
    dispatch(actions.requestRouteDetails());
    try {
        const { jobOrders } = selectRouteDetailsData(getState());
        const afterItem = jobOrders?.filter((i) => i.index === job.insertAfter)[0];
        const insertAfter = afterItem ? afterItem.routeItemId : null;
        const jobToAdd = { id: job.id, insertAfter };
        if ((job.loadsCount && +job.loadsCount) || +job.loadsCount === 0) jobToAdd.loadsCount = +job.loadsCount;
        const data = {
            assignments: [
                {
                    index,
                    jobsToAdd: [jobToAdd],
                },
            ],
        };
        const { data: bundleData } = await updateRouteRequest(bundleId, data);
        const result = getRouteData(bundleData, index);
        dispatch(actions.newJobAdded(job.id));
        dispatch(actions.resultRouteDetails(result));
        setTimeout(() => dispatch(actions.newJobAdded(null)), 3000);
    } catch (error) {
        dispatch(actions.resultRouteDetails(handleError(error)));
        dispatch(actions.resetRouteDetailsMeta());
    }
};

export const removeJobFromRoute =
    ({ bundleId, jobId, index, isBundleView }) =>
    async (dispatch, getState) => {
        try {
            dispatch(actions.requestRouteDetails());
            const data = {
                assignments: [
                    {
                        index,
                        jobsToRemove: [jobId],
                    },
                ],
            };
            const { data: bundleData } = await updateRouteRequest(bundleId, data);
            const result = getRouteData(bundleData, index);
            dispatch(actions.resultRouteDetails(result));
            isBundleView && dispatch(actions.updateCurrentBundle(bundleData));
        } catch (error) {
            dispatch(actions.resultRouteDetails(handleError(error)));
            dispatch(actions.resetRouteDetailsMeta());
        }
    };

export const unassignTruckFromRoute =
    ({ bundleId, index }) =>
    async (dispatch, getState) => {
        try {
            dispatch(actions.requestRoutes());
            const data = {
                assignments: [
                    {
                        index,
                        truckId: null,
                    },
                ],
            };
            await updateRouteRequest(bundleId, data);
            dispatch(requestRoutes());
        } catch (error) {
            dispatch(actions.showSmartDispatchFailureMessage(PROCESS_SERVER_ERROR(error)));
            dispatch(actions.resetRoutesMeta());
        }
    };

export const mockup = [
    {
        activeSuspendPeriod: null,
        company: {
            accountType: 2,
            archived: false,
            hasCard: false,
            id: 1512,
            name: "TestCompanyH",
            stripeId: null,
            type: 2,
            validated: true,
        },
        currentDriver: null,
        deviceName: "Name",
        distance: null,
        iAmOwner: true,
        id: 8527,
        isArchived: false,
        isGranted: false,
        jobsNumberOnDate: 0,
        location: null,
        progress: 0,
        status: 1,
        truckIsBusy: false,
        truckName: "Name",
        truckShiftAllowed: false,
        truckType: "Insulated Tri-Axle",
    },
    {
        activeSuspendPeriod: null,
        company: {
            accountType: 2,
            archived: false,
            hasCard: false,
            id: 1512,
            name: "TestCompanyH",
            stripeId: null,
            type: 2,
            validated: true,
        },
        currentDriver: null,
        deviceName: "New truck",
        distance: null,
        iAmOwner: true,
        id: 10094,
        isArchived: false,
        isGranted: false,
        jobsNumberOnDate: 0,
        location: null,
        progress: 0,
        status: 1,
        truckIsBusy: false,
        truckName: "New truck",
        truckShiftAllowed: false,
        truckType: "Tandem",
    },
    {
        activeSuspendPeriod: null,
        company: {
            accountType: 2,
            archived: false,
            hasCard: false,
            id: 1522,
            name: "TestCompany1",
            stripeId: null,
            type: 1,
            validated: true,
        },
        currentDriver: null,
        deviceName: "Truck2C1",
        distance: null,
        iAmOwner: false,
        id: 9861,
        isArchived: false,
        isGranted: true,
        jobsNumberOnDate: 0,
        location: null,
        progress: 0,
        status: 1,
        truckIsBusy: false,
        truckName: "Truck2C1",
        truckShiftAllowed: false,
        truckType: "Tandem",
    },
];

export const getDeployedTrucks =
    (concat, qparams = {}) =>
    async (dispatch, getState) => {
        try {
            dispatch(actions.requestDeployedTrucks());
            const trucks = selectDeployedTrucksData(getState()) || [];
            const nextPage = getNextPageForRequest(trucks);
            const params = {
                ...qparams,
                jobsForDate: moment(qparams.jobsForDate).format("YYYY-MM-DD"),
                page: concat ? nextPage : 1,
                perPage: 20,
                order: "name",
            };
            const { data, meta } = await requestDeployedTrucks(params);

            const trucksData = data
                .map((i) => ({
                    ...i,
                    deviceName: i.truckName,
                    truckType: i.truckType && i.truckType.name,
                }))
                .sort((a, b) => a.deviceName - b.deviceName);

            dispatch(actions.resultDeployedTrucks({ data: trucksData, meta, concat }));
        } catch (error) {
            dispatch(actions.resultDeployedTrucks(handleError(error)));
        }
    };

export const closeBundleAfterSuccessAction = () => (dispatch) => {
    dispatch(getBundlesList());
    dispatch(resetJobOrderParams());
    dispatch(resetHaulerParam());
    dispatch(updateCurrentBundle(null));
};

export const confirmBundle = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const routes = selectCurrentBundleSolution(state);
        const bundleId = selectCurrentBundleId(state);
        const confirmedRoutes = routes.map((item) => ({
            decision: "accepted",
            routeId: item.id,
        }));
        await confirmAllRoutes(bundleId, [...confirmedRoutes]);
        await dispatch(closeBundleAfterSuccessAction());
    } catch (error) {
        dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(error)]));
    }
};

export const rejectBundle = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const routes = selectCurrentBundleSolution(state);
        const bundleId = selectCurrentBundleId(state);
        const confirmedRoutes = routes.map((item) => ({
            decision: "rejected",
            routeId: item.id,
        }));
        await confirmAllRoutes(bundleId, [...confirmedRoutes]);
        await dispatch(closeBundleAfterSuccessAction());
    } catch (error) {
        dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(error)]));
    }
};

export const recalculateSDBundle = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const bundleId = selectCurrentBundleId(state);
        const body = {};
        await recalculateBundle(bundleId, body);
        await dispatch(closeBundleAfterSuccessAction());
    } catch (error) {
        dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(error)]));
    }
};

export const replaceTrucksAction = (oldTruck, newTruck) => async (dispatch, getState) => {
    try {
        const state = getState();
        const bundleId = selectCurrentBundleId(state);
        const body = {
            replaceTrucks: {
                oldTruckId: oldTruck.id,
                newTruckId: newTruck.id,
            },
        };
        await replaceTrucks(bundleId, body);
        await dispatch(actions.replaceDeployedTruck({ oldTruck, newTruck }));
        await dispatch(actions.myFleetNeedsToBeUpdatedChange(true));
    } catch (error) {
        dispatch(showSmartDispatchFailureMessage([PROCESS_SERVER_ERROR(error)]));
    }
};

export const {
    addJobToBundleSelected,
    removeJobFromBundleSelected,
    updateAddToBundleJobList,
    showSmartDispatchFailureMessage,
    showSmartDispatchSuccessMessage,
    smartDispatchIsLoadingUpdate,
    setSmartDispatchConfirmationModal,
    setSmartDispatchHaulerAssignConfirmationModal,
    bundlesListLoading,
    bundlesListLoadingSuccess,
    bundlesListLoadingFailure,
    updateCurrentBundle,
    updateSelectedTruckSolutionList,
    addSolutionToSelectedList,
    removeSolutionFromSelectedList,
    updateBundleFilters,
    updateActiveSmartDispatchTab,
    updatedBundleInList,
    myFleetNeedsToBeUpdatedChange,
    updateAssignBundleData,
    updateHaulerToAssign,
    updateTruckRouteLocation,
    resetJobsForTrucks,
    resetRoutes,
    resetRouteDetails,
    setRoute,
    resetSelectedRoute,
    updateActiveRegularJobsSortParam,
    moveJobPosition,
    resetDeyployedTrucks,
} = actions;
