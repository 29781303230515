import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import SearchAndFilters, {
    DUPLICATE_TICKET_OPTION_ID,
    FILTERS_DUPLICATE_ID,
    FILTERS_SIGNATURE_ID,
    FILTERS_REGIONS_ID,
    FILTERS_STATUS_ID,
    FILTERS_TICKET_SOURCE_ID,
    FILTERS_UOM_ID,
    UNIQUE_TICKET_OPTION_ID,
} from "../global/searchAndFilters";
import {
    IS_INSPECTOR_USER,
    TICKET_SOURCE_AIRTICKET,
    TICKET_SOURCE_MANUAL,
    TICKET_SOURCE_SUBTICKET,
    TICKET_SOURCES,
    TICKET_STATUS_CLOSED,
    TICKET_STATUS_FINALLY_RECONCILED,
    TICKET_STATUS_INTERRUPTED,
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RECONCILED,
} from "../../constants/maps";
import { selectCompanyRegions, selectUnitOfMeasures } from "../../selectors/index";
import { fetchTicketsSearchOptions } from "../../dataServers/tickets";
import { formValueSelector } from "redux-form";
import PredictiveSearchOptionLabel from "../global/PredictiveSearchOptionLabel";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../constants/global";
import { cleanPredictiveParamOptionString, TICKET_CATEGORY_NAME } from "../../helpers/predictiveSearchHelpers";
import Axios from "axios";

let requestPromise;
let cancelPromise = null;

const TicketFilters = (props) => {
    const {
        form,
        updateListByFilters,
        resetToDefaultHandler,
        account,
        unitOfMeasures,
        clearSelectedFilters,
        ticketsFilters,
    } = props;
    const userRegion = useSelector((state) => state.account.region);
    const regions = useSelector(selectCompanyRegions);

    const { startDate, endDate } = useSelector((state) => formValueSelector(form)(state, "startDate", "endDate"));

    const [initialFilters, setInitialFilters] = useState(
        userRegion
            ? {
                  [FILTERS_REGIONS_ID]: {
                      [userRegion.id]: true,
                  },
              }
            : {},
    );

    const loadPredictiveSearchOptions = (inputValue, { params = {}, loadedCount }) => {
        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        const searchOptionsParams = {
            ...ticketsFilters,
            page: params.page,
            startDate: moment(startDate).format(SERVER_DATE_FORMAT),
            endDate: moment(endDate).format(SERVER_DATE_FORMAT),
            keywords: cleanPredictiveParamOptionString(`+${inputValue}`),
        };

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        if (searchOptionsParams.keywords === "+") {
            searchOptionsParams.keywords = "";
        }

        requestPromise = fetchTicketsSearchOptions({ ...searchOptionsParams, cancelToken });

        return requestPromise.then(({ data, meta }) => {
            const options = data.map((option) => {
                const { category, value } = option;
                return {
                    value: `${category}: ${value}`,
                    label: <PredictiveSearchOptionLabel categoryName={TICKET_CATEGORY_NAME[category]} value={value} />,
                    category: category,
                    predictiveSearchOptionValue: value,
                };
            });

            return {
                options: options,
                hasMore: meta.count > (loadedCount || options.length),
                page: meta.page,
            };
        });
    };

    const [filterList, setFilterList] = useState([
        {
            title: "Ticket Type",
            filterKey: FILTERS_TICKET_SOURCE_ID,
            filters: [
                {
                    label: TICKET_SOURCES[TICKET_SOURCE_AIRTICKET],
                    id: TICKET_SOURCE_AIRTICKET,
                },
                {
                    label: TICKET_SOURCES[TICKET_SOURCE_MANUAL],
                    id: TICKET_SOURCE_MANUAL,
                },
                {
                    label: TICKET_SOURCES[TICKET_SOURCE_SUBTICKET],
                    id: TICKET_SOURCE_SUBTICKET,
                },
            ],
        },
        {
            title: "Ticket Status",
            filterKey: FILTERS_STATUS_ID,
            filters: [
                {
                    label: "Open",
                    id: TICKET_STATUS_OPEN,
                },
                {
                    label: "Closed",
                    id: TICKET_STATUS_CLOSED,
                },
                {
                    label: "Reconciled",
                    id: `${TICKET_STATUS_RECONCILED},${TICKET_STATUS_FINALLY_RECONCILED}`,
                },
                {
                    label: "Closed by system",
                    id: TICKET_STATUS_INTERRUPTED,
                },
            ],
        },
        {
            title: "Duplicates",
            filterKey: FILTERS_DUPLICATE_ID,
            filters: [
                {
                    label: "Duplicate",
                    id: DUPLICATE_TICKET_OPTION_ID,
                },
                {
                    label: "Unique",
                    id: UNIQUE_TICKET_OPTION_ID,
                },
            ],
        },
        {
            title: "Signature",
            filterKey: FILTERS_SIGNATURE_ID,
            filters: [
                {
                    label: "Signed",
                    id: 1,
                },
                {
                    label: "Unsigned",
                    id: 0,
                },
            ],
        },
    ]);

    useEffect(() => {
        const updatedFilters = [...filterList];
        if (!_.isEmpty(regions)) {
            updatedFilters.push({
                title: "Regions",
                filterKey: FILTERS_REGIONS_ID,
                filters: regions.map((item) => ({
                    label: item.regionName,
                    id: item.id,
                })),
            });
        }
        if (!_.isEmpty(unitOfMeasures)) {
            updatedFilters.unshift({
                title: "UOM",
                filterKey: FILTERS_UOM_ID,
                filters: unitOfMeasures.map((item) => ({
                    label: item.label,
                    id: item.value,
                })),
            });
        }
        if (userRegion) {
            setInitialFilters({
                ...initialFilters,
                [FILTERS_REGIONS_ID]: {
                    [userRegion.id]: true,
                },
            });
        }

        setFilterList(_.uniqBy(updatedFilters, "filterKey"));
    }, [regions, unitOfMeasures]);

    return (
        <SearchAndFilters
            chooseOneFilterOptionList={[FILTERS_DUPLICATE_ID, FILTERS_SIGNATURE_ID]}
            key={filterList.length}
            form={form}
            updateList={updateListByFilters}
            isPredictiveSearch={true}
            loadPredictiveSearchOptions={loadPredictiveSearchOptions}
            resetToDefaultHandler={resetToDefaultHandler}
            initialFilters={initialFilters}
            limitWidth={true}
            isResetToDefault
            rememberFilters={!clearSelectedFilters}
            filtersList={IS_INSPECTOR_USER(account.role) ? [] : filterList}
        />
    );
};

TicketFilters.propTypes = {
    account: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    resetToDefaultHandler: PropTypes.func,
    unitOfMeasures: PropTypes.array,
    clearSelectedFilters: PropTypes.bool,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        unitOfMeasures: selectUnitOfMeasures(state),
    }))(TicketFilters),
);
