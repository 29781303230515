import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Carousel from "react-material-ui-carousel";
import { getTicketTicPics, openTicketsTicPicksPDF } from "../../../dataServers/tickets";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Loader from "../../core/loader";
import ReconcileTicketForm, { RECONCILE_TICKET_FORM } from "./reconcileTicketForm";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import EditableImage from "./editableImage";
import TicPicSelectList from "../airTickets/ticpicSelectList";

const useStyles = makeStyles(() => ({
    ticpicContainer: {
        display: "flex",
        flexDirection: "row",
    },
    carouselWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 650,
        height: 400,

        "& .CarouselItem": {
            width: "80%",
        },
    },
    sliderWrapper: {
        width: "100%",
        maxHeight: 500,
        display: "flex",
        flexDirection: "row",

        "& .ticpic-selector-container": {
            paddingTop: "5px",
            margin: "10px",
        },

        "& .image-container": {
            width: "100%",
        },

        "& .decreased-image-container": {},

        "& h1": {
            textAlign: "center",
        },
    },
}));

const inlineStyles = {
    formWrapper: {
        maxWidth: 500,
        margin: "20px auto",
    },
};

const BulkReconcileForm = (props) => {
    const { tickets, closeModal, needLoadNewTickets } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTicPic, setCurrentTicPic] = useState(null);
    const [ticPicList, setTicPicList] = useState(null);
    const [isCropImage, setIsCropImage] = useState(false);
    const [changedTicPic, setChangedTicPic] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        if (!_.isEmpty(tickets)) {
            setCurrentTicket(tickets[0]);
        }
    }, []);

    useEffect(() => {
        if (currentTicket) {
            setCurrentTicPic(null);
            setIsLoading(true);
            setTicPicList(null);
            getTicketTicPics(currentTicket.id)
                .then((data) => {
                    const ticPics = data.data;
                    const firstTicPic = ticPics[0];

                    if (ticPics.length > 0 && !_.isEmpty(firstTicPic.attachments)) {
                        setCurrentTicPic(firstTicPic.attachments[0]);
                        if (ticPics.length > 1) {
                            const updatedTicPicList = ticPics.map((ticPic) => {
                                return {
                                    ...ticPic.attachments[0],
                                };
                            });
                            setTicPicList(updatedTicPicList);
                        }
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [currentTicket]);

    const onChange = (index, active) => {
        if (currentIndex === tickets.length - 1) {
            needLoadNewTickets(true);
        }

        if (active === currentIndex) {
            setCurrentIndex(index);
            setCurrentTicket(tickets[index]);
            setChangedTicPic(null);
        }
    };

    const handleSuccess = (isCancel) => {
        if (isCancel) {
            closeModal();
        }
        // Should bring up the next ticket
        if (!isCancel) {
            if (tickets.length <= +currentIndex + 1) {
                onChange(0, currentIndex);
            }
            if (tickets.length > +currentIndex + 1) {
                onChange(+currentIndex + 1, currentIndex);
            }
        }
    };

    const storeFile = (file) => {
        setChangedTicPic(file);
    };

    const handleTicPicSelect = (ticPic) => {
        setCurrentTicPic(ticPic);
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {currentTicket ? (
                <React.Fragment>
                    <div className={clsx(classes.ticpicContainer)}>
                        <Carousel
                            autoPlay={false}
                            className={clsx(classes.carouselWrapper)}
                            navButtonsAlwaysVisible={true}
                            fullHeightHover={true}
                            onChange={onChange}
                            indicators={false}
                            index={currentIndex}
                        >
                            {tickets.map((ticket) => (
                                <div
                                    className={clsx(classes.sliderWrapper, isLoading && LOADER_WHITE_OVERLAY)}
                                    key={ticket.id}
                                >
                                    {ticPicList && (
                                        <div className={"ticpic-selector-container"}>
                                            <TicPicSelectList
                                                ticPicList={ticPicList}
                                                handleTicPicSelect={handleTicPicSelect}
                                            />
                                        </div>
                                    )}
                                    <div className={clsx(isLoading && LOADER_WHITE_OVERLAY, "image-container")}>
                                        {currentTicPic ? (
                                            currentTicPic.filename.includes(".pdf") ? (
                                                <button
                                                    type="button"
                                                    onClick={() => openTicketsTicPicksPDF(currentTicPic.fileLink)}
                                                    style={{ display: "block" }}
                                                    title="Click to open PDF file"
                                                >
                                                    <PictureAsPdfIcon />
                                                </button>
                                            ) : (
                                                <EditableImage
                                                    image={currentTicPic}
                                                    isCrop={isCropImage}
                                                    storeFile={storeFile}
                                                />
                                            )
                                        ) : isLoading ? (
                                            <Loader />
                                        ) : (
                                            <h1>No picture</h1>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div style={inlineStyles.formWrapper}>
                        <h1 className="title">Reconcile Ticket #{currentTicket.id}</h1>
                        <ReconcileTicketForm
                            ticket={currentTicket}
                            handleSuccess={handleSuccess}
                            changedTicPic={changedTicPic}
                            setIsCropImage={(value) => setIsCropImage(value)}
                            closeModal={closeModal}
                            form={RECONCILE_TICKET_FORM}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <Loader />
            )}
        </div>
    );
};

BulkReconcileForm.propTypes = {
    tickets: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    needLoadNewTickets: PropTypes.func.isRequired,
};

export default withRouter(
    compose(
        connect((state) => ({
            tickets: state.tickets.tickets,
        })),
    )(BulkReconcileForm),
);
