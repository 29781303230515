import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Remove } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import { deleteHauler, getPrefferedHaulers, updateHauler } from "../../../actions/settings";
import { selectPreferredHaulers, selectPreferredMeta } from "../../../selectors";
import TableWithStickyHeader from "../../core/tableWithStickyHeader";
import SettingsSearch from "../settingsSearch";
import EditableInput, { EDITABLE_FIELD_NAME } from "../../core/EditableInput";
import ConfirmationModal from "../../core/confirmationModal";

export const EDIT_HAULER_RATE_FORM_NAME = (id) => `EDIT_HAULER_RATE_FORM-${id}`;

const PREFERRED_HAULERS_COLUMNS = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "defaultRate",
        label: "Default Rate ($)",
    },
    {
        id: "action",
        label: "action",
    },
];

export const HAULERS_FILTERS_FORM = "haulersFiltersForm";

const PreferredHaulersList = (props) => {
    const { classes, handleOpenHaulerForm, updateListByFilters, appliedFilters } = props;

    const [haulerToDelete, setHaulerToDelete] = useState(null);

    const dispatch = useDispatch();

    const companies = useSelector(selectPreferredHaulers);

    const { loading } = useSelector(selectPreferredMeta);

    useEffect(() => {
        if (!companies) {
            dispatch(getPrefferedHaulers());
        }
    }, []);

    const searchValue = appliedFilters && appliedFilters.keywords;

    const getMatchedName = (name, searchText) => name.toLowerCase().includes(searchText.trim().toLowerCase());

    const getMatchedData = (companies) => {
        const list = companies && companies.filter(({ name }) => getMatchedName(name, searchValue));

        return list;
    };

    const handleDeleteHaulerFromPreferred = (hauler) => {
        dispatch(deleteHauler(hauler));
        setHaulerToDelete(null);
    };

    const handleHaulerDefaultRateEdit = (hauler, rate) => dispatch(updateHauler({ ...hauler, defaultRate: rate }));

    const renderEditabeInput = (hauler) => (
        <div className={classes.editDefaultRateForm} key={hauler.id}>
            <EditableInput
                form={EDIT_HAULER_RATE_FORM_NAME(hauler.id)}
                handleSave={(rate) => handleHaulerDefaultRateEdit(hauler, rate)}
                initialValues={{ [EDITABLE_FIELD_NAME]: hauler.defaultRate }}
            />
        </div>
    );

    const renderAction = (hauler) => (
        <Grid container justify={"center"} style={{ minHeight: 50 }}>
            <Tooltip title="delete hauler from preferred">
                <IconButton onClick={() => setHaulerToDelete(hauler)}>
                    <Remove />
                </IconButton>
            </Tooltip>
        </Grid>
    );

    const getRows = () =>
        companies &&
        (searchValue ? getMatchedData(companies) : companies).map((hauler, index) => ({
            index,
            id: hauler.id,
            name: hauler.name,
            defaultRate: renderEditabeInput(hauler),
            action: renderAction(hauler),
        }));

    const getTableData = () => {
        return {
            rows: getRows(),
            columns: PREFERRED_HAULERS_COLUMNS,
        };
    };

    return (
        <>
            <Grid container direction="row" justify="space-between">
                <Grid item justify="flex-start">
                    <Button size="small" color="primary" onClick={() => handleOpenHaulerForm()}>
                        <AddCircleOutlineIcon />
                        Add hauler to preferred filter
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SettingsSearch
                        withoutSearchRules
                        timeout={500}
                        updateListByFilters={updateListByFilters}
                        form={HAULERS_FILTERS_FORM}
                    />
                </Grid>
            </Grid>
            {!_.isEmpty(companies) ? (
                <Grid item>
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        className={clsx(classes.haulersTable)}
                        columns={getTableData().columns}
                    />
                </Grid>
            ) : loading ? null : (
                <Grid container justify="center">
                    <h1 className="--text-center">No haulers match your search</h1>
                </Grid>
            )}

            <ConfirmationModal
                isOpen={!_.isEmpty(haulerToDelete)}
                question={"Are you sure you want to delete hauler from preferred filter?"}
                yesHandler={() => handleDeleteHaulerFromPreferred(haulerToDelete)}
                noHandler={() => setHaulerToDelete(null)}
            />
        </>
    );
};

export default PreferredHaulersList;
