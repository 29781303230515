import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { flushSync } from "react-dom";
import { change } from "redux-form";
import moment from "moment";

import { setLoading } from "../actions/tickets";
import { getTickets } from "../dataServers/tickets";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { handleError } from "../helpers/global";
import PredictiveSearchOptionLabel from "../components/global/PredictiveSearchOptionLabel";

export default function useSearchTickets(fields, form, { value, categoryName, labelValue }) {
    const [error, setError] = useState();
    const dispatch = useDispatch();
    const onSearch = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await getTickets(fields, false, fields);
            const dates = data.map((i) => moment(i.createdDate));
            const maxDate = moment.max(dates);
            const minDate = moment.min(dates);
            dispatch(change(form, "triggerClearFilters", true));
            dispatch(change(form, "startDate", moment.utc(minDate).format(SERVER_DATE_FORMAT)));
            dispatch(change(form, "endDate", moment.utc(maxDate).format(SERVER_DATE_FORMAT)));
            dispatch(
                change(form, "search", [
                    {
                        value,
                        label: <PredictiveSearchOptionLabel categoryName={categoryName} value={labelValue} />,
                        __isNew__: true,
                    },
                ]),
            );
        } catch (error) {
            flushSync(() => {
                setError(handleError(error));
            });
        } finally {
            setError();
            dispatch(setLoading(false));
        }
    };

    return [onSearch, error?.message];
}
