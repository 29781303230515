import React from "react";
import { Field } from "redux-form";
import { useDispatch } from "react-redux";

import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "./asyncAutocompleteComponent";
import { updatePerPageParamForMultiSelector } from "../../../helpers/global";
import { getDispatchersLoadOptions } from "../../../actions/notifications";

const DispatchersFieldSelector = ({
    name,
    onFieldChange,
    isMulti,
    isClearable,
    label,
    loadedList,
    validate,
    fieldNote,
    disabled,
    loadParams,
}) => {
    const dispatch = useDispatch();
    const loadOptions = async (inputValue, { params = {}, loadedCount }) => {
        const requestParams = updatePerPageParamForMultiSelector(params, loadedList);

        return await dispatch(getDispatchersLoadOptions(inputValue, { ...requestParams, ...loadParams }, loadedCount));
    };

    return (
        <Field
            name={name}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            loadOptions={loadOptions}
            component={AsyncAutocompleteComponent}
            isMulti={isMulti}
            isClearable={isClearable}
            label={label}
            onFieldChange={onFieldChange}
            validate={validate}
            fieldNote={fieldNote}
            disabled={disabled}
        />
    );
};

DispatchersFieldSelector.defaultProps = {
    loadParams: {},
};

export default DispatchersFieldSelector;
