import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allSubCompanies: [],
};

const subCompanies = createSlice({
    name: "subCompanies",
    initialState,
    reducers: {
        updateAllSubCompanies(state, { payload }) {
            state.allSubCompanies = payload;
        },
    },
});

export const { actions } = subCompanies;

export const selectAllSubCompaniesIdList = (state) =>
    state.subCompanies.allSubCompanies.map((subCompany) => subCompany.company.id);

export default subCompanies.reducer;
