import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import SuccessNotification from "../core/successNotification";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AppModal from "../core/modal";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { getCompanyRegions } from "../../dataServers/projects";
import { assignUserRegion, fetchCurrentUserData } from "../../dataServers/user";
import { selectAccount } from "../../selectors";
import { updateAllAccountDataInLocalStorage, updateDefaultRegionInSavedFilters } from "../../helpers/global";
import { updateReduxUserData } from "../../actions/globalActions";

const AssignUserRegionDialog = (props) => {
    const {
        regionDialogOpen,
        handleClose,
        user,
        form,
        formValues: { region },
        handleSubmit,
        onSubmit,
        account,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const dispatch = useDispatch();
    const loadRegions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getCompanyRegions(user.company.id).then((data) => {
            const results = data.data.map((i) => ({ ...i, value: i.id, label: i.regionName }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };
    useEffect(() => {
        dispatch(change(form, "region", user.region ? { label: user.region.regionName, value: user.region.id } : ""));
    }, [regionDialogOpen]);

    const updateSelfAssignedUserData = (newRegionId) => {
        fetchCurrentUserData().then((res) => {
            const currentUserData = res.data;

            updateAllAccountDataInLocalStorage(currentUserData);
            dispatch(updateReduxUserData(currentUserData));
            updateDefaultRegionInSavedFilters(newRegionId);
        });
    };

    const handleAssignUserRegion = () => {
        const { id } = user;

        const payload = {
            regionId: region ? region.value : null,
        };

        setLoading(true);

        assignUserRegion(id, payload)
            .then(() => {
                setIsHandledSuccessfully(true);
                setLoading(false);

                const newRegionId = region?.value;
                // new region self-assigned
                if (account.id === id && account.region?.id !== newRegionId) {
                    updateSelfAssignedUserData(newRegionId);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            })
            .finally(() => {
                handleClose();
                onSubmit();
            });
    };

    return (
        <AppModal isOpen={regionDialogOpen} form={ASSIGN_USER_REGION_FORM} closeModal={handleClose}>
            <form
                noValidate={true}
                onSubmit={handleSubmit(handleAssignUserRegion)}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            >
                {isLoading && <Loader />}
                <h2 className="title">ASSIGN REGION</h2>
                <Field
                    name="region"
                    loadOptions={loadRegions}
                    isClearable={true}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    placeholder="Select Region"
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(false) }} />}
        </AppModal>
    );
};

AssignUserRegionDialog.propTypes = {
    user: PropTypes.object.isRequired,
    regionDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object,
};

export const ASSIGN_USER_REGION_FORM = "assignUserRegionForm";

export default withRouter(
    compose(
        reduxForm({
            form: ASSIGN_USER_REGION_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "id", "region"),
                account: selectAccount(state),
            };
        }),
    )(AssignUserRegionDialog),
);
