import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import clsx from "clsx";
import { withRouter } from "react-router";
import { change, Field, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";

import { signInUser } from "../dataServers/user";
import {
    ROUTE_DASHBOARD,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_LOGIN,
    ROUTE_SING_UP_EXTERNAL,
    ROUTE_SING_UP_INTERNAL,
} from "../routes/globalRoutes";
import {
    authorizeUser,
    requestCompanyRegions,
    requestCompanyRegionsResult,
    requestRegions,
    requestRegionsResult,
    saveUsersRegions,
} from "../actions/globalActions";
import { getUnitsOfMeasure } from "../actions/unitsOfMeasure";
import { getTrailers } from "../actions/trailers";
import { PrimaryButton, PrimaryButtonGreen } from "../components/core/buttons/primaryButton";
import ErrorNotification from "../components/core/notification";
import Loader from "../components/core/loader";
import TextInputComponent from "../components/core/form/textInputComponent";
import { ButtonsGroup } from "../components/core/buttons/buttonsGroup";
import { SimpleLink } from "../components/core/link";
import { LOGIN_STYLES } from "../styles/reusableStyles";
import { Validation } from "../helpers/validation";
import { ENV_MODE_PROD, LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../constants/global";
import { ACCOUNT_DATA_STORAGE_KEY } from "../helpers/api";
import { IS_INSPECTOR_USER, TRUCKER_TYPE_DRIVER, USER_ROLE_RESTRICTED_CUSTOMER } from "../constants/maps";
import PasswordField from "../components/global/passwordField";
import { getCompanyRegions } from "../dataServers/projects";
import { makeStyles } from "@material-ui/core";
import { getAdditionalExternalRefs } from "../actions/additionalExternalRefs";

const useStyles = makeStyles((theme) => ({
    responsiveLoginForm: {
        [theme.breakpoints.down(600)]: {
            "& form": {
                minWidth: 0,
                padding: 0,
            },

            "& .login-form-button": {
                width: "45vw",
            },
        },
    },
}));

function Login(props) {
    const {
        handleSubmit,
        authorizeUserWithData,
        saveCompanyData,
        history,
        redirectFrom,
        history: {
            location: { state },
        },
        dispatch,
        form,
    } = props;
    const classes = LOGIN_STYLES();
    const localClasses = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setError] = useState("");

    const initUserData = (userData) => {
        authorizeUserWithData(userData);
        if (userData.company && !IS_INSPECTOR_USER(userData.role)) {
            saveCompanyData(userData.company.id, userData.role);
        }
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));

        if (state && state.username) {
            dispatch(change(form, "username", state.username));
        }
        if (data) {
            initUserData(data);
            redirectFrom
                ? redirectFrom === "/"
                    ? history.push(ROUTE_DASHBOARD)
                    : history.push(redirectFrom)
                : history.push(ROUTE_DASHBOARD);
        } else {
            history.push(ROUTE_LOGIN);
        }
    }, []);

    const loginUser = (values) => {
        setLoading(true);

        signInUser(values)
            .then((data) => {
                const accountData = {
                    ...data.data,
                    token: data.authToken,
                };
                if (accountData.truckerType === TRUCKER_TYPE_DRIVER) {
                    return setError(["Drivers cannot use web app"]);
                }

                setLoading(true);
                localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(accountData));
                initUserData(accountData);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const createUserRedirect = () => {
        if (process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD) {
            history.push(ROUTE_SING_UP_EXTERNAL);
        } else {
            history.push(ROUTE_SING_UP_INTERNAL);
        }
    };

    return (
        <div className={clsx(classes.loginStyles, localClasses.responsiveLoginForm, isLoading && LOADER_WHITE_OVERLAY)}>
            {!!errorMessage && <ErrorNotification message={errorMessage} config={{ onClose: () => setError(null) }} />}
            {isLoading && <Loader />}
            <div className={classes.uiIconLogo} />
            <br />
            <br />
            <div>
                <form noValidate={true} onSubmit={handleSubmit(loginUser)}>
                    <br />
                    <Field
                        type="text"
                        validate={[Validation.required]}
                        name="username"
                        needShowEndAdornment={false}
                        label="Username"
                        component={TextInputComponent}
                    />
                    <br />
                    <PasswordField />
                    <br />
                    <div>
                        <ButtonsGroup className="buttons-set">
                            <PrimaryButton type="submit" size={"large"} className="login-form-button">
                                Login
                            </PrimaryButton>
                            <PrimaryButtonGreen
                                type="button"
                                size={"large"}
                                className="login-form-button"
                                onClick={createUserRedirect}
                            >
                                Sign Up
                            </PrimaryButtonGreen>
                        </ButtonsGroup>
                        <br />
                        <Grid container>
                            <Grid item xs={6} className="--text-center">
                                <SimpleLink className="-primary-color" to={ROUTE_FORGOT_PASSWORD}>
                                    Forgot Password
                                </SimpleLink>
                            </Grid>
                        </Grid>
                    </div>
                    <br />
                </form>
            </div>
        </div>
    );
}

Login.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    authorizeUserWithData: PropTypes.func.isRequired,
    saveCompanyData: PropTypes.func.isRequired,
    redirectFrom: PropTypes.string,
    history: PropTypes.object.isRequired,
};

Login = connect(
    (state) => {
        return {
            account: state.account,
        };
    },
    (dispatch) => ({
        authorizeUserWithData: (accountData) => {
            dispatch(authorizeUser(accountData));
        },
        saveCompanyData: (companyId, role) => {
            const isRestrictedCustomer = role === USER_ROLE_RESTRICTED_CUSTOMER;
            dispatch(requestRegions());
            dispatch(requestCompanyRegions());
            dispatch(getUnitsOfMeasure({ companyId }));
            dispatch(getAdditionalExternalRefs());
            if (!isRestrictedCustomer) {
                dispatch(getTrailers());
                getCompanyRegions(companyId).then((data) => {
                    dispatch(requestCompanyRegionsResult(data.data));
                    const result = data.data;
                    if (!_.isEmpty(result)) {
                        dispatch(
                            saveUsersRegions(
                                result.map((item) => ({
                                    name: item.regionName,
                                    id: item.id,
                                })),
                            ),
                        );
                    }
                    dispatch(requestRegionsResult());
                });
            }
        },
    }),
)(Login);

export default withRouter(
    reduxForm({
        form: "loginUser",
    })(Login),
);
