import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import { saveCurrentJobOrderIdInRedux, saveJobOrdersInRedux } from "../../../actions/jobOrders";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import moment from "moment";
import SearchAndFilters, {
    FILTERS_JOB_DRAFTS,
    FILTERS_JOB_IS_TEMPLATED,
    FILTERS_JOB_REQUESTS,
    FILTERS_REGIONS_ID,
    FILTERS_STATUS_ID,
    GET_USER_INITIAL_FILTERS,
    JOB_BOARD_INITIAL_FILTERS,
} from "../../global/searchAndFilters";
import { dispatchJobBoardRoute } from "../../../routes/globalRoutes";
import _ from "lodash";
import { DividerThin } from "../../core/divider";
import { PAGE_ACTIONS_STYLES } from "../../../styles/reusableStyles";
import {
    JO_DRAFT_ID,
    JO_REQUEST_ID,
    JO_STATUS_ACTIVE_ID,
    JO_STATUS_COMPLETED_ID,
    JO_STATUS_NOT_STARTED_ID,
    JO_STATUS_PENDING_ID,
    JO_TEMPLATED_ID,
} from "../../../constants/maps";
import { selectCompanyRegions, selectUsesSplitJob } from "../../../selectors/index";
import { JOBS_LIST_FORM_ID } from "../../../containers/DispatchJobOrders";
import { JOBS_BOARD_LIST_FORM_ID } from "../../../containers/DispatchJobBoardItem";
import { SERVER_DATE_FORMAT } from "../../../constants/global";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { makeStyles } from "@material-ui/core";
import { JOB_LIST_SMART_DISPATCH_FORM_ID } from "../../../containers/SmartDispatchPage";

export const IS_ONLY_START_DATA = (form) =>
    form === JOBS_LIST_FORM_ID || form === JOBS_BOARD_LIST_FORM_ID || form === JOB_LIST_SMART_DISPATCH_FORM_ID;

const jobsFilterFormStyles = makeStyles((theme) => ({
    jobsFilterForm: {
        "& .tomorrow, & .today": {
            width: "auto",
        },
        "& .buttons-wrap": {
            [theme.breakpoints.down("xs")]: {
                marginTop: 10,
            },
        },
        "& .hide-filters-border": {
            borderBottom: 0,
        },
    },
}));

const getInitialFiltersList = (isSmartDispatchPage, isJobBoardPage, initializedFilters, usesSplitJob) => {
    if (initializedFilters) return initializedFilters;
    let initialFilters = [
        {
            title: "Job Status",
            filterKey: FILTERS_STATUS_ID,
            filters: [
                {
                    label: "Active",
                    id: JO_STATUS_ACTIVE_ID,
                },
                {
                    label: "Not Started",
                    id: JO_STATUS_NOT_STARTED_ID,
                },
                {
                    label: "Pending",
                    id: JO_STATUS_PENDING_ID,
                },
                {
                    label: "Closed",
                    id: JO_STATUS_COMPLETED_ID,
                },
            ],
        },
        {
            title: "Job Templates",
            filterKey: FILTERS_JOB_IS_TEMPLATED,
            filters: [
                {
                    label: "Template",
                    id: JO_TEMPLATED_ID,
                },
            ],
        },
        {
            title: "Job Requests",
            filterKey: FILTERS_JOB_REQUESTS,
            filters: [
                {
                    label: "Request",
                    id: JO_REQUEST_ID,
                },
            ],
        },
        {
            title: "Job Drafts",
            filterKey: FILTERS_JOB_DRAFTS,
            filters: [
                {
                    label: "Draft",
                    id: JO_DRAFT_ID,
                },
            ],
        },
    ];

    if (isSmartDispatchPage) {
        initialFilters = initialFilters.filter((i) => i.filterKey !== FILTERS_STATUS_ID);
    }

    if (isJobBoardPage || isSmartDispatchPage) {
        initialFilters = initialFilters.filter((i) => i.filterKey !== FILTERS_JOB_IS_TEMPLATED);
    }

    if (!usesSplitJob || isJobBoardPage || isSmartDispatchPage) {
        initialFilters = initialFilters.filter((i) => i.filterKey !== FILTERS_JOB_DRAFTS);
    }

    return initialFilters;
};

const JobOrderFiltersForm = React.memo(
    (props) => {
        const {
            isListView,
            pageActionsClassName,
            dateRangeSize,
            form,
            history,
            dispatch,
            [START_DATE_NAME]: startDate,
            dataLoadedFor,
            isLoading,
            currentJobOrder,
            account,
            isJobBoardPage,
            isSmartDispatchPage,
            filtersOffset,
            handleDateChange,
            minDate,
            disabledLeftArrow,
            history: {
                location: { state: locationParams },
            },
            updateFiltersHandler,
            updateListByFilters,
            showFilters,
            resetActiveJobOrders,
            defaultFilter,
            regions,
            containerElementSize,
            initializedFilters,
            withoutDatesRange,
            isResetToDefault,
            withoutEmptySearch,
            autoFocusOnSearch,
            handleOneFilterOptionListChange,
            usesSplitJob,
        } = props;
        const pageActionsStyles = PAGE_ACTIONS_STYLES();
        const classes = jobsFilterFormStyles();
        const [updateStartDate, setUpdateStartDate] = useState("");
        const [filtersList, updateFiltersList] = useState(
            getInitialFiltersList(isSmartDispatchPage, isJobBoardPage, initializedFilters, usesSplitJob),
        );
        const size = isListView ? 8 : 12 - dateRangeSize - filtersOffset;
        const initialStatus = locationParams ? locationParams.initialFilters : {};
        const isJobBoardDispatch = history.location.pathname.includes(dispatchJobBoardRoute);
        const [initialFilters, updateInitialFilters] = useState(
            isJobBoardPage || isJobBoardDispatch
                ? {
                      ...GET_USER_INITIAL_FILTERS(form),
                      ...JOB_BOARD_INITIAL_FILTERS,
                  }
                : {
                      ...GET_USER_INITIAL_FILTERS(form),
                  },
        );
        const chooseOneFilterOptionList = [];
        const presentDay = moment().format(SERVER_DATE_FORMAT);
        if (isSmartDispatchPage && moment(startDate).format(SERVER_DATE_FORMAT) !== presentDay) {
            chooseOneFilterOptionList.push(FILTERS_REGIONS_ID);
        }

        const setStartDate = (date) => {
            if (moment(startDate).format(SERVER_DATE_FORMAT) !== date.format(SERVER_DATE_FORMAT)) {
                dispatch(change(form, START_DATE_NAME, date));
                setUpdateStartDate(date);
            }
        };

        const resetToDefaultHandler = () => {
            const startDate = moment();
            const endDate = moment();
            dispatch(change(form, START_DATE_NAME, startDate.format(SERVER_DATE_FORMAT)));
            handleDateChange(startDate.format(SERVER_DATE_FORMAT), START_DATE_NAME);

            if (!IS_ONLY_START_DATA(form)) {
                dispatch(change(form, END_DATE_NAME, endDate.format(SERVER_DATE_FORMAT)));
                handleDateChange(endDate.format(SERVER_DATE_FORMAT), END_DATE_NAME);
            }
            resetActiveJobOrders("-id");
            setUpdateStartDate(startDate);
        };

        useEffect(() => {
            if (!_.isEmpty(regions)) {
                updateFiltersList([
                    ...filtersList,
                    {
                        title: "Regions",
                        filterKey: FILTERS_REGIONS_ID,
                        filters: regions.map((item) => ({
                            label: item.regionName,
                            id: item.id,
                        })),
                    },
                ]);
            }
            if (account.region) {
                updateInitialFilters({
                    ...initialFilters,
                    [FILTERS_REGIONS_ID]: {
                        [account.region.id]: true,
                    },
                });
            }
        }, [regions]);

        useEffect(() => {
            const initialFilters = locationParams && locationParams.initialFilters;

            if (initialFilters && initialFilters[END_DATE_NAME] && initialFilters[START_DATE_NAME]) {
                const endDate = initialFilters[END_DATE_NAME];
                const startDate = initialFilters[START_DATE_NAME];
                dispatch(change(form, END_DATE_NAME, endDate));
                dispatch(change(form, START_DATE_NAME, startDate));
            }
        }, [locationParams]);

        return (
            <div
                style={!isListView ? { marginBottom: 10 } : { borderBottom: "none" }}
                className={classes.jobsFilterForm}
            >
                <form noValidate={true}>
                    <Grid
                        container
                        component="div"
                        alignItems={"center"}
                        className={clsx(
                            pageActionsClassName,
                            pageActionsStyles.pageActions,
                            showFilters || "hide-filters-border",
                        )}
                    >
                        {showFilters && (
                            <>
                                {isListView ? (
                                    <Grid item xs={6} component="div">
                                        <Grid container>
                                            <Grid item xs={dateRangeSize}>
                                                <DatesRange
                                                    handleStartDateChange={handleDateChange}
                                                    handleEndDateChange={handleDateChange}
                                                    form={form}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    !withoutDatesRange && (
                                        <Grid item xs={isJobBoardDispatch ? null : 9} sm={12 - +size}>
                                            <Grid container alignItems={"center"} justify={"center"}>
                                                <Grid
                                                    item
                                                    xs={isJobBoardDispatch ? 12 : IS_ONLY_START_DATA(form) ? 6 : null}
                                                    sm={IS_ONLY_START_DATA(form) ? 6 : null}
                                                    component="div"
                                                >
                                                    <DatesRange
                                                        minDate={minDate}
                                                        disabledLeftArrow={disabledLeftArrow}
                                                        handleStartDateChange={handleDateChange}
                                                        updateStartDate={updateStartDate}
                                                        setUpdateStartDate={setUpdateStartDate}
                                                        handleEndDateChange={handleDateChange}
                                                        showEndDate={!IS_ONLY_START_DATA(form)}
                                                        isInitialLoadingDatesSave={true}
                                                        form={form}
                                                    />
                                                </Grid>

                                                {IS_ONLY_START_DATA(form) && (
                                                    <Grid
                                                        item
                                                        className={clsx(isJobBoardDispatch && "buttons-wrap")}
                                                        xs={
                                                            isJobBoardDispatch
                                                                ? 12
                                                                : IS_ONLY_START_DATA(form)
                                                                ? 6
                                                                : null
                                                        }
                                                        sm={IS_ONLY_START_DATA(form) ? 6 : null}
                                                        container
                                                    >
                                                        <Grid item component="div">
                                                            <SecondaryButton
                                                                size={"small"}
                                                                className={"today"}
                                                                onClick={() => setStartDate(moment())}
                                                            >
                                                                Today
                                                            </SecondaryButton>
                                                        </Grid>
                                                        <Grid item component="div">
                                                            <SecondaryButton
                                                                size={"small"}
                                                                style={
                                                                    IS_ONLY_START_DATA(form) ? { marginLeft: 6 } : null
                                                                }
                                                                className={"tomorrow"}
                                                                onClick={() => setStartDate(moment().add(1, "d"))}
                                                            >
                                                                Tomorrow
                                                            </SecondaryButton>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                                <Grid
                                    item
                                    xs={isJobBoardDispatch ? null : containerElementSize ? containerElementSize : 3}
                                    sm={isListView ? 6 : size > 0 ? size : false}
                                    component="div"
                                    className="search-and-filters"
                                >
                                    <SearchAndFilters
                                        key={filtersList.length}
                                        chooseOneFilterOptionList={chooseOneFilterOptionList}
                                        rememberFilters
                                        form={form}
                                        isResetToDefault={_.isUndefined(isResetToDefault) ? true : isResetToDefault}
                                        resetToDefaultHandler={resetToDefaultHandler}
                                        dataLoadedFor={dataLoadedFor}
                                        shouldListenType={_.isEmpty(currentJobOrder)}
                                        initialFilters={initialFilters}
                                        defaultFilter={defaultFilter}
                                        initialStatus={initialStatus}
                                        updateFiltersHandler={updateFiltersHandler}
                                        isLoading={isLoading}
                                        updateList={updateListByFilters}
                                        filtersList={filtersList}
                                        withoutEmptySearch={withoutEmptySearch}
                                        autoFocusOnSearch={autoFocusOnSearch}
                                        handleOneFilterOptionListChange={handleOneFilterOptionListChange}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {isListView && <DividerThin marginBottom={0} />}
                </form>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    },
);

JobOrderFiltersForm.propTypes = {
    account: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    showFilters: PropTypes.bool.isRequired,
    currentJobOrder: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
    isListView: PropTypes.bool,
    isLoading: PropTypes.bool,
    isJobBoardPage: PropTypes.bool,
    dataLoadedFor: PropTypes.string,
    pageActionsClassName: PropTypes.string,
    form: PropTypes.string.isRequired,
    dateRangeSize: PropTypes.number,
    filtersOffset: PropTypes.number,
    updateListByFilters: PropTypes.func,
    updateFiltersHandler: PropTypes.func,
    handleDateChange: PropTypes.func,
    [START_DATE_NAME]: PropTypes.any,
    defaultFilter: PropTypes.object,
    isSmartDispatchPage: PropTypes.bool,
    autoFocusOnSearch: PropTypes.bool,
    handleOneFilterOptionListChange: PropTypes.func,
};

JobOrderFiltersForm.defaultValues = {
    showFilters: true,
    autoFocusOnSearch: true,
};

export default withRouter(
    compose(
        reduxForm({}),
        connect(
            (state, props) => {
                const formSelector = formValueSelector(props.form);

                return {
                    account: state.account,
                    currentJobOrder: state.jobOrders.currentJobOrder,
                    [START_DATE_NAME]: formSelector(state, START_DATE_NAME),
                    regions: selectCompanyRegions(state),
                    usesSplitJob: selectUsesSplitJob(state),
                    search: formSelector(state, "search"),
                };
            },
            (dispatch) => ({
                setJobOrders: (jobOrders) => {
                    dispatch(saveJobOrdersInRedux(jobOrders));
                },
                setCurrentJobOrder: (jobOrderId) => {
                    dispatch(saveCurrentJobOrderIdInRedux(jobOrderId));
                },
            }),
        ),
    )(JobOrderFiltersForm),
);
