import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { change, formValueSelector } from "redux-form";
import { connect, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";
import TocIcon from "@material-ui/icons/Toc";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { DividerThin } from "../core/divider";
import PoLineItemForm, { CREATE_POLI_FORM } from "../poLineItems/poLineItemForm";
import AppModal from "../core/modal";
import UnitOfMeasure from "../core/unitOfMeasure";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import { PROJECT_DETAILS_FORM } from "./projectDetailsForm";
import { UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD } from "../../constants/maps";
import {
    getJobOrderEditRoute,
    getJobOrderRequestRoute,
    getProjectItemLabel,
    getPurchaseOrderLabel,
    getSiteEditRoute,
    getSiteValue,
} from "../../helpers/global";
import {
    getAdditionalFieldName,
    getPhaseCodeFromAdditionalFields,
    handleAdditionalFields,
    prepareAdditionalFields,
} from "../../helpers/projects";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobs/jobComponents/pickUp&DropOff";
import { convertTimeToJsFormat } from "../../helpers/jobOrders";
import RoomIcon from "@material-ui/icons/Room";
import { SimpleLink } from "../core/link";
import { ICONS_SITE } from "../../styles/icons";
import { getHoursQty } from "../tickets/components/ticketCard";
import { archivePOLineItem, removePOLineItem, unArchivePOLineItem } from "../../actions/poLineItems";
import { EDIT_JO_FORM } from "../../components/jobs/constants";
import { getIfIsRegionRequired, selectUsesSplitJob } from "../../selectors/index";
import { SELECT_PROJECT_WITHOUT_REGION_WARNING } from "../../constants/strings";
import ConfirmationModal from "../core/confirmationModal";
import ActionNotification from "../core/actionNotification";
import { getSiteByIdWithoutAlertZones } from "../../dataServers/sites";

const useStyles = makeStyles((theme) => ({
    projectItem: {
        padding: "5px 0 10px",
        width: "100%",
        "& .project-item-text": {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 16,
            color: theme.palette.text.primary,
        },
        "& .divider": {
            width: 2,
            height: 50,
            margin: "15px 10px",
        },
        "& .payload": {
            height: 28,
        },
        "& .icon": {
            marginRight: 5,
            paddingBottom: 2,
        },
    },
    highlightedProjectItem: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.superLight,
    },
    siteInfo: {
        marginBottom: 7,

        "& svg": {
            marginRight: 5,
        },
    },
}));

const ProjectLineItem = (props) => {
    const {
        currentProject,
        history,
        poLineItem,
        account,
        disabled,
        archived,
        isArchiveTab,
        isRegionRequired,
        region,
        isHighlighted,
        usesSplitJob,
    } = props;
    const {
        payload,
        canBeRemoved,
        unitOfMeasure,
        ticketsType,
        perUnitPrice,
        dropOffSites,
        pickUpSite,
        filledQuantity,
        totalQuantity,
        unlimited,
        additionalFields,
        truckTypes,
        canBeArchived,
        archived: isArchivedPoLineItem,
        canBeSplitted,
    } = poLineItem;
    const grantedAccessProject = currentProject?.grantedAccess;

    const [showEditPoliModal, setShowEditPoliModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
    const [warning, setWarning] = useState({});
    const classes = useStyles();
    const siteIconsClasses = ICONS_SITE();
    const dispatch = useDispatch();

    const dispatchPoli = (initialValues) => {
        if (isRegionRequired && !region) {
            setWarning({ ...warning, message: SELECT_PROJECT_WITHOUT_REGION_WARNING(currentProject.name) });
            return;
        }
        dispatch(change(`${EDIT_JO_FORM}-0`, "isAppearNewJobOrderForm", true));
        if (grantedAccessProject) {
            history.push(getJobOrderRequestRoute(), { currentProject: currentProject });
        } else {
            const updatePoLineItem = {
                ...poLineItem,
            };
            getSiteByIdWithoutAlertZones(poLineItem.pickUpSite.id).then((r) => {
                const site = r.data;
                updatePoLineItem.pickUpSite = {
                    ...poLineItem.pickUpSite,
                    latitude: site?.latitude,
                    longitude: site?.longitude,
                };
            });
            const state = {
                currentProject: currentProject,
                currentPOLineItem: updatePoLineItem,
            };

            if (initialValues.splitDispatch) {
                state.splitDispatch = initialValues.splitDispatch;
            }

            history.push(getJobOrderEditRoute("new"), state);
        }
    };

    const splitDispatchPoli = () => {
        dispatchPoli({ splitDispatch: true });
    };

    const requestJobOrder = () => {
        if (isRegionRequired && !region) {
            setWarning({ ...warning, message: SELECT_PROJECT_WITHOUT_REGION_WARNING(currentProject.name) });
            return;
        }
        history.push(getJobOrderRequestRoute(), {
            currentProject: currentProject,
            requestPOLineItem: poLineItem,
        });
    };

    const removePoli = () => {
        dispatch(removePOLineItem(poLineItem));
        setShowConfirmationModal(false);
    };

    const archivePoli = () => {
        dispatch(archivePOLineItem(poLineItem));
        setShowArchiveConfirmationModal(false);
    };

    const unArchivePoli = () => {
        dispatch(unArchivePOLineItem(poLineItem));
        setShowArchiveConfirmationModal(false);
    };

    const renderArchivedButton = () => {
        if (canBeArchived) {
            return (
                <SecondaryButton
                    size={"small"}
                    disabled={disabled}
                    onClick={() => setShowArchiveConfirmationModal(true)}
                >
                    archive
                </SecondaryButton>
            );
        }
        if (isArchivedPoLineItem) {
            return (
                <SecondaryButton
                    size={"small"}
                    disabled={disabled}
                    onClick={() => setShowArchiveConfirmationModal(true)}
                >
                    unarchive
                </SecondaryButton>
            );
        }
    };

    return (
        <div>
            <Grid
                component="div"
                container
                className={clsx(classes.projectItem, isHighlighted && classes.highlightedProjectItem)}
                alignItems={"center"}
                justify={"space-between"}
            >
                {!disabled && !archived && <TocIcon className={clsx(classes.tocIconsStyles, "icon")} />}
                <Grid
                    item
                    container
                    xs={3}
                    className="payload project-item-text"
                    alignItems={"center"}
                    alignContent={"center"}
                >
                    {getProjectItemLabel({
                        payload: payload && payload.name,
                        perUnitPrice: grantedAccessProject ? null : perUnitPrice,
                        unitOfMeasure,
                    })}
                </Grid>
                <Grid item xs={3} className="quantity project-item-text">
                    <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
                        {unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                            <Grid item>{filledQuantity && getHoursQty(filledQuantity)}</Grid>
                        ) : (
                            <Grid item>{filledQuantity.toFixed(2)}</Grid>
                        )}

                        <React.Fragment>
                            <Grid item style={{ padding: "0 4px" }}>
                                {` / `}
                            </Grid>
                            {!unlimited ? (
                                unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                                    <Grid item>{totalQuantity && getHoursQty(totalQuantity)}</Grid>
                                ) : (
                                    <Grid item>
                                        {totalQuantity && totalQuantity.toFixed(2)}
                                        {` `}
                                        <UnitOfMeasure value={unitOfMeasure.name} />
                                    </Grid>
                                )
                            ) : (
                                <React.Fragment>
                                    <AllInclusiveIcon className={clsx(classes.infiniteIcon, "icon")} />
                                    <Grid item>
                                        <UnitOfMeasure value={unitOfMeasure.name} />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Grid>
                </Grid>
                <Grid item xs={3} className="quantity project-item-text">
                    {pickUpSite && (
                        <Grid container wrap={"nowrap"} className={classes.siteInfo} justify={"flex-start"}>
                            <Grid item>
                                <RoomIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />
                            </Grid>
                            <Grid item>
                                <SimpleLink to={getSiteEditRoute(pickUpSite.id)}>{pickUpSite.name}</SimpleLink>
                            </Grid>
                        </Grid>
                    )}
                    {dropOffSites.map((site) => {
                        return (
                            <Grid
                                container
                                wrap={"nowrap"}
                                justify={"flex-start"}
                                key={site.id}
                                className={classes.siteInfo}
                            >
                                <Grid item>
                                    <RoomIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                                </Grid>
                                <Grid item>
                                    <SimpleLink to={getSiteEditRoute(site.id)}>{site.name}</SimpleLink>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item>
                    <DividerThin marginTop={0} marginBottom={0} isVertical={true} className="divider" />
                </Grid>
                <Grid item container xs={2} justify={"center"}>
                    {grantedAccessProject ? (
                        <SecondaryButton
                            size={"small"}
                            disabled={isArchivedPoLineItem}
                            style={{ marginBottom: 10 }}
                            onClick={requestJobOrder}
                        >
                            Request
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton
                            size={"small"}
                            disabled={disabled || isArchivedPoLineItem}
                            style={{ marginBottom: 10 }}
                            onClick={dispatchPoli}
                        >
                            Dispatch
                        </SecondaryButton>
                    )}
                    {usesSplitJob && canBeSplitted && (
                        <SecondaryButton
                            size={"small"}
                            disabled={disabled || isArchivedPoLineItem}
                            style={{ marginBottom: 10 }}
                            onClick={splitDispatchPoli}
                        >
                            Split Dispatch
                        </SecondaryButton>
                    )}
                    <SecondaryButton
                        size={"small"}
                        disabled={
                            grantedAccessProject
                                ? isArchivedPoLineItem || isArchiveTab
                                    ? true
                                    : false
                                : disabled || isArchivedPoLineItem
                        }
                        style={{ marginBottom: 10 }}
                        onClick={() => setShowEditPoliModal(true)}
                    >
                        Edit
                    </SecondaryButton>
                    {canBeRemoved && (
                        <SecondaryButton
                            size={"small"}
                            disabled={disabled || isArchivedPoLineItem}
                            style={{ marginBottom: 10 }}
                            onClick={() => setShowConfirmationModal(true)}
                        >
                            Remove
                        </SecondaryButton>
                    )}
                    {renderArchivedButton()}
                </Grid>
            </Grid>
            <DividerThin marginTop={0} marginBottom={0} />
            <AppModal
                isOpen={showEditPoliModal}
                form={CREATE_POLI_FORM}
                modalStyles={{ width: 800 }}
                closeModal={() => setShowEditPoliModal(false)}
            >
                <PoLineItemForm
                    edit={true}
                    poLineItem={poLineItem}
                    initialValues={{
                        unlimited: unlimited,
                        payload: {
                            value: payload.id,
                            label: payload.name,
                            poType: payload.poType,
                        },
                        [PICK_UP_SITE_NAME]: pickUpSite && getSiteValue(pickUpSite),
                        [START_DATE_NAME]: poLineItem[START_DATE_NAME]
                            ? convertTimeToJsFormat(poLineItem[START_DATE_NAME], account.timezone)
                            : null,
                        [END_DATE_NAME]: poLineItem[END_DATE_NAME]
                            ? convertTimeToJsFormat(poLineItem[END_DATE_NAME], account.timezone)
                            : null,
                        [DROP_OF_SITE_NAME]: dropOffSites && dropOffSites.map((site) => getSiteValue(site)),
                        unitOfMeasure:
                            +unitOfMeasure.id > 6
                                ? { value: unitOfMeasure.id, label: unitOfMeasure.name }
                                : `value-${unitOfMeasure.id}`,
                        ticketUsageType:
                            (+unitOfMeasure.id === UNIT_OF_MEASURE_LOAD ||
                                +unitOfMeasure.id === UNIT_OF_MEASURE_HOUR) &&
                            ticketsType &&
                            ticketsType.toString(),
                        perUnitPrice: perUnitPrice,
                        quantity: !unlimited ? totalQuantity : null,
                        purchaseOrder: {
                            id: poLineItem.purchaseOrder.id,
                            label: getPurchaseOrderLabel(poLineItem.purchaseOrder),
                        },
                        additionalFields: handleAdditionalFields(prepareAdditionalFields(additionalFields)).map(
                            (fieldName) => {
                                return {
                                    name: getAdditionalFieldName(fieldName),
                                    value: prepareAdditionalFields(additionalFields)[fieldName],
                                };
                            },
                        ),
                        id: poLineItem.id,
                        truckTypes: truckTypes
                            ? truckTypes.map((i) => ({
                                  value: i.id,
                                  label: i.name,
                              }))
                            : [],
                        ...getPhaseCodeFromAdditionalFields(additionalFields),
                    }}
                    grantedAccess={currentProject?.grantedAccess}
                    closeModal={() => setShowEditPoliModal(false)}
                    showPOSelector={true}
                />
            </AppModal>
            <ActionNotification
                message={warning.message}
                config={{ variant: "warning", onClose: () => setWarning({}) }}
            />
            <ConfirmationModal
                isOpen={!!showConfirmationModal}
                question="Are you sure you want to remove payload?"
                yesHandler={() => removePoli()}
                noHandler={() => setShowConfirmationModal(false)}
            />
            <ConfirmationModal
                isOpen={!!showArchiveConfirmationModal}
                question={`Are you sure you want to ${isArchiveTab ? "unarchive" : "archive"} payload?`}
                yesHandler={() => (isArchiveTab ? unArchivePoli() : archivePoli())}
                noHandler={() => setShowArchiveConfirmationModal(false)}
            />
        </div>
    );
};

ProjectLineItem.propTypes = {
    currentProject: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    poLineItem: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    archived: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            currentProject: state.projects.currentProject,
            region: formValueSelector(PROJECT_DETAILS_FORM)(state, "region"),
            isRegionRequired: getIfIsRegionRequired(state),
            usesSplitJob: selectUsesSplitJob(state),
        };
    })(ProjectLineItem),
);
