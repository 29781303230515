import React, { useState } from "react";
import JobsSubNav from "../components/jobs/jobComponents/jobsSubNav";
import TicketsWrapper, { TICKETS_FILTERS_FORM } from "../components/tickets/ticketsWrapper";
import { GET_USER_INITIAL_FILTERS } from "../components/global/searchAndFilters";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function Tickets(props) {
    const { history } = props;
    const [isListView, setListView] = useState(false);
    const initialValues = GET_USER_INITIAL_FILTERS(TICKETS_FILTERS_FORM);

    const historyState = history?.location?.state;

    const jobOrderId = historyState?.jobOrderId;
    const ticketId = historyState?.ticketId;

    if (jobOrderId) {
        initialValues.search = [{ value: jobOrderId, label: jobOrderId, __isNew__: true }];
    } else if (ticketId) {
        initialValues.search = [{ value: ticketId, label: ticketId, __isNew__: true }];
    }

    return (
        <React.Fragment>
            <JobsSubNav isListView={isListView} setListView={setListView} />
            <TicketsWrapper isListView={isListView} initialValues={initialValues} />
        </React.Fragment>
    );
}

Tickets.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(Tickets);
