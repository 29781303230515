import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";

import EventIcon from "@material-ui/icons/Event";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import { getJobOrderById } from "../../../dataServers/jobOrder";
import {
    saveCurrentJobOrderIdInRedux,
    saveJobOrdersInRedux,
    updateRequestedJobOrderId,
} from "../../../actions/jobOrders";

import { UncontrolledAppTabs } from "../../core/uncontrolledAppTabs";
import JobOrderSummary from "./summary";
import Trucks from "./trucks/trucks";
import ErrorNotification from "../../core/notification";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { IS_INSPECTOR_USER } from "../../../constants/maps";
import Loader from "../../core/loader";
import { getIsJobOrderRequest } from "../../../helpers/jobOrders";
import ProjectLink from "./trucks/ProjectLink";
import { TRAILER_TAB_ICON } from "../../../styles/icons";
import Icon from "@material-ui/core/Icon";
import TrailersJobDetailsTab from "./TrailersJobDetailsTab";
import { selectIfIsRestrictedCustomer } from "../../../selectors/user";

const useStyles = makeStyles((theme) => ({
    jobDetails: {
        "& .header": {
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            marginBottom: 11,
            padding: "8px 0",
            display: "flex",

            "& .job-number": {
                whiteSpace: "nowrap",
                marginRight: 8,
            },
        },
        "& .sub-header": {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.secondary,
            lineHeight: 1,
            display: "flex",
            alignItems: "center",

            "& svg": {
                color: theme.palette.text.secondary,
                width: theme.icon.width,
                height: theme.icon.height,
                marginRight: 14,
            },

            "& span": {
                fontWeight: "inherit",
                fontSize: "inherit",
                color: "inherit",
                lineHeight: "inherit",
            },
        },
    },
    textOverflow: {
        width: "calc(100% - 1px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    projectLink: {
        fontSize: "1em",
    },
}));

function JobOrderDetails(props) {
    const {
        currentJobOrder,
        history,
        jobOrders,
        updateJobOrdersInRedux,
        account,
        requestedJobOrderId,
        isRestrictedCustomer,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [errorMessage] = useState(null);
    const [jobOrder, updateJobOrder] = useState({});
    const isJobBoardPage = history.location.pathname.includes(ROUTE_JOBS.JOB_BOARD);
    const project =
        jobOrder.poLineItem && jobOrder.poLineItem.purchaseOrder && jobOrder.poLineItem.purchaseOrder.project;
    let joTabs = [];
    const isJobOrderRequest = getIsJobOrderRequest(jobOrder, account);
    const hasTrailers = !!jobOrders.find((i) => i.id === jobOrder.id)?.trailers;

    const updateJoStatus = (id, status) => {
        +jobOrder.id === +id && updateJobOrder({ ...jobOrder, status });

        if (jobOrders.find((i) => i.id === +id)) {
            const newList = _.cloneDeep(jobOrders).map((item) => {
                if (+item.id === +id) {
                    item.status = status;

                    return item;
                }

                return item;
            });

            updateJobOrdersInRedux(newList);
        }
    };

    const updateJoCopySchedule = (id, copySchedule) => {
        +jobOrder.id === +id && updateJobOrder({ ...jobOrder, copySchedule });

        if (jobOrders.find((i) => i.id === +id)) {
            const newList = _.cloneDeep(jobOrders).map((item) => {
                if (+item.id === +id) {
                    item.copySchedule = copySchedule;

                    return item;
                }

                return item;
            });

            updateJobOrdersInRedux(newList);
        }
    };

    const loadJobOrderData = () => {
        setLoading(true);
        updateJobOrder({});

        dispatch(updateRequestedJobOrderId(currentJobOrder));
        getJobOrderById(currentJobOrder).then((data) => {
            setLoading(false);
            updateJobOrder(data.data);
        });
    };

    const handleTicketCreate = () => {
        setLoading(true);

        getJobOrderById(currentJobOrder).then((data) => {
            setLoading(false);
            updateJobOrder(data.data);
        });
    };

    const updateTicketCount = (newData) => {
        updateJobOrder({ ...jobOrder, ...newData });
    };

    if (!_.isEmpty(jobOrder)) {
        joTabs = [
            {
                title: "Summary",
                content: (
                    <JobOrderSummary
                        updateJoStatus={updateJoStatus}
                        updateJoCopySchedule={updateJoCopySchedule}
                        updateTicketCount={updateTicketCount}
                        handleTicketCreate={handleTicketCreate}
                        jobOrder={jobOrder}
                    />
                ),
                icon: <EventIcon />,
            },
        ];

        if (!IS_INSPECTOR_USER(account.role)) {
            joTabs.push({
                title: "Trucks",
                content: <Trucks jobOrder={jobOrder} />,
                icon: <LocalShippingIcon />,
                disabled: isRestrictedCustomer,
            });
        }
        if (hasTrailers) {
            joTabs.push({
                title: "Trailers",
                content: <TrailersJobDetailsTab jobOrderId={jobOrder.id} />,
                icon: <Icon style={TRAILER_TAB_ICON} />,
                disabled: isRestrictedCustomer,
            });
        }
    }

    // componentDidMount
    useEffect(() => {
        // componentWillUnMount
        return () => {
            dispatch(saveCurrentJobOrderIdInRedux(null));
            dispatch(updateRequestedJobOrderId(null));
        };
    }, []);

    useEffect(() => {
        if (
            jobOrder &&
            currentJobOrder &&
            jobOrder.id !== currentJobOrder &&
            (!requestedJobOrderId || requestedJobOrderId !== currentJobOrder)
        ) {
            currentJobOrder && loadJobOrderData();
        }

        if (!currentJobOrder) {
            updateJobOrder({});
        }
    }, [currentJobOrder]);

    return (
        <div
            className={clsx(classes.jobDetails, (isLoading || _.isEmpty(jobOrder)) && LOADER_WHITE_OVERLAY)}
            style={{ minHeight: 500 }}
        >
            {!_.isEmpty(jobOrder) && (
                <React.Fragment>
                    <h1 className="header">
                        <div className="job-number">
                            {isJobOrderRequest ? "Request" : "Job"} #{jobOrder.id}
                            {jobOrder.extRef && ` / Ext. Ref #${jobOrder.extRef}`}
                            {isJobBoardPage && `${jobOrder.customerName ? ` - ${jobOrder.customerName}` : ""}`}
                        </div>
                        <div className={classes.textOverflow}>
                            <ProjectLink
                                project={project}
                                jobCompanyId={jobOrder.company?.id}
                                grantedAccess={jobOrder.grantedAccess}
                            />
                        </div>
                    </h1>
                    <UncontrolledAppTabs tabsControls={joTabs} tabsContent={joTabs} firstTab={0} />
                </React.Fragment>
            )}
            {errorMessage && <ErrorNotification message={errorMessage} />}
            {(isLoading || _.isEmpty(jobOrder)) && <Loader />}
        </div>
    );
}

JobOrderDetails.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    currentJobOrder: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
    updateJobOrdersInRedux: PropTypes.func.isRequired,
    jobOrders: PropTypes.array.isRequired,
    isRestrictedCustomer: PropTypes.bool,
    requestedJobOrderId: PropTypes.number,
};

export default withRouter(
    connect(
        (state) => {
            return {
                currentJobOrder: state.jobOrders.currentJobOrder,
                jobOrders: state.jobOrders.list,
                requestedJobOrderId: state.jobOrders.requestedJobOrderId,
                account: state.account,
                isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
            };
        },
        (dispatch) => ({
            updateJobOrdersInRedux: (jobOrders) => {
                dispatch(saveJobOrdersInRedux(jobOrders));
            },
        }),
    )(JobOrderDetails),
);
