import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { getCompanyTrucks } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AppModal from "../core/modal";
import { withRouter } from "react-router-dom";

import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import { Validation } from "../../helpers/validation";

const LinkTruckToIotDeviceDialog = (props) => {
    const {
        openLinkTruckToIotDialog,
        handleClose,
        iotDeviceForEdit,
        onSubmit,
        formValues: { truck },
        handleSubmit,
        reset,
    } = props;
    const [successMessage, setSuccessMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const closeModal = () => {
        reset();
        handleClose && handleClose();
    };

    const onFormSubmit = () => {
        setLoading(true);
        onSubmit(truck.value, iotDeviceForEdit.deviceId)
            .then(() => {
                setSuccessMessage(["Successfully Linked"]);
                setLoading(false);
                closeModal();
            })
            .catch((error) => {
                setLoading(false);
                setErrorMessage(PROCESS_SERVER_ERROR(error));
            });
    };

    const loadOptions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getCompanyTrucks({
            keywords: inputValue,
            ...params,
        }).then((data) => {
            const results = data.data.map((truck) => ({
                ...truck,
                value: truck.id,
                label: truck.truckName,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <React.Fragment>
            <AppModal isOpen={openLinkTruckToIotDialog} form={LINK_TRUCK_TO_IOT_FORM} closeModal={closeModal}>
                <form
                    noValidate={true}
                    onSubmit={handleSubmit(onFormSubmit)}
                    className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                >
                    {isLoading && <Loader />}
                    <h2 className="title">LINK TRUCK DIALOG</h2>
                    <Field
                        name="truck"
                        loadOptions={loadOptions}
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        placeholder="Select Truck To Link"
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton type="button" onClick={closeModal}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </ButtonsGroup>
                </form>
            </AppModal>
            {successMessage && (
                <SuccessNotification message={successMessage} config={{ onClose: () => setSuccessMessage(null) }} />
            )}
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
        </React.Fragment>
    );
};

LinkTruckToIotDeviceDialog.propTypes = {
    openLinkTruckToIotDialog: PropTypes.bool,
    handleClose: PropTypes.func,
    iotDeviceForEdit: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

export const LINK_TRUCK_TO_IOT_FORM = "linkTruckToIotForm";

export default withRouter(
    compose(
        reduxForm({
            form: LINK_TRUCK_TO_IOT_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "id", "truck"),
            };
        }),
    )(LinkTruckToIotDeviceDialog),
);
