/* global google */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { compose as reduxCompose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { compose, withProps } from "recompose";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import CustomMarker from "../global/maps/customMarker";
import { MAP } from "react-google-maps/lib/constants";
import { PrimaryButton } from "../core/buttons/primaryButton";
import AsyncAutoCompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import ErrorNotification from "../core/notification";
import Loader from "../core/loader";
import Grid from "@material-ui/core/Grid";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { createSite, getSiteById, getSites as getServerSites, updateSite } from "../../dataServers/sites";
import { IS_ADMIN_USER, TRUCK_IT_COMPANY_ID, TRUCK_IT_COMPANY_NAME } from "../../constants/maps";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import { getCompanies } from "../../dataServers/companies";
import { Validation } from "../../helpers/validation";
import CustomSwitch from "../core/form/customSwitch";
import { ALERT_ZONE_TYPE_CIRCLE, ALERT_ZONE_TYPE_POLYGON, LOCATION_TYPES } from "../../constants/sites";
import {
    GOOGLE_MAPS_SITE_ICON_SIZE,
    GOOGLE_MAPS_URL,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
} from "../../constants/global";
import { loadCurrentCoordinates } from "../../helpers/location";
import sitePin from "../../styles/images/pale-red-pin.svg";
import * as SitesActions from "../../actions/sites";
import AutocompleteAddress from "../global/autocompleteAddress";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { getSiteEditRoute } from "../../helpers/global";
import { withRouter } from "react-router-dom";
import { getValidCoordinates } from "../../helpers/sites";
import AppModal from "../core/modal";
import AddZonePopupForm from "./addZonePopupForm";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles(() => ({
    siteForm: {
        "& .measure-pill": {
            width: "100%",

            "& .pill": {
                width: "100%",
            },
        },
    },
}));

const SiteFormMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { setMap, currentLocation } = props;

    return (
        <GoogleMap ref={(map) => setMap(map)} defaultZoom={12}>
            {currentLocation && (
                <CustomMarker
                    id={0}
                    position={currentLocation}
                    icon={{
                        url: sitePin,
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                    }}
                />
            )}
        </GoogleMap>
    );
});

const SiteForm = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
)((props) => {
    const {
        account,
        closeModal,
        defaultCoordinates,
        handleSubmit,
        setFieldValue,
        history,
        formValues: { isSubSite, id, coordinates, company },
        getSites,
        setCurrentSite,
        currentSite,
        afterSaveHandler,
        fieldName,
        createForDropdown,
    } = props;
    const classes = useStyles();
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const geocoder = new google.maps.Geocoder();
    const [plService, setPlacesService] = useState();
    let placesService;
    const [map, setMap] = useState();
    const [isLoading, setLoading] = useState(false);
    const [isOpenZonesModal, setIsOpenZonesModal] = useState(false);
    const [zone, setZone] = useState();
    const [alertZones, setAlertZones] = useState(defaultCoordinates ? currentSite.alertZones : []);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [coordinatesChanged, setCoordinatesChanged] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const _onMapClick = (event, map) => {
        setCurrentLocation(event.latLng);
        geocoder.geocode({ location: event.latLng }, (result, status) => {
            if (status === "OK" && !_.isEmpty(result))
                _changeLocation(
                    {
                        placeId: result[0].place_id,
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                    },
                    map,
                    false,
                    false,
                );
        });
    };

    const _changeLocation = (
        { placeId, lat, lng },
        map,
        centerMap = true,
        changeCoords = true,
        changeLocation = true,
    ) => {
        placesService = placesService || plService;
        if (placeId && map && placesService) {
            placesService.getDetails({ placeId: placeId }, (placeDetails, status) => {
                if (status === "OK") {
                    setFieldValue("address", {
                        value: 1,
                        label: placeDetails.formatted_address || null,
                    });

                    changeLocation &&
                        setFieldValue(
                            "coordinates",
                            `${lat || placeDetails.geometry.location.lat()}, ${
                                lng || placeDetails.geometry.location.lng()
                            }`,
                        );

                    changeCoords && setCurrentLocation(placeDetails.geometry.location);

                    if (centerMap) map.setCenter(placeDetails.geometry.location);

                    if (placeDetails.address_components)
                        placeDetails.address_components.forEach((component) => {
                            const postal_index = component.types.indexOf("postal_code");
                            const locality_index = component.types.indexOf("locality");

                            if (postal_index !== -1) setFieldValue("postalCode", component.long_name);

                            if (locality_index !== -1) setFieldValue("city", component.long_name);
                        });
                }
            });
        }
    };

    const loadSites = (inputValue, { params = {}, loadedCount }) => {
        return getServerSites({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadLocationTypes = () => {
        const results = LOCATION_TYPES.map((type, index) => ({
            value: index,
            label: type,
        }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    const loadOwners = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: nextPage,
            };
        });
    };

    const onSubmit = (values) => {
        const {
            name,
            address,
            city,
            postalCode,
            coordinates,
            isSubSite,
            parentSite,
            notes,
            propertyOwner,
            locationType,
            isPublic,
            isAirTicketEnabled,
            // hasScale,
            company,
        } = values;

        if (coordinatesChanged) {
            setError(["Use must click 'Proceed' button after coordinate value change."]);

            return;
        }

        const validatedCoordinates = getValidCoordinates(coordinates);

        let dataForSave = {
            name: name,
            address: address.label,
            latitude: validatedCoordinates.lat,
            longitude: validatedCoordinates.lng,
            isPublic,
            isAirTicketEnabled,
            notes: notes || undefined,
            cityName: city,
            postalCode: postalCode || undefined,
            type: locationType.label.toLowerCase(),
            propertyOwner: propertyOwner && (propertyOwner.value || propertyOwner),
            // hasScale: hasScale || false,
        };

        if (isSubSite && parentSite) {
            dataForSave.parentSite = parentSite.value;
        } else {
            if (values.id) dataForSave.parentSite = null;
        }

        if (isAdmin && company) dataForSave.companyId = company.value;

        setError(null);
        setLoading(true);

        return handleSave(values, dataForSave);
    };

    const setAlertZoneData = (values) => {
        const { name, markers, radius } = values;

        if (!markers && !radius) return;

        const data = {
            name: name,
            coordinates:
                values.radius && !_.isEmpty(markers)
                    ? [markers[0].lat(), markers[0].lng()]
                    : markers.map((coords) => [coords.lat(), coords.lng()]),
            type: radius ? ALERT_ZONE_TYPE_CIRCLE : ALERT_ZONE_TYPE_POLYGON,
            radius: radius,
        };

        setAlertZones([
            // To don't overwrite existed zones
            ...alertZones.filter((alertZone) => alertZone.id),
            data,
        ]);

        setZone(data);

        return new Promise(() => {
            const successText = _.isEmpty(defaultCoordinates)
                ? "Zone is added."
                : "Zone is added. Click save for saving.";

            setSuccess(successText);
            setIsOpenZonesModal(false);
        });
    };

    const handleSave = (values, dataForSave) => {
        if (!values.id && !zone) {
            setError(["Site must have at least one alert zone."]);
            setLoading(false);

            return;
        }

        const zoneData = zone
            ? zone.radius
                ? {
                      coordinates: [zone.coordinates],
                      isPublic: dataForSave.isPublic,
                      name: zone.name,
                      radius: zone.radius,
                      type: zone.type,
                  }
                : {
                      coordinates: zone.coordinates,
                      isPublic: dataForSave.isPublic,
                      name: zone.name,
                      type: zone.type,
                  }
            : null;

        const data = { ...dataForSave };

        if (zoneData) data.alertZone = zoneData;

        const handleSave = !values.id ? createSite(data) : updateSite(currentSite.id, data);

        return handleSave
            .then((response) => {
                if (createForDropdown) {
                    closeModal();
                    setLoading(false);
                    afterSaveHandler && afterSaveHandler(response.data, fieldName);
                } else {
                    if (!values.id) {
                        getSites();

                        return getSiteById(response.data.id, account).then((data) => {
                            setCurrentSite(data);
                            closeModal();
                            setLoading(false);
                            afterSaveHandler && afterSaveHandler(data, fieldName);
                        });
                    } else {
                        getSites();

                        return getSiteById(currentSite.id, account).then((data) => {
                            setCurrentSite(data);
                            history.push(getSiteEditRoute(data.id));
                            closeModal();
                            setLoading(false);
                        });
                    }
                }
            })
            .catch((errorResponse) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(errorResponse));
            });
    };

    const onCoordinatesChange = () => {
        const location = getValidCoordinates(coordinates);
        setCoordinatesChanged(false);
        if (location) {
            geocoder.geocode(
                {
                    location: location,
                },
                (result, status) => {
                    if (status === "OK" && !_.isEmpty(result)) {
                        _changeLocation({ placeId: result[0].place_id }, map.context[MAP]);
                    }
                },
            );

            return;
        }
        setError(["Invalid coordinates format"]);
    };

    const onAddressChange = (input) => {
        if (input) {
            _changeLocation({ placeId: input.placeId }, map.context[MAP], true, true, true);
        }
    };

    const onMessageClose = () => {
        setError(null);
    };

    const isDisabledToggle = company && company.value !== TRUCK_IT_COMPANY_ID;

    useEffect(() => {
        isDisabledToggle && setFieldValue("isPublic", false);
    }, [company]);

    useEffect(() => {
        if (map) {
            placesService = new google.maps.places.PlacesService(map.context[MAP]);
            setPlacesService(placesService);
            if (!_.isEmpty(defaultCoordinates)) {
                setCurrentLocation(defaultCoordinates);
                map.context[MAP].setCenter(defaultCoordinates);
            } else {
                loadCurrentCoordinates().then((response) => {
                    map.context[MAP].setCenter({
                        lat: response.latitude,
                        lng: response.longitude,
                    });
                });
            }

            google.maps.event.addDomListener(map.context[MAP], "click", (event) => {
                if (map) {
                    _onMapClick(event, map.context[MAP]);
                }
            });
        }
    }, [map]);

    return (
        <div className={clsx(classes.siteForm, isLoading && LOADER_WHITE_OVERLAY)}>
            <h2 className="title">{id ? "Edit Site" : "Create Site"}</h2>
            <form
                noValidate={true}
                onSubmit={(e) => {
                    e.stopPropagation();
                    handleSubmit(onSubmit)(e);
                }}
                style={{ width: "100%" }}
            >
                <SiteFormMap
                    currentLocation={currentLocation}
                    setMap={setMap}
                    googleMapURL={GOOGLE_MAPS_URL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: "30vh" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <br />
                <Field
                    type="text"
                    name="name"
                    validate={[Validation.required, Validation.minLength4, Validation.noSpecialSymbols]}
                    label="Site Name"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <AutocompleteAddress onAddressChange={onAddressChange} className={AUTOCOMPLETE_FORM_VIEW_CLASS} />
                <br />
                <Field
                    type="text"
                    name="city"
                    validate={[Validation.noSpecialSymbols]}
                    label="City"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    type="text"
                    validate={[Validation.noSpecialSymbols]}
                    name="postalCode"
                    label="Postal Code"
                    fullWidth={true}
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                />
                <br />
                <br />
                {isAdmin && (
                    <React.Fragment>
                        <Field
                            name="company"
                            validate={[Validation.required]}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            label="Company"
                            isClearable={!id}
                            loadOptions={loadOwners}
                            component={AsyncAutoCompleteComponent}
                        />
                        <br />
                    </React.Fragment>
                )}
                <Grid container component="div" justify="space-between">
                    <Grid item xs={8}>
                        <Field
                            type="text"
                            name="coordinates"
                            validate={[Validation.required, Validation.coordinates]}
                            label="Coordinates"
                            placeholder="Latitude, Longitude"
                            fullWidth={true}
                            onChange={() => setCoordinatesChanged(true)}
                            needShowEndAdornment={true}
                            fieldNote="Coordinates should be separated by a comma"
                            component={TextInputComponent}
                        />
                    </Grid>
                    <SecondaryButton onClick={onCoordinatesChange}>Proceed</SecondaryButton>
                </Grid>
                <br />
                <Grid container style={{ height: 75 }} alignItems={"center"}>
                    <Grid item xs={4} className="item-align-left">
                        <Field name="isSubSite" type="checkbox" label="Is sub-site" component={CustomSwitch} />
                    </Grid>
                    {isSubSite && (
                        <Grid item xs={8}>
                            <Field
                                name="parentSite"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                validate={[Validation.required]}
                                label="Parent Site"
                                loadOptions={loadSites}
                                component={AsyncAutoCompleteComponent}
                            />
                        </Grid>
                    )}
                </Grid>
                {/* <Grid className="item-align-left">
                    <Field name="hasScale" type="checkbox" label="Has scale" component={CustomSwitch} />
                </Grid> */}
                <br />
                {IS_ADMIN_USER(account.company.id) && (
                    <React.Fragment>
                        <Grid className="item-align-left">
                            <Field
                                name="isPublic"
                                type="checkbox"
                                label="Public"
                                disabled={isDisabledToggle}
                                onChange={(e) =>
                                    e.target.checked &&
                                    setFieldValue("company", {
                                        label: TRUCK_IT_COMPANY_NAME,
                                        value: TRUCK_IT_COMPANY_ID,
                                    })
                                }
                                component={CustomSwitch}
                            />
                        </Grid>
                        <br />
                    </React.Fragment>
                )}
                {IS_ADMIN_USER(account.company.id) && (
                    <React.Fragment>
                        <Grid className="item-align-left">
                            <Field
                                name="isAirTicketEnabled"
                                type="checkbox"
                                label="Enable Airticket"
                                component={CustomSwitch}
                            />
                        </Grid>
                        <br />
                    </React.Fragment>
                )}
                <SecondaryButton size={"medium"} onClick={() => setIsOpenZonesModal(true)}>
                    <AddCircleOutlineIcon />
                    Add Zone
                </SecondaryButton>
                <br />
                <br />
                <Field
                    type="text"
                    multiline={true}
                    name="notes"
                    rows={3}
                    label="Site Notes"
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    name="locationType"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    label="Site Type"
                    loadOptions={loadLocationTypes}
                    component={AsyncAutoCompleteComponent}
                />
                {isAdmin && (
                    <React.Fragment>
                        <br />
                        <Field
                            name="propertyOwner"
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            label="Property Owner"
                            isClearable={true}
                            loadOptions={loadOwners}
                            component={AsyncAutoCompleteComponent}
                        />
                    </React.Fragment>
                )}
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            {success && (
                <ErrorNotification error={[success]} type={"success"} config={{ onClose: () => setSuccess(null) }} />
            )}
            <AppModal isOpen={isOpenZonesModal} closeModal={() => setIsOpenZonesModal(false)}>
                <AddZonePopupForm
                    currentSite={getValidCoordinates(coordinates)}
                    alertZones={alertZones}
                    setAlertZone={setAlertZoneData}
                    setError={setError}
                />
            </AppModal>
        </div>
    );
});

SiteForm.propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultCoordinates: PropTypes.object,
    error: PropTypes.any,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    afterSaveHandler: PropTypes.func,
    fieldName: PropTypes.string,
    createForDropdown: PropTypes.bool,
};

SiteForm.defaultPops = {
    createForDropdown: false,
};

export const SITE_FORM = "siteForm";

export default withRouter(
    reduxCompose(
        reduxForm({
            form: SITE_FORM,
        }),
        connect(
            (state) => {
                const formSelector = formValueSelector(SITE_FORM);

                return {
                    account: state.account,
                    currentSite: state.sites.currentSite,
                    formValues: formSelector(state, "alertZone", "isSubSite", "id", "coordinates", "company"),
                };
            },
            (dispatch) => ({
                getSites: (payload) => dispatch(SitesActions.getSites(payload)),
                setFieldValue: (field, value) => dispatch(change(SITE_FORM, field, value)),
                setCurrentSite: (payload) => dispatch(SitesActions.setCurrentSite(payload)),
            }),
        ),
    )(SiteForm),
);
