import React from "react";
import clsx from "clsx";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import TextInputComponent from "../core/form/textInputComponent";
import { IMPERIAL, KILOMETERS_UNIT, MILES_UNIT } from "../../constants/accounts";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    container: {
        padding: "0 15px",
        display: "flex",
        justifyContent: "center",
    },
}));

const RadiusInputForm = ({ measurementSystem, onChange, xs = 5, className }) => {
    const unit = measurementSystem === IMPERIAL ? MILES_UNIT : KILOMETERS_UNIT;
    const classes = useStyles();

    return (
        <Grid item xs={xs} className={classes.container}>
            <Field
                type="number"
                name={RADIUS_FIELD_NAME}
                label="Radius"
                shrink={true}
                onChange={onChange}
                endAdornment={<span position="end">{unit}</span>}
                className={clsx("radius-input-field", className)}
                component={TextInputComponent}
                fullWidth={false}
                needShowEndAdornment
            />
        </Grid>
    );
};

RadiusInputForm.propTypes = {
    measurementSystem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const SET_RADIUS_TO_SEARCH_TRUCKS = "radiusInput";
export const RADIUS_FIELD_NAME = "radius";
export default compose(
    reduxForm({
        form: SET_RADIUS_TO_SEARCH_TRUCKS,
        initialValues: { [RADIUS_FIELD_NAME]: "10" },
        destroyOnUnmount: false,
    }),
    connect((state) => {
        return { measurementSystem: state.account.companyProfile.measurementSystem };
    }),
)(RadiusInputForm);
