import React from "react";
import _ from "lodash";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { makeStyles } from "@material-ui/core";
import { NO_COMPANY_ADMINS_MESSAGE } from "./constants/strings";

const useStyles = makeStyles(() => ({
    adminTable: {
        width: "30%",
    },
}));

const CompanyAdminsTable = ({ companyAdmins, fetchMore, count }) => {
    const classes = useStyles();
    const getTableData = (companyAdmins) => {
        const rows =
            companyAdmins &&
            companyAdmins.map((companyAdmin, index) => {
                const { phone, fullName } = companyAdmin;

                return {
                    phone,
                    fullName,
                    index,
                };
            });
        let columns = [
            {
                id: "fullName",
                label: "NAME",
            },
            {
                id: "phone",
                label: "PHONE",
            },
        ];

        return {
            rows: rows || [],
            columns: columns,
        };
    };
    const tableData = getTableData(companyAdmins);

    return (
        <div>
            {!_.isEmpty(companyAdmins) ? (
                <>
                    <h1 className="--text-center">Please contact admins to change settings</h1>
                    <TableWithStickyHeader
                        className={classes.adminTable}
                        rows={tableData.rows}
                        columns={tableData.columns}
                        fetchMore={fetchMore}
                        itemsCount={count}
                        items={companyAdmins}
                    />
                </>
            ) : (
                <>
                    <br />
                    <h1 className="--text-center">{NO_COMPANY_ADMINS_MESSAGE}</h1>
                    <br />
                </>
            )}
        </div>
    );
};

export default CompanyAdminsTable;
