import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

export const menuActionStyles = makeStyles((theme) => ({
    actionsStyles: {
        padding: "10px",

        "& .iconContainer": {
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
        },
        "& .titleContainer": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 15px 0 10px",
        },

        "& a": {
            textDecoration: "none",
        },
    },
    title: {
        textTransform: "uppercase",
        fontWeight: "bold",
        fintSize: "14px",
        lineHeight: "16px",
        color: theme.palette.primary.main,
    },
}));

const ActionMenuItem = (props) => {
    const classes = menuActionStyles();
    const { title, renderIcon, handleClick, redirect, history, redirectParams, disabled } = props;
    const redirectTo = (e) => {
        e.preventDefault();
        history.push(redirect, redirectParams);
    };

    return (
        <MenuItem className={clsx(classes.actionsStyles)} onClick={handleClick} disabled={disabled}>
            <div className="iconContainer">{renderIcon()}</div>
            <div className="titleContainer">
                {redirect ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a href="#" onClick={redirectTo}>
                        <Typography classes={{ root: classes.title }} align="center">
                            {title}
                        </Typography>
                    </a>
                ) : (
                    <Typography classes={{ root: classes.title }} align="center">
                        {title}
                    </Typography>
                )}
            </div>
        </MenuItem>
    );
};

ActionMenuItem.propTypes = {
    title: PropTypes.any,
    renderIcon: PropTypes.func,
    handleClick: PropTypes.func,
    redirect: PropTypes.string,
    history: PropTypes.object.isRequired,
    redirectParams: PropTypes.object.isRequired,
};

ActionMenuItem.defaultProps = {
    redirect: "",
    redirectParams: {},
};

export default withRouter(ActionMenuItem);
