import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import TruckPillWrapper from "../../core/pills";
import TruckItTimePicker from "../../core/form/timePicker";
import { Field, formValueSelector, getFormInitialValues } from "redux-form";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import clsx from "clsx";
import { JO_STATUS_COMPLETED_ID } from "../../../constants/maps";
import { connect } from "react-redux";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { JOLI_FIELD_ID } from "../constants";
import TextInputComponent from "../../core/form/textInputComponent";

export const useStyles = makeStyles((theme) => ({
    subTrucksWrapper: {
        "& .sub-trucks-pill": {
            textAlign: "left",
        },
    },
}));

const JobBoardTrucksTable = (props) => {
    const classes = useStyles();
    const {
        trucks,
        startDateName,
        specifyNumberOfTrucks,
        jobOrder,
        specifyNumberOfTrucksHandler,
        joliIndex,
        joliData,
        showHaulersColumns,
        form,
    } = props;
    const allTrucks = trucks.getAll();
    const notSmartDispatchJob = !jobOrder.smartDispatch;
    const showRemoveCell =
        notSmartDispatchJob &&
        allTrucks &&
        allTrucks.find((i) => i.isNewAdded || (jobOrder.status !== JO_STATUS_COMPLETED_ID && !i.hasTickets));

    return (
        !_.isEmpty(allTrucks) &&
        trucks.map((truck, index) => {
            const current = trucks.get(index);
            current.haulerRate = current.haulerRate || current.defaultRate;
            const field = truck;

            truck = current;

            const newStartDateName = startDateName || `${field}.startDate`;
            const canRemove = jobOrder.status === JO_STATUS_COMPLETED_ID ? current.isNewAdded : !current.hasTickets;

            const { truckName, name, isMyFleetTruck, id, truckId } = truck;

            return (
                <tr key={`${id || truckId}-${joliData.startDate}`}>
                    <td colSpan={2}>
                        <TruckPillWrapper
                            form={form}
                            title={truckName || name}
                            specifyNumberOfTrucks={specifyNumberOfTrucks}
                            specifyNumberOfTrucksHandler={(add) => specifyNumberOfTrucksHandler(add, joliIndex, index)}
                            needTrucksCount={current.needTrucksCount}
                            haulerName={null}
                            truckName={truckName || name}
                            isMyFleet={isMyFleetTruck}
                            truckId={id || truckId}
                        />
                        {!_.isEmpty(current.trucks) && (
                            <div className={clsx(classes.subTrucksWrapper, "sub-trucks-wrap")}>
                                {current.trucks.map((subTruck) => {
                                    const { truckName, id } = subTruck;

                                    return (
                                        <div key={id} className="sub-trucks-pill">
                                            <TruckPillWrapper
                                                form={form}
                                                title={truckName}
                                                showIcon={false}
                                                specifyNumberOfTrucks={false}
                                                iconContent={<LocalShippingIcon />}
                                                className="sub-truck"
                                                truckName={truckName}
                                                truckId={id || truckId}
                                                isMyFleet={false}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </td>
                    {showHaulersColumns && (
                        <td>
                            {!isMyFleetTruck && (
                                <Field
                                    name={`${field}.haulerRate`}
                                    type="number"
                                    className={clsx("hauler-rate-field", "job-order-input-field")}
                                    fullWidth={false}
                                    component={TextInputComponent}
                                />
                            )}
                        </td>
                    )}
                    <td className="start-date">
                        <Field name={newStartDateName} disabled={true} component={TruckItTimePicker} />
                    </td>
                    {showRemoveCell && (
                        <td>
                            {canRemove && (
                                <IconButton aria-label="delete" onClick={() => trucks.remove(index)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            )}
                        </td>
                    )}
                </tr>
            );
        })
    );
};

JobBoardTrucksTable.propTypes = {
    unlimited: PropTypes.bool,
    showHaulersColumns: PropTypes.bool,
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    summaryInfo: PropTypes.object,
    trucks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    customFooter: PropTypes.object,
    className: PropTypes.string,
    startDateName: PropTypes.string,
    showAsMyFleet: PropTypes.bool.isRequired,
    form: PropTypes.string,
    specifyNumberOfTrucks: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

JobBoardTrucksTable.defaultProps = {
    showAsMyFleet: false,
};

export default withRouter(
    connect((state, props) => {
        const formInitialValuesSelector = getFormInitialValues(props.form);
        const formSelector = formValueSelector(props.form);

        return {
            account: state.account,
            initialValues: formInitialValuesSelector(state),
            formValues: formSelector(state, "id", JOLI_FIELD_ID),
        };
    })(JobBoardTrucksTable),
);
