import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Validation } from "../../helpers/validation";
import TextInputComponent from "../core/form/textInputComponent";
import { Field } from "redux-form";

const PasswordField = (props) => {
    const { className, needShowEndAdornment } = props;

    return (
        <Field
            type="password"
            placeholder="••••••••"
            validate={[Validation.required, Validation.password]}
            name="password"
            needShowEndAdornment={needShowEndAdornment}
            label="Password"
            className={className}
            component={TextInputComponent}
        />
    );
};

PasswordField.propTypes = {
    className: PropTypes.string,
    needShowEndAdornment: PropTypes.bool,
};

PasswordField.defaultProps = {
    needShowEndAdornment: false,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(PasswordField),
);
