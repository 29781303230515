export const NOTIFICATION_EVENTS_LOADING = "notificationEventsLoading";
export const NOTIFICATION_EVENTS_LOADED = "notificationEventsLoaded";
export const NOTIFICATION_EVENTS_FAILED = "notificationEventsFailed";
export const NOTIFICATION_PUSH_EVENT_RECEIVED = "notificationPushEventReceived";
export const NOTIFICATION_SNACKBAR_RECEIVED = "notificationSnackbarReceived";
export const NOTIFICATION_SNACKBAR_REMOVE = "notificationSnackbarRemove";
export const NOTIFICATION_SNACKBARS_SHOW = "notificationSnackbarsShow";
export const NOTIFICATION_SNACKBARS_HIDE = "notificationSnackbarsHide";
export const NOTIFICATIONS_CLEAR = "notificationsClear";
export const NOTIFICATIONS_SET_ALERT_ZONE = "notificationsSetAlertZone";
export const NOTIFICATIONS_FILTERS_SET = "notificationsFiltersSet";
export const NOTIFICATIONS_REQUESTED_FILTERS_SET = "notificationsRequestedFiltersSet";
