import * as api from "../dataServers/trailers";
import { handleError } from "../helpers/global";
import { selectCompanyId } from "../selectors";
import { actions } from "../reducers/trailersReducer";
import handleFormErrors from "../helpers/handleFormErrors";

export const getTrailers = () => async (dispatch, getState) => {
    try {
        dispatch(actions.requestTrailers());
        const params = {};
        const { data } = await api.fetchTrailers(params);
        dispatch(actions.resultTrailers(data));
    } catch (e) {
        dispatch(actions.resultTrailers(handleError(e)));
    }
};

export const refreshTrailers = () => (dispatch) => {
    dispatch(getTrailers());
};

export const createTrailer = (values) => async (dispatch, getState) => {
    try {
        const companyId = selectCompanyId(getState());
        const body = {
            axle: values.axle,
            capacity: values.capacity,
            name: values.name,
            volumeCapacity: values.volumeCapacity,
            companyId,
        };
        await api.createTrailer(body);
    } catch (e) {
        handleFormErrors(e);
    }
};

export const updateTrailer = (trailerId, values) => async (dispatch, getState) => {
    try {
        const companyId = selectCompanyId(getState());
        const body = {
            axle: values.axle || null,
            capacity: values.capacity,
            name: values.name,
            volumeCapacity: values.volumeCapacity || null,
            companyId,
        };
        await api.updateTrailer(trailerId, body);
    } catch (e) {
        handleFormErrors(e);
    }
};

export const archiveTrailer = (id) => async (dispatch, getState) => {
    try {
        await api.archiveTrailer(id);
        await dispatch(refreshTrailers());
    } catch (e) {
        dispatch(actions.resultTrailers(handleError(e)));
    }
};
