import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";

import Box from "@material-ui/core/Box";

import JobOrdersListView from "../../jobs/jobOrdersListView";
import { selectAccount, selectCurrentProject } from "../../../selectors";
import {
    selectProjectHistoryJobs,
    selectProjectHistoryJobsCount,
    selectProjectHistoryJobsOrder,
    selectProjectHistoryLoading,
} from "../../../selectors/projectsSelectors";
import { getProjectHistoryJobs, updateProjectHistoryActiveOrder } from "../../../actions/projects";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";
import ProjectJobsHistoryFilters, { PROJECT_HISTORY_FILTERS_FORM } from "./ProjectJobsHistoryFilters";
import { GET_USER_INITIAL_FILTERS } from "../../global/searchAndFilters";
import { Divider } from "../../core";

const ProjectJobsHistory = () => {
    const dispatch = useDispatch();
    const jobOrders = useSelector(selectProjectHistoryJobs);
    const jobOrdersCount = useSelector(selectProjectHistoryJobsCount);
    const activeJobOrderParam = useSelector(selectProjectHistoryJobsOrder);
    const currentProject = useSelector(selectCurrentProject);
    const loading = useSelector(selectProjectHistoryLoading);
    const account = useSelector(selectAccount);
    const getMoreProjectJobs = (concat) => dispatch(getProjectHistoryJobs(concat));

    const handleSort = (sortParam) => {
        dispatch(updateProjectHistoryActiveOrder(sortParam));
    };

    useEffect(() => {
        dispatch(getProjectHistoryJobs());
    }, [activeJobOrderParam, currentProject?.id]);

    return (
        <div className={clsx(loading && LOADER_WHITE_OVERLAY)}>
            <Divider marginTop={0} />
            {loading && <Loader />}
            <ProjectJobsHistoryFilters initialValues={GET_USER_INITIAL_FILTERS(PROJECT_HISTORY_FILTERS_FORM)} />
            <Box sx={{ maxHeight: "570px", overflowY: "scroll" }}>
                <JobOrdersListView
                    hideProjectColumn
                    jobOrders={jobOrders}
                    account={account}
                    handleSort={handleSort}
                    jobOrdersCount={jobOrdersCount}
                    fetchMore={getMoreProjectJobs}
                    activeJobOrderParam={activeJobOrderParam}
                    isProjectHistoryPage={true}
                />
            </Box>
            {_.isEmpty(jobOrders) && <h1 className="--text-center">No data to show</h1>}
        </div>
    );
};

export default ProjectJobsHistory;
