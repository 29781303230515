import dataProvider from "../helpers/api";
import { GLOBAL_ENDPOINTS, STRIPE, USER } from "../constants/endpoints";

export const signInUser = (data) => {
    return dataProvider({
        url: USER.SIGN_IN(),
        method: "POST",
        data,
    });
};
export const signOutUser = () => {
    return dataProvider({
        url: USER.SIGN_OUT(),
        method: "POST",
    });
};

export const getUsers = (params = {}) => {
    return dataProvider({
        url: USER.ALL(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getInspectorUsers = (params = {}) => {
    return dataProvider({
        url: USER.INSPECTORS(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const userForgotPassword = (data, method = "POST") => {
    return dataProvider({
        url: USER.FORGOT_PASSWORD(),
        method,
        data,
    });
};

export const userUpdatePassword = (data) => {
    return dataProvider({
        url: USER.UPDATE_PASSWORD(),
        method: "PUT",
        data,
    });
};

export const userSignUp = (data) => {
    return dataProvider({
        url: USER.SIGN_UP(),
        method: "POST",
        data,
    });
};

export const getUserProfile = (companyId) => {
    return dataProvider({
        url: USER.USER_PROFILE(companyId),
        method: "GET",
    });
};

export const saveUserProfile = (companyId, data) => {
    return dataProvider({
        url: USER.USER_PROFILE(companyId),
        method: "PUT",
        data,
    });
};

export const getTimeZones = (params = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.TIME_ZONES(),
        method: "GET",
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getUserFiltersTruckers = (params = {}) => {
    return dataProvider({
        url: USER.FILTERS_TRUCKERS(),
        method: "GET",
        params,
    });
};

export const getUserFiltersDefaultTruckers = (params = {}) => {
    return dataProvider({
        url: USER.FILTERS_TRUCKERS_DEFAULT(),
        method: "GET",
        params,
    });
};

export const createUserFilterTrucker = (data) => {
    return dataProvider({
        url: USER.FILTERS_TRUCKERS(),
        method: "POST",
        data,
    });
};
export const editUserFilterTrucker = (filterId, data) => {
    return dataProvider({
        url: `${USER.FILTERS_TRUCKERS()}/${filterId}`,
        method: "PUT",
        data,
    });
};

export const editUserFilterTruckerAPI2 = (filterId, data) => {
    return dataProvider({
        url: `${USER.FILTERS_TRUCKERS_API_2()}/${filterId}`,
        method: "PUT",
        data,
    });
};

export const deleteUserFilterById = (filterId) => {
    return dataProvider({
        url: `${USER.FILTERS_TRUCKERS()}/${filterId}`,
        method: "DELETE",
    });
};

export const getUserNotificationsProfile = (userId, params = {}) => {
    return dataProvider({
        url: USER.NOTIFICATIONS(userId),
        method: "GET",
        params,
    });
};

export const fetchCompanyNotifications = (companyId) => {
    return dataProvider({
        url: USER.COMPANY_NOTIFICATIONS(companyId),
        method: "GET",
    });
};

export const updateCompanyNotificationsRequest = (companyId, data) => {
    return dataProvider({
        url: USER.COMPANY_NOTIFICATIONS(companyId),
        method: "PUT",
        data,
    });
};

export const updateUserNotificationsProfile = (userId, data = {}) => {
    return dataProvider({
        url: USER.NOTIFICATIONS(userId),
        method: "PUT",
        data,
    });
};

export const assignUserRegion = (userId, data = {}) => {
    return dataProvider({
        url: USER.ASSIGN_REGION(userId),
        method: "POST",
        data,
    });
};

export const agreeWithTermsAndConditions = () => {
    return dataProvider({
        url: USER.ACCEPT_TERMS_AND_CONDITIONS(),
        method: "POST",
    });
};

export const createStripeSubscription = (accountType) => {
    return dataProvider({
        url: STRIPE.CREATE_SUBSCRIPTION(),
        method: "POST",
        data: {
            priceId: accountType,
        },
    });
};

export const createStripeCustomer = (currentUserEmail) => {
    return dataProvider({
        url: STRIPE.CREATE_CUSTOMER(),
        method: "POST",
        data: {
            email: currentUserEmail,
        },
    });
};

export const updateUserPaymentDetails = () => {
    return dataProvider({
        url: STRIPE.UPDATE_PAYMENT_DETAILS(),
        method: "POST",
    });
};

export const getStates = (params) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.GET_STATES(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getPricePlans = (accountType) => {
    return dataProvider({
        url: STRIPE.GET_PRICE_PLANS(),
        method: "GET",
        params: { accountType },
    });
};

export const getUserActivities = (userId, params) => {
    return dataProvider({
        url: USER.ACTIVITY(userId),
        params,
    });
};

export const fetchCurrentUserData = () => {
    return dataProvider({
        url: USER.GET_CURRENT_USER_DATA(),
    });
};

export const signInAsOtherCompanyUser = (userId) => {
    return dataProvider({
        url: USER.SIGN_IN_AS_OTHER_COMPANY_USER(userId),
        method: "POST",
    });
};
