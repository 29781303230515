import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Field, reduxForm } from "redux-form";
import TextInputComponent from "../components/core/form/textInputComponent";
import { ButtonsGroup } from "../components/core/buttons/buttonsGroup";
import { PrimaryButton } from "../components/core/buttons/primaryButton";
import { userUpdatePassword } from "../dataServers/user";
import { LOGIN_STYLES } from "../styles/reusableStyles";
import { compose } from "redux";
import ErrorNotification from "../components/core/notification";
import Loader from "../components/core/loader";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../constants/global";
import { Validation, VALIDATION_ERRORS } from "../helpers/validation";
import PasswordField from "../components/global/passwordField";
import { useDispatch, useSelector } from "react-redux";
import { emitUserLoggedOutEvent, updateUserHasTemporaryPasswordField } from "../actions/globalActions";
import { ROUTE_ANALYTICS, ROUTE_DASHBOARD } from "../routes/globalRoutes";
import { SimpleLink } from "../components/core/link";
import { IS_INSPECTOR_USER } from "../constants/maps";
import { updateAccountData } from "../helpers/global";

const validate = ({ password, confirmPassword }) => {
    const errors = {};

    if (password !== confirmPassword) {
        errors.confirmPassword = VALIDATION_ERRORS.passwordConfirm;
    }

    return errors;
};

const UpdatePasswordPage = (props) => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const [state, setState] = useState({ isLoading: false, errorMessage: "", successMessage: "" });
    const { isLoading, errorMessage, successMessage } = state;
    const loginStyles = LOGIN_STYLES();
    const { handleSubmit, history } = props;

    const clearErrorMessage = () => {
        return setState({
            ...state,
            errorMessage: "",
        });
    };

    const returnToLogin = () => {
        setState({
            ...state,
            isLoading: true,
        });
        emitUserLoggedOutEvent().finally(() => {
            setState({
                ...state,
                isLoading: false,
            });
        });
    };

    const updatePassword = ({ password }) => {
        setState({ ...state, isLoading: true, successMessage: "", errorMessage: "" });

        userUpdatePassword({
            password,
        })
            .then((data) => {
                setState({
                    ...state,
                    isLoading: false,
                    successMessage: ["Password was successfully changed."],
                });

                dispatch(updateUserHasTemporaryPasswordField());

                updateAccountData(false, null, "hasTemporaryPassword");

                if (IS_INSPECTOR_USER(account.role)) {
                    history.push(ROUTE_ANALYTICS.TICKETS);
                } else {
                    history.push(ROUTE_DASHBOARD);
                }
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                    errorMessage: PROCESS_SERVER_ERROR(error),
                });
            });
    };

    useEffect(() => {
        setState({
            ...state,
            errorMessage: ["To continue you need to change your temporary password"],
        });
    }, []);

    return (
        <div className={clsx(loginStyles.loginStyles, isLoading && LOADER_WHITE_OVERLAY)}>
            <div className={loginStyles.uiIconLogo} />
            <br />
            <br />
            <div>
                <form onSubmit={handleSubmit(updatePassword)} noValidate={true}>
                    <React.Fragment>
                        <p className="--text-center">Enter your new password (Must be at least 5 characters)</p>
                        <br />
                        <PasswordField />
                        <br />
                        <Field
                            type="password"
                            validate={[Validation.required, Validation.password]}
                            name="confirmPassword"
                            needShowEndAdornment={false}
                            label="Confirm Password"
                            component={TextInputComponent}
                        />
                        <br />
                        <ButtonsGroup className="buttons-set">
                            <PrimaryButton type="submit" size={"large"}>
                                Apply
                            </PrimaryButton>
                        </ButtonsGroup>
                        <br />
                        <SimpleLink className="-primary-color" onClick={returnToLogin}>
                            Return To Login Page
                        </SimpleLink>
                        {!!successMessage && <ErrorNotification type={"success"} message={successMessage} />}
                    </React.Fragment>
                </form>
            </div>
            {!!errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => clearErrorMessage() }} />
            )}

            {isLoading && <Loader />}
        </div>
    );
};

UpdatePasswordPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(
    compose(
        reduxForm({
            form: "userUpdatePasswordForm",
            validate,
        }),
    )(UpdatePasswordPage),
);
