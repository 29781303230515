import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import ReportSubNav from "../reportSubNav";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Container from "@material-ui/core/Container";
import Tabs from "../../core/tabsCustom";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../../styles/reusableStyles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import AsyncAutocompleteComponent from "../../core/form/asyncAutocompleteComponent";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { compose } from "redux";
import moment from "moment";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../../../constants/global";
import {
    createSavedReportFilters,
    downloadReportPDF,
    editSavedReportFilters,
    getReportData,
    getReportFiltersMenu,
    getReportTotals,
    getSavedReportFilters,
    removeSavedReportFilters,
} from "../../../dataServers/reportAndAnalytics";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import AppModal from "../../core/modal";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { ActionLink, SimpleLink } from "../../core/link";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ErrorNotification from "../../core/notification";
import Loader from "../../core/loader";
import CreateFilterForm, { CREATE_FILTERS_SET_FORM } from "./createFilterForm";
import SuccessNotification from "../../core/successNotification";
import IconButton from "@material-ui/core/IconButton";
import ReportTable from "./reportTable";
import { GLOBAL_COUNT_TO_LOAD } from "../../../constants/endpoints";
import GetAppIcon from "@material-ui/icons/GetApp";
import ConfirmationModal from "../../core/confirmationModal";
import { DividerThin } from "../../core/divider";
import {
    ADDITIONAL_FIELDS,
    DATE_ALL_VALUE,
    DATE_INITIAL_VALUE,
    DATE_OPTIONS,
    DATE_RANGE_VALUE,
    defineDateRange,
    getFilters,
    getFiltersForAdditionalReports,
    REPORTING_ADDITIONAL_FILTERS,
    REPORTING_FILTERS_DATE_ID,
    REPORTING_FILTERS_DRIVER_ID,
    REPORTING_FILTERS_HAULER_ID,
    REPORTING_FILTERS_PAYLOAD_ID,
    REPORTING_FILTERS_PROJECT_ID,
    REPORTING_FILTERS_RANGE_ID,
    REPORTING_FILTERS_REGIONS_ID,
    REPORTING_FILTERS_SITE_ID,
    REPORTING_FILTERS_TRUCK_ID,
    REPORTING_GROUP_BY_ID,
    REPORTING_MAIN_FILTERS,
    REPORTING_SECONDARY_FILTERS,
} from "./constants";
import DownloadReportForm, { DOWNLOAD_REPORT_FORM, DOWNLOAD_REPORT_FORM_FIELD } from "./downloadReportForm";
import { loadOptions } from "../../../helpers/global";
import { getReportNames } from "../../../actions/reporting";
import {
    selectAdditionReports,
    selectFormatRegions,
    selectReportNamesMeta,
    selectReportsNames,
} from "../../../selectors/index";

const useStyles = makeStyles((theme) => ({
    reportWrapper: {
        "& .main-filter": {
            "& .form-view.tiny-view": {
                border: `1px solid ${theme.palette.general.softGreen}`,
            },
        },
        "& .saved-filters": {
            "& .___placeholder": {
                top: 6,
            },
        },
        "& .filters": {
            "& .___multi-value__remove": {
                display: "none",
            },
            "& .___placeholder": {
                top: 8,
            },
        },
        "& .remove-applied-filter": {
            color: theme.palette.general.softRed,
        },
        "& .download-icon": {
            color: "#fff",
            marginRight: 10,
        },
        "& .action-link": {
            justifyContent: "flex-end",
            textAlign: "right",
        },
    },
    helpIcon: {
        color: theme.palette.general.softGreen,
        fontSize: 9,
        position: "relative",
        top: -8,
        backgroundColor: "#fff",
    },
    reportsAutoCompleteComponent: {
        position: "relative",
        "&.async-select-wrapper": {
            width: "100%",
            textTransform: "capitalize",
        },
    },
}));

const ReportWrapper = (props) => {
    const classes = useStyles();
    const {
        formValues,
        reportFormValue,
        form,
        dispatch,
        reportNames,
        reportNamesLoading,
        reportNamesError,
        additionalReports,
        regions,
        isHasAdditionalReports,
    } = props;
    const { savedFilters, report } = formValues;
    const REPORTS_TAB = {
        COST: 0,
        ADDITIONAL: 1,
    };
    const wrapper = PAGE_GLOBAL_STYLES();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();
    const [mainFilter, setMainFilter] = useState(null);
    const [confirmationRemoveFilterSetMessage, setConfirmationRemoveFilterSetMessage] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [totalsData, setTotalsData] = useState(null);
    const [reportDataCount, setReportDataCount] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [showCreateSetModal, setShowCreateSetModal] = useState(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const isStartTimeReport = activeTab === REPORTS_TAB.ADDITIONAL && report?.value === "start_time";

    const saveMainFilter = (filter, selectedValue) => {
        !selectedValue && filter === mainFilter && setMainFilter(null);
        !mainFilter && setMainFilter(filter);
        filter === mainFilter &&
            REPORTING_MAIN_FILTERS.forEach((filter) => {
                filter.id !== mainFilter && dispatch(change(form, filter.id, null));
            });
    };
    const resetTopFilters = () => {
        if (activeTab === REPORTS_TAB.COST) {
            dispatch(change(form, "savedFilters", null));
            resetDates();
            resetFilters();
        } else {
            dispatch(change(form, "report", null));
            resetDates();
        }
    };
    const resetDates = () => {
        if (activeTab === REPORTS_TAB.COST) {
            dispatch(change(form, REPORTING_FILTERS_DATE_ID, DATE_INITIAL_VALUE));
            dispatch(change(form, START_DATE_NAME, moment()));
            dispatch(change(form, END_DATE_NAME, moment()));
        } else {
            dispatch(change(form, ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID, DATE_INITIAL_VALUE));
            dispatch(change(form, ADDITIONAL_FIELDS.START_DATE_NAME, moment()));
            dispatch(change(form, ADDITIONAL_FIELDS.END_DATE_NAME, moment()));
        }
    };
    const resetFilters = () => {
        setMainFilter(null);
        setReportData(null);
        setReportDataCount(null);
        REPORTING_MAIN_FILTERS.forEach((filter) => {
            dispatch(change(form, filter.id, null));
        });
        dispatch(change(form, REPORTING_FILTERS_DRIVER_ID, null));
    };
    const loadSavedFilters = (keywords, params) => {
        return getSavedReportFilters().then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: i.name, filterBody: i.filterBody }));

            return {
                options: results,
                hasMore: data.meta.count > (params.loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };
    const applyFiltersForReport = (data) => {
        const { filterBody } = data || {};
        const keys = filterBody ? Object.keys(filterBody) : [];

        resetDates();
        resetFilters();

        if (!data) {
            return false;
        }

        keys.forEach((key) => {
            const data = _.isArray(filterBody[key])
                ? filterBody[key].map((i) => ({
                      value: i.id,
                      label: i.name || i.externalRef || i.deviceName || i.jobNumber,
                  }))
                : key === REPORTING_FILTERS_DATE_ID
                ? DATE_OPTIONS.find((i) => i.value === filterBody[key])
                : filterBody[key];

            dispatch(change(form, key, data));
        });

        if (!filterBody[START_DATE_NAME] && !filterBody[END_DATE_NAME] && filterBody[REPORTING_FILTERS_DATE_ID]) {
            const dates = defineDateRange(filterBody[REPORTING_FILTERS_DATE_ID]);

            dispatch(change(form, END_DATE_NAME, dates[END_DATE_NAME]));
            dispatch(change(form, START_DATE_NAME, dates[START_DATE_NAME]));
        }

        if (!filterBody[REPORTING_FILTERS_DATE_ID]) {
            dispatch(change(form, REPORTING_FILTERS_DATE_ID, DATE_RANGE_VALUE));
        }

        if (!filterBody[REPORTING_FILTERS_DATE_ID] && !filterBody[START_DATE_NAME] && !filterBody[END_DATE_NAME]) {
            dispatch(change(form, REPORTING_FILTERS_DATE_ID, DATE_ALL_VALUE));
        }
    };
    const saveFiltersSet = (values) => {
        const { name, id } = values;
        const {
            [REPORTING_FILTERS_TRUCK_ID]: trucks,
            [REPORTING_FILTERS_PROJECT_ID]: projects,
            [REPORTING_FILTERS_SITE_ID]: sites,
            [REPORTING_FILTERS_PAYLOAD_ID]: payloads,
            [REPORTING_FILTERS_DRIVER_ID]: drivers,
            [REPORTING_FILTERS_HAULER_ID]: haulers,
            [REPORTING_FILTERS_DATE_ID]: date,
            [START_DATE_NAME]: startDate,
            [END_DATE_NAME]: endDate,
        } = formValues;
        const request = id ? editSavedReportFilters : createSavedReportFilters;

        setLoading(true);

        request({
            filterBody: {
                [END_DATE_NAME]:
                    date && date.value === REPORTING_FILTERS_RANGE_ID
                        ? moment(endDate).format(SERVER_DATE_FORMAT)
                        : undefined,
                [START_DATE_NAME]:
                    date && date.value === REPORTING_FILTERS_RANGE_ID
                        ? moment(startDate).format(SERVER_DATE_FORMAT)
                        : undefined,
                [REPORTING_FILTERS_DATE_ID]:
                    date && date.value === null
                        ? undefined
                        : date && date.value === REPORTING_FILTERS_RANGE_ID
                        ? undefined
                        : date && date.value,
                [REPORTING_FILTERS_HAULER_ID]: haulers ? haulers.map((i) => i.value) : undefined,
                [REPORTING_FILTERS_DRIVER_ID]: drivers ? drivers.map((i) => i.value) : undefined,
                [REPORTING_FILTERS_PAYLOAD_ID]: payloads ? payloads.map((i) => i.value) : undefined,
                [REPORTING_FILTERS_PROJECT_ID]: projects ? projects.map((i) => i.value) : undefined,
                [REPORTING_FILTERS_SITE_ID]: sites ? sites.map((i) => i.value) : undefined,
                [REPORTING_FILTERS_TRUCK_ID]: trucks ? trucks.map((i) => i.value) : undefined,
            },
            name,
        })
            .then(({ data }) => {
                closeCreateFilterModal();
                setLoading(false);
                setMessage(["Filter successfully saved"]);
                dispatch(change(form, "savedFilters", { value: data.id, label: data.name }));
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };
    const closeCreateFilterModal = () => {
        setShowCreateSetModal(false);
        dispatch(change(CREATE_FILTERS_SET_FORM, "name", null));
    };
    const removeAppliedFilter = () => {
        setLoading(true);
        setConfirmationRemoveFilterSetMessage("");

        removeSavedReportFilters(savedFilters.value)
            .then(() => {
                setLoading(false);
                resetTopFilters();
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };
    const showConfirmationRemoveModal = () => {
        setConfirmationRemoveFilterSetMessage("Are you sure you want to remove selected filter set?");
    };
    const loadDateOptions = () => {
        return Promise.resolve(DATE_OPTIONS).then(() => {
            return {
                options: DATE_OPTIONS,
                hasMore: false,
                page: 1,
            };
        });
    };
    const loadGroupByOptions = () => {
        const results = REPORTING_MAIN_FILTERS.map((i) => ({ value: i.id, label: i.label }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };
    const handleDateChange = (field) => {
        dispatch(change(form, field, DATE_RANGE_VALUE));
        resetFilters();
    };
    const onChangeDateFilter = (value) => {
        const dates = defineDateRange(value.value);

        dispatch(change(form, START_DATE_NAME, dates[START_DATE_NAME]));
        dispatch(change(form, END_DATE_NAME, dates[END_DATE_NAME]));
        resetFilters();
    };
    const onChangeAdditionalDateFilter = (value) => {
        const dates = defineDateRange(value.value);
        dispatch(change(form, ADDITIONAL_FIELDS.START_DATE_NAME, dates[START_DATE_NAME]));
        dispatch(change(form, ADDITIONAL_FIELDS.END_DATE_NAME, dates[END_DATE_NAME]));
    };
    const loadReportData = (concat) => {
        const currentPage = reportData ? Math.ceil(reportData.length / GLOBAL_COUNT_TO_LOAD) : 1;
        const nextPage = concat ? +currentPage + 1 : 1;
        const params = {
            ...getFilters(formValues, mainFilter || REPORTING_FILTERS_DATE_ID),
            groupBy: formValues[REPORTING_GROUP_BY_ID].value,
            page: concat ? nextPage : 1,
        };

        setLoading(true);

        return getReportData(params, getReportNameForRequest())
            .then((data = {}) => {
                const newOrderData = concat ? [...reportData, ...data.data] : data.data;
                setLoading(false);
                setReportData(newOrderData);
                data.meta && setReportDataCount(data.meta.count);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const loadTotalsData = () => {
        const params = { ...getFilters(formValues, mainFilter || REPORTING_FILTERS_DATE_ID) };

        setLoading(true);

        return getReportTotals(params, formValues[REPORTING_GROUP_BY_ID].value)
            .then((data) => {
                setLoading(false);
                setTotalsData(data.data);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const getReportNameForRequest = () => {
        if (activeTab === REPORTS_TAB.COST) return reportNames && reportNames.filter((i) => i.isGeneral)[0].name;
        return report && report.value;
    };

    const handleDownloadsReport = () => {
        setLoading(true);
        let payload = {};
        const filters = getFilters(formValues, mainFilter || REPORTING_FILTERS_DATE_ID);
        if (activeTab === REPORTS_TAB.ADDITIONAL) {
            const additionalFilters = getFiltersForAdditionalReports(formValues, isStartTimeReport);
            payload = {
                ...additionalFilters,
            };
        } else {
            payload = {
                ...filters,
                groupBy: formValues[REPORTING_GROUP_BY_ID].value,
            };
        }
        payload.reportType = reportFormValue;
        const reportName = getReportNameForRequest();
        reportName &&
            downloadReportPDF(payload, reportName)
                .then(() => {
                    setShowDownloadModal(false);
                    setLoading(false);
                })
                .catch(async (e) => {
                    const error = JSON.parse(await e.text());
                    setLoading(false);
                    setError(PROCESS_SERVER_ERROR(error));
                });
    };
    const loadOptionsMenu = (filterId, keywords, { params }) => {
        const requestParams = getFilters(formValues, mainFilter, keywords, params, filterId);

        return getReportFiltersMenu({ ...requestParams, cancelToken: params.cancelToken }, filterId).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.deviceName || i.fullName || i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (params.loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const renderReportContent = ({ general, additional }) => {
        if (activeTab === REPORTS_TAB.COST) return general();
        if (activeTab === REPORTS_TAB.ADDITIONAL) return additional();
    };

    const renderGeneralReport = () => (
        <div className={clsx(pageActionsStyles.pageActions)}>
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Grid container component="div" alignItems={"center"} spacing={1}>
                        {REPORTING_MAIN_FILTERS.map((filter) => {
                            const filterBanned =
                                mainFilter &&
                                REPORTING_SECONDARY_FILTERS[mainFilter] &&
                                !REPORTING_SECONDARY_FILTERS[mainFilter].includes(filter.id);

                            return (
                                <React.Fragment key={filter.id}>
                                    <Grid item xs={3} component="div" key={filter.id}>
                                        <div className={clsx(filter.id === mainFilter && "main-filter", "filters")}>
                                            <Field
                                                name={filter.id}
                                                className="form-view tiny-view"
                                                disabled={mainFilter ? filterBanned : filter.disabled}
                                                loadOptions={(value, params) =>
                                                    loadOptionsMenu(filter.id, value, params)
                                                }
                                                placeholder={
                                                    <span>
                                                        {filter.label}
                                                        {filter.id === mainFilter && (
                                                            <span className={classes.helpIcon}>Major Filter</span>
                                                        )}
                                                    </span>
                                                }
                                                onChange={(value) => saveMainFilter(filter.id, value)}
                                                isMulti={filter.isMulti}
                                                component={AsyncAutocompleteComponent}
                                            />
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container justify={"flex-end"}>
                        {_.isEmpty(savedFilters) ? (
                            <ActionLink>
                                <SaveIcon />
                                <SimpleLink onClick={() => setShowCreateSetModal(true)}>Save Filters Set</SimpleLink>
                            </ActionLink>
                        ) : (
                            <ActionLink>
                                <EditIcon />
                                <SimpleLink onClick={() => setShowCreateSetModal(true)}>Edit Filters Set</SimpleLink>
                            </ActionLink>
                        )}
                        <ActionLink>
                            <RotateLeftIcon className={clsx("reset-applied-filter")} />
                            <SimpleLink onClick={resetFilters}>Reset Filters</SimpleLink>
                        </ActionLink>
                    </Grid>
                </Grid>
            </Grid>
            <DividerThin />
            <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={3}>
                    <Field
                        name={REPORTING_GROUP_BY_ID}
                        className="form-view tiny-view"
                        loadOptions={loadGroupByOptions}
                        placeholder="Group By"
                        component={AsyncAutocompleteComponent}
                    />
                </Grid>
                <Grid item>
                    <ButtonsGroup>
                        <PrimaryButton onClick={() => setShowDownloadModal(true)} title="Download Tickets Report">
                            <GetAppIcon className="download-icon" />
                            Download
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                loadReportData(false);
                                loadTotalsData();
                            }}
                        >
                            See Report
                        </PrimaryButton>
                    </ButtonsGroup>
                </Grid>
            </Grid>
        </div>
    );

    const renderAdditionalReport = () => (
        <div className={clsx(pageActionsStyles.pageActions)}>
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Grid container component="div" alignItems={"center"} spacing={1}>
                        {REPORTING_ADDITIONAL_FILTERS.map((filterSettings) => {
                            return (
                                <React.Fragment key={filterSettings.id}>
                                    <Grid item xs={4} component="div" key={filterSettings.id}>
                                        <Field
                                            loadOptions={() => loadOptions(regions)}
                                            className="form-view tiny-view"
                                            selectAllHandler={(name) => dispatch(change(form, name, regions))}
                                            component={AsyncAutocompleteComponent}
                                            {...filterSettings}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <DividerThin />
            <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item xs={3}></Grid>
                <Grid item>
                    <ButtonsGroup>
                        <PrimaryButton
                            disabled={!report}
                            onClick={() => setShowDownloadModal(true)}
                            title="Download Tickets Report"
                        >
                            <GetAppIcon className="download-icon" />
                            Download
                        </PrimaryButton>
                    </ButtonsGroup>
                </Grid>
            </Grid>
        </div>
    );

    const renderCostDate = () => (
        <>
            <Grid item xs={3} component="div">
                <Field
                    name={REPORTING_FILTERS_DATE_ID}
                    onChange={onChangeDateFilter}
                    className="form-view tiny-view"
                    loadOptions={loadDateOptions}
                    placeholder="Date"
                    component={AsyncAutocompleteComponent}
                />
            </Grid>
            {formValues[REPORTING_FILTERS_DATE_ID] && formValues[REPORTING_FILTERS_DATE_ID].value !== null && (
                <Grid item xs={5} component="div">
                    <DatesRange
                        form={form}
                        handleStartDateChange={() => handleDateChange(REPORTING_FILTERS_DATE_ID)}
                        handleEndDateChange={() => handleDateChange(REPORTING_FILTERS_DATE_ID)}
                    />
                </Grid>
            )}
        </>
    );

    const renderAdditionalDate = () => (
        <>
            {!isStartTimeReport && (
                <Grid item xs={3} component="div">
                    <Field
                        name={ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID}
                        onChange={onChangeAdditionalDateFilter}
                        className="form-view tiny-view"
                        loadOptions={loadDateOptions}
                        placeholder="Date"
                        component={AsyncAutocompleteComponent}
                    />
                </Grid>
            )}
            {formValues[ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID] &&
                formValues[ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID].value !== null && (
                    <Grid item xs={5} component="div">
                        <DatesRange
                            startDateName={ADDITIONAL_FIELDS.START_DATE_NAME}
                            endDateName={ADDITIONAL_FIELDS.END_DATE_NAME}
                            form={form}
                            handleStartDateChange={() => handleDateChange(ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID)}
                            handleEndDateChange={() => handleDateChange(ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID)}
                            showEndDate={!isStartTimeReport}
                        />
                    </Grid>
                )}
        </>
    );

    const renderSavedFilters = () => (
        <Grid item xs={3} component="div">
            <Field
                name="savedFilters"
                onChange={applyFiltersForReport}
                className="form-view tiny-view saved-filters"
                loadOptions={loadSavedFilters}
                placeholder="Saved Filters"
                isClearable={true}
                component={AsyncAutocompleteComponent}
            />
        </Grid>
    );

    const renderReports = () => (
        <Grid item xs={3} component="div">
            <Field
                name="report"
                onChange={() => {}}
                className="form-view tiny-view saved-filters"
                loadOptions={() => loadOptions(additionalReports)}
                placeholder="Reports"
                isClearable={true}
                component={AsyncAutocompleteComponent}
                wrapperClassName={classes.reportsAutoCompleteComponent}
            />
        </Grid>
    );

    useEffect(() => {
        dispatch(getReportNames());
    }, []);

    return (
        <div className={clsx(classes.reportWrapper, (isLoading || reportNamesLoading) && LOADER_WHITE_OVERLAY)}>
            {(isLoading || reportNamesLoading) && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {reportNamesError && <ErrorNotification error={reportNamesError} />}
            {message && <SuccessNotification message={message} config={{ onClose: () => setMessage(null) }} />}
            <ReportSubNav />
            <Container className={wrapper.globalPageStyles}>
                <Tabs
                    tabsHeaders={[{ label: "Cost Report" }, isHasAdditionalReports && { label: "Additional Reports" }]}
                    onChangeTab={(tab) => setActiveTab(tab)}
                />
            </Container>
            <Container className={wrapper.globalPageStyles}>
                <div className={clsx(pageActionsStyles.pageActions)}>
                    <Grid container component="div" alignItems={"center"} justify={"space-between"}>
                        <Grid item xs={11}>
                            <Grid container justify={"flex-start"} alignItems={"center"} spacing={1}>
                                {renderReportContent({
                                    general: renderSavedFilters,
                                    additional: renderReports,
                                })}
                                {!_.isEmpty(savedFilters) && !activeTab && (
                                    <Grid item component="div">
                                        <IconButton title="Remove Applied Filter" onClick={showConfirmationRemoveModal}>
                                            <DeleteOutlineIcon className={clsx("remove-applied-filter")} />
                                        </IconButton>
                                    </Grid>
                                )}
                                {renderReportContent({
                                    general: renderCostDate,
                                    additional: renderAdditionalDate,
                                })}
                            </Grid>
                        </Grid>
                        <Grid item component="div">
                            <IconButton title="Reset Date and Saved Filters" onClick={resetTopFilters}>
                                <RotateLeftIcon className={clsx("reset-applied-filter")} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                {renderReportContent({ general: renderGeneralReport, additional: renderAdditionalReport })}
                {showCreateSetModal && (
                    <CreateFilterForm
                        saveFiltersSet={saveFiltersSet}
                        initialValues={
                            savedFilters
                                ? {
                                      name: savedFilters.label,
                                      id: savedFilters.value,
                                  }
                                : {}
                        }
                        savedFilter={
                            savedFilters
                                ? {
                                      name: savedFilters.label,
                                      id: savedFilters.value,
                                  }
                                : {}
                        }
                        closeCreateFilterModal={closeCreateFilterModal}
                        showCreateSetModal={showCreateSetModal}
                    />
                )}
                <ConfirmationModal
                    isOpen={!!confirmationRemoveFilterSetMessage}
                    question={confirmationRemoveFilterSetMessage}
                    yesHandler={removeAppliedFilter}
                    noHandler={() => setConfirmationRemoveFilterSetMessage("")}
                />
            </Container>
            {reportData && !activeTab ? (
                <ReportTable
                    mainFilter={mainFilter || REPORTING_FILTERS_DATE_ID}
                    reportData={reportData}
                    totalsData={totalsData}
                    groupBy={formValues[REPORTING_GROUP_BY_ID].value}
                    reportDataCount={reportDataCount || 0}
                    loadMoreReportData={loadReportData}
                    activeTab={activeTab}
                />
            ) : (
                !activeTab && (
                    <Container className={wrapper.globalPageStyles}>
                        <br />
                        <h2 className={"--text-center"}>Please select filters and press 'See Report' to see report</h2>
                    </Container>
                )
            )}
            <br />
            <br />
            <br />
            <br />
            <AppModal isOpen={showDownloadModal} closeModal={() => setShowDownloadModal(false)}>
                <DownloadReportForm
                    loading={isLoading}
                    download={handleDownloadsReport}
                    reportValue={reportFormValue}
                    onClose={() => setShowDownloadModal(false)}
                    isStartTimeReport={isStartTimeReport}
                />
            </AppModal>
        </div>
    );
};

ReportWrapper.propTypes = {
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
};

ReportWrapper.defaultProps = {};

export default withRouter(
    compose(
        reduxForm({
            form: "reportFilersForm",
            initialValues: {
                [REPORTING_FILTERS_DATE_ID]: DATE_INITIAL_VALUE,
                [ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID]: DATE_INITIAL_VALUE,
                [ADDITIONAL_FIELDS.START_DATE_NAME]: moment(),
                [ADDITIONAL_FIELDS.END_DATE_NAME]: moment(),
                [START_DATE_NAME]: moment(),
                [END_DATE_NAME]: moment(),
                [REPORTING_GROUP_BY_ID]: { value: REPORTING_FILTERS_TRUCK_ID, label: "Truck" },
            },
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(
                    state,
                    "savedFilters",
                    "report",
                    REPORTING_GROUP_BY_ID,
                    REPORTING_FILTERS_DATE_ID,
                    REPORTING_FILTERS_HAULER_ID,
                    REPORTING_FILTERS_TRUCK_ID,
                    REPORTING_FILTERS_DRIVER_ID,
                    REPORTING_FILTERS_PAYLOAD_ID,
                    REPORTING_FILTERS_SITE_ID,
                    REPORTING_FILTERS_SITE_ID,
                    REPORTING_FILTERS_PROJECT_ID,
                    REPORTING_FILTERS_REGIONS_ID,
                    START_DATE_NAME,
                    END_DATE_NAME,
                    ADDITIONAL_FIELDS.REPORTING_FILTERS_DATE_ID,
                    ADDITIONAL_FIELDS.START_DATE_NAME,
                    ADDITIONAL_FIELDS.END_DATE_NAME,
                ),
                reportFormValue: formValueSelector(DOWNLOAD_REPORT_FORM)(state, DOWNLOAD_REPORT_FORM_FIELD),
                reportNames: selectReportsNames(state),
                reportNamesLoading: selectReportNamesMeta(state).loading,
                reportNamesError: selectReportNamesMeta(state).error,
                additionalReports: selectAdditionReports(state),
                regions: selectFormatRegions(state),
                isHasAdditionalReports: (selectAdditionReports(state) || []).length,
            };
        }),
    )(ReportWrapper),
);
