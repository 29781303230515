import {
    WEBSOCKET_EVENT_TYPE_MESSAGE_NOT_SENT,
    WEBSOCKET_EVENT_TYPE_MESSAGE_SENT,
    WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS,
} from "../../../constants/maps";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_MESSAGE_SENT]: "Message |`{messageText}`| to |{recipient}| was sent successfully",
    [WEBSOCKET_EVENT_TYPE_MESSAGE_NOT_SENT]: "Message |`{messageText}`| to |{recipient}| wasn't sent",
    [WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS]: "|{messageText}|",
};

const getNotificationTitle = (event) => {
    return notificationTitles[event.type]
        .replace("{messageText}", event.message)
        .replace("{recipient}", event.recipient);
};

export const processMessageEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
