import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import RouterIcon from "@material-ui/icons/Router";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";

import { getJobBoardJobOrderJoli, getJobOrderETAData, getStatsTabData } from "../../../dataServers/jobOrder";
import { TABLE_SECONDARY, TABLE_SECONDARY_SCROLLABLE } from "../../../styles/reusableStyles";
import ErrorNotification from "../../core/notification";
import Loader from "../../core/loader";
import TruckPillWrapper, { StatusPill } from "../../core/pills";
import { Divider } from "../../core/divider";
import { getShowCompletedCubage, timeFromSeconds } from "../../../helpers/jobOrders";
import { selectUnitOfMeasureName } from "../../../reducers/unitsOfMeasureReducer";
import {
    ENV_MODE_PROD,
    FREQUENCY_UPDATE_PROD,
    FREQUENCY_UPDATE_TEST_DEMO,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
} from "../../../constants/global";
import {
    IS_INSPECTOR_USER,
    JO_STATUS_ACTIVE_ID,
    TRUCK_STATUS_AN_ROUTE_TO_DROPOFF,
    TRUCK_STATUS_AN_ROUTE_TO_PICK_UP,
    TRUCK_STATUS_AT_DROPOFF,
    TRUCK_STATUS_AT_PAVER,
    TRUCK_STATUS_AT_PICK_UP,
    TRUCK_STATUS_OFFLINE,
    TRUCK_STATUS_ONLINE,
    TRUCK_STATUSES,
    UNIT_OF_MEASURE_HOUR,
} from "../../../constants/maps";
import CheckIcon from "@material-ui/icons/Check";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import RoomIcon from "@material-ui/icons/Room";
import { ICONS_SITE } from "../../../styles/icons";
import { updateJobMapCoordinatesForTruck } from "../../../actions/jobOrders";
import { PAVERTRACKERS_NAME } from "../../global/pavertrackersSelector";
import { getHoursQty } from "../../tickets/components/ticketCard";
import { selectMeasureSystem } from "../../../selectors";

const rootPadding = "13px 16px";
const useStyles = makeStyles((theme) => ({
    statsTab: {
        "& .stats-table": {
            overflowY: "scroll",
            "& .tr .th": {
                borderTop: "none",
            },

            "& tr:nth-child(odd) td, & .tr:nth-child(odd) .td": {
                backgroundColor: "transparent",
            },

            "& .tr.heading": {
                "& .td": {
                    paddingTop: 0,
                    paddingBottom: 0,
                    backgroundColor: `${theme.palette.general.grey}`,

                    "& div": {
                        justifyContent: "flex-start",
                    },
                },
                "& .offline-icon": {
                    color: theme.palette.secondary.main,
                    marginRight: 10,
                },
            },

            "& .offline-icon": {
                top: "-1px",
                position: "relative",
                height: "34px !important",
            },
        },
    },
    borderedElement: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius * 2,
    },
    rootPadding: {
        padding: rootPadding,
    },
    itemsSection: {
        "& .item": {
            width: "30%",
            marginBottom: 20,
            textAlign: "center",
            padding: 10,
        },
    },
}));

export const GET_STATUS_SITE_NAME = ({ status, pickUpSite, dropOffSite }) => {
    let siteName = null;

    if (status === TRUCK_STATUS_AN_ROUTE_TO_PICK_UP && pickUpSite) {
        siteName = pickUpSite.name;
    }
    if (status === TRUCK_STATUS_AN_ROUTE_TO_DROPOFF && dropOffSite) {
        siteName = dropOffSite.name;
    }

    return siteName;
};

const getTrailersQuantity = (trailers, ticketsTrailersQuantity, truck) => {
    const trailerIdWithQuantity = {};

    (ticketsTrailersQuantity || []).reduce((result, { trailersQuantity }) => {
        trailersQuantity.forEach(({ trailerId, quantity }) => {
            if (trailerIdWithQuantity[trailerId]) {
                trailerIdWithQuantity[trailerId] = (+trailerIdWithQuantity[trailerId] + +quantity).toFixed(2);
            } else {
                trailerIdWithQuantity[trailerId] = quantity;
            }
        });
    }, trailerIdWithQuantity);

    return (
        !_.isEmpty(trailerIdWithQuantity) &&
        trailers &&
        trailers
            .filter(({ truckId }) => truckId === truck.truckId)
            .map(({ trailerId }, index, array) => <div>{trailerIdWithQuantity[trailerId]}</div>)
    );
};

const RenderTruckRow = (props) => {
    const { truck, jobOrder, account, trailers } = props;
    const iconContent =
        timeFromSeconds(+truck.estimatedTimeLeft || -3) ||
        (truck.status === TRUCK_STATUS_OFFLINE || !truck.status ? (
            <OfflineBoltIcon className="offline-icon" />
        ) : truck.status === TRUCK_STATUS_ONLINE ? (
            <SettingsEthernetIcon className="online" />
        ) : (
            <CheckIcon />
        ));
    const dispatch = useDispatch();
    const statusLabel = TRUCK_STATUSES[truck.status];
    const siteName =
        statusLabel &&
        GET_STATUS_SITE_NAME({
            status: truck.status,
            pickUpSite: jobOrder.pickUpSite,
            dropOffSite: jobOrder.dropOffSites && jobOrder.dropOffSites[0],
        });

    useEffect(() => {
        return () => {
            dispatch(updateJobMapCoordinatesForTruck(null));
        };
    }, []);

    const trailersQuantity = getTrailersQuantity(trailers, truck.ticketsTrailersQuantity, truck);
    const styleForQuantityWithTrailers = trailersQuantity && {
        style: { display: "flex", flexDirection: "column" },
    };

    return (
        <div className="tr" style={{ height: "auto" }}>
            <div className="td flex-2">
                <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
                    <TruckPillWrapper
                        title={statusLabel && statusLabel(siteName)}
                        showIcon={false}
                        iconContent={iconContent}
                        onClick={() =>
                            dispatch(updateJobMapCoordinatesForTruck(truck.id || truck.truckId || truck.deviceId))
                        }
                        specifyNumberOfTrucks={false}
                        haulerName={
                            truck.driver ? `${truck.driver.firstName} ${truck.driver.lastName}` : truck.userName
                        }
                        truckName={truck.truckName || truck.deviceId}
                        isMyFleet={truck.isMyFleetTruck}
                        truckId={truck.id || truck.truckId}
                        trailers={trailers}
                    />
                </div>
            </div>
            {jobOrder.unitOfMeasure === UNIT_OF_MEASURE_HOUR && (
                <div className="td">
                    <div>{getHoursQty(truck.hours) || "-"}</div>
                </div>
            )}
            <div className="td" {...styleForQuantityWithTrailers}>
                <div>{truck.completedTYM || "-"}</div>
                {trailersQuantity}
            </div>
            <div className="td">
                <div>{truck.completedLoads || "-"}</div>
            </div>
            {!jobOrder.grantedAccess && (
                <div className="--text-center td">
                    <div>
                        <StatusPill
                            type={"default"}
                            label={
                                truck.isDevice || IS_INSPECTOR_USER(account.role)
                                    ? "-"
                                    : timeFromSeconds(+truck.avgTurntime)
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

RenderTruckRow.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    truck: PropTypes.object.isRequired,
};

const orderByTime = (trucks) => {
    return _.orderBy(trucks, (o) => o.estimatedTimeLeft, ["asc"]);
};
const orderByStatus = (trucks) => {
    return _.orderBy(trucks, (o) => o.status, ["desc"]);
};

const Stats = (props) => {
    const classes = useStyles();
    const siteIconsClasses = ICONS_SITE();
    const tableStyles = TABLE_SECONDARY();
    const tableScrollableStyles = TABLE_SECONDARY_SCROLLABLE();
    const { jobOrder, account, measurementSystem } = props;
    const { completedCubage } = jobOrder;
    const showCompletedCubage = getShowCompletedCubage(completedCubage, measurementSystem);

    const [state, setState] = useState({
        isLoading: false,
        trucks: [],
        summaryData: {},
        errorMessage: false,
        devices: [],
    });
    const { isLoading, errorMessage, trucks, summaryData, devices } = state;
    const [trailers, setTrailers] = useState([]);
    const summaryUnitOfMeasure = useSelector((state) => selectUnitOfMeasureName(state, summaryData?.TYMunitOfMeasure));

    const loadStatsData = () => {
        setState({
            ...state,
            isLoading: true,
        });

        Promise.all([
            getStatsTabData(jobOrder.id),
            jobOrder.status === JO_STATUS_ACTIVE_ID && getJobOrderETAData({ jobOrder: jobOrder.id }),
        ])
            .then(([{ data: statsData }, etaData]) => {
                const eta = etaData && etaData.data;
                const trucks = statsData.trucks.map((truck) => {
                    if (eta) {
                        const truckEta = eta.find((i) => i.truck.id === truck.truckId) || {};
                        const etaCopy = _.cloneDeep(truckEta);

                        delete etaCopy.truck;

                        return { ...truck, ...etaCopy };
                    }

                    return truck;
                });

                setState({
                    ...state,
                    trucks: trucks.filter((i) => !_.isEmpty(i)),
                    devices: statsData[PAVERTRACKERS_NAME],
                    summaryData: statsData.summary,
                    isLoading: false,
                });
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: true,
                    errorMessage: PROCESS_SERVER_ERROR(error),
                });
            });
    };

    const loadTrucks = () => {
        getJobBoardJobOrderJoli(jobOrder.id)
            .then(({ data }) => {
                const trailers =
                    data &&
                    data.length &&
                    data
                        .filter((jobItem) => jobItem.trailers.length)
                        .reduce((acc, jobItem) => {
                            return [...acc, ...jobItem.trailers];
                        }, []);
                setTrailers(trailers);
            })
            .catch((error) => {
                setState({ ...state, errorMessage: PROCESS_SERVER_ERROR(error) });
            });
    };

    useEffect(() => {
        loadStatsData();
        loadTrucks();
    }, [jobOrder.id]);

    const atPaverTrucks = orderByTime(trucks.filter((i) => i.status === TRUCK_STATUS_AT_PAVER));
    const pickUpTrucks = orderByTime(trucks.filter((i) => i.status === TRUCK_STATUS_AT_PICK_UP));
    const enRouteToPickUpTrucks = orderByTime(trucks.filter((i) => i.status === TRUCK_STATUS_AN_ROUTE_TO_PICK_UP));
    const dropOffTrucks = orderByTime(trucks.filter((i) => i.status === TRUCK_STATUS_AT_DROPOFF));
    const enRouteToDropOffTrucks = orderByTime(trucks.filter((i) => i.status === TRUCK_STATUS_AN_ROUTE_TO_DROPOFF));
    const offlineTrucks = orderByStatus(
        orderByTime(
            trucks.filter((i) => i.status === TRUCK_STATUS_OFFLINE || i.status === TRUCK_STATUS_ONLINE || !i.status),
        ),
    );

    useEffect(() => {
        const time =
            process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD ? FREQUENCY_UPDATE_PROD : FREQUENCY_UPDATE_TEST_DEMO;
        const interval = setInterval(() => {
            jobOrder.id && loadStatsData(jobOrder.id);
        }, time);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={clsx(isLoading && `${LOADER_WHITE_OVERLAY} -min-height`)}>
            {isLoading && <Loader />}
            {errorMessage && <ErrorNotification message={errorMessage} />}
            <Divider marginTop={0} />
            {!_.isEmpty(summaryData) || !_.isEmpty(trucks) ? (
                <React.Fragment>
                    {!jobOrder.grantedAccess && !IS_INSPECTOR_USER(account.role) && (
                        <Grid container className={classes.itemsSection} justify={"space-between"}>
                            <div className={clsx(classes.borderedElement, classes.rootPadding, "item")}>
                                {summaryData.completedTYMForJO} {`${summaryUnitOfMeasure?.name}S`.toUpperCase()}
                                <br />
                                DELIVERED
                            </div>
                            <div className={clsx(classes.borderedElement, "item")}>
                                {summaryData.completedLoadsForJO} LOADS
                                <br />
                                DELIVERED
                                {showCompletedCubage && <div> ({completedCubage} CUBIC YARDS)</div>}
                            </div>
                            <div className={clsx(classes.borderedElement, "item")}>
                                ${summaryData.totalCostForJO}
                                <br />
                                TOTAL COST
                            </div>
                            <div className={clsx(classes.borderedElement, "item", "--uppercase")}>
                                {timeFromSeconds(+summaryData.avgTurnTimeAtPickUpForJO, true, "MIN")}
                                <br />
                                AVG PICK-UP TIME
                            </div>
                            <div className={clsx(classes.borderedElement, "item", "--uppercase")}>
                                {timeFromSeconds(+summaryData.avgTurnTimeAtDropOffForJO, true, "MIN")}
                                <br />
                                AVG DROP-OFF TIME
                            </div>
                            <div className={clsx(classes.borderedElement, "item", "--uppercase")}>
                                {timeFromSeconds(+summaryData.avgTurnTimeForJO, true, "MIN")}
                                <br />
                                AVG TURN-TIME
                            </div>
                        </Grid>
                    )}
                    {!_.isEmpty(trucks) && (
                        <Box className={clsx(classes.borderedElement, classes.statsTab)}>
                            <div
                                className={clsx(
                                    tableStyles.secondaryTable,
                                    tableScrollableStyles.scrolableTable,
                                    "stats-table",
                                )}
                            >
                                <div className="tr">
                                    <div className="th flex-2">Trucks</div>
                                    {jobOrder.unitOfMeasure === UNIT_OF_MEASURE_HOUR && <div className="th">Hours</div>}
                                    <div className="th" style={{ textTransform: "capitalize" }}>
                                        {`${summaryUnitOfMeasure?.name}s`}
                                    </div>
                                    <div className="th">Loads</div>
                                    {!jobOrder.grantedAccess && <div className="th">AVG TURN-TIME</div>}
                                </div>
                                <div className={clsx("tbody", trucks.length > 5 && "-scroll")}>
                                    <React.Fragment>
                                        {!_.isEmpty(atPaverTrucks) && (
                                            <div className="tr heading">
                                                <div className="td flex-2">
                                                    <div>
                                                        <RoomIcon
                                                            className={clsx(siteIconsClasses.iconSite, "drop-off")}
                                                        />
                                                        <div className="--text-semi-bold">At Paver</div>
                                                    </div>
                                                </div>
                                                <div className="td" />
                                            </div>
                                        )}
                                        {!_.isEmpty(atPaverTrucks) &&
                                            atPaverTrucks.map((truck, index) => {
                                                return (
                                                    <RenderTruckRow
                                                        truck={truck}
                                                        key={index}
                                                        account={account}
                                                        jobOrder={jobOrder}
                                                    />
                                                );
                                            })}
                                        {jobOrder.pickUpSite && (
                                            <React.Fragment>
                                                {!_.isEmpty(pickUpTrucks) && (
                                                    <div className="tr heading">
                                                        <div className="td flex-2">
                                                            <div>
                                                                <RoomIcon
                                                                    className={clsx(
                                                                        siteIconsClasses.iconSite,
                                                                        "pick-up",
                                                                    )}
                                                                />
                                                                <div className="--text-semi-bold">
                                                                    At {jobOrder.pickUpSite.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="td" />
                                                    </div>
                                                )}
                                                {pickUpTrucks.map((truck, index) => {
                                                    return (
                                                        <RenderTruckRow
                                                            truck={truck}
                                                            key={index}
                                                            account={account}
                                                            jobOrder={jobOrder}
                                                            trailers={trailers}
                                                        />
                                                    );
                                                })}
                                                {!_.isEmpty(enRouteToPickUpTrucks) && (
                                                    <div className="tr heading">
                                                        <div className="td flex-2">
                                                            <div>
                                                                <RoomIcon
                                                                    className={clsx(
                                                                        siteIconsClasses.iconSite,
                                                                        "pick-up",
                                                                    )}
                                                                />
                                                                <div className="--text-semi-bold">
                                                                    En Route to {jobOrder.pickUpSite.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="td" />
                                                    </div>
                                                )}
                                                {!_.isEmpty(enRouteToPickUpTrucks) &&
                                                    enRouteToPickUpTrucks.map((truck, index) => {
                                                        return (
                                                            <RenderTruckRow
                                                                truck={truck}
                                                                key={index}
                                                                account={account}
                                                                jobOrder={jobOrder}
                                                                trailers={trailers}
                                                            />
                                                        );
                                                    })}
                                            </React.Fragment>
                                        )}
                                        {jobOrder.dropOffSites && jobOrder.dropOffSites[0] && (
                                            <React.Fragment>
                                                {!_.isEmpty(dropOffTrucks) && (
                                                    <div className="tr heading">
                                                        <div className="td flex-2">
                                                            <div>
                                                                <RoomIcon
                                                                    className={clsx(
                                                                        siteIconsClasses.iconSite,
                                                                        "drop-off",
                                                                    )}
                                                                />
                                                                <div className="--text-semi-bold">
                                                                    At {jobOrder.dropOffSites[0].name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="td" />
                                                    </div>
                                                )}
                                                {!_.isEmpty(dropOffTrucks) &&
                                                    dropOffTrucks.map((truck, index) => {
                                                        return (
                                                            <RenderTruckRow
                                                                truck={truck}
                                                                key={index}
                                                                account={account}
                                                                jobOrder={jobOrder}
                                                                trailers={trailers}
                                                            />
                                                        );
                                                    })}
                                                {!_.isEmpty(enRouteToDropOffTrucks) && (
                                                    <div className="tr heading">
                                                        <div className="td flex-2">
                                                            <div>
                                                                <RoomIcon
                                                                    className={clsx(
                                                                        siteIconsClasses.iconSite,
                                                                        "drop-off",
                                                                    )}
                                                                />
                                                                <div className="--text-semi-bold">
                                                                    En Route to {jobOrder.dropOffSites[0].name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="td" />
                                                    </div>
                                                )}
                                                {!_.isEmpty(enRouteToDropOffTrucks) &&
                                                    enRouteToDropOffTrucks.map((truck, index) => {
                                                        return (
                                                            <RenderTruckRow
                                                                truck={truck}
                                                                key={index}
                                                                account={account}
                                                                jobOrder={jobOrder}
                                                                trailers={trailers}
                                                            />
                                                        );
                                                    })}
                                            </React.Fragment>
                                        )}
                                        {!_.isEmpty(offlineTrucks) && (
                                            <div className="tr heading">
                                                <div className="td flex-2">
                                                    <div>
                                                        <OfflineBoltIcon className="offline-icon" />
                                                        <div className="--text-semi-bold">Online/Offline</div>
                                                    </div>
                                                </div>
                                                <div className="td" />
                                            </div>
                                        )}
                                        {offlineTrucks.map((truck, index) => {
                                            return (
                                                <RenderTruckRow
                                                    truck={truck}
                                                    key={index}
                                                    account={account}
                                                    jobOrder={jobOrder}
                                                    trailers={trailers}
                                                />
                                            );
                                        })}
                                        {!_.isEmpty(devices) && (
                                            <div className="tr heading">
                                                <div className="td flex-2">
                                                    <div>
                                                        <RouterIcon className="offline-icon" />
                                                        <div className="--text-semi-bold">Pavertracker(s)</div>
                                                    </div>
                                                </div>
                                                <div className="td" />
                                            </div>
                                        )}
                                        {!_.isEmpty(devices) &&
                                            devices.map((device, index) => {
                                                return (
                                                    <RenderTruckRow
                                                        truck={{
                                                            ...device,
                                                            isDevice: true,
                                                        }}
                                                        key={index}
                                                        account={account}
                                                        jobOrder={jobOrder}
                                                    />
                                                );
                                            })}
                                    </React.Fragment>
                                </div>
                            </div>
                        </Box>
                    )}
                </React.Fragment>
            ) : (
                <h2 className="--text-center">No data to display</h2>
            )}
            <Divider />
        </div>
    );
};

Stats.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            measurementSystem: selectMeasureSystem(state),
        };
    })(Stats),
);
