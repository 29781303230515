import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
    data: null,
    customUom: null,
    meta: {
        loading: false,
        error: null,
    },
    uomDetails: {
        loading: false,
    },
};

const unitsOfMeasureSlice = createSlice({
    name: "UNITS OF MEASURE",
    initialState,
    reducers: {
        requestUnitsOfMeasure: (state) => {
            state.meta.loading = true;
        },
        resultUnitsOfMeasure: (state, { payload }) => {
            if (payload.error) {
                state.meta.error = payload.message;
            } else {
                state.data = payload;
                state.customUom = payload
                    .filter((i) => i.measurementSystem === 4)
                    .sort((a, b) => a.displayIndex - b.displayIndex);
            }
            state.meta.loading = false;
        },
        requestUnitsOfMeasureDetails: (state) => {
            state.uomDetails.loading = true;
        },
        resultUnitsOfMeasureDetails: (state) => {
            state.uomDetails.loading = false;
        },
        moveUom: (state, { payload: { dragIndex, hoverIndex } }) => {
            state.customUom = update(state.customUom, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, state.customUom[dragIndex]],
                ],
            });
        },
        initializeUnitsOfMeasure: (state) => {
            state.data = initialState.data;
            state.customUom = initialState.customUom;
            state.meta = initialState.meta;
        },
    },
});

export const { actions } = unitsOfMeasureSlice;

export const selectUnitsOfMeasure = (state) => state.unitsOfMeasure.data;
export const selectCustomUnitsOfMeasure = (state) => state.unitsOfMeasure.customUom;
export const selectDefaultUnitsOfMeasure = (state) =>
    state.unitsOfMeasure.data && state.unitsOfMeasure.data.filter((i) => i.isDefault);
export const hasCreatedUnitsOfMeasure = (state) =>
    state.unitsOfMeasure.data && !!(state.unitsOfMeasure.data.filter((i) => !i.isDefault) || []).length;
export const selectUnitsOfMeasureMeta = (state) => state.unitsOfMeasure.meta;
export const selectUnitOfMeasureDetails = (state) => state.unitsOfMeasure.uomDetails;
export const selectUnitOfMeasureName = (state, uomId) => state.unitsOfMeasure.data?.filter((i) => i.id === uomId)[0];

export default unitsOfMeasureSlice.reducer;
