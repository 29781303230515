import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles, Tooltip } from "@material-ui/core";
import { compose } from "redux";

import Grid from "@material-ui/core/Grid";
import TextInputComponent from "../core/form/textInputComponent";
import Loader from "../core/loader";
import PillSwitch from "../core/form/pillSwitch";
import ErrorNotification from "../core/notification";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { loadTeamUsers, resetUsers } from "../../actions/team";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import SuccessNotification from "../core/successNotification";
import {
    IS_ADMIN_USER,
    TRUCKER_TYPES,
    USER_ROLE_INSPECTOR,
    USER_ROLE_RESTRICTED_CUSTOMER,
    USER_ROLE_SUB_TRUCKER,
    USER_ROLE_TRUCKER,
} from "../../constants/maps";
import { USER_ROLES_SHORT } from "../../constants/users";
import { preparePhone } from "../../helpers/global";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { Validation, VALIDATION_ERRORS } from "../../helpers/validation";
import { getCompanies } from "../../dataServers/companies";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { DOT_COMPANY } from "../../constants/companies";
import HiddenInput from "../core/form/hiddenInput";
import PhoneField, { PRONE_FIELD_NOTE } from "../global/phoneField";
import PasswordField from "../global/passwordField";
import EmailField from "../global/emailField";
import RegionSelector from "../global/regionSelector";
import CustomCheckbox from "../core/form/customCheckbox";
import { UsersFiltersContext } from "./myTeamWrapper";
import { selectIfIsRestrictedCustomer } from "../../selectors/user";

const useStyles = makeStyles(() => ({
    createUserFormContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        paddingTop: 5,

        "& .form-label-bold": {
            color: "#393939",
            fontSize: "12px",
            fontWeight: "500",
            marginLeft: "11.45px",
            marginBottom: "7px",
        },

        "& .form-label-medium": {
            marginTop: 5,
            fontWeight: "normal",
            fontSize: "11px",
        },

        "& .button-container": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "27.5px",
        },
    },
    gridXS: {
        width: "100%",
    },
}));

const validate = (values) => {
    const errors = {};
    const { role } = values;

    if (!role) {
        errors.roleError = VALIDATION_ERRORS.roleRequired;
    }

    return errors;
};

function CreateUserForm(props) {
    const {
        handleSubmit,
        handleSuccess,
        handleClose,
        onSubmitHandler,
        formValues: { role },
        account,
        form,
        initialValues,
        isEditForm,
        activeTab,
        isRestrictedCustomer,
    } = props;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const usersFilters = useContext(UsersFiltersContext);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const dispatch = useDispatch();
    const activeTeamUsersOrder = useSelector((state) => state.teams.activeTeamUsersOrder);

    const generateUserData = (formData) => {
        const {
            firstName,
            lastName,
            email,
            phone,
            password,
            role,
            truckerType,
            username,
            company,
            region,
            hasTemporaryPassword,
        } = formData;

        const primaryData = {
            firstName,
            lastName,
            username,
            hasTemporaryPassword,
            phone: preparePhone(phone) || null,
            email,
            password,
            regionId: !_.isEmpty(region) ? region.id : null,
            company: company
                ? parseInt(role) === USER_ROLE_INSPECTOR
                    ? undefined
                    : company.value
                : account.company.id,
            role: parseInt(role),
        };

        if (isRestrictedCustomer) {
            primaryData.role = USER_ROLE_RESTRICTED_CUSTOMER;
        }

        if (truckerType) {
            primaryData.truckerType = truckerType.value;
        }

        if (!phone && !isEditForm) {
            delete primaryData.phone;
        }

        return primaryData;
    };

    const onSubmit = (values) => {
        setLoading(true);

        return onSubmitHandler(generateUserData(values))
            .then(() => {
                setIsHandledSuccessfully(true);
                dispatch(resetUsers());
                dispatch(
                    loadTeamUsers(
                        {
                            ...usersFilters,
                            keywords: usersFilters?.keywords,
                            order: activeTeamUsersOrder,
                            archived: activeTab === 1,
                        },
                        false,
                    ),
                );
                setLoading(false);
                handleClose();
                handleSuccess && handleSuccess();
            })
            .catch((errorResponce) => {
                setLoading(false);
                setError([PROCESS_SERVER_ERROR(errorResponce)]);
            });
    };

    const loadTruckerTypes = () => {
        const results = Object.entries(TRUCKER_TYPES).map((i) => ({
            value: i[0],
            label: i[1],
        }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const setCompany = (e) => {
        +role === USER_ROLE_INSPECTOR && dispatch(change(form, "company", null));
        +e.target.value === USER_ROLE_INSPECTOR && dispatch(change(form, "company", DOT_COMPANY));
    };
    const setRole = (e) => {
        if (+e.value === DOT_COMPANY.value) {
            dispatch(change(form, "role", USER_ROLE_INSPECTOR));
            // document.getElementById(`role-${USER_ROLE_INSPECTOR}`).checked = true;
        }
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
            className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
        >
            <form noValidate={true} className={classes.createUserFormContainer} onSubmit={handleSubmit(onSubmit)}>
                <Field
                    type="text"
                    name="username"
                    label="User Name"
                    validate={[Validation.required, Validation.minLength4, Validation.maxLength64]}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                {!isRestrictedCustomer && (
                    <>
                        <br />
                        <label className="form-label-bold">Role</label>
                        <Grid container spacing={1} alignItems="center">
                            {USER_ROLES_SHORT.map((item, index) => {
                                return (
                                    <Grid item key={index}>
                                        <Field
                                            type="radio"
                                            name="role"
                                            checked={role === item.value}
                                            id={`role-${item.value}`}
                                            value={`${item.value}`}
                                            onChange={setCompany}
                                            label={item.name}
                                            className="measure-pill"
                                            component={PillSwitch}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <label className="form-label-medium">Used to determine the user's permissions.</label>
                        <Field type="hidden" name="roleError" component={HiddenInput} />
                    </>
                )}
                <br />
                {(role === `${USER_ROLE_TRUCKER}` || role === `${USER_ROLE_SUB_TRUCKER}`) && (
                    <React.Fragment>
                        <Field
                            name="truckerType"
                            placeholder="Trucker type"
                            validate={[Validation.required]}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            loadOptions={loadTruckerTypes}
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                    </React.Fragment>
                )}
                {(IS_ADMIN_USER(account.company.id) || account.isSuperUser) && (
                    <React.Fragment>
                        <Field
                            name="company"
                            label="Company"
                            loadOptions={loadCompanies}
                            validate={[Validation.required]}
                            placeholder="Select Company"
                            onChange={setRole}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            disabled={+role === USER_ROLE_INSPECTOR || initialValues.id}
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                    </React.Fragment>
                )}
                <Field
                    type="text"
                    name="firstName"
                    validate={[
                        Validation.required,
                        Validation.noSpecialSymbols,
                        Validation.minLength2,
                        Validation.maxLength100,
                    ]}
                    label="First Name"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <Field
                    type="text"
                    name="lastName"
                    validate={[Validation.noSpecialSymbols, Validation.maxLength100]}
                    label="Last Name"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <PasswordField className={AUTOCOMPLETE_FORM_VIEW_CLASS} />
                <br />
                {!isEditForm && (
                    <>
                        <Tooltip
                            title={"When logging in, the user will have to change the password"}
                            placement={"top-start"}
                        >
                            <span>
                                <Field
                                    type="checkbox"
                                    name="hasTemporaryPassword"
                                    label="Temporary Password"
                                    component={CustomCheckbox}
                                />
                            </span>
                        </Tooltip>
                        <br />
                    </>
                )}
                <EmailField
                    fieldNote={
                        "Used for system generated notifications. User can manage personal notification preferences in app"
                    }
                />
                <br />
                <PhoneField fieldNote={PRONE_FIELD_NOTE} />
                <br />
                {!isRestrictedCustomer && (
                    <>
                        <RegionSelector isClearable={true} />
                        <br />
                    </>
                )}
                <ButtonsGroup>
                    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
        </div>
    );
}

CreateUserForm.propTypes = {
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    error: PropTypes.string,
    form: PropTypes.string,
    userForEdit: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    initialValues: PropTypes.object,
    hasTemporaryPassword: PropTypes.bool,
    isEditForm: PropTypes.bool,
    password: PropTypes.string,
    activeTab: PropTypes.number,
    isRestrictedCustomer: PropTypes.bool,
};

CreateUserForm.defaultProps = {
    formValues: {},
};

export const CREATE_USER_FORM = "createUserForm";
const formSelector = formValueSelector(CREATE_USER_FORM);

export default withRouter(
    compose(
        reduxForm({
            form: CREATE_USER_FORM,
            validate,
        }),
        connect((state, props) => ({
            account: state.account,
            formValues: formSelector(state, "role", "username", "hasTemporaryPassword", "password"),
            initialValues: props.userForEdit,
            isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
        })),
    )(CreateUserForm),
);
