import { ALLOWED_RESTRICTED_CUSTOMER_ROUTES } from "../routes/restrictedCustomerRouter";
import { USER_ROLE_RESTRICTED_CUSTOMER } from "../constants/maps";

export const getNavigationProps = (role, path, onClick) => {
    if (role === USER_ROLE_RESTRICTED_CUSTOMER && !ALLOWED_RESTRICTED_CUSTOMER_ROUTES[path]) {
        return {
            tabStyles: { opacity: 0.3, cursor: "not-allowed" },
            navStyles: { color: "#d8d8d8", cursor: "not-allowed" },
            to: undefined,
        };
    }

    return { to: path, onClick };
};
