import React from "react";
import HaulerTrucksListItem from "./haulerTrucksListItem";

const HaulerTrucksList = ({ haulersTrucks, handleDropEnd, haulerDefaultRate }) => {
    return haulersTrucks.map((truck) => {
        return (
            <HaulerTrucksListItem
                key={truck.id}
                truck={truck}
                handleDropEnd={handleDropEnd}
                haulerDefaultRate={haulerDefaultRate}
            />
        );
    });
};

export default HaulerTrucksList;
