import React from "react";
import { SimpleLink } from "../../core";
import Grid from "@material-ui/core/Grid";
import { LINKED_JOB_LABEL } from "../../../constants/strings";

const LinkedJobFormLink = ({ linkedJobId, setActive }) => {
    const onLinkedJobLinkClick = async () => {
        setActive({ id: linkedJobId });
    };

    return (
        <Grid item>
            <SimpleLink onClick={onLinkedJobLinkClick}>{LINKED_JOB_LABEL(linkedJobId)}</SimpleLink>
        </Grid>
    );
};

export default LinkedJobFormLink;
