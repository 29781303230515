import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import SearchAndFilters, { FILTERS_SITES_TYPE_ID, FILTERS_STATUS_ID } from "../global/searchAndFilters";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { SimpleLink } from "../core/link";
import { compose } from "redux";
import { formValueSelector, reduxForm } from "redux-form";
import { SITES_STATUS_ACTIVE, SITES_STATUS_ARCHIVE, SITES_TYPE_PRIVATE, SITES_TYPE_PUBLIC } from "../../constants/maps";
import AppModal from "../core/modal";
import SiteForm, { SITE_FORM } from "./siteForm";
import { DEFAULT_RADIUS_VALUE } from "../jobs/jobComponents/pickUp&DropOff";
import { setAlertZoneCoordinates } from "../../actions/sites";

const useStyles = makeStyles((theme) => ({
    searchBarWrap: {
        position: "relative",
        zIndex: 2,
    },
    searchBar: {
        height: 57,
        margin: "0 33px",
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        marginBottom: 12,
        position: "relative",
    },
}));

const filtersList = [
    {
        title: <div>Site&nbsp;Types</div>,
        filterKey: FILTERS_SITES_TYPE_ID,
        filters: [
            {
                label: "Private",
                id: SITES_TYPE_PRIVATE,
            },
            {
                label: "Public",
                id: SITES_TYPE_PUBLIC,
            },
        ],
    },
    {
        title: <div>Site&nbsp;Status</div>,
        filterKey: FILTERS_STATUS_ID,
        filters: [
            {
                label: "Active",
                id: SITES_STATUS_ACTIVE,
            },
            {
                label: "Archive",
                id: SITES_STATUS_ARCHIVE,
            },
        ],
    },
];

const initialFilters = {
    [FILTERS_SITES_TYPE_ID]: {
        [SITES_TYPE_PRIVATE]: true,
    },
    [FILTERS_STATUS_ID]: {
        [SITES_STATUS_ACTIVE]: true,
    },
};

const SiteFilters = (props) => {
    const classes = useStyles();
    const { formValues, updateListByFilters, form, setAlertZoneCoordinates } = props;

    const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);

    const createNewSiteHandler = () => {
        setShowCreateSiteModal(true);
        setAlertZoneCoordinates(null);
    };

    return (
        <React.Fragment>
            <Grid component="div" container justify={"center"} alignItems={"center"} className={classes.searchBarWrap}>
                <SearchAndFilters
                    appliedFilters={formValues.filters}
                    updateList={updateListByFilters}
                    rememberFilters
                    filtersList={filtersList}
                    chooseOneFilterOptionList={[FILTERS_SITES_TYPE_ID]}
                    initialFilters={initialFilters}
                    form={form}
                    size={12}
                    className={classes.searchBar}
                />
            </Grid>
            <div className="create-site">
                <AddCircleOutlineIcon />
                <SimpleLink onClick={createNewSiteHandler}>Create new site</SimpleLink>
            </div>
            {!!showCreateSiteModal && (
                <AppModal
                    isOpen={showCreateSiteModal}
                    form={SITE_FORM}
                    modalStyles={{ width: 600 }}
                    closeModal={() => setShowCreateSiteModal(false)}
                >
                    <SiteForm
                        closeModal={() => setShowCreateSiteModal(false)}
                        initialValues={{
                            // When creating a new site, we should by default turn on the "create alert zone"
                            // https://github.com/TruckITllc/truckit-frontend/issues/424#issue-597412526
                            alertZone: true,
                            // have a default value of 500 meter.
                            // https://github.com/TruckITllc/truckit-frontend/issues/758
                            radius: DEFAULT_RADIUS_VALUE,
                        }}
                    />
                </AppModal>
            )}
        </React.Fragment>
    );
};

SiteFilters.propTypes = {
    formValues: PropTypes.object.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    setAlertZoneCoordinates: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

const formName = "sitesListForm";
const formSelector = formValueSelector(formName);

export default withRouter(
    compose(
        reduxForm({
            form: formName,
            initialValues: { search: null },
        }),
        connect(
            (state) => ({
                formValues: formSelector(state, "search", "filters"),
            }),
            (dispatch) => ({
                setAlertZoneCoordinates: (payload) => dispatch(setAlertZoneCoordinates(payload)),
            }),
        ),
    )(SiteFilters),
);
