import React, { useEffect, useState } from "react";
import { destroy } from "redux-form";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import withScrolling from "react-dnd-scrolling";

import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { Divider } from "../../core/divider";
import { dropItemTypes } from "../../../helpers/jobOrders";
import {
    insertJob,
    moveJob,
    removeJobFromRoute,
    resetRouteDetails,
    resetSelectedRoute,
    setSmartDispatchConfirmationModal,
    updateActiveRegularJobsSortParam,
    updateAssignBundleData,
    updateSelectedTruckSolutionList,
} from "../../../actions/smartDispatch";
import {
    selectCurrentBundle,
    selectCurrentBundleIAmOwner,
    selectIfRouteIsSelected,
    selectJobForProximity,
    selectRouteDetailsData,
    selectRouteDetailsDataIAmOwner,
    selectRouteDetailsMeta,
    selectRouteIsEmpty,
    selectSelectedTruckSolutionList,
} from "../../../selectors/smartDispatchSelectors";
import { DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE, ROUTE_TITLE } from "../../../constants/strings";
import { ICONS_SITE } from "../../../styles/icons";
import { SimpleLink } from "../../core/link";
import { AppModalWithoutConfirmation } from "../../core/modal";
import { getHoursQty } from "../../tickets/components/ticketCard";
import {
    AppModal,
    ButtonsGroup,
    ConfirmationModal,
    ErrorNotification,
    Loader,
    SecondaryButton,
    UnitOfMeasure,
} from "../../core";
import { DRAG_AND_DROP_STYLES } from "../../../styles/reusableStyles";
import { IS_TEST_OR_LOCAL_OR_STAGE_ENVS, LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD } from "../../../constants/maps";
import { OPEN_ROUTE_WITH_ADDED_MANUALLY_JOB_WARNING, REMOVE_JOB_WARNING } from "../constants/strings";
import SelectedRouteContainer from "./SelectedRouteContainer";
import InputLoadsForm from "../InputLoadsForm";
import { INPUT_LOADS_FORM } from "../../../constants/forms";
import { BUNDLES_VIEW_BY, setUpUrl } from "../constants/index";
import { useBrowserButtonHandler } from "../../../hooks";
import {
    ESTIMATE_TITLE,
    ESTIMATED_LOAD_COUNT,
    ESTIMATED_TIME_TO_COMPLETE,
    PROXIMITY_JOB_TOOLTIP,
} from "../../jobs/constants";
import { Tooltip } from "@material-ui/core";

const bundleStyles = makeStyles((theme) => ({
    routeWrapper: {
        border: `2px solid ${theme.palette.secondary.superDark}`,
        borderRadius: "5px",
        margin: "5px 15px 20px",
        padding: "5px 15px",

        "& .route-header-container": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            "& .route-link-container": {
                marginLeft: "5px",
            },
            "& .back-button-container": {
                marginLeft: "5px",
            },
        },
    },

    dragable: {
        position: "relative",
        "& .draggable-container": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        "& .draggable-title": {
            marginTop: "auto",
        },
    },
}));

const joItemWrapper = makeStyles((theme) => ({
    bundleJoItemWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        fontSize: "12px",
        transition: "background-color 0.5s linear",
        backgroundColor: "#fff",
        "& .bundle-detail-jo-item": {
            padding: "5px 15px",
            borderRadius: theme.shape.borderRadius * 2,
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.3)",
        },

        "& .proximity-jo-item": {
            border: null,
            boxShadow: `0 0 5px 0 ${theme.palette.primary.light}`,
        },

        "& h3": {
            fontWeight: 400,
        },
        "& h2": {
            fontSize: "14px",
        },
        "& .location-container": {
            textAlign: "right",
        },
        "& .bundleJoIconSite": {
            margin: 0,
            width: "18px",
            height: "18px",
        },
        "& .remove-btn-container": {
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        "& .manually-added-title": { fontSize: 12, fontWeight: 900, textAlign: "center" },
        "& .remove-btn": {
            marginLeft: 10,
        },
        "& .left-side-data": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        "& .estimation": {
            backgroundColor: "rgba(0,0,0,.03)",
            borderBottom: " 1px solid rgba(0,0,0,.125)",
            borderTop: " 1px solid rgba(0,0,0,.125)",
            padding: "5px 17px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            marginBottom: 5,
        },
        "& .quantity-container": {
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            justifyContent: "end",
        },
        "& .quantity-info": {
            display: "flex",
            alignItems: "center",
        },
        "& .quantity": { marginRight: 3 },
        "& .uom": { marginLeft: 3, textTransform: "capitalize !important" },
    },
    dropArrowItem: {
        border: "3px dashed #b96211",
        textAlign: "center",
        borderRadius: 8,
        padding: 10,
        margin: "10px 0",
    },
    dropArrow: { display: "flex", justifyContent: "center", margin: "5px 0" },
    newAddedJob: {
        backgroundColor: "#fbf7f3",
    },
}));

const TestData = ({ routeItemId, orderingIndex }) => {
    if (IS_TEST_OR_LOCAL_OR_STAGE_ENVS) {
        return (
            <>
                <span style={{ marginLeft: 20 }}>routeItemId: {routeItemId}</span>
                <span style={{ marginLeft: 20 }}>orderingIndex: {orderingIndex}</span>
            </>
        );
    }

    return null;
};

export const BundleRouteItem = ({
    isRenderDrag,
    jobOrder,
    index,
    jobsCount,
    removeJob,
    iAmOwner,
    moveJobPosition,
    addJobToRoute,
    newAddedJob,
    updateJobPosition,
    renderEndDrop,
    setLoadJob,
    viewBy,
}) => {
    const classes = joItemWrapper();
    const siteIconsClasses = ICONS_SITE();
    const {
        projectName,
        payloadName,
        totalLoadsCount,
        loadCount,
        extRef,
        totalQuantity,
        id: jobOrderId,
        pickupLocation,
        dropoffLocations,
        manuallyAdded,
        estimation,
        unitOfMeasure,
        routeItemId,
        orderingIndex,
    } = jobOrder;
    const jobForProximity = useSelector(selectJobForProximity);

    const isProximityRoute = jobForProximity && routeItemId && jobForProximity?.routeItemId === routeItemId;
    const pickUpLabel = pickupLocation.name;
    const dropOffLabel = dropoffLocations?.map((dropOff) => dropOff.name).join(",");

    const type = "BundleRouteItem";

    const ref = React.useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveJobPosition(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
            item.moveIndex = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: {
            type,
            ...jobOrder,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            updateJobPosition(item);
        },
    });

    const renderBundleJobOrderTitle = () => (
        <h2>
            Job #{" "}
            <span className="--font-400">
                {jobOrderId} - {extRef && `${extRef} -`} {projectName}
            </span>
            {viewBy === BUNDLES_VIEW_BY.TRUCK && <TestData routeItemId={routeItemId} orderingIndex={orderingIndex} />}
        </h2>
    );

    const renderQuantity = () => {
        if (totalQuantity === 0)
            return (
                <>
                    <AllInclusiveIcon style={{ marginLeft: 3 }} />
                    <UnitOfMeasure className="uom" value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
                </>
            );
        if (unitOfMeasure === UNIT_OF_MEASURE_HOUR) {
            return getHoursQty(totalQuantity);
        }

        return (
            <>
                {totalQuantity}
                <UnitOfMeasure className="uom" value={UNIT_OF_MEASURE_BY_ID[unitOfMeasure]} />
            </>
        );
    };

    const opacity = isDragging ? 0 : 1;

    manuallyAdded && viewBy === BUNDLES_VIEW_BY.TRUCK && drag(drop(ref));

    const dragAndDropProps =
        viewBy === BUNDLES_VIEW_BY.TRUCK
            ? {
                  ref,
                  style: { opacity, cursor: manuallyAdded && viewBy === BUNDLES_VIEW_BY.TRUCK ? "pointer" : "auto" },
                  ["data-handler-id"]: handlerId,
              }
            : null;

    return (
        <>
            {index === 0 && (
                <Arrow
                    index={null}
                    jobsCount={jobsCount}
                    isRenderDrag
                    hideArrow
                    addJobToRoute={addJobToRoute}
                    setLoadJob={setLoadJob}
                />
            )}
            <Box
                key={+jobOrderId}
                className={clsx(classes.bundleJoItemWrapper, newAddedJob === jobOrderId && classes.newAddedJob)}
                {...dragAndDropProps}
            >
                <Tooltip enterDelay={300} title={isProximityRoute ? PROXIMITY_JOB_TOOLTIP : ""}>
                    <Grid container className={clsx("bundle-detail-jo-item", isProximityRoute && "proximity-jo-item")}>
                        <Grid container>
                            <Grid item xs={manuallyAdded ? 8 : 12}>
                                {renderBundleJobOrderTitle()}
                            </Grid>
                            {manuallyAdded && (
                                <Grid item xs={4} className="remove-btn-container">
                                    <Typography variant="h4" className={"manually-added-title"}>
                                        Added Manually
                                    </Typography>
                                    {iAmOwner && (
                                        <SimpleLink className={"remove-btn"} onClick={() => removeJob(jobOrderId)}>
                                            Remove
                                        </SimpleLink>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        <Divider marginTop={0} marginBottom={0} />
                        <Grid item xs={6} className="left-side-data">
                            <h3>
                                <span className="--font-600">Payload:</span>
                                {payloadName}
                            </h3>
                            {!manuallyAdded && (
                                <h3>
                                    <span className="--font-600">Loads / Total Loads for Job:</span>
                                    {loadCount} / {totalLoadsCount || "N/A"}
                                </h3>
                            )}
                        </Grid>
                        <Grid item xs={6} className="quantity-container">
                            <h3 className="quantity-info">
                                <Typography className="--font-600 quantity">Quantity:</Typography>
                                {renderQuantity()}
                            </h3>
                        </Grid>
                        {estimation && (
                            <Grid item xs={12} className="estimation">
                                <Typography variant="h3" className="--font-600 --text-14">
                                    {ESTIMATE_TITLE}
                                </Typography>
                                <Typography variant="span" className="--font-500">
                                    {ESTIMATED_LOAD_COUNT(estimation.complLoadsCount, estimation.loadsCountToDo)}
                                </Typography>
                                <Typography variant="span" className="--font-500">
                                    {ESTIMATED_TIME_TO_COMPLETE(estimation.oneWayTime)}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <div className={clsx("--text-left")}>
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, "pick-up", "bundleJoIconSite")}
                                />
                                {pickUpLabel}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={clsx("--text-right")}>
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, "drop-off", "bundleJoIconSite")}
                                />
                                {dropOffLabel}
                            </div>
                        </Grid>
                    </Grid>
                </Tooltip>
            </Box>
            <Arrow
                isRenderDrag={isRenderDrag}
                index={index}
                jobsCount={jobsCount}
                addJobToRoute={addJobToRoute}
                renderEndDrop={renderEndDrop}
                setLoadJob={setLoadJob}
            />
        </>
    );
};

const VIEW_ROUTE_SCROLL_HEIGHT = 100;

const Arrow = ({ index, jobsCount, isRenderDrag, addJobToRoute, renderEndDrop, hideArrow, setLoadJob }) => {
    const styles = joItemWrapper();

    const [{ canDrop, hovered }, drop] = useDrop({
        accept: ["job"],
        drop: (item) => {
            if (!item.unlimited && UNIT_OF_MEASURE_LOAD === item.unitOfMeasure) {
                setLoadJob({ ...item, insertAfter: index });
                return;
            }
            addJobToRoute(item, index);
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            hovered: monitor.isOver(),
        }),
    });

    const style = hovered ? { backgroundColor: "rgb(0 0 0 / 7%)" } : null;

    if (canDrop && (isRenderDrag || renderEndDrop)) {
        return (
            <Typography ref={drop} style={style} className={styles.dropArrowItem}>
                {DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE}
            </Typography>
        );
    }

    return !hideArrow && <Box className={styles.dropArrow}>{index !== jobsCount - 1 && <ArrowDownwardIcon />}</Box>;
};

const ScrollingComponent = withScrolling("div");

const BundleRoute = ({
    jobOrders,
    assignedTruck,
    primeHauler,
    subHauler,
    solutionIndex,
    setRoute,
    bundleId,
    viewBy,
    history,
    withCloseButton,
    routeId,
}) => {
    const [loadJob, setLoadJob] = useState();
    const [removeJobOrder, setRemoveJobOrder] = useState();
    const [warning, setWarning] = useState();

    const classes = bundleStyles();
    const dnDStyles = DRAG_AND_DROP_STYLES();
    const dispatch = useDispatch();
    useBrowserButtonHandler(() => dispatch(resetRouteDetails()), viewBy === "truck");
    const selectedTruckSolutionList = useSelector(selectSelectedTruckSolutionList);
    const routeIsEmpty = useSelector((state) => selectRouteIsEmpty(state, solutionIndex));
    const selectedTruckSolutionIndex = selectedTruckSolutionList[0];
    const truckSolutionIsSelected = _.isInteger(selectedTruckSolutionIndex);
    const isRouteSelected = useSelector(selectIfRouteIsSelected);
    const routeDetails = useSelector(selectRouteDetailsData);
    const iAmOwner = useSelector(viewBy === "bundle" ? selectCurrentBundleIAmOwner : selectRouteDetailsDataIAmOwner);
    const { loading, error } = useSelector(selectRouteDetailsMeta);
    const currentBundle = useSelector(selectCurrentBundle);
    const newAddedJob = useSelector((state) => state.smartDispatch.newJob);
    const routeTotalLoadCount = jobOrders && jobOrders.reduce((prev, current) => prev + current.loadCount, 0);
    const hasManuallyAddedJobs = () => jobOrders && jobOrders.find((i) => i.manuallyAdded);

    const assignedTruckTitle = `Assigned Truck: ${assignedTruck ? assignedTruck.name : "Not Assigned"}`;
    const isDraft = currentBundle?.isDraft;
    const moveJobPosition = (dragIndex, hoverIndex) => {
        dispatch(moveJob({ dragIndex, hoverIndex }));
    };

    const updateJobPosition = (item) => {
        dispatch(
            moveJob({
                createCall: true,
                bundleId: routeDetails.bundleId,
                index: routeDetails.solutionIndex,
                job: item,
            }),
        );
    };

    const setLoadJobToRoute = ({ loadsCount }) => {
        dispatch(insertJob(routeDetails.bundleId, routeDetails.solutionIndex, { ...loadJob, loadsCount }));
    };

    const addJobToRoute = (job, insertAfter) => {
        dispatch(insertJob(routeDetails.bundleId, routeDetails.solutionIndex, { id: job.id, insertAfter }));
    };

    const removeJob = (jobOrderId) => {
        setRemoveJobOrder(jobOrderId);
    };

    const yesHandler = () => {
        dispatch(
            removeJobFromRoute({
                bundleId: routeDetails ? routeDetails.bundleId : bundleId,
                jobId: removeJobOrder,
                index: routeDetails ? routeDetails.solutionIndex : solutionIndex,
                isBundleView: !routeDetails,
            }),
        );
        setRemoveJobOrder();
    };

    const noHandler = () => setRemoveJobOrder();

    const [{ canDrop }, drop] = useDrop({
        accept: [dropItemTypes.TRUCK, dropItemTypes.JOB],
        drop: (item) => {
            const { id, isTruck, defaultRate } = item;
            const assignmentObject = {
                index: solutionIndex,
            };
            if (isTruck) {
                assignmentObject.truckId = id;
            } else {
                assignmentObject.haulerId = id;
                assignmentObject.haulerRate = defaultRate;
            }
            const assignData = { assignments: [assignmentObject] };

            dispatch(updateAssignBundleData(assignData));
            dispatch(setSmartDispatchConfirmationModal(true));
        },
        collect: (monitor) => ({
            canDrop: !!monitor.canDrop(),
        }),
        canDrop: (item) => {
            if (item.type === dropItemTypes.TRUCK) {
                if (isDraft) {
                    return false;
                }

                return selectedTruckSolutionList.length === 1 && routeIsEmpty;
            }

            return true;
        },
    });

    const handleViewRouteClick = () => {
        if (setRoute) {
            setRoute();
        } else {
            dispatch(updateSelectedTruckSolutionList([routeId]));
        }
        if (hasManuallyAddedJobs()) {
            setWarning(OPEN_ROUTE_WITH_ADDED_MANUALLY_JOB_WARNING);
        }
        window.scrollTo(0, VIEW_ROUTE_SCROLL_HEIGHT);
    };

    const handleBackButtonClick = () => {
        dispatch(updateSelectedTruckSolutionList([]));
    };

    const onCancel = () => {
        dispatch(resetRouteDetails());
        setUpUrl(history.push)({ viewBy }).push();
    };

    const renderViewOnMap = () => {
        if (viewBy === BUNDLES_VIEW_BY.BUNDLE && !truckSolutionIsSelected) return true;
        if (viewBy === BUNDLES_VIEW_BY.TRUCK && !isRouteSelected) return true;

        return false;
    };

    const renderGoBackButton = () => !renderViewOnMap() && viewBy === BUNDLES_VIEW_BY.BUNDLE;

    useEffect(() => {
        if (!truckSolutionIsSelected) {
            dispatch(destroy("radiusInput"));
        }
    }, [truckSolutionIsSelected]);

    useEffect(() => {
        return () => {
            dispatch(updateActiveRegularJobsSortParam(null));
            dispatch(resetRouteDetails());
            dispatch(resetSelectedRoute());
        };
    }, []);

    const manualJobsIndexes = jobOrders && jobOrders.filter((i) => i.manuallyAdded).map((i) => i.index);

    return (
        <>
            {withCloseButton && (
                <Box margin={"15px"} display="flex" justifyContent="flex-end">
                    <CloseIcon color={"action"} fontSize={"default"} cursor={"pointer"} onClick={onCancel} />
                </Box>
            )}
            <div
                className={clsx(
                    classes.routeWrapper,
                    dnDStyles.dragAndDrop,
                    classes.dragable,
                    loading && LOADER_WHITE_OVERLAY,
                )}
            >
                {loading && <Loader />}
                <div className="route-header-container">
                    <div>
                        <h3 className="--text-left">{ROUTE_TITLE(solutionIndex, routeTotalLoadCount)}</h3>
                    </div>
                    {renderGoBackButton() && (
                        <div className="back-button-container">
                            <SimpleLink onClick={handleBackButtonClick}>Back</SimpleLink>
                        </div>
                    )}
                    {renderViewOnMap() && (
                        <div className="route-link-container">
                            <SimpleLink onClick={handleViewRouteClick}>View Route on Map</SimpleLink>
                        </div>
                    )}
                </div>
                {truckSolutionIsSelected && (
                    <SelectedRouteContainer selectedTruckSolutionIndex={selectedTruckSolutionIndex} viewBy={viewBy} />
                )}
                {isRouteSelected && <SelectedRouteContainer viewBy={viewBy} />}
                <h3 className="--text-left">{assignedTruckTitle}</h3>
                {primeHauler && <h3 className="--text-left">Hauler: {primeHauler.name}</h3>}
                {subHauler && <h3 className="--text-left">Subhauler: {subHauler.name}</h3>}
                {canDrop && viewBy === BUNDLES_VIEW_BY.BUNDLE && (
                    <div className={"draggable-overlay-wrap draggable-container"} ref={drop}>
                        <div className="draggable-overlay">
                            <p className="draggable-text full-height-text" style={{ height: "40%" }}>
                                {DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE}
                            </p>
                        </div>
                    </div>
                )}
                <ScrollingComponent className={clsx("bundle-detail-jo-list")}>
                    {jobOrders &&
                        jobOrders.map((jobOrder, index, array) => {
                            const isRenderDrag =
                                (manualJobsIndexes && manualJobsIndexes.find((i) => i - 1 === index) - 1 === index) ||
                                (manualJobsIndexes && manualJobsIndexes.includes(index));

                            return (
                                <BundleRouteItem
                                    key={jobOrder.routeItemId}
                                    jobOrder={jobOrder}
                                    index={index}
                                    jobsCount={jobOrders.length}
                                    removeJob={removeJob}
                                    iAmOwner={iAmOwner}
                                    moveJobPosition={moveJobPosition}
                                    updateJobPosition={updateJobPosition}
                                    isRenderDrag={isRenderDrag}
                                    addJobToRoute={addJobToRoute}
                                    newAddedJob={newAddedJob}
                                    setLoadJob={setLoadJob}
                                    renderEndDrop={
                                        (!manualJobsIndexes?.length && array.length - 1 === index) ||
                                        (manualJobsIndexes?.length &&
                                            manualJobsIndexes.length - 1 !== index &&
                                            array.length - 1 === index)
                                    }
                                    viewBy={viewBy}
                                />
                            );
                        })}
                </ScrollingComponent>
                {error && <ErrorNotification error={error} simpleString config={{ autoHideDuration: 5000 }} />}
                <AppModalWithoutConfirmation isOpen={loadJob} closeModal={() => setLoadJob()}>
                    <InputLoadsForm
                        form={INPUT_LOADS_FORM}
                        onSubmit={setLoadJobToRoute}
                        closeModal={() => setLoadJob()}
                    />
                </AppModalWithoutConfirmation>
                <ConfirmationModal
                    isOpen={!!removeJobOrder || !!(removeJobOrder === 0)}
                    question={REMOVE_JOB_WARNING}
                    yesHandler={yesHandler}
                    noHandler={noHandler}
                />
                <AppModal isOpen={warning} closeModal={() => setWarning()}>
                    <h2 className="title">{warning}</h2>
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton onClick={() => setWarning()}>OK</SecondaryButton>
                    </ButtonsGroup>
                </AppModal>
            </div>
        </>
    );
};

BundleRoute.propTypes = {
    jobOrders: PropTypes.array,
    assignedTruck: PropTypes.object,
    solutionIndex: PropTypes.number,
    bundleId: PropTypes.number,
};

BundleRouteItem.propTypes = {
    jobOrder: PropTypes.object,
    index: PropTypes.number,
    jobsCount: PropTypes.number,
    removeJob: PropTypes.func,
    iAmOwner: PropTypes.bool,
};

export default BundleRoute;
