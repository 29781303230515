import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { SimpleLink } from "../../core";
import { ROUTE_PROJECTS } from "../../../routes/globalRoutes";
import { getForcedSelectedPoLineItem } from "../../../actions/poLineItems";

const PayloadLink = ({ poLineItemId, projectId, children, onPayloadClick }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handlePayloadLinkClick = (event) => {
        if (projectId && poLineItemId) {
            event.stopPropagation();
            dispatch(getForcedSelectedPoLineItem(projectId, poLineItemId));
            history.push(ROUTE_PROJECTS.PROJECTS + `/${projectId}`, { project: { id: projectId } });
        }
        onPayloadClick && onPayloadClick();
    };

    return <SimpleLink onClick={handlePayloadLinkClick}>{children}</SimpleLink>;
};

PayloadLink.propType = {
    poLineItemId: PropTypes.number.isRequired,
    projectId: PropTypes.number.isRequired,
    children: PropTypes.any,
    onPayloadClick: PropTypes.func,
};
export default PayloadLink;
