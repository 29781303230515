import { USER_ROLE_CUSTOMER, USER_ROLE_INSPECTOR, USER_ROLE_SUB_TRUCKER, USER_ROLE_TRUCKER, USER_ROLES } from "./maps";

export const USER_ROLES_SHORT = [
    {
        name: USER_ROLES[USER_ROLE_CUSTOMER],
        value: USER_ROLE_CUSTOMER,
    },
    {
        name: USER_ROLES[USER_ROLE_SUB_TRUCKER],
        value: USER_ROLE_SUB_TRUCKER,
    },
    {
        name: USER_ROLES[USER_ROLE_TRUCKER],
        value: USER_ROLE_TRUCKER,
    },
    {
        name: USER_ROLES[USER_ROLE_INSPECTOR],
        value: USER_ROLE_INSPECTOR,
    },
];

export const fullCompanyAddress = (values) =>
    values.secondaryAddress
        ? `${values.streetAddress} ${values.secondaryAddress}, ${values.city}, ${values.state?.label}, ${values.zipCode}`
        : `${values.streetAddress}, ${values.city}, ${values.state?.label}, ${values.zipCode}`;

export const generateUserSelectOptions = (user) => ({
    ...user,
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
});
