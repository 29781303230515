import React, { useEffect, useState } from "react";
import SiteStats from "./siteStats";
import { fetchSiteStatsById } from "../../dataServers/sites";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import SiteStatsFilters, { SITE_STATS_FILTERS_FORM } from "./siteStatsFilters";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../../constants/global";
import { DividerThin } from "../core/divider";
import clsx from "clsx";
import Loader from "../core/loader";
import SiteDailyLoads from "./SiteDailyLoads";

const styles = makeStyles((theme) => ({
    siteStatsFilters: {
        width: "40%",
        marginBottom: "25px",
    },
    siteDetailsStatsContainer: {
        marginBottom: "30px",
    },
}));

const SiteDetailsStatsWrapper = ({ siteId, errorHandler }) => {
    const classes = styles();
    const [siteStatsData, setSiteStatsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const eventsDate = useSelector((state) => formValueSelector(SITE_STATS_FILTERS_FORM)(state, "startDate"));

    useEffect(() => {
        if (!eventsDate) {
            return;
        }

        const momentEventsDate = moment(eventsDate);
        if (momentEventsDate.isValid()) {
            const formattedEventsDate = momentEventsDate.format(SERVER_DATE_FORMAT);

            setIsLoading(true);
            fetchSiteStatsById(siteId, { startDate: formattedEventsDate, endDate: formattedEventsDate })
                .then((response) => {
                    setSiteStatsData(response.data);
                })
                .catch((error) => {
                    errorHandler([PROCESS_SERVER_ERROR(error)]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [siteId, eventsDate]);

    return (
        <>
            <Grid item xs={4}>
                <h2>Site Stats</h2>
            </Grid>
            <DividerThin marginTop={1} />
            <SiteDailyLoads siteId={siteId} errorHandler={errorHandler} />
            <div className={clsx(classes.siteDetailsStatsContainer, isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <Grid item xs={12}>
                    <div className={classes.siteStatsFilters}>
                        <SiteStatsFilters initialValues={{ startDate: moment() }} />
                    </div>
                </Grid>
                <SiteStats siteStatsData={siteStatsData} />
            </div>
        </>
    );
};

export default SiteDetailsStatsWrapper;
