import React, { useEffect, useState } from "react";
import { Slider, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateTruckRouteLocation } from "../../../actions/smartDispatch";
import { MAX_COORDINATES_AMOUNT } from "../MapWithDirections";
import SliderMarkTooltip from "./RouteTooltipTitle";
import { toDate } from "../../../helpers/date";
import { selectAccountTimezone } from "../../../selectors";
import { DEFAULT_TIME_FORMAT } from "../../../constants/global";
import Box from "@material-ui/core/Box";
import RouteSliderChart from "./RouteSliderChart";
import { START_LOCATION } from "../constants/strings";

const styles = makeStyles(() => ({
    bundleRouteSliderClasses: {
        "& .MuiSlider-rail, & .MuiSlider-track": {
            height: "6px",
        },
        "& .MuiSlider-thumb": {
            height: "14px",
            width: "14px",
            marginLeft: "-6px",
            marginTop: "-4px",
        },
        "& .MuiSlider-thumb:hover": {
            boxShadow: "0px 0px 0px 2px rgb(185 98 17 / 16%)",
        },

        "& .MuiSlider-mark": {
            height: "15px",
            top: "9px",
            width: "3px",
            marginLeft: "1px",
        },

        "& .slider-location-label": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "10px",
            marginTop: "8px",
        },
        "& .raised-label-marker": {
            marginTop: "-41px",
        },

        "& .same-time-label-marker": {
            marginTop: "-35px",
            marginLeft: "3px",
        },
    },
    chartIcon: {
        fontSize: 20,
    },
}));

const getBundleRouteLocationPoints = (routeLocations) => {
    return routeLocations.map((truckLocation) => {
        return {
            ...truckLocation,
            ...truckLocation.location,
        };
    });
};

const getTooltipTittle = (location) => {
    const { name, address, jobId, isDepot, isStartTruckLocation } = location;
    if (isDepot) {
        return name || address || "N/A";
    }
    if (isStartTruckLocation) {
        return START_LOCATION;
    }

    return `Job # ${jobId}`;
};
const BundleRouteSlider = ({ routeLocations }) => {
    const dispatch = useDispatch();
    const timezone = useSelector(selectAccountTimezone);

    const classes = styles();
    const [marks, setMarks] = useState([]);
    const [sliderValue, setSliderValue] = useState(0);

    const onSliderChange = (e, value) => {
        setSliderValue(value);
        const activeThumb = marks.find((mark) => mark.value === value);
        if (activeThumb) {
            const truckLocation = activeThumb.location;
            dispatch(
                updateTruckRouteLocation({
                    lat: truckLocation.latitude,
                    lng: truckLocation.longitude,
                }),
            );
        }
    };

    useEffect(() => {
        const locationPoints = getBundleRouteLocationPoints(routeLocations);
        let pickUpNumber = 1;
        let dropOffNumber = 1;

        const updatedMarks = locationPoints
            .filter((location) => !location.isJobStart)
            .map((location, index, marks) => {
                const { isDepot, isPickUp, isDropOff, isStartTruckLocation } = location;

                const nextLocation = marks[index + 1];
                const sameTimestampLocation = nextLocation && nextLocation.timestamp === location.timestamp;

                let locationLabel;
                if (isPickUp) {
                    locationLabel = `PU${pickUpNumber}`;
                } else if (isDropOff) {
                    locationLabel = `DO${dropOffNumber}`;
                    dropOffNumber++;
                    pickUpNumber++;
                } else if (isDepot) {
                    locationLabel = "Depot";
                } else if (isStartTruckLocation) {
                    locationLabel = "Start";
                }

                const tooltipTimestamp = toDate({
                    date: location.timestamp,
                    timezone: timezone,
                }).format(DEFAULT_TIME_FORMAT);
                const tooltipTitle = getTooltipTittle(location, marks);
                const isRaisedLabel = isDepot || isStartTruckLocation;

                return {
                    value: Date.parse(location.timestamp),
                    label: (
                        <div
                            className={clsx(
                                "slider-location-label",
                                isRaisedLabel && "raised-label-marker",
                                sameTimestampLocation && "same-time-label-marker",
                            )}
                        >
                            <Tooltip title={<SliderMarkTooltip timestamp={tooltipTimestamp} title={tooltipTitle} />}>
                                <div>{locationLabel}</div>
                            </Tooltip>
                        </div>
                    ),
                    location,
                };
            });

        const initialTruckLocation = updatedMarks[0].location;
        dispatch(
            updateTruckRouteLocation({
                lat: initialTruckLocation.latitude,
                lng: initialTruckLocation.longitude,
            }),
        );

        setMarks(updatedMarks);

        return () => {
            setSliderValue(0);
        };
    }, [routeLocations]);

    if (marks.length > MAX_COORDINATES_AMOUNT) {
        return null;
    }

    if (_.isEmpty(marks)) {
        return null;
    }

    return (
        <Box marginTop="25px">
            <Slider
                className={clsx(classes.bundleRouteSliderClasses)}
                step={null}
                marks={marks}
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                value={sliderValue}
                onChange={onSliderChange}
            />
            <RouteSliderChart routeLocations={routeLocations} />
        </Box>
    );
};

BundleRouteSlider.propTypes = {
    routeLocations: PropTypes.array.isRequired,
};

export default BundleRouteSlider;
