import asteriskOrange from "./images/asterisk-orange.svg";
import iconAccount from "./images/account-circle.svg";
import iconBell from "./images/bell.svg";
import logo from "./images/logo.svg";
import plus from "./images/plus.svg";
import generalTrucks from "./images/general-trucks.svg";
import generalTrucksDark from "./images/general-trucks-dark.svg";
import generalTrucksWhite from "./images/general-trucks-white.svg";
import success from "./images/success.svg";
import I80ConcreteLogo from "./images/I-80ConcreteLogo.svg";
import trailerIcon from "./images/trailer.svg";
import activeTrailerIcon from "./images/activeTrailer.svg";
import trailerFilledSmall from "./images/trailerFilled20x20.svg";
import trailerFilled from "./images/trailerFilled24x24.svg";
import trailerFilledLarge from "./images/trailerFilled32x32.svg";
import iconSplit from "./images/splitScreen.svg";
import { makeStyles } from "@material-ui/core";
import { MAIN_THEME } from "./globalThemeConfig";
import { getTrucksForMap } from "../helpers/global";
import { TRUCK_ITEM_ICON_SIZE } from "../components/fleet/truckItemIcon";

const defaultStyles = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "inline-block",
};

export const THEME_ICONS = {
    logo: {
        ...defaultStyles,
        backgroundImage: `url(${logo})`,
        width: 101,
        height: 105,
        backgroundSize: "70%",
    },
    notifications: {
        ...defaultStyles,
        backgroundImage: `url(${iconBell})`,
        width: 20,
        height: 24.4,
        backgroundSize: "100%",
    },
    account: {
        ...defaultStyles,
        backgroundImage: `url(${iconAccount})`,
        width: 35,
        height: 35,
    },
    asteriskOrange: {
        ...defaultStyles,
        backgroundImage: `url(${asteriskOrange})`,
        width: 24,
        height: 24,
    },
    plus: {
        ...defaultStyles,
        backgroundImage: `url(${plus})`,
        width: 16,
        height: 16,
    },
    eventIcon: {
        width: 24,
        height: 24,
    },
    activeTrailerIcon: {
        ...defaultStyles,
        backgroundImage: `url(${activeTrailerIcon})`,
        width: 32,
        height: 32,
    },
    trailerIcon: {
        ...defaultStyles,
        backgroundImage: `url(${trailerIcon})`,
        width: 32,
        height: 32,
    },
    generalTruck: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucks})`,
        width: 24,
        height: 24,
    },
    generalTruckDark: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucksDark})`,
        width: 24,
        height: 24,
    },
    generalTruckWhite: {
        ...defaultStyles,
        backgroundImage: `url(${generalTrucksWhite})`,
        width: 24,
        height: 24,
    },
    success: {
        ...defaultStyles,
        backgroundImage: `url(${success})`,
        width: 250,
        height: 201,
    },
    i80Logo: {
        ...defaultStyles,
        backgroundImage: `url(${I80ConcreteLogo})`,
        width: 32,
        height: 32,
    },
    trailerFilledLarge: {
        ...defaultStyles,
        backgroundImage: `url(${trailerFilledLarge})`,
        width: 33,
        height: 33,
    },
    trailerFilled: {
        ...defaultStyles,
        backgroundImage: `url(${trailerFilled})`,
        width: 24,
        height: 24,
    },
    trailerFilledSmall: {
        ...defaultStyles,
        backgroundImage: `url(${trailerFilledSmall})`,
        width: 33,
        height: 33,
    },
    tocIconsStyles: {
        color: MAIN_THEME.palette.secondary.dark,
        display: "block",
        width: 25,
        height: 25,
    },
};

export const ICONS_SITE = makeStyles((theme) => ({
    iconSite: {
        width: theme.icon.width,
        height: theme.icon.height,
        position: "relative",
        marginRight: 16,

        "&.pick-up": {
            color: theme.palette.general.softGreen,
        },
        "&.drop-off": {
            color: theme.palette.general.paleRed,
        },
        "&.mid": {
            color: theme.palette.general.pending,
        },
    },
}));

export const TYPOGRAPHY_ICONS_SITE = makeStyles((theme) => {
    return {
        iconSite: {
            width: 15,
            height: 15,
            position: "relative",

            "&.pick-up": {
                color: theme.palette.general.softGreen,
            },
            "&.drop-off": {
                color: theme.palette.general.paleRed,
            },
            "&.mid": {
                color: theme.palette.general.pending,
            },
        },
    };
});

export const MATERIAL_UI_ICONS_COLOR = "rgba(0, 0, 0, 0.54)";

export const getMyFleetTruckIcon = (status) => {
    return getTrucksForMap(status ?? 1, 0, TRUCK_ITEM_ICON_SIZE);
};

export const TRUCK_ITEM_ICONS = {
    truckIcon: (status) => ({
        ...defaultStyles,
        backgroundImage: `url(${getMyFleetTruckIcon(status)})`,
    }),
};

export const TRAILER_TAB_ICON = {
    ...defaultStyles,
    maskImage: `url(${trailerFilled})`,
    WebkitMaskImage: `url(${trailerFilled})`,
    width: 24,
    height: 24,
    marginRight: 15,
    backgroundColor: "currentColor",
};

export const SPLIT_ICON = {
    ...defaultStyles,
    maskImage: `url(${iconSplit})`,
    WebkitMaskImage: `url(${iconSplit})`,
    width: 24,
    height: 24,
    marginRight: 15,
    backgroundColor: "currentColor",
};
