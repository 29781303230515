export const REQUEST_PREFERRED_HAULERS = "SETTINGS: REQUEST PREFERRED HAULERS";
export const RECEIVE_PREFERRED_HAULERS = "SETTINGS: RECEIVE PREFERRED HAULERS";
export const RESET_PREFERRED_HAULERS = "SETTINGS: RESET PREFERRED HAULERS";
export const ERROR_PREFERRED_HAULERS = "SETTINGS: ERROR PREFERRED HAULERS";
export const RESET_PREFERRED_HAULERS_META = "SETTINGS: RESET PREFERRED HAULERS META";
export const SUCCESS_PREFERRED_HAULERS = "SETTINGS: SUCCESS PREFERRED HAULERS META";

export const REQUEST_OFFLINE_HAULERS = "SETTINGS: REQUEST OFFLINE HAULERS";
export const RECEIVE_OFFLINE_HAULERS = "SETTINGS: RECEIVE OFFLINE HAULERS";
export const ERROR_OFFLINE_HAULERS = "SETTINGS: ERROR OFFLINE HAULERS";
export const RESET_OFFLINE_HAULERS_META = "SETTINGS: RESET OFFLINE HAULERS META";
export const SUCCESS_OFFLINE_HAULERS = "SETTINGS: SUCCESS OFFLINE HAULERS META";
export const UPDATE_CUSTOMERS_SETTINGS_ACTIVE_TAB = "SETTINGS: UPDATE CUSTOMERS SETTINGS ACTIVE TAB";
