/* global google */
import React from "react";
import { withScriptjs } from "react-google-maps";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Validation } from "../../helpers/validation";
import AsyncAutoCompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Field } from "redux-form";
import clsx from "clsx";
import { compose, withProps } from "recompose";
import { GOOGLE_MAPS_URL } from "../../constants/global";

const AutocompleteAddress = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
)((props) => {
    const { name, className, clearRequired, onAddressChange, placeholder } = props;
    const autocompleteService = new google.maps.places.AutocompleteService();
    const loadAddressOptions = (inputValue) => {
        return new Promise((resolve) => {
            if (inputValue) {
                autocompleteService.getPlacePredictions({ input: inputValue }, (response) => {
                    resolve(response);
                });
            } else {
                resolve([]);
            }
        }).then((data = []) => {
            const results = data
                ? data.map((place, index) => ({
                      value: index,
                      label: place.description,
                      placeId: place.place_id,
                  }))
                : [];

            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    return (
        <Field
            name={name}
            googleMapURL={GOOGLE_MAPS_URL}
            className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, className)}
            loadOptions={loadAddressOptions}
            isClearable={true}
            validate={!clearRequired && [Validation.required]}
            onChange={(input) => onAddressChange && onAddressChange(input)}
            placeholder={placeholder}
            component={AsyncAutoCompleteComponent}
        />
    );
});

AutocompleteAddress.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    onAddressChange: PropTypes.func,
};

AutocompleteAddress.defaultProps = {
    name: "address",
    placeholder: "Address",
};

export default withRouter(AutocompleteAddress);
