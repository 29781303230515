import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EditIcon from "@material-ui/icons/Edit";
import FlagIcon from "@material-ui/icons/Flag";
import RoomIcon from "@material-ui/icons/Room";
import RouterIcon from "@material-ui/icons/Router";

import { IS_ADMIN_USER, IS_INSPECTOR_USER, JO_STATUS_ACTIVE_ID, JO_STATUS_COMPLETED_ID } from "../../../constants/maps";
import ConfirmationModal from "../../core/confirmationModal";
import Loader from "../../core/loader";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { SimpleLink } from "../../core/link";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { Divider } from "../../core/divider";
import { ICONS_SITE } from "../../../styles/icons";
import AppModal, { AppModalWithoutConfirmation } from "../../core/modal";
import JobOrderMoreActions from "../jobComponents/moreActions";
import MessageDrivers, { MESSAGE_FOR_DRIVERS_FORM } from "./messageDrivers";
import JobOrderMap from "../jobComponents/jobOrderMap";
import { ErrorBoundary } from "../../core/errorBoudaries";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import NotesIcon from "@material-ui/icons/Notes";
import {
    getJobBoardEditRoute,
    getJobOrderEditRoute,
    getJobOrderRequestRoute,
    getProjectEditRoute,
    getSiteEditRoute,
} from "../../../helpers/global";
import Stats from "./stats";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateTicketForm, { CREATE_TICKET_FORM } from "../../tickets/components/createTicketForm";
import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import {
    DEFAULT_DATE_TIME_FORMAT,
    ENV_MODE_PROD,
    FREQUENCY_UPDATE_PROD,
    FREQUENCY_UPDATE_TEST_DEMO,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
    SERVER_DATE_FORMAT,
} from "../../../constants/global";
import PoliAdditionalFields from "../../global/poliAdditionalFields";
import { PAVERTRACKERS_NAME } from "../../global/pavertrackersSelector";
import { closeJobOrderById, getJobOrderById } from "../../../dataServers/jobOrder";
import ErrorNotification from "../../core/notification";
import { refreshJobsList, saveJobOrdersInRedux, updateActiveDispatchedTruckOrder } from "../../../actions/jobOrders";
import { getIsJobOrderRequest, getIfIsDropOffMidpoint, getIfIsPickUpMidpoint } from "../../../helpers/jobOrders";
import { selectCompanyId } from "../../../selectors";
import { getCanSeeProject } from "./trucks/ProjectLink";
import { selectDefaultAdditionalExternalRef } from "../../../reducers/additionalExternalRefsReducer";
import { selectIfIsRestrictedCustomer } from "../../../selectors/user";

const useStyles = makeStyles((theme) => ({
    map: {
        height: 342,
        borderWidth: 0,
        backgroundColor: theme.palette.primary.superLight,
        marginTop: 4,
    },
    uiIcon: {
        width: theme.icon.width,
        height: theme.icon.height,
        position: "relative",
        top: 5,
        marginRight: 11,
    },
    uiIconGrey: {
        color: theme.palette.secondary.dark,
    },
    boxContent: {
        paddingLeft: theme.icon.width + 11,
    },
    boxStyles: {
        padding: "0 11px",
    },
    marginBottom: {
        marginBottom: 24,
    },
    notes: {
        whiteSpace: "normal",
    },
    relatedOrders: {
        padding: "0 46px",
        marginBottom: 47,
    },
    poBar: {
        backgroundColor: theme.palette.general.paleBlue,
        borderRadius: theme.shape.borderRadius,
    },
    poBarWrap: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.superLight,
        border: `1px solid ${theme.palette.secondary.main}`,
        height: 8,
        marginBottom: theme.spacing(2),
    },
    pricePerUnit: {
        color: theme.palette.general.priceGreen,
        fontWeight: theme.typography.fontWeightMedium,
    },
    poQtyBar: {
        width: "100%",
        height: 8,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.superLight,
        border: `1px solid ${theme.palette.border.primary}`,
        position: "relative",
        marginBottom: 5,
        marginTop: theme.spacing(2),

        "& *": {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            borderRadius: theme.shape.borderRadius,

            "&:first-child": {
                height: 6,
            },

            "&:first-child:not(.bar)": {
                zIndex: 2,
            },
        },
    },
    projectLink: {
        marginLeft: 5,
    },
    joButtonsGroup: {
        [theme.breakpoints.down("1350")]: {
            flexWrap: "wrap",
        },
    },
    joButton: {
        [theme.breakpoints.down("1350")]: {
            marginLeft: "0px !important",
            margin: 5,
        },
    },
}));

function JobOrderSummary(props) {
    const companyId = useSelector(selectCompanyId);
    const defaultAdditionalExternalRef = useSelector(selectDefaultAdditionalExternalRef);

    const [showConfirmationModal, setShowConfirmationModal] = useState(null);
    const [state, setState] = useState({ showMessageModal: false, showTicketModal: false });
    const { showMessageModal, showTicketModal } = state;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isJobOrderHauler, setIsJobOrderHauler] = useState(false);
    const {
        jobOrder: {
            pickUpSite,
            dropOffSites,
            poLineItem,
            id,
            status,
            iAmOwner,
            notes,
            ticketsCount,
            unitOfMeasure,
            assignedTrucksCount,
            grantedAccess,
            miscFieldLabel,
            copySchedule,
            costCode,
            createdBy,
            createdDate,
            company: jobCompany,
        },
        account,
        jobOrder,
        history,
        updateJoStatus,
        handleTicketCreate,
        jobOrders,
        setJobOrders,
        updateTicketCount,
        updateJoCopySchedule,
        refreshJobs,
        isRestrictedCustomer,
    } = props;
    const classes = useStyles();
    const siteIconsStyles = ICONS_SITE();

    const isJobBoardPage = history.location.pathname.includes(ROUTE_JOBS.JOB_BOARD);
    const isJobOrderRequest = getIsJobOrderRequest(jobOrder, account);

    const isJoClosed = +jobOrder.status === JO_STATUS_COMPLETED_ID;

    const canSeeProject = getCanSeeProject(account.role, jobCompany?.id, companyId, grantedAccess);

    const openJobOrderForEdit = () => {
        history.push(getJobOrderEditRoute(jobOrder.id));
    };
    const openJobBoardItemEdit = () => {
        history.push(getJobBoardEditRoute(id));
    };
    const openJobOrderRequest = () => {
        history.push(getJobOrderRequestRoute(id));
    };
    const openTicketsPage = () => {
        const data = {
            jobOrderId: jobOrder.id,
            [START_DATE_NAME]: moment.utc(jobOrder[START_DATE_NAME]).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: jobOrder.isOvernight
                ? moment.utc(jobOrder[START_DATE_NAME]).add(1, "days").format(SERVER_DATE_FORMAT)
                : moment.utc(jobOrder[START_DATE_NAME]).format(SERVER_DATE_FORMAT),
        };

        history.push(ROUTE_JOBS.TICKETS, data);
    };
    const createNewTicket = (open) => {
        setState({ ...state, showTicketModal: open });
    };

    const showCloseJobConfirmation = (e) => {
        e && e.stopPropagation();

        setShowConfirmationModal("Are you sure you want to close this job?");
    };

    const closeJobConfirmation = (event) => {
        event && event.stopPropagation();
        setShowConfirmationModal(null);
    };

    const closeJobOrder = (e) => {
        e && e.stopPropagation();
        setLoading(true);
        closeJobOrderById(jobOrder.id)
            .then(() => {
                updateJoStatus && updateJoStatus(jobOrder.id, JO_STATUS_COMPLETED_ID);
                refreshJobs(true);
                setShowConfirmationModal(false);
                setLoading(false);
            })
            .catch((e) => {
                setError(PROCESS_SERVER_ERROR(e));
                setLoading(false);
            });
    };

    const actualJobOrderData = jobOrders.find((i) => +i.id === +jobOrder.id) || {};

    const isDropOffMidpoint = getIfIsDropOffMidpoint(actualJobOrderData);
    const isPickUpMidpoint = getIfIsPickUpMidpoint(actualJobOrderData);

    const loadJobOrderData = () => {
        getJobOrderById(jobOrder.id)
            .then(({ data }) => {
                setJobOrders(
                    jobOrders.map((jo) => {
                        if (jo.id === jobOrder.id) {
                            return {
                                ...jo,
                                status: data.status,
                                dispatchedQuantity: data.dispatchedQuantity,
                                totalQuantity: data.totalQuantity,
                                completedQuantity: data.completedQuantity,
                            };
                        }

                        return jo;
                    }),
                );
                updateTicketCount({ ticketsCount: data.ticketsCount, status: data.status });
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            });
    };
    useEffect(() => {
        const time =
            process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD ? FREQUENCY_UPDATE_PROD : FREQUENCY_UPDATE_TEST_DEMO;
        const interval = setInterval(() => {
            loadJobOrderData(jobOrder.id);
        }, time);

        setIsJobOrderHauler(!_.isEmpty(jobOrder.haulers.filter((item) => item.id === account.company.id)));

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="summary-wrap">
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            <Divider />
            {!IS_INSPECTOR_USER(account.role) && (
                <React.Fragment>
                    <Box mt={1}>
                        <ButtonsGroup className={classes.joButtonsGroup}>
                            {isJobOrderRequest && (
                                <SecondaryButton
                                    className={classes.joButton}
                                    size={"medium"}
                                    onClick={openJobOrderRequest}
                                >
                                    <EditIcon />
                                    Edit Request
                                </SecondaryButton>
                            )}
                            {!isJobBoardPage ? (
                                <>
                                    {!!assignedTrucksCount && !grantedAccess && !copySchedule && !isRestrictedCustomer && (
                                        <SecondaryButton
                                            className={classes.joButton}
                                            size={"medium"}
                                            onClick={() => createNewTicket(true)}
                                        >
                                            <AddCircleOutlineIcon />
                                            Add Ticket
                                        </SecondaryButton>
                                    )}
                                    {(iAmOwner || IS_ADMIN_USER(account.company.id)) && !isRestrictedCustomer && (
                                        <SecondaryButton
                                            className={classes.joButton}
                                            size={"medium"}
                                            onClick={openJobOrderForEdit}
                                        >
                                            <EditIcon />
                                            {isJoClosed ? "View" : "Edit"} Job
                                        </SecondaryButton>
                                    )}
                                    {!!ticketsCount && (
                                        <SecondaryButton className={classes.joButton} onClick={openTicketsPage}>
                                            <AssignmentTurnedInIcon />
                                            TICKETS ({ticketsCount})
                                        </SecondaryButton>
                                    )}
                                    {(iAmOwner || IS_ADMIN_USER(account.company.id)) && !isRestrictedCustomer && (
                                        <React.Fragment>
                                            <br />
                                            <JobOrderMoreActions
                                                buttonClassName={classes.joButton}
                                                displayAsButtons={true}
                                                jobOrder={{
                                                    ...jobOrder,
                                                    status: actualJobOrderData.status || jobOrder.status,
                                                    copySchedule:
                                                        actualJobOrderData.copySchedule || jobOrder.copySchedule,
                                                }}
                                                updateJoCopySchedule={updateJoCopySchedule}
                                                updateJoStatus={updateJoStatus}
                                                print={true}
                                            />
                                        </React.Fragment>
                                    )}
                                    {status !== JO_STATUS_COMPLETED_ID && !isRestrictedCustomer && (
                                        <PrimaryButton
                                            className={classes.joButton}
                                            onClick={() => setState({ ...state, showMessageModal: true })}
                                        >
                                            Message Drivers
                                        </PrimaryButton>
                                    )}
                                </>
                            ) : (
                                <React.Fragment>
                                    {(!isJoClosed || (jobOrder.copySchedule && jobOrder.copySchedule.enabled)) &&
                                        (iAmOwner || IS_ADMIN_USER(account.company.id)) &&
                                        !isRestrictedCustomer && (
                                            <SecondaryButton
                                                className={clsx(loading && LOADER_WHITE_OVERLAY, classes.joButton)}
                                                onClick={showCloseJobConfirmation}
                                            >
                                                {loading && <Loader />}
                                                Close Job
                                            </SecondaryButton>
                                        )}
                                    {(!grantedAccess || isJobOrderHauler) && !isRestrictedCustomer && (
                                        <PrimaryButton className={classes.joButton} onClick={openJobBoardItemEdit}>
                                            Fill Job
                                        </PrimaryButton>
                                    )}
                                </React.Fragment>
                            )}
                        </ButtonsGroup>
                    </Box>
                    <ConfirmationModal
                        isOpen={!!showConfirmationModal}
                        question={showConfirmationModal}
                        yesHandler={closeJobOrder}
                        noHandler={closeJobConfirmation}
                    />
                    <AppModalWithoutConfirmation
                        isOpen={showMessageModal}
                        form={MESSAGE_FOR_DRIVERS_FORM}
                        closeModal={() => setState({ ...state, showMessageModal: false })}
                    >
                        <MessageDrivers
                            jobOrder={jobOrder}
                            closeModal={() => setState({ ...state, showMessageModal: false })}
                        />
                    </AppModalWithoutConfirmation>
                    <AppModal
                        isOpen={showTicketModal}
                        form={CREATE_TICKET_FORM}
                        closeModal={() => createNewTicket(false)}
                        modalStyles={{ width: 500 }}
                    >
                        <CreateTicketForm
                            closeModal={() => createNewTicket(false)}
                            form={CREATE_TICKET_FORM}
                            handleTicketCreate={handleTicketCreate}
                            jobOrderHasHaulers={jobOrder.haulers.length > 0}
                            initialValues={{
                                project:
                                    poLineItem && poLineItem.purchaseOrder && poLineItem.purchaseOrder.project
                                        ? {
                                              value: poLineItem.purchaseOrder.project.id,
                                              label: poLineItem.purchaseOrder.project.name,
                                              ...poLineItem.purchaseOrder.project,
                                          }
                                        : null,
                                jobOrder: {
                                    value: jobOrder.id,
                                    payload: jobOrder?.poLineItem?.payload?.name,
                                    ticketsType: poLineItem && poLineItem.ticketsType,
                                    unitOfMeasure,
                                    iAmOwner,
                                    label: `#${jobOrder.id}${
                                        jobOrder.extRef ? ` / Ext. Ref #${jobOrder.extRef}` : ""
                                    } ${jobOrder.customerName ? ` - ${jobOrder.customerName}` : ""}${
                                        poLineItem && poLineItem.purchaseOrder && poLineItem.purchaseOrder.project
                                            ? `- ${poLineItem.purchaseOrder.project.name}`
                                            : ""
                                    }`,
                                    startDate: jobOrder && jobOrder.startDate,
                                },
                                additionalExternalRefs: defaultAdditionalExternalRef
                                    ? [
                                          {
                                              label: {
                                                  value: defaultAdditionalExternalRef.id,
                                                  label: defaultAdditionalExternalRef.name,
                                              },
                                              isDefault: defaultAdditionalExternalRef.isDefault,
                                              fieldId: _.uniqueId(),
                                          },
                                      ]
                                    : [],
                            }}
                        />
                    </AppModal>
                    <Divider />
                </React.Fragment>
            )}
            {!isRestrictedCustomer && (
                <ErrorBoundary>
                    <JobOrderMap
                        jobOrder={jobOrder}
                        isDropOffMidpoint={isDropOffMidpoint}
                        isPickUpMidpoint={isPickUpMidpoint}
                    />
                </ErrorBoundary>
            )}
            {(jobOrder.status === JO_STATUS_ACTIVE_ID || jobOrder.status === JO_STATUS_COMPLETED_ID) &&
                !isRestrictedCustomer && <Stats jobOrder={jobOrder} />}
            <h3>SITE DETAILS</h3>
            <br />
            {(pickUpSite || !_.isEmpty(dropOffSites)) && (
                <React.Fragment>
                    <Grid component="div" container>
                        <Grid item xs={6} component="div">
                            <Box className={classes.boxStyles}>
                                {!_.isEmpty(pickUpSite) && (
                                    <React.Fragment>
                                        <h3>
                                            <RoomIcon
                                                className={clsx(
                                                    siteIconsStyles.iconSite,
                                                    classes.uiIcon,
                                                    isPickUpMidpoint ? "mid" : "pick-up",
                                                )}
                                            />
                                            Pick-Up Site
                                        </h3>
                                        <Box className={classes.boxContent}>
                                            {IS_INSPECTOR_USER(account.role) || isRestrictedCustomer ? (
                                                pickUpSite.name
                                            ) : (
                                                <SimpleLink to={getSiteEditRoute(pickUpSite.id)}>
                                                    {pickUpSite.name}
                                                </SimpleLink>
                                            )}
                                            <p>{pickUpSite.address}</p>
                                        </Box>
                                    </React.Fragment>
                                )}
                                {!!pickUpSite && pickUpSite.notes && (
                                    <React.Fragment>
                                        <h3>
                                            <NotesIcon className={clsx(siteIconsStyles.iconSite, classes.uiIcon)} />
                                            Site Notes
                                        </h3>
                                        <Box className={classes.boxContent}>
                                            <p className={classes.notes}>{pickUpSite.notes}</p>
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                        </Grid>
                        {!_.isEmpty(dropOffSites) && (
                            <Grid item xs={6} component="div">
                                {dropOffSites &&
                                    dropOffSites.map((site, index) => {
                                        return (
                                            <Box className={classes.boxStyles} key={index}>
                                                <h3>
                                                    <RoomIcon
                                                        className={clsx(
                                                            siteIconsStyles.iconSite,
                                                            classes.uiIcon,
                                                            isDropOffMidpoint ? "mid" : "drop-off",
                                                        )}
                                                    />
                                                    Drop-Off Site
                                                </h3>
                                                <Box className={classes.boxContent}>
                                                    {IS_INSPECTOR_USER(account.role) || isRestrictedCustomer ? (
                                                        site.name
                                                    ) : (
                                                        <SimpleLink to={getSiteEditRoute(site.id)}>
                                                            {site.name}
                                                        </SimpleLink>
                                                    )}
                                                    <p>{site.address}</p>
                                                </Box>
                                                {!!site.notes && (
                                                    <React.Fragment>
                                                        <h3>
                                                            <NotesIcon
                                                                className={clsx(
                                                                    siteIconsStyles.iconSite,
                                                                    classes.uiIcon,
                                                                )}
                                                            />
                                                            Site Notes
                                                        </h3>
                                                        <Box className={classes.boxContent}>
                                                            <p className={classes.notes}>{site.notes}</p>
                                                        </Box>
                                                    </React.Fragment>
                                                )}
                                            </Box>
                                        );
                                    })}
                            </Grid>
                        )}
                    </Grid>
                    <br />
                </React.Fragment>
            )}
            <Divider marginTop={0} />
            <Grid container className={clsx(classes.boxStyles, classes.marginBottom)} justify={"space-between"}>
                <Grid item xs={6}>
                    <h3 className="--uppercase">{isJobOrderRequest ? "REQUEST" : "JOB"} DETAILS</h3>
                </Grid>
                {!_.isEmpty(poLineItem.additionalFields) && (
                    <Grid item xs={6}>
                        <h3 className="--uppercase">PAYLOAD NOTES</h3>
                    </Grid>
                )}
            </Grid>
            <br />
            {poLineItem && poLineItem.purchaseOrder && poLineItem.purchaseOrder.project && (
                <Grid container className={clsx(classes.boxStyles, classes.marginBottom)} justify={"space-between"}>
                    <Grid item xs={6}>
                        <Grid container component={"h3"} wrap={"nowrap"} alignItems={"baseline"}>
                            <FlagIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                            {!canSeeProject ? (
                                poLineItem.purchaseOrder.project.name
                            ) : (
                                <Grid container item alignItems={"center"}>
                                    {poLineItem.purchaseOrder.project.customer &&
                                        `${poLineItem.purchaseOrder.project.customer.name} /`}
                                    <SimpleLink
                                        to={{
                                            pathname: getProjectEditRoute(poLineItem.purchaseOrder.project.id),
                                            state: { project: { id: poLineItem.purchaseOrder.project.id } },
                                        }}
                                        className={classes.projectLink}
                                    >
                                        {poLineItem.purchaseOrder.project.name}
                                    </SimpleLink>
                                </Grid>
                            )}
                        </Grid>
                        <Box className={classes.boxContent}>
                            <p className={classes.notes}>
                                {poLineItem &&
                                    poLineItem.purchaseOrder &&
                                    poLineItem.purchaseOrder.project &&
                                    poLineItem.purchaseOrder.project.notes}
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {!_.isEmpty(poLineItem) && !_.isEmpty(poLineItem.additionalFields) && (
                            <Grid container alignItems={"flex-start"}>
                                <Grid item>
                                    <FlagIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                                </Grid>
                                <Grid item style={{ paddingTop: 9 }}>
                                    <PoliAdditionalFields additionalFields={poLineItem.additionalFields} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            {costCode && (
                <Box className={clsx(classes.boxStyles, classes.marginBottom)}>
                    <h3>
                        <FlagIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        {miscFieldLabel ? miscFieldLabel : "Cost Code"}
                    </h3>
                    <Box className={classes.boxContent}>
                        <p>{costCode}</p>
                    </Box>
                </Box>
            )}
            {notes && (
                <Box className={clsx(classes.boxStyles, classes.marginBottom)}>
                    <h3>
                        <FlagIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        Job Notes
                    </h3>
                    <Box className={classes.boxContent}>
                        <p className={classes.notes}>{notes}</p>
                    </Box>
                </Box>
            )}
            {!_.isEmpty(jobOrder[PAVERTRACKERS_NAME]) && (
                <div className={classes.boxStyles}>
                    <Grid container className={clsx(classes.marginBottom)}>
                        <Grid item xs={6}>
                            <h3 className="--uppercase">Pavertrackers</h3>
                        </Grid>
                    </Grid>
                    {jobOrder[PAVERTRACKERS_NAME].map((item) => {
                        return (
                            <Grid container alignItems={"flex-start"} key={item}>
                                <Grid item>
                                    <RouterIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                                </Grid>
                                <Grid item style={{ paddingTop: 9 }}>
                                    <div>{item}</div>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
            )}
            {createdBy && (
                <Box className={clsx(classes.boxStyles, classes.marginBottom)}>
                    <Grid container alignItems={"baseline"}>
                        <Grid item>
                            <FlagIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        </Grid>
                        <Grid item>
                            <p className={classes.notes}>
                                <strong>Created</strong>{" "}
                                {moment(createdDate).tz(account.timezone).format(DEFAULT_DATE_TIME_FORMAT)} by{" "}
                                {createdBy.firstName} {createdBy.lastName} (
                                <SimpleLink otherProps={{ href: `tel:${createdBy.phone}` }}>
                                    {createdBy.phone}
                                </SimpleLink>
                                )
                            </p>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

JobOrderSummary.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    jobOrders: PropTypes.array.isRequired,
    updateJoStatus: PropTypes.func.isRequired,
    updateJoCopySchedule: PropTypes.func.isRequired,
    handleTicketCreate: PropTypes.func,
    setJobOrders: PropTypes.func,
    updateTicketCount: PropTypes.func.isRequired,
    refreshJobs: PropTypes.func.isRequired,
    isRestrictedCustomer: PropTypes.bool.isRequired,
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                jobOrders: state.jobOrders.list,
                isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
            };
        },
        (dispatch) => ({
            setJobOrders: (jobOrders) => {
                dispatch(saveJobOrdersInRedux(jobOrders));
            },
            setDispatchedTruckOrder: (order) => dispatch(updateActiveDispatchedTruckOrder(order)),
            refreshJobs: (action) => dispatch(refreshJobsList(action)),
        }),
    )(JobOrderSummary),
);
