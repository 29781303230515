import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";

import { Box, Grid, Icon, makeStyles, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { selectBundlesFilters, selectRoutesData, selectRoutesMeta } from "../../selectors/smartDispatchSelectors";
import { selectAccountTimezone } from "../../selectors/index";
import { requestRoutes, resetRoutes, unassignTruckFromRoute } from "../../actions/smartDispatch";
import { CARD_STYLES } from "../../styles/reusableStyles";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { UNASSIGN_WARNING } from "./constants/strings";
import { toDate } from "../../helpers/date";
import Loader from "../core/loader";
import ListWaypoint from "../core/listWaypoint";
import { ConfirmationModal, Divider, SimpleLink } from "../core";
import { THEME_ICONS, TYPOGRAPHY_ICONS_SITE } from "../../styles/icons";

const useStyles = makeStyles((theme) => ({
    wrapper: { flex: 1 },
    message: { flex: 1 },
    container: {
        padding: 15,
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .unassign": {
            fontSize: 16,
        },
        "& .startTime": { textAlign: "right" },
        "& .bundle": { marginBottom: 3 },
    },

    truckIcon: {
        ...THEME_ICONS.generalTruck,
        width: 20,
        height: 20,
        marginRight: 5,
    },
    skeleton: {
        padding: 15,
        "& .name, & .truck-icon, & .pickup, & .startTime, & .unassign": {
            backgroundColor: theme.palette.secondary.main,
            color: "transparent",
            fontSize: 0,
        },
        "& .name": {
            width: 150,
            height: 13,
        },
        "& .pickup": {
            width: 150,
            height: 12,
        },
        "& .truck-icon": {
            width: 20,
            height: 20,
        },
        "& .startTime": {
            width: 120,
            height: 10,
        },
        "& .unassign": {
            width: 100,
            height: 16,
            marginBottom: 5,
        },
        "& .truck-container": {
            marginBottom: 5,
        },
    },
}));

export const ViewByTrucks = (props) => {
    const styles = useStyles();
    const cardStyles = CARD_STYLES();
    const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();

    const [unassignData, setUnassignData] = useState(null);

    const routes = useSelector(selectRoutesData);
    const routesMeta = useSelector(selectRoutesMeta);
    const bundlesFilters = useSelector(selectBundlesFilters);
    const timezone = useSelector(selectAccountTimezone);

    const skeletons = new Array(15).fill({ truck: {} });

    const dispatch = useDispatch();

    const getRoutes = (concat) => dispatch(requestRoutes(concat, bundlesFilters));

    const clearRoutes = () => dispatch(resetRoutes());

    const showModal = (e, item) => {
        e && e.stopPropagation();
        setUnassignData(item);
    };

    const closeModal = () => setUnassignData();

    const unassign = () => {
        dispatch(
            unassignTruckFromRoute({
                bundleId: unassignData.BundleId,
                index: unassignData.index,
            }),
        );
        setUnassignData();
    };

    const skeleton = (_, index) => (
        <Grid
            key={index}
            container
            className={clsx(
                routesMeta.loading && LOADER_WHITE_OVERLAY,
                routesMeta.loading && styles.skeleton,
                cardStyles.cardStyles,
                styles.container,
            )}
        >
            {routesMeta.loading && <Loader />}
            <Box display="flex" alignItems="flex-start" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent="start" className="truck-container">
                    <Typography variant="h3" className="name" />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="start" className="truck-container">
                    <Typography variant="h3" className="name" />
                </Box>
                <Box display="flex" alignItems="flex-start" justifyContent="start" className="pickup">
                    <LocationOnIcon className={"pick-up"} />
                    <Typography className="--font-400" />
                </Box>
            </Box>
            <Box display="flex" alignItems="flex-end" flexDirection="column">
                <Box display="flex" justifyContent="flex-end" className="unassign">
                    <SimpleLink>
                        <div />
                    </SimpleLink>
                </Box>
                <h5 className="startTime">
                    <span className="--font-400" />
                </h5>
            </Box>
        </Grid>
    );

    const renderItem = (item, index) => (
        <>
            <ListWaypoint fetchMore={getRoutes} index={index} items={routes || []} itemsCount={routesMeta?.count} />
            <Grid
                container
                className={clsx(cardStyles.cardStyles, styles.container)}
                onClick={() => props.onRouteItemClick(item.BundleId, item.index)}
            >
                <Box display="flex" alignItems="center" justifyContent="start">
                    {item?.truck?.deviceName && <Icon className={clsx(styles.truckIcon, "truck-icon")} />}
                    <Typography variant="h2" className="name truck-name">
                        {item?.truck?.deviceName || "Assigned Truck: Not Assigned"}
                    </Typography>
                </Box>
                <Divider style={{ margin: "6px 0", height: 3 }} />
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="start">
                        <Typography variant="h3" className="name bundle">
                            Bundle #{item.BundleId} Route #{item.index + 1}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" justifyContent="start" className="pickup">
                        <LocationOnIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />
                        <Typography className="--font-400">{item?.firstPickUp?.name}</Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="flex-end" flexDirection="column">
                    <Box display="flex" justifyContent="flex-end" className="unassign">
                        {item?.truck?.canBeUnassigned && (
                            <SimpleLink onClick={(e) => showModal(e, item)}>Unassign</SimpleLink>
                        )}
                    </Box>

                    <h5 className="startTime">
                        Start Time:{" "}
                        <span className="--font-400">
                            {moment(toDate({ timezone, date: item?.firstJobStart })).format("hh:mm A")}
                        </span>
                    </h5>
                </Box>
            </Grid>
        </>
    );

    const renderTrucksList = () => (routes ? routes.map(renderItem) : skeletons.map(skeleton));

    useEffect(() => {
        getRoutes();

        return () => {
            clearRoutes();
        };
    }, []);

    if (!routesMeta.loading && !(routes || []).length) {
        return <h1 className={clsx(styles.message, "--text-center")}>No routes to display</h1>;
    }

    return (
        <>
            <Box className={clsx(styles.wrapper, routesMeta.loading && LOADER_WHITE_OVERLAY)}>
                {routesMeta.loading && <Loader />}
                {renderTrucksList()}
            </Box>
            <ConfirmationModal
                isOpen={!!unassignData}
                question={UNASSIGN_WARNING}
                yesHandler={unassign}
                noHandler={closeModal}
            />
        </>
    );
};

export default ViewByTrucks;
