import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import AddBoxIcon from "@material-ui/icons/AddBox";

import { IS_ADMIN_USER, TRUCKER_TYPE_DISPATCH } from "../../constants/maps";
import {
    getTruckCurrentLocation,
    handleTruckDialogToggle,
    resetTrucks,
    setTruckForEdit,
    setCustomFields,
} from "../../actions/trucks";
import { archiveTruck, linkIotDevice, linkTruck, unlinkIotDevice, unlinkTruck } from "../../dataServers/trucks";
import { ROUTE_DASHBOARD } from "../../routes/globalRoutes";
import GrantAccessDialog from "./grantAccessDialog";
import AssignTruckRegionDialog from "./assignTruckRegionDialog";
import LinkUserDialog from "./linkUserDialog";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import ConfirmationModal from "../core/confirmationModal";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import SetNotAvailableTruckDialog from "./setNotAvailableTruckDialog";
import LinkIotDeviceDialog from "./linkIotDeviceDialog";
import LinkTrailerDialog from "./linkTrailerDialog";
import AppModal from "../core/modal";
import CustomFields from "../customFields/CustomFields";
import ActionMenuItem from "../core/actionMenuItem";
import MoreActionsMenu from "../core/moreActions";
import { getAdditionalFields } from "../../helpers/global";
import { TRUCK_CUSTOM_FIELDS_FORM } from "../../constants/forms";
import TruckCalendar from "./truckCalendar";

const TrucksActionMenu = (props) => {
    const { truckForEdit, loadTrucksData, trucksFilters } = props;
    const { truckName, id: truckId, activeSuspendPeriod, currentDriverId, linkedIotDevice } = truckForEdit || {};
    const account = useSelector((state) => state.account);
    const [accessDialogOpen, setAccessDialogOpen] = useState(false);
    const [notAvailableDialogOpen, setNotAvailableDialogOpen] = useState(false);
    const [regionDialogOpen, setRegionDialogOpen] = useState(false);
    const [iotDialogOpen, setIotDialogOpen] = useState(false);
    const [trailerDialogOpen, setTrailerDialogOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [linkDialogOpen, setLinkDialogOpen] = useState(false);
    const [customFieldsDialogOpen, setCustomFieldsDialogOpen] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const dispatch = useDispatch();

    const toggleDialog = (truckForEdit) => {
        dispatch(handleTruckDialogToggle(true));
        dispatch(setTruckForEdit(truckForEdit));
    };

    const deleteTruck = (truck) => {
        setConfirmationMessage("");

        archiveTruck(truck.id).then(() => {
            setIsHandledSuccessfully(true);
            loadTrucksData();
        });
    };

    const getCurrentLocation = () => {
        dispatch(getTruckCurrentLocation({ keywords: truckName }));
    };

    const handleGrantAccess = () => {
        setAccessDialogOpen(true);
    };

    const handleLinkToggleTruck = () => {
        setLinkDialogOpen(true);
    };

    const handleLinkToggleIotDevice = () => {
        setIotDialogOpen(true);
    };

    const assignRegion = () => setRegionDialogOpen(true);

    const handleNotAvailable = () => setNotAvailableDialogOpen(true);

    const openCustomFields = () => setCustomFieldsDialogOpen(true);

    const handleLinkTruck = (truckId, userId) => {
        const data = {
            truckId,
            userId,
        };

        return linkTruck(data).then(() => {
            loadTrucksData();
        });
    };

    const handleUnlinkTruck = () => {
        const data = {
            truckId,
        };

        unlinkTruck(data)
            .then(() => {
                setLinkDialogOpen(false);
                dispatch(resetTrucks());
                setIsHandledSuccessfully(true);
                loadTrucksData();
            })
            .catch((error) => {
                setErrorMessage([PROCESS_SERVER_ERROR(error)]);
            });
    };

    const handleLinkIotDevice = (truckId, deviceId) => {
        const data = {
            truckId,
            deviceId,
        };

        return linkIotDevice(data).then(() => {
            loadTrucksData();
        });
    };

    const handleUnlinkIotDevice = () => {
        const data = {
            truckId,
        };

        unlinkIotDevice(data)
            .then(() => {
                setIotDialogOpen(false);
                dispatch(resetTrucks());
                setIsHandledSuccessfully(true);
                loadTrucksData();
            })
            .catch((error) => {
                setErrorMessage([PROCESS_SERVER_ERROR(error)]);
            });
    };

    const submitCustomFields = (id, values) => {
        return setCustomFields(id, values, truckForEdit, {
            setError: setErrorMessage,
            onSuccess: () => {
                setIsHandledSuccessfully(true);
                loadTrucksData();
                setCustomFieldsDialogOpen(false);
            },
        });
    };

    const showConfirmation = () => {
        setConfirmationMessage("Are you sure you want to archive truck?");
    };

    const onSubmit = () => {
        dispatch(resetTrucks());
        loadTrucksData();
    };

    const openCalendar = () => setIsOpenCalendar(true);

    const closeCalendar = () => setIsOpenCalendar(false);

    const menuItems = [
        {
            id: "edit",
            title: "Edit",
            renderIcon: () => <AddBoxIcon />,
            handleClick: toggleDialog,
            visible: true,
        },
        {
            id: "archive",
            title: props.isArchived ? "Unarchive" : "Archive",
            renderIcon: () => <AddBoxIcon />,
            handleClick: props.isArchived ? deleteTruck : showConfirmation,
            visible: true,
        },
        {
            id: "region",
            title: "Assign region",
            renderIcon: () => <AddBoxIcon />,
            handleClick: assignRegion,
            visible: true,
        },
        {
            id: "access",
            title: "Grant access",
            renderIcon: () => <AddBoxIcon />,
            handleClick: handleGrantAccess,
            visible: true,
        },
        {
            id: "link",
            title: currentDriverId ? "Unlink" : "Link",
            renderIcon: () => <AddBoxIcon />,
            handleClick: currentDriverId ? handleUnlinkTruck : handleLinkToggleTruck,
            visible: (isAdmin || account.truckerType === TRUCKER_TYPE_DISPATCH) && !activeSuspendPeriod,
        },
        {
            id: "iot",
            title: linkedIotDevice ? "Unlink iot device" : "Link iot device",
            renderIcon: () => <AddBoxIcon />,
            handleClick: linkedIotDevice ? handleUnlinkIotDevice : handleLinkToggleIotDevice,
            visible:
                (isAdmin || account.truckerType === TRUCKER_TYPE_DISPATCH) &&
                !activeSuspendPeriod &&
                account.companyProfile.usesIotDevices,
        },
        {
            id: "location",
            title: "Current location",
            renderIcon: () => <AddBoxIcon />,
            redirect: ROUTE_DASHBOARD,
            redirectParams: { truckId },
            handleClick: getCurrentLocation,
            visible: true,
        },
        {
            id: "available",
            title: activeSuspendPeriod ? "Available" : "Not Available",
            renderIcon: () => <AddBoxIcon />,
            handleClick: handleNotAvailable,
            visible: true,
        },
        {
            id: "viewCalendar",
            title: "View Calendar",
            renderIcon: () => <AddBoxIcon />,
            handleClick: openCalendar,
            visible: true,
        },
        {
            id: "customFields",
            title: "Custom Fields",
            renderIcon: () => <AddBoxIcon />,
            handleClick: openCustomFields,
            visible: true,
        },
    ];

    const handleItemClick = (callback) => {
        truckForEdit ? callback(truckForEdit) : callback();
    };

    return (
        <React.Fragment>
            <MoreActionsMenu>
                {menuItems.map((menuItem) => {
                    const { id, title, renderIcon, redirect, redirectParams, handleClick } = menuItem;

                    if (!menuItem.visible) {
                        return false;
                    }

                    return (
                        <ActionMenuItem
                            key={id}
                            title={title}
                            renderIcon={renderIcon}
                            redirect={redirect}
                            redirectParams={redirectParams}
                            handleClick={() => handleItemClick(handleClick)}
                        />
                    );
                })}
            </MoreActionsMenu>
            <GrantAccessDialog
                truckForEdit={truckForEdit}
                trucksFilters={trucksFilters}
                handleClose={() => setAccessDialogOpen(false)}
                accessDialogOpen={accessDialogOpen}
            />
            <AssignTruckRegionDialog
                truckForEdit={truckForEdit}
                trucksFilters={trucksFilters}
                handleClose={() => setRegionDialogOpen(false)}
                onSubmit={onSubmit}
                regionDialogOpen={regionDialogOpen}
            />
            <SetNotAvailableTruckDialog
                truckForEdit={truckForEdit}
                trucksFilters={trucksFilters}
                isSetAvailable={!!activeSuspendPeriod}
                handleClose={() => setNotAvailableDialogOpen(false)}
                onSubmit={onSubmit}
                dialogOpen={notAvailableDialogOpen}
            />
            <LinkUserDialog
                truckForEdit={truckForEdit}
                handleClose={() => setLinkDialogOpen(false)}
                onSubmit={handleLinkTruck}
                linkDialogOpen={linkDialogOpen}
            />
            <LinkIotDeviceDialog
                truckForEdit={truckForEdit}
                handleClose={() => setIotDialogOpen(false)}
                onSubmit={handleLinkIotDevice}
                iotDialogOpen={iotDialogOpen}
            />
            <LinkTrailerDialog
                truckForEdit={truckForEdit}
                handleClose={() => setTrailerDialogOpen(false)}
                dialogOpen={trailerDialogOpen}
                onSubmitSuccess={loadTrucksData}
            />
            <TruckCalendar
                isOpenCalendar={isOpenCalendar}
                closeCalendar={closeCalendar}
                truckId={truckId}
                truckName={truckName}
            />
            {truckForEdit && (
                <AppModal
                    isOpen={customFieldsDialogOpen}
                    form={TRUCK_CUSTOM_FIELDS_FORM}
                    closeModal={() => setCustomFieldsDialogOpen(false)}
                    modalStyles={{ minWidth: 700 }}
                >
                    <form noValidate={true}>
                        <h2 className="title">CUSTOM FIELDS</h2>
                        <CustomFields
                            form={TRUCK_CUSTOM_FIELDS_FORM}
                            id={truckForEdit.id}
                            initialValues={{
                                additionalFields: getAdditionalFields(truckForEdit),
                            }}
                            submitHandler={submitCustomFields}
                        />
                    </form>
                </AppModal>
            )}
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
            <ConfirmationModal
                isOpen={!!confirmationMessage}
                question={confirmationMessage}
                yesHandler={() => deleteTruck(truckForEdit)}
                noHandler={() => setConfirmationMessage("")}
            />
        </React.Fragment>
    );
};

TrucksActionMenu.propTypes = {
    truckForEdit: PropTypes.object.isRequired,
    isArchived: PropTypes.bool.isRequired,
    trucksFilters: PropTypes.object.isRequired,
    loadTrucksData: PropTypes.func.isRequired,
};

TrucksActionMenu.defaultProps = {
    isArchived: false,
};

export default withRouter(TrucksActionMenu);
