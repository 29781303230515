import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import TextInputComponent from "../core/form/textInputComponent";
import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";

const MiscFieldLabelForm = (props) => {
    const { initLabelValue, onSubmit, isLoading, dispatch, form, miscFieldLabel } = props;

    useEffect(() => {
        if (initLabelValue) {
            dispatch(change(form, "miscFieldLabel", initLabelValue));
        }
    }, []);

    return (
        <form noValidate={true} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <Field
                name="miscFieldLabel"
                type="text"
                label="Misc Field Label"
                component={TextInputComponent}
                validate={[Validation.noSpecialSymbols, Validation.required]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            />
            <br />
            <br />
            <PrimaryButton type="button" onClick={() => onSubmit(miscFieldLabel)}>
                Save
            </PrimaryButton>
        </form>
    );
};

MiscFieldLabelForm.propTypes = {
    initLabelValue: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    miscFieldLabel: PropTypes.string,
    form: PropTypes.string.isRequired,
};

export const MISC_FIELD_LABEL_FORM = "miscFieldValueForm";

export default withRouter(
    compose(
        reduxForm({
            form: MISC_FIELD_LABEL_FORM,
            enableReinitialize: true,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                miscFieldLabel: formSelector(state, "miscFieldLabel"),
            };
        }),
    )(MiscFieldLabelForm),
);
