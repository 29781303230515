import {
    TICKETS_LIST_CHANGE_TICKET_STATUS,
    TICKETS_LIST_LOADED,
    TICKETS_LIST_REMOVE_TICKET,
    TICKETS_LIST_REPLACE_TICKET,
    TICKETS_LIST_UPDATE,
    TICKETS_TIMER_UPDATED,
    TICKETS_SET_LOADING,
} from "../events/tickets";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    tickets: [],
    ticketsCount: 0,
    meta: {
        loading: false,
    },
};

export const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKETS_LIST_LOADED:
            return {
                ...state,
                tickets: action.payload.tickets,
                ticketsCount: action.payload.ticketsCount,
            };
        case TICKETS_LIST_UPDATE:
            return {
                ...state,
                tickets: action.payload,
            };
        case TICKETS_LIST_CHANGE_TICKET_STATUS:
            return {
                ...state,
                tickets: state.tickets.map((ticket) => {
                    if (ticket.id === action.payload.id) {
                        ticket.status = action.payload.status;
                    }

                    return ticket;
                }),
            };
        case TICKETS_LIST_REPLACE_TICKET:
            return {
                ...state,
                tickets: state.tickets.map((ticket) => (ticket.id === action.payload.id ? action.payload : ticket)),
            };
        case TICKETS_LIST_REMOVE_TICKET:
            return {
                ...state,
                tickets: state.tickets.filter((ticket) => ticket.id !== action.payload.id),
                ticketsCount: state.ticketsCount - 1,
            };
        case TICKETS_TIMER_UPDATED:
            return {
                ...state,
                tickets: state.tickets.map((ticket) =>
                    ticket.id === action.payload.id
                        ? {
                              ...ticket,
                              primaryQuantity: action.payload.quantity,
                          }
                        : ticket,
                ),
            };
        case TICKETS_SET_LOADING:
            return {
                ...state,
                meta: {
                    ...state.meta,
                    loading: action.payload,
                },
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
