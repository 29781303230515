import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import moment from "moment";
import { GLOBAL_CONTENT_WIDTH } from "../styles/globalThemeConfig";
import { makeStyles } from "@material-ui/core";
import { ROUTE_MY_COMPANY } from "../routes/globalRoutes";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import { LIST_ITEM_STYLES } from "../styles/reusableStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ProjectCheckIcon from "../components/global/checkIcon";
import EventNoteIcon from "@material-ui/icons/EventNote";
import General from "../components/settings/general";
import JobsSetting from "../components/settings/jobsSetting";
import Notifications from "../components/settings/notifications";
import {
    fetchCompanyNotifications,
    getUserFiltersTruckers,
    getUserNotificationsProfile,
    getUserProfile,
} from "../dataServers/user";
import { convertSecondsToHours, LOADER_WHITE_OVERLAY } from "../constants/global";
import {
    MAPS_FOR_IOS,
    MEASUREMENT_SYSTEM,
    OVERNIGHT_JOB_CLOSING_HOURS,
    REGULAR_JOB_CLOSING_HOURS,
} from "../constants/accounts";
import Loader from "../components/core/loader";
import HaulersSetting from "../components/settings/haulers";
import Payloads from "../components/settings/payloadsSettings";
import TruckTypesSettings from "../components/settings/truckTypesSettings";
import UnitsOfMeasureSettings from "../components/settings/unitsOfMeasureSettings";
import RegionsSettings from "../components/settings/regionsSettings";
import CustomersSettings from "../components/settings/customersSettings";
import { generateUserSelectOptions } from "../constants/users";
import { fetchCompanyAdmins } from "../dataServers/companyAdmins";

export const useStyles = makeStyles((theme) => ({
    settingsPage: {
        width: GLOBAL_CONTENT_WIDTH,
        padding: 0,
        height: "100%",

        "& .navigation": {
            backgroundColor: theme.palette.general.paleOrange,
            borderRight: `1px solid ${theme.palette.border.primary}`,
            display: "flex",
            flexDirection: "column",
            padding: 30,
        },

        "& .settings-content": {
            padding: "30px 0px 150px 30px",
        },
    },
}));

const subPages = [
    {
        title: "General",
        link: ROUTE_MY_COMPANY.SETTINGS,
    },
    {
        title: "Jobs Settings",
        link: ROUTE_MY_COMPANY.SETTINGS_JOBS,
    },
    {
        title: "Notifications",
        link: ROUTE_MY_COMPANY.SETTINGS_NOTIFICATIONS,
    },
    {
        title: "Customers",
        link: ROUTE_MY_COMPANY.SETTINGS_CUSTOMERS,
    },
    {
        title: "Haulers",
        link: ROUTE_MY_COMPANY.SETTINGS_HAULERS,
    },
    {
        title: "Payloads",
        link: ROUTE_MY_COMPANY.SETTINGS_PAYLOADS,
    },
    {
        title: "Units Of Measure",
        link: ROUTE_MY_COMPANY.SETTINGS_UNITS_OF_MEASURE,
    },
    {
        title: "Truck Types",
        link: ROUTE_MY_COMPANY.SETTINGS_TRUCKS,
    },
    {
        title: "Regions",
        link: ROUTE_MY_COMPANY.SETTINGS_REGIONS,
    },
];

function Settings(props) {
    const classes = useStyles();
    const classesListItem = LIST_ITEM_STYLES();
    const [state, setState] = useState({
        isLoading: false,
        generalSettingsData: {},
        jobOrdersData: {},
        notificationsData: {},
        customersData: {},
        payloadsData: {},
        trucksData: {},
        regionsData: {},
    });
    const {
        isLoading,
        generalSettingsData,
        jobOrdersData,
        notificationsData,
        customersData,
        payloadsData,
        trucksData,
        regionsData,
    } = state;

    const [initialAllowReconciliation, setInitialAllowReconciliation] = useState(null);
    const [haulersData, setHaulersData] = useState({});

    const {
        history: {
            location: { pathname },
        },
        account,
    } = props;

    const getUserData = () => {
        Promise.all([getUserProfile(account.company.id), fetchCompanyAdmins(account.company.id), { perPage: 1000 }])
            .then(([{ data: profileData }, { data: companyAdmins }]) => {
                const { timezone, regionRequired, allowReconciliation, state: profileState } = profileData;
                setInitialAllowReconciliation(allowReconciliation);
                setState({
                    ...state,
                    isLoading: false,
                    regionsData: { regionRequired },
                    generalSettingsData: {
                        ...profileData,
                        measurementSystem: profileData.measurementSystem
                            ? {
                                  value: profileData.measurementSystem,
                                  label: MEASUREMENT_SYSTEM[profileData.measurementSystem],
                              }
                            : null,
                        companyName: account.company.name,
                        state: profileState ? { label: profileState } : null,
                        timezone: timezone
                            ? {
                                  label: `${timezone.name} (GMT ${
                                      timezone.offset > 0 ? `+${timezone.offset}` : `${timezone.offset}`
                                  })`,
                                  value: timezone.id,
                              }
                            : null,
                        workingDayDuration: convertSecondsToHours(profileData.workingDayDuration),
                        companyAdmins: companyAdmins.map(generateUserSelectOptions),
                    },
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const getRelevantData = (link) => {
        setState({
            ...state,
            isLoading: true,
            generalSettingsData: {},
            jobOrdersData: {},
            notificationsData: {},
        });

        if (link === ROUTE_MY_COMPANY.SETTINGS || link === ROUTE_MY_COMPANY.SETTINGS_UNITS_OF_MEASURE) {
            getUserData();
        }

        if (link === ROUTE_MY_COMPANY.SETTINGS_JOBS) {
            getUserProfile(account.company.id)
                .then(({ data, meta }) => {
                    const momentTime = moment(account.companyProfile.defaultStartTime, "hh:mm:ss");

                    setState({
                        ...state,
                        isLoading: false,
                        jobOrdersData: {
                            defaultStartTime: momentTime || new Date(),
                            startCopyJobNextDay: account.companyProfile.startCopyJobNextDay,
                            startDateByDefault: account.companyProfile.startDateByDefault,
                            showPricingInApp: account.companyProfile.showPricingInApp,
                            truckShiftAllowed: account.companyProfile.truckShiftAllowed,
                            miscFieldLabel: account.companyProfile.miscFieldLabel,
                            mapsForIos: {
                                value: account.companyProfile.mapsForIos,
                                label: MAPS_FOR_IOS[account.companyProfile.mapsForIos],
                            },
                            jobClosingHour: {
                                value: data.jobClosingHour,
                                label: REGULAR_JOB_CLOSING_HOURS[data.jobClosingHour],
                            },
                            overnightJobClosingHour: {
                                value: data.overnightJobClosingHour,
                                label: OVERNIGHT_JOB_CLOSING_HOURS[data.overnightJobClosingHour],
                            },
                            jobAcceptanceAllowed: account.companyProfile.jobAcceptanceAllowed,
                            usesSplitJob: account.companyProfile.usesSplitJob,
                            jobMode: account.companyProfile.jobMode,
                        },
                    });
                })
                .catch((error) => {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                });
        }

        if (link === ROUTE_MY_COMPANY.SETTINGS_NOTIFICATIONS) {
            Promise.all([fetchCompanyNotifications(account.company.id), getUserNotificationsProfile(account.id)])
                .then(([{ data: companyNotifications }, { data: notificationsData }]) => {
                    const {
                        anomalyAlertNotifications,
                        jobOrderNotifications,
                        ticketNotifications,
                        truckNotifications,
                        messageNotifications,
                        requestsNotifications,
                        dropOffNotifications,
                    } = companyNotifications;

                    const requestRecipientsOptions = requestsNotifications.recipients?.map((recipient) => {
                        return {
                            value: recipient.id,
                            label: `${recipient.firstName} ${recipient.lastName}`,
                        };
                    });

                    const dropOffRecipients = dropOffNotifications.dropOffRecipients?.map((recipient) => {
                        return {
                            value: recipient.id,
                            label: `${recipient.firstName} ${recipient.lastName}`,
                        };
                    });

                    setState({
                        ...state,
                        isLoading: false,
                        notificationsData: {
                            ...anomalyAlertNotifications,
                            ...jobOrderNotifications,
                            ...ticketNotifications,
                            ...truckNotifications,
                            ...messageNotifications,
                            ...notificationsData,
                            ...requestsNotifications,
                            ...dropOffNotifications,
                            recipients: requestRecipientsOptions,
                            dropOffRecipients,
                        },
                    });
                })
                .catch((error) => {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_CUSTOMERS) {
            setState({
                ...state,
                isLoading: false,
            });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_LIMITED_CUSTOMERS) {
            setState({
                ...state,
                isLoading: false,
            });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_HAULERS) {
            getUserFiltersTruckers()
                .then(({ data, meta }) => {
                    setHaulersData({
                        filtersHaulers: data,
                        filtersHaulersCount: meta.count,
                    });
                    setState({
                        ...state,
                        isLoading: false,
                    });
                })
                .catch((error) => {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_PAYLOADS) {
            setState({
                ...state,
                isLoading: false,
            });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_UNITS_OF_MEASURE) {
            setState({
                ...state,
                isLoading: false,
            });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_TRUCKS) {
            setState({
                ...state,
                isLoading: false,
            });
        }
        if (link === ROUTE_MY_COMPANY.SETTINGS_REGIONS || link === ROUTE_MY_COMPANY.SETTINGS_HAULERS) {
            getUserData();
        }
    };

    useEffect(() => {
        getRelevantData(pathname);
    }, []);

    return (
        <div>
            <Container className={classes.settingsPage}>
                <Grid container component="div">
                    <Grid item xs={4} component="div" className="navigation">
                        {subPages.map((item, index) => {
                            const currentPage = pathname === item.link;

                            return (
                                <Link
                                    to={item.link}
                                    key={index}
                                    onClick={() => getRelevantData(item.link)}
                                    className={clsx(classesListItem.listItem, "link", currentPage && "-current")}
                                >
                                    {currentPage && <ProjectCheckIcon />}
                                    <Grid component="div" container alignItems={"center"} wrap={"nowrap"}>
                                        <Grid component="div" item className="icon">
                                            <EventNoteIcon />
                                        </Grid>
                                        <Grid component="div" item>
                                            <h3>{item.title}</h3>
                                        </Grid>
                                    </Grid>
                                </Link>
                            );
                        })}
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        component="div"
                        className={clsx(
                            "settings-content",
                            isLoading && LOADER_WHITE_OVERLAY,
                            (_.isEmpty(generalSettingsData) ||
                                !_.isEmpty(jobOrdersData) ||
                                !_.isEmpty(notificationsData) ||
                                !_.isEmpty(customersData) ||
                                !_.isEmpty(payloadsData) ||
                                !_.isEmpty(trucksData) ||
                                !_.isEmpty(haulersData) ||
                                !_.isEmpty(regionsData)) &&
                                "-min-height",
                        )}
                    >
                        {isLoading && <Loader />}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS && !_.isEmpty(generalSettingsData) && (
                            <General initialValues={generalSettingsData} getUserData={getUserData} />
                        )}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_JOBS && !_.isEmpty(jobOrdersData) && (
                            <JobsSetting initialValues={jobOrdersData} />
                        )}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_NOTIFICATIONS && !_.isEmpty(notificationsData) && (
                            <Notifications initialValues={notificationsData} />
                        )}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_CUSTOMERS && <CustomersSettings />}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_HAULERS && !_.isEmpty(haulersData) && (
                            <HaulersSetting
                                initialValues={{
                                    ...haulersData,
                                    allowReconciliation: initialAllowReconciliation,
                                }}
                                setInitialAllowReconciliation={setInitialAllowReconciliation}
                            />
                        )}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_PAYLOADS && <Payloads />}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_TRUCKS && <TruckTypesSettings />}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_UNITS_OF_MEASURE && !_.isEmpty(generalSettingsData) && (
                            <UnitsOfMeasureSettings
                                initialValues={{
                                    systems:
                                        generalSettingsData?.measurementSystem?.value === 1
                                            ? [1]
                                            : generalSettingsData?.measurementSystem?.value === 2
                                            ? [2]
                                            : generalSettingsData?.measurementSystem?.value === 3
                                            ? [1, 2]
                                            : [],
                                }}
                                getUserData={getUserData}
                            />
                        )}
                        {pathname === ROUTE_MY_COMPANY.SETTINGS_REGIONS && !_.isEmpty(regionsData) && !isLoading && (
                            <RegionsSettings
                                initialValues={regionsData}
                                setRegionRequiredValue={(value) =>
                                    setState({
                                        ...state,
                                        regionsData: {
                                            regionRequired: value,
                                        },
                                    })
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

Settings.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state, props) => {
        return {
            account: state.account,
        };
    })(Settings),
);
