import {
    SETTINGS_TRUCK_TYPES_FAILURE_MESSAGE,
    SETTINGS_TRUCK_TYPES_FILTERS_SET,
    SETTINGS_TRUCK_TYPES_LOADING,
    SETTINGS_TRUCK_TYPES_LOADING_FAILURE,
    SETTINGS_TRUCK_TYPES_LOADING_SUCCESS,
    SETTINGS_TRUCK_TYPES_SUCCESS_MESSAGE,
    SETTINGS_TRUCK_TYPES_UPDATE,
} from "../events/truckTypesSettings";

const initialState = {
    isLoading: false,
    truckTypesList: [],
    truckTypesListCount: 0,
    truckTypesFilters: {},
    success: undefined,
    error: undefined,
};

export const truckTypesSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_TRUCK_TYPES_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SETTINGS_TRUCK_TYPES_LOADING_SUCCESS: {
            const { meta, data } = action.payload;

            return {
                ...state,
                isLoading: false,
                truckTypesList: data,
                truckTypesListCount: meta.count,
            };
        }
        case SETTINGS_TRUCK_TYPES_LOADING_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SETTINGS_TRUCK_TYPES_FILTERS_SET:
            return {
                ...state,
                truckTypesFilters: action.payload,
            };
        case SETTINGS_TRUCK_TYPES_SUCCESS_MESSAGE:
            return {
                ...state,
                success: action.payload,
            };
        case SETTINGS_TRUCK_TYPES_FAILURE_MESSAGE:
            return {
                ...state,
                error: action.payload,
            };
        case SETTINGS_TRUCK_TYPES_UPDATE: {
            const updatedTruckTypesList = state.truckTypesList.map((truckType) => {
                if (truckType.id === action.payload.id) {
                    return action.payload;
                }

                return truckType;
            });

            return {
                ...state,
                truckTypesList: updatedTruckTypesList,
            };
        }
        default:
            return state;
    }
};

export default truckTypesSettingsReducer;
