import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { change, formValueSelector, getFormInitialValues, isDirty } from "redux-form";
import Grid from "@material-ui/core/Grid";
import {
    showJoliTableLoader,
    updateActiveDispatchedTruckOrder,
    updateActiveHaulerOrder,
    updateJobOrder,
    updateMyFleetSelectedTrucks,
    getTrailersFromPreviousJob,
} from "../../actions/jobOrders";
import { PICKUP_DISTANCE, PROCESS_SERVER_ERROR } from "../../constants/global";

import ListSubheader from "@material-ui/core/ListSubheader";

import { IS_ADMIN_USER, IS_CONTRACTOR_USER, IS_HAULER_USER, UNIT_OF_MEASURE_LOAD } from "../../constants/maps";
import { getJobBoardJobOrderJoli, getJobOrderById, partialJobOrderUpdateById } from "../../dataServers/jobOrder";
import { jobOrderHasHaulers, reorganizeJolis } from "../../helpers/jobOrders";
import { useDebounce } from "../../hooks/useDebounce";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { Divider, DividerThin } from "../core/divider";
import ListWaypoint from "../core/listWaypoint";
import ErrorNotification from "../core/notification";
import SortParams from "../core/sortParams";
import SuccessNotification from "../core/successNotification";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { FILTERS_COMPANIES_IDS_ID, FILTERS_REGIONS_ID } from "../global/searchAndFilters";
import { JOLI_FIELD_ID, SPECIFY_TRUCKS_PER_HAULER } from "../jobs/constants";
import { GET_ALL_HAULERS_TRUCKS_COUNT } from "../jobs/jobBoard/joliTable";
import FleetFilters from "./fleetFilters";
import TruckItem from "./truckItem";
import RadiusInputForm, { RADIUS_FIELD_NAME, SET_RADIUS_TO_SEARCH_TRUCKS } from "./radiusInputForm";
import { selectDeployedTrucksData, selectDeployedTrucksMeta } from "../../selectors/smartDispatchSelectors";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";

const useStyles = makeStyles((theme) => ({
    fleetSection: {
        "&.fleet-section": {
            padding: "9px 24px 24px",
            position: "relative",
        },
        "& .fleet-filters": {
            height: 47,
            position: "relative",

            "& .custom-placeholder input:placeholder-shown": {
                fontSize: "min(0.75vw, 16px)",
                height: 18,
            },
        },
    },
    trucksWrap: {
        height: "100vh",
        overflowY: "scroll",
        paddingRight: 12,
    },
    haulerRadiusInput: {
        width: 150,
        [theme.breakpoints.down("1500")]: {
            width: "90%",
        },
    },
}));

export const getSortedByTruckNameItems = (items) => {
    return items.map((joli) => {
        joli.trucks = !_.isEmpty(joli.trucks)
            ? _.sortBy(joli.trucks, (t) => {
                  const name = t.name || t.truckName;

                  return +name ? +name : name.toLowerCase();
              })
            : [];
        joli.haulers = !_.isEmpty(joli.haulers)
            ? joli.haulers.map((h) => {
                  h.trucks = _.sortBy(h.trucks, (t) => {
                      const name = t.name || t.truckName;

                      return +name ? +name : name.toLowerCase();
                  });

                  return h;
              })
            : [];

        return joli;
    });
};

export const mergeItemsTrucks = (formItem, websocketItem) => {
    const formItemTrucksIdList = formItem?.trucks.map((truck) => truck.truckId)?.sort() || [];
    const formItemHaulersIdList = formItem?.haulers.map((hauler) => hauler.id)?.sort() || [];

    const websocketItemTrucksIdList = websocketItem.trucks.map((truck) => truck.truckId).sort();
    const websocketItemHaulersIdList = websocketItem.haulers.map((hauler) => hauler.id).sort();

    if (!_.isEqual(formItemTrucksIdList, websocketItemTrucksIdList)) {
        formItem.trucks.forEach((formTruck) => {
            if (!websocketItemTrucksIdList.includes(formTruck.truckId)) {
                websocketItem.trucks.push(formTruck);
            }
        });
    }

    if (!_.isEqual(formItemHaulersIdList, websocketItemHaulersIdList)) {
        formItem.haulers.forEach((formHauler) => {
            if (!websocketItemTrucksIdList.includes(formHauler.id)) {
                websocketItem.haulers.push(formHauler);
            }
        });
    }

    formItem.merged = true;

    return [formItem, websocketItem];
};

export const mergeJOItems = (websocketItems, formItems, initialFormItems) => {
    let mergedItems = [];
    websocketItems.forEach((websocketItem) => {
        //https://github.com/TruckITllc/truckit-frontend/issues/2314#issuecomment-916094058
        let sameIdItemInFormItems = formItems.find((formItem) => formItem.id === websocketItem.id);
        let sameTimeItemInFormItems = formItems.find((formItem) =>
            moment(formItem.startDate).isSame(moment(websocketItem.startDate)),
        );

        let sameIdItemInInitialItems = initialFormItems.find((initialItem) => initialItem.id === websocketItem.id);

        if (sameIdItemInFormItems) {
            [sameIdItemInFormItems, websocketItem] = mergeItemsTrucks(sameIdItemInFormItems, websocketItem);
        } else if (sameTimeItemInFormItems) {
            [sameTimeItemInFormItems, websocketItem] = mergeItemsTrucks(sameTimeItemInFormItems, websocketItem);
        }

        // https://github.com/TruckITllc/truckit-frontend/issues/2314#issuecomment-916879092
        if (sameIdItemInInitialItems) {
            const initialItemTrucksIdLIst =
                sameIdItemInInitialItems?.trucks.map((truck) => truck.truckId)?.sort() || [];
            const initialItemHaulersIdLIst = sameIdItemInInitialItems?.haulers.map((hauler) => hauler.id)?.sort() || [];

            const formItemTrucksIdLIst = sameIdItemInFormItems?.trucks.map((truck) => truck.truckId)?.sort() || [];
            const formItemHaulersIdLIst = sameTimeItemInFormItems?.haulers.map((hauler) => hauler.id)?.sort() || [];
            websocketItem.trucks = websocketItem.trucks.filter((truck) => {
                return !(
                    initialItemTrucksIdLIst.includes(truck.truckId) && !formItemTrucksIdLIst.includes(truck.truckId)
                );
            });

            websocketItem.haulers = websocketItem.haulers.filter((hauler) => {
                return !(initialItemHaulersIdLIst.includes(hauler.id) && !formItemHaulersIdLIst.includes(hauler.id));
            });
        }

        mergedItems.push(websocketItem);
    });

    formItems.forEach((formItem) => {
        if (!formItem.merged) {
            mergedItems.push(formItem);
        } else {
            delete formItem.merged;
        }
    });

    return mergedItems;
};

const TrucksList = ({
    withDrop,
    isLoading,
    trucks,
    myFleetSelectedTrucks,
    joliItems,
    showMoreTrucks,
    appliedFilters,
    allTrucksCount,
    isHaulers,
    history,
    jobOrderId,
    isDirtyJoOrderForm,
    joOrderFormValues,
    josListFormSelector,
    memoizedHandleDropEnd,
    memoizedHandleTruckSelection,
    pickUpSite,
    activeHaulerParam,
    activeTruckDispatchedParam,
    isTemplateOn,
    poLineItem,
    routeId,
    disabledDrag,
    isCanDrop,
    isDeployedTruck,
    withoutSkeletons,
}) => {
    const skeletonFleetElements = new Array(10).fill(0);

    const getShowDistance = () => {
        if (isDeployedTruck) {
            return false;
        }

        return isHaulers ? activeHaulerParam === PICKUP_DISTANCE : activeTruckDispatchedParam === PICKUP_DISTANCE;
    };
    const showDistance = getShowDistance();

    return (
        (isLoading && (withoutSkeletons ? _.cloneDeep(trucks) || [] : skeletonFleetElements)) ||
        _.cloneDeep(trucks)
    ).map((truck, index) => {
        const isSelected = myFleetSelectedTrucks.find((i) => i.id === truck.id);
        const truckIsDispatched =
            joliItems &&
            joliItems.find((i) => {
                return (
                    i.trucks.find((t) => (t.truckId || t.id) === truck.id) ||
                    i.haulers.find((h) => h.trucks && h.trucks.find((t) => (t.truckId || t.id) === truck.id))
                );
            });
        const truckWasDispatchedBefore =
            joliItems &&
            joliItems.find((i) => {
                return (
                    i.trucks.find((t) => (t.truckId || t.id) === truck.id && !t.isNewAdded) ||
                    i.haulers.find(
                        (h) => h.trucks && h.trucks.find((t) => (t.truckId || t.id) === truck.id && !t.isNewAdded),
                    )
                );
            });
        const hasAnotherJobOnDate =
            truck.jobsNumberOnDate && truckWasDispatchedBefore
                ? +truck.jobsNumberOnDate - 1
                : truck.jobsNumberOnDate >= 1;

        return (
            <Fragment key={truck.id}>
                <ListWaypoint
                    fetchMore={() => showMoreTrucks(true, appliedFilters)}
                    index={index}
                    items={trucks}
                    loadsCount={5}
                    itemsCount={allTrucksCount}
                />
                <TruckItem
                    withDrop={withDrop}
                    disabledDrag={disabledDrag}
                    isCanDrop={isCanDrop}
                    isLoading={isLoading}
                    isFleetList={true}
                    selectedTrucks={myFleetSelectedTrucks}
                    isHaulers={isHaulers}
                    hasAnotherJobOnDate={hasAnotherJobOnDate}
                    truckIsDispatched={!!truckIsDispatched}
                    history={history}
                    isSelected={!!isSelected}
                    currentJobOrder={jobOrderId}
                    isDirtyJoOrderForm={isDirtyJoOrderForm}
                    startDate={
                        (joOrderFormValues && joOrderFormValues.poLineItem && joOrderFormValues[START_DATE_NAME]) ||
                        josListFormSelector[START_DATE_NAME]
                    }
                    handleDropEnd={memoizedHandleDropEnd}
                    handleTruckSelection={memoizedHandleTruckSelection}
                    truck={truck}
                    pickUpSite={pickUpSite}
                    showDistance={showDistance}
                    isTemplateOn={isTemplateOn}
                    allFieldsExist={!_.isEmpty(poLineItem)}
                    isDeployedTruck={isDeployedTruck}
                />
            </Fragment>
        );
    });
};

const FleetList = React.memo(
    (props) => {
        const classes = useStyles();
        const {
            trucks,
            isLoading,
            showMoreTrucks,
            isHaulers,
            allTrucksCount,
            josListFormSelector,
            form,
            history,
            joOrderFormValues,
            match: {
                params: { jobOrderId },
            },
            joOrderFormValues: { iAmOwner, poLineItem, [JOLI_FIELD_ID]: joliItems, pickUpSite, enabled: isTemplateOn },
            initialValues,
            account,
            placeholder,
            isDirtyJoOrderForm,
            appliedFilters,
            updateListByFilters,
            setLoading,
            jobFormId,
            haulersLoaded,
            activeTruckDispatchedParam,
            activeHaulerParam,
            updateActiveDispatchedTruckOrder,
            updateActiveHaulerOrder,
            radiusInputValue,
            deployedTrucks,
            deployedTrucksMeta,
            hiddenFilter,
        } = props;
        const dispatch = useDispatch();
        const myFleetSelectedTrucks = useSelector((state) => state.jobOrders.myFleetSelectedTrucks);
        const [listIsEmpty, setListIsEmpty] = useState();
        const [error, setError] = useState(null);
        const [success, setSuccess] = useState(null);
        const [initialFilters, setInitialFilters] = useState(
            account.region
                ? {
                      [FILTERS_REGIONS_ID]: {
                          [account.region.id]: true,
                      },
                  }
                : {},
        );
        const skeletonFleetElements = new Array(10).fill(0);

        const handleTruckSelection = (truck, multipleItemsSelectionMode) => {
            const trucksCopy = _.cloneDeep(myFleetSelectedTrucks);
            const removeTruck = trucksCopy.find((i) => i.id === truck.id);

            if (multipleItemsSelectionMode) {
                dispatch(
                    updateMyFleetSelectedTrucks(
                        removeTruck ? [...trucksCopy.filter((i) => i.id !== truck.id)] : [...trucksCopy, truck],
                    ),
                );
            }
        };

        const addTrucksToJO = (joData, droppedTrucks, item, dropResult, isDispatch = true) => {
            const { account, trucks } = props;
            const {
                [JOLI_FIELD_ID]: joliItems,
                [START_DATE_NAME]: joStartDate,
                unitOfMeasure,
                id: joOrderId,
                [SPECIFY_TRUCKS_PER_HAULER]: specifyTrucksPerHauler,
            } = joData;
            const isLoadBaseJob = (unitOfMeasure?.id || unitOfMeasure) === UNIT_OF_MEASURE_LOAD;
            const finalJoData = _.cloneDeep(joData);

            let finalJoliItems = [];
            const isJobBoardDispatchItem = history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM);
            const isJobBoardDispatch = history.location.pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
            let newTrucks = (!_.isEmpty(droppedTrucks) ? droppedTrucks : [trucks.find((i) => i.id === item.id)]).map(
                (i) => {
                    const isTruckAssign = i.isHaulerTruck ? true : !isHaulers;

                    const newTruck = {
                        ...i,
                        truckName: isTruckAssign ? i.deviceName : i.name,
                        [isTruckAssign ? "truckId" : "id"]: i.truckId || i.id,
                        isMyFleetTruck: isTruckAssign,
                        needTrucksCount: !isTruckAssign ? i.isHaulers || 1 : 0,
                        isNewAdded: true,
                    };
                    if (!isTruckAssign) {
                        newTruck.allowSubhaulers = false;
                    }

                    return newTruck;
                },
            );

            setLoading(true);
            // handle trucks add on JO creation
            if (
                IS_CONTRACTOR_USER(account.role) ||
                IS_ADMIN_USER(account.company.id) ||
                iAmOwner ||
                (IS_HAULER_USER(account.role) && iAmOwner) ||
                !joOrderId ||
                isJobBoardDispatch
            ) {
                newTrucks = newTrucks.map((i) => {
                    const isHaulerAssign = i.isHaulerTruck ? false : !isHaulers;
                    const sameTrucks = (_.cloneDeep(joliItems) || []).filter((joli) => {
                        if (!isHaulerAssign) {
                            if (i.truckShiftAllowed) {
                                return joli.haulers.find((truck) => {
                                    return truck.id === i.company.id;
                                });
                            } else {
                                return joli.trucks.find((truck) => {
                                    return truck.truckId === i.id || truck.truckId === i.truckId;
                                });
                            }
                        } else {
                            return joli.haulers.find((truck) => {
                                return truck.id === i.truckId || truck.id === i.id;
                            });
                        }
                    });
                    const joliWithHaulers = joliItems && joliItems.filter((i) => !_.isEmpty(i.haulers));
                    // https://github.com/TruckITllc/truckit-frontend/issues/464#issuecomment-614480102
                    const anotherHaulerTime =
                        joliWithHaulers && joliWithHaulers.length === 1 ? joliWithHaulers[0].startDate : joStartDate;
                    // take the last as jolis are sorted by time
                    const lastestTimeTruck =
                        sameTrucks[sameTrucks.length - 1] ||
                        (joliItems &&
                            joliItems.find((joli) =>
                                joli.haulers.find(
                                    (h) =>
                                        h.trucks && h.trucks.find((t) => (t.truckId || t.id) === (i.id || i.truckId)),
                                ),
                            ));
                    const startDate = lastestTimeTruck
                        ? moment(lastestTimeTruck.startDate).add(15, "minutes")
                        : i.isMyFleetTruck
                        ? joStartDate
                        : anotherHaulerTime;

                    const truck = {
                        ...i,
                        startDate,
                        specifyTrucksPerHauler,
                    };
                    if (!i.isMyFleetTruck) {
                        truck.trucks = [];
                    }

                    return truck;
                });

                let adminCompanyTrucks;
                let haulersCompanyTrucks;

                if (!_.isEmpty(newTrucks) && newTrucks[0].isMyFleetTruck && IS_ADMIN_USER(account.company.id)) {
                    adminCompanyTrucks = newTrucks.filter((i) => i.company.id === account.company.id);
                    haulersCompanyTrucks = newTrucks.filter((i) => i.company.id !== account.company.id);
                }

                // https://github.com/TruckITllc/truckit-frontend/issues/273#issuecomment-606177427
                if (isLoadBaseJob) {
                    finalJoliItems = _.cloneDeep(joliItems) || [];

                    newTrucks =
                        newTrucks[0].isMyFleetTruck &&
                        !_.isEmpty(adminCompanyTrucks) &&
                        IS_ADMIN_USER(account.company.id)
                            ? adminCompanyTrucks
                            : newTrucks;

                    newTrucks.forEach((truck) => {
                        const haulers = [];
                        const trucks = [];
                        const newJoli = {
                            quantity: 1,
                            startDate: truck.startDate,
                            trucks,
                        };

                        if (truck.truckShiftAllowed && truck.isMyFleetTruck) {
                            const newHauler = {
                                startDate: truck.startDate,
                                quantity: truck.quantity,
                                name: truck.isMyFleetTruck ? truck.company.name : truck.name,
                                id: truck.isMyFleetTruck ? truck.company.id : truck.id,
                                needTrucksCount: 1,
                                haulerRate: truck.defaultRate,
                                trucks: [
                                    {
                                        hasTickets: false,
                                        isPending: false,
                                        truckId: truck.id || truck.truckId,
                                        truckName: truck.truckName || truck.name,
                                        truckType: truck.truckType,
                                    },
                                ],
                            };
                            const sameHauler = finalJoliItems.find((i) => {
                                return i.haulers.find((hauler) => {
                                    return (
                                        hauler.id === truck.company.id &&
                                        (truck.isHaulerTruck || hauler.trucks.length < hauler.needTrucksCount) &&
                                        !hauler.trucks.find((t) => (t.truckId || t.id) === (truck.id || truck.truckId))
                                    );
                                });
                            });

                            if (sameHauler) {
                                finalJoliItems = finalJoliItems.map((item) => {
                                    item.haulers = item.haulers.map((hauler) => {
                                        if (
                                            hauler.id === truck.company.id &&
                                            (truck.isHaulerTruck || hauler.trucks.length < hauler.needTrucksCount) &&
                                            !hauler.trucks.find(
                                                (t) => (t.truckId || t.id) === (truck.id || truck.truckId),
                                            )
                                        ) {
                                            hauler.trucks.push({
                                                hasTickets: false,
                                                isPending: false,
                                                truckId: truck.id || truck.truckId,
                                                truckName: truck.truckName || truck.name,
                                                truckType: truck.truckType,
                                            });
                                        }
                                        if (hauler.needTrucksCount < hauler.trucks.length) {
                                            hauler.needTrucksCount = hauler.needTrucksCount + 1;
                                        }

                                        return hauler;
                                    });

                                    return item;
                                });
                            } else {
                                haulers.push(newHauler);

                                finalJoliItems.push({
                                    haulers,
                                    ...newJoli,
                                });
                            }
                        } else {
                            truck.isMyFleetTruck ? trucks.push(truck) : haulers.push(truck);

                            finalJoliItems.push({
                                haulers,
                                ...newJoli,
                            });
                        }
                    });

                    if (isDispatch) {
                        dispatch(change(jobFormId, JOLI_FIELD_ID, getSortedByTruckNameItems(finalJoliItems)));

                        setLoading(false);
                    } else {
                        finalJoData[JOLI_FIELD_ID] = getSortedByTruckNameItems(finalJoliItems);
                    }
                } else if (joliItems) {
                    finalJoliItems = _.cloneDeep(joliItems);
                    newTrucks.forEach((truck) => {
                        finalJoliItems = reorganizeJolis(finalJoliItems, truck);
                    });
                    if (isDispatch) {
                        dispatch(change(jobFormId, JOLI_FIELD_ID, getSortedByTruckNameItems(finalJoliItems)));
                        setLoading(false);
                    } else {
                        finalJoData[JOLI_FIELD_ID] = getSortedByTruckNameItems(finalJoliItems);
                    }
                }

                if (
                    IS_ADMIN_USER(account.company.id) &&
                    isLoadBaseJob &&
                    !_.isEmpty(haulersCompanyTrucks) &&
                    joliItems &&
                    joliItems.find((i) => !_.isEmpty(i.haulers))
                ) {
                    finalJoliItems = _.cloneDeep(joliItems);

                    haulersCompanyTrucks.forEach((truck) => {
                        finalJoliItems = reorganizeJolis(finalJoliItems, truck);
                    });

                    if (isDispatch) {
                        dispatch(change(jobFormId, JOLI_FIELD_ID, getSortedByTruckNameItems(finalJoliItems)));
                        setLoading(false);
                    } else {
                        finalJoData[JOLI_FIELD_ID] = getSortedByTruckNameItems(finalJoliItems);
                    }
                }

                if (jobOrderHasHaulers(finalJoliItems)) {
                    const hasHasHaulers = joliItems && joliItems.find((i) => !_.isEmpty(i.haulers));

                    if (isDispatch) {
                        dispatch(
                            change(jobFormId, SPECIFY_TRUCKS_PER_HAULER, hasHasHaulers ? specifyTrucksPerHauler : true),
                        );
                        setLoading(false);
                    } else {
                        finalJoData[SPECIFY_TRUCKS_PER_HAULER] = hasHasHaulers ? specifyTrucksPerHauler : true;
                    }
                }
            }

            if (isJobBoardDispatch || isJobBoardDispatchItem) {
                // action of job board item fill by hauler
                if (IS_HAULER_USER(account.role)) {
                    const needUpdateJoliIndex = joliItems && joliItems.findIndex((i) => i.id === dropResult.name);

                    if (needUpdateJoliIndex !== -1) {
                        const foundJoli = joliItems && joliItems[needUpdateJoliIndex];

                        // can't add one truck as a hauler to same joli
                        newTrucks = newTrucks.filter(
                            (i) =>
                                foundJoli &&
                                !foundJoli.trucks.find((truck) => (truck.id || truck.truckId) === (i.truckId || i.id)),
                        );

                        const haulersRequiredTrucksCount = GET_ALL_HAULERS_TRUCKS_COUNT(foundJoli.haulers);
                        const addedTrucks = foundJoli.trucks.length + haulersRequiredTrucksCount;

                        if (foundJoli && addedTrucks >= foundJoli.needTrucksCount) {
                            return finalJoData;
                        }

                        const updatedTrucks = [
                            ...(foundJoli ? foundJoli.trucks : []),
                            ...newTrucks.map((i) => ({
                                ...i,
                                startDate: foundJoli.startDate,
                            })),
                        ];
                        const joliData = {
                            ...(foundJoli || {}),
                            trucks: _.sortBy(
                                updatedTrucks.filter((i) => i.isMyFleetTruck),
                                (i) => {
                                    const name = i.name || i.truckName;

                                    return +name ? +name : name.toLowerCase();
                                },
                            ),
                        };

                        if (foundJoli.allowSubhaulers) {
                            joliData.haulers = [
                                ...foundJoli.haulers,
                                ...newTrucks
                                    .filter((i) => !i.isMyFleetTruck)
                                    .map((i) => ({
                                        ...i,
                                        startDate: foundJoli.startDate,
                                        needTrucksCount: 1,
                                    })),
                            ];
                        }
                        if (isDispatch) {
                            dispatch(change(jobFormId, `${JOLI_FIELD_ID}[${needUpdateJoliIndex}]`, joliData));
                            setLoading(false);
                        } else {
                            finalJoData[JOLI_FIELD_ID][needUpdateJoliIndex] = joliData;
                        }
                    }
                }
            }

            return finalJoData;
        };

        const prepareBulkDispatchItem = (joliData, jobOrderData, itemToAdd) => {
            const isLoadBaseJob = jobOrderData.unitOfMeasure === UNIT_OF_MEASURE_LOAD;

            const itemWithSameStartDate = joliData.find((item) => {
                return item.startDate === itemToAdd.startDate;
            });
            if (itemWithSameStartDate && !isLoadBaseJob) {
                itemToAdd.id = itemWithSameStartDate.id;
                delete itemToAdd.startDate;
            }
            if (isLoadBaseJob && !jobOrderData.unlimited) {
                itemToAdd.quantity = 1;
            }

            return itemToAdd;
        };

        const handleDropToJOEnd = (droppedTrucks, item, dropResult) => {
            const { jobOrder, stopLoading } = dropResult;

            setLoading(true);

            Promise.all([getJobOrderById(jobOrder.id), getJobBoardJobOrderJoli(jobOrder.id)])
                .then(([{ data: jobOrderData }, { data: joliData }]) => {
                    const processedJoliData = addTrucksToJO(
                        {
                            ...jobOrderData,
                            [JOLI_FIELD_ID]: joliData,
                        },
                        droppedTrucks,
                        item,
                        dropResult,
                        false,
                    );
                    const partialUpdateData = { items: [] };

                    const newElements = droppedTrucks.length > 0 ? droppedTrucks : [item];
                    const arrayKey = isHaulers ? "haulers" : "trucks";
                    const addKey = isHaulers ? "haulersToAdd" : "trucksToAdd";
                    const elementIdKey = isHaulers ? "id" : "truckId";
                    const defaultHaulerData = {
                        companyId: item.id,
                        needTrucksCount: 1,
                        allowSubhaulers: false,
                    };
                    const assignedElementsIdList = isHaulers
                        ? jobOrderData.haulers.map((hauler) => hauler.id)
                        : jobOrderData.assignedTrucks.map((truck) => truck.id);
                    newElements.forEach((element) => {
                        if (assignedElementsIdList.includes(element.id)) {
                            //such element already is in some item
                            const itemWithSuchInstance = joliData.reverse().find((item) => {
                                let instanceIsInItem = false;
                                item[arrayKey].forEach((instance) => {
                                    if (instance[elementIdKey] === element.id) {
                                        instanceIsInItem = true;
                                    }
                                });

                                return instanceIsInItem;
                            });

                            if (itemWithSuchInstance) {
                                const itemToAdd = {
                                    [addKey]: isHaulers ? [defaultHaulerData] : [element.id],
                                    startDate: moment(itemWithSuchInstance.startDate)
                                        .tz(account.timezone)
                                        .add(15, "minutes")
                                        .format(),
                                };
                                partialUpdateData.items.push(
                                    prepareBulkDispatchItem(joliData, jobOrderData, itemToAdd),
                                );
                            }
                        } else {
                            //New element for JO
                            const itemToAdd = {
                                [addKey]: isHaulers ? [defaultHaulerData] : [element.id],
                                startDate: jobOrderData.startDate,
                            };
                            partialUpdateData.items.push(prepareBulkDispatchItem(joliData, jobOrderData, itemToAdd));
                        }
                    });

                    return partialJobOrderUpdateById(processedJoliData.id, partialUpdateData);
                })
                .then(() => {
                    dispatch(updateJobOrder());
                    setSuccess(true);
                    dispatch(updateMyFleetSelectedTrucks([]));
                    stopLoading();
                })
                .catch((error) => {
                    setError(PROCESS_SERVER_ERROR(error));
                })
                .finally(() => {
                    showMoreTrucks(false, appliedFilters);
                    stopLoading();
                    setLoading(false);
                });
        };

        const handleDropEnd = (droppedTrucks, item, dropResult) => {
            addTrucksToJO({ ...joOrderFormValues, initialValues }, droppedTrucks, item, dropResult, true);
            if (joOrderFormValues.previousJobOrderId) {
                dispatch(getTrailersFromPreviousJob(props.jobFormId, joOrderFormValues.previousJobOrderId, item.id));
            }
            dispatch(updateMyFleetSelectedTrucks([]));
        };

        const addAllHaulersButton =
            isHaulers &&
            appliedFilters &&
            appliedFilters[FILTERS_COMPANIES_IDS_ID] &&
            !_.isEmpty(joOrderFormValues) &&
            poLineItem &&
            (IS_HAULER_USER(account.role) && jobOrderId ? iAmOwner : true);

        useEffect(() => {
            dispatch(updateMyFleetSelectedTrucks([]));
        }, [history.location.pathname]);

        useEffect(() => {
            appliedFilters && showMoreTrucks(false, appliedFilters);
        }, [appliedFilters]);

        const memoizedHandleDropEnd = useCallback(
            (droppedTrucks, item, dropResult) => {
                if (dropResult && !_.isEmpty(dropResult.jobOrder)) {
                    handleDropToJOEnd(droppedTrucks, item, dropResult);
                } else if (dropResult && _.isEmpty(dropResult.jobOrder)) {
                    handleDropEnd(droppedTrucks, item, dropResult);
                }
            },
            [trucks, joOrderFormValues, jobFormId],
        );
        const memoizedHandleTruckSelection = useCallback(
            (truck, multipleItemsSelectionMode) => {
                handleTruckSelection(truck, multipleItemsSelectionMode);
            },
            [myFleetSelectedTrucks],
        );

        const onNotificationClose = () => {
            setError([]);
            setSuccess(null);
        };

        useEffect(() => {
            haulersLoaded && setListIsEmpty(_.isEmpty(trucks));
        }, [haulersLoaded]);

        let columns = [
            {
                id: "name",
                sortParam: "name",
                label: "Name",
            },
            !isTemplateOn && {
                id: "dispatchedRatio",
                sortParam: "dispatchedRatio",
                label: "Dispatched",
            },
        ];

        // show or hide this sort option
        if (pickUpSite && columns.length === 2 && !isTemplateOn) {
            columns.push({
                id: "pickUpDistance",
                sortParam: PICKUP_DISTANCE,
                label: "Pickup Distance",
                direction: "asc",
            });
        }
        if (!pickUpSite && columns.length === 3) {
            columns.pop();
        }

        const haulerColumns = [
            {
                id: "name",
                sortParam: "name",
                label: "Name",
            },
            {
                id: "pickUpDistance",
                sortParam: PICKUP_DISTANCE,
                label: "Pickup Distance",
                direction: "asc",
            },
        ];

        if (pickUpSite && columns.length === 1 && !isTemplateOn) {
            haulerColumns.push({
                id: "pickUpDistance",
                sortParam: PICKUP_DISTANCE,
                label: "Pickup Distance",
                direction: "asc",
            });
        }

        if (!pickUpSite && columns.length === 2) {
            haulerColumns.pop();
        }

        const handleSort = (value) => {
            if (isHaulers) {
                updateActiveHaulerOrder(value);
            } else {
                updateActiveDispatchedTruckOrder(value);
            }
        };

        const [value, onChangeRadius] = useDebounce(() => showMoreTrucks(false, appliedFilters), 200);

        return (
            <div className={clsx(classes.fleetSection, "fleet-section")}>
                <div className="fixed-wrapper">
                    <FleetFilters
                        hiddenFilter={hiddenFilter}
                        updateListByFilters={updateListByFilters}
                        initialFilters={initialFilters}
                        placeholder={placeholder}
                        isHaulersFilters={isHaulers}
                        isHaulers={isHaulers}
                        listIsEmpty={listIsEmpty}
                        initialValues={{ search: null }}
                        form={form}
                    />
                    <DividerThin marginTop={0} />

                    <SortParams
                        isLoading={isLoading}
                        activeOrder={isHaulers ? activeHaulerParam : activeTruckDispatchedParam}
                        handleSort={handleSort}
                        columns={isHaulers ? haulerColumns : columns}
                    />

                    {(isHaulers ? activeHaulerParam : activeTruckDispatchedParam) === PICKUP_DISTANCE && pickUpSite && (
                        <Grid container justify={"flex-end"}>
                            <RadiusInputForm
                                onChange={onChangeRadius}
                                xs={isHaulers ? 7 : 5}
                                className={isHaulers && classes.haulerRadiusInput}
                            />
                            <DividerThin marginTop={0} />
                        </Grid>
                    )}
                    {
                        <div style={props.trucksWrapClasses}>
                            {props.additionalTrucks &&
                                props.additionalTrucks.map(({ trucks, listSubheader, disabledDrag, isCanDrop }) => {
                                    return (
                                        <>
                                            <ListSubheader
                                                style={{
                                                    fontSize: 18,
                                                    padding: 0,
                                                    backgroundColor: " rgb(246 246 246)",
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <LocalShippingIcon style={{ marginRight: 5 }} /> {listSubheader}
                                                </div>
                                                <Divider marginTop={0} />
                                            </ListSubheader>
                                            <TrucksList
                                                withDrop
                                                withoutSkeletons
                                                isDeployedTruck
                                                isLoading={isLoading}
                                                trucks={trucks || []}
                                                myFleetSelectedTrucks={myFleetSelectedTrucks}
                                                joliItems={joliItems}
                                                showMoreTrucks={showMoreTrucks}
                                                appliedFilters={appliedFilters}
                                                allTrucksCount={allTrucksCount}
                                                isHaulers={isHaulers}
                                                history={history}
                                                jobOrderId={jobOrderId}
                                                isDirtyJoOrderForm={isDirtyJoOrderForm}
                                                joOrderFormValues={joOrderFormValues}
                                                josListFormSelector={josListFormSelector}
                                                memoizedHandleDropEnd={memoizedHandleDropEnd}
                                                memoizedHandleTruckSelection={memoizedHandleTruckSelection}
                                                pickUpSite={pickUpSite}
                                                activeHaulerParam={activeHaulerParam}
                                                activeTruckDispatchedParam={activeTruckDispatchedParam}
                                                isTemplateOn={isTemplateOn}
                                                poLineItem={poLineItem}
                                                disabledDrag={disabledDrag}
                                                isCanDrop={isCanDrop}
                                            />
                                        </>
                                    );
                                })}
                        </div>
                    }
                    <div className={clsx(classes.trucksWrap)} style={props.trucksWrapClasses}>
                        {props.mainListSubheader && (
                            <ListSubheader
                                style={{
                                    fontSize: 18,
                                    padding: 0,
                                    backgroundColor: " rgb(246 246 246)",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <LocalShippingOutlinedIcon style={{ marginRight: 5 }} /> {props.mainListSubheader}
                                </div>
                                <Divider marginTop={0} />
                            </ListSubheader>
                        )}

                        <TrucksList
                            isLoading={isLoading}
                            trucks={trucks}
                            myFleetSelectedTrucks={myFleetSelectedTrucks}
                            joliItems={joliItems}
                            showMoreTrucks={showMoreTrucks}
                            appliedFilters={appliedFilters}
                            allTrucksCount={allTrucksCount}
                            isHaulers={isHaulers}
                            history={history}
                            jobOrderId={jobOrderId}
                            isDirtyJoOrderForm={isDirtyJoOrderForm}
                            joOrderFormValues={joOrderFormValues}
                            josListFormSelector={josListFormSelector}
                            memoizedHandleDropEnd={memoizedHandleDropEnd}
                            memoizedHandleTruckSelection={memoizedHandleTruckSelection}
                            pickUpSite={pickUpSite}
                            activeHaulerParam={activeHaulerParam}
                            activeTruckDispatchedParam={activeTruckDispatchedParam}
                            isTemplateOn={isTemplateOn}
                            poLineItem={poLineItem}
                        />
                        {_.isEmpty(trucks) && (
                            <h1 className="--text-center">{isHaulers ? "No haulers to show" : "No trucks to show."}</h1>
                        )}
                    </div>
                    {addAllHaulersButton && (
                        <ButtonsGroup>
                            <PrimaryButton
                                size={"large"}
                                onClick={() =>
                                    handleDropEnd(trucks, null, {
                                        dropEffect: "move",
                                        name: "Job Trucks",
                                    })
                                }
                            >
                                Add All Haulers
                            </PrimaryButton>
                        </ButtonsGroup>
                    )}
                </div>
                {success && <SuccessNotification message="Success" config={{ onClose: onNotificationClose }} />}
                {!_.isEmpty(error) && <ErrorNotification message={error} config={{ onClose: onNotificationClose }} />}
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

FleetList.propTypes = {
    trucks: PropTypes.array.isRequired,
    allTrucksCount: PropTypes.number.isRequired,
    isHaulers: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    haulersLoaded: PropTypes.bool.isRequired,
    isDirtyJoOrderForm: PropTypes.bool,
    form: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    showMoreTrucks: PropTypes.func.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    joOrderFormValues: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    jobFormId: PropTypes.string.isRequired,
    jobsListFormId: PropTypes.string.isRequired,
    josListFormSelector: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    match: PropTypes.object,
    activeTruckDispatchedParam: PropTypes.string,
    updateActiveDispatchedTruckOrder: PropTypes.func.isRequired,
    radiusInputValue: PropTypes.string,
    pickUpSite: PropTypes.object,
};

FleetList.defaultProps = {
    isHaulers: false,
    haulersLoaded: false,
    joOrderFormValues: {},
};

export default withRouter(
    connect(
        (state, props) => {
            const joFormSelector = formValueSelector(props.jobFormId);
            const josListFormSelector = formValueSelector(props.jobsListFormId);
            const joInitialValuesSelector = getFormInitialValues(props.jobFormId);
            const radiusInputSelector = formValueSelector(SET_RADIUS_TO_SEARCH_TRUCKS);

            return {
                joOrderFormValues: joFormSelector(
                    state,
                    JOLI_FIELD_ID,
                    START_DATE_NAME,
                    "totalQuantity",
                    "iAmOwner",
                    "unitOfMeasure",
                    "id",
                    SPECIFY_TRUCKS_PER_HAULER,
                    "poLineItem",
                    "partialUpdateItems",
                    "pickUpSite",
                    "enabled",
                    "previousJobOrderId",
                ),
                initialValues: joInitialValuesSelector(state),
                josListFormSelector: josListFormSelector(state, START_DATE_NAME, END_DATE_NAME),
                account: state.account,
                isDirtyJoOrderForm: props.jobFormId ? isDirty(props.jobFormId)(state) : false,
                activeTruckDispatchedParam: state.jobOrders.activeTruckDispatchedParam,
                activeHaulerParam: state.jobOrders.activeHaulerParam,
                radiusInputValue: radiusInputSelector(state, RADIUS_FIELD_NAME),
                deployedTrucks: selectDeployedTrucksData(state),
                deployedTrucksMeta: selectDeployedTrucksMeta(state),
            };
        },
        (dispatch) => ({
            setLoading: (isLoading) => {
                dispatch(showJoliTableLoader(isLoading));
            },
            updateActiveDispatchedTruckOrder: (payload) => dispatch(updateActiveDispatchedTruckOrder(payload)),
            updateActiveHaulerOrder: (payload) => dispatch(updateActiveHaulerOrder(payload)),
        }),
    )(FleetList),
);
