import React from "react";
import _ from "lodash";

import ListWaypoint from "../core/listWaypoint";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/global";
import { toDate } from "../../helpers/date";
import { BundleItem } from "./";

export default function BundleList({
    bundlesList,
    account,
    cardStyles,
    getMoreBundles,
    bundlesListCount,
    onBundleItemClick,
}) {
    if (_.isEmpty(bundlesList)) {
        return (
            <div className="bundle-wrapper-message">
                <h1 className="--text-center">No bundles to display</h1>
            </div>
        );
    }

    return (
        bundlesList &&
        bundlesList.map((bundle, index) => {
            const {
                problemId,
                status: bundleStatus,
                jobOrders: bundleJobOrders,
                createdDate,
                createdBy,
                trucksCount,
                isDraft,
                message,
            } = bundle;
            const regionName = bundle.region?.name;

            const bundleCreatedDate = createdDate
                ? toDate({
                      date: createdDate,
                      timezone: account.timezone,
                  }).format(DEFAULT_DATE_TIME_FORMAT)
                : "N/A";
            const bundleCreator = createdBy ? `${createdBy.lastName} ${createdBy.firstName}` : "N/A";
            const bundleTrucksCount = trucksCount || "N/A";
            const bundleRegion = regionName || "N/A";

            return (
                <React.Fragment key={problemId}>
                    <ListWaypoint
                        fetchMore={getMoreBundles}
                        index={index}
                        items={bundlesList}
                        itemsCount={bundlesListCount}
                    />
                    <BundleItem
                        handleBundleItemClick={onBundleItemClick}
                        problemId={problemId}
                        bundleStatus={bundleStatus}
                        bundleJobOrders={bundleJobOrders}
                        bundleCreatedDate={bundleCreatedDate}
                        bundleCreator={bundleCreator}
                        bundleTrucksCount={bundleTrucksCount}
                        bundleRegion={bundleRegion}
                        cardStyles={cardStyles}
                        isDraft={isDraft}
                        message={message}
                    />
                </React.Fragment>
            );
        })
    );
}
