import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { destroy } from "redux-form";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";

import { GLOBAL_CONTENT_WIDTH } from "../styles/globalThemeConfig";
import ProjectsList from "../components/projects/projectsList";
import ProjectDetailsForm from "../components/projects/projectDetailsForm";
import { ErrorBoundary } from "../components/core/errorBoudaries";
import * as projectsActions from "../actions/projects";
import * as poLineItemsActions from "../actions/poLineItems";
import { updateForcedSelectedPoLineItem } from "../actions/poLineItems";
import { getProjectById } from "../dataServers/projects";
import { LOADER_WHITE_OVERLAY } from "../constants/global";
import Loader from "../components/core/loader";
import { INITIAL_TERMS_VALUE } from "../components/global/termsSelector";
import { pavertrackerInitialValues, PAVERTRACKERS_NAME } from "../components/global/pavertrackersSelector";
import { selectRestrictedCustomersList } from "../selectors/projectsSelectors";
import { CUSTOMER_VALUE, RESTRICTED_CUSTOMER_VALUE } from "../components/projects/constants";
import { generateUserSelectOptions } from "../constants/users";

const useStyles = makeStyles((theme) => ({
    myProjectsPage: {
        width: GLOBAL_CONTENT_WIDTH,
        padding: 0,
        position: "relative",

        [theme.breakpoints.down(1550)]: {
            width: "100%",
        },

        "& .job-board-page": {
            backgroundColor: theme.palette.general.paleOrange,
        },
    },
    mainContent: {
        "& .scroll-wrap": {
            height: 647,
        },
        "& .sites-and-projects-wrapper": {
            height: "inherit",
            paddingBottom: 8,
        },
    },
    verticalDivider: {
        "&::before": {
            boxShadow: "0 2px 4px 0 rgba(83, 83, 83, 0.5)",
        },
    },
}));

function Projects(props) {
    const {
        currentProject,
        getPOLineItems,
        projects,
        projectsLoading,
        resetPOLineItems,
        setCurrentProject,
        resetCustomFieldsForm,
        restrictedCustomersList,
        getRestrictedCustomersList,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState({ isLoading: false });
    const { isLoading } = state;
    const restrictedCustomerId = currentProject?.restrictedCustomerId;
    const restrictedCustomer = restrictedCustomersList.find((customer) => customer.id === restrictedCustomerId);

    const onProjectSelection = (project) => {
        if ((currentProject && currentProject.id !== project.id) || !currentProject) {
            setState({ ...state, isLoading: true });
            dispatch(updateForcedSelectedPoLineItem(null));
            getProjectById(project.id)
                .then(({ data }) => {
                    resetCustomFieldsForm();
                    setCurrentProject({ ...project, ...data });
                    getPOLineItems(project.id);
                    setState({ ...state, isLoading: false });
                })
                .catch(() => {
                    setState({ ...state, isLoading: false });
                });
        } else {
            setCurrentProject(null);
            resetPOLineItems();
        }
    };

    const projectsLoadedHandler = (loadedProjects, newProject) => {
        if (newProject && loadedProjects && loadedProjects[0]) {
            setState({ ...state, isLoading: true });
            setCurrentProject(newProject);
            getPOLineItems(newProject.id);
            setState({ ...state, isLoading: false });
        }
    };

    useEffect(() => {
        getRestrictedCustomersList();
    }, []);

    return (
        <Container className={clsx(classes.myProjectsPage, "--full-height")}>
            <div className={clsx(classes.mainContent)}>
                <Grid component="div" container className="">
                    <ProjectsList
                        onProjectSelection={onProjectSelection}
                        projectsLoadedHandler={projectsLoadedHandler}
                        setParrentLoader={(show) => setState({ ...state, isLoading: show })}
                        canCreateProject={true}
                        isProjectPage={true}
                        initialValues={{ search: null }}
                    />
                    <Grid item xs={8} component="div" className={clsx("verticalDivider", classes.verticalDivider)}>
                        <div className={clsx(isLoading && `${LOADER_WHITE_OVERLAY}`, !currentProject && "-min-height")}>
                            {isLoading && <Loader />}
                            {!_.isEmpty(projects) && !!currentProject && (
                                <ErrorBoundary>
                                    <ProjectDetailsForm
                                        initialValues={{
                                            photoRequired: currentProject.photoRequired,
                                            ...pavertrackerInitialValues(currentProject[PAVERTRACKERS_NAME]),
                                            allowToNotify: currentProject.allowToNotify,
                                            autoAccept: currentProject.autoAccept,
                                            terms: currentProject.terms
                                                ? { value: currentProject.terms.id, label: currentProject.terms.name }
                                                : INITIAL_TERMS_VALUE,
                                            allowUnfilledStart: currentProject.allowUnfilledStart,
                                            jobContactId: currentProject.jobContact && {
                                                ...currentProject.jobContact,
                                                value: currentProject.jobContact.id,
                                                label: currentProject.jobContact.fullName,
                                            },
                                            customer: currentProject.customer && {
                                                value: currentProject.customer.id,
                                                label: currentProject.customer.name,
                                            },
                                            region: currentProject.region && {
                                                value: currentProject.region.id,
                                                label: currentProject.region.regionName,
                                            },
                                            restrictedCustomer: restrictedCustomer && {
                                                value: restrictedCustomer.id,
                                                label: restrictedCustomer.name,
                                            },
                                            customerType: restrictedCustomer
                                                ? RESTRICTED_CUSTOMER_VALUE
                                                : CUSTOMER_VALUE,
                                            dropOffNotificationsRecipients:
                                                currentProject?.dropOffNotificationsRecipients?.map(
                                                    generateUserSelectOptions,
                                                ),
                                        }}
                                    />
                                </ErrorBoundary>
                            )}
                        </div>
                        {_.isEmpty(projects) && !projectsLoading && (
                            <React.Fragment>
                                <br />
                                <br />
                                <br />
                                <h1 className="--text-center">No data to show</h1>
                            </React.Fragment>
                        )}
                        {!_.isEmpty(projects) && !projectsLoading && !currentProject && (
                            <React.Fragment>
                                <br />
                                <br />
                                <br />
                                <h1 className="--text-center">No project selected</h1>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

Projects.propTypes = {
    currentProject: PropTypes.object,
    getPOLineItems: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    projectsLoading: PropTypes.bool.isRequired,
    resetPOLineItems: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    resetCustomFieldsForm: PropTypes.func,
};

export default withRouter(
    connect(
        (state) => ({
            projects: state.projects.projects,
            projectsError: state.projects.projectsError,
            currentProject: state.projects.currentProject,
            projectsLoading: state.projects.projectsLoading,
            restrictedCustomersList: selectRestrictedCustomersList(state),
        }),
        (dispatch) => ({
            resetCustomFieldsForm: () => dispatch(destroy("UPDATE PROJECT")),
            setCurrentProject: (project) => {
                dispatch(projectsActions.setCurrentProject(project));
            },
            getPOLineItems: (payload) => {
                dispatch(poLineItemsActions.getPOLineItems(payload));
            },
            resetPOLineItems: (payload) => {
                dispatch(poLineItemsActions.resetPOLineItems(payload));
            },
            getRestrictedCustomersList: () => {
                dispatch(projectsActions.getRestrictedCustomersList());
            },
        }),
    )(Projects),
);
