import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import ConfirmationModal from "../core/confirmationModal";
import { getTruckById, getUsersWithTrucks } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AppModal from "../core/modal";
import { withRouter } from "react-router-dom";

import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { USER_ROLE_TRUCKER } from "../../constants/maps";
import Loader from "../core/loader";
import { Validation } from "../../helpers/validation";
import { LINK_USER_DIALOG_WARNING } from "../../constants/strings";

const LinkUserDialog = (props) => {
    const {
        linkDialogOpen,
        handleClose,
        truckForEdit,
        onSubmit,
        formValues: { user },
        handleSubmit,
        reset,
    } = props;
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const closeModal = () => {
        reset();
        handleClose && handleClose();
    };

    const linkDriverToTruck = () => {
        onSubmit(truckForEdit.id, user.value)
            .then(() => {
                setSuccessMessage(["Successfully Linked"]);
                setConfirmationMessage(null);
                setLoading(false);
                closeModal();
            })
            .catch((error) => {
                setLoading(false);
                setConfirmationMessage(null);
                setErrorMessage(PROCESS_SERVER_ERROR(error));
            });
    };

    const onFormSubmit = async () => {
        setLoading(true);
        getTruckById(truckForEdit.id)
            .then((truckData) => {
                const { linkedDriver } = truckData.data;
                if (!linkedDriver || (linkedDriver && user.value === linkedDriver.id)) {
                    linkDriverToTruck();
                } else {
                    const unlinkDriverName = `${linkedDriver.firstName} ${linkedDriver.lastName}`;
                    const linkDriverName = `${user.firstName} ${user.lastName}`;
                    setConfirmationMessage(LINK_USER_DIALOG_WARNING(unlinkDriverName, linkDriverName));
                }
            })
            .catch((error) => {
                setLoading(false);
                setErrorMessage(PROCESS_SERVER_ERROR(error));
            });
    };

    const noHandler = () => {
        setLoading(false);
        setConfirmationMessage(null);
    };

    const loadOptions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getUsersWithTrucks({
            keywords: inputValue || null,
            role: USER_ROLE_TRUCKER,
            ...params,
        }).then((data) => {
            const results = data.data.map((user) => ({
                ...user,
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <React.Fragment>
            <AppModal isOpen={linkDialogOpen} form={LINK_USER_FORM} closeModal={closeModal}>
                <form
                    noValidate={true}
                    onSubmit={handleSubmit(onFormSubmit)}
                    className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                >
                    {isLoading && <Loader />}
                    <h2 className="title">LINK USER DIALOG</h2>
                    <Field
                        name="user"
                        loadOptions={loadOptions}
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        placeholder="Select User To Link"
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton type="button" onClick={closeModal}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </ButtonsGroup>
                </form>
            </AppModal>
            {successMessage && (
                <SuccessNotification message={successMessage} config={{ onClose: () => setSuccessMessage(null) }} />
            )}
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
            <ConfirmationModal
                isOpen={!!confirmationMessage}
                question={confirmationMessage}
                yesHandler={linkDriverToTruck}
                noHandler={noHandler}
                modalStyles={{ maxWidth: 600 }}
            />
        </React.Fragment>
    );
};

LinkUserDialog.propTypes = {
    linkDialogOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    truckForEdit: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

export const LINK_USER_FORM = "linkUserForm";

export default withRouter(
    compose(
        reduxForm({
            form: LINK_USER_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "id", "user"),
            };
        }),
    )(LinkUserDialog),
);
