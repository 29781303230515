import { getClearXSRF } from "../helpers/api";

const MAX_WEBSOCKET_FAILS = 7;
const MIN_WEBSOCKET_RETRY_TIME = 3000; // 3 sec
const MAX_WEBSOCKET_RETRY_TIME = 300000; // 5 mins

class WebSocketClient {
    constructor() {
        this.connection = null;
        this.connectionUrl = null;
        this.connectionFailCount = 0;
        this.eventCallback = null;
    }

    initialize(connectionUrl) {
        if (this.connection) {
            return;
        }

        const token = getClearXSRF();

        this.connection = new WebSocket(connectionUrl);

        this.connectionUrl = connectionUrl;

        this.connection.onopen = () => {
            this.connectionFailCount = 0;

            const authObject = {
                auth: token,
            };
            this.connection.send(JSON.stringify(authObject));
        };

        this.connection.onclose = () => {
            this.connection = null;

            this.connectionFailCount++;

            let retryTime = MIN_WEBSOCKET_RETRY_TIME;

            // If we've failed a bunch of connections then start backing off
            if (this.connectionFailCount > MAX_WEBSOCKET_FAILS) {
                retryTime = MIN_WEBSOCKET_RETRY_TIME * this.connectionFailCount;
                if (retryTime > MAX_WEBSOCKET_RETRY_TIME) {
                    retryTime = MAX_WEBSOCKET_RETRY_TIME;
                }
            }

            setTimeout(() => {
                this.initialize(connectionUrl);
            }, retryTime);
        };

        this.connection.onerror = () => {
            // console.log('websocket error');
        };

        this.connection.onmessage = (evt) => {
            const msg = JSON.parse(evt.data);
            if (this.eventCallback) {
                this.eventCallback(msg);
            }
        };
    }

    close() {
        this.connectionFailCount = 0;
        if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            this.connection.onclose = () => {};
            this.connection.close();
            this.connection = null;
        }
    }

    setEventCallback(callback) {
        this.eventCallback = callback;
    }
}

export const websocketClient = new WebSocketClient();
