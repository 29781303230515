import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { Field, reduxForm } from "redux-form";
import { Validation } from "../../helpers/validation";
import TextInputComponent from "../core/form/textInputComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { sendMessageToTruckDevice } from "../../dataServers/trucks";
import { THEME_ICONS } from "../../styles/icons";
import { compose } from "redux";

const useStyles = makeStyles(() => ({
    messageTruck: {},
    uiIconSuccess: {
        ...THEME_ICONS.success,
    },
}));

const MessageForm = (props) => {
    const classes = useStyles();
    const { closeModal, handleSubmit } = props;
    const [isLoading, setLoader] = useState(false);
    const [dataIsSend, setDataStatus] = useState(false);
    const [error, setError] = useState();
    let timer;

    const onSubmit = ({ message, deviceId }) => {
        setLoader(true);

        sendMessageToTruckDevice(deviceId, { message })
            .then((data) => {
                setLoader(false);
                setDataStatus(true);
                timer = setTimeout(() => {
                    closeModal(false);
                }, 3000);
            })
            .catch((error) => {
                setLoader(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate={true}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY, classes.messageTruck)}
            >
                {isLoading && <Loader />}
                {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                {!dataIsSend ? (
                    <React.Fragment>
                        <h1 className="title">Message To Current Driver</h1>
                        <Field
                            name="message"
                            rows={3}
                            validate={[Validation.required]}
                            placeholder="Message"
                            multiline={true}
                            needShowEndAdornment={false}
                            component={TextInputComponent}
                        />
                        <br />
                        <br />
                        <ButtonsGroup>
                            <SecondaryButton onClick={() => closeModal(false)}>Cancel</SecondaryButton>
                            <PrimaryButton type="submit">Send</PrimaryButton>
                        </ButtonsGroup>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <h1 className="title">Message Sent</h1>
                        <span>
                            <i className={classes.uiIconSuccess} />
                        </span>
                        <br />
                        <ButtonsGroup>
                            <PrimaryButton onClick={() => closeModal(false)}>Close</PrimaryButton>
                        </ButtonsGroup>
                    </React.Fragment>
                )}
            </form>
        </div>
    );
};

MessageForm.propTypes = {
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export const MESSAGE_FOR_DEVICE_FORM = "truckMessageDriversForm";

export default withRouter(
    compose(
        reduxForm({
            form: MESSAGE_FOR_DEVICE_FORM,
        }),
    )(MessageForm),
);
