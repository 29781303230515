import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import SubNavigation, { subNavigationStyles as parentStyles } from "../global/subNavigation";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { THEME_ICONS } from "../../styles/icons";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import PinDropIcon from "@material-ui/icons/PinDrop";
import { ROUTE_PROJECTS } from "../../routes/globalRoutes";
import { GLOBAL_CONTENT_WIDTH } from "../../styles/globalThemeConfig";

const activeClass = "active";

const useStyles = makeStyles((theme) => ({
    uiIconProjects: {
        ...THEME_ICONS.eventIcon,
        marginRight: 20,
    },
    uiIconSites: {
        width: theme.icon.width,
        height: theme.icon.height,
        marginRight: 20,
    },
    subNav: { padding: "9px 0" },
}));

const ProjectsSubNav = (props) => {
    const classes = useStyles();
    const subNavigationStyles = parentStyles();
    const {
        history: {
            location: { pathname },
        },
    } = props;

    return (
        <SubNavigation subMenuWidth={GLOBAL_CONTENT_WIDTH} subNavStyles={classes.subNav}>
            <Grid container component="div" alignItems={"center"}>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            classes.viewSwitcher,
                            pathname.includes(ROUTE_PROJECTS.PROJECTS) && activeClass,
                        )}
                    >
                        <AccountTreeIcon className={classes.uiIconProjects} />
                        <Link to={ROUTE_PROJECTS.PROJECTS}>Projects</Link>
                    </div>
                </Grid>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            pathname.includes(ROUTE_PROJECTS.SITES) && activeClass,
                        )}
                    >
                        <PinDropIcon className={classes.uiIconSites} />
                        <Link to={ROUTE_PROJECTS.SITES}>Sites</Link>
                    </div>
                </Grid>
            </Grid>
        </SubNavigation>
    );
};

ProjectsSubNav.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(ProjectsSubNav);
