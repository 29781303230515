import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import UnitOfMeasure from "./unitOfMeasure";
import { UNIT_OF_MEASURE_BY_ID } from "../../constants/maps";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import clsx from "clsx";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { getUnitOfMeasureValue } from "../../helpers/global";
import { selectUnitOfMeasureName } from "../../reducers/unitsOfMeasureReducer";

const useStyles = makeStyles((theme) => ({
    inBuildSwitcher: {
        width: "auto",
        alignItems: "center",
        flexWrap: "nowrap",
    },
    infiniteIcon: {
        height: 34,
        width: 30,
        position: "relative",
        top: 2,
        marginRight: 4,
        cursor: "pointer",

        "&.-disabled": {
            color: theme.palette.secondary.superLight,
        },

        "&.-active, &:hover": {
            color: theme.palette.general.priceGreen,
        },
    },
}));

const UnlimitedAdornment = (props) => {
    const classes = useStyles();
    const {
        dispatch,
        form,
        formValues: { unlimited, unitOfMeasure: unitValue },
        quantityName,
        disabled,
        setUnlimitedHandler,
        uomFromState,
        unitOfMeasureFromProps,
    } = props;

    const unitOfMeasure = getUnitOfMeasureValue(unitValue) || uomFromState?.name || unitOfMeasureFromProps?.name;

    const setUnlimited = () => {
        if (disabled) {
            return false;
        }

        const value = !unlimited;
        dispatch(change(form, "unlimited", value));
        dispatch(change(form, quantityName, null));
        setUnlimitedHandler && setUnlimitedHandler(value);
    };

    return (
        <Grid container className={clsx(classes.inBuildSwitcher)}>
            {unitOfMeasure && (
                <React.Fragment>
                    <Grid item>
                        <UnitOfMeasure value={unitOfMeasure} />
                    </Grid>
                    <Grid item>&nbsp;&nbsp;/&nbsp;&nbsp;</Grid>
                </React.Fragment>
            )}
            <Grid item>
                <AllInclusiveIcon
                    onClick={setUnlimited}
                    title={"Unlimited"}
                    className={clsx(classes.infiniteIcon, unlimited && "-active", disabled && "-disabled")}
                />
            </Grid>
        </Grid>
    );
};

UnlimitedAdornment.propTypes = {
    form: PropTypes.string.isRequired,
    quantityName: PropTypes.string.isRequired,
    unlimited: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    setUnlimitedHandler: PropTypes.func,
    disabled: PropTypes.bool,
};

UnlimitedAdornment.defaultProps = {
    unlimited: false,
};

export default withRouter(
    connect((state, props) => {
        const values = formValueSelector(props.form)(state, "unitOfMeasure", "unlimited");

        const uom = selectUnitOfMeasureName(state, values.unitOfMeasure);

        return {
            formValues: values,
            uomFromState: uom,
        };
    })(UnlimitedAdornment),
);
