import React from "react";
import { SecondaryButton } from "../core";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { PREVIOUS_ACCOUNT_DATA_STORAGE_KEY } from "../../helpers/api";
import { changeCurrentUser } from "../../actions/globalActions";
import { useDispatch } from "react-redux";
import { ROUTE_DASHBOARD } from "../../routes/globalRoutes";

const useStyles = makeStyles((theme) => ({
    sessionReturnButton: {
        marginLeft: "27px",
        width: "40px",
        height: "15px",
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
    },
}));

const previousAccount = JSON.parse(localStorage.getItem(PREVIOUS_ACCOUNT_DATA_STORAGE_KEY));

const ReturnToMainSessionButton = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleReturnToMainSessionClick = () => {
        dispatch(changeCurrentUser(previousAccount, ROUTE_DASHBOARD));
    };

    return (
        <Tooltip title={`Return to ${previousAccount?.company?.name} account`}>
            <div>
                <SecondaryButton
                    className={clsx(classes.sessionReturnButton)}
                    onClick={() => handleReturnToMainSessionClick()}
                >
                    Return
                </SecondaryButton>
            </div>
        </Tooltip>
    );
};
export default ReturnToMainSessionButton;
