import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTrailers, archiveTrailer as archiveTrailerAC } from "../../actions/trailers";
import TrailersList from "./trailersList";
import TrailersFilters from "./trailersFilters";
import CreateNewTrailer from "./createNewTrailer";
import ConfirmationModal from "../core/confirmationModal";
import { selectTrailers, selectTrailersMeta, actions as trailersActions } from "../../reducers/trailersReducer";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";

function Trailers() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [trailer, setTrailer] = useState(null);
    const [archiveTrailer, setArchiveTrailer] = useState(null);
    const [appliedFilters, setAppliedFilters] = useState(null);

    const trailers = useSelector(selectTrailers);
    const { loading } = useSelector(selectTrailersMeta);

    const searchValue = appliedFilters && appliedFilters.keywords;

    const getMatchedName = (name, searchText) => name.toLowerCase().includes(searchText.trim().toLowerCase());

    const getMatchedData = (companies) => {
        const list = companies && companies.filter(({ name }) => getMatchedName(name, searchValue));

        return list;
    };

    const dispatch = useDispatch();

    const openModal = () => {
        setIsOpenModal(true);
    };

    const closeModal = () => {
        setIsOpenModal(false);
        setTrailer(null);
    };

    useEffect(() => {
        dispatch(getTrailers());
    }, []);

    const actions = {
        editTrailer: (trailer) => setTrailer(trailer),
        archiveTrailer: (trailer) => setArchiveTrailer(trailer),
    };

    return (
        <div className={loading && LOADER_WHITE_OVERLAY}>
            {loading && <Loader />}
            <TrailersFilters updateListByFilters={(filters) => setAppliedFilters(filters)} openModal={openModal} />
            <TrailersList
                trailers={searchValue ? getMatchedData(trailers) : trailers}
                trailer={trailer}
                actions={actions}
            />
            <CreateNewTrailer
                openModal={isOpenModal || !!trailer}
                initialValues={trailer && trailer}
                title={trailer ? "EDIT TRAILER" : "CREATE NEW TRAILER"}
                trailerId={!!trailer && trailer.id}
                closeModal={closeModal}
            />
            <ConfirmationModal
                isOpen={!!archiveTrailer}
                question={`Are you sure you want to archive ${archiveTrailer?.name} trailer?`}
                yesHandler={() => {
                    dispatch(archiveTrailerAC(archiveTrailer?.id));
                    setArchiveTrailer(null);
                }}
                noHandler={() => setArchiveTrailer(null)}
            />
        </div>
    );
}

export default Trailers;
