export const SITES_LOADING = "sitesLoading";
export const SITES_VISIBLE_LOADING = "sitesVisibleLoading";
export const SITES_LOADED = "sitesLoaded";
export const SITES_FAILED = "sitesFailed";
export const SITES_SET_SEARCH_QUERY = "sitesSetSearchQuery";
export const SITES_SET_FILTERS = "sitesSetFilters";
export const SITES_CLEAR_FILTERS = "sitesClearFilters";
export const SITES_RESET = "sitesReset";
export const SITES_UPDATED = "sitesUpdated";
export const SITE_OPENED = "sitesOpened";
export const SITE_SET_ALERT_ZONE_COORDINATES = "siteSetAlertZoneCoordinates";
export const SITE_SET_NEW_ALERT_ZONE_COORDINATES = "siteSetNewAlertZoneCoordinates";
