import {
    SETTINGS_TRUCK_TYPES_FAILURE_MESSAGE,
    SETTINGS_TRUCK_TYPES_FILTERS_SET,
    SETTINGS_TRUCK_TYPES_LOADING,
    SETTINGS_TRUCK_TYPES_LOADING_FAILURE,
    SETTINGS_TRUCK_TYPES_LOADING_SUCCESS,
    SETTINGS_TRUCK_TYPES_SUCCESS_MESSAGE,
    SETTINGS_TRUCK_TYPES_UPDATE,
} from "../events/truckTypesSettings";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import {
    archiveTruckType,
    deleteTruckTypeRequest,
    fetchCompanyTruckTypes,
    unarchiveTruckTypeRequest,
} from "../dataServers/trucks";

const settingsTruckTypesLoading = () => {
    return {
        type: SETTINGS_TRUCK_TYPES_LOADING,
    };
};

const settingsTruckTypesLoadingSuccess = (truckTypesResponse) => {
    return {
        type: SETTINGS_TRUCK_TYPES_LOADING_SUCCESS,
        payload: truckTypesResponse,
    };
};

const settingsTruckTypesLoadingFailure = (error) => {
    return {
        type: SETTINGS_TRUCK_TYPES_LOADING_FAILURE,
        payload: error,
    };
};

export const settingsTruckTypesFiltersSet = (filters) => {
    return {
        type: SETTINGS_TRUCK_TYPES_FILTERS_SET,
        payload: filters,
    };
};

export const truckTypesSettingsSuccessMessage = (success) => {
    return {
        type: SETTINGS_TRUCK_TYPES_SUCCESS_MESSAGE,
        payload: success,
    };
};

export const truckTypesSettingsErrorMessage = (error) => {
    return {
        type: SETTINGS_TRUCK_TYPES_FAILURE_MESSAGE,
        payload: error,
    };
};

export const updateTruckType = (updatedTruckType) => {
    return {
        type: SETTINGS_TRUCK_TYPES_UPDATE,
        payload: updatedTruckType,
    };
};

export const getSettingsTruckTypes = (concat, companyId, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const truckTypesList = state.truckTypesSettings.truckTypesList;
        const currentPage = Math.ceil(truckTypesList.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(truckTypesList) ? +currentPage + 1 : 1;

        dispatch(settingsTruckTypesLoading());

        return fetchCompanyTruckTypes(companyId, {
            page: concat ? nextPage : 1,
            ...params,
        })
            .then((response) => {
                dispatch(
                    settingsTruckTypesLoadingSuccess({
                        meta: response.meta,
                        data: concat ? [...truckTypesList, ...response.data] : response.data,
                    }),
                );
            })
            .catch((error) => {
                dispatch(settingsTruckTypesLoadingFailure(error));
            });
    };
};

export const deleteTruckType = (truckTypeId, truckTypeName) => {
    return (dispatch, getState) => {
        const state = getState();
        const companyId = state.account.company.id;
        const params = state.truckTypesSettings.truckTypesFilters;

        return deleteTruckTypeRequest(truckTypeId)
            .then((response) => {
                dispatch(truckTypesSettingsSuccessMessage(`Truck type ${truckTypeName} was successfully deleted`));
                dispatch(getSettingsTruckTypes(false, companyId, params));
            })
            .catch((error) => {
                dispatch(truckTypesSettingsErrorMessage(error.message));
            });
    };
};

export const changeTruckTypeStatus = (isArchived, truckTypeId, payloadName) => {
    return (dispatch, getState) => {
        const state = getState();
        const companyId = state.account.company.id;

        const params = state.truckTypesSettings.truckTypesFilters;
        const request = isArchived ? unarchiveTruckTypeRequest : archiveTruckType;

        request(truckTypeId)
            .then((response) => {
                dispatch(getSettingsTruckTypes(false, companyId, params));
                const successMessage = `Truck type ${payloadName} was successfully ${
                    isArchived ? "unarchived" : "archived"
                }`;
                dispatch(truckTypesSettingsSuccessMessage(successMessage));
            })
            .catch((error) => {
                dispatch(truckTypesSettingsErrorMessage(error.message));
            });
    };
};
