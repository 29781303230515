import { MIN_DURATION_TIME, TIME_TICK_COUNT } from "../../components/reportAndAnalytic/truckAllActivitiesChart";
import { TimeRange } from "pondjs";
import { useState } from "react";

export const handleChartZoomOut = (timeRange, setTimeRange, minMaxTimes) => {
    if (timeRange) {
        const timeDuration = timeRange.duration();
        const timeTicks = timeDuration / TIME_TICK_COUNT;

        const [currentMinTimestamp, currentMaxTimestamp] = timeRange.toJSON();
        const [minTime, maxTime] = [minMaxTimes.minTime.getTime(), minMaxTimes.maxTime.getTime()];

        const newMinTimestamp = currentMinTimestamp - timeTicks < minTime ? minTime : currentMinTimestamp - timeTicks;
        const newMaxTimestamp = currentMaxTimestamp + timeTicks > maxTime ? maxTime : currentMaxTimestamp + timeTicks;

        const newTimeRange = new TimeRange(newMinTimestamp, newMaxTimestamp);

        if (!timeRange.equals(newTimeRange)) {
            setTimeRange(newTimeRange);
        }
    }
};

export const handleChartZoomIn = (timeRange, setTimeRange) => {
    if (timeRange) {
        const timeDuration = timeRange.duration();
        const timeTicks = timeDuration / TIME_TICK_COUNT;
        const [currentMinTimestamp, currentMaxTimestamp] = timeRange.toJSON();
        // If time range could be less than minimal duration time
        // we should cancel zoom in. For zoom out it won't happen
        if (timeDuration - timeTicks > MIN_DURATION_TIME) {
            setTimeRange(new TimeRange(currentMinTimestamp + timeTicks, currentMaxTimestamp - timeTicks));
        }
    }
};

export const handleChartResetZoom = (timeRange, setTimeRange, minMaxTimes) => {
    if (timeRange) {
        const newTimeRange = new TimeRange(minMaxTimes.minTime, minMaxTimes.maxTime);
        if (!timeRange.equals(newTimeRange)) {
            setTimeRange(newTimeRange);
        }
    }
};

export const useTooltipChartPosition = () => {
    const [position, setPosition] = useState({
        x: undefined,
        y: undefined,
    });
    const [showTip, setShowTip] = useState(false);
    const [currentTitle, setCurrentTitle] = useState("");

    const handleHoverEvent = (event, isLeave = false) => {
        if (isLeave) {
            setShowTip(false);
            setPosition({
                x: null,
                y: null,
            });

            return;
        }
        const { title: newCurrentTitle } = event.toJSON().data;

        if (newCurrentTitle !== currentTitle) {
            setPosition({
                x: null,
                y: null,
            });
        }

        setShowTip(true);
        setCurrentTitle(newCurrentTitle ? newCurrentTitle : "");
    };
    const getBoundingClientRect = () => ({
        top: position.y + window.scrollY,
        left: position.x,
        right: position.x,
        bottom: position.y - window.scrollY,
        width: 0,
        height: 0,
    });
    const onMouseMove = (e) => {
        if (!position.x && !position.y) {
            setPosition({
                x: e.pageX,
                y: e.pageY,
            });
        }
    };

    const handleTooltipClose = () => {
        setShowTip(false);
    };

    return [showTip, currentTitle, handleHoverEvent, getBoundingClientRect, onMouseMove, handleTooltipClose];
};
