import React from "react";
import { Field, reduxForm } from "redux-form";
import clsx from "clsx";

import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import CustomSwitch from "../../core/form/customSwitch";
import { updateCompanyShowPrice } from "../../../actions/settings";
import { useErrorNotification } from "../../../hooks";

const UpdateRestrictedCustomerShowPriceFrom = ({ submitting, dispatch, customerId }) => {
    const showError = useErrorNotification();

    const onChangeShowPricing = async (event) => {
        try {
            return await dispatch(updateCompanyShowPrice(customerId, event.target.checked));
        } catch (error) {
            showError(error);
        }
    };

    return (
        <form className={clsx(submitting && LOADER_WHITE_OVERLAY)}>
            <Field
                name={getShowPriceFieldName(customerId)}
                type="checkbox"
                component={CustomSwitch}
                onChange={onChangeShowPricing}
            />
        </form>
    );
};

export const UPDATE_RESTRICTED_CUSTOMER_SHOW_PRICING_FROM = "updateRestrictedCustomerShowPricingForm";
export const getShowPriceFieldName = (id) => `restrictedCustomerShowPrice-${id}`;

export default reduxForm({ enableReinitialize: false })(UpdateRestrictedCustomerShowPriceFrom);
