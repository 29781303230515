import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { connect, useSelector } from "react-redux";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import ProjectLineItem from "./projectLineItem";
import Loader from "../core/loader";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import ErrorNotification from "../core/notification";
import ListWaypoint from "../core/listWaypoint";
import { selectForcedSelectedPoLineItem } from "../../selectors/poLineItems";

const useStyles = makeStyles(() => ({
    projectItemsList: {},
    loading: {
        minHeight: 170,
    },
}));

const ProjectLineItemsList = (props) => {
    const {
        poLineItems,
        poLineItemsCount,
        poLineItemsError,
        poLineItemsLoading,
        disabled,
        archived,
        getMorePOLineItems,
        isArchiveTab,
        forcedSelectedPoLineItem,
        currentProject
    } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={clsx(poLineItemsLoading && `${LOADER_WHITE_OVERLAY} ${classes.loading}`)}>
                {poLineItemsLoading && <Loader />}
                <Grid
                    component="div"
                    style={{ maxHeight: 500, overflowY: "scroll" }}
                    className={clsx(classes.projectItemsList)}
                >
                    {!isArchiveTab && forcedSelectedPoLineItem && (
                        <ProjectLineItem
                            poLineItem={forcedSelectedPoLineItem}
                            disabled={disabled}
                            archived={archived}
                            isArchiveTab={isArchiveTab}
                            isHighlighted={true}
                        />
                    )}
                    {!_.isEmpty(poLineItems) ? (
                        poLineItems
                            .filter((poLineItem) => poLineItem.id !== forcedSelectedPoLineItem?.id)
                            .map((poLineItem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ListWaypoint
                                            fetchMore={getMorePOLineItems}
                                            index={index}
                                            items={poLineItems}
                                            itemsCount={poLineItemsCount}
                                        />
                                        <ProjectLineItem
                                            disabled={disabled}
                                            archived={archived}
                                            poLineItem={poLineItem}
                                            key={index}
                                            isArchiveTab={isArchiveTab}
                                            currentProject={currentProject}
                                        />
                                    </React.Fragment>
                                );
                            })
                    ) : (
                        <React.Fragment>
                            <br />
                            <h2 className="--text-center">No projects items</h2>
                            <br />
                        </React.Fragment>
                    )}
                </Grid>
            </div>
            <br />
            {poLineItemsError && <ErrorNotification message={poLineItemsError} />}
            {_.isEmpty(poLineItems) && !poLineItemsLoading && (
                <React.Fragment>
                    <br />
                    <br />
                    <h3 className="--text-center">No project items found.</h3>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

ProjectLineItemsList.propTypes = {
    currentProject: PropTypes.object.isRequired,
    getPOLineItems: PropTypes.func.isRequired,
    getMorePOLineItems: PropTypes.func,
    poLineItems: PropTypes.array.isRequired,
    poLineItemsCount: PropTypes.number.isRequired,
    poLineItemsError: PropTypes.string,
    poLineItemsLoading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    resetPOLineItems: PropTypes.func.isRequired,
    archived: PropTypes.bool,
    isArchiveTab: PropTypes.bool,
};

export default withRouter(
    connect((state) => {
        return {
            currentProject: state.projects.currentProject,
            poLineItems: state.poLineItems.poLineItems,
            poLineItemsCount: state.poLineItems.poLineItemsCount,
            poLineItemsError: state.poLineItemsError,
            poLineItemsLoading: state.poLineItems.poLineItemsLoading,
            forcedSelectedPoLineItem: selectForcedSelectedPoLineItem(state)
        };
    })(ProjectLineItemsList),
);
