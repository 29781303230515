import {
    SETTINGS_REGIONS_DELETE,
    SETTINGS_REGIONS_FAILURE_MESSAGE,
    SETTINGS_REGIONS_FILTERS_SET,
    SETTINGS_REGIONS_LOADING,
    SETTINGS_REGIONS_LOADING_FAILURE,
    SETTINGS_REGIONS_LOADING_SUCCESS,
    SETTINGS_REGIONS_REQUEST,
    SETTINGS_REGIONS_REQUEST_RESULT,
    SETTINGS_REGIONS_SUCCESS_MESSAGE,
    SETTINGS_REGIONS_UPDATE,
} from "../events/regionsSettings";
import { updateAccountData } from "../helpers/global.js";
import { ACCOUNT_COMPANY_PROFILE_KEY, PROCESS_SERVER_ERROR } from "../constants/global";
import { getUserAccountId } from "../selectors/index";
import { saveUserProfile } from "../dataServers/user";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import { deleteRegionRequest, fetchCompanyRegions } from "../dataServers/regions";
import { authorizeUser, deleteUserRegion } from "./globalActions";

const settingsRegionsLoading = () => {
    return {
        type: SETTINGS_REGIONS_LOADING,
    };
};

const settingsRegionsLoadingSuccess = (regionsResponse) => {
    return {
        type: SETTINGS_REGIONS_LOADING_SUCCESS,
        payload: regionsResponse,
    };
};

const settingsRegionsLoadingFailure = (error) => {
    return {
        type: SETTINGS_REGIONS_LOADING_FAILURE,
        payload: error,
    };
};

export const settingsRegionsFiltersSet = (filters) => {
    return {
        type: SETTINGS_REGIONS_FILTERS_SET,
        payload: filters,
    };
};

export const regionsSettingsSuccessMessage = (success) => {
    return {
        type: SETTINGS_REGIONS_SUCCESS_MESSAGE,
        payload: success,
    };
};

export const regionsSettingsErrorMessage = (error) => {
    return {
        type: SETTINGS_REGIONS_FAILURE_MESSAGE,
        payload: error,
    };
};

export const updateSettingsRegionData = (updatedRegion) => {
    return {
        type: SETTINGS_REGIONS_UPDATE,
        payload: updatedRegion,
    };
};

export const deleteSettingsRegion = (regionToDeleteId) => {
    return {
        type: SETTINGS_REGIONS_DELETE,
        payload: regionToDeleteId,
    };
};

export const settingsRegionRequest = () => {
    return {
        type: SETTINGS_REGIONS_REQUEST,
    };
};

export const settingsRegionResult = (payload) => {
    return {
        type: SETTINGS_REGIONS_REQUEST_RESULT,
        payload,
    };
};

export const getSettingsRegions = (concat, companyId, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const regionsList = state.regionsSettings.regionsList;
        const currentPage = Math.ceil(regionsList.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(regionsList) ? +currentPage + 1 : 1;

        dispatch(settingsRegionsLoading());

        return fetchCompanyRegions(companyId, {
            page: concat ? nextPage : 1,
            ...params,
        })
            .then((response) => {
                dispatch(
                    settingsRegionsLoadingSuccess({
                        meta: response.meta,
                        data: concat ? [...regionsList, ...response.data] : response.data,
                    }),
                );
            })
            .catch((error) => {
                dispatch(settingsRegionsLoadingFailure([PROCESS_SERVER_ERROR(error)]));
            });
    };
};

export const updateProfile = ({ field, value, successHandler, errorHandler, selectedCompany }) => {
    return (dispatch, getState) => {
        dispatch(settingsRegionRequest());
        let companyId;
        if (selectedCompany) companyId = selectedCompany.value;
        else companyId = getUserAccountId(getState());
        const payload = { [field]: value };
        saveUserProfile(companyId, payload)
            .then((data) => {
                updateAccountData(
                    data.data,
                    (accountData) => dispatch(authorizeUser(accountData)),
                    ACCOUNT_COMPANY_PROFILE_KEY,
                );
                dispatch(settingsRegionResult());
                successHandler(["Regions settings are updated"]);
            })
            .catch((error) => {
                dispatch(settingsRegionResult());
                errorHandler([PROCESS_SERVER_ERROR(error)]);
            });
    };
};

export const deleteRegion = (regionId, regionName) => {
    return (dispatch) => {
        return deleteRegionRequest(regionId)
            .then(() => {
                dispatch(regionsSettingsSuccessMessage([`Region ${regionName} was successfully deleted`]));
                dispatch(deleteSettingsRegion(regionId));
                dispatch(deleteUserRegion(regionId));
            })
            .catch((error) => {
                dispatch(regionsSettingsErrorMessage([error.message]));
            });
    };
};
