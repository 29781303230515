import React, { useState } from "react";
import clsx from "clsx";
import { Validation } from "../../../helpers/validation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../../core/form/asyncAutocompleteComponent";
import { change, Field } from "redux-form";
import { DEFAULT_RADIUS_VALUE } from "../jobComponents/pickUp&DropOff";
import { fetchCompanySitesList } from "../../../dataServers/purchaseOrders";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ICONS_SITE } from "../../../styles/icons";
import SiteForm, { SITE_FORM } from "../../sites/siteForm";
import AppModal from "../../core/modal";

export const MID_SITE_NAME = "midSite";

const MidSite = ({ form, disabled, currentProject, currentProjectCompanyId, sitesRequestParams }) => {
    const dispatch = useDispatch();
    const siteIconsClasses = ICONS_SITE();
    const [showCreateSiteModal, setShowCreateSiteModal] = useState(false);

    const loadSites = (inputValue, { params = {}, loadedCount }) => {
        return fetchCompanySitesList({
            keywords: inputValue,
            company: currentProject?.owner?.id || currentProjectCompanyId,
            ...params,
            ...sitesRequestParams,
        }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: `${i.name} - ${i.address}`,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const createNewMidSiteHandler = (site) => {
        const createdSiteOption = {
            value: site.id,
            label: `${site.name} - ${site.address}`,
        };
        dispatch(change(form, MID_SITE_NAME, createdSiteOption));
    };

    return (
        <>
            <Field
                name={MID_SITE_NAME}
                loadOptions={loadSites}
                validate={[Validation.required]}
                disabled={disabled}
                startAdornment={<LocationOnIcon className={clsx(siteIconsClasses.iconSite, "mid")} />}
                placeholder="Mid Site"
                className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                createNewOption={{
                    label: `Add Site`,
                    value: CREATE_NEW_OPTION_ID,
                }}
                createNewHandler={() => setShowCreateSiteModal(true)}
                component={AsyncAutocompleteComponent}
            />
            <AppModal
                isOpen={showCreateSiteModal}
                modalStyles={{ width: 600 }}
                form={SITE_FORM}
                closeModal={() => setShowCreateSiteModal(false)}
            >
                <SiteForm
                    closeModal={() => setShowCreateSiteModal(false)}
                    createForDropdown={true}
                    afterSaveHandler={createNewMidSiteHandler}
                    initialValues={{
                        // When creating a new site, we should by default turn on the "create alert zone"
                        // https://github.com/TruckITllc/truckit-frontend/issues/424#issue-597412526
                        alertZone: true,
                        // have a default value of 100 meter.
                        // https://github.com/TruckITllc/truckit-frontend/issues/424#issue-597412526
                        radius: DEFAULT_RADIUS_VALUE,
                    }}
                />
            </AppModal>
        </>
    );
};

export default withRouter(
    connect((state, props) => {
        return {
            currentProject: state.projects.currentProject,
            currentProjectCompanyId: state.projects.currentProjectCompany?.id,
            account: state.account,
        };
    })(MidSite),
);
