/* global google */
import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import {
    GOOGLE_MAPS_SITE_ICON_SIZE,
    GOOGLE_MAPS_URL,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
} from "../../constants/global";
import { MAP } from "react-google-maps/lib/constants";
import { compose as reduxCompose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Validation } from "../../helpers/validation";
import TextInputComponent from "../core/form/textInputComponent";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import Grid from "@material-ui/core/Grid";
import { getValidCoordinates } from "../../helpers/sites";
import _ from "lodash";
import { loadCurrentCoordinates } from "../../helpers/location";
import CustomMarker from "../global/maps/customMarker";
import sitePin from "../../styles/images/pale-red-pin.svg";
import { createRegionRequest, updateRegionRequest } from "../../dataServers/regions";
import clsx from "clsx";
import { updateSettingsRegionData } from "../../actions/regionsSettings";
import { addNewUserRegion, updateUserRegion } from "../../actions/globalActions";

const RegionFormMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { setMap, currentLocation } = props;

    return (
        <GoogleMap ref={(map) => setMap(map)} defaultZoom={10}>
            {currentLocation && (
                <CustomMarker
                    id={0}
                    position={currentLocation}
                    icon={{
                        url: sitePin,
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                    }}
                />
            )}
        </GoogleMap>
    );
});

const RegionsSettingsCreateUpdateForm = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
)((props) => {
    const {
        handleSubmit,
        defaultCoordinates,
        formValues: { coordinates, id, company },
        account,
        errorHandler,
        successHandler,
        closeModal,
        getRegions,
    } = props;

    const dispatch = useDispatch();
    let placesService;
    const geocoder = new google.maps.Geocoder();
    const setFieldValue = (fieldName, updatedValue) =>
        dispatch(change(CREATE_UPDATE_REGION_FORM, fieldName, updatedValue));

    const [plService, setPlacesService] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [coordinatesChanged, setCoordinatesChanged] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [map, setMap] = useState();

    const _changeLocation = (
        { placeId, lat, lng },
        map,
        centerMap = true,
        changeCoords = true,
        changeLocation = true,
    ) => {
        placesService = placesService || plService;
        if (placeId && map && placesService) {
            placesService.getDetails({ placeId: placeId }, (placeDetails, status) => {
                if (status === "OK") {
                    changeLocation &&
                        setFieldValue(
                            "coordinates",
                            `${lat || placeDetails.geometry.location.lat()}, ${
                                lng || placeDetails.geometry.location.lng()
                            }`,
                        );

                    changeCoords && setCurrentLocation(placeDetails.geometry.location);

                    if (centerMap) map.setCenter(placeDetails.geometry.location);
                }
            });
        }
    };

    const _onMapClick = (event, map) => {
        setCurrentLocation(event.latLng);
        geocoder.geocode({ location: event.latLng }, (result, status) => {
            if (status === "OK" && !_.isEmpty(result))
                _changeLocation(
                    {
                        placeId: result[0].place_id,
                        lat: event.latLng.lat(),
                        lng: event.latLng.lng(),
                    },
                    map,
                    false,
                    false,
                );
        });
    };

    const onCoordinatesChange = () => {
        const location = getValidCoordinates(coordinates);
        setCoordinatesChanged(false);
        if (location) {
            geocoder.geocode(
                {
                    location: location,
                },
                (result, status) => {
                    if (status === "OK" && !_.isEmpty(result)) {
                        _changeLocation({ placeId: result[0].place_id }, map.context[MAP]);
                    }
                },
            );

            return;
        }
        errorHandler(["Invalid coordinates format"]);
    };

    const onSubmit = (values) => {
        const { regionName, coordinates, company, id } = values;

        const isUpdateForm = !!id;

        if (coordinatesChanged) {
            errorHandler(["Use must click 'Proceed' button after coordinate value change."]);

            return;
        }

        const validatedCoordinates = getValidCoordinates(coordinates);

        const data = {
            name: regionName,
            latitude: validatedCoordinates.lat,
            longitude: validatedCoordinates.lng,
        };

        const companyId = company ? company.value : account.company.id;

        setLoading(true);

        const request = isUpdateForm ? updateRegionRequest(id, data) : createRegionRequest(companyId, data);

        return request
            .then((response) => {
                const region = response.data;
                const regionForFilters = {
                    name: region.regionName,
                    id: region.id,
                };

                successHandler([`Region was ${isUpdateForm ? "updated" : "created"}`]);
                closeModal();
                if (isUpdateForm) {
                    dispatch(updateSettingsRegionData(region));
                    dispatch(updateUserRegion(regionForFilters));
                } else {
                    getRegions();
                    dispatch(addNewUserRegion(regionForFilters));
                }
            })
            .catch((e) => {
                errorHandler([PROCESS_SERVER_ERROR(e)]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (map) {
            placesService = new google.maps.places.PlacesService(map.context[MAP]);
            setPlacesService(placesService);
            if (!_.isEmpty(defaultCoordinates)) {
                setCurrentLocation(defaultCoordinates);
                map.context[MAP].setCenter(defaultCoordinates);
            } else {
                loadCurrentCoordinates().then((response) => {
                    map.context[MAP].setCenter({
                        lat: response.latitude,
                        lng: response.longitude,
                    });
                });
            }

            google.maps.event.addDomListener(map.context[MAP], "click", (event) => {
                if (map) {
                    _onMapClick(event, map.context[MAP]);
                }
            });
        }
    }, [map]);

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                <h2 className="title">
                    {id ? "Edit" : "Create"} Region {company ? `for company ${company.label}` : null}
                </h2>
                <RegionFormMap
                    googleMapURL={GOOGLE_MAPS_URL}
                    currentLocation={currentLocation}
                    setMap={setMap}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: "30vh" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <br />
                <Field
                    type="text"
                    name="regionName"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    label="Region Name"
                    fullWidth={true}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Grid container component="div" justify="space-between">
                    <Grid item xs={8}>
                        <Field
                            type="text"
                            name="coordinates"
                            validate={[Validation.required, Validation.coordinates]}
                            label="Coordinates"
                            placeholder="Latitude, Longitude"
                            fullWidth={true}
                            onChange={() => setCoordinatesChanged(true)}
                            needShowEndAdornment={true}
                            fieldNote="Coordinates should be separated by a comma"
                            component={TextInputComponent}
                        />
                    </Grid>
                    <SecondaryButton onClick={onCoordinatesChange}>Proceed</SecondaryButton>
                </Grid>
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
        </div>
    );
});

export const CREATE_UPDATE_REGION_FORM = "createUpdateRegionForm";

export default withRouter(
    reduxCompose(
        reduxForm({
            form: CREATE_UPDATE_REGION_FORM,
        }),
        connect((state) => {
            const formSelector = formValueSelector(CREATE_UPDATE_REGION_FORM);

            return {
                account: state.account,
                formValues: formSelector(state, "id", "coordinates", "company"),
            };
        }),
    )(RegionsSettingsCreateUpdateForm),
);
