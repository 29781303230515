import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { change, formValues, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ActionLink, SimpleLink } from "../core/link";
import { IS_INSPECTOR_USER } from "../../constants/maps";
import { downloadTicketsTicPicks } from "../../dataServers/tickets";
import TicketsList from "./ticketsList";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import CreateTicketForm, { CREATE_TICKET_FORM } from "./components/createTicketForm";
import AppModal from "../core/modal";
import ErrorNotification from "../core/notification";
import SuccessNotification from "../core/successNotification";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Container from "@material-ui/core/Container";
import Loader from "../core/loader";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import TicketFilters from "./ticketFilters";
import TicketsReportLink from "./components/ticketsReportLink";
import RepeatIcon from "@material-ui/icons/Repeat";
import BulkReconcileForm from "./components/bulkReconcileForm";
import { RECONCILE_TICKET_FORM } from "./components/reconcileTicketForm";
import { generatePredictiveKeywordParam } from "../../helpers/predictiveSearchHelpers";
import { selectDefaultAdditionalExternalRef } from "../../reducers/additionalExternalRefsReducer";
import { selectIfIsRestrictedCustomer } from "../../selectors/user";

function TicketsWrapper(props) {
    const {
        form,
        tickets,
        account,
        [START_DATE_NAME]: startDate,
        [END_DATE_NAME]: endDate,
        search,
        history: {
            location: { state },
        },
        dispatch,
        isListView,
        isRestrictedCustomer,
    } = props;
    const wrapper = PAGE_GLOBAL_STYLES();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();
    const [ticketsFilters, setTicketsFilters] = useState();
    const [isForceUpdateDates, setIsForceUpdateDates] = useState(false);
    const [bulkReconcileModal, setShowBulkReconcileModal] = useState(false);
    const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [needReloadTickets, setNeedReloadTickets] = useState(false);
    const [needLoadNewTickets, setNeedLoadNewTickets] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [errorMessage, setError] = useState(null);
    const defaultAdditionalExternalRef = useSelector(selectDefaultAdditionalExternalRef);
    const createTicketFormInitialValues = defaultAdditionalExternalRef
        ? {
              additionalExternalRefs: [
                  {
                      label: { value: defaultAdditionalExternalRef.id, label: defaultAdditionalExternalRef.name },
                      fieldId: _.uniqueId(),
                      isDefault: defaultAdditionalExternalRef.isDefault,
                  },
              ],
          }
        : {};

    const handleDownloadTicketsPictures = () => {
        const { [END_DATE_NAME]: endDate, [START_DATE_NAME]: startDate } = props;
        const params = {
            ...ticketsFilters,
            [START_DATE_NAME]: moment(startDate).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: moment(endDate).format(SERVER_DATE_FORMAT),
        };
        if (_.isArray(params.keywords)) {
            params.keywords = generatePredictiveKeywordParam(params.keywords);
        }

        setLoading(true);

        downloadTicketsTicPicks(params).finally(() => {
            setLoading(false);
        });
    };

    const handleDateChange = (date, name, datesAreSame) => {
        const { [END_DATE_NAME]: endDate, [START_DATE_NAME]: startDate } = props;
        let newDates = {
            [END_DATE_NAME]: endDate,
            [START_DATE_NAME]: startDate,
        };

        if (moment(date).format() === "Invalid date") {
            return false;
        }
        // if date was changed in ordinary way we should use it
        if (name) {
            newDates[name] = date;
        }

        if (datesAreSame) {
            newDates = {
                [END_DATE_NAME]: date,
                [START_DATE_NAME]: date,
            };
        }

        setTicketsFilters({
            ...(ticketsFilters || {}),
            ...newDates,
        });
    };

    const updateListByFilters = (filters, clearFilters) => {
        const localClearFilters = clearFilters || _.isEmpty(filters);

        if (!localClearFilters) {
            setTicketsFilters({
                keywords: search,
                ...filters,
            });
        } else {
            setTicketsFilters({
                ...filters,
                keywords: search,
                [START_DATE_NAME]: startDate,
                [END_DATE_NAME]: endDate,
            });
        }
    };

    const onErrorClose = () => {
        setError(null);
        setIsHandledSuccessfully(null);
    };

    const resetDatesToDefault = () => {
        const startDate = moment().format(SERVER_DATE_FORMAT);
        const endDate = moment().format(SERVER_DATE_FORMAT);

        dispatch(change(form, START_DATE_NAME, startDate));
        dispatch(change(form, END_DATE_NAME, endDate));

        setIsForceUpdateDates(true);
    };

    useEffect(() => {
        const initialFilters = state && state.initialFilters;

        if (initialFilters && initialFilters[END_DATE_NAME] && initialFilters[START_DATE_NAME]) {
            const endDate = initialFilters[END_DATE_NAME];
            const startDate = initialFilters[START_DATE_NAME];
            dispatch(change(form, END_DATE_NAME, endDate));
            dispatch(change(form, START_DATE_NAME, startDate));
        }
    }, [state]);

    useEffect(() => {
        const startValue = state ? state[START_DATE_NAME] : startDate;
        const endValue = state ? state[END_DATE_NAME] : endDate;

        dispatch(change(form, START_DATE_NAME, startValue));
        dispatch(change(form, END_DATE_NAME, endValue));
    }, []);

    return (
        <div className={clsx("tickets-wrap", isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {errorMessage && <ErrorNotification error={errorMessage} config={{ onClose: onErrorClose }} />}
            {isHandledSuccessfully && <SuccessNotification message="Success" config={{ onClose: onErrorClose }} />}
            <Container className={wrapper.globalPageStyles}>
                <Grid container component="div">
                    <Grid item xs={12} component="div">
                        <div className={pageActionsStyles.pageActions}>
                            <form noValidate={true}>
                                <Grid container component="div" alignItems={"center"}>
                                    <Grid item xs={4} component="div">
                                        <DatesRange
                                            form={form}
                                            isForceSaveDates={isForceUpdateDates}
                                            handleForceSaveDates={() => setIsForceUpdateDates(false)}
                                            handleEndDateChange={handleDateChange}
                                            handleStartDateChange={handleDateChange}
                                        />
                                    </Grid>
                                    <Grid item xs={8} component="div">
                                        <Box className={pageActionsStyles.headerBox}>
                                            {!IS_INSPECTOR_USER(account.role) && !isRestrictedCustomer && (
                                                <ActionLink>
                                                    <AddCircleOutlineIcon />
                                                    <SimpleLink onClick={() => setShowCreateTicketModal(true)}>
                                                        Create new Ticket
                                                    </SimpleLink>
                                                </ActionLink>
                                            )}
                                            {!isRestrictedCustomer && (
                                                <ActionLink>
                                                    <RepeatIcon />
                                                    <SimpleLink
                                                        onClick={() =>
                                                            !_.isEmpty(tickets) && setShowBulkReconcileModal(true)
                                                        }
                                                    >
                                                        Bulk reconcile
                                                    </SimpleLink>
                                                </ActionLink>
                                            )}
                                            <Box sx={{ minWidth: "55%" }}>
                                                <TicketFilters
                                                    ticketsFilters={ticketsFilters}
                                                    form={form}
                                                    updateListByFilters={updateListByFilters}
                                                    resetToDefaultHandler={resetDatesToDefault}
                                                    clearSelectedFilters={!!search}
                                                />
                                            </Box>
                                            {!isRestrictedCustomer && (
                                                <TicketsReportLink form={form} ticketsFilters={ticketsFilters} />
                                            )}
                                            {!isRestrictedCustomer && (
                                                <div
                                                    title="Download Tickets Ticpics"
                                                    onClick={handleDownloadTicketsPictures}
                                                >
                                                    <PhotoLibraryIcon className="action-icons" />
                                                </div>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <TicketsList
                ticketsFilters={ticketsFilters}
                isListView={isListView}
                reloadList={needReloadTickets}
                form={TICKETS_FILTERS_FORM}
                needReloadTickets={setNeedReloadTickets}
                loadNewTickets={needLoadNewTickets}
                needLoadNewTickets={setNeedLoadNewTickets}
                onlyView={isRestrictedCustomer}
            />
            <AppModal
                isOpen={showCreateTicketModal}
                form={CREATE_TICKET_FORM}
                closeModal={() => setShowCreateTicketModal(false)}
                modalStyles={{ width: 500 }}
            >
                <CreateTicketForm
                    closeModal={() => setShowCreateTicketModal(false)}
                    form={CREATE_TICKET_FORM}
                    loadTickets={() => setNeedReloadTickets(true)}
                    initialValues={createTicketFormInitialValues}
                />
            </AppModal>
            <AppModal
                isOpen={bulkReconcileModal}
                closeModal={() => setShowBulkReconcileModal(false)}
                form={RECONCILE_TICKET_FORM}
                showCloseButton={true}
            >
                <BulkReconcileForm
                    closeModal={() => setShowBulkReconcileModal(false)}
                    needLoadNewTickets={() => setNeedLoadNewTickets(true)}
                />
            </AppModal>
        </div>
    );
}

TicketsWrapper.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    tickets: PropTypes.array.isRequired,
    isListView: PropTypes.bool.isRequired,
    isRestrictedCustomer: PropTypes.bool.isRequired,
};

TicketsWrapper.defaultProps = {
    isListView: false,
};

export const TICKETS_FILTERS_FORM = "ticketsFiltersForm";

export default withRouter(
    compose(
        reduxForm({
            form: TICKETS_FILTERS_FORM,
        }),
        connect((state) => {
            return {
                tickets: state.tickets.tickets,
                account: state.account,
                isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
            };
        }),
    )(formValues(START_DATE_NAME, END_DATE_NAME, "search")(TicketsWrapper)),
);
