import {
    ADD_NEW_USER_REGION,
    DELETE_USER_REGION,
    LOGOUT_USER,
    REQUEST_COMPANY_REGIONS,
    REQUEST_COMPANY_REGIONS_RESULT,
    REQUEST_UOM,
    REQUEST_UOM_ERROR,
    REQUEST_UOM_RESULT,
    REQUEST_WEATHER,
    REQUEST_WEATHER_RESULT,
    RESET_UOM_DATA,
    RESET_UOM_META,
    RESET_WEATHER,
    UPDATE_USER_REGION,
    SET_REPORTS_NAMES,
    REPORT_NAMES_REQUEST,
    REPORT_NAMES_ERROR,
    REPORT_NAMES_RESET_META,
    TRUCK_EVENTS_REQUEST,
    TRUCK_EVENTS_RESULT,
    TRUCK_EVENTS_RESET,
    TRUCK_EVENTS_ERROR,
    TRUCK_EVENTS_RESET_META,
    TRUCK_EVENTS_SET_SUSPEND_PERIOD,
} from "../events/globalEvents";

const initialState = {
    weather: {
        data: null,
        meta: {
            loading: false,
            error: null,
        },
    },
    unitOfMeasures: {
        data: null,
        meta: {
            error: null,
            loading: false,
        },
    },
    regions: {
        data: null,
        meta: {
            error: null,
            loading: false,
        },
    },
    reportNames: null,
    reportNamesMeta: {
        loading: false,
        error: null,
    },
    calendarEvents: {
        data: null,
        suspendPeriod: null,
        meta: {
            error: null,
            loading: false,
        },
    },
};

const constantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_UOM: {
            return {
                ...state,
                unitOfMeasures: {
                    ...state.unitOfMeasures,
                    meta: {
                        ...state.unitOfMeasures.meta,
                        loading: true,
                    },
                },
            };
        }
        case REQUEST_UOM_RESULT: {
            return {
                ...state,
                unitOfMeasures: {
                    ...state.unitOfMeasures,
                    data: action.payload,
                    meta: {
                        ...state.unitOfMeasures.meta,
                        loading: false,
                    },
                },
            };
        }
        case REQUEST_UOM_ERROR: {
            return {
                ...state,
                unitOfMeasures: {
                    ...state.unitOfMeasures,
                    meta: {
                        ...state.unitOfMeasures.meta,
                        error: action.payload,
                        loading: false,
                    },
                },
            };
        }
        case RESET_UOM_DATA: {
            return {
                ...state,
                unitOfMeasures: initialState.unitOfMeasures,
            };
        }
        case RESET_UOM_META: {
            return {
                ...state,
                unitOfMeasures: {
                    ...state.unitOfMeasures,
                    meta: initialState.unitOfMeasures.meta,
                },
            };
        }
        case REQUEST_COMPANY_REGIONS: {
            return {
                ...state,
                regions: {
                    ...state.regions,
                    meta: {
                        ...state.regions.meta,
                        loading: true,
                    },
                },
            };
        }
        case REQUEST_COMPANY_REGIONS_RESULT: {
            return {
                ...state,
                regions: {
                    ...state.regions,
                    data: action.payload,
                    meta: {
                        ...state.regions.meta,
                        loading: false,
                    },
                },
            };
        }
        case ADD_NEW_USER_REGION: {
            const newRegion = action.payload;

            newRegion.regionName = newRegion.name;

            const updatedRegionList = state?.regions?.data ? [...state.regions.data, newRegion] : [newRegion];

            return {
                ...state,
                regions: {
                    ...state.regions,
                    data: updatedRegionList,
                },
            };
        }
        case DELETE_USER_REGION: {
            const updatedRegionList = state?.regions?.data?.filter((region) => region.id !== action.payload);

            return {
                ...state,
                regions: {
                    ...state.regions,
                    data: updatedRegionList,
                },
            };
        }
        case UPDATE_USER_REGION: {
            const regionToUpdate = action.payload;
            regionToUpdate.regionName = regionToUpdate.name;

            const updatedRegionList = state?.regions?.data.map((region) => {
                if (region.id === regionToUpdate.id) {
                    return regionToUpdate;
                }

                return region;
            });

            return {
                ...state,
                regions: {
                    ...state.regions,
                    data: updatedRegionList,
                },
            };
        }
        case REQUEST_WEATHER: {
            return {
                ...state,
                weather: {
                    ...state.weather,
                    meta: {
                        ...state.weather.meta,
                        loading: true,
                    },
                },
            };
        }
        case REQUEST_WEATHER_RESULT: {
            return {
                ...state,
                weather: {
                    ...state.weather,
                    data: action.payload,
                    meta: {
                        ...state.weather.meta,
                        loading: false,
                    },
                },
            };
        }
        case RESET_WEATHER: {
            return {
                ...state,
                weather: initialState.weather,
            };
        }
        case REPORT_NAMES_REQUEST: {
            return {
                ...state,
                reportNamesMeta: {
                    ...state.reportNamesMeta,
                    loading: true,
                },
            };
        }
        case SET_REPORTS_NAMES: {
            return {
                ...state,
                reportNames: action.payload,
                reportNamesMeta: {
                    ...state.reportNamesMeta,
                    loading: false,
                },
            };
        }
        case REPORT_NAMES_ERROR: {
            return {
                ...state,
                reportNamesMeta: {
                    ...state.reportNamesMeta,
                    loading: false,
                    error: action.payload,
                },
            };
        }
        case REPORT_NAMES_RESET_META: {
            return {
                ...state,
                reportNamesMeta: initialState.reportNamesMeta,
            };
        }
        case TRUCK_EVENTS_REQUEST: {
            return {
                ...state,
                calendarEvents: {
                    ...state.calendarEvents,
                    meta: {
                        ...state.calendarEvents.meta,
                        loading: true,
                    },
                },
            };
        }
        case TRUCK_EVENTS_SET_SUSPEND_PERIOD: {
            return {
                ...state,
                calendarEvents: {
                    ...state.calendarEvents,
                    suspendPeriod: action.payload,
                    meta: {
                        ...state.calendarEvents.meta,
                        loading: false,
                    },
                },
            };
        }
        case TRUCK_EVENTS_RESULT: {
            return {
                ...state,
                calendarEvents: {
                    ...state.calendarEvents,
                    data: action.payload,
                    meta: {
                        ...state.calendarEvents.meta,
                        loading: false,
                    },
                },
            };
        }
        case TRUCK_EVENTS_RESET: {
            return {
                ...state,
                calendarEvents: initialState.calendarEvents,
            };
        }
        case TRUCK_EVENTS_ERROR: {
            return {
                ...state,
                calendarEvents: {
                    ...state.calendarEvents,
                    meta: {
                        ...state.calendarEvents.meta,
                        loading: false,
                        error: action.payload,
                    },
                },
            };
        }
        case TRUCK_EVENTS_RESET_META: {
            return {
                ...state,
                calendarEvents: {
                    ...state.calendarEvents,
                    meta: initialState.calendarEvents.meta,
                },
            };
        }
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};

export default constantsReducer;
