import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
    meta: {
        loading: false,
        error: null,
    },
};

const trailers = createSlice({
    name: "TRAILERS",
    initialState,
    reducers: {
        requestTrailers(state, action) {
            state.meta.loading = true;
        },
        resultTrailers(state, { payload }) {
            state.meta.loading = false;
            if (payload.error) {
                state.meta.error = payload.error.message;
            } else {
                state.data = payload;
            }
        },
        initialize(state, action) {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
    },
});

export const { actions } = trailers;

export const selectTrailers = (state) => state.trailers.data;
export const selectTrailersMeta = (state) => state.trailers.meta;

export default trailers.reducer;
