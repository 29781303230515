import {
    GET_COMPANY_TRUCKS_SUCCESS,
    IS_TRUCKS_LOADING,
    LIVE_MAP_TRUCKS_FAILED,
    LIVE_MAP_TRUCKS_LOADED,
    LIVE_MAP_TRUCKS_LOADING,
    RESET_COMPANY_TRUCKS,
    RESET_TRUCKS_DATA,
    SET_TRUCK_FOR_EDIT,
    TRUCKS_COORDINATES_UPDATED,
    TRUCKS_DIALOG_TOGGLE,
    UPDATE_ACTIVE_TRUCK_ORDER,
} from "../events/trucks";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    liveMapTrucks: [],
    liveMapTrucksLoading: false,
    liveMapTrucksError: null,
    liveMapTrucksCount: 0,
    truckDialogOpen: false,
    truckForEdit: null,
    trucksList: [],
    trucksCount: 1,
    activeTruckOrder: "name",
};

export const trucksReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_MAP_TRUCKS_LOADING:
            return {
                ...state,
                liveMapTrucksLoading: true,
                liveMapTrucksError: null,
            };
        case LIVE_MAP_TRUCKS_LOADED: {
            return {
                ...state,
                liveMapTrucks: action.payload.liveMapTrucks,
                liveMapTrucksLoading: false,
                liveMapTrucksError: null,
            };
        }
        case LIVE_MAP_TRUCKS_FAILED:
            return {
                ...state,
                liveMapTrucksLoading: false,
                liveMapTrucksError: action.payload.liveMapTrucksError,
            };
        case TRUCKS_COORDINATES_UPDATED:
            return {
                ...state,
                liveMapTrucks: state.liveMapTrucks.map((liveMapTruck) => {
                    if (liveMapTruck.truck.id === action.payload.id) {
                        return {
                            ...liveMapTruck,
                            truckCoordinates: {
                                ...liveMapTruck.truckCoordinates,
                                latitude: action.payload.latitude,
                                longitude: action.payload.longitude,
                            },
                        };
                    }

                    return liveMapTruck;
                }),
            };
        case TRUCKS_DIALOG_TOGGLE: {
            return {
                ...state,
                truckDialogOpen: action.open,
            };
        }
        case SET_TRUCK_FOR_EDIT: {
            return {
                ...state,
                truckForEdit: action.payload,
            };
        }

        case RESET_COMPANY_TRUCKS: {
            return {
                ...state,
                trucksList: [],
                trucksCount: 1,
            };
        }

        case GET_COMPANY_TRUCKS_SUCCESS: {
            return {
                ...state,
                trucksList: action.payload.trucksList,
                trucksCount: action.payload.trucksCount,
                isTrucksLoading: false,
            };
        }
        case IS_TRUCKS_LOADING: {
            return {
                ...state,
                isTrucksLoading: action.payload,
            };
        }
        case UPDATE_ACTIVE_TRUCK_ORDER: {
            const updatedOrderParam = action.payload;
            const activeTruckOrder = state.activeTruckOrder;
            if (activeTruckOrder && updatedOrderParam === activeTruckOrder) {
                return {
                    ...state,
                    activeTruckOrder: `-${updatedOrderParam}`,
                };
            }

            return {
                ...state,
                activeTruckOrder: updatedOrderParam,
            };
        }
        case RESET_TRUCKS_DATA:
            return {
                ...state,
                activeTruckOrder: "name",
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
