import React from "react";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import { TableSortLabel } from "@material-ui/core";

const TableHeaderSortingCell = ({
    sortParam,
    handleSort,
    align,
    label,
    minWidth,
    activeOrderParam,
    borderBottom,
    padding,
    className,
    direction,
}) => {
    return (
        <TableCell
            align={align}
            className={className}
            style={{
                minWidth: minWidth || 0,
                borderBottom: borderBottom,
                padding: padding,
            }}
            onClick={() => handleSort(sortParam)}
        >
            <TableSortLabel
                direction={direction ? direction : activeOrderParam?.includes("-") ? "desc" : "asc"}
                active={activeOrderParam?.replace("-", "") === sortParam}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    );
};

TableHeaderSortingCell.propTypes = {
    sortParam: PropTypes.string.isRequired,
    handleSort: PropTypes.func.isRequired,
    activeOrderParam: PropTypes.string,
    label: PropTypes.string.isRequired,
    align: PropTypes.string,
    minWidth: PropTypes.string,
};

export default TableHeaderSortingCell;
