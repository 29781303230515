import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import _ from "lodash";
import TruckRow from "./truckRow";
import TruckPillWrapper from "../../../core/pills";
import { TABLE_SECONDARY, TABLE_SECONDARY_SCROLLABLE } from "../../../../styles/reusableStyles";
import { JoliTablesStyles } from "../../jobOrder/jolis/jolisTable";

export const generateJolisForTrucksTable = (jolis) => {
    const allTrucks = [];
    const newJolis = jolis.map((joli) => {
        const { trucks, haulers, quantity, startDate, notAddressedTrucksCount } = joli;
        joli.trucks = trucks.map((i) => ({
            ...i,
            joliQuantity: quantity,
            isMyFleetTruck: true,
            startDate,
        }));

        allTrucks.push(...joli.trucks);

        joli.haulers = haulers.map((hauler) => {
            return {
                ...hauler,
                trucks: hauler.trucks,
                joliQuantity: quantity,
                isMyFleetTruck: false,
                startDate,
                notAddressedTrucksCount,
            };
        });

        allTrucks.push(...joli.haulers);

        return joli;
    });

    return [newJolis, allTrucks];
};

const TrucksTable = (props) => {
    const tableStyles = TABLE_SECONDARY();
    const joliTablesStyles = JoliTablesStyles();
    const tableScrollableStyles = TABLE_SECONDARY_SCROLLABLE();
    const { showQty, jolis, isLimitedHaulers, jobOrder } = props;

    return (
        <div className={clsx(tableStyles.secondaryTable, tableScrollableStyles.scrolableTable)}>
            <div className="tr">
                <div className="th flex-2">Trucks</div>
                <div className="th">Starting</div>
                {showQty && <div className="th">Quantity</div>}
            </div>
            <div className={clsx("tbody", "-scroll")} style={{ maxHeight: 520 }}>
                {!_.isEmpty(jolis) &&
                    jolis.map((joli, index) => {
                        const { notAddressedTrucksCount } = joli;
                        const haulers = isLimitedHaulers
                            ? _.cloneDeep(joli.haulers).filter((i) => !_.isEmpty(i.trucks))
                            : joli.haulers;

                        return (
                            <React.Fragment key={index}>
                                {joli.trucks.map((truck, index) => {
                                    return (
                                        <TruckRow
                                            item={truck}
                                            showQty={showQty}
                                            jobOrder={jobOrder}
                                            allItems={joli.trucks}
                                            trailers={joli.trailers}
                                        />
                                    );
                                })}
                                {!!notAddressedTrucksCount && (
                                    <div className="tr">
                                        <div className="td  flex-2">
                                            <TruckPillWrapper
                                                className={clsx(joliTablesStyles.trucksHeader)}
                                                showIcon={false}
                                                iconContent={<div className={joliTablesStyles.truckIcon} />}
                                                title="Total Trucks Needed"
                                                allowButtons={false}
                                                specifyNumberOfTrucks={true}
                                                needTrucksCount={+notAddressedTrucksCount}
                                                truckName="Total Trucks Needed"
                                                isMyFleet={false}
                                            />
                                        </div>
                                        {showQty && <div className="td" />}
                                    </div>
                                )}
                                {haulers.map((hauler, index) => {
                                    return (
                                        <TruckRow
                                            item={hauler}
                                            allItems={haulers}
                                            showQty={showQty}
                                            jobOrder={jobOrder}
                                            specifyTrucksPerHauler={!!notAddressedTrucksCount}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
            </div>
        </div>
    );
};

TrucksTable.propTypes = {
    showQty: PropTypes.bool.isRequired,
    jolis: PropTypes.array,
    isLimitedHaulers: PropTypes.bool.isRequired,
    jobOrder: PropTypes.object.isRequired,
};

TrucksTable.defaultProps = {
    isLimitedHaulers: false,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(TrucksTable),
);
