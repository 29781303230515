import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { arrayPush, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import _ from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../../core/form/textInputComponent";
import ErrorNotification from "../../core/notification";
import Loader from "../../core/loader";
import CloseIcon from "@material-ui/icons/Close";
import { PrimaryButton } from "../../core/buttons/primaryButton";

import { ticketsListReplaceTicket } from "../../../actions/tickets";
import { reconcileAirTicket, reconcileTicket, uploadTicPic } from "../../../dataServers/tickets";
import { formatReduxFormErrors } from "../../../helpers/errorFormatting";
import { needToSetLoadsCount, needToSetWeight } from "../../../helpers/tickets";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { Validation } from "../../../helpers/validation";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import {
    IS_ADMIN_USER,
    IS_INSPECTOR_USER,
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RECONCILED,
    UNIT_OF_MEASURE_HOUR,
} from "../../../constants/maps";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import CustomSwitch from "../../core/form/customSwitch";
import UnitOfMeasure from "../../core/unitOfMeasure";
import PoliAdditionalFields from "../../global/poliAdditionalFields";
import { useTicketExists } from "../../../hooks/useTicketExists";
import ExistedTicketWarning from "./ExistedTicketWarning";
import { selectHasAdditionalExternalRefs } from "../../../reducers/additionalExternalRefsReducer";
import AdditionalExternalRefs from "./additionalExternalRefs";
import { generateAdditionalExternalRefsPUTData } from "../../../helpers/additionalExternalRefsHelpers";

const useStyles = makeStyles((theme) => ({
    textContentContainer: { marginBottom: 15 },
    textLabel: {
        marginRight: 7,
        fontWeight: "bold",
    },
    additionalFieldsItem: {
        "&:not(:last-child)": { marginRight: 15 },
    },
}));

export const showReconcileOption = ({ isAirTicketLite, canBeReconciledByUser }) => {
    if (isAirTicketLite) {
        return true;
    }
    return canBeReconciledByUser;
};

export const isTicketCannotBeReconciled = ({ status }, account) => {
    return status === TICKET_STATUS_OPEN || IS_INSPECTOR_USER(account.role);
};

function renderEntries({ fields, label, disabled }) {
    const removeEntry = (index) => {
        fields.remove(index);
    };

    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <Grid item key={index}>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={!disabled ? 5 : 6}>
                            <Field
                                type="text"
                                disabled={disabled}
                                name={`${field}.number`}
                                label={label}
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                type="text"
                                name={`${field}.quantity`}
                                disabled={disabled}
                                label="Quantity"
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                        {!disabled && (
                            <Grid item container alignItems={"center"} justify={"center"} xs={1}>
                                <CloseIcon
                                    color={"action"}
                                    fontSize={"default"}
                                    cursor={"pointer"}
                                    onClick={() => removeEntry(index)}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
}

renderEntries.propTypes = {
    fields: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

function ReconcileTicketForm(props) {
    const {
        addEmptyEntry,
        ticket,
        initialValues,
        ticketsListReplaceTicket,
        handleSubmit,
        handleSuccess,
        account,
        changedTicPic,
        setIsCropImage,
        error,
        formValues: externalRef,
        hasAdditionalExternalRefs,
        form,
    } = props;
    const {
        isAirTicketLite,
        additionalQuantity,
        id,
        primaryUom,
        joLineItem,
        perUnitPrice,
        additionalFields,
        isSubticket,
        grantedAccess,
        totalPrice,
        status,
        externalRef: initialExtRef,
        unitOfMeasure,
    } = ticket;
    const project = joLineItem?.project;
    const [isLoading, setLoading] = useState(false);
    const bidItemFieldType = "bidItems",
        phaseCodeFieldType = "phaseCodes";
    const disabled = isAirTicketLite ? status === TICKET_STATUS_RECONCILED : !showReconcileOption(ticket);
    const hourTicket = primaryUom === UNIT_OF_MEASURE_HOUR;
    const isBulkReconcileForm = !!setIsCropImage;
    const classes = useStyles();
    const exists = useTicketExists(externalRef, project?.id, project?.createDate, id, initialExtRef);
    const showAdditionalExternalRefs = hasAdditionalExternalRefs && !hourTicket && !isSubticket && !initialExtRef;

    const loadEntryOptions = () => {
        return Promise.resolve([
            {
                name: "Bid Item",
                type: bidItemFieldType,
            },
            {
                name: "Phase code",
                type: phaseCodeFieldType,
            },
        ]).then((data) => {
            const results = data.map((i, index) => ({
                ...i,
                value: index,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    const onSubmit = ({
        quantity,
        hours,
        minutes,
        externalRef,
        phaseCodes,
        bidItems,
        isSaveCrop,
        isDuplicate,
        additionalExternalRefs,
    }) => {
        const { hours: initialHours, minutes: initialMinutes } = initialValues;
        setLoading(true);

        const data = {
            quantity: !hourTicket
                ? quantity
                : // do not calc qty if nothing was changed
                +initialHours === +hours && +initialMinutes === +minutes
                ? quantity
                : +hours + +minutes / 60,
            externalRef: externalRef,
        };

        if (!isAirTicketLite) {
            data.phaseCode = {};
            data.isDuplicate = isDuplicate;

            if (phaseCodes) {
                data.phaseCode.phaseCodes = phaseCodes.reduce((result, field) => {
                    result[field.number] = field.quantity;

                    return { ...result };
                }, {});
            }

            if (bidItems) {
                data.phaseCode.bidItems = bidItems.reduce((result, field) => {
                    result[field.number] = field.quantity;

                    return { ...result };
                }, {});
            }

            if (!_.isEmpty(additionalExternalRefs)) {
                delete data.externalRef;
                data.additionalExternalRef = generateAdditionalExternalRefsPUTData(additionalExternalRefs);
            }
            if (needToSetWeight(ticket)) {
                data.weight = additionalQuantity;
            }
            if (needToSetLoadsCount(ticket)) {
                data.loadsCount = additionalQuantity;
            }

            if (IS_ADMIN_USER(account.company.id)) {
                data.ignoreChange = true;
            }
        }

        const requests = [];

        if (isSaveCrop && changedTicPic) {
            requests.push(uploadTicPic(ticket.id, changedTicPic));
        }

        const request = isAirTicketLite ? reconcileAirTicket : reconcileTicket;

        return Promise.all([request(id, data), ...requests])
            .then(([response]) => {
                setLoading(false);
                ticketsListReplaceTicket(response.data);
                handleSuccess && handleSuccess();
            })
            .catch((errorResponse) => {
                setLoading(false);
                formatReduxFormErrors(errorResponse);
            });
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                <React.Fragment>
                    <Grid container spacing={2} className={classes.textContentContainer}>
                        <Grid item xs={7}>
                            {(!!totalPrice || totalPrice === 0) && !isSubticket && !grantedAccess && (
                                <Grid item xs={12} container alignItems={"center"}>
                                    <span className={classes.textLabel}>Total Price:</span>
                                    <span className="--green-text">${totalPrice.toFixed(2)}</span>
                                </Grid>
                            )}
                            {!isSubticket && !grantedAccess && perUnitPrice && (
                                <Grid item xs={12} container alignItems={"center"}>
                                    <span className={classes.textLabel}>Price per Unit:</span>
                                    <span className="--green-text">${perUnitPrice.toFixed(2)}</span>
                                    &nbsp;/&nbsp;
                                    <UnitOfMeasure value={unitOfMeasure.name} isPlural={true} />
                                </Grid>
                            )}
                            {!IS_INSPECTOR_USER(account.role) && !_.isEmpty(additionalFields) && (
                                <Grid item xs={12} container>
                                    <span className={classes.textLabel}>Additional fields:</span>
                                    <Grid item xs={12} container>
                                        <PoliAdditionalFields
                                            className={classes.additionalFieldsItem}
                                            additionalFields={additionalFields}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {!ticket.isAirTicketLite && (
                            <Grid container item xs={5} alignItems="center" justify="flex-end">
                                <Field
                                    component={CustomSwitch}
                                    name="isDuplicate"
                                    label="Is Duplicate"
                                    type="checkbox"
                                    disabled={disabled}
                                />
                            </Grid>
                        )}
                    </Grid>
                </React.Fragment>
                <Grid container align-items={"center"} spacing={1}>
                    {showAdditionalExternalRefs ? (
                        <Grid item xs={12}>
                            <FieldArray
                                name="additionalExternalRefs"
                                form={form}
                                component={AdditionalExternalRefs}
                                disabled={disabled}
                            />
                            <br />
                        </Grid>
                    ) : (
                        <Grid item xs={hourTicket ? 12 : 6}>
                            <Field
                                type="text"
                                validate={[Validation.required]}
                                disabled={disabled}
                                name="externalRef"
                                label="Ticket Number"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                component={TextInputComponent}
                            />
                            <ExistedTicketWarning exists={exists} />
                        </Grid>
                    )}

                    <Grid item xs={hourTicket || showAdditionalExternalRefs ? 12 : 6}>
                        {hourTicket ? (
                            <React.Fragment>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            type="number"
                                            validate={[Validation.required]}
                                            disabled={disabled}
                                            needShowEndAdornment={true}
                                            endAdornment={"HOURS"}
                                            name="hours"
                                            label="Quantity"
                                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                            component={TextInputComponent}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            type="number"
                                            validate={[Validation.required]}
                                            disabled={disabled}
                                            name="minutes"
                                            label="Quantity"
                                            needShowEndAdornment={true}
                                            endAdornment={"MINUTES"}
                                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                            component={TextInputComponent}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <Field
                                type="number"
                                disabled={disabled}
                                name="quantity"
                                label="Quantity"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                endAdornment={`${unitOfMeasure.name}s`.toUpperCase()}
                                component={TextInputComponent}
                            />
                        )}
                    </Grid>
                </Grid>
                <br />
                {isBulkReconcileForm && !disabled && !!ticket.ticPicsCount && (
                    <React.Fragment>
                        <Field
                            name="isSaveCrop"
                            label="Save cropped Image"
                            type="checkbox"
                            disabled={disabled}
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            onChange={(e) => setIsCropImage(e.target.checked)}
                            component={CustomSwitch}
                        />
                        <br />
                        <br />
                    </React.Fragment>
                )}
                {!ticket.isAirTicketLite && (
                    <React.Fragment>
                        {!disabled && (
                            <Grid container>
                                <Field
                                    name="entry"
                                    label="Add Entry"
                                    disabled={disabled}
                                    className="form-view"
                                    loadOptions={loadEntryOptions}
                                    onChange={(value) => addEmptyEntry(value.type)}
                                    component={AsyncAutocompleteComponent}
                                />
                            </Grid>
                        )}
                        <FieldArray
                            name={bidItemFieldType}
                            disabled={disabled}
                            rerenderOnEveryChange
                            component={renderEntries}
                            label="Bid Item"
                        />
                        <FieldArray
                            name={phaseCodeFieldType}
                            disabled={disabled}
                            rerenderOnEveryChange
                            component={renderEntries}
                            label="Phase Code"
                        />
                        <br />
                    </React.Fragment>
                )}
                <ButtonsGroup>
                    <SecondaryButton type="button" onClick={() => handleSuccess && handleSuccess(true)}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={disabled}>
                        Reconcile
                    </PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} />}
        </div>
    );
}

ReconcileTicketForm.propTypes = {
    addEmptyEntry: PropTypes.func.isRequired,
    ticket: PropTypes.object.isRequired,
    changedTicPic: PropTypes.object,
    setIsCropImage: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    ticketsListReplaceTicket: PropTypes.func.isRequired,
    error: PropTypes.any,
};

export const RECONCILE_TICKET_FORM = "reconcileTicketForm";

// todo get ticket data from {}
export default compose(
    connect(
        (state, { ticket }) => {
            const {
                primaryUom,
                isAirTicketLite,
                ticket: ticketNumber,
                externalRef,
                netTons,
                primaryQuantity,
                secondaryQuantity,
                phaseCode,
                isDuplicate,
                unitOfMeasure,
                additionalExternalRef,
            } = ticket;
            const hourTicket = primaryUom === UNIT_OF_MEASURE_HOUR;
            const formSelector = formValueSelector(RECONCILE_TICKET_FORM);

            return {
                account: state.account,
                unitOfMeasure,
                initialValues: {
                    isSaveCrop: false,
                    externalRef: isAirTicketLite ? ticketNumber : externalRef,
                    quantity: isAirTicketLite ? netTons : primaryQuantity || secondaryQuantity,
                    hours: hourTicket ? Math.trunc(primaryQuantity) : null,
                    minutes: hourTicket ? ((primaryQuantity - Math.trunc(primaryQuantity)) * 60).toFixed(0) : null,
                    bidItems:
                        phaseCode &&
                        phaseCode.bidItems &&
                        Object.entries(phaseCode.bidItems).map((i) => ({
                            number: i[0],
                            quantity: i[1],
                        })),
                    phaseCodes:
                        phaseCode &&
                        !_.isEmpty(phaseCode.phaseCodes) &&
                        phaseCode.phaseCodes.map((i) => ({
                            number: i.code,
                            quantity: i.qty,
                        })),
                    isDuplicate: !!isDuplicate,
                    additionalExternalRefs:
                        additionalExternalRef &&
                        additionalExternalRef.map((ref) => ({
                            label: {
                                value: ref.label.id,
                                label: ref.label.name,
                            },
                            isDefault: ref.label.isDefault,
                            externalRef: ref.externalRef,
                            id: ref.id,
                        })),
                },
                formValues: formSelector(state, "externalRef"),
                hasAdditionalExternalRefs: selectHasAdditionalExternalRefs(state),
            };
        },
        (dispatch) => ({
            ticketsListReplaceTicket: (payload) => {
                dispatch(ticketsListReplaceTicket(payload));
            },
            addEmptyEntry: (fieldType) => {
                dispatch(arrayPush(RECONCILE_TICKET_FORM, fieldType, {}));
            },
        }),
    ),
    reduxForm({
        form: RECONCILE_TICKET_FORM,
        enableReinitialize: true,
    }),
)(ReconcileTicketForm);
