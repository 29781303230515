import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

import { THEME_ICONS } from "../../styles/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    truckRatio: {
        fontSize: 12,
        lineHeight: "20px",
        fontWeight: theme.typography.fontWeightMedium,
    },
    uiIconGeneralTruck: {
        ...THEME_ICONS.generalTruck,
    },
}));

const TrucksRatio = (props) => {
    const classes = useStyles();
    const { component, assignedTrucks, requestedTrucks, iconLeftMargin, showIcon, className, justifyContent } = props;

    return (
        <Box
            component={component}
            display="flex"
            alignItems="center"
            justifyContent={justifyContent}
            className={clsx(classes.truckRatio, className)}
        >
            {assignedTrucks}
            {` / `}
            {requestedTrucks}
            {showIcon && <i className={classes.uiIconGeneralTruck} style={{ marginLeft: iconLeftMargin }} />}
        </Box>
    );
};

TrucksRatio.propTypes = {
    component: PropTypes.string.isRequired,
    iconLeftMargin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showIcon: PropTypes.bool.isRequired,
    assignedTrucks: PropTypes.number,
    requestedTrucks: PropTypes.number,
    className: PropTypes.string,
    justifyContent: PropTypes.string,
};

TrucksRatio.defaultProps = {
    component: "div",
    justifyContent: "center",
    iconLeftMargin: "10px",
    showIcon: true,
};

export default TrucksRatio;
