import dataProvider from "../helpers/api";
import { MY_TEAM, USER } from "../constants/endpoints";

export const createTeamUser = (userData) => {
    return dataProvider({
        url: USER.ALL(),
        method: "POST",
        data: userData,
    });
};

export const editUser = (userData, userId) => {
    return dataProvider({
        url: MY_TEAM.EDIT_USER(userId),
        method: "PUT",
        data: {
            ...userData,
            company: undefined,
        },
    });
};

export const archiveUser = (userId) => {
    return dataProvider({
        url: MY_TEAM.ARCHIVE_USER(userId),
        method: "DELETE",
    });
};

export const unArchiveUser = (userId) => {
    return dataProvider({
        url: MY_TEAM.UNARCHIVE_USER(userId),
        method: "POST",
    });
};

export const sendUserMessage = (userId, data) => {
    return dataProvider({
        url: MY_TEAM.SEND_USER_MESSAGE(userId),
        method: "POST",
        data,
    });
};
export const sendMessages = (data) => {
    return dataProvider({
        url: MY_TEAM.SEND_MESSAGES(),
        method: "POST",
        data,
    });
};
