import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { compose } from "redux";
import moment from "moment";

import TextInputComponent from "../../core/form/textInputComponent";
import Loader from "../../core/loader";
import ErrorNotification from "../../core/notification";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import CustomSwitch from "../../core/form/customSwitch";
import CustomCheckbox from "../../core/form/customCheckbox";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../../core/form/asyncAutocompleteComponent";
import { createManualTicket, removeTicPics, uploadTicPic } from "../../../dataServers/tickets";
import { getJobBoardJobOrderJoli, getJobOrders } from "../../../dataServers/jobOrder";
import {
    PROJECT_STATUS_ACTIVE,
    TICKET_USAGE_TYPE_STANDALONE,
    UNIT_OF_MEASURE_HOUR,
    UNIT_OF_MEASURE_LOAD,
    UNIT_OF_MEASURE_TON,
} from "../../../constants/maps";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { Validation, VALIDATION_ERRORS } from "../../../helpers/validation";
import { getProjects } from "../../../dataServers/projects";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import clsx from "clsx";
import TicpickDrop from "./ticpickDrop";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import UnitOfMeasure from "../../core/unitOfMeasure";
import { ROUTE_JOBS } from "../../../routes/globalRoutes";
import { getCompanyTrucks } from "../../../dataServers/trucks";
import TruckDialog from "../../trucks/truckDialog";
import {
    getDrivers as getAllDrivers,
    getDriversWithLinkEvents,
    handleTruckDialogToggle,
} from "../../../actions/trucks";
import { selectMeasureSystem } from "../../../selectors/index";
import { CREATE_NEW_TICKET_DRIVER_WARNING_MESSAGE } from "../constants/strings";
import { useTicketExists } from "../../../hooks/useTicketExists";
import ExistedTicketWarning from "./ExistedTicketWarning";
import { selectUnitOfMeasureName } from "../../../reducers/unitsOfMeasureReducer";
import AdditionalExternalRefs from "./additionalExternalRefs";
import { selectHasAdditionalExternalRefs } from "../../../reducers/additionalExternalRefsReducer";

const useStyles = makeStyles(() => ({
    createTicketFormContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",

        "& .form-label-bold": {
            color: "#393939",
            fontSize: "12px",
            fontWeight: "500",
            marginLeft: "11.45px",
            marginBottom: "7px",
        },
        "& .form-label-medium": {
            fontWeight: "normal",
            fontSize: "11px",
        },
    },
    gridXS: {
        width: "100%",
    },
}));

const validate = (values) => {
    const { jobOrder, truck, quantity, files } = values;
    const errors = {};

    if (
        files &&
        files.find(
            (i) =>
                i.type !== "image/jpg" &&
                i.type !== "image/jpeg" &&
                i.type !== "image/png" &&
                i.type !== "application/pdf",
        )
    ) {
        errors.files = VALIDATION_ERRORS.fileFormat;
    }

    // https://github.com/TruckITllc/truckit-frontend/issues/1125#issuecomment-744161517
    if (
        jobOrder &&
        truck &&
        (jobOrder.unitOfMeasure === UNIT_OF_MEASURE_TON ||
            (jobOrder.ticketsType === TICKET_USAGE_TYPE_STANDALONE &&
                jobOrder.unitOfMeasure === UNIT_OF_MEASURE_LOAD) ||
            (jobOrder.unitOfMeasure === UNIT_OF_MEASURE_HOUR &&
                !_.isEmpty(truck.parentTickets) &&
                jobOrder.ticketsType === TICKET_USAGE_TYPE_STANDALONE)) &&
        truck &&
        truck.capacity &&
        quantity &&
        +quantity > +truck.capacity
    ) {
        errors.quantity = VALIDATION_ERRORS.trucksCapacity;
    }

    return errors;
};

const Drivers = (props) => {
    const { nothing, warning, field, form, truck, jobOrder } = props;

    const viewAll = useSelector((state) => formValueSelector(form)(state, "viewAll"));

    const truckInfo = truck?.label ? `linked to ${truck.label}` : "";
    const dateInfo = jobOrder?.startDate && truck?.label ? `on ${moment(jobOrder.startDate).format("MM/DD/YYYY")}` : "";

    const placeholder = viewAll ? "Drivers" : `Drivers ${truckInfo} ${dateInfo}`;

    if (nothing) return null;
    if (warning) {
        return (
            <React.Fragment>
                <Alert severity="warning">{CREATE_NEW_TICKET_DRIVER_WARNING_MESSAGE}</Alert>
                <br />
            </React.Fragment>
        );
    }

    const getDrivers = (inputValue, params) =>
        getDriversWithLinkEvents(inputValue, params, {
            truckId: truck?.value,
            date: moment(jobOrder.startDate).format("YYYY-MM-DD"),
        });

    if (field) {
        return (
            <>
                <Box>
                    <Field
                        name="driver"
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        loadOptions={viewAll || !truck ? getAllDrivers : getDrivers}
                        placeholder={placeholder}
                        component={AsyncAutocompleteComponent}
                        clearAfterCloseMenu
                    />
                    {truck && (
                        <Field
                            name="viewAll"
                            type="checkbox"
                            label="View all drivers of my company"
                            style={{ marginTop: 5 }}
                            component={CustomCheckbox}
                        />
                    )}
                </Box>
                <br />
            </>
        );
    }

    return null;
};

Drivers.propTypes = {
    nothing: PropTypes.bool.isRequired,
    warning: PropTypes.bool.isRequired,
    field: PropTypes.bool.isRequired,
};

function CreateTicketForm(props) {
    const {
        handleSubmit,
        closeModal,
        handleTicketCreate,
        loadTickets,
        ticket,
        form,
        history,
        dispatch,
        jobOrderHasHaulers,
        formValues: { project, jobOrder, truck, isSubticket, hauler, externalRef },
    } = props;
    const { id, unitOfMeasure: selectedTicketUnitOfMeasure } = ticket;
    const disabled = !!id;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [assignedHaulers, setAssignedHaulers] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [truckHasParentTicket, setTruckHasParentTicket] = useState(null);
    const [unitOfMeasure, setUnitOfMeasure] = useState();
    const exists = useTicketExists(externalRef, project?.id || project?.value, project?.createDate, id, null, disabled);
    const unitOfMeasureName = useSelector((state) => selectUnitOfMeasureName(state, unitOfMeasure));
    const hasAdditionalExternalRefs = useSelector(selectHasAdditionalExternalRefs);

    const currentFormUnitOfMeasure = unitOfMeasure || selectedTicketUnitOfMeasure?.id;
    const isHourlyTicketForm = currentFormUnitOfMeasure && currentFormUnitOfMeasure === UNIT_OF_MEASURE_HOUR;

    const isJobsTickets = history.location.pathname.includes(ROUTE_JOBS.TICKETS);
    const selectedHaulerData =
        assignedHaulers.length > 0 && hauler ? assignedHaulers.find((i) => i.id === hauler.value) : null;
    const haulerUseTruckIT = selectedHaulerData?.useTruckIT;

    const setJobOrder = (jobId, unitOfMeasure) => {
        setSelectedJob(jobId);
        setUnitOfMeasure(unitOfMeasure);
        dispatch(change(form, "truck", null));
        setTruckHasParentTicket(false);
    };

    const loadJobOrders = (inputValue, { params = {}, loadedCount } = {}) => {
        return getJobOrders({
            keywords: inputValue,
            project: project.id,
            ...params,
            dropdown: true,
            withTrucks: false,
        }).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: `#${i.id}${i.extRef ? ` / Ext. Ref #${i.extRef}` : ""} ${
                    i.customerName ? ` - ${i.customerName}` : ""
                }${i.projectName ? `- ${i.projectName}` : ""}`,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadProjects = (inputValue, { params = {}, loadedCount } = {}) => {
        return getProjects({
            keywords: inputValue,
            ...params,
            dispatch: true,
            dropdown: true,
            status: PROJECT_STATUS_ACTIVE.toString(),
        }).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadHaulersForJobOrder = (inputValue, { params = {}, loadedCount }) => {
        const results = assignedHaulers.map(({ name, id, joLineItemId, useTruckIT }) => ({
            value: id,
            label: name,
            joLineItemId,
            useTruckIT,
        }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    const loadTrucksForJobOrder = (inputValue, { params = {}, loadedCount }) => {
        if (hauler) {
            const selectedHauler = assignedHaulers.find((i) => i.id === hauler.value);
            if (selectedHauler.useTruckIT) {
                const results = selectedHauler.trucks.map((truck) => ({
                    ...truck,
                    value: truck.truckId,
                    label: truck.truckName,
                }));

                return Promise.resolve(results).then(() => {
                    return {
                        options: results,
                        hasMore: false,
                        page: 1,
                    };
                });
            } else {
                return getCompanyTrucks({ ...params, company: selectedHauler.id }).then((response) => {
                    const results = response.data.map((i) => ({
                        ...i,
                        value: i.id,
                        label: i.truckName,
                    }));

                    return {
                        options: results,
                        hasMore: response.meta.count > (loadedCount || results.length),
                        page: response.meta.page,
                    };
                });
            }
        }

        return getJobBoardJobOrderJoli(selectedJob, {
            ...params,
            dropdown: true,
        }).then(({ data }) => {
            const results = [];

            data.forEach((joli) => {
                if (!_.isEmpty(joli.trucks)) {
                    results.push(
                        ...joli.trucks.map((i) => ({
                            capacity: i.capacity,
                            parentTickets: i.parentTickets,
                            value: i.truckId,
                            label: i.truckName,
                            startDate: joli.startDate,
                            joLineItemId: joli.id,
                        })),
                    );
                }

                if (!_.isEmpty(joli.haulers)) {
                    let trucks = [];

                    _.cloneDeep(joli.haulers)
                        .filter((h) => !_.isEmpty(h.trucks))
                        .forEach((h) => {
                            trucks.push(
                                ...h.trucks.map((i) => ({
                                    capacity: i.capacity,
                                    parentTickets: i.parentTickets,
                                    value: i.truckId,
                                    label: i.truckName,
                                    startDate: joli.startDate,
                                    joLineItemId: joli.id,
                                    hauler: { label: h.name, value: h.id, useTruckIT: h.useTruckIT },
                                })),
                            );
                        });

                    results.push(...trucks);
                }
            });

            return {
                options: results,
                hasMore: false,
            };
        });
    };

    const handleSaveWrapper = (values) => {
        const {
            quantity,
            externalRef,
            truck,
            reconciled,
            parentId,
            files,
            filesToRemove,
            driver,
            isDuplicate,
            additionalExternalRefs,
        } = values;

        const createData = {
            externalRef: externalRef,
            isDuplicate: isDuplicate,
            joLineItemId: (truck && truck.joLineItemId) || (hauler && hauler.joLineItemId),
            quantity: parseFloat(quantity),
            reconcile: reconciled,
            truckId: truck && truck.value,
            parentId: parentId ? parentId.value : undefined,
        };

        if (hasAdditionalExternalRefs && !isHourlyTicketForm) {
            createData.additionalExternalRef = additionalExternalRefs.map((ref) => {
                return {
                    externalRef: ref.externalRef,
                    labelId: ref.label.value,
                };
            });
        }

        const handleTicketSave = (data) => {
            setSuccess([id ? "Ticket has been successfully updated" : "Ticket has been successfully saved"]);
            closeModal();
            setLoading(false);
            data && handleTicketCreate && handleTicketCreate(data);
            loadTickets && loadTickets();
        };

        if (!id) {
            if (!createData.joLineItemId) {
                setError(["JoLineItemId is required for save"]);

                return false;
            }

            if (hauler && hauler.useTruckIT === false) {
                createData.haulerId = hauler.value;
            }

            if (driver && driver.id) {
                createData.driverId = driver.id;
            }

            setLoading(true);

            return createManualTicket(createData)
                .then((data) => {
                    if (files) {
                        const promises = files.map((file) => uploadTicPic(data.data.id, file));

                        return Promise.all(promises)
                            .then(() => {
                                handleTicketSave(data);
                            })
                            .catch((error) => {
                                setError(PROCESS_SERVER_ERROR(error));
                            });
                    } else {
                        handleTicketSave(data);
                    }
                })
                .catch((errorMessage) => {
                    setError([PROCESS_SERVER_ERROR(errorMessage)]);
                    setLoading(false);
                });
        } else {
            if (!_.isEmpty(filesToRemove)) {
                setLoading(true);

                removeTicPics(_.uniqWith(filesToRemove, _.isEqual))
                    .then(() => {
                        !files && handleTicketSave();
                    })
                    .catch((error) => {
                        setLoading(false);
                        setError(PROCESS_SERVER_ERROR(error));
                    });
            }

            if (files) {
                const promises = files.map((file) => uploadTicPic(id, file));

                setLoading(true);

                return Promise.all(promises)
                    .then(() => {
                        handleTicketSave();
                    })
                    .catch((error) => {
                        setLoading(false);
                        setError(PROCESS_SERVER_ERROR(error));
                    });
            }

            handleTicketSave();
        }
    };

    const onChangeSubticketCheckbox = (checked) => {
        !checked && dispatch(change(form, "parentId", undefined));

        if (truck.parentTickets && truck.parentTickets.length === 1) {
            dispatch(
                change(form, "parentId", {
                    value: truck.parentTickets[0].id,
                    label: `#${truck.parentTickets[0].externalRef || truck.parentTickets[0].id} - ${jobOrder.payload}`,
                }),
            );
        }
    };

    const getParentTickets = () => {
        const results = truck.parentTickets;

        return Promise.resolve({
            options: results
                ? results.map((i) => ({
                      value: i.id,
                      label: `#${i.externalRef || i.id} - ${jobOrder.payload}`,
                  }))
                : [],
            hasMore: false,
        });
    };

    const defineIfSubTicketMightBeCreated = (selectedTruck) => {
        setTruckHasParentTicket(!_.isEmpty(selectedTruck.parentTickets));
        selectedTruck?.hauler && dispatch(change(form, "hauler", selectedTruck.hauler));
        dispatch(change(form, "driver", null));
    };

    const handleHaulerFieldChange = (selectedHauler) => {
        dispatch(change(form, "truck", null));
    };

    const resetSelectedJob = () => {
        dispatch(change(form, "jobOrder", null));
        dispatch(change(form, "truck", null));
        dispatch(change(form, "hauler", null));
        setUnitOfMeasure(null);
        setTruckHasParentTicket(false);
    };

    const truckAfterSaveHandler = async (createdTrucks) => {
        const truck = createdTrucks && createdTrucks[0];
        if (!truck) {
            return;
        }
        const { data } = await getCompanyTrucks({ company: hauler.value, truck: truck.id });
        const loadedTruck = data && data[0];
        if (loadedTruck) {
            dispatch(
                change(form, "truck", {
                    ...loadedTruck,
                    label: loadedTruck.truckName,
                    value: loadedTruck.id,
                }),
            );
        }
    };

    useEffect(() => {
        jobOrder && setJobOrder(jobOrder.id || jobOrder.value, jobOrder.unitOfMeasure);
    }, []);

    useEffect(() => {
        if (selectedJob) {
            getJobBoardJobOrderJoli(selectedJob).then((response) => {
                const joliItems = response.data;
                let assignedHaulersToUpdate = [];
                joliItems.forEach((item) => {
                    if (item.haulers.length > 0) {
                        const haulersWithItemId = item.haulers.map((i) => {
                            return {
                                ...i,
                                joLineItemId: item.id,
                            };
                        });

                        assignedHaulersToUpdate = [...assignedHaulersToUpdate, ...haulersWithItemId];
                    }
                });

                setAssignedHaulers(assignedHaulersToUpdate);
            });
        }
    }, [selectedJob]);

    const isNoHaulers = !(jobOrderHasHaulers || assignedHaulers.length > 0);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <h1 className="title">{id ? `Add TicPic to ticket #${id}` : "Create Ticket"} </h1>
            <br />
            <form
                noValidate={true}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY, classes.createTicketFormContainer)}
                onSubmit={handleSubmit(handleSaveWrapper)}
            >
                {isLoading && <Loader />}
                <Field
                    name="project"
                    disabled={disabled}
                    validate={!id ? [Validation.required] : []}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    onChange={resetSelectedJob}
                    loadOptions={loadProjects}
                    placeholder="Project"
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <Field
                    name="jobOrder"
                    validate={!id ? [Validation.required] : []}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    disabled={!!(!project || id)}
                    onChange={(value) => setJobOrder(value.id, value.unitOfMeasure)}
                    loadOptions={loadJobOrders}
                    placeholder="Job"
                    component={AsyncAutocompleteComponent}
                />
                <br />
                {selectedJob && (
                    <>
                        {(jobOrderHasHaulers || assignedHaulers.length > 0) && (
                            <>
                                <Field
                                    name="hauler"
                                    isClearable
                                    disabled={disabled}
                                    placeholder="Hauler"
                                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                    loadOptions={loadHaulersForJobOrder}
                                    onChange={handleHaulerFieldChange}
                                    component={AsyncAutocompleteComponent}
                                />
                                <br />
                            </>
                        )}
                        <Field
                            name="truck"
                            disabled={disabled}
                            validate={!id ? [Validation.required] : []}
                            placeholder="Truck"
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            onChange={defineIfSubTicketMightBeCreated}
                            loadOptions={loadTrucksForJobOrder}
                            createNewOption={
                                hauler && !haulerUseTruckIT
                                    ? {
                                          label: `Add Truck`,
                                          value: CREATE_NEW_OPTION_ID,
                                      }
                                    : undefined
                            }
                            createNewHandler={
                                hauler && !haulerUseTruckIT ? () => dispatch(handleTruckDialogToggle(true)) : undefined
                            }
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                    </>
                )}
                <Drivers
                    form={form}
                    nothing={!selectedJob}
                    warning={hauler && truck}
                    field={isNoHaulers || (truck && !truck.hauler)}
                    truck={truck}
                    jobOrder={jobOrder}
                    classes={classes}
                />
                <Field
                    type="number"
                    validate={!id ? [Validation.required] : []}
                    name="quantity"
                    label="Quantity"
                    needShowEndAdornment={!!unitOfMeasure}
                    endAdornment={unitOfMeasure && jobOrder && <UnitOfMeasure value={unitOfMeasureName?.name} />}
                    disabled={disabled}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                {!isHourlyTicketForm && hasAdditionalExternalRefs ? (
                    <FieldArray
                        name="additionalExternalRefs"
                        form={form}
                        component={AdditionalExternalRefs}
                        disabled={disabled}
                    />
                ) : (
                    <>
                        <Field
                            validate={[Validation.required]}
                            name="externalRef"
                            disabled={disabled}
                            label="Ticket Number"
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            component={TextInputComponent}
                        />
                        <ExistedTicketWarning exists={exists} />
                    </>
                )}
                <br />
                {unitOfMeasure === UNIT_OF_MEASURE_HOUR &&
                    truckHasParentTicket &&
                    jobOrder &&
                    jobOrder.ticketsType === TICKET_USAGE_TYPE_STANDALONE && (
                        <React.Fragment>
                            <Field
                                name="isSubticket"
                                label="SubTicket"
                                type="checkbox"
                                disabled={disabled}
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                onChange={(e) => onChangeSubticketCheckbox(e.target.checked)}
                                component={CustomSwitch}
                            />
                            <br />
                        </React.Fragment>
                    )}
                {isSubticket && truck && (
                    <React.Fragment>
                        <Field
                            name="parentId"
                            label="Parent Ticket"
                            validate={[Validation.required]}
                            disabled={disabled}
                            isClearable={true}
                            placeholder="Parent Ticket"
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            loadOptions={getParentTickets}
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                    </React.Fragment>
                )}
                <Grid container>
                    <Grid item xs={6}>
                        <Field
                            name="reconciled"
                            label="Reconcile"
                            disabled={disabled}
                            type="checkbox"
                            component={CustomSwitch}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="isDuplicate"
                            label="Is Duplicate"
                            type="checkbox"
                            disabled={disabled}
                            component={CustomSwitch}
                        />
                    </Grid>
                </Grid>
                <br />
                {isJobsTickets ? (
                    <DndProvider backend={Backend}>
                        <TicpickDrop ticket={ticket} form={form} />
                    </DndProvider>
                ) : (
                    <TicpickDrop ticket={ticket} form={form} />
                )}
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            <TruckDialog
                trucksFilters={{}}
                onlyNameField
                addTruckCompanyId={hauler?.value}
                afterSaveHandler={truckAfterSaveHandler}
            />
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {success && (
                <ErrorNotification type={"success"} message={success} config={{ onClose: () => setSuccess(null) }} />
            )}
        </div>
    );
}

CreateTicketForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loadTickets: PropTypes.func,
    closeModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    handleTicketCreate: PropTypes.func,
    formValues: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    ticket: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    truckDialogOpen: PropTypes.bool,
    jobOrderHasHaulers: PropTypes.bool,
    measureSystem: PropTypes.number.isRequired,
};

CreateTicketForm.defaultProps = {
    ticket: {},
};

export const CREATE_TICKET_FORM = "createTicketForm";
export const EDIT_TICKET_FORM = "editTicketForm";

export default withRouter(
    compose(
        reduxForm({
            initialValues: { files: [] },
            validate,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                truckDialogOpen: state.trucks.truckDialogOpen,
                formValues: formSelector(
                    state,
                    "project",
                    "id",
                    "jobOrder",
                    "truck",
                    "isSubticket",
                    "hauler",
                    "externalRef",
                ),
                measureSystem: selectMeasureSystem(state),
            };
        }),
    )(CreateTicketForm),
);
