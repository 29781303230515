import React, { useEffect, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Tooltip } from "@material-ui/core";
import { ChartContainer, ChartRow, Charts, EventChart, Resizable } from "react-timeseries-charts";
import Box from "@material-ui/core/Box";
import {
    handleChartResetZoom,
    handleChartZoomIn,
    handleChartZoomOut,
    useTooltipChartPosition,
} from "../../../helpers/chartHelpers/chartTimeRangeHandlers";
import { toDate } from "../../../helpers/date";
import {
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
    getHoursDateDifference,
    getMinutesDateDifference,
} from "../../../constants/global";
import { TimeRange, TimeRangeEvent, TimeSeries } from "pondjs";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { selectAccountTimezone } from "../../../selectors";
import PropTypes from "prop-types";
import { SAME_TIME_OFFSET } from "../../reportAndAnalytic/truckAllActivitiesChart";

export const convertToChartDate = (timestamp, timezone) => {
    return new Date(
        toDate({
            date: timestamp,
            timezone: timezone,
        }).format(DEFAULT_DATE_TIME_FORMAT),
    );
};

const generateTimeDifferenceLabel = (isLatenessEvent, hoursDiff, minDiff) => {
    return `${isLatenessEvent ? "Late" : "Idle"} Time: ${hoursDiff && `${hoursDiff} hours `}${
        minDiff && `${minDiff} minutes`
    }`;
};

const getLocationsEvents = (locationPoints, timezone) => {
    const events = [];

    locationPoints.forEach((location, index) => {
        if (location.isJobStart) {
            const prevLocation = locationPoints[index - 1];
            const nextLocation = locationPoints[index + 1];

            let samePickUpLocationTimestamp;
            let eventType;
            let tooltipFlexDirection;

            if (prevLocation?.isPickUp && prevLocation?.jobId === location.jobId) {
                samePickUpLocationTimestamp = prevLocation.timestamp;
                eventType = EARLINESS_EVENT_NAME;
                tooltipFlexDirection = "column-reverse";
            } else if (nextLocation?.isPickUp && nextLocation?.jobId === location.jobId) {
                samePickUpLocationTimestamp = nextLocation.timestamp;
                eventType = LATENESS_EVENT_NAME;
                tooltipFlexDirection = "column";
            }

            if (!samePickUpLocationTimestamp) {
                return false;
            }

            const currentLocationTimestamp = location.timestamp;
            const jobOrderStartDate = toDate({
                date: currentLocationTimestamp,
                timezone: timezone,
            });
            const jobOrderStartTime = jobOrderStartDate.format(DEFAULT_TIME_FORMAT);
            const jobOrderChartStartTime = convertToChartDate(currentLocationTimestamp, timezone);

            const truckEstimatedDate = toDate({
                date: samePickUpLocationTimestamp,
                timezone: timezone,
            });
            const truckEstimatedTime = truckEstimatedDate.format(DEFAULT_TIME_FORMAT);
            const truckEstimatedChartTime = convertToChartDate(samePickUpLocationTimestamp, timezone);

            const isLatenessEvent = eventType === LATENESS_EVENT_NAME;

            const eventTimeRange = isLatenessEvent
                ? new TimeRange(jobOrderChartStartTime, truckEstimatedChartTime)
                : new TimeRange(truckEstimatedChartTime, jobOrderChartStartTime);
            const minDiff = getMinutesDateDifference(truckEstimatedDate, jobOrderStartDate) || "";
            const hoursDiff = getHoursDateDifference(truckEstimatedDate, jobOrderStartDate) || "";
            if (jobOrderStartTime !== truckEstimatedTime) {
                events.push(
                    new TimeRangeEvent(eventTimeRange, {
                        title: (
                            <>
                                <div>{generateTimeDifferenceLabel(isLatenessEvent, hoursDiff, minDiff)}</div>
                                <Box display="flex" flexDirection={tooltipFlexDirection}>
                                    <div>Requested truck time: {jobOrderStartTime}</div>
                                    <div>Estimated truck time: {truckEstimatedTime}</div>
                                </Box>
                            </>
                        ),
                        type: eventType,
                    }),
                );
            }
        }
    });

    return events;
};
export const LATENESS_EVENT_COLOR = "#9d012d";
export const EARLINESS_EVENT_COLOR = "#676464";
export const LATENESS_EVENT_NAME = "lateness";
export const EARLINESS_EVENT_NAME = "earliness";

const outageEventStyleCB = (event, state) => {
    const color = event.get("type") === LATENESS_EVENT_NAME ? LATENESS_EVENT_COLOR : EARLINESS_EVENT_COLOR;
    switch (state) {
        case "normal":
            return {
                fill: color,
            };
        case "hover":
            return {
                fill: color,
                cursor: "pointer",
            };
        default:
            return null;
    }
};

const styles = makeStyles(() => ({
    chartIcon: {
        fontSize: 20,
    },
}));

const RouteSliderChart = ({ routeLocations }) => {
    const classes = styles();
    const timezone = useSelector(selectAccountTimezone);

    const [minMaxTimes, setMinMaxTimes] = useState(null);
    const [series, setSeries] = useState(null);
    const [timeRange, setTimeRange] = useState(null);

    const [showTip, currentTitle, handleHoverEvent, getBoundingClientRect, onMouseMove, handleTooltipClose] =
        useTooltipChartPosition();

    const handleResetZoom = () => {
        handleChartResetZoom(timeRange, setTimeRange, minMaxTimes);
    };

    const handleZoomIn = () => {
        handleChartZoomIn(timeRange, setTimeRange);
    };

    const handleZoomOut = () => {
        handleChartZoomOut(timeRange, setTimeRange, minMaxTimes);
    };

    const handleTimeRangeChange = (newTimeRange) => {
        // setTimeRange(newTimeRange);
        if (Math.abs(newTimeRange.duration() - timeRange.duration()) < SAME_TIME_OFFSET) {
            setTimeRange(newTimeRange);
        }
    };

    useEffect(() => {
        const sliderLocations = routeLocations.filter((location) => !location.isJobStart);
        const minTime = convertToChartDate(sliderLocations[0].timestamp, timezone);
        const maxTime = convertToChartDate(sliderLocations[sliderLocations.length - 1].timestamp, timezone);
        setTimeRange(new TimeRange(minTime, maxTime));
        setMinMaxTimes({
            minTime: minTime,
            maxTime: maxTime,
        });

        const events = getLocationsEvents(routeLocations, timezone);
        setSeries(new TimeSeries({ name: "events", events: [] }));

        // if (!_.isEmpty(events)) {
        //     setSeries(new TimeSeries({ name: "events", events }));
        // }
    }, [routeLocations]);

    // if (_.isEmpty(series)) {
    //     return null;
    // }

    if (!minMaxTimes) {
        return null;
    }

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                onMouseMove={onMouseMove}
                PopperProps={{
                    anchorEl: {
                        clientHeight: 0,
                        clientWidth: 0,
                        getBoundingClientRect: getBoundingClientRect,
                    },
                }}
                onClose={handleTooltipClose}
                open={showTip}
                placement="bottom"
                disableFocusListener
                disableTouchListener
                title={currentTitle}
            >
                <div>
                    <>
                        <Resizable>
                            <ChartContainer
                                timeRange={timeRange}
                                minTime={minMaxTimes.minTime}
                                maxTime={minMaxTimes.maxTime}
                                enablePanZoom={true}
                                onTimeRangeChanged={handleTimeRangeChange}
                            >
                                <ChartRow height={10}>
                                    <Charts height={100}>
                                        <EventChart
                                            onMouseLeave={(event) => handleHoverEvent(event, true)}
                                            onMouseOver={(event) => handleHoverEvent(event)}
                                            series={series}
                                            size={45}
                                            style={outageEventStyleCB}
                                        />
                                    </Charts>
                                </ChartRow>
                            </ChartContainer>
                        </Resizable>
                        {/*<Box width={1} display="flex" justifyContent="center">*/}
                        {/*    <Tooltip title="Reset zoom">*/}
                        {/*        <IconButton aria-label="reset-zoom" onClick={handleResetZoom}>*/}
                        {/*            <RefreshIcon height="100%" className={clsx(classes.chartIcon)} />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*    <Tooltip title="Zoom In">*/}
                        {/*        <IconButton aria-label="add-zoom" onClick={handleZoomIn}>*/}
                        {/*            <AddIcon className={clsx(classes.chartIcon)} />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*    <Tooltip title="Zoom out">*/}
                        {/*        <IconButton aria-label="remove-zoom" onClick={handleZoomOut}>*/}
                        {/*            <RemoveIcon className={clsx(classes.chartIcon)} />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</Box>*/}
                    </>
                </div>
            </Tooltip>
        </ClickAwayListener>
    );
};

RouteSliderChart.propTypes = {
    routeLocations: PropTypes.array.isRequired,
};

export default RouteSliderChart;
