import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { compose } from "redux";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getCompanyTrucks, getMyTeam, getTruckStartTime } from "../../dataServers/trucks";
import { IS_ADMIN_USER } from "../../constants/maps";
import TextInputComponent from "../core/form/textInputComponent";
import { TABLE_SECONDARY, TABLE_SECONDARY_SCROLLABLE } from "../../styles/reusableStyles";
import clsx from "clsx";
import _ from "lodash";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIMEZONE_DATE_FORMAT,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
    SERVER_DATE_FORMAT,
} from "../../constants/global";
import { sendMessages } from "../../dataServers/teams";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { PrimaryButton } from "../core/buttons/primaryButton";
import AppModal from "../core/modal";
import RegionSelector from "../global/regionSelector";
import DateTimePicker from "../core/form/dateTimePicker";
import { Validation, VALIDATION_ERRORS } from "../../helpers/validation";
import { FILTERS_REGIONS_ID } from "../global/searchAndFilters";
import moment from "moment";
import ConfirmationModal from "../core/confirmationModal";
import { convertTimeToJsFormat } from "../../helpers/jobOrders";

const useStyles = makeStyles((theme) => ({
    userTable: {
        "& .message": {
            padding: 5,
            width: 120,
        },
        "& .truck": {
            padding: 5,
            width: 180,
        },
        "& .start-date": {
            padding: 5,
            width: 170,
        },
        "& .tr": {
            height: "auto",
        },
        "& .th": {
            "& .truck-header": {
                width: 180,
            },
            "& .start-date-header": {
                width: 170,
            },
            "& .message-header": {
                width: 120,
            },
        },
    },
    templatePopup: {
        textAlign: "center",
    },
    buttonContainer: {
        width: "100%",
    },
    scrollableValueContainer: {
        "& .___value-container": {
            maxHeight: 250,
            overflow: "auto",
            position: "initial",
        },
        "& .___control--menu-is-open, & .___value-container--has-value, & .___value-container--has-value.___value-container--is-multi":
            {
                "& .___placeholder": {
                    top: -6,
                },
            },
    },
}));

const renderUserMessages = ({ fields, classes, onRemoveUser, loadTrucks, isDriverStartTemplate, setTruck }) => {
    const tableStyles = TABLE_SECONDARY();
    const tableScrolableStyles = TABLE_SECONDARY_SCROLLABLE();
    const allUsers = fields.getAll();

    return (
        <div className={clsx(tableStyles.secondaryTable, tableScrolableStyles.scrolableTable, classes.userTable)}>
            <div className="tbody">
                <div className="tr">
                    <div className="th">
                        <div>User</div>
                    </div>
                    {isDriverStartTemplate && (
                        <>
                            <div className="th">
                                <div className="truck-header">Truck</div>
                            </div>
                            <div className="th">
                                <div className="start-date-header">Start time</div>
                            </div>
                        </>
                    )}
                    {!isDriverStartTemplate ? (
                        <div className="th">
                            <div>Message</div>
                        </div>
                    ) : null}
                    <div className="th" />
                </div>
            </div>
            {!_.isEmpty(allUsers) ? (
                <div className={clsx("tbody", allUsers.length > 4 && "-scroll")} style={{ maxHeight: 405 }}>
                    {fields.map((item, index) => {
                        const user = fields.get(index);
                        const removeUser = () => {
                            fields.remove(index);
                            onRemoveUser(user);
                        };

                        return (
                            <div className="tr" key={user.userId}>
                                <div className="td">
                                    <div>{user.name}</div>
                                </div>
                                {isDriverStartTemplate && (
                                    <>
                                        <div className="td">
                                            <div>
                                                <div className="truck">
                                                    <Field
                                                        name={`${item}.truck`}
                                                        placeholder="Select Truck"
                                                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                                        loadOptions={loadTrucks}
                                                        onChange={setTruck}
                                                        component={AsyncAutocompleteComponent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="td">
                                            <div>
                                                <div className="start-date">
                                                    <Field
                                                        name={`${item}.startDate`}
                                                        placeholder="Start Time"
                                                        validate={[Validation.required]}
                                                        inputVariant={"standard"}
                                                        className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                                                        component={DateTimePicker}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!isDriverStartTemplate ? (
                                    <div className="td">
                                        <div>
                                            <div className="message">
                                                <Field
                                                    type="text"
                                                    placeholder="Message"
                                                    name={`${item}.message`}
                                                    isMulti={true}
                                                    component={TextInputComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="td">
                                    <div className="remove-button">
                                        <IconButton
                                            title="remove hauler truck"
                                            className="pill-button"
                                            onClick={removeUser}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="--text-center">
                    <br />
                    No selected users
                </div>
            )}
        </div>
    );
};

const UpdateButton = React.forwardRef(({ defaultMessage, handleUpdateClick, customMessages, template, users }, ref) => {
    const disabled = () => {
        if (template && users && users.length) return false;
        if (!(defaultMessage && !_.isEmpty(customMessages))) return true;
    };

    return (
        <div ref={ref}>
            <SecondaryButton disabled={disabled()} size={"medium"} onClick={handleUpdateClick}>
                Update
            </SecondaryButton>
        </div>
    );
});

UpdateButton.propTypes = {
    defaultMessage: PropTypes.string,
    customMessages: PropTypes.array,
    handleUpdateClick: PropTypes.func,
    template: PropTypes.number,
    users: PropTypes.array,
};

const isMessageAsDefault = (i, defaultMessage) => {
    return i.message && i.message.toLowerCase().trim() !== defaultMessage.toLowerCase().trim();
};

const AllTeamMessageForm = (props) => {
    const classes = useStyles();
    const {
        handleSubmit,
        account,
        formValues: { defaultMessage, customMessages, users, startDate, regions, template },
        userRegion,
        dispatch,
        form,
        closeModal,
    } = props;
    const { company } = account;
    const [isLoading, setLoading] = useState(false);
    const [openWarn, setSetShowWarn] = useState(false);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const [isDriverStartTemplate, setIsDriverStartTemplate] = useState(false);
    const [confirmationModal, showConfirmationModal] = useState(false);
    const [error, setError] = useState();
    const [prevDefaultMessage, setPrevDefaultMessage] = useState();
    const [selectedTruckData, setSelectedTruckData] = useState(undefined);
    const [forceUpdate, setForceUpdate] = useState(false);

    const onSubmit = (values) => {
        const { customMessages } = values;
        setLoading(true);
        const data = {
            messages: customMessages.map((i) => {
                let restParams = {};
                const startDate = i.startDate ? moment(i.startDate).format(DEFAULT_DATE_TIME_FORMAT) : "No Data";
                const truck = i.truck ? i.truck.label : "No Data";
                const message = `\nTruck: ${truck}\nStart Time: ${startDate}`;
                if (template && template.value === 1)
                    restParams.forDate = moment(i.startDate).format(DEFAULT_TIMEZONE_DATE_FORMAT);

                return {
                    text: isDriverStartTemplate ? message : i.message,
                    userId: i.userId,
                    ...restParams,
                };
            }),
        };
        sendMessages(data)
            .then((data) => {
                setLoading(false);
                closeModal();
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };
    const loadTeamUsers = (inputValue, { params = {}, loadedCount }) => {
        let restParams = {};
        if (template.value === 1) {
            if (regions && regions.length) restParams.regions = regions.map((region) => region.value).join(",");
            restParams.truckerType = 2;
            restParams.forDate = startDate?.format(SERVER_DATE_FORMAT);
        }
        let myTeamParams = {
            keywords: inputValue,
            ...params,
            withArchived: IS_ADMIN_USER(account.company.id),
            ...restParams,
        };

        return getMyTeam(myTeamParams).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: `${i.firstName} ${i.lastName}`,
                truck: i.truck,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const setTruck = (truckItem) => {
        getTruckStartTime(truckItem.value).then((res) => {
            const startDate = res.data.startDate;
            setSelectedTruckData({ truckId: truckItem.value, startDate: startDate });
        });
    };

    const loadTemplateChoices = () => {
        return Promise.resolve().then(() => ({
            options: [
                {
                    value: null,
                    label: "None",
                },
                {
                    value: 1,
                    label: "Driver Start Time",
                },
            ],
            hasMore: false,
            page: 1,
        }));
    };

    const loadTrucks = (inputValue, { params = {}, loadedCount }) => {
        const newParams = {
            [FILTERS_REGIONS_ID]: !_.isEmpty(regions) ? regions.map((item) => item.value).join(",") : undefined,
            company: company.id,
        };

        return getCompanyTrucks({
            keywords: inputValue,
            ...params,
            ...newParams,
        }).then((data) => {
            const chosenTruckIds = customMessages.map((item) => item.truck && item.truck.value);
            const results = data.data
                .filter((truck) => !_.includes(chosenTruckIds, truck.id))
                .map((truck) => ({
                    value: truck.id,
                    label: truck.truckName,
                }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || data.data.length),
                page: data.meta.page,
            };
        });
    };

    const showWarn = () => {
        setSetShowWarn(true);
    };

    const setUserMessage = (values) => {
        if (_.isEmpty(values)) {
            dispatch(change(form, "customMessages", []));

            return;
        }

        if (users && users.length > values.length) {
            const userIdList = values.map((value) => value.value);
            const filteredUsers = users.filter((formUser) => !userIdList.includes(formUser.value));
            const deletedUser = !_.isEmpty(filteredUsers) && filteredUsers[0];

            if (deletedUser) {
                onRemoveUser(deletedUser);

                const newCustomMessages =
                    !_.isEmpty(customMessages) &&
                    customMessages.filter((message) => message.userId !== deletedUser.value);
                newCustomMessages && dispatch(change(form, "customMessages", newCustomMessages));
            }

            return;
        }

        const newItem = values[values.length - 1];
        newItem &&
            dispatch(
                change(form, "customMessages", [
                    ...customMessages,
                    {
                        name: newItem.label,
                        userId: newItem.value,
                        message: defaultMessage,
                        startDate:
                            isDriverStartTemplate && !_.isEmpty(newItem.truck) && newItem.truck.startDate
                                ? newItem.truck.startDate
                                : null,
                        truck:
                            isDriverStartTemplate && !_.isEmpty(newItem.truck)
                                ? {
                                      label: newItem.truck.name,
                                      id: newItem.truck.value,
                                  }
                                : undefined,
                    },
                ]),
            );

        if (defaultMessage) {
            setPrevDefaultMessage(defaultMessage);
        }
    };

    const onRemoveUser = (user) => {
        dispatch(
            change(
                form,
                "users",
                _.cloneDeep(users).filter((i) => {
                    return i.value !== user.userId;
                }),
            ),
        );
    };

    const updateMessages = () => {
        setPrevDefaultMessage(defaultMessage);
        customMessages &&
            dispatch(
                change(
                    form,
                    "customMessages",
                    _.cloneDeep(customMessages).map((i) => ({
                        ...i,
                        startDate: isDriverStartTemplate ? (i.startDate ? i.startDate : startDate) : undefined,
                        truck: isDriverStartTemplate ? i.truck : undefined,
                        message: defaultMessage,
                    })),
                ),
            );
    };

    const updateSingleMessage = () => {
        customMessages &&
            dispatch(
                change(
                    form,
                    "customMessages",
                    _.cloneDeep(customMessages).map((i) => {
                        if (selectedTruckData.truckId === i.truck?.value) {
                            const updatedStartDate = convertTimeToJsFormat(
                                selectedTruckData.startDate,
                                account.timezone,
                            );

                            return {
                                ...i,
                                startDate: isDriverStartTemplate
                                    ? selectedTruckData.startDate
                                        ? updatedStartDate
                                        : null
                                    : null,
                            };
                        }

                        return i;
                    }),
                ),
            );
    };

    const setMessageTemplate = (value) => {
        if (value.value) {
            setIsTemplateModalOpen(true);
            dispatch(change(form, "startDate", moment().add(1, "days")));
            if (userRegion) dispatch(change(form, "regions", [{ label: userRegion.regionName, value: userRegion.id }]));
        } else {
            if (!_.isEmpty(customMessages)) {
                showConfirmationModal(true);
            }
            if (users && !users.length) setIsDriverStartTemplate(false);
            setIsTemplateModalOpen(false);
        }
    };

    const handleUpdateClick = () => {
        const hasCustomMessaged =
            defaultMessage &&
            !_.isEmpty(customMessages) &&
            customMessages.some((i) => {
                return isMessageAsDefault(i, prevDefaultMessage || defaultMessage);
            });

        hasCustomMessaged && !openWarn ? showWarn() : updateMessages();
    };

    const requestAllDrivers = (params) => {
        Promise.all([
            getMyTeam({
                [FILTERS_REGIONS_ID]: !_.isEmpty(regions) ? regions.map((item) => item.value).join(",") : undefined,
                forDate: startDate?.format(SERVER_DATE_FORMAT),
                withArchived: IS_ADMIN_USER(account.company.id),
                withTruck: true,
                perPage: 1000,
            }),
            getMyTeam({
                [FILTERS_REGIONS_ID]: !_.isEmpty(regions) ? regions.map((item) => item.value).join(",") : undefined,
                forDate: startDate?.format(SERVER_DATE_FORMAT),
                withArchived: IS_ADMIN_USER(account.company.id),
                truckerType: 2,
                perPage: 1000,
            }),
        ]).then(([{ data: usersWithTruck }, { data: usersWithoutTruck }]) => {
            const merged = _.merge(_.keyBy(usersWithTruck, "id"), _.keyBy(usersWithoutTruck, "id"));
            const values = _.sortBy(_.values(merged), ({ truck }) => !truck);
            setUsers({ data: values });
        });
    };

    const requestMyTeam = (params) => {
        let restParams = {};
        if (params) restParams = { ...params };
        if (isDriverStartTemplate) {
            getMyTeam({
                [FILTERS_REGIONS_ID]: !_.isEmpty(regions) ? regions.map((item) => item.value).join(",") : undefined,
                forDate: startDate?.format(SERVER_DATE_FORMAT),
                withArchived: IS_ADMIN_USER(account.company.id),
                ...restParams,
            }).then((data) => setUsers(data));
        } else {
            dispatch(change(form, "customMessages", []));
            dispatch(change(form, "users", []));
        }
    };

    useEffect(() => {
        requestMyTeam({ withTruck: true, perPage: 1000 });
    }, [isDriverStartTemplate, forceUpdate]);

    const selectAllDrivers = () => requestAllDrivers();

    const setUsers = (data) => {
        const usersWithJob = data.data;
        if (!_.isEmpty(usersWithJob)) {
            const newCustomMessages = [];
            const newUsers = [];
            usersWithJob.forEach((user) => {
                const usersTruck = user.truck;
                const usersStartDate = usersTruck && usersTruck.startDate;
                const userFullname = `${user.firstName} ${user.lastName}`;
                newUsers.push({
                    label: userFullname,
                    value: user.id,
                });
                newCustomMessages.push({
                    userId: user.id,
                    name: userFullname,
                    startDate: usersStartDate && convertTimeToJsFormat(usersTruck.startDate, account.timezone),
                    truck: usersTruck
                        ? {
                              value: usersTruck.id,
                              label: usersTruck.name,
                          }
                        : null,
                });
            });
            dispatch(change(form, "customMessages", newCustomMessages));
            dispatch(change(form, "users", newUsers));
        }
    };

    const saveTemplateOptions = () => {
        setIsTemplateModalOpen(false);
        showConfirmationModal(false);
        if (template.value === 1) {
            setForceUpdate((state) => !state);
            setIsDriverStartTemplate(true);
        } else {
            setIsDriverStartTemplate(false);
        }
    };

    const cancelTemplateOption = () => {
        dispatch(change(form, "regions", null));
        dispatch(change(form, "startDate", null));
        dispatch(
            change(form, "template", {
                value: null,
                label: "None",
            }),
        );
        showConfirmationModal(false);
        setIsTemplateModalOpen(false);
    };

    const closeTemplateModal = () => {
        if (!isDriverStartTemplate) {
            dispatch(
                change(form, "template", {
                    value: null,
                    label: "None",
                }),
            );
        }
        setIsTemplateModalOpen(false);
    };

    useEffect(() => {
        dispatch(
            change(form, "template", {
                value: null,
                label: "None",
            }),
        );
    }, []);

    // useEffect(() => { if (startDate) updateMessages() }, [startDate]);

    useEffect(() => {
        if (!users) dispatch(change(form, "users", []));
    }, [users]);

    useEffect(() => {
        if (selectedTruckData) updateSingleMessage();
    }, [selectedTruckData]);

    const renderDefaultMessageField = () => {
        if (isDriverStartTemplate) return;

        return (
            <>
                <Grid item xs={3}>
                    <Field
                        type="text"
                        label="Default Message"
                        name="defaultMessage"
                        isMulti={true}
                        component={TextInputComponent}
                    />
                </Grid>
                <Grid item>
                    <ClickAwayListener onClickAway={() => setSetShowWarn(false)}>
                        <div>
                            <Tooltip
                                PopperProps={{ disablePortal: true }}
                                onClose={() => setSetShowWarn(false)}
                                open={openWarn}
                                placement={"top"}
                                disableFocusListener
                                disableTouchListener
                                title={`All custom messages will be overwritten by default message.
                                    \nPress 'Update' button one more time if you are agree.`}
                            >
                                <UpdateButton
                                    customMessages={customMessages}
                                    defaultMessage={defaultMessage}
                                    template={isDriverStartTemplate}
                                    users={users}
                                    handleUpdateClick={handleUpdateClick}
                                />
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </Grid>
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            <h1 className="title">Message Team</h1>
            <Field
                name="users"
                loadOptions={loadTeamUsers}
                isMulti={true}
                onChange={setUserMessage}
                isClearable={true}
                wrapperClassName={users && users.length > 40 && classes.scrollableValueContainer}
                selectAllOption={template && template.value === 1 && { label: "Select All Drivers" }}
                selectAllHandler={selectAllDrivers}
                className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <Grid container alignItems={"center"} justify={"space-between"} spacing={1}>
                <Grid item xs={3}>
                    <Field
                        name="template"
                        label="Message template"
                        loadOptions={loadTemplateChoices}
                        onChange={setMessageTemplate}
                        className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS, "users-selection")}
                        component={AsyncAutocompleteComponent}
                    />
                </Grid>
                {renderDefaultMessageField()}
            </Grid>
            <br />
            <FieldArray
                name="customMessages"
                rerenderOnEveryChange
                classes={classes}
                loadTrucks={loadTrucks}
                isDriverStartTemplate={isDriverStartTemplate}
                setTruck={setTruck}
                onRemoveUser={onRemoveUser}
                component={renderUserMessages}
            />
            <br />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={!props.valid}>
                    Send messages
                </PrimaryButton>
            </ButtonsGroup>
            <AppModal isOpen={isTemplateModalOpen} closeModal={closeTemplateModal}>
                <Box className={classes.templatePopup}>
                    <Field
                        name="startDate"
                        placeholder="Start Date"
                        dateOnly
                        validate={[Validation.required]}
                        inputVariant={"filled"}
                        className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        component={DateTimePicker}
                    />
                    <br />
                    <RegionSelector name={"regions"} isMulti={true} isClearable={true} />
                    <br />
                    <Grid container justify={"space-around"} className={classes.buttonContainer}>
                        <Grid item>
                            <PrimaryButton onClick={() => showConfirmationModal(true)}>Save</PrimaryButton>
                        </Grid>
                        <Grid item>
                            <SecondaryButton onClick={cancelTemplateOption}>Cancel</SecondaryButton>
                        </Grid>
                    </Grid>
                </Box>
            </AppModal>
            <ConfirmationModal
                yesHandler={saveTemplateOptions}
                noHandler={cancelTemplateOption}
                question="If you change template all data will be cleared. Are you sure?"
                isOpen={confirmationModal}
            />
        </form>
    );
};

AllTeamMessageForm.propTypes = {
    formValues: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    userRegion: PropTypes.object,
};

AllTeamMessageForm.defaultProps = {};

export const ALL_TEAM_MESSAGE_FORM = "myAllTeamMessageForm";

const validate = (values) => {
    const errors = {};
    if (values.users && !values.users.length) errors.users = VALIDATION_ERRORS.required;
    if (values.customMessages && !values.defaultMessage && values.customMessages.some((messages) => !messages.message))
        errors.defaultMessage = VALIDATION_ERRORS.required;

    return errors;
};

export default withRouter(
    compose(
        reduxForm({
            form: ALL_TEAM_MESSAGE_FORM,
            initialValues: {
                customMessages: [],
                defaultMessage: "",
            },
            validate,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                userRegion: state.account.region,
                formValues: formSelector(
                    state,
                    "defaultMessage",
                    "customMessages",
                    "users",
                    "template",
                    "startDate",
                    "regions",
                ),
            };
        }),
    )(AllTeamMessageForm),
);
