import React from "react";
import { withRouter } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListWaypoint from "./listWaypoint";
import { PAGE_GLOBAL_STYLES, TABLE_PRIMARY } from "../../styles/reusableStyles";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import TableHeaderSortingCell from "./tableHeaderSortingCell";
import Table from "@material-ui/core/Table";
import Container from "@material-ui/core/Container";
import { REPORT_TABLE_HEADER_FIRST_ROW_HEIGHT } from "../reportAndAnalytic/reporting/reportTable";

const UNAVAILABLE_TABLE_ROW_CLASSNAME = "row-unavailable";

const useStyles = makeStyles((theme) => ({
    unavailable: {
        backgroundColor: `${theme.palette.secondary.superLight} !important`,
    },
    tableOverride: {
        [`& .${UNAVAILABLE_TABLE_ROW_CLASSNAME}`]: {
            "&:hover": {
                backgroundColor: `${theme.palette.secondary.superLight} !important`,
            },
        },
    },
    totalsAmountRow: {
        "& th": {
            height: "40px !important",
            lineHeight: "1",
        },
    },
    totalsAmountItem: {
        color: `${theme.palette.secondary.dark} !important`,
        textAlign: "center",
        fontSize: "12px !important",
        padding: "0px !important",
        top: REPORT_TABLE_HEADER_FIRST_ROW_HEIGHT,
        fontWeight: "bold !important",

        "& .total-value": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            borderTop: `1px solid ${theme.palette.secondary.dark}`,
            borderBottom: `1px solid ${theme.palette.secondary.dark}`,
            wordBreak: "break-all",
        },
    },
    emptyRowItem: { padding: "1px!important" },
}));

const TableWithStickyHeader = (props) => {
    const tableStyles = TABLE_PRIMARY();
    const wrapper = PAGE_GLOBAL_STYLES();
    const {
        rows,
        columns,
        fetchMore,
        totalIndex,
        itemsCount,
        tableAlign,
        className,
        style,
        handleSort,
        activeOrderParam,
        tableContainerClasses,
        headerRowHeight,
    } = props;
    const classes = useStyles();

    const totalRow = rows?.find((row, index) => index === totalIndex);

    return (
        <Container className={clsx(wrapper.globalPageStyles, className)} style={style}>
            <div className={tableContainerClasses}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={clsx(tableStyles.primaryTable, classes.tableOverride)}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                if (column.sortParam) {
                                    return (
                                        <TableHeaderSortingCell
                                            key={index}
                                            align={column.align || tableAlign}
                                            style={{ minWidth: column.minWidth || 0 }}
                                            handleSort={handleSort}
                                            sortParam={column.sortParam}
                                            label={column.label}
                                            activeOrderParam={activeOrderParam}
                                        />
                                    );
                                }

                                const cellStyle = {
                                    minWidth: column.minWidth || 0,
                                };
                                if (headerRowHeight) {
                                    cellStyle.height = headerRowHeight;
                                }

                                return (
                                    <TableCell key={index} align={column.align || tableAlign} style={cellStyle}>
                                        {column.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {totalRow && (
                            <TableRow className={classes.totalsAmountRow}>
                                {columns.map((column) => {
                                    const value = totalRow[column.id];

                                    return (
                                        <TableCell
                                            role="checkbox"
                                            key={column.id}
                                            className={clsx(classes.totalsAmountItem)}
                                            align={column.align}
                                        >
                                            <div className="total-value">{value}</div>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => {
                            const isTotalRow = index === totalIndex;
                            const isEmptyRow = totalIndex && index === 0;

                            if (isTotalRow) return;

                            return (
                                <React.Fragment key={index}>
                                    {fetchMore && (
                                        <tr>
                                            <td style={{ padding: 0 }}>
                                                <ListWaypoint
                                                    fetchMore={fetchMore}
                                                    index={index}
                                                    items={rows}
                                                    itemsCount={itemsCount}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    <TableRow
                                        role="checkbox"
                                        tabIndex={-1}
                                        className={
                                            row.truckName &&
                                            !row.isAvailable &&
                                            clsx(UNAVAILABLE_TABLE_ROW_CLASSNAME, classes.unavailable)
                                        }
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            if (isTotalRow && !value && value !== 0) return;

                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    className={
                                                        (isTotalRow && classes.totalsAmountItem) ||
                                                        (isEmptyRow && classes.emptyRowItem)
                                                    }
                                                >
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Container>
    );
};

TableWithStickyHeader.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    tableAlign: PropTypes.string.isRequired,
    activeOrderParam: PropTypes.string,
    headerRowHeight: PropTypes.number,
    handleSort: PropTypes.func,
    className: PropTypes.string,
    fetchMore: PropTypes.func,
    itemsCount: PropTypes.number,
    style: PropTypes.object,
    tableContainerClasses: PropTypes.any,
};

TableWithStickyHeader.defaultProps = {
    tableAlign: "center",
    style: {},
    tableContainerClasses: "",
    rows: [],
    columns: [],
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(TableWithStickyHeader),
);
