import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ActionNotification = ({ enqueueSnackbar, message, config, closeSnackbar }) => {
    const action = (key) => (
        <Button
            onClick={() => {
                config.onRedirect();
                closeSnackbar(key);
            }}
        >
            Select Region
        </Button>
    );
    if (message) {
        enqueueSnackbar(message, {
            ...config,
            action: config.onRedirect ? action : null,
        });
    }

    return <></>;
};

export default withSnackbar(ActionNotification);
