export const AUTHORIZE_USER = "authorize_user";
export const UPDATE_USER_DATA = "update_user_data";
export const LOGOUT_USER = "logout_user";
export const RESET_REDUX_STATE = "reset_redux_state";
export const SAVE_USERS_REGIONS = "save_users_regions";
export const ADD_NEW_USER_REGION = "add_new_user_region";
export const DELETE_USER_REGION = "delete_user_region";
export const UPDATE_USER_REGION = "update_user_region";
export const UPDATE_USER_TERMS_AND_PERMISSIONS_STATUS = "update_user_terms_and_permissions_status";
export const UPDATE_USER_COMPANY_TYPE = "update_user_company_type";
export const UPDATE_USER_PHONE_NUMBER = "update_user_phone_number";
export const UPDATE_USER_CUSTOMER_STRIPE_ID = "update_user_customer_stripe_id";
export const UPDATE_USER_HAS_TEMPORARY_PASSWORD_FIELD = "update_user_has_temporary_password_field";
export const UPDATE_ACTIVE_IOT_ORDER = "update_active_iot_device_order";
export const REQUEST_REGIONS = "request_regions";
export const REQUEST_REGIONS_RESULT = "request_regions_result";
export const REQUEST_UOM = "request_uom";
export const REQUEST_UOM_RESULT = "request_uom_result";
export const REQUEST_UOM_ERROR = "request_uom_error";
export const RESET_UOM_META = "reset_uom_meta";
export const RESET_UOM_DATA = "reset_uom_data";
export const REQUEST_COMPANY_REGIONS = "request_company_regions";
export const REQUEST_COMPANY_REGIONS_RESULT = "request_company_regions_result";
export const RESET_COMPANY_REGIONS = "reset_company_regions";
export const REQUEST_WEATHER = "request_weather";
export const REQUEST_WEATHER_RESULT = "request_weather_result";
export const RESET_WEATHER = "reset_weather";

export const REPORT_NAMES_REQUEST = "report_names_request";
export const REPORT_NAMES_ERROR = "report_names_error";
export const REPORT_NAMES_RESET_META = "report_names_reset_error";
export const SET_REPORTS_NAMES = "set_reports_names";

export const TRUCK_EVENTS_REQUEST = "TRUCK EVENTS REQUEST";
export const TRUCK_EVENTS_RESULT = "TRUCK EVENTS RESULT";
export const TRUCK_EVENTS_RESET = "TRUCK EVENTS RESET";
export const TRUCK_EVENTS_ERROR = "TRUCK EVENTS ERROR";
export const TRUCK_EVENTS_RESET_META = "TRUCK EVENTS RESET META";
export const TRUCK_EVENTS_SET_SUSPEND_PERIOD = "TRUCK EVENTS SET SUSPEND PERIOD";
