import dataProvider from "../helpers/api";
import { GLOBAL_ENDPOINTS } from "../constants/endpoints";

export const getDevices = (params = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.IOT_DEVICES(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};
export const getDeviceActivity = (id, params = {}) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.IOT_DEVICE_ACTIVITY(id),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};
