import React from "react";
import _ from "lodash";
import { Field } from "redux-form";
import { useSelector } from "react-redux";
import clsx from "clsx";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import { selectAdditionalExternalRefsOptions } from "../../../reducers/additionalExternalRefsReducer";
import { loadOptions } from "../../../helpers/global";
import { ButtonsGroup, SecondaryButton } from "../../core";
import { Validation } from "../../../helpers/validation";
import TextInputComponent from "../../core/form/textInputComponent";

const useStyles = makeStyles(() => ({
    additionalExternalRefContainer: {
        display: "flex",
        alignItems: "center",
    },

    externalRefKey: {
        "& .Mui-error": {
            marginTop: 0,
        },
        "&.form-view": {
            "& .custom-control .___control": {
                padding: "11px 12px 14px 12px",
            },
        },
    },
    externalRefValue: {
        "& .Mui-error": {
            marginTop: 0,
        },
        "& .MuiOutlinedInput-input": {
            padding: "18px 14px 19px 14px",
        },
    },
}));

const AdditionalExternalRefs = ({ fields, disabled }) => {
    const additionalExternalRefsOptions = useSelector(selectAdditionalExternalRefsOptions);
    const classes = useStyles();

    const allFields = fields.getAll() || [];
    const selectedLabelsIdList = allFields.map((field, index) => {
        const fieldObject = fields.get(index);

        return fieldObject.label?.value;
    });
    const notSelectedLabelsOptions = additionalExternalRefsOptions.filter(
        (ref) => !selectedLabelsIdList.includes(ref.value),
    );
    const isMaxAmountOfRefs = allFields.length === additionalExternalRefsOptions.length;

    const addField = () => {
        fields.push({
            fieldId: _.uniqueId(),
            newAdded: true,
        });
    };
    const removeField = (index) => {
        fields.remove(index);
    };

    return (
        <div>
            {fields.map((field, index) => {
                const ref = fields.get(index);
                const fieldId = ref?.fieldId;
                const isDefault = ref?.isDefault;
                const showCloseIcon = !disabled && !isDefault;

                return (
                    <Grid item key={fieldId || ref?.id}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Field
                                    disabled={disabled || isDefault}
                                    type="text"
                                    name={`${field}.label`}
                                    validate={[Validation.required]}
                                    component={AsyncAutocompleteComponent}
                                    className={clsx(classes.externalRefKey, AUTOCOMPLETE_FORM_VIEW_CLASS)}
                                    needShowEndAdornment={false}
                                    loadOptions={() => loadOptions(notSelectedLabelsOptions)}
                                />
                            </Grid>
                            <Grid container item xs={showCloseIcon ? 7 : 8}>
                                <Field
                                    disabled={disabled}
                                    type="text"
                                    name={`${field}.externalRef`}
                                    validate={[Validation.required]}
                                    label="Ticket Number"
                                    component={TextInputComponent}
                                    needShowEndAdornment={false}
                                    className={clsx(classes.externalRefValue)}
                                />
                            </Grid>
                            {showCloseIcon && (
                                <Grid container item xs={1} justify="center" alignItems="center">
                                    <CloseIcon
                                        color={"action"}
                                        fontSize={"default"}
                                        cursor={"pointer"}
                                        onClick={() => removeField(index, field)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <br />
                    </Grid>
                );
            })}

            <ButtonsGroup>
                <SecondaryButton
                    size="small"
                    style={{ flex: 1, height: 35 }}
                    onClick={addField}
                    disabled={disabled || isMaxAmountOfRefs}
                >
                    <AddCircleOutlineIcon />
                    Add Ticket Number
                </SecondaryButton>
            </ButtonsGroup>
        </div>
    );
};

export default AdditionalExternalRefs;
