import dataProvider from "../helpers/api";
import _ from "lodash";
import { GLOBAL_COUNT_TO_LOAD, PROJECTS, PURCHASE_ORDERS } from "../constants/endpoints";
import { FILTERS_STATUS_ID } from "../components/global/searchAndFilters";

export const getProjects = (params = {}) => {
    if (params.isProjectPage !== undefined) {
        delete params.isProjectPage;
    }
    if (params.project !== undefined) {
        delete params.project;
    }
    if (params.withNewProject !== undefined) {
        delete params.withNewProject;
    }

    return dataProvider({
        url: PROJECTS.PROJECTS_LIST_API_2(),
        method: "GET",
        params: {
            ...params,
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            [FILTERS_STATUS_ID]: !_.isEmpty(params[FILTERS_STATUS_ID]) ? params[FILTERS_STATUS_ID] : undefined,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const createProject = (data) => {
    return dataProvider({
        url: PROJECTS.PROJECT(),
        method: "POST",
        data,
    });
};

export const getProjectById = (projectId, params = {}) => {
    return dataProvider({
        url: PROJECTS.PROJECT_BY_ID(projectId),
        method: "GET",
        params,
    });
};

export const updateProject = (projectId, data) => {
    return dataProvider({
        url: PROJECTS.PROJECT_BY_ID_API_1(projectId),
        method: "PUT",
        data,
    });
};

export const getProjectAllowedCompanies = (projectId, params = {}) => {
    return dataProvider({
        url: PROJECTS.PROJECT_ALLOWED_COMPANIES(projectId),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const updateProjectAllowedCompanies = (projectId, data = {}) => {
    return dataProvider({
        url: PROJECTS.PROJECT_ALLOWED_COMPANIES(projectId),
        method: "PUT",
        data,
    });
};

export const archiveProject = (projectId) => {
    return dataProvider({
        url: PROJECTS.PROJECT_ARCHIVE_BY_ID(projectId),
        method: "POST",
    });
};

export const unArchiveProject = (projectId) => {
    return dataProvider({
        url: PROJECTS.PROJECT_UNARCHIVE_BY_ID(projectId),
        method: "POST",
    });
};

export const createNewPayload = (data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.PAYLOADS(),
        method: "POST",
        data,
    });
};

export const getCompanyRegions = (companyId) => {
    return dataProvider({
        url: PROJECTS.PROJECT_COMPANY_REGIONS(companyId),
        method: "GET",
    });
};
