import React from "react";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import SearchAndFilters from "../global/searchAndFilters";
import PropTypes from "prop-types";

const SettingsSearch = ({ form, updateListByFilters, withoutSearchRules, timeout }) => {
    return (
        <SearchAndFilters
            form={form}
            updateList={updateListByFilters}
            withoutSearchRules={withoutSearchRules}
            timeout={timeout}
        />
    );
};

SettingsSearch.propTypes = {
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    withoutSearchRules: PropTypes.bool,
    timeout: PropTypes.number,
};

export default withRouter(reduxForm({})(SettingsSearch));
