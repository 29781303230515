import { actions } from "../reducers/subCompaniesReducer";
import { fetchSubCompanies } from "../dataServers/companies";

export const getAllSubCompanies = () => async (dispatch, getState) => {
    try {
        const {
            meta: { count },
        } = await fetchSubCompanies({ perPage: 1 });
        const nextRequestParams = {
            perPage: count,
        };
        const { data } = await fetchSubCompanies(nextRequestParams);

        dispatch(actions.updateAllSubCompanies(data));
    } catch (e) {}
};
