import { PICKUP_DISTANCE } from "../../src/constants/global";

const ascendingParamsOnly = [PICKUP_DISTANCE];

export const getActiveOrderParam = (updatedParam, activeParam) => {
    if (activeParam && updatedParam === activeParam && !ascendingParamsOnly.includes(activeParam)) {
        return `-${updatedParam}`;
    }

    return updatedParam;
};
