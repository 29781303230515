import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorNotification from "../../core/notification";
import { selectUnitsOfMeasure } from "../../../reducers/unitsOfMeasureReducer";
import _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import TableWithStickyHeader from "../../core/tableWithStickyHeader";
import { makeStyles } from "@material-ui/core";
import { UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR } from "../../../constants/maps";
import { getHoursQty } from "../components/ticketCard";
import { getUnitOfMeasureLabel } from "../../../helpers/tickets";

const useStyles = makeStyles(() => ({
    ticketsList: {
        "& .full-table": {
            width: "100%",
        },
    },
}));

const AirTicketsStatsList = (props) => {
    const { tickets, ticketsCount, fetchMore } = props;
    const classes = useStyles();
    const [error, setError] = useState(false);
    const unitsOfMeasure = useSelector(selectUnitsOfMeasure);
    const getTableData = () => {
        const rows = tickets.map((ticket, index) => {
            const { payload, numberOfLoads, totalQuantity, paymentQuantity, jobNumber, unitOfMeasure } = ticket;

            return {
                index,
                job: <div className="td --uppercase">{jobNumber || "N/A"}</div>,
                item: <div className="td --uppercase">{payload || "N/A"}</div>,
                numberOfLoads: numberOfLoads || "N/A",
                totalQty: totalQuantity
                    ? unitOfMeasure === UNIT_OF_MEASURE_HOUR
                        ? getHoursQty(totalQuantity)
                        : `${(+totalQuantity).toFixed(2)} ${
                              UNIT_OF_MEASURE_BY_ID[unitOfMeasure] ||
                              getUnitOfMeasureLabel(unitsOfMeasure, unitOfMeasure)
                          }s`
                    : "N/A",
                qtyForPayment: paymentQuantity
                    ? unitOfMeasure === UNIT_OF_MEASURE_HOUR
                        ? getHoursQty(paymentQuantity)
                        : `${(+paymentQuantity).toFixed(2)} ${
                              UNIT_OF_MEASURE_BY_ID[unitOfMeasure] ||
                              getUnitOfMeasureLabel(unitsOfMeasure, unitOfMeasure)
                          }s`
                    : "N/A",
            };
        });
        let columns = [
            { id: "job", label: "Job" },
            { id: "item", label: "Item" },
            { id: "numberOfLoads", label: "Number of Loads" },
            { id: "totalQty", label: "Total Quantity" },
            { id: "qtyForPayment", label: "Quantity For Payment" },
        ];

        return { rows: rows, columns: columns };
    };

    return (
        <div style={{ overflowY: "scroll", maxHeight: "55vh" }} className={classes.ticketsList}>
            {!_.isEmpty(tickets) ? (
                <TableWithStickyHeader
                    rows={getTableData().rows}
                    className={"full-table"}
                    fetchMore={fetchMore}
                    itemsCount={ticketsCount}
                    columns={getTableData().columns}
                />
            ) : (
                <React.Fragment>
                    <br />
                    <h2 className="--text-center --text-h1-size">No data match your search / filter parameters.</h2>
                    <br />
                    <br />
                </React.Fragment>
            )}
            {error && <ErrorNotification message={error} config={{ onClose: () => setError(null) }} />}
        </div>
    );
};

AirTicketsStatsList.propTypes = {
    tickets: PropTypes.array.isRequired,
    ticketsCount: PropTypes.number.isRequired,
    fetchMore: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    compose(
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(AirTicketsStatsList),
);
