import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TableWithStickyHeader from "../../core/tableWithStickyHeader";
import Container from "@material-ui/core/Container";
import { PAGE_GLOBAL_STYLES } from "../../../styles/reusableStyles";
import { IS_INSPECTOR_USER } from "../../../constants/maps";
import { PROFILE_MEASURE_SYSTEM } from "../../../constants/accounts";
import { selectMeasureSystem } from "../../../selectors/index";
import { getHoursQty } from "../../tickets/components/ticketCard";
import { REPORTING_FILTERS_PROJECT_ID, REPORTING_FILTERS_TRUCK_ID } from "./constants";
import { timeFromSeconds } from "../../../helpers/jobOrders";
import makeStyles from "@material-ui/core/styles/makeStyles";

const TABLE_KEY_ROW_NAME = "rowName";
const TABLE_KEY_TONS = "completedTons";
const TABLE_KEY_LOADS = "completedLoads";
const TABLE_KEY_HRS = "completedHours";
const TABLE_KEY_COST = "cost";
const TABLE_KEY_COST_TON = "costPerTon";
const TABLE_KEY_COST_MILE = "costPerMile";
const TABLE_KEY_COST_HR = "costPerHour";
const TABLE_KEY_AVG_TRUCK_DAY = "avgTrucksPerDay";
const TABLE_KEY_AVG_LOAD_DAY = "avgLoadsPerDay";
const TABLE_KEY_ACTIVE_DAYS = "activeDays";
const TABLE_KEY_TURN_TIME_AVG = "avgTurnTimes";
const TABLE_KEY_PU_AVG = "avgPickupTime";
const TABLE_KEY_EN_ROUTE_AVG = "avgEnRouteTime";
const TABLE_KEY_DO_AVG = "avgDropoffTime";

export const REPORT_TABLE_HEADER_FIRST_ROW_HEIGHT = 114;

const useStyles = makeStyles(() => ({
    reportTableContainer: { maxHeight: "400px", overflowX: "hidden" },
}));

const TABLE_COLUMNS_SET = ({ isLimit, isInspector = false, tonLabel, mileLabel }) => [
    { id: TABLE_KEY_ROW_NAME, label: "" },
    { id: TABLE_KEY_TONS, label: `${tonLabel}S` },
    { id: TABLE_KEY_LOADS, label: "Loads" },
    { id: TABLE_KEY_HRS, label: "Hrs" },
    { id: TABLE_KEY_COST, label: "Cost", isInspector },
    { id: TABLE_KEY_COST_TON, label: `Cost/ ${tonLabel}`, isInspector },
    { id: TABLE_KEY_COST_MILE, label: mileLabel, isInspector },
    { id: TABLE_KEY_COST_HR, label: "Cost/ hr", isInspector },
    { id: TABLE_KEY_AVG_TRUCK_DAY, label: "Avg. trucks/ day", isLimit },
    { id: TABLE_KEY_AVG_LOAD_DAY, label: "Avg. load/ day", isLimit },
    { id: TABLE_KEY_ACTIVE_DAYS, label: "Active Days", isLimit },
    { id: TABLE_KEY_TURN_TIME_AVG, label: "Turn time (avg.)" },
    { id: TABLE_KEY_PU_AVG, label: "P/U (avg.)" },
    { id: TABLE_KEY_EN_ROUTE_AVG, label: "En route (avg.)" },
    { id: TABLE_KEY_DO_AVG, label: "D/O (avg.)" },
];

const ReportTable = (props) => {
    const {
        mainFilter,
        groupBy,
        reportData,
        totalsData,
        loadMoreReportData,
        reportDataCount,
        account,
        measureSystem,
        activeTab,
    } = props;
    const wrapper = PAGE_GLOBAL_STYLES();
    const classes = useStyles();

    const getUomFormTon = () => {
        switch (measureSystem) {
            case PROFILE_MEASURE_SYSTEM.IMPERIAL:
                return "TON";
            case PROFILE_MEASURE_SYSTEM.METRIC:
                return "TONNE";
            default:
                return "TONNE";
        }
    };

    const getUomForMile = () => {
        switch (measureSystem) {
            case PROFILE_MEASURE_SYSTEM.IMPERIAL:
                return "Cost/ mile";
            case PROFILE_MEASURE_SYSTEM.METRIC:
                return "Cost/ km";
            default:
                return "Cost/ km";
        }
    };

    const hasActions = (value) => (value < 0 ? "N/A" : `$${value}`);

    const getTableData = (data) => {
        const rows = data.map((item, index) => {
            const hasActions = (value) => (value < 0 ? "N/A" : `$${value}`);
            const {
                [TABLE_KEY_TONS]: tons,
                [TABLE_KEY_LOADS]: loads,
                [TABLE_KEY_HRS]: hours,
                [TABLE_KEY_COST]: cost,
                [TABLE_KEY_COST_TON]: costTon,
                [TABLE_KEY_COST_MILE]: costMile,
                [TABLE_KEY_COST_HR]: costHr,
                [TABLE_KEY_AVG_TRUCK_DAY]: truckAvg,
                [TABLE_KEY_AVG_LOAD_DAY]: loadAvg,
                [TABLE_KEY_ACTIVE_DAYS]: activeDays,
                [TABLE_KEY_TURN_TIME_AVG]: turnTime,
                [TABLE_KEY_PU_AVG]: puAvg,
                [TABLE_KEY_EN_ROUTE_AVG]: enRouteAvg,
                [TABLE_KEY_DO_AVG]: doAvg,
                [TABLE_KEY_ROW_NAME]: rowName,
            } = item;

            return {
                index,
                [TABLE_KEY_ROW_NAME]: rowName,
                [TABLE_KEY_TONS]: tons,
                [TABLE_KEY_LOADS]: loads,
                [TABLE_KEY_HRS]: hours ? getHoursQty(hours) : 0,
                [TABLE_KEY_COST]: `$${cost || 0}`,
                [TABLE_KEY_COST_TON]: hasActions(costTon),
                [TABLE_KEY_COST_MILE]: hasActions(costMile),
                [TABLE_KEY_COST_HR]: hasActions(costHr),
                [TABLE_KEY_AVG_TRUCK_DAY]: truckAvg,
                [TABLE_KEY_AVG_LOAD_DAY]: loadAvg,
                [TABLE_KEY_ACTIVE_DAYS]: activeDays,
                [TABLE_KEY_TURN_TIME_AVG]: timeFromSeconds(+turnTime, true, "m", "N/A"),
                [TABLE_KEY_PU_AVG]: timeFromSeconds(+puAvg, true, "m", "N/A"),
                [TABLE_KEY_EN_ROUTE_AVG]: timeFromSeconds(+enRouteAvg, true, "m", "N/A"),
                [TABLE_KEY_DO_AVG]: timeFromSeconds(+doAvg, true, "m", "N/A"),
            };
        });
        const isLimit =
            mainFilter === REPORTING_FILTERS_TRUCK_ID ||
            mainFilter === REPORTING_FILTERS_PROJECT_ID ||
            groupBy === REPORTING_FILTERS_TRUCK_ID ||
            groupBy === REPORTING_FILTERS_PROJECT_ID;
        let columns = TABLE_COLUMNS_SET({
            isLimit,
            isInspector: IS_INSPECTOR_USER(account.role),
            mileLabel: getUomForMile(),
            tonLabel: getUomFormTon(),
        });

        columns = columns.filter((i) => !(i.isLimit || i.isInspector));

        return { rows: [...[{}, getTotalsAmount()], ...rows], columns };
    };

    const getTotalsAmount = (data) => {
        if (!totalsData) return {};
        const {
            [TABLE_KEY_TONS]: tons,
            [TABLE_KEY_LOADS]: loads,
            [TABLE_KEY_HRS]: hours,
            [TABLE_KEY_COST]: cost,
            [TABLE_KEY_COST_TON]: costTon,
            [TABLE_KEY_COST_MILE]: costMile,
            [TABLE_KEY_COST_HR]: costHr,
            [TABLE_KEY_AVG_TRUCK_DAY]: truckAvg,
            [TABLE_KEY_AVG_LOAD_DAY]: loadAvg,
            [TABLE_KEY_ACTIVE_DAYS]: activeDays,
            [TABLE_KEY_TURN_TIME_AVG]: turnTime,
            [TABLE_KEY_PU_AVG]: puAvg,
            [TABLE_KEY_EN_ROUTE_AVG]: enRouteAvg,
            [TABLE_KEY_DO_AVG]: doAvg,
            [TABLE_KEY_ROW_NAME]: rowName,
        } = totalsData;

        return {
            [TABLE_KEY_ROW_NAME]: rowName,
            [TABLE_KEY_TONS]: tons,
            [TABLE_KEY_LOADS]: loads,
            [TABLE_KEY_HRS]: hours ? getHoursQty(hours) : 0,
            [TABLE_KEY_COST]: `$${cost || 0}`,
            [TABLE_KEY_COST_TON]: hasActions(costTon),
            [TABLE_KEY_COST_MILE]: hasActions(costMile),
            [TABLE_KEY_COST_HR]: hasActions(costHr),
            [TABLE_KEY_AVG_TRUCK_DAY]: truckAvg || 0,
            [TABLE_KEY_AVG_LOAD_DAY]: loadAvg || 0,
            [TABLE_KEY_ACTIVE_DAYS]: activeDays,
        };
    };
    const tableData = getTableData(reportData);

    return (
        <div>
            {!_.isEmpty(reportData) ? (
                <TableWithStickyHeader
                    tableContainerClasses={classes.reportTableContainer}
                    headerRowHeight={REPORT_TABLE_HEADER_FIRST_ROW_HEIGHT}
                    rows={tableData.rows}
                    fetchMore={loadMoreReportData}
                    itemsCount={reportDataCount}
                    totalIndex={1}
                    columns={tableData.columns}
                />
            ) : (
                !activeTab && (
                    <Container className={wrapper.globalPageStyles}>
                        <br />
                        <h2 className={"--text-center"}>No data for selected filters</h2>
                        <br />
                    </Container>
                )
            )}
        </div>
    );
};

ReportTable.propTypes = {
    account: PropTypes.object.isRequired,
    reportData: PropTypes.array.isRequired,
    mainFilter: PropTypes.string.isRequired,
    reportDataCount: PropTypes.number.isRequired,
    loadMoreReportData: PropTypes.func.isRequired,
};

ReportTable.defaultProps = {};

export default withRouter(
    connect((state) => ({
        account: state.account,
        measureSystem: selectMeasureSystem(state),
    }))(ReportTable),
);
