/* global google */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { MAP } from "react-google-maps/lib/constants";
import { withGoogleMap, withScriptjs, GoogleMap, Polyline } from "react-google-maps";

import {
    GOOGLE_MAPS_URL,
    LOADER_WHITE_OVERLAY,
    ATLANTA_COORDINATES,
    PROCESS_SERVER_ERROR,
    SERVER_DATE_FORMAT,
    GOOGLE_MAPS_SITE_ICON_SIZE,
} from "../../constants/global";

import clsx from "clsx";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import DeviceCharts from "./deviceCharts";
import { getDeviceActivity } from "../../dataServers/devices";
import { formValueSelector } from "redux-form";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { DEVISES_FORM } from "./devisesFilters";
import Grid from "@material-ui/core/Grid";
import CustomMarker from "../global/maps/customMarker";
import redPushPin from "../../styles/images/pushpin-red.svg";
import greenPushPin from "../../styles/images/pushpin-green.svg";

let _map;

const DeviceMap = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const { deviceData } = props;
    const path = deviceData ? deviceData.map((i) => ({ lat: i.latitude, lng: i.longitude })) : [];
    const [mapCenter] = useState(ATLANTA_COORDINATES);

    useEffect(() => {
        if (!_.isEmpty(deviceData)) {
            const bounds = new google.maps.LatLngBounds();

            deviceData.forEach(({ latitude, longitude }) => {
                bounds.extend(new google.maps.LatLng(latitude, longitude));
            });

            _map && _map.context[MAP].fitBounds(bounds);
        }
    }, []);

    return (
        <GoogleMap center={mapCenter} defaultZoom={14} ref={(map) => (_map = map)}>
            {path && path[0] && (
                <CustomMarker
                    position={path[0]}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        url: greenPushPin,
                        zIndex: 3,
                    }}
                    opacity={1}
                />
            )}
            {!_.isEmpty(path) && (
                <Polyline
                    path={path}
                    options={{
                        strokeColor: "#73B9FF",
                        strokeOpacity: 0.7,
                        strokeWeight: 5,
                    }}
                />
            )}
            {path && path[path.length - 1] && (
                <CustomMarker
                    position={path[path.length - 1]}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        url: redPushPin,
                        zIndex: 3,
                    }}
                    opacity={1}
                />
            )}
        </GoogleMap>
    );
});

const DeviceDetails = (props) => {
    const {
        height,
        device,
        filterFormValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate },
    } = props;
    const [isLoading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [deviceData, setDeviceData] = useState(null);
    const dataForOneDate =
        !_.isEmpty(deviceData) &&
        (deviceData.length > 1
            ? deviceData.every((i) => {
                  return (
                      moment(i.deviceTransmitTimestamp).format(SERVER_DATE_FORMAT) ===
                      moment(deviceData[0].deviceTransmitTimestamp).format(SERVER_DATE_FORMAT)
                  );
              })
            : deviceData.length === 1);

    useEffect(() => {
        setLoading(true);

        getDeviceActivity(device.deviceId, {
            [START_DATE_NAME]: moment(startDate).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: moment(endDate).format(SERVER_DATE_FORMAT),
        })
            .then(({ data }) => {
                setDeviceData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    }, []);

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            <h1 className="--text-center">
                Device #{device.deviceId}
                {dataForOneDate && ` for ${moment(deviceData[0].deviceTransmitTimestamp).format(SERVER_DATE_FORMAT)}`}
            </h1>
            <br />
            <div style={{ height: 650 }}>
                {!_.isEmpty(deviceData) ? (
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <DeviceMap
                                googleMapURL={GOOGLE_MAPS_URL}
                                device={device}
                                deviceData={deviceData}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: height }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DeviceCharts deviceData={deviceData} />
                        </Grid>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <h2 className="--text-center">No data for device for selected date range</h2>
                        <br />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

DeviceDetails.propTypes = {
    height: PropTypes.string,
    device: PropTypes.object.isRequired,
    filterFormValues: PropTypes.object.isRequired,
};

DeviceDetails.defaultProps = {
    height: "650px",
    device: {},
};

export default withRouter(
    connect((state) => {
        const formSelector = formValueSelector(DEVISES_FORM);

        return {
            account: state.account,
            filterFormValues: formSelector(state, START_DATE_NAME, END_DATE_NAME),
        };
    })(DeviceDetails),
);
