import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { dropItemTypes } from "../../helpers/jobOrders";

const useStyles = makeStyles((theme) => ({
    truckContainer: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: theme.shape.borderRadius * 3,
        backgroundColor: theme.palette.general.softOrange,
        margin: 5,
        padding: 5,
    },
}));

const HaulerTrucksListItem = ({ truck, handleDropEnd, haulerDefaultRate }) => {
    const classes = useStyles();
    const droppedTruck = {
        ...truck,
        id: truck.id,
        isTruck: !!truck.truckName,
        defaultRate: haulerDefaultRate,
        type: dropItemTypes.TRUCK,
        truckShiftAllowed: true,
        isHaulerTruck: true,
        deviceName: truck.truckName,
    };
    const [{ isDragging }, drag] = useDrag({
        item: droppedTruck,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (item && dropResult) {
                handleDropEnd([droppedTruck], item, dropResult);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div className={clsx(classes.truckContainer)} ref={drag}>
            {truck.truckName}
        </div>
    );
};

export default HaulerTrucksListItem;
