import React from "react";
import { makeStyles } from "@material-ui/core";
import TableHeaderSortingCell from "./tableHeaderSortingCell";
import TableHead from "@material-ui/core/TableHead";
import clsx from "clsx";
import { LOADER_OVERLAY_WITHOUT_WHITE_BACKGROUND } from "../../constants/global";

const useStyles = makeStyles((theme) => ({
    sortContainer: {
        display: "flex",
        justifyContent: "space-around",
        borderBottom: `1px solid #979797`,
        marginBottom: 12,
    },
}));

const SortParams = ({ columns, handleSort, activeOrder, isLoading }) => {
    const classes = useStyles();

    if (columns && !columns.length) return null;

    return (
        <TableHead className={clsx(classes.sortContainer, isLoading && LOADER_OVERLAY_WITHOUT_WHITE_BACKGROUND)}>
            {columns.map(
                (column, index) =>
                    column && (
                        <TableHeaderSortingCell
                            key={index}
                            align={"center"}
                            handleSort={handleSort}
                            sortParam={column.sortParam}
                            label={column.label}
                            direction={column.direction}
                            borderBottom={"none"}
                            padding={"0 5px 5px 10px"}
                            activeOrderParam={activeOrder}
                        />
                    ),
            )}
        </TableHead>
    );
};

export default SortParams;
