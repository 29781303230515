import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";

import { SimpleLink } from "../../../core";
import { getProjectEditRoute } from "../../../../helpers/global";
import { selectCompanyId, selectUserAccountRole } from "../../../../selectors";
import { IS_ADMIN_USER, IS_INSPECTOR_USER } from "../../../../constants/maps";

const useStyles = makeStyles((theme) => ({
    projectLink: {
        fontSize: "1em",
    },
}));

export const getCanSeeProject = (role, jobCompanyId, companyId, grantedAccess) => {
    if (IS_ADMIN_USER(companyId)) {
        return true;
    }
    if (IS_INSPECTOR_USER(role)) {
        return false;
    }
    if (grantedAccess) {
        return true;
    }

    return jobCompanyId === companyId;
};
const ProjectLink = ({ project, jobCompanyId, grantedAccess }) => {
    const classes = useStyles();
    const companyId = useSelector(selectCompanyId);
    const role = useSelector(selectUserAccountRole);
    const canSeeProject = getCanSeeProject(role, jobCompanyId, companyId, grantedAccess);

    const projectLabel = project
        ? `${project.externalJobNumber ? `${project.externalJobNumber} - ` : ""} ${project.name}`
        : "";

    if (!canSeeProject) {
        return <div className={classes.projectLink}>{projectLabel}</div>;
    }

    return (
        <>
            {project && "Project "}
            <SimpleLink
                to={{
                    pathname: getProjectEditRoute(project.id),
                    state: { project: { id: project.id } },
                }}
                className={classes.projectLink}
            >
                {projectLabel}
            </SimpleLink>
        </>
    );
};

ProjectLink.propTypes = {
    project: PropTypes.object,
    jobCompanyId: PropTypes.number,
    grantedAccess: PropTypes.bool,
};

export default ProjectLink;
