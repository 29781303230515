import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getCompanies } from "../../dataServers/companies";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { grantAccessTruck } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import AppModal from "../core/modal";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { loadCompanyTrucks } from "../../actions/trucks";
import ErrorNotification from "../core/notification";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

const GrantAccessDialog = (props) => {
    const {
        accessDialogOpen,
        handleClose,
        truckForEdit,
        formValues: { company },
        loadTrucksData,
        trucksFilters,
        handleSubmit,
        setFieldValue,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [error, setError] = useState(false);
    const onSubmit = () => {
        const params = {
            companyId: company ? company.value : null,
        };
        const truckId = truckForEdit.id;

        grantAccessTruck(truckId, params)
            .then(() => {
                handleClose();
                setLoading(false);
                setIsHandledSuccessfully(true);
                loadTrucksData(trucksFilters);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    useEffect(() => {
        if (accessDialogOpen) {
            const value = truckForEdit.grantedCompany
                ? { label: truckForEdit.grantedCompany.name, value: truckForEdit.grantedCompany.id }
                : "";
            setFieldValue("company", value);
        }
    }, [accessDialogOpen]);

    const loadCompaniesOptions = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: i.name }));
            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: nextPage,
            };
        });
    };

    return (
        <React.Fragment>
            <AppModal isOpen={accessDialogOpen} form={GRAND_ASSESS_FORM} closeModal={handleClose}>
                <form
                    noValidate={true}
                    onSubmit={handleSubmit(onSubmit)}
                    className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                >
                    {isLoading && <Loader />}
                    <h2 className="title">GRANT ACCESS</h2>
                    <Field
                        name="company"
                        placeholder="Select Company"
                        isClearable={true}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        loadOptions={loadCompaniesOptions}
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </ButtonsGroup>
                </form>
            </AppModal>
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </React.Fragment>
    );
};

GrantAccessDialog.propTypes = {
    accessDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loadTrucksData: PropTypes.func.isRequired,
    truckForEdit: PropTypes.object.isRequired,
    trucksFilters: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export const GRAND_ASSESS_FORM = "grandAccess";

export default withRouter(
    compose(
        reduxForm({
            form: GRAND_ASSESS_FORM,
        }),
        connect(
            (state, props) => {
                const formSelector = formValueSelector(props.form);

                return {
                    formValues: formSelector(state, "id", "company"),
                };
            },
            (dispatch) => ({
                loadTrucksData: (params) => {
                    dispatch(loadCompanyTrucks(params));
                },
                setFieldValue: (field, value) => dispatch(change(GRAND_ASSESS_FORM, field, value)),
            }),
        ),
    )(GrantAccessDialog),
);
