import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

const SuccessNotification = (props) => {
    const { enqueueSnackbar, message, config } = props;

    if (message) {
        enqueueSnackbar(message, { variant: "success", ...config });
    }

    return <div />;
};

SuccessNotification.propTypes = {
    config: PropTypes.object.isRequired,
    message: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
    error: PropTypes.array,
    type: PropTypes.oneOf(["default", "success", "error", "info"]),
};

SuccessNotification.defaultProps = {
    config: {},
    error: [],
    type: "error",
};

export default withSnackbar(SuccessNotification);
