export const GLOBAL_COUNT_TO_LOAD = 20;
export const AIR_TICKETS_COUNT_TO_LOAD = 20;

export const GLOBAL_ENDPOINTS = {
    TERMS: () => `1/terms`,
    IOT_DEVICES: () => "1/iot-devices",
    IOT_DEVICE_ACTIVITY: (deviceId) => `1/iot-devices/${deviceId}/activity-details`,
    LINK_IOT_TO_TRUCK: () => `1/iot-device/link`,
    UNLINK_IOT_DEVICE: () => `1/iot-device/unlink`,
    TERMS_AND_CONDITIONS: (randomDigit) =>
        `https://s3.amazonaws.com/ti-t-and-c/TermsAndConditions_Latest.html?randomDigitToPreventCaching=${randomDigit}`,
    TIME_ZONES: () => `/1/timezones`,
    TIMEZONES: () => "/2/time/timezones",
    GET_STATES: () => "/1/states",
    FILES: (fileId) => `/2/files/${fileId}`,
};

export const EVENTS = {
    NOTIFICATION_EVENTS: () => `1/notification-events`,
    NOTIFICATIONS_SETTINGS_ZONES: () => `2/regions/notifications-zones`,
};

export const USER = {
    SIGN_IN: () => "2/signin",
    SIGN_IN_AS_OTHER_COMPANY_USER: (userId) => `2/signin/${userId}`,
    SIGN_UP: () => "/1/signup",
    SIGN_OUT: () => "/1/signout",
    ALL: () => "1/users",
    INSPECTORS: () => "2/users/inspectors",
    FORGOT_PASSWORD: () => "1/restore",
    UPDATE_PASSWORD: () => "1/password",
    USER_PROFILE: (companyId) => `1/companies/${companyId}/profile`,
    STRIPE_SUBSCRIPTION: (companyId) => `2/companies/${companyId}/stripe-subscription`,
    CERTIFICATE: (companyId) => `1/companies/${companyId}/cert-upload`,
    FILTERS_TRUCKERS: () => "1/filters/truckers",
    FILTERS_TRUCKERS_API_2: () => "2/filters/truckers",
    FILTERS_TRUCKERS_DEFAULT: () => "1/filters/truckers/default-filter",
    NOTIFICATIONS: (userId) => `2/profile/${userId}/notifications`,
    COMPANY_NOTIFICATIONS: (companyId) => `1/companies/${companyId}/notification-profile`,
    ASSIGN_REGION: (userId) => `1/users/${userId}/assign-region`,
    INVITE_HAULER: () => `1/invite-hauler`,
    ACCEPT_TERMS_AND_CONDITIONS: () => "1/users/current/terms-and-conditions/agree",
    GET_CURRENT_USER_DATA: () => "1/users/current",
    ACTIVITY: (userId) => `2/users/${userId}/activity`,
};

export const TRUCKS = {
    COMPANY_TRUCK_TYPES: (companyId) => `2/trucks/truck-types/${companyId}`,
    ARCHIVE_TRUCK_TYPE: (truckTypeId) => `1/truck-types/${truckTypeId}/archive`,
    UNARCHIVE_TRUCK_TYPE: (truckTypeId) => `1/truck-types/${truckTypeId}/unarchive`,
    TRUCK_TYPE: (truckTypeId) => `1/truck-types/${truckTypeId}`,
    DRIVERS_WITH_LINK_EVENTS: (truckId) => `2/trucks/${truckId}/drivers`,
};

export const JOB_ORDERS = {
    JOB_ORDERS: () => `2/job-orders`,
    INSPECTOR_JOB_ORDERS: () => `2/job-orders/inspector-jobs`,
    JOB_BOARD: () => `2/job-board/job-orders`,
    NEXT: (id) => `1/job-orders/next?truck_id=${id}`,
    JOB_BOARD_FOR_TICKETS: () => `2/job-board`,
    JOB_ORDER_BY_ID: (id) => `2/job-orders/${id}`,
    JOB_BOARD_ITEM_BY_ID: (id) => `1/job-board/${id}/details`,
    JOB_ORDER_CLOSE: (id) => `1/job-orders/${id}/close`,
    JOB_ORDER_REOPEN: (id) => `2/job-orders/${id}/reopen`,
    JOB_ORDER_COPY: (id) => `2/job-orders/${id}/copy`,
    JOB_BOARD_JOB_ORDER_JOLIS: (id) => `2/job-board/job-orders/${id}/items`,
    JOB_ORDER_ETA: (id) => `2/trucks/estimated-time`,
    ACTIVATE_TRUCK_BY_ID: (truckId) => `1/trucks/${truckId}/current-job`,
    SUSPEND_TRUCK_BY_ID: (truckId) => `1/trucks/${truckId}/suspend`,
    UNSUSPEND_TRUCK_BY_ID: (truckId) => `1/trucks/${truckId}/unsuspend`,
    MAKE_TRUCK_MOVE: () => `1/demo/simulate-movement`,
    MAKE_DEMO_JOB: () => `1/demo/simulate-job`,
    MAKE_DEMO_AIRTICKET: () => `1/demo/simulate-airticket`,
    JOB_ACCEPTED: (joliId, truckId) => `1/job-orders/${joliId}/accept/${truckId}`,
    JOB_ORDER_TRUCKS_TAB: (joId) => `2/job-orders/${joId}/trucks`,
    JOB_ORDER_TRUCKS_ACTIVITY: (joId) => `2/job-orders/${joId}/trucks-activity`,
    JOB_ORDER_TRUCKS_FORECAST: (joId) => `2/job-orders/trucks-forecast`,
    JOB_ORDER_STATS_TAB: (joId) => `2/job-orders/${joId}/turntimes`,
    JOB_ORDER_MAP: (joId) => `2/job-orders/${joId}/map-details`,
    JOB_ORDER_ROUTES: (joId) => `2/job-orders/${joId}/routes`,
    JOB_TRUCKS: (joliId) => `1/jo-items/${joliId}/trucks`,
    MESSAGE_DRIVERS: (jobId) => `2/job-orders/${jobId}/notify`,
    COUNT: () => `2/job-orders/count`,
    JOB_ORDER_TEMPLATE_COPY_SCHEDULE: (orderId) => `3/job-orders/${orderId}`,
    JOB_ORDER_PARTIAL_UPDATE: (orderId) => `3/job-orders/${orderId}`,
    SMART_DISPATCH: () => `2/smart-dispatch`,
    SMART_DISPATCH_BUNDLE_ID: (bundleId) => `2/job-orders/smart-dispatch/${bundleId}`,
    SMART_DISPATCH_BUNDLE_ID_ROUTE_ID: (bundleId, routeId) => `2/smart-dispatch/${bundleId}/${routeId}`,
    SMART_DISPATCH_BUNDLE_ROUTE_INDEX: (bundleId, truckIndex) => `2/smart-dispatch/${bundleId}/${truckIndex}`,
    SMART_DISPATCH_ROUTES: () => `2/smart-dispatch/routes`,
    SMART_DISPATCH_BUNDLE_CONFIRM: (bundleId) => `2/smart-dispatch/${bundleId}/confirm`,
    SMART_DISPATCH_RECALCULATE_BUNDLE: (bundleId) => `2/smart-dispatch/${bundleId}/recalculate`,
    TRAILERS_HISTORY: (joId) => `1/job-orders/${joId}/trailers-history`,
    JOB_ORDER_SPLIT: (jobOrderId) => `1/job-orders/${jobOrderId}/split`,
};

export const SMART_DISPATCH = {
    REPLACE_TRUCK: (bundleId) => `2/smart-dispatch/intra-day/${bundleId}`,
};

export const PROJECTS = {
    PROJECTS_LIST_API_1: () => `1/projects`,
    PROJECTS_LIST_API_2: () => `2/projects`,
    PROJECT_BY_ID_API_1: (id) => `1/projects/${id}`,
    PROJECT_BY_ID: (id) => `2/projects/${id}`,
    PROJECT: () => "2/projects",
    PROJECT_ALLOWED_COMPANIES: (id) => `1/projects/${id}/allowed-companies`,
    PROJECT_UNARCHIVE_BY_ID: (id) => `2/projects/${id}/unarchive`,
    PROJECT_ARCHIVE_BY_ID: (id) => `1/projects/${id}/archive`,
    PROJECT_COMPANY_REGIONS: (companyId) => `1/companies/${companyId}/regions`,
};

export const COMPANIES = {
    REGION_BY_ID: (regionId) => `1/companies/regions/${regionId}`,
    SUB_COMPANIES: () => `2/companies/sub-companies`,
    RESTRICTED_CUSTOMERS: (companyId) => `1/companies/${companyId}/restricted-customers`,
    ADMINS: (companyId) => `1/companies/${companyId}/admins`,
};

export const SITES = {
    SITES_LIST: () => `1/sites`,
    SITE_BY_ID: (id) => `1/sites/${id}`,
    SITE_STATS_BY_ID: (id) => `2/sites/${id}/stats`,
    SITE_STATS_DAILY_LOADS: (id) => `2/sites/${id}/stats/daily-loads`,
    ALERT_ZONES: () => `1/regions`,
    ALERT_ZONE_BY_ID: (id) => `2/regions/${id}`,
    CALCULATE_DISTANCE: (startSiteId, finishSiteId) => `2/sites/from/${startSiteId}/to/${finishSiteId}/distance`,
};

export const PO_LINE_ITEMS = {
    PROJECT_PO_LINE_ITEMS: (id) => `2/projects/${id}/po-items`,
    PO_LINE_ITEM: (id) => `2/po-items/${id}`,
    PO_LINE_ITEM_ARCHIVE: (id) => `1/po-items/${id}/archive`,
    PO_LINE_ITEM_UNARCHIVE: (id) => `1/po-items/${id}/unarchive`,
};

export const PURCHASE_ORDERS = {
    CREATE_PURCHASE_ORDER: () => `1/purchase-orders`,
    LIST: () => `2/purchase-orders`,
    POLI: (poId) => `2/purchase-orders/${poId}/items`,
    PURCHASE_ORDER: (poId) => `1/purchase-orders/${poId}`,
    PAYLOADS: () => `/1/payloads`,
    COMPANY_PAYLOADS: (companyId) => `/2/payloads/${companyId}`,
    ARCHIVE_PAYLOAD: (payloadId) => `1/payloads/${payloadId}/archive`,
    UNARCHIVE_PAYLOAD: (payloadId) => `1/payloads/${payloadId}/unarchive`,
    PAYLOAD_DETAILS: (payloadId) => `2/payload/${payloadId}`,
    UNIT_OF_MEASURE_LIST: () => `1/units-of-measure`,
    SITES: () => `1/sites`, // pick-up drop-off
};

export const MY_FLEET = {
    TRUCKS: () => `1/trucks`,
    HAULERS: () => `2/companies`,
    PARENT_COMPANIES: () => `2/companies/parent-companies`,
    CUSTOMERS: (companyId) => `1/companies/${companyId}/customers`,
    COMPANIES_ACTIVITY: (companyId) => `2/companies/${companyId}/activity`,
    COMPANY_CUSTOMER: (companyId, customerId) => `1/companies/${companyId}/customers/${customerId}`,
    TRUCKSV2: () => `2/trucks`,
    TRUCKS_DISPATCH: () => `2/trucks/dispatch`,
    TRUCKS_COORDINATES: () => `2/trucks/coordinates`,
    TRUCK_TYPES: () => `1/trucks/truck-types`,
    EDIT_TRUCK: (truckId) => `1/trucks/${truckId}`,
    LIVEMAP: () => `2/trucks/livemap`,
    GET_TRUCK_BY_ID: (truckId) => `1/trucks/${truckId}`,
    ARCHIVE_TRUCK: (truckId) => `1/trucks/${truckId}`,
    GRANT_ACCESS: (truckId) => `1/trucks/${truckId}/grant`,
    REGIONS: () => `1/trucks/truck-regions`,
    ASSIGN_REGION: (truckId) => `1/trucks/${truckId}`,
    USERS_FOR_LINK: () => `1/users`,
    LINK_TRUCK_TO_USER: () => `2/device/link-user`,
    UNLINK_TRUCK: () => `2/device/unlink-user`,
    TRUCK_ACTIVITY: (truckId) => `2/trucks/${truckId}/activity`,
    TRUCK_DEVICE_NOTIFY: (deviceId) => `1/devices/${deviceId}/send-notification`,
};

export const MY_TEAM = {
    USERS: () => `1/users`,
    EDIT_USER: (userId) => `1/users/${userId}`,
    ARCHIVE_USER: (userId) => `1/users/${userId}`,
    UNARCHIVE_USER: (userId) => `1/users/${userId}/unarchive`,
    SEND_USER_MESSAGE: (userId) => `1/users/${userId}/send-msg`,
    SEND_MESSAGES: () => `1/users/send-group-msg`,
    GET_TRUCK_START_TIME: (truckId) => `1/trucks/${truckId}/start-time`,
};

export const ADMIN_ONLY = {
    COMPANIES_LIST_API_1: () => `1/companies`,
    CUSTOMERS: () => `1/customers`,
    COMPANY_BY_ID: (id) => `1/companies/${id}`,
    ARCHIVE_COMPANY: (id) => `1/companies/${id}/archive`,
    VALIDATE: (id) => `1/companies/${id}/validate`,
};

export const TICKETS = {
    TICKETS: () => `2/tickets`,
    TICKET_BY_ID: (id) => `2/tickets/${id}`,
    AIR_TICKETS: () => `2/air-tickets`,
    TICKETS_MATERIALS: () => `2/tickets/summary-material`,
    CLOSE: (id) => `2/tickets/${id}/close`,
    DELETE: (id) => `1/tickets/${id}/delete`,
    RECONCILE: (id) => `1/tickets/${id}/reconcile`,
    REOPEN: (id) => `2/tickets/${id}/reopen`,
    REPORT: () => `2/tickets/report`,
    AIR_TICKETS_REPORT: () => `2/air-tickets/report`,
    AIR_TICKETS_RECONCILE: (ticketId) => `2/air-tickets/${ticketId}/reconcile`,
    TIC_PICS: () => `2/tickets/tic-pics`,
    STATS: () => `2/tickets/stats`,
    MAP_DETAILS: (ticketId) => `2/tickets/${ticketId}/map-details`,
    COUNT: () => `2/tickets/count`,
    NOTES: (id) => `2/tickets/${id}/notes`,
    AIR_TICKET_NOTES: (id) => `2/air-ticket-lite/${id}/notes`,
    ATTACHMENTS: () => `2/attachments`,
    VERIFY: () => `2/tickets/verify`,
    SEARCH: () => `2/tickets/search`,
    LABELS: () => `1/ticket-labels`,
    MANUAL_TICKETS: () => `2/manual-tickets`,
};

export const WEATHER = {
    FORECAST: () => `1/weather/forecast`,
};

export const REPORT_AND_ANALYTICS = {
    TRUCK_ACTIVITY: (jobOrderId, truckId) => `2/job-orders/${jobOrderId}/trucks/${truckId}/analytics`,
    JOB_ORDER_TRUCKS_ACTIVITY: (jobOrderId) => `/api/2/job-orders/${jobOrderId}/trucks-activity`,
    SAVED_REPORT_FILTERS: () => `1/filters`,
    REPORT_FILTERS_MENU: (responseContent) => `1/filters/menu/${responseContent}`,
    REPORT_DATA: (mainFilter) => `2/tickets/report/${mainFilter}`,
    REPORT_DATA_PDF: (mainFilter) => `2/tickets/report/${mainFilter}`,
    TICKETS_REPORT_TOTALS: (attr) => `2/tickets/report/${attr}/totals`,
};

export const STRIPE = {
    CREATE_CUSTOMER: () => "1/payments/create-customer",
    CREATE_SUBSCRIPTION: () => "1/payments/create-subscription",
    UPDATE_PAYMENT_DETAILS: () => "1/payments/customer-portal",
    GET_PRICE_PLANS: () => "1/payments/price-plans",
};

export const REPORT = {
    GET_REPORTS_NAMES: () => "2/reports",
    RETRIEVE_REPORTS: (name) => `2/report/retrieve/${name}`,
};

export const FILTER = {
    PREFERRED_HAULERS: () => "1/filters/truckers/default-filter",
    PREFERRED_HAULER: (haulerId) => `2/filters/truckers/${haulerId}`,
};

export const SETTINGS = {
    ADD_OFFLINE_HAULER: () => "2/companies",
    UPDATE_COMPANY: (companyId) => `1/companies/${companyId}/profile`,
};

export const UNITS_OF_MEASURE = {
    UNITS_OF_MEASURE: () => "1/units-of-measure",
    UNITS_OF_MEASURE_ID: (uomId) => `1/units-of-measure/${uomId}`,
};

export const TRAILERS = {
    TRAILERS: () => "1/trailers",
    TRAILER_ID: (trailerId) => `1/trailer/${trailerId}`,
    TRAILERS_ARCHIVE: (trailerId) => `1/trailer/${trailerId}/archive`,
};
