import {
    HELP_JUICE_LINK,
    ROUTE_ANALYTICS,
    ROUTE_DASHBOARD,
    ROUTE_FAQ,
    ROUTE_JOBS,
    ROUTE_MY_COMPANY,
    ROUTE_PROJECTS,
} from "../routes/globalRoutes";
import _ from "lodash";
import { USER_ROLE_RESTRICTED_CUSTOMER } from "./maps";

export const PENDO_API_KEY = "78edf076-fcdd-4511-676a-7b82c2919110";
const RESERVE_API_KEY = "AIzaSyDb58Bb6LSyB0q_z68DFR5uZVx6wsidNKQ";
const _GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    : RESERVE_API_KEY;

export const ANALYTICS_NAV_ID = "analytics";
export const JOBS_NAV_ID = "jobs";
const ANALYTICS_NAV = { id: ANALYTICS_NAV_ID, path: ROUTE_ANALYTICS.TICKETS, title: "Reporting and Analytics" };

const jobsNav = (account) => ({
    id: JOBS_NAV_ID,
    path: account?.isFakeUser && account?.company?.hasOwnFleet ? ROUTE_JOBS.JOB_BOARD : ROUTE_JOBS.MY_JOBS,
    title: "Jobs",
});

export const appNavigation = (account) => [
    { id: "dashboard", path: ROUTE_DASHBOARD, title: "Dashboard" },
    jobsNav(account),
    { id: "projects-and-sites", path: ROUTE_PROJECTS.PROJECTS, title: "Projects and Sites" },
    ANALYTICS_NAV,
    {
        id: "my-company",
        path: account.role === USER_ROLE_RESTRICTED_CUSTOMER ? ROUTE_MY_COMPANY.MY_TEAM : ROUTE_MY_COMPANY.MY_FLEET,
        title: "My Company",
    },
    { id: ROUTE_FAQ, path: HELP_JUICE_LINK, title: "Resource Center", isExternal: true },
];

export const inspectorAppNavigation = (account) => [jobsNav(account), ANALYTICS_NAV];

export const ATLANTA_COORDINATES = {
    lat: 33.742322,
    lng: -84.393077,
};

export const GOOGLE_MAPS_URL =
    `https://maps.googleapis.com/maps/api/js?key=${_GOOGLE_MAPS_API_KEY}` +
    `&v=3.exp&libraries=geometry,drawing,places`;
export const GOOGLE_MAPS_SITE_ICON_SIZE = 50;
export const GOOGLE_MAPS_TRUCK_ICON_SIZE = 50;

export const GOOGLE_MAPS_REDUCED_TRUCK_ICON_SIZE = GOOGLE_MAPS_TRUCK_ICON_SIZE - 10;

export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
// requirement about format https://github.com/TruckITllc/truckit-frontend/issues/229#issuecomment-602571717
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
export const DEFAULT_TIME_FORMAT = "hh:mm A";
export const DEFAULT_TIMEZONE_DATE_FORMAT = "YYYY-MM-DDThh:mm:ssZ";
export const DEFAULT_NO_TIMEZONE_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const CHART_DATE_FORMAT = "%m/%d %I:%M%p"; //d3.js
export const LOADER_WHITE_OVERLAY = "container-loader-wrap -white";
export const LOADER_OVERLAY_WITHOUT_WHITE_BACKGROUND = "container-loader-wrap";

export const processObjectErrorMessage = (error) => {
    let message = "\n";
    for (const [key, value] of Object.entries(error)) {
        message = `${message} ${key} ${value} \n`;
    }

    return message.toLocaleLowerCase();
};

export const PROCESS_SERVER_ERROR = (error, customMessage = "Something Wrong") => {
    if (_.isEmpty(error)) {
        return null;
    }

    return _.isString(error.message)
        ? [error.message]
        : _.isObject(error.message)
        ? [processObjectErrorMessage(error.message)]
        : [customMessage];
};
export const PHONE_PLUS_SIGN = "+";
export const COMPANIES_TO_SHOW_DEVICES_LOGIC = [4];

export const ENV_MODE_PROD = "Production";
export const ENV_MODE_TEST = "Development";
export const ENV_MODE_LOCAL = "Local";
export const ENV_MODE_STAGE = "Stage";
export const ENV_MODE_DEMO = "Demo";

export const IS_TEST_OR_LOCAL_OR_STAGE_ENVS =
    process.env.REACT_APP_ENVIRONMENT === ENV_MODE_TEST ||
    process.env.REACT_APP_ENVIRONMENT === ENV_MODE_LOCAL ||
    process.env.REACT_APP_ENVIRONMENT === ENV_MODE_STAGE;
export const IS_TEST_OR_LOCAL_ENVS =
    process.env.REACT_APP_ENVIRONMENT === ENV_MODE_TEST || process.env.REACT_APP_ENVIRONMENT === ENV_MODE_LOCAL;
export const IS_PROD_ENV = process.env.REACT_APP_ENVIRONMENT === ENV_MODE_PROD;
export const IS_DEMO_ENV = process.env.REACT_APP_ENVIRONMENT === ENV_MODE_DEMO;

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const FREQUENCY_UPDATE_PROD = 300000;
export const FREQUENCY_UPDATE_TEST_DEMO = 60000;

//Account Fields
export const ACCOUNT_NOTIFICATION_PROFILE_KEY = "notificationProfile";
export const ACCOUNT_COMPANY_PROFILE_KEY = "companyProfile";

export const SECONDS_PER_HOUR = 3600;
export const convertSecondsToHours = (seconds) => {
    // round to 1 decimal place
    return Math.round((seconds / SECONDS_PER_HOUR) * 10) / 10;
};
export const convertHoursToSeconds = (hours) => hours * SECONDS_PER_HOUR;

export const MILLISECONDS_PER_DAY = 86400000;
export const MILLISECONDS_PER_HOUR = 3600000;
export const MILLISECONDS_PER_MINUTE = 60000;

export const getMillisecondsDateDifference = (startDate, endDate) => {
    return Math.abs(startDate - endDate);
};

export const getMinutesDateDifference = (startDate, endDate) => {
    const millisecondsDelta = getMillisecondsDateDifference(startDate, endDate);

    return Math.round(((millisecondsDelta % MILLISECONDS_PER_DAY) % MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE);
};

export const getHoursDateDifference = (startDate, endDate) => {
    const millisecondsDelta = getMillisecondsDateDifference(startDate, endDate);

    return Math.floor((millisecondsDelta % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR);
};

// sort params
export const PICKUP_DISTANCE = "pickUpDistance";

export const TRUCKIT_SUPPORT_EMAIL = "support@truckit.com";
