import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { handleAdditionalFields } from "../../helpers/projects";

const PoliAdditionalFields = (props) => {
    const { additionalFields, className } = props;

    return (
        <React.Fragment>
            {handleAdditionalFields(additionalFields).map((key, index) => {
                const keyValue = key.replaceAll("_", " ");

                return (
                    <div className={clsx("--capitalize", className)} key={index}>
                        {keyValue}: {additionalFields[key]}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

PoliAdditionalFields.propTypes = {
    additionalFields: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(PoliAdditionalFields),
);
