import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import { PAGE_GLOBAL_STYLES } from "../styles/reusableStyles";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../constants/global";
import Loader from "../components/core/loader";
import ErrorNotification from "../components/core/notification";
import { getDevices } from "../dataServers/devices";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import DevisesFilters from "../components/devises/devisesFilters";
import DevisesList from "../components/devises/devisesList";
import DevisesMap from "../components/devises/devisesMap";

const Devises = (props) => {
    const classes = PAGE_GLOBAL_STYLES();
    const [state, setState] = useState({
        devises: [],
        isLoading: false,
        devisesCount: 0,
        tempData: [],
    });
    const [error, setError] = useState();
    const [filters, setActiveFilters] = useState();
    const { isLoading, devises, devisesCount } = state;

    const loadDevises = (concat) => {
        const currentPage = Math.ceil(devises.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(devises) ? +currentPage + 1 : 1;
        const params = {
            page: concat ? nextPage : 1,
            order: props.activeOrderIotParam,
        };
        setState({ ...state, isLoading: true });

        getDevices({ ...params, ...filters })
            .then(({ data, meta }) => {
                setState({
                    ...state,
                    isLoading: false,
                    devises: concat ? [...devises, ...data] : data,
                    devisesCount: meta.count,
                });
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setState({ ...state, isLoading: false });
            });
    };

    const updateListByFilters = (filters) => {
        setActiveFilters(filters);
    };

    useEffect(() => {
        loadDevises();
    }, [props.activeOrderIotParam]);

    useEffect(() => {
        !_.isEmpty(filters) && loadDevises();
    }, [filters]);

    return (
        <React.Fragment>
            <Container className={clsx(classes.globalPageStyles)}>
                <DevisesFilters updateListByFilters={updateListByFilters} />
                <br />
                <DevisesMap devises={devises} />
            </Container>
            <div className={clsx("devises-page", isLoading && LOADER_WHITE_OVERLAY, "-min-height")}>
                {isLoading && <Loader />}
                {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                <br />
                <DevisesList devisesCount={devisesCount} loadDevises={loadDevises} devises={devises} />
                <br />
                <br />
            </div>
        </React.Fragment>
    );
};

Devises.propTypes = {
    account: PropTypes.object.isRequired,
    activeOrderIotParam: PropTypes.string.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            activeOrderIotParam: state.companies.activeOrderIotParam,
        };
    })(Devises),
);
