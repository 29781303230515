import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import SearchAndFilters, { FILTERS_REGIONS_ID } from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES } from "../../styles/reusableStyles";
import { compose } from "redux";
import { change, focus, reduxForm } from "redux-form";
import {
    WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT,
} from "../../constants/maps";
import { selectCompanyRegions } from "../../selectors/index";

const NotificationFeedForm = React.memo(
    (props) => {
        const {
            form,
            notificationEventsLoading,
            updateListByFilters,
            selectedLivemapTruck,
            account: { region },
            regions,
        } = props;
        const pageActionsStyles = PAGE_ACTIONS_STYLES();
        const dispatch = useDispatch();

        const [initialFilters, setInitialFilters] = useState();
        const [loadedFilters, setLoadedFilters] = useState(false);
        const [filters, setFilters] = useState([
            {
                title: "Notification type",
                filterKey: "type",
                filters: [
                    {
                        label: "Truck Event",
                        id: WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT,
                    },
                    {
                        label: "Ticket Event",
                        id: WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT,
                    },
                    {
                        label: "Job Order Event",
                        id: WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
                    },
                    {
                        label: "Message Event",
                        id: WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT,
                    },
                ],
            },
        ]);

        useEffect(() => {
            if (!_.isEmpty(selectedLivemapTruck)) {
                const {
                    truck: { deviceName },
                } = selectedLivemapTruck;
                dispatch(change(form, "search", deviceName));
                dispatch(focus(form, "search", deviceName));
            }
        }, [selectedLivemapTruck]);

        useEffect(() => {
            if (regions) {
                setFilters([
                    ...filters,
                    {
                        title: "Regions",
                        filterKey: FILTERS_REGIONS_ID,
                        filters: regions.map((item) => ({
                            label: item.regionName,
                            id: item.id,
                        })),
                    },
                ]);
                setLoadedFilters(true);
            }
        }, [regions]);

        useEffect(() => {
            if (region) {
                setInitialFilters({
                    ...initialFilters,
                    [FILTERS_REGIONS_ID]: {
                        [region.id]: true,
                    },
                });
            }
        }, []);

        return (
            <form noValidate={true} key={filters.length} className={pageActionsStyles.pageActions}>
                <Grid container component="div" className={clsx("search-and-filters")}>
                    <SearchAndFilters
                        key={filters.length}
                        form={form}
                        rememberFilters
                        shouldListenType={true}
                        initialFilters={initialFilters}
                        isLoading={notificationEventsLoading}
                        updateList={(filters) => updateListByFilters(filters, loadedFilters)}
                        filtersList={filters}
                    />
                </Grid>
            </form>
        );
    },
    (prev, nextProps) => {
        return (
            _.isEqual(prev.search, nextProps.search) &&
            _.isEqual(prev.notificationEventsLoading, nextProps.notificationEventsLoading) &&
            _.isEqual(prev.selectedLivemapTruck, nextProps.selectedLivemapTruck) &&
            _.isEqual(prev.regions, nextProps.regions) &&
            _.isEqual(prev.updateListByFilters, nextProps.updateListByFilters)
        );
    },
);

NotificationFeedForm.propTypes = {
    form: PropTypes.string.isRequired,
    search: PropTypes.string,
    notificationEventsLoading: PropTypes.bool,
    updateListByFilters: PropTypes.func.isRequired,
    selectedLivemapTruck: PropTypes.object.isRequired,
    regions: PropTypes.array,
    account: PropTypes.object,
};

export const DASHBOARD_FORM = "dashboardForm";

export default withRouter(
    compose(
        reduxForm({
            form: DASHBOARD_FORM,
        }),
        connect((state) => {
            return {
                account: state.account,
                notificationEventsLoading: state.notifications.notificationEventsLoading,
                selectedLivemapTruck: state.livemap.selectedLivemapTruck,
                regions: selectCompanyRegions(state),
            };
        }),
    )(NotificationFeedForm),
);
