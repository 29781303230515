import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Validation } from "../../helpers/validation";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Field } from "redux-form";
import { getTerms } from "../../dataServers/jobOrder";

export const INITIAL_TERMS_VALUE = { value: 3, label: "Net 30" };

const TermsSelector = (props) => {
    const { disabled, onChange } = props;
    const loadTerms = (inputValue, { params = {}, loadedCount } = {}) => {
        return getTerms(params).then((data) => {
            const results = data.data.map((i) => ({ ...i, value: i.id, label: i.name }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <Field
            name="terms"
            validate={[Validation.required]}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            onChange={onChange}
            loadOptions={loadTerms}
            placeholder="Terms"
            disabled={disabled}
            component={AsyncAutocompleteComponent}
        />
    );
};

TermsSelector.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

TermsSelector.defaultProps = {
    disabled: false,
    onChange: () => {},
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(TermsSelector),
);
