import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { UNIT_OF_MEASURE_BY_ID } from "../../../constants/maps";
import { TYPOGRAPHY_ICONS_SITE } from "../../../styles/icons";

import Calendar from "./calendar";

const useStyles = makeStyles((theme) => ({
    container: { display: "flex" },
    eventLabel: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        borderColor: "red",
    },
    containerFlags: { display: "flex", alignItems: "center", marginBottom: 5 },
    labelFlag: { fontSize: 12, fontWeight: 500 },
    templateFlag: {
        width: 20,
        height: 20,
        borderRadius: 50,
        margin: "0 10px",
        borderWidth: 2,
    },
    regularFlag: {
        width: 20,
        height: 20,
        borderRadius: 50,
        backgroundColor: "rgb(255, 255, 255)",
        border: "2px solid #979797",
        margin: "0 10px",
        boxShadow: "0 0 4px 0 rgb(112 112 112 / 50%)",
        borderWidth: 2,
    },
    titleText: {
        fontSize: 12,
        fontWeight: 500,
        marginRight: 20,
    },
    headerTitle: {
        display: "flex",
        justifyContent: "space-between",
    },
    site: { display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "0 10px" },
    jobAndProjectName: { marginRight: 15 },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 1500,
        width: "100%",
        border: "1px solid #979797",
        cursor: "pointer",
        fontSize: 12,
        boxShadow: "0 0 4px 0 rgb(112 112 112 / 50%)",
        transition: "box-shadow 0.3s ease-in-out",
        fontWeight: 500,
        lineHeight: 1.67,
        borderRadius: 8,
        marginBottom: 11,
    },
}))(Tooltip);

const setEventPropGetter = ({ isScheduledJob, background, border }, start, end, isSelected) => {
    if (isScheduledJob) {
        return {
            style: {
                backgroundColor: background,
                border: border,
            },
        };
    }

    return {
        style: { backgroundColor: "#fff" },
    };
};

const renderTitle = (
    { jobOrderId, projectName, startDate, payload, unitOfMeasure, timezone, pickupName, dropOffName, ...rest },
    { styles, iconStyles },
) => {
    return (
        <Box>
            <Box className={clsx(styles.titleText, styles.headerTitle)}>
                <span className={styles.jobAndProjectName}>
                    Job #{jobOrderId} - {projectName}
                </span>
                <span>{moment(startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")}</span>
            </Box>
            <Divider marginTop={0} />
            <Box className={styles.container}>
                <span className={styles.titleText}>
                    {payload} - {UNIT_OF_MEASURE_BY_ID[unitOfMeasure]}
                </span>
                <Box className={styles.container}>
                    <Typography className={clsx("payload", "--text-right", styles.site)}>
                        <LocationOnIcon className={clsx(iconStyles.iconSite, "pick-up")} />
                        {pickupName}
                    </Typography>
                    <Typography className={clsx("payload", "--text-right", styles.site)}>
                        <LocationOnIcon className={clsx(iconStyles.iconSite, "drop-off")} />
                        {dropOffName}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const renderFlags = ({ events, styles }) => {
    const flags = events && [
        ...new Map(
            events
                .filter((i) => !!i.background)
                .map((item) => [
                    item["background"],
                    { background: item.background, border: item.border, jo: item.jobOrderId },
                ]),
        ).values(),
    ];

    return (
        flags && (
            <Box className={styles.containerFlags}>
                {flags && flags.length ? <span className={styles.labelFlag}>Templates</span> : null}
                {flags.map((item) => (
                    <Box
                        className={clsx(styles.templateFlag)}
                        style={{ backgroundColor: item.background, border: item.border, borderWidth: 2 }}
                    ></Box>
                ))}
                <span className={styles.labelFlag}>Regular Jobs</span>
                <Box className={styles.regularFlag}></Box>
            </Box>
        )
    );
};

const Event = (props) => {
    const styles = useStyles();
    const iconStyles = TYPOGRAPHY_ICONS_SITE();

    return (
        <HtmlTooltip title={renderTitle(props.event, { styles, iconStyles })}>
            <Box className={styles.eventLabel}>{props.event.label}</Box>
        </HtmlTooltip>
    );
};

Event.propTypes = {
    label: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
};

const TeamCalendar = ({ timezone, userId, events, getEvents, resetEvents, suspendPeriod, loading, error }) => {
    const styles = useStyles();
    const components = {
        event: Event,
    };

    const additional = {
        renderFlags: () => renderFlags({ events, styles }),
    };

    return (
        <React.Fragment>
            <Calendar
                id={userId}
                timezone={timezone}
                getEvents={getEvents}
                resetEvents={resetEvents}
                events={events}
                loading={loading}
                error={error}
                components={components}
                disabledDays={suspendPeriod}
                setEventPropGetter={setEventPropGetter}
                additional={additional}
            />
        </React.Fragment>
    );
};

TeamCalendar.propTypes = {
    userId: PropTypes.number.isRequired,
    activeSuspendPeriod: PropTypes.object.isRequired,
    getEvents: PropTypes.func.isRequired,
    resetEvents: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
};

export default TeamCalendar;
