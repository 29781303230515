import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBAL_CONTENT_WIDTH } from "../../styles/globalThemeConfig";
import UpgradeAccountMessage from "../dashboard/upgradeAccountMessage";

export const subNavigationStyles = makeStyles((theme) => ({
    subNavigation: {
        backgroundColor: theme.palette.secondary.superDark,
        padding: "3px 0",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    subMenu: {
        width: "100%",
        display: "flex",
    },
    subMenuItems: {
        width: "170px",
        color: theme.palette.ternary.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: theme.typography.fontWeightBold,
        padding: "9px 5px",
        borderRadius: theme.shape.borderRadius,

        "&.active": {
            backgroundColor: theme.palette.ternary.superLight,
            color: "#fff",
        },
        "& a": {
            color: "inherit",
            textDecoration: "none",
        },
    },
    subMenuResponsive: {
        [theme.breakpoints.down("840")]: {
            justifyContent: "space-evenly",
        },
    },
    subNavWithoutListView: { padding: "9px 0" },
    trucksSubMenuResponsive: {
        [theme.breakpoints.down("635")]: {
            justifyContent: "space-evenly",
        },
    },
    overrideSubMenuItems: {
        justifyContent: "flex-start",
    },
    smartDispatchSubMenuItem: {
        width: "215px",
    },
}));

function SubNavigation(props) {
    const classes = subNavigationStyles();
    const { subMenuWidth } = props;

    return (
        <>
            <div className={clsx(classes.subNavigation, props.subNavStyles)}>
                <div style={{ width: subMenuWidth || GLOBAL_CONTENT_WIDTH, display: "flex" }}>
                    <div className={classes.subMenu}>
                        <Grid container component="div" alignItems={"center"} justify={"space-between"}>
                            {props.children}
                        </Grid>
                    </div>
                </div>
            </div>
            <UpgradeAccountMessage />
        </>
    );
}

SubNavigation.propTypes = {
    children: PropTypes.any,
    subMenuWidth: PropTypes.string,
    justify: PropTypes.string,
};

SubNavigation.defaultProps = {
    justify: "center",
};

export default withRouter(SubNavigation);
