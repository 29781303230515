import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const buttonsGroupStyles = makeStyles(() => ({
    buttonsGroup: {
        "& > button:not(:first-child)": {
            marginLeft: 15,
        },
    },
}));

export const ButtonsGroup = (props) => {
    const { children, justifyContent, className } = props;
    const classes = buttonsGroupStyles();

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent={justifyContent}
            alignItems="center"
            className={clsx(classes.buttonsGroup, className)}
        >
            {children}
        </Box>
    );
};

ButtonsGroup.propTypes = {
    children: PropTypes.any,
    justifyContent: PropTypes.string,
    className: PropTypes.string,
};

ButtonsGroup.defaultProps = {
    component: "div",
    justifyContent: "center",
    otherProps: {},
};

const buttonsGroupVerticalStyles = makeStyles(() => ({
    buttonGroupVertical: {
        "& button:not(:last-child)": {
            marginBottom: 15,
        },
    },
}));

export const ButtonsGroupVertical = (props) => {
    const { children, justifyContent } = props;
    const classes = buttonsGroupVerticalStyles();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent={justifyContent}
            alignItems="center"
            className={classes.buttonGroupVertical}
        >
            {children}
        </Box>
    );
};

ButtonsGroupVertical.propTypes = {
    children: PropTypes.any,
    justifyContent: PropTypes.string,
};

ButtonsGroupVertical.defaultProps = {
    component: "div",
    justifyContent: "flex-end",
    otherProps: {},
};
