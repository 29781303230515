import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import clsx from "clsx";

import { ticketsListRemoveTicket } from "../../../actions/tickets";
import { deleteTicket } from "../../../dataServers/tickets";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import TextInputComponent from "../../core/form/textInputComponent";
import ErrorNotification from "../../core/notification";
import { formatReduxFormErrors } from "../../../helpers/errorFormatting";
import Loader from "../../core/loader";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";

function DeleteTicketForm(props) {
    const { ticketId, ticketsListRemoveTicket, handleSubmit, handleSuccess, error } = props;
    const [isLoading, setLoading] = useState(false);

    const onSubmit = (values) => {
        setLoading(true);

        const data = {
            reason: values.reason,
            remarks: values.remarks,
        };

        return deleteTicket(ticketId, data)
            .then(() => {
                setLoading(false);
                ticketsListRemoveTicket({ id: ticketId });
                if (handleSuccess) {
                    handleSuccess();
                }
            })
            .catch((errorResponse) => {
                setLoading(false);
                formatReduxFormErrors(errorResponse);
            });
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                <Field
                    type="text"
                    name="reason"
                    label="Reason"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    type="text"
                    name="remarks"
                    label="Remarks"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <PrimaryButton size={"medium"} type="submit">
                    Delete
                </PrimaryButton>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} />}
        </div>
    );
}

DeleteTicketForm.propTypes = {
    ticketId: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    ticketsListRemoveTicket: PropTypes.func.isRequired,
    error: PropTypes.any,
};

export const DELETE_TICKET_FORM = "deleteTicketForm";

export default compose(
    reduxForm({
        form: DELETE_TICKET_FORM,
        initialValues: {
            reason: null,
            remarks: null,
        },
    }),
    connect(null, (dispatch) => ({
        ticketsListRemoveTicket: (payload) => {
            dispatch(ticketsListRemoveTicket(payload));
        },
    })),
)(DeleteTicketForm);
