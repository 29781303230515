import { NOTIFICATION_PUSH_EVENT_RECEIVED, NOTIFICATION_SNACKBAR_RECEIVED } from "../events/notifications";
import { TRUCKS_COORDINATES_UPDATED } from "../events/trucks";
import { TICKET_OPENED_NOTIFICATION, TICKETS_TIMER_UPDATED } from "../events/tickets";
import {
    JO_CLOSED_NOTIFICATION,
    JO_CREATED_NOTIFICATION,
    JO_DELETED_NOTIFICATION,
    JO_REOPENED_NOTIFICATION,
} from "../events/jobOrders";
import { websocketClient } from "../websocket/client";
import { websocketBase } from "../config";
import {
    TICPIC_REPORT_STATUS_FAILURE,
    TICPIC_REPORT_STATUS_IN_PROGRESS,
    TICPIC_REPORT_STATUS_SUCCESS,
    WEBSOCKET_DATA_SUB_TYPE_JO_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT,
    WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT,
    WEBSOCKET_DATA_TYPE_NEW_TRUCK_COORD,
    WEBSOCKET_DATA_TYPE_NOTIFICATION,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_LOSING_ACCESS_EVENT,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_SMART_DISPATCH,
    WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT,
    WEBSOCKET_DATA_TYPE_TICKET_TIMER_UPDATE,
    WEBSOCKET_EVENT_TYPE_JO_CLOSED,
    WEBSOCKET_EVENT_TYPE_JO_CREATED,
    WEBSOCKET_EVENT_TYPE_JO_DELETED,
    WEBSOCKET_EVENT_TYPE_JO_REOPENED,
    WEBSOCKET_EVENT_TYPE_JO_UPDATED,
    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED,
    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR,
    WEBSOCKET_EVENT_TYPE_TICKET_CLOSED,
    WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED,
    WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED,
    WEBSOCKET_EVENT_TYPE_TICKET_OPENED,
    WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT,
    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED,
} from "../constants/maps";
import {
    prepareAnomalyDetectionEvent,
    prepareJobEvent,
    prepareLosingAccessEvent,
    prepareSmartDispatchEvent,
    prepareTicketEvent,
} from "../websocket/events";
import { getJobOrderById, getJobOrders } from "../dataServers/jobOrder";
import { getJobOrderStats, getTicketsStats } from "./stats";
import * as WebSocketActions from "../actions/websocket";
import { getTicketById } from "../dataServers/tickets";
import store from "../store";
import { joUpdatedWebsocketDataReceived, saveJobOrdersInRedux } from "./jobOrders";
import {
    getNotificationFieldNameFromDescription,
    getNotificationsGroupNameFromDescription,
} from "../constants/settings";
import { getJobOrderFiltersFromLocalStorage } from "../helpers/getJobOrderFiltersFromLocalStorage";
import { ROUTE_DASHBOARD, ROUTE_JOBS } from "../routes/globalRoutes";
import { updatedBundleInList } from "./smartDispatch";
import { selectCurrentProject } from "../selectors";

const dispatch = store.dispatch;
const getState = store.getState;

export const initialize = () => {
    const connectionUrl = websocketBase;
    websocketClient.setEventCallback(handleEvent);
    websocketClient.initialize(connectionUrl);
};

export const close = () => {
    websocketClient.close();
};

const handleEvent = (msg) => {
    switch (msg.data_type) {
        case WEBSOCKET_DATA_TYPE_NOTIFICATION:
            switch (msg.subType) {
                case WEBSOCKET_DATA_SUB_TYPE_JO_EVENT:
                    handleJobNotificationEvent(msg);
                    break;

                case WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT:
                case WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT:
                    break;

                case WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT:
                    handleTicketNotificationEvent(msg);
                    break;
                case WEBSOCKET_DATA_TYPE_SUB_TYPE_LOSING_ACCESS_EVENT:
                    handleAccessLosingEvent(msg);
                    break;
                case WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION:
                    handleAnomalyDetectionEvent(msg);
                    break;
                case WEBSOCKET_DATA_TYPE_SUB_TYPE_SMART_DISPATCH:
                    handleSmartDispatchEvent(msg);
                    break;
                default:
                    break;
            }
            break;

        case WEBSOCKET_DATA_TYPE_NEW_TRUCK_COORD:
            handleNewTruckCoordinatesEvent(msg);
            break;

        case WEBSOCKET_DATA_TYPE_TICKET_TIMER_UPDATE:
            handleUpdateTicketTimerEvent(msg);
            break;

        default:
            return;
    }
};

const handleNewTruckCoordinatesEvent = (msg) => {
    dispatch({
        type: TRUCKS_COORDINATES_UPDATED,
        payload: {
            id: msg.data.truckId,
            latitude: msg.data.latitude,
            longitude: msg.data.longitude,
            lastNotification: msg.data.lastNotification,
        },
    });
};

const handleUpdateTicketTimerEvent = (msg) => {
    dispatch({
        type: TICKETS_TIMER_UPDATED,
        payload: {
            id: msg.data.ticketId,
            quantity: msg.data.quantity,
        },
    });
};

// const eventNotificationShow = (event);

const handleNotificationEvent = (event) => {
    const store = getState();
    const eventDescription = event.description;
    const notificationProfile = store.account.notificationProfile;

    const notificationsGroupName = getNotificationsGroupNameFromDescription(eventDescription);
    const notificationFieldName = getNotificationFieldNameFromDescription(eventDescription);
    let showNotification = true;

    if (notificationProfile && notificationsGroupName && notificationFieldName) {
        showNotification = notificationProfile[notificationsGroupName][notificationFieldName];
    }

    if (showNotification) {
        dispatch({
            type: NOTIFICATION_PUSH_EVENT_RECEIVED,
            payload: event,
        });
        if (store.notifications.notificationSnackbarsShow) {
            dispatch({
                type: NOTIFICATION_SNACKBAR_RECEIVED,
                payload: {
                    key: event.uniqueKey,
                    message: event.notificationTitle,
                    notificationType: event.type,
                    subType: event.dataSubType,
                    reportStatus: event.reportStatus,
                },
            });
        }
    }
};

const handleJobNotificationEvent = (msg) => {
    const state = getState();
    const currentProject = selectCurrentProject(state);

    const jobEvent = prepareJobEvent({ event: { ...msg.data, dataSubType: msg.subType } });
    switch (jobEvent.type) {
        case WEBSOCKET_EVENT_TYPE_JO_CLOSED:
            getJobOrderById(jobEvent.jo_id || jobEvent.jobOrderId).then((response) => {
                dispatch({
                    type: JO_CLOSED_NOTIFICATION,
                    payload: {
                        id: jobEvent.jo_id || jobEvent.jobOrderId,
                        job: response.data,
                    },
                });
                getJobOrderStats();
            });
            break;
        case WEBSOCKET_EVENT_TYPE_JO_REOPENED:
            getJobOrderById(jobEvent.jo_id || jobEvent.jobOrderId).then((response) => {
                dispatch({
                    type: JO_REOPENED_NOTIFICATION,
                    payload: {
                        id: jobEvent.jo_id || jobEvent.jobOrderId,
                        job: response.data,
                    },
                });
                getJobOrderStats();
            });
            break;
        case WEBSOCKET_EVENT_TYPE_JO_DELETED:
            getJobOrderById(jobEvent.jo_id || jobEvent.jobOrderId).then((response) => {
                dispatch({
                    type: JO_DELETED_NOTIFICATION,
                    payload: {
                        id: jobEvent.jo_id || jobEvent.jobOrderId,
                        job: response.data,
                    },
                });

                getJobOrderStats();
            });
            break;
        case WEBSOCKET_EVENT_TYPE_JO_CREATED: {
            // prepare requests params based on users opened page
            const currentLocation = window.location.pathname;
            let params = {
                id: jobEvent.jo_id || jobEvent.jobOrderId,
            };
            params = getJobOrderFiltersFromLocalStorage(currentLocation, params);

            params.startDate &&
                params.startDate &&
                getJobOrders(params).then((response) => {
                    dispatch({
                        type: JO_CREATED_NOTIFICATION,
                        payload: response.data,
                    });
                    getJobOrderStats();
                });
            break;
        }

        case WEBSOCKET_EVENT_TYPE_JO_UPDATED: {
            const currentLocation = window.location.pathname;
            let params = { project: currentProject?.id };
            params = getJobOrderFiltersFromLocalStorage(currentLocation, params);

            const jobEventData = jobEvent.data;
            if (currentLocation.includes(jobEventData.id) && !currentLocation.includes(ROUTE_JOBS.MY_JOBS)) {
                dispatch(joUpdatedWebsocketDataReceived(jobEventData));
            } else {
                getJobOrders(params).then((response) => {
                    response.data && dispatch(saveJobOrdersInRedux(response.data));
                });
            }

            break;
        }

        default:
            break;
    }

    handleNotificationEvent(jobEvent);
};

const handleTicketNotificationEvent = async (msg) => {
    const ticketEvent = prepareTicketEvent({ event: { ...msg.data, dataSubType: msg.subType } });
    if (ticketEvent.message) {
        ticketEvent.uniqueKey = `${ticketEvent.uniqueKey}-${ticketEvent.message}`;
    }
    const { link: ticpicReportLink, filename: tickpicReportFilename } = ticketEvent;
    const pathname = window.location.pathname;

    switch (ticketEvent.type) {
        case WEBSOCKET_EVENT_TYPE_TICKET_OPENED:
            if (pathname.includes(ROUTE_DASHBOARD)) {
                dispatch(getTicketsStats(false));
                break;
            }

            if (ticketEvent.ticket && ticketEvent.ticket.id)
                getTicketById(ticketEvent.ticket.id).then((response) => {
                    dispatch({
                        type: TICKET_OPENED_NOTIFICATION,
                        payload: {
                            id: ticketEvent.ticket.id,
                            ticket: response.data,
                        },
                    });
                });
            break;
        case WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED:
        case WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED:
        case WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED:
        case WEBSOCKET_EVENT_TYPE_TICKET_CLOSED:
        case WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED:
            if (pathname.includes(ROUTE_DASHBOARD)) {
                dispatch(getTicketsStats(false));
            }
            break;
        case WEBSOCKET_EVENT_TYPE_TICPIC_REPORT:
            if (ticpicReportLink && tickpicReportFilename) {
                ticketEvent.reportStatus = TICPIC_REPORT_STATUS_SUCCESS;
                const a = document.createElement("a");
                a.href = ticpicReportLink;
                a.download = tickpicReportFilename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                WebSocketActions.initialize();
            } else {
                ticketEvent.reportStatus = TICPIC_REPORT_STATUS_IN_PROGRESS;
            }
            break;
        case WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED:
            ticketEvent.reportStatus = TICPIC_REPORT_STATUS_FAILURE;
            break;
        default:
            break;
    }

    handleNotificationEvent(ticketEvent);
};

const handleAccessLosingEvent = (msg) => {
    const accessLosingEvent = prepareLosingAccessEvent({ event: { ...msg.data, dataSubType: msg.subType } });
    handleNotificationEvent(accessLosingEvent);
};

const handleAnomalyDetectionEvent = (msg) => {
    const anomalyDetectionEvent = prepareAnomalyDetectionEvent({ event: { ...msg.data, dataSubType: msg.subType } });
    handleNotificationEvent(anomalyDetectionEvent);
};

const handleSmartDispatchEvent = (msg) => {
    const smartDispatchEvent = prepareSmartDispatchEvent({ event: { ...msg.data, dataSubType: msg.subType } });

    switch (smartDispatchEvent.type) {
        case WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR:
        case WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED: {
            const currentLocation = window.location.pathname;
            const bundleId = smartDispatchEvent.bundleId;
            const updatedStatus = smartDispatchEvent.status;
            if (bundleId && currentLocation.includes(ROUTE_JOBS.SMART_DISPATCH)) {
                dispatch(updatedBundleInList({ bundleId, updatedData: { status: updatedStatus } }));
            }
            break;
        }
        default:
            break;
    }

    handleNotificationEvent(smartDispatchEvent);
};
