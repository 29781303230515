import React, { useContext, useState } from "react";
import ActionMenuItem from "../core/actionMenuItem";
import MoreActionsMenu from "../core/moreActions";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { handleTeamDialogToggle, loadTeamUsers, resetUsers, setUserForEdit } from "../../actions/team";
import { useDispatch } from "react-redux";
import { archiveUser, unArchiveUser } from "../../dataServers/teams";
import MessageDialog from "./teamMessageDialog";
import ErrorNotification from "../core/notification";
import SuccessNotification from "../core/successNotification";
import PropTypes from "prop-types";
import ConfirmationModal from "../core/confirmationModal";
import AssignUserRegionDialog from "./assignUserRegionDialog";
import UserCalendar from "./userCalendar";
import { UsersFiltersContext } from "./myTeamWrapper";

const MyTeamsActionMenu = (props) => {
    const { itemForEdit, activeOrderParam, activeTab, isRestrictedCustomer } = props;
    const [isMessageDialogOpened, setIsMessageDialogOpened] = useState(false);
    const [error, setError] = useState(null);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [confirmationMessage, showConfirmationMessage] = useState("");
    const [regionDialogOpen, setRegionDialogOpen] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const dispatch = useDispatch();
    const usersFilters = useContext(UsersFiltersContext);

    const refresh = () => {
        dispatch(resetUsers());
        dispatch(
            loadTeamUsers(
                {
                    ...usersFilters,
                    keywords: usersFilters?.keywords,
                    order: activeOrderParam,
                    role: usersFilters?.role,
                    archived: activeTab === 1,
                },
                false,
            ),
        );
    };

    const handleItemClick = (callback) => {
        itemForEdit ? callback(itemForEdit) : callback();
    };

    const toogleDialog = (userForEdit) => {
        dispatch(setUserForEdit(userForEdit));
        dispatch(handleTeamDialogToggle(true));
    };

    const archiveUnarchiveHandler = (userForEdit) => {
        const userId = itemForEdit.id;

        return itemForEdit.archived ? unArchiveHandler(userId) : archiveUserHandler(userId);
    };

    const onSubmitArchive = () => {
        archiveUnarchiveHandler()
            .then(() => {
                setIsHandledSuccessfully(true);
            })
            .catch((error) => {
                setError(error);
                refresh();
            });
    };

    const archiveUserHandler = (userId) => {
        return archiveUser(userId).then(() => {
            setIsHandledSuccessfully(true);
            refresh();
        });
    };

    const unArchiveHandler = (userId) => {
        return unArchiveUser(userId).then(() => {
            refresh();
        });
    };

    const handleOpenDialog = () => {
        setIsMessageDialogOpened(true);
    };

    const handleCloseMessageDialog = () => {
        setIsMessageDialogOpened(false);
    };

    const showConfirmation = () => {
        showConfirmationMessage("Are you sure you want to archive user?");
    };

    const assignRegion = () => setRegionDialogOpen(true);

    const onSubmit = () => {
        refresh();
    };

    const openCalendar = () => setIsOpenCalendar(true);
    const closeCalendar = () => setIsOpenCalendar(false);

    const activeUserMenuItems = [
        {
            id: "edit",
            title: "Edit",
            renderIcon: () => <AddBoxIcon />,
            handleClick: toogleDialog,
            visible: true,
        },
        {
            id: "region",
            title: "Assign region",
            renderIcon: () => <AddBoxIcon />,
            handleClick: assignRegion,
            visible: true,
            disabled: isRestrictedCustomer,
        },
        {
            id: "archive",
            title: "Archive",
            renderIcon: () => <AddBoxIcon />,
            handleClick: showConfirmation,
            visible: true,
        },
        {
            id: "sendMessage",
            title: "Send Message",
            renderIcon: () => <AddBoxIcon />,
            handleClick: handleOpenDialog,
            visible: true,
            disabled: isRestrictedCustomer,
        },
        {
            id: "openCalendar",
            title: "View Calendar",
            renderIcon: () => <AddBoxIcon />,
            handleClick: openCalendar,
            visible: true,
            disabled: isRestrictedCustomer,
        },
    ];

    const archivedUserMenuItems = [
        {
            id: "archive",
            title: "Unarchive",
            renderIcon: () => <AddBoxIcon />,
            handleClick: onSubmitArchive,
            visible: true,
        },
    ];

    return (
        <React.Fragment>
            <MoreActionsMenu>
                {(itemForEdit.archived ? archivedUserMenuItems : activeUserMenuItems).map((menuItem) => {
                    if (!menuItem.visible) {
                        return false;
                    }

                    return (
                        <ActionMenuItem
                            key={menuItem.id}
                            title={menuItem.title}
                            disabled={menuItem.disabled}
                            renderIcon={menuItem.renderIcon}
                            handleClick={() => handleItemClick(menuItem.handleClick)}
                        />
                    );
                })}
            </MoreActionsMenu>
            <MessageDialog user={itemForEdit} isOpened={isMessageDialogOpened} handleClose={handleCloseMessageDialog} />
            {error && <ErrorNotification message={error.message} config={{ onClose: () => setError(null) }} />}
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            <AssignUserRegionDialog
                user={itemForEdit}
                handleClose={() => setRegionDialogOpen(false)}
                onSubmit={onSubmit}
                regionDialogOpen={regionDialogOpen}
            />
            <ConfirmationModal
                isOpen={!!confirmationMessage}
                question={confirmationMessage}
                yesHandler={() => onSubmitArchive(itemForEdit)}
                noHandler={() => showConfirmationMessage("")}
            />
            <UserCalendar
                isOpenCalendar={isOpenCalendar}
                closeCalendar={closeCalendar}
                timezone={props.timezone}
                userName={props.userName}
                getEvents={props.getEvents}
                resetEvents={props.resetEvents}
                userId={props.userId}
            />
        </React.Fragment>
    );
};

export default MyTeamsActionMenu;

MyTeamsActionMenu.propTypes = {
    itemForEdit: PropTypes.object.isRequired,
    activeOrderParam: PropTypes.string,
    activeTab: PropTypes.number,
};
