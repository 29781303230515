import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Field } from "redux-form";
import { connect } from "react-redux";

import { Validation } from "../../helpers/validation";
import CustomPhoneInput from "./CustomPhoneInput";

import "react-phone-input-2/lib/material.css";

const PhoneField = (props) => {
    const { name, label, fieldNote, className, isRequired } = props;
    let validation = [Validation.noSpecialSymbols, Validation.minLength6, Validation.maxLength16];

    if (isRequired) {
        validation = [Validation.required, ...validation];
    }

    return (
        <Field
            type="text"
            name={name}
            label={label}
            className={className}
            validate={validation}
            fieldNote={fieldNote}
            autoFormat={false}
            placeholder={"+XXXXXXXXXXX"}
            preferredCountries={["us"]}
            component={CustomPhoneInput}
        />
    );
};

PhoneField.propTypes = {
    account: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fieldNote: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    className: PropTypes.string,
};

PhoneField.defaultProps = {
    name: "phone",
    label: "Phone",
    fieldNote: "",
};

export const PRONE_FIELD_NOTE =
    "Used for system generated notifications. User can manage personal notification preferences in app";

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(PhoneField),
);
