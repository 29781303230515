export const PROFILE_TICKET_NOTIFICATIONS = [
    { name: "ticketDisputed", label: "Ticket Disputed" },
    { name: "ticketReconciled", label: "Ticket Reconciled" },
    { name: "ticketFinallyReconciled", label: "Ticket Finally Reconciled" },
    { name: "ticketOpened", label: "Ticket Opened" },
    { name: "ticketReopened", label: "Ticket Reopened" },
    { name: "ticketClosed", label: "Ticket Closed" },
    { name: "ticketInterrupted", label: "Ticket Closed by System" },
];
const PROFILE_TICKET_NOTIFICATIONS_DESCRIPTIONS_LIST = [
    "ticket_closed",
    "ticket_dispute",
    "ticket_interrupted",
    "ticket_opened",
    "ticket_reopened",
    "ticket_final_reconcile",
    "ticket_reconcile",
    "airticketlite_reconcile",
];
export const TICKETS_NOTIFICATION_FIELD_NAME = "ticketNotifications";

export const PROFILE_TRUCK_NOTIFICATIONS = [
    { name: "truckEntered", label: "Truck Entered" },
    { name: "truckLeft", label: "Truck Left" },
];
const PROFILE_TRUCK_NOTIFICATIONS_DESCRIPTIONS_LIST = ["entered", "left"];
export const TRUCK_NOTIFICATIONS_FIELD_NAME = "truckNotifications";

export const PROFILE_JOB_NOTIFICATIONS = [
    { name: "jobClosed", label: "Job Closed" },
    { name: "jobCreated", label: "Job Created" },
    { name: "jobReopened", label: "Job Reopened" },
    { name: "jobUpdated", label: "Job Updated" },
    { name: "truckPaused", label: "Truck Paused" },
    { name: "truckResumed", label: "Truck Resumed" },
];
const PROFILE_JOB_NOTIFICATIONS_DESCRIPTIONS_LIST = [
    "close_job_order",
    "create_job_order",
    "reopen_job_order",
    "update_job_order",
    "pause_truck",
    "resume_truck",
];
export const JOB_NOTIFICATIONS_FIELD_NAME = "jobOrderNotifications";

export const PROFILE_MESSAGE_NOTIFICATIONS = [
    { name: "messageSent", label: "Message Sent" },
    { name: "messageNotSent", label: "Message Not Sent" },
];
const PROFILE_MESSAGE_NOTIFICATIONS_DESCRIPTIONS_LIST = ["message_sent", "message_not_sent"];
export const MESSAGE_NOTIFICATIONS_FIELD_NAME = "messageNotifications";

export const PROFILE_ANOMALY_DETECTION = [
    { name: "idleTimeAlert", label: "Idle Time", description: "idle_time_alert" },
    { name: "crowdedZoneAlert", label: "Crowded Zone", description: "crowded_zone_alert" },
    { name: "slowTripAlert", label: "Slow Trip", description: "slow_trip_alert" },
    { name: "lateDriverAlert", label: "Late Driver", description: "late_driver_alert" },
    { name: "missingTicketAlert", label: "Missing Ticket", description: "missing_ticket_alert" },
];
const PROFILE_ANOMALY_ALERT_NOTIFICATIONS_DESCRIPTIONS_LIST = [
    "idle_time_alert",
    "crowded_zone_alert",
    "slow_trip_alert",
    "late_driver_alert",
    "missing_ticket_alert",
];
export const ANOMALY_ALERT_NOTIFICATIONS_FIELD_NAME = "anomalyAlertNotifications";

export const JOB_REQUEST_NOTIFICATIONS_FIELD_NAME = "requestsNotifications";
export const JOB_REQUEST_NOTIFICATIONS = [
    { name: "requestsEmail", label: "Email" },
    { name: "requestsPush", label: "Push Notification" },
    { name: "requestsSms", label: "SMS" },
];
export const DROP_OFF_NOTIFICATIONS_FIELD_NAME = "dropOffNotifications";
export const PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_LABEL = "Prevent driver from closing ticket outside the drop-off zone";
export const PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_TITLE =
    "If driver leaves the delivery zone and does not close the ticket, he will be able to request closure from the dispatcher";
export const RECIPIENTS_TITLE = "Users who will be notified when a driver requests drop-off";

// used to hide notifications if such option is in settings and FALSE selected
export const getNotificationsGroupNameFromDescription = (description) => {
    if (PROFILE_TICKET_NOTIFICATIONS_DESCRIPTIONS_LIST.includes(description)) {
        return TICKETS_NOTIFICATION_FIELD_NAME;
    } else if (PROFILE_TRUCK_NOTIFICATIONS_DESCRIPTIONS_LIST.includes(description)) {
        return TRUCK_NOTIFICATIONS_FIELD_NAME;
    } else if (PROFILE_JOB_NOTIFICATIONS_DESCRIPTIONS_LIST.includes(description)) {
        return JOB_NOTIFICATIONS_FIELD_NAME;
    } else if (PROFILE_ANOMALY_ALERT_NOTIFICATIONS_DESCRIPTIONS_LIST.includes(description)) {
        return ANOMALY_ALERT_NOTIFICATIONS_FIELD_NAME;
    } else if (PROFILE_MESSAGE_NOTIFICATIONS_DESCRIPTIONS_LIST.includes(description)) {
        return MESSAGE_NOTIFICATIONS_FIELD_NAME;
    } else {
        return null;
    }
};

export const getNotificationFieldNameFromDescription = (description) => {
    return (
        {
            ticket_closed: "ticketClosed",
            ticket_dispute: "ticketDisputed",
            ticket_interrupted: "ticketInterrupted",
            ticket_opened: "ticketOpened",
            ticket_reopened: "ticketReopened",
            ticket_final_reconcile: "ticketFinallyReconciled",
            ticket_reconcile: "ticketReconciled",
            airticketlite_reconcile: "ticketReconciled",

            entered: "truckEntered",
            left: "truckLeft",

            close_job_order: "jobClosed",
            create_job_order: "jobCreated",
            reopen_job_order: "jobReopened",
            update_job_order: "jobUpdated",
            pause_truck: "truckPaused",
            resume_truck: "truckResumed",

            idle_time_alert: "idleTimeAlert",
            crowded_zone_alert: "crowdedZoneAlert",
            slow_trip_alert: "slowTripAlert",
            late_driver_alert: "lateDriverAlert",
            missing_ticket_alert: "missingTicketAlert",

            message_sent: "messageSent",
            message_not_sent: "messageNotSent",

            create_job_request: "createJobRequest",
            fill_request_job: "fillRequestJob",
        }[description] || null
    );
};
