import React from "react";
import PropTypes from "prop-types";
import qs from "qs";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.background.paper,
        margin: "0 5px",
    },
}));

export default function MailTo({ email, subject, body, label }) {
    const classes = useStyles();

    const queryParams = {
        subject,
        body,
    };

    const queryString = qs.stringify(queryParams, {
        addQueryPrefix: true,
        encode: false,
    });

    const href = `mailto:${email}${queryString}`;

    return (
        <a target="_blant" href={href} className={classes.link}>
            {email}
        </a>
    );
}

MailTo.propTypes = {
    email: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    label: PropTypes.string,
};
