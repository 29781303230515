import { UPDATE_DEFAULT_FILTER_ID } from "../events/haulersSettings";

const initialState = {
    defaultFilterId: null,
};

export const haulersSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DEFAULT_FILTER_ID:
            return {
                ...state,
                defaultFilterId: action.payload,
            };
        default:
            return state;
    }
};

export default haulersSettingsReducer;
