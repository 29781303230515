import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    imagesList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",

        "& .tic-pic-image-container": {
            width: "100px",

            "& img": {
                maxWidth: "100%",
                maxHeight: "100%",

                "&:hover": {
                    cursor: "pointer",
                },
            },

            "& .selected-image": {
                border: `3px solid ${theme.palette.secondary.superDark}`,
            },
        },
    },
}));
const TicPicSelectList = ({ ticPicList, handleTicPicSelect }) => {
    const classes = useStyles();

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    return (
        <div className={clsx(classes.imagesList)}>
            {ticPicList.map((ticPic, index) => {
                const imageIsSelected = index === selectedImageIndex;

                const onImageClick = () => {
                    setSelectedImageIndex(index);
                    handleTicPicSelect && handleTicPicSelect(ticPic);
                };

                return (
                    <div className="tic-pic-image-container" key={ticPic.fileLink}>
                        <img
                            crossOrigin=""
                            onClick={onImageClick}
                            src={ticPic.fileLink}
                            alt={ticPic.fileName}
                            className={clsx(imageIsSelected && "selected-image")}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default TicPicSelectList;
