import { getFormInitialValues } from "redux-form";
import _ from "lodash";

import {
    TICKETS_LIST_CHANGE_TICKET_STATUS,
    TICKETS_LIST_LOADED,
    TICKETS_LIST_REMOVE_TICKET,
    TICKETS_LIST_REPLACE_TICKET,
    TICKETS_LIST_UPDATE,
    TICKETS_SET_LOADING,
} from "../events/tickets";
import { updateTicketRequest } from "../dataServers/tickets";
import { formatReduxFormErrors } from "../helpers/errorFormatting";
import { UPDATE_TICKET_FORM } from "../components/tickets/components/updateTicketForm";
import { generateAdditionalExternalRefsPUTData } from "../helpers/additionalExternalRefsHelpers";

export const ticketsListLoaded = (payload) => {
    return {
        type: TICKETS_LIST_LOADED,
        payload,
    };
};

export const updateTicket = (ticketId, dataToUpdate, isLoadTicket, ticketForUpdate) => async (dispatch, getState) => {
    try {
        const {
            phaseCode: { phaseCodes, bidItems },
        } = ticketForUpdate;
        const state = getState();
        const updatedTicket = _.cloneDeep(dataToUpdate);
        const { additionalExternalRefs } = updatedTicket;

        const body = {
            externalRef: updatedTicket.externalRef,
            quantity: updatedTicket.quantity,
            isDuplicate: updatedTicket.isDuplicate,
            phaseCode: {
                bidItems,
            },
        };

        if (updatedTicket.phaseCode && updatedTicket.phaseCodeQuantity) {
            body.phaseCode.phaseCodes = { [updatedTicket.phaseCode]: updatedTicket.phaseCodeQuantity };
        }

        if (updatedTicket.trailersQuantity && updatedTicket.trailersQuantity.length) {
            const hasTrailerValues = !!updatedTicket.trailersQuantity.filter((i) => !!+i.quantity).length;
            if (hasTrailerValues) {
                body.trailersQuantity = updatedTicket.trailersQuantity.map((i) => ({
                    quantity: +i.quantity ? +i.quantity : 0,
                    trailerId: i.trailerId,
                }));
            } else {
                body.trailersQuantity = null;
            }
        }

        // https://github.com/TruckITllc/truckit-frontend/issues/2944
        if (isLoadTicket) {
            const initialQuantity = getFormInitialValues(UPDATE_TICKET_FORM)(state).quantity;
            body.quantity = dataToUpdate.quantity - initialQuantity;
        }

        if (!_.isEmpty(additionalExternalRefs)) {
            delete body.externalRef;
            body.additionalExternalRef = generateAdditionalExternalRefsPUTData(additionalExternalRefs);
        }
        await updateTicketRequest(ticketId, body);
    } catch (error) {
        formatReduxFormErrors(error);
    }
};

export const ticketsListUpdate = (payload) => {
    return {
        type: TICKETS_LIST_UPDATE,
        payload,
    };
};

export const ticketsListChangeTicketStatus = (payload) => {
    return {
        type: TICKETS_LIST_CHANGE_TICKET_STATUS,
        payload,
    };
};

export const ticketsListReplaceTicket = (payload) => {
    return {
        type: TICKETS_LIST_REPLACE_TICKET,
        payload,
    };
};

export const ticketsListRemoveTicket = (payload) => {
    return {
        type: TICKETS_LIST_REMOVE_TICKET,
        payload,
    };
};

export const setLoading = (payload) => ({ type: TICKETS_SET_LOADING, payload });
