import { MAIN_THEME } from "../styles/globalThemeConfig";
import { PROFILE_MEASURE_SYSTEM } from "./accounts";
import { IS_PROD_ENV, IS_TEST_OR_LOCAL_OR_STAGE_ENVS } from "./global";

export const TRUCK_IT_COMPANY_ID = 1;
export const TRUCK_IT_COMPANY_NAME = "Truck IT";
export const I80_COMPANY_NAME = "I-80 Concrete";
// admin user is user form company with ID === 1
export const IS_ADMIN_USER = (companyId) => companyId === TRUCK_IT_COMPANY_ID;

export const USER_ROLE_TRUCKER = 1; // hauler
export const USER_ROLE_CUSTOMER = 2; // contractor
export const USER_ROLE_INSPECTOR = 3;
// export const USER_ROLE_BUYER = 4;
// export const USER_ROLE_SCHEDULER = 5;
export const USER_ROLE_LIMITED_CUSTOMER = 6;
export const USER_ROLE_SUB_TRUCKER = 7;
export const USER_ROLE_I80_CUSTOMER = 8;
export const USER_ROLE_RESTRICTED_CUSTOMER = 9;

export const USER_ROLES = {
    [USER_ROLE_TRUCKER]: "Trucker",
    [USER_ROLE_CUSTOMER]: "Customer",
    [USER_ROLE_INSPECTOR]: "Inspector",
    [USER_ROLE_LIMITED_CUSTOMER]: "Limited customer",
    [USER_ROLE_SUB_TRUCKER]: "Sub trucker",
    [USER_ROLE_I80_CUSTOMER]: "I80 Customer",
    [USER_ROLE_RESTRICTED_CUSTOMER]: "Restricted Customer",
};

export const COMPANY_TYPE_TRUCKER = 1; // dispatcher
export const COMPANY_TYPE_CONTRACTOR = 2;
export const COMPANY_TYPE_LIMITED = 3;
export const COMPANY_TYPE_LIMITED_TRUCKER = 4;
export const COMPANY_TYPE_LIMITED_CONTRACTOR = 5;
export const COMPANY_TYPE_RESTRICTED_CUSTOMER = 6;
export const COMPANY_TYPES = {
    [COMPANY_TYPE_TRUCKER]: "Trucker",
    [COMPANY_TYPE_CONTRACTOR]: "Contractor",
    [COMPANY_TYPE_LIMITED]: "Limited",
    [COMPANY_TYPE_LIMITED_TRUCKER]: "Limited trucker",
    [COMPANY_TYPE_LIMITED_CONTRACTOR]: "Limited contractor",
};
export const COMPANY_TYPES_FORM_OPTIONS = {
    [COMPANY_TYPE_TRUCKER]: "Trucker",
    [COMPANY_TYPE_CONTRACTOR]: "Contractor",
    [COMPANY_TYPE_LIMITED]: "Limited",
};

export const IS_HAULER_COMPANY = (companyType) => companyType === COMPANY_TYPE_TRUCKER;

export const IS_CONTRACTOR_USER = (role) => role === USER_ROLE_CUSTOMER || role === USER_ROLE_LIMITED_CUSTOMER;
export const IS_HAULER_USER = (role) => role === USER_ROLE_TRUCKER || role === USER_ROLE_SUB_TRUCKER;
export const IS_INSPECTOR_USER = (role) => role === USER_ROLE_INSPECTOR;

export const IS_LIMITED_COMPANY = (companyType) =>
    companyType === COMPANY_TYPE_LIMITED_TRUCKER || companyType === COMPANY_TYPE_LIMITED_TRUCKER;
export const JO_STATUS_COMPLETED_ID = 1;
export const JO_STATUS_ACTIVE_ID = 2;
export const JO_STATUS_NOT_STARTED_ID = 3;
export const JO_STATUS_PENDING_ID = 4;

// used on fronted only
export const JO_TEMPLATED_ID = 1;
export const JO_REQUEST_ID = 1;
export const JO_DRAFT_ID = 1;

export const JO_STATUSES_UI_MAPPING = {
    completed: JO_STATUS_COMPLETED_ID,
    active: JO_STATUS_ACTIVE_ID,
    notStarted: JO_STATUS_NOT_STARTED_ID,
    pending: JO_STATUS_PENDING_ID,
};

export const PO_TYPE_AGG_DIRT_SAND = 1;
export const PO_TYPE_TIMBER = 2;
export const PO_TYPE_POWDERS = 3;
export const PO_TYPE_ASPHALT_MILLINGS = 4;
export const PO_TYPES = {
    [PO_TYPE_AGG_DIRT_SAND]: "Agg/Dirt/Sand",
    [PO_TYPE_TIMBER]: "Timber",
    [PO_TYPE_POWDERS]: "Powders",
    [PO_TYPE_ASPHALT_MILLINGS]: "Asphalt/Millings",
};

export const PROJECT_STATUS_ACTIVE = 1;
export const PROJECT_STATUS_CLOSED = 2;
export const PROJECT_STATUS_ARCHIVED = 3;
export const PROJECT_STATUSES = {
    [PROJECT_STATUS_ACTIVE]: "Active",
    [PROJECT_STATUS_CLOSED]: "Closed",
    [PROJECT_STATUS_ARCHIVED]: "Archive",
};

export const TIME_ZONE_UTC = 1;
export const TIME_ZONE_US_ALASKA = 2;
export const TIME_ZONE_US_ALEUTIAN = 3;
export const TIME_ZONE_US_ARIZONA = 4;
export const TIME_ZONE_US_CENTRAL = 5;
export const TIME_ZONE_US_EASTERN = 6;
export const TIME_ZONE_US_EAST_INDIANA = 7;
export const TIME_ZONE_US_HAWAII = 8;
export const TIME_ZONE_US_INDIANA_STARKE = 9;
export const TIME_ZONE_US_MICHIGAN = 10;
export const TIME_ZONE_US_MOUNTAIN = 11;
export const TIME_ZONE_US_PACIFIC = 12;
export const TIME_ZONE_US_SAMOA = 13;
export const TIME_ZONES = {
    [TIME_ZONE_UTC]: "UTC",
    [TIME_ZONE_US_ALASKA]: "US/ALASKA",
    [TIME_ZONE_US_ALEUTIAN]: "US/ALEUTIAN",
    [TIME_ZONE_US_ARIZONA]: "US/ARIZONA",
    [TIME_ZONE_US_CENTRAL]: "US/CENTRAL",
    [TIME_ZONE_US_EASTERN]: "US/EASTERN",
    [TIME_ZONE_US_EAST_INDIANA]: "US/EAST-INDIANA",
    [TIME_ZONE_US_HAWAII]: "US/HAWAII",
    [TIME_ZONE_US_INDIANA_STARKE]: "US/INDIANA-STARKE",
    [TIME_ZONE_US_MICHIGAN]: "US/MICHIGAN",
    [TIME_ZONE_US_MOUNTAIN]: "US/MOUNTAIN",
    [TIME_ZONE_US_PACIFIC]: "US/PACIFIC",
    [TIME_ZONE_US_SAMOA]: "US/SAMOA",
};

export const TRUCK_STATUS_OFFLINE = 1;
export const TRUCK_STATUS_ONLINE = 2;
export const TRUCK_STATUS_AN_ROUTE_TO_PICK_UP = 3;
export const TRUCK_STATUS_AN_ROUTE_TO_DROPOFF = 4;
export const TRUCK_STATUS_AT_PICK_UP = 5;
export const TRUCK_STATUS_AT_DROPOFF = 6;
export const TRUCK_STATUS_AT_PAVER = 7;
export const TRUCK_STATUSES = {
    [TRUCK_STATUS_OFFLINE]: () => "Offline",
    [TRUCK_STATUS_ONLINE]: () => "Online",
    [TRUCK_STATUS_AT_PICK_UP]: () => "Picking Up",
    [TRUCK_STATUS_AN_ROUTE_TO_PICK_UP]: (name) => `En Route to ${name || "Pick-Up"}`,
    [TRUCK_STATUS_AT_DROPOFF]: () => "Dropping Off",
    [TRUCK_STATUS_AN_ROUTE_TO_DROPOFF]: (name) => `En Route to ${name || "Drop-Off"}`,
    [TRUCK_STATUS_AT_PAVER]: () => "At Paver",
};

export const TRUCKER_TYPE_DISPATCH = 1;
export const TRUCKER_TYPE_DRIVER = 2;
export const TRUCKER_TYPES = {
    [TRUCKER_TYPE_DISPATCH]: "Dispatch",
    [TRUCKER_TYPE_DRIVER]: "Driver",
};

export const TANDEM = 1;
export const TRAILER = 2;
export const TRI_AXLE = 3;
export const QUAD_AXLE = 4;
export const INSULATED_TANDEM = 5;
export const INSULATED_TRAILER = 6;
export const LOGGING_TRAILER = 7;
export const INSULATED_TRI_AXLE = 8;
export const TRUCK_TYPES = {
    [TANDEM]: "Tandem",
    [TRAILER]: "Trailer",
    [TRI_AXLE]: "Tri Axle",
    [QUAD_AXLE]: "Quad Axle",
    [INSULATED_TANDEM]: "Insulated Tandem",
    [INSULATED_TRAILER]: "Insulated Trailer",
    [LOGGING_TRAILER]: "Logging Trailer",
    [INSULATED_TRI_AXLE]: "Insulated Tri Axle",
};

export const TICKET_STATUS_OPEN = 1;
export const TICKET_STATUS_CLOSED = 2;
export const TICKET_STATUS_RECONCILED = 3;
export const TICKET_STATUS_DISPUTED = 4;
export const TICKET_STATUS_INTERRUPTED = 5;
export const TICKET_STATUS_REJECTED_BY_DRIVER = 6;
export const TICKET_STATUS_FINALLY_RECONCILED = 7;
export const TICKET_STATUSES_LABELS = {
    [TICKET_STATUS_OPEN]: "Open",
    [TICKET_STATUS_CLOSED]: "Closed",
    [TICKET_STATUS_RECONCILED]: "Reconciled",
    [TICKET_STATUS_DISPUTED]: "Disputed",
    [TICKET_STATUS_INTERRUPTED]: "Auto Closed",
    [TICKET_STATUS_REJECTED_BY_DRIVER]: "Rejected By Driver",
    [TICKET_STATUS_FINALLY_RECONCILED]: "Finally reconciled",
};

export const TICKET_TYPE_TICKET = 1;
export const TICKET_TYPE_AIRTICKET = 2;
export const TICKET_TYPE_MANUAL = 3;
export const TICKET_TYPE_SUBTICKET = 4;

export const TICKET_SOURCE_TICKET = 0;
export const TICKET_SOURCE_AIRTICKET = 1;
export const TICKET_SOURCE_SUBTICKET = 2;
export const TICKET_SOURCE_MANUAL = 3;

export const TICKET_SOURCES = {
    [TICKET_SOURCE_AIRTICKET]: "Air Ticket",
    [TICKET_SOURCE_MANUAL]: "Manual Ticket",
    [TICKET_SOURCE_SUBTICKET]: "Sub Ticket",
};
export const TICKET_TYPES_LABELS = {
    [TICKET_TYPE_TICKET]: "T",
    [TICKET_TYPE_AIRTICKET]: "A",
    [TICKET_TYPE_MANUAL]: "M",
    [TICKET_TYPE_SUBTICKET]: "S",
};

export const TICKETS_TITLES_LIST = {
    [TICKET_SOURCE_TICKET]: "Ticket",
    [TICKET_SOURCE_AIRTICKET]: "Air Ticket",
    [TICKET_SOURCE_MANUAL]: "Manual Ticket",
    [TICKET_SOURCE_SUBTICKET]: "Sub Ticket",
};

export const TICKET_USAGE_TYPE_STANDALONE = 1;
export const TICKET_USAGE_TYPE_REUSABLE = 2;
export const TICKET_USAGE_TYPES = {
    [TICKET_USAGE_TYPE_STANDALONE]: "Ticket Per Load",
    [TICKET_USAGE_TYPE_REUSABLE]: "Ticket Per Day",
};

export const TICKET_REPORT_TYPE_CSV = 1;
export const TICKET_REPORT_TYPE_EXCEL = 2;
export const TICKET_REPORT_TYPES = {
    [TICKET_REPORT_TYPE_CSV]: "CSV",
    [TICKET_REPORT_TYPE_EXCEL]: "Excel",
};

export const AIR_TICKET_STATUS_ACCEPT = 1;
export const AIR_TICKET_STATUS_REJECT = 2;
export const AIR_TICKET_STATUS_ACCEPT_LABEL = "accept";
export const AIR_TICKET_STATUS_REJECT_LABEL = "reject";

export const UNIT_OF_MEASURE_HOUR = 1;
export const UNIT_OF_MEASURE_TON = 2;
export const UNIT_OF_MEASURE_CUBIC_YARD = 3;
export const UNIT_OF_MEASURE_LOAD = 4;
export const UNIT_OF_MEASURE_CUBIC_METER = 5;
export const UNIT_OF_MEASURE_TONNE = 6;
export const UNIT_OF_MEASURE_BY_ID = {
    [UNIT_OF_MEASURE_HOUR]: "Hour",
    [UNIT_OF_MEASURE_TON]: "Ton",
    [UNIT_OF_MEASURE_CUBIC_YARD]: "Cubic Yard",
    [UNIT_OF_MEASURE_LOAD]: "Load",
    [UNIT_OF_MEASURE_CUBIC_METER]: "Cubic Meter",
    [UNIT_OF_MEASURE_TONNE]: "Tonne",
};
export const UNIT_OF_MEASURE_LABELS = {
    [UNIT_OF_MEASURE_HOUR]: "Hourly",
    [UNIT_OF_MEASURE_TON]: "Tonnage",
    [UNIT_OF_MEASURE_CUBIC_YARD]: "Cubic Yard",
    [UNIT_OF_MEASURE_LOAD]: "Load",
    [UNIT_OF_MEASURE_CUBIC_METER]: "Cubic Meter",
    [UNIT_OF_MEASURE_TONNE]: "Tonnage",
};

export const UNIT_OF_MEASURE_LABELS_BY_NAME = {
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_HOUR]]: "Hourly",
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TON]]: "Tonnage",
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_YARD]]: "Cubic Yard",
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_LOAD]]: "Load",
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_METER]]: "Cubic Meter",
    [UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TONNE]]: "Tonnage",
};

export const TON_TYPE_BY_METRIC_SYSTEM = {
    [PROFILE_MEASURE_SYSTEM.IMPERIAL]: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TON],
    [PROFILE_MEASURE_SYSTEM.METRIC]: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_TONNE],
};

export const VOLUMETRIC_TYPE_BY_METRIC_SYSTEM = {
    [PROFILE_MEASURE_SYSTEM.IMPERIAL]: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_YARD],
    [PROFILE_MEASURE_SYSTEM.METRIC]: UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_CUBIC_METER],
};

export const WEBSOCKET_DATA_TYPE_NOTIFICATION = 1;
export const WEBSOCKET_DATA_TYPE_NEW_TRUCK_COORD = 2;
export const WEBSOCKET_DATA_TYPE_TICKET_TIMER_UPDATE = 3;
export const WEBSOCKET_DATA_TYPES = {
    [WEBSOCKET_DATA_TYPE_NOTIFICATION]: "Notification",
    [WEBSOCKET_DATA_TYPE_NEW_TRUCK_COORD]: "New Truck Coordinates",
    [WEBSOCKET_DATA_TYPE_TICKET_TIMER_UPDATE]: "Update ticket timer",
};

export const WEBSOCKET_DATA_SUB_TYPE_TRUCK_EVENT = 1;
export const WEBSOCKET_DATA_SUB_TYPE_TICKET_EVENT = 2;
export const WEBSOCKET_DATA_SUB_TYPE_JOB_BOARD_EVENT = 3;
export const WEBSOCKET_DATA_SUB_TYPE_JO_EVENT = 4;
export const WEBSOCKET_DATA_SUB_TYPE_PO_EVENT = 5;
export const WEBSOCKET_DATA_SUB_TYPE_MESSAGE_EVENT = 6;
export const WEBSOCKET_DATA_SUB_TYPE_PAGE_EVENT = 7;
export const WEBSOCKET_DATA_TYPE_SUB_TYPE_SMS_MSG_EVENT = 9;
export const WEBSOCKET_DATA_TYPE_SUB_TYPE_LOSING_ACCESS_EVENT = 10;
export const WEBSOCKET_DATA_TYPE_SUB_TYPE_ANOMALY_DETECTION = 11;
export const WEBSOCKET_DATA_TYPE_SUB_TYPE_SMART_DISPATCH = 15;

// These event types are only used on frontend
export const WEBSOCKET_EVENT_TYPE_UNKNOWN = 1;
// Tickets events
export const WEBSOCKET_EVENT_TYPE_TICKET_CLOSED = 2;
export const WEBSOCKET_EVENT_TYPE_TICKET_DISPUTED = 3;
export const WEBSOCKET_EVENT_TYPE_TICKET_INTERRUPTED = 4;
export const WEBSOCKET_EVENT_TYPE_TICKET_OPENED = 6;
export const WEBSOCKET_EVENT_TYPE_TICKET_REOPENED = 7;
export const WEBSOCKET_EVENT_TYPE_TICKET_RECONCILED = 8;
export const WEBSOCKET_EVENT_TYPE_TICKET_FINALLY_RECONCILED = 9;
// Trucks events
export const WEBSOCKET_EVENT_TYPE_TRUCK_ENTERED = 9;
export const WEBSOCKET_EVENT_TYPE_TRUCK_LEFT = 10;
export const WEBSOCKET_EVENT_TYPE_TRUCK_NOT_MOVING = 12;
export const WEBSOCKET_EVENT_TYPE_TRUCK_NOT_SEND_COORDS = 12;
export const WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_OFFLINE = 13;
export const WEBSOCKET_EVENT_TYPE_TRUCK_DEVICE_WENT_ONLINE = 14;
export const WEBSOCKET_EVENT_TYPE_TRUCK_LINKED = 15;
export const WEBSOCKET_EVENT_TYPE_TRUCK_SIGN_IN = 16;
// JO events
export const WEBSOCKET_EVENT_TYPE_JO_CLOSED = 17;
export const WEBSOCKET_EVENT_TYPE_JO_CREATED = 18;
export const WEBSOCKET_EVENT_TYPE_JO_DELETED = 19;
export const WEBSOCKET_EVENT_TYPE_JO_REOPENED = 20;
export const WEBSOCKET_EVENT_TYPE_JO_UPDATED = 21;
export const WEBSOCKET_EVENT_TYPE_TRUCK_PAUSED = 22;
export const WEBSOCKET_EVENT_TYPE_TRUCK_RESUMED = 23;
export const WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_COMPLETED = 24;
export const WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR = 25;
export const WEBSOCKET_EVENT_TYPE_REQUEST_CREATED = 26;
export const WEBSOCKET_EVENT_TYPE_REQUEST_FILL = 27;
// Message events
export const WEBSOCKET_EVENT_TYPE_MESSAGE_SENT = 28;
export const WEBSOCKET_EVENT_TYPE_MESSAGE_NOT_SENT = 29;
// Ticpic report events
export const WEBSOCKET_EVENT_TYPE_TICPIC_REPORT = 30;
export const WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED = 31;
// Loosing access events
export const WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS = 32;
// Anomaly Detection events
export const WEBSOCKET_EVENT_TYPE_IDLE_TIME = 33;
export const WEBSOCKET_EVENT_TYPE_CROWDED_ZONE = 34;
export const WEBSOCKET_EVENT_TYPE_SLOW_TRIP = 35;
export const WEBSOCKET_EVENT_TYPE_LATE_DRIVER = 36;
export const WEBSOCKET_EVENT_TYPE_MISSING_TICKET = 37;
export const WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK = 38;

export const WEBSOCKET_DATA_TYPES_FOR_ERROR_NOTIFICATION = [
    WEBSOCKET_EVENT_TYPE_IDLE_TIME,
    WEBSOCKET_EVENT_TYPE_CROWDED_ZONE,
    WEBSOCKET_EVENT_TYPE_SLOW_TRIP,
    WEBSOCKET_EVENT_TYPE_LATE_DRIVER,
    WEBSOCKET_EVENT_TYPE_MISSING_TICKET,

    WEBSOCKET_EVENT_TYPE_TICPIC_REPORT_FAILED,

    WEBSOCKET_EVENT_TYPE_SMART_DISPATCH_BUNDLE_ERROR,
    WEBSOCKET_EVENT_TYPE_NO_ROUTE_TRUCK,
];

export const WEBSOCKET_DATA_TYPES_FOR_WARNING_NOTIFICATION = [WEBSOCKET_EVENT_TYPE_LOOSING_ACCESS];

export const HIGHLIGHT_NOTIFICATIONS = [WEBSOCKET_EVENT_TYPE_REQUEST_CREATED, WEBSOCKET_EVENT_TYPE_REQUEST_FILL];
export const ALERT_NOTIFICATIONS = [
    WEBSOCKET_EVENT_TYPE_IDLE_TIME,
    WEBSOCKET_EVENT_TYPE_CROWDED_ZONE,
    WEBSOCKET_EVENT_TYPE_SLOW_TRIP,
    WEBSOCKET_EVENT_TYPE_LATE_DRIVER,
    WEBSOCKET_EVENT_TYPE_MISSING_TICKET,
];

export const TICPIC_REPORT_STATUS_IN_PROGRESS = "ticpicReportStatusInProgress";
export const TICPIC_REPORT_STATUS_SUCCESS = "ticpicReportStatusSuccess";
export const TICPIC_REPORT_STATUS_FAILURE = "ticpicReportStatusFailure";

export const WEBSOCKET_EVENT_TYPE_TRUCK_OWNER_SHIFT_REQUESTED = 105; // temp code
export const WEBSOCKET_EVENT_TYPE_DRIVER_SHIFT_REQUESTED = 106; // temp code

export const REGIONS = {
    JACKSONVILLE: 1,
    ORLANDO: 2,
    TAMPA: 3,
    FT_MYERS_MIAMI: 4,
};

export const FLEET_TYPE_MY_FLEET_ONLY = 1;
export const FLEET_TYPE_GRANTED_ONLY = 2;
export const FLEET_TYPE_SHIFTABLE_ONLY = 3;
export const SITES_TYPE_PUBLIC = 1;
export const SITES_TYPE_PRIVATE = 2;
export const SITES_STATUS_ACTIVE = 1;
export const SITES_STATUS_ARCHIVE = 3;

export const IDLE_STATUS_WITHOUT_TRUCKS = 1;
export const IDLE_STATUS_WITH_TRUCKS = 2;

// frontend only
export const TRUCK_ACTIVE_AVAILABILITY_FILTERS_ID = 1;
export const TRUCK_INACTIVE_AVAILABILITY_FILTERS_ID = 2;

export const AVAILABILITY_ACTIVE = "active";
export const AVAILABILITY_INACTIVE = "inactive";

export const TRUCK_ACTIVITY_EVENT_DO = "DO";
export const TRUCK_ACTIVITY_EVENT_DO_COLOR = MAIN_THEME.palette.general.emeraldGreen;
export const TRUCK_ACTIVITY_EVENT_PU = "PU";
export const TRUCK_ACTIVITY_EVENT_PU_COLOR = MAIN_THEME.palette.general.lightBlue;
export const TRUCK_ACTIVITY_ALL_EVENTS = "ALL";
export const TRUCK_ACTIVITY_ALL_EVENTS_COLOR = MAIN_THEME.palette.general.paleBlue;
export const TRUCK_ACTIVITY_NA = "NA";
export const TRUCK_ACTIVITY_NA_EVENT_COLOR = MAIN_THEME.palette.general.lightGrey;

export const DEVICES_TYPE_HUB = 3;
export const DEVICES_TYPE_PAVERTRACKER = 1;
export const DEVICES_TYPE_MILLINGTRACKER = 2;
export const DEVICES_TYPE_SAMSARA = 4;
export const DEVISES_TYPES = {
    [DEVICES_TYPE_HUB]: "Hub",
    [DEVICES_TYPE_PAVERTRACKER]: "Pavertracker",
    [DEVICES_TYPE_MILLINGTRACKER]: "Millingtrackers",
    [DEVICES_TYPE_SAMSARA]: "Samsara",
};

export const REPORT_TYPE_TITLE = "Report Type";
export const REPORT_TYPE_KEY = "reportType";
export const REPORT_TYPE_CSV = 1;
export const REPORT_TYPE_EXCEL = 2;
export const REPORT_TYPE_QUICK_BOOK_CSV = 3;
export const REPORT_TYPE_QUICK_BOOK_IIF = 4;
export const REPORT_TYPE_PDF = 5;
export const REPORT_TYPE_PAY_ADVICE = 6;
export const REPORT_TYPES = {
    [REPORT_TYPE_CSV]: "CSV",
    [REPORT_TYPE_EXCEL]: "Excel",
    [REPORT_TYPE_QUICK_BOOK_IIF]: "QuickBooks Desktop",
    [REPORT_TYPE_QUICK_BOOK_CSV]: "QuickBooks Online",
    [REPORT_TYPE_PAY_ADVICE]: "Pay Advice",
};

export const isReportFormat = (type) =>
    type === REPORT_TYPE_CSV ||
    type === REPORT_TYPE_EXCEL ||
    type === REPORT_TYPE_QUICK_BOOK_CSV ||
    type === REPORT_TYPE_QUICK_BOOK_IIF ||
    type === REPORT_TYPE_PDF ||
    type === REPORT_TYPE_PAY_ADVICE;

export const SITE_STATS_ALL_EVENTS_TYPE = "siteStatsAllEvents";
export const SITE_STATS_ALL_EVENTS_TYPE_COLOR = MAIN_THEME.palette.general.paleBlue;
export const SITE_STATS_TRUCK_EVENTS_TYPE = "siteStatsTruckEvents";
export const SITE_STATS_TRUCK_EVENTS_TYPE_COLOR = MAIN_THEME.palette.general.lightBlue;

// https://github.com/TruckITllc/truckit-frontend/issues/3327#issuecomment-1274666121
const NWW_ID_PROD = 882;
const HKS_ID_PROD = 1760;
const TEST_COMPANY_ID_PROD = 1384;

const TEST_COMPANY_ID_TEST = 1640;
const PMAC_COMPANY_ID_TEST = 662;

export const getCompaniesWithDefaultPhotoRequiredFalse = () => {
    if (IS_PROD_ENV) {
        return [NWW_ID_PROD, HKS_ID_PROD, TEST_COMPANY_ID_PROD];
    }
    if (IS_TEST_OR_LOCAL_OR_STAGE_ENVS) {
        return [TEST_COMPANY_ID_TEST, PMAC_COMPANY_ID_TEST];
    }

    return [];
};
export const PHOTO_REQUIRED_FALSE_COMPANIES = getCompaniesWithDefaultPhotoRequiredFalse();

export const DEFAULT_CHART_COLOR = MAIN_THEME.palette.general.lightBlue;
export const DEFAULT_COMBINED_CHART_COLOR = MAIN_THEME.palette.general.paleBlue;
export const TRAILER_LINK_ACTION = 1;
export const TRAILER_UNLINK_ACTION = 2;

export const TRAILER_HISTORY_EVENT = "TRAILER_HISTORY_EVENT";
export const TRAILER_HISTORY_COMBINED_EVENTS = "TRAILER_HISTORY_COMBINED_EVENTS";
