import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { assignRegion, getRegions } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AppModal from "../core/modal";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";

const AssignTruckRegionDialog = (props) => {
    const {
        regionDialogOpen,
        handleClose,
        truckForEdit,
        form,
        formValues: { region },
        handleSubmit,
        onSubmit,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const dispatch = useDispatch();
    const loadOptions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getRegions({
            truck: truckForEdit.id,
        }).then((data) => {
            const results = data.data.map((region) => ({ value: region.id, label: region.name }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    useEffect(() => {
        if (regionDialogOpen) {
            dispatch(
                change(
                    form,
                    "region",
                    truckForEdit.region ? { label: truckForEdit.region.name, value: truckForEdit.region.id } : "",
                ),
            );
        }
    }, [regionDialogOpen]);

    const handleAssignRegion = () => {
        const { id } = truckForEdit;

        const payload = {
            truckRegionId: region ? region.value : null,
        };

        setLoading(true);

        assignRegion(id, payload)
            .then(() => {
                setIsHandledSuccessfully(true);
                handleClose();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            })
            .finally(() => {
                onSubmit();
            });
    };

    return (
        <AppModal isOpen={regionDialogOpen} form={ASSIGN_TRUCK_REGION_FORM} closeModal={handleClose}>
            <form
                noValidate={true}
                onSubmit={handleSubmit(handleAssignRegion)}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            >
                {isLoading && <Loader />}
                <h2 className="title">ASSIGN REGION</h2>
                <Field
                    name="region"
                    loadOptions={loadOptions}
                    isClearable={true}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    placeholder="Select Region"
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(false) }} />}
        </AppModal>
    );
};

AssignTruckRegionDialog.propTypes = {
    regionDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    truckForEdit: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
};

export const ASSIGN_TRUCK_REGION_FORM = "assignRegionForm";

export default withRouter(
    compose(
        reduxForm({
            form: ASSIGN_TRUCK_REGION_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "id", "region"),
            };
        }),
    )(AssignTruckRegionDialog),
);
