import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import _ from "lodash";
import clsx from "clsx";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import {
    DEFAULT_DATE_TIME_FORMAT,
    LOADER_WHITE_OVERLAY,
    PROCESS_SERVER_ERROR,
    SERVER_DATE_FORMAT,
} from "../../constants/global";
import {
    AIR_TICKET_STATUS_ACCEPT,
    AIR_TICKET_STATUS_ACCEPT_LABEL,
    AIR_TICKET_STATUS_REJECT,
    AIR_TICKET_STATUS_REJECT_LABEL,
    IS_ADMIN_USER,
    IS_INSPECTOR_USER,
    TICKET_STATUS_OPEN,
    UNIT_OF_MEASURE_BY_ID,
} from "../../constants/maps";
import { toDate } from "../../helpers/date";
import TicketMap from "./components/ticketMap";
import ErrorNotification from "../core/notification";
import { TicketStatusPillWrapper } from "../core/pills";
import Loader from "../core/loader";
import AppModal from "../core/modal";
import UnitOfMeasure from "../core/unitOfMeasure";
import CloseTicketForm, { CLOSE_TICKET_FORM } from "./components/closeTicketForm";
import DeleteTicketForm, { DELETE_TICKET_FORM } from "./components/deleteTicketForm";
import ReconcileTicketForm, { RECONCILE_TICKET_FORM } from "./components/reconcileTicketForm";
import { Box, makeStyles } from "@material-ui/core";
import { loadUnitOfMeasures } from "../../actions/globalActions";
import {
    getAirTicketTicPics,
    getTickets,
    getTicketsMapDetails,
    getTicketTicPics,
    openTicketsTicPicksPDF,
    reopenTicketById,
    uploadTicPic,
} from "../../dataServers/tickets";
import { ErrorBoundary } from "../core/errorBoudaries";
import { getSiteEditRoute } from "../../helpers/global";
import { SimpleLink } from "../core/link";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import CreateTicketForm, { EDIT_TICKET_FORM } from "./components/createTicketForm";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";
import { ticketsListLoaded } from "../../actions/tickets";
import Axios from "axios";
import moment from "moment";
import VerifyModal from "./components/verifyModal";
import TicketCard, { PrimaryTicketQty } from "./components/ticketCard";
import ListWaypoint from "../core/listWaypoint";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import Container from "@material-ui/core/Container";
import { PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import TicketAction from "./components/ticketAction";
import TicketMapAndTickPic from "./components/ticketMapAndTickPic";
import { formValueSelector, hasSubmitSucceeded, reset } from "redux-form";
import EditableImage from "./components/editableImage";
import { PrimaryButton } from "../core/buttons/primaryButton";
import SuccessNotification from "../core/successNotification";
import { usePrevious } from "../../helpers/usePrevious";
import TicPicSelectList from "./airTickets/ticpicSelectList";
import UpdateTicketForm, { UPDATE_TICKET_FORM } from "./components/updateTicketForm";
import { selectTicketsMeta } from "../../selectors/tickets";
import SplitJobForm, { SPLIT_JOB_FORM } from "../jobs/jobComponents/splitJobForm";

const imageStyles = {
    position: "absolute",
};

const imageContainerStyles = {
    width: 600,
};

const sliderStyles = makeStyles(() => ({
    content: {
        width: "auto",
    },
}));

const useStyles = makeStyles(() => ({
    ticketsList: {
        "& .full-table": {
            width: "100%",
        },
    },
    ticketsWrapper: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: "1%",

        "& .ticket-card": {
            width: "calc(100%/3 - 1%)",
            marginRight: "1%",
        },
    },
    actionIcons: {
        marginBottom: 12,
        padding: 0,

        "& svg": {
            width: 21,
            height: 21,
        },

        "&:last-child": {
            marginRight: 0,
        },
    },
    ticPicContainer: {
        padding: "20px 20px 20px 0",
    },
}));

let requestPromise;
let cancelPromise = null;

const getTrailersQuantity = (ticket) => {
    const trailersQuantity = (ticket?.additionalFields?.trailers_quantity || []).reduce((result, item) => {
        result[item.trailer_id] = item.quantity;
        return result;
    }, {});

    return ticket?.trailers?.map((trailer) => ({
        ...trailer,
        quantity: trailersQuantity[trailer.trailerId] ? trailersQuantity[trailer.trailerId].toString() : "",
    }));
};

function TicketsList(props) {
    const classes = useStyles();
    const sliderClasses = sliderStyles();
    const {
        isListView,
        account,
        errorMessage,
        ticketsFilters,
        ticketsListLoaded,
        tickets,
        ticketsCount,
        reloadList,
        needReloadTickets,
        showTitle,
        formValues,
        loadUnitOfMeasures,
        needLoadNewTickets,
        loadNewTickets,
        form,
        onlyView,
    } = props;
    const dispatch = useDispatch();

    const [ticketIdForClose, setTicketIdForClose] = useState(undefined);
    const [ticketForUpdate, setTicketForUpdate] = useState(null);
    const [ticketsData, setTicketData] = useState({});
    const [isMapDataLoading, setMapLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [successMessage, setSuccess] = useState(undefined);
    const [ticketIdForDelete, setTicketIdForDelete] = useState(undefined);
    const [ticketForSplit, setTicketForSplit] = useState(undefined);
    const [ticketForEdit, setTicketIdForEdit] = useState(undefined);
    const [ticketForReconcile, setTicketForReconcile] = useState(undefined);
    const [ticketForRouteInfoAnchorEl, setTicketForRouteInfoAnchorEl] = useState(undefined);
    const [ticketForRouteInfo, setTicketForRouteInfo] = useState(undefined);
    const [ticpicForTicket, setTicpicForTicket] = useState([]);
    const [ticPicList, setTicPicList] = useState(null);
    const [pictureTicketId, setpictureTicketId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [ticketOnEdit, setTicketForEdit] = useState({});
    const [croppedImage, setCroppedImage] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const hasSuccessEditTicket = useSelector(hasSubmitSucceeded(UPDATE_TICKET_FORM));

    const { loading } = useSelector(selectTicketsMeta);

    const prevTicketsFilters = usePrevious(ticketsFilters);

    const updateTicketsTableData = (newTickets) => {
        setTicketData(getTableData(newTickets));
    };

    const loadTickets = (concat) => {
        const currentPage = Math.ceil(tickets.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(tickets) ? +currentPage + 1 : 1;

        if (ticketsFilters?.keywords && _.isObject(ticketsFilters.keywords)) {
            ticketsFilters.keywords = ticketsFilters?.keywords[0]?.value;
        }

        if (requestPromise && cancelPromise) {
            cancelPromise.cancel();
            cancelPromise = null;
        }

        let params = {
            ...(ticketsFilters || {}),
            // required to reset JO as tickets should be get by joLineItemId=[],
            // get by job order required only on redirect from JO Summary
            jobOrder: undefined,
            jobOrders: undefined,
            joLineItemId: ticketsFilters.jobOrders
                ? _.cloneDeep(ticketsFilters.jobOrders)
                      .filter((i) => !_.isEmpty(i.joLineItems))
                      .map((i) => i.joLineItems)
                : undefined,
            [START_DATE_NAME]: moment(formValues[START_DATE_NAME]).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: moment(formValues[END_DATE_NAME]).format(SERVER_DATE_FORMAT),
            page: concat ? nextPage : 1,
        };

        const cancelToken = Axios.CancelToken.source();
        cancelPromise = cancelToken;

        setLoading(true);

        requestPromise = getTickets({
            ...params,
            cancelToken,
        });

        requestPromise
            .then((ticketsData) => {
                const newTickets = concat ? [...tickets, ...ticketsData.data] : ticketsData.data;

                requestPromise = null;

                ticketsListLoaded({
                    tickets: newTickets,
                    ticketsCount: ticketsData.meta.count,
                });
                updateTicketsTableData(newTickets);
                setLoading(false);
            })
            .catch((error) => {
                if (_.isEmpty(error)) {
                    return false;
                }

                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    const storeCroppedImage = (file) => {
        if (file) {
            setCroppedImage(file);
        }
    };

    const handleTicketsRemove = (ticketId) => {
        ticketsListLoaded({
            tickets: tickets.filter((i) => i.id !== ticketId),
            ticketsCount: ticketsCount - 1,
        });
    };

    const updateItemInTheList = (newItem) => {
        const newTickets = _.cloneDeep(tickets).map((i) => {
            if (i.id === newItem.id) {
                return newItem;
            }

            return i;
        });

        ticketsListLoaded({
            tickets: newTickets,
            ticketsCount,
        });
    };
    const handleClickTicketForRouteInfo = (event, ticket) => {
        setTicketForRouteInfoAnchorEl(event.currentTarget);

        setMapLoading(true);

        getTicketsMapDetails(ticket.id)
            .then(({ data }) => {
                setTicketForEdit(ticket);
                setTicketForRouteInfo(data);
                setMapLoading(false);
            })
            .catch((error) => {
                setMapLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const handleCloseTicketForRouteInfo = () => {
        setTicketForRouteInfo(undefined);
        setTicketForEdit(undefined);
        setTicketForRouteInfoAnchorEl(null);
    };

    // @todo optimize data load
    const handleHoverTicpicIcon = (event, ticket) => {
        const tickPicks = [];
        const request = ticket.isAirTicketLite ? getAirTicketTicPics : getTicketTicPics;

        request(ticket.id)
            .then(({ data }) => {
                data.forEach((i) => tickPicks.push(...i.attachments));
                if (tickPicks.length > 1) {
                    setTicPicList(tickPicks);
                } else {
                    setTicPicList(null);
                }
                setTicpicForTicket(tickPicks);
                setpictureTicketId(ticket.id);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const handleTicPicSelect = (ticPic) => {
        setTicpicForTicket([ticPic]);
    };

    const handleSuccessTicketClose = () => {
        setTicketIdForClose(undefined);
    };

    const handleSuccessTicketDelete = () => {
        handleTicketsRemove && handleTicketsRemove(ticketIdForDelete);
        setTicketIdForDelete(undefined);
    };

    const handleSuccessTicketReconcile = () => {
        setTicketForReconcile(undefined);
    };

    const handleCloseEditTicketForm = () => {
        setTicketForUpdate(null);
        dispatch(reset(UPDATE_TICKET_FORM));
    };

    const reopenTicket = (id) => {
        setLoading(true);

        reopenTicketById(id)
            .then(() => {
                const newTickets = tickets.map((i) => {
                    if (i.id === id) {
                        i.status = TICKET_STATUS_OPEN;
                    }

                    return i;
                });

                ticketsListLoaded({
                    tickets: newTickets,
                    ticketsCount: ticketsCount,
                });
                updateTicketsTableData(newTickets);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const openTicketActionsModal = (open, ticket) => {
        setOpenModal(open);
        setTicketForEdit(
            ticket
                ? {
                      ...ticket,
                      ...ticket.verificationNotes,
                      notes: ticket.verificationNotes.notes,
                  }
                : null,
        );
    };

    const uploadCroppedImage = () => {
        if (croppedImage && pictureTicketId) {
            setLoading(true);
            uploadTicPic(pictureTicketId, croppedImage)
                .then(() => {
                    setSuccess("Ticket Picture was saved.");
                })
                .catch((error) => {
                    setError(PROCESS_SERVER_ERROR(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(["Cannot save transformed image."]);
        }
    };

    const displayUserDate = (date) =>
        toDate({
            date: date,
            timezone: account.timezone,
        }).format(DEFAULT_DATE_TIME_FORMAT);

    const handleVerifyClose = () => {
        openTicketActionsModal(false);
    };

    useEffect(() => {
        if (ticketsFilters && !_.isEqual(prevTicketsFilters, ticketsFilters)) {
            loadTickets();
        }
    }, [ticketsFilters]);

    useEffect(() => {
        if (reloadList) {
            loadTickets();
            needReloadTickets && needReloadTickets(false);
        }
    }, [reloadList]);

    useEffect(() => {
        if (loadNewTickets) {
            loadTickets(true);
            needLoadNewTickets && needLoadNewTickets(false);
        }
    }, [loadNewTickets]);

    useEffect(() => {
        loadUnitOfMeasures();

        return () => {
            if (requestPromise && cancelPromise) {
                cancelPromise.cancel();
                cancelPromise = null;
            }
            ticketsListLoaded({
                tickets: [],
                ticketsCount: 0,
            });
        };
    }, []);

    const getTableData = (tickets) => {
        const rows = tickets.map((ticket, index) => {
            const {
                id,
                phaseCode,
                canEditNotes,
                grantedAccess,
                company,
                status,
                canClose,
                canBeReconciledByUser,
                externalRef,
                hauler,
                haulerCompany,
                ticket: ticketNumber,
                dropOffSite,
                jobNumber,
                payloadName,
                timeLoaded,
                isAirTicketLite,
                pickUpSite,
                joLineItem,
                truck,
                truckName,
                isSubticket,
                perUnitPrice,
                secondaryQuantity,
                primaryQuantity,
                primaryUom,
                unitOfMeasure,
                secondaryUom,
                createdDate,
                closedDate,
                totalPrice,
                payload,
                grossTons,
                netTons,
                tareTons,
                weighmaster,
            } = ticket;
            const phaseCodes =
                phaseCode && !_.isEmpty(phaseCode.phaseCodes)
                    ? phaseCode.phaseCodes.map((phaseCode) => phaseCode.code)
                    : [];
            const bidItems = phaseCode && phaseCode.bidItems ? Object.keys(phaseCode.bidItems) : [];

            return {
                index,
                id: externalRef || ticketNumber,
                type: <TicketStatusPillWrapper status={status} ticket={ticket} />,
                companyName: company && company.name,
                project: joLineItem ? (
                    <React.Fragment>
                        {!_.isEmpty(joLineItem.project) &&
                            joLineItem.project.externalJobNumber &&
                            `${joLineItem.project.externalJobNumber} -`}
                        {!_.isEmpty(joLineItem.project) && joLineItem.project.name}
                    </React.Fragment>
                ) : (
                    jobNumber || "N/A"
                ),
                hauler: !_.isEmpty(haulerCompany) ? haulerCompany.name : hauler,
                truck: !_.isEmpty(truck) ? truck.name : truckName,
                pickUpSite: (
                    <div className="--uppercase">
                        {pickUpSite ? (
                            <SimpleLink to={getSiteEditRoute(pickUpSite.id)} className="--uppercase">
                                {pickUpSite.name}
                            </SimpleLink>
                        ) : (
                            "N/A"
                        )}
                    </div>
                ),
                dropOfSite: (
                    <div className="--uppercase">
                        {dropOffSite ? (
                            <SimpleLink to={getSiteEditRoute(dropOffSite.id)} className="--uppercase">
                                {dropOffSite.name}
                            </SimpleLink>
                        ) : (
                            "N/A"
                        )}
                    </div>
                ),
                payload: payloadName || payload || "N/A",
                quantity:
                    primaryQuantity && primaryUom ? (
                        <React.Fragment>
                            <PrimaryTicketQty
                                primaryUom={primaryUom}
                                unitOfMeasure={unitOfMeasure}
                                primaryQuantity={primaryQuantity}
                            />
                            {!!secondaryQuantity && (
                                <div>
                                    ({secondaryQuantity}
                                    {` `}
                                    <UnitOfMeasure value={UNIT_OF_MEASURE_BY_ID[secondaryUom]} />)
                                </div>
                            )}
                        </React.Fragment>
                    ) : isAirTicketLite && netTons ? (
                        `${netTons} TONS`
                    ) : (
                        "N/A"
                    ),
                gross: grossTons ? grossTons + " TONS" : "N/A",
                tare: tareTons ? tareTons + " TONS" : "N/A",
                rate: (
                    <div>
                        {!isSubticket && !grantedAccess && perUnitPrice ? (
                            <span className="--green-text">${perUnitPrice.toFixed(2)}</span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </div>
                ),
                totalPrice: (
                    <React.Fragment>
                        {totalPrice && !isSubticket && !grantedAccess ? (
                            <span className="--green-text">${totalPrice.toFixed(2)}</span>
                        ) : (
                            <span>N/A</span>
                        )}
                    </React.Fragment>
                ),
                status:
                    createdDate || closedDate ? (
                        <React.Fragment>
                            <div>{createdDate && displayUserDate(createdDate)}</div>
                            <br />
                            <div>{closedDate && displayUserDate(closedDate)}</div>
                        </React.Fragment>
                    ) : timeLoaded ? (
                        moment.utc(timeLoaded).format(DEFAULT_DATE_TIME_FORMAT)
                    ) : (
                        "N/A"
                    ),
                weighmaster: weighmaster,
                code: phaseCodes ? phaseCodes.join(", ") : "N/A",
                bidItem: bidItems && bidItems.join(", "),
                action: !onlyView && (
                    <React.Fragment>
                        <Grid container alignItems={"center"} justify={"center"}>
                            <TicketMapAndTickPic
                                ticket={ticket}
                                account={account}
                                actionIconsClasses={classes.actionIcons}
                                isAirTicketLite={isAirTicketLite}
                                handleClickTicketForRouteInfo={handleClickTicketForRouteInfo}
                                handleHoverTicpicIcon={handleHoverTicpicIcon}
                            />
                        </Grid>
                        <TicketAction
                            grantedAccess={grantedAccess}
                            canClose={canClose}
                            setTicketIdForEdit={setTicketIdForEdit}
                            isAirTicketLite={isAirTicketLite}
                            setTicketIdForDelete={setTicketIdForDelete}
                            setTicketIdForClose={setTicketIdForClose}
                            status={status}
                            id={id}
                            canEditNotes={canEditNotes}
                            setTicketForReconcile={setTicketForReconcile}
                            setTicketForUpdate={setTicketForUpdate}
                            account={account}
                            ticket={ticket}
                            reopenTicket={reopenTicket}
                            openTicketActionsModal={openTicketActionsModal}
                            canBeReconciledByUser={canBeReconciledByUser}
                            setTicketForSplit={setTicketForSplit}
                        />
                    </React.Fragment>
                ),
            };
        });
        let columns = [
            {
                id: "type",
                label: "Status",
            },
            {
                id: "id",
                label: "Ticket #",
            },
        ];

        if (IS_ADMIN_USER(account.company.id)) {
            columns.push({
                id: "companyName",
                label: "Company",
            });
        }

        columns = [
            ...columns,
            {
                id: "project",
                label: "Project",
            },
            {
                id: "pickUpSite",
                label: "Pick-up SITE",
            },
            {
                id: "dropOfSite",
                label: "DROP-OFF SITE",
            },
            {
                id: "hauler",
                label: "Hauler",
            },
            {
                id: "truck",
                label: "Truck",
            },
            {
                id: "payload",
                label: "Payload",
            },
            {
                id: "quantity",
                label: "Quantity",
            },
            {
                id: "gross",
                label: "Gross",
            },
            {
                id: "tare",
                label: "Tare",
            },
        ];

        if (!IS_INSPECTOR_USER(account.role)) {
            columns.push(
                ...[
                    {
                        id: "totalPrice",
                        label: "Total Price",
                    },
                    {
                        id: "rate",
                        label: "Rate",
                    },
                ],
            );
        }

        columns = [
            ...columns,
            {
                id: "status",
                label: "Opened Closed",
            },
            {
                id: "weighmaster",
                label: "Weighmaster",
            },
            {
                id: "code",
                label: "Phase Code",
            },
            {
                id: "bidItem",
                label: "Bid Item",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        return {
            rows: rows,
            columns: columns,
        };
    };
    const wrapper = PAGE_GLOBAL_STYLES();

    return (
        <React.Fragment>
            <div className={clsx((isLoading || loading) && LOADER_WHITE_OVERLAY)}>
                {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                {showTitle && (
                    <Container className={wrapper.globalPageStyles}>
                        <br />
                        <h2 className="--text-center --text-h1-size">Tickets</h2>
                        <br />
                    </Container>
                )}
                {(isLoading || loading) && <Loader />}
                <div className={clsx(_.isEmpty(tickets) && "-min-height", classes.ticketsList)}>
                    {!_.isEmpty(tickets) ? (
                        isListView ? (
                            <TableWithStickyHeader
                                rows={ticketsData.rows}
                                fetchMore={loadTickets}
                                itemsCount={ticketsCount}
                                columns={ticketsData.columns}
                            />
                        ) : (
                            <Container className={wrapper.globalPageStyles}>
                                <br />
                                <div className={clsx(classes.ticketsWrapper)}>
                                    {tickets.map((ticket, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ListWaypoint
                                                    fetchMore={loadTickets}
                                                    index={index}
                                                    items={tickets}
                                                    itemsCount={ticketsCount}
                                                />
                                                <TicketCard
                                                    ticket={ticket}
                                                    account={account}
                                                    onlyView={onlyView}
                                                    actionIconsClasses={classes.actionIcons}
                                                    displayUserDate={displayUserDate}
                                                    setTicketForReconcile={setTicketForReconcile}
                                                    setTicketIdForClose={setTicketIdForClose}
                                                    handleClickTicketForRouteInfo={handleClickTicketForRouteInfo}
                                                    handleHoverTicpicIcon={handleHoverTicpicIcon}
                                                    openTicketActionsModal={openTicketActionsModal}
                                                    setTicketIdForDelete={setTicketIdForDelete}
                                                    setTicketIdForEdit={setTicketIdForEdit}
                                                    setTicketForSplit={setTicketForSplit}
                                                    setTicketForUpdate={setTicketForUpdate}
                                                    reopenTicket={reopenTicket}
                                                    form={form}
                                                    setLoading={setLoading}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </Container>
                        )
                    ) : (
                        <Container className={wrapper.globalPageStyles}>
                            <br />
                            <br />
                            <h1 className="--text-center">No tickets match your search / filter parameters.</h1>
                            <br />
                        </Container>
                    )}
                </div>
            </div>
            {errorMessage && <ErrorNotification message={errorMessage} />}
            {successMessage && (
                <SuccessNotification message={successMessage} config={{ onClose: () => setSuccess(null) }} />
            )}
            {hasSuccessEditTicket && (
                <SuccessNotification message={"Ticket was updated"} config={{ autoHideDuration: 5000 }} />
            )}
            {!!ticketIdForClose && (
                <AppModal
                    isOpen={!!ticketIdForClose}
                    form={CLOSE_TICKET_FORM}
                    closeModal={() => setTicketIdForClose(undefined)}
                >
                    <h1 className="title">Close Ticket #{ticketIdForClose}</h1>
                    <CloseTicketForm ticketId={ticketIdForClose} handleSuccess={handleSuccessTicketClose} />
                </AppModal>
            )}
            {!!ticketForEdit && (
                <AppModal
                    isOpen={!!ticketForEdit}
                    form={EDIT_TICKET_FORM}
                    closeModal={() => setTicketIdForEdit(undefined)}
                    modalStyles={{ width: 500 }}
                    у
                >
                    <CreateTicketForm
                        ticket={ticketForEdit}
                        loadTickets={loadTickets}
                        initialValues={{
                            ...ticketForEdit,
                            project: ticketForEdit.joLineItem
                                ? {
                                      value: ticketForEdit.joLineItem.project.id,
                                      label: ticketForEdit.joLineItem.project.name,
                                  }
                                : {},
                            joLineItemId: ticketForEdit.joLineItem
                                ? {
                                      value: ticketForEdit.joLineItem.jobOrderId,
                                      label: ticketForEdit.joLineItem.jobOrderId,
                                  }
                                : {},
                            truckId: ticketForEdit.truckName
                                ? {
                                      value: ticketForEdit.truckName,
                                      label: ticketForEdit.truckName,
                                  }
                                : null,
                            quantity: ticketForEdit.primaryQuantity || ticketForEdit.secondaryQuantity,
                            additionalExternalRefs:
                                ticketForEdit.additionalExternalRef &&
                                ticketForEdit.additionalExternalRef.map((ref) => ({
                                    label: { value: ref.label.id, label: ref.label.name },
                                    externalRef: ref.externalRef,
                                })),
                        }}
                        closeModal={() => setTicketIdForEdit(undefined)}
                        form={EDIT_TICKET_FORM}
                    />
                </AppModal>
            )}
            {!!ticketIdForDelete && (
                <AppModal
                    isOpen={!!ticketIdForDelete}
                    form={DELETE_TICKET_FORM}
                    closeModal={() => setTicketIdForDelete(undefined)}
                >
                    <h1 className="title">Delete Ticket #{ticketIdForDelete}</h1>
                    <DeleteTicketForm ticketId={ticketIdForDelete} handleSuccess={handleSuccessTicketDelete} />
                </AppModal>
            )}
            {!!ticketForUpdate && (
                <AppModal isOpen={!!ticketForUpdate} form={UPDATE_TICKET_FORM} closeModal={handleCloseEditTicketForm}>
                    <UpdateTicketForm
                        initialValues={{
                            quantity: ticketForUpdate.primaryQuantity,
                            isDuplicate: ticketForUpdate.isDuplicate,
                            externalRef: ticketForUpdate.externalRef,
                            phaseCode:
                                ticketForUpdate?.phaseCode?.phaseCodes &&
                                ticketForUpdate?.phaseCode?.phaseCodes[0] &&
                                ticketForUpdate?.phaseCode?.phaseCodes[0].code,
                            phaseCodeQuantity:
                                ticketForUpdate?.phaseCode?.phaseCodes &&
                                ticketForUpdate?.phaseCode?.phaseCodes[0] &&
                                ticketForUpdate?.phaseCode?.phaseCodes[0].qty,
                            trailersQuantity: getTrailersQuantity(ticketForUpdate),
                            additionalExternalRefs:
                                ticketForUpdate.additionalExternalRef &&
                                ticketForUpdate.additionalExternalRef.map((ref) => ({
                                    label: {
                                        value: ref.label.id,
                                        label: ref.label.name,
                                    },
                                    isDefault: ref.label.isDefault,
                                    externalRef: ref.externalRef,
                                    id: ref.id,
                                })),
                        }}
                        ticketForUpdate={ticketForUpdate}
                        setSuccess={setSuccess}
                        setError={setError}
                        closeModal={handleCloseEditTicketForm}
                        tickets={tickets}
                        loadTickets={loadTickets}
                    />
                </AppModal>
            )}
            {!!ticketForReconcile && (
                <AppModal
                    isOpen={!!ticketForReconcile}
                    form={RECONCILE_TICKET_FORM}
                    closeModal={() => setTicketForReconcile(undefined)}
                >
                    <h1 className="title">Reconcile Ticket #{ticketForReconcile.id}</h1>
                    <ReconcileTicketForm ticket={ticketForReconcile} handleSuccess={handleSuccessTicketReconcile} />
                </AppModal>
            )}
            {!!ticketForRouteInfoAnchorEl && (
                <Popover
                    id="simple-popover"
                    open={!!ticketForRouteInfoAnchorEl}
                    anchorEl={ticketForRouteInfoAnchorEl}
                    onClose={handleCloseTicketForRouteInfo}
                    disableRestoreFocus={true}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <div
                        style={{
                            width: "30vw",
                            height: "30vh",
                        }}
                        className={clsx(isMapDataLoading && LOADER_WHITE_OVERLAY)}
                    >
                        {isMapDataLoading && <Loader />}
                        <ErrorBoundary>
                            {!!ticketForRouteInfo && !!ticketOnEdit && (
                                <TicketMap height={"30vh"} ticketMapInfo={ticketForRouteInfo} ticket={ticketOnEdit} />
                            )}
                        </ErrorBoundary>
                    </div>
                </Popover>
            )}
            {!_.isEmpty(ticketOnEdit) && (
                <VerifyModal
                    isOpen={openModal}
                    updateItemInTherList={updateItemInTheList}
                    initialValues={{
                        plantQC: ticketOnEdit.plantQC,
                        roadwayQC: ticketOnEdit.roadwayQC,
                        plantVT: ticketOnEdit.plantVT,
                        roadwayVT: ticketOnEdit.roadwayVT,
                        notes: ticketOnEdit.notes,
                        status:
                            ticketOnEdit.status === AIR_TICKET_STATUS_ACCEPT
                                ? AIR_TICKET_STATUS_ACCEPT_LABEL
                                : ticketOnEdit.status === AIR_TICKET_STATUS_REJECT
                                ? AIR_TICKET_STATUS_REJECT_LABEL
                                : undefined,
                    }}
                    ticket={ticketOnEdit}
                    handleClose={handleVerifyClose}
                />
            )}
            {ticpicForTicket && !_.isEmpty(ticpicForTicket) && (
                <AppModal
                    isOpen={ticpicForTicket.length > 0}
                    className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                    closeModal={() => {
                        setTicpicForTicket(null);
                        setpictureTicketId(null);
                    }}
                    classes={sliderClasses}
                >
                    {isLoading && <Loader />}
                    <Box display="flex" flexDirection="row" position="relative">
                        {ticPicList && (
                            <div className={clsx(classes.ticPicContainer)}>
                                <TicPicSelectList ticPicList={ticPicList} handleTicPicSelect={handleTicPicSelect} />
                            </div>
                        )}
                        {ticpicForTicket[0].filename.includes(".pdf") ? (
                            <button
                                type="button"
                                onClick={() => openTicketsTicPicksPDF(ticpicForTicket[0].fileLink)}
                                style={{ display: "block" }}
                                title="Click to open PDF file"
                            >
                                <PictureAsPdfIcon
                                    style={{
                                        width: 100,
                                        height: 100,
                                        pointerEvents: "none",
                                    }}
                                />
                            </button>
                        ) : (
                            <EditableImage
                                image={ticpicForTicket[0]}
                                isCrop={true}
                                storeFile={storeCroppedImage}
                                imageStyles={imageStyles}
                                containerStyles={imageContainerStyles}
                                height={500}
                            />
                        )}
                    </Box>
                    <br />
                    <br />
                    <Box display="flex" justifyContent="center">
                        <PrimaryButton onClick={uploadCroppedImage}>Save Image</PrimaryButton>
                    </Box>
                </AppModal>
            )}
            {!!ticketForSplit && (
                <AppModal
                    isOpen={!!ticketForSplit}
                    form={SPLIT_JOB_FORM}
                    closeModal={() => setTicketForSplit(null)}
                    modalStyles={{ width: 550 }}
                    handleFormWrapperClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <SplitJobForm
                        handleClose={() => setTicketForSplit(false)}
                        jobOrderId={ticketForSplit.joLineItem.jobOrderId}
                    />
                </AppModal>
            )}
        </React.Fragment>
    );
}

TicketsList.propTypes = {
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    ticketsFilters: PropTypes.object,
    reloadList: PropTypes.bool.isRequired,
    showTitle: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    tickets: PropTypes.array.isRequired,
    loadUnitOfMeasures: PropTypes.func,
    ticketsCount: PropTypes.number.isRequired,
    needReloadTickets: PropTypes.func,
    needLoadNewTickets: PropTypes.func,
    handleTicketsRemove: PropTypes.func,
    ticketsListLoaded: PropTypes.func,
    isListView: PropTypes.bool,
    loadNewTickets: PropTypes.bool,
    form: PropTypes.string,
    onlyView: PropTypes.bool.isRequired,
};

TicketsList.defaultProps = {
    showTitle: false,
    reloadList: false,
};

export default withRouter(
    compose(
        connect(
            (state, props) => {
                const formValuesSelector = formValueSelector(props.form);

                return {
                    tickets: state.tickets.tickets,
                    ticketsCount: state.tickets.ticketsCount,
                    account: state.account,
                    formValues: formValuesSelector(state, START_DATE_NAME, END_DATE_NAME),
                };
            },
            (dispatch) => ({
                ticketsListLoaded: (payload) => {
                    dispatch(ticketsListLoaded(payload));
                },
                loadUnitOfMeasures: () => dispatch(loadUnitOfMeasures()),
            }),
        ),
    )(TicketsList),
);
