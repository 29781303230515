import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { ActionLink, SimpleLink } from "../core/link";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchAndFilters, { FILTERS_PARENT_COMPANY_ID } from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import CompanyForm, { COMPANY_FORM } from "./companyForm";
import AppModal from "../core/modal";
import {
    createCompany,
    updateCompany,
    updateCompanyProfile,
    updateCompanyStripeSubscription,
    uploadCompanyCertificate,
} from "../../dataServers/companies";
import { preparePhone } from "../../helpers/global";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import ErrorNotification from "../core/notification";
import { reduxForm } from "redux-form";
import { IS_ADMIN_USER } from "../../constants/maps";
import { getAllParentCompanies } from "../../actions/companies";
import { selectCompanyId } from "../../selectors";
import { useDispatch, useSelector } from "react-redux";
import { getIsStripeAccount } from "../../constants/accounts";

const useStyles = makeStyles(() => ({
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const useFiltersStyles = makeStyles(() => ({
    companyFiltersSection: {
        minWidth: "250px",
    },
}));

const CompanyFilters = (props) => {
    const classes = useStyles();
    const filtersClasses = useFiltersStyles();
    const dispatch = useDispatch();
    const companyId = useSelector(selectCompanyId);
    const pageActionsStyles = PAGE_ACTIONS_STYLES();
    const wrapper = PAGE_GLOBAL_STYLES();

    const [error, setError] = useState();
    const [filtersList, setFiltersList] = useState([]);
    const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);

    const { form, updateListByFilters, mapCompanyData, getCompanies, appliedFilters } = props;
    const handleCompanyCreate = (values) => {
        const {
            name,
            address,
            companyType,
            accountType,
            purchaseOrderType,
            timezone,
            certificateFile,
            certifiedPayroll,
            ignoreJobsLimit,
            WBE,
            MBE,
            DBE,
            paymentName,
            paymentEmail,
            paymentPhone,
            truckShiftAllowed,
            stripeId,
            allowedPeriodAfterPaymentFailed,
            streetAddress,
            secondaryAddress,
            state,
            city,
            zipCode,
        } = values;
        const createData = {
            name,
            type: companyType.value || companyType,
            accountType: accountType.value || accountType,
            poType: purchaseOrderType.value || purchaseOrderType,
        };

        return createCompany(createData)
            .then(async (data) => {
                const id = data.data.id;
                const updateData = {
                    name: data.data.name,
                    type: data.data.type,
                    accountType: data.data.accountType,
                    dbe: DBE,
                    wbe: WBE,
                    mbe: MBE,
                    ignoreJobsLimit: ignoreJobsLimit,
                    certifiedPayroll: certifiedPayroll,
                };

                if (timezone) {
                    updateData.timezone = { id: timezone.value || timezone };
                }

                const updateProfileData = {
                    paymentName: paymentName,
                    paymentEmail: paymentEmail,
                    paymentPhone: preparePhone(paymentPhone),
                    address: address ? address.label : null,
                    truckShiftAllowed: truckShiftAllowed || undefined,
                    streetAddress: streetAddress || null,
                    secondaryAddress: secondaryAddress || null,
                    state: state ? state.label : null,
                    city: city || null,
                    zipCode: zipCode || null,
                };

                const isStripeAccount = getIsStripeAccount(data.data.accountType);
                if (isStripeAccount) {
                    updateData.stripeId = stripeId;
                }
                try {
                    await updateCompany(id, updateData);
                    await updateCompanyProfile(id, updateProfileData);

                    if (isStripeAccount) {
                        const updateStripeSubscriptionData = {
                            allowedPeriodAfterPaymentFailed,
                        };
                        await updateCompanyStripeSubscription(id, updateStripeSubscriptionData);
                    }
                    if (certificateFile && certificateFile.length !== 0) {
                        return uploadCompanyCertificate(id, certificateFile[0]);
                    }
                } catch (error) {
                    setError([PROCESS_SERVER_ERROR(error)]);
                } finally {
                    getCompanies(false, appliedFilters);
                    setShowCreateCompanyModal(false);
                }
            })
            .catch((error) => {
                setError([PROCESS_SERVER_ERROR(error)]);
            });
    };

    useEffect(() => {
        if (IS_ADMIN_USER(companyId)) {
            dispatch(getAllParentCompanies((e) => setError([PROCESS_SERVER_ERROR(e)]))).then((companies) => {
                if (!_.isEmpty(companies)) {
                    setFiltersList([
                        {
                            title: "Sub Companies",
                            filterKey: FILTERS_PARENT_COMPANY_ID,
                            filters: companies.map((company) => ({ label: company.name, id: company.id })),
                        },
                    ]);
                }
            });
        }
    }, []);

    return (
        <Container className={wrapper.globalPageStyles}>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            <div className={pageActionsStyles.pageActions}>
                <Box className={classes.headerBox}>
                    <ActionLink>
                        <AddCircleOutlineIcon />
                        <SimpleLink onClick={() => setShowCreateCompanyModal(true)}>Create new company</SimpleLink>
                    </ActionLink>
                    <SearchAndFilters
                        filtersList={filtersList}
                        form={form}
                        updateList={updateListByFilters}
                        filtersSectionClassName={filtersClasses.companyFiltersSection}
                    />
                </Box>
            </div>
            <AppModal
                isOpen={showCreateCompanyModal}
                form={COMPANY_FORM}
                closeModal={() => setShowCreateCompanyModal(false)}
            >
                <h2 className="title">Create company</h2>
                <br />
                <CompanyForm
                    closeModal={() => setShowCreateCompanyModal(false)}
                    handleSave={handleCompanyCreate}
                    initialValues={mapCompanyData()}
                    isCreateForm={true}
                />
            </AppModal>
        </Container>
    );
};

CompanyFilters.propTypes = {
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    mapCompanyData: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object,
};

const COMPANY_FILTERS_FORM = "companyFilter";

export default withRouter(
    reduxForm({
        form: COMPANY_FILTERS_FORM,
        initialValues: { search: null },
    })(CompanyFilters),
);
