import dataProvider from "../helpers/api";
import { GLOBAL_ENDPOINTS } from "../constants/endpoints";

export const downloadFile = (fileId) => {
    dataProvider({
        url: GLOBAL_ENDPOINTS.FILES(fileId),
        method: "GET",
    }).then(({ data: { link } }) => {
        const a = document.createElement("a");
        a.href = link;
        a.download = link;
        document.body.appendChild(a);
        a.click();
    });
};
