import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { DROP_OF_SITE_NAME } from "../jobs/jobComponents/pickUp&DropOff";
import { Validation } from "../../helpers/validation";
import { IS_ADMIN_USER } from "../../constants/maps";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { createNewPayload } from "../../dataServers/projects";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import clsx from "clsx";
import Loader from "../core/loader";
import { updatePayloadRequest } from "../../dataServers/payloads";
import * as CompaniesDataServer from "../../dataServers/companies";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

const PayloadOptionForm = (props) => {
    const {
        closeModal,
        handleSubmit,
        handlePayloadSaveSuccess,
        account,
        payloadToUpdateId,
        errorHandler,
        handleCreatePayload,
        fetchPayloads,
        handleUpdatePayload,
    } = props;
    const [isLoading, setLoading] = useState(false);

    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return CompaniesDataServer.getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;
            const options = data.data.map((hauler) => ({ label: hauler.name, value: hauler.id }));

            return {
                options: options,
                hasMore: data.meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        });
    };

    const onSubmit = (values) => {
        setLoading(true);
        const data = { name: values.name };

        if (values.company) {
            data.companyId = values.company.value;
        }

        const request = payloadToUpdateId ? updatePayloadRequest(payloadToUpdateId, data) : createNewPayload(data);

        request
            .then(({ data }) => {
                setLoading(false);
                closeModal();
                const successMessage = payloadToUpdateId
                    ? "Payload was successfully updated"
                    : "New payload was successfully created";
                if (payloadToUpdateId) {
                    handleUpdatePayload(data);
                } else {
                    fetchPayloads && fetchPayloads();
                    handleCreatePayload && handleCreatePayload(data);
                }
                handlePayloadSaveSuccess && handlePayloadSaveSuccess(successMessage);
            })
            .catch((error) => {
                const processedError = PROCESS_SERVER_ERROR(error);
                setLoading(false);
                errorHandler && errorHandler(processedError);
            });
    };

    return (
        <form noValidate={true} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <h2 className="title">{payloadToUpdateId ? "Update payload" : "Add new payload option"}</h2>
            <br />
            <Field
                type="text"
                name="name"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={[Validation.required]}
                placeholder={"Name"}
                component={TextInputComponent}
            />
            <br />
            <br />
            {IS_ADMIN_USER(account.company.id) && (
                <React.Fragment>
                    <Field
                        name="company"
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        validate={[Validation.required]}
                        loadOptions={loadCompanies}
                        placeholder="Company"
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <br />
                </React.Fragment>
            )}
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)}>Save</PrimaryButton>
            </ButtonsGroup>
        </form>
    );
};

PayloadOptionForm.propTypes = {
    account: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handlePayloadSaveSuccess: PropTypes.func,
    handleCreatePayload: PropTypes.func,
    errorHandler: PropTypes.func,
    fetchPayloads: PropTypes.func,
    handleUpdatePayload: PropTypes.func,
    payloadToUpdateId: PropTypes.number,
};

export const NEW_PAYLOAD_OPTION_FORM = "newPayloadOptionForm";

export default withRouter(
    compose(
        reduxForm({
            form: NEW_PAYLOAD_OPTION_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                currentProject: state.projects.currentProject,
                account: state.account,
                formValues: formSelector(state, "unitOfMeasure", "unlimited", DROP_OF_SITE_NAME),
            };
        }),
    )(PayloadOptionForm),
);
