/* global google */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { compose } from "redux";
import { Circle, GoogleMap, Polygon, Polyline, withGoogleMap, withScriptjs } from "react-google-maps";
import {
    ATLANTA_COORDINATES,
    GOOGLE_MAPS_SITE_ICON_SIZE,
    GOOGLE_MAPS_TRUCK_ICON_SIZE,
    GOOGLE_MAPS_URL,
} from "../../../constants/global";
import DropOffMapData from "../../global/maps/dropOffMapData";
import PickUpMapData from "../../global/maps/pickUpMapData";
import CustomMarker from "../../global/maps/customMarker";
import { MAIN_THEME } from "../../../styles/globalThemeConfig";
import { SITE_CIRCLE_OPACITY, SITE_CIRCLE_WIDTH } from "../../../constants/sites";
import redPushPin from "../../../styles/images/pushpin-red.svg";
import greenPushPin from "../../../styles/images/pushpin-green.svg";
import { generateColors } from "../../jobs/jobComponents/jobOrderMap";
import { getTrucksForMap } from "../../../helpers/global";
import { getIfIsDropOffMidpoint, getIfIsPickUpMidpoint } from "../../../helpers/tickets";
import { TRUCK_STATUS_AT_PICK_UP } from "../../../constants/maps";

export const BaseMapWithDirections = compose(
    withScriptjs,
    withGoogleMap,
)((props) => {
    const {
        mapInfo: {
            dropOffSites,
            pickUpSite,
            ticketRoute,
            closeLocation,
            openLocation,
            zones,
            loadsLocations,
            truckData,
        },
        ticket,
    } = props;

    const isDropOffMidpoint = getIfIsDropOffMidpoint(ticket);
    const isPickUpMidpoint = getIfIsPickUpMidpoint(ticket);

    const truckLocation = truckData?.truckLocation;
    const truckStatus = truckData?.truckStatus;
    const bounds = new google.maps.LatLngBounds();
    const [colors, setColors] = useState([]);
    const path = ticketRoute ? ticketRoute.map((i) => ({ lat: i.latitude, lng: i.longitude })) : [];
    const openLocationPosition = openLocation && { lat: openLocation.latitude, lng: openLocation.longitude };
    const closeLocationPosition = closeLocation && { lat: closeLocation.latitude, lng: closeLocation.longitude };
    const pickUpLocationPosition = pickUpSite && { lat: pickUpSite.latitude, lng: pickUpSite.longitude };
    const coordinates = [];
    const [state, setState] = useState({ notFadeMarkers: [] });
    const { notFadeMarkers } = state;

    if (!_.isEmpty(dropOffSites)) {
        dropOffSites.forEach((item) => {
            coordinates.push(item);
        });
    }

    if (!_.isEmpty(pickUpSite)) {
        coordinates.push(pickUpLocationPosition);
    }

    if (!_.isEmpty(ticketRoute)) {
        coordinates.push(...path);
    }

    if (!_.isEmpty(closeLocation)) {
        coordinates.push(closeLocationPosition);
    }

    if (!_.isEmpty(openLocation)) {
        coordinates.push(openLocationPosition);
    }

    if (!_.isEmpty(truckLocation)) {
        coordinates.push(truckLocation);
    }

    if (!_.isEmpty(loadsLocations)) {
        loadsLocations.forEach((location) => {
            if (location.pickUpLocation) {
                coordinates.push({ lat: location.pickUpLocation.latitude, lng: location.pickUpLocation.longitude });
            }

            if (location.dropOffLocation) {
                coordinates.push({ lat: location.dropOffLocation.latitude, lng: location.dropOffLocation.longitude });
            }
        });
    }

    coordinates.forEach((coords) => {
        bounds.extend(new google.maps.LatLng(coords.latitude || coords.lat, coords.longitude || coords.lng));
    });

    const fadeOthers = (ids) => {
        setState({ ...state, notFadeMarkers: ids });
    };
    const opacity = (id) => {
        return !_.isEmpty(notFadeMarkers) && !notFadeMarkers.includes(id) ? 0.1 : 1;
    };

    useEffect(() => {
        if (!_.isEmpty(loadsLocations)) {
            setColors(generateColors(loadsLocations));
        }
    }, [loadsLocations]);

    return (
        <GoogleMap
            ref={(map) => map && map.fitBounds(bounds)}
            defaultCenter={
                pickUpLocationPosition ||
                openLocationPosition ||
                closeLocationPosition ||
                (dropOffSites && dropOffSites[0]) ||
                ATLANTA_COORDINATES
            }
            defaultZoom={11}
        >
            {!_.isEmpty(dropOffSites) && (
                <DropOffMapData dropOffSites={dropOffSites} isDropOffMidpoint={isDropOffMidpoint} />
            )}
            {!_.isEmpty(pickUpSite) && <PickUpMapData pickUpSite={pickUpSite} isPickUpMidpoint={isPickUpMidpoint} />}
            {!_.isEmpty(path) && (
                <Polyline
                    path={path}
                    options={{
                        strokeColor: "#73B9FF",
                        strokeOpacity: 0.7,
                        strokeWeight: 5,
                    }}
                />
            )}
            {truckLocation && (
                <CustomMarker
                    position={truckLocation}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_TRUCK_ICON_SIZE, GOOGLE_MAPS_TRUCK_ICON_SIZE),
                        url: getTrucksForMap(truckStatus || TRUCK_STATUS_AT_PICK_UP, 0),
                        zIndex: 3,
                    }}
                />
            )}
            {closeLocation && (
                <CustomMarker
                    position={closeLocationPosition}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        url: redPushPin,
                        zIndex: 3,
                    }}
                    opacity={1}
                />
            )}
            {openLocation && (
                <CustomMarker
                    position={openLocationPosition}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_SITE_ICON_SIZE, GOOGLE_MAPS_SITE_ICON_SIZE),
                        url: greenPushPin,
                        zIndex: 3,
                    }}
                    opacity={1}
                />
            )}
            {zones &&
                zones.map((zone, index) => {
                    const { polygon, radius, latitude, longitude } = zone;
                    const options = {
                        strokeColor: MAIN_THEME.palette.general.paleRed,
                        strokeWeight: SITE_CIRCLE_WIDTH,
                        fillColor: MAIN_THEME.palette.general.paleRed,
                        fillOpacity: SITE_CIRCLE_OPACITY,
                    };
                    const center = { lat: latitude, lng: longitude };

                    return (
                        <React.Fragment key={index}>
                            {polygon && (
                                <Polygon
                                    path={polygon.map(([lat, lng]) => ({ lat, lng }))}
                                    key={index}
                                    defaultCenter={center}
                                    options={options}
                                />
                            )}
                            {radius && <Circle defaultCenter={center} key={index} radius={radius} options={options} />}
                        </React.Fragment>
                    );
                })}
            {loadsLocations &&
                loadsLocations.map((load, index) => {
                    const { pickUpLocation, dropOffLocation, id } = load;
                    const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="80" width="80">
                    <circle cx="40" cy="40" r="40" fill="${colors[index]}"/>
                </svg>`;
                    const url = "data:image/svg+xml;charset=UTF-8;base64," + btoa(svg);

                    const icon = {
                        scaledSize: new google.maps.Size(15, 15),
                        url: url,
                        zIndex: 3,
                    };

                    return (
                        <React.Fragment>
                            {pickUpLocation && (
                                <CustomMarker
                                    position={{ lat: pickUpLocation.latitude, lng: pickUpLocation.longitude }}
                                    opacity={opacity(id)}
                                    fadeOthers={fadeOthers}
                                    id={id}
                                    icon={icon}
                                />
                            )}
                            {dropOffLocation && (
                                <CustomMarker
                                    position={{ lat: dropOffLocation.latitude, lng: dropOffLocation.longitude }}
                                    opacity={opacity(id)}
                                    fadeOthers={fadeOthers}
                                    id={id}
                                    icon={icon}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
        </GoogleMap>
    );
});

const TicketMap = (props) => (
    <BaseMapWithDirections
        googleMapURL={GOOGLE_MAPS_URL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: props.height }} />}
        mapElement={<div style={{ height: `100%` }} />}
        mapInfo={props.ticketMapInfo}
        ticket={props.ticket}
    />
);

TicketMap.propTypes = {
    height: PropTypes.string.isRequired,
    mapInfo: PropTypes.object.isRequired,
};

export default TicketMap;
