import {
    GET_TEAM_USERS_SUCCESS,
    IS_LOADING_USERS,
    RESET_TEAMS_USERS,
    SET_USER_FOR_EDIT,
    TEAM_DIALOG_TOGGLE,
    UPDATE_ACTIVE_TEAM_USERS_ORDER,
    REQUEST_USER_EVENTS,
    RESULT_USER_EVENTS,
    RESET_USER_EVENTS,
    USER_EVENTS_ERROR,
    USER_EVENTS_SUSPEND_PERIOD,
    USER_EVENTS_RESET_META,
} from "../events/team";

import { getMyTeam } from "../dataServers/trucks";
import { getUserActivities } from "../dataServers/user";
import { PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../constants/global";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import { selectAccountTimezone } from "../selectors/index";
import moment from "moment";
import _ from "lodash";

export const handleTeamDialogToggle = (open) => {
    return {
        type: TEAM_DIALOG_TOGGLE,
        open,
    };
};

export const setUserForEdit = (payload) => {
    return {
        type: SET_USER_FOR_EDIT,
        payload,
    };
};

export const resetUsers = () => {
    return {
        type: RESET_TEAMS_USERS,
    };
};

export const loadTeamUsers =
    (params, concat = false) =>
    (dispatch, getState) => {
        const state = getState();
        const currentPage = Math.ceil(state.teams.teamUsers.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.teams.teamUsers) ? +currentPage + 1 : 1;
        const parameters = {
            ...params,
            page: concat ? nextPage : 1,
        };

        dispatch({
            type: IS_LOADING_USERS,
            payload: true,
        });

        return getMyTeam(parameters)
            .then((usersData) => {
                if (usersData) {
                    const payload = {
                        teamUsers: concat ? [...state.teams.teamUsers, ...usersData.data] : usersData.data,
                        usersCount: usersData.meta.count,
                    };

                    dispatch({
                        type: GET_TEAM_USERS_SUCCESS,
                        payload,
                    });
                }
                dispatch({
                    type: IS_LOADING_USERS,
                    payload: false,
                });
            })
            .catch(() => {
                dispatch({
                    type: IS_LOADING_USERS,
                    payload: false,
                });
            });
    };

export const setLoadingUsers = (payload) => {
    return {
        type: IS_LOADING_USERS,
        payload,
    };
};

export const updateActiveTeamUsersOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_TEAM_USERS_ORDER,
        payload: order,
    };
};
const getJobTitle = (e, timezone) =>
    `Job # ${e.jobOrderId} - ${e.projectName} ${moment(e.startDate).tz(timezone).format("hh:mm A")}`;

const requestUserEvents = () => ({ type: REQUEST_USER_EVENTS });
const resultUserEvents = (payload) => ({ type: RESULT_USER_EVENTS, payload });
const requestEventsError = (payload) => ({ type: USER_EVENTS_ERROR, payload });
const setActiveSuspendPeriod = (payload) => ({ type: USER_EVENTS_SUSPEND_PERIOD, payload });
const userEventsResetMeta = () => ({ type: USER_EVENTS_RESET_META });

export const requestUserActivities = (userId, params) => async (dispatch, getState) => {
    dispatch(requestUserEvents());
    try {
        const state = getState();
        const timezone = selectAccountTimezone(state);
        const dates = params
            ? {
                  startDate: moment(params.start).format(SERVER_DATE_FORMAT),
                  endDate: moment(params.end).format(SERVER_DATE_FORMAT),
              }
            : {
                  startDate: moment().startOf("month").subtract(6, "days").format(SERVER_DATE_FORMAT),
                  endDate: moment().endOf("month").add(6, "days").format(SERVER_DATE_FORMAT),
              };

        const {
            data: { scheduledJobs, activeSuspendPeriod },
        } = await getUserActivities(userId, dates);
        const events =
            scheduledJobs &&
            scheduledJobs.map((event) => {
                if (event.isScheduledJob) {
                    event.border = `1px solid #b96211`;
                    event.background = "#fbf7f3";
                }

                return {
                    start: new Date(moment(event.startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")),
                    end: new Date(moment(event.startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")),
                    label: getJobTitle(event, timezone),
                    timezone,
                    ...event,
                };
            });

        await dispatch(resultUserEvents(events));

        const period =
            activeSuspendPeriod &&
            activeSuspendPeriod.map((period) => {
                return {
                    start: period.startDate ? new Date(period.startDate) : null,
                    end: period.endDate ? new Date(period.endDate) : null,
                    label: period.reason,
                    timezone,
                    isSuspendPeriod: true,
                    ...period,
                };
            });

        await dispatch(setActiveSuspendPeriod(period));
    } catch (error) {
        dispatch(requestEventsError(PROCESS_SERVER_ERROR(error)));
        dispatch(userEventsResetMeta());
    }
};

export const resetUserEvents = () => ({ type: RESET_USER_EVENTS });
