import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import AirTicketsWrapper from "../components/tickets/airTickets/airTicketsWrapper";
import { PAGE_GLOBAL_STYLES } from "../styles/reusableStyles";
import { connect } from "react-redux";
import { Divider } from "../components/core/divider";
import { END_DATE_NAME, START_DATE_NAME } from "../components/global/datesRange";
import { formValueSelector } from "redux-form";
import TicketsList from "../components/tickets/ticketsList";
import ReportFilters from "../components/reportAndAnalytic/reportFilters";
import ReportSubNav from "../components/reportAndAnalytic/reportSubNav";
import { IS_INSPECTOR_USER } from "../constants/maps";
import { GET_USER_INITIAL_FILTERS } from "../components/global/searchAndFilters";

const ReportAndAnalyticks = (props) => {
    const {
        formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate, search },
        account,
    } = props;
    const [ticketsFilters, setFilters] = useState();
    const classes = PAGE_GLOBAL_STYLES();
    const [isListView, setListView] = useState(false);

    const updateListByFilters = (filters, clearFilters) => {
        if (!clearFilters) {
            setFilters({
                keywords: search,
                ...filters,
            });
        } else {
            setFilters({
                ...filters,
                [START_DATE_NAME]: startDate,
                [END_DATE_NAME]: endDate,
            });
        }
    };

    useEffect(() => {
        IS_INSPECTOR_USER(account.role) &&
            updateListByFilters({
                [START_DATE_NAME]: startDate,
                [END_DATE_NAME]: endDate,
            });
    }, []);

    const form = AIR_TICKETS_FILTERS_FORM;

    return (
        <div>
            <ReportSubNav isListView={isListView} setListView={setListView} />
            <Container className={classes.globalPageStyles}>
                <ReportFilters
                    updateListByFilters={updateListByFilters}
                    setFilters={setFilters}
                    initialValues={GET_USER_INITIAL_FILTERS(form)}
                    ticketsFilters={ticketsFilters}
                    form={form}
                />
                <AirTicketsWrapper ticketsFilters={ticketsFilters} form={form} />
                <Divider />
            </Container>
            <TicketsList showTitle={true} isListView={isListView} form={form} ticketsFilters={ticketsFilters} />
            <br />
            <br />
        </div>
    );
};

ReportAndAnalyticks.propTypes = {
    history: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
};

export const AIR_TICKETS_FILTERS_FORM = "reportAndAnalyticksFilters";

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(AIR_TICKETS_FILTERS_FORM);

        return {
            account: state.account,
            formValues: formSelector(state, START_DATE_NAME, END_DATE_NAME, "search"),
        };
    })(ReportAndAnalyticks),
);
