import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Validation } from "../../helpers/validation";
import TextInputComponent from "../core/form/textInputComponent";
import { Field } from "redux-form";

const EmailField = (props) => {
    const { isRequired, name, className, fieldNote, disabled } = props;
    let validation = [Validation.email];

    if (isRequired) {
        validation = [Validation.required, ...validation];
    }

    return (
        <Field
            name={name}
            validate={validation}
            className={className}
            type="email"
            label="Email"
            fieldNote={fieldNote}
            component={TextInputComponent}
            disabled={disabled}
        />
    );
};

EmailField.propTypes = {
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    fieldNote: PropTypes.string,
    name: PropTypes.string.isRequired,
};

EmailField.defaultProps = {
    name: "email",
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(EmailField),
);
