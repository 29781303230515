import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { withRouter } from "react-router-dom";

import AsyncAutocompleteComponent from "./asyncAutocompleteComponent";
import { getPurchaseOrders } from "../../../dataServers/purchaseOrders";
import { getPurchaseOrderLabel } from "../../../helpers/global";

const PurchaseOrderSelector = (props) => {
    const { disabled, projectId, startAdornment, className, validate } = props;

    const loadOptions = (inputValue, { params = {}, loadedCount }) => {
        return getPurchaseOrders({ project: projectId, keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: getPurchaseOrderLabel(i) }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <Field
            name="purchaseOrder"
            disabled={disabled}
            loadOptions={loadOptions}
            className={className}
            startAdornment={startAdornment}
            placeholder="Select Purchase Order"
            validate={validate}
            component={AsyncAutocompleteComponent}
        />
    );
};

PurchaseOrderSelector.propTypes = {
    disabled: PropTypes.bool,
    validate: PropTypes.array,
    projectId: PropTypes.number.isRequired,
    startAdornment: PropTypes.any,
    className: PropTypes.string,
};

export default withRouter(PurchaseOrderSelector);
