import React, { useState } from "react";
import PropTypes from "prop-types";
import { change, Field, formValueSelector, getFormInitialValues, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { compose as reduxCompose } from "redux";
import { getCompanyTimezones, loadStates } from "../../actions/globalActions.js";
import Grid from "@material-ui/core/Grid";
import TextInputComponent from "../core/form/textInputComponent";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { PrimaryButton } from "../core/buttons/primaryButton";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import AutocompleteAddress from "../global/autocompleteAddress";
import CustomSwitch from "../core/form/customSwitch";
import { TABLE_SECONDARY } from "../../styles/reusableStyles";
import {
    COMPANY_TYPES_FORM_OPTIONS,
    IS_ADMIN_USER,
    IS_HAULER_USER,
    IS_LIMITED_COMPANY,
    PO_TYPES,
} from "../../constants/maps";
import clsx from "clsx";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { Validation } from "../../helpers/validation";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import PhoneField from "../global/phoneField";
import EmailField from "../global/emailField";
import { ACCOUNT_TYPES, getIsStripeAccount } from "../../constants/accounts";
import { STRIPE_BASIC, STRIPE_PRO } from "../../constants/accounts.js";
import StripeDataFields from "./StripeDataFields";
import _ from "lodash";
import {
    NON_STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE,
    STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE,
} from "../../constants/strings";

const useStyles = makeStyles((theme) => ({
    companyForm: {
        width: 600,
        "& .item-align-left": {
            textAlign: "start",
        },
        "& .validate-table": {
            width: 400,
        },
        "& .form-section-label": {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.secondary.dark,
            fontSize: 18,
        },
    },
}));

const CompanyAddressForm = (props) => {
    const { accountType, form, dispatch, isCreateForm, address } = props;
    const isStripeAccount = () => {
        const accountTypeValue = accountType && +accountType.value;

        return accountTypeValue === STRIPE_BASIC || accountTypeValue === STRIPE_PRO;
    };

    const setStateValue = (value) => {
        dispatch(change(form, "state", { label: value.abbreviation, value: value.value }));
    };

    return isStripeAccount() ? (
        <>
            {!isCreateForm && address && (
                <>
                    <Field
                        name="address"
                        validate={[Validation.required]}
                        type="text"
                        disabled={true}
                        label="Address"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                </>
            )}
            <Field
                type="text"
                name="streetAddress"
                label="Street Address"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                type="text"
                name="secondaryAddress"
                label="Secondary Address"
                validate={[Validation.noSpecialSymbols]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                type="text"
                name="city"
                label="City"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                name="state"
                placeholder="State"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                onFieldChange={setStateValue}
                loadOptions={loadStates}
                component={AsyncAutocompleteComponent}
            />
            <br />
            <Field
                type="text"
                name="zipCode"
                label="Zip Code"
                validate={[Validation.required, Validation.noSpecialSymbols]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
        </>
    ) : (
        <AutocompleteAddress />
    );
};

const CompanyAccountTypeFieldNote = ({ stripeAccountTypeChangeWarning, nonStripeAccountTypeChangeWarning }) => {
    if (stripeAccountTypeChangeWarning) {
        return STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE;
    }
    if (nonStripeAccountTypeChangeWarning) {
        return NON_STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE;
    }

    return null;
};

const CompanyForm = (props) => {
    const {
        handleSubmit,
        error,
        showValidateCompanyTable,
        showCardInfo,
        closeModal,
        handleSave,
        company,
        account,
        form,
        dispatch,
        isCreateForm,
        formValues: { accountType, address, companyType },
        initialValues: { accountType: initialAccountType },
    } = props;
    const isStripeAccount = getIsStripeAccount(+accountType?.value);
    const isInitiallyStripeAccount = initialAccountType?.value && getIsStripeAccount(+initialAccountType?.value);
    const classes = useStyles();
    const companyId = useSelector((state) => state.account.company.id);
    const [isLoading, setIsLoading] = useState(false);
    const tableStyles = TABLE_SECONDARY();
    const isEditForm = !isCreateForm;
    const disableCompanyTypeSelector = IS_LIMITED_COMPANY(companyType?.value);

    const stripeAccountTypeChangeWarning = isEditForm && isStripeAccount && !isInitiallyStripeAccount;
    const nonStripeAccountTypeChangeWarning = isEditForm && isInitiallyStripeAccount && !isStripeAccount;

    const onSubmit = (values) => {
        setIsLoading(true);

        return handleSave(values)
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const handleAccountTypeChange = (e) => {
        const isStripeAccountSelected = getIsStripeAccount(+e.value);
        if (address && _.isObject(address) && isStripeAccountSelected) {
            dispatch(change(form, "address", address.label));
        }
        if (address && _.isString(address) && !isStripeAccountSelected) {
            dispatch(change(form, "address", { value: address, label: address }));
        }
    };

    const loadStaticOptions = (map) => {
        const results = Object.entries(map).map((i) => ({
            value: i[0],
            label: i[1],
        }));

        return Promise.resolve(results).then(() => {
            return {
                options: results,
                hasMore: false,
                page: 1,
            };
        });
    };

    return (
        <div className={clsx(classes.companyForm, isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <Field
                    type="text"
                    name="name"
                    label="Company Name"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <Field
                    name="accountType"
                    placeholder="Account Type"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    loadOptions={() => loadStaticOptions(ACCOUNT_TYPES)}
                    component={AsyncAutocompleteComponent}
                    onChange={handleAccountTypeChange}
                    fieldNote={
                        <CompanyAccountTypeFieldNote
                            stripeAccountTypeChangeWarning={stripeAccountTypeChangeWarning}
                            nonStripeAccountTypeChangeWarning={nonStripeAccountTypeChangeWarning}
                        />
                    }
                />
                <br />
                <CompanyAddressForm
                    form={form}
                    dispatch={dispatch}
                    accountType={accountType}
                    address={address}
                    isCreateForm={isCreateForm}
                />
                <br />
                <Field
                    name="companyType"
                    placeholder="Company Type"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    loadOptions={() => loadStaticOptions(COMPANY_TYPES_FORM_OPTIONS)}
                    component={AsyncAutocompleteComponent}
                    disabled={disableCompanyTypeSelector}
                />
                <br />
                <Field
                    name="purchaseOrderType"
                    placeholder="Purchase Order Type"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    loadOptions={() => loadStaticOptions(PO_TYPES)}
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <Field
                    name="timezone"
                    placeholder="Time Zone"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    loadOptions={getCompanyTimezones}
                    component={AsyncAutocompleteComponent}
                />
                <br />
                <Grid container justify={"flex-start"} alignItems="center" className={clsx(classes.projectSwitches)}>
                    <Grid item xs={4} className="item-align-left">
                        <Field
                            name="certifiedPayroll"
                            type="checkbox"
                            label="Certified Payroll"
                            component={CustomSwitch}
                        />
                    </Grid>
                    <Grid item xs={4} className="item-align-left">
                        <Field
                            name="ignoreJobsLimit"
                            type="checkbox"
                            label="Ignore Jobs Limit"
                            component={CustomSwitch}
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid container justify={"flex-start"} alignItems="center" className={clsx(classes.projectSwitches)}>
                    <Grid item xs={2} className="item-align-left">
                        <Field name="WBE" type="checkbox" label="WBE" component={CustomSwitch} />
                    </Grid>
                    <Grid item xs={2} className="item-align-left">
                        <Field name="MBE" type="checkbox" label="MBE" component={CustomSwitch} />
                    </Grid>
                    <Grid item xs={2} className="item-align-left">
                        <Field name="DBE" type="checkbox" label="DBE" component={CustomSwitch} />
                    </Grid>
                </Grid>
                <br />
                <Grid container justify={"flex-start"} alignItems="center" className={clsx(classes.projectSwitches)}>
                    <Grid item xs={4} className="item-align-left">
                        <Field
                            name="truckShiftAllowed"
                            type="checkbox"
                            disabled={!(IS_ADMIN_USER(account.company.id) || IS_HAULER_USER(account.role))}
                            label="Allow shift change"
                            component={CustomSwitch}
                        />
                    </Grid>
                    {IS_ADMIN_USER(companyId) ? (
                        <Grid item xs={4} className="item-align-left">
                            <Field
                                name="usesIotDevices"
                                type="checkbox"
                                label="Uses IoT Devices"
                                component={CustomSwitch}
                            />
                        </Grid>
                    ) : null}
                </Grid>
                <br />
                <br />
                {isStripeAccount && <StripeDataFields />}
                <label className="form-section-label">Contact for Payment</label>
                <br />
                <br />
                <Field
                    type="text"
                    name="paymentName"
                    validate={[Validation.noSpecialSymbols]}
                    label="Name"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <EmailField name="paymentEmail" className={AUTOCOMPLETE_FORM_VIEW_CLASS} />
                <br />
                <br />
                <PhoneField name="paymentPhone" className={AUTOCOMPLETE_FORM_VIEW_CLASS} />
                <br />
                <br />
                {showCardInfo && (
                    <React.Fragment>
                        <label className="form-section-label">Card info</label>
                        {company.companyProfile.card ? (
                            <div />
                        ) : (
                            <React.Fragment>
                                <br />
                                <br />
                                <div>No card info</div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                {showValidateCompanyTable && (
                    <React.Fragment>
                        <table className={clsx(tableStyles.secondaryTable, "validate-table")}>
                            <thead>
                                <tr>
                                    <th className="medium">User</th>
                                    <th className="medium">Date</th>
                                    <th className="medium">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{company.validatedUser}</td>
                                    <td>{company.validatedDate}</td>
                                    <td>{company.remarks}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </React.Fragment>
                )}
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {error && <ErrorNotification error={error} />}
        </div>
    );
};

CompanyForm.propTypes = {
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    company: PropTypes.object,
    error: PropTypes.string,
    showValidateCompanyTable: PropTypes.bool,
    showCardInfo: PropTypes.bool,
    form: PropTypes.string,
    dispatch: PropTypes.func,
    isCreateForm: PropTypes.bool,
};

CompanyForm.defaultProps = {
    showValidateCompanyTable: false,
    showCardInfo: false,
    formValues: {},
    isCreateForm: false,
};

export const COMPANY_FORM = "companyForm";
const formSelector = formValueSelector(COMPANY_FORM);
const formInitialSelector = getFormInitialValues(COMPANY_FORM);

export default withRouter(
    reduxCompose(
        reduxForm({
            form: COMPANY_FORM,
            initialValues: {},
        }),
        connect((state) => ({
            account: state.account,
            formValues: formSelector(state, "role", "username", "accountType", "address", "companyType"),
            initialValues: formInitialSelector(state),
        })),
    )(CompanyForm),
);
