import React from "react";
import PhoneInput from "react-phone-input-2";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

import FieldNote from "../core/form/fieldNote";

const USA_FLAG_POSITION = "-168px -336px";
const useStyles = makeStyles((theme) => ({
    phoneFieldContainer: {
        position: "relative",
    },
    phoneField: {
        "& .react-tel-input": {
            padding: "14px 18px",
            fontSize: 16,
        },
        "& .form-control": {
            width: "100%",
            border: `2px solid ${theme.palette.secondary.main}`,
        },
        "& .special-label": {
            color: theme.palette.secondary.superDark,
            fontSize: 12,
            fontWeight: 500,
        },
        "& .form-control:hover": {
            border: `2px solid ${theme.palette.secondary.dark}`,
            boxShadow: "none",
        },
        "& .form-control:focus": {
            border: `2px solid ${theme.palette.general.green}`,
            boxShadow: "none",
        },
        "& .error": {},
    },
    error: {
        "& .form-control": {
            borderColor: theme.palette.general.darkishRed,
        },
    },
    errorNoteContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        bottom: 35,
        pointerEvents: "none",

        "& .input-error": {
            marginRight: 14,
            color: theme.palette.general.darkishRed,
        },
    },
    defaultFlag: {
        "& .selected-flag": {
            "& .flag": {
                backgroundPosition: USA_FLAG_POSITION,
            },
        },
    },
}));

const CustomPhoneInput = ({
    fieldNote,
    placeholder,
    input,
    onChange,
    autoFormat,
    preferredCountries,
    meta: { error, touched },
}) => {
    const classes = useStyles();
    const isError = error && touched;
    const isEmptyValue = !input.value;

    return (
        <div className={classes.phoneFieldContainer}>
            <PhoneInput
                placeholder={placeholder}
                value={input.value}
                className={clsx(classes.phoneField, isError && classes.error, isEmptyValue && classes.defaultFlag)}
                enableSearch
                autoFormat={autoFormat}
                onChange={(e) => {
                    input.onChange(e);
                    onChange && onChange(e);
                }}
                preferredCountries={preferredCountries}
            />
            {!!isError && (
                <div className={classes.errorNoteContainer}>
                    <ErrorIcon className="input-error" />
                </div>
            )}
            {isError && <FieldNote fieldNote={error} isError={true} />}
            {fieldNote && <FieldNote fieldNote={fieldNote} />}
        </div>
    );
};

export default CustomPhoneInput;
