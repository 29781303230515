import {
    SETTINGS_REGIONS_DELETE,
    SETTINGS_REGIONS_FAILURE_MESSAGE,
    SETTINGS_REGIONS_FILTERS_SET,
    SETTINGS_REGIONS_LOADING,
    SETTINGS_REGIONS_LOADING_FAILURE,
    SETTINGS_REGIONS_LOADING_SUCCESS,
    SETTINGS_REGIONS_SUCCESS_MESSAGE,
    SETTINGS_REGIONS_UPDATE,
    SETTINGS_REGIONS_REQUEST,
    SETTINGS_REGIONS_REQUEST_RESULT
} from "../events/regionsSettings";

const initialState = {
    isLoading: false,
    regionsList: [],
    regionsListCount: 0,
    regionsFilters: null,
    success: null,
    error: null,
};

export const regionsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_REGIONS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case SETTINGS_REGIONS_LOADING_SUCCESS: {
            const { meta, data } = action.payload;

            return {
                ...state,
                isLoading: false,
                regionsList: data,
                regionsListCount: meta.count,
            };
        }
        case SETTINGS_REGIONS_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case SETTINGS_REGIONS_REQUEST_RESULT: {
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        }
        case SETTINGS_REGIONS_LOADING_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SETTINGS_REGIONS_FILTERS_SET:
            return {
                ...state,
                regionsFilters: action.payload,
            };
        case SETTINGS_REGIONS_SUCCESS_MESSAGE:
            return {
                ...state,
                success: action.payload,
            };
        case SETTINGS_REGIONS_FAILURE_MESSAGE:
            return {
                ...state,
                error: action.payload,
            };
        case SETTINGS_REGIONS_UPDATE: {
            const updatedRegionsList = state.regionsList.map((region) => {
                if (region.id === action.payload.id) {
                    return action.payload;
                }

                return region;
            });

            return {
                ...state,
                regionsList: updatedRegionsList,
            };
        }
        case SETTINGS_REGIONS_DELETE: {
            const updatedRegionsList = state.regionsList.filter((region) => region.id !== action.payload);

            return {
                ...state,
                regionsList: updatedRegionsList,
                regionsListCount: state.regionsListCount - 1,
            };
        }
        default:
            return state;
    }
};
