import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import { ActionLink, SimpleLink } from "../core/link";
import CustomSwitch from "../core/form/customSwitch";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { makeStyles } from "@material-ui/styles";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AppModal from "../core/modal";
import PropTypes from "prop-types";
import ConfirmationModal from "../core/confirmationModal";
import { IS_ADMIN_USER } from "../../constants/maps";
import {
    deleteRegion,
    getSettingsRegions,
    regionsSettingsErrorMessage,
    regionsSettingsSuccessMessage,
    settingsRegionsFiltersSet,
    updateProfile,
} from "../../actions/regionsSettings";
import SettingsSearch from "./settingsSearch";
import RegionsSettingsCreateUpdateForm, { CREATE_UPDATE_REGION_FORM } from "./regionsSettingsCreateUpdateForm";
import SettingsCompanySelector from "./settingsCompanySelector";

const useStyles = makeStyles(() => ({
    regionsTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
    switch: {
        display: "flex",
    },
}));

const RegionsSettings = ({
    isLoading,
    regionsList,
    account,
    regionsListCount,
    regionsFilters,
    success,
    error,
    selectedCompany,
    form,
    setRegionRequiredValue,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const companyToRequestId = selectedCompany?.value || account.company.id;
    const [openRegionCreateUpdateForm, setOpenRegionCreateUpdateForm] = useState(false);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [confirmationModalRegion, setConfirmationModalRegion] = useState(null);
    const [activeRegionToUpdate, setActiveRegionToUpdate] = useState(null);

    const updateListByFilters = (filters) => {
        let newFilters = {
            ..._.cloneDeep(regionsFilters),
            ..._.cloneDeep(filters),
        };
        if (!_.isEqual(regionsFilters, newFilters)) {
            dispatch(settingsRegionsFiltersSet(newFilters));
        }
    };

    const handleCloseConfirmationModal = () => {
        setOpenDeleteConfirmationModal(false);
        setConfirmationModalRegion(null);
    };

    const onDeleteRegion = () => {
        const { id, regionName } = confirmationModalRegion;
        dispatch(deleteRegion(id, regionName));
        handleCloseConfirmationModal();
    };

    const getTableData = () => {
        const rows = regionsList.map((region, index) => {
            const { regionName, id, company, latitude, longitude, canBeRemoved } = region;
            const coordinates = `${latitude}, ${longitude}`;

            const onEditRegionClick = () => {
                const updatedRegion = {
                    id,
                    regionName,
                    coordinates,
                    latitude,
                    longitude,
                };
                if (isAdmin) {
                    updatedRegion.company = {
                        value: company.id,
                        label: company.name,
                    };
                }
                setActiveRegionToUpdate(updatedRegion);
                setOpenRegionCreateUpdateForm(true);
            };

            const renderOtherActionsButtons = () => {
                return (
                    <>
                        <IconButton title="Edit region" onClick={() => onEditRegionClick()}>
                            <EditIcon />
                        </IconButton>
                        {canBeRemoved && (
                            <IconButton
                                title="Delete region"
                                onClick={() => {
                                    setOpenDeleteConfirmationModal(true);
                                    setConfirmationModalRegion({
                                        id,
                                        regionName,
                                    });
                                }}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        )}
                    </>
                );
            };

            return {
                index,
                regionName,
                companyName: company.name,
                action: (
                    <Grid container justify={"center"} style={{ minHeight: 50 }}>
                        {renderOtherActionsButtons()}
                    </Grid>
                ),
            };
        });
        let columns = [
            {
                id: "regionName",
                label: "Region Name",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        if (IS_ADMIN_USER(account.company.id)) {
            columns.splice(1, 0, {
                id: "companyName",
                label: "Company Name",
            });
        }

        return {
            rows: rows,
            columns: columns,
        };
    };

    const getMoreRegions = (concat) => dispatch(getSettingsRegions(concat, companyToRequestId, regionsFilters));

    const onAddNewRegionClick = () => {
        if (isAdmin) {
            setActiveRegionToUpdate({
                company: selectedCompany,
            });
        } else {
            setActiveRegionToUpdate(null);
        }
        setOpenRegionCreateUpdateForm(true);
    };

    const getRegions = () => {
        dispatch(getSettingsRegions(false, companyToRequestId, regionsFilters));
    };

    const errorHandler = (error) => {
        dispatch(regionsSettingsErrorMessage(error));
    };

    const successHandler = (message) => {
        dispatch(regionsSettingsSuccessMessage(message));
    };

    const handleCloseRegionForm = () => {
        setOpenRegionCreateUpdateForm(false);
    };

    const updateProfileOption = (value) => {
        const field = "regionRequired";
        let config = { field, value, successHandler, errorHandler, selectedCompany };
        dispatch(updateProfile(config));
        setRegionRequiredValue(value);
    };

    useEffect(() => {
        if (regionsFilters) {
            dispatch(getSettingsRegions(false, companyToRequestId, regionsFilters));
        }
        if (account && selectedCompany && selectedCompany.id !== account.company.id) {
            dispatch(change(form, "regionRequired", selectedCompany.regionRequired));
        }
    }, [regionsFilters, selectedCompany]);

    useEffect(() => {
        return () => {
            dispatch(settingsRegionsFiltersSet({}));
        };
    }, []);

    return (
        <>
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <Grid container direction="row" justify="space-between">
                    <Grid container direction="row" alignItems="center" xs={6}>
                        <Grid container xs={6}>
                            <ActionLink>
                                <AddCircleOutlineIcon />
                                <SimpleLink onClick={() => onAddNewRegionClick()}>Add new region</SimpleLink>
                            </ActionLink>
                        </Grid>
                        {!_.isEmpty(regionsList) && (
                            <Grid container xs={6}>
                                <Field
                                    name="regionRequired"
                                    onChange={(e) => updateProfileOption(e.target.checked)}
                                    type="checkbox"
                                    label="Region Required"
                                    component={CustomSwitch}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <SettingsSearch
                            updateListByFilters={updateListByFilters}
                            form={REGIONS_SETTINGS_FILTERS_FORM}
                        />
                    </Grid>
                    {IS_ADMIN_USER(account.company.id) && (
                        <SettingsCompanySelector
                            form={REGIONS_SETTINGS_COMPANY_SELECTOR}
                            initialValues={{
                                company: {
                                    value: account.company.id,
                                    label: account.company.name,
                                },
                            }}
                        />
                    )}
                    {!_.isEmpty(regionsList) ? (
                        <Grid item>
                            <TableWithStickyHeader
                                rows={getTableData().rows}
                                fetchMore={getMoreRegions}
                                itemsCount={regionsListCount}
                                className={clsx(classes.regionsTable)}
                                columns={getTableData().columns}
                            />
                        </Grid>
                    ) : isLoading ? null : (
                        <Grid container justify="center">
                            <h1 className="--text-center">No regions match your search</h1>
                        </Grid>
                    )}
                </Grid>
            </div>
            {!_.isEmpty(success) && (
                <SuccessNotification message={success} config={{ onClose: () => successHandler([]) }} />
            )}
            {!_.isEmpty(error) && <ErrorNotification message={error} config={{ onClose: () => errorHandler([]) }} />}
            <AppModal
                isOpen={openRegionCreateUpdateForm}
                modalStyles={{ width: 600 }}
                form={CREATE_UPDATE_REGION_FORM}
                closeModal={handleCloseRegionForm}
            >
                <RegionsSettingsCreateUpdateForm
                    errorHandler={errorHandler}
                    successHandler={successHandler}
                    getRegions={getRegions}
                    closeModal={handleCloseRegionForm}
                    defaultCoordinates={
                        activeRegionToUpdate && {
                            lat: parseFloat(activeRegionToUpdate.latitude),
                            lng: parseFloat(activeRegionToUpdate.longitude),
                        }
                    }
                    initialValues={activeRegionToUpdate}
                />
            </AppModal>
            <ConfirmationModal
                isOpen={openDeleteConfirmationModal}
                question={`Are you sure you want to delete region?`}
                yesHandler={onDeleteRegion}
                noHandler={() => handleCloseConfirmationModal()}
            />
        </>
    );
};

RegionsSettings.propTypes = {
    account: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    regionsList: PropTypes.array,
    regionsListCount: PropTypes.number,
    regionsFilters: PropTypes.object,
    success: PropTypes.string,
    error: PropTypes.string,
    selectedCompany: PropTypes.object,
    setRegionRequiredState: PropTypes.func,
};

export const REGIONS_SETTINGS_COMPANY_SELECTOR = "regionsSettingsCompanySelector";
export const REGIONS_SETTINGS_FILTERS_FORM = "regionsSettingsFiltersForm";

const REGION_REQIRED_FORM = "REGION_REQIRED_FORM";
export default withRouter(
    compose(
        reduxForm({ form: REGION_REQIRED_FORM, enableReinitialize: true }),
        connect((state, props) => {
            const formSelector = formValueSelector(REGIONS_SETTINGS_COMPANY_SELECTOR);

            return {
                account: state.account,
                regionsList: state.regionsSettings.regionsList,
                regionsListCount: state.regionsSettings.regionsListCount,
                regionsFilters: state.regionsSettings.regionsFilters,
                isLoading: state.regionsSettings.isLoading,
                success: state.regionsSettings.success,
                error: state.regionsSettings.error,
                selectedCompany: formSelector(state, "company"),
            };
        }),
    )(RegionsSettings),
);
