import React from "react";
import { Field } from "redux-form";

import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";

const StripeDataFields = () => {
    return (
        <>
            <label className="form-section-label">Stripe Settings</label>
            <br />
            <br />
            <Field
                type="text"
                name="allowedPeriodAfterPaymentFailed"
                validate={[Validation.isInteger, Validation.required]}
                label="Allowed Period After Payment Failed (Days)"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                type="text"
                name="stripeId"
                label="Stripe Id"
                validate={[Validation.required]}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={TextInputComponent}
            />
            <br />
            <br />
        </>
    );
};

export default StripeDataFields;
