import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    companyAdminsErrorUpdate,
    selectCompanyAdmins,
    selectCompanyAdminsMeta,
} from "../reducers/companyAdminsReducer";
import { getCompanyAdminsTableData } from "../actions/companyAdmins";
import CompanyAdminsTable from "../components/settings/CompanyAdminsTable";
import { ErrorNotification } from "../components/core";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../constants/global";
import Loader from "../components/core/loader";

const NonAdminSettings = () => {
    const dispatch = useDispatch();
    const companyAdmins = useSelector(selectCompanyAdmins);
    const { count, error, loading } = useSelector(selectCompanyAdminsMeta);

    useEffect(() => {
        dispatch(getCompanyAdminsTableData());
    }, []);

    return (
        <div className={clsx(loading && LOADER_WHITE_OVERLAY)}>
            {loading && <Loader />}
            <CompanyAdminsTable
                companyAdmins={companyAdmins}
                fetchMore={(concat) => dispatch(getCompanyAdminsTableData(concat))}
                count={count}
            />
            {error && (
                <ErrorNotification error={error} config={{ onClose: () => dispatch(companyAdminsErrorUpdate(null)) }} />
            )}
        </div>
    );
};

export default NonAdminSettings;
