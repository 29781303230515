/* global google */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import router from "../../styles/images/deviceIcon.svg";
import { GOOGLE_MAPS_TRUCK_ICON_SIZE } from "../../constants/global";
import CustomMarker from "../global/maps/customMarker";
import { DEVICES_TYPE_HUB } from "../../constants/maps";
import { getTrucksForMap } from "../../helpers/global";
import { Polygon } from "react-google-maps";
import { MAIN_THEME } from "../../styles/globalThemeConfig";
import { SITE_CIRCLE_OPACITY, SITE_CIRCLE_WIDTH } from "../../constants/sites";

const DeviceMapIcon = (props) => {
    const { coordinates, infoText, type, id, geoZone } = props;
    const options = {
        strokeColor: MAIN_THEME.palette.general.paleBlue,
        strokeWeight: SITE_CIRCLE_WIDTH,
        fillColor: MAIN_THEME.palette.general.paleBlue,
        fillOpacity: SITE_CIRCLE_OPACITY,
    };
    let icon = {
        scaledSize: new google.maps.Size(GOOGLE_MAPS_TRUCK_ICON_SIZE, GOOGLE_MAPS_TRUCK_ICON_SIZE),
    };
    if (+type !== DEVICES_TYPE_HUB) {
        icon = {
            ...icon,
            url: router,
        };
    } else {
        icon = {
            ...icon,
            url: getTrucksForMap(null, 0),
        };
    }

    return (
        <React.Fragment>
            {!_.isEmpty(coordinates) && (
                <CustomMarker
                    zIndex={1}
                    id={id}
                    position={{
                        lat: coordinates.lat,
                        lng: coordinates.lng,
                    }}
                    icon={icon}
                >
                    {infoText}
                </CustomMarker>
            )}
            {!_.isEmpty(geoZone) && (
                <Polygon
                    path={geoZone.map(([lat, lng]) => ({ lat, lng }))}
                    defaultCenter={coordinates}
                    options={options}
                />
            )}
        </React.Fragment>
    );
};

DeviceMapIcon.propTypes = {
    coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    infoText: PropTypes.node,
    type: PropTypes.string,
    id: PropTypes.number.isRequired,
    geoZone: PropTypes.array,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(DeviceMapIcon),
);
