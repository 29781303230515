import React from "react";
import { Field, reduxForm } from "redux-form";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import PillSwitch from "../../core/form/pillSwitch";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import Loader from "../../core/loader";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import { useStyles as filtersStyles } from "../../global/filters";

const useStyles = makeStyles(() => ({
    filterItem: {
        flex: 1,
        "& .pill": {
            width: "100%",
        },
    },
    subTitle: {
        display: "block",
        marginBottom: "20px",
    },
}));

const DownloadReportForm = ({ onClose, handleSubmit, download, loading, reportValue, isStartTimeReport }) => {
    const items = [
        { id: 1, name: "CSV", value: 1 },
        { id: 5, name: "PDF", value: 5 },
    ];
    const filtersClasses = filtersStyles();
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit(download)} noValidate={true} className={clsx(loading && LOADER_WHITE_OVERLAY)}>
            {loading && <Loader />}
            <h2 className="title">DOWNLOAD REPORT</h2>
            {!isStartTimeReport && (
                <h3 className={clsx(classes.subTitle, "--text-center")}>
                    Note that tickets selection is set up to 5000 tickets
                </h3>
            )}
            <Grid
                container
                spacing={1}
                alignItems={"center"}
                className={`${filtersClasses.filtersComponent} ${filtersClasses.filtersComponentMaxWidth}`}
            >
                {items.map((item) => {
                    return (
                        <Grid item key={item.id} className={classes.filterItem}>
                            <Field
                                type="radio"
                                name="reportFormat"
                                value={`${item.value}`}
                                label={item.name}
                                className="measure-pill"
                                component={PillSwitch}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                <PrimaryButton disabled={!reportValue} type="submit">
                    Download
                </PrimaryButton>
            </ButtonsGroup>
        </form>
    );
};

export const DOWNLOAD_REPORT_FORM = "downloadReportForm";
export const DOWNLOAD_REPORT_FORM_FIELD = "reportFormat";

export default reduxForm({ form: DOWNLOAD_REPORT_FORM })(DownloadReportForm);
