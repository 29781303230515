import {
    ADD_NEW_USER_REGION,
    AUTHORIZE_USER,
    DELETE_USER_REGION,
    LOGOUT_USER,
    REQUEST_COMPANY_REGIONS,
    REQUEST_COMPANY_REGIONS_RESULT,
    REQUEST_REGIONS,
    REQUEST_REGIONS_RESULT,
    REQUEST_UOM,
    REQUEST_UOM_ERROR,
    REQUEST_UOM_RESULT,
    REQUEST_WEATHER,
    REQUEST_WEATHER_RESULT,
    RESET_COMPANY_REGIONS,
    RESET_REDUX_STATE,
    RESET_UOM_DATA,
    RESET_UOM_META,
    RESET_WEATHER,
    SAVE_USERS_REGIONS,
    UPDATE_ACTIVE_IOT_ORDER,
    UPDATE_USER_COMPANY_TYPE,
    UPDATE_USER_CUSTOMER_STRIPE_ID,
    UPDATE_USER_DATA,
    UPDATE_USER_HAS_TEMPORARY_PASSWORD_FIELD,
    UPDATE_USER_PHONE_NUMBER,
    UPDATE_USER_REGION,
    UPDATE_USER_TERMS_AND_PERMISSIONS_STATUS,
} from "../events/globalEvents";
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from "../routes/globalRoutes";
import store from "../store";
import browserHistory from "../utils/browserHistory";
import history from "../utils/browserHistory";
import * as NotificationsActions from "./notifications";
import * as WebSocketActions from "./websocket";
import { ACCOUNT_DATA_STORAGE_KEY, LOCAL_STORAGE_FILTERS_ID } from "../helpers/api";
import { updateAllAccountDataInLocalStorage } from "../helpers/global";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import { fetchCurrentUserData, getStates, signOutUser } from "../dataServers/user";
import { getTimezones } from "../dataServers/companies";
import { getUnitOfMeasureList } from "../dataServers/purchaseOrders";

const dispatch = store.dispatch;

export const authorizeUser = (userData) => {
    return {
        type: AUTHORIZE_USER,
        userData,
    };
};

export const updateReduxUserData = (userData) => {
    return {
        type: UPDATE_USER_DATA,
        payload: userData,
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};

export const resetReduxState = () => {
    return {
        type: RESET_REDUX_STATE,
    };
};

export const emitUserLoggedOutEvent = () => {
    return signOutUser().then(() => {
        localStorage.removeItem(ACCOUNT_DATA_STORAGE_KEY);
        localStorage.removeItem(LOCAL_STORAGE_FILTERS_ID);
        browserHistory.push(ROUTE_LOGIN);
        dispatch(logoutUser());
        dispatch(resetReduxState());
        dispatch(NotificationsActions.notificationsClear());
        WebSocketActions.close();
    });
};

export const updateUserTermsAndPermissionsStatus = () => {
    return {
        type: UPDATE_USER_TERMS_AND_PERMISSIONS_STATUS,
    };
};

export const saveUsersRegions = (regions) => {
    return {
        type: SAVE_USERS_REGIONS,
        regions,
    };
};

export const addNewUserRegion = (newRegion) => {
    return {
        type: ADD_NEW_USER_REGION,
        payload: newRegion,
    };
};

export const updateUserRegion = (updatedRegion) => {
    return {
        type: UPDATE_USER_REGION,
        payload: updatedRegion,
    };
};

export const deleteUserRegion = (regionId) => {
    return {
        type: DELETE_USER_REGION,
        payload: regionId,
    };
};

export const updateUserCompanyType = () => {
    return {
        type: UPDATE_USER_COMPANY_TYPE,
    };
};

export const updateUserCustomerStripeId = (stripeId) => {
    return {
        type: UPDATE_USER_CUSTOMER_STRIPE_ID,
        payload: stripeId,
    };
};

export const updateUserPhoneNumber = (phone) => {
    return {
        type: UPDATE_USER_PHONE_NUMBER,
        payload: phone,
    };
};

export const loadStates = (inputValue, { params = {}, loadedCount }) => {
    let stateParams = { ...params };
    if (inputValue) stateParams.keywords = inputValue;

    return getStates({ ...stateParams }).then((data) => {
        const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
        const nextPage = loadedCount ? +currentPage + 1 : 1;
        const options = data.data.map((it) => ({
            label: `${it.abbreviation} (${it.name})`,
            abbreviation: it.abbreviation,
            value: it.id,
        }));

        return {
            options: options,
            hasMore: data.meta.count > (loadedCount || options.length),
            page: nextPage,
        };
    });
};

export const loadUnitOfMeasures = () => async (dispatch, getState) => {
    try {
        dispatch(requestUom());
        const { data } = await getUnitOfMeasureList({ useMeasurementSystem: true });
        const options = data.map((it) => ({
            label: it.name,
            value: it.id,
        }));
        dispatch(requestUomResult(options));
    } catch (error) {
        dispatch(requestUomError(error.message));
    }
};

export const requestUom = (payload) => {
    return {
        type: REQUEST_UOM,
    };
};

export const requestUomResult = (payload) => {
    return {
        type: REQUEST_UOM_RESULT,
        payload,
    };
};

export const requestUomError = (payload) => {
    return {
        type: REQUEST_UOM_ERROR,
        payload,
    };
};

export const resetUomData = () => {
    return {
        type: RESET_UOM_DATA,
    };
};

export const resetUomMeta = () => {
    return {
        type: RESET_UOM_META,
    };
};

export const updateActiveIotOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_IOT_ORDER,
        payload: order,
    };
};

export const updateUserHasTemporaryPasswordField = () => {
    return {
        type: UPDATE_USER_HAS_TEMPORARY_PASSWORD_FIELD,
    };
};

export const requestRegions = () => {
    return {
        type: REQUEST_REGIONS,
        payload: { loaded: false, hasMore: true, loading: true },
    };
};

export const requestRegionsResult = () => {
    return {
        type: REQUEST_REGIONS_RESULT,
        payload: { loaded: true, hasMore: true, loading: false },
    };
};

export const requestCompanyRegions = () => ({ type: REQUEST_COMPANY_REGIONS });
export const requestCompanyRegionsResult = (payload) => ({ type: REQUEST_COMPANY_REGIONS_RESULT, payload });
export const resetCompanyRegions = () => ({ type: RESET_COMPANY_REGIONS });

export const requestWeather = () => ({ type: REQUEST_WEATHER });
export const requestWeatherResult = (payload) => ({ type: REQUEST_WEATHER_RESULT, payload });
export const resetWeather = () => ({ type: RESET_WEATHER });

export const updateUserProfileData = () => async (dispatch) => {
    try {
        const { data } = await fetchCurrentUserData();
        await updateAllAccountDataInLocalStorage(data);
        await dispatch(updateReduxUserData(data));
    } catch (error) {}
};

export const getCompanyTimezones = async (inputValue, { params = {}, loadedCount }) => {
    const props = { page: params.page };
    if (inputValue) props.keywords = inputValue;

    const { data: timezones, meta } = await getTimezones({ ...props });
    const results = timezones.map((i) => {
        const offset = i.offset > 0 ? `+${i.offset}` : `${i.offset}`;

        return { value: i.id, label: `${i.name} (GMT ${offset})` };
    });

    return {
        options: results,
        hasMore: meta.count > (loadedCount || results.length),
        page: meta.page,
    };
};

export const changeCurrentUser =
    (updatedAccountData, routeToRedirect = ROUTE_DASHBOARD) =>
    (dispatch, getState) => {
        localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(updatedAccountData));
        history.push(routeToRedirect);
        window.location.reload();
    };
