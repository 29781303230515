import React from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";

const ErrorNotification = (props) => {
    const { enqueueSnackbar, message, error, config, type, simpleString } = props;

    if (message) {
        enqueueSnackbar(message, { variant: type, ...config });
    }

    if (!simpleString) {
        for (let error_ of error) {
            enqueueSnackbar(error_, { variant: type, ...config });
        }
    }

    return <div />;
};

ErrorNotification.propTypes = {
    config: PropTypes.object.isRequired,
    message: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
    error: PropTypes.array,
    type: PropTypes.oneOf(["default", "success", "error", "info"]),
};

ErrorNotification.defaultProps = {
    config: {},
    error: [],
    type: "error",
};

export default withSnackbar(ErrorNotification);
