import React from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

import { CARD_STYLES } from "../../styles/reusableStyles";
import { BundleData, SmartDispatchBundleJoPill, SmartDispatchBundlePill } from "./";
import BundleItemErrorMessage from "./BundleItemErrorMessage";

export default function BundleItem({
    handleBundleItemClick,
    problemId,
    bundleStatus,
    bundleJobOrders,
    bundleCreatedDate,
    bundleCreator,
    bundleTrucksCount,
    bundleRegion,
    isDraft,
    message,
}) {
    const cardStyles = CARD_STYLES();

    return (
        <Grid
            container
            className={clsx(cardStyles.cardStyles, "bundle-item")}
            onClick={() => handleBundleItemClick(problemId)}
        >
            <Grid item xs={3}>
                <h2>
                    {isDraft && "Draft"} Bundle # {problemId}
                </h2>
                <div className="bundle-status-container">
                    <SmartDispatchBundlePill status={bundleStatus} />
                </div>
            </Grid>
            <Grid item xs={6} className="bundle-job-container">
                {bundleJobOrders.map((jobOrder) => {
                    return (
                        <SmartDispatchBundleJoPill
                            jobOrderId={jobOrder.id}
                            key={jobOrder.id}
                            manuallyAdded={jobOrder.manuallyAdded}
                        />
                    );
                })}
            </Grid>
            <Grid item xs={3} className="bundle-data-container">
                <BundleData
                    bundleCreatedDate={bundleCreatedDate}
                    bundleCreator={bundleCreator}
                    bundleTrucksCount={bundleTrucksCount}
                    bundleRegion={bundleRegion}
                />
            </Grid>
            {message && (
                <Grid item xs={12}>
                    <BundleItemErrorMessage message={message} />
                </Grid>
            )}
        </Grid>
    );
}
