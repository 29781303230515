import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";
import { change, Field, FieldArray, formValueSelector, getFormInitialValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { PrimaryButton } from "../core/buttons/primaryButton";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
    SELECT_ALL_OPTIONS_ID,
} from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import UnitOfMeasure from "../core/unitOfMeasure";
import PillSwitch from "../core/form/pillSwitch";
import Loader from "../core/loader";
import HiddenInput from "../core/form/hiddenInput";
import PurchaseOrderSelector from "../core/form/purchaseOrderSelector";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { Divider, DividerThin } from "../core/divider";
import { getSiteValue, getUnitOfMeasureValue, handleError, replaceUomToId } from "../../helpers/global";
import {
    createPurchaseOrder,
    createPurchaseOrdersItems,
    getPayloadsList,
    updatePurchaseOrderItems,
} from "../../dataServers/purchaseOrders";
import { resetUomMeta } from "../../actions/globalActions";
import { getUnitsOfMeasure, loadUnitsOfMeasureOptions } from "../../actions/unitsOfMeasure";
import {
    hasCreatedUnitsOfMeasure,
    selectDefaultUnitsOfMeasure,
    selectUnitsOfMeasureMeta,
} from "../../reducers/unitsOfMeasureReducer";
import { fetchCompanyTruckTypes } from "../../dataServers/trucks";
import { Validation, VALIDATION_ERRORS } from "../../helpers/validation";
import * as POLIneItemsActions from "../../actions/poLineItems";
import {
    IS_ADMIN_USER,
    SITES_TYPE_PRIVATE,
    SITES_TYPE_PUBLIC,
    TICKET_USAGE_TYPES,
    UNIT_OF_MEASURE_BY_ID,
    UNIT_OF_MEASURE_HOUR,
    UNIT_OF_MEASURE_LOAD,
} from "../../constants/maps";
import PickUpDropOff, { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobs/jobComponents/pickUp&DropOff";
import { prepareAdditionalName } from "../../helpers/projects";
import { DEFAULT_DATE_TIME_FORMAT, LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import QuantityWithUnlimited from "../core/quantityWithUnlimited";
import ErrorNotification from "../core/notification";
import { convertDateToUserTimeZone } from "../../helpers/jobOrders";
import DatesRange from "../global/datesRange";
import AppModal from "../core/modal";
import PayloadOptionForm, { NEW_PAYLOAD_OPTION_FORM } from "./payloadOptionForm";
import { useStyles as filtersStyles } from "../global/filters";
import { StatusPill } from "../core/pills";
import { useErrorNotification } from "../../hooks";

const validate = (values) => {
    const errors = {};

    if (!getUnitOfMeasureValue(values.unitOfMeasure)) {
        errors.unitOfMeasureError = VALIDATION_ERRORS.required;
    }

    if (!values.ticketUsageType) {
        errors.ticketUsageTypeError = VALIDATION_ERRORS.required;
    }

    return errors;
};

const useStyles = makeStyles(() => ({
    poLineStyles: {
        minWidth: 710,
        "& form": {
            margin: "0 15px",
        },

        "& .measure-pill": {
            width: "100%",

            "& .pill": {
                width: "100%",
            },
        },
    },
    filterItem: {
        flex: 1,
    },
    uomDivider: {
        position: "relative",
        "& .text": {
            position: "absolute",
            left: "50%",
            marginLeft: -15,
            zIndex: 34,
            top: -8,
            width: 30,
            borderRadius: 50,
            textAlign: "center",
            background: "white",
            fontWeight: "bold",
        },
    },
}));

function renderAdditionalFields({ fields, deleteField }) {
    const addEmptyField = () => {
        fields.push({});
    };
    const removeField = (index) => {
        fields.remove(index);
        deleteField && deleteField(fields.get(index));
    };

    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <Grid item key={index}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Field
                                type="text"
                                name={`${field}.name`}
                                label="Field name"
                                fieldNote="Additional field name"
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <Field
                                type="text"
                                name={`${field}.value`}
                                label="Field value"
                                fieldNote="Additional field value"
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid item xs={1} style={{ paddingTop: 16 }}>
                            <CloseIcon
                                color={"action"}
                                fontSize={"default"}
                                cursor={"pointer"}
                                onClick={() => removeField(index)}
                            />
                        </Grid>
                    </Grid>
                    <br />
                </Grid>
            ))}
            <ButtonsGroup>
                <SecondaryButton size="small" onClick={addEmptyField}>
                    Add Fields
                </SecondaryButton>
            </ButtonsGroup>
        </React.Fragment>
    );
}

renderAdditionalFields.propTypes = {
    fields: PropTypes.object.isRequired,
    removeField: PropTypes.func,
};

const UOMDivider = () => {
    const styles = useStyles();

    return (
        <Box className={styles.uomDivider}>
            <Divider />
            <Typography className="text">OR</Typography>
        </Box>
    );
};

const PoLineItemForm = (props) => {
    const {
        account,
        closeModal,
        currentProject,
        form,
        getPOLineItems,
        handleSubmit,
        showPOSelector,
        edit,
        dispatch,
        poliCreationHandler,
        changeSiteFilter,
        currentProjectCompanyId,
        currentProjectCompanyName,
        unitOfMeasuresMeta,
        unitOfMeasures,
        loadUnitOfMeasures,
        resetUomMeta,
        poLineItem,
        hasCreatedUnitsOfMeasure,
        grantedAccess,
        updatePoLineItem,
    } = props;
    const classes = useStyles();
    const showError = useErrorNotification();
    const filtersClasses = filtersStyles();
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const companyId = isAdmin ? currentProject?.owner?.id || currentProjectCompanyId : account.company.id;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openCreatePayloadModal, setOpenCreatePayloadModal] = useState(false);
    let {
        unitOfMeasure: unitOfMeasureValue,
        unlimited,
        [DROP_OF_SITE_NAME]: dropOff,
        siteFilter,
        phaseCodeQuantity,
        fieldsToRemoveValues,
    } = props.formValues;

    const company = {
        value: currentProject?.owner?.id || currentProjectCompanyId,
        label: currentProject?.owner?.name || currentProjectCompanyName,
    };

    const unitOfMeasure = getUnitOfMeasureValue(unitOfMeasureValue);

    const onSubmit = (values) => {
        return handlePOLineItemCreate(values).then((data) => {
            setTimeout(() => getPOLineItems(currentProject.id), 500);
            poliCreationHandler && poliCreationHandler(data);
        });
    };

    const loadTruckTypes = (inputValue, { params = {}, loadedCount }) => {
        return fetchCompanyTruckTypes(currentProject?.owner?.id || currentProjectCompanyId, params).then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: i.name }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadPayloads = (inputValue, { params = {}, loadedCount }) => {
        const companyId = isAdmin ? currentProject?.owner?.id || currentProjectCompanyId : account.company.id;

        return getPayloadsList(companyId, { keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({ value: i.id, label: i.name, poType: i.poType }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadCreatedUOMs = async (inputValue, params) => {
        try {
            const result = await loadUnitsOfMeasureOptions(companyId, inputValue, params);

            return result;
        } catch (error) {
            setError([handleError(error).message]);
        }
    };

    const handlePOLineItemCreate = (values) => {
        const {
            [PICK_UP_SITE_NAME]: pickUpSite,
            [DROP_OF_SITE_NAME]: dropOfSites,
            payload,
            unitOfMeasure,
            ticketUsageType,
            startDate,
            endDate,
            perUnitPrice,
            quantity,
            additionalFields,
            truckTypes,
            id,
            purchaseOrder,
        } = values;
        const attached_files = poLineItem?.additionalFields?.attached_files;
        const startDateFormatted = _.isString(startDate) ? moment(startDate) : startDate;
        const endDateFormatted = _.isString(endDate) ? moment(endDate) : endDate;
        const dataForSave = {
            payload: payload.value || payload || null,
            unitOfMeasure: +(unitOfMeasure?.value || replaceUomToId(unitOfMeasure)),
            quantity: quantity || 0,
            perUnitPrice,
            pickUpSite: pickUpSite.value || pickUpSite,
            dropOffSites: dropOfSites ? dropOfSites.map((site) => site.value || site) : [],
            startDate: startDate
                ? convertDateToUserTimeZone(startDateFormatted, account.timezone)
                : id
                ? null
                : undefined,
            endDate: endDate ? convertDateToUserTimeZone(endDateFormatted, account.timezone) : id ? null : undefined,
            unlimited: !!unlimited,
        };

        if (ticketUsageType) {
            dataForSave.ticketsType = ticketUsageType;
        }

        setLoading(true);

        if (edit) {
            dataForSave.id = id;

            const fieldsToAdd = additionalFields?.reduce((result, field) => {
                const fieldName = prepareAdditionalName(field.name);
                if (fieldName.toLowerCase() === "phase_code") {
                    result["phase_code"] = { [field.value]: 0 };
                } else {
                    result[fieldName] = field.value;
                }

                return { ...result };
            }, {});

            if (values.phaseCode) {
                fieldsToAdd["phase_code"] = {
                    ...fieldsToAdd["phase_code"],
                    [values.phaseCode]: values.phaseCodeQuantity || null,
                };
            }

            if (!_.isEmpty(attached_files)) {
                fieldsToAdd["attached_files"] = attached_files;
            }

            // delete all fields from initial poli
            const initialAdditionalFields = poLineItem?.additionalFields;
            const fieldsToRemove = Object.keys(initialAdditionalFields || {}).map((i) => i);

            dataForSave.additionalFields = {};
            dataForSave.additionalFields.fieldsToAdd = fieldsToAdd;
            dataForSave.additionalFields.fieldsToRemove = fieldsToRemove;

            if (grantedAccess) {
                return updatePoLineItem(id, {
                    additionalFields: _.chain(additionalFields || [])
                        .keyBy("name")
                        .mapValues("value")
                        .value(),
                })
                    .then(() => {
                        setLoading(false);
                        closeModal();
                    })
                    .catch((error) => {
                        showError(error);
                        setLoading(false);
                    });
            }

            return updatePurchaseOrderItems(purchaseOrder.id, {
                items: [dataForSave],
                truckTypes: truckTypes.map((i) => i.value),
            })
                .then(() => {
                    setLoading(false);
                    closeModal();
                })
                .catch((error) => {
                    setLoading(false);
                    setError(PROCESS_SERVER_ERROR(error));
                });
        } else {
            const fieldsToRemove =
                (props?.initialFormValues?.additionalFields &&
                    props.initialFormValues.additionalFields.map((i) => i.name)) ||
                [];
            const fieldsToAdd = additionalFields
                ? additionalFields.reduce((result, field) => {
                      const fieldName = prepareAdditionalName(field.name);
                      if (fieldName === "phase_code") {
                          result["phase_code"] = { [field.value]: 0 };
                      } else {
                          result[fieldName] = field.value;
                      }
                      return { ...result };
                  }, {})
                : {};

            if (values.phaseCode) {
                fieldsToAdd["phase_code"] = {
                    ...fieldsToAdd["phase_code"],
                    [values.phaseCode]: values.phaseCodeQuantity || null,
                };
            }

            dataForSave.additionalFields = {};
            dataForSave.additionalFields.fieldsToAdd = fieldsToAdd;
            dataForSave.additionalFields.fieldsToRemove = fieldsToRemove;

            return createPurchaseOrder({
                project: currentProject.id,
                items: [],
                truckTypes: truckTypes.map((type) => type.value || type),
            })
                .then((purchaseOrder) => {
                    const purchaseOrderId = purchaseOrder.data.id;

                    return createPurchaseOrdersItems(purchaseOrderId, dataForSave).then(({ data }) => {
                        const { id } = data;
                        updatePurchaseOrderItems(purchaseOrderId, {
                            items: [{ ...dataForSave, id }],
                        }).catch((error) => {
                            setLoading(false);
                            setError(PROCESS_SERVER_ERROR(error));
                        });

                        closeModal();
                        setLoading(false);

                        return { ...data, additionalFields: dataForSave.additionalFields };
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    setError(PROCESS_SERVER_ERROR(error));
                });
        }
    };
    const selectAllTruckTypesHandler = () => {
        loadTruckTypes("", {}).then(({ options }) => {
            dispatch(change(form, "truckTypes", options));
        });
    };

    const selectNewCreatedSite = (data, fieldName) => {
        if (fieldName === PICK_UP_SITE_NAME) {
            dispatch(change(form, fieldName, getSiteValue(data)));
        } else {
            dispatch(change(form, fieldName, [...(dropOff || []), getSiteValue(data)]));
        }
    };

    const handleCreatePayload = (data) => {
        if (data) {
            const { id, name } = data;

            dispatch(change(form, "payload", { value: id, label: name }));
        }
    };

    useEffect(() => {
        changeSiteFilter({ id: SITES_TYPE_PRIVATE, label: "Private" });
        loadUnitOfMeasures({ useMeasurementSystem: true });

        return () => {
            loadUnitOfMeasures();
            changeSiteFilter(null);
            resetUomMeta();
        };
    }, []);

    useEffect(() => {
        setLoading(unitOfMeasuresMeta.loading);
        if (unitOfMeasuresMeta.error) setError(PROCESS_SERVER_ERROR(unitOfMeasuresMeta.error));
    }, [unitOfMeasuresMeta]);

    const filtersList = [
        {
            label: "Private",
            id: SITES_TYPE_PRIVATE,
        },
        {
            label: "Public",
            id: SITES_TYPE_PUBLIC,
        },
    ];

    const updateAppliedFilters = (filter) => {
        if (siteFilter && siteFilter.id === filter.id) {
            changeSiteFilter(null);
        } else {
            changeSiteFilter(filter);
        }
    };

    const deleteField = (field) => {
        const initialFields = props.initialFormValues?.additionalFields?.map((i) => i.name) || [];
        if (initialFields.includes(field.name)) {
            dispatch(change(form, "fieldsToRemoveValues", [...(fieldsToRemoveValues || []), field.name]));
        }
    };

    const renderCustomerView = () => (
        <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
            <h1 className="title">Edit Payload</h1>
            <br />
            <DividerThin />
            <FieldArray
                name="additionalFields"
                deleteField={deleteField}
                rerenderOnEveryChange
                component={renderAdditionalFields}
            />
            <DividerThin />
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton type="submit">Save</PrimaryButton>
            </ButtonsGroup>
        </form>
    );

    if (grantedAccess) {
        return renderCustomerView();
    }

    return (
        <React.Fragment>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            <h1 className="title">{edit ? "Edit" : "Add"} Payload</h1>
            <br />
            <div className={clsx(classes.poLineStyles, "create-new-poli", isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
                    {showPOSelector && isAdmin && (
                        <React.Fragment>
                            <PurchaseOrderSelector
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                projectId={currentProject.id}
                                validate={[Validation.required]}
                                disabled={true}
                            />
                            <br />
                        </React.Fragment>
                    )}
                    <Field
                        name="truckTypes"
                        loadOptions={loadTruckTypes}
                        validate={[Validation.required]}
                        placeholder="Truck types"
                        className={`${AUTOCOMPLETE_FORM_VIEW_CLASS} truck-types`}
                        isMulti={true}
                        selectAllOption={{ value: SELECT_ALL_OPTIONS_ID }}
                        selectAllHandler={selectAllTruckTypesHandler}
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <Grid
                        container
                        spacing={1}
                        justify={"space-between"}
                        alignItems={"center"}
                        className={`${filtersClasses.filtersComponent} 
                                      ${filtersClasses.filtersComponentMaxWidth}`}
                    >
                        {filtersList.map((filter, filterIndex) => {
                            const active = siteFilter && siteFilter.id === filter.id;

                            return (
                                <Grid component="div" key={filterIndex} className={classes.filterItem} item>
                                    <Field
                                        name="siteFilter"
                                        label={filter.label}
                                        className={clsx("filters-pill", active && "active")}
                                        onClick={() => updateAppliedFilters(filter)}
                                        component={StatusPill}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <br />
                    <PickUpDropOff canSetSites={true} form={form} afterSaveHandler={selectNewCreatedSite} />
                    <br />
                    <Field
                        name="payload"
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        loadOptions={loadPayloads}
                        placeholder="Payload"
                        createNewHandler={() => setOpenCreatePayloadModal(true)}
                        createNewOption={{ label: "Add payload", value: CREATE_NEW_OPTION_ID }}
                        fieldNote="What kind of material do you need for the job?"
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <Grid container spacing={1} justify={"space-between"} alignItems={"center"}>
                        {unitOfMeasures &&
                            unitOfMeasures.map((item, index) => {
                                return (
                                    <Grid item xs key={index}>
                                        <Field
                                            type="radio"
                                            name={"unitOfMeasure"}
                                            value={`value-${item.value}`}
                                            label={item.label}
                                            className={"measure-pill"}
                                            component={PillSwitch}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Field name="unitOfMeasureError" component={HiddenInput} />
                    <br />
                    {(unitOfMeasure === UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_LOAD] ||
                        unitOfMeasure === UNIT_OF_MEASURE_BY_ID[UNIT_OF_MEASURE_HOUR]) && (
                        <>
                            <Grid container spacing={1} justify={"space-between"} alignItems={"center"}>
                                {Object.entries(TICKET_USAGE_TYPES).map((item, index) => {
                                    return (
                                        <Grid item xs key={index}>
                                            <Field
                                                type="radio"
                                                name={"ticketUsageType"}
                                                value={item[0]}
                                                label={item[1]}
                                                className={"measure-pill"}
                                                component={PillSwitch}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Field name={"ticketUsageTypeError"} component={HiddenInput} />
                        </>
                    )}
                    {hasCreatedUnitsOfMeasure && (
                        <>
                            <UOMDivider />
                            <br />
                            <Field
                                name="unitOfMeasure"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                loadOptions={loadCreatedUOMs}
                                placeholder="Units Of Measure"
                                fieldNote="Select from created units of measure"
                                isClearable
                                component={AsyncAutocompleteComponent}
                            />
                        </>
                    )}
                    <br />
                    {unitOfMeasure && (
                        <React.Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        type="number"
                                        validate={[Validation.required]}
                                        name="perUnitPrice"
                                        startAdornment={<span>$</span>}
                                        endAdornment={
                                            <Box display="flex" alignItems="center">
                                                /&nbsp;
                                                <UnitOfMeasure value={unitOfMeasure} isPlural={false} />
                                            </Box>
                                        }
                                        fieldNote="Price paid per designated unit of measure"
                                        label="Price"
                                        component={TextInputComponent}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <QuantityWithUnlimited
                                        dispatch={dispatch}
                                        form={form}
                                        fieldNote={"How much of the unit of measure would you like on this P.O.?"}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                        </React.Fragment>
                    )}
                    <DatesRange form={form} dateOnly={false} isFormView={true} dateFormat={DEFAULT_DATE_TIME_FORMAT} />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                name="phaseCode"
                                type="text"
                                label="Phase Code"
                                component={TextInputComponent}
                                validate={phaseCodeQuantity && [Validation.required]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="phaseCodeQuantity"
                                type="text"
                                label="Phase Code Quantity"
                                component={TextInputComponent}
                            />
                        </Grid>
                    </Grid>
                    <DividerThin />
                    <FieldArray
                        name="additionalFields"
                        deleteField={deleteField}
                        rerenderOnEveryChange
                        component={renderAdditionalFields}
                    />
                    <DividerThin />
                    <ButtonsGroup>
                        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </ButtonsGroup>
                </form>
                <AppModal
                    isOpen={!!openCreatePayloadModal}
                    form={NEW_PAYLOAD_OPTION_FORM}
                    closeModal={() => setOpenCreatePayloadModal(false)}
                >
                    <PayloadOptionForm
                        handleCreatePayload={handleCreatePayload}
                        closeModal={() => setOpenCreatePayloadModal(false)}
                        errorHandler={(error) => setError(error)}
                        initialValues={{ company }}
                    />
                </AppModal>
            </div>
        </React.Fragment>
    );
};

PoLineItemForm.propTypes = {
    account: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    currentProject: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    getPOLineItems: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    showPOSelector: PropTypes.bool,
    poliCreationHandler: PropTypes.func,
    changeSiteFilter: PropTypes.func,
    edit: PropTypes.bool.isRequired,
    currentProjectCompanyId: PropTypes.number,
    currentProjectCompanyName: PropTypes.string,
    unitOfMeasures: PropTypes.array,
    unitOfMeasuresMeta: PropTypes.object,
    loadUnitOfMeasures: PropTypes.func,
    resetUomMeta: PropTypes.func,
};

PoLineItemForm.defaultProps = {
    showPOSelector: false,
    edit: false,
};

export const CREATE_POLI_FORM = "createPoliItem";

export default withRouter(
    compose(
        reduxForm({
            form: CREATE_POLI_FORM,
            validate,
        }),
        connect(
            (state, props) => {
                const formSelector = formValueSelector(props.form);

                return {
                    currentProject: state.projects.currentProject,
                    currentProjectCompanyId: state.projects.currentProjectCompany?.id,
                    currentProjectCompanyName: state.projects.currentProjectCompany?.name,
                    account: state.account,
                    formValues: formSelector(
                        state,
                        "unitOfMeasure",
                        "unlimited",
                        "siteFilter",
                        "phaseCodeQuantity",
                        "fieldsToRemoveValues",
                        DROP_OF_SITE_NAME,
                    ),
                    unitOfMeasures: (selectDefaultUnitsOfMeasure(state) || []).map((i) => ({
                        value: i.id,
                        label: i.name,
                    })),
                    hasCreatedUnitsOfMeasure: hasCreatedUnitsOfMeasure(state),
                    unitOfMeasuresMeta: selectUnitsOfMeasureMeta(state),
                    initialFormValues: getFormInitialValues(props.form)(state),
                };
            },
            (dispatch) => ({
                getPOLineItems: (payload) => {
                    dispatch(POLIneItemsActions.getPOLineItems(payload));
                },
                resetPOLineItems: (payload) => {
                    dispatch(POLIneItemsActions.resetPOLineItems(payload));
                },
                updatePoLineItem: (id, values) => dispatch(POLIneItemsActions.updatePoLineItem(id, values)),
                changeSiteFilter: (filter) => {
                    dispatch(change(CREATE_POLI_FORM, "siteFilter", filter));
                },
                loadUnitOfMeasures: (params) => dispatch(getUnitsOfMeasure(params)),
                resetUomMeta: () => dispatch(resetUomMeta()),
            }),
        ),
    )(PoLineItemForm),
);
