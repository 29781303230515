import { timeFromSeconds } from "../../helpers/jobOrders";

export const EDIT_JO_FORM = "editJobOrderForm";
export const JOLI_FIELD_ID = "joliItems";
export const SPECIFY_TRUCKS_PER_HAULER = "specifyTrucksPerHauler";

export const ESTIMATE_TITLE = "Estimation";
export const ESTIMATED_LOAD_COUNT = (complLoadsCount, loadsCountToDo, unlimited) => {
    if (unlimited) {
        return `${complLoadsCount} Сompleted Loads / `;
    }

    return `${complLoadsCount} Сompleted Loads / ${Math.abs(loadsCountToDo + complLoadsCount)} Total Loads`;
};
export const ESTIMATED_TIME_TO_COMPLETE = (time) => `One way time: ${timeFromSeconds(time, true, "m", "0")}`;

export const DEFAULT_LOADING_TIME_MINUTES = 10;

export const PROXIMITY_JOB_TOOLTIP = "Base location for sorting is taken from this job";
export const CANT_CALCULATE_DISTANCE = "Can't calculate distance";
