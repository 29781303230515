import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import { ROUTE_JOBS, ROUTE_MY_COMPANY } from "./globalRoutes";

import Jobs from "../containers/Jobs";
import JobOrder from "../containers/JobOrder";
import Tickets from "../containers/Tickets";
import Navigation from "../components/global/navigation";
import MyCompanyTeams from "../containers/MyCompanyTeams";
import MyCompany from "../containers/MyCompany";
import TrucksSubNav from "../components/trucks/trucksSubNav";
import TermsDialogWrapper from "../components/dashboard/termsDialogWrapper";

export const ALLOWED_RESTRICTED_CUSTOMER_ROUTES = {
    [ROUTE_JOBS.ROOT]: ROUTE_JOBS.ROOT,
    [ROUTE_JOBS.JOB_ORDER_BY_ID]: ROUTE_JOBS.JOB_ORDER_BY_ID,
    [ROUTE_JOBS.MY_JOBS]: ROUTE_JOBS.MY_JOBS,
    [ROUTE_MY_COMPANY.MY_TEAM]: ROUTE_MY_COMPANY.MY_TEAM,
};

const restrictedCustomerRoutes = ({ history }) => {
    return (
        <>
            <TermsDialogWrapper />
            <Navigation currentUrl={history.location.pathname} />
            <Switch>
                <Route path={ROUTE_JOBS.ROOT}>
                    <Route exact={false} path={[ROUTE_JOBS.JOB_ORDER_BY_ID, ROUTE_JOBS.MY_JOBS]}>
                        <Jobs>
                            <JobOrder />
                        </Jobs>
                    </Route>
                    <Route path={ROUTE_JOBS.TICKETS} component={Tickets} />
                    <Redirect from={"*"} to={ROUTE_JOBS.MY_JOBS} />
                </Route>
                <Route path={ROUTE_MY_COMPANY.ROOT} component={MyCompany}>
                    <TrucksSubNav />
                    <Route exact path={ROUTE_MY_COMPANY.MY_TEAM} component={MyCompanyTeams} />
                    <Redirect from={"*"} to={ROUTE_MY_COMPANY.MY_TEAM} />
                </Route>
                <Redirect from={"*"} to={ROUTE_JOBS.MY_JOBS} />
            </Switch>
        </>
    );
};

export default withRouter(restrictedCustomerRoutes);
