import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { GLOBAL_CONTENT_WIDTH } from "../styles/globalThemeConfig";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import * as SitesActions from "../actions/sites";
import { getSiteById } from "../dataServers/sites";

import SitesList from "../components/sites/sitesList";
import { ErrorBoundary } from "../components/core/errorBoudaries";
import SiteDetails from "../components/sites/siteDetails";
import { LOADER_WHITE_OVERLAY } from "../constants/global";
import Loader from "../components/core/loader";

const useStyles = makeStyles((theme) => ({
    sitesPage: {
        width: GLOBAL_CONTENT_WIDTH,
        padding: 0,
        position: "relative",

        [theme.breakpoints.down(1550)]: {
            width: "100%",
        },

        "& .job-board-page": {
            backgroundColor: theme.palette.general.paleOrange,
        },
    },
    mainContent: {
        "& .scroll-wrap": {
            height: 647,
        },
        "& .sites-and-projects-wrapper": {
            height: "inherit",
            paddingBottom: 8,
        },
    },
    verticalDivider: {
        "&::before": {
            boxShadow: "0 2px 4px 0 rgba(83, 83, 83, 0.5)",
        },
    },
}));

function Sites(props) {
    const { currentSite, setCurrentSite, sites, sitesLoading, resetSites, account } = props;
    const classes = useStyles();
    const [state, setState] = useState({ isLoading: false });
    const { isLoading } = state;

    const onSiteSelection = (selectedSite) => {
        if ((currentSite && currentSite.id !== selectedSite.id) || !currentSite) {
            setCurrentSite(null);

            setState({ ...state, isLoading: true });

            getSiteById(selectedSite.id, account)
                .then((data) => {
                    if (selectedSite.alertZoneRadius) {
                        data.alertZoneRadius = selectedSite.alertZoneRadius;
                    }
                    setCurrentSite(data);
                    setState({ ...state, isLoading: false });
                })
                .catch(() => {
                    setState({ ...state, isLoading: false });
                });
        } else {
            setCurrentSite(null);
        }
    };

    useEffect(() => {
        return () => {
            resetSites();
        };
    }, []);

    return (
        <div>
            <Container className={classes.sitesPage}>
                <Grid item xs={12} component="div">
                    <div className={clsx(classes.mainContent)}>
                        <Grid component="div" container className="--full-height">
                            <SitesList onSiteSelection={onSiteSelection} initialValues={{ search: null }} />
                            <Grid
                                item
                                xs={8}
                                component="div"
                                className={clsx("verticalDivider", classes.verticalDivider)}
                            >
                                <ErrorBoundary>
                                    <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                                        {isLoading && <Loader />}
                                        {!!currentSite && !_.isEmpty(sites) && (
                                            <SiteDetails onSiteSelection={onSiteSelection} initialValues={{}} />
                                        )}
                                    </div>
                                </ErrorBoundary>
                                {_.isEmpty(sites) && !sitesLoading && (
                                    <React.Fragment>
                                        <br />
                                        <br />
                                        <br />
                                        <h1 className="--text-center">No data to show</h1>
                                    </React.Fragment>
                                )}
                                {!_.isEmpty(sites) && !sitesLoading && !currentSite && (
                                    <React.Fragment>
                                        <br />
                                        <br />
                                        <br />
                                        <h1 className="--text-center">No site selected</h1>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Container>
        </div>
    );
}

Sites.propTypes = {
    currentSite: PropTypes.object,
    setCurrentSite: PropTypes.func.isRequired,
    sites: PropTypes.array.isRequired,
    sitesLoading: PropTypes.bool.isRequired,
    resetSites: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
            sites: state.sites.sites,
            sitesError: state.sites.sitesError,
            currentSite: state.sites.currentSite,
            sitesLoading: state.sites.sitesLoading,
        }),
        (dispatch) => ({
            setCurrentSite: (site) => {
                dispatch(SitesActions.setCurrentSite(site));
            },
            resetSites: (payload) => {
                dispatch(SitesActions.resetSites(payload));
            },
        }),
    )(Sites),
);
