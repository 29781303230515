import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import Box from "@material-ui/core/Box";
import { ActionLink, SimpleLink } from "../core/link";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SearchAndFilters, { FILTERS_ROLE_ID } from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import { reduxForm } from "redux-form";
import AppModal from "../core/modal";
import AllTeamMessageForm, { ALL_TEAM_MESSAGE_FORM } from "./allTeamMessageForm";
import { IS_ADMIN_USER, USER_ROLE_I80_CUSTOMER } from "../../constants/maps";
import { selectCompanyId } from "../../selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const MyTeamFilters = (props) => {
    const { form, updateListByFilters, handleTeamDialogToggle } = props;
    const classes = useStyles();
    const wrapper = PAGE_GLOBAL_STYLES();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();

    const companyId = useSelector(selectCompanyId);
    const [openMessageModal, setOpenMessageModal] = useState(false);

    const handleAddUserFormToggle = () => {
        handleTeamDialogToggle(true);
    };

    const filtersList = IS_ADMIN_USER(companyId)
        ? [
              {
                  title: "I80",
                  filterKey: FILTERS_ROLE_ID,
                  filters: [
                      {
                          label: "I80 Customer",
                          id: USER_ROLE_I80_CUSTOMER,
                      },
                  ],
              },
          ]
        : [];

    return (
        <Container className={wrapper.globalPageStyles}>
            <div className={pageActionsStyles.pageActions}>
                <Box className={classes.headerBox}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ActionLink>
                                <AddCircleOutlineIcon />
                                <SimpleLink onClick={handleAddUserFormToggle}>Create new team member</SimpleLink>
                            </ActionLink>
                        </Grid>
                        {!props.isRestrictedCustomer && (
                            <Grid item>
                                <ActionLink>
                                    <ChatBubbleOutlineIcon />
                                    <SimpleLink onClick={() => setOpenMessageModal(true)}>Message Team</SimpleLink>
                                </ActionLink>
                            </Grid>
                        )}
                    </Grid>
                    <SearchAndFilters
                        form={form}
                        updateList={updateListByFilters}
                        filtersList={filtersList}
                        rememberFilters
                    />
                </Box>
            </div>
            {openMessageModal && (
                <AppModal
                    isOpen={openMessageModal}
                    si
                    form={ALL_TEAM_MESSAGE_FORM}
                    modalStyles={{ width: 800 }}
                    closeModal={() => setOpenMessageModal(false)}
                >
                    <AllTeamMessageForm closeModal={() => setOpenMessageModal(false)} />
                </AppModal>
            )}
        </Container>
    );
};

MyTeamFilters.propTypes = {
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    handleTeamDialogToggle: PropTypes.func.isRequired,
};

const USERS_FILTERS_FORM = "myTeamFilter";

export default withRouter(
    reduxForm({
        form: USERS_FILTERS_FORM,
    })(MyTeamFilters),
);
