import { SET_STATS, TICKETS_STATS_FAILED, TICKETS_STATS_LOADED, TICKETS_STATS_LOADING } from "../events/stats";
import { TICKET_OPENED_NOTIFICATION } from "../events/tickets";
import { calculateStatsWhenOpenTicket } from "../helpers/stats";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    activeJobsCount: 0,
    pendingJobsCount: 0,
    allTicketsCount: 0,
    ticketsStats: {
        statsByStatus: {},
        quantityStats: {},
        statsByUOM: {},
        ticketsStatsLoading: false,
    },
};

export const statsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATS:
            return {
                ...state,
                activeJobsCount: action.payload.activeJobsCount,
                pendingJobsCount: action.payload.pendingJobsCount,
                jobRequestsCount: action.payload.jobRequestsCount,
                allTicketsCount: action.payload.allTicketsCount,
            };
        case TICKETS_STATS_LOADING:
            return {
                ...state,
                ticketsStats: {
                    ...state.ticketsStats,
                    ticketsStatsLoading: action.payload,
                },
            };
        case TICKETS_STATS_LOADED:
            return {
                ...state,
                ticketsStats: {
                    statsByStatus: action.payload.statsByStatus || state.ticketsStats.statsByStatus,
                    quantityStats: action.payload.quantityStats || state.ticketsStats.quantityStats,
                    statsByUOM: action.payload.statsByUOM || state.ticketsStats.statsByUOM,
                    ticketsStatsLoading: false,
                },
            };
        case TICKETS_STATS_FAILED:
            return {
                ...state,
                ticketsStats: {
                    ...state.ticketsStats,
                    ticketsStatsLoading: false,
                },
            };
        case TICKET_OPENED_NOTIFICATION:
            return {
                ...state,
                allTicketsCount: state.allTicketsCount + 1,
                ticketsStats: calculateStatsWhenOpenTicket(state.ticketsStats, action.payload.ticket),
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
