import moment from "moment";

export const SITE_STATS_DAILY_TICKET_COUNT_LABEL = "Daily tickets count:";
export const SITE_STATS_DAILY_TICKET_COUNT = (count = 0) => {
    const date = moment();
    const month = date.format("MMMM");
    const day = date.format("D");
    const year = date.format("YYYY");

    return `${count} Tickets on ${month} ${day}, ${year}`;
};
