import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { TimeRange, TimeRangeEvent, TimeSeries } from "pondjs";
import { ChartContainer, ChartRow, Charts, EventChart, Resizable } from "react-timeseries-charts";
import {
    TRUCK_ACTIVITY_ALL_EVENTS,
    TRUCK_ACTIVITY_ALL_EVENTS_COLOR,
    TRUCK_ACTIVITY_EVENT_DO,
    TRUCK_ACTIVITY_EVENT_DO_COLOR,
    TRUCK_ACTIVITY_EVENT_PU,
    TRUCK_ACTIVITY_EVENT_PU_COLOR,
    TRUCK_ACTIVITY_NA,
    TRUCK_ACTIVITY_NA_EVENT_COLOR,
} from "../../constants/maps";
import { convertTimeToJsFormat } from "../../helpers/jobOrders";
import Grid from "@material-ui/core/Grid";
import { Field } from "redux-form";
import CustomCheckbox from "../core/form/customCheckbox";
import {
    setTrucksActivityActiveTruck,
    setTrucksActivityMapData,
    setTrucksActivityMapTimestamp,
} from "../../actions/trucksAcitivityActions";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ActivityLegend from "./activityLegend";
import { makeStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Box from "@material-ui/core/Box";
import {
    handleChartResetZoom,
    handleChartZoomIn,
    handleChartZoomOut,
    useTooltipChartPosition,
} from "../../helpers/chartHelpers/chartTimeRangeHandlers";

export const TIME_TICK_COUNT = 5;
export const MIN_DURATION_TIME = 3600000;
export const SAME_TIME_OFFSET = 50;

const useStyles = makeStyles((theme) => ({
    allEventsText: {
        marginBottom: 10,
        fontWeight: 500,
    },
    xAxisStyles: {
        "& svg text": {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            fill: "#000 !important",
        },
        "& svg line": {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            stroke: "#000 !important",
        },
    },
    buttonLegendContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    zoomIcon: {
        fontSize: 20,
    },
    resetIconWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 20,
        fontSize: 14,
        fontWeight: 500,
    },
    truckNames: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    truckNamesWrapper: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));

const outageEventStyleFunc = (event, state) => {
    let color;

    switch (event.get("type")) {
        case TRUCK_ACTIVITY_EVENT_PU:
            color = TRUCK_ACTIVITY_EVENT_PU_COLOR;
            break;
        case TRUCK_ACTIVITY_EVENT_DO:
            color = TRUCK_ACTIVITY_EVENT_DO_COLOR;
            break;
        case TRUCK_ACTIVITY_ALL_EVENTS:
            color = TRUCK_ACTIVITY_ALL_EVENTS_COLOR;
            break;
        case TRUCK_ACTIVITY_NA:
            color = TRUCK_ACTIVITY_NA_EVENT_COLOR;
            break;
        default:
            color = TRUCK_ACTIVITY_NA_EVENT_COLOR;
    }
    const style =
        state === "hover"
            ? {
                  fill: color,
                  cursor: "pointer",
              }
            : { fill: color };

    if (event.get("type") === TRUCK_ACTIVITY_NA) {
        style.pointerEvents = "none";
    }
    if (event.get("type") === TRUCK_ACTIVITY_ALL_EVENTS) {
        style.opacity = 0.2;
        style.pointerEvents = "none";
    }

    return style;
};

const TruckAllActivitiesChart = (props) => {
    const {
        setMapTimestamp,
        account,
        jobOrder,
        trucksActivity,
        setActiveTruckMapData,
        setActiveTruckData,
        setActiveTruck,
        loadTruckMapData,
        trucks,
    } = props;
    const [state, setState] = useState({
        allDOEvents: [],
        allPUEvents: [],
        trucksTimeSeriesData: [],
    });

    const [minMaxTimes, setMinMaxTimes] = useState({});
    const [timeRange, setTimeRange] = useState();
    const classes = useStyles();

    const [showTip, currentTitle, handleHoverEvent, getBoundingClientRect, onMouseMove, handleTooltipClose] =
        useTooltipChartPosition();

    useEffect(() => {
        return () => {
            setMapTimestamp(null);
            setActiveTruckMapData([]);
            setActiveTruck(null);
        };
    }, []);

    useEffect(() => {
        if (!_.isEmpty(trucks)) {
            const squashedData = trucks.map((truck) => {
                const truckActData = trucksActivity.find((truckActData) => truckActData.truckId === truck.id);
                if (truckActData) {
                    return {
                        ...truck,
                        ...truckActData,
                    };
                }

                return truck;
            });

            const allEvents = [];
            trucksActivity.forEach((item) => {
                allEvents.push(...item.events);
            });
            if (jobOrder && jobOrder.startDate && !_.isEmpty(allEvents)) {
                const minMaxDates = findMinMaxTimesFromEvents(allEvents, account.timezone);
                const jobOrderStartDate = convertTimeToJsFormat(jobOrder.startDate, account.timezone);
                const [minEventsDate, maxEventsDate] = [
                    convertTimeToJsFormat(minMaxDates.minTime, account.timezone),
                    convertTimeToJsFormat(minMaxDates.maxTime, account.timezone),
                ];
                const minDate = minEventsDate > jobOrderStartDate ? jobOrderStartDate : minEventsDate;

                setMinMaxTimes({
                    minTime: minDate,
                    maxTime: maxEventsDate,
                });
                setTimeRange(new TimeRange(minDate, maxEventsDate));
            }

            setState({
                ...state,
                allEvents: allEvents.map((event) => ({
                    ...event,
                    type: event.additionalInfo.isDoEvent
                        ? TRUCK_ACTIVITY_EVENT_DO
                        : event.additionalInfo.isPuEvent
                        ? TRUCK_ACTIVITY_EVENT_PU
                        : TRUCK_ACTIVITY_NA,
                })),
                allDOEvents: allEvents
                    .filter((event) => event.additionalInfo.isDoEvent)
                    .map((event) => ({
                        ...event,
                        type: TRUCK_ACTIVITY_ALL_EVENTS,
                    })),
                allPUEvents: allEvents
                    .filter((event) => event.additionalInfo.isPuEvent)
                    .map((event) => ({
                        ...event,
                        type: TRUCK_ACTIVITY_ALL_EVENTS,
                    })),
                trucksTimeSeriesData: squashedData.map((truckData) => ({
                    timeSeries: getTruckTimeSeries(truckData),
                    ...truckData,
                })),
            });
        }
    }, [trucksActivity]);

    const findMinMaxTimesFromEvents = (events) => {
        const minTime = _.min(events.map((item) => item.start));
        const maxTime = _.max(events.map((item) => item.end));

        return {
            minTime,
            maxTime,
        };
    };

    const generateTimeSeriesFromEvents = (events, truckInfo) => {
        if (!_.isEmpty(events)) {
            const sortedEvents = events.sort((a, b) => {
                return (
                    convertTimeToJsFormat(a.start || a.end, account.timezone) -
                    convertTimeToJsFormat(b.start || b.end, account.timezone)
                );
            });
            const timeRangeEvents = sortedEvents.map(({ start, end, additionalInfo, type, ...data }) => {
                const extendedData = {
                    type: type,
                };
                const startDate = convertTimeToJsFormat(start, account.timezone);
                const endDate = convertTimeToJsFormat(end ? end : start, account.timezone);
                const range = new TimeRange(startDate, endDate);

                if (!_.isEmpty(additionalInfo) && !extendedData.type) {
                    extendedData.type = additionalInfo.isPuEvent
                        ? TRUCK_ACTIVITY_EVENT_PU
                        : additionalInfo.isDoEvent
                        ? TRUCK_ACTIVITY_EVENT_DO
                        : TRUCK_ACTIVITY_NA;
                }
                if (
                    (truckInfo && extendedData.type === TRUCK_ACTIVITY_EVENT_PU) ||
                    extendedData.type === TRUCK_ACTIVITY_EVENT_DO
                ) {
                    extendedData.title = `Truck ${truckInfo.name} in ${additionalInfo && additionalInfo.siteName}`;
                }

                return new TimeRangeEvent(range, {
                    ...data,
                    ...extendedData,
                });
            });

            return new TimeSeries({
                name: "timeSeries",
                events: timeRangeEvents,
            });
        }

        return new TimeSeries({
            name: "empty",
            events: [new TimeRangeEvent(new TimeRange(minMaxTimes.minTime, minMaxTimes.minTime), {})],
        });
    };

    const getTruckTimeSeries = (truckData) => {
        if (_.isEmpty(truckData.events)) {
            return new TimeSeries({
                name: "empty",
                start: minMaxTimes.minTime,
                end: minMaxTimes.maxTime,
                events: [],
            });
        }
        // Used to show grey line between events
        const eventMinMaxDate = findMinMaxTimesFromEvents(truckData.events);
        const emptyEvent = {
            type: TRUCK_ACTIVITY_NA,
            start: eventMinMaxDate.minTime,
            end: eventMinMaxDate.maxTime,
        };

        const externalTruckData = trucks.find((truck) => truck.id === truckData.truckId);

        return generateTimeSeriesFromEvents([emptyEvent, ...truckData.events], externalTruckData);
    };

    const handleResetZoom = () => {
        handleChartResetZoom(timeRange, setTimeRange, minMaxTimes);
    };

    const handleZoomIn = () => {
        handleChartZoomIn(timeRange, setTimeRange);
    };

    const handleZoomOut = () => {
        handleChartZoomOut(timeRange, setTimeRange, minMaxTimes);
    };

    const handleTimeRangeChange = (newTimeRange) => {
        // @todo workaround before  https://github.com/esnet/react-timeseries-charts/issues/303 will be merged
        if (Math.abs(newTimeRange.duration() - timeRange.duration()) < SAME_TIME_OFFSET) {
            setTimeRange(newTimeRange);
        }
    };

    const loadActiveTruckDate = (truck) => {
        loadTruckMapData(truck.id);
        setActiveTruckData(truck);
    };

    return (
        <Grid container justify={"space-between"}>
            <Grid item spacing={0} xs={2} className={classes.truckNamesWrapper}>
                {!_.isEmpty(state.trucksTimeSeriesData) && (
                    <div className={classes.truckNames}>
                        {state.trucksTimeSeriesData.map((truck, index) => {
                            const { name, id, events } = truck;

                            const truckToolTip = _.isEmpty(events) ? `Truck ${name} doesn't have any events` : null;

                            return (
                                <Field
                                    name="activeTruck"
                                    key={index}
                                    style={{
                                        marginBottom: 10,
                                    }}
                                    type="radio"
                                    onChange={() => loadActiveTruckDate(truck)}
                                    value={`truck-${id}`}
                                    label={name}
                                    component={CustomCheckbox}
                                    tooltipTitle={truckToolTip}
                                />
                            );
                        })}
                        {timeRange && (
                            <>
                                <div className={classes.allEventsText}>
                                    <span>Drop Off Site Activity</span>
                                </div>
                                <div className={classes.allEventsText}>
                                    <span>Pick Up Site Activity</span>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Grid>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    onMouseMove={onMouseMove}
                    PopperProps={{
                        anchorEl: {
                            clientHeight: 0,
                            clientWidth: 0,
                            getBoundingClientRect: getBoundingClientRect,
                        },
                    }}
                    onClose={handleTooltipClose}
                    open={showTip}
                    placement="bottom"
                    disableFocusListener
                    disableTouchListener
                    title={currentTitle}
                >
                    <Grid item xs={8} className={classes.xAxisStyles}>
                        {timeRange && !_.isEmpty(state.trucksTimeSeriesData) && (
                            <>
                                <Resizable>
                                    <ChartContainer
                                        timeRange={timeRange}
                                        minTime={minMaxTimes.minTime}
                                        maxTime={minMaxTimes.maxTime}
                                        enablePanZoom={true}
                                        paddingLeft={25}
                                        timeAxisTickCount={10}
                                        onTimeRangeChanged={handleTimeRangeChange}
                                        paddingRight={25}
                                    >
                                        {state.trucksTimeSeriesData.map((truckData) => {
                                            const { truckId } = truckData;

                                            return (
                                                <ChartRow height={30} key={truckId}>
                                                    <Charts>
                                                        <EventChart
                                                            onMouseLeave={(event) => handleHoverEvent(event, true)}
                                                            onMouseOver={(event) => handleHoverEvent(event)}
                                                            series={truckData.timeSeries}
                                                            size={45}
                                                            style={outageEventStyleFunc}
                                                        />
                                                    </Charts>
                                                </ChartRow>
                                            );
                                        })}
                                        <ChartRow height={30} key={TRUCK_ACTIVITY_EVENT_DO}>
                                            <Charts>
                                                <EventChart
                                                    series={generateTimeSeriesFromEvents(state.allDOEvents)}
                                                    size={45}
                                                    hoverMarkerWidth={0}
                                                    style={outageEventStyleFunc}
                                                />
                                            </Charts>
                                        </ChartRow>
                                        <ChartRow height={30} key={TRUCK_ACTIVITY_EVENT_PU}>
                                            <Charts>
                                                <EventChart
                                                    series={generateTimeSeriesFromEvents(state.allPUEvents)}
                                                    size={45}
                                                    hoverMarkerWidth={0}
                                                    style={outageEventStyleFunc}
                                                />
                                            </Charts>
                                        </ChartRow>
                                    </ChartContainer>
                                </Resizable>
                                <Box width={1} display="flex" justifyContent="center">
                                    <Tooltip title="Reset zoom">
                                        <IconButton aria-label="reset-zoom" onClick={handleResetZoom}>
                                            <RefreshIcon height="100%" className={clsx(classes.zoomIcon)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Zoom In">
                                        <IconButton aria-label="add-zoom" onClick={handleZoomIn}>
                                            <AddIcon className={clsx(classes.zoomIcon)} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Zoom out">
                                        <IconButton aria-label="remove-zoom" onClick={handleZoomOut}>
                                            <RemoveIcon className={clsx(classes.zoomIcon)} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Tooltip>
            </ClickAwayListener>
            <Grid item xs={2}>
                {!_.isEmpty(state.allEvents) && <ActivityLegend trucksActivity={state.allEvents} />}
            </Grid>
        </Grid>
    );
};

TruckAllActivitiesChart.propTypes = {
    trucksActivity: PropTypes.array,
    trucks: PropTypes.array.isRequired,
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    setMapTimestamp: PropTypes.func.isRequired,
    loadTruckMapData: PropTypes.func.isRequired,
    setActiveTruck: PropTypes.func.isRequired,
    setActiveTruckData: PropTypes.func.isRequired,
    setActiveTruckMapData: PropTypes.func.isRequired,
    activeTruckMapData: PropTypes.array,
    activeTruckId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    notificationSelectedTruckId: PropTypes.number,
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
            activeTruckId: state.trucksActivity.activeTruckId,
            activeTruckMapData: state.trucksActivity.activeTruckMapData,
            notificationSelectedTruckId: state.trucksActivity.notificationSelectedTruckId,
        }),
        (dispatch) => ({
            setMapTimestamp: (time) => dispatch(setTrucksActivityMapTimestamp(time)),
            setActiveTruckMapData: (mapData) => dispatch(setTrucksActivityMapData(mapData)),
            setActiveTruck: (truckId) => dispatch(setTrucksActivityActiveTruck(truckId)),
        }),
    )(TruckAllActivitiesChart),
);
