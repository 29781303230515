import * as api from "../dataServers/companyAdmins";
import { getNextPageForRequestFromZeroIndex, handleError } from "../helpers/global";
import { actions, selectCompanyAdmins } from "../reducers/companyAdminsReducer";
import { selectCompanyId } from "../selectors";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";

export const getCompanyAdminsTableData = (concat) => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);
    const companyAdmins = selectCompanyAdmins(state);
    const nextPage = getNextPageForRequestFromZeroIndex(companyAdmins);

    const params = {
        page: concat ? nextPage : 0,
        perPage: GLOBAL_COUNT_TO_LOAD,
    };

    dispatch(actions.companyAdminsRequest());
    try {
        const response = await api.fetchCompanyAdmins(companyId, params);
        dispatch(actions.companyAdminsSuccess({ response, concat }));
    } catch (e) {
        dispatch(actions.companyAdminsFail(handleError(e)));
    }
};
