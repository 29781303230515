import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import SuccessNotification from "../core/successNotification";
import AppModal from "../core/modal";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm, reset } from "redux-form";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import ErrorNotification from "../core/notification";
import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import ConfirmationModal from "../core/confirmationModal";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import DateTimePicker, { INPUT_VARIANT_OUTLINED } from "../core/form/dateTimePicker";
import { suspendTruck, unsuspendTruck } from "../../dataServers/trucks";
import Loader from "../core/loader";

const SetNotAvailableTruckDialog = (props) => {
    const {
        isSetAvailable,
        dialogOpen,
        handleClose,
        truckForEdit: { id: truckId },
        formValues: { reason, startDate, endDate },
        handleSubmit,
        dispatch,
        onSubmit,
        form,
    } = props;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState();
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);

    const submitValues = () => {
        let data = {
            reason: reason ? reason : null,
        };
        if (startDate) data.startDate = moment(startDate);
        if (endDate) data.endDate = moment(endDate);

        setLoading(true);

        return suspendTruck(truckId, data)
            .then(() => {
                setIsHandledSuccessfully(true);
                handleClose();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            })
            .finally(() => {
                onSubmit();
            });
    };

    const handleConfirm = () => {
        if (isSetAvailable) {
            return unsuspendTruck(truckId)
                .then(() => {
                    setIsHandledSuccessfully(true);
                    handleClose();
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError(PROCESS_SERVER_ERROR(error));
                })
                .finally(() => {
                    onSubmit();
                });
        }

        setConfirmationMessage("Truck will be removed " + "from any future jobs");
    };

    return (
        <>
            <AppModal
                isOpen={dialogOpen}
                form={SET_NOT_AVAILABLE_TRUCK_FORM}
                closeModal={() => {
                    handleClose();
                    dispatch(reset(form));
                }}
            >
                <form
                    noValidate={true}
                    autoComplete="off"
                    onSubmit={handleSubmit(handleConfirm)}
                    className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                >
                    {isLoading && <Loader />}
                    {isSetAvailable ? (
                        <h2 className="title">Do you want to make truck available?</h2>
                    ) : (
                        <>
                            <h2 className="title">MAKE TRUCK NOT AVAILABLE</h2>
                            <Field
                                name="startDate"
                                placeholder="Start Date"
                                className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                                generalInputVariant={INPUT_VARIANT_OUTLINED}
                                disablePast={true}
                                component={DateTimePicker}
                            />
                            <br />
                            <Field
                                name="endDate"
                                placeholder="End Date"
                                disablePast={true}
                                generalInputVariant={INPUT_VARIANT_OUTLINED}
                                className={clsx(AUTOCOMPLETE_FORM_VIEW_CLASS)}
                                component={DateTimePicker}
                            />
                            <br />
                            <Field
                                type="text"
                                name="reason"
                                label="Reason"
                                validate={[Validation.required]}
                                multiline={true}
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                component={TextInputComponent}
                            />
                        </>
                    )}
                    <br />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton
                            onClick={() => {
                                handleClose();
                                dispatch(reset(form));
                            }}
                        >
                            {isSetAvailable ? "No" : "Cancel"}
                        </SecondaryButton>
                        <PrimaryButton type="submit">{isSetAvailable ? "Yes" : "Save"}</PrimaryButton>
                    </ButtonsGroup>
                    {isHandledSuccessfully && (
                        <SuccessNotification
                            message="Success"
                            config={{ onClose: () => setIsHandledSuccessfully(false) }}
                        />
                    )}
                    {error && <ErrorNotification error={error} config={{ onClose: () => setError(false) }} />}
                </form>
                <ConfirmationModal
                    isOpen={!!confirmationMessage}
                    question={confirmationMessage}
                    yesHandler={() => submitValues()}
                    noHandler={() => setConfirmationMessage("")}
                />
            </AppModal>
        </>
    );
};

SetNotAvailableTruckDialog.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSetAvailable: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    truckForEdit: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export const SET_NOT_AVAILABLE_TRUCK_FORM = "setNotAvailableTruckForm";

export default withRouter(
    compose(
        reduxForm({
            form: SET_NOT_AVAILABLE_TRUCK_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                formValues: formSelector(state, "id", "reason", "startDate", "endDate"),
            };
        }),
    )(SetNotAvailableTruckDialog),
);
