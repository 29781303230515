import React from "react";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import SearchAndFilters from "../global/searchAndFilters";
import PropTypes from "prop-types";

const CustomerFilters = ({ form, updateListByFilters }) => {
    return <SearchAndFilters form={form} updateList={updateListByFilters} />;
};

CustomerFilters.propTypes = {
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
};

export const CUSTOMERS_FILTERS_FORM = "customersFiltersForm";

export default withRouter(
    reduxForm({
        form: CUSTOMERS_FILTERS_FORM,
    })(CustomerFilters),
);
