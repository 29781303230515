import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { blur, change, Field, focus, formValueSelector, getFormMeta, isDirty } from "redux-form";
import TextInputComponent from "../core/form/textInputComponent";
import Filters from "./filters";
import clsx from "clsx";
import _ from "lodash";
import Loader, { HorizontalLoader } from "../core/loader";
import { JO_STATUS_ACTIVE_ID, JO_STATUS_NOT_STARTED_ID, JO_STATUS_PENDING_ID } from "../../constants/maps";
import { END_DATE_NAME, START_DATE_NAME } from "./datesRange";
import { LOCAL_STORAGE_FILTERS_ID } from "../../helpers/api";
import { connect } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import { SERVER_DATE_FORMAT } from "../../constants/global";
import { IS_ONLY_START_DATA } from "../jobs/jobOrder/jobOrderFiltersForm";
import { usePrevious } from "../../helpers/usePrevious";
import AsyncAutocompleteComponent from "../core/form/asyncAutocompleteComponent";
import { generatePredictiveKeywordParam } from "../../helpers/predictiveSearchHelpers";

const useStyles = makeStyles((theme) => ({
    search: {
        color: theme.palette.secondary.main,
        marginLeft: 5,
        cursor: "pointer",
    },
    jobsSearch: {
        marginRight: 5,
        backgroundColor: "#fff",

        "& input.MuiOutlinedInput-input": {
            padding: "6px 14px",
            backgroundColor: "#fff",
        },
    },
    searchAndFilters: {
        "& .container-loader-wrap": {
            marginLeft: 10,
        },
        "& .container-loader-wrap, .container-loader, .container-loader-wrap .container-loader .sk-circle": {
            width: 20,
            height: 20,
        },
        "& .input-clear": {
            width: 12,
            height: 12,
            cursor: "pointer",
        },
    },
}));

const SearchAndFilters = (props) => {
    const classes = useStyles();
    const {
        filtersList,
        clearAllTitle,
        className,
        search,
        isDirty,
        updateList,
        isLoading,
        shouldListenType,
        formMeta,
        classNames,
        updateFiltersHandler,
        resetToDefaultHandler,
        initialFilters,
        dataLoadedFor,
        filtersLoading,
        form,
        rememberFilters,
        isResetToDefault,
        dispatch,
        initialStatus,
        style,
        placeholder,
        defaultFilter,
        isHaulersFilters,
        showDefaultFilterTip,
        limitWidth,
        chooseOneFilterOptionList,
        withoutSearchRules,
        timeout,
        withoutEmptySearch,
        autoFocusOnSearch,
        isPredictiveSearch,
        loadPredictiveSearchOptions,
        handleOneFilterOptionListChange,
        filtersSectionClassName,
    } = props;

    const [filters, setFilters] = useState();
    const [clearFilters, setClear] = useState(false);
    const [forceSearch, doForceSearch] = useState(false);
    const [userIsTyping, setUserIstyping] = useState(false);
    const searchRef = useRef();
    const prevSearch = usePrevious(search);
    const applyFilters = (filters) => {
        setFilters(filters);
    };

    const clearFiltersHandler = (newFilters) => {
        setFilters(newFilters);
        setClear(true);
    };
    const searchStartHandler = () => {
        if (isPredictiveSearch) {
            setFilters({
                ...filters,
                keywords: generatePredictiveKeywordParam(search),
            });
        }

        if (
            formMeta &&
            formMeta.search &&
            formMeta.search.active &&
            (isDirty || (prevSearch && prevSearch !== "" && search === ""))
        ) {
            if (withoutEmptySearch) {
                const restFilters = { ...filters };
                if (search) restFilters.keywords = search;
                if (prevSearch && prevSearch !== "" && search === "") restFilters.keywords = search;
                !_.isEmpty(restFilters) && setFilters(restFilters);
            } else {
                setFilters({
                    ...filters,
                    keywords: search.trim(),
                });
            }
            search === "" && dispatch(blur(form, "search"));
            updateFiltersHandler && updateFiltersHandler();
        }
        setUserIstyping(false);
        doForceSearch(false);
    };
    const userStartsTyping = (e) => {
        // e.target.localName === 'body' && startSearch(true);
    };
    const processKeyPress = (e) => {
        if (e.key === "Enter") {
            doForceSearch(true);
        }
    };
    const handleClearInput = () => {
        dispatch(change(form, "search", ""));
        dispatch(focus(form, "search"));
    };

    useEffect(() => {
        let timer;
        if (formMeta && formMeta.search && !formMeta.search.active && isDirty) {
            dispatch(focus(form, "search"));
        }

        search && setUserIstyping(true);

        if (isPredictiveSearch) {
            if (search && search.length > 0) {
                const lastSelectedOption = search[search.length - 1];
                const filteredOptions = search.filter((option) => option.__isNew__ === lastSelectedOption.__isNew__);
                if (filteredOptions.length !== search.length) {
                    dispatch(change(form, "search", filteredOptions));
                    return;
                }
                return searchStartHandler();
            }
            if (prevSearch) {
                return searchStartHandler();
            }
        }

        if (withoutSearchRules || search === "" || (search && search.length >= 3)) {
            timer = setTimeout(() => {
                searchStartHandler();
            }, timeout || 1000);
        }

        return () => clearTimeout(timer);
    }, [search]);

    useEffect(() => {
        if (forceSearch) {
            searchStartHandler();
        }
    }, [forceSearch]);

    useEffect(() => {
        if (updateList && filters) {
            const keys = Object.keys(filters);
            const newFilters = {};

            keys &&
                keys.forEach((key) => {
                    if (!_.isObject(filters[key])) {
                        newFilters[key] = filters[key];
                    }
                });

            updateList(newFilters, clearFilters);
            setClear(false);
        }
    }, [filters]);

    useEffect(() => {
        shouldListenType && document.addEventListener("keyup", userStartsTyping);

        document.addEventListener("keypress", processKeyPress);

        return () => {
            shouldListenType && document.removeEventListener("keyup", userStartsTyping);
        };
    }, []);

    return (
        <Grid
            container
            component="div"
            alignItems={"center"}
            justify={"flex-end"}
            wrap={"nowrap"}
            style={style}
            className={clsx(className, classes.searchAndFilters, classNames)}
        >
            <Field
                type="text"
                name="search"
                autoFocus={autoFocusOnSearch}
                fullWidth={true}
                staticEndAdornment={false}
                withRef
                id={`${form}Search`}
                ref={searchRef}
                placeholder={placeholder ? placeholder : "Type to search..."}
                isClearable={true}
                needShowEndAdornment={isLoading || !!search || userIsTyping}
                endAdornment={
                    isLoading || userIsTyping ? (
                        <HorizontalLoader />
                    ) : (
                        search && <ClearIcon className="input-clear" onClick={handleClearInput} />
                    )
                }
                className={clsx(classes.jobsSearch, "search-field", placeholder && "custom-placeholder", "form-view")}
                component={isPredictiveSearch ? AsyncAutocompleteComponent : TextInputComponent}
                isPredictiveSearch={isPredictiveSearch}
                isMulti={isPredictiveSearch}
                loadOptions={loadPredictiveSearchOptions}
            />
            {filtersLoading ? (
                <div className="container-loader-wrap">
                    <Loader />
                </div>
            ) : (
                <Filters
                    applyFilter={applyFilters}
                    form={form}
                    showDefaultFilterTip={showDefaultFilterTip}
                    rememberFilters={rememberFilters}
                    dataLoadedFor={dataLoadedFor}
                    initialStatus={initialStatus}
                    resetToDefaultHandler={resetToDefaultHandler}
                    isResetToDefault={isResetToDefault}
                    isHaulersFilters={isHaulersFilters}
                    filtersLoading={filtersLoading}
                    clearAllTitle={clearAllTitle}
                    initialFilters={initialFilters}
                    defaultFilter={defaultFilter}
                    updateFiltersHandler={updateFiltersHandler}
                    clearFiltersHandler={clearFiltersHandler}
                    filters={filtersList}
                    chooseOneFilterOptionList={chooseOneFilterOptionList}
                    handleOneFilterOptionListChange={handleOneFilterOptionListChange}
                    limitWidth={limitWidth}
                    search={search}
                    filtersSectionClassName={filtersSectionClassName}
                />
            )}
        </Grid>
    );
};

SearchAndFilters.propTypes = {
    updateList: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    updateFiltersHandler: PropTypes.func,
    initialStatus: PropTypes.object,
    clearAllTitle: PropTypes.string,
    dataLoadedFor: PropTypes.string,
    className: PropTypes.string,
    filtersSectionClassName: PropTypes.string,
    search: PropTypes.string,
    appliedFilters: PropTypes.object,
    style: PropTypes.object,
    formMeta: PropTypes.object,
    placeholder: PropTypes.string,
    filtersList: PropTypes.array.isRequired,
    resetToDefaultHandler: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    filtersLoading: PropTypes.bool.isRequired,
    isResetToDefault: PropTypes.bool.isRequired,
    initialFilters: PropTypes.object,
    defaultFilter: PropTypes.object,
    form: PropTypes.string,
    classNames: PropTypes.string,
    shouldListenType: PropTypes.bool.isRequired,
    isHaulersFilters: PropTypes.bool.isRequired,
    showDefaultFilterTip: PropTypes.bool,
    limitWidth: PropTypes.bool,
    chooseOneFilterOptionList: PropTypes.array,
    withoutSearchRules: PropTypes.bool,
    timeout: PropTypes.number,
    withoutEmptySearch: PropTypes.bool,
    rememberFilters: PropTypes.bool.isRequired, // if pass it into component user applied filters will be saved in browser local storage
    autoFocusOnSearch: PropTypes.bool.isRequired,
    loadPredictiveSearchOptions: PropTypes.func,
    isPredictiveSearch: PropTypes.bool,
};

SearchAndFilters.defaultProps = {
    size: 8,
    isLoading: false,
    filtersLoading: false,
    rememberFilters: false,
    isResetToDefault: false,
    shouldListenType: false,
    isHaulersFilters: false,
    showDefaultFilterTip: false,
    filtersList: [],
    style: {},
    search: "",
    autoFocusOnSearch: true,
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            formMeta: getFormMeta(props.form)(state),
            isDirty: isDirty(props.form)(state),
            search: formSelector(state, "search"),
        };
    })(SearchAndFilters),
);

// frontend only
export const DUPLICATE_TICKET_OPTION_ID = 1;
export const UNIQUE_TICKET_OPTION_ID = 2;

export const FILTERS_STATUS_ID = "status";
export const FILTERS_DUPLICATE_ID = "duplicates";
export const FILTERS_SIGNATURE_ID = "signed";
export const FILTERS_JOB_IS_TEMPLATED = "templated";
export const FILTERS_JOB_REQUESTS = "jobRequests";
export const FILTERS_JOB_DRAFTS = "draft";
export const FILTERS_UOM_ID = "uom";
export const FILTERS_TICKET_SOURCE_ID = "source";
export const FILTERS_REGIONS_ID = "regions";
export const FILTERS_TRUCK_TYPE_ID = "truckType";
export const FILTERS_MY_FLEET_TRUCK_TYPE_ID = "type";
export const FILTERS_SITES_TYPE_ID = "accessType";
export const FILTERS_COMPANIES_IDS_ID = "filtersIds";
export const FILTERS_LIST_TYPE_ID = "fleetType"; // Granted or My Fleet
export const FILTERS_LIST_TRUCK_AVAILABILITY = "availability";
export const FILTERS_ROLE_ID = "role";
export const FILTERS_PARENT_COMPANY_ID = "parentCompanies";
export const GET_USER_INITIAL_FILTERS = (form) => {
    const filtersData = localStorage.getItem(LOCAL_STORAGE_FILTERS_ID);
    const appliedFilters = filtersData ? JSON.parse(filtersData) : {};
    const formAppliedFilters = appliedFilters[form];
    const initialValues = {
        [START_DATE_NAME]: moment().format(SERVER_DATE_FORMAT),
        search: null,
    };

    if (!IS_ONLY_START_DATA(form)) {
        initialValues[END_DATE_NAME] = moment().format(SERVER_DATE_FORMAT);
    }

    if (formAppliedFilters) {
        initialValues[START_DATE_NAME] = formAppliedFilters[START_DATE_NAME]
            ? formAppliedFilters[START_DATE_NAME]
            : moment().format(SERVER_DATE_FORMAT);

        initialValues[END_DATE_NAME] = IS_ONLY_START_DATA(form)
            ? null
            : formAppliedFilters[END_DATE_NAME]
            ? formAppliedFilters[END_DATE_NAME]
            : moment().format(SERVER_DATE_FORMAT);
    }

    return initialValues;
};

export const MULTIPLE_COMMA_SEPARATED_SEARCH_PLACEHOLDER = "Separate multiple search terms with a comma...";

export const JOB_BOARD_INITIAL_FILTERS = {
    [FILTERS_STATUS_ID]: {
        [JO_STATUS_PENDING_ID]: true,
        [JO_STATUS_ACTIVE_ID]: true,
        [JO_STATUS_NOT_STARTED_ID]: true,
    },
};
export const LIVE_MAP_TRUCKS_INITIAL_FILTERS = { [FILTERS_STATUS_ID]: {} };
