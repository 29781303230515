import { toDate } from "../../../helpers/date";
import { getEventTypeFromDescription } from "../../helpers";

export const processEvent = ({ event, timezone, dateTimeFormat }) => {
    const eventType = getEventTypeFromDescription(event.description);
    const displayDate = toDate({ date: event.datetime, timezone }).format(dateTimeFormat);

    return {
        ...event,
        type: eventType,
        displayDate: displayDate,
        uniqueKey: `${displayDate}-${eventType}`,
    };
};
