import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import { DEFAULT_DATE_FORMAT } from "../../constants/global";
import { UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_HOUR } from "../../constants/maps";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { ButtonsGroupVertical } from "../core/buttons/buttonsGroup";
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { toDate } from "../../helpers/date";
import { getHoursQty } from "../tickets/components/ticketCard";

const Row = ({ id, item, index, moveCard, openJoForEdit, removeTruckActivity, isHaulers, account }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: "row",
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: "row",
            id,
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const iconStyle = {
        cursor: "pointer",
        width: 17,
        height: 17,
    };
    const opacity = isDragging ? 0 : 1;

    !isHaulers && drag(drop(ref));

    return (
        <div className={clsx("tr", item.isScheduledJob && "template-body")} ref={ref} style={{ opacity }}>
            <div className="td">
                {!isHaulers && (
                    <div>
                        <DragIndicatorIcon />
                    </div>
                )}
            </div>
            <div className="td">
                {item.isScheduledJob && (
                    <>
                        Job # {item.jobOrderId} - Template <br />
                    </>
                )}
                {item.projectName} {!item.isScheduledJob ? `/ ${item.jobOrderId}` : ""}
                <br />
                {!isHaulers
                    ? item.payload
                        ? `${item.payload}`
                        : ""
                    : toDate({
                          date: item.startDate,
                          timezone: account.timezone,
                      }).format(DEFAULT_DATE_FORMAT)}
            </div>
            <div className="td" style={{ textTransform: "capitalize" }}>
                {!isHaulers ? (
                    <div>
                        {item.quantity ? (
                            item.unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                                <span style={{ marginRight: 5 }}>
                                    {`${getHoursQty(item.completedQuantity)} / ${getHoursQty(item.quantity)}`}
                                </span>
                            ) : (
                                <span style={{ marginRight: 5 }}>
                                    {`${item.completedQuantity} / ${item.quantity}`}
                                    {item.unitOfMeasure.name}s
                                </span>
                            )
                        ) : (
                            <>
                                {item.completedQuantity}
                                {` / `}
                                <AllInclusiveIcon
                                    title={"Unlimited"}
                                    style={{ marginLeft: 3 }}
                                    className="infinite-icon"
                                />
                                {item.unitOfMeasure.name}s
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        {item.assignedTrucksCount} / {item.requestedTrucksCount}
                    </div>
                )}
            </div>
            <div className="td" style={{ flex: 0 }}>
                <div>
                    <ButtonsGroupVertical>
                        <div title="Click to edit Job Order">
                            <EditIcon style={iconStyle} onClick={() => openJoForEdit(item.jobOrderId)} />
                        </div>
                        {!item.hasTickets && !isHaulers && (
                            <div title="Remove truck" onClick={() => removeTruckActivity(item)}>
                                <DeleteOutlineIcon style={iconStyle} />
                            </div>
                        )}
                    </ButtonsGroupVertical>
                </div>
            </div>
        </div>
    );
};

Row.propTypes = {
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    moveCard: PropTypes.func.isRequired,
    openJoForEdit: PropTypes.func.isRequired,
    isHaulers: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    removeTruckActivity: PropTypes.func.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
        };
    })(Row),
);
