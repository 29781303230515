import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { change, Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";

import { PrimaryButton } from "../core/buttons/primaryButton";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import AsyncAutoCompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    CREATE_NEW_OPTION_ID,
} from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import ErrorNotification from "../core/notification";
import CloseIcon from "@material-ui/icons/Close";
import CustomCheckbox from "../core/form/customCheckbox";
import Grid from "@material-ui/core/Grid";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import PillSwitch from "../core/form/pillSwitch";
import CustomFields from "../customFields/CustomFields";
import { prepareAdditionalName } from "../../helpers/projects";

import { formatReduxFormErrors } from "../../helpers/errorFormatting";
import {
    COMPANY_TYPE_TRUCKER,
    IS_ADMIN_USER,
    PHOTO_REQUIRED_FALSE_COMPANIES,
    UNIT_OF_MEASURE_BY_ID,
} from "../../constants/maps";
import { getCompanies } from "../../dataServers/companies";
import { Validation } from "../../helpers/validation";
import HiddenInput from "../core/form/hiddenInput";
import { getInspectorUsers } from "../../dataServers/user";
import { FormDataController, getUnitOfMeasureValue } from "../../helpers/global";
import CustomerSelector, { CUSTOMER_FIELD_NAME } from "../companies/customerSelector";
import RegionSelector from "../global/regionSelector";
import { getIfIsRegionRequired } from "../../selectors/index";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import { selectEnableDropOffNotifications } from "../../selectors/user";
import { NOTIFICATIONS_RECIPIENTS_LABEL } from "./constants/strings";

const useStyles = makeStyles(() => ({
    projectForm: {
        minWidth: 450,
        "& .measure-pill": {
            width: "100%",

            "& .pill": {
                width: "100%",
            },
        },
        "& .price-button": {
            margin: "20px 0",
        },
    },
}));

function renderPrices({ fields }) {
    const addEmptyPrice = () => {
        fields.push({});
    };
    const removePrice = (index) => {
        fields.remove(index);
    };

    return (
        <React.Fragment>
            {fields.map((field, index) => (
                <Grid item key={index}>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                type="number"
                                name={`${field}.amount`}
                                label="Agreed Price"
                                fieldNote="Contracted price"
                                startAdornment={<span>$</span>}
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                type="number"
                                name={`${field}.amountNett`}
                                label="Paid Price"
                                fieldNote="Value paid to Haulers"
                                startAdornment={<span>$</span>}
                                needShowEndAdornment={false}
                                validate={[Validation.required]}
                                component={TextInputComponent}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justify={"space-between"} alignItems={"center"}>
                        {Object.entries(UNIT_OF_MEASURE_BY_ID).map((item, index) => {
                            return (
                                <Grid item xs key={index}>
                                    <Field
                                        type="radio"
                                        name={`${field}.unitOfMeasure`}
                                        value={`value-${item[0]}`}
                                        label={item[1]}
                                        validate={[Validation.required]}
                                        className={"measure-pill"}
                                        component={PillSwitch}
                                    />
                                </Grid>
                            );
                        })}
                        <Grid item container xs={1} justify={"center"}>
                            <CloseIcon
                                color={"action"}
                                fontSize={"default"}
                                cursor={"pointer"}
                                onClick={() => removePrice(index)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <ButtonsGroup>
                <SecondaryButton size="small" onClick={addEmptyPrice} className="price-button">
                    Add Price
                </SecondaryButton>
            </ButtonsGroup>
        </React.Fragment>
    );
}

renderPrices.propTypes = {
    fields: PropTypes.object.isRequired,
};

export const GET_INSPECTOR_DATA = (item) => ({
    value: item.id,
    label: `${item.firstName} ${item.lastName}`,
});

function ProjectForm(props) {
    const {
        account,
        handleSave,
        handleSubmit,
        error,
        closeModal,
        dispatch,
        form,
        isRegionRequired,
        formValues: { contract, dotProject, owner },
        grantedAccess,
        iAmOwner,
        enableDropOffNotifications,
    } = props;
    const classes = useStyles();
    const isAdmin = IS_ADMIN_USER(account.company.id);

    const loadInspectorOptions = (inputValue, { params = {}, loadedCount }) => {
        return getInspectorUsers({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => GET_INSPECTOR_DATA(i));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadCompaniesOptions = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({
            order: "-createdAt",
            type: COMPANY_TYPE_TRUCKER,
            keywords: inputValue,
            ...params,
        }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const onSubmit = (values) => {
        const {
            customer,
            owner,
            name,
            externalJobNumber,
            prices,
            haulers,
            contract,
            notes,
            dotProject,
            dotProjectNumber,
            inspectors,
            region,
            externalRef,
            dropOffNotificationsRecipients,
        } = values;

        const data = new FormData();
        const dataController = new FormDataController(data);

        const grantedCompanyData = new FormData();
        const grantedCompanyDataController = new FormDataController(grantedCompanyData);

        data.append("projectOwnerId", owner?.value || null);
        data.append("customerId", customer?.value || null);
        data.append("regionId", region?.value || null);
        name && dataController.appendStringValue("name", name);
        name && grantedCompanyDataController.appendStringValue("name", name);
        dataController.appendStringValue("externalJobNumber", externalJobNumber || "");
        grantedCompanyDataController.appendStringValue("externalRef", externalRef || "");
        dataController.appendStringValue("notes", notes || "");

        const additionalFields = {};
        const fieldsToAdd = values?.additionalFields?.reduce((result, field) => {
            if (field.value instanceof File) {
                data.append(field.name, field.value);
            } else {
                const fieldName = prepareAdditionalName(field.name);
                result[fieldName] = field.value;
            }

            return { ...result };
        }, {});

        if (!_.isEmpty(fieldsToAdd)) additionalFields.fieldsToAdd = fieldsToAdd;

        !_.isEmpty(additionalFields) && data.append("additionalFields", JSON.stringify(additionalFields));

        if (contract) {
            data["prices"] =
                prices &&
                prices.map((price) => ({
                    amount: price.amount,
                    amountNett: price.amountNett,
                    uomId: getUnitOfMeasureValue(price.unitOfMeasure),
                }));
            data["haulers"] = haulers && haulers.map((hauler) => hauler.value || hauler);
        }

        if (contract) {
            prices &&
                data.append(
                    "prices",
                    JSON.stringify(
                        prices.map((price) => ({
                            amount: price.amount,
                            amountNett: price.amountNett,
                            uomId: getUnitOfMeasureValue(price.unitOfMeasure),
                        })),
                    ),
                );
            haulers && data.append("haulers", JSON.stringify(haulers.map((hauler) => hauler.value || hauler)));
        }

        dataController.appendStringValue("dotProjectNumber", dotProjectNumber || "");
        inspectors && data.append("inspectors", JSON.stringify(inspectors.map((i) => i.value)));

        if (PHOTO_REQUIRED_FALSE_COMPANIES.includes(account.company.id)) {
            data.append("photoRequired", false);
        }

        if (enableDropOffNotifications && !_.isEmpty(dropOffNotificationsRecipients)) {
            const recipientsIds = dropOffNotificationsRecipients.map((recipient) => recipient.value);
            data.append("dropOffNotificationsRecipients", JSON.stringify(recipientsIds));
        }

        return handleSave(iAmOwner || isAdmin ? data : grantedCompanyData).catch((errorResponse) => {
            formatReduxFormErrors(errorResponse);
        });
    };

    const resetDotFields = () => {
        dispatch(change(form, "dotProjectNumber", null));
        dispatch(change(form, "inspectors", []));
    };

    const createNewOptionHandler = (data) => {
        dispatch(
            change(form, CUSTOMER_FIELD_NAME, {
                value: data.id,
                label: data.name,
            }),
        );
    };

    const resetCustomer = () => {
        dispatch(change(form, CUSTOMER_FIELD_NAME, []));
    };

    const configForRegionSelector = (owner) => {
        const props = {};
        if (!isAdmin) {
            if (isRegionRequired) {
                props.validate = [Validation.required];
                props.fieldNote = null;
            }
            props.isClearable = !isRegionRequired;
        } else if (owner) {
            const { regionRequired } = owner;
            if (regionRequired) {
                props.validate = [Validation.required];
                props.fieldNote = null;
            }
            props.isClearable = !regionRequired;
        }
        return props;
    };

    return (
        <Grid container className={clsx(classes.projectForm)}>
            <form noValidate={true} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                {(iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <Field
                            type="text"
                            name="name"
                            validate={[Validation.required, Validation.noSpecialSymbols]}
                            label="Project Name"
                            fullWidth={true}
                            needShowEndAdornment={false}
                            component={TextInputComponent}
                        />
                        <br />
                        <br />
                    </React.Fragment>
                )}
                {isAdmin ? (
                    <React.Fragment>
                        <CustomerSelector
                            name="owner"
                            placeholder="Project Owner"
                            iAmOwnerSelector={true}
                            validate={[Validation.required]}
                            onChange={resetCustomer}
                        />
                        <br />
                    </React.Fragment>
                ) : (
                    <Field name="owner" component={HiddenInput} />
                )}
                {(iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <CustomerSelector
                            createNewOption={{
                                label: `Add Customer`,
                                value: CREATE_NEW_OPTION_ID,
                            }}
                            ownerId={owner?.value}
                            isClearable
                            createNewOptionHandler={createNewOptionHandler}
                        />
                        <br />
                    </React.Fragment>
                )}
                <Field
                    type="text"
                    name="externalJobNumber"
                    validate={[Validation.noSpecialSymbols]}
                    label="Project Number"
                    fieldNote="*Optional"
                    needShowEndAdornment={false}
                    component={TextInputComponent}
                    disabled={grantedAccess}
                />
                <br />
                <br />
                {grantedAccess && (
                    <React.Fragment>
                        <Field
                            type="text"
                            name="externalRef"
                            validate={[Validation.noSpecialSymbols]}
                            label="External Reference"
                            fieldNote="*Optional"
                            needShowEndAdornment={false}
                            component={TextInputComponent}
                            disabled={iAmOwner}
                        />
                        <br />
                        <br />
                    </React.Fragment>
                )}
                {account.companyRegions && (iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <RegionSelector
                            isClearable={true}
                            fieldNote="*Optional"
                            companyId={owner && owner.value}
                            {...configForRegionSelector(owner)}
                        />
                        <br />
                    </React.Fragment>
                )}
                {(iAmOwner || isAdmin) && enableDropOffNotifications && (
                    <>
                        <DispatchersFieldSelector
                            name="dropOffNotificationsRecipients"
                            isMulti={true}
                            validate={[Validation.required]}
                            label={NOTIFICATIONS_RECIPIENTS_LABEL}
                        />
                        <br />
                    </>
                )}
                {(iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <Field
                            type="text"
                            multiline={true}
                            name="notes"
                            rows={3}
                            label="Project Notes"
                            fieldNote="Project Notes will appear on the job record and be visible to haulers"
                            component={TextInputComponent}
                        />
                        <br />
                        <br />
                    </React.Fragment>
                )}
                {(iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <CustomFields form={form} isCreateForm /> <br />
                    </React.Fragment>
                )}
                {(iAmOwner || isAdmin) && (
                    <Grid
                        container
                        alignItems={"center"}
                        style={{
                            minHeight: "50px",
                            marginTop: "5px",
                        }}
                    >
                        <Grid item xs={4}>
                            <Field
                                type="checkbox"
                                name="dotProject"
                                label="DOT Project"
                                onChange={resetDotFields}
                                component={CustomCheckbox}
                            />
                        </Grid>
                        {dotProject && (
                            <React.Fragment>
                                <Grid item xs={8}>
                                    <Field
                                        type="text"
                                        name="dotProjectNumber"
                                        label="DOT Project Number"
                                        validate={[Validation.required, Validation.noSpecialSymbols]}
                                        needShowEndAdornment={false}
                                        component={TextInputComponent}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                )}

                {dotProject && (iAmOwner || isAdmin) && (
                    <React.Fragment>
                        <Grid
                            container
                            alignItems={"center"}
                            style={{
                                minHeight: "50px",
                                marginTop: "5px",
                            }}
                        >
                            <Grid item xs={4} />
                            <Grid item xs={8}>
                                <Field
                                    name="inspectors"
                                    isMulti={true}
                                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                    loadOptions={loadInspectorOptions}
                                    placeholder="Inspector"
                                    component={AsyncAutoCompleteComponent}
                                />
                            </Grid>
                        </Grid>
                        <br />
                    </React.Fragment>
                )}

                {isAdmin && (
                    <React.Fragment>
                        <Grid container alignItems={"center"} style={{ minHeight: "50px" }}>
                            <Grid item xs={4}>
                                <Field type="checkbox" name="contract" label="Contract" component={CustomCheckbox} />
                            </Grid>
                            {contract && (
                                <Grid item xs={8}>
                                    <Field
                                        name="haulers"
                                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                        loadOptions={loadCompaniesOptions}
                                        isMulti={true}
                                        placeholder="Haulers"
                                        component={AsyncAutoCompleteComponent}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {contract && (
                            <FieldArray
                                name="prices"
                                classes={classes}
                                rerenderOnEveryChange
                                component={renderPrices}
                            />
                        )}
                    </React.Fragment>
                )}
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {error && <ErrorNotification error={error} />}
        </Grid>
    );
}

ProjectForm.propTypes = {
    formValues: PropTypes.object.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    error: PropTypes.any,
    closeModal: PropTypes.func.isRequired,
};

ProjectForm.defaultProps = {};

export const PROJECT_FORM = "projectForm";

export default compose(
    reduxForm({
        form: PROJECT_FORM,
    }),
    connect((state) => {
        const formSelector = formValueSelector(PROJECT_FORM);

        return {
            account: state.account,
            formValues: formSelector(state, "prices", "contract", "dotProject", "region", "owner", "externalRef"),
            isRegionRequired: getIfIsRegionRequired(state),
            enableDropOffNotifications: selectEnableDropOffNotifications(state),
        };
    }),
)(ProjectForm);
