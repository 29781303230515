import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { getOfflineHaulers } from "../../../actions/settings";
import { selectOfflineHaulers } from "../../../selectors";
import AddOfflineHauler from "./AddOfflineHauler";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflow: "hidden",
    },
}));

const OfflineHaulersList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const offlineHaulers = useSelector(selectOfflineHaulers);

    useEffect(() => {
        dispatch(getOfflineHaulers());
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <AddOfflineHauler />
                    <List>
                        {offlineHaulers &&
                            offlineHaulers.map(({ company }) => (
                                <>
                                    <ListItem key={company.id}>
                                        <ListItemText primary={company.name} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};

export default OfflineHaulersList;
