import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    accordionComponent: {
        width: "100%",

        "& .heading": {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: theme.typography.fontWeightMedium,
        },

        "& .content": {
            fontWeight: theme.typography.fontWeightRegular,
            width: "100%",

            "& iframe": {
                width: "100%",
                border: "none",
            },
        },
    },
}));

function AccordionComponent(props) {
    const classes = useStyles();
    const { accordionItems, styles, containerClass } = props;

    return (
        <div style={styles} className={clsx(classes.accordionComponent, containerClass)}>
            {accordionItems.map((item, index) => {
                return (
                    <ExpansionPanel key={index}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="heading">{item.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography className="content" component="div">
                                {item.content}
                            </Typography>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>{item.actions}</ExpansionPanelActions>
                    </ExpansionPanel>
                );
            })}
        </div>
    );
}

AccordionComponent.propTypes = {
    accordionItems: PropTypes.array.isRequired,
};

AccordionComponent.defaultProps = {
    accordionItems: [],
};

export default withRouter(AccordionComponent);
