import { Field } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCompanyRegions } from "../../dataServers/projects";

const RegionSelector = (props) => {
    const {
        account,
        name,
        disabled,
        onChange,
        fieldNote,
        companyId,
        isClearable,
        isMulti,
        validate
    } = props;

    const loadRegions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getCompanyRegions(companyId ? companyId : account.company.id).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: i.regionName,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <Field
            name={name}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            onChange={onChange}
            isClearable={isClearable}
            isMulti={isMulti}
            loadOptions={loadRegions}
            placeholder="Region"
            disabled={disabled}
            component={AsyncAutocompleteComponent}
            fieldNote={fieldNote}
            validate={validate}
        />
    );
};

RegionSelector.propTypes = {
    account: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    companyId: PropTypes.number,
    isMulti: PropTypes.bool.isRequired,
    fieldNote: PropTypes.string,
    isClearable: PropTypes.bool,
};

RegionSelector.defaultProps = {
    disabled: false,
    isMulti: false,
    isClearable: false,
    name: "region",
    onChange: () => {},
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(RegionSelector),
);
