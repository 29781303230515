import {
    WEBSOCKET_EVENT_TYPE_JO_CLOSED,
    WEBSOCKET_EVENT_TYPE_JO_CREATED,
    WEBSOCKET_EVENT_TYPE_JO_DELETED,
    WEBSOCKET_EVENT_TYPE_JO_REOPENED,
    WEBSOCKET_EVENT_TYPE_JO_UPDATED,
    WEBSOCKET_EVENT_TYPE_REQUEST_CREATED,
    WEBSOCKET_EVENT_TYPE_REQUEST_FILL,
    WEBSOCKET_EVENT_TYPE_TRUCK_PAUSED,
    WEBSOCKET_EVENT_TYPE_TRUCK_RESUMED,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/maps";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_JO_CLOSED]: "Job Order |#{jobOrderId}| was closed successfully",
    [WEBSOCKET_EVENT_TYPE_JO_CREATED]: "Job |#{jobOrderId}| was created",
    [WEBSOCKET_EVENT_TYPE_JO_DELETED]: "Job |#{jobOrderId}| was deleted",
    [WEBSOCKET_EVENT_TYPE_JO_REOPENED]: "Job |#{jobOrderId}| was reopened",
    [WEBSOCKET_EVENT_TYPE_JO_UPDATED]: "Job |#{jobOrderId}| was updated",
    [WEBSOCKET_EVENT_TYPE_TRUCK_PAUSED]: "Truck |{truckDeviceName}| was paused successfully",
    [WEBSOCKET_EVENT_TYPE_TRUCK_RESUMED]: "Truck |{truckDeviceName}| was resumed successfully",
    [WEBSOCKET_EVENT_TYPE_REQUEST_CREATED]:
        "New request (Job |#{jobOrderId}|) created by {firstName} {lastName} ({companyName})",
    [WEBSOCKET_EVENT_TYPE_REQUEST_FILL]:
        "Request (Job |#{jobOrderId}|) filled by {firstName} {lastName} ({companyName})",

    // [WEBSOCKET_EVENT_TYPE_TRUCK_OWNER_SHIFT_REQUESTED]: 'Shift change requested to Job #|{jobOrderId}|/Ext. Ref. |{extRef}|',
    // [WEBSOCKET_EVENT_TYPE_DRIVER_SHIFT_REQUESTED]: 'Shift change requested for |{truckDeviceName}| to Job #|{jobOrderId}|/Ext. Ref. |{extRef}|',
};
export const BUNDLE_EVENT_ERROR_STATUS = "error";

const getNotificationTitle = (event) => {
    let jobOrderId = event.jo_id || event.jobOrderId || (event.jobOrder || {}).id || (event.data || {}).id;
    if (jobOrderId) {
        jobOrderId = `${jobOrderId}&link`;
    }

    return notificationTitles[event.type]
        .replace("{jobOrderId}", jobOrderId || "Unknown")
        .replace("{truckDeviceName}", event.truckDeviceName ? event.truckDeviceName.toUpperCase() : "Unknown")
        .replace("{firstName}", event.firstName)
        .replace("{lastName}", event.lastName)
        .replace("{companyName}", event.companyName);
};

export const processJobEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
