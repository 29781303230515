import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

const ListWaypoint = (props) => {
    const { index, items, itemsCount, fetchMore, loadsCount, defaultCount, lastPosition } = props;
    const subtructValue = items.length > defaultCount ? defaultCount : loadsCount || defaultCount / 2;
    const value = lastPosition || subtructValue;

    return (
        <React.Fragment>
            {index === items.length - value && itemsCount > items.length && (
                <Waypoint onEnter={() => fetchMore && fetchMore(true)} /> 
            )}
        </React.Fragment>
    );
};

ListWaypoint.propTypes = {
    index: PropTypes.number.isRequired,
    itemsCount: PropTypes.number.isRequired,
    defaultCount: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    fetchMore: PropTypes.func.isRequired,
    loadsCount: PropTypes.number,
    lastPosition: PropTypes.number,
};

ListWaypoint.defaultProps = {
    defaultCount: GLOBAL_COUNT_TO_LOAD,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(ListWaypoint),
);
