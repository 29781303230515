import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import DevicesIcon from "@material-ui/icons/Devices";
import { makeStyles } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

import SubNavigation, { subNavigationStyles as parentStyles } from "../global/subNavigation";
import { THEME_ICONS } from "../../styles/icons";
import { ROUTE_MY_COMPANY } from "../../routes/globalRoutes";
import { IS_ADMIN_USER } from "../../constants/maps";
import { GLOBAL_CONTENT_WIDTH } from "../../styles/globalThemeConfig";
import { COMPANIES_TO_SHOW_DEVICES_LOGIC, IS_TEST_OR_LOCAL_ENVS } from "../../constants/global";
import { getNavigationProps } from "../../helpers/ui";

const activeClass = "active";

const useStyles = makeStyles(() => ({
    uiIconTeam: {
        ...THEME_ICONS.account,
        marginRight: 20,
    },
    uiMyFleet: {
        ...THEME_ICONS.generalTruckDark,
        marginRight: 20,
    },
    uiMyFleetActive: {
        ...THEME_ICONS.generalTruckWhite,
        marginRight: 20,
    },
    uiSettings: {
        marginRight: 20,
    },
    trailerIcon: {
        ...THEME_ICONS.trailerIcon,
        marginRight: 20,
    },
    activeTrailerIcon: {
        ...THEME_ICONS.activeTrailerIcon,
        marginRight: 20,
    },
}));

const TruckSubNav = (props) => {
    const classes = useStyles();
    const subNavigationStyles = parentStyles();
    const {
        history: {
            location: { pathname },
        },
    } = props;
    const account = useSelector((state) => state.account);
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const truckIconStyle = pathname === ROUTE_MY_COMPANY.MY_FLEET ? classes.uiMyFleetActive : classes.uiMyFleet;

    return (
        <SubNavigation subMenuWidth={GLOBAL_CONTENT_WIDTH}>
            <Grid
                className={subNavigationStyles.trucksSubMenuResponsive}
                container
                component="div"
                alignItems={"center"}
            >
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            classes.viewSwitcher,
                            pathname === ROUTE_MY_COMPANY.MY_FLEET && activeClass,
                        )}
                    >
                        <Icon
                            className={truckIconStyle}
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.MY_FLEET).tabStyles}
                        />
                        <Link
                            to={getNavigationProps(account.role, ROUTE_MY_COMPANY.MY_FLEET).to}
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.MY_FLEET).tabStyles}
                        >
                            My Fleet
                        </Link>
                    </div>
                </Grid>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            classes.viewSwitcher,
                            pathname === ROUTE_MY_COMPANY.TRAILERS && activeClass,
                        )}
                    >
                        <Icon
                            className={
                                pathname === ROUTE_MY_COMPANY.TRAILERS ? classes.activeTrailerIcon : classes.trailerIcon
                            }
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.TRAILERS).tabStyles}
                        />
                        <Link
                            to={getNavigationProps(account.role, ROUTE_MY_COMPANY.TRAILERS).to}
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.TRAILERS).tabStyles}
                        >
                            Trailers
                        </Link>
                    </div>
                </Grid>
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            pathname.includes(ROUTE_MY_COMPANY.MY_TEAM) && activeClass,
                        )}
                    >
                        <Icon className={classes.uiIconTeam} />
                        <Link to={ROUTE_MY_COMPANY.MY_TEAM}>My Team</Link>
                    </div>
                </Grid>
                {isAdmin && (
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationStyles.subMenuItems,
                                pathname.includes(ROUTE_MY_COMPANY.ALL_COMPANIES) && activeClass,
                            )}
                        >
                            <Icon className={classes.uiIconTeam} />
                            <Link to={ROUTE_MY_COMPANY.ALL_COMPANIES}>Companies</Link>
                        </div>
                    </Grid>
                )}
                {(isAdmin ||
                    COMPANIES_TO_SHOW_DEVICES_LOGIC.includes(account.company.poType) ||
                    account.companyProfile.usesIotDevices) && (
                    <Grid item component="div">
                        <div
                            className={clsx(
                                subNavigationStyles.subMenuItems,
                                pathname.includes(ROUTE_MY_COMPANY.DEVISES) && activeClass,
                            )}
                        >
                            <DevicesIcon
                                className={classes.uiSettings}
                                style={getNavigationProps(account.role, ROUTE_MY_COMPANY.DEVISES).tabStyles}
                            />
                            <Link
                                to={getNavigationProps(account.role, ROUTE_MY_COMPANY.DEVISES).to}
                                style={getNavigationProps(account.role, ROUTE_MY_COMPANY.DEVISES).tabStyles}
                            >
                                IoT Devices
                            </Link>
                        </div>
                    </Grid>
                )}
                <Grid item component="div">
                    <div
                        className={clsx(
                            subNavigationStyles.subMenuItems,
                            pathname.includes(ROUTE_MY_COMPANY.SETTINGS) && activeClass,
                        )}
                    >
                        <SettingsIcon
                            className={classes.uiSettings}
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.SETTINGS).tabStyles}
                        />
                        <Link
                            to={getNavigationProps(account.role, ROUTE_MY_COMPANY.SETTINGS).to}
                            style={getNavigationProps(account.role, ROUTE_MY_COMPANY.SETTINGS).tabStyles}
                        >
                            Settings
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </SubNavigation>
    );
};

TruckSubNav.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(TruckSubNav);
