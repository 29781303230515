import {
    COMPANIES_FAILED,
    COMPANIES_LOADED,
    COMPANIES_LOADING,
    COMPANIES_RESET,
    COMPANY_CLEARED,
    COMPANY_LOADED,
    UPDATE_ACTIVE_COMPANIES_ORDER,
    UPDATE_COMPANY_FOR_MANAGING_HAULERS,
    UPDATE_COMPANY_FOR_UPDATING_ADMINS,
    UPDATE_HAS_SUB_COMPANIES,
} from "../events/companies";
import { LOGOUT_USER, UPDATE_ACTIVE_IOT_ORDER } from "../events/globalEvents";

const initialState = {
    companies: [],
    companiesLoading: false,
    companiesError: null,
    companiesCount: 0,
    companyForEdit: null,
    activeCompaniesOrder: "id",
    activeOrderIotParam: "deviceId",
    companyForManagingHaulers: null,
    companyForUpdatingAdmins: null,
    hasSubCompanies: false,
};

export const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANIES_LOADING:
            return {
                ...state,
                companiesLoading: action.payload.isLoading || true,
                companiesError: null,
            };
        case COMPANIES_LOADED:
            return {
                ...state,
                companies: action.payload.companies,
                companiesLoading: false,
                companiesError: null,
                companiesCount: action.payload.companiesCount || state.companiesCount,
            };
        case COMPANY_LOADED:
            return {
                ...state,
                companyForEdit: action.payload.companyForEdit,
            };
        case COMPANY_CLEARED:
            return {
                ...state,
                companyForEdit: null,
            };
        case COMPANIES_FAILED:
            return {
                ...state,
                companiesLoading: false,
                companiesError: action.payload.projectsError,
            };
        case UPDATE_ACTIVE_COMPANIES_ORDER: {
            const updatedOrderParam = action.payload;
            const activeCompaniesOrder = state.activeCompaniesOrder;
            if (activeCompaniesOrder && updatedOrderParam === activeCompaniesOrder) {
                return {
                    ...state,
                    activeCompaniesOrder: `-${updatedOrderParam}`,
                };
            }

            return {
                ...state,
                activeCompaniesOrder: updatedOrderParam,
            };
        }
        case UPDATE_ACTIVE_IOT_ORDER: {
            const updatedOrderParam = action.payload;
            const activeOrderIotParam = state.activeOrderIotParam;
            if (activeOrderIotParam && updatedOrderParam === activeOrderIotParam) {
                return {
                    ...state,
                    activeOrderIotParam: `-${updatedOrderParam}`,
                };
            }

            return {
                ...state,
                activeOrderIotParam: updatedOrderParam,
            };
        }

        case UPDATE_COMPANY_FOR_MANAGING_HAULERS: {
            return {
                ...state,
                companyForManagingHaulers: action.payload,
            };
        }
        case UPDATE_COMPANY_FOR_UPDATING_ADMINS: {
            return {
                ...state,
                companyForUpdatingAdmins: action.payload,
            };
        }
        case UPDATE_HAS_SUB_COMPANIES: {
            return {
                ...state,
                hasSubCompanies: action.payload,
            };
        }

        case COMPANIES_RESET:
            return {
                ...initialState,
                activeCompaniesOrder: state.activeCompaniesOrder,
                activeOrderIotParam: state.activeOrderIotParam,
                hasSubCompanies: state.hasSubCompanies,
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};
