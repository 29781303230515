import moment from "moment";

import dataProvider from "../helpers/api";
import { EVENTS, GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import { SERVER_DATE_FORMAT } from "../constants/global";

export const getNotificationEvents = (params = {}) => {
    return dataProvider({
        url: EVENTS.NOTIFICATION_EVENTS(),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords || undefined,
            type: params.type,
            startDate: moment().format(SERVER_DATE_FORMAT),
            endDate: moment().format(SERVER_DATE_FORMAT),
            regions: params.regions,
        },
    });
};

export const getNotificationSettingsZones = (params = {}) => {
    return dataProvider({
        url: EVENTS.NOTIFICATIONS_SETTINGS_ZONES(),
        method: "GET",
        params,
    });
};

export const addNotificationSettingsZone = (data) => {
    return dataProvider({
        url: EVENTS.NOTIFICATIONS_SETTINGS_ZONES(),
        method: "POST",
        data,
    });
};

export const deleteNotificationSettingsZone = (id) => {
    return dataProvider({
        url: `${EVENTS.NOTIFICATIONS_SETTINGS_ZONES()}/${id}`,
        method: "DELETE",
        data: { id },
    });
};

export const updateNotificationSettingsZone = (data) => {
    return dataProvider({
        url: `${EVENTS.NOTIFICATIONS_SETTINGS_ZONES()}/${data.id}`,
        method: "PUT",
        data,
    });
};
