import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

function reduxStore(initialState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunk)));

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("../reducers", () => {
            // We need to require for hot reloading to work properly.
            const nextReducer = require("../reducers");

            store.replaceReducer(nextReducer);
        });
    }

    return store;
}

export default reduxStore;
