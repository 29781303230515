import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import { getJobBoardJobOrderJoli } from "../../../../dataServers/jobOrder";
import Loader from "../../../core/loader";
import ErrorNotification from "../../../core/notification";
import PickUpDropOff from "../../jobComponents/pickUp&DropOff";
import { Divider } from "../../../core/divider";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../../constants/global";
import { UNIT_OF_MEASURE_LOAD } from "../../../../constants/maps";
import { SimpleLink } from "../../../core/link";
import AppModal from "../../../core/modal";
import TrucksTable, { generateJolisForTrucksTable } from "./trucksTable";
import { getSortedByTruckNameItems } from "../../../fleet/fleetList";

const rootPadding = "13px 16px";

const trucksStyles = makeStyles((theme) => ({
    trucksTab: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius * 2,

        "& .trucks-tab": {
            "& .medium": {
                width: "25%",
            },
        },
    },
    rootPadding: {
        padding: rootPadding,
    },
    trucksCount: {
        width: 32,
        height: 32,
        borderRadius: theme.shape.borderRadius / 2,
        border: `1px solid ${theme.palette.border.primary}`,
        cursor: "not-allowed",
        textAlign: "center",
        backgroundColor: theme.palette.general.paleGrey,
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
    },
    startTime: {
        paddingRight: 43,
    },
    expectedTime: {
        textTransform: "none",
    },
}));

const modalStyles = {
    minWidth: 550,
};

function Trucks(props) {
    const { jobOrder } = props;
    const [state, setState] = useState({ isLoading: false, errorMessage: false, trucks: [] });
    const { isLoading, errorMessage, trucks, jolis } = state;
    const classes = trucksStyles();
    const [openAllHaulersModal, setOpenHaulersModal] = useState(false);

    const loadTrucks = () => {
        setState({ ...state, isLoading: true });

        Promise.all([getJobBoardJobOrderJoli(jobOrder.id)])
            .then(([{ data: jolis }]) => {
                const [newJolis, allTrucks] = generateJolisForTrucksTable(jolis);

                setState({ ...state, isLoading: false, jolis: getSortedByTruckNameItems(newJolis), trucks: allTrucks });
            })
            .catch((error) => {
                setState({ ...state, isLoading: false, errorMessage: PROCESS_SERVER_ERROR(error) });
            });
    };

    useEffect(() => {
        loadTrucks();
    }, [jobOrder.id]);

    const showQty = jobOrder.unitOfMeasure === UNIT_OF_MEASURE_LOAD && !jobOrder.unlimited;

    return (
        <div className={clsx(isLoading && `${LOADER_WHITE_OVERLAY} -min-height`)}>
            <Divider />
            {isLoading && <Loader />}
            {errorMessage && <ErrorNotification message={errorMessage} />}
            {!_.isEmpty(trucks) ? (
                <React.Fragment>
                    <Box className={clsx(classes.trucksTab)}>
                        <div className={classes.rootPadding}>
                            <PickUpDropOff
                                jobOrder={jobOrder}
                                summaryInfo={{ distanceDuration: jobOrder.distanceDuration }}
                            />
                        </div>
                        <TrucksTable jobOrder={jobOrder} showQty={showQty} jolis={jolis} isLimitedHaulers={true} />
                    </Box>
                    <SimpleLink
                        otherProps={{ style: { marginTop: 7, marginLeft: 5, display: "block" } }}
                        onClick={() => setOpenHaulersModal(true)}
                    >
                        View All Haulers
                    </SimpleLink>
                    <AppModal
                        isOpen={openAllHaulersModal}
                        closeModal={() => setOpenHaulersModal(false)}
                        modalStyles={modalStyles}
                    >
                        <TrucksTable jobOrder={jobOrder} showQty={showQty} jolis={jolis} />
                    </AppModal>
                </React.Fragment>
            ) : (
                <h2 className="--text-center">No truck to display</h2>
            )}
        </div>
    );
}

Trucks.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
};

Trucks.defaultProps = {};

export default withRouter(
    compose(
        reduxForm({
            form: "jobOrderTrucksForm",
        }),
        connect((state) => ({
            account: state.account,
        })),
    )(Trucks),
);
