/* global google */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose, lifecycle, withProps } from "recompose";
import { GoogleMap, Polyline as PolylineComponent, withGoogleMap, withScriptjs } from "react-google-maps";
import clsx from "clsx";
import { connect } from "react-redux";
import { ATLANTA_COORDINATES, GOOGLE_MAPS_TRUCK_ICON_SIZE, GOOGLE_MAPS_URL } from "../../constants/global";
import { getTrucksForMap } from "../../helpers/global";
import { getIfIsPickUpMidpoint, getIfIsDropOffMidpoint } from "../../helpers/jobOrders";
import CustomMarker from "../global/maps/customMarker";
import { TRUCK_STATUS_AT_PICK_UP } from "../../constants/maps";
import { makeStyles } from "@material-ui/core/styles";
import DropOffMapData from "../global/maps/dropOffMapData";
import PickUpMapData from "../global/maps/pickUpMapData";

export const TRUCK_ACTIVITY_MAP_HEIGHT = 300;

const useStyles = makeStyles(() => ({
    truckActivityMapWrap: {
        position: "relative",

        "& .no-map-data": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            textAlign: "center",
            zIndex: 1,
            background: "rgb(255, 255, 255, 0.5)",
            lineHeight: `${TRUCK_ACTIVITY_MAP_HEIGHT}px`,
        },
    },
}));

const getMapBounds = (mapData, trucksPuDoData) => {
    const bounds = new google.maps.LatLngBounds();

    if (!_.isEmpty(mapData)) {
        mapData.forEach((item, i) => {
            const { location } = item;

            let lat;
            let lng;

            if (location.longitude && location.latitude) {
                lat = location.latitude;
                lng = location.longitude;

                bounds.extend(new google.maps.LatLng(lat, lng));
            }
        });
    }

    if (!_.isEmpty(trucksPuDoData)) {
        if (!_.isEmpty(trucksPuDoData.dropoffLocations)) {
            trucksPuDoData.dropoffLocations.forEach((item, i) => {
                if (item.longitude && item.latitude) {
                    bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
                }
            });
        }
        if (!_.isEmpty(trucksPuDoData.pickupLocation)) {
            if (trucksPuDoData.pickupLocation.longitude && trucksPuDoData.pickupLocation.latitude) {
                bounds.extend(
                    new google.maps.LatLng(
                        trucksPuDoData.pickupLocation.latitude,
                        trucksPuDoData.pickupLocation.longitude,
                    ),
                );
            }
        }
    }

    return bounds;
};
const getPathForMap = (mapData) => {
    const path = [];

    mapData.forEach((item, i) => {
        const { location } = item;

        path.push({ lat: location.latitude, lng: location.longitude });
    });

    return path;
};

const ActivityMapComponent = compose(
    withProps({
        googleMapURL: GOOGLE_MAPS_URL,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `${TRUCK_ACTIVITY_MAP_HEIGHT}px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const { mapData, trucksPuDoData } = this.props;

            if (_.isEmpty(trucksPuDoData) && _.isEmpty(mapData)) {
                return false;
            }

            const path = getPathForMap(mapData);
            const bounds = getMapBounds(mapData, trucksPuDoData);

            this.setState({
                bounds: (map) => {
                    bounds && map && map.fitBounds(bounds);
                },
                path,
            });
        },
        componentWillReceiveProps(newProps) {
            const { mapData, trucksPuDoData } = this.props;
            const { mapData: newMapData, trucksPuDoData: newPuDoData } = newProps;

            if (_.isEmpty(newMapData) && _.isEmpty(newPuDoData)) {
                return false;
            }

            if (
                (newMapData && !_.isEqual(mapData, newMapData)) ||
                (newPuDoData && !_.isEqual(trucksPuDoData, newPuDoData))
            ) {
                const path = getPathForMap(newMapData);
                const bounds = getMapBounds(newMapData, newPuDoData);

                this.setState({
                    bounds: (map) => {
                        bounds && map && map.fitBounds(bounds);
                    },
                    path,
                });
            }
        },
    }),
)((props) => {
    const { bounds, currentLocation, path, trucksPuDoData, jobOrder } = props;

    const defaultCenter = !_.isEmpty(currentLocation) ? currentLocation : ATLANTA_COORDINATES;

    return defaultCenter ? (
        <GoogleMap ref={bounds} defaultCenter={defaultCenter} defaultZoom={11}>
            {!_.isEmpty(path) && (
                <PolylineComponent
                    path={path}
                    geodesic={true}
                    options={{
                        strokeColor: "#73B9FF",
                        strokeOpacity: 0.7,
                        strokeWeight: 5,
                    }}
                />
            )}
            {!_.isEmpty(currentLocation) && (
                <CustomMarker
                    position={currentLocation}
                    icon={{
                        scaledSize: new google.maps.Size(GOOGLE_MAPS_TRUCK_ICON_SIZE, GOOGLE_MAPS_TRUCK_ICON_SIZE),
                        url: getTrucksForMap(TRUCK_STATUS_AT_PICK_UP, 0),
                        zIndex: 3,
                    }}
                ></CustomMarker>
            )}
            {!_.isEmpty(trucksPuDoData.dropoffLocations) && (
                <DropOffMapData
                    dropOffSites={trucksPuDoData.dropoffLocations}
                    isDropOffMidpoint={getIfIsDropOffMidpoint(jobOrder)}
                />
            )}
            {!_.isEmpty(trucksPuDoData.pickupLocation) && (
                <PickUpMapData
                    pickUpSite={trucksPuDoData.pickupLocation}
                    isPickUpMidpoint={getIfIsPickUpMidpoint(jobOrder)}
                />
            )}
        </GoogleMap>
    ) : (
        <div>
            <h2 className="--text-center">No Data to display map</h2>
        </div>
    );
});

ActivityMapComponent.propTypes = {
    mapData: PropTypes.array.isRequired,
};

ActivityMapComponent.defaultProps = {
    mapData: [],
};

const TruckActivityMap = (props) => {
    const { mapData, currentLocation, trucksPuDoData, jobOrder } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.truckActivityMapWrap)}>
            {_.isEmpty(mapData) && _.isEmpty(trucksPuDoData) && (
                <div className={"no-map-data"}>
                    <h2>No data for selected truck</h2>
                </div>
            )}
            <ActivityMapComponent
                mapData={mapData}
                jobOrder={jobOrder}
                trucksPuDoData={trucksPuDoData}
                currentLocation={currentLocation}
            />
        </div>
    );
};

TruckActivityMap.propTypes = {
    mapData: PropTypes.array,
    currentLocation: PropTypes.object.isRequired,
    trucksPuDoData: PropTypes.object.isRequired,
};

TruckActivityMap.defaultProps = {
    mapData: [],
    currentLocation: {},
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
        };
    })(TruckActivityMap),
);
