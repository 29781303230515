import React from "react";
import DatesRange from "../global/datesRange";
import { DEFAULT_DATE_FORMAT } from "../../constants/global";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

const SiteStatsFilters = () => {
    return (
        <form>
            <DatesRange form={"siteStatsForm"} showEndDate={false} dateFormat={DEFAULT_DATE_FORMAT} />
        </form>
    );
};

export const SITE_STATS_FILTERS_FORM = "siteStatsForm";

export default withRouter(
    compose(
        reduxForm({
            form: SITE_STATS_FILTERS_FORM,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(SiteStatsFilters),
);
