import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import SearchAndFilters, {
    FILTERS_REGIONS_ID,
    MULTIPLE_COMMA_SEPARATED_SEARCH_PLACEHOLDER,
} from "../global/searchAndFilters";
import { connect, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import DatesRange from "../global/datesRange";
import { DEFAULT_DATE_FORMAT, SERVER_DATE_FORMAT } from "../../constants/global";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { selectAccount, selectCompanyRegions, selectUserRegion } from "../../selectors";
import { IS_ADMIN_USER } from "../../constants/maps";
import { BUNDLES_FILTERS_FORM } from "../../constants/forms";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    bundlesFilterForm: {
        width: "100%",
        margin: "10px 0px",
        padding: "10px 0px",
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        "& .bundles-filters-container": {
            position: "relative",
        },
    },
}));

const BundlesFilters = (props) => {
    const { form, updateListByFilters, handleStartDateChange, isViewByBundles, startDate } = props;
    const classes = useStyles();

    const companyRegions = useSelector(selectCompanyRegions);
    const userRegion = useSelector(selectUserRegion);
    const account = useSelector(selectAccount);

    const [filtersList, setFiltersList] = useState([]);
    const [initialFilters, setInitialFilters] = useState({});

    useEffect(() => {
        if (!IS_ADMIN_USER(account.company.id) && !_.isEmpty(companyRegions)) {
            const presentDay = moment().format(SERVER_DATE_FORMAT);
            if (isViewByBundles && startDate && presentDay === moment(startDate).format(SERVER_DATE_FORMAT)) {
                return setFiltersList([]);
            }

            setFiltersList([
                {
                    title: "Regions",
                    filterKey: FILTERS_REGIONS_ID,
                    filters: companyRegions.map((item) => ({
                        label: item.regionName,
                        id: item.id,
                    })),
                },
            ]);
            if (userRegion) {
                setInitialFilters({
                    [FILTERS_REGIONS_ID]: {
                        [userRegion.id]: true,
                    },
                });
            }
        }
    }, [companyRegions, startDate, isViewByBundles]);

    return (
        <form noValidate={true} className={classes.bundlesFilterForm}>
            <Grid container>
                <Grid item xs={4}>
                    <DatesRange
                        form={form}
                        showEndDate={false}
                        rememberFilters
                        handleStartDateChange={handleStartDateChange}
                        dateFormat={DEFAULT_DATE_FORMAT}
                    />
                </Grid>
                <Grid item xs={8} className="bundles-filters-container">
                    <SearchAndFilters
                        key={filtersList.length}
                        form={form}
                        rememberFilters
                        updateList={(filters, clear) => updateListByFilters(filters, clear)}
                        initialFilters={initialFilters}
                        filtersList={filtersList}
                        placeholder={MULTIPLE_COMMA_SEPARATED_SEARCH_PLACEHOLDER}
                        autoFocusOnSearch={false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

BundlesFilters.propTypes = {
    handleStartDateChange: PropTypes.func,
    updateListByFilters: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

export default withRouter(
    compose(
        reduxForm({
            form: BUNDLES_FILTERS_FORM,
            destroyOnUnmount: false,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(BundlesFilters),
);
