import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { TimeRange, TimeRangeEvent, TimeSeries } from "pondjs";
import { ChartContainer, ChartRow, Charts, EventChart, Resizable } from "react-timeseries-charts";
import clsx from "clsx";

import { Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";

import {
    handleChartResetZoom,
    handleChartZoomIn,
    handleChartZoomOut,
} from "../../../helpers/chartHelpers/chartTimeRangeHandlers";
import { SAME_TIME_OFFSET } from "../../reportAndAnalytic/truckAllActivitiesChart";
import { convertTimeToJsFormat } from "../../../helpers/jobOrders";
import { selectAccountTimezone } from "../../../selectors";
import {
    DEFAULT_CHART_COLOR,
    DEFAULT_COMBINED_CHART_COLOR,
    TRAILER_HISTORY_COMBINED_EVENTS,
    TRAILER_HISTORY_EVENT,
    TRAILER_LINK_ACTION,
    TRAILER_UNLINK_ACTION,
} from "../../../constants/maps";
import moment from "moment";
import { DEFAULT_NO_TIMEZONE_DATE_FORMAT } from "../../../constants/global";
import { convertToChartDate } from "../../bundles/route/RouteSliderChart";
import { THEME_ICONS } from "../../../styles/icons";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const useStyles = makeStyles((theme) => ({
    chartIcon: {
        fontSize: 20,
    },

    chartContainer: {
        width: "90%",

        "& svg text": {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            fill: "#000 !important",
        },
        "& svg line": {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            stroke: "#000 !important",
        },
    },

    trailersHistoryChartWrapper: {
        display: "flex",
        flexDirection: "row",
    },

    namesContainer: {
        width: "10%",
        minWidth: "fit-content",
        display: "flex",
        flexDirection: "column",

        "& .chart-name": {
            display: "flex",
            whiteSpace: "nowrap",
            fontWeight: 700,
            fontSize: 14,
            marginBottom: 10,
        },
    },
    uiIconTrailer: {
        ...THEME_ICONS.trailerFilledSmall,
        width: 20,
        height: 20,
    },
    truckLabelIcon: {
        width: 20,
        height: 20,
        fill: theme.palette.secondary.dark,
    },
}));

const trailersChartStyle = (event, state) => {
    let color;

    switch (event.get("type")) {
        case TRAILER_HISTORY_EVENT:
            color = DEFAULT_CHART_COLOR;
            break;
        case TRAILER_HISTORY_COMBINED_EVENTS:
            color = DEFAULT_COMBINED_CHART_COLOR;
            break;
        default:
            color = DEFAULT_COMBINED_CHART_COLOR;
    }

    const style = { fill: color, pointerEvents: "none" };

    if (event.get("type") === TRAILER_HISTORY_COMBINED_EVENTS) {
        style.opacity = 0.2;
    }

    return style;
};
const TrailersHistory = ({ trailerHistoryData }) => {
    const timezone = useSelector(selectAccountTimezone);
    const classes = useStyles();
    const { trailers, truckName } = trailerHistoryData;

    const [minMaxTimes, setMinMaxTimes] = useState(null);
    const [timeRange, setTimeRange] = useState(null);
    const [trailersEvents, setTrailersEvents] = useState(null);
    const [combinedTrailersEvents, setCombinedTrailersEvents] = useState(null);

    const handleResetZoom = () => {
        handleChartResetZoom(timeRange, setTimeRange, minMaxTimes);
    };

    const handleZoomIn = () => {
        handleChartZoomIn(timeRange, setTimeRange);
    };

    const handleZoomOut = () => {
        handleChartZoomOut(timeRange, setTimeRange, minMaxTimes);
    };

    const handleTimeRangeChange = (newTimeRange) => {
        if (Math.abs(newTimeRange.duration() - timeRange.duration()) < SAME_TIME_OFFSET) {
            setTimeRange(newTimeRange);
        }
    };

    useEffect(() => {
        if (trailerHistoryData) {
            const trailersEvents = trailerHistoryData.trailers.map((trailer) => {
                const { actions } = trailer;
                const linkActions = actions.filter((action) => action.actionType === TRAILER_LINK_ACTION);
                const unlinkActions = actions.filter((action) => action.actionType === TRAILER_UNLINK_ACTION);
                const currentTime = moment().format();

                // if trailer wasn't unlinked
                if (unlinkActions.length !== linkActions.length) {
                    unlinkActions.push({
                        actionType: TRAILER_UNLINK_ACTION,
                        timestamp: currentTime,
                    });
                }
                const events = linkActions.map((linkAction, actionIndex) => {
                    const unlinkAction = unlinkActions[actionIndex];

                    return {
                        start: linkAction.timestamp,
                        end: unlinkAction.timestamp,
                    };
                });

                return {
                    ...trailer,
                    events,
                };
            });
            const allEvents = [];
            trailersEvents.forEach((item) => {
                allEvents.push(...item.events);
            });

            const trailersSeriesEvents = trailersEvents.map((trailerEvents) => {
                const timeRangeEvents = trailerEvents.events.map(({ start, end }) => {
                    const range = new TimeRange(convertToChartDate(start, timezone), convertToChartDate(end, timezone));

                    return new TimeRangeEvent(range, {
                        type: TRAILER_HISTORY_EVENT,
                        title: "name",
                    });
                });

                return {
                    ...trailerEvents,
                    events: timeRangeEvents,
                };
            });

            const minTime = convertToChartDate(_.min(allEvents.map((item) => item.start)), timezone);
            const maxTime = convertToChartDate(_.max(allEvents.map((item) => item.end)), timezone);
            const sortedEvents = allEvents.sort((a, b) => {
                return (
                    convertTimeToJsFormat(a.start || a.end, timezone) -
                    convertTimeToJsFormat(b.start || b.end, timezone)
                );
            });
            const combinedTrailersEvents = sortedEvents.map(({ start, end, trailerName }) => {
                const range = new TimeRange(convertToChartDate(start, timezone), convertToChartDate(end, timezone));

                return new TimeRangeEvent(range, {
                    type: TRAILER_HISTORY_COMBINED_EVENTS,
                });
            });

            setTrailersEvents(trailersSeriesEvents);
            setCombinedTrailersEvents(combinedTrailersEvents);
            setTimeRange(new TimeRange(minTime, maxTime));
            setMinMaxTimes({
                minTime: minTime,
                maxTime: maxTime,
            });
        }

        return () => {
            setTrailersEvents(null);
            setCombinedTrailersEvents(null);
            setTimeRange(null);
            setMinMaxTimes(null);
        };
    }, [trailerHistoryData]);

    return (
        <div className={classes.trailersHistoryChartWrapper}>
            <div className={classes.namesContainer}>
                {trailers.map(({ trailerName, trailerId }) => {
                    return (
                        <div className="chart-name" key={trailerId}>
                            <div className={classes.uiIconTrailer} />
                            <div>{trailerName}</div>
                        </div>
                    );
                })}
                <div className="chart-name">
                    <LocalShippingIcon className={clsx(classes.truckLabelIcon)} />
                    {truckName}
                </div>
            </div>
            {!_.isEmpty(trailersEvents) && !_.isEmpty(combinedTrailersEvents) && (
                <div className={classes.chartContainer}>
                    <>
                        <Resizable>
                            <ChartContainer
                                timeRange={timeRange}
                                minTime={minMaxTimes.minTime}
                                maxTime={minMaxTimes.maxTime}
                                enablePanZoom={true}
                                paddingRight={25}
                                paddingLeft={25}
                                onTimeRangeChanged={handleTimeRangeChange}
                            >
                                {trailersEvents.map((trailerData) => {
                                    const { events, trailerId } = trailerData;
                                    const series = new TimeSeries({
                                        name: `${trailerId}Series`,
                                        events,
                                    });

                                    return (
                                        !_.isEmpty(trailerData) && (
                                            <ChartRow height={30} key={trailerId}>
                                                <Charts>
                                                    <EventChart series={series} size={45} style={trailersChartStyle} />
                                                </Charts>
                                            </ChartRow>
                                        )
                                    );
                                })}
                                <ChartRow height={30}>
                                    <Charts height={100}>
                                        <EventChart
                                            series={
                                                new TimeSeries({
                                                    name: "combinedSeries",
                                                    events: combinedTrailersEvents,
                                                })
                                            }
                                            size={45}
                                            style={trailersChartStyle}
                                        />
                                    </Charts>
                                </ChartRow>
                            </ChartContainer>
                        </Resizable>
                        <Box width={1} display="flex" justifyContent="center">
                            <Tooltip title="Reset zoom">
                                <IconButton aria-label="reset-zoom" onClick={handleResetZoom}>
                                    <RefreshIcon height="100%" className={clsx(classes.chartIcon)} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Zoom In">
                                <IconButton aria-label="add-zoom" onClick={handleZoomIn}>
                                    <AddIcon className={clsx(classes.chartIcon)} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Zoom out">
                                <IconButton aria-label="remove-zoom" onClick={handleZoomOut}>
                                    <RemoveIcon className={clsx(classes.chartIcon)} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                </div>
            )}
        </div>
    );
};

export default TrailersHistory;
