import _ from "lodash";
import { formValueSelector, getFormInitialValues, initialize, SubmissionError } from "redux-form";

import {
    PROJECT_OPENED,
    PROJECTS_CLEAR_FILTERS,
    PROJECTS_FAILED,
    PROJECTS_LOADED,
    PROJECTS_LOADING,
    PROJECTS_RESET,
    PROJECTS_SET_DATE_FILTER,
    PROJECTS_SET_REGION_FILTER,
    PROJECTS_SET_SEARCH_QUERY,
    PROJECTS_SET_STATUS_FILTER,
    PROJECTS_UPDATED,
    PROJECTS_VISIBLE_LOADING,
    SET_LOADING,
    UPDATE_ACTIVE_PROJECT_HISTORY_ORDER,
    UPDATE_ACTIVE_PROJECT_ORDER,
    UPDATE_CURRENT_PROJECT_COMPANY,
    UPDATE_PROJECT_HISTORY_JOBS,
    UPDATE_PROJECT_HISTORY_JOBS_COUNT,
    UPDATE_PROJECT_HISTORY_LOADING,
    UPDATE_PROJECT_SELECTED_TAB,
    UPDATE_RESTRICTED_CUSTOMERS_LIST,
} from "../events/projects";
import { getAdditionalName, prepareAdditionalName } from "../helpers/projects";
import { FormDataController, getNextPageForRequest, handleError } from "../helpers/global";
import { selectCompanyId, selectCurrentProject, selectProjectAdditinalFields } from "../selectors/index";
import * as ProjectsDataServer from "../dataServers/projects";
import { getProjectById } from "../dataServers/projects";
import { selectProjectHistoryJobs, selectProjectHistoryJobsOrder } from "../selectors/projectsSelectors";
import { PROJECT_HISTORY_FILTERS_FORM } from "../components/projects/ProjectJobsHistrory/ProjectJobsHistoryFilters";
import moment from "moment";
import { PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../constants/global";
import { getJobOrders } from "../dataServers/jobOrder";
import { fetchRestrictedCustomers } from "../dataServers/companies";

export const getProjects = (params = {}, concat = false) => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({
            type: concat ? PROJECTS_VISIBLE_LOADING : PROJECTS_LOADING,
            payload: {},
        });

        return ProjectsDataServer.getProjects({
            ...params,
            order: state.projects.activeProjectOrder,
            page: params.page || 1,
            // dispatch: true, // get always with this param as isAmOwner field is required
            keywords: state.projects.projectsSearchQuery,
            status: state.projects.projectsStatusFilter,
            regions: state.projects.projectsRegionFilter,
        })
            .then((response) => {
                const resetItem = state.projects.clearProjectActiveItem;
                let newProjects = concat ? [...state.projects.projects, ...response.data] : response.data;
                const projectsCount = response.meta.count;

                if (params.projectDetailId && !resetItem) {
                    return getProjectById(params.projectDetailId).then(({ data }) => {
                        const insertedProject = { ...data, insertedProject: true };
                        newProjects = [insertedProject, ...newProjects];
                        dispatch({
                            type: PROJECTS_LOADED,
                            payload: {
                                projects: newProjects,
                                projectsCount,
                            },
                        });
                        dispatch(setCurrentProject(insertedProject));

                        return { loadedProjects: newProjects, currentProject: insertedProject };
                    });
                } else {
                    if (params.isProjectPage && !_.isEmpty(newProjects)) {
                        const currentProject = selectCurrentProject(state);
                        const project = params.project || (params.withNewProject ? newProjects[0] : currentProject);

                        return getProjectById(project.id).then(({ data }) => {
                            dispatch({
                                type: PROJECTS_LOADED,
                                payload: {
                                    projects: newProjects,
                                    projectsCount,
                                },
                            });
                            dispatch(setCurrentProject(data));

                            return { loadedProjects: newProjects, currentProject: data };
                        });
                    } else {
                        const project = params.project || newProjects[0];
                        if (project && state.projects.projectsSearchQuery) {
                            return getProjectById(project.id).then(({ data }) => {
                                dispatch({
                                    type: PROJECTS_LOADED,
                                    payload: {
                                        projects: newProjects,
                                        projectsCount,
                                    },
                                });
                                dispatch(setCurrentProject(data));

                                return { loadedProjects: newProjects, currentProject: data };
                            });
                        } else {
                            dispatch({
                                type: PROJECTS_LOADED,
                                payload: {
                                    projects: newProjects,
                                    projectsCount,
                                },
                            });

                            return { loadedProjects: newProjects };
                        }
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: PROJECTS_FAILED,
                    payload: {
                        projectsError: error.message,
                    },
                });
            });
    };
};

const setLoading = (payload) => ({ type: SET_LOADING, payload });

const getAdditionalFields = (currentProject) => {
    const fields = [];
    const additionalFields = currentProject.additionalFields;
    if (additionalFields) {
        const files = additionalFields?.attached_files;
        if (files) {
            Object.keys(files).forEach((file) => {
                fields.push({
                    name: getAdditionalName(files[file].label),
                    value: { name: files[file].filename },
                    fileId: file,
                    fieldId: _.uniqueId(),
                    option: "file",
                });
            });
        }
        Object.keys(additionalFields).map((key) => {
            if (key !== "attached_files") {
                fields.push({
                    name: getAdditionalName(key),
                    value: additionalFields[key],
                    option: "text",
                    fieldId: _.uniqueId(),
                });
            }
        });
    }

    return fields;
};

export const updateProject = (id, values) => async (dispatch, getState) => {
    try {
        const initValues = getFormInitialValues("UPDATE PROJECT")(getState());
        const addfields = selectProjectAdditinalFields(getState());

        const fieldsToAddArray = values.additionalFields
            .filter((i) => !_.isObject(i.value))
            .map((i) => ({ name: i.name, value: i.value }));
        const fieldsToRemove =
            !_.isEmpty(addfields) &&
            Object.keys(addfields)
                .filter((k) => k !== "attached_files")
                .map((i) => i)
                .filter((i) => !!i);

        if (_.isEqual(initValues, values)) return;

        dispatch(setLoading(true));

        const data = new FormData();
        const dataController = new FormDataController(data);

        const body = {};

        const fieldsToAdd = fieldsToAddArray?.reduce((result, field) => {
            const fieldName = prepareAdditionalName(field.name);
            result[fieldName] = field.value;

            return { ...result };
        }, {});

        if (values && values.additionalFields) {
            values.additionalFields.forEach((field) => {
                if (field.value instanceof File) {
                    if (
                        addfields &&
                        addfields.attached_files &&
                        field.fileId &&
                        addfields.attached_files[field.fileId]
                    ) {
                        body.filesToRemove = [...(body.filesToRemove || []), field.fileId];
                    }
                    data.append(field.name, field.value);
                }
            });
        }

        dataController.appendStringValue("name", values.name);

        if (values.filesToRemove && values.filesToRemove.length) {
            body.filesToRemove = [...(body.filesToRemove || []), ...values.filesToRemove];
        }

        if (fieldsToRemove) {
            body.fieldsToRemove = fieldsToRemove;
        }

        if (fieldsToAdd) {
            body.fieldsToAdd = fieldsToAdd;
        }

        !_.isEmpty(body) && data.append("additionalFields", JSON.stringify(body));

        const result = await ProjectsDataServer.updateProject(id, data);
        dispatch(setCurrentProject(result.data));
        dispatch(
            initialize("UPDATE PROJECT", {
                name: values.name,
                additionalFields: getAdditionalFields(result.data),
            }),
        );
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        throw new SubmissionError({ _error: handleError(error) });
    }
};

// export const getProjectsAndSetFirstToCurrent = (projectId) => {
//     return (dispatch, getState) => {
//         return dispatch(getProjects()).then(() => {
//             const projects = getState().projects.projects;
//             if (!_.isEmpty(projects)) {
//                 dispatch({
//                     type: PROJECTS_LOADING,
//                     payload: {}
//                 });
//
//                 return getProjectById(projectId || projects[0].id).then(({ data }) => {
//                     const insertedProject = { ...data, insertedProject: true };
//
//                     dispatch(updateProjects(insertedProject));
//                     history.push(getProjectEditRoute(insertedProject.id));
//
//                     return dispatch(setCurrentProject(insertedProject));
//                 });
//             }
//         }).catch(error => {
//             dispatch({
//                 type: PROJECTS_FAILED,
//                 payload: {
//                     projectsError: error.message,
//                 }
//             });
//         });
//     };
// };

export const updateProjects = (project) => {
    return (dispatch, getState) => {
        const state = getState();
        let updatedProjects = state.projects.projects;

        if (project)
            if (updatedProjects.map((p) => p.id).includes(project.id)) {
                updatedProjects = updatedProjects.map((p) => (project.id === p.id ? project : p));
            } else {
                updatedProjects.unshift(project);
            }

        dispatch({
            type: PROJECTS_UPDATED,
            payload: updatedProjects,
        });
    };
};

export const setProjectsSearchQuery = (payload) => {
    return {
        type: PROJECTS_SET_SEARCH_QUERY,
        payload,
    };
};

export const setProjectsStatusFilter = (payload) => {
    return {
        type: PROJECTS_SET_STATUS_FILTER,
        payload,
    };
};

export const setProjectsRegionFilter = (payload) => {
    return {
        type: PROJECTS_SET_REGION_FILTER,
        payload,
    };
};

export const setProjectsDateFilter = (payload) => {
    return {
        type: PROJECTS_SET_DATE_FILTER,
        payload,
    };
};

export const clearProjectsFilters = () => {
    return {
        type: PROJECTS_CLEAR_FILTERS,
        payload: {},
    };
};

export const resetProjects = () => {
    return {
        type: PROJECTS_RESET,
        payload: {},
    };
};

export const setCurrentProject = (project, manuallySelected = false) => {
    return {
        type: PROJECT_OPENED,
        payload: { project, manuallySelected },
    };
};

export const setcurrentProjectCompany = (company) => {
    return {
        type: UPDATE_CURRENT_PROJECT_COMPANY,
        payload: company,
    };
};

export const updateActiveProjectOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_PROJECT_ORDER,
        payload: order,
    };
};

export const updateProjectHistoryJobs = (jobs) => {
    return {
        type: UPDATE_PROJECT_HISTORY_JOBS,
        payload: jobs,
    };
};
export const updateProjectHistoryJobsCount = (jobsCount) => {
    return {
        type: UPDATE_PROJECT_HISTORY_JOBS_COUNT,
        payload: jobsCount,
    };
};
export const updateProjectHistoryActiveOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_PROJECT_HISTORY_ORDER,
        payload: order,
    };
};

export const updateProjectHistoryLoading = (loading) => {
    return {
        type: UPDATE_PROJECT_HISTORY_LOADING,
        payload: loading,
    };
};
export const getProjectHistoryJobs = (concat) => {
    return async (dispatch, getState) => {
        const state = getState();
        const jobs = selectProjectHistoryJobs(state) || [];
        const currentProject = selectCurrentProject(state);
        const order = selectProjectHistoryJobsOrder(state);
        const endDate = formValueSelector(PROJECT_HISTORY_FILTERS_FORM)(state, "endDate");
        const startDate = formValueSelector(PROJECT_HISTORY_FILTERS_FORM)(state, "startDate");

        const nextPage = getNextPageForRequest(jobs);

        const params = {
            page: concat ? nextPage : 1,
            project: currentProject.id,
            order: order,
            startDate: moment(startDate).format(SERVER_DATE_FORMAT),
            endDate: moment(endDate).format(SERVER_DATE_FORMAT),
        };

        dispatch(updateProjectHistoryLoading(true));
        try {
            const { data, meta } = await getJobOrders(params);
            const updatedJobs = concat ? [...jobs, ...data] : data;
            dispatch(updateProjectHistoryJobs(updatedJobs));
            dispatch(updateProjectHistoryJobsCount(meta.count));
        } catch (error) {
            dispatch({
                type: PROJECTS_FAILED,
                payload: {
                    projectsError: [PROCESS_SERVER_ERROR(error)],
                },
            });
        } finally {
            dispatch(updateProjectHistoryLoading(false));
        }
    };
};

export const updateProjectSelectedTab = (updatedTab) => {
    return {
        type: UPDATE_PROJECT_SELECTED_TAB,
        payload: updatedTab,
    };
};

export const getRestrictedCustomersList = () => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);
    try {
        const { data } = await fetchRestrictedCustomers(companyId);
        dispatch(updateRestrictedCustomersList(data));
    } catch (e) {}
};

export const updateRestrictedCustomersList = (updatedList) => {
    return {
        type: UPDATE_RESTRICTED_CUSTOMERS_LIST,
        payload: updatedList,
    };
};

export const getRestrictedCustomersLoadOptions = (inputValue, loadedCount, params) => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);
    try {
        const response = await fetchRestrictedCustomers(companyId);
        const results = response.data.map((company) => {
            return { value: company.id, label: company.name, company };
        });

        return {
            options: results,
            hasMore: response.meta.count > (loadedCount || results.length),
            page: params.page,
        };
    } catch (error) {}
};
