import _ from "lodash";
import moment from "moment";

import { getValidCoordinates } from "./sites";

const minLength = (min) => (value) => {
    return value && value.length < min ? VALIDATION_ERRORS.minLength(min) : undefined;
};

const maxLength = (max) => (value) => {
    return value && value.length > max ? VALIDATION_ERRORS.maxLength(max) : undefined;
};

const maxLengthOfNumberDecimalPart = (max) => (value) => {
    const splittedByDotNumber = String(value)?.split(".");
    if (splittedByDotNumber?.length === 2) {
        if (splittedByDotNumber[1].length > max) {
            return VALIDATION_ERRORS.maxDecimalPartLength(max);
        }
    }

    return undefined;
};

export const Validation = {
    required: (value) => {
        return (value || value === 0 || value === false) &&
            (_.isString(value) ? value.trim() : true) &&
            (_.isNumber(value) ? true : !_.isEmpty(value) || (value.toString() && value !== ""))
            ? undefined
            : VALIDATION_ERRORS.required;
    },
    password: (value) => (!value ? undefined : value.length >= 5 ? undefined : VALIDATION_ERRORS.passwordMinLengthFive),
    email: (value) =>
        value
            ? value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/i)
                ? undefined
                : VALIDATION_ERRORS.email
            : undefined,
    numbersPositive: (value) => (+value < 0 ? VALIDATION_ERRORS.numbersPositive : undefined),
    noSpecialSymbols: (value) => {
        if (!value || _.isNumber(value)) {
            return undefined;
        }

        const notValid = /[\\`*[\];:"|,./=?]/.test(value.split(" ").join(""));

        return !value ? undefined : notValid ? VALIDATION_ERRORS.specialSymbols : undefined;
    },
    coordinates: (value) => {
        return value ? (getValidCoordinates(value) ? undefined : VALIDATION_ERRORS.coordinates) : undefined;
    },
    isInteger: (value) => {
        if (!value) return undefined;
        if (+value !== Math.trunc(+value)) {
            return VALIDATION_ERRORS.isInteger;
        } else return undefined;
    },
    isNumber: (value) => {
        return value && isNaN(Number(value)) ? VALIDATION_ERRORS.isNumber : undefined;
    },
    isNumberWithSpace: (value) => {
        return value && isNaN(Number(value))
            ? VALIDATION_ERRORS.isNumber
            : _.isString(value) && +value === 0 && value.length
            ? VALIDATION_ERRORS.isNumber
            : undefined;
    },
    isWorkingDay: (value) => {
        return +value <= 24 && +value >= 1 ? undefined : VALIDATION_ERRORS.workDayDuration;
    },
    isValidTime: (value) => {
        if (value && moment.isMoment(value) && !value.isValid()) {
            return VALIDATION_ERRORS.invalidTime;
        }

        return undefined;
    },
    greaterThan0: (value) => {
        if (value && !isNaN(Number(value)) && +value <= 0) {
            return "Must be greater than 0";
        }

        return undefined;
    },
    maxLengthAfterDot1: maxLengthOfNumberDecimalPart(1),
    minLength2: minLength(2),
    minLength4: minLength(4),
    minLength6: minLength(6),
    maxLength16: maxLength(16),
    maxLength64: maxLength(64),
    maxLength100: maxLength(100),
};

export const VALIDATION_ERRORS = {
    required: "Required",
    sameTruckTime: "You should not have same hauler/truck for equal time",
    roleRequired: "Please select role",
    passwordMinLengthFive: "The password must be at least 5 characters",
    passwordConfirm: "Passwords should match",
    coordinates: "Invalid coordinates format",
    email: "Please enter valid email",
    numbersPositive: "Input values should be positive",
    minLength: (min) => `Must be ${min} characters or more`,
    maxLength: (max) => `Must be ${max} characters or less`,
    maxDecimalPartLength: (max) => `Only ${max} digit after dot is available`,
    specialSymbols: 'Special symbols (* . " / \\ [ ] : ; | , = ?) are not allowed',
    fileFormat: "Only jpeg/jpg, png and pdf files are allowed",
    trucksCapacity: "Quantity is greater than truck's capacity",
    isNumber: "Must be a number",
    isInteger: "Must Be Integer",
    workDayDuration: "Must be less than 24 and more then 1",
    invalidTime: "Invalid Time",
    splitJobTrucksAmount: "Only 1 truck is allowed for split jobs",
    splitJobTrailersAmount: "Only 1 trailer is allowed for split jobs",
};
