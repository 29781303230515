import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import MoreActionsMenu from "../../core/moreActions";
import ActionMenuItem from "../../core/actionMenuItem";
import {
    IS_ADMIN_USER,
    IS_INSPECTOR_USER,
    TICKET_STATUS_CLOSED,
    TICKET_STATUS_INTERRUPTED,
    TICKET_STATUS_OPEN,
    TICKET_STATUS_RECONCILED,
} from "../../../constants/maps";
import RepeatIcon from "@material-ui/icons/Repeat";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import clsx from "clsx";
import { EditOutlined } from "@material-ui/icons";
import { showReconcileOption } from "./reconcileTicketForm";
import Icon from "@material-ui/core/Icon";
import { SPLIT_ICON } from "../../../styles/icons";
import { selectUsesSplitJob } from "../../../selectors";

const TicketAction = (props) => {
    const {
        ticket,
        canEditNotes,
        setTicketIdForEdit,
        setTicketForUpdate,
        status,
        canClose,
        grantedAccess,
        setTicketForReconcile,
        account,
        reopenTicket,
        setTicketIdForDelete,
        setTicketIdForClose,
        setTicketForSplit,
        openTicketActionsModal,
        id,
        isAirTicketLite,
        usesSplitJob,
    } = props;

    const { signed, canBeSplitted, isSplit } = ticket;
    const canAddTikPic = canEditNotes && !IS_INSPECTOR_USER(account.role);
    const showReconcileOrViewOption = () => {
        if (isAirTicketLite) {
            // https://github.com/TruckITllc/truckit-backend/issues/3113#issuecomment-688922203
            return (
                (IS_ADMIN_USER(account.company.id) || ticket.company.id === account.company.id) &&
                status !== TICKET_STATUS_RECONCILED
            );
        }

        return true;
    };

    const canReopen = () => {
        // https://github.com/TruckITllc/truckit-frontend/issues/1221
        return (
            !IS_INSPECTOR_USER(account.role) &&
            !isAirTicketLite &&
            (status === TICKET_STATUS_CLOSED || status === TICKET_STATUS_INTERRUPTED) &&
            !grantedAccess &&
            !signed
        );
    };
    const uiCanClose = status === TICKET_STATUS_OPEN && canClose;
    const canUpdate = status === TICKET_STATUS_CLOSED;
    const canVerify = IS_INSPECTOR_USER(account.role);
    const hasMenu =
        canAddTikPic || showReconcileOrViewOption() || canReopen() || canClose || uiCanClose || canVerify || canUpdate;

    return (
        <MoreActionsMenu className={clsx(!hasMenu && "hidden")}>
            {canAddTikPic && (
                <ActionMenuItem
                    title={"Add TicPic"}
                    renderIcon={() => <PhotoLibraryIcon />}
                    handleClick={() => setTicketIdForEdit(ticket)}
                />
            )}
            {showReconcileOrViewOption() && !canVerify && (
                <ActionMenuItem
                    title={showReconcileOption(ticket) ? "Reconcile" : "View"}
                    renderIcon={() => <RepeatIcon />}
                    handleClick={() => setTicketForReconcile(ticket)}
                />
            )}
            {canReopen() && (
                <ActionMenuItem
                    title={"Reopen"}
                    handleClick={() => reopenTicket(id)}
                    renderIcon={() => <RepeatIcon />}
                />
            )}
            {canUpdate && (
                <ActionMenuItem
                    title={"Edit"}
                    handleClick={() => setTicketForUpdate(ticket)}
                    renderIcon={() => <EditOutlined />}
                />
            )}
            {canBeSplitted && usesSplitJob && !isSplit && (
                <ActionMenuItem
                    title={"Split"}
                    renderIcon={() => <Icon style={{ ...SPLIT_ICON, marginRight: 0 }} />}
                    handleClick={() => setTicketForSplit(ticket)}
                />
            )}
            {canClose && (
                <ActionMenuItem
                    title={"Delete"}
                    renderIcon={() => <DeleteOutlineIcon />}
                    handleClick={() => setTicketIdForDelete(id)}
                />
            )}
            {uiCanClose && (
                <ActionMenuItem
                    title={"Close"}
                    renderIcon={() => <HighlightOffIcon />}
                    handleClick={() => setTicketIdForClose(id)}
                />
            )}
            {canVerify && (
                <ActionMenuItem
                    title={"Verify"}
                    renderIcon={() => <HighlightOffIcon />}
                    handleClick={() => openTicketActionsModal(true, ticket)}
                />
            )}
        </MoreActionsMenu>
    );
};

TicketAction.propTypes = {
    isAirTicketLite: PropTypes.bool.isRequired,
    ticket: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    canEditNotes: PropTypes.bool,
    setTicketIdForEdit: PropTypes.func,
    canClose: PropTypes.bool,
    status: PropTypes.number,
    grantedAccess: PropTypes.bool,
    setTicketForReconcile: PropTypes.func,
    reopenTicket: PropTypes.func,
    setTicketForUpdate: PropTypes.func,
    setTicketIdForDelete: PropTypes.func,
    setTicketIdForClose: PropTypes.func,
    openTicketActionsModal: PropTypes.func,
    canBeReconciledByUser: PropTypes.bool,
    id: PropTypes.number,
    usesSplitJob: PropTypes.bool,
    setTicketForSplit: PropTypes.func,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        usesSplitJob: selectUsesSplitJob(state),
    }))(TicketAction),
);
