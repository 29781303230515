import React, { Component } from "react";
import InputRange from "react-input-range";
import moment from "moment";
import "./styles.scss";
import PropTypes from "prop-types";

import { getTimeFromMins, INVALID_DATE, toMinutes } from "../../../helpers/date";

class TimeStampSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    minuteToTime(value) {
        const { format } = this.props;
        value = value > 1439 ? 1439 : value;

        let hours = Math.floor(value / 60),
            minutes = value - hours * 60,
            ampm = null;

        if (hours.length === 1) hours = "0" + hours;
        if (minutes.length === 1) minutes = "0" + minutes;
        if (+minutes === 0) minutes = "00";
        if (+format === 12) {
            ampm = "AM";
            if (hours >= 12) {
                if (+hours === 12) {
                    hours = hours;
                    minutes = minutes;
                } else {
                    hours = hours - 12;
                    minutes = minutes;
                }
                ampm = "PM";
            }
            if (+hours === 0) {
                hours = 12;
                minutes = minutes;
            }
        }

        return { hours: hours, minutes: minutes, am_pm: ampm };
    }

    timeToMinute(time) {
        const { format } = this.props;
        let rMinutes = 1439;

        if (+format === 24) {
            time = time.split(":");
            if (time.length < 2) {
                throw new Error("Invalid time");
            }
            let hours = time[0],
                minutes = parseInt(time[1]);
            hours = parseInt(hours * 60);
            rMinutes = hours + minutes;
        } else {
            time = time.toUpperCase();
            time = time.replace(" ", "");
            let ampm =
                time.indexOf("AM") !== -1
                    ? "AM"
                    : time.indexOf("AM") !== -1
                    ? "PM"
                    : moment(time, "hh:mm:ss").format("A");
            time = time.replace(ampm, "");
            time = time.split(":");
            if (time.length < 2) {
                throw new Error("Invalid time");
            }
            let hours = parseInt(time[0]),
                minutes = parseInt(time[1]);
            if (ampm === "PM") {
                if (+hours !== 12) {
                    hours = hours + 12;
                }
            } else {
                hours = hours === 12 ? 0 : hours;
            }
            hours = hours * 60;
            rMinutes = hours + minutes;
        }

        return rMinutes > 1439 ? 1439 : rMinutes;
    }

    onChange(value) {
        this.props.onChange(getTimeFromMins(value, "YYYY-MM-DDTHH:mm:ss"));
    }

    onChangeComplete(value) {
        let time = this.minuteToTime(value);

        this.props.onChangeComplete(time);
    }

    onChangeStart(value) {
        let time = this.minuteToTime(value);
        this.props.onChangeStart(time);
    }

    formatLabel(value, type) {
        if (!value) return;
        let time = this.minuteToTime(value);
        const result = `${time.hours}:${time.minutes} ${time.am_pm}`;

        const formattedResult = moment(result, "hh:m A").format("hh:mm A");

        if (formattedResult === INVALID_DATE) {
            return value;
        }

        return formattedResult;
    }

    render() {
        let { value, maxValue, minValue, step, disabled } = this.props;
        const time = toMinutes(value);

        return (
            <InputRange
                step={step}
                formatLabel={this.formatLabel.bind(this)}
                disabled={disabled}
                maxValue={maxValue}
                minValue={minValue}
                value={time}
                onChange={this.onChange.bind(this)}
            />
        );
    }
}

TimeStampSlider.propTypes = {
    maxValue: PropTypes.string.isRequired,
    minValue: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    step: PropTypes.number.isRequired,
    format: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    draggableTrack: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func,
    onChangeStart: PropTypes.func,
};

TimeStampSlider.defaultProps = {
    disabled: false,
    draggableTrack: false,
    format: 24,
    maxValue: "06:30 PM",
    minValue: "06:30 AM",
    onChange: () => {},
    onChangeComplete: () => {},
    onChangeStart: () => {},
    step: 1,
    time: "00:00",
};

export default TimeStampSlider;
