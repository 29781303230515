import React from "react";
import EditableInput, { EDITABLE_FIELD_NAME } from "../core/EditableInput";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";
import { updateRouteSettings } from "../../actions/smartDispatch";
import { useDispatch } from "react-redux";

const styles = makeStyles(() => ({
    solutionHaulerRateEditContainer: {
        display: "flex",
        flexDirection: "row",
    },
}));

const HaulerSolutionRateInput = ({ hauler, routeIndex }) => {
    const classes = styles();
    const dispatch = useDispatch();

    const handleHaulerDefaultRateEdit = (hauler, rate) => {
        const updatedRouteData = {
            index: routeIndex,
            haulerRate: rate,
        };
        dispatch(updateRouteSettings({ assignments: [updatedRouteData] }));
    };

    return (
        <div className={clsx(classes.solutionHaulerRateEditContainer)}>
            <Tooltip title={"Hauler Rate"}>
                <span>
                    <EditableInput
                        form={EDIT_ROUTE_HAULER_RATE(routeIndex)}
                        handleSave={(rate) => handleHaulerDefaultRateEdit(hauler, rate)}
                        initialValues={{ [EDITABLE_FIELD_NAME]: hauler.haulerRate }}
                    />
                </span>
            </Tooltip>
        </div>
    );
};

export const EDIT_ROUTE_HAULER_RATE = (routeIndex) => `editRouteHaulerRate-${routeIndex}`;

export default HaulerSolutionRateInput;
