import {
    COMPANY_TYPE_CONTRACTOR,
    COMPANY_TYPE_LIMITED,
    COMPANY_TYPE_LIMITED_CONTRACTOR,
    COMPANY_TYPE_LIMITED_TRUCKER,
    COMPANY_TYPE_RESTRICTED_CUSTOMER,
    COMPANY_TYPE_TRUCKER,
} from "./maps";

export const COMPANY_TYPES = {
    [COMPANY_TYPE_TRUCKER]: "Hauler",
    [COMPANY_TYPE_CONTRACTOR]: "Contractor",
    [COMPANY_TYPE_LIMITED]: "Limited",
    [COMPANY_TYPE_LIMITED_TRUCKER]: "Limited Hauler",
    [COMPANY_TYPE_LIMITED_CONTRACTOR]: "Limited Contractor",
    [COMPANY_TYPE_RESTRICTED_CUSTOMER]: "Restricted Customer",
};

export const DOT_COMPANY = { value: 1500, label: "DOT" };
