import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import clsx from "clsx";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import EventNoteIcon from "@material-ui/icons/EventNote";

import { selectPreferredHaulers } from "../../../selectors";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import ProjectCheckIcon from "../../global/checkIcon";
import { getMatchedData } from "../../../helpers/global";
import { LIST_ITEM_STYLES } from "../../../styles/reusableStyles";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import FleetFilters from "../../fleet/fleetFilters";
import Loader from "../../core/loader";
import TextInputComponent from "../../core/form/textInputComponent";
import { Validation } from "../../../helpers/validation";
import ErrorNotification from "../../core/notification";
import { createUserFilterTrucker, editUserFilterTrucker } from "../../../dataServers/user";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { FILTERS_COMPANIES_IDS_ID } from "../../../components/global/searchAndFilters";

const useStyles = makeStyles((theme) => ({
    editFilterForm: {
        "& .companies-list": {
            overflowY: "scroll",
            height: 400,
            paddingRight: 10,
            marginTop: 10,
        },
        "& .fleet-filters": {
            position: "relative",
            marginBottom: 15,
            marginTop: 10,
            paddingBottom: 7,
            borderBottom: `1px solid ${theme.palette.border.primary}`,
        },
    },
}));

const AddHaulerFilterForm = (props) => {
    const {
        openEditModal,
        formValues: { id, companies, search },
        dispatch,
        form,
        handleSubmit,
        handleFormSave,
        initialValues,
        hiddenFilter,
    } = props;
    const classes = useStyles();

    const [haulerFilters, setHaulerFilter] = useState();

    const [error, setError] = useState();
    const [state, setState] = useState({
        initialFilters: id ? { [FILTERS_COMPANIES_IDS_ID]: { [id]: true } } : null,
        allCompaniesList: [],
        isLoading: false,
        appliedFilters: null,
        allCompaniesCount: 0,
    });
    const { isLoading, appliedFilters, initialFilters } = state;

    const searchValue = appliedFilters && appliedFilters.keywords;

    const allCompaniesList = useSelector(selectPreferredHaulers);

    const classesListItem = LIST_ITEM_STYLES();

    const onCompanySelection = (company) => {
        const remove = companies && companies.find((i) => i.id === company.id);
        const newCompanies = remove
            ? companies.filter((i) => (i.id || i) !== company.id)
            : [...(companies || []), company];
        dispatch(change(form, "companies", newCompanies));
    };

    const onSubmit = (values) => {
        const { companies, id } = values;
        if (_.isEmpty(companies)) {
            return setError(["Please select atleat one hauler"]);
        }

        const dataForAdd = {
            ...values,
            isPublic: false,
            companies: companies.map((i) => i.id),
        };

        const dataForEdit = {
            isPublic: false,
            companies: companies.map((i) => ({ haulerId: i.id, defaultRate: i.defaultRate })),
            name: values.name,
        };

        const request = id ? editUserFilterTrucker(id, dataForEdit) : createUserFilterTrucker(dataForAdd);

        setState({ ...state, isLoading: true });

        request
            .then((data) => {
                setState({ ...state, isLoading: false });
                handleFormSave && handleFormSave(data.data || values);
                openEditModal(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setState({ ...state, isLoading: false });
            });
    };

    const onMessageClose = () => {
        setError(null);
    };

    const updateListByFilters = (filters) => {
        if (!_.isEqual(appliedFilters, filters)) {
            setState({ ...state, appliedFilters: filters });
        }
    };

    const selectCompanies = (companies) => {
        const uniqueIds = [];
        const selectedCompanies = companies.filter((element) => {
            const isDuplicate = uniqueIds.includes(element.id);

            if (!isDuplicate) {
                uniqueIds.push(element.id);

                return true;
            }
        });
        dispatch(change(form, "companies", selectedCompanies));
    };

    const setFilterCompanies = () => {
        const companies = [];
        const filtersIds = !_.isEmpty(appliedFilters) && appliedFilters.filtersIds?.split(",");
        filtersIds &&
            haulerFilters &&
            haulerFilters
                .filter(({ id }) => filtersIds.includes(id.toString()))
                .forEach((i) => companies.push(...(i.companies || [])));
        selectCompanies(companies);
    };

    useEffect(setFilterCompanies, [appliedFilters?.filtersIds]);

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY, classes.editFilterForm)}>
            <form>
                {isLoading && <Loader />}
                {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
                <h1 className="title">{id ? `Edit ${initialValues.name} filter` : "Create New Filter"}</h1>
                <br />
                <Field
                    name="name"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    type="text"
                    label="Filter Name"
                    component={TextInputComponent}
                />
                <br />
                <br />
            </form>
            <FleetFilters
                rememberFilters={false}
                initialFilters={initialFilters}
                hiddenFilter={hiddenFilter}
                form={form}
                defaultFilterEnabled={false}
                isHaulersFilters={false}
                search={search}
                updateListByFilters={updateListByFilters}
                isHaulers={true}
                withoutSearchRules
                withoutEmptySearch
                timeout={500}
                getFilters={setHaulerFilter}
            />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h2 className="--text-center">Add New Haulers</h2>
                    <div className="companies-list">
                        {!_.isEmpty(allCompaniesList) ? (
                            (searchValue ? getMatchedData(allCompaniesList, searchValue) : allCompaniesList).map(
                                (company, index) => {
                                    const active = companies && companies.find((i) => i.id === company.id);

                                    return (
                                        <div
                                            onClick={() => onCompanySelection(company)}
                                            className={clsx(classesListItem.listItem, "link", active && "-current")}
                                        >
                                            {active && <ProjectCheckIcon />}
                                            <Grid component="div" container alignItems={"center"} wrap={"nowrap"}>
                                                <Grid component="div" item className="icon">
                                                    <EventNoteIcon />
                                                </Grid>
                                                <Grid component="div" item>
                                                    <h3>{company.name}</h3>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    );
                                },
                            )
                        ) : (
                            <h2 className="--text-center">No Haulers Found</h2>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <h2 className="--text-center">Selected Haulers</h2>
                    <div className="companies-list">
                        {!_.isEmpty(companies) ? (
                            companies.map((company) => {
                                return (
                                    <div
                                        key={company.id}
                                        className={clsx(classesListItem.listItem, "link", "-current")}
                                    >
                                        <ProjectCheckIcon />
                                        <Grid component="div" container alignItems={"center"} wrap={"nowrap"}>
                                            <Grid component="div" item className="icon">
                                                <EventNoteIcon />
                                            </Grid>
                                            <Grid component="div" item>
                                                <h3>{company.name}</h3>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })
                        ) : (
                            <h2 className="--text-center">No Haulers Found</h2>
                        )}
                    </div>
                </Grid>
            </Grid>
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={() => openEditModal(false)}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)}>Save</PrimaryButton>
            </ButtonsGroup>
        </div>
    );
};

AddHaulerFilterForm.propTypes = {
    openEditModal: PropTypes.func.isRequired,
    handleFormSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
};

export const EDIT_CREATE_FILTER_FORM = "editCreateFilerForm";

export default withRouter(
    compose(
        reduxForm({
            form: EDIT_CREATE_FILTER_FORM,
            enableReinitialize: true,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(state, "id", "companies", "name", "search"),
            };
        }),
    )(AddHaulerFilterForm),
);
