import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { change } from "redux-form";

import Grid from "@material-ui/core/Grid";

import { SimpleLink } from "../../core";
import { getTicketById } from "../../../dataServers/tickets";
import ErrorNotification from "../../core/notification";
import { PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../../../constants/global";
import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";

const LinkedTicketIdLink = ({ linkedTicketId, form, setLoading }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const onLinkClick = async () => {
        setLoading && setLoading(true);
        try {
            const { data } = await getTicketById(linkedTicketId);
            const createdDate = data.createdDate;
            const formattedDate = moment.utc(createdDate).format(SERVER_DATE_FORMAT);
            if (createdDate) {
                dispatch(change(form, "triggerClearFilters", true));
                dispatch(change(form, START_DATE_NAME, formattedDate));
                dispatch(change(form, END_DATE_NAME, formattedDate));
                dispatch(
                    change(form, "search", [
                        {
                            label: linkedTicketId,
                            value: linkedTicketId,
                            __isNew__: true,
                        },
                    ]),
                );
            }
        } catch (error) {
            setError([PROCESS_SERVER_ERROR(error)]);
        } finally {
            setLoading && setLoading(false);
        }
    };

    return (
        <Grid container>
            <Grid item>
                Linked Ticket: <SimpleLink onClick={onLinkClick}>#{linkedTicketId}</SimpleLink>
            </Grid>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </Grid>
    );
};

export default LinkedTicketIdLink;
