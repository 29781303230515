import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import Grid from "@material-ui/core/Grid";

import { getPrice } from "../../helpers/global";
import { ICONS_SITE } from "../../styles/icons";
import { getAdditionalFieldName, handleAdditionalFields } from "../../helpers/projects";
import { selectMeasureSystem } from "../../selectors";
import { parseMetersToUserSystem } from "../../constants/accounts";

const truckItemStyles = makeStyles(() => ({
    poliLabel: {
        "& svg": {
            width: 16,
            height: 16,
            top: `3px !important`,
            margin: 0,
        },
    },
}));

const PoLineItemLabel = (props) => {
    const siteIconsClasses = ICONS_SITE();
    const classes = truckItemStyles();
    const {
        item: { payload, perUnitPrice, unitOfMeasure, pickUpSite, dropOffSites, additionalFields, distance },
        measureSystem,
    } = props;

    return (
        <div className={clsx(classes.poliLabel)}>
            {`${_.isObject(payload) ? payload.name : payload}${getPrice(perUnitPrice, unitOfMeasure)}`}
            <Grid container>
                {(pickUpSite || dropOffSites) && (
                    <Grid item xs={6}>
                        {pickUpSite ? (
                            <div className="hide-when-closed">
                                <RoomIcon className={clsx(siteIconsClasses.iconSite, "pick-up")} />
                                {`${pickUpSite.name}`}
                            </div>
                        ) : (
                            ""
                        )}
                        {dropOffSites ? (
                            <div className="hide-when-closed">
                                <RoomIcon className={clsx(siteIconsClasses.iconSite, "drop-off")} />
                                {`${dropOffSites.map((i) => i.name).join(", ")}`}
                            </div>
                        ) : (
                            ""
                        )}
                        {distance && (
                            <Grid className="hide-when-closed">
                                Distance: {parseMetersToUserSystem(distance, measureSystem)}
                            </Grid>
                        )}
                    </Grid>
                )}
                {additionalFields && (
                    <Grid item xs={6} className="hide-when-closed">
                        {handleAdditionalFields(additionalFields).map((fieldName) => (
                            <div>
                                {getAdditionalFieldName(fieldName)}: {additionalFields[fieldName]}
                            </div>
                        ))}
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

PoLineItemLabel.propTypes = {
    item: PropTypes.object.isRequired,
    measureSystem: PropTypes.number,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        measureSystem: selectMeasureSystem(state),
    }))(PoLineItemLabel),
);
