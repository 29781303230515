import React from "react";
import _ from "lodash";

import { makeStyles } from "@material-ui/core";

import TableWithStickyHeader from "../core/tableWithStickyHeader";
import TrailersActionMenu from "./trailersActionMenu";

function TrailersList({ trailers, actions }) {
    const getTableData = (trailers) => {
        const rows =
            trailers &&
            trailers.map((trailer, index) => {
                const { id, name, capacity, volumeCapacity, axle } = trailer;

                return {
                    key: id,
                    index,
                    id,
                    name,
                    capacity,
                    volumeCapacity,
                    axle,
                    action: (
                        <TrailersActionMenu
                            editTrailer={() => actions.editTrailer(trailer)}
                            archiveTrailer={() => actions.archiveTrailer(trailer)}
                        />
                    ),
                };
            });
        let columns = [
            {
                id: "id",

                label: "TRAILER ID",
            },
            {
                id: "name",

                label: "TRAILER NAME",
            },
            {
                id: "capacity",

                label: "WEIGHT CAPACITY",
            },
            {
                id: "volumeCapacity",

                label: "Volume Capacity ",
            },
            {
                id: "axle",

                label: "axle",
            },
            {
                id: "action",
                label: "OTHER ACTIONS",
            },
        ];

        return {
            rows: rows || [],
            columns: columns,
        };
    };
    const tableData = getTableData(trailers);

    if (_.isEmpty(trailers))
        return (
            <React.Fragment>
                <br />
                <h1 className="--text-center">No trailers match your search / filter parameters.</h1>
                <br />
            </React.Fragment>
        );

    return <TableWithStickyHeader rows={tableData.rows} columns={tableData.columns} />;
}

export default TrailersList;
