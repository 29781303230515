import React, { useState } from "react";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";

import {
    addManagedHauler,
    deleteManagedHauler,
    getHasSubCompanies,
    getManagedHaulersLoadOptions,
} from "../../actions/companies";
import { selectCompanyForManagingHaulers } from "../../selectors/companies";
import { ButtonsGroup, ErrorNotification, Loader, PrimaryButton } from "../core";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import SuccessNotification from "../core/successNotification";
import AsyncAutocompleteComponent, {
    AUTOCOMPLETE_FORM_VIEW_CLASS,
    REMOVE_ACTION_TYPE,
    SELECT_ACTION_TYPE,
} from "../core/form/asyncAutocompleteComponent";
import { updatePerPageParamForMultiSelector } from "../../helpers/global";

const useStyles = makeStyles((theme) => ({
    managedHaulersForm: {
        minWidth: "40vw",
        maxWidth: "60vw",
    },
}));

const ManagedHaulersForm = ({ closeModal, form }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(null);

    const managedHaulers = useSelector((state) => formValueSelector(form)(state, MANAGED_HAULERS));
    const companyForManagingHaulers = useSelector(selectCompanyForManagingHaulers);

    const loadOptions = async (inputValue, { params = {}, loadedCount }) => {
        const requestParams = updatePerPageParamForMultiSelector(params, managedHaulers);

        return await dispatch(getManagedHaulersLoadOptions(inputValue, requestParams, loadedCount, setError));
    };

    const onHaulerChange = async (values, actionMeta) => {
        const { action } = actionMeta;
        let response;

        setLoading(true);
        try {
            if (action === SELECT_ACTION_TYPE) {
                const option = actionMeta.option;
                response = await dispatch(addManagedHauler(companyForManagingHaulers.id, option.company.id, setError));
            } else if (action === REMOVE_ACTION_TYPE) {
                const removedValue = actionMeta.removedValue;
                response = await dispatch(
                    deleteManagedHauler(companyForManagingHaulers.id, removedValue.value, setError),
                );
            }
            if (response?.status === 200) {
                setSuccess(["Managed haulers were updated"]);
                dispatch(getHasSubCompanies(setError));
                dispatch(change(MANAGED_HAULERS_FORM, MANAGED_HAULERS, values));
            }
        } finally {
            setLoading(false);
        }
    };

    if (!companyForManagingHaulers) {
        return null;
    }

    return (
        <form className={clsx(loading && LOADER_WHITE_OVERLAY, classes.managedHaulersForm)}>
            {loading && <Loader />}
            <h2 className="title">Managed Haulers Of {companyForManagingHaulers.name}</h2>
            <Field
                name="managedHaulers"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                loadOptions={loadOptions}
                component={AsyncAutocompleteComponent}
                isMulti={true}
                isClearable={false}
                onFieldChange={onHaulerChange}
            />
            <br />
            <ButtonsGroup>
                <PrimaryButton onClick={closeModal}>Ok</PrimaryButton>
            </ButtonsGroup>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {success && <SuccessNotification message={success} config={{ onClose: () => setSuccess(null) }} />}
        </form>
    );
};

export const MANAGED_HAULERS_FORM = "managedHaulersForm";
export const MANAGED_HAULERS = "managedHaulers";

export default reduxForm({ form: MANAGED_HAULERS_FORM, enableReinitialize: true })(ManagedHaulersForm);
