import _ from "lodash";

export const BUNDLES_VIEW_BY = {
    BUNDLE: "bundle",
    TRUCK: "truck",
};
export const BUNDLES_VIEWS = [
    { key: BUNDLES_VIEW_BY.BUNDLE, title: "view by bundles", value: BUNDLES_VIEW_BY.BUNDLE },
    { key: BUNDLES_VIEW_BY.TRUCK, title: "view by trucks", value: BUNDLES_VIEW_BY.TRUCK },
];

const JOB_ORDERS_JO_TAB = 0;
const JOB_ORDERS_BUNLDE_TAB = 1;

export const SMART_DISPATCH_TABS = {
    [JOB_ORDERS_JO_TAB]: "job-orders",
    [JOB_ORDERS_BUNLDE_TAB]: "job-bundles",
};

export const setUpUrl =
    (push) =>
    (params = {}) => {
        const SD_JOB_BUNDLE_VIEW_BY_BUNDLES = "/jobs/smart-dispatch/job-bundles/bundle";
        const SD_JOB_ORDERS = "/jobs/smart-dispatch/job-orders";
        const SD_JOB_BUNDLES_BUNDLE_TRUCK_ID = `/jobs/smart-dispatch/job-bundles/truck/${params.bundleId}/${params.routeNumber}`;
        const SD_JOB_BUNDLES_BUNDLE_BUNDLE_ID = `/jobs/smart-dispatch/job-bundles/bundle/${params.bundleId}`;
        const SD_JOB_BUNDLES_VIEW_BY = `/jobs/smart-dispatch/job-bundles/${params.viewBy}`;
        const SD_JOB_BUNDLES_TAB = `/jobs/smart-dispatch/${SMART_DISPATCH_TABS[params.currentTab]}/bundle`;
        const SD_JOB_JOB_ORDERS_TAB = `/jobs/smart-dispatch/${SMART_DISPATCH_TABS[params.currentTab]}`;

        return {
            push: (url) => {
                if (!_.isUndefined(params.bundleId) && !_.isUndefined(params.routeNumber))
                    return push(SD_JOB_BUNDLES_BUNDLE_TRUCK_ID);
                if (!_.isUndefined(params.bundleId)) return push(SD_JOB_BUNDLES_BUNDLE_BUNDLE_ID);
                if (!_.isUndefined(params.viewBy)) return push(SD_JOB_BUNDLES_VIEW_BY);
                if (!_.isUndefined(params.currentTab)) {
                    return params.currentTab ? push(SD_JOB_BUNDLES_TAB) : push(SD_JOB_JOB_ORDERS_TAB);
                }
                if (url) return push(url);
            },
            viewByBundle: SD_JOB_BUNDLE_VIEW_BY_BUNDLES,
            jobOrdersTab: SD_JOB_ORDERS,
        };
    };
