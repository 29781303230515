import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Field, formValueSelector } from "redux-form";
import UnlimitedAdornment from "./unlimitedAdornment";
import TextInputComponent from "./form/textInputComponent";
import HiddenInput from "./form/hiddenInput";
import { connect } from "react-redux";
import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "./form/asyncAutocompleteComponent";

const QuantityWithUnlimited = (props) => {
    const {
        name,
        qtyOnBlur,
        form,
        dispatch,
        setUnlimitedHandler,
        validation,
        fieldNote,
        disabled,
        disableUnlimitedButton,
        formValues: { unlimited, unitOfMeasure },
    } = props;

    return (
        <div>
            <Field
                name={name}
                type="number"
                validate={unlimited ? [] : [Validation.required, ...validation]}
                placeholder="Quantity"
                disabled={unlimited || disabled}
                label="Quantity"
                onBlur={(e) => qtyOnBlur && qtyOnBlur(e.target.value)}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                fieldNote={fieldNote}
                endAdornment={
                    <UnlimitedAdornment
                        form={form}
                        disabled={disabled || disableUnlimitedButton}
                        dispatch={dispatch}
                        quantityName={name}
                        unitOfMeasure={unitOfMeasure}
                        unitOfMeasureFromProps={unitOfMeasure}
                        setUnlimitedHandler={setUnlimitedHandler}
                    />
                }
                component={TextInputComponent}
            />
            <Field name={`${name}Error`} component={HiddenInput} />
            <Field name="unlimited" component={HiddenInput} />
        </div>
    );
};

QuantityWithUnlimited.propTypes = {
    name: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    qtyOnBlur: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    setUnlimitedHandler: PropTypes.func,
    validation: PropTypes.array.isRequired,
    fieldNote: PropTypes.string,
    disabled: PropTypes.bool,
    disableUnlimitedButton: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

QuantityWithUnlimited.defaultProps = {
    name: "quantity",
    validation: [],
};

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            formValues: formSelector(state, "unitOfMeasure", "unlimited"),
        };
    })(QuantityWithUnlimited),
);
