import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router";

import clsx from "clsx";
import Loader from "../components/core/loader";

import { connect } from "react-redux";
import JobOrderDetails from "../components/jobs/jobDetails/jobOrderDetails";
import { reduxForm } from "redux-form";
import { ErrorBoundary } from "../components/core/errorBoudaries";
import { LOADER_WHITE_OVERLAY } from "../constants/global";

function JobOrder(props) {
    const { jobOrders, noOrdersLoaded } = props;

    const [isLoading, setLoading] = useState(!noOrdersLoaded);

    useEffect(() => {
        setLoading(false);
    }, [noOrdersLoaded]);

    return (
        <div className="job-order-wrapper">
            <div
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
                style={{
                    position: "relative",
                    zIndex: 1,
                }}
            >
                {!_.isEmpty(jobOrders) && (
                    <ErrorBoundary>
                        <JobOrderDetails jobOrder={jobOrders[0]} />
                    </ErrorBoundary>
                )}
                {noOrdersLoaded && (
                    <React.Fragment>
                        <br />
                        <br />
                        <br />
                        <h1 className="--text-center">No data to show</h1>
                    </React.Fragment>
                )}
            </div>
            {isLoading && <Loader />}
        </div>
    );
}

JobOrder.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    jobOrders: PropTypes.array.isRequired,
    noOrdersLoaded: PropTypes.bool,
};

JobOrder = connect((state) => {
    return {
        jobOrders: state.jobOrders.list,
        account: state.account,
        noOrdersLoaded: state.jobOrders.noOrdersLoaded,
    };
})(JobOrder);

export default withRouter(
    reduxForm({
        form: "jobOrderForm",
    })(JobOrder),
);
