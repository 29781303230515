import _ from "lodash";
import moment from "moment";
import { SubmissionError, getFormInitialValues, initialize } from "redux-form";

import {
    GET_COMPANY_TRUCKS_SUCCESS,
    IS_TRUCKS_LOADING,
    LIVE_MAP_TRUCKS_FAILED,
    LIVE_MAP_TRUCKS_LOADED,
    LIVE_MAP_TRUCKS_LOADING,
    RESET_COMPANY_TRUCKS,
    RESET_TRUCKS_DATA,
    SET_TRUCK_FOR_EDIT,
    TRUCKS_DIALOG_TOGGLE,
    UPDATE_ACTIVE_TRUCK_ORDER,
} from "../events/trucks";
import {
    TRUCK_EVENTS_ERROR,
    TRUCK_EVENTS_REQUEST,
    TRUCK_EVENTS_RESET,
    TRUCK_EVENTS_RESET_META,
    TRUCK_EVENTS_RESULT,
    TRUCK_EVENTS_SET_SUSPEND_PERIOD,
} from "../events/globalEvents";
import { selectAccountTimezone } from "../selectors/index";
import * as TrucksDataServer from "../dataServers/trucks";
import { PROCESS_SERVER_ERROR, SERVER_DATE_FORMAT } from "../constants/global";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import { USER_ROLE_TRUCKER } from "../constants/maps";
import { TRUCK_CUSTOM_FIELDS_FORM } from "../constants/forms";
import { returnDropDownData } from "../helpers/global";
import { FormDataController, handleError } from "../helpers/global";

export const getLiveMapTrucks = (concat, params = {}) => {
    return (dispatch, getState) => {
        dispatch({
            type: LIVE_MAP_TRUCKS_LOADING,
            payload: {},
        });
        const state = getState();

        return TrucksDataServer.getLiveMapTrucks({
            ...params,
        })
            .then((response) => {
                dispatch({
                    type: LIVE_MAP_TRUCKS_LOADED,
                    payload: {
                        liveMapTrucks: concat ? [...state.trucks.liveMapTrucks, ...response.data] : response.data,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: LIVE_MAP_TRUCKS_FAILED,
                    payload: {
                        liveMapTrucksError: error.message,
                    },
                });
            });
    };
};

export const handleTruckDialogToggle = (open) => {
    return {
        type: TRUCKS_DIALOG_TOGGLE,
        open,
    };
};

export const setTruckForEdit = (payload) => {
    return {
        type: SET_TRUCK_FOR_EDIT,
        payload,
    };
};

export const resetTrucks = () => {
    return {
        type: RESET_COMPANY_TRUCKS,
    };
};

export const loadCompanyTrucks =
    (params, concat = false) =>
    (dispatch, getState) => {
        const state = getState();
        const activeOrder = state.trucks.activeTruckOrder;
        const currentPage = Math.ceil(state.trucks.trucksList.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.trucks.trucksList) ? +currentPage + 1 : 1;
        const parameters = {
            ...params,
            order: activeOrder,
            page: concat ? nextPage : 1,
        };

        dispatch({
            type: IS_TRUCKS_LOADING,
            payload: true,
        });

        TrucksDataServer.getCompanyTrucks(parameters)
            .then((truckData) => {
                if (truckData) {
                    const newTrucks = truckData.data;
                    const payload = {
                        trucksList: concat ? [...state.trucks.trucksList, ...newTrucks] : newTrucks,
                        trucksCount: truckData.meta.count,
                    };

                    dispatch({
                        type: GET_COMPANY_TRUCKS_SUCCESS,
                        payload,
                    });
                }
            })
            .catch(() => {
                dispatch({
                    type: IS_TRUCKS_LOADING,
                    payload: false,
                });
            });
    };

export const getTruckCurrentLocation = (params) => (dispatch, getState) => {
    const state = getState();
    const { liveMapTrucks } = state.trucks;

    dispatch({
        type: LIVE_MAP_TRUCKS_LOADING,
        payload: {},
    });

    TrucksDataServer.getCurrentLocationOfTruck(params)
        .then((response) => {
            dispatch({
                type: LIVE_MAP_TRUCKS_LOADED,
                payload: {
                    liveMapTrucks: [...liveMapTrucks, ...response.data],
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: LIVE_MAP_TRUCKS_FAILED,
                payload: {
                    liveMapTrucksError: error.message,
                },
            });
        });
};

export const updateActiveTruckOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_TRUCK_ORDER,
        payload: order,
    };
};

export const resetTrucksData = () => {
    return {
        type: RESET_TRUCKS_DATA,
    };
};

const getJobTitle = (e, timezone) =>
    `Job # ${e.jobOrderId} - ${e.projectName} ${moment(e.startDate).tz(timezone).format("hh:mm A")}`;

const requestTruckEvents = () => ({ type: TRUCK_EVENTS_REQUEST });
const resultTruckEvents = (payload) => ({ type: TRUCK_EVENTS_RESULT, payload });
const setActiveSuspendPeriod = (payload) => ({ type: TRUCK_EVENTS_SET_SUSPEND_PERIOD, payload });
const truckEventsError = (payload) => ({ type: TRUCK_EVENTS_ERROR, payload });
const resetTruckEventsMeta = () => ({ type: TRUCK_EVENTS_RESET_META });

export const resetTruckEvents = () => ({ type: TRUCK_EVENTS_RESET });

export const getTruckEvents = (truckId, params) => async (dispatch, getState) => {
    const state = getState();
    await dispatch(requestTruckEvents());
    try {
        const timezone = selectAccountTimezone(state);
        const dates = params
            ? {
                  startDate: moment(params.start).format(SERVER_DATE_FORMAT),
                  endDate: moment(params.end).format(SERVER_DATE_FORMAT),
              }
            : {
                  startDate: moment().startOf("month").subtract(6, "days").format(SERVER_DATE_FORMAT),
                  endDate: moment().endOf("month").add(6, "days").format(SERVER_DATE_FORMAT),
              };
        const {
            data: { scheduledJobs, activeSuspendPeriod },
        } = await TrucksDataServer.getTruckActivity(truckId, dates);

        const jobOrdersEvents =
            scheduledJobs &&
            scheduledJobs.map((event) => {
                if (event.isScheduledJob) {
                    event.border = `1px solid #b96211`;
                    event.background = "#fbf7f3";
                }

                return {
                    ...event,
                    start: new Date(moment(event.startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")),
                    end: new Date(moment(event.startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")),
                    startDate: new Date(moment(event.startDate).tz(timezone).format("MM/DD/YYYY hh:mm A")),
                    label: getJobTitle(event, timezone),
                    timezone,
                };
            });
        const unavailableDayEvents =
            activeSuspendPeriod &&
            activeSuspendPeriod.map((period) => {
                return {
                    start: new Date(period.startDate),
                    end: new Date(period.endDate),
                    label: `Truck is unavailable (reason: ${period.reason})`,
                    unavailableDayEvent: true,
                    timezone,
                };
            });
        const allEvents = [...jobOrdersEvents, ...unavailableDayEvents];
        await dispatch(resultTruckEvents(allEvents));

        const period =
            activeSuspendPeriod &&
            activeSuspendPeriod.map((period) => {
                return {
                    start: period.startDate ? new Date(period.startDate) : null,
                    end: period.endDate ? new Date(period.endDate) : null,
                    label: period.reason,
                    timezone,
                    isSuspendPeriod: true,
                    ...period,
                };
            });
        await dispatch(setActiveSuspendPeriod(period));
    } catch (error) {
        await dispatch(truckEventsError(PROCESS_SERVER_ERROR(error)));
        await dispatch(resetTruckEventsMeta());
    }
};

export const getDrivers = (inputValue, { params = {}, loadedCount } = {}) =>
    TrucksDataServer.getUsersWithTrucks({ keywords: inputValue || null, role: USER_ROLE_TRUCKER, ...params }).then(
        ({ data: drivers, meta }) => {
            const results = drivers.map((user) => ({
                ...user,
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }));

            return returnDropDownData(results, meta, loadedCount);
        },
    );

export const getDriversWithLinkEvents = (inputValue, { params = {}, loadedCount } = {}, data) => {
    return TrucksDataServer.getDriversWithLinkEvents(data.truckId, {
        keywords: inputValue || null,
        ...params,
        date: data.date,
    }).then(({ data: drivers, meta }) => {
        const results = drivers.map((user) => ({
            ...user,
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
        }));

        return returnDropDownData(results, meta, loadedCount);
    });
};

export const setCustomFields =
    (id, values, currentTruck, { setError, onSuccess }) =>
    async (dispatch, getState) => {
        try {
            const initValues = getFormInitialValues(TRUCK_CUSTOM_FIELDS_FORM)(getState());
            const addfields = currentTruck.additionalFields;
            const fieldsToAddArray = values.additionalFields
                .filter((i) => !_.isObject(i.value))
                .map((i) => ({ name: i.name, value: i.value }));
            const fieldsToRemove =
                addfields &&
                Object.keys(addfields)
                    .filter((k) => k !== "attached_files")
                    .map((i) => i)
                    .filter((i) => !!i);

            if (_.isEqual(initValues, values)) return;

            const data = new FormData();
            const dataController = new FormDataController(data);
            const body = {};

            const fieldsToAdd = fieldsToAddArray?.reduce((result, field) => {
                const fieldName = field.name;
                result[fieldName] = field.value;

                return { ...result };
            }, {});

            if (values && values.additionalFields) {
                values.additionalFields.forEach((field) => {
                    if (field.value instanceof File) {
                        if (
                            addfields &&
                            addfields.attached_files &&
                            field.fileId &&
                            addfields.attached_files[field.fileId]
                        ) {
                            body.filesToRemove = [...(body.filesToRemove || []), field.fileId];
                        }
                        data.append(field.name, field.value);
                    }
                });
            }

            if (values.filesToRemove && values.filesToRemove.length) {
                body.filesToRemove = [...(body.filesToRemove || []), ...values.filesToRemove];
            }

            dataController.appendStringValue("deviceName", currentTruck.truckName);
            data.append("company", currentTruck.company.id);

            if (fieldsToRemove) body.fieldsToRemove = fieldsToRemove;
            if (fieldsToAdd) body.fieldsToAdd = fieldsToAdd;

            !_.isEmpty(body) && data.append("additionalFields", JSON.stringify(body));

            await TrucksDataServer.editTruck(id, data);

            onSuccess();
        } catch (error) {
            setError(handleError(error)?.message);
        }
    };
