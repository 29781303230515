import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { DEFAULT_DATE_FORMAT, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Validation } from "../../helpers/validation";
import { compose } from "redux";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import DateTimePicker from "../core/form/dateTimePicker";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { getJobOrders, getTrucksTabData, makeDemoAirTicket, makeDemoJobOrder } from "../../dataServers/jobOrder";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { getCompanies } from "../../dataServers/companies";
import ErrorNotification from "../core/notification";
import { formatReduxFormErrors } from "../../helpers/errorFormatting";
import SuccessNotification from "../core/successNotification";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { convertDateToUserTimeZone } from "../../helpers/jobOrders";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
    fieldContainer: {
        margin: "20px 0",
    },
    fieldItem: {
        marginTop: 20,
    },
    paperTabs: {
        boxShadow: "unset",
    },
    label: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.secondary.dark,
        fontSize: 16,
    },
}));

function ControlPanel(props) {
    const {
        account,
        handleClose,
        handleSubmit,
        form,
        error,
        formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate, jobOrder, company, dateToCopy, truck },
        dispatch,
    } = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isAirTicket, setIsAirTIcket] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setIsAirTIcket(!!newValue);
    };

    const loadJobOrders = (inputValue, { params = {}, loadedCount } = {}) => {
        const start = isAirTicket ? new Date() : startDate;
        const end = isAirTicket ? new Date() : endDate;

        let paramsData = {
            [START_DATE_NAME]: moment(start).toDate(),
            [END_DATE_NAME]: moment(end).toDate(),
            keywords: inputValue,
            ...params,
            flat: !isAirTicket,
        };

        return getJobOrders(paramsData).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: `Job # ${i.id} - Project ${i.projectName}`,
            }));

            return {
                options: isAirTicket ? results.filter((item) => item) : results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadTrucks = (inputValue, { params = {}, loadedCount }) => {
        return getTrucksTabData(jobOrder.id, { keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((truck) => ({
                value: truck.id,
                label: `Truck ${truck.name}`,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const onJobOrderChanged = () => {
        dispatch(change(form, "company", ""));
    };

    const onSubmit = () => {
        setIsLoading(true);

        if (!isAirTicket) {
            return makeDemoJobOrder({
                companyId: company.value,
                jobOrderId: jobOrder.id,
                dateToCopy: convertDateToUserTimeZone(dateToCopy, account.timezone),
            })
                .then(() => {
                    setIsLoading(false);
                    setIsHandledSuccessfully(true);
                    handleClose();
                })
                .catch((errorResponse) => {
                    setIsLoading(false);
                    formatReduxFormErrors(errorResponse);
                });
        }

        return makeDemoAirTicket({
            jobOrderId: jobOrder.id,
            truckId: +truck.value,
        })
            .then(() => {
                setIsHandledSuccessfully(true);
                setIsLoading(false);
                handleClose();
            })
            .catch((errorResponse) => {
                setIsLoading(false);
                formatReduxFormErrors(errorResponse);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <Paper className={classes.paperTabs}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab label="Simulate Job Order" />
                    <Tab label="Simulate Air Ticket" />
                </Tabs>
            </Paper>
            {isAirTicket ? (
                <>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <Field
                                name="jobOrder"
                                placeholder="Job Order"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                onChange={() => dispatch(change(form, "truck", null))}
                                validate={[Validation.required]}
                                loadOptions={loadJobOrders}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <Field
                                name="truck"
                                placeholder="Select Truck"
                                disabled={_.isEmpty(jobOrder)}
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                validate={[Validation.required]}
                                loadOptions={loadTrucks}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <DatesRange form={form} dateFormat={DEFAULT_DATE_FORMAT} />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <Field
                                name="dateToCopy"
                                placeholder="Date to copy"
                                validate={[Validation.required]}
                                fieldNote="Date to copy Job Order"
                                inputVariant={"filled"}
                                component={DateTimePicker}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <Field
                                name="jobOrder"
                                placeholder="Job Order"
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                validate={[Validation.required]}
                                onChange={onJobOrderChanged}
                                loadOptions={loadJobOrders}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.fieldContainer}>
                        <Grid item xs={12}>
                            <Field
                                name="company"
                                placeholder="New company"
                                disabled={_.isEmpty(jobOrder)}
                                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                                validate={[Validation.required]}
                                loadOptions={loadCompanies}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container className={classes.fieldContainer} justify="center" alignItems="center">
                <Grid item>
                    <PrimaryButton type="submit">Confirm</PrimaryButton>
                </Grid>
            </Grid>
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
            {error && <ErrorNotification error={error} />}
        </form>
    );
}

ControlPanel.propTypes = {
    account: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    error: PropTypes.any,
};

export const CONTROL_PANEL_FORM = "controlPanel";

export default withRouter(
    compose(
        reduxForm({
            form: CONTROL_PANEL_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(
                    state,
                    START_DATE_NAME,
                    END_DATE_NAME,
                    "dateToCopy",
                    "jobOrder",
                    "company",
                    "truck",
                ),
            };
        }),
    )(ControlPanel),
);
