import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Grid } from "@material-ui/core";

import { SimpleLink } from "../../core/link";
import { TICKET_CATEGORY_NAME } from "../../../helpers/predictiveSearchHelpers";
import ErrorNotification from "../../core/notification";
import { useSearchTickets } from "../../../hooks";

const TicketNumber = ({ externalRef, ticketNumber, additionalExternalRef, form, createdDate, isDuplicate }) => {
    const [onSearch, error] = useSearchTickets(
        {
            page: 1,
            perPage: 20,
            order: "-createdDate",
            keywords: "ticketExtRef::" + (externalRef || ticketNumber),
            startDate: null,
            endDate: null,
        },
        form,
        {
            value: "ticketExtRef::" + (externalRef || ticketNumber),
            categoryName: TICKET_CATEGORY_NAME.ticketExtRef,
            labelValue: externalRef || ticketNumber,
        },
    );

    if (!_.isEmpty(additionalExternalRef)) {
        return (
            <div>
                {additionalExternalRef.map((ref) => {
                    return (
                        <div>
                            <span className="--font-900">{ref.label.name}: </span>
                            <span className="--font-400">{ref.externalRef}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <>
            {isDuplicate ? (
                <SimpleLink tooltipTitle={"Has a duplicate. Click on the number to see it"} onClick={onSearch}>
                    Ticket #{externalRef || ticketNumber}
                </SimpleLink>
            ) : (
                <Grid item>Ticket #{externalRef || ticketNumber}</Grid>
            )}
            {error && <ErrorNotification error={[error]} />}
        </>
    );
};

TicketNumber.propTypes = {
    externalRef: PropTypes.string,
    ticketNumber: PropTypes.string,
    additionalExternalRef: PropTypes.array,
    form: PropTypes.string,
    createdDate: PropTypes.string,
    isDuplicate: PropTypes.bool,
};

export default TicketNumber;
