import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    data: null,
    meta: {
        loading: false,
        error: null,
    },
};

const additionalExternalRefs = createSlice({
    name: "additionalExternalRefs",
    initialState,
    reducers: {
        requestAdditionalExternalRefs(state, action) {
            state.meta.loading = true;
        },
        resultAdditionalExternalRefs(state, { payload }) {
            state.meta.loading = false;
            if (payload.error) {
                state.meta.error = payload.error.message;
            } else {
                state.data = payload;
            }
        },
        initialize(state, action) {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
    },
});

export const { actions } = additionalExternalRefs;

export const selectAdditionalExternalRefs = (state) => state.additionalExternalRefs.data;
export const selectHasAdditionalExternalRefs = (state) => !_.isEmpty(selectAdditionalExternalRefs(state));
export const selectAdditionalExternalRefsOptions = (state) => {
    const additionalExternalRefs = selectAdditionalExternalRefs(state) || [];

    return additionalExternalRefs.map((ref) => {
        return {
            value: ref.id,
            label: ref.name,
        };
    });
};
export const selectDefaultAdditionalExternalRef = (state) => {
    const refs = selectAdditionalExternalRefs(state) || [];

    return refs.find((ref) => ref.isDefault) || {};
};
export const selectAdditionalExternalRefsMeta = (state) => state.additionalExternalRefs.meta;

export default additionalExternalRefs.reducer;
