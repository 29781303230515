export const ADD_PROJECT_NETSUITE_INTEGRATION_WARNING_TEXT = "Disabled because of NetSuite integration";
export const ADD_PROJECT_UPGRADE_TO_FULL_ACCESS_WARNING_TEXT = "Upgrade to full-access account to create projects.";

export const CHANGE_RESTRICTED_CUSTOMER_TYPE_WARNING =
    "Are you sure you want to change customer type? Restricted customer will be removed";
export const CHANGE_CUSTOMER_TYPE_WARNING =
    "Are you sure you want to change customer type? Customer and Foreman / Point of contact will be removed";

export const NOTIFICATIONS_RECIPIENTS_LABEL = "Recipients Of Drop Off Request Notifications";
export const ONE_RECIPIENT_REQUIRED_ERROR_MESSAGE = "At least one recipient is required";
