import React, { useEffect, useState } from "react";
import {
    handleChartResetZoom,
    handleChartZoomIn,
    handleChartZoomOut,
    useTooltipChartPosition,
} from "../../helpers/chartHelpers/chartTimeRangeHandlers";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Tooltip } from "@material-ui/core";
import { ChartContainer, ChartRow, Charts, EventChart, Resizable } from "react-timeseries-charts";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import _ from "lodash";
import { TimeRange, TimeRangeEvent, TimeSeries } from "pondjs";
import { useSelector } from "react-redux";
import { selectAccountTimezone } from "../../selectors";
import { makeStyles } from "@material-ui/core/styles";
import { convertTimeToJsFormat } from "../../helpers/jobOrders";
import {
    SITE_STATS_ALL_EVENTS_TYPE,
    SITE_STATS_ALL_EVENTS_TYPE_COLOR,
    SITE_STATS_TRUCK_EVENTS_TYPE,
    SITE_STATS_TRUCK_EVENTS_TYPE_COLOR,
} from "../../constants/maps";
import { SAME_TIME_OFFSET } from "../reportAndAnalytic/truckAllActivitiesChart";

const createSiteTimeRangeEvent = (start, end, timezone) => {
    const startDate = convertTimeToJsFormat(start, timezone);
    const endDate = convertTimeToJsFormat(end ? end : start, timezone);
    return new TimeRange(startDate, endDate);
};

const generateAllSiteSeriesEvents = (allEvents, timezone) => {
    const sortedEvents = allEvents.sort((a, b) => {
        return convertTimeToJsFormat(a.start || a.end, timezone) - convertTimeToJsFormat(b.start || b.end, timezone);
    });

    return sortedEvents.map(({ start, end }) => {
        const range = createSiteTimeRangeEvent(start, end, timezone);
        return new TimeRangeEvent(range, {
            type: SITE_STATS_ALL_EVENTS_TYPE,
        });
    });
};

const generateTruckSiteSeriesEvents = (events, timezone) => {
    return events.map((truckEventData) => {
        const truckEvents = truckEventData?.timelineEvents.map(({ start, end }) => {
            const range = createSiteTimeRangeEvent(start, end, timezone);
            return new TimeRangeEvent(range, {
                title: truckEventData.truck?.deviceName,
                type: SITE_STATS_TRUCK_EVENTS_TYPE,
            });
        });

        return {
            truckId: truckEventData.truck?.id,
            deviceName: truckEventData.truck?.deviceName,
            truckEvents,
        };
    });
};

const outageEventStyleCB = (event, state) => {
    let color;

    switch (event.get("type")) {
        case SITE_STATS_TRUCK_EVENTS_TYPE:
            color = SITE_STATS_TRUCK_EVENTS_TYPE_COLOR;
            break;
        case SITE_STATS_ALL_EVENTS_TYPE:
            color = SITE_STATS_ALL_EVENTS_TYPE_COLOR;
            break;
        default:
            color = SITE_STATS_ALL_EVENTS_TYPE_COLOR;
    }

    const style = { fill: color, pointerEvents: "none" };

    if (event.get("type") === SITE_STATS_ALL_EVENTS_TYPE) {
        style.opacity = 0.2;
    }

    return style;
};

const styles = makeStyles((theme) => ({
    chartIcon: {
        fontSize: 20,
    },

    chartContainer: {
        width: "90%",

        "& svg text": {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            fill: "#000 !important",
        },
        "& svg line": {
            fontSize: 12,
            fontWeight: 500,
            fontFamily: `${theme.typography.fontFamily} !important`,
            stroke: "#000 !important",
        },
    },

    siteStatsChartWrapper: {
        display: "flex",
        flexDirection: "row",
    },

    trucksNamesContainer: {
        width: "10%",
        minWidth: "fit-content",
        display: "flex",
        flexDirection: "column",

        "& .truck-name": {
            whiteSpace: "nowrap",
            fontWeight: 700,
            fontSize: 14,
            marginBottom: 10,
        },
    },
}));

const SiteStats = ({ siteStatsData }) => {
    const classes = styles();
    const timezone = useSelector(selectAccountTimezone);

    const [minMaxTimes, setMinMaxTimes] = useState(null);
    const [timeRange, setTimeRange] = useState(null);
    const [allSiteEvents, setAllSiteEvents] = useState(null);
    const [truckSiteEvents, setTruckSiteEvents] = useState(null);

    const [showTip, currentTitle, handleHoverEvent, getBoundingClientRect, onMouseMove, handleTooltipClose] =
        useTooltipChartPosition();

    const handleResetZoom = () => {
        handleChartResetZoom(timeRange, setTimeRange, minMaxTimes);
    };

    const handleZoomIn = () => {
        handleChartZoomIn(timeRange, setTimeRange);
    };

    const handleZoomOut = () => {
        handleChartZoomOut(timeRange, setTimeRange, minMaxTimes);
    };

    const handleTimeRangeChange = (newTimeRange) => {
        // @todo workaround before  https://github.com/esnet/react-timeseries-charts/issues/303 will be merged
        if (Math.abs(newTimeRange.duration() - timeRange.duration()) < SAME_TIME_OFFSET) {
            setTimeRange(newTimeRange);
        }
    };

    useEffect(() => {
        if (siteStatsData) {
            const allEvents = [];
            siteStatsData.forEach((item) => {
                allEvents.push(...item.timelineEvents);
            });
            const minTime = convertTimeToJsFormat(_.min(allEvents.map((item) => item.start)), timezone);
            const maxTime = convertTimeToJsFormat(_.max(allEvents.map((item) => item.end)), timezone);

            const updatedAllSiteEvents = generateAllSiteSeriesEvents(allEvents, timezone);
            const updatedTruckSiteEvents = generateTruckSiteSeriesEvents(siteStatsData, timezone);

            if (!_.isEmpty(updatedAllSiteEvents) && !_.isEmpty(updatedTruckSiteEvents)) {
                setAllSiteEvents(new TimeSeries({ name: "allSiteEvents", events: updatedAllSiteEvents }));
                setTruckSiteEvents(updatedTruckSiteEvents);
            }
            setTimeRange(new TimeRange(minTime, maxTime));
            setMinMaxTimes({
                minTime: minTime,
                maxTime: maxTime,
            });
        }

        return () => {
            setAllSiteEvents(null);
            setTruckSiteEvents(null);
        };
    }, [siteStatsData]);

    return (
        <>
            {!_.isEmpty(allSiteEvents) && !_.isEmpty(truckSiteEvents) ? (
                <div className={classes.siteStatsChartWrapper}>
                    <div className={classes.trucksNamesContainer}>
                        {truckSiteEvents.map(({ truckId, deviceName }) => {
                            return (
                                <div className="truck-name" key={truckId}>
                                    {deviceName}
                                </div>
                            );
                        })}
                        <div className="truck-name">All Site Activity</div>
                    </div>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            onMouseMove={onMouseMove}
                            PopperProps={{
                                anchorEl: {
                                    clientHeight: 0,
                                    clientWidth: 0,
                                    getBoundingClientRect: getBoundingClientRect,
                                },
                            }}
                            onClose={handleTooltipClose}
                            open={showTip}
                            placement="bottom"
                            disableFocusListener
                            disableTouchListener
                            title={currentTitle}
                        >
                            <div className={classes.chartContainer}>
                                <>
                                    <Resizable>
                                        <ChartContainer
                                            timeRange={timeRange}
                                            minTime={minMaxTimes.minTime}
                                            maxTime={minMaxTimes.maxTime}
                                            enablePanZoom={true}
                                            paddingRight={25}
                                            paddingLeft={25}
                                            onTimeRangeChanged={handleTimeRangeChange}
                                        >
                                            {truckSiteEvents.map((truckData) => {
                                                const { truckId, truckEvents } = truckData;
                                                const series = new TimeSeries({
                                                    name: `${truckId}Series`,
                                                    events: truckEvents,
                                                });

                                                return (
                                                    <ChartRow height={30} key={truckId}>
                                                        <Charts>
                                                            <EventChart
                                                                onMouseLeave={(event) => handleHoverEvent(event, true)}
                                                                onMouseOver={(event) => handleHoverEvent(event)}
                                                                series={series}
                                                                size={45}
                                                                style={outageEventStyleCB}
                                                            />
                                                        </Charts>
                                                    </ChartRow>
                                                );
                                            })}
                                            <ChartRow height={30}>
                                                <Charts height={100}>
                                                    <EventChart
                                                        series={allSiteEvents}
                                                        size={45}
                                                        style={outageEventStyleCB}
                                                    />
                                                </Charts>
                                            </ChartRow>
                                        </ChartContainer>
                                    </Resizable>
                                    <Box width={1} display="flex" justifyContent="center">
                                        <Tooltip title="Reset zoom">
                                            <IconButton aria-label="reset-zoom" onClick={handleResetZoom}>
                                                <RefreshIcon height="100%" className={clsx(classes.chartIcon)} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Zoom In">
                                            <IconButton aria-label="add-zoom" onClick={handleZoomIn}>
                                                <AddIcon className={clsx(classes.chartIcon)} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Zoom out">
                                            <IconButton aria-label="remove-zoom" onClick={handleZoomOut}>
                                                <RemoveIcon className={clsx(classes.chartIcon)} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </>
                            </div>
                        </Tooltip>
                    </ClickAwayListener>
                </div>
            ) : (
                <h1 className="--text-center">No Site Stats</h1>
            )}
        </>
    );
};
export default SiteStats;
