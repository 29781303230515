import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
import Box from "@material-ui/core/Box";
import TruckPillWrapper from "../../../core/pills";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StartDate from "../../../core/time";
import { GET_HAULER_NEED_TRUCKS_COUNT } from "../../jobOrder/jolis/jolisTable";

const TruckRow = (props) => {
    const { item, specifyTrucksPerHauler, showQty, jobOrder, allItems, trailers } = props;

    return (
        // adjust height for trailers and add flex-direction: column to tr
        <div className={clsx("tr", !_.isEmpty(item.trucks) && "-no-height")} style={{ height: "auto" }}>
            <div className="td flex-2">
                <Box
                    component="div"
                    style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}
                >
                    <TruckPillWrapper
                        title={item.truckName || item.name}
                        specifyNumberOfTrucks={item.needTrucksCount}
                        allowButtons={false}
                        needTrucksCount={
                            !item.isMyFleetTruck &&
                            (specifyTrucksPerHauler
                                ? item.neededTrucksCount || 0
                                : GET_HAULER_NEED_TRUCKS_COUNT(
                                      {
                                          ...item,
                                          ownerCompanyId: jobOrder.company && jobOrder.company.id,
                                      },
                                      allItems,
                                  ))
                        }
                        haulerName={item.companyName}
                        truckName={item.truckName || item.name}
                        isMyFleet={item.isMyFleetTruck}
                        truckId={item.id || item.truckId}
                        trailers={trailers}
                    />
                    {item.trucks &&
                        item.trucks.map((subTruck, index) => {
                            return (
                                <div key={index} className="sub-trucks-pill">
                                    <TruckPillWrapper
                                        title={subTruck.truckName}
                                        showIcon={false}
                                        specifyNumberOfTrucks={false}
                                        iconContent={<LocalShippingIcon />}
                                        className="sub-truck"
                                        truckName={subTruck.truckName}
                                        trailers={trailers}
                                        isMyFleet={false}
                                    />
                                </div>
                            );
                        })}
                </Box>
            </div>
            <div className="td">
                <div>
                    <StartDate time={item.startDate} fontSize={16} />
                </div>
            </div>
            {showQty && (
                <div className="--text-center td">
                    <div>{item.joliQuantity}</div>
                </div>
            )}
        </div>
    );
};

TruckRow.propTypes = {
    item: PropTypes.object.isRequired,
    showQty: PropTypes.bool.isRequired,
    allItems: PropTypes.array.isRequired,
    specifyTrucksPerHauler: PropTypes.bool.isRequired,
    jobOrder: PropTypes.object.isRequired,
};

TruckRow.defaultProps = {
    showQty: false,
    specifyTrucksPerHauler: false,
};

export default TruckRow;
