export const hostname = window.location.hostname;

export const api = {
    localhost: "https://test-api.truckit.com:443/api",

    "portal.truckit.com": "//api.truckit.com/api",
    "portal.truckitapp.com": "//api.truckit.com/api",

    "test.truckit.com": "//test-api.truckit.com/api",
    "test.truckitapp.com": "//test-api.truckit.com/api",

    "demo.truckit.com": "//demo-api.truckit.com/api",
    "demo.truckitapp.com": "//demo-api.truckit.com/api",

    "stage.truckit.com": "//stage-api.truckit.com/api",
    "stage.truckitapp.com": "//stage-api.truckit.com/api",
};

const websocket = {
    localhost: "wss://test-api.truckit.com/ws/notifications",

    "portal.truckit.com": "wss://api.truckit.com/ws/notifications",
    "portal.truckitapp.com": "wss://api.truckit.com/ws/notifications",

    "test.truckit.com": "wss://test-api.truckit.com/ws/notifications",
    "test.truckitapp.com": "wss://test-api.truckit.com/ws/notifications",

    "demo.truckit.com": "wss://demo-api.truckit.com/ws/notifications",
    "demo.truckitapp.com": "wss://demo-api.truckit.com/ws/notifications",

    "stage.truckit.com": "wss://stage-api.truckit.com/ws/notifications",
    "stage.truckitapp.com": "wss://stage-api.truckit.com/ws/notifications",
};

export const signUpRedirectUrl = "//truckit.com/forms/signup/";
export const apiBase = api[hostname];
export const websocketBase = websocket[hostname];
