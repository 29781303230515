import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import ProjectCheckIcon from "../global/checkIcon";
import Grid from "@material-ui/core/Grid";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Loader from "../core/loader";
import { LIST_ITEM_STYLES } from "../../styles/reusableStyles";
import { PROJECT_STATUS_ARCHIVED } from "../../constants/maps";

const ProjectItem = React.memo(
    (props) => {
        const classesListItem = LIST_ITEM_STYLES();
        const {
            projectsLoading,
            setActiveProject,
            project: { externalJobNumber, name, grantedAccess, status, company, owner },
            project,
            selectedProject,
            isDispatch,
        } = props;
        const grantedBy = grantedAccess && (company?.name || owner?.name);
        const customerName = project?.customerName || project?.customer?.name;
        const archived = +(_.isObject(status) ? status.id : status) === PROJECT_STATUS_ARCHIVED;

        return (
            <div
                onClick={() => !projectsLoading && setActiveProject(project)}
                className={clsx(
                    classesListItem.listItem,
                    selectedProject && "-current",
                    projectsLoading && "-is-loading container-loader-wrap",
                    (grantedAccess || archived) && "-not-my-project",
                )}
                title={
                    grantedAccess
                        ? "You have readonly access for that project"
                        : archived
                        ? "This project is archived"
                        : ""
                }
            >
                {selectedProject && <ProjectCheckIcon />}
                <Grid component="div" container alignItems={"center"} wrap={"nowrap"}>
                    <Grid component="div" item className="icon">
                        <EventNoteIcon />
                    </Grid>
                    {projectsLoading && (
                        <Grid component="div" item>
                            <Loader />
                        </Grid>
                    )}
                    <Grid component="div" item xs={11}>
                        <h3 className="name" title={name}>
                            {name}
                        </h3>
                        {!!(isDispatch && externalJobNumber) && (
                            <div className="--text-14 ext-number" title={externalJobNumber}>
                                {externalJobNumber}
                            </div>
                        )}
                    </Grid>
                </Grid>
                {customerName ? <h4>Customer Name: {customerName}</h4> : null}
                {grantedBy ? <h4>Granted By: {grantedBy}</h4> : null}
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

ProjectItem.propTypes = {
    project: PropTypes.object.isRequired,
    setActiveProject: PropTypes.func.isRequired,
    projectsLoading: PropTypes.bool.isRequired,
    selectedProject: PropTypes.bool.isRequired,
    isDispatch: PropTypes.bool.isRequired,
};

export default ProjectItem;
