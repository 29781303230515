import {
    WEBSOCKET_EVENT_TYPE_CROWDED_ZONE,
    WEBSOCKET_EVENT_TYPE_IDLE_TIME,
    WEBSOCKET_EVENT_TYPE_LATE_DRIVER,
    WEBSOCKET_EVENT_TYPE_MISSING_TICKET,
    WEBSOCKET_EVENT_TYPE_SLOW_TRIP,
    WEBSOCKET_EVENT_TYPE_UNKNOWN,
} from "../../../constants/maps";
import { timeFromSeconds } from "../../../helpers/jobOrders";

const notificationTitles = {
    [WEBSOCKET_EVENT_TYPE_UNKNOWN]: "Unknown event",
    [WEBSOCKET_EVENT_TYPE_IDLE_TIME]: "|{truck.deviceName}| is idle for more than {idle_time_threshold}",
    [WEBSOCKET_EVENT_TYPE_CROWDED_ZONE]: "|{alertZoneName}| contains more than {alertZoneNamethreshold}",
    [WEBSOCKET_EVENT_TYPE_SLOW_TRIP]:
        "Trip duration of |{truck.deviceName}| is more than {slow_trip_duration}x estimated time",
    [WEBSOCKET_EVENT_TYPE_LATE_DRIVER]:
        "Truck |{truck.deviceName}| is more than {late_driver_time} late to pickup site",
    [WEBSOCKET_EVENT_TYPE_MISSING_TICKET]:
        "|{truck.deviceName}| number of tickets does not match geozone entrance/exits",
};

const getNotificationTitle = (event) => {
    return notificationTitles[event.type]
        .replace(
            "{truck.deviceName}",
            (event.truck || {}).name
                ? `${(event.truck || {}).name.toUpperCase()}&link`
                : (event.truck || {}).deviceName
                ? `${(event.truck || {}).deviceName.toUpperCase()}&link`
                : "Unknown",
        )
        .replace("{idle_time_threshold}", timeFromSeconds(event.threshold) || {} || "Unknown")
        .replace("{alertZoneName}", event.alertZoneName || "Unknown")
        .replace(
            "{alertZoneNamethreshold}",
            event.threshold === 1 ? `${event.threshold} truck` : `${event.threshold} trucks`,
        )
        .replace("{slow_trip_duration}", event.threshold || "Unknown")
        .replace("{late_driver_time}", timeFromSeconds(event.threshold) || {} || "Unknown");
};

export const processAnomalyDetectionEvent = ({ event }) => {
    return {
        ...event,
        notificationTitle: getNotificationTitle(event),
    };
};
