import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
    changePayloadStatus,
    deletePayload,
    getSettingsPayloads,
    payloadSettingsFailureMessage,
    payloadSettingsSuccessMessage,
    settingsPayloadsFiltersSet,
    updatePayload,
} from "../../actions/payloadsSettings";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import { ActionLink, SimpleLink } from "../core/link";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { makeStyles } from "@material-ui/styles";
import TabsCustom from "../core/tabsCustom";
import { ArchiveOutlined, UnarchiveOutlined } from "@material-ui/icons";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AppModal from "../core/modal";
import PayloadOptionForm, { NEW_PAYLOAD_OPTION_FORM } from "../poLineItems/payloadOptionForm";
import PropTypes from "prop-types";
import ConfirmationModal from "../core/confirmationModal";
import { IS_ADMIN_USER } from "../../constants/maps";
import { formValueSelector } from "redux-form";
import SettingsSearch from "./settingsSearch";
import SettingsCompanySelector from "./settingsCompanySelector";

const useStyles = makeStyles(() => ({
    payloadsTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
}));

const Payloads = ({
    isLoading,
    payloadsList,
    account,
    payloadsListCount,
    payloadsFilters,
    success,
    error,
    selectedCompany,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const companyToRequestId = selectedCompany?.value || account.company.id;
    const [activeTab, setActiveTab] = useState(0);
    const [openCreateOrUpdatePayloadModal, setOpenCreateOrUpdatePayloadModal] = useState(false);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [openArchiveConfirmationModal, setOpenArchiveConfirmationModal] = useState(false);
    const [confirmationModalPayload, setConfirmationModalPayload] = useState(undefined);
    const [activePayloadToUpdate, setActivePayloadToUpdate] = useState(undefined);

    const updateListByFilters = (filters, clear) => {
        let newFilters = { ..._.cloneDeep(payloadsFilters), ..._.cloneDeep(filters) };
        if (!_.isEqual(payloadsFilters, newFilters)) {
            dispatch(settingsPayloadsFiltersSet(newFilters));
        }
    };

    const handlePayloadSaveSuccess = (successMessage) => {
        dispatch(payloadSettingsSuccessMessage(successMessage));
    };

    const fetchPayloads = () => {
        dispatch(getSettingsPayloads(false, companyToRequestId, payloadsFilters));
    };

    const handleUpdatePayload = (updatedPayload) => {
        dispatch(updatePayload(updatedPayload));
    };

    const handleCloseConfirmationModal = () => {
        setOpenArchiveConfirmationModal(false);
        setOpenDeleteConfirmationModal(false);
        setConfirmationModalPayload(undefined);
    };

    const onDeletePayload = () => {
        const { id, name } = confirmationModalPayload;
        dispatch(deletePayload(id, name));
        handleCloseConfirmationModal();
    };

    const onArchivePayload = () => {
        const { archived, id, name } = confirmationModalPayload;
        dispatch(changePayloadStatus(archived, id, name));
        handleCloseConfirmationModal();
    };

    const getTableData = () => {
        const rows = payloadsList.map((payload, index) => {
            const { name, id, archived, company, canBeArchived, canBeRemoved } = payload;

            const setProjectStatus = () => {
                dispatch(changePayloadStatus(archived, id, name));
            };

            const onEditPayloadClick = () => {
                const updatedPayload = {
                    id,
                    name,
                };
                if (company) {
                    updatedPayload.company = {
                        value: company.id,
                        label: company.name,
                    };
                }
                setActivePayloadToUpdate(updatedPayload);
                setOpenCreateOrUpdatePayloadModal(true);
            };

            const renderOtherActionsButtons = () => {
                if (archived) {
                    return (
                        <IconButton title="Unarchive payload" onClick={() => setProjectStatus()}>
                            <UnarchiveOutlined />
                        </IconButton>
                    );
                } else {
                    return (
                        <>
                            <IconButton title="Edit payload" onClick={() => onEditPayloadClick()}>
                                <EditIcon />
                            </IconButton>
                            {canBeArchived && (
                                <IconButton
                                    title="Archive payload"
                                    onClick={() => {
                                        setOpenArchiveConfirmationModal(true);
                                        setConfirmationModalPayload({ archived, id, name });
                                    }}
                                >
                                    <ArchiveOutlined />
                                </IconButton>
                            )}
                            {canBeRemoved && (
                                <IconButton
                                    title="Delete payload"
                                    onClick={() => {
                                        setOpenDeleteConfirmationModal(true);
                                        setConfirmationModalPayload({ id, name });
                                    }}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            )}
                        </>
                    );
                }
            };

            return {
                index,
                name,
                companyName: company.name,
                action: (
                    <Grid container justify={"center"} style={{ minHeight: 50 }}>
                        {renderOtherActionsButtons()}
                    </Grid>
                ),
            };
        });
        let columns = [
            {
                id: "name",
                label: "Name",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        if (IS_ADMIN_USER(account.company.id)) {
            columns.splice(1, 0, {
                id: "companyName",
                label: "Company Name",
            });
        }

        return {
            rows: rows,
            columns: columns,
        };
    };

    const getMorePayloads = (concat) => dispatch(getSettingsPayloads(concat, companyToRequestId, payloadsFilters));

    const onAddNewPayloadClick = () => {
        setActivePayloadToUpdate(null);
        setOpenCreateOrUpdatePayloadModal(true);
    };

    const errorHandler = (error) => {
        dispatch(payloadSettingsFailureMessage(error));
    };

    useEffect(() => {
        if (!_.isEmpty(payloadsFilters)) {
            dispatch(getSettingsPayloads(false, companyToRequestId, payloadsFilters));
        }
    }, [payloadsFilters, selectedCompany]);

    useEffect(() => {
        const updatedFilters = {
            ..._.cloneDeep(payloadsFilters),
            archived: activeTab === 1,
        };
        if (!_.isEqual(updatedFilters, payloadsFilters)) {
            dispatch(settingsPayloadsFiltersSet(updatedFilters));
        }
    }, [activeTab]);

    useEffect(() => {
        return () => {
            dispatch(settingsPayloadsFiltersSet({}));
        };
    }, []);

    return (
        <>
            <Grid container spacing={1} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <Grid container direction="row" justify="space-between">
                    <Grid item justify="flex-start">
                        <ActionLink>
                            <AddCircleOutlineIcon />
                            <SimpleLink onClick={() => onAddNewPayloadClick()}>Add new payload</SimpleLink>
                        </ActionLink>
                    </Grid>
                    <Grid item xs={6}>
                        <SettingsSearch
                            updateListByFilters={updateListByFilters}
                            form={PAYLOADS_SETTINGS_FILTERS_FORM}
                        />
                    </Grid>
                    {IS_ADMIN_USER(account.company.id) && (
                        <SettingsCompanySelector
                            form={SETTINGS_PAYLOAD_COMPANY_SELECTOR}
                            initialValues={{
                                company: {
                                    value: account.company.id,
                                    label: account.company.name,
                                },
                            }}
                        />
                    )}
                    <TabsCustom
                        tabsHeaders={[{ label: "Active" }, { label: "Archive" }]}
                        onChangeTab={(tab) => setActiveTab(tab)}
                    />
                </Grid>
                {!_.isEmpty(payloadsList) ? (
                    <Grid item>
                        <TableWithStickyHeader
                            rows={getTableData().rows}
                            fetchMore={getMorePayloads}
                            itemsCount={payloadsListCount}
                            className={clsx(classes.payloadsTable)}
                            columns={getTableData().columns}
                        />
                    </Grid>
                ) : isLoading ? null : (
                    <Grid container justify="center">
                        <h1 className="--text-center">No payloads match your search</h1>
                    </Grid>
                )}
            </Grid>
            {!_.isEmpty(success) && (
                <SuccessNotification
                    message={success}
                    config={{ onClose: () => dispatch(payloadSettingsSuccessMessage([])) }}
                />
            )}
            {!_.isEmpty(error) && (
                <ErrorNotification
                    message={error}
                    config={{ onClose: () => dispatch(payloadSettingsFailureMessage([])) }}
                />
            )}
            <AppModal
                isOpen={!!openCreateOrUpdatePayloadModal}
                form={NEW_PAYLOAD_OPTION_FORM}
                closeModal={() => setOpenCreateOrUpdatePayloadModal(false)}
            >
                <PayloadOptionForm
                    closeModal={() => setOpenCreateOrUpdatePayloadModal(false)}
                    handlePayloadSaveSuccess={handlePayloadSaveSuccess}
                    payloadToUpdateId={activePayloadToUpdate?.id}
                    initialValues={activePayloadToUpdate}
                    errorHandler={errorHandler}
                    fetchPayloads={fetchPayloads}
                    handleUpdatePayload={handleUpdatePayload}
                />
            </AppModal>
            <ConfirmationModal
                isOpen={openDeleteConfirmationModal || openArchiveConfirmationModal}
                question={`Are you sure you want to ${openDeleteConfirmationModal ? "delete" : "archive"} payload?`}
                yesHandler={openDeleteConfirmationModal ? onDeletePayload : onArchivePayload}
                noHandler={() => handleCloseConfirmationModal()}
            />
        </>
    );
};

Payloads.propTypes = {
    account: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    payloadsList: PropTypes.array,
    payloadsListCount: PropTypes.number,
    payloadsFilters: PropTypes.object,
    success: PropTypes.string,
    error: PropTypes.string,
    selectedCompany: PropTypes.object,
};

export const SETTINGS_PAYLOAD_COMPANY_SELECTOR = "settingsPayloadCompanySelector";
export const PAYLOADS_SETTINGS_FILTERS_FORM = "payloadsSettingsFiltersForm";

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(SETTINGS_PAYLOAD_COMPANY_SELECTOR);

        return {
            account: state.account,
            payloadsList: state.payloadsSettings.payloadsList,
            payloadsListCount: state.payloadsSettings.payloadsListCount,
            payloadsFilters: state.payloadsSettings.payloadsFilters,
            isLoading: state.payloadsSettings.isLoading,
            success: state.payloadsSettings.success,
            error: state.payloadsSettings.error,
            selectedCompany: formSelector(state, "company"),
        };
    })(Payloads),
);
