import dataProvider from "../helpers/api";
import { JOB_ORDERS, SMART_DISPATCH } from "../constants/endpoints";
import { SERVER_DATE_FORMAT } from "../constants/global";
import moment from "moment";

export const createBundleRequest = (data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH(),
        method: "POST",
        data,
    });
};

export const fetchBundlesList = (params) => {
    const requestParams = params;
    if (requestParams.keywords === "") {
        delete requestParams.keywords;
    }

    const { startDate, endDate } = requestParams;
    if (startDate) {
        requestParams.startDate = moment(startDate).format(SERVER_DATE_FORMAT);
    }
    if (endDate) {
        requestParams.endDate = moment(endDate).format(SERVER_DATE_FORMAT);
    }

    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH(),
        method: "GET",
        params: requestParams,
    });
};

export const assignIntradayRouteRequest = (bundleId, routeId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_ID_ROUTE_ID(bundleId, routeId),
        method: "PUT",
        data: { assignTruckId: data.assignments[0].truckId },
    });
};

export const assignNextDayRouteRequest = (bundleId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_ID(bundleId),
        method: "PUT",
        data,
    });
};

export const updateRouteRequest = (bundleId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_ID(bundleId),
        method: "PUT",
        data,
    });
};

export const fetchBundleItemDetails = (bundleId) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_ID(bundleId),
        method: "GET",
    });
};

export const fetchBundleRouteDetails = (bundleId, routeIndex) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_ROUTE_INDEX(bundleId, routeIndex),
        method: "GET",
    });
};

export const fetchRoutes = (params) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_ROUTES(),
        method: "GET",
        params,
    });
};

export const confirmBundle = (bundleId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_BUNDLE_CONFIRM(bundleId),
        method: "POST",
        data,
    });
};

export const recalculateBundle = (bundleId, data) => {
    return dataProvider({
        url: JOB_ORDERS.SMART_DISPATCH_RECALCULATE_BUNDLE(bundleId),
        method: "POST",
        data,
    });
};

export const replaceTrucks = (bundleId, data) => {
    return dataProvider({
        url: SMART_DISPATCH.REPLACE_TRUCK(bundleId),
        method: "PUT",
        data,
    });
};
