import moment from "moment";
import _ from "lodash";
import dataProvider, { fileUploader } from "../helpers/api";
import { GLOBAL_COUNT_TO_LOAD, JOB_ORDERS, TICKETS } from "../constants/endpoints";
import { SERVER_DATE_FORMAT } from "../constants/global";
import { END_DATE_NAME, START_DATE_NAME } from "../components/global/datesRange";
import { getDuplicateTicketParam } from "../helpers/tickets";

export const getTickets = (params = {}, isInspectorUser, overriddenFields) => {
    const extendedParams = {};
    const joLineItemId = _.isArray(params.joLineItemId)
        ? _.union(params.joLineItemId).join(",")
        : params.joLineItemId || undefined;
    if (joLineItemId) {
        extendedParams.joLineItemId = joLineItemId;
    }

    if (!_.isNil(params?.signed)) {
        params.signed = !!+params.signed;
    }

    return dataProvider({
        url: isInspectorUser ? TICKETS.AIR_TICKETS() : TICKETS.TICKETS(),
        method: "GET",
        params: {
            ...params,
            ...extendedParams,
            order: "-createdDate",
            page: params.page || 1,
            perPage: params.perPage || GLOBAL_COUNT_TO_LOAD,
            [START_DATE_NAME]: params[START_DATE_NAME]
                ? moment.utc(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : moment().format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: params[END_DATE_NAME]
                ? moment.utc(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT)
                : moment().format(SERVER_DATE_FORMAT),
            keywords: params.keywords,
            jobOrder: params.jobOrder,
            truck: params.truck,
            duplicates: getDuplicateTicketParam(params.duplicates),
            ...overriddenFields,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getTicketById = (id, params = {}) => {
    return dataProvider({
        ...params,
        url: TICKETS.TICKET_BY_ID(id),
        method: "GET",
    });
};

export const getTicketsMaterial = (params = {}) => {
    const extendedParams = {};
    const joLineItemId = _.isArray(params.joLineItemId)
        ? _.union(params.joLineItemId).join(",")
        : params.joLineItemId || undefined;
    if (joLineItemId) {
        extendedParams.joLineItemId = joLineItemId;
    }

    if (!_.isNil(params?.signed)) {
        params.signed = !!+params.signed;
    }

    return dataProvider({
        url: TICKETS.TICKETS_MATERIALS(),
        method: "GET",
        params: {
            ...params,
            ...extendedParams,
            page: params.page || 1,
            perPage: params.perPage || GLOBAL_COUNT_TO_LOAD,
            startDate: params.startDate
                ? moment(params.startDate).format(SERVER_DATE_FORMAT)
                : moment().format(SERVER_DATE_FORMAT),
            endDate: params.endDate
                ? moment(params.endDate).format(SERVER_DATE_FORMAT)
                : moment().format(SERVER_DATE_FORMAT),
            keywords: params.keywords,
            duplicates: getDuplicateTicketParam(params.duplicates),
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const verifyTicket = (data) => {
    return dataProvider({
        url: TICKETS.VERIFY(),
        method: "POST",
        data,
    });
};

export const createTicket = (data) => {
    return dataProvider({
        url: TICKETS.TICKETS(),
        method: "POST",
        data,
    });
};

export const createManualTicket = (data) => {
    return dataProvider({
        url: TICKETS.MANUAL_TICKETS(),
        method: "POST",
        data,
    });
};

export const updateTicketRequest = (id, data) => {
    return dataProvider({
        url: TICKETS.TICKET_BY_ID(id),
        method: "PUT",
        data,
    });
};

export const closeTicket = (ticketId, data) => {
    return dataProvider({
        url: TICKETS.CLOSE(ticketId),
        method: "POST",
        data,
    });
};
export const reopenTicketById = (ticketId) => {
    return dataProvider({
        url: TICKETS.REOPEN(ticketId),
        method: "POST",
    });
};

export const deleteTicket = (ticketId, data) => {
    return dataProvider({
        url: TICKETS.DELETE(ticketId),
        method: "POST",
        data,
    });
};

export const reconcileTicket = (ticketId, data) => {
    return dataProvider({
        url: TICKETS.RECONCILE(ticketId),
        method: "PUT",
        data,
    });
};

export const reconcileAirTicket = (ticketId, data) => {
    return dataProvider({
        url: TICKETS.AIR_TICKETS_RECONCILE(ticketId),
        method: "PUT",
        data,
    });
};

export const downloadTicketsReport = (params) => {
    params = {
        ...params,
        order: "-createdDate",
        [START_DATE_NAME]: params[START_DATE_NAME]
            ? moment.utc(params[START_DATE_NAME]).format(SERVER_DATE_FORMAT)
            : moment().format(SERVER_DATE_FORMAT),
        [END_DATE_NAME]: params[END_DATE_NAME]
            ? moment.utc(params[END_DATE_NAME]).format(SERVER_DATE_FORMAT)
            : moment().format(SERVER_DATE_FORMAT),
        keywords: params.keywords,
        jobOrder: _.isArray(params.jobOrders) ? params.jobOrders.map((i) => i.id || i).join(",") : undefined,
        joLineItemId: _.isArray(params.joLineItemId)
            ? _.union(params.joLineItemId).join(",")
            : params.joLineItemId || undefined,
        truck: params.truck,
        duplicates: getDuplicateTicketParam(params.duplicates),
    };

    delete params.jobOrders;

    return dataProvider({
        url: TICKETS.REPORT(),
        method: "GET",
        responseType: "blob",
        params,
    }).then((response) => {
        const url = window.URL.createObjectURL(response instanceof Blob ? response : new Blob([response.data]));
        const link = document.createElement("a");
        const filename = response.headers["content-disposition"].split("filename=")[1].replaceAll(`"`, "");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    });
};

export const getJobBoardForCreatingTicket = (params) => {
    return dataProvider({
        url: JOB_ORDERS.JOB_BOARD_FOR_TICKETS(),
        method: "GET",
        params: {
            order: "-createDate",
            page: params.page || 1,
            perPage: params.count || GLOBAL_COUNT_TO_LOAD,
        },
    });
};

export const getTicketsStats = (params) => {
    return dataProvider({
        url: TICKETS.STATS(),
        method: "GET",
        params,
    });
};
export const getTicketsMapDetails = (ticketId) => {
    return dataProvider({
        url: TICKETS.MAP_DETAILS(ticketId),
        method: "GET",
    });
};

export const downloadTicketsTicPicks = (params) => {
    return dataProvider({
        url: TICKETS.TIC_PICS(),
        method: "GET",
        responseType: "blob",
        params: params && {
            ...params,
            duplicates: getDuplicateTicketParam(params.duplicates),
        },
    });
};

export const openTicketsTicPicksPDF = (url) => {
    return dataProvider({
        url: url,
        method: "GET",
        responseType: "blob",
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
        link.click();
    });
};

export const uploadTicPic = (ticketId, file) => {
    return fileUploader(TICKETS.NOTES(ticketId), file, { method: "POST" });
};

export const getTicketTicPics = (ticketId) => {
    return dataProvider({
        url: TICKETS.NOTES(ticketId),
        method: "GET",
    });
};

export const getAirTicketTicPics = (ticketId) => {
    return dataProvider({
        url: TICKETS.AIR_TICKET_NOTES(ticketId),
        method: "GET",
    });
};

export const removeTicPics = (attachmentsIds = []) => {
    return dataProvider({
        url: TICKETS.ATTACHMENTS(),
        method: "DELETE",
        data: { attachments: attachmentsIds },
    });
};

export const getTicketsCount = (params) => {
    return dataProvider({
        url: TICKETS.COUNT(),
        method: "GET",
        params: {
            ...params,
            startDate: params.startDate ? moment.utc(params.startDate).format(SERVER_DATE_FORMAT) : null,
            endDate: params.endDate ? moment.utc(params.endDate).format(SERVER_DATE_FORMAT) : null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const fetchTicketsSearchOptions = (params) => {
    if (!params.keywords) {
        return Promise.resolve([]).then(() => ({
            options: [],
        }));
    }

    return dataProvider({
        url: TICKETS.SEARCH(),
        method: "GET",
        params,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};
