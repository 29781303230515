import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";

import TextInputComponent from "../core/form/textInputComponent";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import clsx from "clsx";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { Validation } from "../../helpers/validation";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { createCustomer } from "../../dataServers/companies";
import { IS_ADMIN_USER } from "../../constants/maps";

const useStyles = makeStyles(() => ({
    companyForm: {
        width: 400,
    },
}));

const CompanyFormLite = (props) => {
    const { account, currentProject, handleSubmit, closeModal, onCustomerCreate } = props;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const onSubmit = (values) => {
        setIsLoading(true);

        return createCustomer({
            companyId: IS_ADMIN_USER(account.company.id) ? currentProject.owner.id : account.company.id,
            customerName: values.name,
        })
            .then(({ data }) => {
                onCustomerCreate(data);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    return (
        <div className={clsx(classes.companyForm, isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <form noValidate={true}>
                <Field
                    type="text"
                    name="name"
                    label="Customer Name"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={handleSubmit(onSubmit)}>Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {error && <ErrorNotification error={error} />}
        </div>
    );
};

CompanyFormLite.propTypes = {
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    onCustomerCreate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    company: PropTypes.object,
    error: PropTypes.string,
};

CompanyFormLite.defaultProps = {
    formValues: {},
};

export const COMPANY_FORM = "companyFormLite";

export default withRouter(
    compose(
        reduxForm({
            form: COMPANY_FORM,
        }),
        connect((state) => ({
            account: state.account,
            currentProject: state.projects.currentProject,
        })),
    )(CompanyFormLite),
);
