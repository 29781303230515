import dataProvider, { fileUploader } from "../helpers/api";
import { ADMIN_ONLY, COMPANIES, GLOBAL_COUNT_TO_LOAD, GLOBAL_ENDPOINTS, MY_FLEET, USER } from "../constants/endpoints";
import { FILTERS_COMPANIES_IDS_ID } from "../components/global/searchAndFilters";

export const getCompanyForEdit = (id, params = {}) => {
    return dataProvider({
        url: USER.USER_PROFILE(id),
        method: "GET",
        params,
    });
};

export const getTimezones = (params) => {
    return dataProvider({
        url: GLOBAL_ENDPOINTS.TIMEZONES(),
        method: "GET",
        params,
    });
};

export const getCompanies = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.HAULERS(),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords || undefined,
            withArchived: params.withArchived,
            [FILTERS_COMPANIES_IDS_ID]: params[FILTERS_COMPANIES_IDS_ID],
            type: params.type,
            order: params.order || null,
            parentCompanies: params.parentCompanies || null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const fetchParentCompanies = (params = {}) => {
    return dataProvider({
        url: MY_FLEET.PARENT_COMPANIES(),
        method: "GET",
        params,
    });
};

export const getCustomersByCompany = (companyId, params = {}) => {
    return dataProvider({
        url: MY_FLEET.CUSTOMERS(companyId),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            withArchived: params.withArchived,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getCompanyById = (id, params = {}) => {
    return dataProvider({
        url: ADMIN_ONLY.COMPANY_BY_ID(id),
        method: "GET",
        params,
    });
};

export const archiveCompany = (id, params = {}) => {
    return dataProvider({
        url: ADMIN_ONLY.ARCHIVE_COMPANY(id),
        method: "PUT",
        params,
    });
};

export const validateCompanyById = (id) => {
    return dataProvider({
        url: ADMIN_ONLY.VALIDATE(id),
        method: "PUT",
    });
};

export const createCompany = (data) => {
    return dataProvider({
        url: ADMIN_ONLY.COMPANIES_LIST_API_1(),
        method: "POST",
        data,
    });
};

export const createCustomer = (data) => {
    return dataProvider({
        url: ADMIN_ONLY.CUSTOMERS(),
        method: "POST",
        data,
    });
};

export const updateCompany = (companyId, data) => {
    return dataProvider({
        url: ADMIN_ONLY.COMPANY_BY_ID(companyId),
        method: "PUT",
        data,
    });
};

export const updateCompanyProfile = (companyId, data) => {
    return dataProvider({
        url: USER.USER_PROFILE(companyId),
        method: "PUT",
        data,
    });
};

export const updateCompanyStripeSubscription = (companyId, data) => {
    return dataProvider({
        url: USER.STRIPE_SUBSCRIPTION(companyId),
        method: "PUT",
        data,
    });
};

export const getCompanyStripeSubscriptionRequest = (companyId) => {
    return dataProvider({
        url: USER.STRIPE_SUBSCRIPTION(companyId),
        method: "GET",
    });
};

export const uploadCompanyCertificate = (companyId, file) => {
    return fileUploader(USER.CERTIFICATE(companyId), file, { method: "POST" });
};

export const inviteHauler = (data) => {
    return dataProvider({
        url: USER.INVITE_HAULER(),
        method: "POST",
        data,
    });
};

export const fetchSubCompanies = (params = {}) => {
    return dataProvider({
        url: COMPANIES.SUB_COMPANIES(),
        method: "GET",
        params: params && {
            ...params,
            keywords: params.keywords || null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const fetchMangedHaulers = (params) => {
    return dataProvider({
        url: MY_FLEET.HAULERS(),
        method: "GET",
        params: params && {
            ...params,
            keywords: params.keywords || null,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const deleteManagedHaulersRequest = (data) => {
    return dataProvider({
        url: COMPANIES.SUB_COMPANIES(),
        method: "DELETE",
        data,
    });
};

export const addManagedHaulerRequest = (data) => {
    return dataProvider({
        url: COMPANIES.SUB_COMPANIES(),
        method: "POST",
        data,
    });
};

export const fetchRestrictedCustomers = (companyId, params = {}) => {
    return dataProvider({
        url: COMPANIES.RESTRICTED_CUSTOMERS(companyId),
        method: "GET",
        params,
    });
};

export const createRestrictedCustomer = (companyId, data) => {
    return dataProvider({
        url: COMPANIES.RESTRICTED_CUSTOMERS(companyId),
        method: "POST",
        data,
    });
};
