import PropTypes from "prop-types";
import React from "react";

import Box from "@material-ui/core/Box";
import clsx from "clsx";

const UnitOfMeasure = (props) => {
    const { component, value, isPlural, className } = props;

    return (
        <Box component={component} className={clsx("--uppercase", className)}>
            {value}
            {isPlural && "s"}
        </Box>
    );
};

UnitOfMeasure.propTypes = {
    component: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isPlural: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

UnitOfMeasure.defaultProps = {
    component: "span",
    isPlural: true,
};

export default UnitOfMeasure;
