export const TEAM_DIALOG_TOGGLE = "teamDialogToggle";
export const SET_USER_FOR_EDIT = "serUserForEdit";
export const GET_TEAM_USERS_SUCCESS = "getTeamUsersSuccess";
export const RESET_TEAMS_USERS = "resetTeamUsers";
export const IS_LOADING_USERS = "isLoadingUsers";
export const UPDATE_ACTIVE_TEAM_USERS_ORDER = "updateActiveTeamUsersOrder";

export const REQUEST_USER_EVENTS = "REQUEST USER EVENTS";
export const RESULT_USER_EVENTS = "RESULT USER EVENTS";
export const RESET_USER_EVENTS = "RESET USER EVENTS";
export const USER_EVENTS_ERROR = "USER EVENTS ERROR";
export const USER_EVENTS_SUSPEND_PERIOD = "USER EVENTS SUSPEND PERIOD";
export const USER_EVENTS_RESET_META = "USER EVENTS RESET META";
