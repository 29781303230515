import {
    TRUCKS_ACTIVITY_SET_ACTIVE_TRUCK,
    TRUCKS_ACTIVITY_SET_MAP_DATA,
    TRUCKS_ACTIVITY_SET_NOTIFICATION_SELECTED_TRUCK,
    TRUCKS_ACTIVITY_SET_TIMESTAMP_FOR_MAP,
} from "../events/trucksActivityActions";

export const setTrucksActivityMapTimestamp = (time) => {
    return {
        type: TRUCKS_ACTIVITY_SET_TIMESTAMP_FOR_MAP,
        time,
    };
};

export const setTrucksActivityMapData = (mapData) => {
    return {
        type: TRUCKS_ACTIVITY_SET_MAP_DATA,
        mapData,
    };
};

export const setTrucksActivityActiveTruck = (truckId) => {
    return {
        type: TRUCKS_ACTIVITY_SET_ACTIVE_TRUCK,
        truckId,
    };
};

export const setNotificationSelectedTruckId = (truckId) => {
    return {
        type: TRUCKS_ACTIVITY_SET_NOTIFICATION_SELECTED_TRUCK,
        payload: truckId,
    };
};
