import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

import AppModal from "../core/modal";
import { JobCalendar } from "../../components/global/calendar";
import { THEME_ICONS } from "../../styles/icons";

import { selectAccount } from "../../selectors";
import { getTruckEvents, resetTruckEvents } from "../../actions/trucks";

const useStyles = makeStyles(() => ({
    calendarHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    uiMyFleet: {
        ...THEME_ICONS.generalTruck,
        marginRight: 5,
    },
}));

const TruckCalendar = ({ isOpenCalendar, closeCalendar, timezone, truckName, truckId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const account = useSelector(selectAccount);

    const getEvents = (id, params) => dispatch(getTruckEvents(id, params));
    const resetEvents = () => dispatch(resetTruckEvents());

    return (
        <AppModal isOpen={isOpenCalendar} closeModal={closeCalendar} modalStyles={{ minWidth: 1500 }}>
            <h2 className={clsx("title", classes.calendarHeader)}>
                <Icon className={classes.uiMyFleet} />
                {truckName}
            </h2>
            <JobCalendar
                timezone={account.timezone}
                truckId={truckId}
                getEvents={getEvents}
                resetEvents={resetEvents}
            />
        </AppModal>
    );
};

export default TruckCalendar;

TruckCalendar.propTypes = {
    isOpenCalendar: PropTypes.bool.isRequired,
    closeCalendar: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
};
