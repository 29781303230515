import React from "react";
import Container from "@material-ui/core/Container";
import DashboardWrapper from "../components/dashboard/dashboardWrapper";
import { EmptySubNav } from "../components/jobs/jobComponents/jobsSubNav";
import { PAGE_GLOBAL_STYLES } from "../styles/reusableStyles";

function Dashboard(props) {
    const classes = PAGE_GLOBAL_STYLES();

    return (
        <div>
            <EmptySubNav />
            <Container className={classes.globalPageStyles}>
                <DashboardWrapper />
            </Container>
        </div>
    );
}

export default Dashboard;
