import pickUpPin from "../styles/images/soft-green-pin.svg";
import dropOffPin from "../styles/images/pale-red-pin.svg";
import locationIcon from "../styles/images/location.svg";
import { START_LOCATION } from "../components/bundles/constants/strings";

export const getDirectionMapIcon = (location) => {
    if (location.isPickUp) {
        return pickUpPin;
    } else if (location.isDropOff) {
        return dropOffPin;
    }

    return locationIcon;
};

export const getDepotMarkerInfo = (depot, allLocations) => {
    const { name, address, location: depotLocation } = depot;

    const sameCoordinateStartLocation = allLocations.find(({ isStartTruckLocation, location }) => {
        if (isStartTruckLocation) {
            return location.latitude === depotLocation.latitude && location.longitude === depotLocation.longitude;
        }
        return false;
    });

    if (sameCoordinateStartLocation) {
        return `${name}/${START_LOCATION}`;
    }

    return name || address || "N/A";
};

export const getMarkerInfo = (location, allLocations) => {
    const { isStartTruckLocation, isDepot, address } = location;

    if (isStartTruckLocation) {
        return START_LOCATION;
    }
    if (isDepot) {
        return getDepotMarkerInfo(location, allLocations);
    }

    return address;
};
