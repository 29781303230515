import React from "react";
import { useDrag, useDrop } from "react-dnd";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Table from "@material-ui/core/Table";

import { TABLE_PRIMARY } from "../../styles/reusableStyles";
import TableHeaderSortingCell from "./tableHeaderSortingCell";

const DND_ITEM_TYPE = "row";

const RowTable = ({ row, columns, index, moveRow, onEndRow }) => {
    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: { ...row, type: DND_ITEM_TYPE, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onEndRow(item);
        },
    });

    const opacity = isDragging ? 0 : 1;
    const backgroundColor = row.disabled && "#d8d8d8";

    preview(drop(dropRef));
    drag(dragRef);

    return (
        <TableRow ref={dropRef} style={{ opacity }}>
            <TableCell ref={dragRef} style={{ cursor: "move" }}>
                <DragIndicatorIcon />
            </TableCell>
            {columns.map((column) => {
                const value = row[column.id];

                return (
                    <TableCell key={column.id} align={column.align} style={{ backgroundColor }}>
                        {value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

const DragableTable = (props) => {
    const tableStyles = TABLE_PRIMARY();

    const { rows, columns, onMoveRow } = props;

    const moveRow = (dragIndex, hoverIndex) => onMoveRow && onMoveRow(dragIndex, hoverIndex);

    return (
        <Table className={tableStyles.primaryTable}>
            {!!props.caption && <caption style={{ captionSide: "top", padding: "16px 0" }}>{props.caption}</caption>}
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: 30 }} />
                    {columns.map((column, index) => {
                        if (column.sortParam) {
                            return <TableHeaderSortingCell key={index} align={"center"} label={column.label} />;
                        }

                        return (
                            <TableCell key={index} align={"center"}>
                                {column.label}
                            </TableCell>
                        );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => {
                    if (React.isValidElement(row)) return row;

                    return (
                        <RowTable
                            key={row.name}
                            row={row}
                            moveRow={moveRow}
                            index={index}
                            columns={columns}
                            onEndRow={props.onEndRow}
                        />
                    );
                })}
            </TableBody>
        </Table>
    );
};

DragableTable.propTypes = {};

DragableTable.defaultProps = {};

export default DragableTable;
