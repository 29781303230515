import _ from "lodash";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MapWithDirections, { MAX_COORDINATES_AMOUNT } from "../MapWithDirections";
import { useSelector } from "react-redux";
import { selectTruckRouteLocation } from "../../../selectors/smartDispatchSelectors";
import { MAP_RENDER_ERROR_MESSAGE, WAYPOINTS_EXCEEDED_MESSAGE } from "../../../constants/strings";

export const getDirectionCoordinates = (routeLocations) => {
    return routeLocations.map((truckLocation) => {
        return [truckLocation.location.latitude, truckLocation.location.longitude];
    });
};

export const getLocationMarkers = (routeLocations) => {
    const jobOrderLocations = routeLocations.map((truckLocation) => {
        return {
            ...truckLocation,
            ...truckLocation.location,
        };
    });
    const uniqLocations = _.uniqBy(jobOrderLocations, "id");

    return uniqLocations;
};

export const BundleRouteMap = ({ routeLocations }) => {
    const truckLocation = useSelector(selectTruckRouteLocation);

    const [coordinates, setCoordinates] = useState(null);
    const [locationsMarkers, setLocationsMarkers] = useState(null);
    const [mapRenderError, setMapRenderError] = useState(null);

    const handleMapRenderError = () => {
        setMapRenderError(true);
    };

    useEffect(() => {
        const locationsWithoutJobStarts = routeLocations.filter((location) => !location.isJobStart);

        setCoordinates(getDirectionCoordinates(locationsWithoutJobStarts));
        setLocationsMarkers(getLocationMarkers(locationsWithoutJobStarts));
    }, [routeLocations]);

    if (coordinates && coordinates.length > MAX_COORDINATES_AMOUNT) {
        return <h1 className="--text-center">{WAYPOINTS_EXCEEDED_MESSAGE}</h1>;
    }

    if (mapRenderError) {
        return <h1 className="--text-center">{MAP_RENDER_ERROR_MESSAGE}</h1>;
    }

    return (
        <MapWithDirections
            locationsMarkers={locationsMarkers}
            coordinates={coordinates}
            truckLocation={truckLocation}
            handleMapRenderError={handleMapRenderError}
        />
    );
};

BundleRouteMap.propTypes = {
    routeLocations: PropTypes.array.isRequired,
};
