import React from "react";
import PropTypes from "prop-types";
import FieldNote from "./fieldNote";

const HiddenInput = (props) => {
    const {
        input,
        meta: { error, touched },
        errorAlign,
    } = props;

    return (
        <div>
            <input {...input} type="hidden" />
            {error && touched && <FieldNote textAlign={errorAlign} isError={true} fieldNote={error} />}
        </div>
    );
};

HiddenInput.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    errorAlign: PropTypes.string.isRequired,
};

HiddenInput.defaultProps = {
    errorAlign: "left",
};

export default HiddenInput;
