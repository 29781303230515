import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

import { selectCustomersActiveTab } from "../../selectors/settings";
import { SecondaryButton } from "../core";
import { updateCustomersActiveTab } from "../../actions/settings";
import { REGULAR_CUSTOMERS_SETTINGS, RESTRICTED_CUSTOMERS_SETTINGS } from "../../reducers/settingsReducer";
import RegularCustomersSetting from "./regularCustomersSetting";
import RestrictedCustomersSettings from "./resctrictedCustomers";

const useStyles = makeStyles(() => ({
    settingsTabsContainer: {
        marginBottom: "10px",
    },
    settingsButtonContainer: {
        flex: 1,
        margin: 5,
    },
    settingsTabButton: {
        width: "100%",
    },
    activeButton: {
        backgroundColor: "rgb(255 123 0 / 22%)",
        "&:hover": { backgroundColor: "rgb(247 180 117 / 57%)" },
    },
}));

const CustomersSettings = ({ customersActiveTab }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isActiveTab = (tab) => customersActiveTab === tab;

    const handleTabClick = (tabIndex) => {
        dispatch(updateCustomersActiveTab(tabIndex));
    };

    return (
        <div>
            <Grid container direction="row" justify="space-between" className={clsx(classes.settingsTabsContainer)}>
                <Grid className={clsx(classes.settingsButtonContainer)}>
                    <SecondaryButton
                        className={clsx(
                            classes.settingsTabButton,
                            isActiveTab(REGULAR_CUSTOMERS_SETTINGS) && classes.activeButton,
                        )}
                        size={"medium"}
                        onClick={() => handleTabClick(REGULAR_CUSTOMERS_SETTINGS)}
                    >
                        Customers
                    </SecondaryButton>
                </Grid>
                <Grid
                    className={clsx(
                        classes.settingsButtonContainer,
                        isActiveTab(RESTRICTED_CUSTOMERS_SETTINGS) && classes.activeButton,
                    )}
                >
                    <SecondaryButton
                        className={clsx(classes.settingsTabButton)}
                        size={"medium"}
                        onClick={() => handleTabClick(RESTRICTED_CUSTOMERS_SETTINGS)}
                    >
                        Restricted Customers
                    </SecondaryButton>
                </Grid>
            </Grid>
            {isActiveTab(REGULAR_CUSTOMERS_SETTINGS) && <RegularCustomersSetting />}
            {isActiveTab(RESTRICTED_CUSTOMERS_SETTINGS) && <RestrictedCustomersSettings />}
        </div>
    );
};

export default withRouter(
    compose(
        connect((state, props) => {
            return {
                customersActiveTab: selectCustomersActiveTab(state),
                account: state.account,
            };
        }),
    )(CustomersSettings),
);
