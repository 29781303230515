import React from "react";
import PropTypes from "prop-types";
import { StatusPill } from "../../core/pills";

const JobOrderStatusPill = ({ copySchedule, status }) => {
    return (
        <StatusPill type={copySchedule ? (copySchedule.enabled ? 2 : 1) : status} label={copySchedule && "template"} />
    );
};

JobOrderStatusPill.propTypes = {
    status: PropTypes.number,
    copySchedule: PropTypes.object,
};

export default JobOrderStatusPill;
