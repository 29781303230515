import React from "react";
import { Field } from "redux-form";
import { useDispatch } from "react-redux";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getRestrictedCustomersLoadOptions } from "../../actions/projects";

const RestrictedCustomerSelector = ({ disabled, name, isClearable, onChange }) => {
    const dispatch = useDispatch();

    const loadOptions = async (inputValue, { params = {}, loadedCount }) => {
        return await dispatch(getRestrictedCustomersLoadOptions(inputValue, loadedCount, params));
    };

    return (
        <Field
            name={name}
            disabled={disabled}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            loadOptions={loadOptions}
            placeholder="Restricted Customer"
            component={AsyncAutocompleteComponent}
            isClearable={isClearable}
            onChange={onChange}
        />
    );
};

export default RestrictedCustomerSelector;
