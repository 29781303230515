import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { deleteCompaniesCustomer, getCompaniesCustomer } from "../../dataServers/trucks";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import CustomerForm from "./customerSettingsForm";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import { getCustomersByCompany } from "../../dataServers/companies";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/styles";
import { ActionLink, SimpleLink } from "../core/link";
import ConfirmationModal from "../core/confirmationModal";
import Loader from "../core/loader";
import CustomerFilters from "./customerFilters";
import { SecondaryButton } from "../core";
import { makeCustomerRestricted } from "../../actions/settings";
import { handleError } from "../../helpers/global";

const useStyles = makeStyles(() => ({
    customersTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
    makeRestrictedCustomerContainer: {
        "& button": {
            lineHeight: 2,
            width: 130,
            height: 20,
        },
    },
}));

const RegularCustomersSetting = (props) => {
    const { account } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [activeCustomer, setActiveCustomer] = useState({});
    const [customersData, setCustomersData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(null);
    const [customersCount, setCustomersCount] = useState(null);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState(null);
    const [confirmDeleteCustomer, setConfirmDeleteCustomer] = useState({});
    const [confirmMakeRestricted, setConfirmMakeRestricted] = useState({});

    const updateListByFilters = (filters) => {
        let newFilters = { ..._.cloneDeep(filters) };

        if (!_.isEqual(appliedFilters, newFilters)) {
            setAppliedFilters(newFilters);
        }
    };

    useEffect(() => {
        loadCustomers();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(appliedFilters)) {
            loadCustomers();
        }
    }, [appliedFilters]);

    const loadCustomers = (concat = false) => {
        setIsLoading(true);
        const currentPage = Math.ceil(customersData.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(customersData) ? +currentPage + 1 : 1;
        let getCustomersByCompanyParams = {
            page: concat ? nextPage : 1,
        };
        if (!_.isEmpty(appliedFilters)) {
            getCustomersByCompanyParams.keywords = appliedFilters.keywords;
        }
        getCustomersByCompany(account.company.id, getCustomersByCompanyParams)
            .then(({ data, meta }) => {
                setCustomersCount(meta.count);
                setCustomersData(concat ? [...customersData, ...data] : data);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setIsLoading(false);
            });
    };

    const handleEditCustomer = (customerId) => {
        if (customerId) {
            return getCompaniesCustomer(account.company.id, customerId)
                .then((data) => {
                    setActiveCustomer(data.data);
                })
                .catch((error) => {
                    setError(PROCESS_SERVER_ERROR(error));
                });
        }
        setActiveCustomer({
            accountNumber: null,
            contacts: [],
            id: null,
            name: null,
        });
    };

    const handleDeleteCustomer = (customer) => {
        return deleteCompaniesCustomer(account.company.id, customer.id)
            .then(() => {
                setSuccessMessage([`Customer ${customer.name} was deleted.`]);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            })
            .finally(() => {
                setConfirmDeleteCustomer({});
            });
    };

    const handleMakeCustomerRestricted = async (customer) => {
        try {
            await dispatch(makeCustomerRestricted(customer, setSuccess));
        } catch (error) {
            setError([handleError(error).message]);
        } finally {
            await loadCustomers();
            setConfirmMakeRestricted({});
        }
    };

    const setSuccessMessage = (successMessage) => {
        setSuccess(successMessage);
        loadCustomers();
    };

    const setErrorMessage = (errorMessage) => {
        setError(errorMessage);
    };

    const getTableData = () => {
        const rows = customersData.map((customer, index) => {
            const { name, id, accountNumber } = customer;

            return {
                index,
                accountNumber,
                name,
                restricted: (
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className={clsx(classes.makeRestrictedCustomerContainer)}
                    >
                        <SecondaryButton onClick={() => setConfirmMakeRestricted(customer)}>
                            Make Restricted
                        </SecondaryButton>
                    </Grid>
                ),
                action: (
                    <Grid container justify={"center"} style={{ minHeight: 50 }}>
                        <React.Fragment>
                            <IconButton title="Edit customer" onClick={() => handleEditCustomer(id)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton title="Delete customer" onClick={() => setConfirmDeleteCustomer(customer)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </React.Fragment>
                    </Grid>
                ),
            };
        });
        let columns = [
            {
                id: "name",
                label: "Name",
            },
            {
                id: "accountNumber",
                label: "Account Number",
            },
            {
                id: "restricted",
                label: "restricted",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        return {
            rows: rows,
            columns: columns,
        };
    };

    return (
        <Grid container spacing={1} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            <Loader />
            <Grid container direction="row" justify="space-between">
                <Grid item justify="flex-start">
                    <ActionLink>
                        <AddCircleOutlineIcon />
                        <SimpleLink onClick={() => handleEditCustomer()}>Add new customer</SimpleLink>
                    </ActionLink>
                </Grid>
                <Grid item xs={6}>
                    <CustomerFilters updateListByFilters={updateListByFilters} />
                </Grid>
            </Grid>
            {!_.isEmpty(customersData) ? (
                <Grid item>
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={loadCustomers}
                        itemsCount={customersCount}
                        className={clsx(classes.customersTable)}
                        columns={getTableData().columns}
                    />
                </Grid>
            ) : isLoading ? null : (
                <Grid container justify="center">
                    <h1 className="--text-center">No customers match your search</h1>
                </Grid>
            )}
            {!_.isEmpty(activeCustomer) && (
                <CustomerForm
                    isOpen={!_.isEmpty(activeCustomer)}
                    closeModal={() => setActiveCustomer({})}
                    customerId={activeCustomer.id}
                    isNewCustomer={!activeCustomer.id}
                    setSuccess={setSuccessMessage}
                    setError={setErrorMessage}
                    initialValues={{
                        accountNumber: activeCustomer.accountNumber,
                        name: activeCustomer.name,
                        contacts: activeCustomer.contacts,
                    }}
                />
            )}
            {!_.isEmpty(success) && (
                <SuccessNotification message={success} config={{ onClose: () => setSuccess([]) }} />
            )}
            {!_.isEmpty(error) && <ErrorNotification error={error} config={{ onClose: () => setError([]) }} />}
            <ConfirmationModal
                isOpen={!_.isEmpty(confirmDeleteCustomer)}
                question={"Are you sure you want to delete customer?"}
                yesHandler={() => handleDeleteCustomer(confirmDeleteCustomer)}
                noHandler={() => setConfirmDeleteCustomer({})}
            />
            <ConfirmationModal
                isOpen={!_.isEmpty(confirmMakeRestricted)}
                question={`Are you sure you want to make this customer "Restricted"?`}
                yesHandler={() => handleMakeCustomerRestricted(confirmMakeRestricted)}
                noHandler={() => setConfirmMakeRestricted({})}
            />
        </Grid>
    );
};

RegularCustomersSetting.propTypes = {
    account: PropTypes.object.isRequired,
    fetchMore: PropTypes.func.isRequired,
};

const SETTINGS_CUSTOMER_FORM = "settingsCustomerForm";

export default withRouter(
    compose(
        reduxForm({
            form: SETTINGS_CUSTOMER_FORM,
            enableReinitialize: true,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(RegularCustomersSetting),
);
