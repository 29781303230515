import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { reduxForm } from "redux-form";
import SearchAndFilters, { FILTERS_REGIONS_ID, FILTERS_STATUS_ID } from "../global/searchAndFilters";
import {
    IS_ADMIN_USER,
    PROJECT_STATUS_ACTIVE,
    PROJECT_STATUS_ARCHIVED,
    PROJECT_STATUS_CLOSED,
} from "../../constants/maps";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectCompanyRegions } from "../../selectors/index";

const useStyles = makeStyles((theme) => ({
    searchBarWrap: {
        position: "relative",
        zIndex: 2,
    },
    searchBar: {
        height: 57,
        margin: "0 33px",
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        marginBottom: 12,
        position: "relative",
    },
}));

const ProjectsFilters = (props) => {
    const classes = useStyles();
    const { form, updateListByFilters, companyRegions, userRegion, companyId } = props;

    const [loadedFilters, setLoadedFilters] = useState(false);
    const [filtersList, updateFiltersList] = useState([
        {
            title: "Project Status",
            filterKey: FILTERS_STATUS_ID,
            filters: [
                {
                    label: "Active",
                    id: PROJECT_STATUS_ACTIVE,
                },
                {
                    label: "Closed",
                    id: PROJECT_STATUS_CLOSED,
                },
                {
                    label: "Archived",
                    id: PROJECT_STATUS_ARCHIVED,
                },
            ],
        },
    ]);
    const [initialFilters, updateInitialFilters] = useState({
        [FILTERS_STATUS_ID]: {
            [PROJECT_STATUS_ACTIVE]: true,
        },
    });
    const isResetToDefault = IS_ADMIN_USER(companyId) || !_.isEmpty(companyRegions);

    useEffect(() => {
        if (companyRegions) {
            updateFiltersList([
                ...filtersList,
                {
                    title: "Regions",
                    filterKey: FILTERS_REGIONS_ID,
                    filters: companyRegions.map((item) => ({
                        label: item.regionName,
                        id: item.id,
                    })),
                },
            ]);
            setLoadedFilters(true);
        }
        if (userRegion) {
            updateInitialFilters({
                ...initialFilters,
                [FILTERS_REGIONS_ID]: {
                    [userRegion.id]: true,
                },
            });
        }
    }, [companyRegions]);

    return (
        <Grid component="div" container justify={"center"} alignItems={"center"} className={classes.searchBarWrap}>
            <SearchAndFilters
                key={filtersList.length}
                clearAllTitle={"Clear"}
                form={form}
                rememberFilters
                isResetToDefault={isResetToDefault}
                updateList={(filters, clear) => updateListByFilters(filters, clear, loadedFilters)}
                initialFilters={initialFilters}
                filtersList={filtersList}
                size={12}
                className={classes.searchBar}
            />
        </Grid>
    );
};

ProjectsFilters.propTypes = {
    companyRegions: PropTypes.array,
    companyId: PropTypes.number,
    userRegion: PropTypes.object,
    search: PropTypes.string,
    form: PropTypes.string.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
};

ProjectsFilters.defaultProps = {};

export const PROJECTS_LIST_FORM = "projectsListForm";

export default withRouter(
    compose(
        reduxForm({
            form: PROJECTS_LIST_FORM,
        }),
        connect((state) => ({
            companyRegions: selectCompanyRegions(state),
            userRegion: state.account.region,
            companyId: state.account.company.id,
        })),
    )(ProjectsFilters),
);
