export const LINK_USER_DIALOG_WARNING = (unlinkDriverName, linkDriverName) =>
    `Are you sure you want to unlink ${unlinkDriverName} and link ${linkDriverName}?`;
export const SELECT_PROJECT_WITHOUT_REGION_WARNING = (projectName) =>
    `Project ${projectName} does not have a region but it is required according to your company's settings.`;

export const SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE = "You can't assign trucks and haulers to Smart Dispatch jobs";
export const NOT_OWNER_DRAGGABLE_OVERLAY_MESSAGE = "Only owner can assign trucks to the job";
export const DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE = "Drag Here";
export const SELECT_ITEM_DRAGGABLE_OVERLAY_MESSAGE = "Select Trucks or Haulers to Dispatch";
export const SELECT_TRUCK_TO_DISPATCH_OVERLAY_MESSAGE = "Select Trucks to Dispatch";
export const MULTIPLE_ASSIGNED_DRAGGABLE_OVERLAY_MESSAGE = "Drag here to assign group of trucks to empty routes";
export const ITEM_IS_ALREADY_IN_JOB_ORDER_MESSAGE = (isTruck) =>
    `This ${isTruck ? "Truck" : "Hauler"} is already assigned to Job Order`;

export const INCORRECT_AMOUNT_OF_SELECTED_TRUCKS = "Amount of selected trucks bigger than amount of empty routes";
export const NO_EMPTY_ROUTES = "There are no empty routes for bundle";
export const ASSIGN_WARNING = `Are you sure you want to assign truck/hauler to the bundle? Other bundles including the same jobs will be invalidated.`;
export const UNASSIGN_WARNING = `Are you sure you want to unassign route?`;
export const REJECT_ALL_WARNING = `Are you sure you want to reject all routes and close the bundle?`;

export const BUNDLE_UPDATED_MESSAGE = "Bundle Updated";
export const RESET_TRUCKS_CONFIRM_MESSAGE =
    "If you turn on Smart Dispatch," +
    " assigned trucks and haulers will be removed," +
    " total truck and unlimited fields will be reset. Continue?";
export const JOB_EXPORT_MODE_TOOLTIP_MESSAGE = "Export job considers that a truck visits all pick up sites in time";
export const JOB_IMPORT_MODE_TOOLTIP_MESSAGE = "Import job considers that a truck visits all drop off sites in time";

export const PHONE_UPDATED = "Phone Was Successfully Entered";
export const ENTER_PHONE_IS_REQUIRED = "To use app you must enter phone number";
export const PHONE_DIALOG_TITLE = "Please enter your phone number to continue";

export const ROUTE_TITLE = (solutionIndex, routeTotalLoadCount) =>
    `Route for Truck # ${solutionIndex + 1} - Total Loads: ${routeTotalLoadCount || "-"}`;
export const WAYPOINTS_EXCEEDED_MESSAGE = "Unable to show the path due to big amount of locations on the map";
export const MAP_RENDER_ERROR_MESSAGE = "Couldn't render map route";

// Truck Item
export const TRUCK_ITEM_TEMPLATE_HINT = "Truck is dispatched or is planned for dispatching to another job";
export const TRUCK_ITEM_REGULAR_JOB_HINT = "Truck is dispatched to another job";

export const PROXIMITY_ORDER = "proximity";

export const STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE =
    "If you change company account type to Stripe, company type will be set to limited and company users will be logged out to enter credit card";
export const NON_STRIPE_COMPANY_ACCOUNT_TYPE_FIELD_NOTE =
    "If you change company account type to non Stripe, company users will be logged out";

export const LINKED_JOB_LABEL = (id) => `Linked Job: #${id}`;
export const NEW_JOBS_SPLIT_LABEL =
    "The mid site will be used as a drop-off location for the first job and as a pick-up location for the second job";
export const EXISTING_JOB_SPLIT_LABEL =
    "The mid site will be used as a drop-off location for the selected job and as a pick-up location for the new job";

export const NO_DEFAULT_USER_MESSAGE = "Company doesn't have default manager user";
