import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Field } from "redux-form";
import { getDevices } from "../../dataServers/devices";
import { DEVICES_TYPE_PAVERTRACKER } from "../../constants/maps";
import { COMPANIES_TO_SHOW_DEVICES_LOGIC } from "../../constants/global";

const PavertrackersSelector = (props) => {
    const { onChange, disabled } = props;

    const loadPavertrackers = (inputValue, { params = {}, loadedCount }) => {
        return getDevices({ type: DEVICES_TYPE_PAVERTRACKER, keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.deviceId,
                label: i.deviceId,
                isNewAdded: true,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <Field
            name={PAVERTRACKERS_NAME}
            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
            disabled={disabled}
            onChange={onChange}
            loadOptions={loadPavertrackers}
            isMulti={true}
            placeholder="Pavertracker(s)"
            component={AsyncAutocompleteComponent}
        />
    );
};

export const pavertrackerInitialValues = (list) => {
    return {
        [PAVERTRACKERS_NAME]: !_.isEmpty(list) ? list.map((i) => ({ value: i, label: i })) : undefined,
    };
};
export const IS_CAN_SHOW_PAVERTRAKERS_SELECTOR = (poType) => COMPANIES_TO_SHOW_DEVICES_LOGIC.includes(poType);
export const PAVERTRACKERS_NAME = "paverTrackers";

PavertrackersSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};

PavertrackersSelector.defaultProps = {
    onChange: () => {},
    disabled: false,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(PavertrackersSelector),
);
