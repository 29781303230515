import dataProvider from "../helpers/api";
import { PURCHASE_ORDERS } from "../constants/endpoints";

export const fetchUserPayloads = (companyId, params) => {
    return dataProvider({
        url: PURCHASE_ORDERS.COMPANY_PAYLOADS(companyId),
        method: "GET",
        params,
    });
};

export const archivePayload = (payloadId) => {
    return dataProvider({
        url: PURCHASE_ORDERS.ARCHIVE_PAYLOAD(payloadId),
        method: "POST",
    });
};

export const unarchivePayload = (payloadId) => {
    return dataProvider({
        url: PURCHASE_ORDERS.UNARCHIVE_PAYLOAD(payloadId),
        method: "POST",
    });
};

export const deletePayloadRequest = (payloadId) => {
    return dataProvider({
        url: PURCHASE_ORDERS.PAYLOAD_DETAILS(payloadId),
        method: "DELETE",
    });
};

export const updatePayloadRequest = (payloadId, data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.PAYLOAD_DETAILS(payloadId),
        method: "PUT",
        data,
    });
};
