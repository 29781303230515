import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import {
    selectActiveRegularJobsSortParam,
    selectFirstRouteSmartDispatchJob,
    selectJobsForTrucksData,
    selectJobsForTrucksMeta,
    selectLastRouteSmartDispatchJob,
    selectRouteDetailsData,
} from "../../selectors/smartDispatchSelectors";
import {
    requestNoneSmartDispatchJobs,
    resetJobsForTrucks,
    updateActiveRegularJobsSortParam,
} from "../../actions/smartDispatch.js";
import { JO_STATUS_ACTIVE_ID, JO_STATUS_NOT_STARTED_ID, JO_STATUS_PENDING_ID } from "../../constants/maps";
import { JobOrderFiltersForm, JobsList } from "../jobs";
import { SD_REGULAR_JOBS_LIST_FIELD, SD_REGULAR_JOBS_LIST_FORM } from "../../constants/forms";
import SortParams from "../core/sortParams";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";

const useStyles = makeStyles((theme) => ({
    pageActionsClassName: {
        padding: "0 10px",
    },
}));

const initialFilters = [
    {
        title: "Job Status",
        filterKey: "status",
        filters: [
            {
                label: "Active",
                id: JO_STATUS_ACTIVE_ID,
            },
            {
                label: "Not Started",
                id: JO_STATUS_NOT_STARTED_ID,
            },
            {
                label: "Pending",
                id: JO_STATUS_PENDING_ID,
            },
        ],
    },
];

export const CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM = "dropOff";
export const CLOSEST_TO_LAST_DROP_OFF_PROXIMITY_PARAM = "pickUp";

export const RegularJobs = () => {
    const classes = useStyles();

    const jobs = useSelector(selectJobsForTrucksData);
    const jobsMeta = useSelector(selectJobsForTrucksMeta);
    const routeDetails = useSelector(selectRouteDetailsData);
    const activeRegularJobsSortParam = useSelector(selectActiveRegularJobsSortParam);
    const lastSmartDispatchJob = useSelector(selectLastRouteSmartDispatchJob);
    const firstSmartDispatchJob = useSelector(selectFirstRouteSmartDispatchJob);

    const dispatch = useDispatch();

    const requestJobs = (concat) => dispatch(requestNoneSmartDispatchJobs(concat));

    const resetJobs = () => dispatch(resetJobsForTrucks());

    let columns = [];
    if (routeDetails) {
        columns = [
            {
                id: "closestToDropOff",
                sortParam: CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM,
                label: `Drop-off closest to ${firstSmartDispatchJob.pickupLocation.name}`,
                direction: "asc",
            },
            {
                id: "closestToPickup",
                sortParam: CLOSEST_TO_LAST_DROP_OFF_PROXIMITY_PARAM,
                label: `Pick-up closest to ${lastSmartDispatchJob.dropoffLocations[0].name}`,
                direction: "asc",
            },
        ];
    }

    const onChangeFilters = (filters) => {
        dispatch(change(SD_REGULAR_JOBS_LIST_FORM, SD_REGULAR_JOBS_LIST_FIELD, filters));
    };

    const renderAdditionalInfo = () => {
        return (
            <div>
                <br />
                <h1 className="--text-center">No jobs match your search</h1>
                <br />
            </div>
        );
    };

    const handleSort = (updatedSortParam) => {
        dispatch(updateActiveRegularJobsSortParam(updatedSortParam));
    };

    useEffect(() => {
        if (routeDetails) {
            handleSort(CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM);
        }
    }, [routeDetails?.routeDetails, routeDetails?.solutionIndex]);

    useEffect(() => {
        return () => {
            dispatch(updateActiveRegularJobsSortParam(null));
        };
    }, []);

    return (
        <Box>
            <JobOrderFiltersForm
                form={SD_REGULAR_JOBS_LIST_FORM}
                showFilters
                isLoading={jobsMeta.loading}
                updateListByFilters={onChangeFilters}
                resetActiveJobOrders={resetJobs}
                handleDateChange={() => {}}
                containerElementSize={12}
                pageActionsClassName={classes.pageActionsClassName}
                initializedFilters={initialFilters}
                isResetToDefault={false}
                withoutDatesRange
                withoutEmptySearch
            />
            <div className={clsx(jobsMeta.loading && LOADER_WHITE_OVERLAY)}>
                {jobsMeta.loading && <Loader />}
                <SortParams columns={columns} handleSort={handleSort} activeOrder={activeRegularJobsSortParam} />
                <JobsList
                    isLoading={jobsMeta.loading}
                    jobOrders={jobs || []}
                    jobOrdersCount={jobsMeta.count}
                    requestFetch={requestJobs}
                    skeletonJobOrders={new Array(7).fill(0)}
                    renderNewJobItem
                    resetActiveJobOrders={resetJobs}
                    renderAdditionalInfo={jobs && !jobs.length ? renderAdditionalInfo : null}
                />
            </div>
        </Box>
    );
};

export default RegularJobs;
