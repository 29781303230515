import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Loader from "../components/core/loader";
import Grid from "@material-ui/core/Grid";
import JobOrdersWrapper from "../components/jobs/jobsWrapper";
import Fleet from "../components/fleet/fleet";
import SideBar from "../components/global/sideBar";
import { getJobBoardJobOrderJoli, getJobOrderById } from "../dataServers/jobOrder";
import { DividerThin } from "../components/core/divider";
import { IS_HAULER_USER } from "../constants/maps";
import JobsSubNav from "../components/jobs/jobComponents/jobsSubNav";
import JobBoardFormWrapper, { prepareJobOrderValues } from "../components/jobs/jobBoard/jobBoardFormWrapper";
import { EDIT_JOB_BOARD_FORM } from "../components/jobs/jobBoard/jobBoardForm";
import { openJobOrderById, processTrailers } from "../helpers/jobOrders";
import { ROUTE_JOBS } from "../routes/globalRoutes";
import { connect, useDispatch } from "react-redux";
import { saveCurrentJobOrderIdInRedux, updateActiveDispatchedTruckOrder } from "../actions/jobOrders";
import { isDirty } from "redux-form";
import JobOrderFormWrapper from "../components/jobs/jobOrder/jobOrderFormWrapper";
import { LOADER_WHITE_OVERLAY } from "../constants/global";
import { GET_USER_INITIAL_FILTERS, JOB_BOARD_INITIAL_FILTERS } from "../components/global/searchAndFilters";
import { EDIT_JO_FORM } from "../components/jobs/constants";
import * as projectsActions from "../actions/projects";
import { setcurrentProjectCompany } from "../actions/projects";
import { getProjectById } from "../dataServers/projects";
import { myFleetNeedsToBeUpdatedChange } from "../actions/smartDispatch";

const useStyles = makeStyles((theme) => ({
    jobBoardDispatch: {
        minHeight: "100vh",

        "& .job-list": {
            borderRight: `1px solid ${theme.palette.border.primary}`,

            "& .total-requested": {
                position: "relative",
                top: -7,
                marginLeft: 0,
            },
            "& .qty-wrap": {
                display: "block",
            },
        },
        "& .--local-padding": {
            padding: 15,
        },
        "& .top-panel": {
            backgroundColor: theme.palette.secondary.superDark,
            height: 60,
        },
        "& .job-actions": {
            justifyContent: "flex-end",

            "& .search-and-filters": {
                marginRight: 10,
                width: "100%",

                "& > div": {
                    height: 34,
                },
            },

            "& .search-loop": {
                marginLeft: 10,
            },
        },
    },
    subNavigation: {
        backgroundColor: theme.palette.secondary.superDark,
    },
}));

export const JOBS_BOARD_LIST_FORM_ID = "dispatchPageJobBoardFilters";

const DispatchJobBoardItem = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        isLoading: false,
        currentJobBoardItem: {},
        openAnotherJO: false,
        needUpdateJobOrders: false,
    });
    const { isLoading, currentJobBoardItem, openAnotherJO, needUpdateJobOrders } = state;
    const {
        match: {
            params: { jobOrderId },
        },
        history,
        setCurrentJobOrder,
        account,
        isDirtyJobBoardForm,
        joWebsocketData,
        setCurrentProject,
        currentProject,
    } = props;

    const setActiveJobOrder = ({ id }, continueAnyWay) => {
        if (currentJobBoardItem && currentJobBoardItem.id === jobOrderId) {
            return false;
        }

        if (!_.isEmpty(currentJobBoardItem) && isDirtyJobBoardForm && !continueAnyWay) {
            setState({
                ...state,
                openAnotherJO: id,
            });

            return false;
        }

        setState({
            ...state,
            openAnotherJO: null,
            isLoading: true,
            needUpdateJobOrders: false,
        });
        openJobOrderById(
            {
                history,
                _goTo: ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM,
            },
            id,
            {
                callBack: setCurrentJobOrder,
                args: id,
            },
        );

        Promise.all([getJobOrderById(id), getJobBoardJobOrderJoli(id)])
            .then(([data, { data: items }]) => {
                const jobOrder = data.data;
                const project = jobOrder.poLineItem.purchaseOrder.project;
                const projectId = project.id;
                const trailers = processTrailers(items);

                setState({
                    ...state,
                    openAnotherJO: null,
                    currentJobBoardItem: prepareJobOrderValues(
                        {
                            ...jobOrder,
                            ...trailers,
                            items,
                        },
                        account,
                    ),
                    isLoading: false,
                });
                dispatch(myFleetNeedsToBeUpdatedChange(true));

                if ((currentProject && currentProject.id !== projectId) || !currentProject) {
                    return getProjectById(projectId, { dispatch: true }).then(({ data }) => {
                        const insertedProject = {
                            ...data,
                            insertedProject: true,
                        };
                        setCurrentProject(insertedProject, false);
                    });
                }

                dispatch(setcurrentProjectCompany(jobOrder.company));
            })
            .catch(() => {
                setState({
                    ...state,
                    isLoading: false,
                });
            });
    };

    const closeWarn = (jobOrder) => {
        jobOrder
            ? setActiveJobOrder({ id: openAnotherJO }, true)
            : setState({
                  ...state,
                  openAnotherJO: null,
              });
    };

    const saveChangesHandler = (jobOrder) => {
        setState({
            ...state,
            needUpdateJobOrders: true,
            currentJobBoardItem: prepareJobOrderValues(jobOrder, account),
        });
    };

    useEffect(() => {
        jobOrderId && _.isEmpty(currentJobBoardItem) && setActiveJobOrder({ id: jobOrderId });
    }, [jobOrderId]);

    useEffect(() => {
        if (joWebsocketData && currentJobBoardItem && currentJobBoardItem.id === joWebsocketData.id) {
            setState({ ...state, currentJobBoardItem: prepareJobOrderValues(joWebsocketData, account) });
        }
    }, [joWebsocketData]);

    const jobFormId =
        IS_HAULER_USER(account.role) && currentJobBoardItem && !currentJobBoardItem.iAmOwner
            ? EDIT_JOB_BOARD_FORM
            : EDIT_JO_FORM;

    return (
        <div className={classes.jobBoardDispatch}>
            <SideBar
                sideBarWidth={`${100 / 4}%`}
                openOnRender={true}
                mainContent={
                    <div className={clsx("--full-height")}>
                        <JobsSubNav subMenuWidth={"81%"} />
                        <Grid component="div" container className="--full-height">
                            <Grid component="div" item xs={4}>
                                <JobOrdersWrapper
                                    className="job-list --full-height --local-padding"
                                    initialValues={{
                                        ...GET_USER_INITIAL_FILTERS(JOBS_BOARD_LIST_FORM_ID),
                                        ...JOB_BOARD_INITIAL_FILTERS,
                                    }}
                                    pageUrl={ROUTE_JOBS.DISPATCH_JOB_BOARD_ITEM}
                                    setActiveJO={_.isEmpty(currentJobBoardItem)}
                                    needUpdateJobOrders={needUpdateJobOrders}
                                    pageActionsClassName="job-actions"
                                    setCurrentJobOrder={setActiveJobOrder}
                                    setActive={setActiveJobOrder}
                                    editJobOrder={false}
                                    isJobBoardPage={true}
                                    saveChangesHandler={saveChangesHandler}
                                    currentJobOrder={currentJobBoardItem}
                                    form={JOBS_BOARD_LIST_FORM_ID}
                                    dateRangeSize={12}
                                    isListView={false}
                                />
                            </Grid>
                            <Grid component="div" item xs={8}>
                                <div className="--local-padding">
                                    {!_.isEmpty(currentJobBoardItem) ? (
                                        <React.Fragment>
                                            <h1>{currentJobBoardItem.id ? `Job ${currentJobBoardItem.id}` : <br />}</h1>
                                            <DividerThin />
                                            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                                                {isLoading && <Loader />}
                                                {IS_HAULER_USER(account.role) &&
                                                currentJobBoardItem &&
                                                !currentJobBoardItem.iAmOwner ? (
                                                    <JobBoardFormWrapper
                                                        jobFormId={jobFormId}
                                                        closeWarn={closeWarn}
                                                        saveChangesHandler={saveChangesHandler}
                                                        setActive={setActiveJobOrder}
                                                        showFormWarn={openAnotherJO}
                                                        jobOrder={currentJobBoardItem}
                                                    />
                                                ) : (
                                                    <JobOrderFormWrapper
                                                        form={jobFormId}
                                                        closeWarn={closeWarn}
                                                        setActive={setActiveJobOrder}
                                                        saveChangesHandler={saveChangesHandler}
                                                        showFormWarn={openAnotherJO}
                                                        jobOrder={currentJobBoardItem}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div>
                                            <br />
                                            <h1 className="--text-center">
                                                {isLoading ? "Loading data..." : "No data to display."}
                                            </h1>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                }
                sideBarContent={<Fleet jobFormId={jobFormId} jobsListFormId={JOBS_BOARD_LIST_FORM_ID} />}
            />
        </div>
    );
};

DispatchJobBoardItem.propTypes = {
    match: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setCurrentJobOrder: PropTypes.func.isRequired,
    setDispatchedTruckOrder: PropTypes.func.isRequired,
    isDirtyJobBoardForm: PropTypes.bool.isRequired,
    joWebsocketData: PropTypes.object,
    currentProject: PropTypes.object,
    setCurrentProject: PropTypes.func,
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                isDirtyJobBoardForm: isDirty(EDIT_JOB_BOARD_FORM)(state),
                joWebsocketData: state.jobOrders.joWebsocketData,
                currentProject: state.projects.currentProject,
            };
        },
        (dispatch) => ({
            setCurrentJobOrder: (jobOrderId) => {
                dispatch(saveCurrentJobOrderIdInRedux(jobOrderId));
            },
            setDispatchedTruckOrder: (order) => dispatch(updateActiveDispatchedTruckOrder(order)),
            setCurrentProject: (project) => {
                dispatch(projectsActions.setCurrentProject(project));
            },
        }),
    )(DispatchJobBoardItem),
);
