import React from "react";
import clsx from "clsx";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MuiDivider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";

import { PrimaryButton } from "./buttons/primaryButton";

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        position: "relative",
        width: "100%",
        height: 48,
        "& .primary-button": {
            fontWeight: "600",
            borderRadius: 4,
            height: "100%",
            width: "100%",
            border: "none",
            flex: 1,
            display: "flex",
            paddingRight: 30,
            boxShadow: "none",
        },
        "& .arrow-button": {
            position: "absolute",
            width: "10px !important",
            height: "100%",
            background: "transparent",
            right: 0,
            top: 0,
            bottom: 0,
            boxShadow: "none",
            borderLeft: "1px solid rgb(185 98 17 / 55%)",
            "&:hover": {
                backgroundColor: `rgb(185 98 17 / 55%)`,
                boxShadow: "none",
            },
        },
        "& .menu-item-header": { display: "flex", alignItems: "center", fontWeight: 600 },
    },
    popper: {
        zIndex: 10000,
    },
    menuItem: {
        fontSize: 14,
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "10px 20px",
        "& .menu-item-header": { display: "flex", alignItems: "center", fontWeight: 600 },
        "& .menu-item-subheader": { fontSize: 12, color: "#57606a", fontWeight: 500 },
    },
}));

export default function SplitButton({ options, actions, containerClassName, disabled }) {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const styles = useStyles();

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        actions[selectedIndex]();
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className={containerClassName}>
            <ButtonGroup className={clsx(styles.buttonGroup)} variant="contained" color="primary" ref={anchorRef}>
                <PrimaryButton className="primary-button" disabled={disabled} onClick={handleClick}>
                    <Box display="flex" alignItems="center">
                        {options[selectedIndex].icon}
                        {options[selectedIndex].label}
                    </Box>
                </PrimaryButton>
                <PrimaryButton
                    disabled={disabled}
                    color="primary"
                    size="small"
                    onClick={handleToggle}
                    className="arrow-button"
                >
                    <ArrowDropDownIcon />
                </PrimaryButton>
            </ButtonGroup>
            <Popper
                className={styles.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <>
                                            <MenuItem
                                                className={styles.menuItem}
                                                key={option}
                                                disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                <Typography variant="h4" className="menu-item-header">
                                                    {option.icon}
                                                    {option.label}
                                                </Typography>
                                                {option.helperText && (
                                                    <Typography className="menu-item-subheader">
                                                        {option.helperText}
                                                    </Typography>
                                                )}
                                            </MenuItem>
                                            <MuiDivider />
                                        </>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
