import React, { useState } from "react";
import { change, Field, formValueSelector, reset } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import { updateDropOffNotifications } from "../../actions/notifications";
import useSuccessNotification from "../../hooks/useSuccessNotification";
import { ACCOUNT_NOTIFICATION_PROFILE_KEY, LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import { useErrorNotification } from "../../hooks";
import CustomSwitch from "../core/form/customSwitch";
import DefaultDropOffNotificationsRecipientsModal, {
    DEFAULT_DROP_OFF_RECIPIENTS_FORM,
} from "./DefaultDropOffApproversModal";
import {
    PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_LABEL,
    PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_TITLE,
    RECIPIENTS_TITLE,
} from "../../constants/settings";
import { DEFAULT_NOTIFICATIONS_RECIPIENTS_LABEL } from "./constants/strings";
import { updateAccountData } from "../../helpers/global";
import { authorizeUser } from "../../actions/globalActions";

const RequestDropOffNotifications = ({ enableDropOffNotifications, form, dropOffRecipients }) => {
    const showSuccess = useSuccessNotification();
    const showError = useErrorNotification();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const [isOpenDefaultRecipientsForm, setIsOpenDefaultRecipientsForm] = useState(false);

    const onRecipientChange = async (recipients) => {
        setIsLoading(true);
        const updatedRecipientsIdList = (recipients || []).map((recipient) => recipient.value);
        const updatedEnableDropOffNotifications = !_.isEmpty(updatedRecipientsIdList);
        try {
            const { data } = await dispatch(
                updateDropOffNotifications(updatedRecipientsIdList, !_.isEmpty(updatedRecipientsIdList)),
            );
            updateAccountData(
                data,
                (updatedData) => dispatch(authorizeUser(updatedData)),
                ACCOUNT_NOTIFICATION_PROFILE_KEY,
            );
            dispatch(change(form, "dropOffRecipients", recipients));
            dispatch(change(form, "enableDropOffNotifications", updatedEnableDropOffNotifications));
            showSuccess("Recipients are updated");
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnableNotificationsClick = async (event, newValue) => {
        if (newValue && _.isEmpty(dropOffRecipients)) {
            return setIsOpenDefaultRecipientsForm(true);
        }

        setIsLoading(true);
        try {
            const { data } = await dispatch(
                updateDropOffNotifications(
                    dropOffRecipients.map((recipient) => recipient.value),
                    newValue,
                ),
            );
            updateAccountData(
                data,
                (updatedData) => dispatch(authorizeUser(updatedData)),
                ACCOUNT_NOTIFICATION_PROFILE_KEY,
            );
        } catch (error) {
            showError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onFormClose = () => {
        dispatch(reset(DEFAULT_DROP_OFF_RECIPIENTS_FORM));
        setIsOpenDefaultRecipientsForm(false);
    };

    const onFormCancel = () => {
        onFormClose();
        dispatch(change(form, "enableDropOffNotifications", false));
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}

            <Field
                name="enableDropOffNotifications"
                label={PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_LABEL}
                onChange={handleEnableNotificationsClick}
                type="checkbox"
                component={CustomSwitch}
                title={PREVENT_CLOSURE_OUTSIDE_OF_DROP_OFF_TITLE}
            />
            {enableDropOffNotifications && (
                <Box sx={{ marginTop: 30 }}>
                    <DispatchersFieldSelector
                        name="dropOffRecipients"
                        label={DEFAULT_NOTIFICATIONS_RECIPIENTS_LABEL}
                        loadedList={dropOffRecipients}
                        isMulti={true}
                        onFieldChange={onRecipientChange}
                        fieldNote={RECIPIENTS_TITLE}
                    />
                </Box>
            )}
            <DefaultDropOffNotificationsRecipientsModal
                isOpen={isOpenDefaultRecipientsForm}
                onFormCancel={onFormCancel}
                onClose={onFormClose}
            />
        </div>
    );
};

export default compose(
    connect((state, props) => {
        const formSelector = formValueSelector(props.form);

        return {
            enableDropOffNotifications: formSelector(state, "enableDropOffNotifications"),
            dropOffRecipients: formSelector(state, "dropOffRecipients"),
        };
    }),
)(RequestDropOffNotifications);
