import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isDirty } from "redux-form";
import { connect } from "react-redux";
import ConfirmationModal from "./confirmationModal";

const ConfirmationModalWrap = (props) => {
    const [state, setState] = useState({ isOpenConfirmModal: false });
    const { isOpenConfirmModal } = state;
    const {
        children,
        isDirtyForm,
        customDirtyProperty,
        closeChildModalRequest,
        closeChildModal,
        closeModal: resetCloseModalRequest,
        handleFormWrapperClick,
    } = props;
    const closeModal = () => {
        closeChildModal();
        setState({ ...state, isOpenConfirmModal: false });
    };
    const saveFormChanges = () => {
        resetCloseModalRequest();
        // to submit form get form values and call parent handle submit function with this values
        setState({ ...state, isOpenConfirmModal: false });
    };

    const onFormWrapperClick = (e) => {
        handleFormWrapperClick && handleFormWrapperClick(e);
    };

    useEffect(() => {
        if (closeChildModalRequest) {
            if (customDirtyProperty || isDirtyForm) {
                setState({ ...state, isOpenConfirmModal: true });
            } else {
                closeChildModal();
            }
        }
    }, [closeChildModalRequest]);

    return (
        <div onClick={onFormWrapperClick}>
            {children}
            <ConfirmationModal yesHandler={closeModal} noHandler={saveFormChanges} isOpen={isOpenConfirmModal} />
        </div>
    );
};

ConfirmationModalWrap.propTypes = {
    children: PropTypes.any,
    form: PropTypes.string.isRequired,
    isDirtyForm: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    closeChildModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeChildModalRequest: PropTypes.bool.isRequired,
    customDirtyProperty: PropTypes.bool,
    handleFormWrapperClick: PropTypes.func,
};

ConfirmationModalWrap.defaultProps = {
    form: "anyForm",
};

export default withRouter(
    connect((state, props) => {
        return {
            account: state.account,
            isDirtyForm: props.form ? isDirty(props.form)(state) : false,
        };
    })(ConfirmationModalWrap),
);
