import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
    container: {
        borderColor: "#D68530",
        "& .unactive": {
            borderColor: theme.palette.superDark,
            background: theme.palette.default,
            color: theme.palette.main,
        },
        "& .active": {
            ...theme.mainButton,
            border: "none"
        },
    },
}));

export default function ToggleButtons({
    containerStyle,
    buttons,
    onChange,
    exclusive,
    size,
    initialValue,
    withoutReset,
    fullWidth,
}) {
    const classes = useStyles();
    const [option, setOption] = React.useState(initialValue);

    const handleChange = (event, value) => {
        if (withoutReset && !value) return;
        setOption(value);
        onChange && onChange(value);
    };

    const Button = ({ key, value, title }) => (
        <ToggleButton key={key} value={value} className={option === value ? "active" : "unactive"}>
            {title}
        </ToggleButton>
    );
    const renderButtons = () => buttons && buttons.map(Button);

    return (
        <ToggleButtonGroup
            className={clsx(classes.container, containerStyle)}
            size={size}
            value={option}
            exclusive={exclusive}
            onChange={handleChange}
            fullWidth={fullWidth}
        >
            {renderButtons()}
        </ToggleButtonGroup>
    );
}
