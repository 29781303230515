import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AsyncAutoCompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Field } from "redux-form";
import { COMPANY_TYPE_CONTRACTOR, COMPANY_TYPE_LIMITED, IS_ADMIN_USER } from "../../constants/maps";
import { getCompanies, getCustomersByCompany } from "../../dataServers/companies";
import AppModal from "../core/modal";
import CompanyFormLite, { COMPANY_FORM } from "./companyFormLite";
import ErrorNotification from "../core/notification";

const CustomerSelector = (props) => {
    const [error, setError] = useState(false);
    const [openNewCompanyModal, setOpenNewCompanyModal] = useState(false);
    const {
        account,
        name,
        createNewOption,
        currentProject,
        isOwnerSelector,
        placeholder,
        validate,
        createNewOptionHandler,
        onChange,
        isClearable,
        ownerId,
        disabled,
    } = props;

    const loadCustomerOptions = (inputValue, { params = {}, loadedCount }) => {
        if (isOwnerSelector) {
            return getCompanies({
                keywords: inputValue,
                ...params,
            }).then((data) => {
                const results = data.data.map((i) => ({
                    value: i.id,
                    label: i.name,
                    regionRequired: i.regionRequired
                }));

                return {
                    options: results,
                    hasMore: data.meta.count > (loadedCount || results.length),
                    page: data.meta.page,
                };
            });
        }

        const companyId = IS_ADMIN_USER(account.company.id)
            ? ownerId
                ? ownerId
                : currentProject.owner.id
            : account.company.id;

        return getCustomersByCompany(companyId, {
            type: [COMPANY_TYPE_CONTRACTOR, COMPANY_TYPE_LIMITED].join(","),
            keywords: inputValue,
            ...params,
        }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const onCustomerCreate = (data) => {
        setOpenNewCompanyModal(false);
        createNewOptionHandler && createNewOptionHandler(data);
    };

    return (
        <>
            {error && <ErrorNotification errorCompany={error} config={{ onClose: () => setError(null) }} />}
            <Field
                name={name}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                isClearable={isClearable}
                loadOptions={loadCustomerOptions}
                placeholder={placeholder}
                onChange={onChange}
                validate={validate}
                createNewOption={createNewOption}
                createNewHandler={() => setOpenNewCompanyModal(true)}
                component={AsyncAutoCompleteComponent}
                disabled={disabled}
            />
            {openNewCompanyModal && (
                <AppModal
                    isOpen={openNewCompanyModal}
                    form={COMPANY_FORM}
                    closeModal={() => setOpenNewCompanyModal(false)}
                >
                    <h2 className="title">Create new customer</h2>
                    <br />
                    <CompanyFormLite
                        closeModal={() => setOpenNewCompanyModal(false)}
                        onCustomerCreate={onCustomerCreate}
                    />
                </AppModal>
            )}
        </>
    );
};

CustomerSelector.propTypes = {
    account: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    createNewOptionHandler: PropTypes.func,
    isClearable: PropTypes.bool.isRequired,
    isOwnerSelector: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    createNewOption: PropTypes.object,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ownerId: PropTypes.number,
    validate: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
};

export const CUSTOMER_FIELD_NAME = "customer";

CustomerSelector.defaultProps = {
    name: CUSTOMER_FIELD_NAME,
    placeholder: "Customer",
    createNewOption: null,
    ownerId: null,
    isOwnerSelector: false,
    isClearable: false,
    validate: [],
    disabled: false,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
        currentProject: state.projects.currentProject,
    }))(CustomerSelector),
);
