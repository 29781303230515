import dataProvider from "../helpers/api";
import { GLOBAL_COUNT_TO_LOAD, SITES } from "../constants/endpoints";
import { FILTERS_SITES_TYPE_ID, FILTERS_STATUS_ID } from "../components/global/searchAndFilters";

export const getSites = (params = {}) => {
    return dataProvider({
        url: SITES.SITES_LIST(),
        method: "GET",
        params: {
            order: "-id",
            relZoneInf: true,
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            [FILTERS_SITES_TYPE_ID]: params[FILTERS_SITES_TYPE_ID],
            [FILTERS_STATUS_ID]: params[FILTERS_STATUS_ID],
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const getSiteById = (siteId, account, params = {}) => {
    return dataProvider({
        url: SITES.SITE_BY_ID(siteId),
        method: "GET",
        params,
    }).then((response) => {
        const site = response.data;

        return getSiteAlertZones(site.id).then((response) => {
            site.alertZones = response.data;

            return Promise.resolve(site);
        });
    });
};

export const fetchSiteStatsById = (siteId, params) => {
    return dataProvider({
        url: SITES.SITE_STATS_BY_ID(siteId),
        method: "GET",
        params,
    });
};

export const fetchSiteStatsDailyLoads = (siteId) => {
    return dataProvider({
        url: SITES.SITE_STATS_DAILY_LOADS(siteId),
        method: "GET",
    });
};

export const getSiteByIdWithoutAlertZones = (siteId) => {
    return dataProvider({
        url: SITES.SITE_BY_ID(siteId),
        method: "GET",
    });
};

export const getSiteAlertZones = (siteId, params = {}) => {
    return dataProvider({
        url: SITES.ALERT_ZONES(),
        method: "GET",
        params: {
            ...params,
            siteId: siteId,
        },
    });
};

export const createAlertZone = (data) => {
    return dataProvider({
        url: SITES.ALERT_ZONES(),
        method: "POST",
        data,
    });
};

export const removeAlertZone = (data) => {
    return dataProvider({
        url: SITES.ALERT_ZONES(),
        method: "DELETE",
        data,
    });
};

export const updateAlertZoneById = (id, data = {}) => {
    return dataProvider({
        url: SITES.ALERT_ZONE_BY_ID(id),
        method: "PUT",
        data,
    });
};

export const fetchSitesDistance = (startSiteId, finishSiteId) => {
    return dataProvider({
        url: SITES.CALCULATE_DISTANCE(startSiteId, finishSiteId),
        method: "GET",
    });
};

export const createSite = (data) => {
    return dataProvider({
        url: SITES.SITES_LIST(),
        method: "POST",
        data,
    });
};

export const updateSite = (siteId, data) => {
    return dataProvider({
        url: SITES.SITE_BY_ID(siteId),
        method: "PUT",
        data,
    });
};
