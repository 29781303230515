import _ from "lodash";
import dataProvider from "../helpers/api";
import { COMPANIES, PROJECTS } from "../constants/endpoints";

export const fetchCompanyRegions = (companyId, params) => {
    let requestParams = _.cloneDeep(params);
    if (requestParams && requestParams.cancelToken) {
        delete requestParams.cancelToken;
    }

    return dataProvider({
        url: PROJECTS.PROJECT_COMPANY_REGIONS(companyId),
        method: "GET",
        params: requestParams,
    });
};

export const deleteRegionRequest = (regionId) => {
    return dataProvider({
        url: COMPANIES.REGION_BY_ID(regionId),
        method: "DELETE",
    });
};

export const updateRegionRequest = (regionId, data) => {
    return dataProvider({
        url: COMPANIES.REGION_BY_ID(regionId),
        method: "PUT",
        data,
    });
};

export const createRegionRequest = (companyId, data) => {
    return dataProvider({
        url: PROJECTS.PROJECT_COMPANY_REGIONS(companyId),
        method: "POST",
        data,
    });
};
