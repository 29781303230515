import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flex: 1,
        backgroundColor: theme.palette.secondary.superLight,
        borderRadius: 20,
    },
    pillWrap: {
        flex: 1,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000",
        display: "flex",
        alignItems: "center",

        "& input": {
            display: "none",

            "&:checked + .pill": {
                backgroundColor: theme.palette.general.softOrange,
                borderRadius: 20,
            },
        },
    },
    pill: {
        flex: 1,
        height: 32,
        paddingLeft: 10,
        paddingRight: 10,
        color: theme.palette.secondary.superDark,
        fontSize: theme.typography.fontSize,
        textTransform: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function SwitchSelector(props) {
    const classes = useStyles();
    const {
        input: { name, value: inputValue, onChange, checked },
        disabled,
    } = props;

    const onChangeValue = (value) => {
        if (props.onChangeValue) {
            props.onChangeValue(value);
        } else {
            onChange(value);
        }
    };

    return (
        <div className={classes.container}>
            {props.options &&
                props.options.map(({ label, value }, index) => {
                    return (
                        <label className={clsx(classes.pillWrap)} key={index}>
                            <input
                                type="radio"
                                id={index}
                                name={name}
                                value={value}
                                checked={checked || inputValue === value}
                                onChange={onChangeValue}
                                disabled={disabled}
                            />
                            <div className={clsx(classes.pill, "pill")}>{label}</div>
                        </label>
                    );
                })}
        </div>
    );
}
