import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { userReducer } from "./userReducer";
import { jobOrdersReducer } from "./jobOrdersReducer";
import { notificationsReducer } from "./notificationsReducer";
import { projectsReducer } from "./projectsReducer";
import { ticketsReducer } from "./ticketsReducer";
import { trucksReducer } from "./trucksReducer";
import { reducer as formReducer } from "redux-form";
import { teamReducer } from "./teamsReducer";
import { poLIneItemsReducer } from "./poLineItemsReducer";
import { companiesReducer } from "./companiesReducer";
import { sitesReducer } from "./sitesReducer";
import { statsReducer } from "./statsReducer";
import { trucksActivityReducer } from "./trucksActivityReducer";
import { livemapReducer } from "./livemapReducer";
import haulersSettingsReducer from "./haulersSettingsReducer";
import payloadsSettingsReducer from "./payloadsSettingsReducer";
import constantsReducer from "./constantsReducer";
import truckTypesSettingsReducer from "./truckTypesSettingsReducer";
import smartDispatchReducer from "./smartDispatchReducer";
import { regionsSettingsReducer } from "./regionsSettingsReducer";
import settingsReducer from "./settingsReducer";
import unitsOfMeasureReducer from "./unitsOfMeasureReducer";
import trailersReducer from "./trailersReducer";
import additionalExternalRefReducer from "./additionalExternalRefsReducer";
import subCompaniesReducer from "./subCompaniesReducer";
import companyAdminsReducer from "./companyAdminsReducer";

const combined = combineReducers({
    routing: routerReducer,
    account: userReducer,
    jobOrders: jobOrdersReducer,
    smartDispatch: smartDispatchReducer,
    notifications: notificationsReducer,
    projects: projectsReducer,
    tickets: ticketsReducer,
    trucks: trucksReducer,
    form: formReducer,
    teams: teamReducer,
    poLineItems: poLIneItemsReducer,
    companies: companiesReducer,
    sites: sitesReducer,
    stats: statsReducer,
    trucksActivity: trucksActivityReducer,
    livemap: livemapReducer,
    haulersSettings: haulersSettingsReducer,
    payloadsSettings: payloadsSettingsReducer,
    truckTypesSettings: truckTypesSettingsReducer,
    regionsSettings: regionsSettingsReducer,
    constants: constantsReducer,
    settings: settingsReducer,
    unitsOfMeasure: unitsOfMeasureReducer,
    trailers: trailersReducer,
    additionalExternalRefs: additionalExternalRefReducer,
    subCompanies: subCompaniesReducer,
    companyAdmins: companyAdminsReducer,
});

export default combined;
