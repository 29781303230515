import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Tooltip } from "@material-ui/core";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import { THEME_ICONS, TYPOGRAPHY_ICONS_SITE } from "../../styles/icons";
import BorderLinearProgress from "../core/borderLinearProgress";
import Loader from "../core/loader";
import StartDate from "../core/time";
import TrucksRatio from "../core/truckRatio";
import UnitOfMeasure from "../core/unitOfMeasure";
import { IS_ADMIN_USER, IS_INSPECTOR_USER, UNIT_OF_MEASURE_HOUR, UNIT_OF_MEASURE_LOAD } from "../../constants/maps";
import JobOrderMoreActions, { showMoreActions } from "./jobComponents/moreActions";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_TIME_FORMAT,
    LOADER_WHITE_OVERLAY,
} from "../../constants/global";
import { CARD_STYLES, DRAG_AND_DROP_STYLES } from "../../styles/reusableStyles";
import { getHoursQty } from "../tickets/components/ticketCard";
import { useDrop } from "react-dnd";
import {
    dropItemTypes,
    getIsJobOrderRequest,
    getShowCompletedCubage,
    getIfIsDropOffMidpoint,
    getIfIsPickUpMidpoint,
} from "../../helpers/jobOrders";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ProjectCheckIcon from "../global/checkIcon";
import { addJobToBundleSelected, removeJobFromBundleSelected } from "../../actions/smartDispatch";
import {
    DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE,
    NOT_OWNER_DRAGGABLE_OVERLAY_MESSAGE,
    SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE,
} from "../../constants/strings";
import { selectMeasureSystem, selectRestrictedCustomerShowPrice } from "../../selectors";
import JobOrderStatusPill from "./jobOrder/jobOrderStatusPill";
import PayloadLink from "./jobOrder/PayloadLink";
import { saveCurrentJobOrderIdInRedux } from "../../actions/jobOrders";
import JobListItemLinkedJobLink from "./jobOrder/jolis/jobListItemLinkedJobLink";

const useStyles = makeStyles((theme) => ({
    jobOrder: {
        "&.selected-job": {
            borderColor: theme.palette.general.green,
            borderWidth: 2,
        },
        "&.isSkeleton": {
            paddingTop: 11,

            "& .name, & .startTime, & .payload, & .qty, & .numberOfTrucks, & .status ": {
                backgroundColor: theme.palette.secondary.main,
                color: "transparent",
                height: 12,
                marginBottom: 9,
                fontSize: 0,
            },

            "& .name": {
                width: "39%",
            },

            "& .numberOfTrucks": {
                width: "50%",
            },

            "& .startTime": {
                width: "100%",
            },

            "& .payload": {
                width: "21%",
            },

            "& .status": {
                height: 14,
                width: "73%",
                borderRadius: theme.shape.borderRadius * 2,
                marginTop: 0,
                backgroundColor: theme.palette.secondary.dark,
            },

            "& .qty": {
                height: 18,
                width: "25%",
                marginBottom: 7,
            },
        },

        "&.is-job-board .content, &.is-smart-dispatch .content": {
            padding: "11px 17px",
        },

        "& .name, & .startTime, & .payload": {
            color: theme.palette.text.main,
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& .finishedQty, & .totalQty": {
            fontSize: 19,
            fontWeight: theme.typography.fontWeightBold,
        },
        "& .finishedQty": {
            color: theme.palette.secondary.main,
            whiteSpace: "nowrap",
        },
        "& .totalQty": {
            color: theme.palette.text.secondary,
            whiteSpace: "nowrap",
        },
        "& .status": {
            margin: "6px 0",
            lineHeight: 0,
        },
        "& .qty": {
            display: "flex",
            alignItems: "center",
            marginRight: 5,
        },
        "& .qty-wrap": {
            display: "flex",
        },
        "& .qty-wrap-responsive": {
            [theme.breakpoints.down("1600")]: {
                flexDirection: "column",
            },
        },
        "& .trucks-list": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        "& .trailers-list": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "80%",
        },
        "& .requested-by": {
            overflow: "hidden",
        },
    },
    infiniteIcon: {
        height: 34,
        width: 34,
        marginRight: 4,
    },
    moreActionWrap: {
        position: "relative",
        height: 10,

        "& .more-actions": {
            position: "absolute",
            top: "0px",
            right: 3,
            zIndex: 10,
        },
    },
    checkIconWrapper: {
        position: "relative",

        "& .check-icon": {
            position: "absolute",
            top: "-20px",
            left: "25px",
            zIndex: 10,
        },
    },
    uiIconTrailer: {
        ...THEME_ICONS.trailerFilledLarge,
        marginRight: 4,
    },
}));

const JobOrderListItem = React.memo(
    (props) => {
        const {
            history,
            isSkeleton,
            setActive,
            jobOrder,
            updateJoStatus,
            updateJoCopySchedule,
            deleteJoById,
            currentJobOrder,
            account,
            index,
            jobOrdersListLength,
            addToBundleSelectedJobs,
            measurementSystem,
            restrictedCustomerShowPrice,
        } = props;
        const {
            assignedBy,
            projectName,
            projectId,
            id,
            totalQuantity,
            payloadName,
            poLineItemId,
            assignedTrucksCount,
            startDate,
            unlimited,
            completedQuantity,
            unitOfMeasure,
            totalNeededTrucksCount,
            status,
            unitPrice,
            iAmOwner,
            extRef,
            companyName,
            grantedAccess,
            projectExternalJobNumber,
            dispatchedQuantity,
            trucks,
            trailers,
            copySchedule,
            smartDispatch,
            requestedBy,
            completedCubage,
            previousJobOrderId,
            nextJobOrderId,
            isSplit,
            numberOfTrucksRequested,
        } = jobOrder;
        const pathname = history.location.pathname;
        const linkedJobId = previousJobOrderId || nextJobOrderId;

        const isJobBoardPage = pathname.includes(ROUTE_JOBS.JOB_BOARD);
        const isJobBoardDispatch = pathname.includes(ROUTE_JOBS.DISPATCH_JOB_ORDERS);
        const isSmartDispatchPage = pathname.includes(ROUTE_JOBS.SMART_DISPATCH);
        const currentJobOrderId = pathname.replace(/^\D+/g, "");
        const isJobOrderRequest = getIsJobOrderRequest(jobOrder, account);
        const showCompletedCubage = getShowCompletedCubage(completedCubage, measurementSystem);
        const isDropOffMidpoint = getIfIsDropOffMidpoint(jobOrder);
        const isPickUpMidpoint = getIfIsPickUpMidpoint(jobOrder);

        const siteIconsClasses = TYPOGRAPHY_ICONS_SITE();
        const classes = useStyles();
        const dispatch = useDispatch();
        const cardStyles = CARD_STYLES();
        const dnDStyles = DRAG_AND_DROP_STYLES();
        const joTrucks = trucks && trucks.join(", ");
        const trailerNames = trailers && trailers.map((i, index) => i.trailerName).join(", ");
        const [cardLoading, setLoading] = useState(false);

        const jobIsSelectedForBundle = isSmartDispatchPage && addToBundleSelectedJobs.includes(id);

        const hasProjectAccess = IS_ADMIN_USER(account.company.id) || iAmOwner || grantedAccess;
        const showPayloadLink = poLineItemId && projectId && hasProjectAccess;

        const [{ canDrop, isOver }, drop] = useDrop({
            accept: dropItemTypes.TRUCK,
            drop(item, monitor) {
                setLoading(true);

                return {
                    jobOrder,
                    stopLoading: () => setLoading(false),
                };
            },
            canDrop: () => {
                if (isSplit && !startDate) {
                    return false;
                }
                if (grantedAccess && !iAmOwner) {
                    return false;
                }

                return !!isJobBoardDispatch && !cardLoading && !smartDispatch;
            },
            collect: (monitor) => ({
                canDrop: monitor.canDrop(),
                isOver: monitor.isOver(),
            }),
        });

        const handleJobOrderClick = () => {
            if (!isSkeleton && setActive) {
                return setActive(jobOrder);
            } else if (isSmartDispatchPage) {
                if (addToBundleSelectedJobs.includes(id)) {
                    dispatch(removeJobFromBundleSelected(id));
                } else {
                    dispatch(addJobToBundleSelected(id));
                }
            }
        };

        return (
            <div
                onClick={handleJobOrderClick}
                className={clsx(
                    classes.jobOrder,
                    cardStyles.cardStyles,
                    cardLoading && LOADER_WHITE_OVERLAY,
                    (+currentJobOrderId || currentJobOrder) === id &&
                        !isJobBoardDispatch &&
                        !isSmartDispatchPage &&
                        "active",
                    unlimited && "is-unlimited",
                    isSmartDispatchPage && "is-smart-dispatch",
                    isSkeleton && "isSkeleton container-loader-wrap -grey",
                    "draggable-overlay-wrap",
                    jobIsSelectedForBundle && "selected-job",
                    dnDStyles.dragAndDrop,
                )}
            >
                {(isSkeleton || cardLoading) && <Loader />}
                {!isSkeleton && !unlimited && (
                    <BorderLinearProgress
                        value={isJobBoardPage ? null : completedQuantity}
                        totalValue={isJobBoardPage ? null : totalQuantity}
                    />
                )}
                {!isSmartDispatchPage &&
                    !isSkeleton &&
                    !IS_INSPECTOR_USER(account.role) &&
                    showMoreActions(jobOrder, account) && (
                        <div className={classes.moreActionWrap} style={{ zIndex: 50 + (jobOrdersListLength - index) }}>
                            <JobOrderMoreActions
                                jobOrder={jobOrder}
                                setActive={setActive}
                                showParentLoader={setLoading}
                                updateJoStatus={updateJoStatus}
                                updateJoCopySchedule={updateJoCopySchedule}
                                deleteJoById={deleteJoById}
                                isJobBoardPage={isJobBoardPage}
                            />
                        </div>
                    )}
                {!isSkeleton && jobIsSelectedForBundle && (
                    <div className={classes.checkIconWrapper}>
                        <div className="check-icon">
                            <ProjectCheckIcon />
                        </div>
                    </div>
                )}
                <div ref={isJobBoardDispatch ? drop : null} className="draggable-overlay-wrap">
                    {/*contractor can't add trucks or haulers on dispatch*/}
                    {((canDrop && !cardLoading) || isOver) && !isSmartDispatchPage && (
                        <div className="draggable-overlay">
                            <p className="draggable-text --text-center">
                                {grantedAccess && !iAmOwner
                                    ? NOT_OWNER_DRAGGABLE_OVERLAY_MESSAGE
                                    : smartDispatch
                                    ? SMART_DISPATCH_DRAGGABLE_OVERLAY_MESSAGE
                                    : DRAG_HERE_DRAGGABLE_OVERLAY_MESSAGE}
                            </p>
                        </div>
                    )}
                    <Grid container component="div" className="content" ref={drop} style={{ paddingBottom: 0 }}>
                        <Grid item xs={copySchedule ? 5 : 6} component="div">
                            <Typography className={clsx("name")}>
                                {isJobOrderRequest ? "Request" : "Job"} # {id} -{" "}
                                {extRef ? (
                                    <React.Fragment>
                                        {extRef}
                                        <br />
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                                {iAmOwner ? (
                                    <>
                                        {projectName}
                                        {projectExternalJobNumber ? ` - ${projectExternalJobNumber}` : ""}
                                    </>
                                ) : assignedBy ? (
                                    assignedBy.name
                                ) : (
                                    companyName
                                )}
                            </Typography>
                            {linkedJobId && (
                                <JobListItemLinkedJobLink
                                    linkedJobId={linkedJobId}
                                    setActive={setActive}
                                    isJobBoardDispatch={isJobBoardDispatch}
                                />
                            )}
                            <div className={clsx("qty-wrap", showCompletedCubage && "qty-wrap-responsive")}>
                                <Box className={clsx("qty")} component="div">
                                    {!isSkeleton && (
                                        <div className="finishedQty">
                                            {unitOfMeasure.id === UNIT_OF_MEASURE_HOUR
                                                ? getHoursQty(completedQuantity)
                                                : +completedQuantity?.toFixed(2)}
                                            {showCompletedCubage && <span> ({completedCubage} CUBIC YARDS)</span>}
                                        </div>
                                    )}
                                    <div style={{ padding: "0 4px" }}>{` / `}</div>
                                    {!isSkeleton &&
                                        (!unlimited ? (
                                            <div className="totalQty">
                                                {unitOfMeasure.id === UNIT_OF_MEASURE_LOAD ? (
                                                    <>
                                                        {dispatchedQuantity ? +dispatchedQuantity?.toFixed(2) : 0}
                                                        {` `}
                                                        <UnitOfMeasure value={unitOfMeasure.name} />
                                                    </>
                                                ) : unitOfMeasure.id === UNIT_OF_MEASURE_HOUR ? (
                                                    getHoursQty(totalQuantity)
                                                ) : (
                                                    <>
                                                        {+totalQuantity?.toFixed(2)}
                                                        {` `}
                                                        <UnitOfMeasure value={unitOfMeasure.name} />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                <AllInclusiveIcon className={clsx(classes.infiniteIcon)} />
                                                <div className="totalQty">
                                                    <UnitOfMeasure className="totalQty" value={unitOfMeasure.name} />
                                                </div>
                                            </React.Fragment>
                                        ))}
                                </Box>
                                {!isSkeleton && !unlimited && unitOfMeasure.id === UNIT_OF_MEASURE_LOAD && (
                                    <span className="totalQty">
                                        {+dispatchedQuantity !== +totalQuantity &&
                                            ` (${+totalQuantity.toFixed(2)} Total Requested)`}
                                    </span>
                                )}
                            </div>
                            {!IS_INSPECTOR_USER(account.role) && (
                                <Typography className="payload">
                                    {showPayloadLink ? (
                                        <PayloadLink poLineItemId={poLineItemId} projectId={projectId}>
                                            {payloadName}
                                        </PayloadLink>
                                    ) : (
                                        payloadName
                                    )}
                                    {unitPrice && !grantedAccess && restrictedCustomerShowPrice && (
                                        <React.Fragment>
                                            {` - `}
                                            <span className="--green-text">${unitPrice.toFixed(2)}</span>
                                            {` / `}
                                            <UnitOfMeasure
                                                value={unitOfMeasure.name}
                                                className={classes.unitOfMeasure}
                                                isPlural={false}
                                            />
                                        </React.Fragment>
                                    )}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={copySchedule ? 7 : 6} component="div">
                            <Grid container alignItems={"flex-end"} direction={"column"} component="div">
                                {(copySchedule || startDate) && (
                                    <Box className="startTime --text-right" component="div">
                                        <StartDate
                                            time={startDate}
                                            copySchedule={copySchedule}
                                            format={DEFAULT_DATE_TIME_FORMAT}
                                            periodFormat={DEFAULT_DATE_FORMAT}
                                            periodTimeFormat={DEFAULT_TIME_FORMAT}
                                            showIcon={false}
                                        />
                                    </Box>
                                )}
                                <Box component="div" className="status ----text-right">
                                    {!isSkeleton && <JobOrderStatusPill status={status} copySchedule={copySchedule} />}
                                </Box>
                                <Box className="numberOfTrucks --text-right" component="div">
                                    <TrucksRatio
                                        showIcon={!isSkeleton}
                                        assignedTrucks={assignedTrucksCount}
                                        requestedTrucks={+totalNeededTrucksCount}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {!isSkeleton && (
                    <Grid container justify={"space-between"} className={"content"}>
                        <Grid item xs={8}>
                            <div className="trucks-list" title={joTrucks}>
                                {joTrucks}
                            </div>
                            {trailerNames && trailerNames.length && (
                                <Tooltip title={trailerNames}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className={classes.uiIconTrailer} />
                                        <div className="trailers-list">{trailerNames}</div>
                                    </div>
                                </Tooltip>
                            )}
                            {requestedBy && (
                                <div className="requested-by" title={joTrucks}>
                                    <span className="--font-900">
                                        Requested {numberOfTrucksRequested ? `${numberOfTrucksRequested} Trucks` : ""}{" "}
                                        by {requestedBy.name}
                                    </span>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={clsx("payload", "--text-right")}>
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, isPickUpMidpoint ? "mid" : "pick-up")}
                                />
                                {jobOrder.pickupLocation.name}
                            </Typography>
                            <Typography className={clsx("payload", "--text-right")}>
                                <LocationOnIcon
                                    className={clsx(siteIconsClasses.iconSite, isDropOffMidpoint ? "mid" : "drop-off")}
                                />
                                {jobOrder.dropOffSites}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    },
    (prev, newProps) => {
        return (
            _.isEqual(prev.jobOrder, newProps.jobOrder) &&
            _.isEqual(prev.currentJobOrder, newProps.currentJobOrder) &&
            _.isEqual(prev.addToBundleSelectedJobs, newProps.addToBundleSelectedJobs)
        );
    },
);

JobOrderListItem.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    currentJobOrder: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isSkeleton: PropTypes.bool.isRequired,
    setCurrentJobOrder: PropTypes.func.isRequired,
    deleteJoById: PropTypes.func,
    setActive: PropTypes.func,
    updateJoStatus: PropTypes.func,
    updateJoCopySchedule: PropTypes.func,
    jobOrdersListLength: PropTypes.number,
    index: PropTypes.number,
    addToBundleSelectedJobs: PropTypes.array,
    measurementSystem: PropTypes.number,
};

JobOrderListItem.defaultProps = {
    isSkeleton: false,
};

export default withRouter(
    connect(
        (state) => {
            return {
                currentJobOrder: state.jobOrders.currentJobOrder,
                account: state.account,
                addToBundleSelectedJobs: state.smartDispatch.addToBundleSelectedJobs,
                measurementSystem: selectMeasureSystem(state),
                restrictedCustomerShowPrice: selectRestrictedCustomerShowPrice(state),
            };
        },
        (dispatch) => ({
            setCurrentJobOrder: (jobOrderId) => {
                dispatch(saveCurrentJobOrderIdInRedux(jobOrderId));
            },
        }),
    )(JobOrderListItem),
);
