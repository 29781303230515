import React, { useEffect } from "react";
import { formValueSelector, hasSubmitSucceeded, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";
import { selectCompanyForUpdatingAdmins } from "../../selectors/companies";
import { ButtonsGroup, Loader, PrimaryButton, SecondaryButton } from "../core";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import { Validation } from "../../helpers/validation";
import useSuccessNotification from "../../hooks/useSuccessNotification";
import { COMPANIES_ADMINS_ARE_UPDATED } from "./constants/strings";

const useStyles = makeStyles((theme) => ({
    managedHaulersForm: {
        minWidth: "60vw",
        maxWidth: "80vw",
    },
}));

const CompanyAdminsForm = ({ closeModal, handleSubmit, form, submitting, onSubmit, destroy }) => {
    const classes = useStyles();
    const showSuccess = useSuccessNotification();

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(form));
    const usersIds = useSelector((state) => formValueSelector(form)(state, "usersIds"));
    const companyForUpdatingAdmins = useSelector(selectCompanyForUpdatingAdmins);

    useEffect(() => {
        if (hasSubmitSuccess) {
            showSuccess(COMPANIES_ADMINS_ARE_UPDATED);
            closeModal();
            destroy();
        }
    }, [hasSubmitSuccess]);

    if (!companyForUpdatingAdmins) {
        return null;
    }

    return (
        <form className={clsx(submitting && LOADER_WHITE_OVERLAY, classes.companyAdminsForm)}>
            {submitting && <Loader />}
            <h2 className="title">Company Admins Of {companyForUpdatingAdmins.name}</h2>
            <DispatchersFieldSelector
                name={"usersIds"}
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                component={AsyncAutocompleteComponent}
                isMulti={true}
                label="Company Admins"
                loadParams={{ companies: companyForUpdatingAdmins.id }}
                validate={[Validation.required]}
                loadedList={usersIds}
            />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)} type="submit">
                    Save
                </PrimaryButton>
            </ButtonsGroup>
        </form>
    );
};

export const COMPANY_ADMINS_FORM = "companyAdminsForm";

export default reduxForm({ form: COMPANY_ADMINS_FORM, enableReinitialize: true })(CompanyAdminsForm);
