import React from "react";
import { SecondaryButton } from "../../core";
import { makeStyles } from "@material-ui/core";
import iconContentCopy from "../../../styles/images/content-copy.svg";
import { useDispatch } from "react-redux";
import { change } from "redux-form";

const useStyles = makeStyles(() => ({
    contentCopyIcon: {
        maskImage: `url(${iconContentCopy})`,
        WebkitMaskImage: `url(${iconContentCopy})`,
        width: 24,
        height: 24,
        backgroundColor: "currentColor",
    },
    copyProjectNotesButton: {
        marginLeft: 16,
        marginBottom: 16,
        height: 35,
        width: 175,
    },
}));

const CopyProjectNotesButton = ({ disabled, projectNotes, form }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleCopyProjectNotesClick = () => {
        dispatch(change(form, "notes", projectNotes));
    };

    return (
        <SecondaryButton
            className={classes.copyProjectNotesButton}
            disabled={disabled}
            onClick={handleCopyProjectNotesClick}
        >
            <div className={classes.contentCopyIcon} />
            copy project notes
        </SecondaryButton>
    );
};

export default CopyProjectNotesButton;
