import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import MenuList from "@material-ui/core/MenuList";
import Loader from "./loader";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";

const useStyles = makeStyles((theme) => ({
    moreActions: {
        "& button": {
            padding: 5,
        },
        "& .menu-items": {
            padding: 0,
            position: "relative",
            boxShadow: "1px 1px 5px #ccc",
            borderRadius: theme.shape.borderRadius,
            outline: "none",

            "& .more-actions-item": {
                padding: "10px 20px",
                color: theme.palette.primary.main,
                fontSize: 14,
                fontWeight: theme.typography.fontWeightBold,
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",

                "& svg": {
                    marginRight: 15,
                    color: "inherit",
                },
            },
        },
        "& .popper": {
            zIndex: 100,
            boxShadow: "none",
        },
        "&.hidden": {
            height: 13,
            visibility: "hidden",
        },
    },
}));

const MoreActions = (props) => {
    const classes = useStyles();
    const { children, placement, className, isLoading } = props;
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            const callOnClick = (e) => {
                child.props.onClick(e);
                handleClickAway();
            };

            return React.cloneElement(child, { onClick: callOnClick });
        }

        return child;
    });

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={clsx(classes.moreActions, "more-actions", className)}>
                <IconButton
                    aria-label="more-actions"
                    title="More action"
                    ref={anchorRef}
                    className="more-actions"
                    onClick={handleClick}
                >
                    <MoreHorizIcon />
                </IconButton>
                <br />
                {open ? (
                    <Popper
                        open={true}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        placement={placement}
                        className={clsx(classes.popper, "popper")}
                        disablePortal
                    >
                        <Paper className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                            {isLoading && <Loader />}
                            <MenuList id="split-button-menu" className="menu-items" onClick={handleClickAway}>
                                {childrenWithProps}
                            </MenuList>
                        </Paper>
                    </Popper>
                ) : null}
            </div>
        </ClickAwayListener>
    );
};

MoreActions.propTypes = {
    children: PropTypes.any.isRequired,
    placement: PropTypes.string.isRequired,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
};

MoreActions.defaultProps = {
    placement: "bottom-end",
};

export default withRouter(MoreActions);
