import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { Validation } from "../../helpers/validation";
import { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import TextInputComponent from "../core/form/textInputComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import clsx from "clsx";
import Loader from "../core/loader";
import { createNewTruckTypeRequest, updateTruckTypeRequest } from "../../dataServers/trucks";
import { getCapacityAdornment, getVolumeCapacityAdornment } from "../../helpers/measureSystemHelpers";
import { selectMeasureSystem } from "../../selectors";

const UpdateOrCreateTruckTypeForm = (props) => {
    const {
        closeModal,
        handleSubmit,
        handleTruckTypeSaveSuccess,
        truckTypeToUpdateId,
        errorHandler,
        handleCreateTruckType,
        fetchTruckTypes,
        handleUpdateTruckType,
        companyToRequestId,
        measureSystem,
    } = props;
    const [isLoading, setLoading] = useState(false);

    const onSubmit = (values) => {
        setLoading(true);
        const data = {
            name: values.name,
            defaultCapacity: values.defaultCapacity,
            defaultVolumeCapacity: values.defaultVolumeCapacity || null,
        };

        const request = truckTypeToUpdateId
            ? updateTruckTypeRequest(truckTypeToUpdateId, data)
            : createNewTruckTypeRequest(companyToRequestId, data);

        request
            .then(({ data }) => {
                setLoading(false);
                closeModal();
                const successMessage = truckTypeToUpdateId
                    ? "Truck type was successfully updated"
                    : "New truck was type successfully created";
                if (truckTypeToUpdateId) {
                    handleUpdateTruckType(data);
                } else {
                    fetchTruckTypes && fetchTruckTypes();
                    handleCreateTruckType && handleCreateTruckType(data);
                }
                handleTruckTypeSaveSuccess && handleTruckTypeSaveSuccess(successMessage);
            })
            .catch((error) => {
                const processedError = PROCESS_SERVER_ERROR(error);
                setLoading(false);
                errorHandler && errorHandler(processedError);
            });
    };

    return (
        <form noValidate={true} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader />}
            <h2 className="title">{truckTypeToUpdateId ? "Update truck type" : "Add new truck type"}</h2>
            <br />
            <Field
                type="text"
                name="name"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={[Validation.required]}
                label="Name"
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                type="number"
                name="defaultCapacity"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={[Validation.required, Validation.isNumber]}
                label="Default Weight Capacity"
                endAdornment={getCapacityAdornment(measureSystem)}
                component={TextInputComponent}
            />
            <br />
            <br />
            <Field
                type="number"
                name="defaultVolumeCapacity"
                className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                validate={Validation.isNumber}
                label="Default Volume Capacity"
                endAdornment={<span style={{ textAlign: "center" }}>{getVolumeCapacityAdornment(measureSystem)}</span>}
                component={TextInputComponent}
            />
            <br />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)}>Save</PrimaryButton>
            </ButtonsGroup>
        </form>
    );
};

UpdateOrCreateTruckTypeForm.propTypes = {
    measureSystem: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleTruckTypeSaveSuccess: PropTypes.func,
    handleCreateTruckType: PropTypes.func,
    errorHandler: PropTypes.func,
    fetchTruckTypes: PropTypes.func,
    handleUpdateTruckType: PropTypes.func,
    truckTypeToUpdateId: PropTypes.number,
    companyToRequestId: PropTypes.number,
};

export const UPDATE_OR_CREATE_TRUCK_TYPE = "updateOrCreateTruckType";

export default withRouter(
    compose(
        reduxForm({
            form: UPDATE_OR_CREATE_TRUCK_TYPE,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(state, "capacity", "name"),
                measureSystem: selectMeasureSystem(state),
            };
        }),
    )(UpdateOrCreateTruckTypeForm),
);
