import React from "react";
import FieldNote from "../../core/form/fieldNote";

export const SAME_EXTERNAL_REF_TICKET_LABEL = "Ticket with same number already exists";

const ExistedTicketWarning = ({ exists }) => {
    if (!exists) {
        return null;
    }

    return <FieldNote fieldNote={SAME_EXTERNAL_REF_TICKET_LABEL} isError={true} />;
};

export default ExistedTicketWarning;
