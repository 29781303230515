import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import { COMPANY_TYPES, IS_INSPECTOR_USER, IS_LIMITED_COMPANY, PO_TYPES } from "../../constants/maps";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import * as CompaniesActions from "../../actions/companies";
import {
    updateCompany,
    updateCompanyProfile,
    updateCompanyStripeSubscription,
    uploadCompanyCertificate,
} from "../../dataServers/companies";
import AllCompaniesList from "./allCompaniesList";
import ErrorNotification from "../core/notification";
import AppModal from "../core/modal";
import CompanyForm, { COMPANY_FORM } from "./companyForm";
import { preparePhone } from "../../helpers/global";
import { ACCOUNT_DATA_STORAGE_KEY } from "../../helpers/api";
import { authorizeUser, saveUsersRegions } from "../../actions/globalActions";
import CompanyFilters from "./companyFilters";
import { getCompanyRegions } from "../../dataServers/projects";
import { ACCOUNT_TYPES, getIsStripeAccount } from "../../constants/accounts";
import { fullCompanyAddress } from "../../constants/users.js";
import ManagedHaulersForm, { MANAGED_HAULERS } from "./managedHaulersForm";
import { selectCompanyForManagingHaulers, selectCompanyForUpdatingAdmins } from "../../selectors/companies";
import CompanyAdminsForm, { COMPANY_ADMINS_FORM } from "./companyAdminsForm";

export const DEFAULT_ALLOWED_PERIOD_IN_DAYS = 10;

function AllCompaniesWrapper(props) {
    const {
        getCompanies,
        clearCompanyForEdit,
        resetCompanies,
        companiesError,
        companyForEdit,
        account,
        authorizeUserWithData,
        saveRegions,
        clearCompanyForManagingHaulers,
        clearCompanyForUpdatingAdmins,
        companyForManagingHaulers,
        companyForUpdatingAdmins,
        submitCompanyAdminsForm,
    } = props;
    const [error, setError] = useState();
    const [appliedFilters, setFilters] = useState({ withArchived: true });
    const activeCompaniesOrder = useSelector((state) => state.companies.activeCompaniesOrder);
    const updateListByFilters = (filters) => {
        let newFilters = { ..._.cloneDeep(filters), withArchived: true };

        if (!_.isEqual(appliedFilters, newFilters)) {
            setFilters(newFilters);
        }
    };
    const onMessageClose = () => {
        setError(null);
    };

    const initUserData = (userData) => {
        authorizeUserWithData(userData);
        if (userData.company && !IS_INSPECTOR_USER(userData.role)) {
            saveRegions(userData.company.id);
        }
    };

    const loadCompanies = (concat) => {
        let params = { ...appliedFilters };

        getCompanies(concat, params);
    };

    const parseAddress = (companyData) => {
        const isStripeAccount = companyData.accountType === 3 || companyData.accountType === 4;
        if (isStripeAccount) {
            return companyData.address ? companyData.address : null;
        } else {
            return companyData.address ? { label: companyData.address } : null;
        }
    };

    const mapCompanyData = (companyData) => {
        if (companyData) {
            const isStripeAccount = getIsStripeAccount(companyData.accountType);

            return {
                ...companyData,
                name: companyData.name,
                address: parseAddress(companyData),
                state: companyData.state ? { label: companyData.state } : null,
                purchaseOrderType: companyData.poTypeId
                    ? { value: companyData.poTypeId, label: PO_TYPES[companyData.poTypeId] }
                    : null,
                timezone: {
                    value: companyData.timezone.id,
                    label: `${companyData.timezone.name} (GMT ${
                        companyData.timezone.offset > 0
                            ? `+${companyData.timezone.offset}`
                            : `${companyData.timezone.offset}`
                    })`,
                },
                certificateFile: companyData.certificate ? companyData.certificate.fileName : null,
                MBE: companyData.mbe,
                DBE: companyData.dbe,
                WBE: companyData.wbe,
                companyType: { value: companyData.type, label: COMPANY_TYPES[companyData.type] },
                accountType: { value: companyData.accountType, label: ACCOUNT_TYPES[companyData.accountType] },
                paymentPhone: companyData.paymentPhone,
                truckShiftAllowed: companyData.truckShiftAllowed || false,
                usesIotDevices: companyData.usesIotDevices || false,
                allowedPeriodAfterPaymentFailed: isStripeAccount
                    ? companyData.allowedPeriodAfterPaymentFailed || DEFAULT_ALLOWED_PERIOD_IN_DAYS
                    : DEFAULT_ALLOWED_PERIOD_IN_DAYS,
                stripeId: isStripeAccount ? companyData.stripeId : null,
            };
        }

        return {
            allowedPeriodAfterPaymentFailed: DEFAULT_ALLOWED_PERIOD_IN_DAYS,
        };
    };

    const handleCompanyUpdate = (values) => {
        const {
            name,
            address,
            companyType,
            accountType,
            purchaseOrderType,
            timezone,
            certificateFile,
            certifiedPayroll,
            ignoreJobsLimit,
            WBE,
            MBE,
            DBE,
            paymentName,
            paymentEmail,
            paymentPhone,
            truckShiftAllowed,
            usesIotDevices,
            streetAddress,
            secondaryAddress,
            state,
            city,
            zipCode,
            allowedPeriodAfterPaymentFailed,
            stripeId,
        } = values;
        const companyTypeValue = companyType.value;

        const isStripeAccount = getIsStripeAccount(accountType.value);

        const updateCompanyData = {
            name,
            dbe: DBE,
            wbe: WBE,
            mbe: MBE,
            accountType: accountType.value,
            ignoreJobsLimit: ignoreJobsLimit,
            certifiedPayroll: certifiedPayroll,
            poType: parseInt(purchaseOrderType.value),
            timezone: { id: timezone.value },
        };
        if (!IS_LIMITED_COMPANY(companyTypeValue)) {
            updateCompanyData.type = companyTypeValue;
        }
        if (isStripeAccount) {
            updateCompanyData.stripeId = stripeId;
        }

        const updateCompanyProfileData = {
            paymentName: paymentName || undefined,
            paymentEmail: paymentEmail || undefined,
            paymentPhone: paymentPhone ? preparePhone(paymentPhone) : undefined,
            streetAddress: streetAddress ? streetAddress : null,
            secondaryAddress: secondaryAddress ? secondaryAddress : null,
            state: state ? state.label : null,
            address: address ? (address.label ? address.label : fullCompanyAddress(values)) : null,
            city: city ? city : null,
            zipCode: zipCode ? zipCode : null,
            truckShiftAllowed: truckShiftAllowed,
            usesIotDevices: usesIotDevices,
        };

        const updateCompanyPromises = [
            updateCompany(companyForEdit.id, updateCompanyData),
            updateCompanyProfile(companyForEdit.id, updateCompanyProfileData),
        ];

        return Promise.all(updateCompanyPromises)
            .then(async ([{ data: companyData }, { data: newProfileData }]) => {
                if (isStripeAccount) {
                    const updateCompanyStripeSubscriptionData = {
                        allowedPeriodAfterPaymentFailed,
                    };

                    await updateCompanyStripeSubscription(companyForEdit.id, updateCompanyStripeSubscriptionData);
                }
                if (account.company.id === companyData.id) {
                    const currentData = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));
                    const newDate = {
                        ...currentData,
                        companyProfile: { ...currentData.companyProfile, ...newProfileData },
                    };

                    localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(newDate));
                    initUserData(newDate);
                }

                clearCompanyForEdit();

                if (certificateFile && certificateFile.length !== 0) {
                    return uploadCompanyCertificate(companyForEdit.id, certificateFile[0]);
                }

                loadCompanies(false);

                return Promise.resolve();
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const getMoreCompanies = (concat) => loadCompanies(concat);

    useEffect(() => {
        return () => {
            clearCompanyForEdit();
            clearCompanyForManagingHaulers();
            clearCompanyForUpdatingAdmins();
        };
    }, []);

    useEffect(() => {
        loadCompanies(false);

        return () => {
            resetCompanies();
        };
    }, [appliedFilters, activeCompaniesOrder]);

    return (
        <div>
            {error && <ErrorNotification error={error} config={{ onClose: onMessageClose }} />}
            <CompanyFilters
                updateListByFilters={updateListByFilters}
                getCompanies={getCompanies}
                mapCompanyData={mapCompanyData}
                appliedFilters={appliedFilters}
            />
            <AllCompaniesList fetchMore={getMoreCompanies} getCompanies={getCompanies} />
            {companiesError && <ErrorNotification message={companiesError} />}
            <AppModal isOpen={!!companyForEdit} form={COMPANY_FORM} closeModal={clearCompanyForEdit}>
                <h2 className="title">Edit company</h2>
                <br />
                <CompanyForm
                    closeModal={clearCompanyForEdit}
                    handleSave={handleCompanyUpdate}
                    initialValues={{ ...mapCompanyData(companyForEdit) }}
                    isCreateForm={false}
                />
            </AppModal>
            <AppModal isOpen={!!companyForManagingHaulers} closeModal={clearCompanyForManagingHaulers}>
                <ManagedHaulersForm
                    closeModal={clearCompanyForManagingHaulers}
                    initialValues={{ [MANAGED_HAULERS]: companyForManagingHaulers?.subCompanies }}
                />
            </AppModal>
            <AppModal
                form={COMPANY_ADMINS_FORM}
                isOpen={!!companyForUpdatingAdmins}
                closeModal={clearCompanyForUpdatingAdmins}
                modalStyles={{ minWidth: 700, maxWidth: "70vw" }}
            >
                <CompanyAdminsForm
                    initialValues={{ usersIds: companyForUpdatingAdmins?.companyAdmins }}
                    closeModal={clearCompanyForUpdatingAdmins}
                    onSubmit={submitCompanyAdminsForm}
                />
            </AppModal>
        </div>
    );
}

AllCompaniesWrapper.propTypes = {
    clearCompanyForEdit: PropTypes.func.isRequired,
    getCompanies: PropTypes.func.isRequired,
    resetCompanies: PropTypes.func.isRequired,
    authorizeUserWithData: PropTypes.func.isRequired,
    companiesError: PropTypes.string,
    companyForEdit: PropTypes.object,
    account: PropTypes.object.isRequired,
    saveRegions: PropTypes.func.isRequired,
    clearCompanyForUpdatingAdmins: PropTypes.func,
    clearCompanyForManagingHaulers: PropTypes.func,
    submitCompanyAdminsForm: PropTypes.func,
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                companyForEdit: state.companies.companyForEdit,
                companiesError: state.companies.companiesError,
                companyForManagingHaulers: selectCompanyForManagingHaulers(state),
                companyForUpdatingAdmins: selectCompanyForUpdatingAdmins(state),
            };
        },
        (dispatch) => ({
            getCompanies: (concat, params) => dispatch(CompaniesActions.getCompanies(concat, params)),
            resetCompanies: (payload) => dispatch(CompaniesActions.resetCompanies(payload)),
            clearCompanyForEdit: (payload) => dispatch(CompaniesActions.clearCompanyForEdit(payload)),
            authorizeUserWithData: (accountData) => {
                dispatch(authorizeUser(accountData));
            },
            saveRegions: (companyId) => {
                getCompanyRegions(companyId).then((data) => {
                    const result = data.data;
                    if (!_.isEmpty(result)) {
                        dispatch(
                            saveUsersRegions(
                                result.map((item) => ({
                                    name: item.regionName,
                                    id: item.id,
                                })),
                            ),
                        );
                    }
                });
            },
            clearCompanyForManagingHaulers: () => dispatch(CompaniesActions.updateCompanyForManagingHaulers(null)),
            clearCompanyForUpdatingAdmins: () => dispatch(CompaniesActions.updateCompanyForUpdatingAdmins(null)),
            submitCompanyAdminsForm: (values) => dispatch(CompaniesActions.submitCompanyAdminsForm(values)),
        }),
    )(AllCompaniesWrapper),
);
