import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import TableWithChoose from "../core/tableWithChoose";
import { selectAccount } from "../../selectors/index";
import { loadCompanyTrucks } from "../../actions/trucks";
import { DEFAULT_DATE_FORMAT, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { selectTrailers, actions as trailersActions } from "../../reducers/trailersReducer";
import { getTrailers } from "../../actions/trailers";

const headCells = [
    { id: "id", label: "TRAILER ID" },
    { id: "name", label: "TRAILER NAME" },
    { id: "capacity", label: "WEIGHT CAPACITY" },
    { id: "volumeCapacity", label: "VOLUME CAPACITY	" },
    { id: "axle", label: "AXLE" },
];

export default function SelectTrailers({ handleClose, form, name }) {
    const dispatch = useDispatch();
    const trailers = useSelector(selectTrailers);

    const createData = (data, index) => ({
        id: data.id,
        name: data.name,
        capacity: data.capacity,
        volumeCapacity: data.volumeCapacity,
        axle: data.axle,
    });

    useEffect(() => {
        dispatch(getTrailers());
    }, []);

    return (
        <TableWithChoose
            form={form}
            name={name}
            rows={trailers || []}
            createData={createData}
            headCells={headCells}
            activeOrderParam={"id"}
            handleClose={handleClose}
        />
    );
}
