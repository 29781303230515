import dataProvider from "../helpers/api";
import _ from "lodash";
import { GLOBAL_COUNT_TO_LOAD, PURCHASE_ORDERS } from "../constants/endpoints";
import { SITES_TYPE_PRIVATE } from "../constants/maps";

export const getPurchaseOrders = (params = {}) => {
    return dataProvider({
        url: PURCHASE_ORDERS.LIST(),
        method: "GET",
        params: {
            order: "id",
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            project: params.project,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const createPurchaseOrder = (data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.CREATE_PURCHASE_ORDER(),
        method: "POST",
        data,
    });
};

export const addPurchaseOrder = (data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.CREATE_PURCHASE_ORDER_2(),
        method: "POST",
        data,
    });
};

export const getPurchaseOrdersItems = (poId, params = {}) => {
    return dataProvider({
        url: PURCHASE_ORDERS.POLI(poId),
        method: "GET",
        params: {
            order: "id",
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
            project: params.project,
        },
    });
};

export const createPurchaseOrdersItems = (poId, data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.POLI(poId),
        method: "POST",
        data,
    });
};

export const updatePurchaseOrderItems = (poId, data) => {
    return dataProvider({
        url: PURCHASE_ORDERS.PURCHASE_ORDER(poId),
        method: "PUT",
        data,
    });
};

export const getPayloadsList = (companyId, params = {}) => {
    let requestParams = _.cloneDeep(params);
    delete requestParams.cancelToken;

    return dataProvider({
        url: PURCHASE_ORDERS.COMPANY_PAYLOADS(companyId),
        method: "GET",
        params: {
            ...requestParams,
            order: "name",
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords,
        },
    });
};

export const getUnitOfMeasureList = (params) => {
    return dataProvider({
        url: PURCHASE_ORDERS.UNIT_OF_MEASURE_LIST(),
        method: "GET",
        params,
    });
};

export const getSitesList = (params = {}) => {
    return dataProvider({
        url: PURCHASE_ORDERS.SITES(),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords || undefined,
            accessType: params.accessType || undefined,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const fetchCompanySitesList = (params = {}) => {
    return dataProvider({
        url: PURCHASE_ORDERS.SITES(),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            keywords: params.keywords || undefined,
            accessType: params.accessType || undefined,
            company: params.accessType === SITES_TYPE_PRIVATE ? params.company : undefined,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};
