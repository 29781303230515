import _ from "lodash";
import { selectCompanyId } from "./index";
import { CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM } from "../components/smartDispatch/RegularJobs";
import { IS_ADMIN_USER } from "../constants/maps";

export const selectSmartDispatch = (state) => state.smartDispatch;
export const selectSmartDispatchMeta = (state) => selectSmartDispatch(state).meta;
export const selectAddToBundleSelectedJobs = (state) => selectSmartDispatch(state).addToBundleSelectedJobs;
export const selectBundlesList = (state) => selectSmartDispatch(state).bundlesList;
export const selectBundlesListCount = (state) => selectSmartDispatch(state).bundlesListCount;
export const selectBundlesFilters = (state) => selectSmartDispatch(state).bundlesFilters;

export const selectCurrentBundle = (state) => selectSmartDispatch(state).currentBundle;
export const selectCurrentBundleJobOrders = (state) => selectCurrentBundle(state)?.jobOrders;
export const selectCurrentBundleId = (state) => selectCurrentBundle(state).problemId;
export const selectCurrentBundleSolution = (state) => selectCurrentBundle(state)?.solution;
export const selectCurrentBundleIAmOwner = (state) => selectCurrentBundle(state)?.iAmOwner;
export const selectCurrentBundleAllowSubhaulers = (state) => selectCurrentBundle(state)?.allowSubhaulers;
export const selectSolutionEmptyRoutes = (state) => {
    const solution = selectCurrentBundleSolution(state);
    const iAmOwner = selectCurrentBundleIAmOwner(state);
    const companyId = selectCompanyId(state);
    const isSubhauler = selectIsSubhauler(state);

    const isAdmin = IS_ADMIN_USER(companyId);

    return (solution ?? []).filter((route) => {
        // Customer routes, check if assigned truck or hauler
        if (iAmOwner || isAdmin) {
            return !route.assignedTruck && !route.primeHauler;
        }

        // subhauler routes, current company is in subhauler field of route and no assigned truck
        if (isSubhauler) {
            return !route.assignedTruck && route.subHauler?.id === companyId;
        }

        //Hauler routes, assigned truck or not this hauler
        return !route.assignedTruck && !route.subHauler && route.primeHauler?.id === companyId;
    });
};
export const selectRouteIsEmpty = (state, routeIndex) =>
    selectSolutionEmptyRoutes(state)
        .map((route) => route.index)
        .includes(routeIndex);

export const selectSelectedTruckSolutionList = (state) => selectSmartDispatch(state).selectedTruckSolutionList;
export const selectActiveSmartDispatchTab = (state) => selectSmartDispatch(state).activeSmartDispatchTab;
export const selectSmartDispatchErrorMessage = (state) => selectSmartDispatch(state).errorMessage;
export const selectSmartDispatchSuccessMessage = (state) => selectSmartDispatch(state).successMessage;
export const selectSmartDispatchIsLoading = (state) => selectSmartDispatch(state).isLoading;
export const selectSmartDispatchIsConfirmationModalShown = (state) =>
    selectSmartDispatch(state).isConfirmationModalShown;
export const selectSmartDispatchIsHaulerAssignModalShown = (state) =>
    selectSmartDispatch(state).isHaulerAssignModalShown;
export const selectBundleAssignData = (state) => selectSmartDispatch(state).bundleAssignData;
export const selectHaulerToAssign = (state) => selectSmartDispatch(state).haulerToAssign;

export const selectTruckRouteLocation = (state) => selectSmartDispatch(state).truckRouteLocation;

export const selectRouteFirstPickUpLocation = (state) => {
    const solution = selectCurrentBundleSolution(state);
    const jobOrders = selectCurrentBundleJobOrders(state);
    const selectedTruckSolutionList = selectSelectedTruckSolutionList(state);
    if (selectedTruckSolutionList && selectedTruckSolutionList.length) {
        const routeId = selectedTruckSolutionList[0];
        const route = solution && solution.find((route) => route.id === routeId);

        const jobId = route && route.routeItems[0].jobId;
        const jobOrder = jobOrders && jobOrders.length && jobOrders.find((i) => i.id === jobId);

        return jobOrder?.pickupLocation;
    }

    return null;
};

export const selectIfIsTruckSolutionSelected = (state) => _.isInteger(selectSelectedTruckSolutionList(state)[0]);

export const selectJobsForTrucksData = (state) => state.smartDispatch.jobsForTrucks.data;
export const selectJobsForTrucksMeta = (state) => state.smartDispatch.jobsForTrucks.meta;

export const selectRoutesData = (state) => state.smartDispatch.routes.data;
export const selectRoutesMeta = (state) => state.smartDispatch.routes.meta;

export const selectRouteDetailsData = (state) => state.smartDispatch.routeDetails.data;
export const selectRouteJobs = (state) => selectRouteDetailsData(state)?.jobOrders;
export const selectRouteDetailsDataIAmOwner = (state) =>
    state.smartDispatch.routeDetails.data && state.smartDispatch.routeDetails.data.iAmOwner;
export const selectIfHasSelectedRoute = (state) => !!state.smartDispatch.routeDetails.data;
export const selectRouteDetailsMeta = (state) => state.smartDispatch.routeDetails.meta;

export const selectIfRouteIsSelected = (state) => !_.isNull(state.smartDispatch.selectedRoute);

export const selectActiveRegularJobsSortParam = (state) => selectSmartDispatch(state).activeSortParam;

export const selectSmartDispatchJobsFromRoute = (state) => {
    const routeJobs = selectRouteJobs(state);
    return routeJobs?.filter((job) => !job.manuallyAdded);
};
export const selectFirstRouteSmartDispatchJob = (state) => {
    const smartDispatchJobs = selectSmartDispatchJobsFromRoute(state);
    return smartDispatchJobs && smartDispatchJobs[0];
};
export const selectLastRouteSmartDispatchJob = (state) => {
    const smartDispatchJobs = selectSmartDispatchJobsFromRoute(state);
    return smartDispatchJobs && smartDispatchJobs[smartDispatchJobs?.length - 1];
};
export const selectProximityLocation = (state) => {
    const proximityTo = selectActiveRegularJobsSortParam(state);
    if (proximityTo === CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM) {
        const firstSmartDispatchJob = selectFirstRouteSmartDispatchJob(state);
        return firstSmartDispatchJob && { ...firstSmartDispatchJob.pickupLocation, isPickUp: true };
    }
    const lastSmartDispatchJob = selectLastRouteSmartDispatchJob(state);
    return lastSmartDispatchJob && { ...lastSmartDispatchJob.dropoffLocations[0], isPickUp: false };
};
export const selectProximityLocationId = (state) => selectProximityLocation(state)?.id;
export const selectJobForProximity = (state) => {
    const proximityTo = selectActiveRegularJobsSortParam(state);

    if (proximityTo === CLOSEST_TO_FIRST_PICK_UP_PROXIMITY_PARAM) {
        return selectFirstRouteSmartDispatchJob(state);
    }

    return selectLastRouteSmartDispatchJob(state);
};

export const selectIsSubhauler = (state) => {
    const companyId = selectCompanyId(state);
    const solution = selectCurrentBundleSolution(state);

    const subHaulerRoutes = solution?.filter((route) => {
        return route?.subHauler?.id && route.subHauler.id === companyId;
    });

    // if company is subHauler on all routes - company in subHauler
    return subHaulerRoutes && subHaulerRoutes.length === solution.length;
};

export const selectDeployedTrucksData = (state) => state.smartDispatch.deployedTrucks.data;
export const selectDeployedTrucksMeta = (state) => state.smartDispatch.deployedTrucks.meta;
