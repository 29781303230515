import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import moment from "moment";
import { notificationSnackbarsHide, notificationSnackbarsShow } from "../../actions/notifications";
import { getForecast } from "../../dataServers/weather";
import { getTicketsCount } from "../../dataServers/tickets";
import * as statsActions from "../../actions/stats";
import { getJobOrderStats } from "../../actions/stats";
import { requestWeather, requestWeatherResult } from "../../actions/globalActions";
import { selectMeasureSystem, selectWeather, selectWeatherMeta } from "../../selectors/index";
import { loadCurrentCoordinates } from "../../helpers/location";
import { ROUTE_JOBS } from "../../routes/globalRoutes";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import Loader from "../core/loader";
import { Divider } from "../core/divider";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import WhetherForecast from "./weatherForecast";
import { FILTERS_JOB_REQUESTS, FILTERS_STATUS_ID } from "../global/searchAndFilters";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import _ from "lodash";
import { JO_REQUEST_ID, JO_STATUS_ACTIVE_ID, JO_STATUS_PENDING_ID } from "../../constants/maps";
import LivemapWrapper from "./livemapWrapper";
import StatisticDashboard from "./statisticDashboard";

export const DASHBOARD_STATS_BUTTON_HEIGHT = "45px";

const useStyles = makeStyles(() => ({
    dashboard: {
        "& .header-box": {
            paddingBottom: 20,
        },
        "& .search-and-filters": {
            position: "relative",
        },
        "& .notification-label": {
            fontSize: 12,
        },
        "& .stats-table": {
            "& td": {
                paddingRight: 5,
            },
        },
    },
    weatherBox: {
        position: "relative",
        top: 0,
        bottom: 0,
        margin: "auto",
    },
    today: {
        fontSize: 20,
        fontWeight: 800,
    },
    statsBox: {
        marginBottom: 10,
        width: "80%",

        "& button": {
            width: "100%",
            color: "rgba(0, 0, 0, 0.87)",
            lineHeight: "17px",
            textTransform: "none",
            fontSize: 14,
        },

        "&:last-child": {
            marginBottom: 0,
        },

        "& .stats-button": {
            height: DASHBOARD_STATS_BUTTON_HEIGHT,
        },
    },
}));

const DashboardWrapper = React.memo(
    (props) => {
        const {
            history,
            activeJobsCount,
            pendingJobsCount,
            jobRequestsCount,
            setStats,
            getTicketsStats,
            weather,
            weatherLoading,
            requestWeatherResult,
            requestWeather,
            system,
        } = props;
        const dispatch = useDispatch();
        const [isStatsLoading, setStatsLoader] = useState(false);
        const classes = useStyles();
        const canShowTruckOnMap = (liveMapTruck) =>
            !!liveMapTruck.truckCoordinates || !!liveMapTruck.pickupSite || !!liveMapTruck.dropoffSite;

        const currentDate = moment().format(SERVER_DATE_FORMAT);
        const openActiveJobs = () => {
            history.push(ROUTE_JOBS.MY_JOBS, {
                initialFilters: {
                    [FILTERS_STATUS_ID]: { [JO_STATUS_ACTIVE_ID]: true },
                    [START_DATE_NAME]: currentDate,
                    [END_DATE_NAME]: currentDate,
                },
            });
        };
        const openPendingJobs = () => {
            history.push(ROUTE_JOBS.MY_JOBS, {
                initialFilters: {
                    [FILTERS_STATUS_ID]: { [JO_STATUS_PENDING_ID]: true },
                    [START_DATE_NAME]: currentDate,
                    [END_DATE_NAME]: currentDate,
                },
            });
        };
        const openJobRequests = () => {
            history.push(ROUTE_JOBS.MY_JOBS, {
                initialFilters: {
                    [FILTERS_JOB_REQUESTS]: { [JO_REQUEST_ID]: true },
                    [START_DATE_NAME]: currentDate,
                    [END_DATE_NAME]: currentDate,
                },
            });
        };

        const getQuantitiesStats = () => {
            setStatsLoader(true);

            Promise.all([getJobOrderStats(), getTicketsCount({ [START_DATE_NAME]: moment() })])
                .then(([{ activeJobsCount, pendingJobsCount, jobRequestsCount }, tickets]) => {
                    setStats({
                        allTicketsCount: tickets.data,
                        activeJobsCount,
                        pendingJobsCount,
                        jobRequestsCount,
                    });
                    setStatsLoader(false);
                })
                .catch(() => {
                    setStatsLoader(false);
                });
        };
        const getWeather = () => {
            if (weather) return;
            requestWeather();
            loadCurrentCoordinates()
                .then((coordinates) => getForecast(coordinates))
                .then((response) => {
                    requestWeatherResult(response.data);
                });
        };

        useEffect(() => {
            dispatch(notificationSnackbarsHide());

            return () => {
                dispatch(notificationSnackbarsShow());
            };
        }, [dispatch]);

        useEffect(() => {
            getWeather();
            getQuantitiesStats();
            getTicketsStats();
        }, []);

        return (
            <div className={clsx(classes.dashboard)}>
                <br />
                <Grid
                    component="div"
                    container
                    justify={"space-between"}
                    alignItems={"flex-start"}
                    className={clsx("header-box")}
                >
                    <Grid
                        item
                        xs={3}
                        component="div"
                        container
                        alignItems={"center"}
                        className={clsx(classes.weatherBox, weatherLoading && LOADER_WHITE_OVERLAY)}
                    >
                        {weatherLoading && <Loader />}
                        {weather && <WhetherForecast system={system} weatherToday={weather} />}
                    </Grid>
                    <Grid
                        component="div"
                        container
                        direction={"column"}
                        alignItems={"center"}
                        item
                        xs={2}
                        justify={"space-between"}
                    >
                        <Grid item className={clsx(classes.statsBox)}>
                            <SecondaryButton
                                onClick={openActiveJobs}
                                className={clsx(isStatsLoading && LOADER_WHITE_OVERLAY, "stats-button")}
                            >
                                {isStatsLoading && <Loader />}
                                {activeJobsCount}
                                <br />
                                Active Jobs
                            </SecondaryButton>
                        </Grid>
                        <Grid item className={clsx(classes.statsBox)}>
                            <SecondaryButton
                                onClick={openPendingJobs}
                                className={clsx(isStatsLoading && LOADER_WHITE_OVERLAY, "stats-button")}
                            >
                                {isStatsLoading && <Loader />}
                                {pendingJobsCount}
                                <br />
                                Pending Jobs
                            </SecondaryButton>
                        </Grid>
                        <Grid item className={clsx(classes.statsBox)}>
                            <SecondaryButton
                                onClick={openJobRequests}
                                className={clsx(isStatsLoading && LOADER_WHITE_OVERLAY, "stats-button")}
                            >
                                {isStatsLoading && <Loader />}
                                {jobRequestsCount}
                                <br />
                                Job Requests
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                    <StatisticDashboard isStatsLoading={isStatsLoading} />
                </Grid>
                <Divider marginTop={0} marginBottom={2} />
                <LivemapWrapper canShowTruckOnMap={canShowTruckOnMap} />
            </div>
        );
    },
    (prev, newProps) => {
        return _.isEqual(prev, newProps);
    },
);

DashboardWrapper.propTypes = {
    account: PropTypes.object.isRequired,
    allTicketsCount: PropTypes.number,
    activeJobsCount: PropTypes.number.isRequired,
    pendingJobsCount: PropTypes.number.isRequired,
    jobRequestsCount: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    ticketsStats: PropTypes.object,
    setStats: PropTypes.func.isRequired,
    getTicketsStats: PropTypes.func.isRequired,
    weather: PropTypes.object,
    weatherLoading: PropTypes.bool,
    requestWeatherResult: PropTypes.func,
    requestWeather: PropTypes.func,
    system: PropTypes.number,
};

DashboardWrapper.defaultProps = {
    formValues: {},
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                allTicketsCount: state.stats.allTicketsCount,
                activeJobsCount: state.stats.activeJobsCount,
                pendingJobsCount: state.stats.pendingJobsCount,
                jobRequestsCount: state.stats.jobRequestsCount,
                weather: selectWeather(state),
                weatherLoading: selectWeatherMeta(state).loading,
                system: selectMeasureSystem(state),
            };
        },
        (dispatch) => ({
            setStats: (payload) => {
                dispatch(statsActions.setStats(payload));
            },
            getTicketsStats: (payload) => {
                dispatch(statsActions.getTicketsStats(payload));
            },
            requestWeather: (payload) => {
                dispatch(requestWeather());
            },
            requestWeatherResult: (payload) => {
                dispatch(requestWeatherResult(payload));
            },
        }),
    )(DashboardWrapper),
);
