import dataProvider from "../helpers/api";
import { GLOBAL_COUNT_TO_LOAD, PO_LINE_ITEMS } from "../constants/endpoints";

export const getPOLineItems = (projectId, params = {}) => {
    const restParams = {};
    if (params.archived) restParams.archived = params.archived;
    if (params.poLineItemIds) restParams.poLineItemIds = params.poLineItemIds;

    return dataProvider({
        url: PO_LINE_ITEMS.PROJECT_PO_LINE_ITEMS(projectId),
        method: "GET",
        params: {
            page: params.page || 1,
            perPage: params.limit || GLOBAL_COUNT_TO_LOAD,
            order: params.order || "name",
            ...restParams,
        },
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const updatePoLineItem = (id, data) => {
    return dataProvider({
        url: PO_LINE_ITEMS.PO_LINE_ITEM(id),
        method: "PATCH",
        data,
    });
};

export const deletePOLineItem = (id) => {
    return dataProvider({
        url: PO_LINE_ITEMS.PO_LINE_ITEM(id),
        method: "DELETE",
    });
};

export const archivePoli = (id) => {
    return dataProvider({
        url: PO_LINE_ITEMS.PO_LINE_ITEM_ARCHIVE(id),
        method: "POST",
    });
};

export const unarchivePoli = (id) => {
    return dataProvider({
        url: PO_LINE_ITEMS.PO_LINE_ITEM_UNARCHIVE(id),
        method: "POST",
    });
};
