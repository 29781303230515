import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { Validation } from "../../helpers/validation";
import AsyncAutocompleteComponent from "../core/form/asyncAutocompleteComponent";
import DatesRange, { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import moment from "moment";
import { getProjects } from "../../dataServers/projects";
import { AIR_TICKETS_FILTERS_FORM } from "../../containers/ReportAndAnalyticks";
import Box from "@material-ui/core/Box";
import SearchAndFilters from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES } from "../../styles/reusableStyles";
import { getInspectorJobOrders, getJobOrders } from "../../dataServers/jobOrder";
import { SERVER_DATE_FORMAT } from "../../constants/global";
import { IS_INSPECTOR_USER } from "../../constants/maps";
import TicketFilters from "../tickets/ticketFilters";
import TicketsReportLink from "../tickets/components/ticketsReportLink";
import { SimpleLink } from "../core/link";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";
import { useDebounce } from "../../hooks/useDebounce";
import clsx from "clsx";

const getJobOrderLabel = (i) => {
    const projectName = i.projectName || i?.poLineItem?.purchaseOrder?.project?.name || "";

    return `#${i.id}${i.extRef ? ` / Ext. Ref #${i.extRef}` : ""} ${i.customerName ? ` - ${i.customerName}` : ""}${
        projectName ? `- ${projectName}` : ""
    }`;
};

const useStyles = makeStyles((theme) => ({
    reportFilters: {
        "& .form-view.tiny-view .___placeholder": {
            top: 9,
        },
    },
    redirectLinkContainer: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
    },
}));

const ReportFilters = (props) => {
    const classes = useStyles();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();
    const {
        form,
        parentFormValues: { endDate, startDate, project, jobOrder },
        parentFormValues,
        updateListByFilters,
        dispatch,
        account,
        showDownloadTickets,
        showJobOrders,
        setFilters,
        ticketsFilters,
        onRedirect,
        redirectIcon,
        redirectLabel,
        formStyles,
        location: { state },
    } = props;
    const [needReloadProjects, setReloadProjectsList] = useState();
    const [needReloadJobs, setReloadJobsList] = useState();
    const [isForceUpdateDates, setIsForceUpdateDates] = useState(false);
    const [lastSelectedProject, setLastSelectedProject] = useState();
    const isFirstLoad = (inputValue, concat) => {
        return inputValue === "" && !concat;
    };

    const handleDateChangeInner = (date, name, datesAreSame) => {
        let newDates = {
            [END_DATE_NAME]: endDate,
            [START_DATE_NAME]: startDate,
        };

        if (moment(date).format() === "Invalid date") {
            return false;
        }

        // if date was changed in ordinary way we should use it
        if (name) {
            newDates[name] = date;
        }

        if (datesAreSame) {
            newDates = {
                [END_DATE_NAME]: date,
                [START_DATE_NAME]: date,
            };
        }

        setFilters({
            ...ticketsFilters,
            ...newDates,
        });

        if (!IS_INSPECTOR_USER(account.role)) {
            setReloadProjectsList(true);
            dispatch(change(form, "jobOrder", null));
        } else {
            setReloadJobsList(true);
        }
    };

    const [value, handleDateChange] = useDebounce(handleDateChangeInner);

    const loadProjects = (inputValue, { params = {}, loadedCount } = {}, concat) => {
        let start = moment(startDate).format(SERVER_DATE_FORMAT);
        let end = moment(endDate).format(SERVER_DATE_FORMAT);

        if (moment(end).isBefore(moment(startDate))) {
            start = end;
        }

        return getProjects({
            startDate: start,
            endDate: end,
            analytic: true,
            keywords: inputValue,
            ...params,
        }).then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                label: i.name,
            }));

            if (!_.isEmpty(results)) {
                if (isFirstLoad(inputValue, concat) && !state) {
                    dispatch(change(form, "project", results[0]));
                    setLastSelectedProject(results[0].id);
                }

                setReloadJobsList(true);
            } else {
                dispatch(change(form, "project", null));
                dispatch(change(form, "jobOrder", null));
            }

            setReloadProjectsList(false);

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const resetSelectedJobOrder = (project) => {
        setReloadJobsList(true);
        project && setLastSelectedProject(project.id);
    };

    const setJobOrder = (jobOrder, jobOrders, projectValue) => {
        if (jobOrder && _.isEqual(projectValue, project.id)) {
            dispatch(
                change(form, "jobOrder", {
                    label: getJobOrderLabel(jobOrder),
                    ...jobOrder,
                }),
            );
        } else {
            dispatch(change(form, "jobOrder", jobOrders));
        }
        updateListByFilters({
            jobOrders: jobOrders,
            endDate,
            startDate,
        });
    };

    const loadJobOrders = (inputValue, { params = {}, loadedCount } = {}, concat, initialLoad) => {
        const start = moment(parentFormValues[START_DATE_NAME]).format(SERVER_DATE_FORMAT);
        const end = moment(parentFormValues[END_DATE_NAME]).format(SERVER_DATE_FORMAT);
        let paramsData = {
            [START_DATE_NAME]: start,
            [END_DATE_NAME]: end,
            keywords: inputValue,
            ...params,
            withTrucks: false,
        };

        if (lastSelectedProject || project) {
            paramsData.project = lastSelectedProject || project.id;
        }

        const request = IS_INSPECTOR_USER(account.role) ? getInspectorJobOrders(paramsData) : getJobOrders(paramsData);

        return request.then((data) => {
            const results = data.data.map((i) => ({
                ...i,
                value: i.id,
                joLineItems: i.joLineItems,
                label: getJobOrderLabel(i),
            }));

            // inspector has several jo selected but all other users can select only one jo
            // that's why selection logic not required and always first jo or already selected jo can be in the dropdown
            if (initialLoad || !IS_INSPECTOR_USER(account.role)) {
                if (!_.isEmpty(results)) {
                    if (IS_INSPECTOR_USER(account.role)) {
                        dispatch(change(form, "jobOrder", [results[0]]));
                        updateListByFilters({
                            jobOrders: [results[0]],
                            endDate,
                            startDate,
                        });
                    } else {
                        setJobOrder(jobOrder, results[0], lastSelectedProject || project.id);
                    }
                }
            } else if (IS_INSPECTOR_USER(account.role)) {
                let selectedJobOrders = _.cloneDeep(jobOrder || []);

                !concat &&
                    (jobOrder || []).forEach((i) => {
                        const itemInResults = results.find((o) => o.id === i.id);

                        if (!itemInResults && !inputValue) {
                            selectedJobOrders = selectedJobOrders.filter((o) => o.id !== i.id);
                        }
                    });

                const jobOrders = _.isEmpty(selectedJobOrders)
                    ? !_.isEmpty(results)
                        ? [results[0]]
                        : null
                    : selectedJobOrders;
                dispatch(change(form, "jobOrder", jobOrders));
                updateListByFilters({
                    jobOrders,
                    endDate,
                    startDate,
                });
            }

            setReloadJobsList(false);

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const loadDataByJobOrder = (jobOrders) => {
        updateListByFilters({ jobOrders: !_.isEmpty(jobOrders) ? jobOrders : undefined });
    };

    const resetDatesToDefault = () => {
        const startDate = moment().format(SERVER_DATE_FORMAT);
        const endDate = moment().format(SERVER_DATE_FORMAT);
        dispatch(change(form, START_DATE_NAME, startDate));
        dispatch(change(form, END_DATE_NAME, endDate));
        setIsForceUpdateDates(true);
    };

    return (
        <div className={clsx(pageActionsStyles.pageActions, classes.reportFilters)}>
            <form noValidate={true}>
                <Grid container style={formStyles} component="div" alignItems={"center"}>
                    <Grid item xs={5} component="div">
                        <DatesRange
                            form={form}
                            isForceSaveDates={isForceUpdateDates}
                            handleForceSaveDates={() => setIsForceUpdateDates(false)}
                            handleEndDateChange={handleDateChange}
                            handleStartDateChange={handleDateChange}
                        />
                    </Grid>
                    {form !== AIR_TICKETS_FILTERS_FORM && (
                        <Grid item xs={3} component="div">
                            <Field
                                name="project"
                                validate={[Validation.required]}
                                className="form-view tiny-view"
                                reloadList={needReloadProjects}
                                loadOptions={loadProjects}
                                onChange={resetSelectedJobOrder}
                                placeholder="Project"
                                defaultOptions={true}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    )}
                    {showJobOrders && (
                        <Grid item xs={3} component="div" style={{ marginLeft: 15 }}>
                            <Field
                                name="jobOrder"
                                validate={[Validation.required]}
                                className="form-view tiny-view"
                                disabled={!project}
                                reloadList={needReloadJobs}
                                loadOptions={loadJobOrders}
                                placeholder="Job Order"
                                defaultOptions={!_.isEmpty(project)}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    )}
                    {IS_INSPECTOR_USER(account.role) && (
                        <Grid item xs={3} component="div">
                            <Field
                                name="jobOrder"
                                validate={[Validation.required]}
                                onChange={loadDataByJobOrder}
                                className="form-view tiny-view"
                                loadOptions={loadJobOrders}
                                reloadList={needReloadJobs}
                                placeholder="Job Order"
                                isMulti={true}
                                defaultOptions={true}
                                component={AsyncAutocompleteComponent}
                            />
                        </Grid>
                    )}
                    {!showJobOrders && (
                        <Grid
                            item
                            xs={
                                showJobOrders
                                    ? 3
                                    : form !== AIR_TICKETS_FILTERS_FORM
                                    ? 4
                                    : IS_INSPECTOR_USER(account.role)
                                    ? 4
                                    : 7
                            }
                            component="div"
                        >
                            <Box className={pageActionsStyles.headerBox}>
                                {IS_INSPECTOR_USER(account.role) ? (
                                    <SearchAndFilters
                                        form={form}
                                        style={{ marginLeft: 15 }}
                                        isResetToDefault
                                        resetToDefaultHandler={resetDatesToDefault}
                                        updateList={updateListByFilters}
                                    />
                                ) : (
                                    <TicketFilters
                                        ticketsFilters={ticketsFilters}
                                        form={form}
                                        updateListByFilters={updateListByFilters}
                                        resetToDefaultHandler={resetDatesToDefault}
                                    />
                                )}
                                <TicketsReportLink
                                    ticketsFilters={ticketsFilters}
                                    form={form}
                                    showLink={showDownloadTickets}
                                    isInspector={IS_INSPECTOR_USER(account.role)}
                                />
                            </Box>
                        </Grid>
                    )}
                    {onRedirect && redirectLabel && (
                        <Grid component="div" xs={1}>
                            <Box className={classes.redirectLinkContainer}>
                                {redirectIcon()}
                                <SimpleLink onClick={onRedirect}>{redirectLabel}</SimpleLink>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </form>
        </div>
    );
};

ReportFilters.propTypes = {
    form: PropTypes.string.isRequired,
    parentFormValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    showDownloadTickets: PropTypes.bool.isRequired,
    showJobOrders: PropTypes.bool.isRequired,
    ticketsFilters: PropTypes.object.isRequired,
    onRedirect: PropTypes.func,
    redirectIcon: PropTypes.any,
    redirectLabel: PropTypes.string,
    formStyles: PropTypes.object,
    location: PropTypes.object,
};

ReportFilters.defaultProps = {
    showDownloadTickets: true,
    showJobOrders: false,
    ticketsFilters: {},
};

export default withRouter(
    compose(
        reduxForm({}),
        connect((state, props) => {
            const parentFormSelector = formValueSelector(props.form);

            return {
                account: state.account,
                parentFormValues: parentFormSelector(
                    state,
                    "project",
                    "redirect",
                    START_DATE_NAME,
                    END_DATE_NAME,
                    "jobOrder",
                ),
            };
        }),
    )(ReportFilters),
);
