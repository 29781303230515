import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Validation } from "../../../helpers/validation";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../../core/form/asyncAutocompleteComponent";
import CustomCheckbox from "../../core/form/customCheckbox";
import { messageDrivers } from "../../../dataServers/jobOrder";
import TextInputComponent from "../../core/form/textInputComponent";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import Loader from "../../core/loader";
import ErrorNotification from "../../core/notification";
import { THEME_ICONS } from "../../../styles/icons";

const useStyles = makeStyles(() => ({
    uiIconSuccess: {
        ...THEME_ICONS.success,
    },
}));

const MessageDrivers = (props) => {
    const classes = useStyles();
    const {
        jobOrder,
        closeModal,
        handleSubmit,
        formValues: { messageAllDrivers },
    } = props;
    const [error, setError] = useState(null);
    const [state, setState] = useState({ dataIsSend: false, isLoading: false, allDriversList: [] });
    const { dataIsSend, isLoading, allDriversList } = state;
    let timer;

    const onSubmit = (values) => {
        if (_.isEmpty(allDriversList)) {
            setError(PROCESS_SERVER_ERROR("You can't send message. No drivers assign to job order trucks"));

            return false;
        }

        setState({ ...state, isLoading: true });
        setError(PROCESS_SERVER_ERROR(null));

        messageDrivers(jobOrder.id, {
            trucksIds: values.messageAllDrivers
                ? allDriversList.map((i) => i.value)
                : values.drivers.map((i) => i.value),
            message: values.message,
        })
            .then((data) => {
                setState({ ...state, isLoading: false, dataIsSend: true });
                timer = setTimeout(() => {
                    closeModal(false);
                }, 3000);
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setState({ ...state, isLoading: false });
            })
            .finally(() => {
                clearTimeout(timer);
            });
    };
    const loadDrivers = () => {
        return Promise.all([]).then(() => {
            return {
                options: allDriversList,
                hasMore: false,
                page: 1,
            };
        });
    };

    useEffect(() => {
        const { assignedTrucks: trucks } = jobOrder;
        const results = trucks
            ? trucks
                  .filter((i) => i.currentDriver)
                  .map((i) => ({
                      label: `${i.currentDriver} (${i.truckName})`,
                      value: i.id,
                  }))
            : [];

        setState({ ...state, allDriversList: _.unionBy(results, "value"), isLoading: false });
    }, []);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate={true}
            className={clsx(isLoading && LOADER_WHITE_OVERLAY, classes.messageDrivers)}
        >
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {!dataIsSend ? (
                <React.Fragment>
                    <h1 className="title">Message Drivers On Job #{jobOrder.id}</h1>
                    <br />
                    <Field
                        name="drivers"
                        loadOptions={loadDrivers}
                        validate={!messageAllDrivers ? [Validation.required] : []}
                        placeholder="Select Drivers"
                        disabled={messageAllDrivers}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        noOptionsMessage="No drivers currently online"
                        isMulti={true}
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <Field
                        name="messageAllDrivers"
                        type="checkbox"
                        disabled={_.isEmpty(allDriversList)}
                        value={true}
                        label="Message All Drivers"
                        component={CustomCheckbox}
                    />
                    <br />
                    <Field
                        type="text"
                        name="message"
                        rows={3}
                        validate={[Validation.required]}
                        placeholder="Message"
                        multiline={true}
                        needShowEndAdornment={false}
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton onClick={() => closeModal(false)}>Cancel</SecondaryButton>
                        <PrimaryButton type="submit">Send</PrimaryButton>
                    </ButtonsGroup>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1 className="title">Message Sent</h1>
                    <span>
                        <i className={classes.uiIconSuccess} />
                    </span>
                    <br />
                    <ButtonsGroup>
                        <PrimaryButton onClick={() => closeModal(false)}>Close</PrimaryButton>
                    </ButtonsGroup>
                </React.Fragment>
            )}
        </form>
    );
};

MessageDrivers.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object,
};

MessageDrivers.defaultProps = {
    jobOrder: {},
};

export const MESSAGE_FOR_DRIVERS_FORM = "jobOrderMessageDriversForm";

export default withRouter(
    compose(
        reduxForm({
            form: MESSAGE_FOR_DRIVERS_FORM,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(state, "id", "messageAllDrivers"),
            };
        }),
    )(MessageDrivers),
);
