import {
    ERROR_OFFLINE_HAULERS,
    ERROR_PREFERRED_HAULERS,
    RECEIVE_OFFLINE_HAULERS,
    RECEIVE_PREFERRED_HAULERS,
    REQUEST_OFFLINE_HAULERS,
    REQUEST_PREFERRED_HAULERS,
    RESET_OFFLINE_HAULERS_META,
    RESET_PREFERRED_HAULERS,
    RESET_PREFERRED_HAULERS_META,
    SUCCESS_OFFLINE_HAULERS,
    SUCCESS_PREFERRED_HAULERS,
    UPDATE_CUSTOMERS_SETTINGS_ACTIVE_TAB,
} from "../events/settings";
import { LOGOUT_USER } from "../events/globalEvents";

export const REGULAR_CUSTOMERS_SETTINGS = 0;
export const RESTRICTED_CUSTOMERS_SETTINGS = 1;

const initialState = {
    preferredHaulers: {
        data: null,
        meta: {
            loading: false,
            error: null,
            success: false,
        },
    },
    offlineHaulers: {
        data: [],
        meta: {
            loading: false,
            error: null,
            success: false,
            count: 0,
        },
    },
    customersActiveTab: REGULAR_CUSTOMERS_SETTINGS,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_PREFERRED_HAULERS: {
            return {
                ...state,
                preferredHaulers: {
                    ...state.preferredHaulers,
                    meta: {
                        ...state.preferredHaulers.meta,
                        loading: true,
                    },
                },
            };
        }
        case RECEIVE_PREFERRED_HAULERS: {
            return {
                ...state,
                preferredHaulers: {
                    ...state.preferredHaulers,
                    data: action.payload,
                    meta: {
                        ...state.preferredHaulers.meta,
                        loading: false,
                    },
                },
            };
        }
        case SUCCESS_PREFERRED_HAULERS: {
            return {
                ...state,
                preferredHaulers: {
                    ...state.preferredHaulers,
                    meta: {
                        ...state.preferredHaulers.meta,
                        loading: false,
                        success: action.payload,
                    },
                },
            };
        }
        case ERROR_PREFERRED_HAULERS: {
            return {
                ...state,
                preferredHaulers: {
                    ...state.preferredHaulers,
                    meta: {
                        ...state.preferredHaulers.meta,
                        loading: false,
                        error: action.payload,
                    },
                },
            };
        }
        case RESET_PREFERRED_HAULERS_META: {
            return {
                ...state,
                preferredHaulers: {
                    ...state.preferredHaulers,
                    meta: initialState.preferredHaulers.meta,
                },
            };
        }
        case RESET_PREFERRED_HAULERS: {
            return {
                ...state,
                preferredHaulers: initialState.preferredHaulers,
            };
        }
        case REQUEST_OFFLINE_HAULERS: {
            return {
                ...state,
                offlineHaulers: {
                    ...state.offlineHaulers,
                    meta: {
                        ...state.offlineHaulers.meta,
                        loading: true,
                    },
                },
            };
        }
        case RECEIVE_OFFLINE_HAULERS: {
            return {
                ...state,
                offlineHaulers: {
                    ...state.offlineHaulers,
                    data: action.payload.data,
                    meta: {
                        ...state.offlineHaulers.meta,
                        loading: false,
                        count: action.payload.meta.count,
                    },
                },
            };
        }
        case SUCCESS_OFFLINE_HAULERS: {
            return {
                ...state,
                offlineHaulers: {
                    ...state.offlineHaulers,
                    meta: {
                        ...state.offlineHaulers.meta,
                        loading: false,
                        success: action.payload,
                    },
                },
            };
        }
        case ERROR_OFFLINE_HAULERS: {
            return {
                ...state,
                offlineHaulers: {
                    ...state.offlineHaulers,
                    meta: {
                        ...state.offlineHaulers.meta,
                        loading: false,
                        error: action.payload,
                    },
                },
            };
        }
        case RESET_OFFLINE_HAULERS_META: {
            return {
                ...state,
                offlineHaulers: {
                    ...state.offlineHaulers,
                    meta: initialState.offlineHaulers.meta,
                },
            };
        }
        case LOGOUT_USER: {
            return {
                ...state,
                preferredHaulers: initialState.preferredHaulers,
                offlineHaulers: initialState.offlineHaulers,
            };
        }
        case UPDATE_CUSTOMERS_SETTINGS_ACTIVE_TAB: {
            return {
                ...state,
                customersActiveTab: action.payload,
            };
        }
        default:
            return state;
    }
};

export default settingsReducer;
