import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";

import ChatIcon from "@material-ui/icons/Chat";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import Loader, { fixedLoaderStyles } from "../core/loader";
import TrucksActionMenu from "./trucksActionMenu";
import { DEFAULT_DATE_FORMAT, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { IS_ADMIN_USER } from "../../constants/maps";
import AppModal from "../core/modal";
import MessageForm, { MESSAGE_FOR_DEVICE_FORM } from "./messageForm";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { resetTrucksData, updateActiveTruckOrder } from "../../actions/trucks";
import { getCapacityAdornment, getVolumeCapacityAdornment } from "../../helpers/measureSystemHelpers";
import { selectMeasureSystem } from "../../selectors";

const useStyles = makeStyles(() => ({
    trucksList: {
        width: "100%",
    },
    messageIcon: {
        width: 21,
        height: 21,
        marginLeft: 10,
        cursor: "pointer",
    },
}));

const TrucksList = (props) => {
    const { trucks, isLoading, fetchMore, trucksCount, account, trucksFilters, loadTrucksData, resetTrucksData } =
        props;
    const [state, setState] = useState({
        openMessageModal: false,
        currentDeviceId: null,
    });
    const { openMessageModal, currentDeviceId } = state;
    const classes = useStyles();
    const dispatch = useDispatch();
    const activeTruckOrder = useSelector((state) => state.trucks.activeTruckOrder);
    const measureSystem = useSelector(selectMeasureSystem);
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const showMessageModal = (show, truck = null) => {
        setState({
            ...state,
            openMessageModal: show,
            currentDeviceId: truck ? truck.deviceId : null,
        });
    };
    const getTableData = () => {
        const rows = trucks.map((truck, index) => {
            const {
                id,
                activeSuspendPeriod,
                truckName,
                company,
                currentJobOrderId,
                currentDriver,
                projectName,
                region,
                deviceId,
                lastActiveDate,
                trailers,
                capacity,
                volumeCapacity,
            } = truck;

            return {
                index,
                id,
                isAvailable: !activeSuspendPeriod,
                truckName,
                capacity,
                volumeCapacity,
                companyName: company.name,
                region: region ? region.name : null,
                job: currentJobOrderId ? `${projectName} - JO# ${currentJobOrderId}` : "",
                driver: (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <span>{currentDriver}</span>
                        {currentDriver && !!deviceId && (
                            <ChatIcon className={classes.messageIcon} onClick={() => showMessageModal(true, truck)} />
                        )}
                    </Box>
                ),
                trailers: trailers && trailers.map((i, index) => i.name).join(", "),
                lastActiveDate: lastActiveDate
                    ? moment(lastActiveDate).tz(account.timezone).format(DEFAULT_DATE_FORMAT)
                    : null,
                action: (
                    <TrucksActionMenu
                        trucksFilters={trucksFilters}
                        truckForEdit={truck}
                        loadTrucksData={loadTrucksData}
                    />
                ),
            };
        });
        let columns = [
            {
                id: "id",
                sortParam: "id",
                label: "TRUCK ID",
            },
            {
                id: "truckName",
                sortParam: "name",
                label: "TRUCK NAME",
            },
        ];

        if (isAdmin) {
            columns.push({
                id: "companyName",
                sortParam: "company",
                label: "COMPANY",
            });
        }

        columns = [
            ...columns,
            {
                id: "region",
                sortParam: "region",
                label: "REGION",
            },
            {
                id: "capacity",
                label: `WEIGHT CAPACITY (${getCapacityAdornment(measureSystem)})`,
            },
            {
                id: "volumeCapacity",
                label: `VOLUME CAPACITY (${getVolumeCapacityAdornment(measureSystem)})`,
            },
            {
                id: "job",
                sortParam: "job",
                label: "CURRENT JOB",
            },
            {
                id: "driver",
                sortParam: "driver",
                label: "CURRENT DRIVER",
            },
            {
                id: "trailers",
                label: "CURRENT TRAILERS",
            },
            {
                id: "lastActiveDate",
                label: "LAST ACTIVE DATE",
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        return {
            rows: rows,
            columns: columns,
        };
    };

    const handleSort = (sortParam) => {
        dispatch(updateActiveTruckOrder(sortParam));
    };

    useEffect(() => {
        return () => {
            resetTrucksData();
        };
    }, []);

    return (
        <React.Fragment>
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY, _.isEmpty(trucks) && isLoading && "-min-height")}>
                {isLoading && <Loader style={fixedLoaderStyles()} />}
                {!_.isEmpty(trucks) && (
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={fetchMore}
                        handleSort={handleSort}
                        itemsCount={trucksCount}
                        columns={getTableData().columns}
                        activeOrderParam={activeTruckOrder}
                        className={classes.trucksList}
                    />
                )}
            </div>
            {_.isEmpty(trucks) && !isLoading && (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No trucks match your search / filter parameters.</h1>
                    <br />
                </React.Fragment>
            )}
            <AppModal
                isOpen={openMessageModal}
                form={MESSAGE_FOR_DEVICE_FORM}
                closeModal={() => showMessageModal(false)}
            >
                <MessageForm
                    closeModal={() => showMessageModal(false)}
                    initialValues={{
                        deviceId: currentDeviceId,
                        message: "",
                    }}
                />
            </AppModal>
        </React.Fragment>
    );
};

TrucksList.propTypes = {
    trucks: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    fetchMore: PropTypes.func.isRequired,
    trucksCount: PropTypes.number.isRequired,
    account: PropTypes.object.isRequired,
    trucksFilters: PropTypes.object.isRequired,
};

TrucksList.defaultProps = {
    isLoading: false,
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
        }),
        (dispatch) => ({
            resetTrucksData: () => dispatch(resetTrucksData()),
        }),
    )(TrucksList),
);
