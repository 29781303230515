import dataProvider from "../helpers/api";
import { REPORT_AND_ANALYTICS, REPORT } from "../constants/endpoints";

export const getTruckActivityAnalytics = (jobOrderId, truckId, params = {}) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.TRUCK_ACTIVITY(jobOrderId, truckId),
        method: "GET",
        params,
    });
};

export const getJobOrderTrucksActivity = (joOrderId, params = {}) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.JOB_ORDER_TRUCKS_ACTIVITY(joOrderId),
        method: "GET",
        params,
    });
};

export const getReportFiltersMenu = (params, responseContent) => {
    const getParams = params;

    delete getParams.cancelToken;

    return dataProvider({
        url: REPORT_AND_ANALYTICS.REPORT_FILTERS_MENU(responseContent),
        method: "GET",
        params: getParams,
        cancelToken: params.cancelToken ? params.cancelToken.token : {},
    });
};

export const requestReportNames = (params) => {
    return dataProvider({
        url: REPORT.GET_REPORTS_NAMES(),
        method: "GET",
        params,
    });
};

export const getSavedReportFilters = (params) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.SAVED_REPORT_FILTERS(),
        method: "GET",
        params,
    });
};

export const createSavedReportFilters = (data) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.SAVED_REPORT_FILTERS(),
        method: "POST",
        data,
    });
};
export const editSavedReportFilters = (data) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.SAVED_REPORT_FILTERS(),
        method: "PUT",
        data,
    });
};

export const removeSavedReportFilters = (id) => {
    return dataProvider({
        url: `${REPORT_AND_ANALYTICS.SAVED_REPORT_FILTERS()}/${id}`,
        method: "DELETE",
    });
};

export const getReportData = (params, name) => {
    return dataProvider({
        url: REPORT.RETRIEVE_REPORTS(name),
        method: "GET",
        params,
    });
};

export const getReportTotals = (params, attr) => {
    return dataProvider({
        url: REPORT_AND_ANALYTICS.TICKETS_REPORT_TOTALS(attr),
        method: "GET",
        params,
    });
};

export const downloadReportPDF = (params, name) => {
    return dataProvider({
        url: REPORT.RETRIEVE_REPORTS(name),
        method: "GET",
        responseType: "blob",
        params,
    }).then((response) => {
        const url = window.URL.createObjectURL(response instanceof Blob ? response : new Blob([response.data]));
        const link = document.createElement("a");
        const filename = response.headers["content-disposition"].split("filename=")[1].replaceAll(`"`, "");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    });
};
