import React, { useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

import { sortByStartDate } from "../../../helpers/global";
import JobOrderForm from "./jobOrderForm";
import { ErrorBoundary } from "../../core/errorBoudaries";
import {
    convertTimeToJsFormat,
    getCopyStartDate,
    getJobOrderInitialDate,
    jobOrderHasHaulers,
} from "../../../helpers/jobOrders";
import { getPhaseCodeFromAdditionalFields } from "../../../helpers/projects.js";
import { calculateMinutesFromSeconds, isValidDate } from "../../../helpers/date";
import { DEFAULT_LOADING_TIME_MINUTES, JOLI_FIELD_ID, SPECIFY_TRUCKS_PER_HAULER } from "../constants";
import { LOADER_WHITE_OVERLAY } from "../../../constants/global";
import Loader from "../../core/loader";
import { INITIAL_TERMS_VALUE } from "../../global/termsSelector";
import PoLineItemLabel from "../../global/PoLineItemLabel";
import { pavertrackerInitialValues, PAVERTRACKERS_NAME } from "../../global/pavertrackersSelector";
import { START_DATE_NAME } from "../../global/datesRange";
import { changeJoDate, changeJOFirstRenderedInitialValues } from "../../../actions/jobOrders";
import { UNIT_OF_MEASURE_BY_ID } from "../../../constants/maps";

const styles = makeStyles(() => ({
    formWrapper: {
        marginTop: "10px",
    },
}));

const JobOrderFormWrapper = React.memo(
    (props) => {
        const {
            cancelFormEditHandler,
            jobOrder,
            form,
            currentPoLineItem,
            currentProject,
            setActive,
            account,
            parentLoading,
            updateJoDate,
            updateJOFirstRenderedInitialValues,
            joFirstRenderInitialValues,
            isStartDateByDefault,
            isStartCopyJobNextDay,
            location: { state },
        } = props;
        const classes = styles();

        let initialValues = { trucks: [] };

        if (jobOrder.id || jobOrder.isCopy || jobOrder.templateId) {
            const jobStartDate = jobOrder.isCopy
                ? getCopyStartDate(jobOrder[START_DATE_NAME])
                : jobOrder[START_DATE_NAME];
            const jolis = _.cloneDeep(jobOrder.joliItems).map((i) => {
                if (jobOrder.isCopy) {
                    return {
                        ...i,
                        // https://github.com/TruckITllc/truckit-frontend/issues/1524#issuecomment-788873457
                        [START_DATE_NAME]: getCopyStartDate(i[START_DATE_NAME]),
                        // https://github.com/TruckITllc/truckit-frontend/issues/1518#issuecomment-791403544
                        haulers: i.haulers.filter((i) => i.assignedBy.id === jobOrder.company.id),
                    };
                }

                return i;
            });
            const sortedJolis = sortByStartDate(jolis);

            initialValues = {
                ...jobOrder,
                [START_DATE_NAME]: jobStartDate,
                dynamicStartDate: jobStartDate,
                id: jobOrder.isCopy ? null : jobOrder.id,
                projectId:
                    jobOrder.projectId ||
                    (jobOrder.poLineItem &&
                        jobOrder.poLineItem.purchaseOrder &&
                        jobOrder.poLineItem.purchaseOrder.project &&
                        jobOrder.poLineItem.purchaseOrder.project.id),
                poType:
                    (currentProject && currentProject.customer && currentProject.customer.poType) ||
                    account.company.poType,
                ...pavertrackerInitialValues(jobOrder[PAVERTRACKERS_NAME]),
                totalQuantity: jobOrder.totalQuantity || null,
                [JOLI_FIELD_ID]: sortedJolis,
                purchaseOrder: jobOrder.poLineItem && jobOrder.poLineItem.purchaseOrder,
                poLineItem: {
                    value: jobOrder.poLineItem.id,
                    label: (
                        <PoLineItemLabel
                            item={{
                                ...jobOrder,
                                id: jobOrder.poLineItem.id,
                                perUnitPrice: jobOrder.poLineItem.perUnitPrice,
                                payload:
                                    jobOrder.poLineItem &&
                                    jobOrder.poLineItem.payload &&
                                    jobOrder.poLineItem.payload.name,
                                unitOfMeasure: {
                                    id: jobOrder.unitOfMeasure,
                                    name: UNIT_OF_MEASURE_BY_ID[jobOrder.unitOfMeasure],
                                },
                            }}
                        />
                    ),
                },
                costCode: jobOrder.costCode,
                dropOffSites: !_.isEmpty(jobOrder.dropOffSites)
                    ? jobOrder.dropOffSites.map((i) => ({
                          value: i.id,
                          label: i.name,
                      }))
                    : [],
                dropOffNotes: jobOrder.dropOffNotes || "",
                pickUpNotes: jobOrder.pickUpNotes || "",
                terms: jobOrder.terms
                    ? {
                          value: jobOrder.terms.id,
                          label: jobOrder.terms.name,
                      }
                    : INITIAL_TERMS_VALUE,
                jobContactId: jobOrder.jobContact
                    ? {
                          ...jobOrder.jobContact,
                          value: jobOrder.jobContact.id,
                          label: jobOrder.jobContact.fullName,
                      }
                    : null,
                extRef: jobOrder.extRef || "",
                [SPECIFY_TRUCKS_PER_HAULER]: !!(
                    jobOrderHasHaulers(jolis) && jolis.find((i) => i.notAddressedTrucksCount === 0)
                ),
                pickUpSite: jobOrder.pickUpSite
                    ? {
                          value: jobOrder.pickUpSite.id,
                          label: jobOrder.pickUpSite.name,
                          latitude: jobOrder.pickUpSite.latitude,
                          longitude: jobOrder.pickUpSite.longitude,
                      }
                    : null,
                isCopy: jobOrder.isCopy,
                templateEndDate:
                    jobOrder.copySchedule && convertTimeToJsFormat(jobOrder.copySchedule.endDate, account.timezone),
                templateStartDate:
                    jobOrder.copySchedule && convertTimeToJsFormat(jobOrder.copySchedule.startDate, account.timezone),
                weekdays: jobOrder.copySchedule && jobOrder.copySchedule.weekdays,
                enabled: jobOrder.copySchedule && jobOrder.copySchedule.enabled,
                hasCopySchedule: !!jobOrder.copySchedule,
                endDate: jobOrder.endDate && convertTimeToJsFormat(jobOrder.endDate, account.timezone),
                jobMode: jobOrder.jobMode,
                unitOfMeasure: jobOrder.unitOfMeasure,
                loadTime: calculateMinutesFromSeconds(jobOrder.loadTimeSec),
                unloadTime: calculateMinutesFromSeconds(jobOrder.unloadTimeSec),
                ...getPhaseCodeFromAdditionalFields(jobOrder?.additionalFields),
            };
            if (typeof isStartCopyJobNextDay !== "undefined" && !isStartCopyJobNextDay) {
                initialValues.joStartTime = jobStartDate;
            }
            if (jobOrder.isSplit && !isValidDate(jobOrder.startDate)) {
                initialValues.startDate = null;
            }
        } else if (currentPoLineItem && currentProject) {
            initialValues = {
                poLineItem: {
                    id: currentPoLineItem.id,
                    label: (
                        <PoLineItemLabel
                            item={{
                                ...currentPoLineItem,
                                dropOffSites: null,
                                pickUpSite: null,
                            }}
                        />
                    ),
                    ...currentPoLineItem,
                },
                poType:
                    (currentProject && currentProject.customer && currentProject.customer.poType) ||
                    account.company.poType,
                autoAccept: currentProject.autoAccept,
                ...pavertrackerInitialValues(currentProject[PAVERTRACKERS_NAME]),
                [START_DATE_NAME]: isStartDateByDefault
                    ? getJobOrderInitialDate(account.companyProfile.defaultStartTime)
                    : null,
                dynamicStartDate: isStartDateByDefault
                    ? getJobOrderInitialDate(account.companyProfile.defaultStartTime)
                    : moment(),
                isOvernight: currentProject.isOvernight,
                allowUnfilledStart: currentProject.allowUnfilledStart,
                unlimited: currentPoLineItem.unlimited,
                unitOfMeasure: currentPoLineItem.unitOfMeasure,
                allowToNotify: currentProject.allowToNotify,
                projectId: currentProject.id,
                costCode: currentPoLineItem.additionalFields && currentPoLineItem.additionalFields.cost_code,
                jobContactId: currentProject.jobContact
                    ? {
                          ...currentProject.jobContact,
                          value: currentProject.jobContact.id,
                          label: currentProject.jobContact.fullName,
                      }
                    : null,
                dropOffSites: currentPoLineItem.dropOffSites
                    ? currentPoLineItem.dropOffSites.map((site) => ({
                          label: site.name,
                          value: site.id,
                      }))
                    : [],
                pickUpSite: currentPoLineItem.pickUpSite && {
                    label: currentPoLineItem.pickUpSite.name,
                    value: currentPoLineItem.pickUpSite.id,
                    latitude: currentPoLineItem.pickUpSite.latitude,
                    longitude: currentPoLineItem.pickUpSite.longitude,
                },
                [JOLI_FIELD_ID]: [],
                terms: currentProject.terms
                    ? {
                          value: currentProject.terms.id,
                          label: currentProject.terms.name,
                      }
                    : INITIAL_TERMS_VALUE,
                notes: "",
                jobMode: account.companyProfile.jobMode,
                loadTime: DEFAULT_LOADING_TIME_MINUTES,
                unloadTime: DEFAULT_LOADING_TIME_MINUTES,
                splitDispatch: !!state?.splitDispatch,
                ...getPhaseCodeFromAdditionalFields(currentPoLineItem?.additionalFields),
            };
        } else {
            initialValues = {
                autoAccept: currentProject && currentProject.autoAccept,
                poType:
                    (currentProject && currentProject.customer && currentProject.customer.poType) ||
                    account.company.poType,
                [START_DATE_NAME]: isStartDateByDefault
                    ? getJobOrderInitialDate(account.companyProfile.defaultStartTime)
                    : null,
                dynamicStartDate: isStartDateByDefault
                    ? getJobOrderInitialDate(account.companyProfile.defaultStartTime)
                    : moment(),
                isOvernight: false,
                allowUnfilledStart: currentProject && currentProject.allowUnfilledStart,
                unlimited: currentProject && currentProject.unlimited,
                unitOfMeasure: null,
                allowToNotify: currentProject && currentProject.allowToNotify,
                projectId: jobOrder.projectId,
                [JOLI_FIELD_ID]: [],
                terms:
                    currentProject && currentProject.terms
                        ? {
                              value: currentProject.terms.id,
                              label: currentProject.terms.name,
                          }
                        : INITIAL_TERMS_VALUE,
                notes: "",
                jobContactId:
                    currentProject && currentProject.jobContact
                        ? {
                              ...currentProject.jobContact,
                              value: currentProject.jobContact.id,
                              label: currentProject.jobContact.fullName,
                          }
                        : null,
                jobMode: account.companyProfile.jobMode,
                loadTime: DEFAULT_LOADING_TIME_MINUTES,
                unloadTime: DEFAULT_LOADING_TIME_MINUTES,
                ...getPhaseCodeFromAdditionalFields(currentPoLineItem?.additionalFields),
            };
        }

        useEffect(() => {
            if (initialValues.poLineItem) {
                updateJoDate(initialValues[START_DATE_NAME]);
            }

            if (
                !joFirstRenderInitialValues ||
                (joFirstRenderInitialValues && joFirstRenderInitialValues.id !== initialValues.id)
            ) {
                updateJOFirstRenderedInitialValues(initialValues);
            }

            return () => {
                updateJOFirstRenderedInitialValues(undefined);
            };
        }, []);

        return (
            <div className={clsx(classes.formWrapper, parentLoading && LOADER_WHITE_OVERLAY)}>
                {parentLoading && <Loader />}
                <ErrorBoundary>
                    <JobOrderForm
                        jobOrder={jobOrder}
                        form={form}
                        isStartDateByDefault={isStartDateByDefault}
                        isStartCopyJobNextDay={isStartCopyJobNextDay}
                        setActive={setActive}
                        cancelFormEditHandler={cancelFormEditHandler}
                        initialValues={initialValues}
                    />
                    <br />
                </ErrorBoundary>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            _.isEqual(prevProps.jobOrder, nextProps.jobOrder) &&
            _.isEqual(prevProps.currentProject, nextProps.currentProject) &&
            _.isEqual(prevProps.currentPoLineItem, nextProps.currentPoLineItem) &&
            _.isEqual(prevProps.parentLoading, nextProps.parentLoading)
        );
    },
);

JobOrderFormWrapper.propTypes = {
    jobOrder: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    cancelFormEditHandler: PropTypes.func,
    isJobBoard: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    setActive: PropTypes.func.isRequired,
    updateJoDate: PropTypes.func.isRequired,
    currentProject: PropTypes.object,
    currentPoLineItem: PropTypes.object,
    parentLoading: PropTypes.bool,
};

JobOrderFormWrapper.defaultProps = {
    isJobBoard: false,
};

export default withRouter(
    connect(
        (state) => {
            return {
                account: state.account,
                currentPoLineItem: state.poLineItems.currentPoLineItem,
                currentProject: state.projects.currentProject,
                joFirstRenderInitialValues: state.jobOrders.joFirstRenderInitialValues,
            };
        },
        (dispatch) => ({
            updateJoDate: (joDate) => {
                dispatch(changeJoDate(joDate));
            },
            updateJOFirstRenderedInitialValues: (initialValues) => {
                dispatch(changeJOFirstRenderedInitialValues(initialValues));
            },
        }),
    )(JobOrderFormWrapper),
);
