import { useSnackbar } from "notistack";

import { handleError } from "../helpers/global";

const useErrorNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showError = (error) => {
        enqueueSnackbar(handleError(error).message, {
            autoHideDuration: 5000,
            variant: "error",
        });
    };

    return showError;
};

export default useErrorNotification;
