import React, { useState } from "react";
import clsx from "clsx";
import { change, Field, formValueSelector, hasSubmitSucceeded, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";

import { selectPreferredMeta } from "../../../selectors";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import SuccessNotification from "../../core/successNotification";
import ErrorNotification from "../../core/notification";
import { START_DATE_NAME } from "../../global/datesRange";
import AppModal from "../../core/modal";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import Loader from "../../core/loader";
import { deleteUserFilterById, getUserFiltersTruckers } from "../../../dataServers/user";
import { ADD_HAULER_FORM } from "../constants/forms";
import { ADD_OFFLINE_HAULER_SUCCESS_MESSAGE } from "../constants/strings";
import CustomSwitch from "../../core/form/customSwitch";
import { updateHaulersSettingsProfileData } from "../../../actions/haulersSettings";
import AccordionComponent from "../../core/accordion";
import ConfirmationModal from "../../core/confirmationModal";

import InviteHaulersForm, { INVITE_HAULER_FORM } from "./inviteHaulersForm";
import AddHaulerFilterForm, { EDIT_CREATE_FILTER_FORM } from "./AddHaulerFilterForm";
import AddHaulerToPreferredListModal from "./AddHaulerToPreferredListModal";
import OfflineHaulersList from "./offlineHaulersList";
import PreferredHaulersList from "./preferredHaulersList";
import HaulerFilterList from "./haulerFilterList";

const useStyles = makeStyles(() => ({
    haulersTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
    haulerButtonsContainer: {
        marginBottom: "10px",
        "& button": {
            "&:first-child": {
                marginRight: 10,
            },
        },
    },
    allowReconciliationContainer: {
        marginBottom: "20px",
    },
    haulerButtonContainer: {
        flex: 1,
        "&:nth-child(1)": {
            paddingRight: "10px",
        },
        "&:nth-child(2)": {
            paddingLeft: "10px",
        },
    },
    haulerButton: {
        width: "100%",
    },
    section: {
        "& .custom-switch": {
            marginLeft: 20,
        },
        "& .picker-wrap": {
            justifyContent: "flex-start",
            marginLeft: 20,
        },
    },
    activeButton: {
        backgroundColor: "rgb(255 123 0 / 22%)",
        "&:hover": { backgroundColor: "rgb(247 180 117 / 57%)" },
    },
    editDefaultRateForm: {
        padding: "0px 40px",
    },
    offlineHaulersContainer: {
        margin: "15px 0",
    },
}));

const HaulersSetting = (props) => {
    const { loading: haulersLoading, error: haulersError, success: haulersSuccess } = useSelector(selectPreferredMeta);
    const hasSuccessAddOfflineHauler = useSelector(hasSubmitSucceeded(ADD_HAULER_FORM));
    const [filterToDelete, setFilterToDelete] = useState(null);
    const [state, setState] = useState({
        isLoading: false,
        showEditModal: false,
        filterOnEdit: {},
        showInviteHaulerModal: false,
    });
    const setLoading = (isLoading) => {
        setState({ ...state, isLoading });
    };
    const { isLoading: haulerFilterLoading, showEditModal, showInviteHaulerModal, filterOnEdit } = state;
    const [appliedFilters, setAppliedFilters] = useState(null);
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState(null);
    const [isAddHaulerToPreferredDialogActive, setIsAddHaulerToPreferredDialogActive] = useState(false);
    const {
        formValues: { filtersHaulers },
        account,
        dispatch,
        form,
        setInitialAllowReconciliation,
    } = props;
    const classes = useStyles();

    const updateListByFilters = (filters) => {
        let newFilters = { ..._.cloneDeep(filters) };

        if (!_.isEqual(appliedFilters, newFilters)) {
            setAppliedFilters(newFilters);
        }
    };

    const setSuccessMessage = (successMessage) => {
        setSuccess(successMessage);
    };

    const setErrorMessage = (errorMessage) => {
        setError(errorMessage);
    };

    const handleOpenHaulerForm = () => {
        setIsAddHaulerToPreferredDialogActive(true);
    };

    const openInviteHaulerModal = (show) => {
        setState({ ...state, showInviteHaulerModal: show });
    };

    const openEditModal = (show, filter = {}) => {
        setState({
            ...state,
            showEditModal: show,
            filterOnEdit: show ? filter : {},
        });
    };

    const handleFormSave = () => {
        setState({ ...state, isLoading: true });

        getUserFiltersTruckers()
            .then(({ data }) => {
                setSuccess([`Filter was added`]);
                dispatch(change(form, "filtersHaulers", data));
                setState({ ...state, isLoading: false, showEditModal: false });
            })
            .catch((error) => {
                setState({ ...state, isLoading: false });
            });
    };

    const removeFilter = (filter) => {
        setState({ ...state, isLoading: true });

        deleteUserFilterById(filter.id)
            .then(() => {
                dispatch(
                    change(
                        form,
                        "filtersHaulers",
                        filtersHaulers.filter((i) => i.id !== filter.id),
                    ),
                );
                setSuccess([`Filter ${filter.name} was removed`]);
                setState({ ...state, isLoading: false });
                setFilterToDelete(null);
            })
            .catch((error) => {
                setError([PROCESS_SERVER_ERROR(error)]);
                setState({ ...state, isLoading: false });
                setFilterToDelete(null);
            });
    };

    const handleAllowReconciliationUpdating = (value) => {
        const dataToUpdate = { allowReconciliation: value };
        setInitialAllowReconciliation(value);
        dispatch(updateHaulersSettingsProfileData(account.company.id, dataToUpdate, setSuccess, setError, setLoading));
    };

    const getHaulerFilters = () => {
        const rows = filtersHaulers
            .filter((i) => !i.isDefault && !i.isPublic)
            .map((i) => ({
                title: i.name,
                content: <HaulerFilterList companies={i.companies} />,
                actions: (
                    <>
                        <Button size="small" onClick={() => setFilterToDelete(i)}>
                            Remove
                        </Button>
                        <Button size="small" color="primary" onClick={() => openEditModal(true, i)}>
                            Edit
                        </Button>
                    </>
                ),
            }));

        return rows;
    };

    return (
        <Grid className={clsx(haulersLoading && LOADER_WHITE_OVERLAY, haulerFilterLoading && LOADER_WHITE_OVERLAY)}>
            {haulerFilterLoading && <Loader />}
            <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                    <Field
                        name="allowReconciliation"
                        onChange={(e) => handleAllowReconciliationUpdating(e.target.checked)}
                        type="checkbox"
                        label="Allow sub-haulers to reconcile tickets"
                        component={CustomSwitch}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" justify="flex-end" className={clsx(classes.haulerButtonsContainer)}>
                        <SecondaryButton
                            size={"small"}
                            style={{ width: 150 }}
                            onClick={() => openInviteHaulerModal(true)}
                        >
                            <AddCircleOutlineIcon />
                            Invite Hauler
                        </SecondaryButton>
                        <SecondaryButton size={"small"} style={{ width: 150 }} onClick={() => openEditModal(true)}>
                            <AddCircleOutlineIcon />
                            Add Filter
                        </SecondaryButton>
                        <AppModal
                            closeModal={() => openEditModal(false)}
                            isOpen={showEditModal}
                            form={EDIT_CREATE_FILTER_FORM}
                            modalStyles={{ textAlign: "left", width: 600 }}
                        >
                            <AddHaulerFilterForm
                                openEditModal={openEditModal}
                                initialValues={filterOnEdit}
                                handleFormSave={handleFormSave}
                            />
                        </AppModal>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={clsx(classes.offlineHaulersContainer)}>
                <AccordionComponent
                    accordionItems={[
                        {
                            title: "Preferred Haulers",
                            content: (
                                <PreferredHaulersList
                                    appliedFilters={appliedFilters}
                                    classes={classes}
                                    handleOpenHaulerForm={handleOpenHaulerForm}
                                    updateListByFilters={updateListByFilters}
                                />
                            ),
                        },
                        {
                            title: "Offline Haulers",
                            content: <OfflineHaulersList />,
                        },
                        ...getHaulerFilters(),
                    ]}
                />
            </Grid>
            {hasSuccessAddOfflineHauler && (
                <SuccessNotification message={ADD_OFFLINE_HAULER_SUCCESS_MESSAGE} config={{ autoHideDuration: 5000 }} />
            )}
            {!_.isEmpty(haulersSuccess) && (
                <SuccessNotification message={haulersSuccess} config={{ onClose: () => setSuccess([]) }} />
            )}
            {!_.isEmpty(success) && (
                <SuccessNotification message={success} config={{ onClose: () => setSuccess([]) }} />
            )}
            {!_.isEmpty(haulersError) && (
                <ErrorNotification error={haulersError} config={{ onClose: () => setError([]) }} />
            )}
            {!_.isEmpty(error) && <ErrorNotification error={error} config={{ onClose: () => setError([]) }} />}
            {isAddHaulerToPreferredDialogActive && (
                <AddHaulerToPreferredListModal
                    isOpen={isAddHaulerToPreferredDialogActive}
                    closeModal={() => setIsAddHaulerToPreferredDialogActive(false)}
                    setSuccess={setSuccessMessage}
                    setError={setErrorMessage}
                />
            )}
            <AppModal
                closeModal={() => openInviteHaulerModal(false)}
                isOpen={showInviteHaulerModal}
                form={INVITE_HAULER_FORM}
                modalStyles={{ textAlign: "left", width: 600 }}
            >
                <InviteHaulersForm
                    openInviteHaulerModal={openInviteHaulerModal}
                    handleFormSave={() => setSuccess(["We have sent a sign-up link to the invited user."])}
                />
            </AppModal>
            <ConfirmationModal
                isOpen={!_.isEmpty(filterToDelete)}
                question={"Are you sure you want to delete filter?"}
                yesHandler={() => removeFilter(filterToDelete)}
                noHandler={() => setFilterToDelete(null)}
            />
        </Grid>
    );
};

HaulersSetting.propTypes = {
    account: PropTypes.object.isRequired,
    fetchMore: PropTypes.func.isRequired,
};

export const HAULERS_SETTINGS_FORM = "haulerSettingsForm";

export default withRouter(
    compose(
        reduxForm({
            form: HAULERS_SETTINGS_FORM,
            enableReinitialize: true,
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);

            return {
                account: state.account,
                formValues: formSelector(state, "filtersHaulers", START_DATE_NAME, "filtersHaulersCount"),
            };
        }),
    )(HaulersSetting),
);
