import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Circle, InfoWindow, Marker, Polygon } from "react-google-maps";
import { polygonCenter } from "../../../helpers/global";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grid from "@material-ui/core/Grid";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";

const CustomMarker = ({
    position,
    icon,
    opacity,
    fadeOthers,
    onOpenTip,
    onCloseTip,
    onClick,
    id,
    children,
    zIndex,
}) => {
    const [state, setState] = useState({ open: false });
    const { open } = state;

    const openTip = (open) => {
        setState({ ...state, open });
        fadeOthers && fadeOthers(open ? [id] : []);
        if (open) {
            onOpenTip && onOpenTip(id);
        } else {
            onCloseTip && onCloseTip(id);
        }
    };

    return (
        <Marker
            position={position}
            opacity={opacity}
            zIndex={zIndex}
            onMouseOver={() => openTip(true)}
            onMouseOut={() => openTip(false)}
            icon={icon}
            onClick={onClick}
        >
            {open && children && <InfoWindow>{children}</InfoWindow>}
        </Marker>
    );
};

CustomMarker.propTypes = {
    children: PropTypes.any,
    position: PropTypes.any.isRequired,
    icon: PropTypes.object,
    opacity: PropTypes.number.isRequired,
    onOpenTip: PropTypes.func,
    onClick: PropTypes.func,
    onCloseTip: PropTypes.func,
    fadeOthers: PropTypes.func,
    id: PropTypes.number,
    zIndex: PropTypes.number,
};

CustomMarker.defaultProps = {
    opacity: 1,
};

export default CustomMarker;

export const CircleWithTooltip = (props) => {
    const { infoContent, circleConfig, allowRemove, onRightClick: rightClickCallBack, removeAlertZone } = props;
    const [state, setState] = useState({ open: false });
    const [showMenu, setShowMenu] = useState(false);
    const { open } = state;

    const openTip = (open) => {
        setState({ ...state, open });
    };

    const onRightClick = () => {
        rightClickCallBack && rightClickCallBack(props);
        allowRemove && setShowMenu(true);
    };

    const removeSite = () => {
        removeAlertZone && removeAlertZone(circleConfig);
    };

    return (
        <React.Fragment>
            <Circle
                defaultCenter={circleConfig.center}
                onRightClick={onRightClick}
                onMouseOver={() => openTip(true)}
                onMouseOut={() => openTip(false)}
                radius={+circleConfig.radius}
                options={circleConfig.options}
            />
            {open && !showMenu && (
                <InfoWindow position={circleConfig.center}>
                    <div>{infoContent}</div>
                </InfoWindow>
            )}
            {showMenu && (
                <MapContextMenu position={circleConfig.center} siteName={circleConfig.name} removeZone={removeSite} />
            )}
        </React.Fragment>
    );
};

CircleWithTooltip.propTypes = {
    circleConfig: PropTypes.object.isRequired,
    infoContent: PropTypes.any,
    onRightClick: PropTypes.func,
    removeAlertZone: PropTypes.func,
    allowRemove: PropTypes.bool.isRequired,
};

CircleWithTooltip.defaultProps = {
    opacity: 0,
    allowRemove: true,
    onRightClick: () => {},
};

export const PolygonWithTooltip = (props) => {
    let _poly;
    const {
        infoContent,
        polygonConfig,
        allowRemove,
        onRightClick: rightClickCallBack,
        onEmitSave,
        removeAlertZone,
        saveEditOfZone,
        editZoneHandler,
        onEditAlertZoneId,
        setOnEditAlertZone,
    } = props;
    const [state, setState] = useState({ open: false });
    const [showMenu, setShowMenu] = useState(false);
    const [onEdit, setEditable] = useState();
    const { open } = state;

    const openTip = (open) => {
        setState({ ...state, open });
    };

    const onRightClick = () => {
        rightClickCallBack && rightClickCallBack(props);
        allowRemove && setShowMenu(true);
    };

    const onCloseClick = () => {
        setShowMenu(false);
    };

    const removeZone = () => {
        removeAlertZone && removeAlertZone(polygonConfig);
    };

    const editZone = () => {
        editZoneHandler && editZoneHandler(polygonConfig);
        setEditable(true);
        setShowMenu(false);
        setOnEditAlertZone && setOnEditAlertZone(polygonConfig.id);
    };

    const saveZone = () => {
        setEditable(false);
        setShowMenu(false);
        saveEditOfZone && saveEditOfZone({ ...polygonConfig, paths: getPolyPaths() });
        setOnEditAlertZone && setOnEditAlertZone(null);
    };

    const getPolyPaths = () => {
        const newCoords = _poly.getPath().getArray();
        const paths = [];

        newCoords.forEach((i) => {
            paths.push({ lat: i.lat(), lng: i.lng() });
        });

        return paths;
    };

    useEffect(() => {
        if (onEmitSave && saveEditOfZone) {
            saveEditOfZone({ ...polygonConfig, paths: getPolyPaths() });
        }
    }, [onEmitSave]);

    useEffect(() => {
        setEditable(onEditAlertZoneId === polygonConfig.id);
    }, [onEditAlertZoneId]);

    return (
        <React.Fragment>
            <Polygon
                paths={polygonConfig.paths}
                onRightClick={onRightClick}
                ref={(poly) => (_poly = poly)}
                onMouseOver={() => openTip(true)}
                onMouseOut={() => openTip(false)}
                options={{ ...polygonConfig.options, editable: onEdit }}
            />
            {open && !showMenu && (
                <InfoWindow position={polygonCenter(polygonConfig.paths)} onCloseClick={onCloseClick}>
                    <div>
                        <div className="--text-center">{infoContent}</div>
                    </div>
                </InfoWindow>
            )}
            {showMenu && (
                <MapContextMenu
                    position={polygonCenter(polygonConfig.paths)}
                    siteName={polygonConfig.name}
                    editZone={editZone}
                    saveZone={saveZone}
                    onEdit={onEdit}
                    onCloseClick={onCloseClick}
                    saveEditOfZone={saveEditOfZone}
                    removeZone={removeZone}
                />
            )}
        </React.Fragment>
    );
};

PolygonWithTooltip.propTypes = {
    polygonConfig: PropTypes.object.isRequired,
    infoContent: PropTypes.any,
    onRightClick: PropTypes.func,
    removeAlertZone: PropTypes.func,
    saveEditOfZone: PropTypes.func,
    onEmitSave: PropTypes.bool,
    onEditAlertZoneId: PropTypes.string,
    editZoneHandler: PropTypes.func,
    allowRemove: PropTypes.bool.isRequired,
    setOnEditAlertZone: PropTypes.func,
};

PolygonWithTooltip.defaultProps = {
    opacity: 0,
    allowRemove: true,
    onRightClick: () => {},
};

export const MapContextMenu = (props) => {
    const { position, siteName, removeZone, editZone, saveEditOfZone, onCloseClick, onEdit, saveZone } = props;
    const iconStyles = { marginRight: 10, width: 20, height: 20 };

    return (
        <InfoWindow position={position} onCloseClick={onCloseClick}>
            <MenuList id="split-button-menu" style={{ padding: 0 }}>
                {removeZone && (
                    <MenuItem component="div" onClick={removeZone}>
                        <Grid container alignItems={"center"}>
                            <DeleteOutlineIcon style={iconStyles} />
                            Remove {siteName}
                        </Grid>
                    </MenuItem>
                )}
                {editZone && saveEditOfZone && (
                    <MenuItem component="div" onClick={!onEdit ? editZone : saveZone} style={{ marginTop: 10 }}>
                        <Grid container alignItems={"center"}>
                            {!onEdit ? (
                                <>
                                    <EditIcon style={iconStyles} />
                                    Edit {siteName}
                                </>
                            ) : (
                                <>
                                    <SaveIcon style={iconStyles} />
                                    Save {siteName}
                                </>
                            )}
                        </Grid>
                    </MenuItem>
                )}
            </MenuList>
        </InfoWindow>
    );
};

MapContextMenu.propTypes = {
    siteName: PropTypes.string.isRequired,
    position: PropTypes.object.isRequired,
    removeZone: PropTypes.func,
    editZone: PropTypes.func,
    saveEditOfZone: PropTypes.func,
    onCloseClick: PropTypes.func,
    saveZone: PropTypes.func,
    onEdit: PropTypes.bool,
};

MapContextMenu.defaultProps = {
    onCloseClick: () => {},
    saveZone: () => {},
    editZone: () => {},
    onEdit: false,
};
