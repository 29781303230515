import { UPDATE_DEFAULT_FILTER_ID } from "../events/haulersSettings";
import { saveUserProfile } from "../dataServers/user";
import { updateAccountData } from "../helpers/global";
import { updateReduxUserData } from "./globalActions";
import { ACCOUNT_COMPANY_PROFILE_KEY, PROCESS_SERVER_ERROR } from "../constants/global";

export const updateDefaultFilterId = (defaultFilterId) => {
    return {
        type: UPDATE_DEFAULT_FILTER_ID,
        payload: defaultFilterId,
    };
};

export const updateHaulersSettingsProfileData = (companyId, dataToUpdate, successHandler, errorHandler, setLoading) => {
    return (dispatch) => {
        setLoading(true);
        saveUserProfile(companyId, dataToUpdate)
            .then((data) => {
                updateAccountData(
                    data.data,
                    (accountData) => dispatch(updateReduxUserData(accountData)),
                    ACCOUNT_COMPANY_PROFILE_KEY,
                );
                successHandler(["Hauler settings are updated"]);
            })
            .catch((error) => {
                errorHandler([PROCESS_SERVER_ERROR(error)]);
            })
            .finally(() => {
                setLoading(false);
            });
    };
};
