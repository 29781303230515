import React, { useState } from "react";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { sendUserMessage } from "../../dataServers/teams";
import TextInputComponent from "../core/form/textInputComponent";
import { Field, reduxForm } from "redux-form";
import ErrorNotification from "../core/notification";
import SuccessNotification from "../core/successNotification";
import AppModal from "../core/modal";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { Validation } from "../../helpers/validation";
import PropTypes from "prop-types";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";

const MessageDialog = (props) => {
    const { user, isOpened, handleClose, handleSubmit, reset } = props;
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);

    const closeModal = () => {
        reset();
        handleClose();
    };

    const handleSendMessage = (values) => {
        const userId = user.id;
        const params = {
            type: "sms",
            ...values,
        };

        setLoading(true);

        sendUserMessage(userId, params)
            .then(() => {
                setIsHandledSuccessfully(true);
                setLoading(false);
                closeModal();
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    return (
        <AppModal isOpen={isOpened} form={TEAM_MESSAGE_FORM} closeModal={closeModal}>
            <div className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <h2 className="title">SEND MESSAGE</h2>
                <form noValidate={true} onSubmit={handleSubmit(handleSendMessage)}>
                    <Field
                        type="text"
                        validate={[Validation.required]}
                        name="text"
                        label="Message"
                        className="form-view"
                        component={TextInputComponent}
                    />
                    <br />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                        <PrimaryButton type="submit">Send</PrimaryButton>
                    </ButtonsGroup>
                </form>
            </div>
            {error && <ErrorNotification error={error.message.text} config={{ onClose: () => setError(null) }} />}
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
        </AppModal>
    );
};

export const TEAM_MESSAGE_FORM = "myTeamsMessageForm";

MessageDialog.propTypes = {
    user: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

const Form = reduxForm({
    form: TEAM_MESSAGE_FORM,
})(MessageDialog);

export default Form;
