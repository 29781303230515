import { SubmissionError } from "redux-form";
import { isString, isObject } from "lodash";

const responseHasErrors = (response) => response.status >= 300 && !!response.message;

export const formatReduxFormErrors = (response) => {
    if (!responseHasErrors(response)) return null;

    let errors = { _error: [] };

    if (isString(response.message)) {
        errors._error = errors._error.concat(response.message);
    } else if (isObject(response.message)) {
        for (let [field, messages] of Object.entries(response.message)) {
            if (messages && messages.length > 0) {
                errors[field] = messages[0];
            }
        }
    }

    if (!errors._error.length) {
        delete errors._error;
    }

    throw new SubmissionError(errors);
};
