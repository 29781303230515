import _ from "lodash";

export const generateAdditionalExternalRefsPUTData = (additionalExternalRefs) => {
    const refsToModify = additionalExternalRefs.filter((ref) => _.isNumber(ref.id));
    const refsToCreate = additionalExternalRefs.filter((ref) => !_.isNumber(ref.id));

    return {
        toModify: refsToModify.map((ref) => ({
            externalRef: ref.externalRef,
            labelId: ref.label.value,
            id: ref.id,
        })),
        toCreate: refsToCreate.map((ref) => ({
            externalRef: ref.externalRef,
            labelId: ref.label.value,
        })),
    };
};
