import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import clsx from "clsx";
import DispatchersFieldSelector from "../core/form/dispatchersFieldSelector";
import { NOTIFICATIONS_RECIPIENTS_LABEL } from "./constants/strings";
import React from "react";

const ProjectNotificationsRecipients = ({ classes, setLoading, updateFieldByName, disabled }) => {
    const onRecipientChange = async (recipients) => {
        setLoading(true);
        const updatedRecipientsIdList = (recipients || []).map((recipient) => recipient.value);
        try {
            await updateFieldByName(updatedRecipientsIdList, "dropOffNotificationsRecipients");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container alignItems="center" className="notifications-recipients">
            <AssignmentIndIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
            <Grid item xs={5}>
                <DispatchersFieldSelector
                    name="dropOffNotificationsRecipients"
                    label={NOTIFICATIONS_RECIPIENTS_LABEL}
                    isMulti={true}
                    onFieldChange={onRecipientChange}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default ProjectNotificationsRecipients;
