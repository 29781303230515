import React, { useState } from "react";
import PropTypes from "prop-types";
import ActionMenuItem from "../core/actionMenuItem";
import MoreActionsMenu from "../core/moreActions";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { connect, useDispatch } from "react-redux";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SettingsIcon from "@material-ui/icons/Settings";

import {
    getAllCompanyAdmins,
    getCompanyForEdit,
    getCompanySubCompanies,
    handleCompanyDialogToggle,
    setCompaniesLoading,
    updateCompaniesList,
    updateCompanyById,
    updateCompanyForManagingHaulers,
    updateCompanyForUpdatingAdmins,
} from "../../actions/companies";
import { archiveCompany, validateCompanyById } from "../../dataServers/companies";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import { withRouter } from "react-router-dom";
import { PROCESS_SERVER_ERROR } from "../../constants/global";
import ConfirmationModal from "../core/confirmationModal";
import { ROUTE_MY_COMPANY } from "../../routes/globalRoutes";
import { IS_ADMIN_USER } from "../../constants/maps";
import { generateUserSelectOptions } from "../../constants/users";

const CompaniesActionMenu = (props) => {
    const { companyForEdit, updateCompany, setLoading, updateCompaniesList, companies, history, account } = props;
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [confirmationMessage, setConfirmation] = useState();
    const dispatch = useDispatch();
    const toggleDialog = (companyForEdit) => {
        dispatch(handleCompanyDialogToggle());
        dispatch(getCompanyForEdit(companyForEdit));
    };
    const unarchiveCompany = (company = companyForEdit) => {
        setLoading(true);
        setConfirmation("");

        archiveCompany(company.id)
            .then(() => {
                setIsHandledSuccessfully(true);
                setLoading(false);
                const newCompanies = companies.map((i) => {
                    if (i.id === company.id) {
                        return { ...i, archived: !company.archived };
                    }

                    return i;
                });
                updateCompaniesList(newCompanies);
            })
            .catch((error) => {
                setLoading(false);
                setErrorMessage(PROCESS_SERVER_ERROR(error));
            });
    };
    const showConfirmationModal = () => {
        setConfirmation("Are you sure you want to archive this compmany?");
    };
    const openCompanyTeam = () => {
        history.push(ROUTE_MY_COMPANY.MY_TEAM, { company: companyForEdit });
    };
    const openCompanyFleet = () => {
        history.push(ROUTE_MY_COMPANY.MY_FLEET, { company: companyForEdit });
    };
    const openManagedHaulersForm = async (company) => {
        const { data } = await dispatch(getCompanySubCompanies(company.id, setErrorMessage));
        const subCompanies = data.map((subCompany) => {
            return {
                value: subCompany.company.id,
                label: subCompany.company.name,
            };
        });

        dispatch(updateCompanyForManagingHaulers({ ...company, subCompanies: subCompanies }));
    };
    const openCompanyAdminsForm = async (company) => {
        const { data } = await dispatch(getAllCompanyAdmins(company.id, setErrorMessage));
        const admins = data.map(generateUserSelectOptions);

        dispatch(updateCompanyForUpdatingAdmins({ ...company, companyAdmins: admins }));
    };
    const menuItems = [
        {
            id: "archive",
            title: companyForEdit.archived ? "Unarchive" : "Archive",
            renderIcon: () => <AddBoxIcon />,
            handleClick: companyForEdit.archived ? unarchiveCompany : showConfirmationModal,
            visible: true,
        },
        {
            id: "viewTeam",
            title: "View Team",
            renderIcon: () => <VisibilityIcon />,
            handleClick: openCompanyTeam,
            visible: true,
        },
        {
            id: "viewFleet",
            title: "View Fleet",
            renderIcon: () => <VisibilityIcon />,
            handleClick: openCompanyFleet,
            visible: true,
        },
    ];

    if (account.isSuperUser) {
        menuItems.push({
            id: "managedHaulers",
            title: "Managed Haulers",
            visible: true,
            handleClick: openManagedHaulersForm,
            renderIcon: () => <SettingsIcon />,
        });
    }

    if (IS_ADMIN_USER(account.company.id)) {
        menuItems.push({
            id: "companyAdmins",
            title: "Company Admins",
            visible: true,
            handleClick: openCompanyAdminsForm,
            renderIcon: () => <SettingsIcon />,
        });
    }

    if (!companyForEdit.archived) {
        menuItems.unshift({
            id: "edit",
            title: "Edit",
            renderIcon: () => <EditIcon />,
            handleClick: toggleDialog,
            visible: true,
        });
    }

    const validateCompany = () => {
        setLoading(true);
        validateCompanyById(companyForEdit.id)
            .then(() => {
                updateCompany({ ...companyForEdit, validated: true });
                setIsHandledSuccessfully(true);
            })
            .catch((error) => {
                setErrorMessage(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    if (!companyForEdit.validated) {
        menuItems.push({
            id: "validate",
            title: "Validate",
            renderIcon: () => <CheckCircleOutlineIcon />,
            handleClick: validateCompany,
            visible: true,
        });
    }

    const handleItemClick = (callback) => {
        companyForEdit ? callback(companyForEdit) : callback();
    };

    const onMessageClose = () => {
        setErrorMessage(null);
        setIsHandledSuccessfully(null);
    };

    return (
        <React.Fragment>
            <MoreActionsMenu>
                {menuItems.map((menuItem) => {
                    if (!menuItem.visible) {
                        return false;
                    }

                    return (
                        <ActionMenuItem
                            key={menuItem.id}
                            title={menuItem.title}
                            renderIcon={menuItem.renderIcon}
                            redirect={menuItem.redirect}
                            handleClick={() => handleItemClick(menuItem.handleClick)}
                        />
                    );
                })}
            </MoreActionsMenu>
            {isHandledSuccessfully && <SuccessNotification message="Success" config={{ onClose: onMessageClose }} />}
            {errorMessage && <ErrorNotification message={errorMessage} config={{ onClose: onMessageClose }} />}
            <ConfirmationModal
                isOpen={!!confirmationMessage}
                question={confirmationMessage}
                yesHandler={() => unarchiveCompany(companyForEdit)}
                noHandler={() => setConfirmation("")}
            />
        </React.Fragment>
    );
};

CompaniesActionMenu.propTypes = {
    companyForEdit: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    updateCompany: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    updateCompaniesList: PropTypes.func.isRequired,
};
CompaniesActionMenu.defaultProps = {
    companyForEdit: {},
};

export default withRouter(
    connect(
        (state) => ({
            account: state.account,
            companies: state.companies.companies,
        }),
        (dispatch) => ({
            setLoading: (isLoading) => {
                dispatch(setCompaniesLoading(isLoading));
            },
            updateCompany: (updatedCompany) => {
                dispatch(updateCompanyById(updatedCompany));
            },
            updateCompaniesList: (companies) => {
                dispatch(updateCompaniesList(companies));
            },
        }),
    )(CompaniesActionMenu),
);
