import React from "react";
import { makeStyles } from "@material-ui/core";
import { MEDIUM_BUTTON_SIZE, PrimaryButton } from "./primaryButton";

const secondaryButtonStyles = makeStyles((theme) => ({
    button: {
        "&:disabled": {
            color: theme.palette.secondary.light,
            background: theme.palette.secondary.superLight,
            borderColor: theme.palette.border.primary,

            "& svg": {
                color: theme.palette.secondary.bark,
            },
        },
    },
    text: {
        width: MEDIUM_BUTTON_SIZE,
        height: 55,
        borderRadius: theme.shape.borderRadius,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: theme.palette.primary.superDark,
        padding: "8px 10px",
        lineHeight: 1,
        fontSize: 12,

        "& svg": {
            width: 18,
            height: 18,
            marginRight: 5,
        },
    },
    textSecondary: {
        color: theme.palette.primary.main,
        background: "#fff",

        "&:hover, &:active": {
            boxShadow: `inset 0px 0px 2px ${theme.palette.primary.superDark}`,
            background: "#fff",
        },
    },
    textSizeSmall: {
        fontSize: theme.typography.button.fontSize,
    },
    textSizeLarge: {
        fontSize: theme.typography.button.fontSize,
    },
    sizeSmall: {
        width: 125,
        height: 35,
        padding: 3,
    },
    sizeLarge: {
        width: 280,
        height: 64,
    },
    sizeXSmall: {
        width: 75,
        height: 35,
    },
}));

export const SecondaryButton = (props) => {
    const classes = secondaryButtonStyles();

    return <PrimaryButton {...props} color={"secondary"} classes={classes} />;
};
