import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import Loader, { fixedLoaderStyles } from "../core/loader";
import CompaniesActionMenu from "./companiesActionMenu";
import { COMPANY_TYPES } from "../../constants/companies";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { IS_ADMIN_USER } from "../../constants/maps";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import { ACCOUNT_TYPES } from "../../constants/accounts";
import { updateActiveCompanyOrder } from "../../actions/companies";
import { THEME_ICONS } from "../../styles/icons";

const CompaniesList = (props) => {
    const { companies, companiesLoading, fetchMore, companiesCount, account } = props;
    const dispatch = useDispatch();
    const activeCompaniesOrder = useSelector((state) => state.companies.activeCompaniesOrder);
    const getTableData = () => {
        const rows = companies.map((company, index) => {
            const { id, name, type, accountType, parentCompany, isAffiliatedCompany, validated, archived } = company;

            return {
                index,
                id,
                name: isAffiliatedCompany ? (
                    <Box className="td" display="flex" alignItems="center">
                        <Icon style={THEME_ICONS.i80Logo} />
                        <Typography component="td">
                            {parentCompany.name} / {name}
                        </Typography>
                    </Box>
                ) : (
                    name
                ),
                type: COMPANY_TYPES[type],
                accountType: ACCOUNT_TYPES[accountType],
                validated: validated ? (
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                ) : (
                    <HighlightOffIcon style={{ color: "red" }} />
                ),
                status: IS_ADMIN_USER(account.company.id) && (
                    <div className="td">
                        {archived ? (
                            <CloudOffIcon titleAccess="Archived" />
                        ) : (
                            <CloudQueueIcon titleAccess="Not Archived" />
                        )}
                    </div>
                ),
                action: <CompaniesActionMenu companyForEdit={company} />,
            };
        });
        let columns = [
            { id: "id", label: "ID", sortParam: "id" },
            { id: "name", label: "NAME", sortParam: "name", subLabel: "Company Name / Sub Company Name" },
            { id: "type", label: "Type", sortParam: "type" },
            { id: "accountType", label: "Account Type", sortParam: "accountType" },
            { id: "validated", label: "Validated", sortParam: "validated" },
        ];

        if (IS_ADMIN_USER(account.company.id)) {
            columns.push({ id: "status", label: "Status", sortParam: "archived" });
        }

        columns = [...columns, { id: "action", label: TEXT_ACTIONS_TABLE_CELL }];

        return { rows: rows, columns: columns };
    };

    const handleCompaniesSort = (sortParam) => {
        dispatch(updateActiveCompanyOrder(sortParam));
    };

    return (
        <React.Fragment>
            <div className={clsx(companiesLoading && LOADER_WHITE_OVERLAY, _.isEmpty(companies) && "-min-height")}>
                {companiesLoading && <Loader style={fixedLoaderStyles()} />}
                {!_.isEmpty(companies) && (
                    <div>
                        <TableWithStickyHeader
                            rows={getTableData().rows}
                            fetchMore={fetchMore}
                            itemsCount={companiesCount}
                            columns={getTableData().columns}
                            handleSort={handleCompaniesSort}
                            activeOrderParam={activeCompaniesOrder}
                        />
                    </div>
                )}
            </div>
            {_.isEmpty(companies) && !companiesLoading && (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No companies match your search / filter parameters.</h1>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

CompaniesList.propTypes = {
    companies: PropTypes.array,
    companiesLoading: PropTypes.bool.isRequired,
    fetchMore: PropTypes.func.isRequired,
    companiesCount: PropTypes.number.isRequired,
    account: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => ({
        companies: state.companies.companies,
        companiesLoading: state.companies.companiesLoading,
        companiesCount: state.companies.companiesCount,
        account: state.account,
    }))(CompaniesList),
);
