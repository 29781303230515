import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    meta: {
        loading: false,
        error: null,
        count: 0,
    },
};

const companyAdmins = createSlice({
    name: "COMPANY ADMINS",
    initialState,
    reducers: {
        companyAdminsRequest(state, action) {
            state.meta.loading = true;
        },
        companyAdminsSuccess(state, { payload }) {
            const { response, concat } = payload;
            const { data, meta } = response;
            state.meta.loading = false;
            state.meta.count = meta.count;

            if (concat) {
                state.data.push(...data);
            } else {
                state.data = data;
            }
        },
        companyAdminsFail(state, { payload }) {
            state.meta.loading = false;
            state.meta.error = [payload.message];
        },
        companyAdminsErrorUpdate(state, { payload }) {
            state.meta.error = payload;
        },
        initialize(state, action) {
            state.data = initialState.data;
            state.meta = initialState.meta;
        },
    },
});

export const { actions } = companyAdmins;
export const companyAdminsErrorUpdate = actions.companyAdminsErrorUpdate;

export const selectCompanyAdmins = (state) => state.companyAdmins.data;
export const selectCompanyAdminsMeta = (state) => state.companyAdmins.meta;

export default companyAdmins.reducer;
