import React from "react";
import { Field, reduxForm } from "redux-form";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { Validation } from "../../helpers/validation";
import { withRouter } from "react-router-dom";
import { compose as reduxCompose } from "redux";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import * as CompaniesDataServer from "../../dataServers/companies";
import { GLOBAL_COUNT_TO_LOAD } from "../../constants/endpoints";

const SettingsCompanySelector = () => {
    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return CompaniesDataServer.getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const currentPage = Math.ceil(loadedCount / GLOBAL_COUNT_TO_LOAD);
            const nextPage = loadedCount ? +currentPage + 1 : 1;
            const options = data.data.map((hauler) => ({
                label: hauler.name,
                value: hauler.id,
                regionRequired: hauler.regionRequired,
            }));

            return {
                options: options,
                hasMore: data.meta.count > (loadedCount || options.length),
                page: nextPage,
            };
        });
    };

    return (
        <Grid container>
            <Grid item xs={5}>
                <br />
                <Field
                    name="company"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    validate={[Validation.required]}
                    loadOptions={loadCompanies}
                    placeholder="Company"
                    component={AsyncAutocompleteComponent}
                />
                <br />
            </Grid>
        </Grid>
    );
};

export default withRouter(
    reduxCompose(
        reduxForm({}),
        connect((state) => ({
            account: state.account,
        })),
    )(SettingsCompanySelector),
);
