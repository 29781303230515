import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { change, Field, FieldArray, formValueSelector, getFormInitialValues, reduxForm } from "redux-form";

import { compose } from "redux";
import { connect } from "react-redux";
import Loader from "../../core/loader";
import { Divider } from "../../core/divider";
import PickUpDropOff, { DROP_OF_SITE_NAME, PICK_UP_SITE_NAME } from "../jobComponents/pickUp&DropOff";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import HiddenInput from "../../core/form/hiddenInput";
import TruckItTimePicker from "../../core/form/timePicker";
import { convertDateToUserTimeZone } from "../../../helpers/jobOrders";
import { updateJobOrderById } from "../../../dataServers/jobOrder";
import ErrorNotification from "../../core/notification";
import { TRUCKS_TABLE_STYLES } from "../../../styles/reusableStyles";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import ConfirmationModal from "../../core/confirmationModal";
import JoliTable from "./joliTable";
import { JOLI_FIELD_ID } from "../constants";

const useStyles = makeStyles(() => ({
    generalPadding: {
        padding: "20px 27px",
    },
    generalSidePadding: {
        padding: "0 27px",
    },
    pickUp: {
        "& a": {
            fontSize: 16,
        },
    },
}));

const renderJoli = ({ fields, jobOrder, unitOfMeasure, specifyNumberOfTrucksHandler, form }) => {
    const all = fields.getAll() || [];

    return (
        <div>
            {fields.map((item, index) => {
                const current = fields.get(index);
                // was error: Rendered more hooks than during the previous render
                // important using <Component value={value} /> syntax instead of function syntax Component({id})
                // when dealing with React hooks!
                return (
                    <React.Fragment key={index}>
                        <JoliTable
                            jobOrder={jobOrder}
                            unitOfMeasure={unitOfMeasure}
                            index={index}
                            item={item}
                            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                            current={current}
                            form={form}
                        />
                        {all.length > 1 && <br />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

renderJoli.propTypes = {
    fields: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    customFooter: PropTypes.any,
    drop: PropTypes.any,
    specifyNumberOfTrucksHandler: PropTypes.func,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
    unitOfMeasure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const prepareFromValuesForSave = (values = {}, account, props) => {
    const {
        allowToNotify,
        autoAccept,
        allowUnfilledStart,
        [DROP_OF_SITE_NAME]: dropOffSites,
        isOvernight,
        [PICK_UP_SITE_NAME]: pickUpSite,
        poLineItem,
        regionId,
        terms,
        startDate,
        totalQuantity,
        unlimited,
        [JOLI_FIELD_ID]: joliItems,
        costCode,
        paverTrackers,
        autoApprove,
        smartDispatch,
    } = values;
    const correctStartDate = convertDateToUserTimeZone(startDate, account.timezone);
    const addTrailers = Object.keys(values)
        .filter((fieldName) => fieldName.includes("-trailers") && !!values[fieldName])
        .map((name) => {
            const truckId = name.split("-")[0];
            const trailers = values[name];
            return trailers.map((t) => ({ ...t, truckId }));
        })
        .flat()
        .map((trailer) => ({
            trailer: +trailer.id,
            truck: +trailer.truckId,
        }));
    const result = {
        costCode,
        allowToNotify,
        allowUnfilledStart,
        autoAccept,
        dropOffSites: !_.isEmpty(dropOffSites) ? dropOffSites.map((i) => i.value || i.id) : [],
        isOvernight: isOvernight || false,
        pickUpSite: pickUpSite ? pickUpSite.value || pickUpSite.id : null,
        poLineItemId: poLineItem.id || poLineItem.value || poLineItem,
        regionId,
        startDate: correctStartDate,
        totalQuantity: totalQuantity ? +totalQuantity : 0,
        unlimited,
        paverTrackers,
        smartDispatch,
        items: _.cloneDeep(joliItems).map((joli) => {
            return {
                id: joli.id,
                assignAllHaulers: false,
                autoApprove,
                quantity: joli.quantity,
                startDate: convertDateToUserTimeZone(joli.startDate, account.timezone),
                trucks: [],
                terms: terms ? terms.id || terms.value : 1,
                addTrailers: addTrailers.length ? addTrailers : [],
                removeTrailers: props.removeTrailers.length ? props.removeTrailers : [],
                haulers: [joli.currentHauler, ...joli.haulers]
                    .filter((item) => !_.isEmpty(item))
                    .map((hauler) => {
                        const newHauler = {
                            needTrucksCount: hauler.needTrucksCount,
                            companyId: hauler.id,
                            trucks: hauler.trucks && hauler.trucks.map((i) => i.id || i.truckId),
                            haulerRate: hauler.haulerRate || null,
                        };
                        if (hauler.id === joli.currentHauler.id) {
                            delete newHauler.haulerRate;
                        }

                        if (hauler.id === account.company.id) {
                            newHauler.trucks = joli.trucks.map((i) => i.id || i.truckId);
                        }

                        return newHauler;
                    }),
            };
        }),
    };

    return result;
};

const JobBoardForm = (props) => {
    const classes = useStyles();
    const trucksTableStyles = TRUCKS_TABLE_STYLES();
    const [state, setState] = useState({
        isLoading: false,
        openConfirmModal: false,
        showCreatePoliModal: false,
        showFormWarn: false,
        newPoliWasCreated: false,
    });
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const { isLoading } = state;
    const {
        jobOrder,
        reset,
        handleSubmit,
        saveChangesHandler,
        form,
        showFormWarn,
        closeWarn,
        dispatch,
        pristine,
        formValues: { unitOfMeasure, [JOLI_FIELD_ID]: joliItems },
        account,
    } = props;
    const { id: jobOrderId, summaryInfo } = jobOrder;
    const cancelFormEdit = () => {
        reset();
        !pristine && setMessage(["Changes canceled"]);
    };
    const onSubmit = (values) => {
        const data = prepareFromValuesForSave(
            {
                ...values,
                autoApprove: jobOrder.autoApprove,
                smartDispatch: jobOrder.smartDispatch,
            },
            account,
            props,
        );

        setState({
            ...state,
            isLoading: true,
        });

        updateJobOrderById(jobOrderId, data)
            .then(({ data }) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setMessage([`Job #${values.id} updated.`]);
                saveChangesHandler && saveChangesHandler(data);
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                });
                setError([PROCESS_SERVER_ERROR(error, "Job order was not saved")]);
            });
    };
    const specifyNumberOfTrucksHandler = (add, joliIndex, truckIndex) => {
        dispatch(
            change(
                form,
                JOLI_FIELD_ID,
                _.cloneDeep(joliItems).map((joli, indexJoli) => {
                    if (joliIndex === indexJoli) {
                        // @todo add check for possible assign trucks count
                        joli.haulers = joli.haulers.map((hauler, index) => {
                            // is not used as several same trucks can be added in table
                            if (index === truckIndex) {
                                if (add) {
                                    const haulersRequestedTrucksSum = joli.haulers.reduce((prev, current) => {
                                        return prev + current.needTrucksCount;
                                    }, 0);
                                    const haulerTrucks = hauler.trucks ? hauler.trucks.length : 0;
                                    const countAdded = joli.trucks.length + haulerTrucks + haulersRequestedTrucksSum;

                                    if (countAdded < joli.needTrucksCount) {
                                        hauler.needTrucksCount = +hauler.needTrucksCount + 1;
                                    }
                                } else if (hauler.needTrucksCount > 1) {
                                    hauler.needTrucksCount = +hauler.needTrucksCount - 1;
                                }
                            }

                            return hauler;
                        });
                    }

                    return joli;
                }),
            ),
        );
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate={true}
                className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            >
                {!_.isEmpty(jobOrder) && (
                    <div className={clsx(trucksTableStyles.jobOrderEditForm)}>
                        <Grid
                            container
                            component="div"
                            justify={"space-between"}
                            alignItems={"center"}
                            className={classes.generalPadding}
                        >
                            <Grid item component="div" xs={6}>
                                <h2>{jobOrderId ? `Job #${jobOrderId}` : "New Job"}</h2>
                                <Field name="unitOfMeasure" component={HiddenInput} />
                            </Grid>
                            <Grid item component="div" xs={3}>
                                <Grid container component="div" alignItems={"center"} justify={"space-between"}>
                                    <Field
                                        name="startDate"
                                        disablePast={true}
                                        disabled={!jobOrder.iAmOwner}
                                        component={TruckItTimePicker}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider marginBottom={0} marginTop={0} />
                        <PickUpDropOff
                            form={form}
                            className={clsx(classes.generalPadding, classes.pickUp)}
                            jobOrder={jobOrder}
                            // additionalField={(
                            //     <Grid item component="div" xs={5}>
                            //         <Field name="price"
                            //                type="number"
                            //                label="Propose New Price"
                            //                startAdornment={<LocalAtmIcon/>}
                            //                component={TextInputComponent}/>
                            //     </Grid>
                            // )}
                            summaryInfo={summaryInfo}
                        />
                        <FieldArray
                            name={JOLI_FIELD_ID}
                            rerenderOnEveryChange
                            classes={classes}
                            unitOfMeasure={unitOfMeasure}
                            jobOrder={jobOrder}
                            specifyNumberOfTrucksHandler={specifyNumberOfTrucksHandler}
                            form={form}
                            component={renderJoli}
                        />
                    </div>
                )}
                <br />
                <Field name="formError" errorAlign="right" component={HiddenInput} />
                <br />
                <ButtonsGroup justifyContent="flex-end">
                    <PrimaryButton type="button" onClick={() => cancelFormEdit()}>
                        Cancel
                    </PrimaryButton>
                    <PrimaryButton type="submit">Save Job</PrimaryButton>
                </ButtonsGroup>
                {isLoading && <Loader />}
                <br />
                <br />
                <br />
            </form>
            <ConfirmationModal
                isOpen={showFormWarn}
                noHandler={() => closeWarn && closeWarn(jobOrder)}
                yesHandler={() => {
                    reset();
                    closeWarn && closeWarn(jobOrder);
                }}
            />
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {message && (
                <ErrorNotification error={message} type="success" config={{ onClose: () => setMessage(null) }} />
            )}
        </div>
    );
};

JobBoardForm.propTypes = {
    formValues: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    jobOrder: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showFormWarn: PropTypes.bool.isRequired,
    reset: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    cancelFormEditHandler: PropTypes.func,
    saveChangesHandler: PropTypes.func,
    closeWarn: PropTypes.func,
    [JOLI_FIELD_ID]: PropTypes.array,
};

JobBoardForm.defaultProps = {
    formValues: {},
    jobOrder: {},
    showFormWarn: false,
};

export const EDIT_JOB_BOARD_FORM = "editJobBoardForm";

export default withRouter(
    compose(
        reduxForm({
            // enableReinitialize: true,
            // validate
        }),
        connect((state, props) => {
            const formSelector = formValueSelector(props.form);
            const initValues = getFormInitialValues(props.form)(state);

            return {
                formValues: formSelector(state, "trucks", "id", JOLI_FIELD_ID, "unitOfMeasure"),
                account: state.account,
                removeTrailers:
                    initValues &&
                    Object.keys(initValues)
                        .filter((i) => i.includes("-trailers"))
                        .map((name) => {
                            const truckId = name.split("-")[0];
                            const trailers = initValues[name];
                            return trailers.map((t) => ({ ...t, truckId }));
                        })
                        .flat()
                        .map((trailer) => ({
                            trailer: +trailer.id,
                            truck: +trailer.truckId,
                        })),
            };
        }),
    )(JobBoardForm),
);
