import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Field, reduxForm } from "redux-form";
import TextInputComponent from "../components/core/form/textInputComponent";
import { ButtonsGroup } from "../components/core/buttons/buttonsGroup";
import { PrimaryButton } from "../components/core/buttons/primaryButton";
import { userForgotPassword } from "../dataServers/user";
import { LOGIN_STYLES } from "../styles/reusableStyles";
import { compose } from "redux";
import ErrorNotification from "../components/core/notification";
import Loader from "../components/core/loader";
import { SimpleLink } from "../components/core/link";
import { ROUTE_LOGIN } from "../routes/globalRoutes";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../constants/global";
import { Validation, VALIDATION_ERRORS } from "../helpers/validation";
import PasswordField from "../components/global/passwordField";
import EmailField from "../components/global/emailField";

const validate = ({ password, confirmPassword }) => {
    const errors = {};

    if (password !== confirmPassword) {
        errors.confirmPassword = VALIDATION_ERRORS.passwordConfirm;
    }

    return errors;
};

let timeout;

const ForgotPassword = (props) => {
    const [state, setState] = useState({ isLoading: false, errorMessage: "", sucussesMessage: "" });
    const { isLoading, errorMessage, sucussesMessage } = state;
    const loginStyles = LOGIN_STYLES();
    const { handleSubmit, history } = props;

    const forgotPassword = ({ email }) => {
        setState({ ...state, isLoading: true, sucussesMessage: "", errorMessage: "" });

        userForgotPassword({ email })
            .then((data) => {
                setState({ ...state, isLoading: false, sucussesMessage: [`Email was successfully sent to ${email}`] });
            })
            .catch((error) => {
                if (error.status === 404) {
                    setState({ ...state, isLoading: false, errorMessage: ["User with current email not found!"] });
                }
                setState({ ...state, isLoading: false });
            });
    };
    const resetPassword = ({ password }) => {
        const token = history.location.search.substring(4);
        setState({ ...state, isLoading: true, sucussesMessage: "", errorMessage: "" });

        userForgotPassword(
            {
                password,
                token,
                forceTokenDelete: true,
            },
            "PUT",
        )
            .then((data) => {
                setState({
                    ...state,
                    isLoading: false,
                    sucussesMessage: [
                        `Password was successfully changed. You will be redirected to login page in 3 sec...`,
                    ],
                });

                timeout = setTimeout(() => {
                    history.push(ROUTE_LOGIN);
                }, 3000);
            })
            .catch((error) => {
                setState({
                    ...state,
                    isLoading: false,
                    error: PROCESS_SERVER_ERROR(error),
                });
            });
    };
    const returnToLogin = () => {
        history.push(ROUTE_LOGIN);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={clsx(loginStyles.loginStyles, isLoading && LOADER_WHITE_OVERLAY)}>
            <div className={loginStyles.uiIconLogo} />
            <br />
            <br />
            <div>
                <form
                    onSubmit={handleSubmit(!history.location.search ? forgotPassword : resetPassword)}
                    noValidate={true}
                >
                    {!history.location.search ? (
                        sucussesMessage ? (
                            <React.Fragment>
                                <p className="--text-center">{sucussesMessage[0]}</p>
                                <br />
                                <ButtonsGroup className="buttons-set">
                                    <PrimaryButton type="button" onClick={returnToLogin} size={"large"}>
                                        Return to Login page
                                    </PrimaryButton>
                                </ButtonsGroup>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p className="--text-center">
                                    Enter your email that you used to register.
                                    <br />
                                    We'll send you an email with your user name and a link to reset you password
                                </p>
                                <br />
                                <EmailField isRequired={true} />
                                <br />
                                <div>
                                    <ButtonsGroup className="buttons-set">
                                        <PrimaryButton type="submit" size={"large"}>
                                            Reset Password
                                        </PrimaryButton>
                                    </ButtonsGroup>
                                    <br />
                                    <div className="--text-center">
                                        <SimpleLink to={ROUTE_LOGIN}>Return to Login page</SimpleLink>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    ) : (
                        <React.Fragment>
                            <p className="--text-center">Enter your new password (Must be at least 5 characters)</p>
                            <br />
                            <PasswordField />
                            <br />
                            <Field
                                type="password"
                                validate={[Validation.required, Validation.password]}
                                name="confirmPassword"
                                needShowEndAdornment={false}
                                label="Confirm Password"
                                component={TextInputComponent}
                            />
                            <br />
                            <ButtonsGroup className="buttons-set">
                                <PrimaryButton type="submit" size={"large"}>
                                    Apply
                                </PrimaryButton>
                            </ButtonsGroup>
                            {!!sucussesMessage && <ErrorNotification type={"success"} message={sucussesMessage} />}
                        </React.Fragment>
                    )}
                </form>
            </div>
            {!!errorMessage && <ErrorNotification message={errorMessage} />}

            {isLoading && <Loader />}
        </div>
    );
};

ForgotPassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(
    compose(
        reduxForm({
            form: "userForgotPasswordForm",
            validate,
        }),
    )(ForgotPassword),
);
