import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    notificationStyles: {
        position: "absolute",
        backgroundColor: theme.palette.general.paleRed,
        color: "#fff",
        fontSize: 11,
        width: 20,
        height: 20,
        borderRadius: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: theme.typography.fontWeightBold,
        top: "-7px",
        right: "-7px",
        pointerEvents: "none",
    },
}));

const CountNotification = (props) => {
    const classes = useStyles();
    const { value } = props;

    return <div className={classes.notificationStyles}>{value}</div>;
};

CountNotification.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withRouter(CountNotification);
