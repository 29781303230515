import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import TextInputComponent from "../core/form/textInputComponent";
import { fetchCompanyTruckTypes } from "../../dataServers/trucks";
import Grid from "@material-ui/core/Grid";
import PillSwitch from "../core/form/pillSwitch";
import { makeStyles } from "@material-ui/core";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorNotification from "../core/notification";
import Loader from "../core/loader";
import { loadCompanyTrucks, resetTrucks } from "../../actions/trucks";
import SuccessNotification from "../core/successNotification";
import { IS_ADMIN_USER } from "../../constants/maps";
import { Validation, VALIDATION_ERRORS } from "../../helpers/validation";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { getCompanies } from "../../dataServers/companies";
import HiddenInput from "../core/form/hiddenInput";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import RegionSelector from "../global/regionSelector";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import { selectMeasureSystem } from "../../selectors";
import { getCapacityAdornment, getVolumeCapacityAdornment } from "../../helpers/measureSystemHelpers";

const useStyles = makeStyles(() => ({
    createTruckFormContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",

        "& .form-label-bold": {
            color: "#393939",
            fontSize: "12px",
            fontWeight: "500",
            marginLeft: "11.45px",
            marginBottom: "7px",
        },
        "& .form-label-medium": {
            marginTop: 5,
            fontWeight: "normal",
            fontSize: "11px",
            marginLeft: "11.45px",
        },
        "& .button-container": {
            display: "flex",
            justifyContent: "space-between",
            marginTop: "27.5px",
        },
    },
    gridXS: {
        width: "100%",
    },
}));

const validate = (values) => {
    const errors = {};

    if (!values.truckType) {
        errors.truckTypeError = VALIDATION_ERRORS.required;
    }

    return errors;
};

function CreateTruckForm(props) {
    const dispatch = useDispatch();
    const {
        form,
        handleSubmit,
        account,
        handleClose,
        onSubmitHandler,
        trucksFilters,
        addTruckCompanyId,
        formValues: { company },
        measureSystem,
        afterSaveHandler,
    } = props;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [isHandledSuccessfully, setIsHandledSuccessfully] = useState(false);
    const [error, setError] = useState(null);
    const [truckTypesList, setTruckTypes] = useState([]);
    const gridXSOneStyle = {
        "spacing-xs-1": classes.gridXS,
    };

    const getFetchCompanyId = () => {
        if (addTruckCompanyId) {
            return addTruckCompanyId;
        }
        if (company) {
            return company.value;
        }

        return account.company.id;
    };

    const loadTruckTypes = () => {
        setLoading(true);

        fetchCompanyTruckTypes(getFetchCompanyId())
            .then((truckTypes) => {
                const truckTypesList = truckTypes.data;
                setTruckTypes(truckTypesList);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onSubmit = (values) => {
        setLoading(true);

        const data = {
            deviceName: values.deviceName,
            truckType: parseInt(values.truckType),
            company: addTruckCompanyId || (values.company ? values.company.value : account.company.id),
            capacity: values.capacity,
            volumeCapacity: values.volumeCapacity || null,
        };

        if (!_.isEmpty(values.region)) data.regionId = values.region.id || values.region.value;

        return onSubmitHandler(data)
            .then((response) => {
                setLoading(false);
                handleClose();
                dispatch(resetTrucks());
                dispatch(loadCompanyTrucks(trucksFilters, false));
                setIsHandledSuccessfully(true);
                afterSaveHandler && afterSaveHandler(response.data);
            })
            .catch((e) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(e));
            });
    };

    const loadCompanies = (inputValue, { params = {}, loadedCount }) => {
        return getCompanies({ keywords: inputValue, ...params }).then((data) => {
            const results = data.data.map((i) => ({
                value: i.id,
                label: i.name,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    const handleTruckTypeChange = (event) => {
        const selectedTypeId = event.target.value;
        const selectedType = truckTypesList.find((type) => type.id === +selectedTypeId);
        const defaultCapacity = selectedType?.defaultCapacity;
        const defaultVolumeCapacity = selectedType?.defaultVolumeCapacity;
        if (defaultCapacity) {
            dispatch(change(form, "capacity", defaultCapacity));
        }
        if (defaultCapacity) {
            dispatch(change(form, "volumeCapacity", defaultVolumeCapacity));
        }
    };

    useEffect(() => {
        if (company && company.value && (IS_ADMIN_USER(account.company.id) || account.isSuperUser)) {
            dispatch(change(form, "region", null));
            dispatch(change(form, "truckType", null));
        }
    }, [company]);

    useEffect(() => {
        loadTruckTypes();
    }, [company]);

    return (
        <div
            className={clsx(isLoading && LOADER_WHITE_OVERLAY)}
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <form noValidate={true} className={classes.createTruckFormContainer} onSubmit={handleSubmit(onSubmit)}>
                <Field
                    type="text"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    name="deviceName"
                    label="Truck Name"
                    className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                    component={TextInputComponent}
                />
                <br />
                {!addTruckCompanyId && (IS_ADMIN_USER(account.company.id) || account.isSuperUser) && (
                    <React.Fragment>
                        <Field
                            name="company"
                            label="Company"
                            loadOptions={loadCompanies}
                            validate={[Validation.required]}
                            placeholder="Select Company"
                            className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                            component={AsyncAutocompleteComponent}
                        />
                        <br />
                    </React.Fragment>
                )}
                <RegionSelector isClearable={true} companyId={company && company.value} />
                <br />
                <React.Fragment>
                    <Field
                        type="number"
                        name="capacity"
                        label="Weight Capacity"
                        validate={[Validation.required, Validation.isNumber]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={TextInputComponent}
                        endAdornment={getCapacityAdornment(measureSystem)}
                    />
                    <br />
                </React.Fragment>
                <React.Fragment>
                    <Field
                        type="number"
                        name="volumeCapacity"
                        label="Volume Capacity"
                        validate={Validation.isNumber}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        component={TextInputComponent}
                        endAdornment={
                            <span style={{ textAlign: "center" }}>{getVolumeCapacityAdornment(measureSystem)}</span>
                        }
                    />
                    <br />
                </React.Fragment>
                <label className="form-label-bold">Truck type</label>
                <Grid container classes={gridXSOneStyle} spacing={1} alignItems="center">
                    {truckTypesList.map((item) => {
                        return (
                            <Grid item key={item.id}>
                                <Field
                                    type="radio"
                                    name="truckType"
                                    value={`${item.id}`}
                                    label={item.name}
                                    className="measure-pill"
                                    component={PillSwitch}
                                    onChange={handleTruckTypeChange}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <label className="form-label-medium">This helps match your truck to the right jobs.</label>
                <Field type="hidden" name="truckTypeError" component={HiddenInput} />
                <br />
                <ButtonsGroup>
                    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </ButtonsGroup>
            </form>
            {isLoading && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {isHandledSuccessfully && (
                <SuccessNotification message="Success" config={{ onClose: () => setIsHandledSuccessfully(false) }} />
            )}
        </div>
    );
}

CreateTruckForm.propTypes = {
    account: PropTypes.object.isRequired,
    trucksFilters: PropTypes.object.isRequired,
    company: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    handleClose: PropTypes.any,
    onlyNameField: PropTypes.bool,
    addTruckCompanyId: PropTypes.number,
    form: PropTypes.string,
    afterSaveHandler: PropTypes.func,
    measureSystem: PropTypes.number,
    formValues: PropTypes.object,
};

export const CREATE_TRUCK_FORM = "createTruckForm";

const Form = reduxForm({
    form: CREATE_TRUCK_FORM,
    validate,
})(CreateTruckForm);

export default withRouter(
    connect((state, props) => {
        return {
            account: state.account,
            initialValues: props.truckForEditMappedData,
            formValues: formValueSelector(CREATE_TRUCK_FORM)(state, "company", "truckType"),
            measureSystem: selectMeasureSystem(state),
        };
    })(Form),
);
