import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Box from "@material-ui/core/Box";

import { DEFAULT_TIME_FORMAT } from "../../constants/global";
import { MAIN_THEME } from "../../styles/globalThemeConfig";

const useStyles = makeStyles((theme) => ({
    uiIconClock: {
        width: theme.icon.width,
        height: theme.icon.height,
        color: theme.palette.secondary.dark,
        marginRight: 20,
    },
    copyScheduleDate: { textAlign: "center" },
    dateSeparator: { margin: "0 4px" },
}));

const StartDate = (props) => {
    const classes = useStyles();
    const {
        component,
        time,
        copySchedule,
        iconMarginRight,
        timezone,
        account,
        showIcon,
        fontSize,
        justifyContent,
        format,
        periodFormat,
        periodTimeFormat,
    } = props;
    const userTimeZone = account.timezone;

    const renderTime = () => {
        if (copySchedule)
            return moment(time)
                .tz(timezone || userTimeZone)
                .format(periodTimeFormat);

        return moment(time)
            .tz(timezone || userTimeZone)
            .format(format);
    };

    const renderPeriod = () => {
        if (!copySchedule) return;
        const startDate = copySchedule && copySchedule.startDate;
        const endDate = copySchedule && copySchedule.endDate;

        return (
            <>
                <div className={classes.copyScheduleDate}>
                    {moment(startDate)
                        .tz(timezone || userTimeZone)
                        .format(periodFormat)}
                </div>
                <div className={classes.dateSeparator}>-</div>
                <div className={classes.copyScheduleDate} style={{ marginRight: 10 }}>
                    {moment(endDate)
                        .tz(timezone || userTimeZone)
                        .format(periodFormat)}
                </div>
            </>
        );
    };

    return (
        <Box
            component={component}
            display="flex"
            alignItems="center"
            justifyContent={justifyContent}
            fontSize={fontSize}
        >
            {showIcon && <AccessTimeIcon className={classes.uiIconClock} style={{ marginRight: iconMarginRight }} />}
            {renderPeriod()}
            {renderTime()}
        </Box>
    );
};

StartDate.propTypes = {
    component: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    copySchedule: PropTypes.object,
    iconMarginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fontSize: PropTypes.number,
    timezone: PropTypes.string,
    justifyContent: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    showIcon: PropTypes.bool.isRequired,
};

StartDate.defaultProps = {
    component: "div",
    justifyContent: "center",
    iconMarginRight: "16px",
    showIcon: true,
    fontSize: MAIN_THEME.typography.fontSize,
    format: DEFAULT_TIME_FORMAT,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
        };
    })(StartDate),
);
