import React, { useState } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import SiteMap from "../sites/siteMap";
import AlertZoneCustomPopUp from "./alertZoneCustomPopUp";
import { Box, Tooltip } from "@material-ui/core";
import { setAlertZoneCoordinates } from "../../actions/sites";

const AddZonePopupForm = (props) => {
    const {
        currentSite,
        setAlertZone,
        setError,
        newZoneData,
        alertZones,
        alertZoneCoordinates,
        setAlertZoneCoordinates,
    } = props;

    const [onAddAlertZone, setOnAddAlertZone] = useState(true);

    const site = _.isEmpty(currentSite)
        ? {
              alertZones,
          }
        : {
              latitude: +currentSite.lat,
              longitude: +currentSite.lng,
              alertZones,
          };

    const cancelAlertZoneCreate = () => {
        setOnAddAlertZone(false);
        setAlertZoneCoordinates(null);
        // To reset markers on the map
        setTimeout(() => setOnAddAlertZone(true), 700);
    };

    const handleAlertZoneCreate = (values) => {
        if (newZoneData && newZoneData.markers.length === 1) {
            if (!values.radius) {
                setError(["Please specify radius or add full Alert Zone Coordinates"]);

                return Promise.resolve();
            }
            // https://github.com/TruckITllc/truckit-frontend/issues/442
        } else if (!alertZoneCoordinates) {
            setError(["For save add full Alert Zone Coordinates"]);

            return Promise.resolve();
        }

        return setAlertZone({
            name: values.name,
            radius: values.radius,
            markers: values.radius ? newZoneData.markers : alertZoneCoordinates,
        }).then(() => {
            setAlertZoneCoordinates(null);
        });
    };

    return (
        <React.Fragment>
            <SiteMap
                colors={[]}
                width="60vw"
                setAlertZoneCoordinates={setAlertZoneCoordinates}
                currentSite={site}
                drawingEnabled={onAddAlertZone}
            />
            <br />
            <br />
            <Box width={"100%"}>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    open={true}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        "You can create a polygon by selecting several" +
                        " points on the map or create a circle by selecting its " +
                        "center and specifying a radius"
                    }
                >
                    <div>
                        <AlertZoneCustomPopUp handleClose={cancelAlertZoneCreate} handleSave={handleAlertZoneCreate} />
                    </div>
                </Tooltip>
            </Box>
        </React.Fragment>
    );
};

AddZonePopupForm.propTypes = {
    account: PropTypes.object.isRequired,
    setAlertZone: PropTypes.func.isRequired,
    setAlertZoneCoordinates: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    newZoneData: PropTypes.object.isRequired,
    alertZones: PropTypes.array.isRequired,
    currentSite: PropTypes.object,
    alertZoneCoordinates: PropTypes.array,
    notificationAlertZoneCoordinates: PropTypes.array,
};

export default withRouter(
    compose(
        reduxForm({
            form: "settingsAddZonePopupFormForm",
            enableReinitialize: true,
        }),
        connect(
            (state) => {
                return {
                    account: state.account,
                    alertZoneCoordinates: state.sites.alertZoneCoordinates,
                    newZoneData: state.sites.newZoneData,
                };
            },
            (dispatch) => ({
                setAlertZoneCoordinates: (payload) => dispatch(setAlertZoneCoordinates(payload)),
            }),
        ),
    )(AddZonePopupForm),
);
