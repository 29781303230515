import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
    tabs: {
        flexGrow: 1,
        height: "100%",
    },
    tabHeader: {
        height: 60,
        padding: 0,
    },
    tabIndicator: {
        height: 4,
        width: "98%",
    },
    tabsHeader: {
        padding: " 0 16px",

        "& button": {
            width: "50%",
        },
    },
}));

export const FIRST_TAB_INDEX = 0;

function TabsCustom(props) {
    const classes = useStyles();
    const { tabsHeaders, tabsPanels, onChangeTab, initialTab } = props;
    const [value, setValue] = React.useState(initialTab || 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChangeTab && onChangeTab(newValue);
    };

    useEffect(() => {
        // Prevent case when selected header is not presented in tabs list
        if (value >= tabsHeaders.length) {
            setValue(FIRST_TAB_INDEX);
            onChangeTab && onChangeTab(FIRST_TAB_INDEX);
        }
    }, [tabsHeaders]);

    return (
        <div className={clsx(classes.tabs)}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor={"primary"}
                className={classes.tabsHeader}
                TabIndicatorProps={{ className: classes.tabIndicator }}
            >
                {tabsHeaders &&
                    tabsHeaders.map((header, index) => {
                        return (
                            header && (
                                <Tab label={header.label} {...a11yProps(1)} className={classes.tabHeader} key={index} />
                            )
                        );
                    })}
            </Tabs>
            {tabsPanels &&
                tabsPanels.map((panel, index) => {
                    return (
                        <TabPanel value={value} key={index} index={index} className="tab-content">
                            {panel.content}
                        </TabPanel>
                    );
                })}
        </div>
    );
}

TabsCustom.propTypes = {
    tabsPanels: PropTypes.array,
    tabsHeaders: PropTypes.array.isRequired,
    onChangeTab: PropTypes.func,
    initialTab: PropTypes.number,
};

export default TabsCustom;
