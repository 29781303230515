import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import SearchAndFilters, {
    FILTERS_COMPANIES_IDS_ID,
    FILTERS_LIST_TRUCK_AVAILABILITY,
    FILTERS_LIST_TYPE_ID,
    FILTERS_MY_FLEET_TRUCK_TYPE_ID,
    FILTERS_REGIONS_ID,
} from "../global/searchAndFilters";
import {
    FLEET_TYPE_GRANTED_ONLY,
    FLEET_TYPE_MY_FLEET_ONLY,
    FLEET_TYPE_SHIFTABLE_ONLY,
    IDLE_STATUS_WITH_TRUCKS,
    IDLE_STATUS_WITHOUT_TRUCKS,
    IS_ADMIN_USER,
    TRUCK_ACTIVE_AVAILABILITY_FILTERS_ID,
    TRUCK_INACTIVE_AVAILABILITY_FILTERS_ID,
} from "../../constants/maps";
import { ENTERPRISE } from "../../constants/accounts";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { getUserFiltersTruckers } from "../../dataServers/user";
import { SimpleLink } from "../core/link";
import { ROUTE_MY_COMPANY } from "../../routes/globalRoutes";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { updateDefaultFilterId } from "../../actions/haulersSettings";
import { selectCompanyRegions } from "../../selectors/index";
import { fetchCompanyTruckTypes } from "../../dataServers/trucks";

export const idleStatusTypes = [
    {
        label: "Trucks with job",
        id: IDLE_STATUS_WITH_TRUCKS,
    },
    {
        label: "Trucks without job",
        id: IDLE_STATUS_WITHOUT_TRUCKS,
    },
];
export const FILTERS_IDLE_TYPE_ID = "idleStatus"; // https://github.com/TruckITllc/truckit-frontend/issues/961
export const idleStatus = {
    title: "Assigned Trucks",
    filterKey: FILTERS_IDLE_TYPE_ID,
    filters: idleStatusTypes,
    type: "radio",
};

export const activityOptions = [
    {
        label: "Active",
        id: TRUCK_ACTIVE_AVAILABILITY_FILTERS_ID,
    },
    {
        label: "Inactive",
        id: TRUCK_INACTIVE_AVAILABILITY_FILTERS_ID,
    },
];

const useStyles = makeStyles(() => ({
    editLink: {
        marginLeft: 10,
    },
}));

const FleetFilters = (props) => {
    const {
        account,
        updateListByFilters,
        isHaulers,
        initialFilters,
        form,
        placeholder,
        history,
        defaultFilterEnabled,
        isHaulersFilters,
        listIsEmpty,
        regions,
        rememberFilters,
        getFilters,
        withoutSearchRules,
        withoutEmptySearch,
        timeout,
        hiddenFilter
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        filtersList: [],
        isLoading: false,
    });
    const [defaultFilter, setDefaultFilters] = useState();
    const [truckTypeFilters, setTruckTypeFilters] = useState(undefined);
    const { filtersList, isLoading } = state;
    const navigateToHaulersFilters = () => {
        history.push({ pathname: ROUTE_MY_COMPANY.SETTINGS_HAULERS, goToTab: 1 });
    };
    const isResetToDefault = !_.isEmpty(account.companyRegions) && !_.isEmpty(account.region);

    const listTypes = [
        {
            label: "Granted",
            id: FLEET_TYPE_GRANTED_ONLY,
        },
        {
            label: "My Fleet",
            id: FLEET_TYPE_MY_FLEET_ONLY,
        },
    ];

    if (account.companyProfile.truckShiftAllowed && !IS_ADMIN_USER(account.company.id)) {
        listTypes.push({
            label: "3rd Party",
            id: FLEET_TYPE_SHIFTABLE_ONLY,
        });
    }

    const commonFleetFilters = [
        !hiddenFilter[FILTERS_IDLE_TYPE_ID] &&idleStatus,
        {
            title: "Granted/My Fleet",
            filterKey: FILTERS_LIST_TYPE_ID,
            filters: listTypes,
            type: "radio",
        },
        !hiddenFilter[FILTERS_LIST_TRUCK_AVAILABILITY] && {
            title: "Trucks Availability",
            filterKey: FILTERS_LIST_TRUCK_AVAILABILITY,
            filters: activityOptions,
        },
    ];

    useEffect(() => {
        if (!isHaulers && truckTypeFilters) {
            setState({
                ...state,
                isLoading: true,
                filtersList: [...commonFleetFilters],
            });
            let newState = {
                isLoading: false,
                filtersList: [...commonFleetFilters],
            };

            let updateFiltersList = [...commonFleetFilters];

            if (regions && regions.length) {
                updateFiltersList = [
                    ...updateFiltersList,
                    {
                        title: "Regions",
                        filterKey: FILTERS_REGIONS_ID,
                        filters: regions.map((item) => ({
                            label: item.regionName,
                            id: item.id,
                        })),
                    },
                ];
            }
            if (truckTypeFilters) {
                updateFiltersList = [truckTypeFilters, ...updateFiltersList];
            }
            newState = {
                ...newState,
                filtersList: updateFiltersList,
            };
            setState({
                ...state,
                ...newState,
            });
        }
    }, [regions, truckTypeFilters]);

    useEffect(() => {
        if (isHaulers) {
            setState({
                ...state,
                isLoading: true,
            });

            getUserFiltersTruckers()
                .then(({ data: filters }) => {
                    const filtersFormatted = filters
                        .map((filter) => ({
                            label: filter.isDefault ? "Preferred Filter" : filter.name,
                            id: filter.id,
                            isDefault: filter.isDefault,
                            isPublic: filter.isPublic,
                            companies: filter.companies,
                        }))
                        .filter((filter) => !filter.isPublic);
                    const defaultFilter = filtersFormatted.find((i) => i.isDefault);

                    getFilters && getFilters(filtersFormatted);

                    dispatch(updateDefaultFilterId(defaultFilter.id));

                    if (defaultFilter && defaultFilterEnabled && account.company.accountType === ENTERPRISE) {
                        setDefaultFilters({
                            [FILTERS_COMPANIES_IDS_ID]: { [defaultFilter.id]: true },
                        });
                    }

                    setState({
                        ...state,
                        isLoading: false,
                        filtersList: [
                            ...filtersList,
                            {
                                title: "Hauler Filters",
                                additionalLink: defaultFilterEnabled ? (
                                    <SimpleLink
                                        className={clsx(classes.editLink, "clear-all-link")}
                                        onClick={navigateToHaulersFilters}
                                    >
                                        Edit
                                    </SimpleLink>
                                ) : undefined,
                                filterKey: FILTERS_COMPANIES_IDS_ID,
                                filters: filtersFormatted,
                            },
                        ],
                    });
                })
                .catch(() => {
                    setState({
                        ...state,
                        isLoading: false,
                    });
                });
        } else {
            if (account.company) {
                setState({
                    ...state,
                    isLoading: true,
                });
                fetchCompanyTruckTypes(account.company.id)
                    .then((response) => {
                        const truckTypesList = response.data;
                        const truckTypeFilterObject = {
                            title: "Trucks types",
                            filterKey: FILTERS_MY_FLEET_TRUCK_TYPE_ID,
                            filters: truckTypesList.map((type) => {
                                return {
                                    label: type.name,
                                    id: type.id,
                                };
                            }),
                        };

                        setTruckTypeFilters(truckTypeFilterObject);
                    })
                    .finally(() => {
                        setState({
                            ...state,
                            isLoading: false,
                        });
                    });
            }
        }
    }, []);

    return (
        <SearchAndFilters
            key={filtersList.length}
            rememberFilters={rememberFilters}
            isResetToDefault={defaultFilterEnabled && (isResetToDefault || !_.isEmpty(defaultFilter))}
            initialFilters={initialFilters}
            defaultFilter={defaultFilter}
            showDefaultFilterTip={defaultFilter && listIsEmpty}
            className="fleet-filters"
            filtersLoading={isLoading}
            placeholder={placeholder}
            isHaulersFilters={isHaulersFilters}
            form={form}
            updateList={updateListByFilters}
            filtersList={filtersList}
            chooseOneFilterOptionList={[FILTERS_LIST_TRUCK_AVAILABILITY]}
            withoutEmptySearch={withoutEmptySearch}
            withoutSearchRules={withoutSearchRules}
            timeout={timeout}
        />
    );
};

FleetFilters.propTypes = {
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    initialFilters: PropTypes.object,
    placeholder: PropTypes.string,
    updateListByFilters: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    isHaulers: PropTypes.bool,
    listIsEmpty: PropTypes.bool,
    isHaulersFilters: PropTypes.bool,
    defaultFilterEnabled: PropTypes.bool,
    rememberFilters: PropTypes.bool,
    getFilters: PropTypes.func,
    withoutEmptySearch: PropTypes.bool,
    withoutSearchRules: PropTypes.bool,
    timeout: PropTypes.number,
};

FleetFilters.defaultProps = {
    defaultFilterEnabled: true,
    rememberFilters: true,
    hiddenFilter: {}
};

export default withRouter(
    compose(
        reduxForm({}),
        connect((state) => {
            return {
                account: state.account,
                regions: selectCompanyRegions(state),
            };
        }),
    )(FleetFilters),
);
