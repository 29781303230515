import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadTicketsReport } from "../../../dataServers/tickets";
import { isOSWindows } from "../../../helpers/global";
import { formValueSelector } from "redux-form";
import { END_DATE_NAME, START_DATE_NAME } from "../../global/datesRange";
import moment from "moment";
import _ from "lodash";
import { SERVER_DATE_FORMAT } from "../../../constants/global";
import {
    REPORT_TYPE_CSV,
    REPORT_TYPE_EXCEL,
    REPORT_TYPE_KEY,
    REPORT_TYPE_QUICK_BOOK_CSV,
    REPORT_TYPE_QUICK_BOOK_IIF,
    REPORT_TYPE_PAY_ADVICE,
    REPORT_TYPE_TITLE,
    REPORT_TYPES,
} from "../../../constants/maps.js";
import Filters from "../../global/filters";
import ErrorNotification from "../../core/notification";
import { PROCESS_SERVER_ERROR } from "../../../constants/global.js";
import { generatePredictiveKeywordParam } from "../../../helpers/predictiveSearchHelpers";

const TicketsReportLink = (props) => {
    const { ticketsFilters, showLink, ticketsCount, formValues, isInspector } = props;

    const [error, setError] = useState(null);

    const handleDownloadTicketsReport = (type) => {
        let params = {
            ...ticketsFilters,
            [START_DATE_NAME]: moment(formValues[START_DATE_NAME]).format(SERVER_DATE_FORMAT),
            [END_DATE_NAME]: moment(formValues[END_DATE_NAME]).format(SERVER_DATE_FORMAT),
            reportType: type,
        };
        if (_.isArray(params.keywords)) {
            params.keywords = generatePredictiveKeywordParam(params.keywords);
        }

        return downloadTicketsReport(params);
    };

    const [filterList, setFilterList] = useState([
        {
            title: REPORT_TYPE_TITLE,
            filterKey: REPORT_TYPE_KEY,
            filters: [
                {
                    label: isOSWindows() ? REPORT_TYPES[REPORT_TYPE_EXCEL] : REPORT_TYPES[REPORT_TYPE_CSV],
                    id: isOSWindows() ? REPORT_TYPE_EXCEL : REPORT_TYPE_CSV,
                },
                {
                    label: REPORT_TYPES[REPORT_TYPE_QUICK_BOOK_CSV],
                    id: REPORT_TYPE_QUICK_BOOK_CSV,
                },
                {
                    label: REPORT_TYPES[REPORT_TYPE_QUICK_BOOK_IIF],
                    id: REPORT_TYPE_QUICK_BOOK_IIF,
                },
                {
                    label: REPORT_TYPES[REPORT_TYPE_PAY_ADVICE],
                    id: REPORT_TYPE_PAY_ADVICE,
                },
            ],
        },
    ]);

    useEffect(() => {
        if (isInspector) {
            setFilterList([
                {
                    title: REPORT_TYPE_TITLE,
                    filterKey: REPORT_TYPE_KEY,
                    filters: [
                        {
                            label: isOSWindows() ? REPORT_TYPES[REPORT_TYPE_EXCEL] : REPORT_TYPES[REPORT_TYPE_CSV],
                            id: isOSWindows() ? REPORT_TYPE_EXCEL : REPORT_TYPE_CSV,
                        },
                    ],
                },
            ]);
        }
    }, [isInspector]);

    const applyFilter = (values, closeModal) => {
        values.reportType &&
            handleDownloadTicketsReport(values.reportType).catch(async (e) => {
                try {
                    const error = JSON.parse(await e.text());
                    setError(PROCESS_SERVER_ERROR(error));
                } catch (error) {
                    const textError = await e.text();
                    setError(PROCESS_SERVER_ERROR(textError));
                }
            });
        closeModal();
    };

    const renderCustomIcon = (onClick) => (
        <GetAppIcon className="action-icons" title="Download Tickets Report" onClick={onClick} />
    );

    return (
        <React.Fragment>
            {showLink && !!ticketsCount && (
                <div title="Download Tickets Report">
                    <Filters
                        icon={GetAppIcon}
                        filters={filterList}
                        renderCustomIcon={renderCustomIcon}
                        applyFilter={applyFilter}
                    />
                </div>
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </React.Fragment>
    );
};

TicketsReportLink.propTypes = {
    history: PropTypes.object.isRequired,
    ticketsCount: PropTypes.number.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    ticketsFilters: PropTypes.object.isRequired,
    showLink: PropTypes.bool.isRequired,
    isInspector: PropTypes.bool,
};

TicketsReportLink.defaultProps = {
    ticketsFilters: {},
    showLink: true,
};

export default withRouter(
    connect((state, props) => {
        const formValuesSelector = formValueSelector(props.form);

        return {
            account: state.account,
            ticketsCount: state.tickets.ticketsCount,
            formValues: formValuesSelector(state, START_DATE_NAME, END_DATE_NAME),
        };
    })(TicketsReportLink),
);
