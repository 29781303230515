import React from "react";
import { useSelector } from "react-redux";

import { selectCanEditSettings } from "../selectors";
import NonAdminSettings from "./NonAdminSettings";
import Settings from "./Settings";

const ProtectedSettings = () => {
    const canEditSettings = useSelector(selectCanEditSettings);

    if (canEditSettings) {
        return <Settings />;
    }

    return <NonAdminSettings />;
};

export default ProtectedSettings;
