import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { ButtonsGroup } from "../components/core/buttons/buttonsGroup";
import { PrimaryButton } from "../components/core/buttons/primaryButton";
import { ROUTE_LOGIN } from "../routes/globalRoutes";
import Box from "@material-ui/core/Box";
import { LOGIN_STYLES } from "../styles/reusableStyles";

const ConfirmEmail = (props) => {
    const classes = LOGIN_STYLES();
    const { history } = props;
    const [timeLeft, setTimeLeft] = useState(5);
    const retunToLogin = () => {
        history.push(ROUTE_LOGIN);
    };

    useEffect(() => {
        if (!timeLeft) {
            history.push(ROUTE_LOGIN);
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <div>
            <div className={clsx(classes.loginStyles)}>
                <br />
                <br />
                <div className={classes.uiIconLogo} />
                <br />
                <br />
                <Box className="--text-center">
                    <h1>Congratulations!</h1>
                    <br />
                    Your e-mail confirmed. You will be redirected to login page in {timeLeft} seconds
                </Box>
                <br />
                <ButtonsGroup className="buttons-set">
                    <PrimaryButton type="button" onClick={retunToLogin} size={"large"}>
                        Return to Login page
                    </PrimaryButton>
                </ButtonsGroup>
            </div>
        </div>
    );
};

ConfirmEmail.propTypes = {
    history: PropTypes.object.isRequired,
};

export default withRouter(ConfirmEmail);
