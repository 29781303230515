import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import ConfirmationModalWrap from "./confirmationModalWrap";

const useStyles = makeStyles((theme) => ({
    modalWrapper: {
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        overflowY: "scroll",
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        padding: "16px 16px 26px",
        outline: 0,
        borderRadius: theme.shape.borderRadius * 2,
        minWidth: 332,
        textAlign: "left",

        "& .title": {
            textTransform: "uppercase",
            fontSize: 21,
            marginBottom: 20,
            textAlign: "center",
        },
    },
    closeIcon: {
        cursor: "pointer",
    },
}));

export const AppModalWithoutConfirmation = (props) => {
    const classes = useStyles();
    const { children, closeModal, showCloseButton, isOpen: open, modalStyles } = props;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disableRestoreFocus={true}
            className={classes.modalWrapper}
            onBackdropClick={closeModal}
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box className={classes.modalContent} style={modalStyles}>
                    {showCloseButton && (
                        <Box component="div" display="flex" justifyContent={"flex-end"} mb={2}>
                            <CloseIcon onClick={closeModal} className={clsx(classes.closeIcon)} />
                        </Box>
                    )}
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
};

AppModalWithoutConfirmation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCloseButton: PropTypes.bool.isRequired,
    children: PropTypes.any,
    modalStyles: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    form: PropTypes.string,
};

AppModalWithoutConfirmation.defaultProps = {
    isOpen: false,
    modalStyles: {},
    showCloseButton: true,
};

const AppModal = (props) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const {
        children,
        closeModal,
        showCloseButton,
        isOpen: open,
        modalStyles,
        form,
        customDirtyProperty,
        handleFormWrapperClick,
    } = props;
    const closeLocalModal = () => {
        setShowConfirmModal(true);
    };
    const closeChildModal = () => {
        closeModal();
        setShowConfirmModal(false);
    };

    return (
        <ConfirmationModalWrap
            form={form}
            customDirtyProperty={customDirtyProperty}
            closeModal={() => setShowConfirmModal(false)}
            closeChildModal={closeChildModal}
            closeChildModalRequest={showConfirmModal}
            handleFormWrapperClick={handleFormWrapperClick}
        >
            <AppModalWithoutConfirmation
                modalStyles={modalStyles}
                closeModal={closeLocalModal}
                isOpen={open}
                children={children}
                showCloseButton={showCloseButton}
            />
        </ConfirmationModalWrap>
    );
};

AppModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCloseButton: PropTypes.bool.isRequired,
    children: PropTypes.any,
    modalStyles: PropTypes.object.isRequired,
    closeModal: PropTypes.func,
    handleFormWrapperClick: PropTypes.func,
    form: PropTypes.string,
    customDirtyProperty: PropTypes.bool,
};

AppModal.defaultProps = {
    isOpen: false,
    modalStyles: {},
    showCloseButton: true,
};

export default AppModal;
