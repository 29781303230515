import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { formValueSelector, reduxForm } from "redux-form";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import AppModal from "../../core/modal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
    logoutUser,
    resetReduxState,
    updateUserCompanyType,
    updateUserCustomerStripeId,
} from "../../../actions/globalActions";
import ErrorNotification from "../../core/notification";
import SuccessNotification from "../../core/successNotification";
import Loader from "../../core/loader";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import { createStripeCustomer, createStripeSubscription } from "../../../dataServers/user";
import { ACCOUNT_DATA_STORAGE_KEY, LOCAL_STORAGE_FILTERS_ID } from "../../../helpers/api";
import browserHistory from "../../../utils/browserHistory";
import { ROUTE_LOGIN } from "../../../routes/globalRoutes";
import * as NotificationsActions from "../../../actions/notifications";
import * as WebSocketActions from "../../../actions/websocket";
import {
    COMPANY_TYPE_CONTRACTOR,
    COMPANY_TYPE_LIMITED_CONTRACTOR,
    COMPANY_TYPE_LIMITED_TRUCKER,
    COMPANY_TYPE_TRUCKER,
} from "../../../constants/maps";
import { selectStripeId } from "../../../selectors";

export const getUpdatedLimitedCompanyType = (companyType) => {
    if (companyType === COMPANY_TYPE_LIMITED_TRUCKER) {
        return COMPANY_TYPE_TRUCKER;
    } else if (companyType === COMPANY_TYPE_LIMITED_CONTRACTOR) {
        return COMPANY_TYPE_CONTRACTOR;
    }
};

const useStyles = makeStyles(() => ({
    creditCardWrapper: {
        display: "flex",
        alignItems: "center",
        border: "1px solid red",
        height: "50px",
        borderColor: "#D68530",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px",

        "& .StripeElement": {
            width: "100%",
            padding: "15px",
        },
    },
}));

const CreditCardDialog = (props) => {
    const { handleSubmit, handleClose, creditCardDialogOpen } = props;
    const currentUserEmail = useSelector((state) => state.account.email);
    const companyType = useSelector((state) => state.account.company.type);
    const stripeId = useSelector(selectStripeId);
    const classes = useStyles();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [successMessage, setSuccessMessage] = useState(false);
    const [amountToPayNow, setAmountToPayNow] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [customerCreated, setCustomerCreated] = useState(false);

    const updateAccountCompanyInLocalStorage = (field, value) => {
        const currentData = JSON.parse(localStorage.getItem(ACCOUNT_DATA_STORAGE_KEY));
        const newData = {
            ...currentData,
            company: {
                ...currentData.company,
                [field]: value,
            },
        };
        localStorage.setItem(ACCOUNT_DATA_STORAGE_KEY, JSON.stringify(newData));
    };

    const closeModal = () => {
        if (!isLoading) {
            setErrorMessage("To use app you must confirm your credit card.");
            handleClose && handleClose();
            localStorage.removeItem(ACCOUNT_DATA_STORAGE_KEY);
            localStorage.removeItem(LOCAL_STORAGE_FILTERS_ID);
            browserHistory.push(ROUTE_LOGIN);
            dispatch(logoutUser());
            dispatch(resetReduxState());
            dispatch(NotificationsActions.notificationsClear());
            WebSocketActions.close();
        }
    };

    const onFormSubmit = async () => {
        setLoading(true);
        const cardElement = elements.getElement(CardElement);

        const confirmedCardPaymentRequest = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
        const setupIntent = confirmedCardPaymentRequest.setupIntent;
        const error = confirmedCardPaymentRequest.error;
        if (setupIntent) {
            if (setupIntent.status === "succeeded") {
                setSuccessMessage("Your card confirmed successfully!");
                updateAccountCompanyInLocalStorage("type", getUpdatedLimitedCompanyType(companyType));
                dispatch(updateUserCompanyType());
            }
        } else if (error) {
            setErrorMessage(error.message);
            closeModal();
        } else {
            setErrorMessage("Something went wrong.");
            closeModal();
        }
        setLoading(false);
    };

    const handleCardElementChange = (element) => {
        if (!element.empty && element.complete) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    };

    useEffect(() => {
        if (!stripeId) {
            setLoading(true);
            createStripeCustomer(currentUserEmail)
                .then(({ data }) => {
                    const customerId = data.customerId;
                    updateAccountCompanyInLocalStorage("stripeId", customerId);
                    dispatch(updateUserCustomerStripeId(customerId));
                })
                .catch((e) => {
                    setErrorMessage([PROCESS_SERVER_ERROR(e)]);
                })
                .finally(() => {
                    setLoading(false);
                    setCustomerCreated(true);
                });
        } else {
            setCustomerCreated(true);
        }
    }, []);

    useEffect(() => {
        if (customerCreated) {
            setLoading(true);

            createStripeSubscription(companyType)
                .then(({ data }) => {
                    const { amountToPayNow, clientSecret } = data;

                    setAmountToPayNow(amountToPayNow);
                    setClientSecret(clientSecret);
                })
                .catch((e) => {
                    setErrorMessage([PROCESS_SERVER_ERROR(e)]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [customerCreated]);

    return (
        <AppModal
            isOpen={creditCardDialogOpen}
            form={CREDIT_CARD_DIALOG_FORM}
            modalStyles={{ width: 800 }}
            closeModal={closeModal}
        >
            <form onSubmit={handleSubmit(onFormSubmit)} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                {isLoading && <Loader />}
                <h2 className="title">Please confirm credit card to continue</h2>
                {!_.isNull(amountToPayNow) && (
                    <h3 className="--text-center">You will be charged ${amountToPayNow / 100} for the current month</h3>
                )}
                <br />
                <div className={clsx(classes.creditCardWrapper)}>
                    <CardElement onChange={handleCardElementChange} />
                </div>
                <br />
                <ButtonsGroup>
                    <SecondaryButton type="button" onClick={closeModal}>
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={isSubmitDisabled}>
                        Approve
                    </PrimaryButton>
                </ButtonsGroup>
            </form>
            {errorMessage && (
                <ErrorNotification message={errorMessage} config={{ onClose: () => setErrorMessage(null) }} />
            )}
            {successMessage && (
                <SuccessNotification message={successMessage} config={{ onClose: () => setSuccessMessage(null) }} />
            )}
        </AppModal>
    );
};

CreditCardDialog.propTypes = {
    isAccepted: PropTypes.bool.isRequired,
    creditCardDialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export const CREDIT_CARD_DIALOG_FORM = "creditCardDialogForm";

export default compose(
    reduxForm({
        form: CREDIT_CARD_DIALOG_FORM,
    }),
    connect((state, props) => {
        const formSelector = formValueSelector(CREDIT_CARD_DIALOG_FORM);

        // return {
        //     isAccepted: formSelector(state)
        // };
    }),
)(CreditCardDialog);
