import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SettingsIcon from "@material-ui/icons/Settings";
import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import HistoryIcon from "@material-ui/icons/History";
import ConfirmationModal from "../core/confirmationModal";

import { Divider } from "../core/divider";
import { SecondaryButton } from "../core";
import CustomSwitch from "../core/form/customSwitch";
import SwitchSelector from "../core/SwitchSelector";
import JobContactSelector, { JOB_CONTACT_NAME } from "../global/jobContactSelector";
import ProjectLineItemsList from "./projectLineItemsList";
import AppModal from "../core/modal";
import CustomFields from "../customFields/CustomFields";
import PoLineItemForm, { CREATE_POLI_FORM } from "../poLineItems/poLineItemForm";
import ProjectForm, { GET_INSPECTOR_DATA, PROJECT_FORM } from "./projectForm";
import {
    archiveProject,
    getProjectAllowedCompanies,
    unArchiveProject,
    updateProject,
} from "../../dataServers/projects";
import {
    IS_ADMIN_USER,
    PO_TYPE_TIMBER,
    PROJECT_STATUS_ACTIVE,
    PROJECT_STATUS_ARCHIVED,
    PROJECT_STATUSES,
} from "../../constants/maps";
import { getAdditionalName, timberAdditionalFields } from "../../helpers/projects";
import { Validation } from "../../helpers/validation";
import * as projectActions from "../../actions/projects";
import * as poLineItemsActions from "../../actions/poLineItems";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import ErrorNotification from "../core/notification";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AllowedCompaniesForm, { ALLOWED_COMPANIES_FORM } from "./allowedCompaniesForm";
import TermsSelector from "../global/termsSelector";
import PavertrackersSelector, {
    IS_CAN_SHOW_PAVERTRAKERS_SELECTOR,
    PAVERTRACKERS_NAME,
} from "../global/pavertrackersSelector";
import CustomerSelector, { CUSTOMER_FIELD_NAME } from "../companies/customerSelector";
import { CREATE_NEW_OPTION_ID } from "../core/form/asyncAutocompleteComponent";
import RegionSelector from "../global/regionSelector";
import TabsCustom from "../core/tabsCustom";
import { getIfIsRegionRequired, selectIfIsCanAddPayloads } from "../../selectors";
import {
    ADD_PROJECT_NETSUITE_INTEGRATION_WARNING_TEXT,
    CHANGE_CUSTOMER_TYPE_WARNING,
    CHANGE_RESTRICTED_CUSTOMER_TYPE_WARNING,
    ONE_RECIPIENT_REQUIRED_ERROR_MESSAGE,
} from "./constants/strings";
import ProjectJobsHistory from "./ProjectJobsHistrory/ProjectJobsHistory";
import { ControlledAppTabs } from "../core/controlledAppTabs";
import { selectProjectSelectedTab } from "../../selectors/projectsSelectors";
import RestrictedCustomerSelector from "../companies/restrictedCustomerSelector";
import { CUSTOMER_TYPES, CUSTOMER_VALUE, RESTRICTED_CUSTOMER_VALUE } from "./constants";
import ProjectNotificationsRecipients from "./projectNotificationsRecipients";
import { selectEnableDropOffNotifications } from "../../selectors/user";
import { generateUserSelectOptions } from "../../constants/users";

const useStyles = makeStyles((theme) => ({
    projectInfo: {
        padding: "10px 20px 20px 20px",
        "& h3": {
            color: theme.palette.secondary.dark,
        },
        "& .text-offset": {
            paddingLeft: 36,
            margin: "5px 0 15px",
        },
        "& .button-offset": {
            paddingRight: 30,
        },
        "& .customer-section": {
            margin: "5px 0 15px",

            "& svg": {
                top: 0,
            },
        },
        "& .contact-section, & .terms-section, & .regions-section, & .notifications-recipients": {
            margin: "25px 0 15px",

            "& svg": {
                top: 0,
            },
        },
        "& .name": {
            width: "calc(100% - 1px)",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    },
    uiIcon: {
        width: 25,
        height: 25,
        position: "relative",
        top: 8,
        marginRight: 11,
    },
    uiIconGrey: {
        color: theme.palette.secondary.dark,
    },
    projectSwitches: {
        marginLeft: 36,
        paddingTop: 15,
        "& .divider": {
            width: 2,
            height: 31,
            margin: "0 7px 0",
        },
    },
    addPayload: { marginLeft: 15 },
}));

function ProjectDetailsForm(props) {
    const {
        account,
        currentProject,
        getProjects,
        resetProjects,
        setCurrentProject,
        updateProjects,
        form,
        getPOLineItems,
        resetPOLineItems,
        isRegionRequired,
        canAddPayloads,
        loading,
        projectSelectedTab,
        updateProjectSelectedTab,
        customerType,
        destroy,
        restrictedCustomer,
        jobContact,
        customer,
        change,
        enableDropOffNotifications,
    } = props;
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [isLoading, setLoading] = useState(false);
    const [showCreatePoliModal, setShowCreatePoliModal] = useState(false);
    const [showEditProjectModal, setShowEditProjectModal] = useState(false);
    const [showGrandAccessModal, setShowGrandAccessModal] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const [allowedCompanies, setAllowedCompanies] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const archived = !_.isEmpty(currentProject) && currentProject.status.id === PROJECT_STATUS_ARCHIVED;
    const notArchived = !_.isEmpty(currentProject) && currentProject.status.id === PROJECT_STATUS_ACTIVE;
    const disabled = !_.isEmpty(currentProject) && (currentProject.grantedAccess || archived);

    const isArchiveTab = activeTab === 1;

    const isRestrictedCustomerType = customerType === RESTRICTED_CUSTOMER_VALUE;

    const getMorePOLineItems = (concat) => getPOLineItems(currentProject.id, concat, activeTab);

    useEffect(() => {
        !_.isEmpty(currentProject) && getPOLineItems(currentProject.id, false, activeTab);

        return () => {
            resetPOLineItems();
            destroy();
        };
    }, []);

    const onChangeTab = (activeTab) => {
        setActiveTab(activeTab);
        if (!_.isEmpty(currentProject)) {
            getPOLineItems(currentProject.id, false, activeTab);
        }
    };

    const handleProjectUpdate = (data) => {
        setLoading(true);

        return updateProject(currentProject.id, data)
            .then((data) => {
                const result = data.data;

                resetProjects();
                setShowEditProjectModal(false);
                setCurrentProject(result);

                if (result) {
                    if (result.customer) {
                        change("customer", {
                            value: result.customer.id,
                            label: result.customer.name,
                        });
                    }
                    if (result.region) {
                        change("region", {
                            value: result.region.id,
                            label: result.region.regionName,
                        });
                    }
                }

                setLoading(false);

                return getProjects();
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };
    const updateFieldByName = (value, name) => {
        setLoading(true);
        const requestData = {
            [name]: name === PAVERTRACKERS_NAME ? (value ? value.map((i) => i.label) : []) : value,
            name: currentProject.name,
        };
        if (name === "customerId") {
            requestData.jobContact = null;
        }
        if (name === "restrictedCustomerId") {
            requestData.jobContact = null;
            requestData.customerId = null;
        }

        return updateProject(currentProject.id, requestData)
            .then((response) => {
                const data = response.data;
                setLoading(false);
                updateProjects(data);
                setCurrentProject(data);
            })
            .catch((error) => {
                setLoading(false);
                if (error?.message?.dropOffNotificationsRecipients) {
                    return setError([ONE_RECIPIENT_REQUIRED_ERROR_MESSAGE]);
                }
                setError(PROCESS_SERVER_ERROR(error));
            });
    };
    const setProjectStatus = () => {
        const request = !archived ? archiveProject(currentProject.id) : unArchiveProject(currentProject.id);

        setLoading(true);

        request
            .then(({ data }) => {
                setLoading(false);
                setCurrentProject(data);
                updateProjects(data);
                setMessage(`Project is ${PROJECT_STATUSES[data.status.id]}`);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const openGrandAccessModal = () => {
        setLoading(true);

        getProjectAllowedCompanies(currentProject.id)
            .then(({ data }) => {
                if (_.isEmpty(data.allowedCompanies)) {
                    setAllowedCompanies([]);
                } else {
                    setAllowedCompanies(data.allowedCompanies.map((i) => ({ value: i.id, label: i.name })));
                }
                setShowGrandAccessModal(true);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const createNewCompanyHandler = (data) => {
        change(CUSTOMER_FIELD_NAME, { value: data.id, label: data.name });
        updateFieldByName(data.id, "customerId");
    };

    const onCustomerFieldChange = (value) => {
        change(JOB_CONTACT_NAME, null);

        return updateFieldByName(value ? value.value : null, "customerId");
    };

    const onRestrictedCustomerFieldChange = (value) => {
        change(JOB_CONTACT_NAME, null);

        return updateFieldByName(value ? value.value : null, "restrictedCustomerId");
    };

    const onChangeCustomerType = (e) => {
        const value = e.target.value;
        if (value === CUSTOMER_VALUE && restrictedCustomer) {
            setConfirmationModalMessage(CHANGE_RESTRICTED_CUSTOMER_TYPE_WARNING);

            return;
        }

        if (value === RESTRICTED_CUSTOMER_VALUE && (customer || jobContact)) {
            setConfirmationModalMessage(CHANGE_CUSTOMER_TYPE_WARNING);

            return;
        }

        change("customerType", value);
    };

    const onConfirmChangeCustomerType = () => {
        setLoading(true);

        change(JOB_CONTACT_NAME, null);
        setConfirmationModalMessage("");

        return updateProject(currentProject.id, {
            customerId: null,
            restrictedCustomerId: null,
            jobContactNew: null,
            name: currentProject.name,
        })
            .then((response) => {
                const data = response.data;
                setLoading(false);
                updateProjects(data);
                setCurrentProject(data);
                if (customerType === CUSTOMER_VALUE) {
                    setTimeout(() => change("customerType", RESTRICTED_CUSTOMER_VALUE));
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const configForRegionSelector = (owner) => {
        const props = {};
        if (!IS_ADMIN_USER(account.company.id)) {
            if (isRegionRequired) {
                props.validate = [Validation.required];
            }
            props.isClearable = !isRegionRequired;
            props.fieldNote = !isRegionRequired;
        } else if (owner) {
            const { regionRequired } = owner;
            if (regionRequired) {
                props.validate = [Validation.required];
                props.fieldNote = null;
            }
            props.isClearable = !regionRequired;
        }

        return props;
    };

    const getAdditionalFields = () => {
        const fields = [];
        const additionalFields = currentProject.additionalFields;
        if (additionalFields) {
            const files = additionalFields.attached_files;
            if (files) {
                Object.keys(files).forEach((file) => {
                    fields.push({
                        name: files[file].label,
                        value: { name: getAdditionalName(files[file].filename) },
                        fileId: file,
                        fieldId: _.uniqueId(),
                        option: "file",
                    });
                });
            }
            Object.keys(additionalFields).map((key) => {
                if (key !== "attached_files") {
                    fields.push({
                        name: getAdditionalName(key),
                        value: additionalFields[key],
                        option: "text",
                        fieldId: _.uniqueId(),
                    });
                }
            });
        }

        return fields;
    };

    const getTextAdditionalFields = () => {
        const fields = getAdditionalFields();

        return fields.filter((i) => !i?.fileId);
    };

    const handleProjectTabChange = (event, newTab) => {
        updateProjectSelectedTab(newTab);
    };

    const joTabs = [
        {
            title: "project details",
            content: () => renderProjectDetails(),
            icon: <AccountTreeIcon />,
        },
        {
            title: "History",
            content: <ProjectJobsHistory />,
            icon: <HistoryIcon />,
        },
        {
            title: "custom fields",
            content: (
                <CustomFields
                    form={"UPDATE PROJECT"}
                    grantedAccess={currentProject.grantedAccess}
                    id={currentProject.id}
                    initialValues={{
                        name: currentProject.name,
                        additionalFields: getAdditionalFields(),
                    }}
                    submitHandler={projectActions.updateProject}
                />
            ),
            icon: <BookmarkIcon />,
        },
    ];

    const renderProjectDetails = () => {
        return (
            <>
                <Divider marginTop={0} />
                <Grid component="div" container alignItems={"flex-start"}>
                    <Grid item xs={6}>
                        <h3>
                            <AssignmentIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                            Project Notes
                        </h3>
                        <Box className={classes.boxContent}>
                            <p className="text-offset" style={{ wordBreak: "break-all" }}>
                                {currentProject.notes}
                            </p>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonsGroup justifyContent={"flex-end"}>
                            {(archived || notArchived) && !currentProject.grantedAccess && (
                                <SecondaryButton onClick={setProjectStatus}>
                                    {!archived ? "Archive" : "Unarchive"}
                                </SecondaryButton>
                            )}
                            <Tooltip
                                title={ADD_PROJECT_NETSUITE_INTEGRATION_WARNING_TEXT}
                                disableHoverListener={canAddPayloads}
                            >
                                <Box className={classes.addPayload}>
                                    <SecondaryButton
                                        disabled={disabled || !canAddPayloads}
                                        onClick={() => setShowCreatePoliModal(true)}
                                    >
                                        <AddCircleOutlineIcon />
                                        Add Payload
                                    </SecondaryButton>
                                </Box>
                            </Tooltip>
                        </ButtonsGroup>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" xs={5} style={{ marginLeft: 36 }}>
                    <Field
                        options={CUSTOMER_TYPES}
                        name="customerType"
                        value={customerType}
                        disabled={disabled}
                        component={SwitchSelector}
                        onChangeValue={onChangeCustomerType}
                    />
                </Grid>
                <Grid container alignItems="center" className="customer-section">
                    <AssignmentIndIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                    <Grid item xs={5}>
                        {isRestrictedCustomerType ? (
                            <RestrictedCustomerSelector
                                isClearable
                                disabled={disabled}
                                name="restrictedCustomer"
                                onChange={onRestrictedCustomerFieldChange}
                            />
                        ) : (
                            <CustomerSelector
                                disabled={disabled}
                                createNewOptionHandler={createNewCompanyHandler}
                                fieldName="customerId"
                                form={form}
                                isClearable
                                onChange={onCustomerFieldChange}
                                createNewOption={{ label: "Add Customer", value: CREATE_NEW_OPTION_ID }}
                            />
                        )}
                    </Grid>
                </Grid>
                {!isRestrictedCustomerType && (
                    <Grid container alignItems="center" className="contact-section">
                        <AssignmentIndIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        <Grid item xs={5}>
                            <JobContactSelector
                                disabled={disabled}
                                additionalParams={
                                    currentProject.customer ? { customerId: currentProject.customer.id } : {}
                                }
                                onChange={(value) => {
                                    updateFieldByName(
                                        value
                                            ? {
                                                  isCustomersContact: value.isCustomersContact,
                                                  jobContactId: value.value,
                                              }
                                            : null,
                                        "jobContactNew",
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container alignItems="center" className="terms-section">
                    <AssignmentIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                    <Grid item xs={5}>
                        <TermsSelector
                            disabled={disabled}
                            onChange={(value) => updateFieldByName(value && value.value, "termsId")}
                        />
                    </Grid>
                    {IS_CAN_SHOW_PAVERTRAKERS_SELECTOR(
                        (currentProject.customer && currentProject.customer.poType) || account.company.poType,
                    ) && (
                        <Grid item xs={5} style={{ marginLeft: 15 }}>
                            <PavertrackersSelector
                                disabled={disabled}
                                onChange={(values) => updateFieldByName(values, PAVERTRACKERS_NAME)}
                            />
                        </Grid>
                    )}
                </Grid>
                {account.companyRegions && (
                    <Grid container alignItems="center" className="regions-section">
                        <AssignmentIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                        <Grid item xs={5}>
                            <RegionSelector
                                disabled={disabled}
                                isClearable={true}
                                companyId={currentProject.owner && currentProject.owner.id}
                                onChange={(value) => updateFieldByName(value && value.value, "regionId")}
                                {...configForRegionSelector(currentProject.owner)}
                            />
                        </Grid>
                    </Grid>
                )}
                {enableDropOffNotifications && (
                    <ProjectNotificationsRecipients
                        setLoading={setLoading}
                        disabled={disabled}
                        classes={classes}
                        updateFieldByName={updateFieldByName}
                    />
                )}
                <h3>
                    <SettingsIcon className={clsx(classes.uiIcon, classes.uiIconGrey)} />
                    Project Options
                </h3>
                <Box className={clsx(classes.projectSwitches, classes.boxContent)}>
                    <Grid container component="div" justify={"space-between"} alignItems="center">
                        <Grid item>
                            <Field
                                name="photoRequired"
                                type="checkbox"
                                disabled={disabled}
                                label="Photo Required"
                                onChange={(e) => updateFieldByName(e.target.checked, "photoRequired")}
                                component={CustomSwitch}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <TabsCustom tabsHeaders={[{ label: "Active" }, { label: "Archive" }]} onChangeTab={onChangeTab} />
                <Divider marginBottom={0} />
                <ProjectLineItemsList
                    getPOLineItems={getPOLineItems}
                    getMorePOLineItems={getMorePOLineItems}
                    disabled={disabled}
                    archived={archived}
                    isArchiveTab={isArchiveTab}
                />
            </>
        );
    };

    return (
        <Grid className={clsx(classes.projectInfo, (isLoading || loading) && LOADER_WHITE_OVERLAY)}>
            {(isLoading || loading) && <Loader />}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
            {message && (
                <ErrorNotification type={"success"} message={message} config={{ onClose: () => setMessage(null) }} />
            )}
            <h1
                style={{
                    borderBottom: "1px solid #979797",
                    paddingBottom: 8,
                }}
            >
                <Grid component="div" container>
                    <Grid item xs={IS_ADMIN_USER(account.company.id) ? 7 : 8}>
                        <div className="name" title={currentProject.name}>
                            {currentProject.name}
                        </div>
                        <div className="--text-16" style={{ wordBreak: "break-all" }}>
                            {currentProject.externalJobNumber ? `#${currentProject.externalJobNumber}` : ""}
                        </div>
                    </Grid>
                    <Grid item xs={IS_ADMIN_USER(account.company.id) ? 5 : 4}>
                        <ButtonsGroup justifyContent="flex-end">
                            <SecondaryButton
                                size={"small"}
                                disabled={archived}
                                onClick={() => setShowEditProjectModal(true)}
                            >
                                Edit
                            </SecondaryButton>
                            <SecondaryButton size={"small"} disabled={disabled} onClick={openGrandAccessModal}>
                                Grant Access
                            </SecondaryButton>
                        </ButtonsGroup>
                    </Grid>
                </Grid>
            </h1>
            <ControlledAppTabs
                tabsControls={joTabs}
                tabsContent={joTabs}
                firstTab={0}
                containerStyles={{ padding: "10px 0" }}
                itemStyles={{ height: 70 }}
                value={projectSelectedTab}
                handleChange={handleProjectTabChange}
            />
            <AppModal
                isOpen={showCreatePoliModal}
                form={CREATE_POLI_FORM}
                modalStyles={{ width: 800 }}
                closeModal={() => setShowCreatePoliModal(false)}
            >
                <PoLineItemForm
                    closeModal={() => setShowCreatePoliModal(false)}
                    initialValues={{
                        additionalFields:
                            account.company.poType === PO_TYPE_TIMBER
                                ? [...timberAdditionalFields, ...getTextAdditionalFields()].map((fieldName) => ({
                                      name: fieldName,
                                      value: null,
                                  }))
                                : getTextAdditionalFields(),
                        [START_DATE_NAME]: new Date(),
                        [END_DATE_NAME]: new Date(),
                    }}
                />
            </AppModal>
            <AppModal
                isOpen={showGrandAccessModal}
                form={ALLOWED_COMPANIES_FORM}
                closeModal={() => setShowGrandAccessModal(false)}
            >
                <AllowedCompaniesForm
                    closeModal={() => setShowGrandAccessModal(false)}
                    initialValues={{
                        allowedCompanies: allowedCompanies,
                    }}
                />
            </AppModal>
            <AppModal
                isOpen={showEditProjectModal}
                form={PROJECT_FORM}
                closeModal={() => setShowEditProjectModal(false)}
                modalStyles={{ minWidth: 700 }}
            >
                <h2 className="title">Edit Project</h2>
                <ProjectForm
                    closeModal={() => setShowEditProjectModal(false)}
                    grantedAccess={currentProject.grantedAccess}
                    iAmOwner={currentProject.iAmOwner}
                    initialValues={{
                        name: currentProject.name,
                        notes: currentProject.notes,
                        externalJobNumber: currentProject.externalJobNumber,
                        externalRef: currentProject.externalRef,
                        customer: currentProject.customer && {
                            value: currentProject.customer.id,
                            label: currentProject.customer.name,
                        },
                        owner: currentProject.owner && {
                            value: currentProject.owner.id,
                            label: currentProject.owner.name,
                        },
                        contract: !!currentProject.prices,
                        haulers:
                            currentProject.haulers &&
                            currentProject.haulers.map((hauler) => ({
                                value: hauler.id,
                                label: hauler.name,
                            })),
                        prices:
                            currentProject.prices &&
                            currentProject.prices.map((price) => ({
                                amount: price.amount,
                                amountNett: price.amountNett,
                                unitOfMeasure: `value-${price.uom.id}`,
                            })),
                        region: currentProject.region && {
                            value: currentProject.region.id,
                            label: currentProject.region.regionName,
                        },
                        dotProject: !!currentProject.dotProjectNumber || !_.isEmpty(currentProject.inspectors),
                        inspectors: currentProject.inspectors
                            ? currentProject.inspectors.map((i) => GET_INSPECTOR_DATA(i))
                            : undefined,
                        dotProjectNumber: currentProject.dotProjectNumber,
                        dropOffNotificationsRecipients:
                            currentProject.dropOffNotificationsRecipients?.map(generateUserSelectOptions),
                    }}
                    handleSave={handleProjectUpdate}
                />
            </AppModal>
            <ConfirmationModal
                isOpen={!!confirmationModalMessage}
                question={confirmationModalMessage}
                yesHandler={onConfirmChangeCustomerType}
                noHandler={() => setConfirmationModalMessage("")}
            />
        </Grid>
    );
}

ProjectDetailsForm.propTypes = {
    account: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    getProjects: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    resetProjects: PropTypes.func.isRequired,
    setCurrentProject: PropTypes.func.isRequired,
    updateProjects: PropTypes.func.isRequired,
    canAddPayloads: PropTypes.bool.isRequired,
    enableDropOffNotifications: PropTypes.bool,
};

ProjectDetailsForm.defaultProps = {
    formValues: {},
};

export const PROJECT_DETAILS_FORM = "projectDetailsForm";
export const PROJECT_DETAILS_TAB_INDEX = 0;

export default withRouter(
    compose(
        reduxForm({
            form: PROJECT_DETAILS_FORM,
            enableReinitialize: true,
        }),
        connect(
            (state, props) => {
                const formSelector = formValueSelector(props.form);

                return {
                    poLineItems: state.poLineItems.poLineItems,
                    currentProject: state.projects.currentProject || {},
                    account: state.account,
                    loading: state.projects.loading,
                    isRegionRequired: getIfIsRegionRequired(state),
                    canAddPayloads: selectIfIsCanAddPayloads(state),
                    projectSelectedTab: selectProjectSelectedTab(state),
                    customerType: formSelector(state, "customerType"),
                    customer: formSelector(state, CUSTOMER_FIELD_NAME),
                    jobContact: formSelector(state, JOB_CONTACT_NAME),
                    restrictedCustomer: formSelector(state, "restrictedCustomer"),
                    enableDropOffNotifications: selectEnableDropOffNotifications(state),
                };
            },
            (dispatch) => ({
                setCurrentProject: (payload) => {
                    dispatch(projectActions.setCurrentProject(payload));
                },
                resetProjects: (payload) => {
                    dispatch(projectActions.resetProjects(payload));
                },
                updateProjects: (payload) => {
                    dispatch(projectActions.updateProjects(payload));
                },
                getProjects: (params) => {
                    dispatch(projectActions.getProjects(params));
                },
                getPOLineItems: (projectId, concat, archived) => {
                    dispatch(poLineItemsActions.getPOLineItems(projectId, concat, archived));
                },
                resetPOLineItems: (payload) => {
                    dispatch(poLineItemsActions.resetPOLineItems(payload));
                },
                updateProjectSelectedTab: (updatedTab) => {
                    dispatch(projectActions.updateProjectSelectedTab(updatedTab));
                },
            }),
        ),
    )(ProjectDetailsForm),
);
