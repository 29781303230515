import React, { useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { selectMeasureSystem } from "../../../selectors";
import { useErrorNotification } from "../../../hooks";
import { fetchSitesDistance } from "../../../dataServers/sites";
import { CANT_CALCULATE_DISTANCE } from "../constants";
import { parseMetersToUserSystem } from "../../../constants/accounts";

const useStyles = makeStyles((theme) => ({
    recalculateIcon: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        width: 20,
        height: 20,
    },
}));

export const SitesDistance = ({
    isLoading,
    classes,
    initialDropOffSites,
    initialPickUpSite,
    dropOffSites,
    pickUpSite,
    id,
    isCopy,
    distance,
    poLineItem,
    setState,
}) => {
    const localClasses = useStyles();
    const showError = useErrorNotification();

    const [manuallyCalculatedData, setManuallyCalculatedData] = useState(null);
    const measureSystem = useSelector(selectMeasureSystem);

    const currentDropOffSiteId = dropOffSites && dropOffSites[0] && dropOffSites[0].value;
    const currentPickUpSiteId = pickUpSite?.value;

    const onCalculateDistanceClick = async () => {
        try {
            setState((state) => ({ ...state, isLoading: true }));
            const { data } = await fetchSitesDistance(currentPickUpSiteId, currentDropOffSiteId);
            if (_.isEmpty(data)) {
                return showError(CANT_CALCULATE_DISTANCE);
            }
            setManuallyCalculatedData({
                distance: data.distance,
                pickUpSiteId: currentPickUpSiteId,
                dropOffSiteId: currentDropOffSiteId,
            });
        } catch (error) {
            showError(error);
        } finally {
            setState((state) => ({ ...state, isLoading: false }));
        }
    };

    const selectDistanceToShow = () => {
        const initDropOffSiteId = initialDropOffSites && initialDropOffSites[0] && initialDropOffSites[0]?.value;
        const initPickUpSiteId = initialPickUpSite?.value;

        if (
            (id || isCopy) &&
            distance &&
            initDropOffSiteId === currentDropOffSiteId &&
            initPickUpSiteId === currentPickUpSiteId
        ) {
            return distance;
        }
        if (!_.isEmpty(poLineItem)) {
            const poDropOffId = poLineItem.dropOffSites && poLineItem.dropOffSites[0]?.id;
            const poPickUpId = poLineItem.pickUpSite?.id;
            if (currentDropOffSiteId === poDropOffId && currentPickUpSiteId === poPickUpId && poLineItem?.distance) {
                return poLineItem.distance;
            }
        }

        if (
            manuallyCalculatedData?.pickUpSiteId === currentPickUpSiteId &&
            manuallyCalculatedData?.dropOffSiteId === currentDropOffSiteId
        ) {
            return manuallyCalculatedData?.distance;
        }
    };
    const distanceToShow = selectDistanceToShow();

    return (
        <>
            {!isLoading && (
                <Grid
                    component="div"
                    item
                    justify="center"
                    className={clsx(classes.generalSidePadding, classes.infoText, classes.distanceContainer)}
                >
                    Distance:{" "}
                    {distanceToShow ? (
                        parseMetersToUserSystem(distanceToShow, measureSystem)
                    ) : (
                        <Box sx={{ marginLeft: 5, display: "flex" }}>
                            Not Calculated{" "}
                            {currentPickUpSiteId &&
                                currentDropOffSiteId &&
                                currentPickUpSiteId !== currentDropOffSiteId && (
                                    <Tooltip title={"Recalculate Distance"}>
                                        <RefreshIcon
                                            className={clsx(localClasses.recalculateIcon)}
                                            onClick={onCalculateDistanceClick}
                                        />
                                    </Tooltip>
                                )}
                        </Box>
                    )}
                </Grid>
            )}
        </>
    );
};

export default SitesDistance;
