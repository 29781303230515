import {
    NOTIFICATION_EVENTS_FAILED,
    NOTIFICATION_EVENTS_LOADED,
    NOTIFICATION_EVENTS_LOADING,
    NOTIFICATION_SNACKBAR_REMOVE,
    NOTIFICATION_SNACKBARS_HIDE,
    NOTIFICATION_SNACKBARS_SHOW,
    NOTIFICATIONS_CLEAR,
    NOTIFICATIONS_FILTERS_SET,
    NOTIFICATIONS_REQUESTED_FILTERS_SET,
    NOTIFICATIONS_SET_ALERT_ZONE,
} from "../events/notifications";
import * as NotificationEventsDataServer from "../dataServers/notifications";
import { prepareEvent } from "../websocket/events";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import { PROCESS_SERVER_ERROR } from "../constants/global";
import { getUsers, updateCompanyNotificationsRequest } from "../dataServers/user";
import { TRUCKER_TYPE_DISPATCH } from "../constants/maps";
import { REMOVE_ACTION_TYPE, SELECT_ACTION_TYPE } from "../components/core/form/asyncAutocompleteComponent";
import { selectCompanyId } from "../selectors";
import { change } from "redux-form";
import { SETTINGS_NOTIFICATIONS_FORM } from "../components/settings/notifications";
import { DROP_OFF_NOTIFICATIONS_FIELD_NAME } from "../constants/settings";

export const getNotificationEvents = (concat, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentPage = Math.ceil(state.notifications.notificationEvents.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.notifications.notificationEvents) ? +currentPage + 1 : 1;

        dispatch({
            type: NOTIFICATION_EVENTS_LOADING,
            payload: {},
        });

        return NotificationEventsDataServer.getNotificationEvents({
            page: concat ? nextPage : 1,
            ...params,
        })
            .then((response) => {
                const newNotifications = response.data.map((event) => prepareEvent({ event }));
                dispatch({
                    type: NOTIFICATION_EVENTS_LOADED,
                    payload: {
                        notificationEvents: concat
                            ? [...state.notifications.notificationEvents, ...newNotifications]
                            : newNotifications,
                        notificationEventsCount: response.meta.count,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: NOTIFICATION_EVENTS_FAILED,
                    payload: {
                        notificationEventsError: error.message,
                    },
                });
            });
    };
};

export const notificationSnackbarRemove = (payload) => {
    return {
        type: NOTIFICATION_SNACKBAR_REMOVE,
        payload,
    };
};

export const notificationSnackbarsShow = () => {
    return {
        type: NOTIFICATION_SNACKBARS_SHOW,
        payload: {},
    };
};

export const notificationSnackbarsHide = () => {
    return {
        type: NOTIFICATION_SNACKBARS_HIDE,
        payload: {},
    };
};

export const notificationsClear = () => {
    return {
        type: NOTIFICATIONS_CLEAR,
    };
};

export const setNotificationAlertZone = (payload) => {
    return {
        type: NOTIFICATIONS_SET_ALERT_ZONE,
        payload,
    };
};

export const setNotificationsFilters = (payload) => {
    return {
        type: NOTIFICATIONS_FILTERS_SET,
        payload,
    };
};

export const setNotificationsRequestedFilters = (payload) => {
    return {
        type: NOTIFICATIONS_REQUESTED_FILTERS_SET,
        payload,
    };
};

export const getDispatchersLoadOptions =
    (inputValue, params, loadedCount, setError = null) =>
    async (dispatch, getState) => {
        try {
            const response = await getUsers({
                keywords: inputValue || null,
                ...params,
                truckerType: TRUCKER_TYPE_DISPATCH,
            });
            const results = response.data.map((user) => {
                return { value: user.id, label: user.fullName };
            });

            return {
                options: results,
                hasMore: response.meta.count > (loadedCount || results.length),
                page: params.page,
            };
        } catch (error) {
            setError && setError([PROCESS_SERVER_ERROR(error)]);
        }
    };

export const updateJobNotificationRecipients = (values, actionMeta, setError) => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);
    const { action } = actionMeta;

    let response;
    try {
        if (action === SELECT_ACTION_TYPE) {
            const addedOption = actionMeta.option;

            const data = {
                requestsNotifications: { recipientsToAdd: [addedOption.value] },
            };
            response = await updateCompanyNotificationsRequest(companyId, data);
        } else if (action === REMOVE_ACTION_TYPE) {
            const removedOption = actionMeta.removedValue;
            const data = {
                requestsNotifications: { recipientsToRemove: [removedOption.value] },
            };
            response = await updateCompanyNotificationsRequest(companyId, data);
        }

        if (response?.status === 200) {
            dispatch(change(SETTINGS_NOTIFICATIONS_FORM, "recipients", values));
        }
    } catch (error) {
        setError([PROCESS_SERVER_ERROR(error)]);
    }

    return response;
};

export const updateCompanyNotifications = (data) => async (dispatch, getState) => {
    const state = getState();
    const companyId = selectCompanyId(state);

    return await updateCompanyNotificationsRequest(companyId, data);
};

export const updateDropOffNotifications =
    (dropOffRecipients, enableDropOffNotifications) => async (dispatch, getState) => {
        return dispatch(
            updateCompanyNotifications({
                [DROP_OFF_NOTIFICATIONS_FIELD_NAME]: {
                    dropOffRecipients,
                    enableDropOffNotifications,
                },
            }),
        );
    };
