import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import JobOrdersListView from "./jobOrdersListView";
import JobOrderListItem from "./jobOrderListItem";
import RegularJobOrderItem from "./regularJobOrderItem";
import ListWaypoint from "../core/listWaypoint";
import { changeInitialTrucksListLoadingSkip, changeRequestedFleetListDate } from "../../actions/jobOrders";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
    jobOrdersList: {
        maxHeight: "100vh",
        paddingLeft: 5,
        paddingTop: 3,

        "&.-has-scroll": {
            paddingRight: 13,
            overflowY: "scroll",
            overflowX: "hidden",
        },
    },
}));

const JobsList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        isListView,
        isLoading,
        jobOrders,
        skeletonJobOrders,
        jobOrdersCount,
        requestFetch,
        setActive,
        updateJoStatus,
        updateJoCopySchedule,
        deleteJoById,
        account,
        activeJobOrderParam,
        handleSort,
        initialHeader,
        resetActiveJobOrders,
        renderNewJobItem,
        renderAdditionalInfo,
    } = props;

    useEffect(() => {
        return () => {
            resetActiveJobOrders(null);
            dispatch(changeRequestedFleetListDate(undefined));
            dispatch(changeInitialTrucksListLoadingSkip(false));
        };
    }, []);

    if (renderAdditionalInfo) {
        return renderAdditionalInfo();
    }

    return (
        <div>
            {isListView ? (
                !_.isEmpty(jobOrders) && (
                    <JobOrdersListView
                        jobOrders={jobOrders}
                        account={account}
                        jobOrdersCount={jobOrdersCount}
                        fetchMore={requestFetch}
                        activeJobOrderParam={activeJobOrderParam}
                        initialHeader={initialHeader}
                        resetActiveJobOrders={resetActiveJobOrders}
                        handleSort={handleSort}
                    />
                )
            ) : !_.isEmpty(jobOrders) || !isLoading ? (
                <div className={clsx(classes.jobOrdersList, jobOrders.length > 5 && "-has-scroll")}>
                    {jobOrders.map((jobOrder, index) => (
                        <Fragment key={jobOrder.id}>
                            <ListWaypoint
                                fetchMore={requestFetch}
                                index={index}
                                items={jobOrders}
                                itemsCount={jobOrdersCount}
                            />
                            {renderNewJobItem ? (
                                <RegularJobOrderItem
                                    setActive={setActive}
                                    jobOrder={jobOrder}
                                    updateJoStatus={updateJoStatus}
                                    updateJoCopySchedule={updateJoCopySchedule}
                                    deleteJoById={deleteJoById}
                                    index={index}
                                    jobOrdersListLength={jobOrders.length}
                                />
                            ) : (
                                <JobOrderListItem
                                    setActive={setActive}
                                    jobOrder={jobOrder}
                                    updateJoStatus={updateJoStatus}
                                    updateJoCopySchedule={updateJoCopySchedule}
                                    deleteJoById={deleteJoById}
                                    index={index}
                                    jobOrdersListLength={jobOrders.length}
                                />
                            )}
                        </Fragment>
                    ))}
                </div>
            ) : (
                skeletonJobOrders.map((item, index) => {
                    return <JobOrderListItem setActive={setActive} jobOrder={{}} key={index} isSkeleton={true} />;
                })
            )}
        </div>
    );
};

JobsList.propTypes = {
    isListView: PropTypes.bool,
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    jobOrders: PropTypes.array.isRequired,
    skeletonJobOrders: PropTypes.array.isRequired,
    jobOrdersCount: PropTypes.number,
    requestFetch: PropTypes.func.isRequired,
    deleteJoById: PropTypes.func,
    setActive: PropTypes.func,
    updateJoStatus: PropTypes.func,
    updateJoCopySchedule: PropTypes.func,
    requestPage: PropTypes.func,
    activeJobOrderParam: PropTypes.string,
    handleSort: PropTypes.func,
    resetActiveJobOrders: PropTypes.func,
    initialHeader: PropTypes.bool,
};

export default withRouter(JobsList);
