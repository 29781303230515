import React, { useEffect, useState } from "react";
import { isMobile, isIOS, isChromium, isChrome } from "react-device-detect";
import { useLocation, withRouter } from "react-router-dom";

import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { THEME_ICONS } from "../styles/icons";
import { MOBILE_REDIRECTION } from "../routes/globalRoutes";
import {
    APP_ENVIRONMENT,
    ENV_MODE_PROD,
    ENV_MODE_TEST,
    ENV_MODE_LOCAL,
    ENV_MODE_STAGE,
    ENV_MODE_DEMO,
} from "../constants/global";

const LINKS = {
    [ENV_MODE_TEST]: "https://link.truckit.com/test-go",
    [ENV_MODE_LOCAL]: "https://link.truckit.com/test-go",
    [ENV_MODE_DEMO]: "https://link.truckit.com/demo-go",
    [ENV_MODE_STAGE]: "https://link.truckit.com/stage-go",
    [ENV_MODE_PROD]: "https://link.truckit.com/go",
};

export const IOSRedirection = () => {
    const appStoreUrl = "https://apps.apple.com/us/app/truckit-mobile/id1501588184";
    const onClick = () => {
        setTimeout(function appNotInstalled() {
            window.location.replace(appStoreUrl);
        }, 500);
        window.location.replace("com.truckitapp.truckit://");
    };

    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                background: "#fff",
                height: "100vh",
                zIndex: 1000000000000,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 40,
                }}
            >
                <div style={{ ...THEME_ICONS.logo, width: 200, height: 200 }} />
                <h3>TruckIT Mobile</h3>
            </div>
            <Button variant="contained" color="primary" style={{ width: 200, height: 50 }} onClick={onClick}>
                Get The App
            </Button>
        </div>
    );
};

export const MobileShortcut = withRouter((props) => {
    const { pathname } = useLocation();
    if (!isMobile) return null;
    const openMobileApp = async () => {
        if ((isChrome || isChromium) && isIOS) {
            props.history.push(MOBILE_REDIRECTION);
            return;
        }
        window.open(LINKS[APP_ENVIRONMENT] + `?uri=${pathname}`);
    };

    return (
        <div
            variant="outlined"
            style={{
                backgroundColor: "#fff",
                borderTop: "1px solid #979797",
                position: "fixed",
                right: 0,
                left: 0,
                bottom: 0,
                height: 95,
                zIndex: 124123,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ ...THEME_ICONS.logo, width: 60, height: 80 }} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <h3>Open in the TruckIT Mobile app</h3>
                <Button variant="outlined" color="primary" onClick={openMobileApp}>
                    Open
                </Button>
            </div>
        </div>
    );
});

export const MobileLoginDrawer = withRouter((props) => {
    const [open, setOpen] = useState(isMobile);
    useEffect(() => {}, []);

    const onBrowserPress = () => {
        setOpen(false);
    };

    const onOpenAppPress = () => {
        if ((isChrome || isChromium) && isIOS) {
            props.history.push(MOBILE_REDIRECTION);
            setOpen(false);
            return;
        }
        window.open(LINKS[APP_ENVIRONMENT]);
        setOpen(false);
    };

    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            style={{
                borderTopRightRadius: 8,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "15px",
                }}
            >
                <h3 style={{ textAlign: "center", marginBottom: 8 }}>TruckIT is better on the app</h3>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 10,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ ...THEME_ICONS.logo, width: 60, height: 80 }} />
                        <span style={{ marginLeft: 10 }}>TruckIT Mobile</span>
                    </div>
                    <Button variant="contained" color="primary" onClick={onOpenAppPress}>
                        Open App
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LanguageIcon style={{ width: 50, height: 60 }} />
                        <span style={{ marginLeft: 20 }}>Browser</span>
                    </div>

                    <Button variant="contained" onClick={onBrowserPress}>
                        Continue
                    </Button>
                </div>
            </div>
        </Drawer>
    );
});
