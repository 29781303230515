import dataProvider from "../helpers/api";
import { UNITS_OF_MEASURE } from "../constants/endpoints";

export const fetchUnitsOfMeasure = (params) => {
    return dataProvider({
        url: UNITS_OF_MEASURE.UNITS_OF_MEASURE(),
        method: "GET",
        params
    });
};

export const fetchUnitOfMeasureById = (uomId) => {
    return dataProvider({
        url: UNITS_OF_MEASURE.UNITS_OF_MEASURE_ID(uomId),
        method: "GET",
    });
};

export const postUnitOfMeasure = (data) => {
    return dataProvider({
        url: UNITS_OF_MEASURE.UNITS_OF_MEASURE(),
        method: "POST",
        data,
    });
};

export const deleteUnitOfMeasure = (uomId) => {
    return dataProvider({
        url: UNITS_OF_MEASURE.UNITS_OF_MEASURE_ID(uomId),
        method: "DELETE",
    });
};

export const putUnitOfMeasure = (uomId, data) => {
    return dataProvider({
        url: UNITS_OF_MEASURE.UNITS_OF_MEASURE_ID(uomId),
        method: "PUT",
        data,
    });
};
