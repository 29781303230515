import {
    ADD_NEW_USER_REGION,
    AUTHORIZE_USER,
    DELETE_USER_REGION,
    LOGOUT_USER,
    REQUEST_REGIONS,
    REQUEST_REGIONS_RESULT,
    SAVE_USERS_REGIONS,
    UPDATE_USER_COMPANY_TYPE,
    UPDATE_USER_CUSTOMER_STRIPE_ID,
    UPDATE_USER_DATA,
    UPDATE_USER_HAS_TEMPORARY_PASSWORD_FIELD,
    UPDATE_USER_PHONE_NUMBER,
    UPDATE_USER_REGION,
    UPDATE_USER_TERMS_AND_PERMISSIONS_STATUS,
} from "../events/globalEvents";
import {
    COMPANY_TYPE_CONTRACTOR,
    COMPANY_TYPE_LIMITED_CONTRACTOR,
    COMPANY_TYPE_LIMITED_TRUCKER,
    COMPANY_TYPE_TRUCKER,
} from "../constants/maps";

const initialState = {};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHORIZE_USER:
            return { ...state, ...action.userData };
        case UPDATE_USER_DATA:
            return { ...state, ...action.payload };
        case LOGOUT_USER:
            return initialState;
        case SAVE_USERS_REGIONS:
            return { ...state, companyRegions: action.regions };
        case ADD_NEW_USER_REGION: {
            const updatedRegionList = state?.companyRegions
                ? [...state.companyRegions, action.payload]
                : [action.payload];

            return {
                ...state,
                companyRegions: updatedRegionList,
            };
        }
        case DELETE_USER_REGION: {
            const updatedRegionList = state?.companyRegions?.filter((region) => region.id !== action.payload);

            return {
                ...state,
                companyRegions: updatedRegionList,
            };
        }
        case UPDATE_USER_REGION: {
            const updatedRegionList = state?.companyRegions.map((region) => {
                if (region.id === action.payload.id) {
                    return action.payload;
                }

                return region;
            });

            return {
                ...state,
                companyRegions: updatedRegionList,
            };
        }
        case UPDATE_USER_TERMS_AND_PERMISSIONS_STATUS:
            return { ...state, userData: { ...state.userData, hasAgreedToLatestTC: true } };
        case REQUEST_REGIONS:
            return { ...state, regionsMeta: action.payload };
        case REQUEST_REGIONS_RESULT:
            return { ...state, regionsMeta: action.payload };
        case UPDATE_USER_COMPANY_TYPE: {
            const companyType = state.company.type;
            let updatedCompanyType;
            if (companyType) {
                if (companyType === COMPANY_TYPE_LIMITED_TRUCKER) {
                    updatedCompanyType = COMPANY_TYPE_TRUCKER;
                } else if (companyType === COMPANY_TYPE_LIMITED_CONTRACTOR) {
                    updatedCompanyType = COMPANY_TYPE_CONTRACTOR;
                }

                return { ...state, company: { ...state.company, type: updatedCompanyType } };
            }

            return state;
        }
        case UPDATE_USER_CUSTOMER_STRIPE_ID: {
            return {
                ...state,
                company: { ...state.company, stripeId: action.payload },
            };
        }
        case UPDATE_USER_PHONE_NUMBER: {
            return {
                ...state,
                phone: action.payload,
            };
        }
        case UPDATE_USER_HAS_TEMPORARY_PASSWORD_FIELD: {
            return {
                ...state,
                hasTemporaryPassword: false,
            };
        }
        default:
            return state;
    }
};
