import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";

import Loader, { fixedLoaderStyles } from "../core/loader";
import MyTeamsActionMenu from "./teamActionMenu";
import { IS_ADMIN_USER, TRUCKER_TYPES, USER_ROLES } from "../../constants/maps";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import moment from "moment";
import { updateActiveTeamUsersOrder } from "../../actions/team";

const MyTeamList = (props) => {
    const { users, isLoading, fetchMore, usersCount, account, activeTab, isRestrictedCustomer } = props;
    const isAdmin = IS_ADMIN_USER(account.company.id);
    const dispatch = useDispatch();
    const activeTeamUsersOrder = useSelector((state) => state.teams.activeTeamUsersOrder);

    const getTableData = () => {
        const rows = users.map((user, index) => {
            const {
                id,
                firstName,
                lastName,
                username,
                company,
                role,
                truckerType,
                email,
                region,
                phone,
                lastLogin,
                lastActive,
            } = user;

            return {
                index,
                id,
                name: `${firstName} ${lastName}`,
                username,
                companyName: company.name,
                role: USER_ROLES[role],
                truckerType: TRUCKER_TYPES[truckerType],
                email,
                phone,
                region: region ? region.regionName : null,
                lastLogin: lastLogin ? moment(lastLogin).tz(account.timezone).format(DEFAULT_DATE_FORMAT) : null,
                lastActive: lastActive
                    ? moment(lastActive).tz(account.timezone).format(DEFAULT_DATE_TIME_FORMAT)
                    : null,
                action: (
                    <MyTeamsActionMenu
                        activeOrderParam={activeTeamUsersOrder}
                        itemForEdit={user}
                        activeTab={activeTab}
                        account={account}
                        userName={`${firstName} ${lastName}`}
                        timezone={account.timezone}
                        isRestrictedCustomer={isRestrictedCustomer}
                        userId={id}
                    />
                ),
            };
        });
        let columns = [
            { id: "name", label: "NAME", sortParam: "lastName,firstName" },
            { id: "username", label: "USER NAME", sortParam: "username" },
        ];

        if (isAdmin) {
            columns.push({ id: "companyName", label: "COMPANY", sortParam: "company" });
        }

        columns = [
            ...columns,
            { id: "role", label: "ROLE", sortParam: "role" },
            { id: "truckerType", label: "Trucker Type", sortParam: "truckerType" },
            { id: "phone", label: "PHONE", sortParam: "phone" },
            { id: "email", label: "EMAIL", sortParam: "email" },
            { id: "region", label: "REGION", sortParam: "region" },
            { id: "lastLogin", label: "LAST LOGIN DATE", sortParam: "lastLogin" },
            { id: "lastActive", label: "LAST ACTIVE", sortParam: "lastActive" },
            { id: "action", label: TEXT_ACTIONS_TABLE_CELL },
        ];

        if (isRestrictedCustomer) {
            columns = columns.filter((column) => column.id !== "truckerType");
        }

        return { rows: rows, columns: columns };
    };

    const handleTeamUsersSort = (sortParam) => {
        dispatch(updateActiveTeamUsersOrder(sortParam));
    };

    return (
        <React.Fragment>
            <div className={clsx(isLoading && `${LOADER_WHITE_OVERLAY}`, _.isEmpty(users) && "-min-height")}>
                {isLoading && <Loader style={fixedLoaderStyles()} />}
                {!_.isEmpty(users) && (
                    <TableWithStickyHeader
                        rows={getTableData().rows}
                        fetchMore={fetchMore}
                        itemsCount={usersCount}
                        columns={getTableData().columns}
                        handleSort={handleTeamUsersSort}
                        activeOrderParam={activeTeamUsersOrder}
                    />
                )}
            </div>
            {_.isEmpty(users) && !isLoading && (
                <React.Fragment>
                    <br />
                    <h1 className="--text-center">No users match your search / filter parameters.</h1>
                    <br />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

MyTeamList.propTypes = {
    users: PropTypes.array,
    isLoading: PropTypes.bool,
    account: PropTypes.object.isRequired,
    fetchMore: PropTypes.func,
    usersCount: PropTypes.number,
    activeTab: PropTypes.number,
};

export default withRouter(
    connect((state) => ({
        account: state.account,
    }))(MyTeamList),
);
