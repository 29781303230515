export const ADD_OFFLINE_HAULER_BUTTON_TITLE = "Add Offline Hauler";
export const ADD_OFFLINE_HAULER_FORM_TITLE = "Add Offline Hauler";
export const ADD_OFFLINE_HAULER_FORM_COMPANY_NAME_LABEL = "Company Name";
export const ADD_OFFLINE_HAULER_FORM_COMPANY_TYPE_PLACEHOLDER = "Company Type";
export const ADD_OFFLINE_HAULER_FORM_ACCOUNT_TYPE_PLACEHOLDER = "Account Type";
export const ADD_OFFLINE_HAULER_FORM_PO_TYPE_PLACEHOLDER = "Purchase Order Type";
export const ADD_OFFLINE_HAULER_FORM_TIMEZONE_PLACEHOLDER = "Time Zone";
export const ADD_OFFLINE_HAULER_CANCEL_BUTTON_TITLE = "Cancel";
export const ADD_OFFLINE_HAULER_SUBMIT_BUTTON_TITLE = "Save";
export const ADD_OFFLINE_HAULER_SUCCESS_MESSAGE = "The hauler is created";
export const ADD_OFFLINE_HAULER_ERROR_MESSAGE = "Something Wrong";
export const COMPANY_ADMINS_FIELD_NOTE = "Users who can edit company settings";
export const DEFAULT_NOTIFICATIONS_RECIPIENTS_LABEL = "Default Notifications Recipients";
export const ONE_ADMIN_REQUIRED_ERROR_MESSAGE = "At least one admin is required";
export const REMOVE_YOURSELF_CONFIRMATION_TITLE = "ARE YOU SURE YOU WANT TO REMOVE ADMIN RIGHTS FOR THE USER?";
export const NO_COMPANY_ADMINS_MESSAGE =
    "Your company does not have a designated administrator. Please contact TruckIT support";
