import { TICKET_STATUSES_LABELS, UNIT_OF_MEASURE_BY_ID, UNIT_OF_MEASURE_LABELS } from "../constants/maps";
import _ from "lodash";
import React from "react";

export const calculateStatsWhenOpenTicket = (currentStats, ticket) => {
    let newStats = _.cloneDeep(currentStats);

    newStats.statsByStatus[ticket.status] = {
        name: TICKET_STATUSES_LABELS[ticket.status],
        value: newStats.statsByStatus[ticket.status] ? newStats.statsByStatus[ticket.status].value + 1 : 1,
    };

    newStats.quantityStats[ticket.unitOfMeasure] = {
        name: UNIT_OF_MEASURE_BY_ID[ticket.unitOfMeasure],
        value:
            newStats.quantityStats[ticket.unitOfMeasure] && ticket.unitOfMeasureQuantity
                ? newStats.quantityStats[ticket.unitOfMeasure].value + ticket.unitOfMeasureQuantity
                : ticket.unitOfMeasureQuantity,
    };

    newStats.statsByUOM[ticket.unitOfMeasure] = {
        name: <span>{UNIT_OF_MEASURE_LABELS[ticket.unitOfMeasure]} Tickets</span>,
        value: newStats.statsByUOM[ticket.unitOfMeasure] ? newStats.statsByUOM[ticket.unitOfMeasure].value + 1 : 1,
    };
    if (ticket.isAirticket)
        newStats.statsByUOM["air"] = {
            name: "AirTickets™",
            value: newStats.statsByUOM["air"] ? newStats.statsByUOM["air"] + 1 : 1,
        };

    return newStats;
};
