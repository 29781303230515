// might be used for https://github.com/TruckITllc/truckit-frontend/issues/2761#issuecomment-1024206289 test\

export const FAKE_BUNDLE_761_DATA = [
    {
        jobId: 86377,
        timestamp: "2022-01-31T11:30:00+00:00",
        location: {
            latitude: 33.810712,
            name: "Atlanta",
            longitude: -84.431604,
            id: 446,
        },
        isPickUp: false,
        isDropOff: false,
        isDepot: true,
        isJobStart: false,
    },
    {
        jobId: 86377,
        timestamp: "2022-01-31T12:00:00+00:00",
        location: {
            latitude: 34.017103,
            name: "Attica Smart Dispatch",
            longitude: -83.496161,
            address: "4352 Lebanon Church Rd, Athens, GA 30607, USA",
            id: 173736,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: true,
    },
    {
        jobId: 86377,
        timestamp: "2022-01-31T12:30:00+00:00",
        location: {
            latitude: 34.017103,
            name: "Attica Smart Dispatch",
            longitude: -83.496161,
            address: "4352 Lebanon Church Rd, Athens, GA 30607, USA",
            id: 173736,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86377,
        timestamp: "2022-01-31T13:07:07+00:00",
        location: {
            latitude: 34.075376,
            name: "Alpharetta Smart Dispatch",
            longitude: -84.29409,
            address: "Alpharetta, GA, USA",
            id: 173735,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },

    //
    {
        jobId: 86376,
        timestamp: "2022-01-31T14:00:00+00:00",
        location: {
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
            address: "I-20, Atlanta, GA 30312, USA",
            id: 173713,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86376,
        timestamp: "2022-01-31T15:15:00+00:00",
        location: {
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
            address: "I-20, Atlanta, GA 30312, USA",
            id: 173713,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: true,
    },

    {
        jobId: 86376,
        timestamp: "2022-01-31T16:15:37+00:00",
        location: {
            latitude: 33.948684,
            name: "Athens Smart Dispatch",
            longitude: -83.360221,
            address: "1220 Oconee St, Athens, GA 30605, USA",
            id: 173720,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86376,
        timestamp: "2022-01-31T17:30:00+00:00",
        location: {
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
            address: "I-20, Atlanta, GA 30312, USA",
            id: 173713,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86376,
        timestamp: "2022-01-31T18:00:00+00:00",
        location: {
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
            address: "I-20, Atlanta, GA 30312, USA",
            id: 173713,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: true,
    },

    {
        jobId: 86376,
        timestamp: "2022-01-31T19:15:37+00:00",
        location: {
            latitude: 33.948684,
            name: "Athens Smart Dispatch",
            longitude: -83.360221,
            address: "1220 Oconee St, Athens, GA 30605, USA",
            id: 173720,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: null,
        timestamp: "2022-01-31T19:35:14+00:00",
        location: {
            latitude: 33.810712,
            name: "Atlanta",
            longitude: -84.431604,
            id: 446,
        },
        isPickUp: false,
        isDropOff: false,
        isDepot: true,
        isJobStart: false,
    },
];

export const FAKE_BUNDLE_764_DATA = [
    {
        jobId: 86514,
        timestamp: "2022-02-04T09:30:05+00:00",
        location: {
            id: 446,
            latitude: 33.810712,
            name: "Atlanta",
            longitude: -84.431604,
        },
        isPickUp: false,
        isDropOff: false,
        isDepot: true,
        isJobStart: false,
    },
    {
        jobId: 86514,
        timestamp: "2022-02-04T10:00:05+00:00",
        location: {
            id: 173713,
            address: "I-20, Atlanta, GA 30312, USA",
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: true,
    },
    {
        jobId: 86514,
        timestamp: "2022-02-04T11:00:05+00:00",
        location: {
            id: 173713,
            address: "I-20, Atlanta, GA 30312, USA",
            latitude: 33.744962,
            name: "Atlanta - Smart Dispatch",
            longitude: -84.390175,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86514,
        timestamp: "2022-02-04T11:54:07+00:00",
        location: {
            id: 173721,
            address: "125 S Main St, Madison, GA 30650, USA",
            latitude: 33.595953,
            name: "Madison Smart Dispatch",
            longitude: -83.467963,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86475,
        timestamp: "2022-02-04T15:48:09+00:00",
        location: {
            id: 173714,
            address: "1415 E Main St, Richmond, VA 23219, USA",
            latitude: 37.534996,
            name: "Richmond - Smart Dispatch",
            longitude: -77.431353,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86475,
        timestamp: "2022-02-04T16:00:00+00:00",
        location: {
            id: 173714,
            address: "1415 E Main St, Richmond, VA 23219, USA",
            latitude: 37.534996,
            name: "Richmond - Smart Dispatch",
            longitude: -77.431353,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: true,
    },
    {
        jobId: 86475,
        timestamp: "2022-02-04T19:22:52+00:00",
        location: {
            id: 173721,
            address: "125 S Main St, Madison, GA 30650, USA",
            latitude: 33.595953,
            name: "Madison Smart Dispatch",
            longitude: -83.467963,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86475,
        timestamp: "2022-02-05T02:57:35+00:00",
        location: {
            id: 173714,
            address: "1415 E Main St, Richmond, VA 23219, USA",
            latitude: 37.534996,
            name: "Richmond - Smart Dispatch",
            longitude: -77.431353,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 86475,
        timestamp: "2022-02-05T10:32:18+00:00",
        location: {
            id: 173721,
            address: "125 S Main St, Madison, GA 30650, USA",
            latitude: 33.595953,
            name: "Madison Smart Dispatch",
            longitude: -83.467963,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: null,
        timestamp: "2022-02-05T18:37:01+00:00",
        location: {
            id: 446,
            latitude: 33.810712,
            name: "Atlanta",
            longitude: -84.431604,
        },
        isPickUp: false,
        isDropOff: false,
        isDepot: true,
        isJobStart: false,
    },
];

export const FAKE_BUNDLE = [
    {
        jobId: 94141,
        timestamp: "2022-09-16T17:00:00+00:00",
        location: { latitude: 33.0, longitude: -84.0 },
        isPickUp: false,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
        isStartTruckLocation: true,
    },

    {
        jobId: 94141,
        timestamp: "2022-09-16T17:27:50+00:00",
        location: {
            id: 721,
            latitude: 33.887333,
            name: "4825 Dudley Lane",
            address: "4825 Dudley Lane, Sandy Springs, GA, USA",
            longitude: -84.3965,
        },
        isPickUp: true,
        isDropOff: false,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 94141,
        timestamp: "2022-09-16T18:02:10+00:00",
        location: {
            id: 723,
            latitude: 33.985153,
            name: "1398 OWENBY DR",
            address: "1398 Owenby Drive, Marietta, GA, USA",
            longitude: -84.531189,
        },
        isPickUp: false,
        isDropOff: true,
        isDepot: false,
        isJobStart: false,
    },
    {
        jobId: 94141,
        timestamp: "2022-09-16T19:58:20+00:00",
        location: {
            id: 310,
            latitude: 33.748995,
            address: "Atlanta, GA, USA",
            longitude: -84.387982,
        },
        isPickUp: false,
        isDropOff: false,
        isDepot: true,
        isJobStart: false,
    },
];
