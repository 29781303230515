import React, { useEffect, useState } from "react";
import TrucksTable, { generateJolisForTrucksTable } from "../jobDetails/trucks/trucksTable";
import { getRequestTrucksAndHaulers } from "../../../actions/jobOrders";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentJobOrderRequest } from "../../../selectors/jobOrders";
import { getSortedByTruckNameItems } from "../../fleet/fleetList";
import _ from "lodash";
import { PROCESS_SERVER_ERROR } from "../../../constants/global";
import { ErrorNotification } from "../../core";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { UNIT_OF_MEASURE_LOAD } from "../../../constants/maps";

const useStyles = makeStyles((theme) => ({
    requestTrucksWrapper: {
        "& div.tr:last-child div.td:first-child": {
            borderRadius: 0,
        },
    },
}));

const RequestItems = ({ requestId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentJobOrderRequest = useSelector(selectCurrentJobOrderRequest);

    const [jolis, setJolis] = useState(null);
    const [error, setError] = useState(false);

    const showQty =
        currentJobOrderRequest &&
        currentJobOrderRequest.unitOfMeasure === UNIT_OF_MEASURE_LOAD &&
        !currentJobOrderRequest.unlimited;

    useEffect(() => {
        const getRequestJolis = async () => {
            const jolis = await dispatch(getRequestTrucksAndHaulers(requestId));
            const [newJolis, _] = generateJolisForTrucksTable(jolis);

            setJolis(getSortedByTruckNameItems(newJolis));
        };

        if (requestId) {
            try {
                getRequestJolis(requestId);
            } catch (error) {
                setError([PROCESS_SERVER_ERROR(error)]);
            }
        }
    }, [requestId]);

    return (
        <>
            {!_.isEmpty(jolis) && (
                <div className={clsx(classes.requestTrucksWrapper)}>
                    <br />
                    <TrucksTable showQty={showQty} jolis={jolis} jobOrder={currentJobOrderRequest} />
                </div>
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </>
    );
};

export default RequestItems;
