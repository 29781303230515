import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";

import MyTeamList from "./myTeamList";
import TeamDialog from "./teamDialog";
import { handleTeamDialogToggle, loadTeamUsers, resetUsers } from "../../actions/team";
import MyTeamFilters from "./myTeamFilters";
import TabsCustom from "../core/tabsCustom";
import { PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import { selectIfIsRestrictedCustomer } from "../../selectors/user";

export const UsersFiltersContext = createContext({});

const useStyles = makeStyles(() => ({
    myTeamWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

function MyTeamWrapper(props) {
    const wrapper = PAGE_GLOBAL_STYLES();
    const classes = useStyles();
    const userStoreData = useSelector((state) => state.teams);
    const activeTeamUsersOrder = useSelector((state) => state.teams.activeTeamUsersOrder);
    const { isLoadingUsers, handleTeamDialogToggle, history } = props;
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [usersFilters, setUsersFilters] = useState();
    const companyName =
        history.location.state && history.location.state.company ? history.location.state.company.name : undefined;

    const loadUsers = (concat) => {
        let params = {
            ...usersFilters,
            archived: activeTab === 1,
        };
        if (activeTeamUsersOrder) {
            params.order = activeTeamUsersOrder;
        }
        dispatch(loadTeamUsers(params, concat));
    };
    const updateListByFilters = (filters) => {
        dispatch(resetUsers());
        setUsersFilters({
            ...usersFilters,
            keywords: filters.keywords === undefined ? companyName : filters.keywords,
            role: filters?.role,
        });
    };

    useEffect(() => {
        usersFilters && loadUsers();
    }, [usersFilters, activeTeamUsersOrder, activeTab]);

    return (
        <div className={clsx(classes.myTeamWrapper)}>
            <MyTeamFilters
                updateListByFilters={updateListByFilters}
                initialValues={{
                    search: companyName,
                }}
                handleTeamDialogToggle={handleTeamDialogToggle}
                isRestrictedCustomer={props.isRestrictedCustomer}
            />
            <div className={wrapper.globalPageStyles}>
                <TabsCustom
                    tabsHeaders={[{ label: "Active" }, { label: "Archive" }]}
                    onChangeTab={(tab) => setActiveTab(tab)}
                />
            </div>
            <UsersFiltersContext.Provider value={usersFilters}>
                <MyTeamList
                    isLoading={isLoadingUsers}
                    users={userStoreData.teamUsers}
                    usersCount={userStoreData.usersCount}
                    fetchMore={loadUsers}
                    activeTab={activeTab}
                    activeTeamUsersOrder={activeTeamUsersOrder}
                    isRestrictedCustomer={props.isRestrictedCustomer}
                />
                <TeamDialog activeTab={activeTab} />
            </UsersFiltersContext.Provider>
        </div>
    );
}

MyTeamWrapper.propTypes = {
    history: PropTypes.object.isRequired,
    handleTeamDialogToggle: PropTypes.func.isRequired,
    isLoadingUsers: PropTypes.bool.isRequired,
};

export default withRouter(
    connect(
        (state) => {
            return {
                isLoadingUsers: state.teams.isLoadingUsers,
                isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
            };
        },
        (dispatch) => ({
            handleTeamDialogToggle: (open) => dispatch(handleTeamDialogToggle(open)),
        }),
    )(MyTeamWrapper),
);
