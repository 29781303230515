import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Cropper from "react-easy-crop";
import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import ErrorNotification from "../../core/notification";

const useStyles = makeStyles((theme) => ({
    rotateButton: {
        position: "absolute",
        backgroundColor: theme.palette.general.paleGrey,
        marginRight: 5,
        bottom: 0,
        right: 0,
        zIndex: 103,

        "&:hover": {
            backgroundColor: theme.palette.general.lightGrey,
        },
    },
    downloadButton: {
        position: "absolute",
        backgroundColor: theme.palette.general.paleGrey,
        marginLeft: 5,
        bottom: 0,
        left: 0,
        zIndex: 103,

        "&:hover": {
            backgroundColor: theme.palette.general.lightGrey,
        },
    },
}));

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "";
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.src = url;
    });

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

function getCroppedImg(image, pixelCrop, rotation = 0) {
    return new Promise((resolve, reject) => {
        let data;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);

        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

        try {
            data = ctx.getImageData(0, 0, safeArea, safeArea);
        } catch (e) {
            reject(e);
        }

        if (!_.isEmpty(pixelCrop)) {
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;
        }

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
            Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
        );

        canvas.toBlob(
            (blob) => {
                resolve(blob);
            },
            "image/jpg",
            1,
        );
    });
}

const EditableImage = (props) => {
    const { image, height, imageStyles, containerStyles, storeFile, isCrop } = props;
    const [crop, setCrop] = useState({
        x: 0,
        y: 0,
    });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [transformBlocked, setTransformBlocked] = useState(false);
    const [error, setError] = useState(null);
    const [imageToTransform, setImageToTransform] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [pictureFile, setPictureFile] = useState(null);
    const ref = useRef();

    const classes = useStyles();

    const onCropComplete = useCallback((croppedArea, areaPixels) => {
        setCroppedAreaPixels(areaPixels);
    }, []);

    const onRotate = () => {
        const newRotation = rotation === 270 ? 0 : rotation + 90;
        setRotation(newRotation);
    };

    const downloadImg = () => {
        if (image && image.fileLink) {
            const link = document.createElement("a");
            document.body.appendChild(link);
            link.href = image.fileLink;
            link.click();
        }
    };

    const getFile = (areaPixels, rotationNumber) => {
        if (!_.isEmpty(image) && imageToTransform && !transformBlocked) {
            return getCroppedImg(imageToTransform, areaPixels, rotationNumber)
                .then((blob) => {
                    return new File([blob], image.filename, { type: "image/jpg" });
                })
                .catch((error) => {
                    setError(["Cannot transform image"]);
                    setTransformBlocked(true);
                });
        }
    };

    useEffect(() => {
        if (croppedAreaPixels && !transformBlocked && imageToTransform) {
            getFile(croppedAreaPixels, rotation).then((file) => {
                setPictureFile(file);
            });
        }
    }, [rotation, croppedAreaPixels]);

    useEffect(() => {
        if (storeFile && pictureFile && !transformBlocked && imageToTransform) {
            storeFile(pictureFile);
        }
    }, [pictureFile]);

    useEffect(() => {
        return () => {
            setCrop({
                x: 0,
                y: 0,
            });
            setZoom(1);
            setRotation(0);
            setCroppedAreaPixels(null);
            setImageToTransform(null);
            setTransformBlocked(false);
            setPictureFile(null);
        };
    }, []);

    useEffect(() => {
        if (image && image.fileLink) {
            setRotation(0);
            setZoom(1);
            createImage(image.fileLink)
                .then((image) => {
                    setImageToTransform(image);
                    setTransformBlocked(false);
                })
                .catch((error) => {
                    setError(["Cannot transform image"]);
                    setTransformBlocked(true);
                });
        }
    }, [image]);

    return (
        <Box className={classes.cropperWrapper}>
            <Cropper
                image={image.fileLink}
                ref={ref}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                onCropComplete={onCropComplete}
                showGrid={false}
                // added because of know issue of chromium
                // https://bugs.chromium.org/p/chromium/issues/detail?id=718352
                mediaProps={{
                    crossOrigin: "",
                }}
                style={{
                    containerStyle: {
                        height: height,
                        position: "relative",
                        ...containerStyles,
                    },
                    cropAreaStyle: {
                        color: "transparent",
                        border: isCrop ? "1px solid rgba(255, 255, 255, 0.5)" : "unset",
                    },
                    mediaStyle: {
                        ...imageStyles,
                    },
                }}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
            />
            <IconButton className={clsx(classes.rotateButton)} onClick={onRotate}>
                <RotateLeftOutlinedIcon />
            </IconButton>
            <IconButton className={clsx(classes.downloadButton)} onClick={downloadImg}>
                <GetAppOutlinedIcon />
            </IconButton>
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </Box>
    );
};

EditableImage.propTypes = {
    image: PropTypes.object.isRequired,
    storeFile: PropTypes.func.isRequired,
    imageStyles: PropTypes.object,
    containerStyles: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    isCrop: PropTypes.bool.isRequired,
};

EditableImage.defaultProps = {
    isCrop: false,
    containerStyles: {},
    height: 400,
};

export default EditableImage;
