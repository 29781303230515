import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { reduxForm } from "redux-form";

import { Container, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { ActionLink, SimpleLink } from "../core/link";
import SearchAndFilters from "../global/searchAndFilters";
import { PAGE_ACTIONS_STYLES, PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import { handleTruckDialogToggle } from "../../actions/trucks";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
    headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
}));

const TrucksFilters = (props) => {
    const { form, dispatch, updateListByFilters } = props;

    const classes = useStyles();
    const wrapper = PAGE_GLOBAL_STYLES();
    const pageActionsStyles = PAGE_ACTIONS_STYLES();

    const handleAddTruckFormToggle = () => {
        dispatch(handleTruckDialogToggle(true));
    };

    return (
        <Container className={clsx(wrapper.globalPageStyles, classes.container)}>
            <div className={pageActionsStyles.pageActions}>
                <Box className={classes.headerBox}>
                    <ActionLink>
                        <AddCircleOutlineIcon />
                        <SimpleLink onClick={handleAddTruckFormToggle}>Create new truck</SimpleLink>
                    </ActionLink>
                    <SearchAndFilters form={form} updateList={updateListByFilters} />
                </Box>
            </div>
        </Container>
    );
};

TrucksFilters.propTypes = {
    dispatch: PropTypes.func.isRequired,
    updateListByFilters: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
};

const TRUCKS_FILTERS_FORM = "truckFilter";

export default withRouter(
    reduxForm({
        form: TRUCKS_FILTERS_FORM,
    })(TrucksFilters),
);
