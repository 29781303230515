import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    customCheckBox: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000",
        display: "flex",
        alignItems: "center",

        "&.radio": {
            "& input::before": {
                borderRadius: 20,
            },

            "& input:checked::before": {
                borderColor: theme.palette.border.primary,
                backgroundColor: theme.palette.general.paleGrey,
            },

            "& input::after": {
                content: "''",
                position: "absolute",
                top: 2,
                left: 3,
                width: 0,
                height: 0,
                borderColor: theme.palette.primary.main,
                borderRadius: 17,
                borderWidth: 0,
                borderStyle: "solid",
                transition: "borderWidth 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            },

            "& input:checked::after": {
                borderWidth: 5,
            },
        },

        "&.--disabled": {
            color: theme.palette.secondary.superLight,

            "& input::before": {
                borderColor: theme.palette.secondary.light,
                backgroundColor: theme.palette.secondary.superLight,
            },
        },

        "& .input": {
            position: "relative",
            marginRight: 16,

            "& input": {
                position: "relative",
                top: 0,
            },

            "& input::before": {
                content: "''",
                position: "absolute",
                top: -3,
                left: -2,
                width: 20,
                height: 20,
                border: `2px solid`,
                borderColor: theme.palette.border.primary,
                backgroundColor: theme.palette.general.paleGrey,
                borderRadius: theme.shape.borderRadius,
            },
            "& input:checked::before": {
                backgroundColor: theme.palette.general.green,
                borderColor: theme.palette.general.green,
            },

            "& input:checked + .checkbox-icon": {
                display: "block",
            },

            "& .checkbox-icon": {
                position: "absolute",
                top: 3,
                left: 2,
                width: 18,
                height: 18,
                color: "white",
                display: "none",
            },
        },
    },
}));

const CustomCheckbox = (props) => {
    const classes = useStyles();
    const { label, input, className, disabled, type, style, tooltipTitle } = props;

    return (
        <Tooltip title={tooltipTitle} disableHoverListener={!tooltipTitle} placement="left">
            <label
                htmlFor={`${input.name}-${input.value}`}
                style={style}
                className={clsx(classes.customCheckBox, type, className, disabled && "--disabled")}
            >
                <div className="input">
                    <input type={type} {...input} id={`${input.name}-${input.value}`} disabled={disabled} />
                    {type === "checkbox" && <CheckIcon className="checkbox-icon" />}
                </div>
                {label}
            </label>
        </Tooltip>
    );
};

CustomCheckbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    input: PropTypes.object,
    style: PropTypes.object.isRequired,
    meta: PropTypes.object,
    disabled: PropTypes.bool,
    tooltipTitle: PropTypes.string,
};

CustomCheckbox.defaultProps = {
    style: {},
};

export default withRouter(CustomCheckbox);
