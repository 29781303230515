import { formValueSelector, reset } from "redux-form";
import {
    COMPANIES_FAILED,
    COMPANIES_LOADED,
    COMPANIES_LOADING,
    COMPANIES_RESET,
    COMPANY_CLEARED,
    COMPANY_DIALOG_TOGGLE,
    COMPANY_FAILED,
    COMPANY_LOADED,
    COMPANY_LOADING,
    SET_COMPANY_FOR_EDIT,
    UPDATE_ACTIVE_COMPANIES_ORDER,
    UPDATE_COMPANY_FOR_MANAGING_HAULERS,
    UPDATE_COMPANY_FOR_UPDATING_ADMINS,
    UPDATE_HAS_SUB_COMPANIES,
} from "../events/companies";
import * as CompaniesDataServer from "../dataServers/companies";
import {
    addManagedHaulerRequest,
    deleteManagedHaulersRequest,
    fetchMangedHaulers,
    fetchSubCompanies,
    updateCompanyProfile,
} from "../dataServers/companies";
import { actions as uomActions } from "../reducers/unitsOfMeasureReducer";
import { updateUserProfileData } from "./globalActions";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";
import _ from "lodash";
import { IS_ADMIN_USER } from "../constants/maps";
import { signInAsOtherCompanyUser } from "../dataServers/user";
import { PROCESS_SERVER_ERROR } from "../constants/global";
import { handleError } from "../helpers/global";
import { selectCompanyId } from "../selectors";
import { UNITS_OF_MEASURE_TABLE } from "../constants/forms";
import { getIsStripeAccount } from "../constants/accounts";
import { fetchCompanyAdmins, updateCompanyAdminsRequest } from "../dataServers/companyAdmins";
import { selectCompanyForUpdatingAdmins } from "../selectors/companies";
import { formatReduxFormErrors } from "../helpers/errorFormatting";

export const clearCompanyForEdit = () => {
    return (dispatch) => {
        return dispatch({
            type: COMPANY_CLEARED,
            payload: {},
        });
    };
};

const generateStripeSubscriptionData = async (company) => {
    const isStripeAccount = getIsStripeAccount(company.accountType);
    if (isStripeAccount) {
        try {
            const { data } = await CompaniesDataServer.getCompanyStripeSubscriptionRequest(company.id);

            return data;
        } catch (e) {
            return null;
        }
    }

    return null;
};

export const getCompanyForEdit = (company) => {
    return async (dispatch) => {
        dispatch({
            type: COMPANY_LOADING,
            payload: {},
        });
        const stripeSubscriptionData = await generateStripeSubscriptionData(company);

        return CompaniesDataServer.getCompanyForEdit(company.id)
            .then((response) => {
                const updatedCompanyForEdit = {
                    companyForEdit: {
                        ...response.data,
                        ...company,
                        allowedPeriodAfterPaymentFailed: stripeSubscriptionData?.allowedPeriodAfterPaymentFailed,
                    },
                };
                dispatch({
                    type: COMPANY_LOADED,
                    payload: updatedCompanyForEdit,
                });
            })
            .catch((error) => {
                dispatch({
                    type: COMPANY_FAILED,
                    companiesError: error.message,
                });
            });
    };
};

export const getCompanies = (concat = false, params = {}) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentPage = Math.ceil(state.companies.companies.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.companies.companies) ? +currentPage + 1 : 1;
        const activeCompaniesOrder = state.companies.activeCompaniesOrder;

        if (activeCompaniesOrder) {
            params.order = activeCompaniesOrder;
        }

        dispatch({
            type: COMPANIES_LOADING,
            payload: {},
        });

        return CompaniesDataServer.getCompanies({
            page: concat ? nextPage : 1,
            withArchived: IS_ADMIN_USER(state.account.company.id),
            ...params,
        })
            .then((response) => {
                dispatch({
                    type: COMPANIES_LOADED,
                    payload: {
                        companies: concat ? [...state.companies.companies, ...response.data] : response.data,
                        companiesCount: response.meta.count,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: COMPANIES_FAILED,
                    payload: {
                        companiesError: error.message,
                    },
                });
            });
    };
};

export const updateCompanyById = (updatedCompany) => {
    return (dispatch, getState) => {
        const state = getState();
        const { companies } = state.companies;

        dispatch({
            type: COMPANIES_LOADING,
            payload: {},
        });

        const newCompanies = companies.map((company) => {
            if (company.id === updatedCompany.id) {
                return updatedCompany;
            }

            return company;
        });

        dispatch({
            type: COMPANIES_LOADED,
            payload: {
                companies: newCompanies,
            },
        });
    };
};

export const updateCompaniesList = (companies) => {
    return (dispatch) => {
        dispatch({
            type: COMPANIES_LOADED,
            payload: {
                companies: companies,
            },
        });
    };
};

export const setCompaniesLoading = (isLoading) => {
    return {
        type: COMPANIES_LOADING,
        payload: { isLoading },
    };
};

export const resetCompanies = () => {
    return {
        type: COMPANIES_RESET,
        payload: {},
    };
};

export const handleCompanyDialogToggle = () => {
    return {
        type: COMPANY_DIALOG_TOGGLE,
    };
};

export const setCompanyForEdit = (payload) => {
    return {
        type: SET_COMPANY_FOR_EDIT,
        payload,
    };
};

export const updateActiveCompanyOrder = (order) => {
    return {
        type: UPDATE_ACTIVE_COMPANIES_ORDER,
        payload: order,
    };
};

export const updateCompanyForManagingHaulers = (company) => {
    return {
        type: UPDATE_COMPANY_FOR_MANAGING_HAULERS,
        payload: company,
    };
};

export const updateCompanyForUpdatingAdmins = (company) => {
    return {
        type: UPDATE_COMPANY_FOR_UPDATING_ADMINS,
        payload: company,
    };
};

export const updateHasSubCompanies = (hasSubCompanies) => {
    return {
        type: UPDATE_HAS_SUB_COMPANIES,
        payload: hasSubCompanies,
    };
};

export const getHasSubCompanies = (setError) => async (dispatch, getState) => {
    try {
        const { data } = await fetchSubCompanies();
        const hasSubCompanies = data.length > 0;
        dispatch(updateHasSubCompanies(hasSubCompanies));
    } catch (error) {
        setError && setError([PROCESS_SERVER_ERROR(error)]);
    }
};

export const getSubCompaniesLoadOptions = (inputValue, loadedCount, params) => async (dispatch, getState) => {
    try {
        const response = await fetchSubCompanies({ keywords: inputValue, ...params });
        const results = response.data.map((subCompany) => {
            const { company, masterUsers } = subCompany;

            return { value: company.id, label: company.name, company, masterUsers };
        });

        return {
            options: results,
            hasMore: response.meta.count > (loadedCount || results.length),
            page: params.page,
        };
    } catch (error) {}
};

export const getOtherCompanyUserData = (userId) => async (dispatch, getState) => {
    const response = await signInAsOtherCompanyUser(userId);

    return {
        ...response.data,
        isFakeUser: true,
        token: response.authToken,
    };
};

export const getManagedHaulersLoadOptions =
    (inputValue, params, loadedCount, setError) => async (dispatch, getState) => {
        try {
            const response = await fetchMangedHaulers({ keywords: inputValue, ...params });
            const results = response.data.map((company) => {
                return { value: company.id, label: company.name, company };
            });

            return {
                options: results,
                hasMore: response.meta.count > (loadedCount || results.length),
                page: params.page,
            };
        } catch (error) {
            setError([PROCESS_SERVER_ERROR(error)]);
        }
    };

export const getCompanySubCompanies = (masterCompanyId, setError) => async (dispatch, getState) => {
    try {
        return await fetchSubCompanies({ perPage: 1000, masterCompanyId });
    } catch (error) {
        setError([PROCESS_SERVER_ERROR(error)]);
    }
};

export const getAllCompanyAdmins = (companyId, setError) => async (dispatch, getState) => {
    try {
        return await fetchCompanyAdmins(companyId, { perPage: 1000 });
    } catch (error) {
        setError([PROCESS_SERVER_ERROR(error)]);
    }
};

export const deleteManagedHauler = (masterCompanyId, subCompanyId, setError) => async (dispatch, getState) => {
    try {
        return await deleteManagedHaulersRequest({ masterCompanyId, subCompanyId });
    } catch (error) {
        setError([PROCESS_SERVER_ERROR(error)]);
    }
};

export const addManagedHauler = (masterCompanyId, subCompanyId, setError) => async (dispatch, getState) => {
    try {
        return await addManagedHaulerRequest({ masterCompanyId, subCompanyId });
    } catch (error) {
        setError([PROCESS_SERVER_ERROR(error)]);
    }
};

export const getAllParentCompanies = (errorHandler) => async (dispatch, getState) => {
    try {
        const companiesResponse = await CompaniesDataServer.fetchParentCompanies();

        return companiesResponse.data;
    } catch (e) {
        errorHandler && errorHandler(e);
    }
};

export const updateMeasurementSystemSettings = (onSuccess) => async (dispatch, getState) => {
    try {
        const state = getState();
        const systems = formValueSelector(UNITS_OF_MEASURE_TABLE)(state, "systems");
        const companyId = selectCompanyId(state);
        let result;
        if (systems.length === 2) {
            result = await updateCompanyProfile(companyId, { measurementSystem: 3 });
        } else {
            result = await updateCompanyProfile(companyId, { measurementSystem: systems[0] });
        }
        dispatch(updateUserProfileData(result.data));
        onSuccess && onSuccess(companyId);
    } catch (error) {
        dispatch(uomActions.resultUnitsOfMeasure(handleError(error)));
        dispatch(reset(UNITS_OF_MEASURE_TABLE));
    }
};

export const submitCompanyAdminsForm =
    ({ usersIds }) =>
    async (dispatch, getState) => {
        const state = getState();
        const companyForUpdatingAdmins = selectCompanyForUpdatingAdmins(state);

        const updatedAdminsIds = usersIds?.map((admin) => admin.value);
        try {
            await updateCompanyAdminsRequest(companyForUpdatingAdmins.id, {
                usersIds: updatedAdminsIds,
            });
        } catch (error) {
            formatReduxFormErrors(error);
        }
    };
