import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import IconButton from "@material-ui/core/IconButton";

import * as WebSocketActions from "../../actions/websocket";
import { appNavigation, ENV_MODE_PROD, inspectorAppNavigation, LOADER_WHITE_OVERLAY } from "../../constants/global";
import { ROUTE_DASHBOARD } from "../../routes/globalRoutes";
import { THEME_ICONS } from "../../styles/icons";
import { emitUserLoggedOutEvent } from "../../actions/globalActions";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { IS_ADMIN_USER, IS_INSPECTOR_USER } from "../../constants/maps";
import Loader from "../core/loader";
import NavigationNotifications from "./navigationNotifications";
import AppModal from "../core/modal";
import ControlPanel, { CONTROL_PANEL_FORM } from "../controlPanel/controlPanel";
import { END_DATE_NAME, START_DATE_NAME } from "./datesRange";
import { SimpleLink } from "../core/link";
import ErrorNotification from "../core/notification";
import ReturnToMainSessionButton from "./ReturnToMainSessionButton";
import { getNavigationProps } from "../../helpers/ui";
import { selectIfIsRestrictedCustomer } from "../../selectors/user";
import NavigationLoginAs from "./navigationLoginAs";

const useStyles = makeStyles((theme) => ({
    navigation: {
        display: "flex",
        alignItems: "center",
        zIndex: 100,
        position: "relative",
        boxShadow: `0 0 6px 0 ${theme.palette.secondary.main}`,
    },
    mainContent: {
        width: "90%",

        [theme.breakpoints.down("xs")]: {
            width: "98%",
        },
    },
    logo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    uiIconLogo: THEME_ICONS.logo,
    menu: {
        width: "67%",

        "& .menu-link": {
            padding: theme.spacing(1.5),
            textAlign: "center",
            borderBottom: `4px solid transparent`,

            "&.active": {
                color: "rgba(80,80,80,0.7)",
                borderBottom: `4px solid ${theme.palette.primary.main}`,
            },
            "& a": {
                color: theme.palette.text.primary,
                fontSize: 18,
                textDecoration: "none",
                display: "inline-block",

                [theme.breakpoints.down("xs")]: {
                    fontSize: 16,
                },
            },
        },
    },
    userSection: {
        width: "23%",
    },
    accountMenu: {
        textAlign: "right",
    },
    uiIconAccount: {
        ...THEME_ICONS.account,
    },
    userName: {
        paddingRight: 15,
        textAlign: "right",
        display: "block",
        width: 180,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",

        [theme.breakpoints.down("xs")]: {
            fontSize: 14,
        },
    },
}));

function RenderNavigation({ navigation, history, classes, account }) {
    return navigation.map((link, index) => {
        let active = history.location.pathname.includes(`${link.id}`);

        const navProps = getNavigationProps(account.role, link.path);

        const linkProps = { to: navProps.to, style: navProps.navStyles };

        return (
            <Grid
                item
                component="div"
                key={index}
                style={{ width: `calc(100% / ${appNavigation(account).length})` }}
                className={clsx("menu-link", active && "active")}
            >
                {link.isExternal ? (
                    <SimpleLink onClick={() => window.open(link.path, "_blank")}>{link.title}</SimpleLink>
                ) : (
                    <Link {...linkProps}>{link.title}</Link>
                )}
            </Grid>
        );
    });
}

function Navigation(props) {
    const { history, account, isRestrictedCustomer } = props;
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showControlModal, setShowControlModal] = useState(false);
    const anchorRef = React.useRef(null);
    const navigation = IS_INSPECTOR_USER(account.role) ? inspectorAppNavigation(account) : appNavigation(account);

    useEffect(() => {
        WebSocketActions.initialize();
        window.addEventListener("beforeunload", (e) => {
            e.preventDefault();
            WebSocketActions.close();
        });
    }, []);

    const handleLogoutClick = () => {
        setLoading(true);

        return emitUserLoggedOutEvent().finally(() => {
            setLoading(false);
        });
    };
    const userName = `${account.firstName} ${account.lastName}`;

    return (
        <div className={clsx(classes.navigation, "navigation", "bottom-shadow")}>
            <Grid
                container
                component="div"
                alignItems={"center"}
                justify="space-between"
                className={classes.mainContent}
            >
                <Grid item xs={1}>
                    <Link to={ROUTE_DASHBOARD} className={classes.logo}>
                        <div className={classes.uiIconLogo} />
                    </Link>
                </Grid>
                <Grid item xs={8} component="nav" className={classes.menu}>
                    <Grid
                        container
                        component="div"
                        justify={IS_INSPECTOR_USER(account.role) ? "flex-start" : "space-between"}
                        alignItems={"center"}
                    >
                        <RenderNavigation
                            account={account}
                            navigation={navigation}
                            history={history}
                            classes={classes}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={3} className={classes.userSection} component="div">
                    <Grid container component="div" justify={"flex-end"} alignItems={"center"}>
                        <h3 className={classes.userName} title={userName}>
                            {userName}
                        </h3>
                        <ButtonsGroup>
                            <NavigationNotifications />
                            {account.isSuperUser ? (
                                <IconButton
                                    id="profile"
                                    ref={anchorRef}
                                    title="Profile"
                                    onClick={() => setShowUserMenu(!showUserMenu)}
                                >
                                    <SupervisorAccountIcon id="profile" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    id="profile"
                                    className={classes.uiIconAccount}
                                    title="Profile"
                                    ref={anchorRef}
                                    onClick={() => setShowUserMenu(!showUserMenu)}
                                />
                            )}
                            {account.isFakeUser ? (
                                <ReturnToMainSessionButton />
                            ) : (
                                !isRestrictedCustomer && <NavigationLoginAs setError={setError} />
                            )}
                            <Popper
                                open={showUserMenu}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                        }}
                                    >
                                        <Paper className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                                            {isLoading && <Loader />}
                                            <ClickAwayListener
                                                onClickAway={(e) => {
                                                    if (!(e.target.localName === "path") && e.target.id !== "profile") {
                                                        setShowUserMenu(!showUserMenu);
                                                    }
                                                }}
                                            >
                                                <MenuList id="split-button-menu">
                                                    {process.env.REACT_APP_ENVIRONMENT !== ENV_MODE_PROD &&
                                                        IS_ADMIN_USER(account.company.id) && (
                                                            <MenuItem onClick={() => setShowControlModal(true)}>
                                                                Control Panel
                                                            </MenuItem>
                                                        )}
                                                    <MenuItem
                                                        onClick={(event) => handleLogoutClick(event)}
                                                        component="div"
                                                    >
                                                        Logout
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </ButtonsGroup>
                    </Grid>
                </Grid>
            </Grid>
            {process.env.REACT_APP_ENVIRONMENT !== ENV_MODE_PROD && IS_ADMIN_USER(account.company.id) && (
                <AppModal
                    isOpen={showControlModal}
                    form={CONTROL_PANEL_FORM}
                    closeModal={() => setShowControlModal(false)}
                >
                    <ControlPanel
                        initialValues={{
                            [START_DATE_NAME]: new Date(),
                            [END_DATE_NAME]: new Date(),
                            dateToCopy: new Date(),
                            jobOrder: null,
                        }}
                        handleClose={() => setShowControlModal(false)}
                    />
                </AppModal>
            )}
            {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
        </div>
    );
}

Navigation.propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    currentUrl: PropTypes.string.isRequired,
};

export default withRouter(
    connect((state) => {
        return {
            account: state.account,
            isRestrictedCustomer: selectIfIsRestrictedCustomer(state),
        };
    })(Navigation),
);
