export const PROJECTS_LOADING = "projectsLoading";
export const PROJECTS_VISIBLE_LOADING = "projectsVisibleLoading";
export const PROJECTS_LOADED = "projectsLoaded";
export const PROJECTS_FAILED = "projectsFailed";
export const PROJECTS_UPDATED = "projectsUpdated";
export const PROJECTS_SET_SEARCH_QUERY = "projectsSetSearchQuery";
export const PROJECTS_SET_STATUS_FILTER = "projectsSetStatusFilter";
export const PROJECTS_SET_REGION_FILTER = "projectsSetRegionFilter";
export const PROJECTS_SET_DATE_FILTER = "projectsDateFilter";
export const PROJECTS_CLEAR_FILTERS = "projectsClearFilters";
export const PROJECTS_RESET = "projectsReset";
export const PROJECT_OPENED = "projectOpened";
export const UPDATE_ACTIVE_PROJECT_ORDER = "updateActiveProjectOrder";
export const UPDATE_CURRENT_PROJECT_COMPANY = "updateCurrentProjectCompany";
export const SET_LOADING = "PROJECTS: SET_LOADING";
export const UPDATE_PROJECT_HISTORY_JOBS = "updateProjectHistoryJobs";
export const UPDATE_PROJECT_HISTORY_JOBS_COUNT = "updateProjectHistoryJobsCount";
export const UPDATE_ACTIVE_PROJECT_HISTORY_ORDER = "updateActiveProjectHistoryOrder";
export const UPDATE_PROJECT_HISTORY_LOADING = "updateProjectHistoryLoading";
export const UPDATE_PROJECT_SELECTED_TAB = "updateProjectSelectedTab";
export const UPDATE_RESTRICTED_CUSTOMERS_LIST = "updateRestrictedCustomersList";
