import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import SearchAndFilters, { GET_USER_INITIAL_FILTERS } from "../global/searchAndFilters";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { PAGE_ACTIONS_STYLES } from "../../styles/reusableStyles";
import DatesRange from "../global/datesRange";

const DevisesFilters = (props) => {
    const { form, updateListByFilters } = props;
    const pageActionsStyles = PAGE_ACTIONS_STYLES();

    return (
        <form noValidate={true}>
            <Grid container className={pageActionsStyles.pageActions}>
                <Grid item xs={6}>
                    <DatesRange form={form} />
                </Grid>
                <Grid item xs={6}>
                    <SearchAndFilters form={form} rememberFilters updateList={updateListByFilters} />
                </Grid>
            </Grid>
        </form>
    );
};

DevisesFilters.propTypes = {
    account: PropTypes.object.isRequired,
    initialFilters: PropTypes.object,
    updateListByFilters: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    isHaulers: PropTypes.bool,
};

export const DEVISES_FORM = "devisesFiltersForm";

export default withRouter(
    compose(
        reduxForm({
            form: DEVISES_FORM,
            initialValues: GET_USER_INITIAL_FILTERS(DEVISES_FORM),
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(DevisesFilters),
);
