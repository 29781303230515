import React, { useEffect } from "react";
import { Field, hasSubmitSucceeded, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import clsx from "clsx";

import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { getIotDevices } from "../../dataServers/trucks";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AsyncAutocompleteComponent, { AUTOCOMPLETE_FORM_VIEW_CLASS } from "../core/form/asyncAutocompleteComponent";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import AppModal from "../core/modal";
import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import Loader from "../core/loader";
import { Validation } from "../../helpers/validation";

const LinkTrailerDialog = (props) => {
    const { dialogOpen, handleClose, handleSubmit, submitting, reset, form } = props;

    const hasSubmitSuccess = useSelector(hasSubmitSucceeded(form));

    const closeModal = () => {
        handleClose && handleClose();
        reset();
    };

    useEffect(() => {
        if (hasSubmitSuccess) {
            closeModal();
        }
    }, [hasSubmitSuccess]);

    const loadOptions = (inputValue, { params = {}, loadedCount } = {}) => {
        return getIotDevices({
            keywords: inputValue,
            ...params,
        }).then((data) => {
            const results = data.data.map((device) => ({
                ...device,
                value: device.deviceId,
                label: device.deviceId,
            }));

            return {
                options: results,
                hasMore: data.meta.count > (loadedCount || results.length),
                page: data.meta.page,
            };
        });
    };

    return (
        <React.Fragment>
            <AppModal isOpen={dialogOpen} form={LINK_TRAILER} closeModal={closeModal}>
                <form noValidate={true} onSubmit={handleSubmit} className={clsx(submitting && LOADER_WHITE_OVERLAY)}>
                    {submitting && <Loader />}
                    <h2 className="title">LINK TRAILER</h2>
                    <Field
                        name="trailer"
                        loadOptions={loadOptions}
                        validate={[Validation.required]}
                        className={AUTOCOMPLETE_FORM_VIEW_CLASS}
                        placeholder="Select Trailer To Link"
                        component={AsyncAutocompleteComponent}
                    />
                    <br />
                    <ButtonsGroup>
                        <SecondaryButton type="button" onClick={closeModal}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton type="submit">SAVE</PrimaryButton>
                    </ButtonsGroup>
                </form>
            </AppModal>
            <SuccessNotification message={hasSubmitSuccess && "Successfully Linked"} />
        </React.Fragment>
    );
};

export const LINK_TRAILER = "linkTrailer";

const onSubmit = (values, dispatch, { onSubmit, truckForEdit }) => {
    return new Promise((resolve, reject) => setTimeout(resolve, 2000));
};

export default reduxForm({
    form: LINK_TRAILER,
    onSubmit,
})(LinkTrailerDialog);
