import { change } from "redux-form";

import {
    FLEET_LIST_REQUESTED_DATE_CHANGE,
    IGNORE_WEBSOCKET_JO_DATA,
    JO_DATE_CHANGE,
    JO_SHOW_TABLE_LOADER,
    JO_UPDATE_NOTIFICATION,
    JO_UPDATED_WEBSOCKET_DATA_RECEIVED,
    JOB_ORDER_OPENED,
    JOB_ORDERS_LOADED,
    NEED_UPDATE_JOB_ORDERS,
    NEED_UPDATE_MY_FLEET_LIST_FOR_ADMIN,
    NEW_JOB_ORDER_PAGE_LEAVE,
    REFRESH_JOBS_LIST,
    RESET_ACTIVE_JOB_ORDERS,
    RESET_HAULER_ORDER,
    RESET_JOB_ORDERS_PARAMS,
    SET_JO_MAP_COORDINATES,
    SKIP_JOB_ORDER_INITIAL_LOADING,
    SKIP_TRUCKS_INITIAL_LOADING_FOR_LISTING_DATE,
    UPDATE_ACTIVE_DISPATCHED_TRUCK_ORDER,
    UPDATE_ACTIVE_JOB_ORDER,
    UPDATE_CURRENT_JOB_ORDER_REQUEST,
    UPDATE_HAULER_ORDER,
    UPDATE_JO_FIRST_RENDERED_INITIAL_VALUES,
    UPDATE_JOB_ORDERS_LIST_COUNT,
    UPDATE_MY_FLEET_SELECTED_TRUCKS,
    UPDATE_REDIRECT_JOB_ID,
    UPDATE_REQUESTED_JOB_ORDER_ID,
} from "../events/jobOrders";
import {
    createJobOrder,
    deleteJobOrderById,
    getJobBoardJobOrderJoli,
    getJobOrderById,
    partialJobOrderUpdateById,
} from "../dataServers/jobOrder";
import { convertDateToUserTimeZone } from "../helpers/jobOrders";
import { selectAccountTimezone, selectCurrentProject } from "../selectors";
import { formatReduxFormErrors } from "../helpers/errorFormatting";
import { getProjectById } from "../dataServers/projects";
import { setCurrentProject, updateProjects } from "./projects";
import { PROCESS_SERVER_ERROR } from "../constants/global";
import { getSortedByTruckNameItems } from "../components/fleet/fleetList";

export const saveJobOrdersInRedux = (jobOrders) => {
    return {
        type: JOB_ORDERS_LOADED,
        jobOrders,
    };
};

export const saveJobOrdersCountInRedux = (count) => {
    return {
        type: UPDATE_JOB_ORDERS_LIST_COUNT,
        payload: count,
    };
};

export const updateRequestedJobOrderId = (jobOrderId) => {
    return {
        type: UPDATE_REQUESTED_JOB_ORDER_ID,
        payload: jobOrderId,
    };
};

export const saveCurrentJobOrderIdInRedux = (jobOrderId) => {
    return {
        type: JOB_ORDER_OPENED,
        jobOrderId,
    };
};

export const updateMyFleetListForAdmin = (joId) => {
    return {
        type: NEED_UPDATE_MY_FLEET_LIST_FOR_ADMIN,
        joId,
    };
};

export const updateJobOrder = () => {
    return {
        type: NEED_UPDATE_JOB_ORDERS,
    };
};

export const updateJobMapCoordinatesForTruck = (truckId) => {
    return {
        type: SET_JO_MAP_COORDINATES,
        truckId,
    };
};

export const showJoliTableLoader = (show) => {
    return {
        type: JO_SHOW_TABLE_LOADER,
        show,
    };
};
export const changeJoDate = (date) => {
    return {
        type: JO_DATE_CHANGE,
        date,
    };
};

export const changeRequestedFleetListDate = (date) => {
    return {
        type: FLEET_LIST_REQUESTED_DATE_CHANGE,
        date,
    };
};

export const newJobOrderPageLeave = (leave) => {
    return {
        type: NEW_JOB_ORDER_PAGE_LEAVE,
        payload: leave,
    };
};

export const joUpdatedWebsocketDataReceived = (joData) => {
    return {
        type: JO_UPDATED_WEBSOCKET_DATA_RECEIVED,
        joData,
    };
};

export const updateIgnoreWebsocketJoData = (ignore) => {
    return {
        type: IGNORE_WEBSOCKET_JO_DATA,
        payload: ignore,
    };
};

export const updateJoListWithWebsocketData = (websocketJoListData) => {
    return {
        type: JO_UPDATE_NOTIFICATION,
        payload: websocketJoListData,
    };
};

export const updateActiveJobOrder = (order, hardParam) => {
    return {
        type: UPDATE_ACTIVE_JOB_ORDER,
        payload: { order, hardParam },
    };
};

export const resetActiveJobOrders = (payload) => {
    return {
        type: RESET_ACTIVE_JOB_ORDERS,
        payload,
    };
};

export const changeJOFirstRenderedInitialValues = (initialValues) => {
    return {
        type: UPDATE_JO_FIRST_RENDERED_INITIAL_VALUES,
        payload: initialValues,
    };
};

export const changeInitialTrucksListLoadingSkip = (skipLoading) => {
    return {
        type: SKIP_TRUCKS_INITIAL_LOADING_FOR_LISTING_DATE,
        payload: skipLoading,
    };
};

export const changeInitialJobOrdersListLoadingSkip = (skipLoading) => {
    return {
        type: SKIP_JOB_ORDER_INITIAL_LOADING,
        payload: skipLoading,
    };
};

export const updateActiveDispatchedTruckOrder = (payload) => {
    return {
        type: UPDATE_ACTIVE_DISPATCHED_TRUCK_ORDER,
        payload,
    };
};

export const updateActiveHaulerOrder = (payload) => {
    return {
        type: UPDATE_HAULER_ORDER,
        payload,
    };
};

export const resetHaulerParam = () => {
    return {
        type: RESET_HAULER_ORDER,
    };
};

export const resetJobOrderParams = () => {
    return {
        type: RESET_JOB_ORDERS_PARAMS,
    };
};

export const updateMyFleetSelectedTrucks = (updatedTrucksList) => {
    return {
        type: UPDATE_MY_FLEET_SELECTED_TRUCKS,
        payload: updatedTrucksList,
    };
};

export const getCurrentJobOrderRequest = (jobOrderId, setError) => async (dispatch, getState) => {
    const state = getState();
    try {
        const currentProject = selectCurrentProject(state);
        const { data: jobOrderRequest } = await getJobOrderById(jobOrderId);
        dispatch(updateCurrentJobOrderRequest(jobOrderRequest));
        if (!currentProject) {
            const { data } = await getProjectById(jobOrderRequest.poLineItem.purchaseOrder.project.id, {
                dispatch: true,
            });
            const insertedProject = {
                ...data,
                insertedProject: true,
            };

            dispatch(setCurrentProject(insertedProject, false));
            dispatch(updateProjects(insertedProject));
        }
    } catch (error) {
        setError(PROCESS_SERVER_ERROR(error));
    }
};

export const updateCurrentJobOrderRequest = (updatedJobOrderRequest) => {
    return {
        type: UPDATE_CURRENT_JOB_ORDER_REQUEST,
        payload: updatedJobOrderRequest,
    };
};

export const createOrUpdateJobOrderRequest = (formValues, poLineItemId, requestId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const timezone = selectAccountTimezone(state);

        const {
            dropOffSites,
            pickUpSite,
            isOvernight,
            totalQuantity,
            unlimited,
            startDate,
            requestNotes,
            numberOfTrucksRequested,
        } = formValues;
        const data = {
            dropOffSites: dropOffSites.map((site) => site.value),
            pickUpSite: pickUpSite.value,
            totalQuantity: totalQuantity ? +totalQuantity : 0,
            startDate: convertDateToUserTimeZone(startDate, timezone),
            unlimited: !!unlimited,
            requestNotes: requestNotes || "",
            isOvernight,
            poLineItemId,
            numberOfTrucksRequested: numberOfTrucksRequested ? +numberOfTrucksRequested : null,
        };
        const response = requestId ? await partialJobOrderUpdateById(requestId, data) : await createJobOrder(data);
        const requestJobOrder = response.data;

        dispatch(updateCurrentJobOrderRequest(requestJobOrder));

        return requestJobOrder;
    } catch (error) {
        formatReduxFormErrors(error);
    }
};

export const deleteJobOrderRequest = (requestId, setError) => async (dispatch, getState) => {
    try {
        return await deleteJobOrderById(requestId);
    } catch (error) {
        setError(PROCESS_SERVER_ERROR(error));
    }
};

export const getRequestTrucksAndHaulers = (requestId) => async (dispatch, getState) => {
    try {
        const { data: jolis } = await getJobBoardJobOrderJoli(requestId);
        return getSortedByTruckNameItems(jolis);
    } catch (error) {}
};

export const refreshJobsList = (payload) => ({ type: REFRESH_JOBS_LIST, payload });

export const updateRedirectJobId = (jobId) => {
    return {
        type: UPDATE_REDIRECT_JOB_ID,
        payload: jobId,
    };
};

export const getTrailersFromPreviousJob = (form, jobId, truckId) => async (dispatch) => {
    try {
        dispatch(showJoliTableLoader(true));
        const { data } = await getJobBoardJobOrderJoli(jobId);
        if (data && data.length && data[0] && data[0].trailers) {
            dispatch(change(form, `${truckId}-trailers`, data[0].trailers));
        }
        dispatch(showJoliTableLoader(false));
    } catch (e) {
        dispatch(showJoliTableLoader(false));
    }
};
