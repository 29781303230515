import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { change, formValueSelector, reduxForm, reset } from "redux-form";
import { END_DATE_NAME, START_DATE_NAME } from "../global/datesRange";
import { PAGE_GLOBAL_STYLES } from "../../styles/reusableStyles";
import { getJobOrderTrucksActivity, getTrucksTabData } from "../../dataServers/jobOrder";
import clsx from "clsx";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../constants/global";
import Loader from "../core/loader";
import { getTruckActivityAnalytics } from "../../dataServers/reportAndAnalytics";
import TruckActivityMap, { TRUCK_ACTIVITY_MAP_HEIGHT } from "./truckActivityMap";
import CustomTimeStampSlider from "../core/customTimeStampSlider";
import ErrorNotification from "../core/notification";
import { toDate, toMinutes } from "../../helpers/date";
import ReportFilters from "./reportFilters";
import EventIcon from "@material-ui/icons/Event";
import Container from "@material-ui/core/Container";
import TruckAllActivitiesChart from "./truckAllActivitiesChart";
import { ErrorBoundary } from "../core/errorBoudaries";
import { setTrucksActivityActiveTruck, setTrucksActivityMapData } from "../../actions/trucksAcitivityActions";
import ReportSubNav from "./reportSubNav";
import { setAppliedFiltersToLocalStorage } from "../../helpers/global";
import { MY_JOB_LIST_FORM } from "../../containers/Jobs";

import { GET_USER_INITIAL_FILTERS } from "../global/searchAndFilters";
import { compose } from "redux";
import { convertTimeToJsFormat } from "../../helpers/jobOrders";

const activityMaxTime = "23:59:59";
const activityMinTime = "+00:00:01";

const getTruckId = (value = "") => {
    return value.replace(/^\D+/g, "");
};

const TrucksActivity = (props) => {
    const timeFormat = "YYYY-MM-DDTHH:mm:ss";
    const [controlJobOrder, setControlJobOrder] = useState();
    const [trucks, setTrucksList] = useState([]);
    const [trucksActivity, setTrucksActivity] = useState();
    const [truckTime, setTruckTime] = useState(moment().format(timeFormat));
    const [showTruckInSpecificTime, setTruckSpecificTime] = useState();
    const [isTrucksLoading, setIsTrucksLoading] = useState(false);
    const [currentTruckLocation, setCurrentTruckLocation] = useState();
    const [error, setError] = useState(null);
    const [isMapLoading, setIsMapLoading] = useState(false);
    const [truckMapData, setTrucksMapData] = useState([]);
    const [trucksPuDoData, setTrucksPuDoData] = useState({});
    const [trucksActivityData, setTrucksActivityData] = useState([]);
    const [activeTruckData, setActiveTruckData] = useState([]);
    const {
        formValues: { [START_DATE_NAME]: startDate, [END_DATE_NAME]: endDate, jobOrder, project, activeTruck },
        account,
        dispatch,
        form,
        setActiveTruckMapData,
        setActiveTruck,
        timestampForMap,
        activeTruckId,
        history,
        notificationSelectedTruckId,
    } = props;

    useEffect(() => {
        dispatch(change(form, "jobOrder", jobOrder));
        dispatch(change(form, "project", project));

        return () => dispatch(reset(form));
    }, []);

    const [ticketsFilters, setFilters] = useState({ startDate, endDate });
    const wrapper = PAGE_GLOBAL_STYLES();
    const updateListByFilters = (filters, clearFilters) => {
        if (!clearFilters) {
            setFilters({
                ...ticketsFilters,
                ...filters,
                keywords: filters.keywords,
            });
        } else {
            setFilters({});
        }
    };

    const getTrucks = (newJobOrder = jobOrder, date = startDate) => {
        setIsTrucksLoading(true);
        resetTruckData();
        getTrucksTabData(newJobOrder.id)
            .then((data) => {
                const newTrucks = data.data;
                setTrucksList(newTrucks);
                setIsTrucksLoading(false);

                if (!_.isEmpty(newTrucks)) {
                    let activeTruck = notificationSelectedTruckId
                        ? newTrucks.find((i) => i.id === notificationSelectedTruckId)
                        : newTrucks[0];
                    dispatch(change(form, "activeTruck", `truck-${activeTruck.id}`));
                    setActiveTruckData(activeTruck);
                    loadTruckMapData(activeTruck.id);
                }
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setIsTrucksLoading(false);
            });
    };
    const loadTruckMapData = (truckId) => {
        truckId = truckId || (activeTruck ? activeTruck.id : null);
        if (!truckId) {
            return null;
        }

        setTrucksMapData([]);
        setTrucksPuDoData({});
        setCurrentTruckLocation(undefined);
        setIsMapLoading(true);

        if (!jobOrder) {
            return false;
        }

        Promise.all([getTruckActivityAnalytics(jobOrder.id, truckId), getJobOrderTrucksActivity(jobOrder.id)])
            .then(([{ data }, { data: trucksActivityData }]) => {
                if (!_.isEmpty(trucksActivityData)) {
                    setTrucksActivityData(trucksActivityData);
                    setTrucksActivity(trucksActivityData);
                }
                const newTruckMapData = data.truckData.map((locationData) => {
                    return {
                        ...locationData,
                        timestamp: convertTimeToJsFormat(locationData.timestamp, account.timezone),
                    };
                });
                const truckStartTime = getTruckMinTime(newTruckMapData);
                const newLocation = defineTruckLocation(newTruckMapData, truckStartTime);

                setTrucksMapData(newTruckMapData);
                setTrucksPuDoData({
                    dropoffLocations: data.dropoffLocations || [],
                    pickupLocation: data.pickupLocation || {},
                });
                setCurrentTruckLocation(!_.isEmpty(newTruckMapData) ? newLocation || currentTruckLocation : null);
                setIsMapLoading(false);
            })
            .catch((error) => {
                setIsMapLoading(false);
                setError(PROCESS_SERVER_ERROR(error));
            });
    };

    const getTimeValue = (time) => {
        setTruckTime(time);
    };
    const defineTruckLocation = (truckMapData, truckTime) => {
        return truckMapData.find((i) => {
            const time = moment(i.timestamp, "hh:mm").format("hh:mm A");
            const selectedTime = moment(truckTime).format("hh:mm A");

            return time === selectedTime;
        });
    };
    const getTruckMinTime = (truckMapData) => (truckMapData ? _.min(truckMapData.map((i) => i.timestamp)) : undefined);

    const getTruckMaxTime = (truckMapData) => {
        let timestampEndTime = truckMapData ? _.max(truckMapData.map((i) => i.timestamp)) : undefined;

        if (timestampEndTime) {
            timestampEndTime = toDate({ date: timestampEndTime, timezone: account.timezone }).format("hh:mm A");
        }

        return timestampEndTime;
    };
    const stampMinTime = getTruckMinTime(truckMapData) || activityMinTime;
    const stampMaxTime = getTruckMaxTime(truckMapData) || activityMaxTime;
    const resetTruckData = () => {
        setTrucksMapData([]);
        setActiveTruckData({});
        setTrucksList([]);
        setCurrentTruckLocation(undefined);
        setTrucksActivity(undefined);
        setTrucksActivityData([]);
    };

    useEffect(() => {
        if (truckMapData) {
            const newLocation = defineTruckLocation(truckMapData, truckTime);

            setCurrentTruckLocation(newLocation || currentTruckLocation || truckMapData[0]);
        }
    }, [truckTime]);

    useEffect(() => {
        if (jobOrder && controlJobOrder && startDate && _.isEqual(controlJobOrder.id, jobOrder.id)) {
        } else {
            jobOrder && jobOrder.id && getTrucks(jobOrder);
            setControlJobOrder(jobOrder);
        }

        if (!jobOrder) {
            resetTruckData();
        }
    }, [jobOrder, controlJobOrder]);

    useEffect(() => {
        setActiveTruckMapData(truckMapData);
    }, [truckMapData]);

    useEffect(() => {
        setActiveTruck(getTruckId(activeTruck));
    }, [activeTruck]);

    // useEffect(() => {
    //     setTrucksPuDoData({ dropoffLocations: DEFAULT_DATA.dropoffLocations, pickupLocation: DEFAULT_DATA.pickupLocation });
    // }, []);

    useEffect(() => {
        if (timestampForMap && activeTruckId && +activeTruckId === +getTruckId(activeTruck)) {
            let time = timestampForMap;
            const formated = moment(timestampForMap, "hh:mm").format("hh:mm A");
            const timeLessThanAllowed = moment(formated, "hh:mm A").isBefore(moment(stampMinTime, "hh:mm A"));
            const timeMoreThanAllowed = moment(formated, "hh:mm A").isAfter(moment(stampMaxTime, "hh:mm A"));

            if (timeLessThanAllowed) {
                time = stampMinTime;
            }

            if (timeMoreThanAllowed) {
                time = stampMaxTime;
            }

            setTruckSpecificTime(time);
            setTruckTime(time);
        }
    }, [timestampForMap]);

    const minTime = truckMapData ? _.min(truckMapData.map((i) => i.timestamp)) : undefined;

    const getMinTimeMinutes = () => toMinutes(minTime);
    const getMaxTimeMinutes = () => {
        const maxTime = truckMapData ? _.max(truckMapData.map((i) => i.timestamp)) : undefined;
        const duration = maxTime && minTime && moment.duration(moment(maxTime).diff(moment(minTime))).asMinutes();
        if (maxTime && minTime && duration) {
            const max = toMinutes(minTime) + Math.floor(duration) - toMinutes(maxTime);
            const result = toMinutes(maxTime) < toMinutes(minTime) ? max : toMinutes(maxTime);

            return result;
        }
    };

    const onRedirect = async () => {
        await setAppliedFiltersToLocalStorage(MY_JOB_LIST_FORM, { startDate, endDate });
        await history.push(`/jobs/my-jobs/${jobOrder.id}`);
    };

    return (
        <React.Fragment>
            <ReportSubNav />
            <Container className={wrapper.globalPageStyles}>
                <div style={{ minHeight: TRUCK_ACTIVITY_MAP_HEIGHT }}>
                    {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                    <ReportFilters
                        form={REPORT_TRUCKS_ACTIVITY_FORM}
                        showDownloadTickets={false}
                        formStyles={{ flexWrap: "nowrap" }}
                        onRedirect={onRedirect}
                        redirectIcon={() => <EventIcon style={{ marginRight: 5 }} />}
                        redirectLabel={jobOrder && "#" + jobOrder.id}
                        ticketsFilters={ticketsFilters}
                        showJobOrders={true}
                        initialValues={GET_USER_INITIAL_FILTERS(REPORT_TRUCKS_ACTIVITY_FORM)}
                        updateListByFilters={updateListByFilters}
                        setFilters={setFilters}
                    />
                    <br />
                    <br />
                    <Grid
                        container
                        justify={"center"}
                        alignItems={"flex-start"}
                        className={clsx(isTrucksLoading && LOADER_WHITE_OVERLAY)}
                    >
                        {isTrucksLoading && <Loader />}
                        {!_.isEmpty(trucks) ? (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <ErrorBoundary>
                                        {!_.isEmpty(trucks) && (
                                            <TruckAllActivitiesChart
                                                loadTruckMapData={loadTruckMapData}
                                                trucks={trucks}
                                                jobOrder={jobOrder}
                                                setActiveTruckData={setActiveTruckData}
                                                trucksActivity={trucksActivityData}
                                            />
                                        )}
                                    </ErrorBoundary>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <br />
                                <h2 className="--text-center">No trucks activity data</h2>
                            </React.Fragment>
                        )}
                    </Grid>
                    <br />
                    <div className={clsx(isMapLoading && LOADER_WHITE_OVERLAY)}>
                        {isMapLoading && <Loader />}
                        {!_.isEmpty(trucks) ? (
                            <React.Fragment>
                                <br />
                                <br />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <br />
                                <h2 className="--text-center">No trucks for selected job order</h2>
                                <br />
                            </React.Fragment>
                        )}
                        <div
                            className={clsx(isMapLoading && LOADER_WHITE_OVERLAY)}
                            style={{ minHeight: TRUCK_ACTIVITY_MAP_HEIGHT }}
                        >
                            {isMapLoading && <Loader />}
                            <TruckActivityMap
                                mapData={truckMapData}
                                trucksPuDoData={trucksPuDoData}
                                jobOrder={jobOrder}
                                truck={activeTruck}
                                currentLocation={
                                    currentTruckLocation
                                        ? {
                                              lat: currentTruckLocation.location.latitude,
                                              lng: currentTruckLocation.location.longitude,
                                          }
                                        : {}
                                }
                            />
                        </div>
                        <br />
                        <br />
                        <h2 className="--text-center">Trucks location in range:</h2>
                        <br />
                        {!_.isEmpty(activeTruckData) && !_.isEmpty(trucksActivityData) && (
                            <React.Fragment>
                                <div>
                                    <span>View route for truck: {activeTruckData.name}</span>
                                </div>
                                <br />
                            </React.Fragment>
                        )}
                        <CustomTimeStampSlider
                            disabled={_.isEmpty(truckMapData)}
                            time={minTime}
                            format={12}
                            timezone={account.timezone}
                            maxTime={getMaxTimeMinutes()}
                            minTime={getMinTimeMinutes()}
                            getCurrentValue={getTimeValue}
                            name="truckActivityStamp"
                        />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

TrucksActivity.propTypes = {
    dispatch: PropTypes.func.isRequired,
    setActiveTruckMapData: PropTypes.func.isRequired,
    setActiveTruck: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    timestampForMap: PropTypes.string,
    activeTruckId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    account: PropTypes.object.isRequired,
    history: PropTypes.object,
    notificationSelectedTruckId: PropTypes.number,
};

export const REPORT_TRUCKS_ACTIVITY_FORM = "truckActivity";

export default withRouter(
    compose(
        reduxForm({
            form: "truckActivity",
        }),
        connect(
            (state) => {
                const formSelector = formValueSelector(REPORT_TRUCKS_ACTIVITY_FORM);

                return {
                    account: state.account,
                    timestampForMap: state.trucksActivity.timestampForMap,
                    activeTruckId: state.trucksActivity.activeTruckId,
                    formValues: formSelector(
                        state,
                        "activeTruck",
                        "jobOrder",
                        "project",
                        START_DATE_NAME,
                        END_DATE_NAME,
                    ),
                    notificationSelectedTruckId: state.trucksActivity.notificationSelectedTruckId,
                };
            },
            (dispatch) => ({
                setActiveTruckMapData: (mapData) => dispatch(setTrucksActivityMapData(mapData)),
                setActiveTruck: (truckId) => dispatch(setTrucksActivityActiveTruck(truckId)),
            }),
        ),
    )(TrucksActivity),
);
