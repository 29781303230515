import React from "react";
import { StatusPill } from "../../core/pills";
import clsx from "clsx";
import { JOB_EXPORT_MODE_TOOLTIP_MESSAGE, JOB_IMPORT_MODE_TOOLTIP_MESSAGE } from "../../../constants/strings";
import Tooltip from "@material-ui/core/Tooltip";

export const SMART_DISPATCH_EXPORT_MODE_ID = 1;
export const SMART_DISPATCH_IMPORT_MODE_ID = 2;

export const SMART_DISPATCH_MODS_LIST = [
    {
        label: "Export",
        id: SMART_DISPATCH_EXPORT_MODE_ID,
        tooltipLabel: JOB_EXPORT_MODE_TOOLTIP_MESSAGE,
    },
    {
        label: "Import",
        id: SMART_DISPATCH_IMPORT_MODE_ID,
        tooltipLabel: JOB_IMPORT_MODE_TOOLTIP_MESSAGE,
    },
];

const SmartDispatchJobModePicker = ({ handleJobModeClick, activeModeId }) => {
    return SMART_DISPATCH_MODS_LIST.map((mode) => {
        const { id, label, tooltipLabel } = mode;
        const active = activeModeId === id;

        return (
            <Tooltip title={tooltipLabel} key={id}>
                <div>
                    <StatusPill
                        label={label}
                        className={clsx("filters-pill", active && "active")}
                        onClick={() => handleJobModeClick(id)}
                    />
                </div>
            </Tooltip>
        );
    });
};

export default SmartDispatchJobModePicker;
