import { requestReportNames } from "../dataServers/reportAndAnalytics";
import {
    REPORT_NAMES_ERROR,
    REPORT_NAMES_REQUEST,
    REPORT_NAMES_RESET_META,
    SET_REPORTS_NAMES,
} from "../events/globalEvents";
import { selectReportsNames } from "../selectors/index";
import { PROCESS_SERVER_ERROR } from "../constants/global";

export const setReportsNames = (payload) => ({ type: SET_REPORTS_NAMES, payload });
export const reportNamesRequest = (payload) => ({ type: REPORT_NAMES_REQUEST, payload });
export const reportNamesError = (payload) => ({ type: REPORT_NAMES_ERROR, payload });
export const reportNamesResetMeta = () => ({ type: REPORT_NAMES_RESET_META });

export const getReportNames = () => async (dispatch, getState) => {
    const reportsNames = selectReportsNames(getState());
    if (reportsNames) return;
    try {
        await dispatch(reportNamesRequest());
        const reports = await requestReportNames();
        await dispatch(setReportsNames(reports));
    } catch (error) {
        await dispatch(reportNamesError(PROCESS_SERVER_ERROR(error)));
        await dispatch(reportNamesResetMeta());
    }
};
