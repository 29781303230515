import React, { useEffect } from "react";
import { CARD_STYLES } from "../../styles/reusableStyles";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";
import moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router-dom";

import {
    getBundlesList,
    requestBundleDetails,
    requestNoneSmartDispatchJobs,
    requestRouteDetails,
    requestRoutes,
    setRoute,
    updateBundleFilters,
} from "../../actions/smartDispatch";
import BundleDetails from "./BundleDetails";
import BundleRoute from "./route/BundleRoute";
import BundlesFilters from "./BundleFiltersForm";
import {
    BUNDLES_FILTERS_FORM,
    SD_REGULAR_JOBS_LIST_FIELD,
    SD_REGULAR_JOBS_LIST_FORM,
    TRUCK_FILTERS_FORM,
    TRUCK_FILTERS_FORM_DATE_FIELD,
} from "../../constants/forms";
import { GET_USER_INITIAL_FILTERS } from "../global/searchAndFilters";
import { LOADER_WHITE_OVERLAY, SERVER_DATE_FORMAT } from "../../constants/global";
import { changeJoDate } from "../../actions/jobOrders";
import Loader, { fixedLoaderStyles } from "../core/loader";
import {
    selectActiveRegularJobsSortParam,
    selectBundlesFilters,
    selectBundlesList,
    selectBundlesListCount,
    selectCurrentBundle,
    selectRouteDetailsData,
    selectSmartDispatchIsLoading,
} from "../../selectors/smartDispatchSelectors";
import { selectAccount } from "../../selectors";
import { BundleList as ViewByBundles } from "./index";
import { ViewByTrucks } from "./../index";
import { BUNDLES_VIEW_BY, setUpUrl } from "./constants";
import { BUNDLE_DRAFT_MODE_FORM } from "./BundleDraftModeForm";

const useStyles = makeStyles((theme) => ({
    bundlesWrapper: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: "1%",

        "& .bundle-item": {
            padding: "10px",

            "& .bundle-job-container": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
            },

            "& .bundle-status-container": {
                width: "145px",
                whiteSpace: "nowrap",
            },

            "& .bundle-data-container": {
                textAlign: "right",
            },
        },

        "& .bundle-wrapper-message": {
            width: "100%",
        },
    },
}));

const BundlesWrapper = ({ jobFormId, viewBy, bundleId, truckId, routeNumber, history }) => {
    const cardStyles = CARD_STYLES();
    const classes = useStyles();
    const dispatch = useDispatch();

    const account = useSelector(selectAccount);

    const bundlesList = useSelector(selectBundlesList);
    const bundlesListCount = useSelector(selectBundlesListCount);

    const isLoading = useSelector(selectSmartDispatchIsLoading);

    const bundlesFilters = useSelector(selectBundlesFilters);
    const currentBundle = useSelector(selectCurrentBundle);

    const route = useSelector(selectRouteDetailsData);
    const activeRegularJobsSortParam = useSelector(selectActiveRegularJobsSortParam);
    const regularJobFilters = useSelector((state) =>
        formValueSelector(SD_REGULAR_JOBS_LIST_FORM)(state, SD_REGULAR_JOBS_LIST_FIELD),
    );
    const startDate = useSelector((state) => formValueSelector(BUNDLES_FILTERS_FORM)(state, "startDate"));
    const trucksStartDate = useSelector((state) =>
        formValueSelector(TRUCK_FILTERS_FORM)(state, TRUCK_FILTERS_FORM_DATE_FIELD),
    );
    const search = useSelector((state) => formValueSelector(BUNDLES_FILTERS_FORM)(state, "search"));
    const isDraft = useSelector((state) => formValueSelector(BUNDLE_DRAFT_MODE_FORM)(state, "isDraft"));

    const isViewByTrucks = viewBy === BUNDLES_VIEW_BY.TRUCK;
    const isViewByBundles = viewBy === BUNDLES_VIEW_BY.BUNDLE;

    const onBundleItemClick = async (bundleId) => {
        const isRedirect = await dispatch(requestBundleDetails(bundleId));
        isRedirect && setUpUrl(history.push)({ bundleId }).push();
    };

    const onRouteItemClick = (bundleId, routeNumber) => {
        dispatch(requestRouteDetails(bundleId, routeNumber));
        setUpUrl(history.push)({ bundleId, routeNumber }).push();
    };

    useEffect(() => {
        if (bundleId) {
            dispatch(requestBundleDetails(+bundleId));
        }
    }, []);

    useEffect(() => {
        if (truckId && routeNumber) {
            dispatch(requestRouteDetails(+truckId, +routeNumber));
        }
    }, []);

    useEffect(() => {
        if (!viewBy) {
            const { viewByBundle, push } = setUpUrl(history.push)();
            push(viewByBundle);
        }
    }, [viewBy]);

    const updateListByFilters = (filters) => {
        let newFilters = {
            startDate: startDate,
            endDate: startDate,
            keywords: search === "" ? null : search,
            ..._.cloneDeep(filters),
        };

        if (!_.isEqual(bundlesFilters, newFilters)) {
            dispatch(updateBundleFilters(newFilters));
        }
    };

    const handleStartDateChange = (date) => {
        const updatedDate = moment.isMoment(date) ? date.format(SERVER_DATE_FORMAT) : date;
        dispatch(changeJoDate(updatedDate));
        dispatch(change(TRUCK_FILTERS_FORM, TRUCK_FILTERS_FORM_DATE_FIELD, updatedDate));
        updateListByFilters({
            ...bundlesFilters,
            startDate: updatedDate,
            endDate: updatedDate,
        });
    };

    const getMoreBundles = (concat) => bundlesFilters && dispatch(getBundlesList(concat, bundlesFilters));

    const getBundles = () => dispatch(getBundlesList(false, bundlesFilters));

    useEffect(() => {
        if (!_.isEmpty(bundlesFilters) && isViewByBundles) {
            getBundles();
        }
    }, [bundlesFilters, viewBy, isDraft]);

    useEffect(() => {
        if (!_.isEmpty(bundlesFilters) && isViewByTrucks) {
            dispatch(requestRoutes());
        }
    }, [bundlesFilters?.regions, bundlesFilters?.keywords]);

    useEffect(() => {
        if (!_.isEmpty(bundlesFilters) && isViewByTrucks) {
            dispatch(requestRoutes());
            dispatch(requestNoneSmartDispatchJobs(false));
        }
    }, [trucksStartDate]);

    useEffect(() => {
        if (routeNumber && !activeRegularJobsSortParam) {
            return;
        }
        if (regularJobFilters || activeRegularJobsSortParam) {
            dispatch(requestNoneSmartDispatchJobs(false));
        }
    }, [regularJobFilters, activeRegularJobsSortParam]);

    useEffect(() => {
        return () => {
            dispatch(updateBundleFilters(null));
        };
    }, []);

    const renderView = () => {
        if (isViewByBundles) {
            return (
                <ViewByBundles
                    getBundles={getBundles}
                    bundlesList={bundlesList}
                    handleBundleItemClick={onBundleItemClick}
                    account={account}
                    cardStyles={cardStyles}
                    getMoreBundles={getMoreBundles}
                    bundlesListCount={bundlesListCount}
                    onBundleItemClick={onBundleItemClick}
                />
            );
        }
        if (isViewByTrucks) {
            return <ViewByTrucks onRouteItemClick={onRouteItemClick} />;
        }
    };

    if (isViewByBundles && currentBundle) {
        return <BundleDetails jobFormId={jobFormId} startDate={startDate} />;
    }

    if (isViewByTrucks && route) {
        return (
            <BundleRoute
                viewBy={viewBy}
                setRoute={() => dispatch(setRoute(route.solutionIndex))}
                key={route.solutionIndex}
                jobOrders={route.jobOrders}
                assignedTruck={route.assignedTruck}
                primeHauler={route.primeHauler}
                solutionIndex={route.solutionIndex}
                history={history}
                withCloseButton
            />
        );
    }

    return (
        <div className={clsx(classes.bundlesWrapper, isLoading && LOADER_WHITE_OVERLAY)}>
            {isLoading && <Loader style={fixedLoaderStyles()} />}
            <BundlesFilters
                isViewByBundles={isViewByBundles}
                startDate={startDate}
                updateListByFilters={(filters, clear) => {
                    updateListByFilters(filters, clear);
                }}
                handleStartDateChange={handleStartDateChange}
                initialValues={GET_USER_INITIAL_FILTERS(BUNDLES_FILTERS_FORM)}
            />
            {renderView()}
        </div>
    );
};

export default withRouter(BundlesWrapper);
