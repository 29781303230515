import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    inputFile: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "& input": {
            marginLeft: "10px",
        },
    },
}));

const InputFileComponent = (props) => {
    const classes = useStyles();
    const { label, disabled, classNames, input, title, accept } = props;
    delete input.value;

    return (
        <div className={clsx(classes.inputFile, classNames)}>
            {label ? label : ""}
            <input type="file" accept={accept} disabled={disabled} {...input} title={title || "No file Selected"} />
        </div>
    );
};

InputFileComponent.propTypes = {
    label: PropTypes.any,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    accept: PropTypes.string,
    classNames: PropTypes.any,
    input: PropTypes.object,
};

InputFileComponent.defaultProps = {
    disabled: false,
    accept: ".jpeg,.jpg,.png,.pdf",
};

export default withRouter(InputFileComponent);
