import {
    SITE_OPENED,
    SITE_SET_ALERT_ZONE_COORDINATES,
    SITE_SET_NEW_ALERT_ZONE_COORDINATES,
    SITES_FAILED,
    SITES_LOADED,
    SITES_LOADING,
    SITES_RESET,
    SITES_SET_FILTERS,
    SITES_SET_SEARCH_QUERY,
    SITES_UPDATED,
    SITES_VISIBLE_LOADING,
} from "../events/sites";
import _ from "lodash";
import * as SitesDataServer from "../dataServers/sites";
import { FILTERS_SITES_TYPE_ID, FILTERS_STATUS_ID } from "../components/global/searchAndFilters";
import history from "../utils/browserHistory";
import { getSiteEditRoute } from "../helpers/global";
import { GLOBAL_COUNT_TO_LOAD } from "../constants/endpoints";

export const getSites = (concat = false, siteId) => {
    return (dispatch, getState) => {
        dispatch({
            type: concat ? SITES_VISIBLE_LOADING : SITES_LOADING,
            payload: {},
        });

        const state = getState();
        const currentSite = state.sites.currentSite;
        const currentPage = Math.ceil(state.sites.sites.length / GLOBAL_COUNT_TO_LOAD);
        const nextPage = !_.isEmpty(state.sites.sites) ? +currentPage + 1 : 1;

        return SitesDataServer.getSites({
            page: concat ? nextPage : 1,
            keywords: state.sites.sitesSearchQuery,
            [FILTERS_SITES_TYPE_ID]: state.sites.sitesFilters[FILTERS_SITES_TYPE_ID],
            [FILTERS_STATUS_ID]: state.sites.sitesFilters[FILTERS_STATUS_ID],
        })
            .then(async (response) => {
                const newSites = concat ? [...state.sites.sites, ...response.data] : response.data;
                const account = getState().account;

                if ((newSites && newSites.length) || siteId) {
                    await dispatch(getCurrentSiteData(siteId || newSites[0].id, newSites, account));
                }

                await dispatch({
                    type: SITES_LOADED,
                    payload: {
                        sites: newSites,
                        sitesCount: response.meta.count,
                    },
                });

                return response;
            })
            .catch((error) => {
                dispatch({
                    type: SITES_FAILED,
                    payload: {
                        sitesError: error.message,
                    },
                });
            });
    };
};

export const getCurrentSiteData = (siteId, sites, account) => (dispatch, getState) => {
    return SitesDataServer.getSiteById(siteId || sites[0].id, account).then(async (data) => {
        if (sites && sites.length && sites[0].alertZoneRadius) {
            data.alertZoneRadius = +sites[0].alertZoneRadius;
        }

        history.push(getSiteEditRoute(data.id));
        await dispatch(setCurrentSite(data));
        await dispatch(updateSites(data));
    });
};

export const updateSites = (site) => {
    return (dispatch, getState) => {
        const state = getState();
        let updatedSites = state.sites.sites;

        if (site) {
            if (updatedSites.map((p) => p.id).includes(site.id)) {
                updatedSites = updatedSites.map((p) => (site.id === p.id ? site : p));
            } else {
                updatedSites.unshift(site);
            }
        }

        dispatch({
            type: SITES_UPDATED,
            payload: updatedSites,
        });
    };
};

export const setSitesSearchQuery = (payload) => {
    return {
        type: SITES_SET_SEARCH_QUERY,
        payload,
    };
};

export const setSitesFilters = (payload) => {
    return {
        type: SITES_SET_FILTERS,
        payload,
    };
};

export const resetSites = () => {
    return {
        type: SITES_RESET,
        payload: {},
    };
};

export const setCurrentSite = (payload) => {
    return {
        type: SITE_OPENED,
        payload,
    };
};

export const setAlertZoneCoordinates = (payload) => {
    return {
        type: SITE_SET_ALERT_ZONE_COORDINATES,
        payload,
    };
};

export const setNewAlertZoneCoordinates = (payload) => {
    return {
        type: SITE_SET_NEW_ALERT_ZONE_COORDINATES,
        payload,
    };
};
