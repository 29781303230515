import React, { useEffect, useState } from "react";
import AppModal from "../core/modal";
import { useDispatch, useSelector } from "react-redux";
import { assignToRoute, setSmartDispatchHaulerAssignConfirmationModal } from "../../actions/smartDispatch";
import { selectHaulerToAssign, selectSolutionEmptyRoutes } from "../../selectors/smartDispatchSelectors";
import SolutionTruckListItem from "./SolutionTruckListItem";
import { SecondaryButton } from "../core/buttons/secondaryButton";
import { PrimaryButton } from "../core/buttons/primaryButton";
import { ButtonsGroup } from "../core/buttons/buttonsGroup";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(() => ({
    routesContainer: {
        maxHeight: "70vh",
        overflowY: "scroll",
    },
}));

const HaulerAssignConfirmationModal = ({ isOpen }) => {
    const classes = styles();

    const dispatch = useDispatch();
    const emptyRoutes = useSelector(selectSolutionEmptyRoutes);
    const haulerToAssign = useSelector(selectHaulerToAssign);
    const [routesForHaulerToAssign, setRoutesForHaulerToAssign] = useState([]);
    const disableAssignButton = routesForHaulerToAssign.length === 0;
    const showCloseConfirmModal = routesForHaulerToAssign.length !== 0;

    const handleRouteSelection = (clickedRouteId) => {
        if (routesForHaulerToAssign.includes(clickedRouteId)) {
            const updatedSelectedRoutes = routesForHaulerToAssign.filter((routeId) => routeId !== clickedRouteId);
            return setRoutesForHaulerToAssign(updatedSelectedRoutes);
        }
        setRoutesForHaulerToAssign([...routesForHaulerToAssign, clickedRouteId]);
    };

    const onCloseModal = () => {
        dispatch(setSmartDispatchHaulerAssignConfirmationModal(false));
        setRoutesForHaulerToAssign([]);
    };

    const onAssignHauler = () => {
        const assignData = routesForHaulerToAssign.map((routeId) => {
            const route = emptyRoutes.find((emptyRoute) => emptyRoute.id === routeId);

            return {
                haulerId: haulerToAssign.id,
                index: route.index,
                haulerRate: haulerToAssign.defaultRate,
            };
        });
        dispatch(assignToRoute({ assignments: assignData }));
        onCloseModal();
    };

    useEffect(() => {
        return () => {
            setRoutesForHaulerToAssign([]);
        };
    }, []);

    return (
        <AppModal
            isOpen={isOpen}
            form={HAULER_ASSIGN_FORM}
            closeModal={onCloseModal}
            customDirtyProperty={showCloseConfirmModal}
        >
            <h1 className="--text-center">Select Routes For Hauler</h1>
            <div className={classes.routesContainer}>
                {emptyRoutes.map((route) => {
                    const { id } = route;
                    const truckIsSelected = routesForHaulerToAssign.includes(id);

                    return (
                        <SolutionTruckListItem
                            key={id}
                            route={route}
                            truckIsSelected={truckIsSelected}
                            handleTruckSolutionClick={handleRouteSelection}
                        />
                    );
                })}
            </div>
            <ButtonsGroup>
                <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
                <PrimaryButton onClick={onAssignHauler} disabled={disableAssignButton}>
                    Assign
                </PrimaryButton>
            </ButtonsGroup>
        </AppModal>
    );
};

export const HAULER_ASSIGN_FORM = "haulerAssignFrom";

export default HaulerAssignConfirmationModal;
