import React from "react";
import _ from "lodash";
import CreateTruckForm, { CREATE_TRUCK_FORM } from "./createTruckForm";
import { connect } from "react-redux";
import { handleTruckDialogToggle, setTruckForEdit } from "../../actions/trucks";
import { createTruck, editTruck } from "../../dataServers/trucks";
import AppModal from "../core/modal";
import PropTypes from "prop-types";

const TruckDialog = (props) => {
    const {
        closeTruckModal,
        truckForEdit,
        truckDialogOpen,
        setTruckForEdit,
        trucksFilters,
        onlyNameField,
        addTruckCompanyId,
        afterSaveHandler,
    } = props;
    const { truckName, truckType, company, id, region } = truckForEdit || {};

    const mapTruckData = (truckData) => {
        if (truckData) {
            return {
                region: !_.isEmpty(region)
                    ? {
                          value: region.id,
                          label: region.name,
                      }
                    : null,
                deviceName: truckName,
                truckType: truckType && truckType.id && truckType.id.toString(),
                capacity: truckData.capacity,
                volumeCapacity: truckData.volumeCapacity,
                company: company
                    ? {
                          value: company.id,
                          label: company.name,
                      }
                    : null,
            };
        }
    };

    const handleEdit = (data) => {
        return editTruck(id, data);
    };

    const handleSubmit = (data) => {
        return truckForEdit ? handleEdit(data) : createTruck([data]);
    };

    const handleClose = () => {
        closeTruckModal();
        setTruckForEdit(null);
    };

    return (
        <AppModal
            isOpen={truckDialogOpen}
            form={CREATE_TRUCK_FORM}
            modalStyles={{ width: 400 }}
            closeModal={handleClose}
        >
            <h2 className="title">{truckForEdit ? "EDIT TRUCK" : "NEW TRUCK"}</h2>
            <CreateTruckForm
                onSubmitHandler={handleSubmit}
                trucksFilters={trucksFilters}
                truckForEditMappedData={mapTruckData(truckForEdit)}
                handleClose={handleClose}
                onlyNameField={onlyNameField}
                addTruckCompanyId={addTruckCompanyId}
                afterSaveHandler={afterSaveHandler}
            />
        </AppModal>
    );
};

TruckDialog.propTypes = {
    truckForEdit: PropTypes.object,
    trucksFilters: PropTypes.object.isRequired,
    truckDialogOpen: PropTypes.bool,
    closeTruckModal: PropTypes.func.isRequired,
    setTruckForEdit: PropTypes.func.isRequired,
    onlyNameField: PropTypes.bool,
    addTruckCompanyId: PropTypes.number,
    afterSaveHandler: PropTypes.func,
};

export default connect(
    (state) => ({
        truckDialogOpen: state.trucks.truckDialogOpen,
        truckForEdit: state.trucks.truckForEdit,
        account: state.account,
    }),
    (dispatch) => ({
        closeTruckModal: (open) => dispatch(handleTruckDialogToggle(open)),
        setTruckForEdit: (truck) => dispatch(setTruckForEdit(truck)),
    }),
)(TruckDialog);
