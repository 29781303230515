import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";
import { formValueSelector } from "redux-form";
import _ from "lodash";

import { Tooltip } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles } from "@material-ui/styles";
import { ArchiveOutlined, UnarchiveOutlined } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";

import { LOADER_WHITE_OVERLAY } from "../../constants/global";
import { ActionLink, SimpleLink } from "../core/link";
import { TEXT_ACTIONS_TABLE_CELL } from "../../constants/texts";
import TableWithStickyHeader from "../core/tableWithStickyHeader";
import TabsCustom from "../core/tabsCustom";
import SuccessNotification from "../core/successNotification";
import ErrorNotification from "../core/notification";
import AppModal from "../core/modal";
import PropTypes from "prop-types";
import ConfirmationModal from "../core/confirmationModal";
import { IS_ADMIN_USER } from "../../constants/maps";
import Loader from "../core/loader";
import {
    changeTruckTypeStatus,
    deleteTruckType,
    getSettingsTruckTypes,
    settingsTruckTypesFiltersSet,
    truckTypesSettingsErrorMessage,
    truckTypesSettingsSuccessMessage,
    updateTruckType,
} from "../../actions/truckTypesSettings";
import UpdateOrCreateTruckTypeForm, { UPDATE_OR_CREATE_TRUCK_TYPE } from "./createOrUpdateTruckTypeForm";
import SettingsSearch from "./settingsSearch";
import SettingsCompanySelector from "./settingsCompanySelector";
import { getCapacityAdornment, getVolumeCapacityAdornment } from "../../helpers/measureSystemHelpers";
import { selectMeasureSystem } from "../../selectors";

const useStyles = makeStyles(() => ({
    truckTypesTable: {
        width: "100%",

        "&.no-actions": {
            "& td": {
                padding: 17,
            },
        },
    },
}));

const TruckTypesSettings = ({
    isLoading,
    truckTypesList,
    account,
    truckTypesListCount,
    truckTypesFilters,
    success,
    error,
    selectedCompany,
    measureSystem,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const companyToRequestId = selectedCompany?.value || account.company.id;
    const [activeTab, setActiveTab] = useState(0);
    const [openCreateOrUpdateTruckTypeModal, setOpenCreateOrUpdateTruckTypeModal] = useState(false);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [openArchiveConfirmationModal, setOpenArchiveConfirmationModal] = useState(false);
    const [confirmationModalTruckType, setConfirmationModalTruckType] = useState(undefined);
    const [activeTruckTypeToUpdate, setActiveTruckTypeToUpdate] = useState(undefined);

    const updateListByFilters = (filters, clear) => {
        let newFilters = { ..._.cloneDeep(truckTypesFilters), ..._.cloneDeep(filters) };
        if (!_.isEqual(truckTypesFilters, newFilters)) {
            dispatch(settingsTruckTypesFiltersSet(newFilters));
        }
    };

    const handleTruckTypeSaveSuccess = (successMessage) => {
        dispatch(truckTypesSettingsSuccessMessage(successMessage));
    };

    const fetchTruckTypes = () => {
        dispatch(getSettingsTruckTypes(false, companyToRequestId, truckTypesFilters));
    };

    const handleUpdateTruckType = (updatedTruckType) => {
        dispatch(updateTruckType(updatedTruckType));
    };

    const handleCloseConfirmationModal = () => {
        setOpenArchiveConfirmationModal(false);
        setOpenDeleteConfirmationModal(false);
        setConfirmationModalTruckType(undefined);
    };

    const onDeleteTruckType = () => {
        const { id, name } = confirmationModalTruckType;
        dispatch(deleteTruckType(id, name));
        handleCloseConfirmationModal();
    };

    const onArchiveTruckType = () => {
        const { archived, id, name } = confirmationModalTruckType;
        dispatch(changeTruckTypeStatus(archived, id, name));
        handleCloseConfirmationModal();
    };

    const getTableData = () => {
        const rows = truckTypesList?.map((truckType, index) => {
            const { name, id, archived, defaultCapacity, defaultVolumeCapacity, company, canBeArchived, canBeRemoved } =
                truckType;

            const setTruckTypeStatus = () => {
                dispatch(changeTruckTypeStatus(archived, id, name));
            };

            const onEditTruckTypeClick = () => {
                const updatedTruckType = {
                    id,
                    name,
                    defaultCapacity,
                    defaultVolumeCapacity,
                };

                setActiveTruckTypeToUpdate(updatedTruckType);
                setOpenCreateOrUpdateTruckTypeModal(true);
            };

            const renderOtherActionsButtons = () => {
                if (archived) {
                    return (
                        <IconButton title="Unarchive truck type" onClick={() => setTruckTypeStatus()}>
                            <UnarchiveOutlined />
                        </IconButton>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => onEditTruckTypeClick()}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {canBeArchived && (
                                <Tooltip title="Archive">
                                    <IconButton
                                        onClick={() => {
                                            setOpenArchiveConfirmationModal(true);
                                            setConfirmationModalTruckType({ archived, id, name });
                                        }}
                                    >
                                        <ArchiveOutlined />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {canBeRemoved && (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => {
                                            setOpenDeleteConfirmationModal(true);
                                            setConfirmationModalTruckType({ id, name });
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    );
                }
            };

            return {
                index,
                name,
                defaultCapacity,
                defaultVolumeCapacity,
                companyName: company.name,
                action: (
                    <Grid container justify={"center"} style={{ minHeight: 50 }}>
                        {renderOtherActionsButtons()}
                    </Grid>
                ),
            };
        });
        let columns = [
            {
                id: "name",
                label: "Name",
            },
            {
                id: "defaultCapacity",
                label: `Default Weight Capacity (${getCapacityAdornment(measureSystem)})`,
            },
            {
                id: "defaultVolumeCapacity",
                label: `Default Volume Capacity (${getVolumeCapacityAdornment(measureSystem)})`,
            },
            {
                id: "action",
                label: TEXT_ACTIONS_TABLE_CELL,
            },
        ];

        if (IS_ADMIN_USER(account.company.id)) {
            columns.splice(1, 0, {
                id: "companyName",
                label: "Company Name",
            });
        }

        return {
            rows: rows,
            columns: columns,
        };
    };

    const getMoreTruckTypes = (concat) =>
        dispatch(getSettingsTruckTypes(concat, companyToRequestId, truckTypesFilters));

    const onAddNewTruckTypeClick = () => {
        setActiveTruckTypeToUpdate(null);
        setOpenCreateOrUpdateTruckTypeModal(true);
    };

    const errorHandler = (error) => {
        dispatch(truckTypesSettingsErrorMessage(error));
    };

    useEffect(() => {
        if (!_.isEmpty(truckTypesFilters)) {
            dispatch(getSettingsTruckTypes(false, companyToRequestId, truckTypesFilters));
        }
    }, [truckTypesFilters, selectedCompany]);

    useEffect(() => {
        const updatedFilters = {
            ..._.cloneDeep(truckTypesFilters),
            archived: activeTab === 1,
        };
        if (!_.isEqual(updatedFilters, truckTypesFilters)) {
            dispatch(settingsTruckTypesFiltersSet(updatedFilters));
        }
    }, [activeTab]);

    useEffect(() => {
        return () => {
            dispatch(settingsTruckTypesFiltersSet({}));
        };
    }, []);

    return (
        <>
            <Grid container spacing={1} className={clsx(isLoading && LOADER_WHITE_OVERLAY)}>
                <Loader />
                <Grid container direction="row" justify="space-between">
                    <Grid item justify="flex-start">
                        <ActionLink>
                            <AddCircleOutlineIcon />
                            <SimpleLink onClick={() => onAddNewTruckTypeClick()}>Add new truck type</SimpleLink>
                        </ActionLink>
                    </Grid>
                    <Grid item xs={6}>
                        <SettingsSearch
                            updateListByFilters={updateListByFilters}
                            form={TRUCK_TYPES_SETTINGS_FILTERS_FORM}
                        />
                    </Grid>
                    {IS_ADMIN_USER(account.company.id) && (
                        <SettingsCompanySelector
                            form={SETTINGS_TRUCK_TYPES_COMPANY_SELECTOR}
                            initialValues={{
                                company: {
                                    value: account.company.id,
                                    label: account.company.name,
                                },
                            }}
                        />
                    )}
                    <TabsCustom
                        tabsHeaders={[{ label: "Active" }, { label: "Archive" }]}
                        onChangeTab={(tab) => setActiveTab(tab)}
                    />
                </Grid>
                {!_.isEmpty(truckTypesList) ? (
                    <Grid item>
                        <TableWithStickyHeader
                            rows={getTableData().rows}
                            fetchMore={getMoreTruckTypes}
                            itemsCount={truckTypesListCount}
                            className={clsx(classes.truckTypesTable)}
                            columns={getTableData().columns}
                        />
                    </Grid>
                ) : isLoading ? null : (
                    <Grid container justify="center">
                        <h1 className="--text-center">No truck types match your search</h1>
                    </Grid>
                )}
            </Grid>
            {!_.isEmpty(success) && (
                <SuccessNotification
                    message={success}
                    config={{ onClose: () => dispatch(truckTypesSettingsSuccessMessage([])) }}
                />
            )}
            {!_.isEmpty(error) && (
                <ErrorNotification
                    message={error}
                    config={{ onClose: () => dispatch(truckTypesSettingsErrorMessage([])) }}
                />
            )}
            <AppModal
                isOpen={!!openCreateOrUpdateTruckTypeModal}
                form={UPDATE_OR_CREATE_TRUCK_TYPE}
                closeModal={() => setOpenCreateOrUpdateTruckTypeModal(false)}
            >
                <UpdateOrCreateTruckTypeForm
                    closeModal={() => setOpenCreateOrUpdateTruckTypeModal(false)}
                    handleTruckTypeSaveSuccess={handleTruckTypeSaveSuccess}
                    truckTypeToUpdateId={activeTruckTypeToUpdate?.id}
                    initialValues={activeTruckTypeToUpdate}
                    errorHandler={errorHandler}
                    fetchTruckTypes={fetchTruckTypes}
                    handleUpdateTruckType={handleUpdateTruckType}
                    companyToRequestId={companyToRequestId}
                />
            </AppModal>
            <ConfirmationModal
                isOpen={openDeleteConfirmationModal || openArchiveConfirmationModal}
                question={`Are you sure you want to ${openDeleteConfirmationModal ? "delete" : "archive"} truck type?`}
                yesHandler={openDeleteConfirmationModal ? onDeleteTruckType : onArchiveTruckType}
                noHandler={() => handleCloseConfirmationModal()}
            />
        </>
    );
};

TruckTypesSettings.propTypes = {
    account: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    truckTypesList: PropTypes.array,
    truckTypesListCount: PropTypes.number,
    truckTypesFilters: PropTypes.object,
    success: PropTypes.string,
    error: PropTypes.string,
    selectedCompany: PropTypes.object,
};

export const SETTINGS_TRUCK_TYPES_COMPANY_SELECTOR = "settingsTruckTypesCompanySelector";
export const TRUCK_TYPES_SETTINGS_FILTERS_FORM = "truckTypesSettingsFiltersForm";

export default withRouter(
    connect((state, props) => {
        const formSelector = formValueSelector(SETTINGS_TRUCK_TYPES_COMPANY_SELECTOR);

        return {
            account: state.account,
            truckTypesList: state.truckTypesSettings.truckTypesList,
            truckTypesListCount: state.truckTypesSettings.truckTypesListCount,
            truckTypesFilters: state.truckTypesSettings.truckTypesFilters,
            isLoading: state.truckTypesSettings.isLoading,
            success: state.truckTypesSettings.success,
            error: state.truckTypesSettings.error,
            selectedCompany: formSelector(state, "company"),
            measureSystem: selectMeasureSystem(state),
        };
    })(TruckTypesSettings),
);
