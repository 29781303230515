import {
    GET_TEAM_USERS_SUCCESS,
    IS_LOADING_USERS,
    RESET_TEAMS_USERS,
    SET_USER_FOR_EDIT,
    TEAM_DIALOG_TOGGLE,
    UPDATE_ACTIVE_TEAM_USERS_ORDER,
    REQUEST_USER_EVENTS,
    RESULT_USER_EVENTS,
    RESET_USER_EVENTS,
    USER_EVENTS_ERROR,
    USER_EVENTS_SUSPEND_PERIOD,
    USER_EVENTS_RESET_META,
} from "../events/team";
import { LOGOUT_USER } from "../events/globalEvents";

const initialState = {
    isLoadingUsers: false,
    teamDialogOpen: false,
    userForEdit: null,
    teamUsers: [],
    usersCount: 1,
    activeTeamUsersOrder: "lastName,firstName",
    userEvents: {
        data: null,
        suspendPeriod: null,
        meta: {
            loading: false,
            error: null,
        },
    },
};

export const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case TEAM_DIALOG_TOGGLE: {
            return {
                ...state,
                teamDialogOpen: action.open,
            };
        }

        case SET_USER_FOR_EDIT: {
            return {
                ...state,
                userForEdit: action.payload,
            };
        }

        case GET_TEAM_USERS_SUCCESS: {
            return {
                ...state,
                teamUsers: [...action.payload.teamUsers],
                usersCount: action.payload.usersCount,
            };
        }
        case RESET_TEAMS_USERS: {
            return {
                ...state,
                usersCount: 1,
                teamUsers: [],
            };
        }
        case IS_LOADING_USERS: {
            return {
                ...state,
                isLoadingUsers: action.payload,
            };
        }
        case LOGOUT_USER:
            return initialState;

        case UPDATE_ACTIVE_TEAM_USERS_ORDER: {
            const updatedOrderParam = action.payload;
            const activeTeamUsersOrder = state.activeTeamUsersOrder;
            if (activeTeamUsersOrder && updatedOrderParam === activeTeamUsersOrder) {
                return {
                    ...state,
                    activeTeamUsersOrder: `-${updatedOrderParam}`,
                };
            }

            return {
                ...state,
                activeTeamUsersOrder: updatedOrderParam,
            };
        }

        case REQUEST_USER_EVENTS: {
            return {
                ...state,
                userEvents: {
                    ...state.userEvents,
                    meta: {
                        ...state.userEvents.meta,
                        loading: true,
                    },
                },
            };
        }
        case RESULT_USER_EVENTS: {
            return {
                ...state,
                userEvents: {
                    ...state.userEvents,
                    data: action.payload,
                    meta: {
                        ...state.userEvents.meta,
                        loading: false,
                    },
                },
            };
        }
        case USER_EVENTS_SUSPEND_PERIOD: {
            return {
                ...state,
                userEvents: {
                    ...state.userEvents,
                    suspendPeriod: action.payload,
                    meta: {
                        ...state.userEvents.meta,
                        loading: false,
                    },
                },
            };
        }
        case USER_EVENTS_ERROR: {
            return {
                ...state,
                userEvents: {
                    ...state.userEvents,
                    meta: {
                        ...state.userEvents.meta,
                        loading: false,
                        error: action.payload,
                    },
                },
            };
        }
        case USER_EVENTS_RESET_META: {
            return {
                ...state,
                userEvents: {
                    ...state.userEvents,
                    meta: initialState.userEvents.meta,
                },
            };
        }
        case RESET_USER_EVENTS: {
            return {
                ...state,
                userEvents: initialState.userEvents,
            };
        }
        default:
            return state;
    }
};
