import React from "react";

import ActionMenuItem from "../core/actionMenuItem";
import MoreActionsMenu from "../core/moreActions";
import AddBoxIcon from "@material-ui/icons/AddBox";

const TrailersActionMenu = (props) => {
    const visibleItems = [
        {
            id: "edit",
            title: "Edit",
            renderIcon: () => <AddBoxIcon />,
            handleClick: props.editTrailer,
            visible: true,
        },
        {
            id: "archive",
            title: "archive",
            renderIcon: () => <AddBoxIcon />,
            handleClick: props.archiveTrailer,
            visible: true,
        },
    ];

    return (
        <React.Fragment>
            <MoreActionsMenu>
                {visibleItems.map((menuItem) => {
                    const { id, title, renderIcon, handleClick } = menuItem;

                    return <ActionMenuItem key={id} title={title} renderIcon={renderIcon} handleClick={handleClick} />;
                })}
            </MoreActionsMenu>
        </React.Fragment>
    );
};

TrailersActionMenu.propTypes = {};

export default TrailersActionMenu;
