import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    pillWrap: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium,
        color: "#000",
        display: "flex",
        alignItems: "center",

        "& input": {
            display: "none",

            "&:checked + .pill": {
                backgroundColor: theme.palette.general.softOrange,
            },
        },
    },
    pill: {
        backgroundColor: theme.palette.secondary.superLight,
        height: 32,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: theme.shape.borderRadius * 4,
        color: theme.palette.secondary.superDark,
        fontSize: theme.typography.fontSize,
        textTransform: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const PillSwitch = (props) => {
    const classes = useStyles();
    const { label, input, className, disabled, type, id, checked } = props;

    return (
        <label className={clsx(classes.pillWrap, className)}>
            <input
                type={type}
                id={id}
                name={input.name}
                checked={checked || input.checked}
                value={input.value}
                onChange={input.onChange}
                disabled={disabled}
            />
            <div className={clsx(classes.pill, "pill")}>{label}</div>
        </label>
    );
};

PillSwitch.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    id: PropTypes.string,
    input: PropTypes.object,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    type: PropTypes.string,
};

PillSwitch.defaultProps = {
    id: null,
    checked: undefined,
};

export default PillSwitch;
