import { toCapitalLetter } from "./global";
import { isPlainObject, cloneDeep } from "lodash";

export const timberAdditionalFields = ["Delivered Price", "Cut Rate", "Stumpage Rate", "Haul Rate"];

export const getAdditionalFieldName = (fieldName) => {
    let namesArray = fieldName.split("_"),
        name;
    namesArray = namesArray.map((word) => toCapitalLetter(word));
    name = namesArray.join(" ");

    return name;
};

export const getAdditionalName = (fieldName) => {
    let namesArray = fieldName.split("_"),
        name;
    name = namesArray.join(" ");

    return name;
};

export const prepareAdditionalName = (fieldName) => {
    let namesArray = fieldName.split(" "),
        name;
    name = namesArray.join("_");

    return name;
};

export const getAdditionalFieldValue = (array, name) => {};

export const prepareAdditionalFieldName = (fieldName) => {
    let namesArray = fieldName.toLowerCase().split(" "),
        name;
    name = namesArray.join("_");

    return name;
};

export const handleAdditionalFields = (additionalFields) => {
    const fields = {};

    const keys = Object.keys(additionalFields).filter(
        (i) => !isPlainObject(additionalFields[i]) && i !== "trailers_quantity",
    );
    keys && keys.forEach((i) => (fields[i] = additionalFields[i]));

    return Object.keys(fields);
};

export const prepareAdditionalFields = (additionalFields) => {
    if (!additionalFields) return {};
    const fields = {};

    const keys = Object.keys(additionalFields).filter((i) => !isPlainObject(additionalFields[i]));
    keys && keys.forEach((i) => (fields[i] = additionalFields[i]));
    const secondaryKeys = Object.keys(additionalFields).filter((i) => isPlainObject(additionalFields[i]));
    secondaryKeys &&
        secondaryKeys.forEach((i) => {
            const item = Object.keys(additionalFields[i]).filter((key) => additionalFields[i][key] === 0);
            const value = item[0];
            if (value) {
                const key = secondaryKeys[0];
                fields[key] = value;
            }
        });

    return fields;
};

export const getPhaseCodeFromAdditionalFields = (fields) => {
    if (!fields) return;
    const additionalFields = cloneDeep(fields);
    if (additionalFields.attached_files) {
        delete additionalFields.attached_files;
    }
    const phaseCode = Object.values(additionalFields || {}).filter((i) => isPlainObject(i));
    const phaseCodeKey = phaseCode && phaseCode.length && Object.keys(phaseCode[0]);

    if (phaseCode && phaseCode.length && phaseCodeKey && phaseCodeKey.length) {
        return {
            phaseCode: phaseCodeKey[0],
            phaseCodeQuantity: phaseCode[0][phaseCodeKey[0]],
        };
    }

    return {};
};
