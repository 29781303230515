import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import clsx from "clsx";

import { ButtonsGroup } from "../../core/buttons/buttonsGroup";
import { PrimaryButton } from "../../core/buttons/primaryButton";
import Loader from "../../core/loader";
import TextInputComponent from "../../core/form/textInputComponent";
import { Validation } from "../../../helpers/validation";
import ErrorNotification from "../../core/notification";
import { SecondaryButton } from "../../core/buttons/secondaryButton";
import PhoneField from "../../global/phoneField";
import EmailField from "../../global/emailField";
import { LOADER_WHITE_OVERLAY, PROCESS_SERVER_ERROR } from "../../../constants/global";
import { inviteHauler } from "../../../dataServers/companies";

const useStyles = makeStyles((theme) => ({
    editFilterForm: {
        "& .companies-list": {
            overflowY: "scroll",
            height: 400,
            paddingRight: 10,
            marginTop: 10,
        },
        "& .fleet-filters": {
            position: "relative",
            marginBottom: 15,
            marginTop: 10,
            paddingBottom: 7,
            borderBottom: `1px solid ${theme.palette.border.primary}`,
        },
    },
}));

const InviteHaulersForm = (props) => {
    const classes = useStyles();
    const [error, setError] = useState();
    const [isLoading, setLoading] = useState();
    const { openInviteHaulerModal, handleSubmit, handleFormSave } = props;

    const onSubmit = (values) => {
        setLoading(true);

        return inviteHauler(values)
            .then((data) => {
                setLoading(false);
                openInviteHaulerModal(false);
                handleFormSave();
            })
            .catch((error) => {
                setError(PROCESS_SERVER_ERROR(error));
                setLoading(false);
            });
    };

    return (
        <div className={clsx(isLoading && LOADER_WHITE_OVERLAY, classes.editFilterForm)}>
            <form>
                {isLoading && <Loader />}
                {error && <ErrorNotification error={error} config={{ onClose: () => setError(null) }} />}
                <h1 className="title">Invite Hauler</h1>
                <br />
                <Field
                    name="name"
                    validate={[Validation.required, Validation.noSpecialSymbols]}
                    type="text"
                    label="Hauler Name"
                    component={TextInputComponent}
                />
                <br />
                <br />
                <PhoneField isRequired={true} />
                <br />
                <br />
                <EmailField isRequired={true} />
            </form>
            <br />
            <br />
            <ButtonsGroup>
                <SecondaryButton onClick={() => openInviteHaulerModal(false)}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleSubmit(onSubmit)}>Save</PrimaryButton>
            </ButtonsGroup>
        </div>
    );
};

InviteHaulersForm.propTypes = {
    openInviteHaulerModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleFormSave: PropTypes.func.isRequired,
};

export const INVITE_HAULER_FORM = "inviteHaulerForm";

export default withRouter(
    compose(
        reduxForm({
            form: INVITE_HAULER_FORM,
            enableReinitialize: true,
        }),
        connect((state) => {
            return {
                account: state.account,
            };
        }),
    )(InviteHaulersForm),
);
